import React from "react";
import FinancialNavigation from "./components/FinancialNavigation";
import FinanceTableMain from "../../../../components/tables/FinanceTable/FinanceTableMain";
import { Menu, MenuItem } from "@szhsin/react-menu";
import { default as MuiButton } from "@mui/material/Button";
import { ArrowDown, StarChartIcon, TrashCan } from "../../../../components/icons/svgIcons";
import { FinancialStatementsTypes } from "../../../../data/Finance/Dashboards/constants";
import "@szhsin/react-menu/dist/index.css";
import "../../../Admin/Dashboards/components/dashboardsBlankAdmin.scss";
import "./index.scss";
import FooterContent from "../../Financials/components/Footer";
import IconButton from "@mui/material/IconButton";
import { ExpandCollapseTablesID } from "../../../../data/Finance/constants";

export default class DisplayReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: new Date().getTime(),
      loading: true,
      months: this.props.allMonths,
      years: this.props.allYears,
      allYears: this.props.allYears,
      allMonths: this.props.allMonths,
      selectedSubsection: props.selectedSubsection,
      types: FinancialStatementsTypes,
      selectedType: props.defaultView.financial_statements,
    };
    this.gridRows = props.selectedSubsection.gridRows;
  }

  componentDidMount() {
    this.getMonths();
    this.getYears();
    this.setData();
  }

  getMonths = (
    changeState = false,
    start = this.props.financialMonths.startMonthOrder,
    end = this.props.financialMonths.endMonthOrder,
  ) => {
    const getFromState = this.state.allMonths;
    this.state.months = getFromState.filter(
      (m) => m.Order >= start && m.Order <= end && m.PeriodType === "month",
    );
    if (changeState) {
      this.props.saveDates();
      this.props.updateDataGrid();
      this.forceUpdate();
    }
  };

  getYears = (
    changeState = false,
    start = this.props.financialMonths.startYearOrder,
    end = this.props.financialMonths.endYearOrder,
  ) => {
    const getFromState = this.state.allYears;
    this.state.years = getFromState.filter(
      (m) => m.Order >= start && m.Order <= end && m.PeriodType === "year",
    );
    if (changeState) {
      this.props.saveDates();
      this.props.updateDataGrid();
      this.forceUpdate();
    }
  };

  setData = (selectedReport) => {
    if (selectedReport) {
      this.state.selectedSubsection = selectedReport;
      this.gridRows = selectedReport.gridRows;
    }
    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 100);
  };

  changeSubsection = (section) => {
    if (this.state.selectedSubsection != section) {
      this.setState({ loading: true }, () =>
        this.props.setSelectedSubsection(section, () => {
          this.setData(section);
        }),
      );
    }
  };

  changeType = (e, value) => {
    this.props.defaultView.financial_statements = value;
    this.setState({ selectedType: value });
  };

  getGridRows = () => {
    const checkChildrens = (row) => {
      if (row.children && row.children.length > 0) {
        row.children.forEach((c) => checkView(c));
      }
    };

    const checkView = (row) => {
      if (
        this.state.selectedType === FinancialStatementsTypes.Quarterly ||
        this.state.selectedType === FinancialStatementsTypes.Comparative
      ) {
        row.data = row.views.tmpData;
      } else {
        row.data = row.views.data;
      }
      if (row.header === "Change In Working Capital" || row.header === "Change In Debt") {
        row.isExpanded = false;
      }

      checkChildrens(row);

      return row;
    };

    return this.gridRows
      .map((row) => {
        if (
          this.state.selectedType === FinancialStatementsTypes.Comparative &&
          row.header === "Revenue Growth"
        ) {
          return null;
        } else {
          row = checkView(row);
          checkChildrens(row);
          return row;
        }
      })
      .filter((d) => d);
  };

  changeColumns = () => {
    this.forceUpdate();
  };

  getTable = (rows, Headers, isComparative, isEditable = true) => (
    <FinanceTableMain
      rows={rows}
      wrapperClassName={"max_height"}
      headers={Headers}
      showRowHeaders={true}
      useActual={true}
      disabledEdit={!isEditable || !global.Modeliks.PERMISSIONS.Dashboards.restrictions.edit.active}
      disableExpandAll={
        !isEditable || !global.Modeliks.PERMISSIONS.Dashboards.restrictions.edit.active
      }
      isCustomDashboard={!isEditable}
      disabledMove={true}
      useCustomizableTable={true}
      disableRowType
      applyItalic={true}
      dates={
        this.state.selectedType === FinancialStatementsTypes.Annual
          ? this.state.years
          : this.state.months
      }
      useExpandCollapseStore={true}
      ExpandCollapseID={ExpandCollapseTablesID.Dashboards.FinancinalStatements}
      useDashboards={true}
      isComparativeView={isComparative}
      useDateIndexes={true}
      textColor={isComparative}
      editableColor={isEditable}
      unitDisplay={true}
      titleDate={this.props.titleDate}
      title={
        <>
          {this.state.selectedSubsection.name}
          {!this.props.customDashboard ? (
            <IconButton onClick={this.props.addTableToCustomDashboard}>
              <StarChartIcon />
            </IconButton>
          ) : (
            ""
          )}
        </>
      }
      // onDataChanged={this.handleGridDataChanged}
    />
  );

  getFooterComponent = (getHeaders) => (
    <FooterContent
      showCheckBox={true}
      disabled={this.EditPermission}
      hideCheckBox
      disablePrev={true}
      getHeaders={getHeaders}
      changeType={this.changeType}
      disableFilter={!this.props.customDashboard}
      selectedType={this.state.selectedType}
      customDashboard={this.props.customDashboard}
      showMonthly={this.state.showMonthly}
      changeColumns={this.changeColumns}
      updateTable={() => this.forceUpdate()}
      // showCashFlowMessage={!hideMessage && this.state.selectedSubsection && this.state.selectedSubsection.key === 'CashFlow'}
      useCustomizableTable={true}
      showMonthlyOnChange={() => this.setState({ showMonthly: !this.state.showMonthly })}
    />
  );

  render() {
    if (this.state.loading) {
      return this.props.loader;
    }
    const isQuarterly = this.state.selectedType === FinancialStatementsTypes.Quarterly;
    const isComparative = this.state.selectedType === FinancialStatementsTypes.Comparative;
    const isMonthly = this.state.selectedType === FinancialStatementsTypes.Monthly;
    const isTmpData = isQuarterly || isComparative;
    const qMonths = this.props.FinancialStatements.headers.quarterly;
    const cMonths = this.props.FinancialStatements.headers.comparative;
    const mMonths = this.state.months;
    const yYears = this.state.years;
    const getHeaders =
      isTmpData && isQuarterly
        ? qMonths
        : isTmpData && isComparative
          ? cMonths
          : !isTmpData && isMonthly
            ? mMonths
            : yYears;
    let Headers = isComparative
      ? getHeaders.filter((h) => h.checked)
      : isQuarterly
        ? qMonths.filter(
            (h) =>
              h.Order + 2 >= this.props.curMonths.startMonthOrder &&
              h.Order <= this.props.curMonths.endMonthOrder,
          )
        : getHeaders;
    if (isTmpData && isQuarterly) {
      Headers = JSON.parse(JSON.stringify(Headers));
      Headers.forEach((h) => {
        if (
          this.props.curMonths.startMonthOrder <= h.Order &&
          this.props.curMonths.startMonthOrder <= h.Order + 2
        ) {
        } else if (this.props.curMonths.startMonthOrder <= h.Order + 1) {
          h.Header = h.HeaderMonths[1] + "-" + h.HeaderMonths[2] + " " + h.HeaderMonths[3];
        } else {
          h.Header = h.HeaderMonths[2] + " " + h.HeaderMonths[3];
        }
        if (
          this.props.curMonths.endMonthOrder >= h.Order &&
          this.props.curMonths.endMonthOrder >= h.Order + 2
        ) {
        } else if (this.props.curMonths.endMonthOrder >= h.Order + 1) {
          h.Header = h.HeaderMonths[0] + "-" + h.HeaderMonths[1] + " " + h.HeaderMonths[3];
        } else {
          h.Header = h.HeaderMonths[0] + " " + h.HeaderMonths[3];
        }

        if (
          this.props.curMonths.endMonthOrder >= h.Order + 1 &&
          this.props.curMonths.startMonthOrder <= h.Order + 1 &&
          this.props.curMonths.endMonthOrder === this.props.curMonths.startMonthOrder
        ) {
          h.Header = h.HeaderMonths[1] + " " + h.HeaderMonths[3];
        }
      });
    }
    const rows = this.getGridRows();
    if (this.props.customDashboard) {
      return (
        <>
          <div>{this.getFooterComponent(getHeaders)}</div>
          <div className={"main_table_container table_container"}>
            {this.getTable(rows, Headers, isComparative, false)}
          </div>
        </>
      );
    }

    return (
      <React.Fragment>
        <div className="psdf_menu_navigation">
          <Menu
            arrow
            menuClassName="psdf_menu"
            menuButton={
              <MuiButton endIcon={<ArrowDown />} className="psdf_menu_section_button">
                {this.state.selectedSubsection.name}
              </MuiButton>
            }
          >
            {Object.values(this.props.FinancialStatements.subsections).map(
              (c, index) =>
                c.key !== "EmployeeSchedule" && (
                  <MenuItem key={"dash_" + index} onClick={() => this.changeSubsection(c, index)}>
                    {c === this.state.selectedSubsection ? <strong>{c.name}</strong> : c.name}
                  </MenuItem>
                ),
            )}
          </Menu>

          <FinancialNavigation
            financialMonths={this.props.financialMonths}
            types={this.state.types}
            changeType={this.changeType}
            getMonths={this.getMonths}
            getYears={this.getYears}
            getHeaders={getHeaders}
            months={this.state.allMonths}
            years={this.state.allYears}
            selectedType={this.state.selectedType}
            saveSettings={this.props.saveSettings}
            changeColumns={this.changeColumns}
            selectedSubsection={this.props.selectedSubsection}
            exportReport={this.props.FinancialStatements.exportReport}
            exportAllReports={this.props.FinancialStatements.exportAllReports}
            periods={Headers}
            useActual={true}
            isTmpData={isTmpData}
            title={this.state.selectedSubsection.name}
            rows={rows}
            pdfReportType={this.props.FinancialStatements.selectedSubsection.shortName}
          />
        </div>

        {this.getFooterComponent(getHeaders)}

        <div className="main_table_container report_table">
          {this.gridRows && (
            <div className="psf_reports_export_pdf" id="report_table">
              {this.getTable(rows, Headers, isComparative)}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}
