import React from "react";
import styled from "styled-components";
import { Theme } from "../../theme/styles/theme";

const StyledVector = styled.div`
  border-bottom: 1px solid
    ${(props) =>
      props.color ? Theme.colors.primary.lightgrey[500] : Theme.colors.primary.lightgrey[150]};
  margin-top: ${(props) => (props.top ? "20px" : 0)};
`;

export const Vector = ({ ...props }) => {
  return <StyledVector color={props.color} top={props.top} />;
};
