import React from "react";
import request from "superagent";
import ButtonMui from "../../../../components/buttons/buttonMui/buttonMui";
import "./subscriptions.scss";
import SwitchAccountDialog from "../../../../components/dialogs/SwitchAccount/SwitchAccountDialog";
import checkMark from "./Images/img.png";

const subscriptionsObject = (enableMostPopular, enableRecommended) => {
  return {
    Start: {
      shortDescription: {
        title: "",
        features: [
          "Pitch deck creator. 100+ template slides",
          "Business plan. Lender approved format",
          "Guided financial planning",
          "Automated financial statements",
          "users_number users ($price_extra_user per additional user)",
          "companies_number companies ($price_extra_company per additional company)",
        ],
      },
    },
    Fund: {
      mostPopular: enableMostPopular,
      shortDescription: {
        title: "Everything in START, plus:",
        features: [
          "Driver-based financial planning",
          "Financial model templates by industry",
          "users_number users ($price_extra_user per additional user)",
          "companies_number companies ($price_extra_company per additional company)",
        ],
      },
    },
    Grow: {
      recommended: enableRecommended,
      shortDescription: {
        title: "Everything in FUND, plus:",
        features: [
          "Plan vs. actual reporting",
          "Predefined dashboards",
          "Custom dashboards",
          "One-click investor reports",
          "Ratio analysis",
          "users_number users ($price_extra_user per additional user)",
          "companies_number companies ($price_extra_company per additional company)",
        ],
      },
    },
  };
};

class Subscriptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      SubscriptionPeriod: "year",
      NotSubscriptionPeriod: "month",
      AllSubscriptions: null,
      SelectedPeriodSubscriptions: [],
      NotSelectedPeriodSubscriptions: [],
      FreeTrial: null,
      guid: window.location.pathname.split("/").pop()
        ? window.location.pathname.split("/").pop().toUpperCase()
        : "",
    };

    if (!this.props.changeSubscriptionPlan) {
      request.get(`/api/getAllSubscriptions`).then((res) => {
        if (res) {
          let AllSubscriptions = res.body.filter(
            (c) => c.Type !== "AppSumo" && c.Type !== "Static",
          );
          let SelectedPeriodSubscriptions = AllSubscriptions.filter(
            (c) => c.billing_type.trim() === this.state.SubscriptionPeriod,
          );
          let NotSelectedPeriodSubscriptions = AllSubscriptions.filter(
            (c) => c.billing_type.trim() === this.state.NotSubscriptionPeriod,
          );
          let FreeTrial = AllSubscriptions.find((c) => c.billing_type.trim() === "free_trial");
          let subscription = AllSubscriptions.find((c) => c.GUID == this.state.guid);
          if (this.state.guid && subscription) {
            this.onClick(subscription.ID, subscription.recurring_price, subscription.paddle_id);
          } else {
            this.setState({
              AllSubscriptions,
              SelectedPeriodSubscriptions,
              NotSelectedPeriodSubscriptions,
              FreeTrial,
            });
          }
        }
      });
    }
  }

  componentDidMount() {
    if (
      this.props.changeSubscriptionPlan &&
      global.Modeliks.subscriptionPlans &&
      global.Modeliks.subscriptionPlans.length
    ) {
      let AllSubscriptions = global.Modeliks.subscriptionPlans.filter(
        (c) => c.Type !== "AppSumo" && c.Type !== "Static",
      );
      let SelectedPeriodSubscriptions = AllSubscriptions.filter(
        (c) => c.billing_type.trim() === this.state.SubscriptionPeriod,
      );
      let NotSelectedPeriodSubscriptions = AllSubscriptions.filter(
        (c) => c.billing_type.trim() === this.state.NotSubscriptionPeriod,
      );
      this.setState({
        AllSubscriptions: AllSubscriptions,
        NotSelectedPeriodSubscriptions: NotSelectedPeriodSubscriptions,
        SelectedPeriodSubscriptions: SelectedPeriodSubscriptions,
      });
    }
  }

  onClick = (id, price, paddle_price, strength, label, isFreeTrial = false) => {
    this.props.onCardClick(id, price, paddle_price, isFreeTrial);
  };

  onPeriodChange = (event) => {
    let subscriptionPeriod = event.target.value;
    if (this.state.SubscriptionPeriod !== subscriptionPeriod) {
      let SelectedPeriodSubscriptions = this.state.AllSubscriptions.filter(
        (c) => c.billing_type.trim() === subscriptionPeriod,
      );
      let NotSelectedPeriodSubscriptions = this.state.AllSubscriptions.filter(
        (c) => c.billing_type.trim() === this.state.SubscriptionPeriod,
      );
      this.setState({
        SubscriptionPeriod: subscriptionPeriod,
        NotSelectedPeriodSubscriptions: NotSelectedPeriodSubscriptions,
        NotSubscriptionPeriod: this.state.SubscriptionPeriod,
        SelectedPeriodSubscriptions,
      });
    }
  };

  getSubscriptonButtonLabel = (subscription, selectedSubscription) => {
    if (selectedSubscription) {
      if (
        selectedSubscription.strength > subscription.strength ||
        (selectedSubscription.strength === subscription.strength &&
          selectedSubscription.billing_type === "year" &&
          subscription.billing_type === "month")
      ) {
        return "DOWNGRADE";
      }
      if (
        selectedSubscription.strength < subscription.strength ||
        (selectedSubscription.strength === subscription.strength &&
          selectedSubscription.billing_type === "month" &&
          subscription.billing_type === "year")
      ) {
        return "UPGRADE";
      }
      if (selectedSubscription.strength == subscription.strength) {
        return "YOUR CURRENT PLAN";
      }
    }

    return "GET STARTED";
  };

  getDifference = (index) => {
    let monthlySubscription = this.state.NotSelectedPeriodSubscriptions[index].recurring_price;
    let yearlySubscription = this.state.SelectedPeriodSubscriptions[index].recurring_price / 12;
    if (monthlySubscription && yearlySubscription) {
      return (
        Math.round(
          (Math.abs(monthlySubscription - yearlySubscription) / monthlySubscription) * 100,
        ) + "%"
      );
    }

    return "";
  };

  CancelAccount = () => {
    const AccountID = global.Modeliks.Account?.ID ?? window.localStorage.getItem("Account_ID");
    const UserID = global.Modeliks.User?.ID ?? window.localStorage.getItem("User_ID");
    if (AccountID && UserID) {
      request
        .post("/api/DeleteAccount")
        .set("Access-Control-Allow-Origin", window.location.protocol + "//" + window.location.host)
        .set("Accept", "application/json")
        .set("authorization", "Bearer " + window.localStorage.getItem("token"))
        .query({ AccountID, UserID })
        .then((e) => {
          this.setState({ switchAccount: true });
          // this.props.returnSelectAccount();
        })
        .catch((e) => {
          console.error("error adding account", e);
        });
    }
  };

  element = () => {
    const { FreeTrial } = this.state;
    let checkMarkImg = (
      <img
        src={require("./Images/img.png")}
        data-src={require("./Images/img.png")}
        decoding="async"
        className=" ls-is-cached lazyloaded"
        data-src-webp={require("./Images/img.png")}
      />
    );
    let secondaryCheckMarkImg = <img src={require("./Images/img.png")} alt="Check" data-eio="l" />;

    let selectedSubscription = null;
    if (this.props.changeSubscriptionPlan && global.Modeliks.subscriptionInfo) {
      selectedSubscription = global.Modeliks.subscriptionPlans.find(
        (c) => c.ID === global.Modeliks.subscriptionInfo.SubscriptionID,
      );
    }
    let enableMostPopular = true;
    let enableRecommended = true;
    if (selectedSubscription) {
      if (
        selectedSubscription.billing_type == "year" &&
        this.state.SubscriptionPeriod === "month"
      ) {
        enableMostPopular = false;
        enableRecommended = false;
      }
      if (selectedSubscription.strength > 2) {
        enableMostPopular = false;
      }
    }

    const subscriptionsObj = subscriptionsObject(enableMostPopular, enableRecommended);

    // const isYearly = subscription.billing_type === "year";
    return (
      <section className="hero-pricing">
        <div className="container-fluid container-custom-width">
          <h1 className="text-center pricing-heading"> Select monthly or annual plan. </h1>
          {/*<p className="text-center"> Select monthly or annual plan. </p>*/}
          <div className="pricing-switcher">
            <div className="fieldset">
              <input
                type="radio"
                name="duration"
                value="month"
                id="monthly"
                checked={this.state.SubscriptionPeriod === "month"}
                onClick={this.onPeriodChange}
              />
              <label htmlFor="monthly">Monthly</label>
              <input
                type="radio"
                name="duration"
                value="year"
                id="yearly"
                checked={this.state.SubscriptionPeriod === "year"}
                onClick={this.onPeriodChange}
              />
              <label htmlFor="yearly">Yearly</label>
              <span className="switch"></span>
            </div>
            <div className="pricing-back_login">
              {this.props.CancelAccount ? (
                <ButtonMui
                  onClick={() => this.CancelAccount()}
                  label={"Cancel"}
                  width="130px"
                  roundnessType={"rounded"}
                  variant={"contained"}
                />
              ) : (
                <ButtonMui
                  onClick={() =>
                    this.props.goBack ? this.props.goBack() : global.Modeliks.LogOut()
                  }
                  label={this.props.goBackLabel ? this.props.goBackLabel : "Back to login"}
                  width="120px"
                  linkTo={
                    this.props.goBackLink
                      ? this.props.goBackLink
                      : this.props.goBack
                        ? undefined
                        : "/login"
                  }
                  roundnessType={"rounded"}
                  variant={"contained"}
                />
              )}
            </div>
          </div>
          <p className="pricing-save"> Save up to 30% with annual plan </p>
          <div className="hero-pricing__plans-wrapper-monthly switch-cards">
            {this.state.SelectedPeriodSubscriptions &&
              this.state.SelectedPeriodSubscriptions.map((subscription, index) => {
                const isYearly = subscription.billing_type === "year";
                let className = isYearly ? "yearly " : "";
                className += subscriptionsObj[subscription.data_key].recommended
                  ? "secondary"
                  : "primary";
                const linkTo =
                  selectedSubscription &&
                  selectedSubscription.strength === subscription.strength &&
                  this.props.goBackLink
                    ? this.props.goBackLink
                    : null;
                const label = this.getSubscriptonButtonLabel(subscription, selectedSubscription);
                const price = !isYearly
                  ? subscription.recurring_price
                  : subscription.recurring_price / 12;
                const wrapperClassName =
                  this.props.changeSubscriptionPlan && selectedSubscription.ID === subscription.ID
                    ? "blue_border"
                    : "";
                const color = subscriptionsObj[subscription.data_key].recommended
                  ? "secondary"
                  : "primary";

                return (
                  <div className={`hero-pricing__plans-wrapper-monthly__card ${wrapperClassName}`}>
                    {subscriptionsObj[subscription.data_key].mostPopular && (
                      <div className="most-popular"> Most Popular</div>
                    )}
                    {subscriptionsObj[subscription.data_key].recommended && (
                      <div className="most-popular recommended"> Recommended</div>
                    )}
                    <h1>{subscription.data_key.toUpperCase()}</h1>
                    <h2 className={className}>
                      ${price} <span>/ Mo</span>
                    </h2>
                    {isYearly && (
                      <div className={"savings"}>
                        Paid annually |&nbsp;
                        <span className={"price"}> Save {this.getDifference(index)}</span>
                      </div>
                    )}
                    <div className="hero-pricing__plans-wrapper-monthly__card__link">
                      <ButtonMui
                        additionalProps={{
                          "data-plan-name": subscription.data_key,
                          "data-plan-value": price,
                          "data-plan-duration": isYearly ? "Yearly" : "Monthly",
                        }}
                        linkTo={linkTo}
                        color={color}
                        onClick={() =>
                          this.onClick(
                            subscription.ID,
                            subscription.recurring_price,
                            subscription.paddle_id,
                            subscription.strength,
                            label,
                          )
                        }
                        label={label}
                        style={{
                          height: "44px",
                          width: "238px",
                          fontSize: "20px",
                          marginBottom: "15px",
                        }}
                        roundnessType={"squared"}
                        variant={"contained"}
                      />
                    </div>
                    <p className="first-paragraph">
                      {subscriptionsObj[subscription.data_key].shortDescription.title}
                    </p>
                    {subscriptionsObj[subscription.data_key].shortDescription.features.map(
                      (feature) => (
                        <div>
                          {" "}
                          {checkMarkImg}{" "}
                          <span className={"feature_span"}>
                            {" "}
                            {feature
                              .replace("users_number", subscription.users_number)
                              .replace("companies_number", subscription.companies_number)
                              .replace(
                                "price_extra_user",
                                !isYearly
                                  ? subscription.price_extra_user
                                  : subscription.price_extra_user / 12,
                              )
                              .replace(
                                "price_extra_company",
                                !isYearly
                                  ? subscription.price_extra_company
                                  : subscription.price_extra_company / 12,
                              )}{" "}
                          </span>
                        </div>
                      ),
                    )}
                  </div>
                );
              })}
          </div>
          {!this.props.dontShowFreeTrial ? (
            <div className={"f_t_btn_cont"}>
              <ButtonMui
                onClick={() =>
                  this.onClick(FreeTrial.ID, FreeTrial.recurring_price, FreeTrial.paddle_id, undefined, undefined, true)
                }
                label={"FREE TRIAL"}
                style={{ height: "44px", width: "238px", fontSize: "20px", marginBottom: "15px" }}
                roundnessType={"squared"}
                variant={"contained"}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </section>
    );
  };

  render() {
    const className = this.props.className ? this.props.className : "";
    if (this.state.switchAccount) {
      return <SwitchAccountDialog open={true} disableClose={true} />;
    }

    if (this.state.AllSubscriptions)
      return (
        <div
          className={
            "subscriptions_container_register container-fluid container-custom-width " + className
          }
        >
          {this.element()}
        </div>
      );
    return <></>;
  }
}

export default Subscriptions;
