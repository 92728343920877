import React from "react";

function Container(props) {
  return (
    <div style={props.style} className={"scale_bp_container"}>
      {props.children}
    </div>
  );
}

class ScalePanel extends React.Component {
  cycleCounter = 0;

  constructor(props) {
    super(props);
    this.props = props;

    this.scalePanelRef = null;
    this.scaleParentPanelRef = null;

    this.state = {
      width: undefined,
      height: undefined,
      scale: undefined,
    };
  }

  resizeBusinessPlanScale = (initial = false) => {
    let callBack = undefined;
    if (initial && this.getZoomValue() != 1) {
      callBack = this.forceUpdate;
    }
    const zoomValue = initial ? 1 : this.getZoomValue();
    if (this.scalePanelRef && this.scaleParentPanelRef) {
      const pSize = this.scaleParentPanelRef.getBoundingClientRect();
      let ratio = pSize.width / zoomValue / this.props.width;
      this.setState(
        {
          width: pSize.width / zoomValue,
          height: ratio * this.props.height,
          scale: ratio,
        },
        callBack,
      );
      window.panelScale = ratio * zoomValue;
    }
  };

  getZoomValue = () => {
    if (this.props.getZoom) {
      if (!isNaN(Number(this.props.getZoom()))) {
        return this.props.getZoom();
      }
    }
    return 1;
  };

  resizeSlideEditorScale = (initial = false) => {
    let callBack = undefined;
    if (initial && this.getZoomValue() != 1) {
      callBack = this.forceUpdate;
    }
    const zoomValue = initial ? 1 : this.getZoomValue();
    if (this.scalePanelRef && this.scaleParentPanelRef) {
      const pSize = this.scaleParentPanelRef.getBoundingClientRect();
      let ratio = pSize.width / zoomValue / this.props.width;
      if (ratio * this.props.height < pSize.height / zoomValue) {
        this.setState(
          {
            width: pSize.width / zoomValue,
            height: ratio * this.props.height,
            scale: ratio,
          },
          callBack,
        );
        window.panelScale = ratio * zoomValue;
      } else {
        ratio = pSize.height / zoomValue / this.props.height;

        this.setState(
          {
            width: this.props.width * ratio,
            height: pSize.height / zoomValue,
            scale: ratio,
          },
          callBack,
        );
        window.panelScale = ratio * zoomValue;
      }
    }
  };

  handleResize = (initial) => {
    if (this.props.businessPlan) {
      this.resizeBusinessPlanScale(initial);
    } else {
      this.resizeSlideEditorScale(initial);
    }
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize(true);
    if (this.props.scale) {
      this.props.scale.handleResize = this.handleResize;
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    ++this.cycleCounter;
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  setScalePanelRef = (ref) => {
    this.scalePanelRef = ref;
  };
  setScaleParentPanelRef = (ref) => {
    this.scaleParentPanelRef = ref;
  };

  getZoomOffset = () => {
    if (!this.props.businessPlan) {
      if (this.cycleCounter < 1) {
        return 1;
      }
    }
    return 0.5;
  };

  render = () => {
    this.scalePanelStyle = {};
    this.panelStyle = {};
    this.scalePanelTopStyle = {};
    const zoomValue = this.cycleCounter == 0 ? 1 : this.getZoomValue();

    if (this.state.height) {
      if (this.props.businessPlan) {
        this.scalePanelStyle.width = this.state.width;
        this.scalePanelStyle.height = this.state.height;
        this.scalePanelStyle.paddingBottom = "67px";
        this.panelStyle.transform = "scale(" + this.state.scale + ")";

        if (zoomValue < 1) {
          this.scalePanelTopStyle = { width: `${zoomValue * 90}%` };
          this.scalePanelStyle.width = "100%";
          this.scalePanelStyle.height = "100%";
          this.panelStyle.transform = "scale(" + this.state.scale * zoomValue + ")";
        }
      } else {
        this.scalePanelStyle.width = this.state.width;
        this.scalePanelStyle.height = this.state.height;
        this.panelStyle.transform = "scale(" + this.state.scale + ")";
      }
    }

    let renderItems = (
      <div
        style={this.scalePanelTopStyle}
        className={
          this.props.businessPlan ? "se_scale_panel_top business_plan" : "se_scale_panel_top"
        }
        id={this.props.uniqueId ? this.props.uniqueId : ""}
        ref={this.setScaleParentPanelRef}
        onClick={this.props.onClick}
      >
        <div id="scale_panel_container" ref={this.setScalePanelRef} style={this.scalePanelStyle}>
          <div
            className={this.props.className ? `se_panel ${this.props.className}` : "se_panel"}
            id="se_scale_panel"
            style={this.panelStyle}
          >
            {this.props.children}
          </div>
        </div>
      </div>
    );

    if (this.props.businessPlan) {
      if (zoomValue > 1) {
        return (
          <Container>
            <div>
              <div
                style={{
                  display: "grid",
                  overflow: "hidden",
                  transformOrigin: "top left",
                  height: "100%",
                  width: "100%",
                  transform: `scale(${zoomValue})`,
                }}
              >
                {renderItems}
              </div>
            </div>
          </Container>
        );
      }
      return <Container>{renderItems}</Container>;
    }

    if (zoomValue > this.getZoomOffset()) {
      return (
        <div style={{ height: "100%", width: "100%", overflow: "auto" }}>
          <div
            style={{
              display: "grid",
              overflow: zoomValue >= 1 ? "hidden" : "visible",
              transformOrigin: zoomValue >= 1 ? "top left" : "center center",
              height: "100%",
              width: "100%",
              transform: `scale(${zoomValue})`,
            }}
          >
            <>
              <div className={`insert_mask ${this.props.maskClassName}`}>
                <br />
              </div>
              {renderItems}
            </>
          </div>
        </div>
      );
    }

    return renderItems;
  };
}

export default ScalePanel;
