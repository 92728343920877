import React, { Component } from "react";
import { Alert } from "@mui/material";
import "./appNotification.scss";

class AppNotification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };

    global.Modeliks.showNotification = this.showNotification;
    global.Modeliks.showNotification.handleClose = this.hideNotification;
    global.Modeliks.showNotification.IsActive = false;
    this.msg = "";
  }

  showNotification = (msg) => {
    this.msg = msg;
    global.Modeliks.showNotification.IsActive = true;
    this.setState({ open: true }, () => {
      setTimeout(this.hideNotification, 3000);
    });
  };

  hideNotification = () => {
    this.setState({ open: false });
    this.msg = "";
  };

  render() {
    if (this.state.open) {
      return (
        <div className={"notification_container"}>
          <Alert className={"notification"} variant="contained" severity="info">
            {this.msg}
          </Alert>
        </div>
      );
    }
    return null;
  }
}

export default AppNotification;
