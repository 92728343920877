import React from "react";
import Card from "../../../components/cards/Card";
import Input from "../../../components/actions/Input";
import { GreyCard } from "../../../components/cards/GreyCard";
import { UserCard } from "../../../components/cards/UserCard";
import styled from "styled-components";
import { IconI, IconPlus, CameraIcon, EditCircleIcon } from "../../../components/icons/svgIcons";
import MaterialSelect from "../../../components/actions/SelectM";
import DatePickerYM from "../../../components/actions/DatePickers/DatePickerYM";
import AddUserDialog from "../../../components/dialogs/settings/AddUserDialog";
import UserSmall from "../ProfileSettings/Users/UserSmall";
import CircleLoader from "../../../components/Loaders/CircleLoader/CircleLoader";
import request from "superagent";
import ButtonJ from "../../../SlideEditor/components/components/Buttons/Button";
import UsersLimit from "../../../components/dialogs/LimitDialogs/UsersLimit";
import { DialogTypes, ForecastPeriodYears } from "../../../data/Finance/constants";
import "./SettingsComponent.scss";
import WarningDialog from "../../../components/dialogs/LimitDialogs/WarningDialog";
import SuccessDialog from "../../../components/dialogs/LimitDialogs/SuccessDialog";
import IconButton from "@mui/material/IconButton";
import ButtonMui from "../../../components/buttons/buttonMui/buttonMui";
import "../ProfileSettings/Profile/personalCard.scss";
import EditUserDialog from "../../../components/dialogs/settings/EditUserDialog";
import LocalStorage from "../../../helpers/LocalStorage";
import ImageCropper from "./Image/ImageCropper";
import { Currencies, AllCurrencies } from "../../../helpers/constants";
import DropDownAutocomplete from "../../../components/actions/inputs/DropDownAutocomplete";

const P2Strong = styled.div`
  font-weight: 700;
  font-size: 16px;
  font-family: Inter;
`;

const StyledLI = styled.div`
  padding-top: 1px;
  display: flex;
  gap: 17px;
  align-items: center;
  font-family: Inter;
`;

const colors = [
  { colorHex: "#01AFFD", color: "blue" },
  { colorHex: "#01AFFD", color: "blue" },
  { colorHex: "#01AFFD", color: "blue" },
  { colorHex: "#01AFFD", color: "blue" },
];

const currencies = [
  { description: "Dollar ($)", value: "$" },
  { description: "Euro (€)", value: "€" },
  { description: "British pound (£)", value: "£" },
];

export const imageTypes = { png: "png", jpeg: "jpeg", jpg: "jpg", tiff: "tiff", webp: "webp" };
export const MAX_FILE_UPLOAD = 3242880;

const settingsObject = "chartConfig";
const settingsConfigTypes = {
  showSuccess: "showSuccess",
};
LocalStorage.appendObjectToLocalStorage(
  settingsObject,
  JSON.stringify({
    showSuccess: false,
  }),
);

class SettingsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.Company = global.Modeliks.CompanyInfo;
    this.Companies = global.Modeliks.Companies;

    this.FinancialsEditPermission = global.Modeliks.PERMISSIONS.Financials.restrictions.edit.active;
    this.Users = global.Modeliks.AccountUsers.map((c) => c.User);
    this.AllCurrencies = Object.values(AllCurrencies);

    this.state = {
      open: true,
      StartDate: new Date(),
      StartMonth: null,
      StartYear: null,
      Currency: this.Company.Currency,
      CurrencyName: this.Company.Currency.description,
      CompanyName: this.Company.CompanyName,
      dialogUser: false,
      openUser: true,
      existingUser: false,
      users: [],
      openUsersLimit: false,
      reqChangeForecast: false,
      reqChangeForecastValue: null,
      logo: null,
      id: null,
      loading: false,
      type: null,
      error: false,
      newLogo: false,
      successMessage: false,
      showSuccess: LocalStorage.getByKeyFromLocalStorageObject(
        settingsObject,
        settingsConfigTypes.showSuccess,
      ),
      IsEdited: false,
      activeLogo: false,
      imageType: null,
    };
    this.getLogo();

    this.prevState = {
      prevCurrency: this.Company.Currency.code,
      prevCompanyName: this.Company.CompanyName,
      prevActiveYears: this.Company.ActiveYears,
      prevStartMonth: this.Company.StartMonth,
      prevStartYear: this.Company.StartYear,
    };
  }
  fileInputRef = React.createRef();

  componentDidMount() {
    if (this.state.showSuccess) {
      LocalStorage.updateToLocalStorageObject(
        settingsObject,
        settingsConfigTypes.showSuccess,
        false,
      );
      this.setState({ successMessage: true }, () => {
        setTimeout(() => {
          this.setState({ successMessage: false });
        }, 2000);
      });
    }
  }

  openDialogUser = () => {
    this.setState({ dialogUser: true });
  };

  closeDialogUser = () => {
    this.setState({ dialogUser: false });
  };

  getLogo = () => {
    request
      .get(
        `/api/logo?account_id=${global.Modeliks.Account.ID}&company_id=${global.Modeliks.CompanyInfo.ID}&v=${new Date().getTime()}`,
      )
      .then((res) => {
        if (res && res.text && !res.text.includes("not ok")) {
          this.setState({ logo: res.text, activeLogo: true });
        }
      })
      .catch((err) => {
        console.error(err.message, err.response);
      });
  };

  openDialog = () => {
    this.setState({ dialog: true });
  };

  closeDialog = () => {
    this.setState({ dialog: false });
  };

  onUserEdit = (id) => {
    this.setState({ id: id });
    this.changeUserAction(true);
  };

  changeUserAction = (existingUser) => {
    if (!existingUser) {
      if (global.Modeliks.limitInfo.ActiveUsers + 1 > global.Modeliks.limitInfo.TotalUsers) {
        this.setState({ openUsersLimit: true });
        return;
      }
    }
    this.setState({ existingUser: existingUser, dialogUser: true });
  };

  saveCompany = () => {
    this.Company.CompanyName = this.state.CompanyName;
    this.Company.Currency = this.state.Currency.code;
    this.state.loading = true;
    this.forceUpdate();
    global.Modeliks.onPreviewMode(true);
    global.Modeliks.del(
      "settings_client",
      { AccountID: this.Company.AccountID, CompanyID: this.Company.ID },
      (success, err) => {
        this.Company.Save(() => {
          global.Modeliks.DateHelper.buildDates(this.Company);
          LocalStorage.updateToLocalStorageObject(
            settingsObject,
            settingsConfigTypes.showSuccess,
            true,
          );
          window.location.reload(false);
        });
      },
    );
  };

  changeForecastPeriodYears = (e) => {
    this.setState({ reqChangeForecast: true, reqChangeForecastValue: e.target.value });
  };

  handleChange = (e) => {
    let IsEdited = false;
    if (e && e.target.name === "CompanyName") {
      this.Company.CompanyName = e.target.value;
    }

    if (e && e.target.name === "Currency") {
      this.Company.Currency = e.target.value;
    }

    this.setState({ [e.target.name]: e.target.value });
  };

  handleChangeCurrency = (Currency) => {
    this.Company.Currency = Currency;
    this.setState({ Currency: Currency });
    // this.setState({})
  };

  handleChangeMonthAndYear = (e) => {
    if (e) {
      this.Company.StartMonth = e.getMonth();
      this.Company.StartYear = e.getFullYear();
      this.forceUpdate();
      // const IsEdited = this.prevState.prevStartMonth !== this.Company.StartMonth || this.prevState.prevStartYear !== this.Company.StartYear;
      //
      // this.setState({IsEdited})
    }
  };

  getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  handleFileInputChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onloadend = () => {
      let fileType = e.target.files[0].type.split("/")[1];
      if (Object.values(imageTypes).findIndex((c) => c === fileType) === -1) {
        this.setState({ error: "This image type is not supported." });
      } else if (e.target.files[0].size > MAX_FILE_UPLOAD) {
        this.setState({ error: "Uploaded logo should be less than 3 MB." });
      } else {
        this.setState({
          src: reader.result,
          type: e.target.files[0].type.toString(),
          error: false,
          newLogo: true,
          imageType: fileType,
        });
      }
    };

    let { file } = this.state;

    file = e.target.files[0];

    this.getBase64(file)
      .then((result) => {
        file["base64"] = result;
        this.setState({
          base64str: result,
          file,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  handleUploadLogo = (basebase64str = this.state.base64str) => {
    request
      .post("/api/uploadLogo")
      .set("authorization", "Bearer " + window.localStorage.getItem("token"))
      .send({
        companyID: global.Modeliks.CompanyInfo.ID,
        base64str: basebase64str,
        type: this.state.type.split("/")[1],
      })
      .then((res) => {
        global.Modeliks.BusinessPlanConfig.UpdatedAt = new Date().toISOString();
        global.Modeliks.BusinessPlanConfig.Save(
          () => {
            this.setState({ base64str: null, file: null, src: null, newLogo: false });
            this.getLogo();
          },
          (err) => console.error(err),
        );
      });
  };

  handleCancel = () => {
    this.setState({ base64str: null, file: null, src: null, newLogo: false });
  };

  handleDeleteLogo = () => {
    request
      .del("/api/deleteLogo")
      .set("authorization", "Bearer " + window.localStorage.getItem("token"))
      .send({ companyID: global.Modeliks.CompanyInfo.ID })
      .then((res, err) => {
        if (!res) {
          console.error(err);
        }
        global.Modeliks.BusinessPlanConfig.UpdatedAt = new Date().toISOString();
        global.Modeliks.BusinessPlanConfig.Save(
          () => {
            this.setState({ base64str: null, file: null, src: null, logo: null, newLogo: false });
          },
          (err) => console.error(err),
        );
      });
  };

  closeReqChangeForecast = () => {
    this.setState({ reqChangeForecast: false, reqChangeForecastValue: null });
  };

  onChangeReqForecast = () => {
    this.Company.ActiveYears = this.state.reqChangeForecastValue;
    this.setState({ reqChangeForecast: false, reqChangeForecastValue: null });
  };

  saveProccess = false;

  handleSaveFunc = () => {
    global.Modeliks.showDialog(
      "Do you want to save your changes?",
      DialogTypes.WarningActions,
      () => {
        if (this.saveProccess) {
          this.setState({
            IsEdited: false,
            reqChangeForecast: false,
            reqChangeForecastValue: null,
          });
        } else {
          this.Company.Currency = AllCurrencies[this.prevState.prevCurrency];
          this.Company.CompanyName = this.prevState.prevCompanyName;
          this.Company.ActiveYears = this.prevState.prevActiveYears;
          this.Company.StartMonth = this.prevState.prevStartMonth;
          this.Company.StartYear = this.prevState.prevStartYear;
          this.setState({
            IsEdited: false,
            Currency: AllCurrencies[this.prevState.prevCurrency],
            CompanyName: this.prevState.prevCompanyName,
            reqChangeForecast: false,
            reqChangeForecastValue: null,
          });
        }
      },
      () => {
        this.saveProccess = true;
        this.saveCompany();
      },
    );
  };

  getIsEdited = () => {
    const { CompanyName, ActiveYears, StartMonth, StartYear } = this.Company;
    const Currency = this.Company.Currency.code;
    const { prevCurrency, prevCompanyName, prevActiveYears, prevStartMonth, prevStartYear } =
      this.prevState;
    return (
      Currency !== prevCurrency ||
      CompanyName !== prevCompanyName ||
      ActiveYears !== prevActiveYears ||
      StartMonth !== prevStartMonth ||
      StartYear !== prevStartYear
    );
  };

  changeImage = (img) => {
    this.handleUploadLogo(img);
  };

  render() {
    if (this.state.loading) {
      return (
        <div className={"main_circle_loader_container"}>
          <CircleLoader />
          <SuccessDialog
            open={this.state.successMessage}
            onClose={() => this.setState({ successMessage: false })}
          />
        </div>
      );
    } else {
      const IsEdited = this.getIsEdited();
      return (
        <>
          {IsEdited && (
            <div className="spsss_settings_input_save_mask" onClick={this.handleSaveFunc} />
          )}

          <div
            className={`grid_container spss_settings_set_zIndex ${IsEdited && "spss_settings_div_index"}`}
          >
            <SuccessDialog
              open={this.state.successMessage}
              onClose={() => this.setState({ successMessage: false })}
            />
            <div className="spss_settings_div">
              <div className="spss_settings_button_save">
                <ButtonMui
                  disabled={!IsEdited}
                  onClick={this.saveCompany}
                  variant={"contained"}
                  width={136}
                  label={"Save"}
                />
              </div>
            </div>
            <div className="spss_cards_container">
              <Card
                marginTop={false}
                title="General Info"
                height={"574px"}
                width={"368px"}
                label="Account Name"
                hasTitle={true}
              >
                <div className="spss_second_container_input remove_padding_top">
                  <StyledLI>
                    <P2Strong>Company Name</P2Strong>
                  </StyledLI>
                </div>

                <div className="spss_cards_container_input">
                  <Input
                    disabled={!global.Modeliks.PERMISSIONS.Companies.restrictions.edit.active}
                    value={this.state.CompanyName}
                    max={30}
                    name="CompanyName"
                    onChange={this.handleChange}
                    placeholder={"Enter company name"}
                  />
                </div>
                <div className="spss_second_container_input">
                  <StyledLI>
                    <P2Strong>Company Logo</P2Strong>
                  </StyledLI>
                </div>
                <div className="spss_cards_container_logo">
                  <GreyCard error={this.state.error} height={"90px"}>
                    <div className="spss_format_allowed">
                      <p className="spss_settings_msg">
                        Allowed image formats:
                        <br /> *.jpg, *.jpeg, *.png, *.tiff and *.webp
                      </p>
                      <p className="spss_settings_msg">Allowed image size: up to 3 MB</p>
                    </div>
                  </GreyCard>

                  <div className={"img_cont"}>
                    <label htmlFor="upload-photo">
                      {!this.state.newLogo && (
                        <input
                          style={{ display: "none" }}
                          id="upload-photo"
                          name="upload-photo"
                          type="file"
                          accept="image/*"
                          onChange={this.handleFileInputChange}
                        />
                      )}
                      <div className={"img_cont_card"}>
                        <div className={"svg_cont"}>
                          <div className="spss_logo_text ">
                            <p className="spss_settings_text_message_logo">Company Logo</p>
                          </div>
                        </div>
                        {this.state.newLogo && (
                          <ImageCropper
                            imageType={this.state.imageType}
                            img={`${this.state.src}`}
                            changeImage={(src) => this.changeImage(src)}
                          />
                        )}
                        {(this.state.src || this.state.logo) && !this.state.newLogo && (
                          <img
                            className={"img"}
                            src={
                              this.state.src
                                ? this.state.src
                                : `/api/logo?account_id=${global.Modeliks.Account.ID}&company_id=${global.Modeliks.CompanyInfo.ID}&v=${new Date().getTime()}`
                            }
                            alt={""}
                          />
                        )}
                        <IconButton
                          onClick={this.handleDeleteLogo}
                          className={"float_btn"}
                          component="span"
                        >
                          <EditCircleIcon />
                        </IconButton>
                      </div>
                    </label>
                  </div>

                  {/*</GreyCard>*/}

                  {this.state.error && (
                    <span className="spss_settings_err">
                      {this.state.error}
                      <ButtonJ
                        size="large"
                        width="80px"
                        padding="0 0 0 0"
                        color="warning"
                        noElevation={true}
                        onClick={() =>
                          this.setState({ error: false, src: null, type: null, newLogo: false })
                        }
                        label={"Cancel"}
                      />
                    </span>
                  )}
                </div>
                {/*<input ref={this.fileInputRef} className='spss_container_inv_input' type="file" accept="image/*" id='file'  onChange={this.handleFileInputChange}/>*/}

                <div className="spss_logo_actions">
                  {this.state.newLogo && (
                    <>
                      {/*<ButtonMui width={90} style={{height:'34px'}} label='Save' variant={'outlined'} onClick={this.handleUploadLogo} />*/}
                      <ButtonMui
                        width={90}
                        style={{ height: "34px" }}
                        label="Cancel"
                        variant={"outlined"}
                        color={"red"}
                        onClick={this.handleCancel}
                      />
                    </>
                  )}
                  {this.state.logo &&
                    !this.state.logo.includes("not ok") &&
                    !this.state.newLogo && (
                      <ButtonMui
                        width={90}
                        style={{ height: "34px" }}
                        label="Remove"
                        variant={"outlined"}
                        color={"red"}
                        onClick={this.handleDeleteLogo}
                      />
                    )}
                  {/*<button onClick={this.handleUploadLogo}>Upload</button>*/}
                </div>

                {/*<div style={{width: '100%'}}><StyledLI>*/}
                {/*<Label size={'medium'} label={'Primary Color'}/>*/}
                {/*<div style={{paddingLeft: '3px'}}><IconI/></div>*/}
                {/*</StyledLI></div>*/}
                {/*<div style={{marginTop: '20px', width: '100%'}}>*/}
                {/*<MaterialSelect*/}
                {/*minWidth='332px'*/}
                {/*onChange={(e) => ''}*/}
                {/*// value={value}*/}
                {/*value={'blue'}>*/}
                {/*{colors.map((row, index) => {*/}
                {/*return <MenuItem key={row + index} value={'blue'} style={{*/}
                {/*zIndex: 10,*/}
                {/*color: '#B9BFC3',*/}
                {/*fontSize: '12px',*/}
                {/*fontWeight: '400',*/}
                {/*lineHeight: '160%',*/}
                {/*fontStyle: 'normal',*/}
                {/*margin: '0'*/}
                {/*}}>*/}
                {/*<div style={{width: '100%', display: 'flex', alignItems: 'center'}}>*/}
                {/*<div style={{width: '14px', height: '14px', background: row.colorHex}}/>*/}
                {/*<div style={{width: '100%', paddingLeft: '15px'}}>{row.color}</div>*/}
                {/*</div>*/}
                {/*</MenuItem>*/}
                {/*})*/}

                {/*}*/}
                {/*</MaterialSelect></div>*/}
              </Card>
              <Card
                marginTop={false}
                title="Financial Forecast"
                height={"574px"}
                label="Subscription Plan"
                hasTitle={true}
              >
                <div className="spss_second_container_label">
                  <StyledLI>
                    <P2Strong>Forecast Start</P2Strong>
                  </StyledLI>
                </div>
                <div className="spss_second_container_date">
                  <DatePickerYM
                    disabled={!this.FinancialsEditPermission}
                    marginLeft="0px"
                    label="Select Month & Year"
                    value={`${this.Company.StartYear}-${(this.Company.StartMonth + 1).toString().padStart(2, "0")}`}
                    onChange={this.handleChangeMonthAndYear}
                  />
                </div>
                <div className="spss_second_container_input">
                  <StyledLI>
                    <P2Strong>Forecast Period</P2Strong>
                  </StyledLI>
                </div>
                <div className="spss_second_container_input">
                  <MaterialSelect
                    disabled={!this.FinancialsEditPermission}
                    minWidth="332px"
                    onChange={this.changeForecastPeriodYears}
                    value={this.Company.ActiveYears}
                    options={ForecastPeriodYears}
                  />
                </div>
                {/*<div className='spss_second_container_input'>*/}
                {/*<StyledLI>*/}
                {/*<P2Strong>Monthly Detail</P2Strong>*/}
                {/*/!*<Label size={'medium'} label={'Monthly Detail'}/>*!/*/}
                {/*</StyledLI>*/}
                {/*</div>*/}
                {/*<div className='spss_second_container_input'>*/}
                {/*<MaterialSelect*/}
                {/*minWidth='332px'*/}
                {/*disabled={true}*/}
                {/*onChange={(e) => ''}*/}
                {/*// value={value}*/}
                {/*value={'1 year (default)'}*/}
                {/*options={[{value: '1 year (default)',description: '1 year (default)'}, {value: '2 years', description: '2 years'}, {value: '3 years',description: '3 years'}]}/>*/}

                {/*</div>*/}
                {/*<div style={{width: '100%', paddingTop: '27px', paddingLeft: '2px'}}>*/}
                {/*<StyledLI>*/}
                {/*<P2Strong>Multi Period Revenue</P2Strong>*/}
                {/*/!*<Label size={'medium'} label={'Multi Period Revenue'}/>*!/*/}
                {/*<IconI/>*/}
                {/*</StyledLI>*/}
                {/*</div>*/}
                {/*<div style={{width: '100%', paddingTop: '18px', paddingLeft: '2px'}}>*/}
                {/*<MaterialSelect*/}
                {/*disabled={true}*/}
                {/*minWidth='332px'*/}
                {/*onChange={(e) => ''}*/}
                {/*// value={value}*/}
                {/*value={'Spread Out multi period revenue (default)'} options={[{*/}
                {/*value: 'Spread Out multi period revenue (default)',*/}
                {/*description: 'Spread Out multi period revenue (default)'*/}
                {/*}, {value: 'other', description: 'other'}]}/>*/}
                {/*</div>*/}
                <div className="spss_second_container_currency">
                  <div className="spss_seoncd_container_currency_input">
                    <div className="spss_second_container_currency_label">
                      <StyledLI>
                        <P2Strong>Currency Symbol Display</P2Strong>
                        {/*<Label size={'medium'} label={'Currency'}/>*/}
                      </StyledLI>
                    </div>
                    <DropDownAutocomplete
                      CurrencyName={this.state.CurrencyName}
                      placeholder="Search..."
                      className="fdd_cd_driver_autocomplete_input_style"
                      disabled={!this.FinancialsEditPermission}
                      options={this.AllCurrencies}
                      value={this.Company.Currency}
                      onChange={this.handleChangeCurrency}
                    />
                    {/*<MaterialSelect*/}
                    {/*width='332px'*/}
                    {/*name='Currency'*/}
                    {/*disabled={!this.FinancialsEditPermission}*/}
                    {/*value={this.state.Currency.code}*/}
                    {/*options={AllCurrencies}*/}
                    {/*onChange={this.handleChange}*/}
                    {/*/>*/}
                  </div>
                  {/*<div style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>*/}
                  {/*<StyledLI>*/}
                  {/*<P2Strong>Number Format</P2Strong>*/}
                  {/*<IconI/>*/}
                  {/*</StyledLI>*/}
                  {/*<MaterialSelect*/}
                  {/*minWidth='150px'*/}
                  {/*onChange={this.handleChange}*/}
                  {/*name='NumberFormat'*/}
                  {/*label='Select Format'*/}
                  {/*value={this.state.NumberFormat}*/}
                  {/*options={*/}
                  {/*[*/}
                  {/*{value: 'Thousands', description: 'Thousands'},*/}
                  {/*{value: 'Hundreds', description: 'Hundreds'},*/}
                  {/*{value: 'Millions', description: 'Millions'}*/}
                  {/*]}/>*/}
                  {/*</div>*/}
                </div>
              </Card>
              <Card marginTop={false} title="Users" height={"574px"} hasTitle={true}>
                <UserCard
                  name={"Blagoja Hamamdziev"}
                  email={"baze@companyemail.com"}
                  userType={"Owner"}
                  onClick={this.onUserEdit}
                  // onClick={() => this.changeUserAction(true)}
                  size="small"
                >
                  {this.Users.length > 0 &&
                    this.Users.map((user, i) => (
                      <UserSmall
                        key={i}
                        onClose={() => this.getUsers()}
                        user={user}
                        companies={this.Companies}
                        id={user.ID}
                        name={user.FullName}
                        userType={user.UserType}
                        email={user.Email}
                        onUserEdit={this.onUserEdit}
                      />
                    ))}
                </UserCard>
                <div style={{ marginTop: "18px", marginBottom: "18px" }}>
                  <ButtonMui
                    label="Add new user"
                    endIcon={<IconPlus />}
                    variant={"text"}
                    disabled={!global.Modeliks.PERMISSIONS.Users.restrictions.edit.active}
                    onClick={() => this.changeUserAction(false)}
                  />
                </div>
              </Card>
            </div>
            {this.state.dialogUser && (
              <EditUserDialog
                add
                setOpenUsersLimit={() => {
                  this.setState({ openUsersLimit: true });
                }}
                open={this.state.dialog}
                changeUserType={this.changeUserType}
                companies={this.Companies}
                closeDialog={this.closeDialogUser}
                existingUser={this.state.existingUser}
              />
            )}
            <UsersLimit
              open={this.state.openUsersLimit}
              handleClose={() => {
                this.setState({ openUsersLimit: false });
              }}
            />
            <WarningDialog
              open={this.state.reqChangeForecast}
              onChange={this.onChangeReqForecast}
              onClose={this.closeReqChangeForecast}
              title="Warning"
            />
          </div>
        </>
      );
    }
  }
}

export default SettingsComponent;
