import React from "react";
import { useMatch, useResolvedPath, Link } from "react-router-dom";
import type, { LinkProps } from "react-router-dom";

export default function ActiveSubMenu({
  children,
  to,
  color,
  label,
  size,
  link,
  width = "107px",
  ...props
}) {
  let resolved = useResolvedPath(link);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <div>
      <Link match={match} style={{ textDecoration: "none" }} to={link} {...props}></Link>
    </div>
  );
}
