import CalculatedDriver from "../CalculatedDriver/index";
import { DriverCategories, UnitTypes } from "../CalculatedDriver/constants";
import { FinanceTermType, FinancingTypes } from "./constants";
import datastructure from "../../datastructure.json";
import FinanceGeneric from "../FinanceGeneric";
import ProfitAndLoss from "../Reports/ProfitLoss/index";
import IncomeTax from "../Taxes/IncomeTax";
import VAT from "../Taxes/VAT";
import SalesTax from "../Taxes/SalesTax";
import AccountPayable from "../WorkingCapital/AccountPayable";
import Assets from "../Assets";
import Subscription from "../Revenue/Subscription";
import { PeriodTypes } from "../../dates";
import { PERMISSIONS } from "../../Permissions/Permissions";
import Personnel from "../Personnel";
import { DialogTypes } from "../constants";

class Financing extends FinanceGeneric {
  Name = "";
  ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
  InterestDuringGracePeriod = false;
  FinanceType = "";
  TermType = FinanceTermType.Current;

  constructor(db_record) {
    super(db_record);
    if (this.db_record) {
      this.Name = this.db_record.Name;
      this.InterestDuringGracePeriod = this.db_record.InterestDuringGracePeriod;
      this.TermType = this.db_record.TermType;
      this.FinanceType = this.db_record.FinanceType;
    }
  }

  static DriversDesc = Object.assign({
    Total: {
      driverName: "$Name",
      fieldName: "Totals",
      driverID: "total",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
  });

  static TableName = datastructure.Finance_Financing.TableName;

  createDrivers = (recreate = false) => {
    Object.values(this.constructor.DriversDesc).forEach((driver) => {
      if (this.hasOwnProperty(driver.fieldName) == false || recreate) {
        this[driver.fieldName] = CalculatedDriver.createDriverFromTable(
          this,
          driver.driverID,
          driver.unit,
          driver.category,
        );
      }
    });
  };

  changeDriversName = () => {
    Object.keys(this.constructor.DriversDesc).forEach((key) => {
      const driverDesc = this.constructor.DriversDesc[key];
      if (this[driverDesc.fieldName]) {
        this[driverDesc.fieldName].DriverName = driverDesc.driverName.replace("$Name", this.Name);
      } else {
        console.log("driver not found", this, driverDesc, this[driverDesc.fieldName]);
      }
    });
  };

  static createFinance = () => {
    const newFinancing = new Financing();
    newFinancing.Name = "";
    newFinancing.ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
    // newFinancing.FinanceType = FinancingTypes.Loan;
    return newFinancing;
  };

  static getEmptyFinance = (callBack) => {
    const finance = Financing.createFinance();
    finance.SaveNew((record) => {
      callBack(record);
    });
  };

  SaveNew = (callBack) => {
    let limit =
      global.Modeliks.FinancingStore.length >=
      PERMISSIONS.Financials.restrictions.MaxStreamsCount.TotalFinancing;
    if (limit) {
      global.Modeliks.showDialog(
        `
            You have reached the maximum number of [${PERMISSIONS.Financials.restrictions.MaxStreamsCount.TotalFinancing}] streams in Financing. 
            Please reorganize your streams in Financing. \n Click outside of the box to continue with your work.`,
        DialogTypes.WarningSimpleText,
        () => {
          callBack(null);
        },
      );
    } else {
      global.Modeliks.post(this.constructor.TableName, this, (res) => {
        this.ID = res.id;
        callBack(this);
      });
    }
  };

  static getRetainedEarningID = () => {
    const driverID = "retained_earning_driver";
    let driver = global.Modeliks.DriversStore.getItem(`${Financing.TableName}-${driverID}-totals`);
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Financing.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.LastPeriod,
        "Retained Totals",
        true,
        false,
        false,
      );
      driver.LastPeriodOnly = true;
      driver.isExpense = true;
    }

    const totalDrivers = global.Modeliks.DriversStore.filter(
      (d) => d.Ref_Table === Financing.TableName && d.Ref_Field === "total",
    );
    if (totalDrivers && totalDrivers.length > 0) {
      driver.setFormula_Sum(totalDrivers);
    }

    return driver;
  };
  static getFinanceTotals = () => {
    const driverID = 0;
    let driver = global.Modeliks.DriversStore.getItem(`${Financing.TableName}-${driverID}-totals`);
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Financing.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.LastPeriod,
        "Financing Totals",
        true,
        false,
        false,
      );
      driver.LastPeriodOnly = true;
      driver.isExpense = true;
      const liabilities = Financing.getLiabilities();
      const currentLiabilities = Financing.getCurrentLiabilities();
      const equity = Financing.getEquity();
      const allDrivers = [currentLiabilities, liabilities, equity];
      allDrivers.forEach((c) => (c.LastPeriodOnly = true));
      driver.setFormula_Sum(allDrivers);

      const years = global.Modeliks.DateHelper.years_all;
      years.forEach((year) => {
        if (!year.Active) {
          const curYear = driver.getItemByDateSufix(year.sufix);
          const lastMonth = driver.getItemByDateSufix(year.months[year.months.length - 1].sufix);
          curYear.Formula = `${lastMonth}`;
        }
      });
    }

    return driver;
  };

  static getCurrentOtherLiabilities = () => {
    const driverID = "current_other_liability";
    let driver = global.Modeliks.DriversStore.getItem(`${Financing.TableName}-${driverID}-totals`);
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Financing.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.LastPeriod,
        "Other Current Liability",
        false,
        true,
        false,
      );
      driver.Save();
    }
    driver.IsExisting = true;
    driver.LastPeriodOnly = true;
    driver.isExpense = true;
    driver.IsFormulaEditable = false;

    const LiabilityDrivers = global.Modeliks.FinancingStore.filter(
      (d) =>
        d.FinanceType === FinancingTypes.OtherLiability && d.TermType === FinanceTermType.Current,
    );
    const totalDrivers = LiabilityDrivers.map((d) => d.Totals);

    if (LiabilityDrivers.length > 0) {
      driver.Formula = totalDrivers.map((c) => c.ID_f).join("+");
      driver.Values.forEach((c) => {
        const curValues = totalDrivers.map((d) =>
          d.Values.find((td) => td.PeriodType == c.PeriodType && td.Order == c.Order),
        );
        c.Formula = curValues.map((c) => c.ID_f).join("+");
      });
    }
    return driver;
  };
  static getCurrentOtherLiabilitiesOpeningBalance = () => {
    const driverID = "current_other_liability_opening_balance";
    let driver = global.Modeliks.DriversStore.getItem(`${Financing.TableName}-${driverID}-totals`);
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Financing.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.FirstPeriod,
        "Current Other Liability Opening Balance",
        true,
        false,
        false,
      );
      driver.LastPeriodOnly = true;
      driver.isExpense = true;
    }

    const LiabilityDrivers = global.Modeliks.FinancingStore.filter(
      (d) =>
        d.FinanceType === FinancingTypes.OtherLiability && d.TermType === FinanceTermType.Current,
    );
    const totalDrivers = LiabilityDrivers.map((d) => d.OpenningBalance);

    if (LiabilityDrivers.length > 0) {
      driver.Formula = totalDrivers.map((c) => c.ID_f).join("+");
      driver.Values.forEach((c) => {
        const curValues = totalDrivers.map((d) =>
          d.Values.find((td) => td.PeriodType == c.PeriodType && td.Order == c.Order),
        );
        c.Formula = curValues.map((c) => c.ID_f).join("+");
      });
    }
    return driver;
  };
  static getCurrentOtherLiabilitiesOtherLiabilityBalance = () => {
    const driverID = "current_other_liability_balance";
    let driver = global.Modeliks.DriversStore.getItem(`${Financing.TableName}-${driverID}-totals`);
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Financing.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.LastPeriod,
        "Current Other Liability Closing Balance",
        true,
        false,
        false,
      );
      driver.LastPeriodOnly = true;
      driver.isExpense = true;
    }

    const LiabilityDrivers = global.Modeliks.FinancingStore.filter(
      (d) =>
        d.FinanceType === FinancingTypes.OtherLiability && d.TermType === FinanceTermType.Current,
    );
    const totalDrivers = LiabilityDrivers.map((d) => d.OtherLiabilityBalance);

    if (LiabilityDrivers.length > 0) {
      driver.Formula = totalDrivers.map((c) => c.ID_f).join("+");
      driver.Values.forEach((c) => {
        const curValues = totalDrivers.map((d) =>
          d.Values.find((td) => td.PeriodType == c.PeriodType && td.Order == c.Order),
        );
        c.Formula = curValues.map((c) => c.ID_f).join("+");
      });
    }
    return driver;
  };
  static getLongTermOtherLiabilitiesOpeningBalance = () => {
    const driverID = "long_term_other_liability_opening_balance";
    let driver = global.Modeliks.DriversStore.getItem(`${Financing.TableName}-${driverID}-totals`);
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Financing.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.FirstPeriod,
        "Long Term Other Liability Opening Balance",
        true,
        false,
        false,
      );
      driver.LastPeriodOnly = true;
      driver.isExpense = true;
    }

    const LiabilityDrivers = global.Modeliks.FinancingStore.filter(
      (d) =>
        d.FinanceType === FinancingTypes.OtherLiability && d.TermType === FinanceTermType.LongTerm,
    );

    if (LiabilityDrivers.length > 0) {
      const totalDrivers = LiabilityDrivers.map((d) => d.OpenningBalance);
      driver.Formula = totalDrivers.map((c) => c.ID_f).join("+");
      driver.Values.forEach((c) => {
        const curValues = totalDrivers.map((d) =>
          d.Values.find((td) => td.PeriodType == c.PeriodType && td.Order == c.Order),
        );
        c.Formula = curValues.map((c) => c.ID_f).join("+");
      });
    }
    return driver;
  };
  static getLongTermOtherLiabilitiesOtherLiabilityBalance = () => {
    const driverID = "long_term_other_liability_balance";
    let driver = global.Modeliks.DriversStore.getItem(`${Financing.TableName}-${driverID}-totals`);
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Financing.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.LastPeriod,
        "Long Term Other Liability Closing Balance",
        true,
        false,
        false,
      );
      driver.LastPeriodOnly = true;
      driver.isExpense = true;
    }

    const LiabilityDrivers = global.Modeliks.FinancingStore.filter(
      (d) =>
        d.FinanceType === FinancingTypes.OtherLiability && d.TermType === FinanceTermType.LongTerm,
    );
    const totalDrivers = LiabilityDrivers.map((d) => d.OtherLiabilityBalance);

    if (LiabilityDrivers.length > 0) {
      driver.Formula = totalDrivers.map((c) => c.ID_f).join("+");
      driver.Values.forEach((c) => {
        const curValues = totalDrivers.map((d) =>
          d.Values.find((td) => td.PeriodType == c.PeriodType && td.Order == c.Order),
        );
        c.Formula = curValues.map((c) => c.ID_f).join("+");
      });
    }
    return driver;
  };
  static getLongTermOtherLiabilities = () => {
    const driverID = "long_other_liability";
    let driver = global.Modeliks.DriversStore.getItem(`${Financing.TableName}-${driverID}-totals`);
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Financing.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.LastPeriod,
        "Other Long Term Liability",
        false,
        true,
        false,
      );
      driver.Save();
    }
    driver.IsExisting = true;
    driver.LastPeriodOnly = true;
    driver.isExpense = true;
    driver.IsFormulaEditable = false;

    const LiabilityDrivers = global.Modeliks.FinancingStore.filter(
      (d) =>
        d.FinanceType === FinancingTypes.OtherLiability && d.TermType === FinanceTermType.LongTerm,
    );
    const totalDrivers = LiabilityDrivers.map((d) => d.Totals).concat(
      Assets.getAssetPayableLongTerm(),
    );
    if (totalDrivers.length > 0) {
      driver.Formula = totalDrivers.map((c) => c.ID_f).join("+");
      driver.Values.forEach((c) => {
        const curValues = totalDrivers.map((d) =>
          d.Values.find((td) => td.PeriodType == c.PeriodType && td.Order == c.Order),
        );
        c.Formula = curValues.map((c) => c.ID_f).join("+");
      });
    }

    return driver;
  };
  static getLiabilities = () => {
    const driverID = "long_term_liab_driver";
    let driver = global.Modeliks.DriversStore.getItem(`${Financing.TableName}-${driverID}-totals`);
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Financing.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.LastPeriod,
        "Long Term Liabilities",
        true,
        true,
        false,
      );
      driver.LastPeriodOnly = true;
      driver.isExpense = true;
    }

    const Liabilities = Financing.getLongTermOtherLiabilities();
    const LiabilityDrivers = global.Modeliks.FinancingStore.filter(
      (d) => d.FinanceType == FinancingTypes.Loan || d.FinanceType == FinancingTypes.LineOfCredit,
    );
    const totalDrivers = LiabilityDrivers.map((d) => d.Totals).concat(Liabilities);

    if (totalDrivers && totalDrivers.length > 0) {
      totalDrivers.forEach((c) => (c.LastPeriodOnly = true));
      driver.Formula = totalDrivers.map((c) => c.ID_f).join("+");
      driver.Values.forEach((c) => {
        const curValues = totalDrivers.map((d) =>
          d.Values.find((td) => td.PeriodType == c.PeriodType && td.Order == c.Order),
        );
        c.Formula = curValues.map((c) => c.ID_f).join("+");
      });
    }

    return driver;
  };
  static getCurrentAndLongTermLiabilities = () => {
    const driverID = "current_and_long_l_driver";
    let driver = global.Modeliks.DriversStore.getItem(`${Financing.TableName}-${driverID}-totals`);
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Financing.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.LastPeriod,
        "Total Liabilities",
        true,
        true,
        false,
      );
      const drivers = [Financing.getCurrentLiabilities(), Financing.getLiabilities()];
      driver.Values.forEach((c) => {
        const curValues = drivers.map((d) =>
          d.Values.find((td) => td.PeriodType == c.PeriodType && td.Order == c.Order),
        );
        c.Formula = curValues.map((c) => c.ID_f).join("+");
      });
      driver.setFormula_Sum(drivers);
    }

    return driver;
  };
  static getCurrentLiabilities = () => {
    const driverID = "current_liab_driver";
    let driver = global.Modeliks.DriversStore.getItem(`${Financing.TableName}-${driverID}-totals`);
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Financing.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.LastPeriod,
        "Current Liabilities",
        true,
        true,
        false,
      );
      driver.LastPeriodOnly = true;
      driver.isExpense = true;
    }

    const CurrentOtherLiabilities = Financing.getCurrentOtherLiabilities();
    const TaxesClosingBalanceIncomeTax = global.Modeliks.DriversStore.find(
      (d) => d.Ref_Field === IncomeTax.DriversDesc.IncomeTaxPayableClosingBalance.driverID,
    );
    const prepaidRevenueClosingBalance = Financing.getPrepaidRevenueClosingBalance();
    const TaxesClosingBalanceSalesTax = global.Modeliks.DriversStore.find(
      (d) => d.Ref_Field === SalesTax.DriversDesc.SalesTaxPayableClosingBalance.driverID,
    );
    const TaxesClosingBalanceVAT = global.Modeliks.DriversStore.find(
      (d) => d.Ref_Field === VAT.DriversDesc.VATTaxPayableClosingBalance.driverID,
    );
    const AccountPayableDriver = global.Modeliks.DriversStore.find(
      (d) => d.Ref_Field === AccountPayable.DriversDesc.ClosingBalance.driverID,
    );
    const AssetPayableCurrent = Assets.getAssetPayableCurrent();

    let totalDrivers = [
      AccountPayableDriver,
      prepaidRevenueClosingBalance,
      CurrentOtherLiabilities,
      TaxesClosingBalanceIncomeTax,
      TaxesClosingBalanceSalesTax,
      TaxesClosingBalanceVAT,
      AssetPayableCurrent,
    ].filter((d) => d !== undefined);
    driver.setFormula(totalDrivers.map((d) => d.ID_f).join("+"));
    totalDrivers.forEach((c) => (c.LastPeriodOnly = true));
    driver.Values.forEach((c) => {
      const curValues = totalDrivers.map((d) =>
        d.Values.find((td) => td.PeriodType == c.PeriodType && td.Order == c.Order),
      );
      c.Formula = curValues.map((c) => c.ID_f).join("+");
    });
    return driver;
  };
  static getEquity = () => {
    const driverID = "equity_driver";
    let driver = global.Modeliks.DriversStore.getItem(`${Financing.TableName}-${driverID}-totals`);
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Financing.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.LastPeriod,
        "Equity",
        true,
        true,
        false,
      );
      driver.LastPeriodOnly = true;
      driver.isExpense = true;
      const equityInvestments = Financing.getEquityInvestments();
      const retainedEarning = Financing.getRetainedEarnings();
      [equityInvestments, retainedEarning].forEach((c) => (c.LastPeriodOnly = true));

      driver.setFormula_Sum([equityInvestments, retainedEarning]);
      const years = global.Modeliks.DateHelper.years_all;
      years.forEach((year) => {
        const curYear = driver.getItemByDateSufix(year.sufix);
        if (!year.Active) {
          curYear.Formula = `MxMath.Sum([${[equityInvestments, retainedEarning].map((driver) => driver.getItemByDateSufix(year.sufix)).join(",")}])`;
        }
      });
    }

    return driver;
  };
  static getEquityInvestments = () => {
    const driverID = "shareholder_eq_driver";
    let driver = global.Modeliks.DriversStore.getItem(`${Financing.TableName}-${driverID}-totals`);
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Financing.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.LastPeriod,
        "Shareholder Equity",
        false,
        true,
        false,
      );
      driver.Save();
    }
    driver.LastPeriodOnly = true;
    driver.isExpense = true;
    driver.IsExisting = true;
    driver.IsFormulaEditable = false;

    const EquityInvestmentsDrivers = global.Modeliks.FinancingStore.filter(
      (d) => d.FinanceType == FinancingTypes.Investments,
    );
    if (EquityInvestmentsDrivers && EquityInvestmentsDrivers.length > 0) {
      const totalDrivers = EquityInvestmentsDrivers.map((d) => d.Totals);
      driver.setFormula_Sum(totalDrivers);
      const years = global.Modeliks.DateHelper.years_all;
      years.forEach((year) => {
        const curYear = driver.getItemByDateSufix(year.sufix);
        if (!year.Active) {
          curYear.Formula = `MxMath.Sum([${totalDrivers.map((driver) => driver.getItemByDateSufix(year.sufix)).join(",")}])`;
        }
      });
    }

    return driver;
  };
  static getEquityInvestmentsOnlyEquity = () => {
    const driverID = "equity_invest_driver";
    let driver = global.Modeliks.DriversStore.getItem(`${Financing.TableName}-${driverID}-totals`);
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Financing.TableName,
        driverID,
        "totals",
        UnitTypes.PriceNegative,
        DriverCategories.Sum,
        "Equity Investments",
        false,
        true,
        false,
      );
      driver.Save();
    }
    driver.isExpense = true;
    driver.IsFormulaEditable = false;

    const EquityInvestmentsDrivers = global.Modeliks.FinancingStore.filter(
      (d) => d.FinanceType === FinancingTypes.Investments,
    );
    const totalDrivers = EquityInvestmentsDrivers.map((d) => d.EquityInvestment);
    const ShareHolderEquity = Financing.getEquityInvestments();

    const months = [
      ...global.Modeliks.DateHelper.months_before_actual,
      ...global.Modeliks.DateHelper.months,
    ];
    const years = [
      ...global.Modeliks.DateHelper.years_before_actual,
      ...global.Modeliks.DateHelper.years_all,
    ];

    months.forEach((month) => {
      const prevMonthDate = months.find((c) => c.Order == month.Order - 1);
      const curTotalDrivers = totalDrivers.map((d) => d.getItemByDateSufix(month.sufix));
      const curMonth = driver.getItemByDateSufix(month.sufix);
      curMonth.Formula = `${curTotalDrivers.map((c) => c.ID_f).join("+")}`;

      if (prevMonthDate) {
        const curMonthEquity = ShareHolderEquity.getItemByDateSufix(month.sufix);
        const prevMonthEquity = ShareHolderEquity.getItemByDateSufix(prevMonthDate.sufix);
        curMonth.Formula =
          `${curMonth.Formula ? curMonth.Formula : ""}` +
          ` Actual( ${curMonthEquity.ID_f_actual} -  ${prevMonthEquity.ID_f_actual})`;
      } else {
        curMonth.evalFormulaActual = null;
      }
    });

    years.forEach((year) => {
      const prevYearDate = years.find((c) => c.Order === year.Order - 1);
      const curTotalDrivers = totalDrivers.map((d) => d.getItemByDateSufix(year.sufix));
      const curYear = driver.getItemByDateSufix(year.sufix);
      curYear.Formula = null;
      if (curTotalDrivers && curTotalDrivers.length > 0) {
        curYear.Formula = `${curTotalDrivers.map((c) => c.ID_f).join("+")}`;
      }
      if (prevYearDate) {
        const curYearEquity = ShareHolderEquity.getItemByDateSufix(year.sufix);
        const prevYearEquity = ShareHolderEquity.getItemByDateSufix(prevYearDate.sufix);
        curYear.Formula =
          `${curYear.Formula ? curYear.Formula : ""}` +
          ` Actual( ${curYearEquity.ID_f_actual} -  ${prevYearEquity.ID_f_actual} )`;
      }
    });

    return driver;
  };

  static getRetainedEarnings = () => {
    const driverID = "retained_earnings";
    let driver = global.Modeliks.DriversStore.getItem(
      Financing.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Financing.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.LastPeriod,
        "Retained Earnings",
        true,
        true,
        false,
      );
      driver.LastPeriodOnly = true;
      driver.isExpense = true;

      const closingBalance = Financing.getClosingBalanceRetainedEarnings();
      const dividendsTotals = Financing.getDividendsTotals();
      const AssetsTotals = Assets.getAssetsTotals();
      const CurrentAndLongTermTotals = Financing.getCurrentAndLongTermLiabilities();
      const FinancingEquityInvestmentsTotals = Financing.getEquityInvestments();

      driver.Values.forEach((value) => {
        // const curMonthValue = driver.getItemByDateSufix(value.Date.sufix);
        const curMonthAssetsTotals = AssetsTotals.getItemByDateSufix(value.Date.sufix);
        const curMonthCurrentAndLongTermLiabilities = CurrentAndLongTermTotals.getItemByDateSufix(
          value.Date.sufix,
        );
        const curMonthEquityInvestments = FinancingEquityInvestmentsTotals.getItemByDateSufix(
          value.Date.sufix,
        );
        if (value.Date.PeriodType === PeriodTypes.year && !value.Date.Active) {
          value.Formula = `${closingBalance.getItemByDateSufix(value.Date.sufix)}`;
        } else {
          value.Formula = `${closingBalance.getItemByDateSufix(value.Date.sufix)}`;
        }

        value.Formula =
          value.Formula +
          ` Actual(${curMonthAssetsTotals.ID_f_actual} - ${curMonthCurrentAndLongTermLiabilities.ID_f_actual} - ${curMonthEquityInvestments.ID_f_actual})`;

        // curMonthValue.Actual = null;
        // curMonthValue.evalFormulaActual = `valIndexes['${curMonthAssetsTotals.toString().replaceAll('|', '')}'].ActualFormula - valIndexes['${curMonthCurrentAndLongTermLiabilities.toString().replaceAll('|', '')}'].ActualFormula - valIndexes['${curMonthEquityInvestments.toString().replaceAll('|', '')}'].ActualFormula`;
      });

      driver.Formula = dividendsTotals.ID_f;
    }

    return driver;
  };
  static getOpenningBalanceRetainedEarnings = (callBack) => {
    const driverID = `retained_erning_ob_driver`;
    let driver = global.Modeliks.DriversStore.getItem(`${Financing.TableName}-${driverID}-totals`);
    if (!driver) {
      driver = CalculatedDriver.createDriver(
        Financing.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.FirstPeriod,
        "Opening Balance",
      );
      driver.IsFormulaEditable = false;
    }

    const closingBalance = Financing.getClosingBalanceRetainedEarnings();
    const netIncome = global.Modeliks.DriversStore.find(
      (d) => d.Ref_Field === ProfitAndLoss.DriversDesc.NetIncome.driverID,
    );
    const dividendsTotals = Financing.getDividendsTotals();
    driver.Formula = `${dividendsTotals.ID_f}${netIncome.ID_f}${closingBalance.ID_f}`;
    driver.Values.forEach((c) => {
      if (
        c.Date.Order === global.Modeliks.DateHelper.months[0].Order &&
        c.Date.PeriodType === PeriodTypes.month
      ) {
        const curNetIncome = netIncome.getItemByDateSufix(c.Date.sufix);
        const curClosingBalance = closingBalance.getItemByDateSufix(c.Date.sufix);
        const curDividendsTotals = dividendsTotals.getItemByDateSufix(c.Date.sufix);
        curClosingBalance.Formula = `${c} + ${curDividendsTotals} + ${curNetIncome}`;
      } else {
        if (c.Date.PeriodType === PeriodTypes.year && !c.Date.Active) {
          const curYearOpeningBalance = driver.getItemByDateSufix(c.Date.sufix);
          const curYearClosingBalance = closingBalance.getItemByDateSufix(c.Date.sufix);
          curYearOpeningBalance.Formula = `${driver.getItemByDateSufix(c.Date.months[0].sufix)}`;
          curYearClosingBalance.Formula = `${closingBalance.getItemByDateSufix(c.Date.months[c.Date.months.length - 1].sufix)}`;
        } else {
          const curNetIncome = netIncome.getItemByDateSufix(c.Date.sufix);
          const curClosingBalance = closingBalance.getItemByDateSufix(c.Date.sufix);
          const curDividendsTotals = dividendsTotals.getItemByDateSufix(c.Date.sufix);
          const prevClosingBalance = closingBalance.Values.find(
            (td) => td.PeriodType == c.PeriodType && td.Order == c.Order - 1,
          );
          if (prevClosingBalance) {
            c.Formula = `${prevClosingBalance}`;
          }
          curClosingBalance.Formula = `${c} + ${curDividendsTotals} + ${curNetIncome}`;
        }
      }
    });

    if (callBack) {
      callBack(driver);
    } else {
      return driver;
    }
  };
  static getDividendsTotals = () => {
    const driverID = "dividends_all";
    let driver = global.Modeliks.DriversStore.getItem(`${Financing.TableName}-${driverID}-totals`);
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Financing.TableName,
        driverID,
        "totals",
        UnitTypes.PriceNegative,
        DriverCategories.Sum,
        "Dividend Payments",
        false,
        true,
        false,
      );
      driver.Save();
    }
    driver.IsFormulaEditable = false;

    const DividendsDrivers = global.Modeliks.FinancingStore.filter(
      (d) => d.FinanceType === FinancingTypes.Dividends,
    );
    if (DividendsDrivers.length > 0) {
      const totalDrivers = DividendsDrivers.map((d) => d.Totals).join("+");
      driver.setFormula(`(${totalDrivers}) * -1`);
    }

    return driver;
  };

  static getTotalPrincipalRepayment = () => {
    const driverID = "total_principal_repayments";
    let driver = global.Modeliks.DriversStore.getItem(`${Financing.TableName}-${driverID}-totals`);
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Financing.TableName,
        driverID,
        "totals",
        UnitTypes.PriceNegative,
        DriverCategories.Sum,
        "Total Principal payments ",
        false,
        true,
        false,
      );
    }

    const TotalLoan = global.Modeliks.FinancingStore.filter(
      (d) => d.FinanceType === FinancingTypes.Loan,
    );
    if (TotalLoan.length > 0) {
      driver.setFormula_Sum(TotalLoan.map((d) => d.PrincipalPayment));
    }
    return driver;
    // PrincipalPayment
  };

  static getClosingBalanceRetainedEarnings = () => {
    const driverID = `reained_earnings_cl_driver`;
    let driver = global.Modeliks.DriversStore.getItem(`${Financing.TableName}-${driverID}-totals`);
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Financing.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.LastPeriod,
        "Closing Balance",
        false,
        true,
        false,
      );
      driver.Save();
    }

    driver.IsFormulaEditable = false;

    driver.LastPeriodOnly = true;
    driver.isExpense = true;

    return driver;
  };
  static getLoanReceipts = () => {
    const driverID = "total_loan_receipts";
    let driver = global.Modeliks.DriversStore.getItem(`${Financing.TableName}-${driverID}-totals`);
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Financing.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.Sum,
        "Loan Receipts",
        false,
        false,
        false,
      );
      driver.Save();
    }
    driver.IsFormulaEditable = false;

    const loanDrivers = global.Modeliks.FinancingStore.filter(
      (d) => d.FinanceType === FinancingTypes.Loan,
    );

    if (loanDrivers && loanDrivers.length > 0) {
      const totalDrivers = loanDrivers.map((d) => d.LoanReceipt);

      if (loanDrivers.length > 0) {
        driver.Formula = totalDrivers.map((c) => c.ID_f).join("+");
        driver.Values.forEach((c) => {
          const curValues = totalDrivers.map((d) =>
            d.Values.find((td) => td.PeriodType == c.PeriodType && td.Order == c.Order),
          );
          c.Formula = curValues.map((c) => c.ID_f).join("+");
        });
      }
    }

    return driver;
  };
  static getLoanRepayments = () => {
    const driverID = "total_loan_repayments";
    let driver = global.Modeliks.DriversStore.getItem(`${Financing.TableName}-${driverID}-totals`);
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Financing.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.Sum,
        "Loan Repayments",
        false,
        false,
        false,
      );
      driver.Save();
    }

    driver.IsFormulaEditable = false;

    const loanDrivers = global.Modeliks.FinancingStore.filter(
      (d) => d.FinanceType === FinancingTypes.Loan,
    );
    if (loanDrivers.length > 0) {
      driver.Formula = loanDrivers
        .map((c) => `MxMath.Negative(${c.PrincipalPayment.ID_f} + ${c.ExtraPaymentAmount.ID_f})`)
        .join("+");
      driver.Values.forEach((c) => {
        const curPrincipalPayments = loanDrivers.map((d) =>
          d.PrincipalPayment.Values.find(
            (td) => td.PeriodType == c.PeriodType && td.Order == c.Order,
          ),
        );
        const curExtraPayments = loanDrivers.map((d) =>
          d.ExtraPaymentAmount.Values.find(
            (td) => td.PeriodType == c.PeriodType && td.Order == c.Order,
          ),
        );
        c.Formula = `MxMath.Negative(${curPrincipalPayments.map((c) => c.ID_f).join("+")} + ${curExtraPayments.map((c) => c.ID_f).join("+")})`;
      });
    }
    return driver;
  };
  static getChangeInLineOfCredit = () => {
    const driverID = "total_change_in_line_of_credit";
    let driver = global.Modeliks.DriversStore.getItem(`${Financing.TableName}-${driverID}-totals`);
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Financing.TableName,
        driverID,
        "totals",
        UnitTypes.PriceNegative,
        DriverCategories.Sum,
        "Change In Line Of Credit",
        false,
        true,
        false,
      );
      driver.Save();
    }
    driver.IsFormulaEditable = false;

    const lineOfCreditDrivers = global.Modeliks.FinancingStore.filter(
      (d) => d.FinanceType === FinancingTypes.LineOfCredit,
    );

    if (lineOfCreditDrivers.length > 0) {
      const months = [
        ...global.Modeliks.DateHelper.months_before_actual,
        ...global.Modeliks.DateHelper.months,
      ];
      const years = [
        ...global.Modeliks.DateHelper.years_before_actual,
        ...global.Modeliks.DateHelper.years_all,
      ];

      months.forEach((month) => {
        const prevMonthDate = months.find((c) => c.Order == month.Order - 1);
        const curMonthLineOfCreditBalanceDrivers = lineOfCreditDrivers.map((d) =>
          d.LineOfCreditBalance.getItemByDateSufix(month.sufix),
        );
        const curMonthOpeningBalanceDrivers = lineOfCreditDrivers.map((d) =>
          d.OpenningBalance.getItemByDateSufix(month.sufix),
        );
        const curMonth = driver.getItemByDateSufix(month.sufix);

        if (prevMonthDate) {
          const curMonthTotalsDrivers = lineOfCreditDrivers.map((d) =>
            d.Totals.getItemByDateSufix(month.sufix),
          );
          const prevMonthTotalsDrivers = lineOfCreditDrivers.map((d) =>
            d.Totals.getItemByDateSufix(prevMonthDate.sufix),
          );
          curMonth.Formula =
            `(${curMonthLineOfCreditBalanceDrivers.map((c) => c.ID_f).join("+")}) - (${curMonthOpeningBalanceDrivers.map((c) => c.ID_f).join("+")})` +
            ` Actual( ${curMonthTotalsDrivers.map((c) => c.ID_f_actual).join("+")} -  ${prevMonthTotalsDrivers.map((c) => c.ID_f_actual).join("-")} )`;
        } else {
          curMonth.Formula = `(${curMonthLineOfCreditBalanceDrivers.map((c) => c.ID_f).join("+")}) - (${curMonthOpeningBalanceDrivers.map((c) => c.ID_f).join("+")})`;
          curMonth.evalFormulaActual = null;
        }
      });

      years.forEach((year) => {
        const curYearLineOfCreditBalanceDrivers = lineOfCreditDrivers.map((d) =>
          d.LineOfCreditBalance.getItemByDateSufix(year.sufix),
        );
        const curYearOpeningBalanceDrivers = lineOfCreditDrivers.map((d) =>
          d.OpenningBalance.getItemByDateSufix(year.sufix),
        );
        const curYear = driver.getItemByDateSufix(year.sufix);
        curYear.Formula = `(${curYearLineOfCreditBalanceDrivers.map((c) => c.ID_f).join("+")}) - (${curYearOpeningBalanceDrivers.map((c) => c.ID_f).join("+")})`;
        if (year.Order !== years[0].Order) {
          const prevYearDate = years.find((c) => c.Order === year.Order - 1);
          const curYearTotalsDrivers = lineOfCreditDrivers.map((d) =>
            d.Totals.getItemByDateSufix(year.sufix),
          );
          const prevYearTotalsDrivers = lineOfCreditDrivers.map((d) =>
            d.Totals.getItemByDateSufix(prevYearDate.sufix),
          );
          curYear.Formula =
            `${curYear.Formula ? curYear.Formula : ""}` +
            ` Actual( ${curYearTotalsDrivers.map((c) => c.ID_f_actual).join("+")} -  ${prevYearTotalsDrivers.map((c) => c.ID_f_actual).join("-")} )`;
        }
      });
    }
    return driver;
  };
  static getChangeInLone = () => {
    const driverID = "total_change_in_lone";
    let driver = global.Modeliks.DriversStore.getItem(`${Financing.TableName}-${driverID}-totals`);
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Financing.TableName,
        driverID,
        "totals",
        UnitTypes.PriceNegative,
        DriverCategories.Sum,
        "Change In Loan",
        false,
        true,
        false,
      );
      driver.Save();
    }

    driver.IsFormulaEditable = false;

    const lineOfCreditDrivers = global.Modeliks.FinancingStore.filter(
      (d) => d.FinanceType === FinancingTypes.Loan,
    );

    if (lineOfCreditDrivers.length > 0) {
      const months = [
        ...global.Modeliks.DateHelper.months_before_actual,
        ...global.Modeliks.DateHelper.months,
      ];
      const years = [
        ...global.Modeliks.DateHelper.years_before_actual,
        ...global.Modeliks.DateHelper.years_all,
      ];

      months.forEach((month) => {
        const prevMonthDate = months.find((c) => c.Order == month.Order - 1);
        const curMonthLineOfCreditBalanceDrivers = lineOfCreditDrivers.map((d) =>
          d.LoanBalance.getItemByDateSufix(month.sufix),
        );
        const curMonthOpeningBalanceDrivers = lineOfCreditDrivers.map((d) =>
          d.OpenningBalance.getItemByDateSufix(month.sufix),
        );
        const curMonth = driver.getItemByDateSufix(month.sufix);

        if (prevMonthDate) {
          const curMonthTotalsDrivers = lineOfCreditDrivers.map((d) =>
            d.Totals.getItemByDateSufix(month.sufix),
          );
          const prevMonthTotalsDrivers = lineOfCreditDrivers.map((d) =>
            d.Totals.getItemByDateSufix(prevMonthDate.sufix),
          );
          curMonth.Formula =
            `(${curMonthLineOfCreditBalanceDrivers.map((c) => c.ID_f).join("+")}) - (${curMonthOpeningBalanceDrivers.map((c) => c.ID_f).join("+")})` +
            ` Actual( ${curMonthTotalsDrivers.map((c) => c.ID_f_actual).join("+")} -  ${prevMonthTotalsDrivers.map((c) => c.ID_f_actual).join("-")} )`;
        } else {
          curMonth.Formula = `(${curMonthLineOfCreditBalanceDrivers.map((c) => c.ID_f).join("+")}) - (${curMonthOpeningBalanceDrivers.map((c) => c.ID_f).join("+")})`;
          curMonth.evalFormulaActual = null;
        }
      });

      years.forEach((year) => {
        const curYearLineOfCreditBalanceDrivers = lineOfCreditDrivers.map((d) =>
          d.LoanBalance.getItemByDateSufix(year.sufix),
        );
        const curYearOpeningBalanceDrivers = lineOfCreditDrivers.map((d) =>
          d.OpenningBalance.getItemByDateSufix(year.sufix),
        );
        const curYear = driver.getItemByDateSufix(year.sufix);
        curYear.Formula = `(${curYearLineOfCreditBalanceDrivers.map((c) => c.ID_f).join("+")}) - (${curYearOpeningBalanceDrivers.map((c) => c.ID_f).join("+")})`;
        if (year.Order !== years[0].Order) {
          const prevYearDate = years.find((c) => c.Order === year.Order - 1);
          const curYearTotalsDrivers = lineOfCreditDrivers.map((d) =>
            d.Totals.getItemByDateSufix(year.sufix),
          );
          const prevYearTotalsDrivers = lineOfCreditDrivers.map((d) =>
            d.Totals.getItemByDateSufix(prevYearDate.sufix),
          );
          curYear.Formula =
            `${curYear.Formula ? curYear.Formula : ""}` +
            ` Actual( ${curYearTotalsDrivers.map((c) => c.ID_f_actual).join("+")} -  ${prevYearTotalsDrivers.map((c) => c.ID_f_actual).join("-")} )`;
        }
      });
    }

    return driver;
  };
  static getInterestExpense = () => {
    const driverID = "interest_expense_driver";
    let driver = global.Modeliks.DriversStore.getItem(
      Financing.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Financing.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.Sum,
        "Interest Expense",
        false,
        true,
        false,
      );
      driver.Save();
    }
    driver.isExpense = true;
    driver.IsExisting = true;
    driver.IsFormulaEditable = false;
    const drivers = global.Modeliks.DriversStore.filter((c) => c.Ref_Field === "interest_expense");
    if (drivers && drivers.length > 0) {
      driver.setFormula_Sum(drivers);
      driver.Formula = null;
    }
    return driver;
  };
  static getPrepaidRevenueOpeningBalance = () => {
    const driverID = "prepaid-revenue-opening-balance";
    let driver = global.Modeliks.DriversStore.getItem(
      Financing.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Financing.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.FirstPeriod,
        "Prepaid Revenue Opening Balance",
        false,
        false,
        false,
      );
      const prepaidClosingBalance = Financing.getPrepaidRevenueClosingBalance();

      const months = global.Modeliks.DateHelper.months;
      const years = global.Modeliks.DateHelper.years_all;

      months.forEach((month) => {
        const curMonth = driver.getItemByDateSufix(month.sufix);
        if (month.Order > 0) {
          const monthBefore = months.find((m) => m.Order === curMonth.Order - 1);
          curMonth.Formula = `${prepaidClosingBalance.getItemByDateSufix(monthBefore.sufix)}`;
        } else {
          curMonth.Formula = null;
        }
      });

      years.forEach((year) => {
        const curYear = driver.getItemByDateSufix(year.sufix);
        const lastMonth = prepaidClosingBalance.getItemByDateSufix(
          year.months[year.months.length - 1].sufix,
        );
        const firstMonth = driver.getItemByDateSufix(year.months[0].sufix);
        if (!year.Active) {
          curYear.Formula = `${firstMonth}`;
        } else {
          curYear.Formula = `${prepaidClosingBalance.getItemByDateSufix(years[years.indexOf(year) - 1].sufix)}`;
        }
      });
      driver.Save();
    }

    driver.isExpense = true;
    driver.IsFormulaEditable = false;

    return driver;
  };
  static getPrepaidRevenueClosingBalance = () => {
    const driverID = "prepaid-revenue-closing-balance";
    let driver = global.Modeliks.DriversStore.getItem(
      Financing.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Financing.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.LastPeriod,
        "Unearned Revenue",
        false,
        true,
        false,
      );
      driver.Save();
    }

    if (driver.DriverName !== "Unearned Revenue") {
      driver.DriverName = "Unearned Revenue";
      driver.Save();
    }

    driver.IsExisting = true;
    driver.LastPeriodOnly = true;
    driver.isExpense = true;
    driver.IsFormulaEditable = false;

    const prepaidOpeningBalance = Financing.getPrepaidRevenueOpeningBalance();
    const cumulativeCashCollections = Subscription.getSubscriptionsCashCollections();
    const cumulativeSubscriptionRevenue = Subscription.getSubscriptionsTotals();

    const months = global.Modeliks.DateHelper.months;
    const years = global.Modeliks.DateHelper.years_all;

    months.forEach((month) => {
      const curMonth = driver.getItemByDateSufix(month.sufix);
      curMonth.Formula = `${prepaidOpeningBalance.getItemByDateSufix(month.sufix)} + ${cumulativeCashCollections.getItemByDateSufix(month.sufix)} - ${cumulativeSubscriptionRevenue.getItemByDateSufix(month.sufix)}`;
    });

    years.forEach((year) => {
      const curYear = driver.getItemByDateSufix(year.sufix);
      if (!year.Active) {
        const lastMonthPrepaidOpeningBalance = prepaidOpeningBalance.getItemByDateSufix(
          year.months[year.months.length - 1].sufix,
        );
        const lastMonthCashCollections = cumulativeCashCollections.getItemByDateSufix(
          year.months[year.months.length - 1].sufix,
        );
        const lastMonthSubscriptionRevenue = cumulativeSubscriptionRevenue.getItemByDateSufix(
          year.months[year.months.length - 1].sufix,
        );
        curYear.Formula = `${lastMonthPrepaidOpeningBalance} + ${lastMonthCashCollections} - ${lastMonthSubscriptionRevenue}`;
      } else {
        curYear.Formula = `${prepaidOpeningBalance.getItemByDateSufix(year.sufix)} + ${cumulativeCashCollections.getItemByDateSufix(year.sufix)} - ${cumulativeSubscriptionRevenue.getItemByDateSufix(year.sufix)}`;
      }
    });

    return driver;
  };
}

export default Financing;
