import React, { lazy } from "react";
import Revenue from "../../data/Finance/Revenue";
import CostSales from "../../data/Finance/CostSales";
import Personnel from "../../data/Finance/Personnel";
import Expense from "../../data/Finance/Expense";
import Financing from "../../data/Finance/Financing";
import Assets from "../../data/Finance/Assets";
import WorkingCapital from "../../data/Finance/WorkingCapital";
import Taxes from "../../data/Finance/Taxes";
import CashFlow from "../../data/Finance/Reports/CashFlow";
import { ReportsTypes } from "../../data/Finance/Reports/constants";
import ProfitLoss from "../../data/Finance/Reports/ProfitLoss";
import { WorkingCapitalTypes } from "../../data/Finance/WorkingCapital/constants";
import AccountReceivable from "../../data/Finance/WorkingCapital/AccountReceivable";
import RevenueOnly from "../../pages/Secure/Financials/Revenue/components/RevenueOnly";
import { ExpenseTypes, PersonnelFunctionTypes, RevenueTypes } from "../../data/Finance/constants";
import UnitSales from "../../data/Finance/Revenue/UnitSales";
import BillableHours from "../../data/Finance/Revenue/BillableHours";
import Subscription from "../../data/Finance/Revenue/Subscription";
import Expenses from "../../pages/Secure/Financials/Expenses";
import FinanceGeneric from "../../data/Finance/FinanceGeneric";
// const Taxes = React.lazy(()=> import("../../data/Finance/Taxes"));
import dataStructure from "../../data/datastructure";

export const DefaultChartOptions = {
  chartOptions: {
    backgroundColor: "#FFFFFF",
    borderColor: "#FFFFFF",
    showVerticalAxis: true,
    showHorizontalAxis: true,
    showTitle: false,
    titleAlign: "start",
    showSubtitle: false,
    title: "Title",
    subtitle: "Subtitle",
    xGrid: false,
    yGrid: false,
    displayLegend: false,
    dataLabelsFontSize: 30,
    datalabelsDecimalPoints: 1,
    axisFontSize: 24,
    legendFontSize: 20,
    gapWidth: 3,
    categoryGapWidth: 7,
    titleFontSize: 28,
    subTitleFontSize: 24,
    dataType: "number",
    minimumVerticalAxisValue: "",
    maximumVerticalAxisValue: "",
    minimumHorizontalAxisValue: "",
    maximumHorizontalAxisValue: "",
    legendPosition: "bottom",
    showDataLabels: false,
    hideSeriesDataLabels: false,
    DataLabelsPosition: "end",
  },
  chartData: {
    labels: [],
    datasets: [],
  },
};
const ChartTypes = {
  BarChart: {
    key: "BarChart",
    name: "Bar Chart",
    type: "bar",
  },
  LineChart: {
    key: "LineChart",
    name: "Line Chart",
    type: "line",
  },
  StackedBarChart: {
    key: "StackedBarChart",
    name: "Stacked Bar Chart",
    type: "bar",
  },
  WaterfallChart: {
    key: "WaterfallChart",
    name: "Waterfall Bar Chart",
    type: "bar",
  },
  HorizontalBarChart: {
    key: "HorizontalBarChart",
    name: "Horizontal Bar Chart",
    type: "bar",
  },
  AreaChart: {
    key: "AreaChart",
    name: "Area Chart",
    type: "line",
  },
};

export const ChartDataTypes = {
  comparisonPeriod: "Comparison Period",
  driver: "driver",
  reportFrequency: "Report Frequency",
};

export const ChartWidth = {
  fullScreen: {
    name: "Full Screen",
    value: 100,
  },
  threeQuartersScreen: {
    name: "3/4 screen",
    value: 75,
  },
  halfScreen: {
    name: "1/2 screen",
    value: 50,
  },
  quarterScreen: {
    name: "1/4 screen",
    value: 25,
  },
};

const ReportFrequency = {
  monthly: {
    name: "Monthly",
    PeriodType: "month",
  },
  quarterly: {
    name: "Quarterly",
    PeriodType: "month",
  },
  annually: {
    name: "Annually",
    PeriodType: "month",
  },
  annuallyY: {
    name: "Annually (by year)",
    PeriodType: "year",
  },
};

const ChangeableChartTypes = {
  BarChart: {
    key: "BarChart",
    label: (
      <svg
        width="14"
        height="18"
        viewBox="0 0 14 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 17V7M13 17V1M1 17V13"
          stroke="#979797"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
  LineChart: {
    key: "LineChart",
    label: (
      <svg
        width="24"
        height="14"
        viewBox="0 0 24 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23 1L13.5 10.5L8.5 5.5L1 13M23 1H17M23 1V7"
          stroke="#979797"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
  },
};

const ComparisonPeriod = {
  actual: "Actual",
  forecast: "Forecast",
  previousYear: "Previous Year",
  previousPeriod: "Previous Period",
  actualForecast: "Actual & Forecast",
};

const DashboardChartDriverType = {
  actual: "Actual",
  forecast: "Forecast",
};

const StreamTypes = Object.assign(
  {
    Revenue: {
      key: "Revenue",
      driverSection: "StreamTypes",
      DriverName: "Finance Revenues Totals",
      data: Revenue,
      getData: "getRevenueTotals",
    },
    Expense: {
      key: "Expense",
      driverSection: "StreamTypes",
      DriverName: "Finance Expenses Totals",
      data: Expense,
      getData: "getExpenseOnlyTotals",
      isExpense: true,
    },
    Assets: {
      key: "Assets",
      driverSection: "StreamTypes",
      DriverName: "Finance Assets Totals",
      data: Assets,
      getData: "getAssetsTotals",
    },
    WorkingCapital: {
      key: "WorkingCapital",
      driverSection: "StreamTypes",
      DriverName: "Finance WorkingCapital Totals",
      data: WorkingCapital,
      getData: "getWorkingCapitalTotals",
    },
    Taxes: {
      key: "Taxes",
      driverSection: "StreamTypes",
      DriverName: "Finance Taxes Totals",
      data: Taxes,
      getData: "getTaxesTotals",
    },
    Financing: {
      isExpense: true,
      key: "Financing",
      driverSection: "StreamTypes",
      DriverName: "Finance Financing Totals",
      data: Financing,
      getData: "getFinanceTotals",
    },
    RevenueAll: {
      key: "RevenueAll",
      driverSection: "AllSectionDrivers",
      DriverName: "Revenues (all)",
      driversStore: ["RevenuesStore"],
    },
    CostSalesAll: {
      isExpense: true,
      key: "CostSalesAll",
      driverSection: "AllSectionDrivers",
      DriverName: "Cost of Goods Sold (all)",
      driversStore: ["CostSaleStore", "ExpensesStore", "PersonnelStore"],
      exceptionKeys: ["ExpenseType", "FunctionType"], // FunctionType === PersonnelFunctionTypes.DirectLabor
      except: [
        ExpenseTypes.GeneralAndAdministrative,
        ExpenseTypes.ResearchAndDevelopment,
        ExpenseTypes.SalesAndMarketing,
      ], //check and add personnel types if bugs in the future
    },
    ExpensesAll: {
      isExpense: true,
      key: "ExpensesAll",
      driverSection: "AllSectionDrivers",
      DriverName: "Expenses (all)",
      driversStore: ["ExpensesStore"],
      exceptionKeys: ["ExpenseType"],
      except: [ExpenseTypes.DirectCost],
    },
    AssetsAll: {
      key: "AssetsAll",
      driverSection: "AllSectionDrivers",
      DriverName: "Assets (all)",
      driversStore: ["AssetsStore"],
      function: "getAssets",
    },
    FinancingAll: {
      key: "FinancingAll",
      driverSection: "AllSectionDrivers",
      DriverName: "Financing (all)",
      driversStore: ["FinancingStore"],
      function: "getFinances",
    },
    NetCashOperatingActivitiesAll: {
      isCashFlow: true,
      DriverName: "Net cash from operating activities(All)",
      key: "NetCashOperatingActivitiesAll",
      containingDriver: "NetCashFromOperatingActivities",
    },
    NetCashOperatingInvestingAll: {
      isCashFlow: true,
      DriverName: "Net cash from investing (All)",
      key: "NetCashOperatingInvestingAll",
      containingDriver: "NetCashFromInvestingActivities",
    },
    NetCashOperatingFinancingActivitiesAll: {
      isCashFlow: true,
      DriverName: "Net cash from financing activities (All)",
      key: "NetCashOperatingFinancingActivitiesAll",
      containingDriver: "NetCashFromFinancingActivities",
    },
  },
  {},
);

const NegativeDriverTables = [
  dataStructure.Finance_CostSales.TableName,
  dataStructure.Finance_Expenses.TableName,
  dataStructure.Finance_Personnel.TableName,
];

const NumberOfMonths = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const NumberFormatTypes = {
  fullNumbers: {
    key: "fullNumbers",
    fullName: "Full Numbers",
    name: "",
    splitBy: 1,
    shortName: "",
    fixedDecimals: false,
    numDecimals: 0,
  },
  thousands: {
    key: "thousands",
    fullName: "Thousands",
    name: "Thousands",
    splitBy: 1000,
    shortName: "K",
    fixedDecimals: true,
    numDecimals: 2,
  },
  millions: {
    key: "millions",
    fullName: "Millions",
    name: "Millions",
    splitBy: 1000000,
    shortName: "M",
    fixedDecimals: true,
    numDecimals: 2,
  },
};

const DashboardElementType = {
  charts: "charts",
  financial_statements: "financial_statements",
  custom_kpis: "custom_kpis",
};

const ChartDescriptions = {
  "Bookings (all customers)":
    "Bookings (all Customers) provides a concise overview of the total revenue generated by all customers within the specified period, offering a snapshot of the overall financial performance of your business",
  "Bookings (new customers)":
    "Bookings (New Customers) highlights the total revenue generated from new customers during the defined period. It serves as a focused indicator of the financial contribution from newly acquired customers, offering insights into the growth and acquisition aspects of your business",
  "Average Contract Price":
    "Average Contract Price represents the average monetary value of contracts within the specified period. It provides insight into the typical pricing structure of the offered services, aiding in understanding the average financial commitment of customers. Monitoring this metric assists in assessing pricing strategies and overall revenue potential for your SaaS business",
  "Average Contract Duration (months, new customers)":
    "Average Contract Duration (months, new customers) indicates the average length of contracts with new customers over the  specific period. It offers insights into the duration of commitments from newly acquired customers, helping assess customer retention and engagement strategies. Monitoring this metric aids in understanding the typical timeframe for which new customers engage with the services offered by your SaaS business.",
  "Average MRR":
    "Average Monthly Recurring Revenue (MRR) provides the average revenue generated from recurring subscriptions on a monthly basis. It offers a key indicator of the typical income your SaaS business can expect from its customer base. Monitoring this metric helps gauge the overall financial stability and predictability of revenue streams.",
  "Average MRR (new customers)":
    "Average Monthly Recurring Revenue (MRR) for New Customers specifically focuses on the average revenue generated from new customers' recurring subscriptions on a monthly basis. This metric provides insights into the initial financial contribution of newly acquired clients and helps assess the effectiveness of strategies aimed at onboarding and retaining new customers in your SaaS business.",
  "Average MRR vs. New MRR":
    "Average MRR vs. New MRR compares the average monthly recurring revenue (MRR) with the MRR specifically generated from new customers. This analysis provides insights into the balance between ongoing revenue from existing customers and the additional revenue contributed by newly acquired customers. It is a valuable metric for understanding the overall composition of MRR and evaluating the effectiveness of customer retention and acquisition efforts in your SaaS business.",
  "Average ARR vs. New ARR":
    "Average ARR vs. New ARR compares the average annual recurring revenue (ARR) with the ARR specifically generated from new customers. This metric offers insights into the balance between ongoing revenue from existing customers and the additional revenue contributed by newly acquired customers over a year. It helps assess the overall composition of ARR, providing valuable information for evaluating customer retention and ",
  "Average ARR":
    'Average Annual Recurring Revenue (ARR)" represents the average annualized revenue generated from all customers within a specified period. This metric provides a standard measure of the recurring revenue per customer, aiding in assessing the overall financial health and stability of your SaaS business. Monitoring the Average ARR is crucial for understanding the typical revenue contribution from each customer over a year.',
  "Average ARR (new customers)":
    "Average Annual Recurring Revenue (ARR) for New Customers specifically focuses on the average annualized revenue generated from new customers within the defined period. This metric offers insights into the initial financial impact of newly acquired clients, providing a standard measure of the recurring revenue per new customer. Monitoring the Average ARR for new customers is valuable for evaluating the effectiveness of customer acquisition strategies and understanding the typical annual revenue contribution from this segment of your SaaS business.",
  "Total MRR":
    "Total Monthly Recurring Revenue (MRR) represents the overall sum of revenue generated from all recurring subscriptions on a monthly basis. This metric provides a comprehensive view of the total ongoing revenue contributed by the entire customer base within a specified period. Monitoring Total MRR is crucial for understanding the aggregate financial performance and stability of your SaaS business, as it encompasses contributions from all customers and subscription plans.",
  "Total ARR":
    "Total Annual Recurring Revenue (ARR) represents the overall sum of revenue generated from all recurring subscriptions over a year. This metric provides a comprehensive view of the total ongoing revenue contributed by the entire customer base within a specified period. Monitoring Total ARR is crucial for understanding the aggregate financial performance and stability of your SaaS business, as it encompasses contributions from all customers and subscription plans over the course of a year.",
  "Customers at end of period (#)":
    "Customers at End of Period (#) are the total number of customers at the end of the period. This metric serves as a key measure of the customer base's size and growth over time, providing insights into the overall customer retention and acquisition dynamics within the given period. Monitoring this metric is essential for assessing the company's customer-related performance and its ability to maintain and expand its user base.",
  "New and Churned Customers (#)": (
    <span>
      New and Churned Customers (#) involves tracking the total number of new customer signups, the
      total number of churned customers, and calculating the net new customers within the specified
      period. This metric provides a comprehensive overview of customer acquisition and loss
      dynamics.
      <p>Total Signups: This represents the number of new customers who have subscribed</p>
      <p>
        Total Churned Customers: This indicates the total number of customers who have discontinued
        their subscriptions{" "}
      </p>
      <p>
        Net New Customers: Calculated by subtracting the total churned customers from the total
        signups, this figure represents the overall growth or decline in the customer base. A
        positive net new customers value suggests growth, while a negative value indicates a
        reduction in the customer base.
      </p>
      <p>
        Monitoring these metrics is crucial for understanding customer acquisition trends, assessing
        customer retention efforts, and evaluating the overall health and growth potential of your
        SaaS business.
      </p>{" "}
    </span>
  ),
  "Customer Churn Rate per month":
    "Customer Churn Rate per month calculates the percentage of customers who discontinue their subscriptions within a given month. A higher churn rate indicates a higher level of customer attrition, while a lower churn rate suggests better customer retention. Monitoring the Customer Churn Rate per month is crucial for assessing the effectiveness of customer retention strategies and understanding the overall satisfaction and loyalty of customers in a Software as a Service business.",
  "MRR Churn Rate per month":
    "MRR Churn Rate per month calculates the percentage of Monthly Recurring Revenue (MRR) lost due to customer churn within a given month. A higher MRR Churn Rate indicates a higher impact on revenue due to customer cancellations, while a lower rate suggests better revenue retention. Monitoring this metric is essential for assessing the financial implications of customer churn and evaluating the effectiveness of strategies aimed at preserving and growing Monthly Recurring Revenue in your SaaS business.",
  "LTV & CAC": (
    <span>
      <p>
        LTV & CAC where CAC is fully loaded involves analyzing the relationship between Customer
        Lifetime Value (LTV) and Customer Acquisition Cost (CAC) with the CAC being fully loaded,
        meaning that all associated costs are taken into account.
      </p>
      <p>
        1. Customer Lifetime Value (LTV): LTV represents the total expected revenue a business
        anticipates to generate from a customer throughout their entire lifecycle. It takes into
        account factors such as average revenue per user and the expected duration of the customer
        relationship.
      </p>
      <p>
        2. Customer Acquisition Cost (CAC) - Fully Loaded: CAC includes all costs associated with
        acquiring a customer, including marketing, sales, and operational expenses. When fully
        loaded, it accounts for all relevant costs to acquire and onboard a customer.
      </p>
      <p>
        The relationship between LTV and CAC is crucial for assessing the sustainability and
        profitability of customer acquisition efforts. Ideally, the LTV should exceed the fully
        loaded CAC to ensure that the business generates more revenue over the customer's lifetime
        than it invested in acquiring and onboarding that customer. This metric is fundamental for
        making informed decisions about the efficiency and effectiveness of customer acquisition
        strategies in your SaaS business.
      </p>
    </span>
  ),
  "LTV to CAC Ratio":
    "LTV to CAC Ratio involves evaluating the relationship between Customer Lifetime Value (LTV) and Customer Acquisition Cost (CAC). This ratio is a key indicator of the efficiency and sustainability of your SaaS business's customer acquisition strategy.The LTV to CAC Ratio is calculated by dividing the Customer Lifetime Value by the Customer Acquisition Cost. This ratio indicates how much revenue a business expects to generate for every dollar spent on customer acquisition.A ratio greater than 1 suggests a positive return on investment, indicating that the business is likely to recoup its acquisition costs and generate additional revenue over the customer's lifetime. Monitoring this metric is crucial for optimizing marketing and sales strategies and ensuring the long-term financial health of a SaaS business.",
  "Months to Recover CAC":
    'The "Months to Recover CAC" metric measures the time it takes for a business to recoup its Customer Acquisition Cost (CAC) through the revenue generated by a newly acquired customer. It provides insights into the speed at which your business can recover the investment made in acquiring a customer and start realizing a positive return.',
  "Customer Lifetime (months)":
    "Customer Lifetime (months) represents the average duration, in months, that a customer is expected to remain subscribed with your service. It provides valuable insights into the longevity of customer relationships and aids in predicting the revenue potential from individual customers over time.",
  "Revenue per S&M employee":
    "Revenue per Sales and Marketing (S&M) Employee measures the efficiency of a company's sales and marketing team by evaluating the amount of revenue generated per S&M employee. This metric provides insights into the productivity and effectiveness of the sales and marketing teams in driving revenue for your business. ",
};

export {
  ReportFrequency,
  ChartDescriptions,
  ComparisonPeriod,
  DashboardChartDriverType,
  StreamTypes,
  ChartTypes,
  NegativeDriverTables,
  NumberFormatTypes,
  ChangeableChartTypes,
  NumberOfMonths,
  DashboardElementType,
};
