import React, { useState } from "react";
import LocalStorage from "../../../helpers/LocalStorage";
import {
  tableConfig,
  tableConfigTypes,
  tableObject,
} from "../../../components/tables/FinanceTable/components/constants";
import "./exportpdf.scss";
import "../Financials/components/Export/pdf_export.scss";
import "../Financials/components/Export/ExportReport.scss";
import Mx_NumInput from "../../../components/actions/Mx_NumInput";
const notBoldHeaders = [
  "Revenue Growth",
  "Gross Profit Margin",
  "Sale of Property, Plant & Equipment",
  "EBITDA Margin",
  "Depreciation",
  "Operating Profit Margin",
  "Interest Expense",
  "Profit Before Tax Margin",
  "Accrued Income Tax - Income Tax",
  "Net Income Margin",
];

const boldHeaders = [
  "Total Assets",
  "Total Liabilities",
  "Equity",
  "Total Liabilities and Equity",
  "Net Cash From Investing Activities",
  "Net Cash From Operating Activities",
  "Net Cash From Financing Activities",
];

const normalBoldHeaders = [
  "Cost of Goods Sold",
  "Operating Expenses",
  "Current Assets",
  "Long Term Assets",
  "Current Liabilities",
  "Long Term Liabilities",
  "Change In Working Capital",
  "Change In Debt",
  "Net increase (decrease) in cash",
];

const BoldBackgroundHeaders = ["Revenue"];

const BoldBorderTop = [
  "Gross Profit",
  "EBITDA",
  "Operating Profit",
  "Profit Before Tax",
  "Net Income",
];

const insertEmptyRowBefore = [
  "Total Liabilities and Equity",
  "Net Cash From Investing Activities",
  "Net Cash From Financing Activities",
  "Net increase (decrease) in cash",
];

const NoIndent = [
  "Net Income",
  "Depreciation",
  "Change In Working Capital",
  "Asset Purchases",
  "Change In Other Long Term Liabilities",
  "Change In Debt",
  "Equity Investments",
  "Dividend Payments",
];

class ImageHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasImage: true,
    };
  }

  render() {
    if (this.state.hasImage) {
      return (
        <img
          onError={() => {
            this.setState({ hasImage: false }, () => {
              window.print();
            });
          }}
          src={`/api/logo?account_id=${global.Modeliks.Account.ID}&company_id=${global.Modeliks.CompanyInfo.ID}&v=${new Date().getTime()}`}
          alt={"rep_img"}
          onLoad={() => {
            window.print();
          }}
        />
      );
    }
  }
}

const Header = (props) => {
  return (
    <div className="report_header">
      <span className="report title report_company_name report_sub_title">
        {global.Modeliks.CompanyInfo.CompanyName}
        <p>{global.Modeliks.CompanyScenarioInfo.ScenarioName}</p>
        <p>Report from: {new Date().toLocaleDateString()}</p>
      </span>
      <div className="report_image">
        <ImageHeader />
      </div>
    </div>
  );
};

const getNumberFormat = (props) => {
  let type = LocalStorage.get(tableObject, tableConfig, tableConfigTypes.unitDisplayReportFormat);

  if (props && props.isDashboards) {
    type = global.Modeliks.DashboardSettings.Value.financialDates.numberFormatType;
  }

  return `(${global.Modeliks.CompanyInfo.Currency.value}${type === "fullNumbers" ? "" : ` in ${type}`})`;

  return `(in ${type === "fullNumbers" ? global.Modeliks.CompanyInfo.Currency.value : type})`;
};

const ExportPDFComponent = (props) => {
  return (
    <React.Fragment>
      <Header />
      <Table {...props} />
    </React.Fragment>
  );
};

export default ExportPDFComponent;

const getRowStyle = (UnitType, header, isCashFlow = false) => {
  if (isCashFlow && header === "Net Income") {
    return " ";
  }

  if (isCashFlow && header === "Cash Balance") {
    return "boldBorderTop";
  }

  if (UnitType === "percentage") {
    return "italic";
  }
  if (boldHeaders.includes(header)) {
    return "boldBorderBottom";
  }

  if (normalBoldHeaders.includes(header)) {
    return "normalBold";
  }
  if (BoldBackgroundHeaders.includes(header)) {
    return "boldAndGray";
  }
  if (BoldBorderTop.includes(header)) {
    return "boldBorderTop";
  }

  return " ";
};

const Table = (props) => {
  const { ReportName, rows, splitArr, pdfSuffix, field, useActual } = props;

  const validHeaders = ["Forecast", "Previous Period", "Previous Year"];
  let valueField = "Actual";
  if (field && field === "Forecast") {
    valueField = "Value";
  }
  const isCashFlow = ReportName === "Cash Flow";

  const GetTRows = (row = null, period, isComparative = false) => {
    const comparativeActualClassName = isComparative ? "comparative_actuals" : "";
    let comaprativeEnd = {
      ["Previous Period"]: null,
      ["Previous Year"]: null,
      ["Forecast"]: null,
    };

    if (isComparative) {
      const [forecast, previousPeriod, previousYear] = period.reduce(
        ([forecast, prevPeriod, prevYear], e) =>
          e.Order >= 14 && e.Order <= 16
            ? [[...forecast, e], prevPeriod, prevYear]
            : e.Order >= 17 && e.Order <= 19
              ? [forecast, [...prevPeriod, e], prevYear]
              : e.Order >= 20 && e.Order <= 22
                ? [forecast, prevPeriod, [...prevYear, e]]
                : [forecast, prevPeriod, prevYear],
        [[], [], []],
      );
      comaprativeEnd["Forecast"] = forecast.length;
      comaprativeEnd["Previous Period"] = previousPeriod.length;
      comaprativeEnd["Previous Year"] = previousYear.length;
    }

    const getRow = (row, level = 1, padding = 10) => {
      const getChildren = () => {
        if (row.children && row.children.length > 0) {
          const setLevel = (row) => {
            if (isCashFlow && NoIndent.includes(row.header)) {
              return 0;
            } else {
              return level + 1;
            }
          };
          return row.children.map((c) => getRow(c, setLevel(c)));
        }
      };
      const rowClassName = getRowStyle(row.data.UnitType, row.header, isCashFlow);

      const endIndexes = {
        ["Previous Period"]: null,
        ["Previous Year"]: null,
        ["Forecast"]: null,
      };

      const getEmptyRow = () => {
        return (
          <tr key={row.dateID} className={`s_e_ft_tb_tr ${comparativeActualClassName}`}>
            <td
              className="s_e_ft_tb_td "
              style={{ paddingLeft: `${padding * level}px`, width: "14%" }}
            ></td>
            {period.map((d, index) => {
              const Value = row.data.getItemByDateSufix(d.sufix);
              if (Object.keys(comaprativeEnd).includes(d.Header)) {
                endIndexes[d.Header] = index + comaprativeEnd[d.Header] - 1;
              }
              let className = "";

              if (Object.keys(endIndexes).find((key) => endIndexes[key] === index)) {
                className = "border_right ";
              }

              return (
                <td
                  key={`first-empty-row-` + index}
                  style={{ height: "12px" }}
                  className={`s_e_ft_tb_td   ${className} ${d.Header === "Actual" ? "actual_td" : ""}`}
                >
                  <div className="mx_numinput mx_numinput_input"></div>
                </td>
              );
            })}
          </tr>
        );
      };

      return (
        <>
          {insertEmptyRowBefore.includes(row.header) && getEmptyRow()}
          <tr
            key={row.dateID}
            className={`s_e_ft_tb_tr ${rowClassName} ${comparativeActualClassName}`}
          >
            <td
              className="s_e_ft_tb_td "
              style={{
                width: "18%",
                paddingLeft: `${padding * level}px`,
              }}
            >
              {row.header}
            </td>
            {period.map((d, index) => {
              const Value = row.data.getItemByDateSufix(d.sufix);
              const unit = Value.UnitParsed;
              if (Object.keys(comaprativeEnd).includes(d.Header)) {
                endIndexes[d.Header] = index + comaprativeEnd[d.Header] - 1;
              }
              let className = "";

              if (Object.keys(endIndexes).find((key) => endIndexes[key] === index)) {
                className = "border_right ";
              }

              if (
                d.Header.includes("%") ||
                d.Header.includes(global.Modeliks.CompanyInfo.Currency.value)
              ) {
                if (!isNaN(Value[valueField])) {
                  if (Value[valueField] > 0) {
                    if (row.colorType.positiveGreen) {
                      className += "color_success";
                    } else {
                      className += "color_warning";
                    }
                  }
                  if (Value[valueField] < 0) {
                    if (row.colorType.positiveGreen) {
                      className += "color_warning";
                    } else {
                      className += "color_success";
                    }
                  }
                }
              }
              return (
                <td
                  key={`second-empty-row-` + index}
                  className={`s_e_ft_tb_td ${className} ${d.Header === "Actual" ? "actual_td" : ""}`}
                >
                  <Mx_NumInput
                    data={Value[valueField]}
                    unit={unit}
                    field={"Actual"}
                    allowNaNValue={d.Header.includes("%")}
                    IsReport={!useActual}
                    displayOfDecimals={true}
                    unitDisplay={true}
                    hideUnit={true}
                  />
                </td>
              );
            })}
          </tr>
          {getChildren()}
        </>
      );
    };

    if (row) {
      return row.map((r) => getRow(r, 0));
    }
    return rows.map((row) => getRow(row, 0));
  };
  const getComparative = (periods) => {
    const [forecast, previousPeriod, previousYear] = periods.reduce(
      ([forecast, prevPeriod, prevYear], e) =>
        e.Order >= 14 && e.Order <= 16
          ? [[...forecast, e], prevPeriod, prevYear]
          : e.Order >= 17 && e.Order <= 19
            ? [forecast, [...prevPeriod, e], prevYear]
            : e.Order >= 20 && e.Order <= 22
              ? [forecast, prevPeriod, [...prevYear, e]]
              : [forecast, prevPeriod, prevYear],
      [[], [], []],
    );

    return (
      <>
        <th className="s_e_ft_th actual" rowSpan={3}>
          Actual
        </th>
        {forecast.length > 0 && (
          <th className="s_e_ft_th" colSpan={forecast.length}>
            {validHeaders[0]}
          </th>
        )}
        {previousPeriod.length > 0 && (
          <th className="s_e_ft_th" colSpan={previousPeriod.length}>
            {validHeaders[1]}
          </th>
        )}
        {previousYear.length > 0 && (
          <th className="s_e_ft_th" colSpan={previousYear.length}>
            {validHeaders[2]}
          </th>
        )}
      </>
    );
  };

  const getComparativeSecondRow = (periods) => {
    return (
      <thead className="s_e_ft_thead comparative">
        <tr>
          <th className="s_e_ft_th comparative">
            <p>{getNumberFormat(props)}</p>
          </th>
          {periods.map((header, index) => {
            if (index !== 0) {
              return (
                <th key={`second-comparative-row` + index} className={"s_e_ft_th"}>
                  {validHeaders.includes(header.Header)
                    ? global.Modeliks.CompanyInfo.Currency.value
                    : header.Header}
                </th>
              );
            }
            return (
              <th key={`second-comparative-row` + index} className={"s_e_ft_th border_apply"}></th>
            );
          })}
        </tr>
      </thead>
    );
  };
  const getHeader = (period) => {
    if (pdfSuffix && pdfSuffix.includes("variance analysis")) {
      return (
        <>
          <thead className="s_e_ft_thead comparative">
            <tr>
              <th className="s_e_ft_th" style={{ width: "18%" }}>
                <p>{ReportName}</p>
              </th>
              {getComparative(period)}
            </tr>
          </thead>
          {getComparativeSecondRow(period)}
        </>
      );
    }
    return (
      <thead className="s_e_ft_thead">
        <tr>
          <th className="s_e_ft_th" style={{ width: "18%" }}>
            <p>{ReportName}</p>
            <p>{getNumberFormat()}</p>
          </th>
          {period.map((row) => (
            <th key={row.dateID} className="s_e_ft_th ">
              {row.Header}
            </th>
          ))}
        </tr>
      </thead>
    );
  };

  const TableContainer = (props) => {
    const { period, row } = props;
    return (
      <div className="table_container report_pdf_container">
        <div className="s_e_financial_table_container">
          <table className="s_e_table">
            {getHeader(period)}
            <tbody className="s_e_ft_tbody">
              {GetTRows(row, period, pdfSuffix && pdfSuffix.includes("variance analysis"))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  if (props.useSplit) {
    return splitArr().map((period) => {
      return props.rows.map((r) => <TableContainer period={period} row={r} />);
    });
  }

  return splitArr().map((periodInterval) => <TableContainer period={periodInterval} />);
};
