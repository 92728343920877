import React from "react";
import "../components/Icons/slideIcons.scss";
import ContextMenu from "../components/menus/newContextMenu/ContextMenu";
import SCContextMenuPortal from "../components/menus/newContextMenu/SCContextMenuPortal";
import { Copy, Cut, Exit, Paste } from "../components/IconButtons/SubMenuIcons";
import RotateSubMenu from "../components/menus/newContextMenu/RotateSubMenu";
import CenterOnPageSubMenu from "../components/menus/newContextMenu/CenterOnPageSubMenu";
import HeaderDefault from "../SlideHeader/HeaderDefault";
import OrderSubMenu from "../components/menus/newContextMenu/OrderSubMenu";
import ButtonJ from "../components/Buttons/Button";
import {
  Icon1,
  Icon2,
  Icon3,
  Icon4,
  Icon5,
  Icon6,
  Icon7,
  Icon8,
  Icon9,
  Icon10,
  Icon11,
  Icon12,
  Icon13,
  Icon14,
  Icon15,
  Icon16,
  Icon17,
  Icon18,
  Icon19,
  Icon20,
  Icon21,
  Icon22,
  Icon23,
  Icon24,
  Icon25,
  Icon26,
  Icon27,
  Icon28,
  Icon29,
  Icon30,
  Icon31,
  Icon32,
  Icon33,
  Icon34,
  Icon35,
  Icon36,
  Icon37,
  Icon38,
  Icon39,
  Icon40,
  Icon41,
  Icon42,
  Icon43,
  Icon44,
  Icon45,
  Icon46,
  Icon47,
  Icon48,
  Icon49,
  Icon50,
  Icon51,
  Icon52,
  Icon53,
  Icon54,
  Icon55,
  Icon56,
  Icon57,
  Icon58,
  Icon59,
  Icon60,
  Icon61,
  Icon62,
  Icon63,
  Icon64,
  Icon65,
  Icon66,
  Icon67,
  Icon68,
  Icon69,
  Icon70,
  Icon71,
  Icon72,
  Icon73,
  Icon74,
  Icon75,
  Icon76,
  Icon77,
  Icon78,
  Icon79,
  Icon80,
  Icon81,
  Icon82,
  Icon83,
  Icon84,
  Icon85,
  Icon86,
  Icon87,
  Icon88,
  Icon89,
  Icon90,
  Icon91,
  Icon92,
  Icon93,
  Icon94,
  Icon95,
  Icon96,
  Icon97,
  Icon98,
  Icon99,
  Icon100,
  Icon101,
  Icon102,
  Icon103,
  Icon104,
  Icon105,
  Icon106,
  Icon107,
  Icon108,
  Icon109,
  Icon110,
  Icon111,
  Icon112,
  Icon113,
  Icon114,
  Icon115,
  Icon116,
  Icon117,
  Icon118,
  Icon119,
  Icon120,
  Icon121,
  Icon122,
  Icon123,
  Icon124,
  Icon125,
  Icon126,
  Icon127,
  Icon128,
  Icon129,
  Icon130,
  Icon131,
  Icon132,
  Icon133,
  Icon134,
  Icon135,
  Icon136,
  Icon137,
  Icon138,
  Icon139,
  Icon140,
  Icon141,
  Icon142,
  Icon143,
  Icon144,
  Icon145,
  Icon146,
  Icon147,
  Icon148,
  Icon149,
  Icon150,
} from "../components/Icons/Icons";
import { MenuItem, SubMenu } from "@szhsin/react-menu";
import HeaderPortal from "../SlideHeader/HeaderPortal";
import FillColorEditComponent from "../SlideHeader/EditorComponents/FillColorEditComponent";
import InsertIconsMenu from "../components/menus/newTopMenu/InsertIconsMenu";
import SizeAndRotation from "../components/RightMenuComponents/SizeAndRotationComponent";
import Position from "../components/RightMenuComponents/PositionComponent";
import ListSubheader from "@mui/material/ListSubheader";
import { IconButton } from "@mui/material";
import List from "@mui/material/List";
import RightMenuPortal from "../components/Portals/RightMenuPortal.js";
import HeaderMidPortal from "../components/Portals/HeaderMidPortal";
import ButtonMui from "../../../components/buttons/buttonMui/buttonMui";

const icons = [
  Icon1,
  Icon2,
  Icon3,
  Icon4,
  Icon5,
  Icon6,
  Icon7,
  Icon8,
  Icon9,
  Icon10,
  Icon11,
  Icon12,
  Icon13,
  Icon14,
  Icon15,
  Icon16,
  Icon17,
  Icon18,
  Icon19,
  Icon20,
  Icon21,
  Icon22,
  Icon23,
  Icon24,
  Icon25,
  Icon26,
  Icon27,
  Icon28,
  Icon29,
  Icon30,
  Icon31,
  Icon32,
  Icon33,
  Icon34,
  Icon35,
  Icon36,
  Icon37,
  Icon38,
  Icon39,
  Icon40,
  Icon41,
  Icon42,
  Icon43,
  Icon44,
  Icon45,
  Icon46,
  Icon47,
  Icon48,
  Icon49,
  Icon50,
  Icon51,
  Icon52,
  Icon53,
  Icon54,
  Icon55,
  Icon56,
  Icon57,
  Icon58,
  Icon59,
  Icon60,
  Icon61,
  Icon62,
  Icon63,
  Icon64,
  Icon65,
  Icon66,
  Icon67,
  Icon68,
  Icon69,
  Icon70,
  Icon71,
  Icon72,
  Icon73,
  Icon74,
  Icon75,
  Icon76,
  Icon77,
  Icon78,
  Icon79,
  Icon80,
  Icon81,
  Icon82,
  Icon83,
  Icon84,
  Icon85,
  Icon86,
  Icon87,
  Icon88,
  Icon89,
  Icon90,
  Icon91,
  Icon92,
  Icon93,
  Icon94,
  Icon95,
  Icon96,
  Icon97,
  Icon98,
  Icon99,
  Icon100,
  Icon101,
  Icon102,
  Icon103,
  Icon104,
  Icon105,
  Icon106,
  Icon107,
  Icon108,
  Icon109,
  Icon110,
  Icon111,
  Icon112,
  Icon113,
  Icon114,
  Icon115,
  Icon116,
  Icon117,
  Icon118,
  Icon119,
  Icon120,
  Icon121,
  Icon122,
  Icon123,
  Icon124,
  Icon125,
  Icon126,
  Icon127,
  Icon128,
  Icon129,
  Icon130,
  Icon131,
  Icon132,
  Icon133,
  Icon134,
  Icon135,
  Icon136,
  Icon137,
  Icon138,
  Icon139,
  Icon140,
  Icon141,
  Icon142,
  Icon143,
  Icon144,
  Icon145,
  Icon146,
  Icon147,
  Icon148,
  Icon149,
  Icon150,
];

class IconObject extends React.Component {
  constructor(props) {
    super(props);

    this.rightMenuFunc = {};

    this.state = {
      anchorPoint: { x: 0, y: 0 },
      menuProps: undefined,
      value: this.props.value,
      color: props.backgroundColor ? props.backgroundColor : "",
    };

    global.pasteListener = this.handlePaste;
  }

  RGBAToHexA = (r, g, b, a) => {
    r = r.toString(16);
    g = g.toString(16);
    b = b.toString(16);
    a = Math.round(a * 255).toString(16);

    if (r.length == 1) r = "0" + r;
    if (g.length == 1) g = "0" + g;
    if (b.length == 1) b = "0" + b;
    if (a.length == 1) a = "0" + a;

    return "#" + r + g + b + a;
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.multipleSeleced != this.props.multipleSeleced) {
      if (nextProps.multipleSeleced) {
        global.pasteListener = this.handlePaste;
      }
    }

    if (nextProps.selected != this.props.selected) {
      if (nextProps.selected) {
        global.pasteListener = this.handlePaste;
      }
    }

    return (
      nextProps.selected !== this.props.selected ||
      nextState.value !== this.state.value ||
      nextProps.formatOptions !== this.props.formatOptions
    );
  }

  handlePaste = (event) => {
    event.preventDefault();
    this.props.slideComponentPaste(event, true);
  };

  handleContextMenu = (e) => {
    if (!this.props.disableEdit && !this.props.moreThanOneSelected) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({
        menuProps: true,
        anchorPoint: { x: e.pageX, y: e.pageY },
      });
      this.props.onSelect();
      this.forceUpdate();
    }
  };
  handleContextMenuClose = () => {
    this.setState({ anchorPoint: { x: 0, y: 0 }, menuProps: undefined });
    this.forceUpdate();
  };

  handleFillColorChange = (event) => {
    let rgba = event.backgroundColor.toString().slice(5).slice(0, -1).split(",");
    this.state.color = this.RGBAToHexA(
      Number(rgba[0]),
      Number(rgba[1]),
      Number(rgba[2]),
      Number(rgba[3]),
    );
    this.forceUpdate();
    this.props.onPropsChange({
      backgroundColor: this.RGBAToHexA(
        Number(rgba[0]),
        Number(rgba[1]),
        Number(rgba[2]),
        Number(rgba[3]),
      ),
    });
  };

  render() {
    return (
      <>
        {this.props.selected && !this.props.multipleSelected && (
          <HeaderMidPortal>
            <div className="sub_header_vertical_separator" />
            <div
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
            >
              <FillColorEditComponent
                onChange={(e) => this.handleFillColorChange(e)}
                style={{ backgroundColor: this.state.color }}
              />
            </div>
            <div className="sub_header_vertical_separator" />
            <InsertIconsMenu
              onReplaceIcon={(index) => {
                this.props.onPropsChange({ value: index });
                this.setState({ value: index });
              }}
            />
            <div className="sub_header_vertical_separator" />
            <ButtonMui
              tooltipText={"Format options"}
              onClick={this.props.toggleFormatOptions}
              variant={"text"}
              roundnessType={"squared"}
              color={"black"}
              label={"Format options"}
            />
          </HeaderMidPortal>
        )}
        {this.state.menuProps && (
          <SCContextMenuPortal>
            <ContextMenu
              handleClose={() => this.handleContextMenuClose()}
              anchorPoint={this.state.anchorPoint}
              menuProps={this.state.menuProps}
              onDelete={this.props.onDelete}
            >
              <MenuItem className="cm_icon_helper" onClick={this.props.cutObjects}>
                <Cut className="cm_icon" />
                <span className="cm_btn_name">Cut</span>
                <span className="cm_helper_text">Ctrl+X</span>
              </MenuItem>
              <MenuItem className="cm_icon_helper" onClick={this.props.copyObjects}>
                <Copy className="cm_icon" />
                <span className="cm_btn_name">Copy</span>
                <span className="cm_helper_text">Ctrl+C</span>
              </MenuItem>
              <MenuItem className="cm_icon_helper" onClick={this.props.handleDuplicateObject}>
                <Paste className="cm_icon" />
                <span className="cm_btn_name">Duplicate</span>
                <span className="cm_helper_text">Ctrl+D</span>
              </MenuItem>
              <MenuItem onClick={this.props.onDelete}>
                <span className="cm_btn_name">Delete</span>
              </MenuItem>
              <div className="cm_divider" />
              <OrderSubMenu
                sentToFront={this.props.sentToFront}
                sentToBack={this.props.sentToBack}
                bringForword={this.props.bringForword}
                bringBackward={this.props.bringBackward}
              />
              <CenterOnPageSubMenu
                {...this.props}
                horizontally={() =>
                  this.props.updatePosition(640 - this.props.width / 2, this.props.top)
                }
                vertically={() =>
                  this.props.updatePosition(this.props.left, 360 - this.props.height / 2)
                }
              />
              <RotateSubMenu
                height={this.props.height}
                width={this.props.width}
                rotateAngle={this.props.rotateAngle}
                scaleX={this.props.scaleX}
                scaleY={this.props.scaleY}
                onChange={this.props.onChange}
              />
              <div className="cm_divider" />
              <MenuItem onClick={this.props.toggleFormatOptions}>
                <span className={"cm_btn_name"}>Format options</span>
              </MenuItem>
            </ContextMenu>
          </SCContextMenuPortal>
        )}
        {this.props.formatOptions && this.props.selected && (
          <RightMenuPortal>
            <div className={"right_menu_mask"} onMouseDown={(e) => e.stopPropagation()}>
              <List
                style={{
                  paddingLeft: "0",
                  position: "absolute",
                  width: "100%",
                  height: "auto",
                  maxWidth: 250,
                  bgcolor: "background.paper",
                  paddingBottom: "0",
                  paddingTop: "0",
                  // overflow:'scroll',
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 700,
                          fontSize: "16px",
                          textAlign: "left",
                        }}
                      >
                        Format Options
                      </span>
                      <IconButton onClick={this.props.toggleFormatOptions}>
                        <Exit />
                      </IconButton>
                    </div>
                  </ListSubheader>
                }
              >
                <SizeAndRotation
                  changeLockedAspect={this.props.changeLockedAspect}
                  rightMenuFunc={this.rightMenuFunc}
                  lockedAspect={this.props.lockedAspect}
                  onChange={this.props.onUpdateSizeAndRotation}
                  style={this.props.style}
                  width={this.props.width}
                  height={this.props.height}
                  rotateAngle={this.props.rotateAngle}
                  scaleX={this.props.scaleX}
                  scaleY={this.props.scaleY}
                />
                <Position
                  onChange={this.props.onUpdatePosition}
                  rightMenuFunc={this.rightMenuFunc}
                  style={this.props.style}
                  top={this.props.top}
                  left={this.props.left}
                />
              </List>
            </div>
          </RightMenuPortal>
        )}
        {this.state.value === 0 && (
          <Icon1
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 1 && (
          <Icon2
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 2 && (
          <Icon3
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 3 && (
          <Icon4
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 4 && (
          <Icon5
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 5 && (
          <Icon6
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 6 && (
          <Icon7
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 7 && (
          <Icon8
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 8 && (
          <Icon9
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 9 && (
          <Icon10
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 10 && (
          <Icon11
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 11 && (
          <Icon12
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 12 && (
          <Icon13
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 13 && (
          <Icon14
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 14 && (
          <Icon15
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 15 && (
          <Icon16
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 16 && (
          <Icon17
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 17 && (
          <Icon18
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 18 && (
          <Icon19
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 19 && (
          <Icon20
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 20 && (
          <Icon21
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 21 && (
          <Icon22
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 22 && (
          <Icon23
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 23 && (
          <Icon24
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 24 && (
          <Icon25
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 25 && (
          <Icon26
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 26 && (
          <Icon27
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 27 && (
          <Icon28
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 28 && (
          <Icon29
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 29 && (
          <Icon30
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 30 && (
          <Icon31
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 31 && (
          <Icon32
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 32 && (
          <Icon33
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 33 && (
          <Icon34
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 34 && (
          <Icon35
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 35 && (
          <Icon36
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 36 && (
          <Icon37
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 37 && (
          <Icon38
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 38 && (
          <Icon39
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 39 && (
          <Icon40
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 40 && (
          <Icon41
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 41 && (
          <Icon42
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 42 && (
          <Icon43
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 43 && (
          <Icon44
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 44 && (
          <Icon45
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 45 && (
          <Icon46
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 46 && (
          <Icon47
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 47 && (
          <Icon48
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 48 && (
          <Icon49
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 49 && (
          <Icon50
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 50 && (
          <Icon51
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 51 && (
          <Icon52
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 52 && (
          <Icon53
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 53 && (
          <Icon54
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 54 && (
          <Icon55
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 55 && (
          <Icon56
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 56 && (
          <Icon57
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 57 && (
          <Icon58
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 58 && (
          <Icon59
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 59 && (
          <Icon60
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 60 && (
          <Icon61
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 61 && (
          <Icon62
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 62 && (
          <Icon63
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 63 && (
          <Icon64
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 64 && (
          <Icon65
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 65 && (
          <Icon66
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 66 && (
          <Icon67
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 67 && (
          <Icon68
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 68 && (
          <Icon69
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 69 && (
          <Icon70
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 70 && (
          <Icon71
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 71 && (
          <Icon72
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 72 && (
          <Icon73
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 73 && (
          <Icon74
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 74 && (
          <Icon75
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 75 && (
          <Icon76
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 76 && (
          <Icon77
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 77 && (
          <Icon78
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 78 && (
          <Icon79
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 79 && (
          <Icon80
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 80 && (
          <Icon81
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 81 && (
          <Icon82
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 82 && (
          <Icon83
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 83 && (
          <Icon84
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 84 && (
          <Icon85
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 85 && (
          <Icon86
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 86 && (
          <Icon87
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 87 && (
          <Icon88
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 88 && (
          <Icon89
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 89 && (
          <Icon90
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 90 && (
          <Icon91
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 91 && (
          <Icon92
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 92 && (
          <Icon93
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 93 && (
          <Icon94
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 94 && (
          <Icon95
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 95 && (
          <Icon96
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 96 && (
          <Icon97
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 97 && (
          <Icon98
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 98 && (
          <Icon99
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 99 && (
          <Icon100
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 100 && (
          <Icon101
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 101 && (
          <Icon102
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 102 && (
          <Icon103
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 103 && (
          <Icon104
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 104 && (
          <Icon105
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 105 && (
          <Icon106
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 106 && (
          <Icon107
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 107 && (
          <Icon108
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 108 && (
          <Icon109
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 109 && (
          <Icon110
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 110 && (
          <Icon111
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 111 && (
          <Icon112
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 112 && (
          <Icon113
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 113 && (
          <Icon114
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 114 && (
          <Icon115
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 115 && (
          <Icon116
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 116 && (
          <Icon117
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 117 && (
          <Icon118
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 118 && (
          <Icon119
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 119 && (
          <Icon120
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 120 && (
          <Icon121
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 121 && (
          <Icon122
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 122 && (
          <Icon123
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 123 && (
          <Icon124
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 124 && (
          <Icon125
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 125 && (
          <Icon126
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 126 && (
          <Icon127
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 127 && (
          <Icon128
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 128 && (
          <Icon129
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 129 && (
          <Icon130
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 130 && (
          <Icon131
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 131 && (
          <Icon132
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 132 && (
          <Icon133
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 133 && (
          <Icon134
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 134 && (
          <Icon135
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 135 && (
          <Icon136
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 136 && (
          <Icon137
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 137 && (
          <Icon138
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 138 && (
          <Icon139
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 139 && (
          <Icon140
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 140 && (
          <Icon141
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 141 && (
          <Icon142
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 142 && (
          <Icon143
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 143 && (
          <Icon144
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 144 && (
          <Icon145
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 145 && (
          <Icon146
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 146 && (
          <Icon147
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 147 && (
          <Icon148
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 148 && (
          <Icon149
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
        {this.state.value === 149 && (
          <Icon150
            color={this.state.color}
            onContextMenu={(e) => this.handleContextMenu(e)}
            className={"slide_icon"}
          />
        )}
      </>
    );
  }
}

export default IconObject;
