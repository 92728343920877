import React, { Fragment, useState } from "react";
import "../HeaderTextEditor.scss";
import "../HeaderDefault.scss";
import { BorderColor, Redo } from "../../components/IconButtons/SubMenuIcons";
import { SketchPicker } from "react-color";
import Tooltip from "@mui/material/Tooltip";
import { Menu, MenuButton, ControlledMenu, MenuItem, SubMenu } from "@szhsin/react-menu";
import MostUsedColors from "../../components/ColorPicker/MostUsedColors";
import ButtonMui from "../../../../components/buttons/buttonMui/buttonMui";

function BorderColorEditComponent(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openBorderColor = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onChange = (style) => {
    props.onChange(style);
  };
  const rgbToRgba = (event) => {
    return "rgba(" + event.rgb.r + "," + event.rgb.g + "," + event.rgb.b + "," + event.rgb.a + ")";
  };
  const handleColor = (color) => {
    onChange({ borderColor: color });
    global.Modeliks.addColor(color);
  };
  const BorderColorEdit = () => {
    return (
      <Menu
        className={"color_menu"}
        menuButton={({ open }) => (
          <ButtonMui
            tooltipText={"Border Color"}
            variant={"text"}
            isIconButton
            roundnessType={"squared"}
            color={"black"}
            label={
              <BorderColor
                active={open}
                activeColor={
                  !props.style || !props.style.borderColor || props.style.borderColor === undefined
                    ? "#585858"
                    : props.style.borderColor
                }
              />
            }
          />
        )}
        onMouseMove={(e) => {
          e.stopPropagation();
        }}
      >
        <SketchPicker
          color={
            !props.style || !props.style.borderColor || props.style.borderColor === undefined
              ? "black"
              : props.style.borderColor
          }
          onChange={(event) => handleColor(rgbToRgba(event))}
        />
        <div>
          <MostUsedColors onClick={handleColor} />
        </div>
      </Menu>
    );
  };

  return <>{BorderColorEdit()}</>;
}

export default BorderColorEditComponent;
