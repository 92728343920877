import React, { Fragment, useState } from "react";
import { Menu, MenuItem, SubMenu, MenuButton } from "@szhsin/react-menu";
import Tooltip from "@mui/material/Tooltip";
import { Icons, ArrowDownSubMenu } from "../../IconButtons/SubMenuIcons";
import {
  Icon1,
  Icon2,
  Icon3,
  Icon4,
  Icon5,
  Icon6,
  Icon7,
  Icon8,
  Icon9,
  Icon10,
  Icon11,
  Icon12,
  Icon13,
  Icon14,
  Icon15,
  Icon16,
  Icon17,
  Icon18,
  Icon19,
  Icon20,
  Icon21,
  Icon22,
  Icon23,
  Icon24,
  Icon25,
  Icon26,
  Icon27,
  Icon28,
  Icon29,
  Icon30,
  Icon31,
  Icon32,
  Icon33,
  Icon34,
  Icon35,
  Icon36,
  Icon37,
  Icon38,
  Icon39,
  Icon40,
  Icon41,
  Icon42,
  Icon43,
  Icon44,
  Icon45,
  Icon46,
  Icon47,
  Icon48,
  Icon49,
  Icon50,
  Icon51,
  Icon52,
  Icon53,
  Icon54,
  Icon55,
  Icon56,
  Icon57,
  Icon58,
  Icon59,
  Icon60,
  Icon61,
  Icon62,
  Icon63,
  Icon64,
  Icon65,
  Icon66,
  Icon67,
  Icon68,
  Icon69,
  Icon70,
  Icon71,
  Icon72,
  Icon73,
  Icon74,
  Icon75,
  Icon76,
  Icon77,
  Icon78,
  Icon79,
  Icon80,
  Icon81,
  Icon82,
  Icon83,
  Icon84,
  Icon85,
  Icon86,
  Icon87,
  Icon88,
  Icon89,
  Icon90,
  Icon91,
  Icon92,
  Icon93,
  Icon94,
  Icon95,
  Icon96,
  Icon97,
  Icon98,
  Icon99,
  Icon100,
  Icon101,
  Icon102,
  Icon103,
  Icon104,
  Icon105,
  Icon106,
  Icon107,
  Icon108,
  Icon109,
  Icon110,
  Icon111,
  Icon112,
  Icon113,
  Icon114,
  Icon115,
  Icon116,
  Icon117,
  Icon118,
  Icon119,
  Icon120,
  Icon121,
  Icon122,
  Icon123,
  Icon124,
  Icon125,
  Icon126,
  Icon127,
  Icon128,
  Icon129,
  Icon130,
  Icon131,
  Icon132,
  Icon133,
  Icon134,
  Icon135,
  Icon136,
  Icon137,
  Icon138,
  Icon139,
  Icon140,
  Icon141,
  Icon142,
  Icon143,
  Icon144,
  Icon145,
  Icon146,
  Icon147,
  Icon148,
  Icon149,
  Icon150,
} from "../../Icons/Icons";
import "./HeaderMenu.scss";
import { SlideObject, SlideTypes } from "../../../SlideObjects/SlideStructures";
import ButtonMui from "../../../../../components/buttons/buttonMui/buttonMui";

function InsertIconsMenu(props) {
  const icons = [
    Icon1,
    Icon2,
    Icon3,
    Icon4,
    Icon5,
    Icon6,
    Icon7,
    Icon8,
    Icon9,
    Icon10,
    Icon11,
    Icon12,
    Icon13,
    Icon14,
    Icon15,
    Icon16,
    Icon17,
    Icon18,
    Icon19,
    Icon20,
    Icon21,
    Icon22,
    Icon23,
    Icon24,
    Icon25,
    Icon26,
    Icon27,
    Icon28,
    Icon29,
    Icon30,
    Icon31,
    Icon32,
    Icon33,
    Icon34,
    Icon35,
    Icon36,
    Icon37,
    Icon38,
    Icon39,
    Icon40,
    Icon41,
    Icon42,
    Icon43,
    Icon44,
    Icon45,
    Icon46,
    Icon47,
    Icon48,
    Icon49,
    Icon50,
    Icon51,
    Icon52,
    Icon53,
    Icon54,
    Icon55,
    Icon56,
    Icon57,
    Icon58,
    Icon59,
    Icon60,
    Icon61,
    Icon62,
    Icon63,
    Icon64,
    Icon65,
    Icon66,
    Icon67,
    Icon68,
    Icon69,
    Icon70,
    Icon71,
    Icon72,
    Icon73,
    Icon74,
    Icon75,
    Icon76,
    Icon77,
    Icon78,
    Icon79,
    Icon80,
    Icon81,
    Icon82,
    Icon83,
    Icon84,
    Icon85,
    Icon86,
    Icon87,
    Icon88,
    Icon89,
    Icon90,
    Icon91,
    Icon92,
    Icon93,
    Icon94,
    Icon95,
    Icon96,
    Icon97,
    Icon98,
    Icon99,
    Icon100,
    Icon101,
    Icon102,
    Icon103,
    Icon104,
    Icon105,
    Icon106,
    Icon107,
    Icon108,
    Icon109,
    Icon110,
    Icon111,
    Icon112,
    Icon113,
    Icon114,
    Icon115,
    Icon116,
    Icon117,
    Icon118,
    Icon119,
    Icon120,
    Icon121,
    Icon122,
    Icon123,
    Icon124,
    Icon125,
    Icon126,
    Icon127,
    Icon128,
    Icon129,
    Icon130,
    Icon131,
    Icon132,
    Icon133,
    Icon134,
    Icon135,
    Icon136,
    Icon137,
    Icon138,
    Icon139,
    Icon140,
    Icon141,
    Icon142,
    Icon143,
    Icon144,
    Icon145,
    Icon146,
    Icon147,
    Icon148,
    Icon149,
    Icon150,
  ];

  const onNewObject = (index, e) => {
    if (props.newIcon) {
      props.setInsertMode(
        SlideTypes.IconObject,
        (top, left) => {
          const nObj = new SlideObject(SlideTypes.IconObject, index, {
            width: 100,
            height: 100,
            top: top,
            left: left,
          });
          props.onNewObject(nObj);
        },
        e,
      );
    } else {
      props.onReplaceIcon(index);
    }
  };

  const MenuItems = () => {
    return (
      <>
        <SubMenu
          label={
            <>
              <Icon1 />
              <span className="item_name">SaaS</span>
            </>
          }
        >
          {icons.map(
            (icon, index) =>
              index < 12 && (
                <MenuItem onClick={(e) => onNewObject(index, e)} key={"icn_" + index}>
                  {icon}
                </MenuItem>
              ),
          )}
        </SubMenu>
        <SubMenu
          label={
            <>
              <Icon13 />
              <span className="item_name">E Commerce</span>
            </>
          }
        >
          {icons.map(
            (icon, index) =>
              index >= 12 &&
              index < 42 && (
                <MenuItem onClick={(e) => onNewObject(index, e)} key={"icn_" + index}>
                  {icon}
                </MenuItem>
              ),
          )}
        </SubMenu>
        <SubMenu
          label={
            <>
              <Icon43 />
              <span className="item_name">Services</span>
            </>
          }
        >
          {icons.map(
            (icon, index) =>
              index >= 42 &&
              index < 72 && (
                <MenuItem onClick={(e) => onNewObject(index, e)} key={"icn_" + index}>
                  {icon}
                </MenuItem>
              ),
          )}
        </SubMenu>
        <SubMenu
          label={
            <>
              <Icon73 />
              <span className="item_name">Retail</span>
            </>
          }
        >
          {icons.map(
            (icon, index) =>
              index >= 72 &&
              index < 90 && (
                <MenuItem onClick={(e) => onNewObject(index, e)} key={"icn_" + index}>
                  {icon}
                </MenuItem>
              ),
          )}
        </SubMenu>
        <SubMenu
          label={
            <>
              <Icon91 />
              <span className="item_name">Restaurant</span>
            </>
          }
        >
          {icons.map(
            (icon, index) =>
              index >= 90 &&
              index < 104 && (
                <MenuItem onClick={(e) => onNewObject(index, e)} key={"icn_" + index}>
                  {icon}
                </MenuItem>
              ),
          )}
        </SubMenu>
        <SubMenu
          label={
            <>
              <Icon105 />
              <span className="item_name">Devices</span>
            </>
          }
        >
          {icons.map(
            (icon, index) =>
              index >= 104 &&
              index < 116 && (
                <MenuItem onClick={(e) => onNewObject(index, e)} key={"icn_" + index}>
                  {icon}
                </MenuItem>
              ),
          )}
        </SubMenu>
        <SubMenu
          label={
            <>
              <Icon117 />
              <span className="item_name">Buildings</span>
            </>
          }
        >
          {icons.map(
            (icon, index) =>
              index >= 116 &&
              index < 126 && (
                <MenuItem onClick={(e) => onNewObject(index, e)} key={"icn_" + index}>
                  {icon}
                </MenuItem>
              ),
          )}
        </SubMenu>
        <SubMenu
          label={
            <>
              <Icon127 />
              <span className="item_name">Support</span>
            </>
          }
        >
          {icons.map(
            (icon, index) =>
              index >= 126 &&
              index < 138 && (
                <MenuItem onClick={(e) => onNewObject(index, e)} key={"icn_" + index}>
                  {icon}
                </MenuItem>
              ),
          )}
        </SubMenu>
        <SubMenu
          label={
            <>
              <Icon139 />
              <span className="item_name">Time</span>
            </>
          }
        >
          {icons.map(
            (icon, index) =>
              index >= 138 && (
                <MenuItem onClick={(e) => onNewObject(index, e)} key={"icn_" + index}>
                  {icon}
                </MenuItem>
              ),
          )}
        </SubMenu>
      </>
    );
  };

  //<Tooltip disableHoverListener={props.disableEdit} arrow title={'Insert Icon'}><span></span></Tooltip>

  const IconInsert = () => {
    return (
      <>
        {props.newIcon ? (
          <SubMenu
            className="header_menu arrow"
            label={({ open }) => (
              <span>
                <Icons active={open} />
              </span>
            )}
          >
            <MenuItems />
          </SubMenu>
        ) : (
          <Menu
            className="header_menu arrow"
            menuButton={({ open }) => (
              <ButtonMui
                style={open ? { color: "#01AFFD" } : {}}
                tooltipText={"Replace Icon"}
                endIcon={<ArrowDownSubMenu active={open} />}
                variant={"text"}
                disabled={props.disableEdit}
                roundnessType={"squared"}
                color={"black"}
                label={"Replace icon"}
              />
            )}
          >
            <MenuItems />
          </Menu>
        )}
      </>
    );
  };
  return <>{IconInsert()}</>;
}
export default InsertIconsMenu;
