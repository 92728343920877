import httpService from "../services/api/httpService";

export const GetHelper = {
	getAccounts: () => new Promise((async resolve => {
		let data = await httpService().get(
			"/api/users_accounts"
		)
		resolve(data)
	})),
	getCreditsInfo: () => new Promise((async resolve => {
		let data = await httpService().get(
			"/api/user_credits_info"
		)
		resolve(data)
	})),
	getReferralInfo: () => new Promise((async resolve => {
		let data = await httpService().get(
			"/api/referral_info"
		)
		resolve(data)
	}))
};

export const UpdateHelper = {
	setCreditedAccount: (data, callBack) =>
		global.Modeliks.post(
			"/api/changeCreditedAccount",
			data,
			callBack,
			(err) => callBack(null, err),
			true,
		), //data needed: UserID - ID, AccountID - ID, CreditedAccount - ID
};
