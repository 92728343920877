import { RevenueTypes, maxRows } from "../../../components/constants/finance";
import { DriverPeriodTypes } from "../CalculatedDriver/constants";
import Revenue from "./index";

const RevenueDataGridRows = (RevenueStorage) => {
  const revTotals = Revenue.getRevenueTotals();
  RevenueStorage.dataGridRows = {
    revenueTotals: revTotals,
    revenueTotalsGrowth: Revenue.getRevenueGrowth(revTotals),
    gridRows: [],
    totalsRows: [],
    totalRows: 0,
    maxRows,
  };
  let totalRows = RevenueStorage.dataGridRows.totalRows;

  const get_subscription_drivers = (revenue) => {
    let arr = [];
    revenue.getChildDrivers().forEach((driver) => {
      if (driver && driver.Ref_Table !== "Finance_CalculatedDrivers") {
        if (!driver.IsSimple) {
          driver.getChildDrivers().forEach((dr) => {
            arr.push(revenue_only_create(dr, true, 1));
          });
        }
      } else {
        arr.push(revenue_only_create(driver, true, 1));
      }
    });

    return arr;
  };
  const sub_row_single_SubscriptionPlans = (sub, isMulti = false) => {
    const subChildrenRows = [
      {
        id: "qty_" + sub.Quantity.ID,
        unit: null,
        header: "Signups",
        hasEdit: false,
        isEditable: true,
        onEditClick: () => {},
        field: "Quantity",
        data: sub.Quantity,
      },
      {
        id: "cr_" + sub.ChurnRate.ID,
        header: "Churn Rate",
        hasEdit: false,
        unit: "%",
        isEditable: true,
        onEditClick: () => {},
        field: "ChurnRate",
        data: sub.ChurnRate,
      },
      {
        id: "cc_" + sub.ChurnRate.ID,
        header: "Churned Customers",
        unit: null,
        isEditable: true,
        // onEditClick: () => this.handleOnEditRevenueChildren(sub.ChurnedCustomers.Ref_ID, 1),
        field: "ChurnedCustomers",
        data: sub.ChurnedCustomers,
      },
      {
        id: "rf_" + sub.Refund.ID,
        header: "Refund",
        hasEdit: false,
        unit: "%",
        isEditable: true,
        onEditClick: () => {},
        field: "Refund",
        data: sub.Refund,
        children: !sub.Refund.IsSimple ? get_subscription_drivers(sub.Refund) : [],
      },
      {
        id: "sp_" + sub.SinglePrice.ID,
        header: "Subscription Price",
        hasEdit: false,
        unit: global.Modeliks.CompanyInfo.Currency.value,
        isEditable: true,
        onEditClick: () => {},
        field: "SinglePrice",
        data: sub.SinglePrice,
      },
      {
        id: "cs_" + sub.CustomersAtStart.ID,
        header: "Customers at Start",
        unit: global.Modeliks.CompanyInfo.Currency.value,
        isEditable: true,
        hasEdit: false,
        // onEditClick: () => this.handleOnEditRevenueChildren(sub.CustomersAtStart.Ref_ID, 3),
        field: "CustomersAtStart",
        data: sub.CustomersAtStart,
      },
      {
        id: "caeop_" + sub.CustomersAtEndOfPeriod.ID,
        unit: null,
        header: "Customers at End of Period",
        isEditable: false,
        hasEdit: false,
        field: "calc_CustomersAtEndOfPeriod",
        data: sub.CustomersAtEndOfPeriod,
      },
      {
        id: "cdpc_" + sub.TotalCustomersDueForPayment.ID,
        unit: global.Modeliks.CompanyInfo.Currency.value,
        header: `Customers Due For Payment`,
        isEditable: false,
        field: "calc_TotalCustomersDueForPayment",
        data: sub.TotalCustomersDueForPayment,
      },
      {
        id: "cc_" + sub.CashCollection.ID,
        unit: global.Modeliks.CompanyInfo.Currency.value,
        header: `Cash Collections`,
        hasEdit: false,
        isEditable: false,
        field: "calc_CashCollection",
        data: sub.CashCollection,
      },
    ];
    totalRows = totalRows + subChildrenRows.length;
    return subChildrenRows;
  };
  const row_single_Subscriptions = (revenue, isChild = false, isMulti = false) => {
    const row = {
      id: revenue.ID,
      unit: global.Modeliks.CompanyInfo.Currency.value,
      header: revenue.RevenueName,
      revenue: revenue,
      hasEdit: !isChild,
      isEditable: false,
      boldRowHeader: !isChild,
      onEditClick: !isChild && RevenueStorage.dataGridRows.handleOnEditRevenue,
      onDeleteClick: RevenueStorage.dataGridRows.handleOnDeleteRevenue,
      field: "total",
      data: revenue.Totals,
      children: [],
    };
    totalRows = totalRows + 1;
    if (totalRows < maxRows) {
      if (revenue.hasOwnProperty("children") && revenue.children.length > 0) {
        revenue.children.forEach((el) => {
          row.children.push(row_single_Subscriptions(el, true, isMulti));
        });
        row.children.push({
          id: revenue.Totals.ID,
          unit: global.Modeliks.CompanyInfo.Currency.value,
          header: "Totals",
          data: revenue.Totals,
          children: sub_row_single_SubscriptionPlans(revenue, true),
        });
      } else {
        row.children = sub_row_single_SubscriptionPlans(revenue, isMulti);
      }
    }

    return row;
  };
  const revenue_only_create = (revenue, isChild = false, level = 0, stopRecursive = false) => {
    let row = {};
    if (revenue) {
      if (level) {
        row = {
          id: revenue.ID,
          header: revenue.Name,
          revenue: revenue,
          onEditClick: (revenue, row) =>
            RevenueStorage.dataGridRows.handleOnEditRevenue(revenue.ID, row),
          onEditChildren: RevenueStorage.dataGridRows.handleOnEditRevenueChildren,
          onDeleteClick: RevenueStorage.dataGridRows.handleOnDeleteRevenue,
          hasEdit: false,
          changeDriverNameEnabled:
            revenue.Ref_Table === global.Modeliks.Tables.Finance_Revenues.TableName &&
            revenue.getParentStream() &&
            revenue.getParentStream().RevenueType !== RevenueTypes.Subscriptions,
          data: revenue,
          children: [],
        };
        totalRows = totalRows + 1;
        if (revenue.getChildDrivers() && !stopRecursive && totalRows < maxRows) {
          let children = revenue.getChildDrivers();
          if (children && children.length > 0) {
            children.map((driver) => {
              if (totalRows < maxRows) {
                row.children.push(
                  revenue_only_create(
                    driver,
                    true,
                    level + 1,
                    revenue.PeriodType === DriverPeriodTypes.Previous ||
                      driver.DriverName === "Revenue",
                  ),
                );
              }
            });
          }
        }
      } else {
        if (revenue.hasOwnProperty("Totals")) {
          row = {
            id: revenue.Totals.ID,
            header: revenue.Totals ? revenue.Totals.Name : revenue.Name,
            revenue: revenue.Totals ? revenue.Totals : revenue,
            onEditClick: (revenue, row) =>
              RevenueStorage.dataGridRows.handleOnEditRevenue(revenue.ID, row),
            hasEdit: true,
            onDeleteClick: RevenueStorage.dataGridRows.handleOnDeleteRevenue,
            data: revenue.Totals ? revenue.Totals : revenue,
            isExpanded: true,
            boldRowHeader: true,
            children: [],
          };
          totalRows = totalRows + 1;
          let children = revenue.Totals.getChildDrivers();
          if (children && children.length > 0) {
            children.map((driver) => {
              if (totalRows < maxRows) {
                row.children.push(revenue_only_create(driver, true, level + 1));
              }
            });
          }
        }
      }
      return row;
    } else {
      return null;
    }
  };
  const row_create = (revenue) => {
    if (revenue.RevenueType === RevenueTypes.Subscriptions) {
      return row_single_Subscriptions(revenue);
    } else {
      return revenue_only_create(revenue);
    }
  };

  const row_totals = (totals = revTotals) => {
    return {
      id: totals.ID,
      unit: global.Modeliks.CompanyInfo.Currency.value,
      header: "Total Revenue",
      cssClass: "totals",
      boldRowHeader: true,
      data: totals,
    };
  };
  const createGridRows = () => {
    RevenueStorage.dataGridRows.gridRows = RevenueStorage.map((revenue) =>
      row_create(revenue),
    ).filter((c) => c != null);
    RevenueStorage.dataGridRows.totalsRows = [row_totals()];
    RevenueStorage.dataGridRows.totalRows = totalRows;
  };

  createGridRows();
};

export default RevenueDataGridRows;
