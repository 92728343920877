import CostSale from "./index";
import Personnel from "../Personnel";
import { DriverPeriodTypes } from "../CalculatedDriver/constants";
import Expense from "../Expense";
import { ExpenseTypes } from "../constants";
import { maxRows } from "../../../components/constants/finance";

const ExpensesCategoryName = {
  ResearchAndDevelopment: "Research & Development",
  GeneralAndAdministrative: "General & Administrative",
  SalesAndMarketing: "Sales & Marketing",
  DirectCost: "Direct Cost",
};

const ExpensesDataGridRows = (Storage) => {
  const expensesTotals = Expense.getExpenseTotals();
  const expense = [
    Expense.getExpensesDirectCostTotals(),
    Expense.getResearchAndDevelopmentTotals(),
    Expense.getSalesAndMarketingTotals(),
    Expense.getGeneralAndAdministrativeTotals(),
  ];

  Storage.dataGridRows = {
    streamTotals: expensesTotals,
    streamTotalsGrowth: Expense.getTotalsGrowth(expensesTotals),
    expensesDirectCostTotals: Expense.getExpensesDirectCostTotals(),
    gridRows: [],
    totalsRows: [],
    expense,
    maxRows,
    totalRows: 0,
  };
  let totalRows = Storage.dataGridRows.totalRows;
  const getExpenseCategoryName = (name) => {
    switch (name) {
      case ExpenseTypes.ResearchAndDevelopment:
        return ExpensesCategoryName.ResearchAndDevelopment;
      case ExpenseTypes.GeneralAndAdministrative:
        return ExpensesCategoryName.GeneralAndAdministrative;
      case ExpenseTypes.SalesAndMarketing:
        return ExpensesCategoryName.SalesAndMarketing;
      case ExpenseTypes.DirectCost:
        return ExpensesCategoryName.DirectCost;
      default:
        return name;
    }
  };

  const row_create = (expense) => {
    return revenue_only_create(expense);
  };
  const revenue_only_create = (expense, parentRevenue, level = 0, stopRecursive = false) => {
    let row = {};

    if (expense) {
      totalRows = totalRows + 1;
      if (
        level === 0 &&
        (expense.getChildDrivers() === null || expense.getChildDrivers().length === 0)
      ) {
        return null;
      }
      if (level > 1) {
        row = {
          id: expense.ID,
          header: expense.Name,
          revenue: expense,
          hasEdit: false,
          onEditClick: () => {},
          onDeleteClick: () => {},
          changeDriverNameEnabled:
            expense.Ref_Table === global.Modeliks.Tables.Finance_Expenses.TableName,
          data: expense,
          children: [],
        };
      } else if (level === 1) {
        row = {
          id: expense.ID,
          header: getExpenseCategoryName(expense.DriverName),
          revenue: expense,
          onEditClick: () => {},
          hasEdit: getExpenseCategoryName(expense.DriverName) === expense.DriverName,
          // onDeleteClick: this.handleOnDeleteExpense,
          data: expense,
          children: [],
        };
      } else {
        row = {
          id: expense.ID,
          header: expense.Name,
          revenue: expense,
          hasEdit: false,
          isExpanded: true,
          boldRowHeader: true,
          // onDeleteClick: this.handleOnDeleteAsset,
          data: expense,
          children: [],
        };
      }
      let children = expense.getChildDrivers();
      if (
        children &&
        expense.Ref_Table !== global.Modeliks.Tables.Finance_Revenues.TableName &&
        !stopRecursive
      ) {
        children.map((driver) => {
          if (totalRows <= maxRows) {
            row.children.push(
              revenue_only_create(
                driver,
                expense,
                level + 1,
                expense.PeriodType === DriverPeriodTypes.Previous,
              ),
            );
          }
        });
      }
      return row;
    } else {
      return null;
    }
  };

  const FinanceExpensePersonnelParent = (revenue, isExpense = true) => {
    return revenue_totals_create({
      id: revenue.ID,
      unit: global.Modeliks.CompanyInfo.Currency.value,
      header: isExpense ? "Other Direct Expenses" : "Direct Labor",
      data: revenue,
      boldRowHeader: true,
      revenue: revenue,
      children: [],
    });
  };

  const revenue_totals_create = (
    revenue,
    isChild = false,
    level = 0,
    stopRecursive = false,
    hasEdit = false,
  ) => {
    let row = {};
    if (revenue) {
      totalRows = totalRows + 1;
      if (isChild) {
        row = {
          id: revenue.ID,
          header: revenue.Name,
          data: revenue,
          children: [],
        };
        if (revenue.getChildDrivers() && !stopRecursive) {
          let children = revenue.getChildDrivers();
          level++;
          if (children && revenue.Ref_Field !== Personnel.DriversDesc.EmployeeBenefits.driverID) {
            children.map((driver) => {
              if (
                driver.Ref_Table !== global.Modeliks.Tables.Finance_Revenues.TableName ||
                driver.Ref_ID === 0
              ) {
                if (totalRows <= maxRows) {
                  row.children.push(
                    revenue_totals_create(
                      driver,
                      true,
                      level++,
                      driver.PeriodType === DriverPeriodTypes.Previous,
                    ),
                  );
                }
              }
            });
          }
        }
        if (hasEdit) {
          (row.onEditClick = () => {}), (row.hasEdit = true);
        }
      } else {
        row = revenue;
        let children = revenue.data.getChildDrivers();
        if (children) {
          level++;
          children.map((driver) => {
            if (totalRows <= maxRows) {
              row.children.push(
                revenue_totals_create(
                  driver,
                  true,
                  level,
                  driver.PeriodType === DriverPeriodTypes.Previous,
                  true,
                ),
              );
            }
          });
        }
      }
      return row;
    } else {
      return null;
    }
  };

  const row_totals = (totals = expensesTotals) => {
    totalRows = totalRows + 1;
    return {
      id: totals.ID,
      unit: global.Modeliks.CompanyInfo.Currency.value,
      header: "Total Expenses",
      cssClass: "totals",
      boldRowHeader: true,
      data: totals,
    };
  };

  const createGridRows = () => {
    const expenses = Storage.dataGridRows.expensesDirectCostTotals;
    Storage.dataGridRows.gridRows = expense
      .map((expense) => row_create(expense))
      .filter((c) => c != null);
    Storage.dataGridRows.totalsRows = [row_totals()];
    if (expenses && expenses.Formula && expenses.Formula.length > 3) {
      global.Modeliks.CostSaleStore.dataGridRows.gridRows.push(
        FinanceExpensePersonnelParent(expenses, true),
      );
    }
    Storage.dataGridRows.totalRows = totalRows;
  };

  createGridRows();
};

export default ExpensesDataGridRows;
