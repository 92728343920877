import React from "react";
import AuthPage from "../components/AuthPage";

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
  }

  componentDidMount() {}

  render() {
    return <>{<AuthPage type="register-appsumo" showAppSumoLogo />}</>;
  }
}

export default Register;
