import CircleLoader from '../../components/Loaders/CircleLoader/CircleLoader';
import * as React from "react";
import {Routes, Route, Outlet, Link, Navigate} from "react-router-dom";
import {MainContainer} from "../../components/containers/MainContainer";
import Menu from "../../components/containers/Menu";
import {Box} from "../../components/containers/Box";
import {
	Logo,
	QuestionIcon, StarsIcon,
} from "../../components/icons/svgIcons";
import TabsM from '../../components/tabs/Tabs'
import AvatarMenu from "../../components/menus/AvatarMenu/AvatarMenu";
import SettingsActiveIcon from "../../components/icons/SettingsActiveIcon";
import Invite from "../Public/Auth/Invite/Invite";
import {FinancialStatementsTypes} from "../../data/Finance/Dashboards/constants";
import Verification from "../Public/Auth/Email/Verification";
import BusinessPlanPublish from '../../BusinessPlan/User/publish';
import SlideEditor from "../../SlideEditor/User";
import NewPassword from "../Public/Auth/Reset/NewPassword";
import {ReusableMenu, ReusableMenuItem} from "../../components/menus/ReusableMenu/ReusableMenu";
import ButtonMui from "../../components/buttons/buttonMui/buttonMui";
import {Exit} from "../../SlideEditor/components/components/IconButtons/SubMenuIcons";
import Mx_MainLoader from "../../components/Loaders/MainLoader/Mx_MainLoader";
import {toggleChatBot} from "../../helpers/utils/chat_bot";
import {createNewAccount} from "../../helpers/accountCreation";

const ProfileSettings = React.lazy(() => import("../Secure/ProfileSettings/index"));

export const defaultSelection = {
	url: {
		financials: '/forecast/revenue',
		valuation: '/valuation/settings',
		dashboards: '/dashboards/financial_statements'
	},
	selectedReport: {
		actuals: 'ProfitAndLoss',
		financial_statements: 'ProfitAndLoss',
		reports: 'ProfitAndLoss',
	},
	reportView: {
		financial_statements: FinancialStatementsTypes.Comparative,
		custom_kpi: FinancialStatementsTypes.Comparative,
	}
}

class SecureApp extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			ctrl_key: (new Date()).getTime(),
			loading: false
		}
		this.modeliks_path_elements = {
			error_routes: [

			],
			helper_routes: [
				{path: `/newPassword/:guid/:id`, element: <NewPassword key={'NewPassword_' + this.state.ctrl_key}/>,},
				{path: `/verification/:guid`, element: <Verification key={'verification_' + this.state.ctrl_key}/>,},
				{path: `/invite/:invite_guid/:user_id`, element: <Invite key={'invite_guid_' + this.state.ctrl_key}/>,},
				{path: `*`, element: <Navigate to="/" key={'navigate_' + this.state.ctrl_key}/>,},
				// {
				// 	path: `/manage_subscription_upgrade`,
				// 	element: <ManageSubscription upgrade_plan key={'invite_guid_' + this.state.ctrl_key}/>,
				// 	UserPermissions: true,
				// },
			],
			routes: [
				// {
				// 	path: '/CheckoutSuccess/:transaction_id',
				// 	element: <SuccessPayment/>,
				// 	UserPermissions: false,
				// },
				{path: "/publish/:account_guid/:client_guid", element: <SlideEditor publishMode={true}/>},
				{
					path: "/publish_business_plan/:account_guid/:client_guid",
					element: <BusinessPlanPublish publishMode={true}/>
				},
				{
					path: `/profile/*`,
					element: <React.Suspense fallback={<>...</>}><ProfileTab key={'m_profile_' + this.state.ctrl_key}/></React.Suspense>,
					UserPermissions: false,
				},
				{
					path: `/`,
					element: <Navigate to={'/profile/details'}/>,
					UserPermissions: false,
					exact: true
				},
			],
			tabs: [
			]
		};
		this.renderModeliksRoutes = this.modeliks_path_elements.routes.map((route) => <Route
			key={`f_route_${route.path}`} exact path={route.path} element={route.element}/>);
		this.renderModeliksHelperRutes = this.modeliks_path_elements.helper_routes.map((route) => <Route
			key={`f_route_${route.path}`} exact path={route.path} element={route.element}/>);
		this.renderModeliksErrorRutes = this.modeliks_path_elements.error_routes.map((route) => <Route
			key={`f_2route_${route.path}`} exact path={route.path} element={route.element}/>);


	}

	toggleGlobalLoader = (data = !this.state.loading, loaderText = '') => {
		// debugger
		if (!data) {
			loaderText = undefined;
		}
		if (this.state.loading != (!!data)) {
			global.Modeliks.isLoading = (!!data);
			const objToSave = {}
			if (this.state.loading) {
				Object.assign(objToSave, {ctrl_key: new Date().getTime()})
			}
			this.setState({loading: (!!data), ...objToSave, loaderText})
		}
	}

	componentDidMount() {
		global.Modeliks.toggleGlobalLoader = this.toggleGlobalLoader;
	}


	changeKey() {
		this.setState({ctrl_key: new Date().getTime()})
	}

	render = () => {
		return (
			<>
				{this.state.loading && <Mx_MainLoader message={this.state.loaderText}/>}
				<Routes>
					<Route path="/" element={<Layout defaultUrl={defaultSelection.url}
													 modeliks_elements={this.modeliks_path_elements}
													 key={'layout_' + this.state.ctrl_key}/>}>
						{this.renderModeliksRoutes}
					</Route>
					{this.renderModeliksHelperRutes}
					{this.renderModeliksErrorRutes}
				</Routes>
			</>
		);
	}
}

export default SecureApp;



let menuItems = null;

const buildMenuItems = () => {
	global.Modeliks.toggleChatBot = toggleChatBot;

	menuItems = [];
	window.HubSpotConversations && menuItems.push(new ReusableMenuItem('Chat Support', toggleChatBot));
	if (global.HelpHero) {
		menuItems.push(new ReusableMenuItem('Guided Tours', () => {
			HelpHeroHelpers.enableHelpHero();
			global.HelpHero.openChecklist();
		}));
	}
	menuItems.push(new ReusableMenuItem('Help Center', () => window.open('https://help.modeliks.com/', '_blank')));
	menuItems.push(new ReusableMenuItem('Roadmap', () => window.open('https://roadmap.modeliks.com/', '_blank')));
}


function Layout(props) {
	const [loadingCompany, setLoadingCompany] = React.useState(false);

	if (!loadingCompany && props.modeliks_elements) {
		if (menuItems == null) {
			buildMenuItems();
		}

		return (
			<>
				<MainContainer id='MainContainer'>
					<Menu>
						<Box className={'box1'} display='flex' gap='25px' alignItems='center'>
							<Logo className='Logo' width={'50px'} height={'50px'}/>
						</Box>
						<Box display='flex' gap='40px' alignitems='center'>
							<div className="upgrade_plan">
								<span>Your user account is not associated to an account</span>
								<ButtonMui label={'Upgrade'} color={'secondary'} startIcon={<StarsIcon/>}
													 onClick={createNewAccount} roundnessType={'squared'} variant={'contained'}/>
							</div>
						</Box>
						<Box display='flex' gap='15px' alignitems='center'>
							<ReusableMenu
								menuButton={
									<ButtonMui style={{height: '42px'}} tooltipText='Help' variant='text'
														 color={'black'} label={<QuestionIcon/>} isIconButton/>
								}
								menuItems={menuItems}/>
							{/*<ButtonMui style={{height: '42px'}} linkTo={'/profile/details'} tooltipText='Settings'*/}
							{/*		   variant='text' color={'black'} label={<SettingsActiveIcon/>} isIconButton/>*/}
							<AvatarMenu only_profile/>
						</Box>
					</Menu>
					<Outlet/>
				</MainContainer>
				<div onClick={toggleChatBot} id={'chat_bot_area'}>
					<ButtonMui onClick={toggleChatBot} variant={'contained'} isFloatingButton={true} label={<Exit/>}/>
				</div>
				<div onClick={toggleChatBot} id={'chat_bot_area'} className={'high'}/>
			</>
		);
	} else {
		return <div className={'main_circle_loader_container'}>
			<CircleLoader/>
		</div>
	}
}


function ProfileTab() {
	return (
		<ProfileSettings only_profile/>
	);
}