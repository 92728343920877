import { React, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const MaterialSelect = (props) => {
  const options = props.options;
  const [value, setValue] = useState(props.value ? props.value : null);

  const handleChange = (e) => {
    setValue(e.target.value);
    props.onChange(e);
  };

  return (
    <Select
      style={{
        maxHeight: "27px",
        minWidth: props.minWidth,
        color: "black",
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "160%",
        fontStyle: "normal",
        marginLeft: 6,
        marginRight: 6,
        borderLeft: `1px solid #F0F1F7`,
        zIndeX: 5,
      }}
      value={value}
      onChange={handleChange}
      autoWidth
      sx={{
        width: 100,
        height: 40,
        zIndex: 100,
        marginRight: 15,

        border: "1px solid #F0F1F7",
        color: "black",
        "& fieldset.MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
          borderColor: "none",
          borderWidth: 0,
          zIndex: 100,
          // borderBottom: "1px solid blue",
        },
        "& .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
          {
            // background: "black",
            zIndex: 100,
          },
      }}
      MenuProps={{
        PaperProps: {
          sx: {
            marginTop: -2.15,
            paddingX: `15px`,
            width: "full",
            marginLeft: ``,
            // bgcolor: 'black',
            boxShadow: "none",
            borderRadius: "0",
            borderLeft: `1px solid #F0F1F7`,
            borderRight: `1px solid #F0F1F7`,
            borderBottom: `1px solid #F0F1F7`,
            borderBottomLeftRadius: "8px",
            borderBottomRightRadius: "8px",
            zIndex: 0,
            // boxSizing: `border-box`,
            // borderRadius: `8px`,
            "& .MuiMenuItem-root": {
              paddingleft: 0,
              marginTop: 0,
              zIndex: 0,
            },
            "& .css-vuo6ct-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper": {
              paddingleft: 0,
              marginTop: 0,
              zIndex: 0,
            },
          },
        },
      }}
    >
      {options.length > 0 &&
        options.map((row) => {
          return (
            <MenuItem
              style={{
                zIndex: 10,
                color: "black",
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "160%",
                fontStyle: "normal",
              }}
              value={row.value}
            >
              {row.name}
            </MenuItem>
          );
        })}
    </Select>
  );
};
export default MaterialSelect;
