import React, { Component } from "react";
import RestrictedAccessImg from "./restricted_access.png";
import "./restrictedAccess.scss";

const HelpHeroStyleID = "helphero_style";

export const HelpHeroHelpers = {
  disableHelpHero: () => {
    const head = document.head || document.getElementsByTagName("head")[0];
    if (head) {
      const style = document.createElement("style");
      style.id = HelpHeroStyleID;
      const css = "#helphero-dom {display: none !important;}";
      style.appendChild(document.createTextNode(css));
      head.append(style);
    }
  },
  enableHelpHero: () => {
    const head = document.head || document.getElementsByTagName("head")[0];
    if (head) {
      const style = document.getElementById(HelpHeroStyleID);
      if (style) {
        head.removeChild(style);
      }
    }
  },
};

class RestrictedAccess extends Component {
  componentDidMount() {
    HelpHeroHelpers.disableHelpHero();
  }

  componentWillUnmount() {
    HelpHeroHelpers.enableHelpHero();
  }

  render() {
    return (
      <div className={"restricted_access_page"}>
        <div className={"r_a_container"}>
          <img src={RestrictedAccessImg} alt={"restricted access image"} />
        </div>
        <div className={"r_a_container"}>
          <p className={"title"}>Access Restricted</p>
        </div>
        <div className={"r_a_container ordinary_text"}>
          <p>
            We’re sorry, but you don't have access granted to this module/feature. Please contact
            the account owner or the user who set your permissions for assistance.
          </p>
        </div>
      </div>
    );
  }
}

export default RestrictedAccess;
