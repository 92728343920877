import React from "react";
import "./lineObject.scss";
import HeaderLineEditor from "../SlideHeader/HeaderLineEditor";

export default class ElbowConnectorObject extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      style: props.style ? props.style : {},
    };
    global.pasteListener = this.handlePaste;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.multipleSeleced != this.props.multipleSeleced) {
      if (nextProps.multipleSeleced) {
        global.pasteListener = this.handlePaste;
      }
    }

    if (nextProps.selected != this.props.selected) {
      if (nextProps.selected) {
        global.pasteListener = this.handlePaste;
      }
    }

    return true;
  }

  handlePaste = (event) => {
    event.preventDefault();
    this.props.slideComponentPaste(event, true);
  };

  handleColorChange = (event) => {
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          style: {
            ...prevState.style,
            borderColor: event.target.value,
          },
        };
      },
      () => this.props.onPropsChange({ style: this.state.style }),
    );
  };

  handleLineType = (event) => {
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          style: {
            ...prevState.style,
            borderTop: event.target.value,
          },
        };
      },
      () => this.props.onPropsChange({ style: this.state.style }),
    );
  };
  handleMinusLineWidth = () => {
    if (Number(this.state.style.borderWidth.slice(0, -2)) > 0) {
      this.setState(
        (prevState) => {
          return {
            ...prevState,
            style: {
              ...prevState.style,
              borderWidth: Number(prevState.style.borderWidth.slice(0, -2)) - 1 + "px",
            },
          };
        },
        () => this.props.onPropsChange({ style: this.state.style }),
      );
    }
  };
  handlePlusLineWidth = () => {
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          style: {
            ...prevState.style,
            borderWidth: Number(prevState.style.borderWidth.slice(0, -2)) + 1 + "px",
            height: Number(prevState.style.height.slice(0, -2)) + "px",
          },
        };
      },
      () => this.props.onPropsChange({ style: this.state.style }),
    );
  };

  onStyleChange = (newStyle) => {
    let curStyle = { ...this.state.style, ...newStyle };
    this.setState({ style: curStyle }, () => this.props.onPropsChange({ style: curStyle }));
  };

  getRotateAngle = (rotateAngle) => {
    if (rotateAngle >= 360) {
      rotateAngle -= 360;
    } else if (rotateAngle < 0) {
      rotateAngle += 360;
    }
    if (rotateAngle > 315 || rotateAngle <= 45) {
      rotateAngle = 0;
    } else if (rotateAngle > 45 && rotateAngle <= 135) {
      rotateAngle = 90;
    } else if (rotateAngle > 135 && rotateAngle <= 225) {
      rotateAngle = 180;
    } else if (rotateAngle > 225 && rotateAngle <= 359) {
      rotateAngle = 270;
    }

    return rotateAngle - this.props.rotateAngle;
  };

  getStyles = (rotateAngle = this.props.rotateAngle) => {
    let style = {
      primaryLine: {
        width: Math.abs(this.props.deltaX),
        top: 0,
        left: 0,
        height: 10,
      },
      connectingLine: {
        width: Math.abs(this.props.deltaY),
        top: Math.abs(this.props.deltaY) / 2,
        left: Math.abs(this.props.deltaX) / 2 - Math.abs(this.props.deltaY) / 2,
        height: 10,
      },
      secondaryLine: {
        width: Math.abs(this.props.deltaX),
        top: Math.abs(this.props.deltaY),
        left: Math.abs(this.props.deltaX) / 2,
        height: 10,
      },
    };

    if (rotateAngle > 315 && rotateAngle <= 359) {
      style.connectingLine.left = Math.abs(this.props.deltaX / 2) - Math.abs(this.props.deltaY / 2);
      style.connectingLine.top = -Math.abs(this.props.deltaY / 2);

      style.secondaryLine.top = -Math.abs(this.props.deltaY);
      style.secondaryLine.left = Math.abs(this.props.deltaX / 2);
    } else if (rotateAngle > 45 && rotateAngle <= 135) {
      style.primaryLine.width = Math.abs(this.props.deltaY);

      style.connectingLine.width = Math.abs(this.props.deltaX);
      style.connectingLine.top = -Math.abs(this.props.deltaX / 2);
      style.connectingLine.left = Math.abs(this.props.deltaY / 2) - Math.abs(this.props.deltaX / 2);

      style.secondaryLine.width = Math.abs(this.props.deltaY);
      style.secondaryLine.top = -Math.abs(this.props.deltaX);
      style.secondaryLine.left = Math.abs(this.props.deltaY / 2);
    } else if (rotateAngle > 135 && rotateAngle <= 180) {
      style.primaryLine.top = 0;

      style.connectingLine.top = -Math.abs(this.props.deltaY / 2);
      style.connectingLine.left = Math.abs(this.props.deltaX / 2) - Math.abs(this.props.deltaY / 2);

      style.secondaryLine.top = -Math.abs(this.props.deltaY);
    } else if (rotateAngle > 180 && rotateAngle <= 225) {
      style.primaryLine.top = 0;

      style.connectingLine.top = Math.abs(this.props.deltaY / 2);
      style.connectingLine.left = Math.abs(this.props.deltaX / 2) - Math.abs(this.props.deltaY / 2);

      style.secondaryLine.top = Math.abs(this.props.deltaY);
    } else if (rotateAngle > 225 && rotateAngle <= 270) {
      style.primaryLine.width = Math.abs(this.props.deltaY);

      style.connectingLine.top = -Math.abs(this.props.deltaX / 2);
      style.connectingLine.width = Math.abs(this.props.deltaX);
      style.connectingLine.left = Math.abs(this.props.deltaY / 2) - Math.abs(this.props.deltaX / 2);

      style.secondaryLine.width = Math.abs(this.props.deltaY);
      style.secondaryLine.left = Math.abs(this.props.deltaY / 2);
      style.secondaryLine.top = -Math.abs(this.props.deltaX);
    } else if (rotateAngle > 270 && rotateAngle <= 315) {
      style.primaryLine.width = Math.abs(this.props.deltaY);

      style.connectingLine.width = Math.abs(this.props.deltaX);
      style.connectingLine.top = Math.abs(this.props.deltaX) / 2;
      style.connectingLine.left = Math.abs(this.props.deltaY) / 2 - Math.abs(this.props.deltaX) / 2;

      style.secondaryLine.width = Math.abs(this.props.deltaY);
      style.secondaryLine.top = Math.abs(this.props.deltaX);
      style.secondaryLine.left = Math.abs(this.props.deltaY / 2);
    } else if (rotateAngle > 90 && rotateAngle <= 135) {
    }
    if (rotateAngle > 90 && rotateAngle <= 135) {
      style.connectingLine.top = Math.abs(this.props.deltaX) / 2;
      style.connectingLine.left = Math.abs(this.props.deltaY) / 2 - Math.abs(this.props.deltaX) / 2;

      style.secondaryLine.top = Math.abs(this.props.deltaX);
    }

    return style;
  };

  render() {
    console.log("style", this.state.style);
    this.styles = this.getStyles();
    this.height = 30;

    this.className = "";
    if (this.props.selected || this.props.multipleSeleced) {
      this.className = " selected";
    }
    return (
      <>
        {this.props.selected && !this.props.multipleSeleced && (
          <div
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
          >
            <HeaderLineEditor
              visible={true}
              handleColorChange={this.handleColorChange}
              handleLineType={this.handleLineType}
              onStyleChange={this.onStyleChange}
              handleMinusLineWidth={this.handleMinusLineWidth}
              handlePlusLineWidth={this.handlePlusLineWidth}
              slideObjKey={this.props.slideObjKey}
              style={this.state.style}
              toggleFormatOptions={this.props.toggleFormatOptions}
              onPreview={this.props.onPreview}
            />
          </div>
        )}

        <div
          onContextMenu={this.props.onContextMenu}
          className={"line_object_container" + this.className}
          style={{
            height: 0,
            width: 0,
            transform: `rotate(${this.getRotateAngle(this.props.rotateAngle)}deg)`,
          }}
        >
          <div
            style={{
              position: "absolute",
              top: this.styles.primaryLine.top - 15,
              display: "flex",
              alignItems: "center",
              left: this.styles.primaryLine.left,
              overflow: "visible",
              width: this.styles.primaryLine.width / 2,
              height: this.height,
            }}
            className={"line_object"}
          >
            <svg
              style={{ overflow: "visible" }}
              width={this.styles.primaryLine.width}
              height={1}
              stroke={this.state.style.borderColor ? this.state.style.borderColor : "#000000"}
              fill={this.state.style.borderColor ? this.state.style.borderColor : "#000000"}
            >
              <defs>
                <marker
                  fill={this.state.style.borderColor ? this.state.style.borderColor : "#000000"}
                  id={this.props.slideObjKey + "arrowhead"}
                  markerWidth="10"
                  markerHeight="7"
                  refX="10"
                  refY="3.5"
                  orient="auto-start-reverse"
                >
                  >
                  <polygon points="0 0, 10 3.5, 0 7" />
                </marker>

                <marker
                  fill={this.state.style.borderColor ? this.state.style.borderColor : "#000000"}
                  id={this.props.slideObjKey + "transparentArrowhead"}
                  markerWidth="10"
                  markerHeight="7"
                  refX="0.5"
                  refY="3.5"
                  orient="auto-start-reverse"
                >
                  >
                  <polygon points="0 0, 10 3.5, 0 7" fill={"transparent"} />
                </marker>

                <marker
                  fill={this.state.style.borderColor ? this.state.style.borderColor : "#000000"}
                  id={this.props.slideObjKey + "circle"}
                  markerWidth="8"
                  markerHeight="8"
                  refX="5"
                  refY="5"
                >
                  <circle cx="5" cy="5" r="2" />
                </marker>

                <marker
                  fill={this.state.style.borderColor ? this.state.style.borderColor : "#000000"}
                  id={this.props.slideObjKey + "transparentCircle"}
                  markerWidth="8"
                  markerHeight="8"
                  refX="5"
                  refY="5"
                >
                  <circle cx="5" cy="5" r="2" fill={"transparent"} />
                </marker>

                <marker
                  fill={this.state.style.borderColor ? this.state.style.borderColor : "#000000"}
                  id={this.props.slideObjKey + "square"}
                  markerWidth="7"
                  markerHeight="7"
                  refX="4"
                  refY="4"
                  orient="auto"
                >
                  <rect x="1.25" y="1.25" width="5" height="5" />
                </marker>

                <marker
                  fill={this.state.style.borderColor ? this.state.style.borderColor : "#000000"}
                  id={this.props.slideObjKey + "transparentSquare"}
                  markerWidth="7"
                  markerHeight="7"
                  refX="4"
                  refY="4"
                  orient="auto"
                >
                  <rect x="1.25" y="1.25" width="5" height="5" fill={"transparent"} />
                </marker>

                <marker
                  fill={this.state.style.borderColor ? this.state.style.borderColor : "#000000"}
                  id={this.props.slideObjKey + "pointer"}
                  markerWidth="10"
                  markerHeight="8"
                  refX="9.5"
                  refY="5.1"
                  orient="-45"
                  markerUnits="userSpaceOnUse"
                >
                  <polyline points="1 1, 9 5, 1 7" />
                </marker>
              </defs>
              <line
                x1="0"
                x2="50%"
                strokeLinecap="butt"
                fill="none"
                strokeWidth={this.state.style.borderWeight ? this.state.style.borderWeight : "3"}
                strokeDasharray={
                  this.state.style.strokeStyle ? this.state.style.strokeStyle : "0 0"
                }
                markerStart={
                  this.state.style.markerStart
                    ? `url(#${this.props.slideObjKey}${this.state.style.markerStart})`
                    : ""
                }
              />
            </svg>
          </div>
          <div
            style={{
              position: "absolute",
              top: this.styles.connectingLine.top - 15,
              left: this.styles.connectingLine.left,
              transform: `rotate(90deg)`,
              overflow: "visible",
              display: "flex",
              alignItems: "center",
              height: this.height,
            }}
            className={"line_object"}
          >
            <svg
              style={{
                overflow: "visible",
              }}
              width={this.styles.connectingLine.width + 2}
              height={1}
              stroke={this.state.style.borderColor ? this.state.style.borderColor : "#000000"}
              fill={this.state.style.borderColor ? this.state.style.borderColor : "#000000"}
            >
              <line
                x1="0"
                x2="100%"
                strokeLinecap="butt"
                fill="none"
                strokeWidth={this.state.style.borderWeight ? this.state.style.borderWeight : "3"}
                strokeDasharray={
                  this.state.style.strokeStyle ? this.state.style.strokeStyle : "0 0"
                }
              />
            </svg>
          </div>
          <div
            style={{
              position: "absolute",
              display: "flex",
              alignItems: "center",
              top: this.styles.secondaryLine.top - 15,
              left: this.styles.secondaryLine.left,
              overflow: "visible",
              height: this.height,
            }}
            className={"line_object"}
          >
            <svg
              style={{
                overflow: "visible",
              }}
              width={this.styles.secondaryLine.width / 2}
              height={1}
              stroke={this.state.style.borderColor ? this.state.style.borderColor : "#000000"}
              fill={this.state.style.borderColor ? this.state.style.borderColor : "#000000"}
            >
              <line
                x1="0"
                x2="100%"
                y1="100%"
                y2="100%"
                strokeLinecap="butt"
                fill="none"
                strokeWidth={this.state.style.borderWeight ? this.state.style.borderWeight : "3"}
                strokeDasharray={
                  this.state.style.strokeStyle ? this.state.style.strokeStyle : "0 0"
                }
                markerEnd={
                  this.state.style.markerEnd
                    ? `url(#${this.props.slideObjKey}${this.state.style.markerEnd})`
                    : ""
                }
              />
            </svg>
          </div>
        </div>
      </>
    );
  }
}
