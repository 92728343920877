import React, { Component } from "react";
import { Chart as ChartJS, LinearScale, PointElement, Tooltip, Legend, Title } from "chart.js";
import { Bubble } from "react-chartjs-2";
import MaterialSelect from "../../../../../../components/actions/SelectM";
import "./bubbleChart.scss";
import {
  ReusableMenu,
  ReusableMenuItem,
} from "../../../../../../components/menus/ReusableMenu/ReusableMenu";
import Button from "@mui/material/Button";
import { ArrowDown } from "../../../../../../components/icons/svgIcons";
import PropTypes from "prop-types";

ChartJS.register(LinearScale, Title, PointElement, Tooltip, Legend);

const Select = (props) => (
  <div className="dropdown_container">
    <div className="dropdown_label">{props.label}</div>
    <MaterialSelect
      width={"200px"}
      value={props.value}
      options={props.options}
      onChange={props.onChange}
    />
  </div>
);

const driverTypes = {
  RevenueGrowth: "Revenue Growth",
  EBITDAMargin: "EBITDA Margin",
  NetProfitMargin: "Net Profit Margin",
};

export const storeTypes = {
  TransactionsMultiplesStore: "Transaction Multiples",
  TradingMultiplesStore: "Trading Multiples",
};

const multiplesTypes = {
  EV_Revenue: "EV / Revenue",
  EV_EBITDA: "EV / EBITDA",
  P_E: "P / E",
};

const getRoundedValue = (value, decimalPoints = 0) => {
  return value.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: decimalPoints,
  });
};

const data = {
  datasets: [
    {
      label: "Red dataset",
      data: [
        {
          x: 90,
          y: 80,
          r: 20,
        },
      ],
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "Blue dataset",
      data: [
        {
          x: 50,
          y: 40,
          r: 15,
        },
      ],
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};

const valueKey = {
  Value: "Value",
  Actual: "Actual",
};

const driverSelectOptions = Object.keys(driverTypes).map((key) => {
  return { description: driverTypes[key], value: key };
});

class BubbleChart extends Component {
  storeKey = Object.keys(storeTypes)[1];
  store = null;
  valueKey = valueKey.Value;
  chartRef = null;

  constructor(props) {
    super(props);

    if (props.storeKey) {
      this.storeKey = props.storeKey;
    }
    this.store = global.Modeliks[this.storeKey];

    this.state = {
      verticalAxis: driverSelectOptions[0].value,
      horizontalAxis: driverSelectOptions[1].value,
      multiplesType: Object.keys(multiplesTypes)[0],
    };
  }

  getOptions = () => {
    return {
      scales: {
        x: {
          grid: {
            display: false,
          },
          title: {
            display: true,
            text: driverTypes[this.state.horizontalAxis],
            align: "end",
          },
        },
        y: {
          grid: {
            display: false,
          },
          title: {
            display: true,
            text: driverTypes[this.state.verticalAxis],
            align: "end",
          },
          beginAtZero: true,
        },
      },
      layout: {
        autoPadding: true,
      },
      plugins: {
        legend: false,
        datalabels: {
          align: "center",
          textAlign: "center",
          color: "#252525",
          font: {
            weight: "700",
            lineHeight: "17px",
          },
        },
      },
    };
  };

  getValue = (company, firstKey, secondKey) => {
    if (company) {
      if (company[firstKey]) {
        if (company[firstKey][secondKey]) {
          return company[firstKey][secondKey];
        }
      }
    }
    return 0;
  };

  multiplesMenuItems = () =>
    Object.keys(multiplesTypes).map((key) => {
      return new ReusableMenuItem(
        multiplesTypes[key],
        () => this.changeAxis("multiplesType", key),
        this.state.multiplesType === key,
      );
    });

  getData = () => {
    return {
      datasets: this.store.map((company) => {
        return {
          label: company.CompanyName,
          data: [
            {
              x: this.getValue(company, this.state.horizontalAxis, this.valueKey),
              y: this.getValue(company, this.state.verticalAxis, this.valueKey),
              r: Math.floor(Math.random() * (80 - 50)) + 50,
            },
          ],
          backgroundColor: "#E1F2F6",
          borderColor: "#01AFFD",
          datalabels: {
            labels: {
              value: {
                align: "start",
                formatter: (value, ctx) => {
                  return getRoundedValue(value.r, 1);
                },
                font: {
                  size: 26,
                },
                offset: function (ctx) {
                  return -28;
                },
              },
              companyName: {
                align: "start",
                formatter: (value, ctx) => {
                  return ctx.dataset.label;
                },
                font: {
                  size: 18,
                },
                offset: function (ctx) {
                  return 0;
                },
              },
            },
          },
        };
      }),
    };
  };

  changeAxis = (axisToChange, value) => {
    this.setState({ [axisToChange]: value }, () => {
      if (this.chartRef) {
        this.chartRef.data = this.getData();
        this.chartRef.update();
      }
    });
  };

  setChartRef = (ref) => {
    this.chartRef = ref;
  };

  render() {
    return (
      <div className="bubble_chart_container">
        <div className="bc_heading">
          <ReusableMenu
            menuButton={
              <Button>
                {`${multiplesTypes[this.state.multiplesType]} ${storeTypes[this.storeKey]}`}
                <ArrowDown />
              </Button>
            }
            menuItems={this.multiplesMenuItems()}
          />
          <div className="bc_h_item">
            <Select
              value={this.state.verticalAxis}
              options={driverSelectOptions}
              onChange={(e) => this.changeAxis("verticalAxis", e.target.value)}
              label={"Vertical Axis"}
            />
            <Select
              value={this.state.horizontalAxis}
              options={driverSelectOptions}
              onChange={(e) => this.changeAxis("horizontalAxis", e.target.value)}
              label={"Horizontal Axis"}
            />
          </div>
        </div>
        <div className="bc_sub_heading"></div>
        <Bubble ref={this.setChartRef} options={this.getOptions()} data={this.getData()} />
      </div>
    );
  }
}

BubbleChart.propTypes = {
  storeKey: PropTypes.string,
};

export default BubbleChart;
