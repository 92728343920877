import CalculatedDriver from "../CalculatedDriver/index";
import { DriverCategories, UnitTypes } from "../CalculatedDriver/constants";
import MxIDHelper from "../../MxIDHelper";
import datastructure from "../../datastructure.json";
import FinanceGeneric from "../FinanceGeneric";
import { RateChange, TaxesTypes } from "./constants";
import Financing from "../Financing";
import { FinanceTermType, FinancingTypes } from "../Financing/constants";
import CostSales from "../CostSales";
import { CostOfSalesGeneral, CostOfSalesTypes } from "../../../components/constants/costofsales";

class Taxes extends FinanceGeneric {
  Name = "";
  ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
  TaxType = null;
  RateChange = RateChange.false;

  constructor(db_record) {
    super(db_record);
    if (this.db_record) {
      this.Name = this.db_record.Name;
    }
  }

  static DriversDesc = Object.assign({
    Total: {
      driverName: "$Name",
      fieldName: "Totals",
      driverID: "total",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
  });

  static TableName = datastructure.Finance_Taxes.TableName;

  SaveNew = (callBack) => {
    global.Modeliks.post(this.constructor.TableName, this, (res) => {
      this.ID = res.id;
      callBack(this);
    });
  };

  Save = (callBack, saveDrivers = true) => {
    if (!this.isNew && !this.IsCreated) {
      global.Modeliks.put(this.constructor.TableName, null, this, (res) => {
        if (saveDrivers) {
          this.SaveDrivers(callBack);
        } else {
          callBack();
        }
      });
    } else {
      global.Modeliks.put(this.constructor.TableName, null, this, (res) => {
        global.Modeliks.DriversStore.createDriversFrom(
          this.ID,
          this.constructor.TableName,
          this.Totals,
          this,
        );
        if (saveDrivers) {
          this.SaveDrivers(callBack);
        } else {
          callBack();
        }
      });
    }
  };

  static getEmptyTax = (callBack) => {
    const cost = Taxes.createNewEmpty();
    cost.SaveNew((record) => {
      return callBack(record);
    });
  };

  static createNewEmpty = () => {
    const tax = new Taxes();
    tax.ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
    tax.TaxType = TaxesTypes.IncomeTax;
    return tax;
  };

  static createNew = (id = MxIDHelper.newID()) => {
    const newTax = new Taxes();
    newTax.ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;

    return newTax;
  };

  createDrivers = (recreate = false) => {
    Object.values(this.constructor.DriversDesc).forEach((driver) => {
      if (this.hasOwnProperty(driver.fieldName) == false || recreate) {
        this[driver.fieldName] = CalculatedDriver.createDriverFromTable(
          this,
          driver.driverID,
          driver.unit,
          driver.category,
        );
      }
    });
  };

  changeDriversName = () => {
    Object.keys(this.constructor.DriversDesc).forEach((key) => {
      const driverDesc = this.constructor.DriversDesc[key];
      if (this[driverDesc.fieldName]) {
        this[driverDesc.fieldName].DriverName = driverDesc.driverName.replace("$Name", this.Name);
      } else {
        console.log("driver not found", this, driverDesc, this[driverDesc.fieldName]);
      }
    });
  };

  static getTaxesTotals = () => {
    const driverID = 0;
    let driver = global.Modeliks.DriversStore.getItem(`${Taxes.TableName}-${driverID}-totals`);
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Taxes.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.Sum,
        "Taxes Totals",
        true,
      );
    }
    driver.setFormula(global.Modeliks.TaxesStore.map((d) => d.Totals).join("+"));

    return driver;
  };

  static getIncomeTaxRateDriver = () => {
    const driverID = "income_tax_rate_driver";
    let driver = global.Modeliks.DriversStore.getItem(`${Taxes.TableName}-${driverID}-totals`);
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Taxes.TableName,
        driverID,
        "totals",
        UnitTypes.PriceNegative,
        DriverCategories.Sum,
        "Income Tax Rate",
        false,
        false,
      );
    }
    const TaxesDriver = global.Modeliks.TaxesStore.find((d) => d.TaxType === TaxesTypes.IncomeTax);
    if (TaxesDriver) {
      driver.setFormula(TaxesDriver.IncomeTaxRate.ID_f);
    }
    return driver;
    // PrincipalPayment
  };

  static getChangeInIncomeTaxPayable = () => {
    const driverID = "change_in_income_tax_payable";
    let driver = global.Modeliks.DriversStore.getItem(`${Taxes.TableName}-${driverID}-totals`);
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Taxes.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.Sum,
        "Change in Income Tax Payable",
        false,
        false,
        false,
      );
      driver.Save();
    }
    driver.IsFormulaEditable = false;
    const changeDriver = global.Modeliks.TaxesStore.find((d) => d.TaxType === TaxesTypes.IncomeTax);
    if (changeDriver && !driver.isFormulasCreated) {
      // driver.Formula = `${changeDriver.IncomeTaxPayableClosingBalance.ID_f} - ${changeDriver.IncomeTaxPayableOpenningBalance.ID_f}`;
      driver.Formula = null;
      const months = [
        ...global.Modeliks.DateHelper.months_before_actual,
        ...global.Modeliks.DateHelper.months,
      ];
      const years = [
        ...global.Modeliks.DateHelper.years_before_actual,
        ...global.Modeliks.DateHelper.years_all,
      ];

      months.forEach((month) => {
        const prevMonthDate = months.find((c) => c.Order == month.Order - 1);
        const curMonthClosing = changeDriver.IncomeTaxPayableClosingBalance.getItemByDateSufix(
          month.sufix,
        );
        const curMonthOpening = changeDriver.IncomeTaxPayableOpenningBalance.getItemByDateSufix(
          month.sufix,
        );
        const curMonth = driver.getItemByDateSufix(month.sufix);

        if (prevMonthDate) {
          curMonth.Formula =
            `${curMonthClosing} - ${curMonthOpening}` +
            ` Actual(${curMonthClosing.ID_f_actual} - ${changeDriver.IncomeTaxPayableClosingBalance.getItemByDateSufix(prevMonthDate.sufix).ID_f_actual})`;
        } else {
          curMonth.Formula = `${curMonthClosing} - ${curMonthOpening}`;
          curMonth.evalFormulaActual = null;
        }
      });

      years.forEach((year) => {
        const curYearClosing = changeDriver.IncomeTaxPayableClosingBalance.getItemByDateSufix(
          year.sufix,
        );
        const curYearOpening = changeDriver.IncomeTaxPayableOpenningBalance.getItemByDateSufix(
          year.sufix,
        );
        const curYear = driver.getItemByDateSufix(year.sufix);
        curYear.Formula = `${curYearClosing} - ${curYearOpening}`;

        if (year.Order !== years[0].Order) {
          const prevYearDate = years.find((c) => c.Order === year.Order - 1);
          curYear.Formula =
            `${curYear.Formula ? curYear.Formula : ""}` +
            `Actual(${curYearClosing.ID_f_actual} - ${changeDriver.IncomeTaxPayableClosingBalance.getItemByDateSufix(prevYearDate.sufix).ID_f_actual})`;
        }
      });

      driver.isFormulasCreated = true;
    }
    return driver;
  };
  static getChangeInSalesTaxPayable = () => {
    const driverID = "change_in_sales_tax_payable";
    let driver = global.Modeliks.DriversStore.getItem(`${Taxes.TableName}-${driverID}-totals`);
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Taxes.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.Sum,
        "Change in Sales Tax Payable",
        false,
        false,
        false,
      );
      driver.Save();
    }
    driver.IsFormulaEditable = false;
    const changeDriver = global.Modeliks.TaxesStore.find((d) => d.TaxType === TaxesTypes.SalesTax);

    if (changeDriver && !driver.isFormulasCreated) {
      // driver.Formula = `${changeDriver.SalesTaxPayableClosingBalance.ID_f} - ${changeDriver.SalesTaxPayableOpenningBalance.ID_f}`;
      driver.Formula = null;

      const months = [
        ...global.Modeliks.DateHelper.months_before_actual,
        ...global.Modeliks.DateHelper.months,
      ];
      const years = [
        ...global.Modeliks.DateHelper.years_before_actual,
        ...global.Modeliks.DateHelper.years_all,
      ];

      months.forEach((month) => {
        const prevMonthDate = months.find((c) => c.Order == month.Order - 1);
        const curMonthClosing = changeDriver.SalesTaxPayableClosingBalance.getItemByDateSufix(
          month.sufix,
        );
        const curMonthOpening = changeDriver.SalesTaxPayableOpenningBalance.getItemByDateSufix(
          month.sufix,
        );
        const curMonth = driver.getItemByDateSufix(month.sufix);

        if (prevMonthDate) {
          curMonth.Formula =
            `${curMonthClosing} - ${curMonthOpening}` +
            ` Actual(${curMonthClosing.ID_f_actual} - ${changeDriver.SalesTaxPayableClosingBalance.getItemByDateSufix(prevMonthDate.sufix).ID_f_actual})`;
        } else {
          curMonth.Formula = `${curMonthClosing} - ${curMonthOpening}`;
          curMonth.evalFormulaActual = null;
        }
      });

      years.forEach((year) => {
        const curYearClosing = changeDriver.SalesTaxPayableClosingBalance.getItemByDateSufix(
          year.sufix,
        );
        const curYearOpening = changeDriver.SalesTaxPayableOpenningBalance.getItemByDateSufix(
          year.sufix,
        );
        const curYear = driver.getItemByDateSufix(year.sufix);
        curYear.Formula = `${curYearClosing} - ${curYearOpening}`;

        if (year.Order !== years[0].Order) {
          const prevYearDate = years.find((c) => c.Order === year.Order - 1);
          curYear.Formula =
            `${curYear.Formula ? curYear.Formula : ""}` +
            `Actual(${curYearClosing.ID_f_actual} - ${changeDriver.SalesTaxPayableClosingBalance.getItemByDateSufix(prevYearDate.sufix).ID_f_actual})`;
        }
      });
      driver.isFormulasCreated = true;
    }
    return driver;
  };

  static getChangeInVATTaxPayable = () => {
    const driverID = "change_in_vat_tax_payable";
    let driver = global.Modeliks.DriversStore.getItem(`${Taxes.TableName}-${driverID}-totals`);
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Taxes.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.Sum,
        "Change in Vat Tax Payable",
        false,
        false,
        false,
      );
      driver.Save();
    }
    driver.IsFormulaEditable = false;
    const changeDriver = global.Modeliks.TaxesStore.find((d) => d.TaxType === TaxesTypes.VAT);

    if (changeDriver && !driver.isFormulasCreated) {
      // driver.Formula = `${changeDriver.VATTaxPayableClosingBalance.ID_f} - ${changeDriver.VATTaxPayableOpenningBalance.ID_f}`;
      driver.Formula = null;
      const months = [
        ...global.Modeliks.DateHelper.months_before_actual,
        ...global.Modeliks.DateHelper.months,
      ];
      const years = [
        ...global.Modeliks.DateHelper.years_before_actual,
        ...global.Modeliks.DateHelper.years_all,
      ];

      months.forEach((month) => {
        const prevMonthDate = months.find((c) => c.Order == month.Order - 1);
        const curMonthClosing = changeDriver.VATTaxPayableClosingBalance.getItemByDateSufix(
          month.sufix,
        );
        const curMonthOpening = changeDriver.VATTaxPayableOpenningBalance.getItemByDateSufix(
          month.sufix,
        );
        const curMonth = driver.getItemByDateSufix(month.sufix);

        if (prevMonthDate) {
          curMonth.Formula =
            `${curMonthClosing} - ${curMonthOpening}` +
            ` Actual(${curMonthClosing.ID_f_actual} - ${changeDriver.VATTaxPayableClosingBalance.getItemByDateSufix(prevMonthDate.sufix).ID_f_actual})`;
        } else {
          curMonth.Formula = `${curMonthClosing} - ${curMonthOpening}`;
          curMonth.evalFormulaActual = null;
        }
      });

      years.forEach((year) => {
        const curYearClosing = changeDriver.VATTaxPayableClosingBalance.getItemByDateSufix(
          year.sufix,
        );
        const curYearOpening = changeDriver.VATTaxPayableOpenningBalance.getItemByDateSufix(
          year.sufix,
        );
        const curYear = driver.getItemByDateSufix(year.sufix);
        curYear.Formula = `${curYearClosing} - ${curYearOpening}`;

        if (year.Order !== years[0].Order) {
          const prevYearDate = years.find((c) => c.Order === year.Order - 1);
          curYear.Formula =
            `${curYear.Formula ? curYear.Formula : ""}` +
            `Actual(${curYearClosing.ID_f_actual} - ${changeDriver.VATTaxPayableClosingBalance.getItemByDateSufix(prevYearDate.sufix).ID_f_actual})`;
        }
      });

      driver.isFormulasCreated = true;
    }
    return driver;
  };
}

export default Taxes;
