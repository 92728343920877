import React from "react";
import Button from "./../../../../../components/actions/Button";
import { FirstSection } from "../FirstSection";
import { ButtonsWrapper } from "../ButtonsWrapper";
import styled from "styled-components";
import { Theme } from "./../../../../../theme/styles/theme";
import MaterialSelect from "./../../../../../components/actions/SelectM";
import ButtonMui from "../../../../../components/buttons/buttonMui/buttonMui";
import { ArrowLeft, ArrowRight } from "../../../../../components/icons/svgIcons";

const ParagraphP3 = styled.p`
  ${Theme.text.paragraph.p3_strong};
  color: #252525;
  font-family: Inter;
  text-align: center;
`;

const SelectWrapperStep2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 25px;
`;
const InputLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledP3Normal = styled.div`
  ${Theme.text.paragraph.p3};
  color: #252525;
  font-family: Inter;
  margin-top: 30px;
`;

const currencies = [
  { description: "Dollar ($)", value: "$" },
  { description: "Euro (€)", value: "€" },
  { description: "British pound (£)", value: "£" },
];

export default function Step3(props) {
  const [currency, setCurrency] = React.useState("$");

  const nextStep = () => {
    props.setCurrency(currency);
    props.NextStep();
  };

  const changeStep = (callBack) => {
    if (currency) {
      props.setCurrency(currency, callBack);
    }
  };

  return (
    <>
      <div className="p title strong center p_b">
        What currency symbol do you want to use in your plan?
      </div>

      <FirstSection>
        <SelectWrapperStep2>
          <InputLabelWrapper>
            <div style={{ width: "225px" }}>
              <MaterialSelect
                label=""
                width={"225px"}
                value={currency}
                onChange={(event) => setCurrency(event.target.value)}
                options={currencies}
              />
            </div>
          </InputLabelWrapper>
          {/*<InputLabelWrapper>*/}
          {/*<ParagraphP3>Number Separator</ParagraphP3>*/}
          {/*<div style={{width: '225px'}}>*/}
          {/*<MaterialSelect label='' width='225px' value='1,000.00'*/}
          {/*options={[{description: '1,000.00', value: '1,000.00'}, {*/}
          {/*description: '1,000',*/}
          {/*value: '1,000'*/}
          {/*},]}/>*/}
          {/*</div>*/}

          {/*</InputLabelWrapper>*/}
        </SelectWrapperStep2>
      </FirstSection>
      <ButtonsWrapper>
        <div className="o_btn_container">
          <ButtonMui
            startIcon={<ArrowLeft />}
            label={"Back"}
            width={120}
            variant={"contained"}
            onClick={() => changeStep(() => props.BackStep())}
          />
        </div>
        <div className="o_btn_container start">
          <ButtonMui
            label={"Next"}
            width={120}
            variant={"contained"}
            endIcon={<ArrowRight />}
            onClick={() => changeStep(() => props.NextStep())}
          />
        </div>
      </ButtonsWrapper>
    </>
  );
}
