import React from "react";
import Dialog from "@mui/material/Dialog";
import "./CompanyLimit.scss";
import { Exit } from "../../../SlideEditor/components/components/IconButtons/SubMenuIcons";
import IconButton from "@mui/material/IconButton";
import { Navigate } from "react-router-dom";
import ButtonMui from "../../buttons/buttonMui/buttonMui";

class CompanyLimit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: true,
      link: null,
    };
  }

  handleClose = () => {
    this.props.handleClose();
  };

  archiveNow = () => {
    if (window.location.href.indexOf("/profile/companies")) {
      this.props.handleClose();
    } else {
      this.setState({ link: "/profile/companies" });
    }
  };

  render() {
    if (this.state.link) {
      return <Navigate to={this.state.link} />;
    }
    return (
      <React.Fragment>
        <Dialog
          fullWidth={true}
          maxWidth={"lg"}
          open={this.props.open}
          className={"company_limit_dialog"}
          onClose={this.handleClose}
        >
          <IconButton onClick={this.handleClose} className={"cld_close_button"}>
            <Exit />
          </IconButton>

          <div className={"p1_strong cld_heading"}>{this.props.title ?? "Add new company"}</div>

          <div className={"cld_typography"}>
            <p>
              You've reached your company limit based on your subscription plan. In order to create
              new company you can:
            </p>
            <ul>
              <li>upgrade your subscription</li>
              <li>
                archive one of your active companies (
                {
                  <a onClick={this.archiveNow} className={"lcd_typography_link"}>
                    archive now
                  </a>
                }
                )
              </li>
            </ul>
          </div>
          <div className={"cld_typography2_buttons"}>
            <ButtonMui
              label={"Upgrade Now"}
              width={130}
              variant={"contained"}
              onClick={() =>
                global.Modeliks.NavigateTo("/profile/account_details/manage_subscription")
              }
            />
            <ButtonMui
              label={"Cancel"}
              width={130}
              variant={"text"}
              color={"black"}
              onClick={this.handleClose}
            />
          </div>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default CompanyLimit;
