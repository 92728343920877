import React from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import "./Mx_Tooltip_Click.scss";
import { InfoIcon } from "./svgIcons";

const ToolTipComponent = (props) => {
  const { icon, title, onClick } = props;
  const [show, setShow] = React.useState(false);
  const handleClick = (e, handle = false) => {
    if (show) {
      setShow(false);
    } else {
      setShow(true);
    }
    if (onClick) {
      onClick(e);
    }
  };
  return (
    <div
      style={{ display: "grid", margin: 0, padding: 0, height: "-webkit-fill-available" }}
      onMouseOver={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      <Tooltip
        title={title}
        open={show}
        className="scim_tool_tip_click"
        placement={props.placement ? props.placement : "top"}
        arrow
      >
        <IconButton size="small" onClick={handleClick} aria-label="tool_tip">
          {icon}
        </IconButton>
      </Tooltip>
    </div>
  );
};

const ToolTipComponentChildren = (props) => {
  const { title, children, onClick } = props;
  const [show, setShow] = React.useState(false);
  const handleClick = (handle = false) => {
    if (show) {
      setShow(false);
    } else {
      setShow(true);
    }
    if (onClick) {
      onClick();
    }
  };
  return (
    <div
      className={props.disableStyle ? "scim_tooltip_div_disable_style" : `scim_tooltip_div`}
      style={{ display: "grid", margin: 0, padding: 0, height: "-webkit-fill-available" }}
      onMouseOver={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      <Tooltip
        title={title}
        open={show}
        className="scim_tool_tip_click"
        placement={props.placement ? props.placement : "top"}
        arrow
      >
        <IconButton size="small" onClick={handleClick} aria-label="tool_tip">
          {children}
        </IconButton>
      </Tooltip>
    </div>
  );
};

class Mx_Tooltip_Hover extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  render() {
    if (this.props.singleCell) {
      return (
        <ToolTipComponentChildren
          disableStyle={this.props.disableStyle}
          placement={this.props.placement}
          onClick={this.props.onClick}
          children={this.props.children}
          title={this.props.title}
        />
      );
    }
    return (
      <ToolTipComponent
        placement={this.props.placement}
        onClick={this.props.onClick}
        icon={this.props.infoIcon ? <InfoIcon /> : this.props.icon}
        title={this.props.title}
      />
    );
  }
}

export default Mx_Tooltip_Hover;
