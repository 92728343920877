import React from "react";
import styled, { keyframes, css } from "styled-components";
import PropTypes from "prop-types";
import { Theme } from "../../theme/styles/theme";

let styles = {
  white: Theme.colors.greyscale.white,
  lightgray500: Theme.colors.primary.lightgrey[500],
  lightblue150: Theme.colors.primary.lightblue[150],
  lightblue300: Theme.colors.primary.lightblue[300],
  darkgrey150: Theme.colors.primary.darkgrey[150],
  paragraph4: Theme.text.paragraph.p4,
  paragraph3: Theme.text.paragraph.p3,
};

const Input = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;
`;
const Label = styled.label`
  font-family: Inter;
  position: relative;
  color: ${styles["darkgrey150"]};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  margin: ${(props) => (props.size === "small" ? "0" : `0 0.75em`)};
  ${(props) => (props.size === "small" ? styles["paragraph4"] : styles["paragraph3"])};
  user-select: none;
`;

const rotate = keyframes`
         from {
            opacity: 0;
            transform: rotate(0deg);
          }
          to {
            opacity: 1;
            transform: rotate(45deg);
          }
    `;
const Indicator = styled.div`
  width: ${(props) => (props.size === "small" ? `13px` : "24px")};
  height: ${(props) => (props.size === "small" ? `13px` : "24px")};
  background: ${styles["white"]};
  position: absolute;
  top: ${(props) => (props.size === "small" ? "1.9px" : "-5px")};
  left: -1.6em;
  border: 1px solid #d4d5de;
  border-radius: ${(props) => (props.size === "small" ? `2px` : `100px`)};
  box-sizing: border-box;
  box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.2);
  margin-left: ${(props) => (props.size === "small" ? "0" : "-8px")};
  ${Input}:not(:disabled):checked & {
    background: #d1d1d1;
  }
  ${Label}:hover & {
    &:hover {
      background: ${styles["lightgray500"]};
      box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.2);
    }
  }
  &::after {
    content: "";
    position: absolute;
    display: none;
  }
  ${Input}:checked + & {
    background-color: ${styles["lightblue300"]};
    border: none;
    &:hover {
      background: ${styles["lightblue150"]};
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      border: none;
    }
  }

  ${Input}:checked + &::after {
    ${(props) =>
      props.size === "small" &&
      css`
        display: flex;
        border: solid ${styles["white"]};
        border-width: 0 0.11em 0.11em 0;
        width: 20%;
        left: 4.3px;
        height: 65%;
        top: 1px;
        animation-name: ${rotate};
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
      `}

    ${(props) =>
      props.size === "large" &&
      css`
        border: solid ${styles["white"]};
        width: 20%;
        left: 8.5px;
        height: 45%;
        top: 3.5px;
        border-width: 0 0.17em 0.17em 0;
        display: flex;
        animation-name: ${rotate};
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
      `}
  }
  &::disabled {
    cursor: not-allowed;
  }
`;

function Checkbox({
  value,
  type,
  name,
  placeholder,
  size = "small",
  onChange,
  disabled,
  onClick,
  checked,
}) {
  return (
    <Label size={size}>
      {placeholder}
      <Input
        onClick={onClick}
        id={name}
        disabled={disabled}
        checked={checked}
        type="checkbox"
        name={name}
        value={value}
        // disabled={disabled}
        // checked={checked}
        onChange={onChange}
      />
      <Indicator size={size} />
    </Label>
  );
}

Checkbox.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(["small", "large"]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  // error: PropTypes.bool,
  // errorMessage: PropTypes.string,
};

export default Checkbox;
