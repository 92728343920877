import React from "react";
import SP_Driver_BD_Component from "../../components/SP_Driver_BD_Component";
import SP_Driver_Control from "../../components/SP_Driver_Control";
import SP_Driver_Values_Control from "../../components/SP_Driver_Values_Control";

class SimpleValues extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  changeBrakeDown = () => {
    if (this.checkSingleDriverValues()) {
      this.props.revenue.convertToSingleValue(this.props.field);
    } else {
      this.props.revenue.removeSingleValue(this.props.field);
    }
    if (
      this.props.tab &&
      this.props.tab.hasOwnProperty("errors") &&
      this.props.tab.errors.simpleValue
    ) {
      this.props.tab.errors.simpleValue = false;
    }

    this.props.onDataChanged();
    this.forceUpdate();
  };

  onChangeValues = (child, value) => {
    if (this.props.tab.hasOwnProperty("errors")) {
      this.props.tab.errors.simpleValue = false;
    }

    if (value === "") {
      value = null;
    }

    child.Value = value;
    this.props.onDataChanged();
  };

  checkSingleDriverValues = () => {
    return this.props.revenue.children[0].hasSingleValue(this.props.field);
  };

  render() {
    return (
      <>
        {!this.checkSingleDriverValues() ? (
          <>
            <SP_Driver_Values_Control
              rows={this.props.childrens}
              min={0}
              max={this.props.tab.unit === "%" && 100}
              onChange={this.onChangeValues}
              field={this.props.field}
              singleValues={true}
              unit={this.props.tab.unit}
            />
            {this.props.tab.hasOwnProperty("errors") && this.props.tab.errors.simpleValue ? (
              <p className="psrcrsmcs_error_values">The % breakdown should be equal to 100%</p>
            ) : (
              <p className="psrcrsmcs_error_values" />
            )}
          </>
        ) : (
          <SP_Driver_Control
            onScroll={this.props.onScroll}
            title={this.props.title}
            showSpace={true}
            min={0}
            tab={this.props.tab}
            max={this.props.tab.unit === "%" && 100}
            rows={this.props.childrens}
            field={this.props.field}
            headers={this.props.headers}
            onChange={this.props.onDataChanged}
          />
        )}
        <SP_Driver_BD_Component
          breakDown={this.checkSingleDriverValues()}
          onChange={this.changeBrakeDown}
        />
      </>
    );
  }
}

export default SimpleValues;
