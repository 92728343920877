import * as React from "react";
import Dialog from "@mui/material/Dialog";
import ButtonJ from "../Buttons/Button";
import TabsMenu from "../menus/TabsMenu/TabsMenu";
import "./SectionDialog.scss";
import { BluePlus, Exit } from "../IconButtons/SubMenuIcons";
import "./SectionName.scss";
import CircleCheckboxM from "../../../../components/actions/CircleCheckboxM";
import Button from "../../../../components/actions/Button";
import IconButton from "@mui/material/IconButton";
import { TrashCan } from "../../../../components/icons/svgIcons";
import EditIcon from "@mui/icons-material/Edit";

export default function AdminUpdateSectionDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [name, setName] = React.useState(props.name);

  React.useEffect(() => {
    // Update the document title using the browser API
    setName(props.name);
  }, [props.name]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setSuccess(false);
      setName("Custom Section");
    }, 150);
  };

  const handleSave = () => {
    props.updateSection(name, () => setSuccess(true));
  };

  return (
    <React.Fragment>
      {
        <IconButton onClick={handleClickOpen}>
          <EditIcon fontSize={"small"} />
        </IconButton>
      }
      {open && (
        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          open={open}
          className={"name_dialog"}
          onClose={handleClose}
        >
          {success ? (
            <>
              <div className={"checkbox_container"}>
                <CircleCheckboxM size="large" checked={true} />
              </div>
              <div className="Label_2">Successfully saved</div>
              <div className="Paragraph_P4">
                Your section is available under <br /> custom sections in Add new section view
              </div>
              <ButtonJ
                size="large"
                width="118px"
                padding="0 0 0 0"
                backgroundColor="primary"
                label={"OK"}
                onClick={() => handleClose()}
              />
            </>
          ) : (
            <>
              <div className={"label"}>Update section</div>
              <div className={"input_container"}>
                <input
                  className={"input"}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder={"Section name..."}
                />
              </div>
              <div className={"btns_container"}>
                <ButtonJ
                  size="large"
                  width="118px"
                  padding="0 0 0 0"
                  color="black"
                  noElevation={true}
                  onClick={handleClose}
                  label={"Cancel"}
                />
                <ButtonJ
                  size="large"
                  width="118px"
                  padding="0 0 0 0"
                  backgroundColor="primary"
                  label={"Save Section"}
                  onClick={() => handleSave()}
                />
              </div>
            </>
          )}
        </Dialog>
      )}
    </React.Fragment>
  );
}
