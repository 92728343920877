import React from "react";
import PropTypes from "prop-types";
import CheckBoxMaterial from "../../../../../../components/actions/CheckBoxMaterial";
import CalculatedDriverGrowthDataGrid from "./CalculatedDriverGrowthDataGrid";
import "./CalculatedDriverGrowth.scss";

class CalculatedDriverGrowth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      isEnabledGrowth,
      data,
      isHidden,
      onChange,
      onScroll,
      onDataChanged,
      headers,
      growthDriverTableID,
      title,
      hasChildren,
      childrenData,
      showRowHeaders,
      className,
    } = this.props;
    let showCheckBox = !isEnabledGrowth || (!data.IsSimple && !data.hasOwnProperty("GrowthDriver"));
    if (isHidden === false) {
      showCheckBox = false;
    }
    let checked = data.hasOwnProperty("GrowthDriver");

    // if(this.props.isHidden === false && this.props.driver){
    //     checked = this.props.driver.hasOwnProperty('GrowthDriver');
    // }

    return (
      <div
        className="psfcfc_growth_div"
        hidden={
          showCheckBox ||
          (data.Formula &&
            data.Formula.length > 3 &&
            !data.hasOwnProperty("GrowthDriver") &&
            isEnabledGrowth !== false)
        }
      >
        <CheckBoxMaterial
          checked={checked}
          size="medium"
          onChange={() => onChange(data)}
          label={this.props.label}
        />
        {checked && (
          <CalculatedDriverGrowthDataGrid
            title={title}
            growthDriverTableID={growthDriverTableID}
            dataGridRow={data}
            className={className}
            showRowHeaders={showRowHeaders}
            hasChildren={hasChildren}
            onScroll={onScroll}
            childrenData={childrenData}
            headers={headers}
            onDataChanged={onDataChanged}
          />
        )}
      </div>
    );
  }
}

CalculatedDriverGrowth.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  isHidden: PropTypes.bool,
};

CalculatedDriverGrowth.defaultProps = {
  label: "% growth",
  onChange: PropTypes.func,
  isHidden: true,
  hasChildren: false,
  showRowHeaders: false,
};

export default CalculatedDriverGrowth;
