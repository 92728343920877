import React, { Component } from "react";
import request from "superagent";
import SlideComponent from "../../SlideEditor/components/SlideComponent";
import CircleLoader from "../../components/Loaders/CircleLoader/CircleLoader";
import SlideHeaderContainer from "../../SlideEditor/components/components/SlideHeaderContainer";
import { Logo } from "../../components/icons/svgIcons";
import { SlideTypes } from "../../SlideEditor/components/SlideObjects/SlideStructures";

class Publish extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
    this.scale = {};
  }

  componentDidMount() {
    this.isLinkValid();
  }

  isLinkValid = () => {
    const account_guid = window.location.href.split("/")[4];
    const client_guid = window.location.href.split("/")[5];

    request
      .get(`/api/BusinessPlanPublish?account_guid=${account_guid}&client_guid=${client_guid}`)
      .then((res) => {
        if (res.text !== "0") {
          let obj = JSON.parse(res.text);
          request
            .get(
              `/api/getBusinessPlanPublish?client_id=${obj.client_id}&company_id=${obj.company_id}&business_plan_scenario_id=${obj.business_plan_scenario_id}&type=${obj.type}&ID=${obj.ID}`,
            )
            .then((res2) => {
              if (res2.text !== "0") {
                request.get("/api/getPublishCharts").then((chartsRes) => {
                  global.Modeliks.PitchFinancialChartsYearly = chartsRes.body;
                  [
                    ...global.Modeliks.PitchFinancialChartsYearly,
                    ...global.Modeliks.PitchFinancialChartsMonthly,
                  ].forEach((c) => {
                    if (c && c.Data) {
                      try {
                        c.Data = JSON.parse(c.Data);
                      } catch (e) {}
                    }
                  });
                  let res = JSON.parse(res2.text);
                  let templatesArr = JSON.parse(res.jsonStringArr);
                  let pageNames = res.pageNames;
                  let config = templatesArr[0];
                  templatesArr.splice(0, 1);
                  this.slideSections = res.slideSections;

                  console.log("templates", this.slideSections);

                  this.setState({
                    index: 0,
                    templatesArray: templatesArr,
                    slideConfig: JSON.parse(config),
                    pageNames: pageNames,
                    loadedData: true,
                    accountID: obj.client_id,
                    companyID: obj.company_id,
                  });
                });
              } else {
                this.setState({ loading: false, open: false });
              }
            });
        } else {
          this.setState({ loading: false, open: false });
        }
      });
  };

  checkIfChartsHaveFinished = () => {
    console.log("test", window.amountOfChartsToRender, window.amountOfChartsRendered);
    return window.amountOfChartsToRender === window.amountOfChartsRendered;
  };

  resetPrintingValues = () => {
    window.chartsForRender = [];
    window.amountOfChartsRendered = 0;
    window.amountOfChartsToRender = 0;
  };

  returnSlideComp = () => {
    console.log("enters return slide comp");
    let counter = 0;
    window.iscurrentlyPrinting = true;
    this.resetPrintingValues();

    function checkFinishedWrapper() {
      checkFinished(true);
    }

    const checkFinished = (fromChart) => {
      if (!fromChart) {
        counter++;
      }
      console.log(
        "enters check",
        counter == this.state.templatesArray.length,
        this.checkIfChartsHaveFinished(),
      );
      if (counter == this.state.templatesArray.length && this.checkIfChartsHaveFinished()) {
        global.Modeliks.Unsubscribe2("onChartRenderFinish", checkFinishedWrapper);
        window.iscurrentlyPrinting = false;

        setTimeout(() => {
          const children = document
            .getElementsByClassName("preview_hidden_container")[0]
            .childNodes.forEach((c, i) => (this.slideSections[i].html = c.outerHTML));

          request
            .post("/api-pdf/createpdf")
            .set("Content-Type", "application/json")
            .set("authorization", "Bearer " + window.localStorage.getItem("token"))
            .send({
              config: {
                ...this.state.slideConfig,
                companyName: "Company 1",
                CompanyID: this.state.companyID,
                AccountID: this.state.accountID,
                logoUrl: `${window.location.protocol + "//" + window.location.host}/api/logo?account_id=${this.state.accountID}&company_id=${this.state.companyID}`,
                forceRecrate: true,
              },
              sections: this.slideSections,
            })
            .then((res) => {
              this.setState({ loading: false, open: true, fileName: res.body });
            })
            .catch((e) => console.error(e));
        }, 600);
      }
    };

    global.Modeliks.Subscribe("onChartRenderFinish", checkFinishedWrapper);
    return (
      <div className={"preview_hidden_container"}>
        {this.state.templatesArray.map((c, index) => {
          let jsonString = JSON.parse(c);
          window.amountOfChartsToRender += jsonString.slideObjects.filter(
            (c) => c.type === SlideTypes.chartObject || c.type === SlideTypes.financialChart,
          ).length;
          return (
            <SlideComponent
              onPreview={() => this.onPreview()}
              scale={this.scale}
              pageBreakAfter={index === this.state.templatesArray.length - 1 ? false : true}
              showLogo={true}
              slideConfig={this.state.slideConfig}
              didMount={() => checkFinished()}
              disableHeader
              preview={true}
              width={2480}
              height={3057}
              dontShowHeader={true}
              dontShowFooter={true}
              showPageName={index !== 0}
              pageName={this.state.pageNames[index] ? this.state.pageNames[index] : ""}
              businessPlan={true}
              onUnmount={true}
              jsonData={c}
              isBpPrinting={true}
              isFirstPage={index === 0}
              openDialog={true}
              className={this.saving && " hidden"}
              key={"sc_" + index.toString()}
            />
          );
        })}
      </div>
    );
  };

  render() {
    if (this.state.loading) {
      return (
        <>
          <div className={"main_circle_loader_container"}>
            <CircleLoader />
          </div>
          {this.state.loadedData && this.returnSlideComp()}
        </>
      );
    } else if (this.state.open) {
      {
        console.log("this", this.state.fileName);
      }
      return (
        <div style={{ width: "100%", height: "100%" }}>
          <iframe
            style={{ height: "100%", width: "100%" }}
            src={`/api-pdf/getpdf/${this.state.fileName}?v=${new Date().getTime()}`}
            frameBorder="0"
          ></iframe>
        </div>
      );
    } else {
      return (
        <div className={"se_invalid_link_container"}>
          <Logo width={"30%"} height={"30%"} />
          <h1>Your access was revoked</h1>
        </div>
      );
    }
  }
}

export default Publish;
