import React from "react";
import FinanceTable from "./index";
import "./financetablemain.scss";

export default (props) => {
  const handleScroll = (e) => {
    if (props.doubleScroll) {
      let wrapper1 = document.getElementById("dbl_srcl_wrapper1");
      let wrapper2 = document.getElementById("dbl_srcl_wrapper2");

      let scrolling = false;
      wrapper1.onscroll = function () {
        if (scrolling) {
          scrolling = false;
          return true;
        }
        scrolling = true;
        wrapper2.scrollLeft = wrapper1.scrollLeft;
      };
      wrapper2.onscroll = function () {
        if (scrolling) {
          scrolling = false;
          return true;
        }
        scrolling = true;
        wrapper1.scrollLeft = wrapper2.scrollLeft;
      };
    }
  };

  const getHorizontalScrollBar = () => {
    if (props.doubleScroll) {
      return (
        <div id={"dbl_srcl_wrapper1"} onScroll={handleScroll}>
          <div id={"dbl_srcl_wrapper1_div1"} />
        </div>
      );
    }
    return null;
  };

  React.useEffect(() => {
    if (props.doubleScroll) {
      let table = document.getElementById("dbl_srcl_wrapper1_div2").getBoundingClientRect();
      document.getElementById("dbl_srcl_wrapper1_div1").style.width = `${table.width}px`;
      console.log(
        document.getElementById("dbl_srcl_wrapper1").style.width,
        document.getElementById("dbl_srcl_wrapper1_div1").style.width,
      );
    }
  }, []);

  return (
    <>
      {getHorizontalScrollBar()}
      <div
        onScroll={handleScroll}
        className={`${props.wrapperClassName && props.wrapperClassName} finance_table_main_wrapper_div`}
        id={"dbl_srcl_wrapper2"}
      >
        <FinanceTable
          {...props}
          className={
            props.className
              ? props.className + " financetablemain_wrapper"
              : "financetablemain_wrapper"
          }
          tableID={props.doubleScroll && "dbl_srcl_wrapper1_div2"}
        />
      </div>
    </>
  );
};
