import styled from "styled-components";
import { Theme } from "../../../../theme/styles/theme";

const StyledParagraph = styled.p`
  ${Theme.text.paragraph.p3_strong};
  font-family: Inter;
  color: #252525;
  margin: 0;
  text-align: center;
`;

export const ParagraphP2Strong = (props) => {
  return (
    <StyledParagraph style={props.style} className={props.className}>
      {props.children}
    </StyledParagraph>
  );
};
