import React from "react";
import { Dialog } from "@mui/material";
import Button from "../../../../../../../components/actions/Button";
import styled from "styled-components";

const P1Strong = styled.div`
  text-align: center;
  padding-left: 7px;
  font-weight: 700;
  font-size: 20px;
  font-family: Inter;
`;
const P3Strong = styled.div`
  text-align: center;
  padding-left: 7px;
  font-family: Inter;
  font-weight: 700;
  font-size: 14px;
  line-height: 160%;
  margin-bottom: 15px;
`;
const P3 = styled.div`
  font-family: Inter;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  ${(props) => (props.padding ? "margin-top: 15px" : "")}
`;

class ConvertToSimpleDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open ? props.open : false,
    };
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        scroll="paper"
        maxWidth={"md"}
        sx={{
          zIndex: 2000,
          ".MuiBackdrop-root": {
            backgroundColor: `rgb(255 255 255 / 90%)`,
          },
          "& .MuiDialog-container": {
            justifyContent: "center",
            alignItems: "center",
          },
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "600px", // Set your width here
            height: "auto",
            maxHeight: "334px",
          },
        }}
        PaperProps={{
          sx: {
            position: "fixed",
            marginLeft: "25px",
            marginRight: "38px",
            boxShadow: `0px 0px 10px rgba(0, 0, 0, 0.15)`,
          },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingRight: "10px",
            paddingTop: "35px",
            paddingLeft: "13px",
          }}
        >
          <P1Strong>{"Remove formula"}</P1Strong>
        </div>
        <div
          style={{
            padding: "30px 70px 25px 70px",
            fontFamily: "Inter",
            fontSize: "14px",
            lineHeight: "28px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <P3>
            Removing formula will make {this.props.title} an input and remove the current formula
            for calculating {this.props.title}.
          </P3>
          <p>
            <P3>
              Are you sure that you want to remove the formula for calculating {this.props.title}?
            </P3>
          </p>
          {/*<P3>and remove the current formula for calculating {this.props.title}</P3>*/}
          {/*/!*<P3>The following drivers will be deleted:</P3>*!/*/}

          {/*/!*<div style={{width:'100%', display:'flex', flexWrap:'wrap' ,justifyContent:'center'}}>*!/*/}
          {/*/!*{this.props.drivers.map(driver => {return <P3Strong key={driver.ID} style={{margin:0}}>{driver.DriverName}</P3Strong>})}*!/*/}
          {/*/!*</div>*!/*/}

          {/*<P3>Are you sure that you want to remove the formula for calculating {this.props.title}?</P3>*/}
        </div>
        <div
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            paddingBottom: "31px",
            gap: "30px",
          }}
        >
          <Button
            size="small"
            disable={false}
            padding="15px"
            noElevation={true}
            outlined={false}
            color="black"
            label={"No"}
            onClick={this.props.onClose}
          />
          <Button
            size="small"
            disable={false}
            padding="15px"
            backgroundColor="red150"
            label={"Yes"}
            onClick={this.props.Convert}
          />
        </div>
      </Dialog>
    );
  }
}

export default ConvertToSimpleDialog;
