import React from "react";
import CircleLoader from "../../../../components/Loaders/CircleLoader/CircleLoader";
import {
  Container,
  Header,
  DTextDivL,
  SubHeader,
  DInputsContainer,
  CB,
  PSInput,
  CBInput,
  PIconH,
  PSelect,
} from "../components/ValuationContainer";
import { Vector } from "../../../../components/dividers/Vector";
import CostOfCapitalWACC from "../../../../data/Finance/Valuation/CostOfCapitalWACC/index";

const CostOfCapitalTypes = {
  DiscountRate: "discount-rate",
  IndustryBenchmark: "industry-benchmark",
  AdvancedWACC: "advanced-wacc",
};

const StateOfDevelopment = {
  PreSeed: { value: "pre-seed", description: "Pre-seed" },
  Maturity: { value: "maturity", description: "Maturity" },
  Growth: { value: "growth", description: "Growth" },
  Seed: { value: "seed", description: "Seed" },
};

const Loader = (
  <div className={"main_circle_loader_container"}>
    <CircleLoader />
  </div>
);

export default class CostOfCapitalMain extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      key: new Date().getTime(),
      loading: true,
    };

    this.CostOfCapital = CostOfCapitalWACC.getCostOfCapitalWACC();
  }
  componentDidMount() {
    if (this.CostOfCapital) {
      this.setState({ loading: false });
    }
  }

  onChangeCheckBox = (e, type) => {
    this.CostOfCapital[e.target.name] = type;
    this.CostOfCapital.Save(() => {}, false, false);
    this.forceUpdate();
  };

  onChangeInput = (value, key) => {
    this.CostOfCapital[key].Value = value;
    this.CostOfCapital[key].Save();
    this.forceUpdate();
  };

  changeStateOfDevelopment = (e) => {
    this.CostOfCapital.StateOfDevelopment = e.target.value;
    this.CostOfCapital.Save(() => {}, false, false);
    this.forceUpdate();
  };

  render() {
    if (this.state.loading || !this.CostOfCapital) {
      return Loader;
    }

    return (
      <Container className={" fill_height"} key={this.state.key}>
        <Header>Select how you want to enter your Cost of Capital (WACC)</Header>
        <CBInput
          Input={{
            onChange: (val) => this.onChangeInput(val, "DiscountRate"),
            name: "DiscountRate",
            key: "DiscountRate",
            value: this.CostOfCapital["DiscountRate"].Value,
            disabled: this.CostOfCapital["CostOfCapitalType"] !== CostOfCapitalTypes.DiscountRate,
          }}
          value={this.CostOfCapital["CostOfCapitalType"] === CostOfCapitalTypes.DiscountRate}
          onChange={(e) => this.onChangeCheckBox(e, CostOfCapitalTypes.DiscountRate)}
          name="CostOfCapitalType"
          label="Enter Discount Rate"
        />

        <CBInput
          Input={{
            onChange: (val) => this.onChangeInput(val, "WACCSelectedStage"),
            name: "WACCSelectedStage",
            key: "WACCSelectedStage",
            value: this.CostOfCapital["WACCSelectedStage"].Value,
            disabled: true,
          }}
          value={this.CostOfCapital["CostOfCapitalType"] === CostOfCapitalTypes.IndustryBenchmark}
          onChange={(e) => this.onChangeCheckBox(e, CostOfCapitalTypes.IndustryBenchmark)}
          name="CostOfCapitalType"
          label="Use Industry Benchmarks"
        />

        {/*<CB value={this.CostOfCapital['CostOfCapitalType'] === CostOfCapitalTypes.IndustryBenchmark} onChange={(e) => this.onChangeCheckBox(e, CostOfCapitalTypes.IndustryBenchmark)} name='CostOfCapitalType'  label='Use Industry Benchmarks'/>*/}

        {/*{this.CostOfCapital['CostOfCapitalType'] === CostOfCapitalTypes.IndustryBenchmark &&*/}
        {/*<>*/}
        {/*<DTextDivL>*/}
        {/*<PIconH title='Stage of development' description='Text Here'/>*/}
        {/*<PSelect value={this.CostOfCapital.StateOfDevelopment} name='StateOfDevelopment' onChange={this.changeStateOfDevelopment}*/}
        {/*options={Object.values(StateOfDevelopment)}*/}
        {/*/>*/}
        {/*</DTextDivL>*/}
        {/*<DTextDivL>*/}
        {/*<p>Select Industry</p>*/}
        {/*<PSelect value={555} name='SelectedIndustry' onChange={() => console.log('d')}*/}
        {/*options={[{description: 'Select Industry', value: 555},{description: 'ADD', value:1}]}*/}
        {/*/>*/}
        {/*</DTextDivL>*/}
        {/*<DTextDivL>*/}
        {/*<p/>*/}
        {/*<PSInput Input={{onChange: (val) => this.onChangeInput(val,'WACCSelectedStage'),name:'WACCSelectedStage',key:'WACCSelectedStage',value: this.CostOfCapital['WACCSelectedStage'].Value}} className='spsvc_input_wacc_range' label='WACC for selected stage is '/>*/}
        {/*</DTextDivL>*/}
        {/*</>*/}
        {/*}*/}

        <CB
          value={this.CostOfCapital["CostOfCapitalType"] === CostOfCapitalTypes.AdvancedWACC}
          name="CostOfCapitalType"
          onChange={(e) => this.onChangeCheckBox(e, CostOfCapitalTypes.AdvancedWACC)}
          label="Use Advanced WACC Calculation"
        />

        {this.CostOfCapital["CostOfCapitalType"] === CostOfCapitalTypes.AdvancedWACC && (
          <>
            <DInputsContainer>
              <div className="spsvc_child_inputs_container">
                <p>
                  <SubHeader>Cost of Equity</SubHeader>
                </p>
                {[
                  { name: "Risk Free Rate", key: "RiskFreeRate" },
                  { name: "Country Risk Premium", key: "CountryRiskPremium" },
                  { name: "Inflation Rate", key: "InflationRate" },
                  { name: "Market Risk Premium", key: "MarketRiskPremium" },
                  { name: "Liquidity Risk Premium", key: "LiquidityRiskPremium" },
                  { name: "Beta", key: "Beta" },
                ].map((r) => (
                  <PSInput
                    Input={{
                      onChange: (val) => this.onChangeInput(val, r.key),
                      name: r.key,
                      key: r.key,
                      value: this.CostOfCapital[r.key].Value,
                    }}
                    label={r.name}
                  />
                ))}
              </div>
              <div className="spsvc_child_inputs_container">
                <p>
                  {" "}
                  <SubHeader>Cost of Debt</SubHeader>
                </p>
                {[
                  { name: "TaxRate", key: "TaxRate" },
                  { name: "DebtSpread", key: "DebtSpread" },
                  { name: "DebtEquityRadio", key: "DebtEquityRatio" },
                ].map((r) => (
                  <PSInput
                    Input={{
                      onChange: (val) => this.onChangeInput(val, r.key),
                      name: r.key,
                      key: r.key,
                      value: this.CostOfCapital[r.key].Value,
                    }}
                    label={r.name}
                  />
                ))}
              </div>
            </DInputsContainer>
            <DInputsContainer>
              <div className="spsvc_child_inputs_container">
                <PSInput
                  Input={{
                    onChange: (val) => this.onChangeInput(val, "TotalCostOfEquity"),
                    name: "TotalCostOfEquity",
                    key: "TotalCostOfEquity",
                    value: this.CostOfCapital["TotalCostOfEquity"].Value,
                  }}
                  label="Total Cost of Equity"
                />
              </div>
              <div className="spsvc_child_inputs_container">
                <PSInput
                  Input={{
                    onChange: (val) => this.onChangeInput(val, "TotalCostOfDebt"),
                    name: "TotalCostOfDebt",
                    key: "TotalCostOfDebt",
                    value: this.CostOfCapital["TotalCostOfDebt"].Value,
                  }}
                  label="Total Cost of Debt"
                />
              </div>
            </DInputsContainer>
            <Vector />
            <DInputsContainer>
              <div className="spsvc_child_inputs_container">
                <PSInput
                  Input={{
                    onChange: (val) => this.onChangeInput(val, "WACC"),
                    name: "WACC",
                    key: "WACC",
                    value: this.CostOfCapital["WACC"].Value,
                  }}
                  label={<SubHeader>WACC</SubHeader>}
                />
              </div>
              <div className="spsvc_child_inputs_container" />
            </DInputsContainer>
          </>
        )}
      </Container>
    );
  }
}
