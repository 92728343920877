import React, { Fragment } from "react";
import HeaderPortal from "../SlideHeader/HeaderPortal.js";
import HeaderTextEditor from "../SlideHeader/HeaderTextEditor";
import RightMenuObject from "../RightMenuObject";
import {
  Exit,
  FlipHorizontally,
  FlipVertically,
  Rotate90,
} from "../components/IconButtons/SubMenuIcons";
import SizeAndRotation from "../components/RightMenuComponents/SizeAndRotationComponent";
import Position from "../components/RightMenuComponents/PositionComponent";
import TextFitting from "../components/RightMenuComponents/TextFittingComponent";
import SpecialCharacters from "../components/RightMenuComponents/SpecialCharactersComponent";
import RightMenuPortal from "../components/Portals/RightMenuPortal.js";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ContextMenuPortal from "../Templates/ContextMenuPortal";
import TextContextMenuComponent from "../components/ContextMenus/TextContextMenu";
import DefaultItems from "../components/ContextMenus/DefaultSubContextItems";
import OrderSubContextMenu from "../components/ContextMenus/OrderSubContextMenu";
import CapitalizationSubContextMenu from "../components/ContextMenus/CapitalizationSubContextMenu";
import TextSubContextMenu from "../components/ContextMenus/TextSubContextMenu";
import LinkSubContextMenu from "../components/ContextMenus/LinkSubContextMenu";
import "../components/RightMenuComponents/RightMenu.css";

class TextObject extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      style: props.style ? props.style : {},
      formatOptions: false,
      selection: null,
      curRow: 0,
    };

    this.struct = [];
  }
  handleStyleChanged = (changedStyle) => {
    const newStyle = {};
    Object.assign(newStyle, this.state.style);
    Object.assign(newStyle, changedStyle);

    this.props.onPropsChange({ style: newStyle });
    this.setState({ style: newStyle });
    this.forceUpdate();
  };

  handleStyleRemoved = (styles) => {
    const newStyle = {};
    Object.assign(newStyle, this.state.style);
    styles.forEach((style) => {
      if (newStyle[style]) {
        delete newStyle[style];
      }
    });

    this.setState({ style: newStyle });
  };
  getCaretCharOffset(element) {
    var caretOffset = 0;

    if (window.getSelection) {
      var range = window.getSelection().getRangeAt(0);
      var preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(element);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      caretOffset = preCaretRange.toString().length;
    } else if (document.selection && document.selection.type != "Control") {
      var textRange = document.selection.createRange();
      var preCaretTextRange = document.body.createTextRange();
      preCaretTextRange.moveToElementText(element);
      preCaretTextRange.setEndPoint("EndToEnd", textRange);
      caretOffset = preCaretTextRange.text.length;
    }

    return caretOffset;
  }

  setCaretPosition(elem, caretPos) {
    if (elem != null) {
      if (elem.createTextRange) {
        var range = elem.createTextRange("as");
        range.move("character", caretPos);
        range.select();
      } else {
        if (elem.selectionStart) {
          elem.focus();
          elem.setSelectionRange(caretPos, caretPos);
        } else elem.focus();
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.dragging || this.props.resizing || this.props.rotating) {
      return false;
    }
    return true;
  }

  printCaretPosition(event) {
    // for(let i=0;i<this.getCaretCharOffset(event.target);i++){
    //
    // }
    this.setCaretPosition(event.target, this.getCaretCharOffset(event.target) + 5);
  }

  setTextEvent = (event) => {
    if (this.props.dragging || this.props.resizing || this.props.rotating) {
      return;
    }
    this.props.onValueChange(event.target.innerHTML);
    this.props.onChangedSize();
  };

  setSelection = (e) => {
    this.setState({ selection: document.getSelection() });
  };

  render() {
    return (
      <Fragment>
        <ContextMenuPortal>
          <CapitalizationSubContextMenu
            onChange={this.handleStyleChanged}
            style={this.state.style}
          />
          <div id={"divider"}></div>
          <LinkSubContextMenu />
          <div id={"contextMenuOption"}>
            <span>Format options</span>
          </div>
        </ContextMenuPortal>
        <HeaderPortal visible={this.props.selected}>
          <HeaderTextEditor
            visible={true}
            onValueChange={this.props.onValueChange}
            value={this.props.value}
            style={this.state.style}
            onChange={this.handleStyleChanged}
            onStyleRemove={this.handleStyleRemoved}
            width={this.props.width}
          />
        </HeaderPortal>
        {this.props.selected && (
          <RightMenuPortal>
            <List
              style={{
                paddingLeft: "0",
                position: "relative",
                width: "100%",
                height: "auto",
                maxWidth: 250,
                bgcolor: "background.paper",
                paddingBottom: "0",
                paddingTop: "0",
                // overflow:'scroll',
              }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              <TextFitting onChange={this.handleStyleChanged} style={this.state.style} />
              <SpecialCharacters
                onChange={this.props.onChange}
                style={this.props.style}
                onValueChange={this.props.onValueChange}
                value={this.props.value}
                selection={this.state.selection}
              />
            </List>
          </RightMenuPortal>
        )}

        <div
          className="sp_cp_textobject"
          id={"test"}
          onBlur={this.setTextEvent}
          contentEditable={"true"}
          style={{ ...this.state.style }}
          onKeyUp={(e) => this.setSelection(e)}
          onClick={(e) => this.setSelection()}
          dangerouslySetInnerHTML={{ __html: this.props.value }}
        ></div>
      </Fragment>
    );
  }
}

TextObject.config = {
  height: "auto",
  minWidth: 50,
};

export default TextObject;
