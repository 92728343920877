import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Theme } from "../../../../theme/styles/theme";
import { AppSumoLogo, ArrowLeft, Avatar, Logo } from "../../../../components/icons/svgIcons";
import Input, { Input2 } from "../../../../components/actions/Input";
import { Vector } from "../../../../components/dividers/Vector";
import { BodyWrapper } from "./BodyWrapper";
import { BottomWrapper } from "./BottomWrapper";
import request from "superagent";
import CircleLoader from "../../../../components/Loaders/CircleLoader/CircleLoader";
import { default as MuiButton } from "@mui/material/Button";
import { IconButton } from "@mui/material";
import { BluePlus } from "../../../../SlideEditor/components/components/IconButtons/SubMenuIcons";
import CheckBoxMaterial from "../../../../components/actions/CheckBoxMaterial";
import ButtonMui from "../../../../components/buttons/buttonMui/buttonMui";
import InputM from "../../../../components/actions/inputs/InputM";
import StrengthMeter from "../../../../components/actions/inputs/StrengthMeter";
import Mx_Tooltip from "../../../../components/icons/Mx_Tooltip_Click";
import { ValidationTextPassword } from "./ValidationTextPassword";
import GetUserTypeIcon from "./GetUserTypeIcon";
import { Navigate, useNavigate } from "react-router-dom";
import {modeliksLinks} from "../../../../Enums/ModeliksEnum";

export const EmailRegex =
  /^([+\w-]+(?:\.[+\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

export const LoginWrapper = styled.div`
  background: #ffffff;
  border: 1px solid ${Theme.colors.primary.lightgrey[150]};
  box-sizing: border-box;
  border-radius: 5px;
  width: 368px;
`;
const HeadingP1 = styled.p`
  ${Theme.text.paragraph.p1_strong};
  font-family: Inter;
  color: ${Theme.colors.primary.darkgrey[500]};
`;
const HeadingP1Strong = styled.p`
  ${Theme.text.paragraph.p1_strong};
  font-family: Inter;
  font-weight: 900;
  color: ${Theme.colors.primary.darkgrey[500]};
`;
const TextP2 = styled.p`
  ${Theme.text.paragraph.p2_strong};
  font-family: Inter;
  color: ${Theme.colors.primary.darkgrey[500]};
`;
const TextP3 = styled.p`
  ${Theme.text.paragraph.p3};
  font-family: Inter;
  color: ${Theme.colors.primary.darkgrey[500]};
  margin-left: 1.5px;
  margin-right: 11px;
  margin-top: -4px;
`;

const TextP3Strong = styled.p`
  ${Theme.text.paragraph.p3_strong};
  font-family: Inter;
  font-weight: 600;
  color: ${Theme.colors.primary.darkgrey[500]};
`;

const TextTip = styled.p`
  ${Theme.text.paragraph.p4_strong};
  font-family: Inter;

  color: ${Theme.colors.primary.darkgrey[50]};
  margin-left: 1.5px;
  margin-right: 11px;
  margin-top: -4px;
`;
const LabelInputWrapper = styled.div`
  margin-left: 16px;
  margin-right: 16px;
  margin-top: -1px;
`;
const ButtonWrapper = styled.div`
  margin-right: 14px;
  margin-left: 14px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  margin-top: 28px;
  margin-bottom: ${(props) => (props.small ? "12px" : "20px")};
`;
const LogoWrapper = styled.div`
  align-content: center;
  align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-bottom: 36px;
  margin-top: 47px;
  cursor: pointer;
`;
const VectorWrapper = styled.div`
  margin-top: -1px;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: ${(props) => (props.reset ? "14.5px" : "29.5px")};
`;

const ButtonBottomWrapper = styled.div`
  margin: ${(props) => (props.margin ? props.margin : `0 0 30px 13px`)};
`;
export const Tip = styled.div`
  color: grey;
  font-size: 14px;
  font-family: Inter;
  margin-top: 10px;
  min-height: 16px;
`;

function AuthPage({ label, type = "login", ...props }) {
  const [password, setPassword] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [Name, setName] = React.useState("");
  const [Coupon, setCoupon] = React.useState("");
  const [Surname, setSurname] = React.useState("");
  const [isMarketingSubscribed, setIsMarketingSubscribed] = React.useState(false);
  const [emailError, setEmailError] = React.useState("");
  const [nameError, setNameError] = React.useState("");
  const [surnameError, setSurnameError] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const [newPassword1, setNewPassword1] = React.useState("");
  const [newPassword1Error, setNewPassword1Error] = React.useState("");
  const [newPassword2, setNewPassword2] = React.useState("");
  const [newPassword2Error, setNewPassword2Error] = React.useState("");
  const [forgottenPasswordEmail, setForgottenPasswordEmail] = React.useState("");
  const [forgottenPasswordEmailError, setForgottenPasswordEmailError] = React.useState("");
  const [couponError, setCouponError] = React.useState("");
  const [tipText, setTipText] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [chooseAccount, setChooseAccount] = React.useState(false);
  const [accounts, setAccounts] = React.useState([]);
  const [ReqData, setData] = React.useState({});
  const [link, setLink] = React.useState(null);
  const [userID, setUserID] = React.useState(null);
  const [keepMeLoggedIn, setKeepMeLoggedIn] = React.useState(false);

  const validate = () => {
    let curEmailError = "";
    let curPasswordError = "";
    let emailRegexAllowPlus =
      /^([+\w-]+(?:\.[+\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    let specialCharPassword =
      /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[A-Z])(?=.*[-\#\$\.\%\&\*])(?=.*[a-zA-Z]).{8,44}$/;

    if (email == "Administrator") {
      return true;
    }
    switch (true) {
      case !emailRegexAllowPlus.test(email):
        curEmailError = "email error";
        break;
    }
    // switch (true) {
    //     case password.length < 8:
    //         curPasswordError = 'Your password must be 8 characters or longer';
    //         break;
    //     case password === email:
    //         curPasswordError = 'Email and Password cannot be the same';
    //         break;
    // }

    if (curEmailError || curPasswordError) {
      setEmailError(curEmailError);
      setPasswordError(curPasswordError);
      return false;
    }
    return true;
  };
  const validateEmail = () => {
    let curEmailError = "";
    let emailRegexAllowPlus = EmailRegex;
    switch (true) {
      case !emailRegexAllowPlus.test(forgottenPasswordEmail):
        curEmailError = "Invalid email address";
        break;
    }
    setForgottenPasswordEmailError(curEmailError);
    if (curEmailError) {
      return false;
    }

    return true;
  };
  const validateNewPasswords = () => {
    let specialCharPassword =
      /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[A-Z])(?=.*[-\#\$\.\%\&\*])(?=.*[a-zA-Z]).{8,44}$/;
    let curPassword1Error = "";
    let curPassword2Error = "";
    switch (true) {
      case newPassword1.length < 6:
        curPassword1Error = "Your password must be at least 6 characters";
        break;
      case newPassword1 !== newPassword2:
        curPassword1Error = "Passwords do not match";
        curPassword2Error = "Passwords do not match";
        break;

      // case !specialCharPassword.test(newPassword1):
      //     curPassword1Error = 'Special characters are required';
      //     break;

      case newPassword1 === forgottenPasswordEmail:
        curPassword1Error = "Email and Password cannot be the same";
        break;
    }

    setNewPassword1Error(curPassword1Error);
    setNewPassword2Error(curPassword2Error);
    if (curPassword1Error) {
      return false;
    }
    return true;
  };
  const login = () => {
    if (validate()) {
      !loading && setLoading(true);
      request
        .post("/api/auth")
        .send({
          Email: email,
          Password: password,
          TimeStamp: new Date().getTime(),
          keepLoggedIn: keepMeLoggedIn,
        })
        .then((newData) => {
          setData(newData); //AdminUser
          if (newData.body.Accounts) {
            if (newData.body.Accounts.length > 1) {
              loading && setLoading(false);
              !chooseAccount && setChooseAccount(true);
              setAccounts(newData.body.Accounts);
            } else {
              loginWithAccount(0, newData);
            }
          }
        })
        .catch((err) => {
          if (err.response.body.error.User_ID) {
            setUserID(err.response.body.error.User_ID);
            setChooseAccount(true);
          } else {
            err.response.body.error[0]
              ? setPasswordError(err.response.body.error)
              : setPasswordError("Invalid Credentials");
            setChooseAccount(false);
          }
          setLoading(false);
          // err.response.body.error[1] && setTipText(err.response.body.error);
        });
    }
  };

  const validateFirstName = () => {
    if (checkForMaliciousCharacters(Name)) {
      setNameError("Invalid characters");
      return;
    }
    if (Name.toString().length) {
      return true;
    }
    setNameError("Required field");
    return false;
  };

  const validateCoupon = () => {
    if (Coupon.toString().length) {
      return true;
    }
    setCouponError("Required field");
    return false;
  };

  const validateLastName = () => {
    if (checkForMaliciousCharacters(Surname)) {
      setSurnameError("Invalid characters");
      return;
    }
    if (Surname.toString().length && !checkForMaliciousCharacters(Surname)) {
      return true;
    }
    setSurnameError("Required field");
    return false;
  };

  const newPassword = () => {
    // let lastSegment = window.location.pathname.split("/").pop();
    if (validateNewPasswords()) {
      props.sendNewPassword(newPassword1, () => {
        setNewPassword1Error("bad passwords");
        setNewPassword2Error("bad passwords");
      });
    }
  };
  const forgottenPassword = () => {
    if (validateEmail()) {
      request
        .post("/api/forgotPassword")
        .send({ Email: forgottenPasswordEmail })
        .then((data) => {
          window.location.href = "/emailSent";
        })
        .catch((err) => {
          err.response.body.error[0]
            ? setForgottenPasswordEmailError(err.response.body.error[0])
            : setForgottenPasswordEmailError("Invalid Email");
          err.response.body.error[1] && setTipText(err.response.body.error[1]);
        });
    }
  };
  const registerAccount = () => {
    let valid_firstName = validateFirstName();
    let valid_lastName = validateLastName();
    let valid_email = validateEmail();
    let valid_password = validateNewPasswords();
    if (valid_firstName && valid_lastName && valid_email && valid_password) {
      setLoading(true);
      request
        .post("/api/register")
        .send({
          Email: forgottenPasswordEmail.trim().toLowerCase(),
          Password: newPassword1,
          Name: Name.trim(),
          Surname: Surname.trim(),
          isMarketingSubscribed,
          subscriptionID: props.SelectedSubscriptionID,
          ReferralID: props.referralId,
          ReferralSource: props.referralSource,
        })
        .then((data) => {
          if (data.body == "already a mail") {
            setLoading(false);
            setForgottenPasswordEmailError("There is already an account linked to this email");
          } else if (JSON.parse(data.body).freeTrial) {
            const res = JSON.parse(data.body);

            if (global.onUserRegisterPending) {
              global.onUserRegisterPending(res.User_ID, forgottenPasswordEmail);
            }

            if (global.onSubscriptionUpdate) {
              global.onSubscriptionUpdate(
                res.Account_ID,
                res.User_ID,
                res.Subscription_ID,
                "Free_Trial",
                true,
              );
            }

            window.localStorage.setItem("User_ID", res.User_ID);
            window.location.href = "/SuccessPayment/1";
          } else {
            const res = JSON.parse(data.body);
            window.localStorage.setItem("Account_ID", res.Account_ID);
            window.localStorage.setItem("isVerified", false);
            window.localStorage.setItem("Email", forgottenPasswordEmail);
            window.localStorage.setItem("User_ID", res.User_ID);

            if (props.referralId) {
              window.localStorage.setItem("clickid", props.referralId);
            }

            if (props.referralSource) {
              window.localStorage.setItem("referralSource", props.referralSource);
            }

            if (global.onUserRegisterPending) {
              global.onUserRegisterPending(res.User_ID, forgottenPasswordEmail);
            }
            // window.open(res.url, '_self');
            window.Paddle.Checkout.open({
              items: [
                {
                  priceId: props.paddle_price,
                  quantity: 1,
                },
              ],
              customer: {
                email: forgottenPasswordEmail,
              },
              customData: {
                account_id: res.Account_ID,
                subscription_id: props.SelectedSubscriptionID,
              },
            });
          }
        });
    }
  };
  const registerAccountAppSumo = () => {
    let valid_firstName = validateFirstName();
    let valid_lastName = validateLastName();
    let valid_email = validateEmail();
    let valid_password = validateNewPasswords();
    let validate_coupon = validateCoupon();
    if (valid_firstName && valid_lastName && valid_email && valid_password && validate_coupon) {
      setLoading(true);
      request
        .post("/api/registerAppSumo")
        .send({
          Email: forgottenPasswordEmail.trim().toLowerCase(),
          Password: newPassword1,
          Name: Name.trim(),
          Surname: Surname.trim(),
          isMarketingSubscribed,
          Coupon,
        })
        .then((data) => {
          if (JSON.parse(data.text) == "already a mail") {
            setLoading(false);
            setForgottenPasswordEmailError("There is already an account linked to this email");
          } else if (JSON.parse(data.text) == "Invalid coupon") {
            setLoading(false);
            setCouponError(
              "Invalid code. Please enter a valid code. You can find the correct codes in your AppSumo account.",
            );
          } else if (JSON.parse(data.text) == "Used coupon") {
            setLoading(false);
            setCouponError(
              "This code has already been used. Please enter a valid code. You can find the correct codes in your AppSumo account.",
            );
          } else {
            const res = JSON.parse(data.body);
            window.localStorage.setItem("Account_ID", res.Account_ID);
            window.localStorage.setItem("isVerified", false);
            window.localStorage.setItem("Email", forgottenPasswordEmail);
            window.localStorage.setItem("User_ID", res.User_ID);
            window.location.href = "/verificationSent";
          }
        });
    }
  };
  const handleKeyDown = (e, submitFunction, defaultFunction) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      submitFunction();
    } else {
      defaultFunction && defaultFunction(e);
    }
  };

  const resendVerification = () => {
    request
      .post("/api/resendVerification")
      .send({ User_ID: parseInt(window.localStorage.getItem("User_ID")) })
      .then(() => {})
      .catch((e) => {
        console.error("error adding account", e);
      });
  };

  const Login = (
    <LoginWrapper>
      <LabelInputWrapper>
        <HeadingP1>Login to your account</HeadingP1>
        <TextP2>Email</TextP2>
        <Input2
          type="email"
          onChange={(e) => {
            if (emailError) {
              setEmailError("");
            }
            setEmail(e.target.value);
          }}
          errorMessage={emailError !== "" ? "Your email is not valid" : ""}
          value={email}
          placeholder="Enter your email"
        />
        <TextP2>Password</TextP2>

        <InputM
          onKeyDown={(e) => handleKeyDown(e, login)}
          type="password"
          value={password}
          onChange={(e) => {
            if (passwordError) {
              setPasswordError("");
            }
            setPassword(e.target.value);
          }}
          errorMessage={passwordError}
          allowEmpty
          placeholder="Enter your password"
        />
        <Tip>{tipText}</Tip>
        <CheckBoxMaterial
          label="Keep me logged in"
          size="medium"
          fontSize={"12px"}
          checked={keepMeLoggedIn}
          onChange={() => setKeepMeLoggedIn(!keepMeLoggedIn)}
        />
      </LabelInputWrapper>
      <ButtonWrapper style={{ marginTop: "5px" }}>
        <ButtonMui label="Login" width={180} variant={"contained"} onClick={login} />
        <ButtonMui
          label="Forgot Password?"
          variant={"text"}
          style={{ textDecoration: "underline" }}
          linkTo="/reset"
        />
      </ButtonWrapper>
      <VectorWrapper>
        <Vector />
      </VectorWrapper>
      <LabelInputWrapper>
        <TextP2>Don't have an account?</TextP2>
      </LabelInputWrapper>

      <ButtonBottomWrapper>
        <ButtonMui
          width={180}
          label="Register account"
          variant={"outlined"}
          linkTo={"/register" + props.qs}
        />
      </ButtonBottomWrapper>
    </LoginWrapper>
  );
  const Register = (
    <LoginWrapper>
      <LabelInputWrapper>
        <HeadingP1>Register Account</HeadingP1>
        <TextP2>Name</TextP2>
        <div style={{ display: "flex", columnGap: "10px" }}>
          <Input2
            onChange={(e) => {
              if (nameError) {
                setNameError("");
              }
              setName(e.target.value);
            }}
            errorMessage={nameError}
            value={Name}
            placeholder="First Name"
          />
          <Input2
            onChange={(e) => {
              if (surnameError) {
                setSurnameError("");
              }
              setSurname(e.target.value);
            }}
            errorMessage={surnameError}
            value={Surname}
            placeholder="Last Name"
            allowEmpty
          />
        </div>

        <TextP2>Email</TextP2>
        <Input2
          onChange={(e) => {
            if (forgottenPasswordEmailError) {
              setForgottenPasswordEmailError("");
            }
            setForgottenPasswordEmail(e.target.value);
          }}
          errorMessage={forgottenPasswordEmailError}
          value={forgottenPasswordEmail}
          error={!!forgottenPasswordEmailError}
          allowEmpty
          placeholder="Enter your email"
        />
        <div style={{ marginTop: "-2px" }}>
          <TextP2>
            Password <Mx_Tooltip description={<ValidationTextPassword />} />
          </TextP2>

          <InputM
            type="password"
            errorMessage={newPassword1Error ? newPassword1Error : ""}
            onChange={(e) => {
              if (newPassword1Error) {
                setNewPassword1Error("");
              }
              setNewPassword1(e.target.value);
            }}
            value={newPassword1}
            allowEmpty
            placeholder="Enter your password"
          />
          <span>
            <StrengthMeter password={newPassword1} />
          </span>

          <TextP2>Confirm Password</TextP2>

          <InputM
            onKeyDown={(e) => handleKeyDown(e, registerAccount)}
            type="password"
            errorMessage={newPassword2Error ? newPassword2Error : ""}
            value={newPassword2}
            onChange={(e) => {
              if (newPassword2Error) {
                setNewPassword2Error("");
              }
              setNewPassword2(e.target.value);
            }}
            allowEmpty
            placeholder="Confirm your password"
          />
        </div>
      </LabelInputWrapper>

      <ButtonWrapper small>
        <span>
          <TextP3>
            By clicking Register Account you confirm that you have read and agree to our{" "}
            <a href={modeliksLinks.termsOfService} target="_blank" rel="noreferrer">
              Terms and Conditions,
            </a>{" "}
            <a href={modeliksLinks.privacyPolicy} target="_blank" rel="noreferrer">
              Privacy Policy
            </a>{" "}
            and receiving product onboarding instructions via email.
          </TextP3>
          <div style={{ display: "flex", height: "75px", alignItems: "baseline" }}>
            <div style={{ marginRight: "-10px" }}>
              <CheckBoxMaterial
                value={isMarketingSubscribed}
                onChange={(e) => setIsMarketingSubscribed(!isMarketingSubscribed)}
                size="medium"
              />
            </div>
            <TextP3>Modeliks may keep me informed via email about products and services.</TextP3>
          </div>
          <ButtonMui
            width={180}
            label="Register account"
            variant={"contained"}
            onClick={() => registerAccount()}
          />
        </span>
      </ButtonWrapper>
      <div style={{ paddingTop: "3px" }}>
        <VectorWrapper>
          <Vector />
        </VectorWrapper>
      </div>
      <LabelInputWrapper>
        <TextP2>Already have an account?</TextP2>
      </LabelInputWrapper>

      <ButtonBottomWrapper>
        <ButtonMui label="Login" width={180} variant={"outlined"} linkTo={"/login"} />
      </ButtonBottomWrapper>
    </LoginWrapper>
  );

  const RegisterAppSumo = (
    <LoginWrapper>
      <LabelInputWrapper>
        <HeadingP1>Welcome Sumoling!</HeadingP1>
        <TextP3>Let's setup your Modeliks account.</TextP3>
        <TextP2>Name</TextP2>
        <div style={{ display: "flex", columnGap: "10px" }}>
          <Input2
            onChange={(e) => {
              if (nameError) {
                setNameError("");
              }
              setName(e.target.value);
            }}
            errorMessage={nameError}
            value={Name}
            placeholder="First Name"
          />
          <Input2
            onChange={(e) => {
              if (surnameError) {
                setSurnameError("");
              }
              setSurname(e.target.value);
            }}
            errorMessage={surnameError}
            value={Surname}
            placeholder="Last Name"
            allowEmpty
          />
        </div>

        <TextP2>Email</TextP2>
        <Input2
          onChange={(e) => {
            if (forgottenPasswordEmailError) {
              setForgottenPasswordEmailError("");
            }
            setForgottenPasswordEmail(e.target.value);
          }}
          errorMessage={forgottenPasswordEmailError}
          value={forgottenPasswordEmail}
          error={!!forgottenPasswordEmailError}
          allowEmpty
          placeholder="Enter your email"
        />
        <TextP2>AppSumo Code</TextP2>
        <Input2
          onChange={(e) => {
            if (couponError) {
              setCouponError("");
            }
            setCoupon(e.target.value);
          }}
          errorMessage={couponError}
          value={Coupon}
          error={!!couponError}
          allowEmpty
          placeholder="Enter your AppSumo code"
        />
        <div style={{ marginTop: "-2px" }}>
          <TextP2>
            Password <Mx_Tooltip description={<ValidationTextPassword />} />
          </TextP2>

          <InputM
            type="password"
            errorMessage={newPassword1Error ? newPassword1Error : ""}
            onChange={(e) => {
              if (newPassword1Error) {
                setNewPassword1Error("");
              }
              setNewPassword1(e.target.value);
            }}
            value={newPassword1}
            allowEmpty
            placeholder="Enter your password"
          />
          <span>
            <StrengthMeter password={newPassword1} />
          </span>

          <TextP2>Confirm Password</TextP2>

          <InputM
            onKeyDown={(e) => handleKeyDown(e, registerAccount)}
            type="password"
            errorMessage={newPassword2Error ? newPassword2Error : ""}
            value={newPassword2}
            onChange={(e) => {
              if (newPassword2Error) {
                setNewPassword2Error("");
              }
              setNewPassword2(e.target.value);
            }}
            allowEmpty
            placeholder="Confirm your password"
          />
        </div>
      </LabelInputWrapper>

      <ButtonWrapper small>
        <span>
          <TextP3>
            By clicking Register Account you confirm that you have read and agree to our{" "}
            <a href={modeliksLinks.termsOfService} target="_blank" rel="noreferrer">
              Terms and Conditions,
            </a>{" "}
            <a href={modeliksLinks.privacyPolicy} target="_blank" rel="noreferrer">
              Privacy Policy
            </a>{" "}
            and receiving product onboarding instructions via email.
          </TextP3>
          <div style={{ display: "flex", height: "75px", alignItems: "baseline" }}>
            <div style={{ marginRight: "-10px" }}>
              <CheckBoxMaterial
                value={isMarketingSubscribed}
                onChange={(e) => setIsMarketingSubscribed(!isMarketingSubscribed)}
                size="medium"
              />
            </div>
            <TextP3>Modeliks may keep me informed via email about products and services.</TextP3>
          </div>
          <ButtonMui
            width={180}
            label="Register account"
            variant={"contained"}
            onClick={() => registerAccountAppSumo()}
          />
        </span>
      </ButtonWrapper>
      <div style={{ paddingTop: "3px" }}>
        <VectorWrapper>
          <Vector />
        </VectorWrapper>
      </div>
      <LabelInputWrapper>
        <TextP2>Already have an account?</TextP2>
      </LabelInputWrapper>

      <ButtonBottomWrapper>
        <ButtonMui label="Login" width={180} variant={"outlined"} linkTo={"/login"} />
      </ButtonBottomWrapper>
    </LoginWrapper>
  );

  const registerInvitedUser = () => {
    let valid_firstName = validateFirstName();
    let valid_lastName = validateLastName();
    let valid_password = validateNewPasswords();
    if (valid_firstName && valid_lastName && valid_password) {
      props.updateInvitedUser({
        name: Name,
        surname: Surname,
        password: newPassword1,
        isMarketingSubscribed,
      });
    }
  };

  const InviteRegister = (
    <LoginWrapper>
      <LabelInputWrapper>
        <HeadingP1>{props.mainUserEmail} has invited you to Modeliks</HeadingP1>
        {/*<TextP3>To get started, <a href='#' target='_blank'>sing-in</a> or create and account.</TextP3>*/}
        <TextP2>Name</TextP2>
        <div style={{ display: "flex", gap: "5px" }}>
          <Input2
            onChange={(e) => {
              if (nameError) {
                setNameError("");
              }
              setName(e.target.value);
            }}
            errorMessage={nameError}
            value={Name}
            placeholder="First Name"
          />
          <Input2
            onChange={(e) => {
              if (surnameError) {
                setSurnameError("");
              }
              setSurname(e.target.value);
            }}
            errorMessage={surnameError}
            value={Surname}
            placeholder="Last Name"
          />
        </div>

        <TextP2>Email</TextP2>
        <Input2
          onChange={(e) => e.preventDefault()}
          disabled={true}
          value={props.email}
          placeholder="Enter your email"
        />
        <div style={{ marginTop: "-2px" }}>
          <TextP2>
            Password <Mx_Tooltip description={<ValidationTextPassword />} />
          </TextP2>

          <InputM
            type="password"
            errorMessage={newPassword1Error ? newPassword1Error : ""}
            error={!!newPassword1Error}
            onChange={(e) => {
              if (newPassword1Error) {
                setNewPassword1Error("");
              }
              setNewPassword1(e.target.value);
            }}
            value={newPassword1}
            placeholder="Enter your password"
          />

          <span>
            <StrengthMeter password={newPassword1} />
          </span>

          <TextP2>Confirm Password</TextP2>
          <InputM
            onKeyDown={(e) => handleKeyDown(e, registerInvitedUser)}
            type="password"
            errorMessage={newPassword2Error ? newPassword2Error : ""}
            value={newPassword2}
            onChange={(e) => {
              if (newPassword2Error) {
                setNewPassword2Error("");
              }
              setNewPassword2(e.target.value);
            }}
            placeholder="Confirm your password"
          />
        </div>
      </LabelInputWrapper>

      <ButtonWrapper small>
        <span>
          <TextP3>
            By clicking Register Account you confirm that you have read and agree to our{" "}
            <a href={modeliksLinks.termsOfService} target="_blank" rel="noreferrer">
              Terms and Conditions,
            </a>{" "}
            <a href={modeliksLinks.privacyPolicy} target="_blank" rel="noreferrer">
              Privacy Policy
            </a>{" "}
            and receiving product onboarding instructions via email.
          </TextP3>
          <div style={{ display: "flex", height: "75px", alignItems: "baseline" }}>
            <div style={{ marginRight: "-10px" }}>
              <CheckBoxMaterial
                value={isMarketingSubscribed}
                onChange={(e) => setIsMarketingSubscribed(!isMarketingSubscribed)}
                size="medium"
              />
            </div>
            <TextP3>Modeliks may keep me informed via email about products and services.</TextP3>
          </div>
          <ButtonMui
            width={180}
            label="Register account"
            variant={"contained"}
            onClick={registerInvitedUser}
          />
        </span>
      </ButtonWrapper>
      {/*<div style={{paddingTop: '3px'}}>*/}
      {/*<VectorWrapper>*/}
      {/*<Vector/>*/}
      {/*</VectorWrapper>*/}
      {/*</div>*/}
      {/*<LabelInputWrapper>*/}
      {/*<TextP2>Already have an account?</TextP2>*/}
      {/*</LabelInputWrapper>*/}

      {/*<ButtonBottomWrapper>*/}
      {/*<Link style={{textDecoration: 'none'}} to='/'>*/}
      {/*<Button label='Login' padding='7px 81px' color='primary' size='large' noElevation={true}*/}
      {/*outlined='primary'/>*/}
      {/*</Link>*/}
      {/*</ButtonBottomWrapper>*/}
    </LoginWrapper>
  );
  const ForgottenPassword = (
    <LoginWrapper>
      <LabelInputWrapper>
        <HeadingP1>Reset your password</HeadingP1>
        <TextP3>
          Enter your email address below, and we will send you a link to reset your password. Please
          be sure that the email address you enter is the same one associated with your user profile
          — that's a common mistake.
        </TextP3>

        <div style={{ marginTop: "22px" }}>
          <TextP2>Email</TextP2>
          <Input2
            value={forgottenPasswordEmail}
            onKeyDown={(e) => handleKeyDown(e, forgottenPassword)}
            errorMessage={forgottenPasswordEmailError ? forgottenPasswordEmailError : ""}
            onChange={(e) => {
              if (forgottenPasswordEmailError) {
                setForgottenPasswordEmailError("");
              }
              setForgottenPasswordEmail(e.target.value);
            }}
            placeholder="Enter your email"
          />
        </div>
        <Tip>{tipText}</Tip>
      </LabelInputWrapper>
      <div style={{ marginBottom: "-10px" }}>
        <ButtonWrapper>
          <ButtonMui
            width={150}
            label="Send"
            variant={"contained"}
            onClick={() => forgottenPassword()}
          />
        </ButtonWrapper>
      </div>
      <VectorWrapper>
        <Vector />
      </VectorWrapper>
      <ButtonBottomWrapper>
        <div style={{ marginTop: "-18px", marginBottom: "-14px" }}>
          <ButtonMui
            width={150}
            label="Back to Login"
            variant={"text"}
            startIcon={<ArrowLeft />}
            linkTo={"/login" + props.qs}
          />
        </div>
      </ButtonBottomWrapper>
    </LoginWrapper>
  );
  const NewPassword = (
    <LoginWrapper>
      <LabelInputWrapper>
        <HeadingP1>New Password</HeadingP1>
        <TextP3>
          To reset your password, just enter a new password below. Please type it twice to make sure
          we get it right.
        </TextP3>
        <TextP2>
          Password <Mx_Tooltip description={<ValidationTextPassword />} />
        </TextP2>

        <InputM
          type="password"
          value={newPassword1}
          errorMessage={newPassword1Error ? newPassword1Error : ""}
          onChange={(e) => {
            if (newPassword1Error) {
              setNewPassword1Error("");
            }
            setNewPassword1(e.target.value);
          }}
          placeholder="Enter your new password"
        />

        <span>
          <StrengthMeter password={newPassword1} />
        </span>
        <TextP2>Confirm Password</TextP2>

        <InputM
          type="password"
          value={newPassword2}
          errorMessage={newPassword2Error ? newPassword2Error : ""}
          onKeyDown={(e) => handleKeyDown(e, newPassword)}
          onChange={(e) => {
            if (newPassword2Error) {
              setNewPassword2Error("");
            }
            setNewPassword2(e.target.value);
          }}
          placeholder="Confirm new password"
        />
      </LabelInputWrapper>

      <div style={{ marginTop: "-9px", paddingBottom: "10px" }}>
        <ButtonWrapper>
          <ButtonMui
            width={180}
            label="Set Password"
            variant={"contained"}
            onClick={() => newPassword()}
          />
        </ButtonWrapper>
      </div>
    </LoginWrapper>
  );
  const Loader = (
    <LoginWrapper>
      <div className="auth_page_container loader_container">
        <CircleLoader />
      </div>
    </LoginWrapper>
  );
  const loginWithAccount = (index, data = ReqData) => {
    const data_body = JSON.parse(JSON.stringify(data.body));
    global.Modeliks.isAdmin = data_body.Accounts[index].isAdmin;
    setLoading(true);
    setChooseAccount(false);
    window.localStorage.setItem("User_ID", data_body.ID);
    window.localStorage.setItem("token", data_body.Accounts[index].token);
    let shouldInit = true;
    if (data.body.Accounts[index].ID) {
      window.localStorage.setItem("Account_ID", data_body.Accounts[index].ID);
      global.Modeliks.Account = { ID: data_body.Accounts[index].ID };
      request
        .get(`/api/checkIfShouldInit?AccountID=${data_body.Accounts[index].ID}`)
        .then((result) => {
          global.Modeliks.Login(data.body, result.body);
        })
        .catch((e) => {
          console.error("error adding account", e);
        });
    } else {
      global.Modeliks.Login(data.body, false);
    }
  };
  const createNewAccount = () => {
    if (!accounts.length && userID) {
      setLoading(true);
      request
        .post("/api/insertAccount")
        .send({ User_ID: userID })
        .then(() => {
          login();
        })
        .catch((e) => {
          console.error("error adding account", e);
        });
    }
  };
  const ChooseAccount = (
    <LoginWrapper>
      <div className="choose_account_title">
        <span className="Paragraph P1_Strong">Choose account</span>
      </div>
      <div className="auth_page_container choose_account">
        <div className="auth_page_container_scroll choose_account">
          {accounts.map((account, index) => (
            <MuiButton
              key={"user_acc_" + index}
              onClick={() => loginWithAccount(index)}
              type="text"
              className={"account_card auth_page_accounts_container"}
            >
              <div className="icon_container_auth">
                <Avatar />
                <span
                  className={`Paragraph P3_Strong ${account.isSubscriptionActive ? "account_active" : ""}`}
                >
                  {account.Name ? account.Name : "Account " + Number(index + 1)}
                </span>
              </div>
              <GetUserTypeIcon UserType={account.UserType} />
            </MuiButton>
          ))}
        </div>
        {!accounts.length && (
          <MuiButton onClick={createNewAccount} type="text" className={"new_acc_btn"}>
            <BluePlus />
            Create new Account
          </MuiButton>
        )}
      </div>
    </LoginWrapper>
  );
  const EmailSent = (
    <LoginWrapper>
      <LabelInputWrapper>
        <HeadingP1>Email sent</HeadingP1>
        <TextP3>We sent you an email containing a link to reset your password.</TextP3>
      </LabelInputWrapper>

      <VectorWrapper reset={true}>
        <Vector />
      </VectorWrapper>

      <ButtonBottomWrapper margin="12.5px  0 12px">
        <div style={{ marginLeft: "10px" }}>
          <ButtonMui
            width={150}
            label="Back to Login"
            variant={"text"}
            startIcon={<ArrowLeft />}
            linkTo={"/login"}
          />
        </div>
      </ButtonBottomWrapper>
    </LoginWrapper>
  );
  const verificationSent = (
    <LoginWrapper>
      <LabelInputWrapper>
        <HeadingP1Strong>Check Your Email Inbox</HeadingP1Strong>
        <TextP3Strong>
          We sent you an email containing a link to activate your account.
        </TextP3Strong>
        <TextP3Strong>Once activated, you'll be able to continue.</TextP3Strong>
        <TextTip>Tip: Check your spam folder in case the email was incorrectly identified</TextTip>
        <div style={{ display: "inline-flex", alignItems: "center", columnGap: "5px" }}>
          <TextP3Strong>Didn't receive an email?</TextP3Strong>
          <ButtonMui
            style={{ textDecoration: "underline" }}
            label={"Resend"}
            variant={"text"}
            onClick={resendVerification}
          />
        </div>
      </LabelInputWrapper>

      <VectorWrapper reset={true}>
        <Vector />
      </VectorWrapper>

      <ButtonBottomWrapper margin="12.5px  0 12px">
        <div style={{ marginLeft: "10px" }}>
          <ButtonMui
            width={150}
            label="Back to Login"
            variant={"text"}
            startIcon={<ArrowLeft />}
            linkTo={"/login"}
          />
        </div>
      </ButtonBottomWrapper>
    </LoginWrapper>
  );
  const SuccesVerification = (
    <LoginWrapper>
      <LabelInputWrapper>
        <HeadingP1>Email verification</HeadingP1>

        <TextP3>You successfully verified your email.</TextP3>
      </LabelInputWrapper>

      <div style={{ marginTop: "42px", marginBottom: "-22px" }}>
        <VectorWrapper reset={true}>
          <Vector />
        </VectorWrapper>
      </div>
      <div style={{ marginLeft: "-5px", marginTop: "-27px" }}>
        <ButtonWrapper small={true}>
          <ButtonMui
            width={150}
            label="Back"
            variant={"text"}
            startIcon={<ArrowLeft />}
            linkTo={"/login"}
          />
        </ButtonWrapper>
      </div>
    </LoginWrapper>
  );
  const FailedVerification = (
    <LoginWrapper>
      <LabelInputWrapper>
        <HeadingP1>Email verification failed</HeadingP1>
        <TextP3>Invalid verification ID.</TextP3>
      </LabelInputWrapper>

      <div style={{ marginTop: "42px", marginBottom: "-22px" }}>
        <VectorWrapper reset={true}>
          <Vector />
        </VectorWrapper>
      </div>
      <div style={{ marginLeft: "-5px", marginTop: "-27px" }}>
        <ButtonWrapper small={true}>
          <ButtonMui
            width={150}
            label="Back"
            variant={"text"}
            startIcon={<ArrowLeft />}
            linkTo={"/login"}
          />
        </ButtonWrapper>
      </div>
    </LoginWrapper>
  );

  const whatToRender = () => {
    if (!loading && !chooseAccount) {
      switch (type) {
        case "login":
          return Login;
        case "register":
          return Register;
        case "register-appsumo":
          return RegisterAppSumo;
        case "invite-register":
          return InviteRegister;
        case "forgottenPassword":
          return ForgottenPassword;
        case "emailSent":
          return EmailSent;
        case "failed_verification":
          return FailedVerification;
        case "verification":
          return SuccesVerification;
        case "newPassword":
          return NewPassword;
        case "verificationSent":
          return verificationSent;
      }
    } else if (chooseAccount) {
      return ChooseAccount;
    } else return Loader;
  };

  return (
    <>
      <BodyWrapper>
        <LogoWrapper onClick={() => (window.location.href = "https://www.modeliks.com/")}>
          {props.showAppSumoLogo ? (
            <div className={"appsumo_logo_container_main"}>
              <div className={"appsumo_logo_container"}>
                <AppSumoLogo width="46.42px" height="80px" />
              </div>
              <div className={"plus_container"}>+</div>
            </div>
          ) : null}
          <LogoWithName />
        </LogoWrapper>
        {whatToRender()}
      </BodyWrapper>
      <BottomWrapper>
        © Copyright - Modeliks Ltd {new Date().getFullYear()}. All Rights Reserved.
      </BottomWrapper>
    </>
  );
}

AuthPage.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
};

const LogoWithName = () => (
  <svg height="48px" viewBox="0 0 1908 462" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1_13)">
      <path
        d="M436.78 339.441C402.679 339.441 375.047 366.867 375.047 400.714C375.047 434.561 402.679 461.987 436.78 461.987C470.881 461.987 498.513 434.561 498.513 400.714C498.513 366.867 470.881 339.441 436.78 339.441Z"
        fill="url(#paint0_linear_1_13)"
      />
      <path
        d="M460.564 0C459.764 0 458.986 0 458.186 0.0661932C457.408 0.0220645 456.607 0 455.807 0H275.276C250.378 0 230.171 20.0345 230.171 44.7689C230.171 69.4812 250.356 89.538 275.276 89.538H339.21L272.964 155.29L223.502 106.196C185.933 68.9076 129.913 57.8311 80.8068 78.0198C31.7224 98.187 0 145.317 0 198.073V394.822C0 424.411 24.1642 448.417 53.9971 448.417C83.8074 448.417 107.994 424.433 107.994 394.822V198.073C107.994 184.812 116.886 179.208 122.177 177.023C127.468 174.839 137.76 172.61 147.186 181.966L273.008 306.829L415.481 165.418V223.955C415.481 248.667 435.667 268.724 460.586 268.724C485.485 268.724 505.691 248.689 505.691 223.955V53.6829V44.7689C505.691 20.0566 485.507 0 460.586 0H460.564Z"
        fill="url(#paint1_linear_1_13)"
      />
      <path
        d="M875.928 195.404C886.776 206.789 892.206 222.367 892.206 242.114V327.107C892.206 328.364 891.18 329.379 889.914 329.379H841.788C840.516 329.379 839.496 328.364 839.496 327.107V249.043C839.496 241.033 837.252 234.811 832.782 230.354C828.294 225.919 822.198 223.69 814.488 223.69C806.772 223.69 800.682 225.919 796.188 230.354C791.7 234.811 789.48 241.033 789.48 249.043V327.107C789.48 328.364 788.454 329.379 787.188 329.379H739.062C737.79 329.379 736.77 328.364 736.77 327.107V249.043C736.77 241.033 734.568 234.811 730.188 230.354C725.79 225.919 719.742 223.69 712.05 223.69C704.358 223.69 697.98 225.919 693.486 230.354C688.998 234.789 686.772 241.033 686.772 249.043V327.107C686.772 328.364 685.752 329.379 684.486 329.379H636.354C635.088 329.379 634.068 328.364 634.068 327.107V181.922C634.068 180.665 635.088 179.65 636.354 179.65H684.486C685.752 179.65 686.772 180.665 686.772 181.922V193.087C686.772 195.139 689.31 196.154 690.708 194.653C694.686 190.417 699.426 186.887 704.916 184.063C712.362 180.245 720.918 178.325 730.59 178.325C741.702 178.325 751.596 180.731 760.314 185.541C767.982 189.777 774.228 195.558 779.052 202.84C779.964 204.208 781.944 204.208 782.922 202.906C788.01 196.043 794.412 190.395 802.128 185.938C810.906 180.863 820.578 178.325 831.162 178.325C850.164 178.325 865.08 184.018 875.928 195.404Z"
        fill="black"
      />
      <path
        d="M950.688 321.921C938.772 315.698 929.394 306.806 922.59 295.223C915.762 283.661 912.366 270.047 912.366 254.381C912.366 238.715 915.81 225.389 922.722 213.805C929.616 202.243 939.042 193.351 950.952 187.107C962.868 180.884 976.362 177.773 991.416 177.773C1006.46 177.773 1019.96 180.884 1031.87 187.107C1043.79 193.329 1053.22 202.243 1060.1 213.805C1067 225.367 1070.47 238.892 1070.47 254.381C1070.47 269.87 1067.02 283.441 1060.1 295.09C1053.19 306.74 1043.74 315.677 1031.74 321.921C1019.74 328.143 1006.19 331.254 991.122 331.254C976.05 331.254 962.58 328.143 950.664 321.921H950.688ZM1009.58 277.748C1014.51 272.32 1016.98 264.531 1016.98 254.404C1016.98 244.276 1014.51 236.531 1009.58 231.191C1004.64 225.852 998.592 223.182 991.434 223.182C984.276 223.182 978.252 225.852 973.428 231.191C968.604 236.531 966.162 244.276 966.162 254.404C966.162 264.531 968.496 272.563 973.164 277.88C977.832 283.22 983.832 285.89 991.17 285.89C998.508 285.89 1004.66 283.175 1009.6 277.748H1009.58Z"
        fill="black"
      />
      <path
        d="M1091.84 213.827C1097.49 202.265 1105.25 193.373 1115.09 187.129C1124.95 180.907 1135.88 177.796 1147.91 177.796C1157.58 177.796 1166.23 179.804 1173.85 183.797C1179.38 186.71 1184.05 190.395 1187.83 194.852C1189.17 196.418 1191.73 195.47 1191.73 193.44V134.086C1191.73 132.872 1192.72 131.902 1193.92 131.902H1242.47C1243.7 131.902 1244.68 132.895 1244.68 134.086V327.195C1244.68 328.408 1243.67 329.379 1242.47 329.379H1193.92C1192.7 329.379 1191.73 328.386 1191.73 327.195V315.523C1191.73 313.471 1189.12 312.544 1187.81 314.132C1184.17 318.523 1179.65 322.186 1174.25 325.121C1166.71 329.225 1157.84 331.255 1147.62 331.255C1135.61 331.255 1124.72 328.144 1114.94 321.922C1105.16 315.699 1097.47 306.763 1091.82 295.091C1086.17 283.441 1083.35 269.871 1083.35 254.382C1083.35 238.892 1086.17 225.389 1091.82 213.805L1091.84 213.827ZM1184.08 231.7C1178.78 226.183 1172.29 223.426 1164.58 223.426C1156.87 223.426 1150.15 226.139 1144.95 231.567C1139.75 236.995 1137.15 244.607 1137.15 254.382C1137.15 264.157 1139.75 271.592 1144.95 277.196C1150.15 282.801 1156.69 285.603 1164.58 285.603C1172.47 285.603 1178.78 282.845 1184.08 277.329C1189.37 271.813 1192.01 264.157 1192.01 254.382C1192.01 244.607 1189.37 237.216 1184.08 231.7Z"
        fill="black"
      />
      <path
        d="M1413.44 265.061H1324.32C1321.03 265.061 1318.54 268.039 1319.21 271.238C1320.3 276.38 1322.3 280.417 1325.26 283.352C1329.28 287.346 1334.44 289.354 1340.73 289.354C1348.49 289.354 1354.27 286.309 1358.11 280.219C1359.11 278.63 1360.87 277.615 1362.76 277.615H1408.88C1412.44 277.615 1414.98 281.08 1413.85 284.411C1411.27 292.024 1407.37 298.952 1402.18 305.218C1395.46 313.316 1386.99 319.67 1376.77 324.304C1366.54 328.937 1355.24 331.254 1342.88 331.254C1328.02 331.254 1314.79 328.143 1303.22 321.921C1291.67 315.698 1282.62 306.806 1276.06 295.223C1269.53 283.661 1266.26 270.047 1266.26 254.381C1266.26 238.715 1269.48 225.146 1275.93 213.673C1282.37 202.199 1291.4 193.351 1302.96 187.107C1314.52 180.884 1327.84 177.773 1342.88 177.773C1357.94 177.773 1370.94 180.796 1382.41 186.842C1393.88 192.887 1402.84 201.559 1409.31 212.856C1415.75 224.153 1418.98 237.458 1418.98 252.749C1418.98 255.242 1418.89 257.735 1418.69 260.228C1418.47 262.921 1416.2 265.016 1413.49 265.016L1413.44 265.061ZM1359.25 239.708C1362.71 239.708 1365.25 236.399 1364.27 233.111C1363.29 229.823 1361.4 226.911 1358.71 224.638C1354.4 220.998 1349.04 219.166 1342.57 219.166C1336.1 219.166 1330.84 220.91 1326.7 224.374C1324.21 226.47 1322.26 229.162 1320.81 232.449C1319.36 235.737 1321.81 239.731 1325.59 239.731H1359.25V239.708Z"
        fill="black"
      />
      <path
        d="M1493.18 154.651V306.631C1493.18 319.207 1482.91 329.379 1470.26 329.379H1463.41C1450.74 329.379 1440.49 319.186 1440.49 306.631V154.651C1440.49 142.073 1450.76 131.902 1463.41 131.902H1470.26C1482.93 131.902 1493.18 142.096 1493.18 154.651Z"
        fill="black"
      />
      <path
        d="M1527.46 158.18C1521.81 153.106 1518.98 146.751 1518.98 139.095C1518.98 131.438 1521.81 124.775 1527.46 119.612C1533.1 114.449 1540.59 111.867 1549.91 111.867C1559.22 111.867 1566.44 114.449 1572.09 119.612C1577.74 124.775 1580.56 131.262 1580.56 139.095C1580.56 146.927 1577.74 153.106 1572.09 158.18C1566.44 163.255 1559.05 165.793 1549.91 165.793C1540.77 165.793 1533.1 163.255 1527.46 158.18ZM1575.98 202.42V306.631C1575.98 319.207 1565.71 329.379 1553.06 329.379H1546.22C1533.55 329.379 1523.3 319.185 1523.3 306.631V202.42C1523.3 189.843 1533.57 179.671 1546.22 179.671H1553.06C1565.74 179.671 1575.98 189.865 1575.98 202.42Z"
        fill="black"
      />
      <path
        d="M1697.2 324.547L1679.02 297.761C1672.93 288.781 1658.81 293.061 1658.81 303.895V318.413C1658.81 324.481 1653.85 329.379 1647.76 329.379H1617.15C1611.04 329.379 1606.1 324.459 1606.1 318.413V142.846C1606.1 136.778 1611.06 131.88 1617.15 131.88H1647.76C1653.88 131.88 1658.81 136.8 1658.81 142.846V203.766C1658.81 214.468 1672.68 218.836 1678.88 210.076L1697.18 184.305C1699.25 181.393 1702.63 179.65 1706.2 179.65H1740.97C1750.18 179.65 1755.35 190.174 1749.67 197.389L1709.65 248.05C1706.47 252.065 1706.51 257.736 1709.74 261.707L1750.22 311.529C1756.04 318.7 1750.91 329.379 1741.61 329.379H1706.34C1702.67 329.379 1699.22 327.57 1697.18 324.547H1697.2Z"
        fill="black"
      />
      <path
        d="M1807.91 324.436C1797.23 319.891 1788.81 313.669 1782.63 305.747C1780.74 303.343 1779.12 300.827 1777.74 298.223C1773.1 289.442 1779.52 278.917 1789.5 278.917H1813.71C1818.55 278.917 1823.14 281.3 1825.64 285.426C1826.76 287.258 1828.25 288.824 1830.09 290.126C1833.85 292.796 1838.51 294.119 1844.08 294.119C1848.39 294.119 1851.79 293.237 1854.3 291.45C1856.81 289.685 1858.06 287.368 1858.06 284.522C1858.06 280.793 1855.99 278.035 1851.88 276.247C1847.74 274.46 1840.94 272.519 1831.45 270.378C1820.69 268.238 1811.73 265.833 1804.55 263.163C1797.37 260.494 1791.14 256.147 1785.85 250.079C1780.56 244.033 1777.92 235.847 1777.92 225.521C1777.92 216.629 1780.34 208.576 1785.19 201.361C1790.03 194.146 1797.14 188.408 1806.57 184.15C1815.98 179.87 1827.31 177.751 1840.58 177.751C1860.3 177.751 1875.82 182.561 1887.11 192.16C1893.58 197.676 1898.51 204.185 1901.85 211.687C1905.72 220.336 1899.19 230.066 1889.65 230.066H1868.26C1863.61 230.066 1859.19 227.705 1856.88 223.69C1855.84 221.836 1854.48 220.292 1852.81 218.99C1849.5 216.409 1845.05 215.129 1839.5 215.129C1835.18 215.129 1831.91 215.923 1829.69 217.534C1827.44 219.145 1826.33 221.351 1826.33 224.197C1826.33 227.75 1828.4 230.464 1832.51 232.339C1836.63 234.215 1843.27 236.112 1852.41 238.076C1863.35 240.392 1872.44 242.974 1879.71 245.821C1886.98 248.667 1893.34 253.256 1898.81 259.567C1904.27 265.877 1907.01 274.46 1907.01 285.316C1907.01 294.031 1904.45 301.864 1899.34 308.792C1894.22 315.721 1886.98 321.193 1877.56 325.208C1868.15 329.202 1857.08 331.21 1844.34 331.21C1830.71 331.21 1818.58 328.937 1807.91 324.414V324.436Z"
        fill="black"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1_13"
        x1="498.513"
        y1="400.714"
        x2="375.047"
        y2="400.714"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00B2FF" />
        <stop offset="0.22" stopColor="#00AEFC" />
        <stop offset="0.39" stopColor="#01A5F5" />
        <stop offset="0.54" stopColor="#0494E9" />
        <stop offset="0.68" stopColor="#087DD8" />
        <stop offset="0.82" stopColor="#0C60C2" />
        <stop offset="0.95" stopColor="#123CA8" />
        <stop offset="1" stopColor="#152A9B" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1_13"
        x1="505.669"
        y1="224.197"
        x2="0"
        y2="224.197"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00B2FF" />
        <stop offset="0.22" stopColor="#00AEFC" />
        <stop offset="0.39" stopColor="#01A5F5" />
        <stop offset="0.54" stopColor="#0494E9" />
        <stop offset="0.68" stopColor="#087DD8" />
        <stop offset="0.82" stopColor="#0C60C2" />
        <stop offset="0.95" stopColor="#123CA8" />
        <stop offset="1" stopColor="#152A9B" />
      </linearGradient>
      <clipPath id="clip0_1_13">
        <rect width="1908" height="462" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const checkForMaliciousCharacters = (string) =>
  string.includes("/") || string.includes("<") || string.includes(">");

export default AuthPage;
