import React from "react";
import "./CircleLoader.scss";

export default function CircleLoader(props) {
  return (
    <div
      className={`loader_container_main ${navigator.saysWho.includes("Firefox ") && "loader_firefox"}`}
    >
      {getSVG(true)}
      {props.message && <div className={"loader_message p p1 strong title"}>{props.message}</div>}
    </div>
  );
}

const getSVG = (secondLoader) => {
  if (secondLoader) {
    return (
      <svg
        className={"loader_border_radius"}
        width="150"
        height="150"
        viewBox="0 0 150 150"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.5"
          d="M75 12.5C62.6387 12.5 50.555 16.1656 40.2769 23.0331C29.9988 29.9007 21.988 39.6619 17.2576 51.0823C12.5271 62.5027 11.2894 75.0693 13.7009 87.1931C16.1125 99.317 22.0651 110.453 30.8059 119.194C39.5466 127.935 50.6831 133.887 62.8069 136.299C74.9307 138.711 87.4974 137.473 98.9178 132.742C110.338 128.012 120.099 120.001 126.967 109.723C133.834 99.4451 137.5 87.3613 137.5 75C137.5 66.7924 135.883 58.6651 132.743 51.0823C129.602 43.4994 124.998 36.6095 119.194 30.8058C113.391 25.0022 106.501 20.3984 98.9178 17.2575C91.3349 14.1166 83.2076 12.5 75 12.5ZM75 125C65.111 125 55.444 122.068 47.2215 116.573C38.9991 111.079 32.5904 103.27 28.8061 94.1342C25.0217 84.9979 24.0315 74.9445 25.9608 65.2455C27.89 55.5464 32.6521 46.6373 39.6447 39.6447C46.6373 32.652 55.5465 27.89 65.2455 25.9607C74.9446 24.0315 84.9979 25.0216 94.1342 28.806C103.271 32.5904 111.079 38.999 116.574 47.2215C122.068 55.4439 125 65.1109 125 75C125 88.2608 119.732 100.979 110.355 110.355C100.979 119.732 88.2609 125 75 125Z"
          fill="#CBEFFF"
        />
        <path
          d="M124.61 68.766C125.04 72.1909 127.798 75 131.25 75C134.702 75 137.532 72.1949 137.188 68.7604C136.579 62.6932 135.084 56.7364 132.742 51.0823C129.602 43.4994 124.998 36.6095 119.194 30.8058C113.391 25.0022 106.501 20.3984 98.9177 17.2575C93.2636 14.9155 87.3068 13.421 81.2396 12.8122C77.8051 12.4676 75 15.2982 75 18.75C75 22.2018 77.8091 24.9597 81.234 25.39C92.1962 26.7672 102.46 31.7494 110.355 39.6447C118.251 47.5399 123.233 57.8038 124.61 68.766Z"
          fill="#01AFFD"
        />
      </svg>
    );
  }
  return (
    <svg
      className={"loader"}
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        style={{ maskType: "alpha" }}
        id="mask0_515_34847"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="100"
        height="100"
      >
        {/*<circle cx="50" cy="50" r="45" stroke="#01AFFD" strokeWidth="10"/>*/}
      </mask>
      <g mask="url(#mask0_515_34847)">
        <path className={"blue_loader_part"} d="M51 51V-16.5L137 51H51Z" fill="#01AFFD" />
      </g>
    </svg>
  );
};
