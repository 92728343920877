import React, { useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "./Demo.css";

const defaultSrc =
  "https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg";

export const Demo = (props) => {
  const [image, setImage] = useState(props.value);
  const [cropData, setCropData] = useState("#");
  const [cropper, setCropper] = useState();
  const onChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const getCropData = (e) => {
    if (typeof cropper !== "undefined") {
      setCropData(cropper.getCroppedCanvas().toDataURL());
      props.setImageSrc(
        cropper.getCroppedCanvas().toDataURL(),
        parseInt(cropper.cropBoxData.width),
        parseInt(cropper.cropBoxData.height),
      );
      props.handleCrop();
    }
  };

  return (
    <div style={{ width: "100%", height: "100%", position: "relative" }}>
      <div style={{ position: "absolute", left: 0, top: "-60px" }}>
        <div
          className="box"
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            gap: "15px",
            flexDirection: "row",
          }}
        >
          <button className="crop_button" onClick={getCropData}>
            Crop Image
          </button>
          <button className="crop_button" onClick={props.handleCrop}>
            Cancel
          </button>
        </div>
      </div>
      <div style={{ ...props.style, width: "100%", height: "100%" }}>
        <Cropper
          style={{ height: "100%", width: "100%" }}
          zoomTo={0.5}
          initialAspectRatio={1}
          preview=".img-preview"
          src={image}
          rotateTo={parseInt(props.rotateAngle)}
          viewMode={1}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={1}
          checkOrientation={true} // https://github.com/fengyuanchen/cropperjs/issues/671
          onInitialized={(instance) => {
            setCropper(instance);
            // document.addEventListener('mousedown',props.handleCrop)
          }}
          guides={true}
        />
      </div>
    </div>
  );
};

export default Demo;
