import React from "react";

import Box from "@mui/material/Box";

class LeftMenuResponsiveBox extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.addEventListener("resize", this.refreshSize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.refreshSize);
  }

  shouldComponentUpdate(nextProps) {
    return this.props.children != nextProps;
  }

  refreshSize = () => {
    this.forceUpdate();
  };

  getHeight = () => {
    const offset = this.props.offsetHeight ?? 0;
    if (this.props.admin) {
      return window.innerHeight - 270;
    }
    return window.innerHeight - (210 + offset);
  };

  render() {
    return (
      <Box
        sx={{
          bgcolor: "background.paper",
          display: "flex",
          flexDirection: "column",
          height: this.getHeight(),
          width: "230px",
        }}
      >
        {this.props.children}
      </Box>
    );
  }
}

export default LeftMenuResponsiveBox;
