import React from "react";
import "../../../SlideEditor/App.scss";
import SlideComponent from "../../../SlideEditor/components/SlideComponent";
import {
  SlideObject,
  SlideTypes,
} from "../../../SlideEditor/components/SlideObjects/SlideStructures";
import Box from "@mui/material/Box";
import Footer from "../../../SlideEditor/components/components/Footer/ScalePanelFooter";
import request from "superagent";

// import '../components/MenuObject.scss';
import html2canvas from "html2canvas";

import RightMenu from "../../../SlideEditor/components/components/RightMenuComponents/RightMenu";
// import ButtonJ from '../components/components/Buttons/Button';
import LeftMenu from "../../../SlideEditor/components/components/menus/LeftMenu/LeftMenu";
// import PrintablePortal from "../components/components/Portals/printablePortal";
import { Logo } from "../../../components/icons/svgIcons";
import ChartsComponent from "../../../SlideEditor/components/ChartsComponent";
import Chart from "../../../SlideEditor/Admin/Charts/Chart";
import BarChart from "../../../SlideEditor/Admin/Charts/BarChart";
import StackedBarChart from "../../../SlideEditor/Admin/Charts/StackedBarChart";
import WaterfallChart from "../../../SlideEditor/Admin/Charts/WaterfallChart";
import HorizontalBarChart from "../../../SlideEditor/Admin/Charts/HorizontalBarChart";
import LineChart from "../../../SlideEditor/Admin/Charts/LineChart";
import { ChartTypes } from "../../../components/constants/charts";
import Button from "../../../components/actions/Button";

const scale = {
  enabled: false,
  rightMenu: true,
  textObjs: [],
};

class ScreenTypes {
  constructor(props) {
    this.ScreenName = props.ScreenName;
    this.draggable = props.draggable;
    this.TabKey = props.TabKey;
  }
}

class ChartEditor extends React.Component {
  CompanyInfo = [];
  Account = null;
  didMount = false;

  constructor(props) {
    super(props);

    this.EditingScreens = [
      new ScreenTypes({
        ScreenName: "Pitch Sections",
        draggable: true,
        TabKey: "SectionName",
      }),
      new ScreenTypes({
        ScreenName: "Pitch Charts",
        draggable: false,
        TabKey: ["Data", "chartOptions", "title"],
      }),
      new ScreenTypes({
        ScreenName: "Dashboard Charts",
        draggable: false,
        TabKey: ["Data", "chartOptions", "title"],
      }),
    ];

    this.dashboardCharts = [];

    this.state = {
      CurrentScreen: 1,
      selectedDashboardChart: null,
      loading: true,
    };
    this.getDashboardCharts();
  }

  componentDidMount() {
    this.didMount = true;
  }

  createNewChartObject = (type = ChartTypes.BarChart.name, chartData = {}, ID, Name) => {
    switch (type) {
      case ChartTypes.BarChart.name:
        return new BarChart({ ...chartData, ID: ID, Name: Name });
      case ChartTypes.StackedBarChart.name:
        return new StackedBarChart({ ...chartData, ID: ID, Name: Name });
      case ChartTypes.HorizontalBarChart.name:
        return new HorizontalBarChart({ ...chartData, ID: ID, Name: Name });
      case ChartTypes.LineChart.name:
        return new LineChart({ ...chartData, ID: ID, Name: Name });
      case ChartTypes.WaterfallChart.name:
        return new WaterfallChart({ ...chartData, ID: ID, Name: Name });
    }
    return null;
  };

  getDashboardCharts = () => {
    if (this.props.charts && this.props.charts.length) {
      this.dashboardCharts = this.props.charts;
      if (this.didMount) {
        this.setState({ loading: false, selectedDashboardChart: this.dashboardCharts[0] });
      } else {
        this.state.selectedDashboardChart = this.dashboardCharts[0];
        this.state.loading = false;
      }
    } else {
      request
        .get(`/api/master/dashboardcharts`)
        .set("authorization", "Bearer " + window.localStorage.getItem("token"))
        .then((res) => {
          if (res) {
            if (res.body.length) {
              res.body.forEach((chart) => {
                let chartData = JSON.parse(chart.Data);
                this.dashboardCharts.push({ ...chart, Data: chartData });
              });
              this.dashboardCharts = this.dashboardCharts.filter((c) =>
                this.props.chartSettings.hasOwnProperty(c.ID),
              );
            }
            if (this.dashboardCharts.length) {
              this.state.selectedDashboardChart = this.dashboardCharts[0];
            }
            this.setState({ loading: false });
          }
        })
        .catch((err) => console.error(err));
    }
  };

  updateState = () => {
    this.forceUpdate();
  };

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  handleChange = (e, val, callBack) => {
    this.state.selectedDashboardChart = this.dashboardCharts.find((chart) => chart.ID == val);
    this.forceUpdate(() => {
      if (callBack) {
        callBack();
      }
    });
  };

  changeScreen = (screen) => {
    this.setState({ CurrentScreen: screen });
  };

  getTabs = () => {
    return this.dashboardCharts;
  };

  handleAddChart = () => {
    let newChart = new BarChart({
      Name: `Chart ${this.dashboardCharts.length + 1}`,
      months: this.props.months,
      years: this.props.years,
      dateRange: this.props.dateRange,
    });
    request
      .post(`/api/master/dashboardcharts`)
      .set("authorization", "Bearer " + window.localStorage.getItem("token"))
      .send(newChart.getDataFromChart())
      .then((res) => {
        if (res) {
          let chartObjData = {
            Data: JSON.parse(newChart.getDataFromChart().Data),
            Name: newChart.getDataFromChart().Name,
            ID: res.body.id,
          };
          this.dashboardCharts.push(chartObjData);
          this.props.chartSettings[chartObjData.ID] = {};
          this.setState(
            { selectedDashboardChart: this.dashboardCharts[this.dashboardCharts.length - 1] },
            () => {
              newChart = null;
              this.props.onChartInsert();
            },
          );
        }
      });
  };

  handleDeleteChart = (chart_id, callBack) => {
    request
      .delete(`/api/master/dashboardcharts`)
      .set("authorization", "Bearer " + window.localStorage.getItem("token"))
      .query({ ID: chart_id })
      .then((res) => {
        this.props.onChartDelete(chart_id);
        callBack && callBack();
      });
  };

  handleDuplicate = (tab) => {
    let newTab = JSON.parse(JSON.stringify(tab));
    delete newTab.ID;
    newTab.Data = JSON.stringify(newTab.Data);
    request
      .post("/api/master/dashboardcharts")
      .set("authorization", "Bearer " + window.localStorage.getItem("token"))
      .send(newTab)
      .then((c) => {
        newTab.Data = JSON.parse(newTab.Data);
        newTab.ID = c.body.id;
        this.dashboardCharts.push(newTab);
        this.props.chartSettings[newTab.ID] = {};
        this.setState(
          { selectedDashboardChart: this.dashboardCharts[this.dashboardCharts.length - 1] },
          () => {
            newTab = null;
            this.props.onChartInsert();
          },
        );
      });
  };

  render() {
    if (!this.state.loading) {
      return (
        <div className="App slide_editor charts">
          <div className={"se_panel_header"} id={"se_panel_header"}>
            <Button
              style={{ backgroundColor: "#01AFFD" }}
              label="Go Back"
              size="large"
              width="200px"
              backgroundColor="primary"
              padding="0 0 0 0"
              onClick={() => this.props.goBack()}
            />
          </div>
          <div className={"se_panel_wrapper"}>
            <LeftMenu
              updateSection={this.updateSection}
              hideSection={this.hideSection}
              deleteSection={this.handleDeleteChart}
              addSection={() => {}}
              enableDuplicate
              scale={scale}
              addNewSection={() => {}}
              update={(val) => scale.handleResize(val)}
              handleAddChart={this.handleAddChart}
              selectedChart={this.state.selectedDashboardChart}
              tabs={this.getTabs()}
              deleteComplete={() => {}}
              editSection={() => {}}
              handleDuplicate={this.handleDuplicate}
              tabKey={this.EditingScreens[this.state.CurrentScreen].TabKey}
              isAdmin={true}
              categories={[]}
              selectedSectionID={this.state.selectedSectionID}
              selectedSection={this.state.selectedDashboardChart}
              handleChange={this.handleChange}
              changeCategory={() => {}}
              selectedCategory={null}
              onSectionMove={() => scale.updateSlideComp()}
              CurrentScreen={this.state.CurrentScreen}
              EditingScreens={this.EditingScreens}
              changeScreen={() => {}}
            />
            {this.dashboardCharts.length ? (
              <div className={"chart_component_container"}>
                <ChartsComponent
                  showIndicatorBoxes={this.props.showIndicatorBoxes}
                  months={this.props.months}
                  years={this.props.years}
                  dateRange={this.props.dateRange}
                  setSelectedChart={(c) => {
                    this.setState({
                      selectedDashboardChart: this.dashboardCharts.find((chart) => chart == c),
                    });
                  }}
                  selectedDashboardChart={this.state.selectedDashboardChart}
                  charts={this.dashboardCharts}
                />
              </div>
            ) : null}
            <RightMenu
              scale={scale}
              update={() => {}}
              className={"options_menu"}
              selectTemplate={() => {}}
              isAdmin={true}
              selectedTemplateID={null}
              templates={[]}
              sections={[]}
            />
          </div>
        </div>
      );
    } else {
      return <div>wait a sec</div>;
    }
  }
}

export default ChartEditor;
