import { CommandHistoryManager } from "../services/commands/CommandManager";

export const changeTypes = {
  props: "props",
  delete: "delete",
  "multiple delete": "multiple delete",
  insert: "insert",
  position: "position",
  resize: "resize",
  multiple_group: "multiple_group",
};

class SlideHistory {
  changes = [];
  undoArray = [];
  redoArray = [];
  listeners = [];
  currentIndex = -1;

  constructor(commandManager) {
    if (commandManager.hasBackHistory()) {
      this.undoArray.push(commandManager);
      this.currentIndex = this.undoArray.length - 1;
    }

    if (commandManager.hasForwardHistory()) {
      this.redoArray.push(commandManager);
    }
  }

  pushNewChange = (obj, type, nextChange, prevChange, historyTag = null, objsArr = []) => {
    if (this.currentIndex === -1 || this.currentIndex === this.undoArray.length - 1) {
      if (this.undoArray.length < 20) {
        if (historyTag) {
          if (
            this.undoArray.length === 0 ||
            this.undoArray[this.undoArray.length - 1].historyTag === null ||
            this.undoArray[this.undoArray.length - 1].historyTag !== historyTag
          ) {
            this.undoArray.push({
              obj,
              type,
              change: JSON.stringify({ prevChange }),
              historyTag,
              objsArr,
            });
            this.redoArray.push({
              obj,
              type,
              change: JSON.stringify({ nextChange }),
              historyTag,
              objsArr,
            });
          } else {
            this.redoArray[this.redoArray.length - 1] = {
              obj,
              type,
              change: JSON.stringify({ nextChange }),
              historyTag,
              objsArr,
            };
          }
        } else {
          this.undoArray.push({
            obj,
            type,
            change: JSON.stringify({ prevChange }),
            historyTag,
            objsArr,
          });
          this.redoArray.push({
            obj,
            type,
            change: JSON.stringify({ nextChange }),
            historyTag,
            objsArr,
          });
        }

        this.currentIndex = this.undoArray.length - 1;
      } else {
        if (historyTag) {
          if (
            this.undoArray.length === 0 ||
            this.undoArray[this.undoArray.length - 1].historyTag === null ||
            this.undoArray[this.undoArray.length - 1].historyTag !== historyTag
          ) {
            this.undoArray.push({
              obj,
              type,
              change: JSON.stringify({ prevChange }),
              historyTag,
              objsArr,
            });
            this.undoArray.splice(0, 1);

            this.redoArray.push({
              obj,
              type,
              change: JSON.stringify({ nextChange }),
              historyTag,
              objsArr,
            });
            this.redoArray.splice(0, 1);
          } else {
            this.redoArray[this.redoArray.length - 1] = {
              obj,
              type,
              change: JSON.stringify({ nextChange }),
              historyTag,
              objsArr,
            };
          }
        } else {
          this.undoArray.push({
            obj,
            type,
            change: JSON.stringify({ prevChange }),
            historyTag,
            objsArr,
          });
          this.undoArray.splice(0, 1);

          this.redoArray.push({
            obj,
            type,
            change: JSON.stringify({ nextChange }),
            historyTag,
            objsArr,
          });
          this.redoArray.splice(0, 1);
        }
        this.currentIndex = this.undoArray.length - 1;
      }
    } else {
      this.undoArray = this.undoArray.splice(0, this.currentIndex + 1);
      this.redoArray = this.redoArray.splice(0, this.currentIndex + 1);

      this.undoArray.push({
        obj,
        type,
        change: JSON.stringify({ prevChange }),
        historyTag,
        objsArr,
      });
      this.redoArray.push({
        obj,
        type,
        change: JSON.stringify({ nextChange }),
        historyTag,
        objsArr,
      });

      this.currentIndex = this.undoArray.length - 1;
    }

    this.notify();
  };

  undo = (
    callback,
    callback2,
    callback3,
    callback4,
    callback5,
    callback6,
    callback7,
    groupCallBack,
  ) => {
    if (this.currentIndex !== -1) {
      let CurrChange = this.undoArray[this.currentIndex];
      this.currentIndex = this.currentIndex - 1;

      let change = CurrChange.change ? JSON.parse(CurrChange.change) : null;

      if (CurrChange.type === "props") {
        CurrChange.obj.props = change.prevChange;
        callback5(CurrChange.obj);
        callback6(CurrChange.obj);
      } else if (CurrChange.type === "delete") {
        callback2(CurrChange.obj, CurrChange.type);
      } else if (CurrChange.type === "multiple delete") {
        callback2(CurrChange.obj, CurrChange.type);
      } else if (CurrChange.type === "insert") {
        callback3(CurrChange.obj, false);
      } else if (CurrChange.type === "position") {
        callback4(CurrChange.obj.key, change.prevChange.top, change.prevChange.left);
      } else if (CurrChange.type === "resize") {
        callback7(
          CurrChange.obj.key,
          change.prevChange.width,
          change.prevChange.height,
          change.prevChange.rotateAngle,
        );
      } else if (CurrChange.type === changeTypes.multiple_group) {
        groupCallBack(CurrChange.objsArr, change.prevChange);
      } else if (
        CurrChange instanceof CommandHistoryManager ||
        CurrChange.obj instanceof CommandHistoryManager
      ) {
        const commandManager =
          CurrChange instanceof CommandHistoryManager ? CurrChange : CurrChange.obj;
        commandManager.undo();
      }

      callback();
      this.notify();
    }
  };

  redo = (
    callback,
    callback2,
    callback3,
    callback4,
    callback5,
    callback6,
    callback7,
    groupCallBack,
  ) => {
    if (this.currentIndex !== this.redoArray.length - 1) {
      this.currentIndex = this.currentIndex + 1;
      let CurrChange;
      CurrChange = this.redoArray[this.currentIndex];

      let change = CurrChange.change ? JSON.parse(CurrChange.change) : null;

      if (CurrChange.type === "props") {
        let newObj = { ...CurrChange.obj.props };
        Object.assign(newObj, change.nextChange);
        CurrChange.obj.props = newObj;
        callback5(CurrChange.obj);
        callback6(CurrChange.obj);
      } else if (CurrChange.type === "delete") {
        callback2(CurrChange.obj, CurrChange.type);
      } else if (CurrChange.type === "multiple delete") {
        callback2(CurrChange.obj, CurrChange.type);
      } else if (CurrChange.type === "insert") {
        callback3(CurrChange.obj);
      } else if (CurrChange.type === "position") {
        callback4(CurrChange.obj.key, change.nextChange.top, change.nextChange.left);
      } else if (CurrChange.type === "resize") {
        callback7(
          CurrChange.obj.key,
          change.nextChange.width,
          change.nextChange.height,
          change.nextChange.rotateAngle,
        );
      } else if (CurrChange.type === changeTypes.multiple_group) {
        groupCallBack(CurrChange.objsArr, change.nextChange);
      } else if (
        CurrChange instanceof CommandHistoryManager ||
        CurrChange.obj instanceof CommandHistoryManager
      ) {
        const commandManager =
          CurrChange instanceof CommandHistoryManager ? CurrChange : CurrChange.obj;
        commandManager.redo();
      }
      callback();
      this.notify();
    }
  };

  // Method to notify listeners of change
  notify() {
    this.listeners.forEach((listener) => listener());
  }

  // Method to subscribe to change events
  subscribe(listener) {
    this.listeners.push(listener);
    // Return an unsubscribe function
    return () => {
      this.listeners = this.listeners.filter((l) => l !== listener);
    };
  }

  hasUndoHistory() {
    return this.currentIndex >= 0 && this.currentIndex < this.undoArray.length;
  }

  hasRedoHistory() {
    return this.currentIndex >= -1 && this.currentIndex < this.redoArray.length - 1;
  }

  reset() {
    this.undoArray = this.undoArray
      .filter(undoObject => {
        return undoObject instanceof CommandHistoryManager || undoObject.obj instanceof CommandHistoryManager
      })
      .map(commandManager => {
        if (commandManager.getGlobalFilteredHistoriesManager) {
          return commandManager.getGlobalFilteredHistoriesManager();
        }
        return null;
      });
    this.redoArray = this.redoArray.filter(redoObject => {
      return redoObject instanceof CommandHistoryManager || redoObject.obj instanceof CommandHistoryManager
    })
    .map(commandManager => {
      if (commandManager.getGlobalFilteredHistoriesManager) {
        return commandManager.getGlobalFilteredHistoriesManager();
      }
      return null;
    });
    this.currentIndex = this.undoArray.length - 1;
    this.notify();
  }
}

export default SlideHistory;
