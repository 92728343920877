import CalculatedDriver from "../../CalculatedDriver/index";
import { DriverCategories, UnitTypes } from "../../CalculatedDriver/constants";
import MxIDHelper from "../../../MxIDHelper";
import WorkingCapital from "../index";
import CalculatedDriver_Values from "../../CalculatedDriver/CalculatedDriver_Values";
import Revenue from "../../Revenue";
import { WorkingCapitalTypes } from "../constants";
import { ExpenseTypes } from "../../constants";
import CostSales from "../../CostSales";
import AccountPayable from "../AccountPayable";

class Inventory extends WorkingCapital {
  Name = "";
  Type = WorkingCapitalTypes.Inventory;
  CreditSales;
  InventoryDays;
  IsDetailed = false;

  constructor(db_record) {
    super(db_record);
    if (this.db_record) {
      this.Name = this.db_record.Name;
      this.Type = this.db_record.Type;
      this.IsDetailed = this.db_record.IsDetailed;
      this.setDriversFromDataStorageSub();
      this.setWorkingCapital();
    }
    this.SaveRevenue = this.Save;
    this.Save = this.SaveWorkingCapital;
  }

  get hasChange() {
    return this.IsDetailed !== this.db_record.IsDetailed;
  }

  static DriversDesc = Object.assign({
    Total: {
      driverName: "$Name",
      fieldName: "Totals",
      driverID: "total",
      unit: UnitTypes.Price,
      category: DriverCategories.LastPeriod,
    },
    CreditSalesTerms: {
      driverName: "Credit Sales Terms",
      fieldName: "CreditSalesTerms",
      driverID: "credit_sales_terms",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    CumulativeCreditSalesTerms: {
      driverName: "Cumulative Credit Sales Terms",
      fieldName: "CumulativeCreditSalesTerms",
      driverID: "cumulative_credit_sales_terms",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    OpeningBalance: {
      driverName: "Opening Balance",
      fieldName: "OpeningBalance",
      driverID: "inventory_opening_balance",
      unit: UnitTypes.Price,
      category: DriverCategories.FirstPeriod,
    },
    OpeningBalanceReduction: {
      driverName: "Opening Balance Reduction",
      fieldName: "OpeningBalanceReduction",
      driverID: "opening_balance_reduction",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    OpeningBalanceResidual: {
      driverName: "Opening Balance Residual",
      fieldName: "OpeningBalanceResidual",
      driverID: "opening_balance_residual",
      unit: UnitTypes.Price,
      category: DriverCategories.LastPeriod,
    },
    ClosingBalance: {
      driverName: "Inventory",
      fieldName: "ClosingBalance",
      driverID: "inventory_closing_balance",
      unit: UnitTypes.Price,
      category: DriverCategories.LastPeriod,
    },
  });

  SaveWorkingCapital = (callBack, saveDrivers = true) => {
    console.log("SaveWorkingCapital", this);
    this.SaveRevenue((newID) => {
      this.CreditSales.Save();
      this.InventoryDays.Save();

      if (saveDrivers) {
        this.SaveDrivers(callBack, this.ID);
      } else {
        callBack(this.ID);
      }
    }, false);
  };

  setDriversFromDataStorageSub = () => {
    this.CreditSales = global.Modeliks.DriverValuesStore.getItem(this.getCreditSalesID());
    this.InventoryDays = global.Modeliks.DriverValuesStore.getItem(this.getInventoryDaysID());
  };

  getCreditSalesID = () => {
    return `${Inventory.TableName}-${this.ID}-credit_sales`;
  };
  getInventoryDaysID = () => {
    return `${Inventory.TableName}-${this.ID}-inventory_days`;
  };

  getCostCreditSalesID = (ID, TableName) => {
    return `${TableName}-${ID}-cost_expense-${Inventory.TableName}-${this.ID}-credit_sales`;
  };

  getCostInventoryDaysID = (ID, TableName) => {
    return `${TableName}-${ID}-cost_expense-${Inventory.TableName}-${this.ID}-inventory_days`;
  };

  getCostCreditSalesValue = (ID, TableName) => {
    let value = global.Modeliks.DriverValuesStore.getItem(this.getCostCreditSalesID(ID, TableName));
    if (value) {
      return value;
    }
    value = new CalculatedDriver_Values(
      null,
      this.getCostCreditSalesID(ID, TableName),
      null,
      UnitTypes.Percentage,
    );
    value.Value = 100;
    value.Save(() => value);
  };

  getCostInventoryDaysValue = (ID, TableName) => {
    let value = global.Modeliks.DriverValuesStore.getItem(
      this.getCostInventoryDaysID(ID, TableName),
    );
    if (value) {
      return value;
    }

    value = new CalculatedDriver_Values(
      null,
      this.getCostInventoryDaysID(ID, TableName),
      null,
      UnitTypes.Units,
    );
    value.Value = 0;
    value.Save(() => value);
  };

  getRevenueDrivers = () => {
    const CostAndExpenses = CostSales.getCostSaleAndDirectExpenses();
    if (this.IsDetailed) {
      const children = CostAndExpenses.getChildDrivers();
      if (children && children.length > 0) {
        return children;
      }
      return [CostAndExpenses];
    } else {
      return CostAndExpenses;
    }
  };

  getMonthDatesAll = () => {
    return [...global.Modeliks.DateHelper.months];
  };

  getYearDatesAll = () => {
    return global.Modeliks.DateHelper.years_all;
  };
  periodsData = {};

  buildPeriodsData = () => {
    const allPeriods = this.CreditSalesTerms.Values.map((c) => c.Date);
    allPeriods.forEach((period) => {
      if (!this.periodsData[period.dateID]) {
        this.periodsData[period.dateID] = {};
        Object.values(this.constructor.DriversDesc).forEach(
          (driver) =>
            (this.periodsData[period.dateID][driver.fieldName] = this[
              driver.fieldName
            ].getItemByDateSufix(period.sufix)),
        );
        this.periodsData[period.dateID].Date = period;
      }
    });
  };

  setWorkingCapital = () => {
    this.buildPeriodsData();
    this.createMonthsFormulas();
    this.createYearsFormulas();
  };

  createMonthsFormulas = () => {
    const months = this.getMonthDatesAll();
    const years = this.getYearDatesAll();
    const revenueDrivers = this.getRevenueDrivers();
    let revenueCreditAndDaysTotals = [];
    if (this.IsDetailed) {
      revenueCreditAndDaysTotals = `MxMath.Sum([${revenueDrivers.map((revenue) => `${this.getCostCreditSalesValue(revenue.Ref_ID, revenue.Ref_Table)} * ${this.getCostInventoryDaysValue(revenue.Ref_ID, revenue.Ref_Table)}`).join(",")}])`;
    }
    months.forEach((month) => {
      const prevMonthDate = months.find((c) => c.Order == month.Order - 1);
      const curMonth = this.periodsData[month.dateID];
      const prevMonth = prevMonthDate ? this.periodsData[prevMonthDate.dateID] : null;
      const firstMonth = this.periodsData[months[0].dateID];

      if (prevMonth) {
        curMonth.CumulativeCreditSalesTerms.Formula = `${prevMonth.CumulativeCreditSalesTerms} + ${curMonth.CreditSalesTerms}`;
        curMonth.OpeningBalance.Formula = `${prevMonth.ClosingBalance}`;
        curMonth.OpeningBalanceResidual.Formula = `${prevMonth.OpeningBalanceResidual} - ${curMonth.OpeningBalanceReduction}`;
      } else {
        curMonth.CumulativeCreditSalesTerms.Formula = `${curMonth.CreditSalesTerms}`;
        curMonth.OpeningBalanceResidual.Formula = `${curMonth.OpeningBalance} - ${curMonth.OpeningBalanceReduction}`;
      }

      if (this.IsDetailed) {
        const revenueReduction = `MxMath.Sum([${revenueDrivers.map((revenue) => `MxMath.IFELSE(${month.Order + 1} <=  MxMath.Ceil(${this.getCostInventoryDaysValue(revenue.Ref_ID, revenue.Ref_Table)} / 31),(${firstMonth.OpeningBalance} * ${revenue.getItemByDateSufix(months[0].sufix)} * ${this.getCostCreditSalesValue(revenue.Ref_ID, revenue.Ref_Table)} / ${firstMonth.CreditSalesTerms}) / MxMath.Ceil(${this.getCostInventoryDaysValue(revenue.Ref_ID, revenue.Ref_Table)} / 31), 0)`).join(",")}])`;

        curMonth.CreditSalesTerms.Formula = `MxMath.Sum([${revenueDrivers.map((revenue) => revenue.getItemByDateSufix(month.sufix) + "*" + this.getCostCreditSalesValue(revenue.Ref_ID, revenue.Ref_Table)).join(",")}])`;
        curMonth.OpeningBalanceReduction.Formula = `MxMath.IFELSE(${revenueCreditAndDaysTotals} === 0 , ${curMonth.OpeningBalance} , ${revenueReduction})`;
        curMonth.ClosingBalance.Formula = `MxMath.Min(${revenueDrivers.map((revenue) => `(${revenue.getItemByDateSufix(month.sufix)} * ${this.getCostCreditSalesValue(revenue.Ref_ID, revenue.Ref_Table)} / 30 * ${this.getCostInventoryDaysValue(revenue.Ref_ID, revenue.Ref_Table)})`).join("+")}, ${curMonth.CumulativeCreditSalesTerms}) + ${curMonth.OpeningBalanceResidual}`;
      } else {
        curMonth.CreditSalesTerms.Formula = `${revenueDrivers.getItemByDateSufix(month.sufix)} * ${this.CreditSales}`;
        curMonth.ClosingBalance.Formula = `MxMath.Min(${curMonth.CreditSalesTerms} / 30 * ${this.InventoryDays}, ${curMonth.CumulativeCreditSalesTerms} ) + ${curMonth.OpeningBalanceResidual}`;
        curMonth.OpeningBalanceReduction.Formula = `MxMath.IFELSE(${this.CreditSales} * ${this.InventoryDays} > 0,MxMath.IFELSE(${month.Order + 1} <=  MxMath.Ceil(${this.InventoryDays} / 31),${firstMonth.OpeningBalance} / MxMath.Ceil(${this.InventoryDays} / 31), 0), ${curMonth.OpeningBalance})`;
      }
      curMonth.Totals.Formula = `${curMonth.ClosingBalance}`;
    });

    years.forEach((year) => {
      const curYear = this.periodsData[year.dateID];
      const months = this.periodsData[year.dateID].Date.monthIndexes.map(
        (index) => this.periodsData[index],
      );
      const prevYearDate = years.find((c) => c.Order === year.Order - 1);
      const prevYear = prevYearDate ? this.periodsData[prevYearDate.dateID] : null;
      const lastMonth = months[months.length - 1];
      const firstMonth = months[0];
      curYear.CumulativeCreditSalesTerms.Formula = "";
      curYear.OpeningBalanceReduction.Formula = "";
      curYear.OpeningBalanceResidual.Formula = "";

      if (!year.Active) {
        if (this.IsDetailed) {
          curYear.CreditSalesTerms.Formula = `MxMath.Sum([${months.map((c) => c.CreditSalesTerms.ID_f).join(",")}])`;
        } else {
          curYear.CreditSalesTerms.Formula = `${revenueDrivers.getItemByDateSufix(year.sufix)} * ${this.CreditSales}`;
        }

        curYear.OpeningBalance.Formula = `${firstMonth.OpeningBalance}`;
        curYear.ClosingBalance.Formula = `${lastMonth.ClosingBalance}`;
      } else {
        if (this.IsDetailed) {
          curYear.CreditSalesTerms.Formula = `${revenueDrivers.map((revenue) => `${revenue.getItemByDateSufix(year.sufix)} * ${this.getCostCreditSalesValue(revenue.Ref_ID, revenue.Ref_Table)}`).join("+")}`;
          curYear.ClosingBalance.Formula = `MxMath.Sum([${revenueDrivers.map((revenue) => `${revenue.getItemByDateSufix(year.sufix)} * ${this.getCostCreditSalesValue(revenue.Ref_ID, revenue.Ref_Table)} / 365 * ${this.getCostInventoryDaysValue(revenue.Ref_ID, revenue.Ref_Table)}`).join(",")}])`;
        } else {
          curYear.CreditSalesTerms.Formula = `${revenueDrivers.getItemByDateSufix(year.sufix)} * ${this.CreditSales}`;
          curYear.ClosingBalance.Formula = `${this.InventoryDays} / 365 * ${curYear.CreditSalesTerms}`;
        }

        curYear.OpeningBalance.Formula = `${prevYear.ClosingBalance}`;
      }

      curYear.Totals.Formula = `${curYear.ClosingBalance}`;
    });
  };

  createYearsFormulas = () => {};

  cleanWorkingCapital = (ID, TableName, callBack) => {
    this.ClosingBalance.Values.forEach((v) => v.Formula === null);
    this.CreditSalesTerms.Values.forEach((v) => v.Formula === null);
    this.OpeningBalanceReduction.Values.forEach((v) => v.Formula === null);
    this.ClosingBalance.Values.forEach((v) => v.evalFormula === null);
    this.CreditSalesTerms.Values.forEach((v) => v.evalFormula === null);
    this.OpeningBalanceReduction.Values.forEach((v) => v.evalFormula === null);
    global.Modeliks.del(
      global.Modeliks.Tables.Finance_CalculatedDriver_Values.TableName,
      {
        ID: this.getCostCreditSalesID(ID, TableName),
        ID_CompanyScenario: global.Modeliks.CompanyScenarioInfo.ID,
      },
      (res) => {
        global.Modeliks.del(
          global.Modeliks.Tables.Finance_CalculatedDriver_Values.TableName,
          {
            ID: this.getCostInventoryDaysID(ID, TableName),
            ID_CompanyScenario: global.Modeliks.CompanyScenarioInfo.ID,
          },
          (res) => {
            if (callBack) {
              this.setWorkingCapital();
              this.Save(() => {
                callBack();
              });
            }
          },
        );
      },
    );
  };

  static create_Inventory = (Name, record) => {
    const newAccountInventory = new Inventory();
    newAccountInventory.ID = record.ID;
    newAccountInventory.Name = Name;
    newAccountInventory.ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
    newAccountInventory.Type = WorkingCapitalTypes.Inventory;
    newAccountInventory.Totals = CalculatedDriver.createDriverFromTable(
      newAccountInventory,
      WorkingCapital.DriversDesc.Total.driverID,
      UnitTypes.Price,
      DriverCategories.Sum,
    );
    newAccountInventory.CreditSales = new CalculatedDriver_Values(
      null,
      newAccountInventory.getCreditSalesID(),
      null,
      UnitTypes.Percentage,
    );
    newAccountInventory.CreditSales.Value = 100;
    newAccountInventory.InventoryDays = new CalculatedDriver_Values(
      null,
      newAccountInventory.getInventoryDaysID(),
      null,
      UnitTypes.Units,
    );
    newAccountInventory.InventoryDays.Value = 0;
    newAccountInventory.createDrivers();
    newAccountInventory.setWorkingCapital();
    global.Modeliks.WorkingCapitalStore.push(newAccountInventory);
    return newAccountInventory;
  };

  static getInventoryDriver = (callBack) => {
    let driver = global.Modeliks.WorkingCapitalStore.find(
      (driver) => driver.Type === WorkingCapitalTypes.Inventory,
    );
    if (driver) {
      if (callBack) {
        callBack(driver);
      } else {
        return driver;
      }
    } else {
      WorkingCapital.getEmptyWorkingCapital((record) => {
        driver = Inventory.create_Inventory("Inventory", record);
        driver.changeDriversName();
        driver.Save(() => {
          callBack();
        });
      });
    }
  };
}

export default Inventory;
