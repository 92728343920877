import request from "superagent";
import httpService from "../services/api/httpService";

const createNewAccount = () => {
	global.Modeliks.toggleGlobalLoader && global.Modeliks.toggleGlobalLoader()
	request
		.post("/api/insertAccount")
		.send({User_ID: global.Modeliks.User.ID})
		.then(async () => {
			let accounts = await getAccounts();
			if (accounts.length) {
				loginWithAccount(accounts[0]);
			}
		})
		.catch((e) => {
			console.error("error adding account", e);
		});
};
const getAccounts = async () => {
	try {
		let {Accounts} = await httpService().post('/api/changeAccount', {TimeStamp: new Date().getTime()})
		return Accounts;
	} catch (e) {
		global.Modeliks.LogOut(() => {
			window.location.replace("https://www.modeliks.com/");
		});
	}
};

const loginWithAccount = (account) => {
	window.localStorage.setItem("token", account.token);
	window.localStorage.setItem("Account_ID", account.ID);
	window.localStorage.removeItem(global.Modeliks.Tables.Finance_CalculatedDrivers.TableName);
	window.localStorage.removeItem(
		"lastGetTime" + global.Modeliks.Tables.Finance_CalculatedDrivers.TableName,
	);
	window.localStorage.removeItem(
		global.Modeliks.Tables.Finance_CalculatedDriver_Values.TableName,
	);
	window.localStorage.removeItem(
		"lastGetTime" + global.Modeliks.Tables.Finance_CalculatedDriver_Values.TableName,
	);
	window.localStorage.removeItem("CompanyScenarioID");
	window.location.reload();
};

export {createNewAccount, loginWithAccount, getAccounts}