import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, DialogActions, DialogContent } from "@mui/material";
import ButtonMui, { button_theme } from "../../../buttons/buttonMui/buttonMui";
import { ThemeProvider } from "@mui/material/styles";
import MxDataArray from "../../../../data/MxDataArray";
import "./exceededLimit.scss";

class ExceededSubscriptionsLimit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      changedSelection: new Date().getTime(),
    };

    this.selectedItems = MxDataArray.create([], "ID");
  }

  getIsSelected = (item) => {
    return this.selectedItems.exists(item);
  };

  handleClick = (item) => {
    if (this.selectedItems.exists(item)) {
      this.selectedItems.remove(item);
    } else {
      this.selectedItems.pushThis(item);
    }
    this.forceUpdate();
  };

  howManyToDisable = (actualItems, limit, disabled) => {
    if (actualItems - limit - disabled > 0) {
      return `You need to disable ${actualItems - limit - disabled} more`;
    } else {
      return `You can continue`;
    }
  };

  canContinue = (actualItems, limit, disabled) => {
    if (actualItems - limit - disabled > 0) {
      return false;
    }
    return true;
  };

  handleUpdateUsers = () => {
    global.Modeliks.put(
      this.props.limit.data_manager_key,
      { ID: this.selectedItems.map((c) => c.ID) },
      { [this.props.limit.db_key]: false },
      (success, error) => {
        window.location.reload();
      },
    );
  };

  getItems = (parentItems) => {
    return parentItems.filter((c) => c[this.props.limit.db_key]);
  };

  getItem = (item) => {
    if (item.User) {
      return item.User;
    }
    return item;
  };

  render() {
    const actualItems = this.getItems(global.Modeliks[this.props.limit.data_manager_key]).length;
    const limit = global.Modeliks.subscriptionInfo[this.props.limit.subscription_key];
    const disabled = this.selectedItems.length;

    return (
      <>
        <DialogContent className={"exceeded_limit_content"}>
          <span>You have exceeded the {this.props.limit.key} limit</span>
          <div>Please select which {this.props.limit.key} you want to deactivate</div>
          <div>{this.howManyToDisable(actualItems, limit, disabled)}</div>
          <div className={"btns_container"}>
            {this.getItems(global.Modeliks[this.props.limit.data_manager_key]).map((c) => (
              <ThemeProvider theme={button_theme}>
                <Button
                  onClick={() => this.handleClick(c)}
                  variant={this.getIsSelected(c) ? "outlined" : "text"}
                >
                  {this.props.limit.name_key.map((key) => this.getItem(c)[key]).join(" ")}
                </Button>
              </ThemeProvider>
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <ButtonMui
            onClick={this.handleUpdateUsers}
            variant={"contained"}
            disabled={!this.canContinue(actualItems, limit, disabled)}
            label={"Continue"}
          />
        </DialogActions>
      </>
    );
  }
}

ExceededSubscriptionsLimit.propTypes = {
  limit: PropTypes.object,
};

export default ExceededSubscriptionsLimit;
