import React, { Fragment, useState } from "react";
import "../../SlideHeader/HeaderTextEditor.scss";
import "../../SlideHeader/HeaderDefault.scss";
import Menu from "@mui/material/Menu";
import { FillColor } from "../../components/IconButtons/SubMenuIcons";
import { SketchPicker } from "react-color";

function FillColorEditComponent(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openFillColor = Boolean(anchorEl);
  const [color, setColor] = React.useState(props.color);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeColor = (color) => {
    if (props.background) {
      setColor(color);
      props.handleColorChange(color);
    } else if (props.border) {
      setColor(color);
      props.handleBorderColorChange(color);
    } else if (props.series) {
      props.changeColorAtIndex(props.index, color);
    }
  };

  const rgbToRgba = (event) => {
    return "rgba(" + event.rgb.r + "," + event.rgb.g + "," + event.rgb.b + "," + event.rgb.a + ")";
  };
  const FillColorEdit = () => {
    return (
      <>
        {props.span && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              gap: "15px",
              paddingLeft: "20px",
              alignItems: "center",
              fontFamily: "Inter",
            }}
            onClick={handleClick}
            aria-controls={openFillColor ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openFillColor ? "true" : undefined}
          >
            <div
              style={{
                marginLeft: "5px",
                height: "14px",
                width: "14px",
                borderRadius: "50%",
                background: props.color !== "#FFFFFF" ? props.color : "grey",
              }}
            />
            <span style={{ color: "#585858", fontSize: "10px" }}>{props.span}</span>
          </div>
        )}
        {!props.span && (
          <div
            aria-controls={openFillColor ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openFillColor ? "true" : undefined}
            style={{
              marginLeft: "5px",
              backgroundColor: props.color ? props.color : "gray",
              height: "14px",
              width: "14px",
              borderRadius: "50%",
              display: "inline",
              marginRight: "8px",
            }}
            onClick={handleClick}
          ></div>
        )}
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={openFillColor}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <div style={{ padding: "8px" }}>
            <SketchPicker color={props.color} onChange={(event) => changeColor(rgbToRgba(event))} />
          </div>
        </Menu>
      </>
    );
  };

  return <>{FillColorEdit()}</>;
}

export default FillColorEditComponent;
