import CostSale from "./index";
import Personnel from "../Personnel";
import { DriverPeriodTypes } from "../CalculatedDriver/constants";
import Expense from "../Expense";
import { maxRows } from "../../../components/constants/finance";

const CostSaleDataGridRows = (Storage) => {
  const costTotals = CostSale.getCostSalesTotals();
  Storage.dataGridRows = {
    streamTotals: costTotals,
    streamTotalsGrowth: CostSale.getTotalsGrowth(costTotals),
    streamMargin: CostSale.getCostSalesMarginFromRevenue(),
    expensesDirectCostTotals: Expense.getExpensesDirectCostTotals(),
    personnelDirectLaborTotals: Personnel.getPersonnelDirectLaborTotals(),
    allDrivers: [],
    gridRows: [],
    totalsRows: [],
    maxRows,
    totalRows: 0,
  };
  let totalRows = Storage.dataGridRows.totalRows;

  const FinanceExpensePersonnelParent = (revenue, isExpense = false) => {
    totalRows = totalRows + 1;
    return revenue_totals_create({
      id: revenue.ID,
      unit: global.Modeliks.CompanyInfo.Currency.value,
      header: isExpense ? "Other Direct Expenses" : "Direct Labor",
      data: revenue,
      boldRowHeader: true,
      revenue: revenue,
      children: [],
    });
  };

  const revenue_totals_create = (
    revenue,
    isChild = false,
    level = 0,
    stopRecursive = false,
    hasEdit = false,
  ) => {
    let row = {};
    if (revenue) {
      totalRows = totalRows + 1;

      if (isChild) {
        row = {
          id: revenue.ID,
          header: revenue.Name,
          data: revenue,
          children: [],
        };
        if (revenue.getChildDrivers() && !stopRecursive) {
          let children = revenue.getChildDrivers();
          level++;
          if (children && revenue.Ref_Field !== Personnel.DriversDesc.EmployeeBenefits.driverID) {
            children.map((driver) => {
              if (
                driver.Ref_Table !== global.Modeliks.Tables.Finance_Revenues.TableName ||
                driver.Ref_ID === 0
              ) {
                if (totalRows <= maxRows) {
                  row.children.push(
                    revenue_totals_create(
                      driver,
                      true,
                      level++,
                      driver.PeriodType === DriverPeriodTypes.Previous,
                    ),
                  );
                }
              }
            });
          }
        }
        if (hasEdit) {
          row.onEditClick = () => {};
          row.hasEdit = true;
        }
      } else {
        row = revenue;
        let children = revenue.data.getChildDrivers();
        if (children) {
          level++;
          children.map((driver) => {
            if (totalRows <= maxRows) {
              row.children.push(
                revenue_totals_create(
                  driver,
                  true,
                  level,
                  driver.PeriodType === DriverPeriodTypes.Previous,
                  true,
                ),
              );
            }
          });
        }
      }
      return row;
    } else {
      return null;
    }
  };

  const row_create = (revenue) => {
    if (
      revenue.Ref_Table === global.Modeliks.Tables.Finance_Expenses.TableName ||
      revenue.Ref_Table === global.Modeliks.Tables.Finance_Personnel.TableName
    ) {
      return FinanceExpensePersonnelParent(
        revenue,
        revenue.Ref_Table === global.Modeliks.Tables.Finance_Expenses.TableName,
      );
    } else {
      return revenue_only_create(revenue);
    }
  };

  const EditableTables = [
    global.Modeliks.Tables.Finance_CostSales.TableName,
    // global.Modeliks.Tables.Finance_Expenses.TableName,
    // global.Modeliks.Tables.Finance_Personnel.TableName,
  ];

  const revenue_only_create = (revenue, parentRevenue, level = 0, stopRecursive = false) => {
    let row = {};
    if (revenue) {
      totalRows = totalRows + 1;
      if (level > 1) {
        row = {
          id: revenue.ID,
          header: revenue.Name,
          onEditChildren: () => {},
          data: revenue,
          children: [],
          changeDriverNameEnabled:
            revenue.Ref_Table === global.Modeliks.Tables.Finance_CostSales.TableName,
          hasEdit: false,
        };
      } else if (level === 1) {
        row = {
          id: revenue.ID,
          header: revenue.Name,
          revenue: revenue,
          onEditClick: () => {},
          onDeleteClick: () => {},
          data: revenue,
          children: [],
          hasEdit: EditableTables.includes(revenue.Ref_Table),
        };
      } else {
        row = {
          id: revenue.ID,
          header: revenue.Name,
          revenue: revenue,
          onEditClick: () => {},
          onDeleteClick: () => {},
          data: revenue,
          isExpanded: true,
          boldRowHeader: true,
          children: [],
        };
      }

      let children = revenue.getChildDrivers();
      if (
        children &&
        revenue.Ref_Table !== global.Modeliks.Tables.Finance_Revenues.TableName &&
        !stopRecursive
      ) {
        children.map((driver) => {
          if (totalRows <= maxRows) {
            row.children.push(
              revenue_only_create(
                driver,
                revenue,
                level + 1,
                revenue.PeriodType === DriverPeriodTypes.Previous,
              ),
            );
          }
        });
      }
      return row;
    } else {
      return null;
    }
  };

  const row_totals = (totals = costTotals) => {
    totalRows = totalRows + 1;
    return {
      id: totals.ID,
      unit: global.Modeliks.CompanyInfo.Currency.value,
      header: "Total Cost of Goods Sold",
      cssClass: "totals",
      boldRowHeader: true,
      data: totals,
    };
  };

  const createGridRows = () => {
    const gridRows = [];
    const groupedCostSales = CostSale.getCostSalesGrouped();

    if (groupedCostSales.getChildDrivers() && groupedCostSales.getChildDrivers().length > 0) {
      gridRows.push(groupedCostSales);
    }
    if (gridRows && gridRows.length > 0) {
      Storage.dataGridRows.gridRows = gridRows
        .map((costsale) => row_create(costsale))
        .filter((c) => c != null);
      Storage.dataGridRows.allDrivers = gridRows;
    }
    Storage.dataGridRows.totalsRows = [row_totals()];
    Storage.dataGridRows.totalRows = totalRows;
  };
  createGridRows();
};

export default CostSaleDataGridRows;
