import PropTypes from "prop-types";
import React from "react";
import CircleLoader from "../CircleLoader/CircleLoader";
import "./mx_mainloader.scss";
import PrintablePortal from "../../../SlideEditor/components/components/Portals/printablePortal";

export default class Mx_MainLoader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleState = () => {
    this.state.open = !this.state.open;
    // this.props.subscriptions.pop();
    this.forceUpdate();
  };

  componentDidMount() {
    // this.props.subscriptions.push(this.handleState)
  }

  componentWillUnmount() {
    // this.props.subscriptions.pop();
  }

  getClassNameSuffix = () => {
    if (this.props.className) {
      return " " + this.props.className;
    }
    return "";
  };

  render() {
    return (
      <div className={"mx_main_loader" + this.getClassNameSuffix()}>
        <CircleLoader
          secondLoader={this.props.secondLoader}
          message={this.props.message ?? undefined}
        />
      </div>
    );
  }
}

Mx_MainLoader.propTypes = {
  secondLoader: PropTypes.any,
  message: PropTypes.any,
};
