import React from "react";
import HeaderTable from "../../SlideHeader/HeaderShape";
import HeaderMidPortal from "../../components/Portals/HeaderMidPortal";

export default class ShapeTextEditor extends React.Component {
  constructor(props) {
    super(props);

    this.id = "shp_txt" + props.id;

    this.state = {
      key: "so_" + new Date().getTime(),
      value: props.value ? props.value : "",
      style: {
        width: "100%",
        height: "100%",
        zIndex: 10000,
        pointerEvents: "auto",
        fontSize: "30px",
        fontFamily: "Inter",
        ...props.style,
      },
      numbering: "",
      bullet: "",
      currentBulletType: "",
      currentNumberType: "",
      doubleClicked: false,
    };

    this.props.textEditorUtils.updateMe = this.updateMe;
  }

  updateMe = () => {
    this.forceUpdate();
  };

  removeStyles = () => {
    this.setState(
      {
        style: {
          width: "100%",
          height: "100%",
          zIndex: 10000,
          pointerEvents: "auto",
          fontSize: "18px",
        },
      },
      () => this.props.onPropsChange({ style: this.state.style }),
    );
  };

  changeStyles = (newStyle) => {
    const newObj = {};
    Object.assign(newObj, this.state.style);
    Object.assign(newObj, newStyle);
    this.setState({ style: newObj });
    this.props.onPropsChange({ style: newObj });
  };

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.selected != this.props.selected || this.state != nextState;
  }

  handleValueChange = (e, val) => {
    this.setState({
      key: "so_" + new Date().getTime(),
      value: e.target.innerText,
    });
    this.props.onPropsChange({ value: e.target.innerText });
  };

  setBulletType = (type, curType) => {
    if (type === "number") {
      if (this.state.numbering && curType === this.state.currentNumberType) {
        this.setState({
          currentNumberType: "decimal",
          numbering: false,
        });
        this.props.onPropsChange({ numbering: false });
      } else {
        this.setState({
          currentNumberType: curType,
          bullet: false,
          numbering: true,
        });
        this.props.onPropsChange({
          bullet: false,
          numbering: true,
          currentNumberType: curType,
        });
      }
    } else if (type === "bullet") {
      if (this.state.bullet && curType === this.state.currentBulletType) {
        this.setState({
          bullet: false,
          currentBulletType: "disc",
        });
        this.props.onPropsChange({ bullet: false });
      } else {
        this.setState({
          bullet: true,
          currentBulletType: curType,
          numbering: false,
        });
        this.props.onPropsChange({ numbering: false, bullet: true });
      }
    }

    this.setState({
      key: "so_" + new Date().getTime(),
    });
  };

  renderValue = (arr) => {
    // setKey(new Date().getTime())
    return arr.map((c, index) => {
      if (index != arr.length - 1) {
        return <div>{c}</div>;
      } else {
        return <div>{c}</div>;
      }
    });
  };

  renderBullet = (arr) => {
    return (
      <ul style={{ listStyleType: this.state.currentBulletType }} key={new Date().getTime()}>
        {arr.map((c, index) => {
          return <li key={new Date().getTime() + index.toString()}>{c}</li>;
        })}
      </ul>
    );
  };

  renderNumbering = (arr) => {
    return (
      <ol style={{ listStyleType: this.state.currentNumberType }}>
        {arr.map((c) => {
          return <li>{c}</li>;
        })}
      </ol>
    );
  };

  render() {
    return (
      <>
        <div
          className="shape_text_box"
          onMouseDown={(e) => {
            if (this.state.doubleClicked) e.stopPropagation();
          }}
          onKeyDown={(e) => {
            if (this.state.doubleClicked) e.stopPropagation();
          }}
          onDoubleClick={() => {
            this.setState({
              doubleClicked: true,
            });
            document.getElementById(this.id).focus();
          }}
          suppressContentEditableWarning
        >
          <div className="text_box_container" suppressContentEditableWarning>
            <div
              id={this.id}
              key={this.state.key}
              className={this.state.doubleClicked ? "text_box focused" : "text_box"}
              suppressContentEditableWarning
              contentEditable={true}
              plainTextOnly={true}
              tabIndex={-1}
              onBlur={(e) => {
                if (window.getSelection()) window.getSelection().removeAllRanges();
                this.handleValueChange(e);
                this.setState({
                  doubleClicked: false,
                });
              }}
              onMouseMove={(e) => {
                if (this.state.doubleClicked) {
                  e.stopPropagation();
                }
              }}
              onMouseDown={(e) => {
                if (!this.state.doubleClicked) {
                  e.preventDefault();
                }
              }}
              spellCheck={"true"}
              style={this.state.style}
            >
              {this.state.bullet
                ? this.renderBullet(this.state.value.split("\n"))
                : this.state.numbering
                  ? this.renderNumbering(this.state.value.split("\n"))
                  : this.renderValue(this.state.value.split("\n"))}
            </div>
          </div>
        </div>
        {this.props.selected && (
          <div
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
          >
            <HeaderMidPortal>
              {this.props.children}

              <HeaderTable
                visible
                style={this.state.style}
                onChange={this.changeStyles}
                toggleFormatOptions={this.props.toggleFormatOptions}
                onStyleRemove={this.removeStyles}
                setBulletType={this.setBulletType}
              />
            </HeaderMidPortal>
          </div>
        )}
      </>
    );
  }
}
