import MxDataArray from "./MxDataArray";
window.valueUpdated = 0;
class MxDriversStore extends MxDataArray {
  formulaIndexes = {};

  valueUpdated = (ID) => {
    if (!global.startPerform) {
      global.startPerform = performance.now();
      // console.log("startPeform", global.startPerform)
      window.valueUpdated = 0;
    }

    const drivers = this.filter(
      (c) =>
        c.IsSimple == false &&
        c.Formula &&
        c.Formula.indexOf(ID) > -1 &&
        c.cur_record.Value != null,
    );
    drivers.forEach((d) => (d.Value = null));

    // console.log('valueUpdated', window.valueUpdated++, performance.now() - global.startPerform);
  };

  valueObjectUpdated = (objectVal) => {
    if (!global.startPerform) {
      global.startPerform = performance.now();
      // console.log("startPeform", global.startPerform)
      window.valueUpdated = 0;
    }

    objectVal.childrenRels.forEach((child) => (child.Value = null));
    // console.log('valueUpdated', window.valueUpdated++, performance.now() - global.startPerform);
  };

  actualObjectUpdated = (objectVal) => {
    if (!global.startPerform) {
      global.startPerform = performance.now();
      // console.log("startPeform", global.startPerform)
      window.valueUpdated = 0;
    }
    // objectVal.childrenRels.forEach((c) => {
    //     if(c.ID === 'Finance_Assets-CompanyScenarios-1-11027-totals_m_-12'){
    //         // console.log('c',c)
    //     }
    // })
    // objectVal.childrenRels.forEach(child => child.Actual = null);

    objectVal.childrenRels.forEach((child) => {
      if (child.evalFormulaActual) {
        child.Actual = null;
      }
    });
  };

  updateID = (newID, oldID) => {
    this._indexes[newID] = this._indexes[oldID];
    // console.log(newID,this._indexes[newID], oldID, this._indexes[oldID], this._indexes)
    const drivers = this.filter(
      (c) => c.IsSimple == false && c.Formula && c.Formula.indexOf("|" + oldID + "|") > -1,
    );
    // console.log('update id', drivers);
    drivers.forEach(
      (d) => (d.Formula = d.Formula.replaceAll("|" + oldID + "|", "|" + newID + "|")),
    );
    delete this._indexes[oldID];
  };
}

export default MxDriversStore;
