import React from "react";
import { SettingsIcon } from "./svgIcons";
import { useMatch, useResolvedPath } from "react-router-dom";

export default function SettingsActiveIcon() {
  let resolved = useResolvedPath("/settings");
  let match = useMatch({ path: resolved.pathname + `/*`, end: true });
  return (
    <>
      <SettingsIcon active={match} />
    </>
  );
}
