import React from "react";
import Dialog from "@mui/material/Dialog";
import "./CompanyLimit.scss";
import { MenuItem } from "@szhsin/react-menu";
import { Exit } from "../../../SlideEditor/components/components/IconButtons/SubMenuIcons";
import ButtonJ from "../../../SlideEditor/components/components/Buttons/Button";
import IconButton from "@mui/material/IconButton";
import request from "superagent";
import CircleLoader from "../../Loaders/CircleLoader/CircleLoader";
import Button from "../../actions/Button";
import { Navigate } from "react-router-dom";

class UsersLimit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: true,
    };
  }

  handleClose = () => {
    this.props.handleClose();
  };

  deactivateNow = () => {
    if (window.location.href.indexOf("/profile/users")) {
      this.props.handleClose();
    } else {
      this.setState({ link: "/profile/users" });
    }
  };

  render() {
    if (this.state.link) {
      return <Navigate to={this.state.link} />;
    }
    return (
      <React.Fragment>
        <Dialog
          fullWidth={true}
          maxWidth={"lg"}
          open={this.props.open}
          className={"company_limit_dialog"}
          onClose={this.handleClose}
        >
          <IconButton onClick={this.handleClose} className={"cld_close_button"}>
            <Exit />
          </IconButton>
          <div className={"p1_strong cld_heading"}>Add new user</div>`
          <div className={"cld_typography"}>
            <p>
              You've reached your users limit based on your subscription plan. In order to create
              new user you can:
            </p>
            <ul>
              <li>upgrade your subscription</li>
              <li>
                deactivate one of your active users (
                {
                  <a onClick={this.deactivateNow} className={"lcd_typography_link"}>
                    deactivate now
                  </a>
                }
                )
              </li>
            </ul>
          </div>
          <div className={"cld_typography2_buttons"}>
            <Button
              label={"Upgrade Now"}
              padding={"0 0 0 0"}
              backgroundColor={"primary"}
              onClick={() => {
                this.setState({ link: "/profile/account_details/manage_subscription" });
              }}
            />
            <Button
              label={"Cancel"}
              padding={"0 0 0 0"}
              noElevation={true}
              color={"black"}
              onClick={this.handleClose}
            />
          </div>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default UsersLimit;
