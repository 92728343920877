import React from "react";
import Dialog from "@mui/material/Dialog";
import { Exit } from "../../../../../SlideEditor/components/components/IconButtons/SubMenuIcons";
import IconButton from "@mui/material/IconButton";
import "./CreateManagementReportDialog.scss";
import Input from "../../../../../components/actions/Input";
import ButtonMui from "../../../../../components/buttons/buttonMui/buttonMui";
import MaterialSelect from "../../../../../components/actions/SelectM";
import CircleCheckboxM from "../../../../../components/actions/CircleCheckboxM";
import { Link } from "react-router-dom";

class CreateManagementReportDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Name: "",
      error: false,
      newFolder: !this.props.Folders.length,
      FolderName: null,
      selectedFolder: this.props.selectedFolder ? this.props.selectedFolder.ID : 0,
      selectedDashboard: props.customDashboards.length ? props.customDashboards[0] : null,
    };
  }

  handleClose = () => {
    this.props.onClose();
  };

  onChangeName = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  changeFolderCreationMode = (e) => {
    if (!this.props.Folders.length) {
      return;
    }
    if (e.target.value == "true") {
      this.setState({ newFolder: true });
    } else {
      this.setState({ newFolder: false });
    }
  };

  setSelectedFolder = (e) => {
    this.setState({ selectedFolder: e.target.value, selectedFolderError: false });
  };

  handleAdd = () => {
    if (this.state.newFolder) {
      this.props.onAdd({
        customDashboard: this.state.selectedDashboard,
        Name: this.state.Name,
        FolderName: this.state.FolderName,
      });
    } else {
      if (!this.state.selectedFolder) {
        this.setState({ selectedFolderError: "Please select a folder!" });
      } else {
        this.props.onAdd({
          customDashboard: this.state.selectedDashboard,
          Name: this.state.Name,
          selectedFolder: this.state.selectedFolder,
        });
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <Dialog
          fullWidth={true}
          maxWidth={"lg"}
          open={this.props.open}
          className={"create_management_report_dialog"}
          onClose={this.handleClose}
        >
          <IconButton onClick={this.handleClose} className={"cmrd_close_button"}>
            <Exit />
          </IconButton>

          <div className={"cmrd_title"}>Create Management Report</div>

          <div className={"cmrd_select_dashboard"}>
            <div className={"cmrd_title_2"}>Select dashboard</div>
            <div className={"cmrd_message"}>
              Please select a Custom Dashboard that you want your Management Report to be based on.
            </div>
            <div className={"cmrd_customDashboardsContainer"}>
              {this.props.customDashboards &&
                this.props.customDashboards.map((c) => {
                  return (
                    <div
                      className={`custom_dashboard${this.state.selectedDashboard == c ? " selected" : ""}`}
                      onClick={() => this.setState({ selectedDashboard: c })}
                    >
                      <div className={"mr_svg_container"}>
                        <DashboardDesignSVG selected={this.state.selectedDashboard == c} />
                      </div>
                      <div className={"custom_dashboard_name"}>{c[this.props.keyField]}</div>
                    </div>
                  );
                })}
            </div>
          </div>

          <div className={"cmrd_name borderless"}>
            <div className={"cmrd_title_2"}>Where do you want to save your report?</div>
            <div className={"flex"}>
              <div className={"flex"}>
                <CircleCheckboxM
                  disabled={!this.props.customDashboards.length}
                  size="medium"
                  checked={this.state.newFolder && this.props.customDashboards.length}
                  value={"true"}
                  onChange={this.changeFolderCreationMode}
                  label="Create new folder"
                />
                <div className="cmrd_input mid_wide">
                  <Input
                    placeholder="Please enter name..."
                    value={this.state.FolderName ? this.state.FolderName : ""}
                    error={this.state.error}
                    disabled={!this.props.customDashboards.length || !this.state.newFolder}
                    name={"FolderName"}
                    errorMessage={this.state.error && "Name is required"}
                    onChange={(e) => this.onChangeName(e, "FolderName")}
                  />
                </div>
              </div>
              <div className={"flex m_l"}>
                <CircleCheckboxM
                  disabled={!this.props.Folders.length || !this.props.customDashboards.length}
                  size="medium"
                  value={"false"}
                  checked={!this.state.newFolder && this.props.customDashboards.length}
                  onChange={this.changeFolderCreationMode}
                  label="Save in existing folder"
                />
                <div className="cmrd_input">
                  {this.props.Folders.length == 1 ? (
                    <Input
                      value={this.props.Folders.getItem(this.state.selectedFolder).Name}
                      disabled={true}
                    />
                  ) : (
                    <MaterialSelect
                      disabled={
                        !this.props.Folders.length ||
                        !this.props.customDashboards.length ||
                        this.state.newFolder
                      }
                      onChange={this.setSelectedFolder}
                      error={!!this.state.selectedFolderError}
                      errorMessage={this.state.selectedFolderError}
                      options={[
                        {
                          value: 0,
                          description: "Please Choose a Folder",
                        },
                        ...this.props.Folders.map((c) => {
                          return { description: c.Name, value: c.ID };
                        }),
                      ]}
                      value={this.state.selectedFolder}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className={"cmrd_name"}>
            <div className={"cmrd_title_2"}>Please enter your report name</div>
            <div className="cmrd_input wide">
              <Input
                placeholder="Please enter name..."
                value={this.state.Name}
                error={this.state.error}
                disabled={!this.props.customDashboards.length}
                name={"Name"}
                errorMessage={this.state.error && "Name is required"}
                onChange={this.onChangeName}
              />
            </div>
          </div>

          <div className={"cmrd_buttons_container"}>
            <ButtonMui
              width={146}
              variant={"outlined"}
              onClick={this.handleClose}
              label={"Cancel"}
            />
            <ButtonMui
              width={146}
              variant={"contained"}
              disabled={!this.props.customDashboards.length}
              label={"Create report"}
              onClick={this.handleAdd}
            />
          </div>
        </Dialog>
      </React.Fragment>
    );
  }
}

const DashboardDesignSVG = (props) => {
  let color = "#E1F2F6";
  if (props.selected) {
    color = "#F9F9F9";
  }

  return (
    <svg
      width="152"
      height="66"
      viewBox="0 0 152 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="4" y="15" width="31" height="15" fill={color} />
      <rect x="42" y="15" width="30" height="15" fill={color} />
      <rect x="79" y="15" width="31" height="15" fill={color} />
      <rect x="117" y="15" width="31" height="15" fill={color} />
      <path d="M15 2L136 1.99999" stroke={color} strokeWidth="4" strokeLinecap="round" />
      <path d="M15 9L136 8.99999" stroke={color} strokeWidth="4" strokeLinecap="round" />
      <path d="M14 66H4V34H14V66Z" fill={color} />
      <path d="M26 66H16V43H26V66Z" fill={color} />
      <path d="M44.5 66H34.5V34H44.5V66Z" fill={color} />
      <path d="M56.5 66H46.5V43H56.5V66Z" fill={color} />
      <path d="M75 66H65V34H75V66Z" fill={color} />
      <path d="M87 66H77V43H87V66Z" fill={color} />
      <path d="M105.5 66H95.5V34H105.5V66Z" fill={color} />
      <path d="M117.5 66H107.5V43H117.5V66Z" fill={color} />
      <path d="M136 66H126V34H136V66Z" fill={color} />
      <path d="M148 66H138V43H148V66Z" fill={color} />
      <line y1="65.75" x2="152" y2="65.75" stroke={color} strokeWidth="0.5" />
    </svg>
  );
};

export default CreateManagementReportDialog;
