const DEFAULT_FONT_SIZE = "14pt";
const TagNames = Object.freeze({
  Sup: "SUP",
  Sub: "SUB",
  A: "A",
  Div: "DIV",
  Span: "SPAN",
});

function createStyle(style) {
  let newStyle = "";
  let key = "";
  let styleObj = {};

  for (let i = 0; i < style.length; i++) {
    if (style[i] === "-") {
      newStyle += "";
      newStyle += style[i + 1].toUpperCase();
      i++;
    } else if (style[i] === ";") {
      styleObj[key] = newStyle;
      key = "";
      newStyle = "";
    } else if (style[i] === ":") {
      key = newStyle;
      newStyle = "";
    } else if ((key !== "fontFamily" && style[i] === " ") || style[i] === '"') {
      /* empty */
    } else {
      newStyle += style[i];
    }
  }

  if (!styleObj.fontSize) {
    styleObj.fontSize = DEFAULT_FONT_SIZE;
  }

  return styleObj;
}

function getPropsByNodeName(element) {
  const nodeName = element.nodeName;
  return {
    sub: nodeName === TagNames.Sub,
    sup: nodeName === TagNames.Sup,
    href: nodeName === TagNames.A ? element.attributes["href"].value : "",
  };
}

function readContentByNodeType(element) {
  switch (element.nodeType) {
    case Node.TEXT_NODE:
      return element.nodeValue;
    default:
      return element.innerText;
  }
}

function getCharactersFromElementNode(
  elements,
  currentRowLength = 0,
  currentSpanLength = 0,
  currentRow = {},
) {
  let innerSpanLength = currentSpanLength;
  let innerRowLength = currentRowLength;
  let style = "";

  const characters = Array.from(elements).map((element, index) => {
    const { sub, sup, href } = getPropsByNodeName(element);

    let elemObj = {
      startIndex: innerSpanLength,
      length: "",
      sup: sup,
      sub: sub,
      href: href,
      char: "",
      style: {},
    };

    if ([TagNames.Sub, TagNames.Sup, TagNames.A, TagNames.Span].includes(element.nodeName)) {
      style = element.attributes.style ? element.attributes.style.nodeValue : "";
    } else {
      style = currentRow.attributes.style ? currentRow.attributes.style.nodeValue : "";
    }
    const content = readContentByNodeType(element);
    elemObj.char = content;
    elemObj.style = style ? createStyle(style) : { fontSize: DEFAULT_FONT_SIZE };
    innerSpanLength += content.length;
    elemObj.length = innerSpanLength - elemObj.startIndex;
    innerRowLength += content.length;

    return elemObj;
  });
  return {
    spanLength: innerSpanLength,
    rowLength: innerRowLength,
    characters,
  };
}

export { getCharactersFromElementNode, createStyle };
