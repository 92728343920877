import PropTypes from "prop-types";
import React, { Component } from "react";
import CalloutRect from "./CalloutRect/index";
import {
  centerToTL,
  tLToCenter,
  getNewStyle,
  degToRadian,
} from "../../../components/ResizableRect/utils";

export default class ResizableCalloutRect extends Component {
  static propTypes = {
    left: PropTypes.number.isRequired,
    top: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    rotatable: PropTypes.bool,
    moveHandle: PropTypes.bool,
    rotateAngle: PropTypes.number,
    parentRotateAngle: PropTypes.number,
    scaleX: PropTypes.number,
    scaleY: PropTypes.number,
    zIndex: PropTypes.number,
    zoomable: PropTypes.string,
    minWidth: PropTypes.number,
    minHeight: PropTypes.number,
    aspectRatio: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    onDoubleClick: PropTypes.func,
    onRotateStart: PropTypes.func,
    onRotate: PropTypes.func,
    onRotateEnd: PropTypes.func,
    onResizeStart: PropTypes.func,
    onResize: PropTypes.func,
    onResizeEnd: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrag: PropTypes.func,
    onDragEnd: PropTypes.func,
    onDelete: PropTypes.func,
    className: PropTypes.string,
  };

  static defaultProps = {
    parentRotateAngle: 0,
    rotateAngle: 0,
    rotatable: true,
    moveHandle: false,
    minWidth: 10,
    minHeight: 10,
    zIndex: 0,
  };

  handleResize = (angle, startAngle, newSide) => {
    if (!this.props.onResize) return;
    let rotateAngle = Math.round(startAngle + angle);
    if (rotateAngle >= 360) {
      rotateAngle -= 360;
    } else if (rotateAngle < 0) {
      rotateAngle += 360;
    }
    if (rotateAngle > 356 || rotateAngle < 4) {
      rotateAngle = 0;
    } else if (rotateAngle > 86 && rotateAngle < 94) {
      rotateAngle = 90;
    } else if (rotateAngle > 176 && rotateAngle < 184) {
      rotateAngle = 180;
    } else if (rotateAngle > 266 && rotateAngle < 274) {
      rotateAngle = 270;
    }
    this.props.onResize({ height: newSide, calloutAngle: rotateAngle });
  };

  render() {
    const {
      className,
      top,
      left,
      width,
      height,
      rotateAngle,
      parentRotateAngle,
      rotatable,
      onRotate,
      onResizeStart,
      onResizeEnd,
      onClick,
      zIndex,
    } = this.props;

    const styles = tLToCenter({
      top,
      left,
      width,
      height,
      rotateAngle,
      zIndex,
    });
    return (
      <CalloutRect
        styles={styles}
        className={className}
        rotatable={Boolean(rotatable && onRotate)}
        parentRotateAngle={parentRotateAngle}
        onClick={onClick}
        onResizeStart={onResizeStart}
        onResize={this.handleResize}
        onResizeEnd={onResizeEnd}
      >
        {this.props.children}
      </CalloutRect>
    );
  }
}
