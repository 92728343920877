import MxDataArray from "../../MxDataArray";
import { WorkingCapitalTypes } from "./constants";
import AccountReceivable from "./AccountReceivable";
import AccountPayable from "./AccountPayable";
import Inventory from "./Inventory";

class WorkingCapitalStorage extends MxDataArray {
  dataManager = null;
  driverStorage = null;
  loaded = false;

  setData = (callBack, data = this.data) => {
    this.pushArray(
      data.map((item) => {
        const newItem = this.getType(item);
        if (newItem && newItem.Totals) {
          this.setExistingDrivers(newItem);
          return newItem;
        } else {
          // this.dataManager.del(this.dataManager.Tables.Finance_WorkingCapital.TableName, {ID_CompanyScenario: this.dataManager.CompanyScenarioInfo.ID}, () => {
          //     console.log('deleting',newItem);
          //
          // }, () => {
          // });
        }
      }),
    );

    this.loaded = true;

    callBack();
  };

  loadWorkingCapital = (callBack, shouldFinish = true) => {
    this.clear();
    this.dataManager.get(
      this.dataManager.Tables.Finance_WorkingCapital.TableName,
      { ID_CompanyScenario: this.dataManager.CompanyScenarioInfo.ID },
      (data) => {
        if (shouldFinish) {
          this.setData(callBack, data);
        } else {
          this.data = data;
          callBack();
        }
      },
      null,
      false,
    );
  };

  getType = (item) => {
    switch (item.Type) {
      case WorkingCapitalTypes.AccountReceivable:
        return new AccountReceivable(item);
      case WorkingCapitalTypes.AccountPayable:
        return new AccountPayable(item);
      case WorkingCapitalTypes.Inventory:
        return new Inventory(item);
    }
  };

  setExistingDrivers = (item) => {
    const IncludedDrivers = [
      AccountReceivable.DriversDesc.ClosingBalance.driverID,
      AccountPayable.DriversDesc.ClosingBalance.driverID,
      Inventory.DriversDesc.ClosingBalance.driverID,
    ];
    item.getAllDrivers().forEach((d) => (d.IsExisting = IncludedDrivers.includes(d.Ref_Field)));
  };

  static create = () => {
    const newArr = new WorkingCapitalStorage();

    newArr.pushOld = newArr.push;
    newArr.push = newArr.pushThis;

    return newArr;
  };
}

export default WorkingCapitalStorage;
