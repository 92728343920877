import React from "react";
import styled from "styled-components";
import { Theme } from "../../theme/styles/theme";
import InstructionalPopup from "../dialogs/instructionsPopup/instructionalPopup";
import { IconPlus, InfoIcon, VideoIcon } from "../icons/svgIcons";
import ButtonMui from "../buttons/buttonMui/buttonMui";

const CenterDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
`;

const StyledParagraph = styled.p`
  ${Theme.text.heading.h4_strong};
  font-family: Inter;
`;

const StyledParagraphp1 = styled.p`
  ${Theme.text.paragraph.p1};
  font-family: Inter;
`;

export const FinancialBlank = ({ ...props }) => {
  return (
    <div id="parentID" className="sccf_container">
      <div style={{ width: "450px", marginLeft: "15px" }}></div>
      <CenterDiv>
        <StyledParagraph>{props.headerText}</StyledParagraph>
        <div style={{ marginTop: "15px" }}>
          <ButtonMui
            disabled={props.disabled}
            label={props.buttonLabel}
            variant={"contained"}
            endIcon={<IconPlus />}
            onClick={props.disabled ? undefined : props.onAdd}
          />
        </div>

        <StyledParagraphp1>Need help to start?</StyledParagraphp1>
        <div
          style={{
            display: "flex",
            marginTop: 0,
            flexDirection: "row",
            gap: "26px",
            justifyContent: "center",
            justifyItems: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <InstructionalPopup
            slider
            actionButton={
              <ButtonMui
                width={150}
                label={"Videos"}
                variant={"contained"}
                endIcon={<VideoIcon />}
                color={"secondary"}
              />
            }
            data_key={"video_links"}
            route={window.location.pathname}
          />
        </div>
        <InstructionalPopup
          actionButton={
            <ButtonMui
              width={150}
              label={"Instructions"}
              variant={"contained"}
              endIcon={<InfoIcon />}
              color={"secondary"}
            />
          }
          data_key={"instructions_contents"}
          route={window.location.pathname}
        />
      </CenterDiv>
      <div style={{ width: "450px", marginRight: "15px" }}></div>
    </div>
  );
};
