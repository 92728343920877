import {
  ComparisonPeriod,
  NumberFormatTypes,
  ReportFrequency,
  StreamTypes,
} from "../components/constants/charts";

const DateSettingsObject = () => {
  if (!global.Modeliks.CompanyInfo) {
    return {};
  }

  let endYear =
    global.Modeliks.DateHelper.years_all[global.Modeliks.DateHelper.years_all.length - 1].Header;
  let maxYear =
    global.Modeliks.DateHelper.years_all[global.Modeliks.DateHelper.years_all.length - 1].Header;
  let endYearOrder =
    global.Modeliks.DateHelper.years_all[global.Modeliks.DateHelper.years_all.length - 1].Order;
  let startMonth =
    global.Modeliks.DateHelper.months_before_actual[0].Year +
    "-" +
    (global.Modeliks.DateHelper.months_before_actual[0].Month + 1);
  let endMonth =
    global.Modeliks.DateHelper.months[global.Modeliks.DateHelper.months.length - 1].Year +
    "-" +
    (global.Modeliks.DateHelper.months[global.Modeliks.DateHelper.months.length - 1].Month + 1);
  let startMonthOrder = global.Modeliks.DateHelper.months_before_actual[0].Order;
  let endMonthOrder =
    global.Modeliks.DateHelper.months[global.Modeliks.DateHelper.months.length - 1].Order;
  let startYear = global.Modeliks.DateHelper.years_all[0].Header;
  let startYearOrder = global.Modeliks.DateHelper.years_all[0].Order;
  let minDate = `${global.Modeliks.DateHelper.months_before_actual[0].Year}-${global.Modeliks.DateHelper.months_before_actual[0].Month + 1}`;
  let maxDate = `${global.Modeliks.DateHelper.months[global.Modeliks.DateHelper.months.length - 1].Year}-${global.Modeliks.DateHelper.months[global.Modeliks.DateHelper.months.length - 1].Month + 1}`;
  let minYear = `${global.Modeliks.DateHelper.years_before_actual[0].Header}`;
  let startYearActual = global.Modeliks.DateHelper.years_before_actual[1].Header;
  let startYearActualOrder = global.Modeliks.DateHelper.years_before_actual[1].Order;
  let endYearActual = global.Modeliks.DateHelper.years_all[1].Header;
  let endYearActualOrder = global.Modeliks.DateHelper.years_all[1].Order;

  if (global.Modeliks.DateHelper.ActiveYears < 5) {
    const findYear = global.Modeliks.DateHelper.years_all.find(
      (y) => y.Order === global.Modeliks.DateHelper.ActiveYears - 1,
    );
    endYear = findYear.Header;
    maxYear = findYear.Header;
    endYearOrder = findYear.Order;
    // if(global.Modeliks.DateHelper.ActiveYears){
    // console.log('is setting object', global.Modeliks.DateHelper.ActiveYears);
    // }
  }

  return {
    actualDates: {
      startMonth,
      endMonth,
      startMonthOrder,
      endMonthOrder,
      startYear: startYearActual,
      endYear: endYearActual,
      startYearOrder: startYearActualOrder,
      endYearOrder: endYearActualOrder,
      minDate,
      maxDate,
      minYear,
      maxYear,
      showAllDrivers: false,
      showMonthly: true,
    },
    financialDates: {
      startMonth,
      endMonth,
      startMonthOrder,
      endMonthOrder,
      startYear,
      endYear,
      startYearOrder,
      endYearOrder,
      minDate,
      maxDate,
      minYear,
      maxYear,
      reportFrequency: Object.keys(ReportFrequency)[0],
      comparisonPeriod: [ComparisonPeriod.actual, ComparisonPeriod.forecast],
      driverKeys: [StreamTypes.Revenue.key],
      numberFormatType: NumberFormatTypes.fullNumbers.key,
    },
  };
};

const DateMonthChange = (month) => {
  if (month) {
    return month.toString().padStart(2, "0");
  }
};

const DateGetMonth = (months, month, year) => {
  if (months && months.length > 0) {
    return months.find((m) => m.Year === year && m.Month === month);
  }
};

const DateGetYear = (years, header) => {
  if (years && years.length > 0) {
    return years.find((m) => m.Header === header.toString());
  }
};

export { DateSettingsObject, DateMonthChange, DateGetMonth, DateGetYear };
