import ReactDOM from "react-dom";

const MenuPortal = ({ children }) => {
  const el = document.getElementById("se_right_menu_container");
  if (el) {
    return ReactDOM.createPortal(children, el);
  } else {
    return null;
  }
  return ReactDOM.createPortal(children, el);
};

export default MenuPortal;
