import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Theme } from "../../theme/styles/theme";
import { SpecialChar } from "../../data/Finance/CalculatedDriver/constants";

let styles = {
  white: Theme.colors.greyscale.white,
  lightgray150: Theme.colors.primary.lightgrey[150],
  lightgray500: Theme.colors.primary.lightgrey[500],
  darkgray300: Theme.colors.primary.darkgrey[300],
  darkgray150: Theme.colors.primary.darkgrey[50],
  darkgray50: Theme.colors.primary.darkgrey[50],
  paragraph3: Theme.text.paragraph.p3,
  paragraph5: Theme.text.paragraph.p5,
  error: Theme.colors.accent.red[150],
};

const StyledInput = styled.input`
  font-family: Inter;
  touch-action: none;
  background-repeat: no-repeat;
  box-sizing: border-box;
  padding: 0 16px 0 16px;
  text-align: start;
  height: 34px;
  // maxWidth: 427px;
  width: ${(props) => (props.width ? props.width : `100%`)};
  // width: 100%;
  color: ${styles["darkgray300"]};
  ${styles["paragraph3"]};
  border: 1px solid ${(props) => (props.error ? styles["error"] : "#E0E0E0")};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : "")};
  border-radius: 8px;
  background-color: ${styles["white"]};
  caret-color: ${styles["darkgray300"]};
  &:focus {
    outline: none;
  }
  ::placeholder {
    color: ${styles["darkgray150"]};
    opacity: 1;
  }

  :-ms-input-placeholder {
    color: ${styles["darkgray150"]};
  }

  ::-ms-input-placeholder {
    color: ${styles["darkgray150"]};
  }
`;

export const ErrorMessage = styled.span`
  display: block !important;
  ${styles["paragraph5"]};
  color: ${styles["error"]};
  margin-top: 0;
  margin-left: 8px;
  font-family: Inter;
`;

function Input(props) {
  useEffect(() => {
    setError(props.errorMessage);
  }, [props.errorMessage]);

  const [error, setError] = React.useState(props.errorMessage);

  const onChange = (e) => {
    if (e.target.value.length > props.max) {
      setError(`Maximum ${props.max} characters.`);
    } else if (e.target.value.length > 0 && e.target.value.toString().trim() === "") {
      setError(`Leading space not allowed.`);
    } else if (e.nativeEvent.data === SpecialChar.DriverNameESCChar && props.validateSpecialChar) {
      setError(`"-" character is not allowed.`);
    } else if (e.target.value.includes("    ")) {
      setError(`consecutive spaces not allowed.`);
    } else {
      if (error) {
        setError(null);
      }

      if (e.target.value.length === 0 && !props.allowEmpty) {
        setError(`Some name is required`);
      }
      props.onChange(e);
    }
  };

  return (
    <>
      <StyledInput
        autoComplete="off"
        onChange={onChange}
        onKeyDown={props.onKeyDown}
        error={props.error}
        max={props.max}
        onBlur={props.onBlur}
        disabled={props.disabled}
        marginRight={props.marginRight}
        width={props.width}
        name={props.name}
        value={props.value}
        type={props.type}
        placeholder={props.placeholder}
        id={props.id}
      />
      <ErrorMessage>{error}</ErrorMessage>
    </>
  );
}

Input.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.any,
  width: PropTypes.any,
  unit: PropTypes.oneOf(["unit", "percentage", "price"]),
  max: PropTypes.number,
  allowEmpty: PropTypes.bool,
  validateSpecialChar: PropTypes.bool,
};

Input.defaultProps = {
  unit: "unit",
  max: 50,
  allowEmpty: false,
  validateSpecialChar: false,
};

function Input2(props) {
  const [error, setError] = React.useState(props.errorMessage);

  useEffect(() => {
    setError(props.errorMessage);
  }, [props.errorMessage]);

  return (
    <div style={props.parentStyle}>
      <StyledInput
        autoComplete="off"
        onChange={props.onChange}
        onKeyDown={props.onKeyDown}
        error={!!error}
        max={props.max}
        onBlur={props.onBlur}
        disabled={props.disabled}
        marginRight={props.marginRight}
        width={props.width}
        name={props.name}
        value={props.value}
        type={props.type}
        placeholder={props.placeholder}
        id={props.id}
      />
      <ErrorMessage>{error}</ErrorMessage>
    </div>
  );
}

export default Input;

export { Input2 };
