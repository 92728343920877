import React from "react";
import { Box, Step, StepLabel, Stepper } from "@mui/material";

const steps = ["Subscription plan", "Register"];

class Steps extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    return (
      <Box pt={2}>
        <Stepper activeStep={this.props.step} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    );
  }
}

export default Steps;
