import React from "react";
import { SketchPicker } from "react-color";
import { MenuItem } from "@szhsin/react-menu";
class ChartSketchPicker extends React.Component {
  state = {
    displayColorPicker: false,
    color: "#FFF",
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = (color) => {
    this.setState({ color: color.hex });
  };

  render() {
    return (
      <>
        <SketchPicker color={this.state.color} onChange={this.handleChange} />
        <MenuItem
          className="sccc_chart_color_picker_button"
          onClick={() => this.props.chooseColor(this.state.color)}
        >
          Set Color
        </MenuItem>
      </>
    );
  }
}

export default ChartSketchPicker;
