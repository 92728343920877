import ReactDOM from "react-dom";

const HeaderLeftPortal = ({ children }) => {
  const el = document.getElementById("hd_left_container");
  if (el) {
    return ReactDOM.createPortal(children, el);
  } else {
    return null;
  }
  return ReactDOM.createPortal(children, el);
};

export default HeaderLeftPortal;
