import { fetchTemplatesForSection } from "../../services/api/Repositories/master";
import { createBlankPitchSection } from "../../services/api/Repositories/root";

const pitchFlows = ({
                      SlideSectionsTableName = "slidesections_client",
                      TemplatesTableName = "slidetemplates_client",
                      SectionNameKey = "",
                      PitchScenarioIDKey = "PitchScenario_ID"
                    }) => {

  async function createEntityOnTemplatesTable(template) {
    return await new Promise((resolve, reject) =>
      global.Modeliks.post(TemplatesTableName, template, async (newTemplateResult) => {
        const newTemplate = {
          ...template,
          ID: newTemplateResult.id,
        };
        return resolve(newTemplate);
      }),
    );
  }

  async function modeliksCreateNewSection(newSection) {
    return await new Promise((resolve, reject) =>
      global.Modeliks.post(
        SlideSectionsTableName,
        {...newSection, isCustom: false},
        async (section) => {
          return resolve(section);
        },
      ),
    );
  }

  async function createFirstEmptySection(newSection) {
    return await new Promise((resolve, reject) =>
      global.Modeliks.post(SlideSectionsTableName, newSection, async (newSection, error) => {
        if (newSection) {
          return resolve(newSection.id);
        }
      }),
    );
  }

  async function createTemplate(template) {
    return await new Promise((resolve, reject) =>
      global.Modeliks.post(TemplatesTableName, template, async (newTemplate, error) => {
        if (newTemplate) {
          return resolve(newTemplate.id);
        }
      }),
    );
  }

  async function modeliksDeleteSection(id) {
    return new Promise((resolve, reject) => {
      global.Modeliks.del(SlideSectionsTableName, {ID: id}, () => {
        return resolve();
      });
    });
  }

  function modeliksDeleteCustomSection(id) {
    return new Promise((resolve, reject) => {
      global.Modeliks.put(SlideSectionsTableName, {ID: id}, {isDeleted: true}, () => {
        return resolve();
      });
    });
  }

  function modeliksGetSectionById(id) {
    return new Promise((resolve, reject) => {
      global.Modeliks.get(SlideSectionsTableName, {ID: id}, (section) => {
        return resolve(section);
      });
    });
  }

  function modeliksGetTemplateById(id) {
    return new Promise((resolve, reject) => {
      global.Modeliks.get(TemplatesTableName, {ID: id}, (section) => {
        return resolve(section);
      });
    });
  }

  async function createCustomSection(section) {
    //TODO: improvement: for now only return the modeliks create section, but all creation flow should be refactored here
    return await modeliksCreateNewSection(section);
  }

  async function createBlankSection({key, value, order}) {
    const result = await createBlankPitchSection({key, value, order});
    if (!result) {
      throw new Error("Blank pitch section not created");
    }
    //let parsedResult = JSON.parse(result.text);

    const {sectionID, templateID} = result;
    const section = await modeliksGetSectionById(sectionID);
    const template = await modeliksGetTemplateById(templateID);

    return {section, template};
  }

  async function createNonCustomSection(newSection, oldSectionId) {
    try {
      const sectionElement = await modeliksCreateNewSection(newSection);
      const templatesResult = await fetchTemplatesForSection(oldSectionId);

      const updatedNewSection = {
        ...newSection,
        ID: sectionElement.id,
      };
      //why this condition?
      if (templatesResult.length > 0) {
        let IDs = templatesResult.map((template) => template.ID);
        let templateDtos = [...templatesResult].map(
          // remove ID prop from templatesResults
          ({ID, ...template}, index) => {
            return {
              ...template,
              SectionID: sectionElement.id,
              MasterTemplateID: IDs[index],
              isCustom: false,
            };
          },
        );

        const newTemplates = await Promise.all(templateDtos.map(createEntityOnTemplatesTable));

        return {updatedNewSection, newTemplates};
      }
      return {updatedNewSection, newTemplates: updatedNewSection.Templates};
    } catch (error) {
      console.error(error);
      throw new Error(`Error creating non custom section: ${error}`);
    }
  }

  async function createDefaultSectionAndTemplate() {
    const DEFAULT_EMPTY_SECTION = Object.freeze({
      [SectionNameKey]: "Blank Slide",
      order: 1,
      CategoryID: 5,
      CreatedAt: new Date().toISOString(),
      UpdatedAt: new Date().toISOString(),
      CreatedBy: global.Modeliks.User.ID,
      UpdatedBy: global.Modeliks.User.ID,
      Hidden: false,
      [PitchScenarioIDKey]: global.Modeliks.PitchScenarioInfo.ID,
      isCustom: true,
      isDeleted: false,
      AccountID: global.Modeliks.Account.ID,
      dontShowInDialog: true,
    });

    const DEFAULT_EMPTY_TEMPLATE = Object.freeze({
      TemplateName: "Template 1",
      order: 1,
      MasterTemplateID: null,
      isDefault: true,
      CreatedAt: new Date().toISOString(),
      UpdatedAt: new Date().toISOString(),
      CreatedBy: global.Modeliks.User.ID,
      UpdatedBy: global.Modeliks.User.ID,
      isCustom: true,
      AccountID: global.Modeliks.Account.ID,
      SectionId: newSectionId,
    });

    let defaultSection = {...DEFAULT_EMPTY_SECTION};
    let defaultTemplate = {...DEFAULT_EMPTY_TEMPLATE};
    const newSectionId = await createFirstEmptySection(defaultSection);

    const templateId = await createTemplate(defaultTemplate);

    defaultTemplate = {
      ...defaultSection,
      ID: templateId,
      SectionID: newSectionId,
    };

    defaultSection = {
      ...defaultSection,
      ID: newSectionId,
      Templates: [defaultTemplate],
    };

    return {section: defaultSection, template: defaultTemplate};
  }

  async function deleteCustomSectionWithId(index, section, tabs) {
    // is this needed?
    section.isDeleted = true;
    if (section.Save) {
      await new Promise((resolve, reject) => {
        return section.Save(() => {
          return resolve();
        });
      });
    } else {
      await modeliksDeleteCustomSection(section.ID);
    }

    let updatedTabs = tabs.filter((_, i) => i !== index);
    if (updatedTabs.length === 0) {
      const {section, template} = await createDefaultSectionAndTemplate();
      return {slideSections: updatedTabs, section, template};
    }

    return {slideSections: updatedTabs};
  }

  async function deleteNonCustomSectionWithId(index, sectionId, tabs) {
    await modeliksDeleteSection(sectionId);
    let updatedTabs = tabs.filter((_, i) => i !== index);

    if (updatedTabs.length === 0) {
      const {section, template} = await createDefaultSectionAndTemplate();
      return {slideSections: updatedTabs, section, template};
    }

    return {slideSections: updatedTabs};
  }

  return {
    createNonCustomSection,
    deleteNonCustomSectionWithId,
    deleteCustomSectionWithId,
    createCustomSection,
    createBlankSection,
  };
}

export default pitchFlows;
