import React, { useState, useEffect } from "react";
import AddDialog from "../AddDialog/AddDialog";
import { AddDialogTitle } from "../AddDialog/AddDialogTitle";
import { ButtonsWrapper } from "../../../../pages/Secure/Onboarding/components/ButtonsWrapper";
import ButtonMui from "../../../../components/buttons/buttonMui/buttonMui";
import { financials_paths } from "../../../../data/Finance/constants";
import { Link, useLocation } from "react-router-dom";
import { ReportsTypes } from "../../../../data/Finance/Reports/constants";
import "./index.scss";
import Mx_Tooltip_Hover from "../../../icons/Mx_Tooltip_Hover";

const fn_path = "/forecast";

function CashBalanceDialog() {
  const [open, setOpen] = useState(false);
  const CashBalanceDriver = global.Modeliks.ReportsStore.find(
    (d) => d.ReportType === ReportsTypes.CashFlow,
  ).CashAtEndOfThePeriod;
  if (!CashBalanceDriver) {
    return;
  }

  if (!CashBalanceDriver.Values.some((v) => v.Value < 0)) {
    return;
  }

  const LinkButton = (props) => {
    const location = useLocation();
    const onClickLink = () => {
      if (location.pathname === `${fn_path}${props.to}`) {
        setOpen(false);
      }
    };

    return (
      <Link onClick={onClickLink} to={`${fn_path}${props.to}`} className="scdfci_link_button">
        <span className="scdfci_text_button italic">({props.label})</span>
      </Link>
    );
  };

  const textTitle = (
    <span className="scdfci_text_balance">
      Your Cash Balance must not be negative. Please increase your cash inflow or decrease your cash
      outflow.
    </span>
  );

  return (
    <>
      <div className="scdfci_cash_balance_div">
        <Mx_Tooltip_Hover
          icon={
            <>
              {textTitle}
              <span className="scdfci_text_button" onClick={() => setOpen(true)}>
                Read More...
              </span>
            </>
          }
          title={textTitle}
        />
      </div>
      <AddDialog width="850px" color="#FFFFFF" open={open}>
        <AddDialogTitle
          onClose={() => setOpen(false)}
          title={<span className="scdfci_title_dialog">How to Resolve Negative Cash Balance</span>}
        />

        <span className="scdfci_dialog_div">
          <div>
            <b>There are several options to avoid running into a negative cash balance:</b>
          </div>
          <ul>
            <li>
              <b>Increase cash inflow by</b>
              <ul className="scdfci_list_item">
                <li>
                  using <b>financing</b> instruments, such as Loans, Line of Credit, Facility,
                  Equity, etc.
                  <LinkButton to={financials_paths.financing} label="go to Financing" />
                </li>
                <li>
                  increasing <b>revenues</b> and other income by e.g., price or volume increase
                  <LinkButton to={financials_paths.revenue} label="go to Revenue" />
                </li>
                <li>
                  managing working capital by e.g., reducing accounts receivable or inventory days
                  outstanding
                  <LinkButton to={financials_paths.working_capital} label="go to Working Capital" />
                </li>
              </ul>
            </li>
            <li>
              <b>Decrease cash outflow by</b>
              <ul className="scdfci_list_item">
                <li>
                  reducing <b>expenses</b>, such as cost of goods sold
                  <LinkButton
                    to={financials_paths.cost_of_sales}
                    label="go to Cost of Goods Sold"
                  />
                  , employee cost
                  <LinkButton to={financials_paths.employees} label="go to Employees" />
                </li>
                <li>
                  managing <b>working capital</b> by e.g., increasing accounts payable days
                  outstanding{" "}
                  <LinkButton to={financials_paths.working_capital} label="go to Working Capital" />
                </li>
                <li>
                  reducing <b>capital expenditure</b>
                  <LinkButton to={financials_paths.assets} label="go to Assets" />
                </li>
              </ul>
            </li>
          </ul>
        </span>
        <ButtonsWrapper>
          <ButtonMui label={"Got it"} variant={"contained"} onClick={() => setOpen(false)} />
        </ButtonsWrapper>
      </AddDialog>
    </>
  );
}

export default CashBalanceDialog;
