import React, { Fragment, useState, useRef } from "react";
import "../HeaderTextEditor.scss";
import "../HeaderDefault.scss";
import { Minus, Plus } from "../../components/IconButtons/SubMenuIcons";
import {} from "../../components/IconButtons/SubMenuIcons";
import Tooltip from "@mui/material/Tooltip";
import {
  Menu,
  MenuItem,
  SubMenu,
  MenuButton,
  ControlledMenu,
  useMenuState,
} from "@szhsin/react-menu";
import "./FontSizeEditComponent.scss";
import IconButton from "@mui/material/IconButton";
import { SmallArrowDown } from "../../../../components/icons/svgIcons";

function FontSizeEditComponent(props) {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState();
  const [value, setValue] = useState(props.style ? props.style : "");

  const onChange = (val) => {
    if (props.objType && props.objType === "tableObject") {
      props.onChange({ fontSize: val });
    } else {
      props.onChange("fontSize", val);
    }
    setValue(val);
  };

  if (props.style && props.style != value) {
    setValue(props.style);
  }

  const menuItems = [6, 7, 8, 9, 11, 12, 14, 18, 24, 30, 36, 48, 60, 72, 96, 120, 150, 200];

  const FontSizeEdit = (props) => {
    return (
      <>
        <div
          className={
            props.className ? "font_size_container " + props.className : "font_size_container "
          }
        >
          <button
            onClick={props.style ? (event) => onChange(props.style - 1 + "pt") : null}
            className={"font_size_button"}
          >
            <Minus />
          </button>
          <div className={"input_btn_container"}>
            <input
              ref={ref}
              onMouseDown={(e) => {
                e.defaultPrevented = false;
              }}
              onBlur={() => onChange(value + "pt")}
              type={"number"}
              className={"input"}
              placeholder={props.style ? props.style : ""}
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
            <IconButton className={isOpen ? "active" : ""} onClick={() => setOpen(true)}>
              <SmallArrowDown />
            </IconButton>
          </div>
          <button
            onClick={props.style ? (event) => onChange(props.style + 1 + "pt") : null}
            className={"font_size_button"}
          >
            <Plus />
          </button>
        </div>

        <ControlledMenu
          state={isOpen ? "open" : "closed"}
          anchorRef={ref}
          onClose={() => setOpen(false)}
          className={"font_size_menu"}
        >
          {menuItems.map((c, index) => (
            <MenuItem id={"MenuItems"} key={"newMI_" + index} onClick={() => onChange(c + "pt")}>
              {c}
            </MenuItem>
          ))}
        </ControlledMenu>
      </>
    );
  };

  return <>{FontSizeEdit(props)}</>;
}

export default FontSizeEditComponent;
