import React, { Fragment, useState } from "react";
import Menu from "@mui/material/Menu";
import { RightArrow, LinkFromExternalSource } from "../IconButtons/SubMenuIcons";

function LinkSubContextMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openLink = Boolean(anchorEl);
  const [inputValue, setInputValue] = React.useState("Search or paste link");
  const [deletedValue, setDeletedValue] = React.useState(0);

  const handleInputClick = () => {
    if (deletedValue === 0) {
      setInputValue("");
      setDeletedValue(1);
    }
  };
  const handleClick = (event) => {
    if (!anchorEl) setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const toLink = () => {
    let selectedObject = props.getSelectedObject();

    selectedObject.forEach((p) => {
      if (Object.prototype.toString.call(p) == "[object Array]") {
        p.forEach((obj) => {
          obj.href = inputValue;
        });
      } else {
        p.href = inputValue;
      }
    });

    props.groupStruct();
  };

  const LinkSubMenu = () => {
    return (
      <>
        <div
          aria-controls={openLink ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openLink ? "true" : undefined}
          onClick={(e) => handleClick(e)}
          // onMouseOver={(e)=>handleClick(e)}
        >
          <Menu
            style={{ left: "372px", top: "-23px" }}
            anchorEl={anchorEl}
            open={openLink}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <div>
              <div>
                <input
                  type={"text"}
                  onChange={(event) => setInputValue(event.currentTarget.value)}
                  value={inputValue}
                  onFocus={handleInputClick}
                ></input>
                <div onClick={() => toLink()}>Set</div>
              </div>
              <div id={"contextMenuOption"}>
                <span>Company</span>
              </div>
              <div id={"contextMenuOption"}>
                <span>Headline</span>
              </div>
              <div id={"contextMenuOption"}>
                <span>Problem</span>
              </div>
              <div id={"contextMenuOption"}>
                <span>Solution</span>
              </div>
              <div id={"contextMenuOption"}>
                <span>Target Market</span>
              </div>
              <div id={"contextMenuOption"}>
                <span>Competition</span>
              </div>
              <div id={"contextMenuOption"}>
                <span>Sales channels</span>
              </div>
              <div id={"contextMenuOption"}>
                <span>Marketing activities</span>
              </div>
              <div id={"contextMenuOption"}>
                <span>Financials</span>
              </div>
              <div id={"contextMenuOption"}>
                <span>Roadmap</span>
              </div>
              <div id={"contextMenuOption"}>
                <span>Team</span>
              </div>
            </div>
          </Menu>
          <span id={"contextMenuOptionWithIconAndArrow"}>
            <LinkFromExternalSource />
            <span id={"TextBetweenIconAndHelper"}>Link</span>
            <RightArrow />
          </span>
        </div>
      </>
    );
  };
  return <>{LinkSubMenu()}</>;
}
export default LinkSubContextMenu;
