export const ROLES = {
  viewer: "VIEWER",
  editor: "EDITOR",
  owner: "OWNER",
};

export const SCOPES = {
  canCreate: "can-create",
  canEdit: "can-edit",
  canDelete: "can-delete",
  canView: "can-view",
};

const NumberOfBusinessPlans = 5;
const NumberOfPitchDesks = 5;
const NumberOfStreams = 15;
const NumberOfOtherStreams = 25;
const NumberOfCustomKPI = 10;
const NumberCustomKPILists = 100;
const NumberTotalScenarios = 3;
const NumberOfCOGSAndRevenues = 30;
const NumberOfMaxStreams = 60;

export const PERMISSIONS = {
  Financials: {
    key: "Financials",
    restrictions: {
      view: { key: "view", active: false },
      edit: { key: "edit", active: false },
      overAll: { key: "overAll", active: true },
      FinancialModelTemplates: { key: "FinancialModelTemplates", active: true },
      FinancialDriverFeature: { key: "FinancialDriverFeature", active: true },
      ExportExcel: { key: "ExportExcel", active: true },
      ExportPDF: { key: "ExportPDF", active: true },
      ScenarioManager: { key: "ScenarioManager", active: true },
      MaxStreamsCount: {
        TotalRevenues: NumberOfCOGSAndRevenues,
        TotalCostOfSales: NumberOfCOGSAndRevenues,
        TotalEmployees: NumberOfMaxStreams,
        TotalExpenses: NumberOfMaxStreams,
        TotalAssets: NumberOfOtherStreams,
        TotalFinancing: NumberOfOtherStreams,
        TotalSubscriptionPerPlan: NumberOfStreams,
        TotalCustomKPI: NumberOfCustomKPI,
        TotalCustomKPILists: NumberCustomKPILists,
        TotalDriverPerFormula: NumberOfStreams,
        TotalScenarios: NumberTotalScenarios,
      },
    },
  },
  Pitch: {
    key: "Pitch",
    restrictions: {
      view: { key: "view", active: false },
      edit: { key: "edit", active: false },
      overAll: { key: "overAll", active: true },
      NumberOfPitchDesks: NumberOfPitchDesks,
      ShareLink: { key: "ShareLink", active: true },
      ExportPDF: { key: "ExportPDF", active: true },
      Industries: { key: "Industries", active: true },
    },
  },
  BusinessPlan: {
    key: "BusinessPlan",
    restrictions: {
      view: { key: "view", active: false },
      edit: { key: "edit", active: false },
      overAll: { key: "overAll", active: true },
      NumberOfBP: NumberOfBusinessPlans,
      ShareLink: { key: "ShareLink", active: true },
      ExportPDF: { key: "ExportPDF", active: true },
    },
  },
  Dashboards: {
    key: "Dashboards",
    restrictions: {
      view: { key: "view", active: false },
      edit: { key: "edit", active: false },
      overAll: { key: "overAll", active: true },
      CustomKPIS: { key: "CustomKPIS", active: true },
      CustomDashboards: { key: "CustomDashboards", active: true },
      ManagementReports: { key: "ManagementReports", active: true },
      ExportExcel: { key: "ExportExcel", active: true },
      ExportPDF: { key: "ExportPDF", active: true },
    },
  },
  Valuation: {
    key: "Valuation",
    restrictions: {
      view: { key: "view", active: false },
      edit: { key: "edit", active: false },
    },
  },
  Subscriptions: {
    key: "Subscriptions",
    restrictions: {
      view: { key: "view", active: false },
      edit: { key: "edit", active: false },
    },
  },
  Users: {
    key: "Users",
    restrictions: {
      view: { key: "view", active: false },
      edit: { key: "edit", active: false },
    },
  },
  Companies: {
    key: "Companies",
    restrictions: {
      view: { key: "view", active: false },
      edit: { key: "edit", active: false },
    },
  },
};
