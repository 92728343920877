import React, { Fragment, useState } from "react";
import "../HeaderTextEditor.scss";
import "../HeaderDefault.scss";
import { Tick, DownArrow, Redo } from "../../components/IconButtons/SubMenuIcons";
import { Menu, MenuItem, SubMenu, MenuButton } from "@szhsin/react-menu";
import Tooltip from "@mui/material/Tooltip";
import ButtonMui from "../../../../components/buttons/buttonMui/buttonMui";

function FontFamilyEditComponent(props) {
  const [fonts, setFonts] = React.useState([
    "Inter",
    "Arial",
    "Arial Black",
    "Bahnschrift",
    "Calibri",
    "Cambria",
    "Cambria Math",
    "Candara",
    "Comic Sans MS",
    "Consolas",
    "Constantia",
    "Corbel",
    "Courier New",
    "Ebrima",
    "Franklin Gothic Medium",
    "Open Sans, sans-serif",
    "Gabriola",
    "Gadugi",
    "Georgia",
    "HoloLens MDL2 Assets",
    "Impact",
    "Ink Free",
    "Javanese Text",
    "Leelawadee UI",
    "Lucida Console",
    "Lucida Sans Unicode",
    "Malgun Gothic",
    "Marlett",
    "Microsoft Himalaya",
    "Microsoft JhengHei",
    "Microsoft New Tai Lue",
    "Microsoft PhagsPa",
    "Microsoft Sans Serif",
    "Microsoft Tai Le",
    "Microsoft YaHei",
    "Microsoft Yi Baiti",
    "MingLiU-ExtB",
    "Mongolian Baiti",
    "MS Gothic",
    "MV Boli",
    "Myanmar Text",
    "Nirmala UI",
    "Palatino Linotype",
    "Segoe MDL2 Assets",
    "Segoe Print",
    "Segoe Script",
    "Segoe UI",
    "Segoe UI Historic",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "SimSun",
    "Sitka",
    "Sylfaen",
    "Symbol",
    "Tahoma",
    "Times New Roman",
    "Trebuchet MS",
    "Verdana",
    "Webdings",
    "Wingdings",
    "Yu Gothic",
  ]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openFontFamily = Boolean(anchorEl);
  const handleClick = (event) => {
    if (!anchorEl) setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onChange = (font) => {
    if (props.objType && props.objType === "tableObject") {
      props.onChange({ fontFamily: font });
    } else {
      props.onChange("fontFamily", font, false);
    }
  };
  const FontFamilyEdit = () => {
    return (
      <Menu
        className="font_family"
        menuButton={({ open }) => (
          <ButtonMui
            tooltipText={"Font Family"}
            style={{
              color: open ? "#01AFFD" : "inherit",
              fontFamily: props.style && props.style.fontFamily ? props.style.fontFamily : "Inter",
            }}
            className={`font_family_btn ${props.className ? props.className : ""}`}
            disabled={props.disableEdit}
            variant={"text"}
            roundnessType={"squared"}
            color={"black"}
            label={props.style && props.style.fontFamily ? props.style.fontFamily : "Inter"}
            endIcon={<DownArrow active={open} />}
          />
        )}
      >
        <div className="heading_container">
          <span className="heading">Theme Font</span>
        </div>
        <MenuItem>
          <Tick shouldShow={true} />
          <span
            style={{
              fontFamily: props.style && props.style.fontFamily ? props.style.fontFamily : "Inter",
            }}
            className="font_name"
          >
            {props.style && props.style.fontFamily ? props.style.fontFamily : "Inter"}
          </span>
        </MenuItem>
        <div className="horizontal_separator" />

        {fonts.map((font, index) => (
          <MenuItem onClick={() => onChange(font)} key={"font_" + new Date() + "_" + index}>
            <Tick
              key={"font_" + new Date() + "_" + index}
              shouldShow={true}
              className={props.style && props.style.fontFamily == font ? "tick" : "tick hidden"}
            />
            <span style={{ fontFamily: font }} className="font_name">
              {font}
            </span>
          </MenuItem>
        ))}
      </Menu>
    );
  };
  return <>{FontFamilyEdit()}</>;
}

export default FontFamilyEditComponent;
