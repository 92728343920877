import React from "react";
import { Paragraph } from "../../../../../components/typography/paragraph/Paragraph";
import BorderedWhiteCard from "./components/BorderedWhiteCard";
import { IconX, InfoIcon, VideoIcon } from "../../../../../components/icons/svgIcons";
import IconButton from "@mui/material/IconButton";
import "./components/CalculatedDriver.scss";
import Button from "../../../../../components/actions/Button";
import InstructionalPopup from "../../../../../components/dialogs/instructionsPopup/instructionalPopup";
import ButtonMui from "../../../../../components/buttons/buttonMui/buttonMui";

class CalculatedDriverTitle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
    };
  }

  render() {
    return (
      <>
        <div className="psfccc_title_div_b">
          <Paragraph size="p1_strong" label={this.props.title} />
          {this.props.enableClose && (
            <Button
              size={"large"}
              onClick={() => this.props.closeCalcululatedDriver(true)}
              label="Back"
              width={"135px"}
              padding={"0 0 0 0"}
              iconLeft={true}
              noElevation={true}
              iconColor={"primary"}
              icon="ArrowLeft"
              color={"primary"}
            />
          )}
        </div>
        <div className={"psfccc_white_card_container"}>
          <BorderedWhiteCard visible={this.state.visible && this.props.isNew}>
            {this.props.IsKPI ? (
              <span className="notification_text">
                You can create your own custom indicators by using your business' value drivers.
                Create a formula to calculate your custom indicator for your reporting purposes and
                needs.
              </span>
            ) : (
              <span className="notification_text">
                Activating the "Set Formula" option enables you to create specific value drivers
                (indicators) for your business, which you can use to setup formulas for calculating
                any revenue or cost line in your financial plan. Every business has its own specific
                value drivers, so you can customize your financial plan to any business model.
              </span>
            )}

            <IconButton onClick={() => this.setState({ visible: false })}>
              <IconX />
            </IconButton>
          </BorderedWhiteCard>
          <div className={"psfccc_wcc_cont"}>
            <InstructionalPopup
              startIndex={0}
              route={"drivers"}
              actionButton={
                <ButtonMui
                  width={136}
                  label={"Videos"}
                  variant={"contained"}
                  endIcon={<VideoIcon />}
                  color={"secondary"}
                />
              }
              data_key={"video_links"}
            />
            <InstructionalPopup
              startIndex={0}
              route={"drivers"}
              actionButton={
                <ButtonMui
                  width={136}
                  label={"Instructions"}
                  variant={"contained"}
                  endIcon={<InfoIcon />}
                  color={"secondary"}
                />
              }
              data_key={"instructions_contents"}
            />
          </div>
        </div>
      </>
    );
  }
}

export default CalculatedDriverTitle;
