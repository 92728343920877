import React from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import { UserEdit, KeyIcon, GroupIcon, TimeLoadIcon } from "../../../../components/icons/svgIcons";
import Tooltip from "@mui/material/Tooltip";

const getIcon = (UserType,ActiveAccount = false) => {
  switch (UserType) {
    case 0:
      return <GroupIcon ActiveAccount={ActiveAccount} />;
    case 1:
      return <GroupIcon ActiveAccount={ActiveAccount} />;
    case 2:
      return <KeyIcon ActiveAccount={ActiveAccount}/>;
    default:
      return <TimeLoadIcon />;
  }
};

const getTitle = (UserType) => {
  switch (UserType) {
    case 0:
      return "User";
    case 1:
      return "User";
    case 2:
      return "Account Owner";
    default:
      return "Account Activation Pending";
  }
};

function GetUserTypeIcon(props) {
  return (
    <Tooltip title={getTitle(props.UserType)}>
      <IconButton size="small">{getIcon(props.UserType, props.activeAccount)}</IconButton>
    </Tooltip>
  );
}

GetUserTypeIcon.propTypes = {
  UserType: PropTypes.number,
};
export default GetUserTypeIcon;
