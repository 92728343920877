export const IndustryTypes = {
  "E-Commerce": "E-Commerce",
  Education: "Education",
  Hotels: "Hotels",
  Restaurants: "Restaurants",
  Retail: "Retail",
  "SAAS/Subscription": "SAAS/Subscription",
  "Service Businesses": "Service Businesses",
  Other: "Other",
};
