import React from "react";
import Mx_NumInput from "../../../../../../../components/actions/Mx_NumInput";
import { Paragraph } from "../../../../../../../components/typography/paragraph/Paragraph";
import "./single_subscription.scss";
import Mx_Tooltip from "../../../../../../../components/icons/Mx_Tooltip_Click";

class SingleSubscriptionContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onChangeNumberOfCustomer = (e) => {
    this.props.value.CustomersAtStart = e;
    this.props.CustomersAtStartInit.Value = e;
    this.forceUpdate();
  };

  render() {
    return (
      <div className="psfrss_single_subscription_content_div">
          <div className="psfrss_single_subscription_header">
              <div className="psfrss_single_subscription_title">
                  <Paragraph size="p2_strong" label={this.props.label ? this.props.label : 'Numbers of customer at start'} />
              </div>
              {this.props.label ? <Mx_Tooltip description={'If there are existing employees prior to the forecast period, please enter it below.'} /> : ''}
          </div>
        <div className="psfrss_single_subscription_input_div">
          <Mx_NumInput
            singleInput={true}
            selected={true}
            isMultipleSelected={false}
            isEditable={true}
            key={"p_br" + this.props.index}
            onChange={(e) => this.onChangeNumberOfCustomer(e)}
            data={this.props.value.CustomersAtStart}
          />
        </div>
      </div>
    );
  }
}

export default SingleSubscriptionContent;
