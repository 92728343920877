import React, { Fragment } from "react";
import "./HeaderTextEditor.scss";
import HeaderPortal from "../SlideHeader/HeaderPortal.js";
import BorderDashEditComponent from "../SlideHeader/EditorComponents/BorderDashEditComponent";
import BorderColorEditComponent from "../SlideHeader/EditorComponents/BorderColorEditComponent";
import BorderWeightEditComponent from "../SlideHeader/EditorComponents/BorderWeightEditComponent";
import Cropper from "../components/Cropper";
import {
  ArrowDownSubMenu,
  FormatColor,
  Separator,
} from "../components/IconButtons/SubMenuIcons.js";
import { CropImage, ResetImage } from "../components/IconButtons/SubMenuIcons";
import Tooltip from "@mui/material/Tooltip";
import InsertImageComponent from "./EditorComponents/InsertImageComponent";
import ButtonJ from "../components/Buttons/Button";
import ButtonMui from "../../../components/buttons/buttonMui/buttonMui";

function HeaderImageEditor(props) {
  const onChange = (style) => {
    props.onChange(style);
  };

  const handleReset = () => {
    props.setImageSrc(
      props.originalSrc,
      parseInt(props.originalSize[1]),
      parseInt(props.originalSize[0]),
    );
  };

  return (
    <Fragment>
      <BorderColorEditComponent
        onMouseMove={(e) => {
          e.stopPropagation();
        }}
        onChange={onChange}
        style={props.style}
      />
      <BorderWeightEditComponent
        onMouseMove={(e) => {
          e.stopPropagation();
        }}
        onChange={onChange}
        objType={"imageObj"}
        style={props.style}
      />
      <BorderDashEditComponent
        onMouseMove={(e) => {
          e.stopPropagation();
        }}
        onChange={onChange}
        objType={"imageObj"}
        style={props.style}
      />
      <div
        className="sub_header_vertical_separator"
        onMouseMove={(e) => {
          e.stopPropagation();
        }}
      />
      <ButtonMui
        tooltipText={"Crop Image"}
        onClick={props.handleCrop}
        variant={"text"}
        isIconButton
        roundnessType={"squared"}
        color={"black"}
        label={<CropImage active={props.crop} />}
      />
      <ButtonMui
        tooltipText={"Reset Image"}
        onClick={handleReset}
        variant={"text"}
        isIconButton
        roundnessType={"squared"}
        color={"black"}
        label={<ResetImage />}
      />
      <div
        className="sub_header_vertical_separator"
        onMouseMove={(e) => {
          e.stopPropagation();
        }}
      />
      <InsertImageComponent newImage={false} onReplaceImage={props.onReplaceImage} />
      <ButtonMui
        tooltipText={"Format options"}
        className={"xxl"}
        onClick={props.toggleFormatOptions}
        variant={"text"}
        roundnessType={"squared"}
        color={"black"}
        label={"Format options"}
      />
    </Fragment>
  );
}

export default HeaderImageEditor;
