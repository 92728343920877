import React, { Fragment, useState } from "react";
import "../HeaderDefault.scss";
import {
  Images,
  UploadFromPC,
  SearchWeb,
  UploadFromDrive,
  LinkFromExternalSource,
  ArrowDownSubMenu,
  FormatColor,
} from "../../components/IconButtons/SubMenuIcons";
import DialogM from "../../components/Dialog/DialogM";
import { SubMenu, MenuButton, MenuItem, Menu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import Tooltip from "@mui/material/Tooltip";
import ButtonMui from "../../../../components/buttons/buttonMui/buttonMui";

function InsertImageComponent(props) {
  const [open, setOpen] = React.useState(false);

  const openModal = () => {
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
  };

  const handleOpenUploadImage = (file, size) => {
    props.handleUploadImage(file, size);
    setOpen(false);
  };

  const ImageInsert = () => {
    return (
      <>
        {props.newImage ? (
          <SubMenu
            onMouseMove={(e) => e.stopPropagation()}
            className={"header_menu"}
            label={
              // ({ open }) =><Tooltip disableHoverListener={props.disableEdit} arrow title={props.newImage?'Insert Image':'Replace Image'}><span>
              // {
              ({ open }) => (
                <span>
                  <Images active={open} />
                </span>
              )
              // }</span></Tooltip>
            }
          >
            <MenuItem onClick={openModal}>
              <UploadFromPC />
              <span className="item_name">Upload from computer</span>
              {/*<span className='helper_text'>Shift+Alt</span>*/}
            </MenuItem>
          </SubMenu>
        ) : (
          <Menu
            onMouseMove={(e) => e.stopPropagation()}
            menuButton={({ open }) => (
              <ButtonMui
                style={open ? { color: "#01AFFD" } : {}}
                disabled={props.disableEdit}
                tooltipText={"Replace Image"}
                variant={"text"}
                roundnessType={"squared"}
                color={"black"}
                label={"Replace image"}
                endIcon={<ArrowDownSubMenu active={open} />}
              />
            )}
            className={"header_menu"}
          >
            <MenuItem onClick={props.onReplaceImage}>
              <UploadFromPC />
              <span className="item_name">Upload from computer</span>
              <span className="helper_text">Shift+Alt</span>
            </MenuItem>
          </Menu>
        )}

        <DialogM open={open} handleUpload={handleOpenUploadImage} onClose={closeModal} />
      </>
    );
  };

  return <>{ImageInsert()}</>;
}
export default InsertImageComponent;
