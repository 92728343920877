import * as React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Register from "./Auth/Register";
import RegisterAppSumo from "./Auth/RegisterAppSumo";
import Login from "./Auth/Login";
import ForgottenPassword from "./Auth/Reset/ForgottenPassword";
import NewPassword from "./Auth/Reset/NewPassword";
import Sent from "./Auth/Email/Sent";
import Verification from "./Auth/Email/Verification";
import SlideEditor from "../../SlideEditor/User/index";
import BusinessPlan from "../../BusinessPlan/User/publish";
import "./AuthPage.scss";
import Invite from "./Auth/Invite/Invite";
import VerificationSent from "./Auth/Email/VerificationSent";
import RessetPassword from "./Auth/Reset/RessetPassword";
import LandingPage from "./Auth/LandingPage/index";
import Publish from "./Publish";
import SuccessPayment from "../../components/pages/SuccessPayment";
import Dashboards from "../Secure/Dashboards";
import { defaultSelection } from "../Secure";
import VerificationSentCheckInbox from "./Auth/Email/VerificationSentCheckInbox/VerificationSentCheckInbox";

class PublicApp extends React.Component {
  constructor(props) {
    super(props);

    this.qs = "";
    if (window.location.search) {
      this.qs = window.location.search;
    }
  }

  render = () => {
    return (
      <>
        <Routes>
          <Route
            path="/login"
            element={
              <Login
                LoginApp={() => {
                  global.Modeliks.Login();
                }}
              />
            }
          />
          <Route
            path="/login/:guid/:id"
            element={
              <Login
                shouldPrefillEmail={true}
                LoginApp={() => {
                  global.Modeliks.Login();
                }}
              />
            }
          />
          <Route path="/register" element={<Register />} />
          <Route path="/register/appsumo" element={<RegisterAppSumo />} />
          <Route path="/register/:guid" element={<Register />} />
          <Route path="/reset" element={<ForgottenPassword />} />
          <Route path="/newPassword/:guid/:id" element={<NewPassword />} />
          <Route path="/resetPassword/:guid" element={<RessetPassword />} />
          <Route path="/verification/:guid" element={<Verification />} />
          <Route path="/emailSent" element={<VerificationSentCheckInbox secondTextOption />} />
          <Route path="/VerificationSent" element={<VerificationSent />} />
          <Route path="/SuccessPayment/:free_trial" element={<LandingPage />} />
          <Route
            path="/publish/:account_guid/:client_guid"
            element={
              <Publish dataKey={"SlideEditor"}>
                <SlideEditor publishMode={true} />
              </Publish>
            }
          />
          <Route
            path="/publish_mr/:account_guid/:client_guid"
            element={
              <Publish editor_type={"management_reports"} dataKey={"SlideEditor"}>
                <Dashboards
                  defaultView={defaultSelection.reportView}
                  defaultSelectedReport={defaultSelection.selectedReport}
                  defaultUrl={defaultSelection.url}
                  publishManagementReports
                  publishMode={true}
                />
              </Publish>
            }
          />
          <Route
            path="/publish_business_plan/:account_guid/:client_guid"
            element={
              <Publish dataKey={"BusinessPlan"}>
                <BusinessPlan publishMode={true} />
              </Publish>
            }
          />
          <Route path="/invite/:invite_guid/:user_id" element={<Invite />} />
          <Route path="/CheckoutSuccess/:transaction_id" element={<SuccessPayment />} />
          <Route index path="/*" element={<Navigate to={`/login${this.qs}`} />} />
          {/*<Route*/}
          {/*path="*"*/}
          {/*element={<Navigate to="/" />}*/}
          {/*/>*/}
        </Routes>
      </>
    );
  };
}

export default PublicApp;
