import React, { Fragment, useState } from "react";
import "../HeaderTextEditor.scss";
import "../HeaderDefault.scss";
import {
  Bulleted,
  BulletedList1,
  BulletedList2,
  BulletedList3,
  BulletedList4,
  BulletedList5,
  BulletedList6,
  Numbered,
} from "../../components/IconButtons/SubMenuIcons";
import { Menu, MenuItem, SubMenu } from "@szhsin/react-menu";
import Tooltip from "@mui/material/Tooltip";
import ButtonMui from "../../../../components/buttons/buttonMui/buttonMui";

function BulletingEditComponent(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openBulleting = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onChange = (style) => {
    props.onChange(style);
  };

  const Bullet1 = () => {
    const list = props.value.split("<div>");
    let all = [];
    all.push(
      `<ul style="list-style-type: '●'">${list.map((row) => {
        if (row.includes("        ")) {
          return `<ul><ul style="list-style-type: '■'"><li>${row.toString().trim()}</li></ul></ul>`;
        } else if (row.includes("    ")) {
          return `<ul style="list-style-type: '⚬'"><li>${row.toString().trim()}</li></ul>`;
        } else {
          return `<li>${row}</li>`;
        }
      })} </ul>`,
    );
    props.onValueChange(all.toString().split(",").join(""));
  };
  const Bullet2 = () => {
    const list = props.value.split("<div>");
    let all = [];
    all.push(
      `<ul style="list-style-type: '◆'">${list.map((row) => {
        if (row.includes("        ")) {
          return `<ul><ul style="list-style-type: '■"><li>${row.toString().trim()}</li></ul></ul>`;
        } else if (row.includes("    ")) {
          return `<ul style="list-style-type: '➢'"><li>${row.toString().trim()}</li></ul>`;
        } else {
          return `<li>${row}</li>`;
        }
      })}`,
    );
    props.onValueChange(all.toString().split(",").join(""));
  };
  const Bullet3 = () => {
    const list = props.value.split("<div>");
    let all = [];
    all.push(
      `<ul style="list-style-type: '▢">${list.map((row) => {
        if (row.includes("        ")) {
          return `<ul><ul style="list-style-type: '▢"><li>${row.toString().trim()}</li></ul></ul>`;
        } else if (row.includes("    ")) {
          return `<ul style="list-style-type: '▢"><li>${row.toString().trim()}</li></ul>`;
        } else {
          return `<li>${row}</li>`;
        }
      })} </ul>`,
    );
    props.onValueChange(all.toString().split(",").join(""));
  };
  const Bullet4 = () => {
    const list = props.value.split("<div>");
    let all = [];
    all.push(
      `<ul style="list-style-type: '➜'">${list.map((row) => {
        if (row.includes("        ")) {
          return `<ul><ul style="list-style-type: '●'"><li>${row.toString().trim()}</li></ul></ul>`;
        } else if (row.includes("    ")) {
          return `<ul style="list-style-type: '◆'"><li>${row.toString().trim()}</li></ul>`;
        } else {
          return `<li>${row}</li>`;
        }
      })} </ul>`,
    );
    props.onValueChange(all.toString().split(",").join(""));
  };
  const Bullet5 = () => {
    const list = props.value.split("<div>");
    let all = [];
    all.push(
      `<ul style="list-style-type: '★">${list.map((row) => {
        if (row.includes("        ")) {
          return `<ul><ul  style="list-style-type: '⚬'"><li>${row.toString().trim()}</li></ul></ul>`;
        } else if (row.includes("    ")) {
          return `<ul style="list-style-type: '■'"><li>${row.toString().trim()}</li></ul>`;
        } else {
          return `<li>${row}</li>`;
        }
      })} </ul>`,
    );
    props.onValueChange(all.toString().split(",").join(""));
  };
  const Bullet6 = () => {
    const list = props.value.split("<div>");
    let all = [];
    all.push(
      `<ul style="list-style-type: '➢'">${list.map((row) => {
        if (row.includes("        ")) {
          return `<ul><ul style="list-style-type: '■'"><li>${row.toString().trim()}</li></ul></ul>`;
        } else if (row.includes("    ")) {
          return `<ul style="list-style-type: '⚬'"><li>${row.toString().trim()}</li></ul>`;
        } else {
          return `<li>${row}</li>`;
        }
      })} </ul>`,
    );
    props.onValueChange(all.toString().split(",").join(""));
  };

  const BulletingEdit = () => {
    if (props.menu) {
      return (
        <Menu
          className={props.className ? "bulleting " + props.className : "bulleting"}
          menuButton={({ open }) => (
            <ButtonMui
              tooltipText={"Bulleting"}
              className={props.className ? props.className : ""}
              variant={"text"}
              isIconButton
              roundnessType={"squared"}
              color={"black"}
              label={<Bulleted active={open} />}
            />
          )}
        >
          <MenuItems />
        </Menu>
      );
    } else {
      return (
        <SubMenu
          className={props.className ? "bulleting " + props.className : "bulleting"}
          label={
            <Tooltip title="Bulleting" arrow>
              <Bulleted
              // activeColor={props.style.backgroundColor === undefined ? '#585858' : props.style.backgroundColor}
              />
            </Tooltip>
          }
          openTrigger="clickOnly"
          direction={"bottom"}
        >
          <MenuItems />
        </SubMenu>
      );
    }
  };

  const MenuItems = () => {
    return (
      <>
        <MenuItem onClick={() => props.setBullet("bullet", "disc")}>
          <BulletedList1
            id={"MenuItems"}
            active={
              props.objType && props.objType === "table"
                ? props.style &&
                  props.style.bullet &&
                  props.style.curType &&
                  props.style.curType === "disc"
                : props.rowStyle &&
                  props.rowStyle.bullet &&
                  props.rowStyle.curType &&
                  props.rowStyle.curType === "disc"
            }
          />
        </MenuItem>
        <MenuItem onClick={() => props.setBullet("bullet", "'◆'")}>
          <BulletedList2
            id={"MenuItems"}
            active={
              props.objType && props.objType === "table"
                ? props.style &&
                  props.style.bullet &&
                  props.style.curType &&
                  props.style.curType.indexOf("◆") > -1
                : props.rowStyle &&
                  props.rowStyle.bullet &&
                  props.rowStyle.curType &&
                  props.rowStyle.curType.indexOf("◆") > -1
            }
          />
        </MenuItem>
        <MenuItem onClick={() => props.setBullet("bullet", "'▢'")}>
          <BulletedList3
            id={"MenuItems"}
            active={
              props.objType && props.objType === "table"
                ? props.style &&
                  props.style.bullet &&
                  props.style.curType &&
                  props.style.curType.indexOf("▢") > -1
                : props.rowStyle &&
                  props.rowStyle.bullet &&
                  props.rowStyle.curType &&
                  props.rowStyle.curType.indexOf("▢") > -1
            }
          />
        </MenuItem>
        <MenuItem onClick={() => props.setBullet("bullet", "'➜'")}>
          <BulletedList4
            id={"MenuItems"}
            active={
              props.objType && props.objType === "table"
                ? props.style &&
                  props.style.bullet &&
                  props.style.curType &&
                  props.style.curType.indexOf("➜") > -1
                : props.rowStyle &&
                  props.rowStyle.bullet &&
                  props.rowStyle.curType &&
                  props.rowStyle.curType.indexOf("➜") > -1
            }
          />
        </MenuItem>
        <MenuItem onClick={() => props.setBullet("bullet", "'★'")}>
          <BulletedList5
            id={"MenuItems"}
            active={
              props.objType && props.objType === "table"
                ? props.style &&
                  props.style.bullet &&
                  props.style.curType &&
                  props.style.curType.indexOf("★") > -1
                : props.rowStyle &&
                  props.rowStyle.bullet &&
                  props.rowStyle.curType &&
                  props.rowStyle.curType.indexOf("★") > -1
            }
          />
        </MenuItem>
        <MenuItem onClick={() => props.setBullet("bullet", "'➢'")}>
          <BulletedList6
            id={"MenuItems"}
            active={
              props.objType && props.objType === "table"
                ? props.style &&
                  props.style.bullet &&
                  props.style.curType &&
                  props.style.curType.indexOf("➢") > -1
                : props.rowStyle &&
                  props.rowStyle.bullet &&
                  props.rowStyle.curType &&
                  props.rowStyle.curType.indexOf("➢") > -1
            }
          />
        </MenuItem>
      </>
    );
  };

  return <>{BulletingEdit()}</>;
}

export default BulletingEditComponent;
