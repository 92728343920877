import React, { Fragment, useState } from "react";
// import RightMenuPortal from "./RightMenuPortal.js";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ColorPicker from "../ChartTable/ColorPicker";
import InputBox from "../InputBoxComponent/InputBox";
import { FlipHorizontally, FlipVertically, Rotate90 } from "../IconButtons/SubMenuIcons";

function ColorComponent(props) {
  const [openColor, setOpenColor] = React.useState(false);
  const [checkedBackgroundColor, setCheckedBackgroundColor] = React.useState(false);
  const [checkedBorderColor, setCheckedBorderColor] = React.useState(false);

  const handleClickColor = () => {
    if (openColor) {
      setOpenColor(false);
    } else {
      setOpenColor(true);
      props.rightMenuFunc.closeColor = null;
      Object.values(props.rightMenuFunc).map((value) => {
        if (typeof value === "function") {
          value.call();
        }
      });
    }
  };

  const closeMenu = () => {
    setOpenColor(false);
  };

  props.rightMenuFunc.closeColor = closeMenu;

  const handleCheckBackground = (e) => {
    setCheckedBackgroundColor(e.target.checked);
  };

  const handleCheckBorder = (e) => {
    setCheckedBorderColor(e.target.checked);
  };

  const handleColorChange = (color) => {
    props.onPropsChange({ backgroundColor: color });
  };

  const handleBorderColorChange = (color) => {
    props.onPropsChange({ borderColor: color });
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      onKeyDown={(e) => {
        e.stopPropagation();
      }}
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
    >
      <ListItemButton id={"listItem"} onClick={handleClickColor}>
        <ListItemText primary="Color" />
        {openColor ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <div style={{ width: "230px", border: "1px solid #F0F1F7", marginLeft: "18px" }} />
      <Collapse in={openColor} timeout="auto" unmountOnExit>
        <div className={"collapse"}>
          {/*<div className={'listSubHeading'}>Series color</div>*/}
          {/*<div className={'listRow'}>*/}
          {/*<div id='circle' style={{backgroundColor:'#01AFFD',width:'14px',height:'14px',borderRadius:'50%',marginRight:'4px'}}/>*/}
          {/*<div className={'listSubHeading'}>Change color scheme</div>*/}
          {/*</div>*/}
          {/*<div className={'listRow'} style={{paddingLeft:'20px'}}>*/}
          {/*<div id='circle' style={{backgroundColor:'#80D7FE',width:'14px',height:'14px',borderRadius:'50%',marginRight:'4px'}}/>*/}
          {/*<div className={'listRowElement'}>Series 1</div>*/}
          {/*</div>*/}
          {/*<div className={'listRow'} style={{paddingLeft:'20px'}}>*/}
          {/*<div id='circle' style={{backgroundColor:'#01AFFD',width:'14px',height:'14px',borderRadius:'50%',marginRight:'4px'}}/>*/}
          {/*<div className={'listRowElement'}>Series 2</div>*/}
          {/*</div>*/}
          {/*<div className={'listRow'} style={{paddingLeft:'20px'}}>*/}
          {/*<div id='circle' style={{backgroundColor:'#1796FF',width:'14px',height:'14px',borderRadius:'50%',marginRight:'4px'}}/>*/}
          {/*<div className={'listRowElement'}>Series 3</div>*/}
          {/*</div>*/}
          {/*<div className={'listRow'} style={{paddingLeft:'20px'}}>*/}
          {/*<div id='circle' style={{backgroundColor:'#006FC9',width:'14px',height:'14px',borderRadius:'50%',marginRight:'4px'}}/>*/}
          {/*<div className={'listRowElement'}>Series 4</div>*/}
          {/*</div>*/}
          {/*<div className={'listRow'} style={{paddingLeft:'20px'}}>*/}
          {/*<div id='circle' style={{backgroundColor:'#004D8C',width:'14px',height:'14px',borderRadius:'50%',marginRight:'4px'}}/>*/}
          {/*<div className={'listRowElement'}>Series 5</div>*/}
          {/*</div>*/}
          {/*<div className={'listRow'} style={{paddingLeft:'20px'}}>*/}
          {/*<div id='circle' style={{backgroundColor:'#003968',width:'14px',height:'14px',borderRadius:'50%',marginRight:'4px'}}/>*/}
          {/*<div className={'listRowElement'}>Series 6</div>*/}
          {/*</div>*/}
          {/*<div className={'listRow'} style={{paddingLeft:'20px'}}>*/}
          {/*<div id='circle'/>*/}
          {/*<div className={'listRowElement'}></div>*/}
          {/*</div>*/}
          <div className={"listSubHeading"}>Background & border colors</div>
          <div className={"listRow"}>
            <input
              type="checkbox"
              checked={checkedBackgroundColor}
              onChange={handleCheckBackground}
              style={{ margin: 0, marginRight: "4px" }}
            />
            <div className={"listSubHeading"}>Background color</div>
          </div>
          {checkedBackgroundColor && (
            <ColorPicker
              color={props.backgroundColor}
              span={"Select background color"}
              handleColorChange={handleColorChange}
              background={true}
            />
          )}
          <div className={"listRow"}>
            <input
              type="checkbox"
              checked={checkedBorderColor}
              onChange={handleCheckBorder}
              style={{ margin: 0, marginRight: "4px" }}
            />
            <div className={"listSubHeading"}>Border Color</div>
          </div>
          {checkedBorderColor && (
            <ColorPicker
              color={props.borderColor}
              span={"Select border color"}
              handleBorderColorChange={handleBorderColorChange}
              border={true}
            />
          )}
        </div>
      </Collapse>
    </div>
  );
}
export default ColorComponent;
