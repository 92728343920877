import httpService from "../httpService";
const resource = "/api-pdf";

function create(data = {}) {
  return httpService().post(`${resource}/createpdf`, data);
}

function fetch(file, query = {}) {
  return httpService().download(`${resource}/getpdf/${file}`, query);
}

export { create, fetch };
