import { ShortMonths, PeriodTypes } from "../../data/dates";
import MxMath from "../../data/MxMath";
import datastructure from "../datastructure.json";
const RevenueTypes = {
  UnitSales: "unit-sales",
  BillableHours: "billable-hours",
  Subscriptions: "subscription",
  RevenueOnly: "revenue-only",
};

const DialogTypes = {
  WarningSimpleText: "warning-simple-text",
  WarningActions: "warning-actions",
  WarningActionTitle: "warning-actions-title",
  ExceededSubscriptionsLimit: "subscriptions-limit",
  DowngradeSubscription: "downgrade-subscription",
  UpgradeSubscription: "upgrade-subscription",
  CancelSubscription: "cancel-subscription",
  SessionExpired: "session-expired",
  AppUpdate: "app-update",
  SimpleDialog: "simple-dialog",
  RefErrorAllDrivers: "circle-reference-all-drivers",
  ReferAFriend: "refer-a-friend",
  NoAccount: "NoAccount",
};

const ExceededLimitTypes = {
  Users: {
    key: "Users",
    subscription_key: "TotalUsers",
    data_manager_key: "AccountUsers",
    name_key: ["Name", "Surname", "Email"],
    db_key: datastructure.AccountUsers.Fields.isActive.name,
  },
  Companies: {
    key: "Companies",
    subscription_key: "TotalCompanies",
    data_manager_key: "Companies",
    name_key: ["CompanyName"],
    db_key: datastructure.Companies.Fields.Status.name,
  },
};

const PersonnelFunctionTypes = {
  DirectLabor: "direct-labor",
  SalesAndMarketing: "sales-and-marketing",
  ResearchAndDevelopment: "research-and-development",
  GeneralAndAdministrative: "general-and-administrative",
};
const PersonnelEmploymentStatus = {
  Employed: "employed-by-you",
  Contract: "contract-workers",
};

const PersonnelEmployeeSalaries = {
  Value: "per-employee",
  Totals: "totals-of-revenue",
  SpecificStream: "specific-revenue-stream",
};

const ForecastPeriodYears = [
  {
    value: 3,
    description: "3 years",
  },
  {
    value: 5,
    description: "5 years",
  },
];

const ExpenseTypes = {
  SalesAndMarketing: "sales-and-marketing",
  ResearchAndDevelopment: "research-and-development",
  GeneralAndAdministrative: "general-and-administrative",
  DirectCost: "direct-cost",
};

const ExpenseValues = {
  Value: "per-employee",
  Totals: "totals-of-revenue",
  SpecificStream: "specific-revenue-stream",
};

const PayBackOptions = [
  { value: 12, description: "Monthly" },
  { value: 4, description: "Quarterly" },
  { value: 2, description: "Semi Annually" },
  { value: 1, description: "Annually" },
];

const ExpandCollapseTablesID = {
  Revenue: "main_rev",
  CostSales: "main_cost",
  Employees: "main_emp",
  Expense: "main_exp",
  Assets: "main_assets",
  Financing: "main_financing",
  Taxes: "main_taxes",
  Reports: {
    ProfitLoss: "main_profit_loss",
    BalanceSheet: "main_balance_sheet",
    CashFlow: "main_cash_fLow",
    EmployeeSchedule: "main_emp_schedule",
  },
  Dashboards: {
    Actuals: "main_actuals",
    FinancinalStatements: "main_financial_statements",
    CustomKPI: "main_custom_kpi",
    EmployeeSchedule: "act_emp_schedule",
  },
};

const InputFormats = {
  formatNumber: (number, maximumFractionDigits = 1) => {
    if (number != null) {
      return number.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits });
    } else {
      return "";
    }
  },
};

const RevenueTotals = {
  getMonthlyData: (data) => {
    return data.filter((c) => c.PeriodType == PeriodTypes.month);
  },
  getYearlyData: (data) => {
    return data.filter((c) => c.PeriodType == PeriodTypes.year);
  },
  splitIntoPeriodsData: (data) => {
    const months = [];
    const years = [];
    data.forEach((c) => (c.PeriodType == PeriodTypes.month ? months.push(c) : years.push(c)));
    return { months, years };
  },
  getPeriodHeaders: (data) => {
    const periods = RevenueTotals.splitIntoPeriodsData(data);
    const monthHeaders = periods.months.map(
      (i) => ShortMonths[i.Month] + " " + i.Year.toString().substring(2),
    );
    const yearHeaders = periods.years.map((i) => i.Year.toString());

    return { monthHeaders, yearHeaders };
  },
  calculateTotals: (revenue, data) => {
    switch (revenue.RevenueType) {
      case RevenueTypes.UnitSales:
      case RevenueTypes.BillableHours:
      case RevenueTypes.Subscriptions:
        return RevenueTotals.calculateQuantitySinglePrice(data);
      case RevenueTypes.RevenueOnly:
        return RevenueTotals.calculatePriceOnly(data);
    }
  },
  calculateQuantitySinglePrice: (data) => {
    const periods = RevenueTotals.splitIntoPeriodsData(data);
    const monthValues = periods.months.map(RevenueTotals.calculation_QuantitySinglePrice);
    const yearValues = periods.years.map(RevenueTotals.calculation_QuantitySinglePrice);

    return { monthValues, yearValues };
  },
  calculatePriceOnly: (data) => {
    const periods = RevenueTotals.splitIntoPeriodsData(data);
    const monthValues = periods.months.map((c) => c.SinglePrice);
    const yearValues = periods.years.map((c) => c.SinglePrice);
    return { monthValues, yearValues };
  },

  calculation_QuantitySinglePrice: (data) =>
    data.Quantity != null && data.SinglePrice != null ? data.Quantity * data.SinglePrice : null,
  calculation_SubscriptionPlan: (data) => {
    if (data.Quantity == null && data.SinglePrice == null) {
      return null;
    }

    const successSubscriptions = data.Refund != null ? 1 - data.Refund / 100 : 1;
    return data.Quantity * data.SinglePrice * successSubscriptions;
  },
  calculation_CalculateChange: (item, prevItem, calcField = "total") => {
    if (prevItem) {
      const val = MxMath.Round(
        ((item[calcField] - prevItem[calcField]) / prevItem[calcField]) * 100,
      );
      if (prevItem[calcField] < item[calcField]) {
        return Math.abs(val);
      }
      return val;
    }
    return null;
  },
  calculation_CalculateChange2: (item, prevItem, calcField = "total") => {
    if (prevItem) {
      if (prevItem[calcField] == 0) {
        return null;
      } else if (item[calcField] == 0) {
        return -100;
      } else {
        return RevenueTotals.calculation_CalculateChange(item, prevItem, calcField);
      }
    }
    return null;
  },
};

const financials_paths = {
  revenue: "/revenue",
  cost_of_sales: "/costofsales",
  personnel: "/personnel",
  employees: "/employees",
  expenses: "/expenses",
  assets: "/assets",
  financing: "/financing",
  taxes: "/taxes",
  working_capital: "/workingcapital",
  reports: "/reports",
  profit_loss: "/reports/profitloss",
  balance_sheet: "/reports/balancesheet",
  cash_flow: "/reports/cashflow",
  employee_schedule: "/reports/employee_schedule",
  charts_annually: "/reports/charts_annually",
  charts_monthly: "/reports/charts_monthly",
  opening_balance: "/opening_balance",
  saas_dashboard_annually: "/reports/saas_dashboard_annually",
  saas_dashboard_monthly: "/reports/saas_dashboard_monthly",
};

export {
  ExpandCollapseTablesID,
  RevenueTypes,
  RevenueTotals,
  InputFormats,
  PersonnelEmployeeSalaries,
  PersonnelEmploymentStatus,
  PersonnelFunctionTypes,
  ExpenseTypes,
  ExpenseValues,
  PayBackOptions,
  ForecastPeriodYears,
  financials_paths,
  DialogTypes,
  ExceededLimitTypes,
};
