import React from "react";
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-dragdata";
import ChartDataLabels from "chartjs-plugin-datalabels";
import PropTypes from "prop-types";
import { DataTypes } from "../ChartComponents/DataTypes";

import {
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import DoughnoutChart from "./DoughnutChart";

const connectorLines = {
  // id: 'connectorLines',
  //
  // beforeDraw(chart, args, options)
  // {
  //     const {ctx, config, scales: {x, y}} = chart;
  //
  //     ctx.save();
  //
  //     ctx.strokeStyle = 'black';
  //
  //     console.log('heeeeere')
  //     for (let i = 0; i <= config._config.data.datasets[0].data.length - 2; i++) {
  //         ctx.strokeRect(x.getPixelForValue(i), y.getPixelForValue(config._config.data.datasets[0].data[i][1]), x.getPixelForValue(i + 1), 0)
  //     }
  //
  //     ctx.restore();
  // }
};

ChartJS.register(
  CategoryScale,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
  connectorLines,
);

class WaterfallBarChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        labels: [],
        datasets: [],
      },
      options: this.buildOptions(this.props.chartOptions),
    };

    this.ref = null;
  }

  componentDidMount() {
    this.buildData();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps != this.props && !this.imgSrc) {
      this.ref.options = this.buildOptions(nextProps.chartOptions);
      this.buildData(nextProps);
      this.ref.update();

      return true;
    }
    return true;
  }

  barColorCode = () => {
    return (ctx) => {
      const start = ctx.parsed._custom.start;
      const end = ctx.parsed._custom.end;

      if (this.props.colors[ctx.dataIndex]) {
        return this.props.colors[ctx.dataIndex];
      } else {
        return end === 0 ? "grey" : start > end ? "red" : "green";
      }
    };
  };

  buildOptions = (props) => {
    if (this.props.preview && this.props.businessPlan) {
      if (window.chartsForRender.indexOf(this.buildOptions) === -1) {
        window.chartsForRender.push(this.buildOptions);
      }
    }
    let options = {
      type: "bar",
      maintainAspectRatio: props.lockedAspect ? props.lockedAspect : false,
      devicePixelRatio: 2.0,
      barRoundness: 0.3,
      layout: {
        padding: {
          top: props.showTitle || props.showSubtitle ? 5 : 45,
        },
      },
      animation: {
        duration: 1,
        onComplete: (animation) => {
          if (this.props.preview && this.props.businessPlan && this.ref) {
            this.imgSrc = this.ref.toBase64Image();
            this.forceUpdate(() => {
              window.amountOfChartsRendered++;
              global.Modeliks.NotifySubsctiptions("onChartRenderFinish");
            });
          }
        },
      },
      // title:{
      //     display:true,
      //     text:'Average Rainfall per month',
      //     fontSize:20
      // },
      elements: {
        bar: {
          backgroundColor: this.barColorCode(),
        },
      },
      scales: {
        x: {
          grid: {
            display: props.xGrid ? props.xGrid : false,
            drawBorder: props.xGrid ? props.xGrid : false,
          },
          ticks: {
            display: props.showHorizontalAxis,
            color: "#889299",
            font: {
              weight: 400,
              style: "normal",
              size: props.axisFontSize,
              lineHeight: "160%",
            },
          },
        },
        y: {
          grid: {
            display: props.yGrid ? props.yGrid : false,
            drawBorder: props.xGrid ? props.xGrid : false,
          },
          ticks: {
            display: props.showVerticalAxis,
            maxTicksLimit: 4,
            color: "#889299",
            font: {
              weight: 400,
              style: "normal",
              size: 12,
              lineHeight: "160%",
            },
            callback: function (value) {
              if (value >= 1000 && value < 1000000) {
                return value / 1000 + "k";
              } else if (value >= 1000000) {
                return value / 1000000 + "mil";
              } else {
                return value;
              }
            },
          },
        },
      },
      plugins: {
        connectorLines,
        tooltip: {
          titleFont: {
            size: 14,
          },
          bodyFont: {
            size: 14,
          },
        },
        title: {
          display: props.showTitle ? props.showTitle : false,
          text:
            props.title && props.titleAlign && props.titleAlign === "start"
              ? props.title
              : props.title
                ? props.title
                : "",
          align: props.titleAlign ? props.titleAlign : "center",
          color: "#000000",

          font: {
            size: props.titleFontSize,
          },
          padding: {
            bottom: props.showSubtitle && props.showSubtitle === true ? 0 : 45,
          },
        },
        subtitle: {
          display: props.showSubtitle ? props.showSubtitle : false,
          text:
            props.subtitle && props.titleAlign && props.titleAlign === "start"
              ? props.subtitle
              : props.subtitle
                ? props.subtitle
                : "",
          align: props.titleAlign ? props.titleAlign : "center",
          padding: {
            bottom: 45,
          },
          font: {
            size: props.subTitleFontSize,
          },
        },
        datalabels: {
          anchor: "end",
          align: "end",
          clamp: false,
          formatter: function (value, ctx) {
            if (props.showDataLabels) {
              let val = value[1] !== 0 ? value[1] - value[0] : value[0];

              return (
                (props.dataType === "currency" ? DataTypes[props.dataType] : "") +
                val.toLocaleString(undefined, {
                  minimumFractionDigits: props.datalabelsDecimalPoints,
                  maximumFractionDigits: props.datalabelsDecimalPoints,
                }) +
                (props.dataType === "percent" ? DataTypes[props.dataType] : "")
              );
            } else {
              return "";
            }
          },
          color: "#252525",
          font: {
            size: props.dataLabelsFontSize,
            weight: "700",
            lineHeight: "17px",
          },
        },
        legend: {
          display: props.displayLegend ? props.displayLegend : false,
          position: props.legendPosition ? props.legendPosition : "bottom",
          align: "center",
          labels: {
            usePointStyle: true,
            fontSize: props.legendFontSize,
          },
        },
      },
    };

    return options;
  };

  buildData = (nextProps = null) => {
    let labels = [];
    let datasets = [];
    let rows = this.props.data;
    let colors = this.props.colors;

    rows[0].forEach((label, index) => {
      if (index !== 0) {
        labels.push(label);
      }
    });

    rows.forEach((row, index) => {
      if (index !== 0) {
        let obj = {
          label: "",
          // backgroundColor: colors[0],
          categoryPercentage: 1,
          barPercentage:
            nextProps && nextProps.chartOptions.gapWidth
              ? nextProps.chartOptions.gapWidth / 10
              : this.props.chartOptions.gapWidth
                ? this.props.chartOptions.gapWidth / 10
                : 0.3,
          data: [],
        };
        row.forEach((data, indexData) => {
          if (indexData === 0) {
            obj.label = data;
          } else {
            if (indexData === 1) {
              let parsedData = parseFloat(data);
              obj.data.push([0, parsedData]);
            } else {
              let currentData = 0;
              let beforeData = 0;

              row.forEach((data, indexData2) => {
                if (indexData2 > 0 && indexData2 <= indexData) {
                  if (data) {
                    currentData += parseFloat(data);
                  } else {
                    currentData += 0;
                  }
                }

                if (indexData2 > 0 && indexData2 <= indexData - 1) {
                  if (data) {
                    beforeData += parseFloat(data);
                  } else {
                    beforeData += 0;
                  }
                }
              });

              if (rows[index][indexData]) {
                let parsedData = parseFloat(currentData);
                let parsedBeforeData = parseFloat(beforeData);
                obj.data.push([
                  parsedBeforeData ? parsedBeforeData : "",
                  parsedData ? parsedData : "",
                ]);
              } else {
                let parsedData = parseFloat(currentData);
                let parsedBeforeData = parseFloat(beforeData);
                obj.data.push([parsedBeforeData ? parsedBeforeData : "", 0]);
              }
            }
          }
        });
        datasets.push(obj);
      }
    });

    this.state.data.labels = labels;
    this.state.data.datasets = datasets;
  };

  setRef = (ref) => {
    this.ref = ref;
  };

  render() {
    if (this.imgSrc) {
      return <img src={this.imgSrc} style={{ height: "100%", width: "100%" }} />;
    }
    return (
      <Bar
        type={"bar"}
        data={this.state.data}
        options={this.state.options}
        ref={this.setRef}
        style={{
          background: this.props.chartOptions.backgroundColor,
          border: `2px solid ${this.props.chartOptions.borderColor}`,
        }}
      />
    );
  }
}

WaterfallBarChart.config = {
  data: [
    ["", "label1", "label2", "label3", "label4"],
    ["ser1", "1", "2", "3", ""],
  ],
  colors: [],
  dataGridConfig: {
    title: "blabla",
    subtitle: "blabla",
    canAddRows: false,
    canAddColumns: true,
    hasColumnHeaders: true,
    hasRowHeaders: true,
    lastRowNotEditable: true,
    rowSeries: true,
  },
  dataLalbelsConfig: {
    hideSeries: false,
    dataLabelsOptions: false,
  },
  legendAndGridlinesConfig: {
    hasGridlines: true,
  },
  gapWidthConfig: {
    haveGapWidth: true,
  },
  defaultConfig: {
    gapWidth: 5,
    showDataLabels: true,
    showVerticalAxis: false,
  },
};

WaterfallBarChart.propTypes = {
  data: PropTypes.array.isRequired,
  colors: PropTypes.array.isRequired,
};

export default WaterfallBarChart;
