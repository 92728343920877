import React, { Component, useContext } from "react";
import ChartsComponent from "../../ChartsComponent";
import HeaderMidPortal from "../../components/Portals/HeaderMidPortal";
import SizeAndRotation from "../../components/RightMenuComponents/SizeAndRotationComponent";
import Position from "../../components/RightMenuComponents/PositionComponent";
import ButtonMui from "../../../../components/buttons/buttonMui/buttonMui";
import "./FinancialChart.scss";
import { MyContext } from "../../../../pages/Secure/Dashboards/ManagementReports";
import CircleLoader from "../../../../components/Loaders/CircleLoader/CircleLoader";

class FinancialChart extends Component {
  chart = null;
  rightMenuFunc = {};
  currentOptions = { chartOptions: {}, chartData: {}, snapShotData: {} };

  constructor(props) {
    super(props);
    let ActualDates = null;
    if (global.Modeliks.CompanyInfo) {
      ActualDates = require("../../../../pages/Secure/Financials/Actuals/constants").ActualDates;
      this.state = {
        loading: true,
        chartEditor: false,
        chartEditorKey: "",
      };
      this.SummaryDateSettings = global.Modeliks.SummaryDateSettings;
      this.SummaryDateSettingsValue = {};
      this.setSummaryDateSettingsValue();

      this.curMonths = {
        startMonth: `${global.Modeliks.DateHelper.months[0].Year}-${global.Modeliks.DateHelper.months_before_actual[0].Month + 1}`,
        endMonth: `${global.Modeliks.DateHelper.months[global.Modeliks.DateHelper.months.length - 1].Year}-${global.Modeliks.DateHelper.months[global.Modeliks.DateHelper.months.length - 1].Month + 1}`,
        startYear: global.Modeliks.DateHelper.gridPeriodHeaders_Years()[0].Header.toString(),
        endYear:
          global.Modeliks.DateHelper.gridPeriodHeaders_Years()[
            global.Modeliks.DateHelper.gridPeriodHeaders_Years().length - 1
          ].Header.toString(),

        startMonthOrder: global.Modeliks.DateHelper.months[0].Order,
        endMonthOrder:
          global.Modeliks.DateHelper.months[global.Modeliks.DateHelper.months.length - 1].Order,
        startYearOrder: global.Modeliks.DateHelper.gridPeriodHeaders_Years()[0].Order,
        endYearOrder:
          global.Modeliks.DateHelper.gridPeriodHeaders_Years()[
            global.Modeliks.DateHelper.gridPeriodHeaders_Years().length - 1
          ].Order,
        showAllDrivers: false,

        months: ActualDates.allMonths,
        years: ActualDates.allYears,
      };

      if (!this.SummaryDateSettingsValue.hasOwnProperty("from")) {
        Object.assign(this.curMonths, this.SummaryDateSettingsValue);
      }
    } else {
      this.state = {};
    }

    if (
      [
        ...global.Modeliks.DashboardCharts,
        ...global.Modeliks.PitchFinancialChartsYearly,
        ...global.Modeliks.PitchFinancialChartsMonthly,
      ].length &&
      props.ID
    ) {
      let tempChart = [
        ...global.Modeliks.DashboardCharts,
        ...global.Modeliks.PitchFinancialChartsYearly,
        ...global.Modeliks.PitchFinancialChartsMonthly,
      ].find((c) => {
        return c.ID == props.ID;
      });

      if (tempChart) {
        this.chart = JSON.parse(JSON.stringify(tempChart));
      }
    }

    if (this.chart) {
      if (props.chartOptions) {
        Object.assign(this.chart.Data.chartOptions, props.chartOptions);
      }
      if (props.chartData) {
        Object.assign(this.chart.Data.chartData, props.chartData);
      }
      // if(props.snapShotData){
      //     if(!this.chart.Data.snapShotData){
      //         this.chart.Data.snapShotData = {};
      //     }
      //     Object.assign(this.chart.Data.snapShotData, props.snapShotData);
      // }
    }

    if (!this.chart) {
      this.props.onDelete();
    }

    global.pasteListener = this.handlePaste;
  }

  handlePaste = (event) => {
    event.preventDefault();
    this.props.slideComponentPaste(event, true);
  };
  setSummaryDateSettingsValue = () => {
    if (this.SummaryDateSettings && this.SummaryDateSettings.Value) {
      this.SummaryDateSettingsValue = JSON.parse(this.SummaryDateSettings.Value);
    }
  };
  shouldComponentUpdate(nextProps) {
    if (nextProps.multipleSeleced != this.props.multipleSeleced) {
      if (nextProps.multipleSeleced) {
        global.pasteListener = this.handlePaste;
      }
    }

    if (nextProps.selected != this.props.selected) {
      if (nextProps.selected) {
        global.pasteListener = this.handlePaste;
      }
    }

    if (this.props !== nextProps) {
      return true;
    }
    return false;
  }

  getDateRange = () => {
    if (global.Modeliks.CompanyInfo) {
      return {
        dateFrom: {
          month: this.curMonths.months.find((c) => c.Order == this.curMonths.startMonthOrder),
          year: this.curMonths.years.find((c) => c.Order == this.curMonths.startYearOrder),
        },
        dateTo: {
          month: this.curMonths.months.find((c) => c.Order == this.curMonths.endMonthOrder),
          year: this.curMonths.years.find((c) => c.Order == this.curMonths.endYearOrder),
        },
      };
    }
    return null;
  };

  handleChartOptionsChange = (props) => {
    let newPropsObj = this.currentOptions.chartOptions;
    Object.assign(newPropsObj, this.props.chartOptions);
    let newProps = Object.assign(newPropsObj, props);
    this.props.onPropsChange({ chartOptions: newProps });
    if (this.props.chartOptions) {
      Object.assign(this.props.chartOptions, newProps);
    } else {
      Object.assign(this.currentOptions.chartOptions, newProps);
    }
  };

  handleChartTypeChange = (type) => {
    if (type.hasOwnProperty("chartClassType") || type.hasOwnProperty("numberFormat")) {
      this.props.onPropsChange(type);
    } else if (
      type.hasOwnProperty("showContribution") ||
      type.hasOwnProperty("chartDatasetsColors") ||
      type.hasOwnProperty("showDetailedNumbers")
    ) {
      this.handleChartOptionsChange(type);
    }
  };

  handleChartDataOptionsChange = (props) => {
    let newPropsObj = this.currentOptions.chartData;
    Object.assign(newPropsObj, this.props.chartData);
    let newProps = Object.assign(newPropsObj, props);
    this.props.onPropsChange({ chartData: newProps });
    if (this.props.chartData) {
      Object.assign(this.props.chartData, newProps);
    } else {
      Object.assign(this.currentOptions.chartData, newProps);
    }
  };

  handleSnapShotData = (props) => {
    let newPropsObj = this.currentOptions.snapShotData;
    Object.assign(newPropsObj, this.props.snapShotData);
    let newProps = Object.assign(newPropsObj, props);
    this.props.onPropsChange({ snapShotData: newProps });
    if (this.props.snapShotData) {
      Object.assign(this.props.snapShotData, newProps);
    } else {
      Object.assign(this.currentOptions.snapShotData, newProps);
    }
  };

  getResizeFormatOptions = () => {
    if (!this.props.businessPlan) {
      return (
        <>
          {" "}
          <SizeAndRotation
            changeLockedAspect={this.props.changeLockedAspect}
            rightMenuFunc={this.rightMenuFunc}
            lockedAspect={this.props.lockedAspect}
            onChange={this.props.onUpdateSizeAndRotation}
            style={this.props.style}
            width={this.props.width}
            height={this.props.height}
            rotateAngle={this.props.rotateAngle}
            scaleX={this.props.scaleX}
            scaleY={this.props.scaleY}
          />
          <Position
            onChange={this.props.onUpdatePosition}
            rightMenuFunc={this.rightMenuFunc}
            style={this.props.style}
            top={this.props.top}
            left={this.props.left}
          />
        </>
      );
    }
    return null;
  };

  getCompProps = () => {
    if (global.Modeliks.CompanyInfo) {
      return {
        months: this.curMonths && this.curMonths.months ? this.curMonths.months : [],
        years: this.curMonths && this.curMonths.years ? this.curMonths.years : [],
        handleSnapShotData: null, //this.handleSnapShotData
      };
    } else {
      return {
        months: this.curMonths && this.curMonths.months ? this.curMonths.months : [],
        years: this.curMonths && this.curMonths.years ? this.curMonths.years : [],
        handleSnapShotData: null, //global.Modeliks.CompanyInfo?this.handleSnapShotData:null
      };
    }
  };

  disableLoader = () => {
    this.state.loading = false;
    this.forceUpdate();
  };

  setContext = (context = {}) => {
    this.Context = context;
    Object.assign(this.curMonths, this.Context.curMonths);
    if (this.state.loading) {
      if (!window.iscurrentlyPrinting) {
        setTimeout(this.disableLoader, 400);
      } else {
        this.disableLoader();
      }
    }
  };

  render() {
    if (this.chart) {
      if (this.props.chartClassType) {
        this.chart.Data.chartClassType = this.props.chartClassType;
      }

      if (this.props.numberFormat) {
        this.chart.Data.chartOptions.numberFormat = this.props.numberFormat;
      }
      if (this.curMonths?.numberFormatType) {
        this.chart.Data.chartOptions.numberFormat = this.curMonths.numberFormatType;
      }
      return (
        <>
          <ContextConsumer setContext={this.setContext} />
          {!this.state.loading && (
            <>
              {this.props.selected && !this.props.multipleSelected && (
                <HeaderMidPortal>
                  <div className="sub_header_vertical_separator" />
                  <ButtonMui
                    tooltipText={"Format options"}
                    onClick={this.props.toggleFormatOptions}
                    variant={"text"}
                    roundnessType={"squared"}
                    color={"black"}
                    label={"Format options"}
                  />
                </HeaderMidPortal>
              )}
              <ChartsComponent
                businessPlan={this.props.businessPlan}
                preview={this.props.preview}
                isScaled
                rightMenuFunc={this.rightMenuFunc}
                comparisonPeriod={this.curMonths.comparisonPeriod}
                formatOptions={this.props.selected && this.props.formatOptions}
                selectedDashboardChart={this.chart}
                dateRange={this.getDateRange()}
                {...this.getCompProps()}
                grid
                scaled
                handleChartOptionsChange={this.handleChartOptionsChange}
                saveChartOptions={this.handleChartTypeChange}
                handleChartDataOptionsChange={this.handleChartDataOptionsChange}
              >
                {this.getResizeFormatOptions()}
              </ChartsComponent>
            </>
          )}
          {this.state.loading && (
            <div className="f_c_loader_container">
              <CircleLoader />
            </div>
          )}
        </>
      );
    }
    return null;
  }
}

const ContextConsumer = (props) => {
  let { properties } = useContext(MyContext);
  props.setContext(properties);
};

export default FinancialChart;
