import React from "react";
import FinancialBarStackedChart from "../../../../../../../components/chart/FinancialBarStackedChart";
import "./FinancialMainBreakDownCharts.scss";
import { bluePallete, greenPallete } from "../../../../Revenue/components/ColorPalette";
import CheckBoxMaterial from "../../../../../../../components/actions/CheckBoxMaterial";
import { DateHelper, PeriodTypes } from "../../../../../../../data/dates";
import {
  tableConfig,
  tableConfigTypes,
  tableObject,
} from "../../../../../../../components/tables/FinanceTable/components/constants";
import LocalStorage from "../../../../../../../helpers/LocalStorage";

class FinancialMainBreakDownCharts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chartKey: "chart_" + new Date().getTime(),
      maxStackedLabels: 7,
      monthDatasets: [],
      yearDatasets: [],
      isMounted: false,
    };

    this.colors = bluePallete;
    this.colorsYear = greenPallete;
    this.buildStackedData();
  }

  componentDidMount() {
    this.state.isMounted = true;
  }

  componentWillUnmount() {
    this.state.isMounted = false;
  }

  buildStackedData = () => {
    let counterBlue = 0;
    let counterGreen = 0;
    let monthDatasets = [];
    let yearsDatasets = [];
    let tmpMonthArr = [];
    let tmpYearArr = [];
    let revenueTotalsF = this.props.revenues;
    this.props.totalsDriver.setFormula_Sum(revenueTotalsF);

    this.growthMonths = this.props.totalsDriver[this.props.DriverName].Values.filter(
      (c) => c.PeriodType == PeriodTypes.month,
    );
    this.growthYears = this.props.totalsDriver[this.props.DriverName].Values.filter(
      (c) => c.PeriodType == PeriodTypes.year,
    );

    this.props.revenues.forEach((revenue) => {
      let firstYearCalculated = false;
      revenue.totalValue = 0;
      this.props.headers.map((header) => {
        let revenue_value = revenue.getItemByDateSufix(header.sufix);
        if (!revenue_value) {
          revenue_value = 0;
        }
        let value = revenue_value;

        if (value.PeriodType === "year") {
          if (!firstYearCalculated) {
            revenue.totalValue += parseInt(value.Value);
            firstYearCalculated = true;
          }
        }
      });
    });
    this.props.revenues.sort((a, b) => b.totalValue - a.totalValue);

    this.props.revenues.forEach((revenue) => {
      revenue.totalValue = 0;
      this.props.headers.map((header) => {
        let revenue_value = revenue.getItemByDateSufix(header.sufix);
        if (!revenue_value) {
          revenue_value = 0;
        }
        let value = revenue_value;

        if (value.hasOwnProperty("growthDriver") && value.growthDriver.Value < 0) {
          this.align = this.alignStart;
        }

        // let value=revenue.getItemByDateSufix(header.sufix)
        if (value.PeriodType === "month") {
          tmpMonthArr.push({
            ID: value.ID,
            Value: parseInt(value.Value),
            Header: value.DateHeader,
            RevenueName: revenue.RevenueName ? revenue.RevenueName : revenue.Name,
            growthDriver: value.growthDriver,
          });
        } else {
          tmpYearArr.push({
            ID: value.ID,
            Value: parseInt(value.Value),
            Header: value.DateHeader,
            RevenueName: revenue.RevenueName ? revenue.RevenueName : revenue.Name,
            growthDriver: value.growthDriver,
          });
        }
      });

      monthDatasets.unshift({
        backgroundColor: this.colors[counterBlue],
        barPercentage: 0.49,
        // barThickness: 37,
        maxBarThickness: 37,
        minBarLength: 2,
        borderRadius: 5,
        hidden: false,
        data: tmpMonthArr,
        revenue: revenue,
      });

      yearsDatasets.push({
        backgroundColor: this.colorsYear[counterGreen],
        barPercentage: 0.49,
        // barThickness: 37,
        maxBarThickness: 37,
        minBarLength: 2,
        borderRadius: 5,
        hidden: false,
        revenue: revenue,
        data: tmpYearArr,
      });
      if (counterGreen !== this.colorsYear.length - 1) {
        counterGreen++;
      }

      if (counterBlue !== this.colorsYear.length - 1) {
        counterBlue++;
      }
      tmpMonthArr = [];
      tmpYearArr = [];
    });

    this.state.monthDatasets = monthDatasets.sort(DateHelper.sortByDate);
    this.state.yearDatasets = yearsDatasets;
    if (this.state.isMounted) {
      this.forceUpdate();
    }
  };

  hideDataset = (index) => {
    this.state.monthDatasets[index].hidden = !this.state.monthDatasets[index].hidden;
    this.state.yearDatasets[index].hidden = !this.state.yearDatasets[index].hidden;

    if (index === this.state.maxStackedLabels) {
      if (this.state.monthDatasets[index + 1]) {
        for (let i = index + 1; i < this.state.monthDatasets.length; i++) {
          this.state.monthDatasets[i].hidden = !this.state.monthDatasets[i].hidden;
          this.state.yearDatasets[i].hidden = !this.state.yearDatasets[i].hidden;
        }
      }
    }
    const revenueTotals = this.state.monthDatasets
      .filter((c) => c.hidden == false)
      .map((c) => c.revenue);

    this.props.totalsDriver.setFormula_Sum(revenueTotals);
    this.forceUpdate();
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.showGrowth != this.props.showGrowth ||
      nextProps.showMargin != this.props.showMargin ||
      nextProps.showNumberOfEmployees != this.props.showNumberOfEmployees ||
      nextProps.unitValue != this.props.unitValue
    ) {
      this.setState({ chartKey: "chart_" + new Date().getTime() });
    } else if (nextProps.revenues != this.props.revenueTotals) {
      this.buildStackedData();
      this.setState({ chartKey: "chart_" + new Date().getTime() });
    }
    return true;
  }

  render() {
    return (
      <div className="financial_main_brakedown_wrapper">
        <div className="fmbc_charts_container">
          <div className={"financial_main_breakdown_monthly_chart_container"}>
            <FinancialBarStackedChart
              key={this.state.chartKey}
              height={64}
              data={this.state.monthDatasets}
              isPercentage={this.props.isPercentage}
              labels={this.props.periodHeaders.monthHeaders}
              hideCurrency={
                this.props.useShowHideCurrency
                  ? LocalStorage.get(tableObject, tableConfig, tableConfigTypes.showCurrency)
                  : false
              }
              valueLabels={true}
              growth={this.props.showGrowth ? this.growthMonths : ""}
              unitValue={this.props.unitValue}
            />
          </div>
          <div className={"financial_main_breakdown_yearly_chart_container"}>
            <FinancialBarStackedChart
              key={this.state.chartKey}
              height={202}
              isPercentage={this.props.isPercentage}
              data={this.state.yearDatasets}
              hideCurrency={
                this.props.useShowHideCurrency
                  ? LocalStorage.get(tableObject, tableConfig, tableConfigTypes.showCurrency)
                  : false
              }
              labels={this.props.periodHeaders.yearHeaders}
              valueLabels={true}
              growth={this.props.showGrowth ? this.growthYears : ""}
              unitValue={this.props.unitValue}
            />
          </div>
        </div>
        <div className={"financial_main_breakdown_cb_container"}>
          {this.props.revenues
            .filter((row, i) => i <= this.state.maxStackedLabels)
            .map((revenue, index) => (
              <div key={"grth_checkbox" + revenue.ID}>
                <CheckBoxMaterial
                  size={"medium"}
                  label={index === this.state.maxStackedLabels ? "other" : revenue.Name}
                  color={
                    index !== bluePallete.length - 1
                      ? bluePallete[index]
                      : bluePallete[bluePallete.length - 1]
                  }
                  onChange={() => this.hideDataset(index)}
                  checked={!this.state.monthDatasets[index].hidden}
                />
              </div>
            ))}
        </div>
      </div>
    );
  }
}

export default FinancialMainBreakDownCharts;
