const LocalStorage = {
  appendObjectToLocalStorage: (name, object) => {
    let existing = window.localStorage.getItem(name);
    if (!existing) {
      window.localStorage.setItem(name, JSON.stringify(object));
    }
  },
  appendObjectsToLocalStorage: (name, objectKey, key) => {
    let existing = window.localStorage.getItem(name);
    if (!existing) {
      window.localStorage.setItem(name, JSON.stringify(objectKey));
    } else {
      const parsed = JSON.parse(existing);
      window.localStorage.setItem(name, JSON.stringify(Object.assign(parsed, objectKey)));
    }
  },
  updateToLocalStorageObject: (name, key, value) => {
    // Get the existing data
    let existing = window.localStorage.getItem(name);
    const parsed = JSON.parse(JSON.parse(existing));
    parsed[key] = value;
    window.localStorage.setItem(name, JSON.stringify(JSON.stringify(parsed)));
  },
  getByKeyFromLocalStorageObject: (name, key) => {
    const retrievedString = window.localStorage.getItem(name);
    const existing = JSON.parse(JSON.parse(retrievedString));
    if (existing && existing.hasOwnProperty(key)) {
      return existing[key];
    }
    return null;
  },
  getByKeyFromLocalStorageObjects: (name, key) => {
    const retrievedString = window.localStorage.getItem(name);
    const existing = JSON.parse(retrievedString);
    if (existing && existing.hasOwnProperty(key)) {
      return existing[key];
    }
    return null;
  },

  addToLocalStorageObject: (name, objectKey, key, value) => {
    let existing = window.localStorage.getItem(name);
    const parsed = JSON.parse(existing);
    parsed[objectKey] = {
      [key]: value,
      ...parsed[objectKey],
    };

    window.localStorage.setItem(name, JSON.stringify(parsed));
  },
  updateValueFromObject: (name, objectKey, key, value) => {
    const retrievedString = window.localStorage.getItem(name);
    const existing = JSON.parse(retrievedString);
    existing[objectKey][key] = value;
    window.localStorage.setItem(name, JSON.stringify(existing));
  },
  getKeyFromObjectLocalStorage: (name, objectKey, key) => {
    const retrievedString = window.localStorage.getItem(name);
    const existing = JSON.parse(retrievedString);
    if (existing[objectKey].hasOwnProperty(key)) {
      return existing[objectKey][key];
    }
    return null;
  },
  getObjectFromLocalStorage: (name) => {
    return JSON.parse(JSON.parse(window.localStorage.getItem(name)));
  },
  get: (storageItem, storageObjectKey, ObjctKey) => {
    return LocalStorage.getKeyFromObjectLocalStorage(storageItem, storageObjectKey, ObjctKey);
  },
};

const LocalS = {
  get: (storageItem, storageObjectKey, ObjctKey) =>
    LocalStorage.getKeyFromObjectLocalStorage(storageItem, storageObjectKey, ObjctKey),
};

export default LocalStorage;
