import { Command } from "../../models/Command";

export class RemoveSection extends Command {
  _sectionId = null;
  _isGlobal = true;
  constructor({ actionForward, actionBackward, index, section, callBack }) {
    super({ actionForward, actionBackward, index, section, callBack });
  }

  async execute() {
    const { actionForward, index, section } = this.args;
    if (!section.ID) {
      throw new Error("No sectionId provided");
    }
    await actionForward(section, index);
  }

  async undo() {
    console.log("RemoveSection undo command");
    const { actionBackward, index, section, callBack } = this.args;
    const sectionId = await actionBackward(index, section, callBack);
    this._sectionId = sectionId;
  }

  get isGlobal() {
    return this._isGlobal;
  }

  getInfo() {
    return "This is RemoveSection command";
  }
}
