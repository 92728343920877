import React from "react";
import { CustomPicker, ChromePicker } from "react-color";
import { EditableInput, Hue, Saturation, Alpha } from "react-color/lib/components/common";

export const MyPicker = ({ hex, hsl, hsv, onChange }) => {
  const styles = {
    saturation: {
      borderRadius: "5px",
      width: 200,
      height: 100,
      position: "relative",
      overflow: "hidden",
    },
    hue: {
      height: 10,
      width: 200,
      position: "relative",
      marginBottom: 10,
    },
    input: {
      height: 34,

      border: `1px solid ${hex}`,
      paddingLeft: 10,
    },
    swatch: {
      width: 54,
      height: 38,
      background: hex,
    },
  };
  return (
    <div>
      <div style={styles.saturation}>
        <Saturation hsl={hsl} hsv={hsv} onChange={onChange} style={{ borderRadius: "50%" }} />
      </div>

      <div style={styles.hue}>
        <Hue hsl={hsl} onChange={onChange} />
      </div>

      <div style={{ display: "flex" }}>
        <EditableInput style={{ input: styles.input }} value={hex} onChange={onChange} />
      </div>
      <div style={styles.hue}>
        <Alpha value={0.91} onChange={onChange} />
      </div>
    </div>
  );
};

export default CustomPicker(MyPicker);
