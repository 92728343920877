import React, { Fragment, useState } from "react";
import "../HeaderTextEditor.scss";
import "../HeaderDefault.scss";
import {
  Lines,
  Line,
  Arrow,
  Elbow,
  Curved,
  Curve,
  PolyLine,
  Scribble,
} from "../../components/IconButtons/SubMenuIcons";
import { SlideObject, SlideTypes } from "../../SlideObjects/SlideStructures";
import { SubMenu, MenuItem, MenuButton } from "@szhsin/react-menu";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

function InsertLineComponent(props) {
  const handleNewLineObj = (lineType, e) => {
    props.setInsertMode(
      SlideTypes.lineObject,
      (top, left) => {
        const nObj = new SlideObject(SlideTypes.newLineObject, null, {
          lineType,
          width: 300,
          top: top,
          left: left,
        });
        props.onNewObject(nObj);
      },
      e,
    );
  };
  const handleArrowObj = (lineType, e) => {
    props.setInsertMode(
      SlideTypes.lineObject,
      (top, left) => {
        const nObj = new SlideObject(SlideTypes.newLineObject, null, {
          style: { markerEnd: "arrowhead" },
          width: 300,
          height: 100,
          top: top,
          left: left,
        });
        props.onNewObject(nObj);
      },
      e,
    );
  };
  const handleNewElbowObj = (e) => {
    props.setInsertMode(
      SlideTypes.lineObject,
      (top, left) => {
        const nObj = new SlideObject(SlideTypes.elbowConnectorObject, null, {
          deltaX: 297,
          deltaY: 164,
          width: 339,
          rotateAngle: 28,
          top: top,
          left: left,
        });
        props.onNewObject(nObj);
      },
      e,
    );
  };
  const LineInsert = () => {
    return (
      <SubMenu
        className="header_menu"
        //                 menuButton={({ open }) =><Tooltip disableHoverListener={props.disableEdit} arrow title={'Insert Line'}><span>
        //     <MenuButton disabled={props.disableEdit}><Lines active={open}/></MenuButton>
        // </span></Tooltip>}
        label={({ open }) => (
          <span>
            <Lines active={open} />
          </span>
        )}
      >
        <MenuItem onClick={(e) => handleNewLineObj(1, e)}>
          <Line />
          <span className="item_name">Line</span>
          {/*<span className='helper_text'>Shift+Alt</span>*/}
        </MenuItem>
        <MenuItem onClick={(e) => handleArrowObj(2, e)}>
          <Arrow />
          <span className="item_name">Arrow</span>
          {/*<span className='helper_text'>Shift+Alt</span>*/}
        </MenuItem>
        <MenuItem onClick={(e) => handleNewElbowObj(e)}>
          <Elbow />
          <span className="item_name">Elbow</span>
          {/*<span className='helper_text'>Shift+Alt</span>*/}
        </MenuItem>
        {/*<MenuItem  onClick={() => handleLineObj(1)}>*/}
        {/*<Curved/>*/}
        {/*<span className='item_name'>Curved</span>*/}
        {/*<span className='helper_text'>Shift+Alt</span>*/}
        {/*</MenuItem>*/}
        {/*<MenuItem  onClick={() => handleNewLineObj(5)}>*/}
        {/*<Curve/>*/}
        {/*<span className='item_name'>Curve</span>*/}
        {/*<span className='helper_text'>Shift+Alt</span>*/}
        {/*</MenuItem>*/}
        {/*<MenuItem  onClick={props.handleNewScribble}>*/}
        {/*<Scribble/>*/}
        {/*<span className='item_name'>Scribble</span>*/}
        {/*<span className='helper_text'>Shift+Alt</span>*/}
        {/*</MenuItem>*/}
      </SubMenu>
    );
  };

  return <>{LineInsert()}</>;
}

export default InsertLineComponent;
