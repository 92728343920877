import React, { Fragment, useState } from "react";
// import RightMenuPortal from "./RightMenuPortal.js";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import InputBox from "../InputBoxComponent/InputBox";
import PropTypes from "prop-types";
import { FlipHorizontally, FlipVertically, Rotate90 } from "../IconButtons/SubMenuIcons";
import MaterialSelect from "../Select/MaterialSelect";

function LegendAndGridlines(props) {
  const [openLegendAndGridlines, setOpenLegendAndGridlines] = React.useState(false);
  const [showGridLine, setShowGridLine] = React.useState(true);

  const handleShowGridLines = (e) => {
    setShowGridLine(!e.target.checked);
  };

  const handleShowLegend = (e) => {
    props.onPropsChange({ displayLegend: e.target.checked });
  };

  const handleLegendPosition = (e) => {
    props.onPropsChange({ legendPosition: e.target.value });
  };

  const handleYgrid = (e) => {
    props.onPropsChange({ yGrid: e.target.checked });
  };

  const handleXgrid = (e) => {
    props.onPropsChange({ xGrid: e.target.checked });
  };

  const handleClickLegendAndGridlines = () => {
    if (openLegendAndGridlines) {
      setOpenLegendAndGridlines(false);
    } else {
      setOpenLegendAndGridlines(true);
      props.rightMenuFunc.closeLegendAndGridlines = null;
      Object.values(props.rightMenuFunc).map((value) => {
        if (typeof value === "function") {
          value.call();
        }
      });
    }
  };

  const closeMenu = () => {
    setOpenLegendAndGridlines(false);
  };

  props.rightMenuFunc.closeLegendAndGridlines = closeMenu;

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      onKeyDown={(e) => {
        e.stopPropagation();
      }}
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
    >
      <ListItemButton id={"listItem"} onClick={handleClickLegendAndGridlines}>
        <ListItemText primary="Legend & Gridlines" />
        {openLegendAndGridlines ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <div style={{ width: "230px", border: "1px solid #F0F1F7", marginLeft: "18px" }} />
      <Collapse in={openLegendAndGridlines} timeout="auto" unmountOnExit>
        <div className={"collapse"}>
          <div className={"listSubHeading"}>Legend</div>
          <div className={"listRow"}>
            <input
              type="checkbox"
              style={{ margin: 0, marginRight: "4px" }}
              onChange={handleShowLegend}
              checked={props.displayLegend}
            />
            <div className={"listSubHeading"}>Show legend</div>
          </div>
          <div className={"listRow"}>
            <MaterialSelect
              value={props.legendPosition}
              options={[
                { value: "bottom", name: "Bottom" },
                { value: "left", name: "Left" },
                { value: "right", name: "Right" },
                { value: "top", name: "Top" },
              ]}
              onChange={handleLegendPosition}
            />
          </div>
          {props.hasGridlines ? (
            <>
              <div className={"listRow"}>
                <input
                  type="checkbox"
                  style={{ margin: 0, marginRight: "4px" }}
                  onChange={handleShowGridLines}
                />
                <div className={"listSubHeading"}>Show gridlines</div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <div className={"listRow"}>
                  <input
                    type="checkbox"
                    disabled={showGridLine}
                    style={{ margin: 0, marginRight: "4px" }}
                    onChange={handleXgrid}
                    checked={props.xGrid}
                  />
                  <div className={"listSubHeading"}>Vertical</div>
                </div>
                <div className={"listRow"}>
                  <input
                    type="checkbox"
                    disabled={showGridLine}
                    style={{ margin: 0, marginRight: "4px" }}
                    onChange={handleYgrid}
                    checked={props.yGrid}
                  />
                  <div className={"listSubHeading"}>Horizontal</div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </Collapse>
    </div>
  );
}

// LegendAndGridlines.PropTypes = {
// 	hasGridlines: PropTypes.bool
// }

export default LegendAndGridlines;
