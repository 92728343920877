import React from "react";
import "./ForgotPassword/ForgotPassword.scss";
import {
  LoginBottomRightCornerBigShape,
  LoginTopLeftCornerBigShape,
  MobileTitleShape,
} from "../../../../components/icons/svgIcons";
import { handleKeyDown } from "../Login";
import ButtonMui from "../../../../components/buttons/buttonMui/buttonMui";
import BackgroundBalls from "../components/BackgroundBalls";
import RegisterPage, { CopyrightMsg, ModeliksHeaderLogo } from "../Register/RegisterPage";
import request from "superagent";
import Mx_MainLoader from "../../../../components/Loaders/MainLoader/Mx_MainLoader";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoginInput from "../../../../components/inputs/loginInput/LoginInput";

class ForgottenPassword extends RegisterPage {
  send = () => {
    if (this.validateEmail()) {
      this.setState({ loading: true });
      sendReqForPasswordReset(
        { Email: this.state.email },
        () => {
          global.Modeliks.NavigateTo("/emailSent");
          global.Modeliks.ForgottenPasswordEmail = this.state.email;
        },
        (err) => {
          let emailError = err.response.body.error[0]
            ? err.response.body.error[0]
            : "Invalid Email";
          let errorTip = err.response.body.error[1] ? err.response.body.error[1] : undefined;
          this.setState({ emailError, errorTip, loading: false });
        },
      );
    }
  };

  render() {
    const { email, emailError, errorTip, loading } = this.state;
    return (
      <div className="forgot-pass-wrapper">
        {loading && <Mx_MainLoader className={"blurred"} />}
        <div className="forgot-pass-content-wrapper">
          <div className="content">
            <div className="logo">
              <ModeliksHeaderLogo />
            </div>

            <div className="forgot-pass-content">
              <div className="forgot-pass-title">
                <span>Forgot </span> <span> Password</span>
              </div>
              <div className="desc-text">
                Please enter your email address to receive a verification code.
              </div>
              <div className="input-field" style={{ marginTop: "20px" }}>
                <LoginInput
                  value={email}
                  type="email"
                  name="email"
                  label="Email"
                  onKeyDown={(e) => handleKeyDown(e, this.send)}
                  placeholder="Enter your email"
                  hideLabel
                  errorTip={errorTip}
                  onChange={this.handleInputChange}
                  errorMessage={emailError}
                />
              </div>
              <div className="action-btns-wrapper">
                <div className="resend-btn">
                  {/*<ButtonMui*/}
                  {/*  label="Try other way"*/}
                  {/*  width="100%"*/}
                  {/*  variant={"text"}*/}
                  {/*/>*/}
                </div>
                <div className="forgot-pass-btn">
                  <ButtonMui label="Send" width="100%" variant={"contained"} onClick={this.send} />
                </div>
                <div className="forgot-pass-btn auto-width">
                  <ButtonMui
                    label="Back"
                    variant={"text"}
                    onClick={() => global.Modeliks.NavigateTo("/login")}
                    startIcon={<ArrowBackIcon />}
                  />
                </div>
              </div>
            </div>
            <CopyrightMsg />
          </div>
        </div>
        <div className="top-left-shape">
          <LoginTopLeftCornerBigShape></LoginTopLeftCornerBigShape>
        </div>
        <div className="bottom-right-shape">
          <LoginBottomRightCornerBigShape></LoginBottomRightCornerBigShape>
        </div>
        <div className="mobile-title-shape">
          <MobileTitleShape></MobileTitleShape>
        </div>

        <BackgroundBalls
          bigRightTopBall
          smallRightTopBall
          bigLeftTopBall
          smallLeftTopBall
          smallCenterMidBall
          smallLeftCenterBall
          smallRightCenterBall
          bigRightCenterBall
          smallCenterTopBall
          bigLeftBottomBall
          smallRightBottomBall
        />
      </div>
    );
  }
}

export const sendReqForPasswordReset = (data, success, err) => {
  request.post("/api/forgotPassword").send(data).then(success).catch(err);
};

export default ForgottenPassword;
