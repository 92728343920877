import { Menu, MenuItem } from "@szhsin/react-menu";
import { default as MuiButton } from "@mui/material/Button";
import { ArrowDown } from "../../../../../components/icons/svgIcons";
import ButtonMui from "../../../../../components/buttons/buttonMui/buttonMui";
import "./forecast_reports_menu.scss";
import { IndustryTypes } from "../../../../../components/constants/industries";
import { RevenueTypes } from "../../../../../data/Finance/constants";

export const shouldShowReportsSaas = () => {
  if (
    global.Modeliks &&
    global.Modeliks.CompanyInfo &&
    global.Modeliks.CompanyInfo.Industry == IndustryTypes["SAAS/Subscription"] &&
    global.Modeliks.RevenuesStore.find((r) => r.RevenueType === RevenueTypes.Subscriptions)
  ) {
    return true;
  }
  return false;
};

const ReportNav = () => {
  return {
    tabs: [
      {
        link: `/forecast/reports/profitloss`,
        color: "black",
        size: "p3",
        label: "Profit & Loss",
        key: "reports",
        defaultReport: "ProfitAndLoss",
      },
      {
        link: `/forecast/reports/balancesheet`,
        color: "black",
        size: "p3",
        label: "Balance Sheet",
        key: "reports",
        defaultReport: "BalanceSheet",
      },
      {
        link: `/forecast/reports/cashflow`,
        color: "black",
        size: "p3",
        label: "Cash Flow",
        key: "reports",
        defaultReport: "CashFlow",
      },
      {
        link: `/forecast/reports/employee_schedule`,
        color: "black",
        size: "p3",
        label: "Employee Schedule",
        key: "reports",
        defaultReport: "EmployeeSchedule",
      },
      {
        link: `/forecast/reports/charts_monthly`,
        color: "black",
        size: "p3",
        label: "Charts",
        key: "reports",
        defaultReport: "Charts",
      },
      shouldShowReportsSaas()
        ? {
            link: `/forecast/reports/saas_dashboard_monthly`,
            color: "black",
            size: "p3",
            label: "Saas Dashboard",
            key: "reports",
            defaultReport: "SaasDashboard",
          }
        : null,
      // { link: `/forecast/reports/charts_monthly`,color: 'black',size: 'p3',label: 'Charts: Monthly',key:'reports',defaultReport: 'ChartsMonthly'}
    ],
  };
};

export default function ForecastReportsMenu(props) {
  return (
    <Menu
      arrow
      menuClassName="spsfrcf_reports_menu"
      menuButton={
        <MuiButton endIcon={<ArrowDown />} className="psdf_menu_section_button">
          {props.label}
        </MuiButton>
      }
    >
      {ReportNav()
        .tabs.filter((c) => !!c)
        .map((c, index) => (
          <MenuItem key={index} takeOverflow={true}>
            <ButtonMui label={c.label} linkTo={c.link} color="primary" variant={"text"} />
          </MenuItem>
        ))}
    </Menu>
  );
}
