import { DriverPeriodTypes } from "../CalculatedDriver/constants";
import Assets from "./index";
import { AssetCategories } from "./constants";
import { maxRows } from "../../../components/constants/finance";

const AssetsCategoryName = {
  Furniture: "Furniture",
  Intangibles: "Intangibles",
  Investments: "Investments",
  PropertyPlantAndEquipment: "Property Plant And Equipment",
  Vehicles: "Vehicles",
};

const getAssetsCategoryName = (name) => {
  switch (name) {
    case AssetCategories.Furniture:
      return AssetsCategoryName.Furniture;
    case AssetCategories.Intangibles:
      return AssetsCategoryName.Intangibles;
    case AssetCategories.Investments:
      return AssetsCategoryName.Investments;
    case AssetCategories.PropertyPlantAndEquipment:
      return AssetsCategoryName.PropertyPlantAndEquipment;
    case AssetCategories.Vehicles:
      return AssetsCategoryName.Vehicles;
    default:
      return name;
  }
};

const AssetsDataGridRows = (Storage) => {
  const assets = [Assets.getCurrentAssetsTotals(), Assets.getLongTermAssetsTotals()];
  const assetsTotals = Assets.getAssetsTotals();
  Storage.dataGridRows = {
    streamTotals: Assets.getAssetsTotals(),
    streamTotalsGrowth: Assets.getTotalsGrowth(assetsTotals),
    gridRows: [],
    totalsRows: [],
    assets,
    maxRows,
    totalRows: 0,
  };
  let totalRows = Storage.dataGridRows.totalRows;
  const setDriversFromAsset = (id) => {
    let asset = global.Modeliks.AssetsStore.getItem(id);
    if (asset) {
      return [asset.GrossBookValue, asset.AccumulatedDepreciation];
    } else {
      return [];
    }
  };

  const row_create = (asset) => {
    return revenue_only_create(asset);
  };

  const revenue_only_create = (asset, parentRevenue, level = 0, stopRecursive = false) => {
    let row = {};
    if (asset) {
      totalRows = totalRows + 1;
      if (level > 1) {
        row = {
          id: asset.ID,
          header: asset.Name,
          revenue: asset,
          hasEdit: level === 2,
          onEditClick: () => {},
          onDeleteClick: () => {},
          data: asset,
          children: [],
        };
        if (
          level === 2 &&
          asset.Name &&
          asset.Ref_Table === global.Modeliks.Tables.Finance_Assets.TableName
        ) {
          let findAsset = global.Modeliks.AssetsStore.getItem(asset.Ref_ID);
          if (findAsset) {
            if (totalRows <= maxRows) {
              row.children.push(
                revenue_only_create(findAsset.AccumulatedDepreciation, asset, level + 1),
              );
              row.children.push(revenue_only_create(findAsset.GrossBookValue, asset, level + 1));
            }
          }
        }
      } else if (level === 1) {
        row = {
          id: asset.ID,
          header: getAssetsCategoryName(asset.DriverName),
          revenue: asset,
          onEditClick: () => () => {},
          hasEdit: false,
          onDeleteClick: () => {},
          data: asset,
          children: [],
          isExpanded: true,
        };

        if (asset.DriverName === "Cash Balance") {
          row.borderErrors = true;
        }

        if (asset.Ref_Table === global.Modeliks.Tables.Finance_Assets.TableName) {
          let rowChildren = setDriversFromAsset(asset.Ref_ID);
          if (rowChildren.length > 0 && !stopRecursive) {
            rowChildren.map((driver) => {
              if (totalRows <= maxRows) {
                row.children.push(
                  revenue_only_create(
                    driver,
                    asset,
                    level + 1,
                    asset.PeriodType === DriverPeriodTypes.Previous,
                  ),
                );
              }
            });
          }
        }
      } else {
        // if(!asset.isValid){
        //     return null
        // }

        row = {
          id: asset.ID,
          header: asset.Name,
          revenue: asset,
          hasEdit: false,
          boldRowHeader: true,
          onDeleteClick: () => {},
          data: asset,
          isExpanded: true,
          children: [],
        };
      }

      let children = asset.getChildDrivers();
      if (children && !stopRecursive) {
        children.map((driver) => {
          if (totalRows <= maxRows) {
            row.children.push(
              revenue_only_create(
                driver,
                asset,
                level + 1,
                asset.PeriodType === DriverPeriodTypes.Previous,
              ),
            );
          }
        });
      }
      return row;
    } else {
      return null;
    }
  };

  const row_totals = (totals = assetsTotals) => {
    totalRows = totalRows + 1;
    return {
      id: totals.ID,
      unit: global.Modeliks.CompanyInfo.Currency.value,
      header: "Total Assets",
      cssClass: "totals",
      boldRowHeader: true,
      data: totals,
    };
  };

  const createGridRows = () => {
    Storage.dataGridRows.gridRows = assets
      .map((personnel) => row_create(personnel))
      .filter((c) => c != null);
    Storage.dataGridRows.totalsRows = [row_totals()];
    Storage.dataGridRows.totalRows = totalRows;
  };
  createGridRows();
};

export default AssetsDataGridRows;
