import React from "react";
import "./onboarding.scss";
import { ModeliksTextIcon, Logo } from "../../../../components/icons/svgIcons";

export const BoardingTitle = (props) => {
  return (
    <div className="spso_boarding_header_container">
      <div className="spso_boarding_header">
        <div className="spso_boarding_logo">
          <Logo width="80px" height="72px" />
        </div>
        <div className="spso_boarding_title">
          <span className="spso_first_title">Let’s get you started!</span>
          <span className="spso_second_title">
            Please answer {props.isCompanies ? "7" : "8"} quick questions to tailor your plan.
          </span>
        </div>
        <span className="spso_boarding_empty_div" />
      </div>
    </div>
  );
};
