import { Command } from "../../models/Command";

export class AddObject extends Command {
  _object = null;
  constructor({ actionForward, actionBackward, object }) {
    super({ actionForward, actionBackward, object });
  }

  async execute() {
    const { actionForward, object } = this.args;
    const newObject = await actionForward(object);
    this._object = newObject;
  }

  async undo() {
    console.log("AddObject undo command");
    if (!this._object) {
      throw new Error("No object provided");
    }
    const { actionBackward, object } = this.args;
    await actionBackward(object, false);
  }

  getInfo() {
    return "This is AddObject command";
  }
}
