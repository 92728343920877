import { Command } from "../../models/Command";

export class AddSection extends Command {
  _sectionId = null;
  _isGlobal = true;
  constructor({ actionForward, actionBackward, index, section, callBack }) {
    super({ actionForward, actionBackward, index, section, callBack });
  }

  async execute() {
    const { actionForward, index, section, callBack } = this.args;
    const sectionId = await actionForward(index, section, callBack);
    this._sectionId = sectionId;
  }

  async undo() {
    console.log("AddSection undo command");
    if (!this._sectionId) {
      throw new Error("No sectionId provided");
    }
    const { actionBackward, index, section } = this.args;
    await actionBackward({ ...section, ID: this._sectionId }, index);
  }

  get isGlobal() {
    return this._isGlobal;
  }

  getInfo() {
    return "This is AddSection command";
  }
}
