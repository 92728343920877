import * as React from "react";
import { TableHead, TableRow, TableCell } from "@mui/material";
import "../financetablemain.scss";

const validHeaders = ["Forecast", "Previous Period", "Previous Year"];

export const FinanceTableHeadComparative = (props) => {
  const {
    children,
    disabled,
    headers,
    tableWidth,
    MaxLengthOfValue,
    getRecalculatedTableCellByHeader,
  } = props;
  const [forecast, previousPeriod, previousYear] = headers.reduce(
    ([forecast, prevPeriod, prevYear], e) =>
      e.Order >= 14 && e.Order <= 16
        ? [[...forecast, e], prevPeriod, prevYear]
        : e.Order >= 17 && e.Order <= 19
          ? [forecast, [...prevPeriod, e], prevYear]
          : e.Order >= 20 && e.Order <= 22
            ? [forecast, prevPeriod, [...prevYear, e]]
            : [forecast, prevPeriod, prevYear],
    [[], [], []],
  );

  return (
    <TableHead className="mct_ft_tablehead">
      <TableRow className={props.CustomizableStyles(false, true)}>
        {children}
        <TableCell className={"mct_ft_tablehead_cell"} rowSpan={2}>
          <p
            className={`mct_ft_tablehead_paragraph  mct_cell_center ${props.CustomizableStyles()}`}
          >
            Actual
          </p>
        </TableCell>
        {forecast.length > 0 && (
          <TableCell className={"mct_ft_tablehead_cell"} colSpan={forecast.length}>
            <p
              className={`mct_ft_tablehead_paragraph mct_cell_center ${props.CustomizableStyles()}`}
            >
              {validHeaders[0]}
            </p>
          </TableCell>
        )}

        {previousPeriod.length > 0 && (
          <TableCell className={"mct_ft_tablehead_cell"} colSpan={previousPeriod.length}>
            <p
              className={`mct_ft_tablehead_paragraph mct_cell_center ${props.CustomizableStyles()}`}
            >
              {validHeaders[1]}
            </p>
          </TableCell>
        )}

        {previousYear.length > 0 && (
          <TableCell className={"mct_ft_tablehead_cell"} colSpan={previousYear.length}>
            <p
              className={`mct_ft_tablehead_paragraph mct_cell_center ${props.CustomizableStyles()}`}
            >
              {validHeaders[2]}
            </p>
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        {headers.map((header, index) => {
          if (MaxLengthOfValue < (header && header.Header.length)) {
            getRecalculatedTableCellByHeader(header.Header.length);
          }
          if (index !== 0) {
            return (
              <TableCell key={index} className={"mct_ft_tablehead_cell low_z_index"}>
                <p
                  className={`mct_ft_tablehead_paragraph mct_cell_center ${props.CustomizableStyles()}`}
                >
                  {validHeaders.includes(header.Header)
                    ? global.Modeliks.CompanyInfo.Currency.value
                    : header.Header}
                </p>
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
};
