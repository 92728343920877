import * as React from "react";
import Dialog from "@mui/material/Dialog";
import ButtonJ from "../Buttons/Button";
import "./InfoShareDialog.scss";

export default function InfoShareDialog(props) {
  const handleClose = () => {
    props.handleClose();
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={props.open}
        className={"info_dialog"}
        onClose={handleClose}
      >
        {
          <>
            <div className="Paragraph_P4">{props.text}</div>
            <ButtonJ
              size="large"
              width="118px"
              padding="0 0 0 0"
              backgroundColor="primary"
              label={"OK"}
              onClick={() => handleClose()}
            />
          </>
        }
      </Dialog>
    </React.Fragment>
  );
}
