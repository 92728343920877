import React from "react";
import "./components/CalculatedDriver.scss";
import "./components/CalculatedDriverObject.scss";
import DriverCard from "./components/DriverCard";
import AddDriver from "./components/AddDriver";
import CalculatedDriverFormula from "./components/CalculatedDriverFormula";
import {
  UnitTypes,
  DriverCategories,
  DriverPeriodTypes,
} from "../../../../../data/Finance/CalculatedDriver/constants";
import CalculatedDriver from "../../../../../data/Finance/CalculatedDriver/index";
import MxIDHelper from "../../../../../data/MxIDHelper";
import MxIDUseID from "../../../../../helpers/MxIUniqueD";

class CalculatedDriverDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drivers: props.drivers ? props.drivers : [],
      creatingDriver: false,
      createDriver: null,
      isNewDriver: false,
      isExistingDriver: false,
      driver: false,
    };

    this.validDrivers = null;
  }

  componentDidMount() {
    this.setValidDrivers();
  }

  setValidDrivers = (drivers = this.state.drivers) => {
    let curDriverID = this.props.revenueID ? this.props.revenueID : null;
    let totalStream = this.props.totalDriver ? this.props.totalDriver : null;
    this.validDrivers = global.Modeliks.DriversStore.getFormulaBuildDrivers(
      curDriverID,
      totalStream,
      drivers,
    );
    this.forceUpdate();
  };

  createDriverFromValues = (driver) => {
    const newDriver = CalculatedDriver.createDriver(
      "Finance_CalculatedDrivers",
      `${MxIDUseID()}`,
      "value",
      driver.UnitType,
      driver.DriverCategory,
      driver.DriverName,
    );
    newDriver.PeriodType = driver.PeriodType;
    return newDriver;
  };
  setNewDriver = () => {
    this.state.isNewDriver = true;
    this.state.driver = {
      DriverName: "",
      UnitType: UnitTypes.Price,
      DriverCategory: DriverCategories.Average,
      Ref_Field: "value",
      PeriodType: DriverPeriodTypes.Current,
      error: false,
    };
    this.forceUpdate();
  };
  setExistingDriver = () => {
    this.state.isExistingDriver = true;
    this.state.driver = {
      DriverName: "",
      UnitType: UnitTypes.Price,
      DriverCategory: DriverCategories.Average,
      error: false,
    };
    // this.state.driver = this.createDriver();
    this.forceUpdate();
  };
  addDriver = () => {
    this.state.isNewDriver = false;
    this.state.isExistingDriver = false;
    this.state.drivers.push(this.createDriverFromValues(this.state.driver));
    //recalculate
    this.setValidDrivers(this.state.drivers);
    this.state.driver = false;
    this.forceUpdate();
  };
  removeDriver = (driver) => {
    let arr = [];
    this.props.Formula.forEach((d, index) => {
      if (d.ID === driver.ID) {
        arr.push({ driver_index: index });
      }
    });
    arr.forEach((el) =>
      this.props.Formula.splice(el.driver_index !== 0 ? el.driver_index - 1 : el.driver_index, 2),
    );
    let drivers = this.state.drivers.filter((d) => d.ID !== driver.ID);

    if (driver.IsNew) {
      global.Modeliks.DriversStore.removeItemByID(driver.ID);
    }
    //recalculate
    this.setValidDrivers(drivers);
    this.setState({ drivers: drivers });
  };
  setDriverFromExisting = (driver) => {
    if (driver) {
      this.state.driver = driver;
      this.state.driver.error = false;
      this.state.isNewDriver = false;
      this.state.isExistingDriver = false;
      this.state.drivers.push(this.state.driver);
      //recalculate
      this.setValidDrivers(this.state.drivers);
      this.state.driver = false;
      this.forceUpdate();
    }
  };
  changedDriver = () => {
    this.forceUpdate();
  };

  setFormula = (formula) => {
    let isSomeDriverInEditingMode = false;
    this.state.drivers.forEach((d) => {
      if (d.editing) {
        d.error = true;
        d.errorMessage = "Please save changes to driver!";
        isSomeDriverInEditingMode = true;
      }
    });

    if (isSomeDriverInEditingMode) {
      this.forceUpdate();
    } else {
      this.props.createFormula(formula);
    }
  };

  cancelDriver = () => {
    this.state.driver.error = false;
    this.state.isNewDriver = false;
    this.state.isExistingDriver = false;
    this.state.driver = false;
    this.forceUpdate();
  };

  render() {
    if (!this.validDrivers) {
      return "loading...";
    }

    return (
      <>
        {this.state.drivers.length > 0 &&
          this.state.drivers.map((driver, index) => (
            <DriverCard
              key={`driver_card_existing_drivers-${index}`}
              isNewDriver={false}
              totalDriver={this.props.totalDriver}
              isExistingDriver={false}
              creatingDriver={false}
              index={index}
              driver={driver}
              validDrivers={this.validDrivers}
              drivers={this.state.drivers}
              changedDriver={this.changedDriver}
              revenueID={this.props.revenueID}
              removeDriver={this.removeDriver}
            />
          ))}

        {this.state.driver && (
          <DriverCard
            key="driver_card_new_drivers"
            index={this.state.driver.ID}
            totalDriver={this.props.totalDriver}
            creatingDriver={true}
            validDrivers={this.validDrivers}
            isNewDriver={this.state.isNewDriver}
            isExistingDriver={this.state.isExistingDriver}
            driver={this.state.driver}
            drivers={this.state.drivers}
            revenueID={this.props.revenueID}
            changedDriver={this.changedDriver}
            setDriverFromExisting={this.setDriverFromExisting}
            addDriver={this.addDriver}
            cancelDriver={this.cancelDriver}
          />
        )}

        {!this.state.isNewDriver && !this.state.isExistingDriver && (
          <AddDriver
            customIndicator={this.props.customIndicator}
            setExistingDriver={this.setExistingDriver}
            setNewDriver={this.setNewDriver}
          />
        )}

        {this.state.drivers.length > 0 && (
          <CalculatedDriverFormula
            errorMessage={this.props.errorMessage}
            open={this.state.drivers.length > 0}
            Operators={this.props.Operators}
            HiddenOperators={this.props.HiddenOperators}
            customIndicator={this.props.customIndicator}
            createFormula={this.setFormula}
            title={this.props.title}
            drivers={this.state.drivers}
            Formula={this.props.Formula}
            updateButton={this.props.updateButton}
            hasFormula={this.props.hasFormula}
            setFormula={this.props.setFormula}
          />
        )}
      </>
    );
  }
}

export default CalculatedDriverDetails;
