import ReactDOM from "react-dom";

const ContextMenuPortal = ({ children }) => {
  const el = document.getElementById("contextMenuContentPanel");
  if (el) {
    return ReactDOM.createPortal(children, el);
  } else {
    return null;
  }
  return ReactDOM.createPortal(children, el);
};

export default ContextMenuPortal;
