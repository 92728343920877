import React from "react";
import AddExpense from "./components/AddExpense";
import InfoExpense from "./components/InfoExpense";
import Expense from "../../../../data/Finance/Expense";
import CostSales from "../../../../data/Finance/CostSales";
import DialogLoader from "../../../../components/Loaders/DialogLoader/DialogLoader";

class ExpensesDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 0,
      expense: props.expense
        ? global.Modeliks.ExpensesStore.getItem(props.expense)
        : this.newExpense(),
      RevenueStreams: global.Modeliks.RevenuesStore.filter(
        (c) => c.hasOwnProperty("ID") && !c.isNew,
      ).map((c) =>
        Object.assign({
          ID: c.ID,
          description: c.RevenueName,
          value: c.ID.toString(),
          RevenueName: c.RevenueName,
        }),
      ),
    };
    this.isDeletedRecord = {
      delete: false,
    };
  }

  newExpense = () => {
    Expense.getEmptyExpense((expense) => {
      if (expense) {
        this.setState({ expense });
      } else {
        this.setState({ loading: false }, () => this.props.onClose());
      }
    });
  };

  handleAddDialogNext = () => {
    this.state.step = 1;
    this.forceUpdate();
  };
  handleGeneralClick = () => this.setState({ step: 0 });

  savingInProgress = false;

  changeExpense = () => {
    if (this.state.expense.db_record) {
      this.setState({ loading: true }, this.changeDbExpense);
    } else {
      this.setState({ loading: true }, this.changeNewExpense);
    }
  };

  changeNewExpense = () => {
    this.state.expense.IsCreated = true;
    this.state.expense.setExpense();
    this.setState({ loading: false, step: 1 });
  };

  changeDbExpense = () => {
    // todo check if there is drivers to delete

    const expense = this.state.expense;
    expense.IsCreated = true;
    expense.Name = this.state.expense.Name;
    expense.ExpenseMetric = this.state.expense.ExpenseMetric;
    expense.ExpenseType = this.state.expense.ExpenseType;
    expense.ID_Revenue = this.state.expense.ID_Revenue;
    this.setState({ expense, loading: false, step: 1 });
  };

  saveExpense = () => {
    if (this.savingInProgress) {
      return;
    }

    this.savingInProgress = true;
    this.props.showLoading(() => {
      const expense = this.state.expense;
      expense.changeDriversName();
      expense.Save((ID) => {
        this.props.onClose(true, true, true);
      });
    });
  };
  onCloseAddDialog = () => {
    if (!this.state.expense.db_record) {
      const drivers = this.state.expense.getAllDrivers();
      drivers.forEach((d) => {
        global.Modeliks.DriversStore.getDeleteDrivers(d);
        global.Modeliks.DriversStore.remove(d);
      });
    } else {
      const drivers = this.state.expense.getAllDrivers();
      drivers.forEach((d) => global.Modeliks.DriversStore.getDeleteDrivers(d));
      this.state.expense.clean(true);
    }

    this.props.onClose(true, true, this.isDeletedRecord.delete);
  };

  render() {
    if (!this.state.expense || this.state.loading) {
      return <DialogLoader />;
    }

    return (
      <>
        <AddExpense
          open={this.state.step === 0}
          onClose={this.onCloseAddDialog}
          expense={this.state.expense}
          RevenueStreams={this.state.RevenueStreams}
          isDeletedRecord={this.isDeletedRecord}
          changeExpense={this.changeExpense}
          onNextClick={this.handleAddDialogNext}
        />
        {this.state.step === 1 && (
          <InfoExpense
            open={this.state.step === 1}
            totalDriver={this.props.totalDriver}
            onGeneralClick={this.handleGeneralClick}
            onClose={this.onCloseAddDialog}
            expense={this.state.expense}
            tabIndex={this.props.step}
            saveRevenue={this.saveExpense}
          />
        )}
      </>
    );
  }
}

export default ExpensesDetails;
