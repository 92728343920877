import MxDriversStore from "../../MxDriversStore";
import CalculatedDriver_Values from "./CalculatedDriver_Values";

class DriverValuesStorage extends MxDriversStore {
  dataManager = null;
  data = [];
  loadDriverValues = (callBack) => {
    this.dataManager.get(
      this.dataManager.Tables.Finance_CalculatedDriver_Values.TableName,
      { ID_CompanyScenario: this.dataManager.CompanyScenarioInfo.ID },
      (data) => {
        data.forEach((item) => {
          this.data.push(item);
        });
        callBack();
      },
      null,
      false,
    );
  };

  setTemporaryDriverValues = (data) => {
    this.data.push(...data);
  };

  formulasCounter = {};
  formulasCounterI = 0;

  settingForumula = (driver) => {
    this.formulasCounterI++;
    if (!this.formulasCounter[driver.ID]) {
      this.formulasCounter[driver.ID] = 0;
    }

    this.formulasCounter[driver.ID]++;

    if (this.formulasCounter[driver.ID] > 1) {
      console.error("double the formula " + driver.ID + " " + this.formulasCounter[driver.ID]);
    }
  };

  clearFormulas = () => {
    this.formulasCounter = {};
    this.formulasCounterI = 0;
  };

  printForumulas = () => {
    console.log(this.formulasCounterI, this.formulasCounter);
  };

  static create = () => {
    const newArr = new DriverValuesStorage();

    newArr.pushOld = newArr.push;
    newArr.push = newArr.pushThis;

    return newArr;
  };
}

export default DriverValuesStorage;
