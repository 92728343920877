import Revenue from "../../Revenue/index";
import CalculatedDriver_Values from "../../CalculatedDriver/CalculatedDriver_Values";
import datastructure from "../../../datastructure.json";
import FinanceGeneric from "../../FinanceGeneric";
import Valuation from "../index";

class CostOfCapitalWACC extends FinanceGeneric {
  CostOfCapitalType;
  StateOfDevelopment;
  SelectedIndustry;

  constructor(db_record) {
    super(db_record);
    if (this.db_record) {
      this.CostOfCapitalType = this.db_record.CostOfCapitalType;
      this.StateOfDevelopment = this.db_record.StateOfDevelopment;
      this.SelectedIndustry = this.db_record.SelectedIndustry;
      this.setDriversFromDataStorageSub();
    }
    this.SaveRevenue = this.Save;
    this.Save = this.SaveWACC;
  }

  static getCostOfCapitalWACC = (callBack) => {
    if (!global.Modeliks.CostOfCapitalStore.length > 0) {
      CostOfCapitalWACC.createNewWacc((newrec) => {
        callBack && callBack();
      });
    } else {
      return global.Modeliks.CostOfCapitalStore[0];
    }
  };

  SaveNew = (callBack) => {
    global.Modeliks.post(this.constructor.TableName, this, (res) => {
      this.ID = res.id;
      this.createDriverValues(this.ID);
      this.DiscountRate.Value = 10;
      this.Save(() => callBack(this));
    });
  };

  static createNewWacc = (callBack) => {
    const newCost = new CostOfCapitalWACC();
    newCost.CostOfCapitalType = "discount-rate";
    newCost.StateOfDevelopment = false;
    newCost.SelectedIndustry = false;
    newCost.SaveNew(callBack);
  };

  static DriverValuesDesc = Object.assign({
    DiscountRate: {
      fieldName: "DiscountRate",
      driverID: "wacc_discount_rate",
    },
    WACCSelectedStage: {
      fieldName: "WACCSelectedStage",
      driverID: "wacc_selected_stage",
    },
    RiskFreeRate: {
      fieldName: "RiskFreeRate",
      driverID: "wacc_risk_free_rate",
    },
    CountryRiskPremium: {
      fieldName: "CountryRiskPremium",
      driverID: "wacc_country_risk_premium",
    },
    InflationRate: {
      fieldName: "InflationRate",
      driverID: "wacc_infation_rate",
    },
    MarketRiskPremium: {
      fieldName: "MarketRiskPremium",
      driverID: "wacc_bmarket_risk_premium",
    },
    LiquidityRiskPremium: {
      fieldName: "LiquidityRiskPremium",
      driverID: "wacc_bliquidity_risk_premium",
    },
    Beta: {
      fieldName: "Beta",
      driverID: "wacc_beta",
    },
    TaxRate: {
      fieldName: "TaxRate",
      driverID: "wacc_tax_rate",
    },
    DebtSpread: {
      fieldName: "DebtSpread",
      driverID: "wacc_debt_spread",
    },
    DebtEquityRatio: {
      fieldName: "DebtEquityRatio",
      driverID: "wacc_equity_ratio",
    },
    TotalCostOfEquity: {
      fieldName: "TotalCostOfEquity",
      driverID: "wacc_total_cost_of_equity",
    },
    TotalCostOfDebt: {
      fieldName: "TotalCostOfDebt",
      driverID: "wacc_total_cost_of_debt",
    },
    WACC: {
      fieldName: "WACC",
      driverID: "wacc_wacc",
    },
  });

  static TableName = datastructure.Valuation_CostOfCapital.TableName;

  createDriverValues = (ID) => {
    Object.values(this.constructor.DriverValuesDesc).forEach((driverVal) => {
      if (this.hasOwnProperty(driverVal.fieldName) == false) {
        this[driverVal.fieldName] = new CalculatedDriver_Values(
          null,
          `${CostOfCapitalWACC.TableName}-${ID}-${driverVal.driverID}`,
        );
      }
    });
  };

  SaveWACC = (callBack, saveDrivers = true, saveDriverValues = true) => {
    global.Modeliks.put(this.constructor.TableName, null, this, (res) => {
      global.Modeliks.post(
        global.Modeliks.Tables.Finance_CalculatedDriver_Values.TableName,
        Object.values(this.constructor.DriverValuesDesc).map(
          (driverVal) => this[driverVal.fieldName].cur_record,
        ),
        (res) => {
          callBack();
        },
      );
    });
  };

  getWACCValueID = (valID) => {
    return `${CostOfCapitalWACC.TableName}-${this.ID}-${valID}`;
  };

  setDriversFromDataStorageSub = () => {
    Object.values(this.constructor.DriverValuesDesc).forEach((driverVal) => {
      this[driverVal.fieldName] = global.Modeliks.DriverValuesStore.getItem(
        this.getWACCValueID(driverVal.driverID),
      );
    });
  };
}

export default CostOfCapitalWACC;
