import React from "react";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import { Search } from "../IconButtons/SubMenuIcons";

class TableLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      link: "",
    };
  }

  handleChange = (e) => {
    this.setState({ link: e.target.value });
  };

  setLink = () => {
    this.props.setLink(this.state.link);
  };

  render() {
    return (
      <div className="menu_item_search">
        <FormControl variant="outlined">
          <OutlinedInput
            id="outlined-adornment-link"
            value={this.state.link}
            onChange={this.handleChange}
            endAdornment={
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            }
            inputProps={{
              "aria-label": "link",
            }}
            placeholder={"Search or paste link"}
          />
        </FormControl>
        <Button variant="text" onClick={this.setLink}>
          Set
        </Button>
      </div>
    );
  }
}

export default TableLink;
