import React from "react";
import { MenuItem, SubMenu } from "@szhsin/react-menu";

export default function CenterOnPageSubMenu(props) {
  return (
    <SubMenu className="cm_sub_small" label="Center on page">
      <MenuItem onClick={props.horizontally}>Horizontally</MenuItem>
      <MenuItem onClick={props.vertically}>Vertically</MenuItem>
      <MenuItem
        onClick={() => {
          props.updatePosition(640 - props.width / 2, 360 - props.height / 2);
          // props.updatePosition(props.left, 540 - props.height / 2)
        }}
      >
        Center
      </MenuItem>
    </SubMenu>
  );
}
