import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import React, {Component} from "react";
import {DialogTitle, DialogContent, DialogActions} from "@mui/material";
import ButtonMui from "../../../buttons/buttonMui/buttonMui";
import {ErrorMessage, Input2} from "../../../actions/Input";
import {EmailRegex} from "../../../../pages/Public/Auth/components/AuthPage";
import request from "superagent";
import {Exit} from "../../../../SlideEditor/components/components/IconButtons/SubMenuIcons";
import {GetHelper, UpdateHelper} from "../../../../helpers/ApiHelper";
import {swearWords} from "../../../../helpers/Other";
import {ReferAFriendSVGPic} from "../../../icons/svgIcons";
import {setClipboard} from "../../../../pages/Secure/ProfileSettings/Profile/LoginCard";
import {Avatar2} from "../../../icons/svgIcons";
import MxDataArray from "../../../../data/MxDataArray";
import {functions} from "../../../constants/functionNameMappings";

const wordForReplacingCreditsInfo = '[credit per customer]'
const referalMsgTextValue = `Hey there, \nI think you should checkout Modeliks. \nIt's a business planning software for startups and SMEs! Create a fundable pitch deck, business and financial plan and investor reports. It's a guided business planning software where no experience is required! If you're a first-time Modeliks customer, you and I can get $${wordForReplacingCreditsInfo} off any paid plan using my personal invitation link below. Sign up for a free trial first (no cc required) if you're undecided.`;

class ReferralEmailDialog extends Component {
	Accounts = MxDataArray.create();

	constructor(props) {
		super(props);

		this.state = {
			emailError: null,
			email: "",
			disabled: false,
			label: "Send Invitation",
			referralMsgTextValue: referalMsgTextValue,
			isTocDialogOpen: false,
			errorTextField: null,
			isAccountsDialogOpen: false,
			creditPerCustomer: 0,
			CreditsInfo: 0,
			selectedAccountID: global.Modeliks.User.CreditedAccount,
		};

	}

	componentDidMount() {
		this.initialize();
	}

	getAll = () => Promise.all([GetHelper.getAccounts(), GetHelper.getCreditsInfo(), GetHelper.getReferralInfo()])

	initialize = async () => {
		const stateObj = {};

		const data = await this.getAll();
		this.Accounts = MxDataArray.create(data[0].Accounts);
		this.CreditsInfo = data[1].sum;
		stateObj.CreditsInfo = data[1].sum;
		let {referralMsgTextValue} = this.state;
		Object.assign(stateObj, {
			creditPerCustomer: data[2].credit_per_customer,
			link: data[2].link,
			referralMsgTextValue: referralMsgTextValue.replace(wordForReplacingCreditsInfo, data[2].credit_per_customer)
		});
		this.setState(stateObj)
	};

	handleReferalMsgChange = (event) => {
		this.setState({
			referralMsgTextValue: event.target.value,
			errorTextField: null,
		});
	};
	handleTocDialogOpen = () => {
		if (global[functions.toggle_referral_tos_dialog]) {
			global[functions.toggle_referral_tos_dialog](true)
		}
		// this.setState({isTocDialogOpen: true});
	};
	handleTocDialogClose = () => {

		if (global[functions.toggle_referral_tos_dialog]) {
			global[functions.toggle_referral_tos_dialog](false)
		}
		// this.setState({isTocDialogOpen: false});
	};
	handleAccountsDialogOpen = () => {
		this.setState({
			isAccountsDialogOpen: true,
			selectedAccountID: global.Modeliks.User.CreditedAccount,
		});
	};
	handleAccountsDialogClose = () => {
		this.setState({isAccountsDialogOpen: false});
	};
	acceptToc = () => {
		this.setState({isTocDialogOpen: false});
		//
		// accept TOC Logic here
	};
	confirmAccChange = () => {
		//  confirmAccChange Logic here
		this.setState({isAccountsDialogOpen: false});

		UpdateHelper.setCreditedAccount(
			{
				AccountID: this.state.selectedAccountID,
				UserID: global.Modeliks.User.ID,
				CreditedAccount: global.Modeliks.User?.CreditedAccount,
			},
			() => {
			},
		);
		global.Modeliks.User.CreditedAccount = this.state.selectedAccountID;
		this.forceUpdate();
	};

	bogusCheck = () => {
		const foundSwears = swearWords.filter((word) =>
			this.state.referralMsgTextValue.toLowerCase().includes(word.toLowerCase()),
		);
		return !foundSwears.length;
	};

	getEmailsSplitByComma = () => this.state.email.split(",").map((c) => c.trim());

	checkEmailsForValidity = (emails) => {
		let result = true;
		emails.forEach((c) => {
			if (!EmailRegex.test(c)) {
				result = false;
			}
		});

		return result;
	};

	sendEmail = () => {
		let pass = true;
		const emails = this.getEmailsSplitByComma();
		if (!this.checkEmailsForValidity(emails)) {
			pass = false;
			this.setState({emailError: "Please input a valid email address"});
		}
		if (!this.bogusCheck()) {
			pass = false;
			this.setState({errorTextField: "Please use appropriate language."});
		}
		if (pass) {
			this.setState({disabled: true});
			request
				.post("/api/send_referral_email")
				.set("authorization", "Bearer " + window.localStorage.getItem("token"))
				.send({
					emails,
					custom_message: this.state.referralMsgTextValue,
				})
				.then((c) => {
					global.Modeliks.showNotification("Email has been sent!");
					setTimeout(() => this.setState({disabled: false, email: ""}), 500);
				})
				.catch((e) => global.Modeliks.showNotification("Email has not been sent!"));
		}
	};

	render() {
		const {
			referralMsgTextValue,
			isTocDialogOpen,
			isAccountsDialogOpen,
			creditPerCustomer,
			CreditsInfo,
			selectedAccountID,
		} = this.state;

		return (
			<>
				<DialogTitle>
					<span>Earn Credits</span>
					<ButtonMui
						style={{padding: "4px"}}
						width={36}
						onClick={this.props.onCancel}
						color={"black"}
						variant={"text"}
						label={<Exit/>}
						isIconButton
					/>
				</DialogTitle>
				<DialogContent className={"send_mail"}>
					<div className={"white_container"}>
						<div className={"h1"}>Your Credit Balance</div>
						<div className={"flex a_t"}>
							<div className={"blue_container"}>
								<b>{CreditsInfo}</b>&nbsp;credits
							</div>
							<div className={"text"}>
								Your current <b>credit
								balance</b> is <b>${CreditsInfo}</b>. {global.Modeliks.NoAccount ? <> <br/>
								<b>Please note</b> that you do not currently have an account with a paid plan. Credits
								can only be applied to an account with a paid plan. Once you sign up for a paid plan,
								you will be able to apply your earned credits. In case you delete your user account,
								your credits will be forfeited and cannot be redeemed by any other means.
							</> : <>
								The credit balance will
								be applied to your next bill or purchase. The credits will be applied to your
								account:{" "}
								<span className="acc-name-link" onClick={this.handleAccountsDialogOpen}>
                  {this.Accounts &&
				  this.Accounts.getItem(global.Modeliks.User.CreditedAccount)?.Name
					  ? this.Accounts.getItem(global.Modeliks.User.CreditedAccount)?.Name
					  : "Unnamed Account"}
                </span>{" "}
							</>}
								{/* {this.Accounts.getItem(global.Modeliks.User.CreditedAccount)
                  ?.Name
                  ? "your"
                  : ""}{" "} */}

								<Dialog
									open={isAccountsDialogOpen}
									onClose={this.handleAccountsDialogClose}
									PaperProps={{
										sx: {
											width: "50%",
											height: "max-content",
										},
									}}
								>
									<DialogTitle>{"Select Account"}</DialogTitle>
									<DialogContent sx={{padding: "0"}}>
										{/* Add content for your dialog */}
										<div className="accounts">
											{this.Accounts.map((account, index) => (
												<div
													key={`r_a_${index}`}
													className="account"
													onClick={() => this.setState({selectedAccountID: account.ID})}
												>
													<Card
														key={index}
														sx={{
															minWidth: "100%",
															border: "none",
															boxShadow: "none",
															backgroundColor:
																selectedAccountID === account.ID ? "#01affd50" : "inherit",
														}}
													>
														<CardHeader
															sx={{
																padding: "10px",
																color: "#01affd",
																"& .MuiCardHeader-title": {
																	fontWeight: "700",
																},
															}}
															avatar={<Avatar2/>}
															title={account.Name ? account.Name : `Account ${index + 1} (unnamed)`}
														/>
													</Card>
												</div>
											))}
										</div>
									</DialogContent>
									<DialogContent sx={{padding: "0"}}>
										<div className="explanation_text">
											Please note that all your credits will be transfered instantly to the
											selected
											account after confirmation.
										</div>
									</DialogContent>
									<DialogActions sx={{justifyContent: "center"}}>
										<ButtonMui
											width={160}
											label={"Confirm"}
											variant={"contained"}
											onClick={this.confirmAccChange}
										/>
									</DialogActions>
								</Dialog>
							</div>
						</div>
					</div>

					<div className={"white_container wc_pf"}>
						<div className={"h1"}>Invite Friends and Earn Credit</div>
						<div className={"text p_b"}>
							You’ll receive ${creditPerCustomer} in Modeliks credit, when the persons you invite
							signup for a <b>paid plan</b>, and they’ll also get ${creditPerCustomer} in credits to
							get started. <br></br>
							<span className="toc" onClick={this.handleTocDialogOpen}>
                *Terms and conditions apply
              </span>

						</div>

						<div className={"flex"}>
							<ReferAFriendSVGPic/>
							<div className={"flex col"}>
								<div className={"flex"}>
									<Input2
										width={"470px"}
										errorMessage={this.state.emailError}
										value={this.state.email}
										onChange={(e) =>
											this.setState({
												email: e.target.value,
												emailError: false,
											})
										}
										error={!!this.state.emailError}
										type={"email"}
										placeholder={"Enter Email Address (separate multiple emails by commas)"}
									/>
									<ButtonMui
										width={160}
										label={this.state.label}
										disabled={this.state.disabled}
										variant={"contained"}
										onClick={this.sendEmail}
									/>
								</div>
								<div className="referal-text-field">
									<TextField
										multiline
										rows={4}
										value={referralMsgTextValue}
										onChange={this.handleReferalMsgChange}
										variant="outlined"
										error={!!this.state.errorTextField}
										aria-errormessage={this.state.errorTextField}
										InputProps={{
											sx: {
												fontSize: "14px",
												lineHeight: "17px",
												"& .MuiOutlinedInput-notchedOutline": {
													borderColor: "rgba(192, 192, 192, 0.55)", // Change this to your desired border color
												},
											},
										}}
									/>
									<ErrorMessage>{this.state.errorTextField}</ErrorMessage>
								</div>

								<div className={"flex col gap"}>
									<div className={"text"}>
										You can also share your personal referral link by copying and sending it or
										sharing it on social media.
									</div>
									<div className={"flex"}>
										<Input2 width={"470px"} value={this.state.link || "getting link"} disabled/>
										<ButtonMui
											width={160}
											onClick={() =>
												setClipboard(this.state.link, () => {
													global.Modeliks.showNotification &&
													global.Modeliks.showNotification(
														"The link was successfully copied to the clipboard",
													);
												})
											}
											label={"Copy Link"}
											variant={"contained"}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="text">
              <span className="toc" onClick={this.handleTocDialogOpen}>
                Terms and conditions
              </span>
						</div>
					</div>
				</DialogContent>
			</>
		);
	}
}

export default ReferralEmailDialog;
