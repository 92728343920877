import React from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ButtonMui from "../../../../../../components/buttons/buttonMui/buttonMui";
import "./CircleRefDrivers.scss";

const route = "/forecast/revenue";
const errorMessage =
  "Your formula creates a circular reference! Please change the formula in order to avoid a circular reference.";
const onEditButtonClick = (driver) => {
  global.Modeliks.showDialog.handleClose();
  return global.Modeliks.NavigateTo(`/formula/${driver.ID}`);
};

export default function CircleRefDrivers(props) {
  const allCircleRefDrivers = global.Modeliks.DriversStore.getCircleRefDrivers(props.drivers);
  if (allCircleRefDrivers) {
    return (
      <>
        <DialogTitle>{errorMessage}</DialogTitle>
        <DialogContent
          sx={{ display: "block", alignSelf: "center", fontSize: "18px", textAlign: "start" }}
        >
          {allCircleRefDrivers.map((driver) => {
            return (
              <div className="spsfccc_ref_div">
                <span>{driver.DriverName}</span>
                <ButtonMui
                  width={125}
                  label="Edit"
                  color="red"
                  onClick={() => onEditButtonClick(driver)}
                  variant={"contained"}
                />
              </div>
            );
          })}
        </DialogContent>
      </>
    );
  }
}
