import React from "react";
import { IconI, IconPlus } from "../../../../../../components/icons/svgIcons";
import Input from "../../../../../../components/actions/Input";
import CircleCheckboxM from "../../../../../../components/actions/CircleCheckboxM";
import AutocompleteInput from "../../../../../../components/actions/inputs/AutocompleteInput";
import {
  UnitTypes,
  DriverPeriodTypes,
  DriverCategoriesSelect,
  DriverPeriodTypesSelect,
  DriverDescriptions,
} from "../../../../../../data/Finance/CalculatedDriver/constants";
import ButtonMui from "../../../../../../components/buttons/buttonMui/buttonMui";
import MaterialSelect from "../../../../../../components/actions/SelectM";
import Mx_Tooltip from "../../../../../../components/icons/Mx_Tooltip_Click";

const CalculatedDriver_Value = "value";

class DriverCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      editDriverID: null,
    };
    this.validDrivers = props.validDrivers;
  }

  componentDidMount() {
    // this.setValidDrivers();
  }

  setValidDrivers = () => {
    let curDriverID = this.props.revenueID ? this.props.revenueID : null;
    let totalStream = this.props.totalDriver ? this.props.totalDriver : null;
    this.validDrivers = global.Modeliks.DriversStore.getFormulaBuildDrivers(
      curDriverID,
      totalStream,
    );
    this.forceUpdate();
  };

  checkIsExistingDrivers = () => {
    if (this.props.validDrivers && this.props.validDrivers.length > 0) {
      return this.props.validDrivers.filter((o) => this.props.drivers.indexOf(o) === -1).length > 0;
    }
    return false;
  };

  changeName = (e) => {
    this.props.driver.DriverName = e.target.value;
    if (this.props.driver.error !== "") {
      this.props.driver.error = "";
    }

    if (this.props.driver.errorMessage !== "") {
      this.props.driver.errorMessage = "";
    }

    this.forceUpdate();
  };
  changeUnitType = (e) => {
    this.props.driver.UnitType = e.target.value;
    this.props.driver.error = "";
    if (this.props.driver.hasOwnProperty("ID")) {
      this.props.driver.changeDriverTypeAndCategory(
        e.target.value,
        this.props.driver.DriverCategory,
      );
    }
    this.forceUpdate();
  };
  changeCategory = (e, v) => {
    this.props.driver.DriverCategory = e.target.value;
    this.props.driver.error = "";
    if (this.props.driver.hasOwnProperty("ID")) {
      this.props.driver.changeDriverTypeAndCategory(this.props.driver.UnitType, e.target.value);
    }
    this.forceUpdate();
  };

  changePeriodType = (e) => {
    this.props.driver.PeriodType = parseInt(e.target.value);
    this.forceUpdate();
  };

  validate = () => {
    const Driver = this.props.driver;
    if (Driver.DriverName === "" || Driver.UnitType === "" || Driver.DriverCategory === "") {
      this.props.driver.error = "error";
      this.forceUpdate();
      return false;
    }

    if (
      global.Modeliks.DriversStore.some(
        (e) => e.DriverName === Driver.DriverName && e.ID !== Driver.ID,
      )
    ) {
      this.props.driver.error = "error";
      this.props.driver.errorMessage = "Driver Name already exists!";
      this.forceUpdate();
      return false;
    }

    return true;
  };

  createDriver = () => {
    const isValid = this.validate();
    if (!isValid) {
      return null;
    } else {
      this.props.addDriver();
    }
    this.forceUpdate();
  };

  setDriverFromExisting = (e, driver) => {
    if (this.props.driver.IsNew) {
      global.Modeliks.DriversStore.removeItemByID(this.props.driver.ID);
    }
    this.props.setDriverFromExisting(driver);
    this.forceUpdate();
  };

  validateExisting = () => {
    const Driver = this.props.driver;

    if (Driver.DriverName.length < 2) {
      this.props.driver.error = "error";
      this.forceUpdate();
      return false;
    }

    if (Driver.IsNew) {
      this.props.driver.error = "error";
      this.forceUpdate();
      return false;
    }
    return true;
  };

  addExistingDriver = () => {
    const isValid = this.validateExisting();
    if (!isValid) {
      return null;
    } else {
      this.props.addDriver(this.props.driver);
    }
  };
  setNewDriver = () => {
    return (
      <>
        <ButtonMui
          style={{ height: "30px", fontSize: "14px" }}
          width={80}
          label="Create"
          onClick={this.createDriver}
          variant={"contained"}
        />
        <ButtonMui
          style={{ height: "30px", fontSize: "14px" }}
          width={80}
          label="Cancel"
          color="red"
          onClick={() => this.props.cancelDriver()}
          variant={"contained"}
        />
      </>
    );
  };

  setExistingDriver = () => {
    return (
      <>
        <ButtonMui
          style={{ height: "30px", fontSize: "14px" }}
          width={80}
          label="Add"
          onClick={this.addExistingDriver}
          variant={"contained"}
        />
        <ButtonMui
          style={{ height: "30px", fontSize: "14px" }}
          width={80}
          label="Cancel"
          color="red"
          onClick={() => this.props.cancelDriver()}
          variant={"contained"}
        />
      </>
    );
    // return <Button onClick={this.addExistingDriver} label='Add Driver' width={'136px'} padding={'0 0 0 2px'} iconRight={false} noElevation={true} color={'primary'}/>
  };

  checkDriverButtons = () => {
    if (this.props.driver.Ref_Field === CalculatedDriver_Value) {
      const label = this.state.editDriverID === this.props.driver.ID ? "Save" : "Edit";
      const onClickFunc =
        this.state.editDriverID === this.props.driver.ID ? this.saveChanges : this.setEditMode;
      return (
        <>
          <ButtonMui
            style={{ height: "30px", fontSize: "14px" }}
            width={80}
            color={"red"}
            label="Remove"
            onClick={() => this.props.removeDriver(this.props.driver)}
            variant={"contained"}
          />
          {this.props.driver &&
            this.props.driver.IsFormulaEditable === true &&
            this.props.driver.DriverName !== "Number Of New Employees (delta)" && (
              <ButtonMui
                style={{ height: "30px", fontSize: "14px" }}
                width={80}
                label={label}
                onClick={onClickFunc}
                variant={"contained"}
              />
            )}
        </>
      );
    } else {
      return (
        <ButtonMui
          style={{ height: "30px", fontSize: "14px" }}
          width={80}
          color={"red"}
          label="Remove"
          onClick={() => this.props.removeDriver(this.props.driver)}
          variant={"contained"}
        />
      );
    }
  };

  setEditMode = () => {
    if (this.props.driver.ID) {
      this.props.driver.editing = true;
      this.setState({ editDriverID: this.props.driver.ID });
    }
  };

  saveChanges = () => {
    const isValid = this.validate();
    if (!isValid) {
      return null;
    } else {
      this.props.driver.editing = false;
      this.props.driver.error = false;
      this.props.driver.errorMessage = "";
      this.setState({ editDriverID: null });
    }
  };

  render() {
    const { editDriverID } = this.state;
    const { driver, drivers, isNewDriver, isExistingDriver, index } = this.props;
    const isDisabled = isNewDriver
      ? false
      : !(driver.Ref_Field === CalculatedDriver_Value && editDriverID === driver.ID);

    if (!this.validDrivers) {
      return "loading...";
    }

    return (
      <div key={"drivers_" + index} className={"driver_card " + driver.error}>
        <div className="fdd_calculated_driver_object">
          <div className="fdd_cd_object_data_container">
            <span className="fdd_cd_object_data_name">Driver Name</span>
            <Mx_Tooltip description={DriverDescriptions.DriverNameDesc} />
            <div className="fdd_cd_driver_autocomplete">
              {isExistingDriver && this.checkIsExistingDrivers() ? (
                <AutocompleteInput
                  className="fdd_cd_driver_autocomplete_input_style"
                  getOptionDisabled={(option) => drivers.some((r) => r === option)}
                  options={this.validDrivers}
                  width="unset"
                  value={driver}
                  onChange={(e, driver) => this.setDriverFromExisting(e, driver)}
                />
              ) : (
                <Input
                  errorMessage={driver.errorMessage}
                  error={driver.error && driver.errorMessage}
                  onBlur={() => this.props.changedDriver()}
                  width="200px"
                  max={45}
                  disabled={isDisabled && driver.Ref_Field !== "custom_indicator"}
                  onChange={this.changeName}
                  value={driver.DriverName}
                />
              )}
            </div>
          </div>
          <div className="fdd_cd_object_data_container">
            <span className="fdd_cd_object_data_name data_type">Data Type</span>
            <Mx_Tooltip description={DriverDescriptions.DataTypeDesc} />
            <div className="fdd_cd_checkbox_div">
              <CircleCheckboxM
                onChange={this.changeUnitType}
                disabled={isDisabled}
                value={UnitTypes.Price}
                label={global.Modeliks.CompanyInfo.Currency.value}
                size="medium"
                checked={driver.UnitType === UnitTypes.Price}
              />
            </div>
            <div className="fdd_cd_checkbox_div">
              <CircleCheckboxM
                onChange={this.changeUnitType}
                disabled={isDisabled}
                value={UnitTypes.Percentage}
                label="%"
                size="medium"
                checked={driver.UnitType === UnitTypes.Percentage}
              />
            </div>
            <div className="fdd_cd_checkbox_div">
              <CircleCheckboxM
                onChange={this.changeUnitType}
                disabled={isDisabled}
                value={UnitTypes.Units}
                label="#"
                size="medium"
                checked={driver.UnitType === UnitTypes.Units}
              />
            </div>
          </div>
          <div className="fdd_cd_object_data_container">
            <span className="fdd_cd_object_data_name category">Category</span>
            <Mx_Tooltip description={DriverDescriptions.CategoryDesc} />
            <div className="fdd_cd_select_div">
              <MaterialSelect
                // errorMessage={(this.props.error && row.SubscriptionPeriodMonths === '') && 'Please enter subscription period'}
                // error={this.props.error && row.SubscriptionPeriodMonths === ''}
                value={driver.DriverCategory}
                disabled={isDisabled}
                onChange={this.changeCategory}
                width={"140px"}
                options={DriverCategoriesSelect}
              />
            </div>
            {/*<div className='fdd_cd_checkbox_div'><CircleCheckboxM onChange={this.changeCategory} disabled={isDisabled} label='avg.' size='medium' value={DriverCategories.Average} checked={driver.DriverCategory === DriverCategories.Average} /></div>*/}
            {/*<div className='fdd_cd_checkbox_div'><CircleCheckboxM onChange={this.changeCategory} disabled={isDisabled} label='sum' size='medium'  value={DriverCategories.Sum} checked={driver.DriverCategory === DriverCategories.Sum} /></div>*/}
            {/*<div className='fdd_cd_checkbox_div'><CircleCheckboxM onChange={this.changeCategory} disabled={isDisabled} label='opening balance' size='medium' value={DriverCategories.FirstPeriod} checked={driver.DriverCategory === DriverCategories.FirstPeriod} /></div>*/}
            {/*<div className='fdd_cd_checkbox_div'><CircleCheckboxM onChange={this.changeCategory} disabled={isDisabled} label='closing balance' size='medium'  value={DriverCategories.LastPeriod} checked={driver.DriverCategory === DriverCategories.LastPeriod} /></div>*/}
          </div>
          <div className="fdd_cd_object_data_container">
            <span className="fdd_cd_object_data_name category">Period</span>
            <Mx_Tooltip description={DriverDescriptions.PeriodDesc} />
            <div className="fdd_cd_select_div">
              <MaterialSelect
                value={driver.PeriodType}
                disabled={!isDisabled ? !isDisabled && driver.db_record : true}
                onChange={this.changePeriodType}
                width={"95px"}
                options={DriverPeriodTypesSelect}
              />
            </div>
            {/*<div className='fdd_cd_checkbox_div'><CircleCheckboxM onChange={this.changePeriodType} disabled={!isDisabled ? !isDisabled && driver.db_record : true} label='current' size='medium' value={DriverPeriodTypes.Current} checked={driver.PeriodType === DriverPeriodTypes.Current} /></div>*/}
            {/*<div className='fdd_cd_checkbox_div'><CircleCheckboxM onChange={this.changePeriodType} disabled={!isDisabled ? !isDisabled && driver.db_record : true} label='previous' size='medium'  value={DriverPeriodTypes.Previous} checked={driver.PeriodType === DriverPeriodTypes.Previous} /></div>*/}
          </div>
          <div className="fdd_cd_object_data_container">
            {isNewDriver
              ? this.setNewDriver()
              : isExistingDriver
                ? this.setExistingDriver()
                : this.checkDriverButtons()}
          </div>
        </div>
      </div>
    );
  }
}

export default DriverCard;
