import React from "react";
import "@szhsin/react-menu/dist/index.css";
import "../../../Admin/Dashboards/components/dashboardsBlankAdmin.scss";
import "../FinancialStatements/index.scss";
import FinanceTableMain from "../../../../components/tables/FinanceTable/FinanceTableMain";
import { Menu, MenuItem } from "@szhsin/react-menu";
import { default as MuiButton } from "@mui/material/Button";
import { ArrowDown } from "../../../../components/icons/svgIcons";
import Mx_Switch from "../../../../components/actions/Mx_Switch/Mx_Switch";
import NavigationActualFilters from "./components/NavigationActualFilters";
import ProfitLoss from "../../../../data/Finance/Reports/ProfitLoss";
import { ExpandCollapseTablesID, RevenueTypes } from "../../../../data/Finance/constants";
import BalanceSheet from "../../../../data/Finance/Reports/BalanceSheet";
import CashFlow from "../../../../data/Finance/Reports/CashFlow";
import CashFlowMessage from "./components/CashFlowMessage";
import Personnel from "../../../../data/Finance/Personnel";
import { DriverPeriodTypes } from "../../../../data/Finance/CalculatedDriver/constants";
import CollapseTableComponent from "../../../../components/tables/FinanceTable/components/CollapseTableComponent";
import FooterContent from "../../Financials/components/Footer";

export default class DisplayActuals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: new Date().getTime(),
      loading: true,
      months: this.props.allMonths,
      years: this.props.allYears,
      allYears: this.props.allYears,
      allMonths: this.props.allMonths,
      selectedSubsection: props.selectedSubsection,
      activeOpeningBalances: props.activeOpeningBalances,
      openingBalanceMonths: this.props.allMonths[0],
      openingBalanceYears: this.props.allYears[0],
    };
    this.personnelTotals = Personnel.geTotalEmployeeCostTotals();
    this.personnelTotalEmployees = Personnel.getPersonnelEmployeeTotals();
    this.gridRowsEmployeeCostBurdenRate =
      global.Modeliks.PersonnelStore.dataGridRows.gridRowsBurdenRateActual;
    this.gridRowsEmployeeCostBurdenRateActual =
      global.Modeliks.PersonnelStore.dataGridRows.gridRowsBurdenRateActual;
    this.totalsRows = this.row_totals();
    this.totalsCostPerEmployee = this.row_totals(
      Personnel.getPersonnelTotalCostPerEmployee(),
      "Average Cost per Employee",
    );
    this.totalsRowsCost = this.row_totals_cost();
  }

  componentDidMount() {
    this.getMonths();
    this.getYears();
    this.setData();
  }

  row_totals_cost = (totals = this.personnelTotals) => {
    return [
      {
        id: totals.ID,
        unit: global.Modeliks.CompanyInfo.Currency.value,
        header: totals.DriverName,
        cssClass: "totals",
        boldRowHeader: true,
        data: totals,
      },
    ];
  };
  row_totals = (data = this.personnelTotalEmployees, header = "Total Number of Employees ") => {
    return [
      {
        id: data.ID,
        unit: global.Modeliks.CompanyInfo.Currency.value,
        header: header,
        cssClass: "totals",
        boldRowHeader: true,
        data: data,
      },
    ];
  };
  getMonths = (
    changeState = false,
    start = this.props.financialMonths.startMonthOrder,
    end = this.props.financialMonths.endMonthOrder,
  ) => {
    if (this.props.financialMonths.showMonthly) {
      const getFromState = this.state.allMonths;
      this.state.months = getFromState.filter(
        (m) => m.Order >= start && m.Order <= end && m.PeriodType === "month",
      );
    } else {
      const getFromState = this.state.allYears;
      start = this.props.financialMonths.startYearOrder;
      end = this.props.financialMonths.endYearOrder;
      this.state.years = getFromState.filter(
        (m) => m.Order >= start && m.Order <= end && m.PeriodType === "year",
      );
      // this.state.months = this.state.years.map(m => m.months).flat();
    }

    if (changeState) {
      // this.forceUpdate();
      this.props.updateDataGrid();
    }
  };

  getYears = (
    changeState = false,
    start = this.props.financialMonths.startYearOrder,
    end = this.props.financialMonths.endYearOrder,
  ) => {
    const getFromState = this.state.allYears;
    start = this.props.financialMonths.startYearOrder;
    end = this.props.financialMonths.endYearOrder;
    this.state.years = getFromState.filter(
      (m) => m.Order >= start && m.Order <= end && m.PeriodType === "year",
    );

    if (changeState) {
      // this.forceUpdate();
      this.props.updateDataGrid();
    }
  };

  setData = (selectedReport, setOpeningBalance = false) => {
    // this.renderActualReport()
    if (selectedReport) {
      this.state.selectedSubsection = selectedReport;
      this.renderActualReport(selectedReport);
    } else {
      this.renderActualReport();
    }

    setTimeout(() => {
      this.setState({
        loading: false,
      });
    }, 100);
  };

  changeSubsection = (section) => {
    if (this.state.selectedSubsection != section) {
      this.setState({ loading: true }, () =>
        this.props.setSelectedSubsection(section, () => {
          this.setData(section);
        }),
      );
    }
  };

  changeType = (e, value) => {
    this.setState({ selectedType: value });
  };

  changeColumns = () => {
    this.forceUpdate();
  };

  changeMonthlyAnnually = (e, showMontly = true) => {
    this.props.financialMonths.showMonthly = !showMontly;
    this.props.saveActualDates();
  };

  changeDriversView = () => {
    this.props.financialMonths.showAllDrivers = !this.props.financialMonths.showAllDrivers;
    this.props.saveActualDates();
    this.setData(this.state.selectedSubsection);
  };
  handleGridDataChanged = (items) => {
    this.forceUpdate();

    items.forEach((item) => {
      setTimeout(() => {
        if (item.Save) {
          setTimeout(item.Save, 100);
        }
      }, 1);
    });
    this.forceUpdate();
  };

  setDataProfitAndLoss = () => {
    this.gridRows = ProfitLoss.getReport()
      .getReportTotals()
      .map((stream) => this.revenue_only_create_Profit_and_Loss(stream));
  };
  revenue_only_create_Profit_and_Loss = (revenue, isChild = false, stopChildren = false) => {
    if (!revenue) {
      return;
    }

    const displayDriver = !revenue.IsSimple && !isChild;
    const row = {
      id: revenue.ID,
      // header: revenue.DriverName.includes(SpecialChar.DriverNameESCChar) ? revenue.DriverName.substring(revenue.DriverName.indexOf(SpecialChar.DriverNameESCChar) + 2, revenue.DriverName.length) : revenue.DriverName,
      header: revenue.DriverName,
      // revenue: revenue,
      hasEdit: false,
      disabledEdit: true,
      isExpanded: !isChild,
      linkButton: {
        active: false,
        title: null,
        url: null,
      },
      // isEditable: true,
      boldRowHeader: false,
      field: "Actual",
      data: revenue,
      children: [],
    };

    let displayChildrenDrivers = false;

    if (!isChild) {
      const boldRowHeaderDrivers = [
        "gross_profit",
        "ebitda",
        "operating_profit",
        "net_income",
        "profit_before_tax",
      ];

      row.boldRowHeader =
        boldRowHeaderDrivers.includes(revenue.Ref_Field) ||
        revenue.Ref_Table === global.Modeliks.Tables.Finance_Revenues.TableName ||
        revenue.Ref_Table === global.Modeliks.Tables.Finance_CostSales.TableName ||
        revenue.Ref_ID === "operating_expenses";
    } else {
      if (revenue.Ref_Table === global.Modeliks.Tables.Finance_Revenues.TableName) {
        if (row.data && this.props.financialMonths.showAllDrivers) {
          const parentStream = row.data.getParentStream();
          if (parentStream && parentStream.RevenueType === RevenueTypes.Subscriptions) {
            row.children.push(
              Object.assign({
                id: parentStream.SubscriptionRevenue.ID,
                unit: global.Modeliks.CompanyInfo.Currency.value,
                header: "Totals",
                data: parentStream.SubscriptionRevenue,
                children: this.sub_row_single_SubscriptionPlans(parentStream, false),
              }),
            );

            row.data = parentStream.SubscriptionRevenue;
          }
        } else {
          const parentStream = row.data.getParentStream();
          if (parentStream && parentStream.RevenueType === RevenueTypes.Subscriptions) {
            row.data = parentStream.SubscriptionRevenue;
          }
        }
      }
      if (
        (!revenue.IsSimple && this.props.financialMonths.showAllDrivers) ||
        revenue.Ref_Table === global.Modeliks.Tables.Finance_Personnel.TableName
      ) {
        displayChildrenDrivers = true;
      }
      if (
        revenue.Ref_ID === "personnel_expenses" ||
        revenue.Ref_ID === "personnel_direct_labor_d"
      ) {
        row.linkButton.active = true;
        row.linkButton.title = "Edit";
        row.linkButton.url = "/dashboards/actuals/employee_schedule";
        if (
          !this.props.financialMonths.showAllDrivers &&
          revenue.Ref_ID !== "personnel_direct_labor_d"
        ) {
          const children = revenue.getChildDrivers();
          if (children) {
            children.forEach((driver) => {
              if (driver.Ref_Field == "total") {
                if (
                    driver.Ref_Table === global.Modeliks.Tables.Finance_Personnel.TableName &&
                    (driver.Ref_Field === "total" || driver.Ref_Field === "totals")
                ) {
                  const burdenID = "burden-changeable-driver";
                  const BurdenChangeableDriver = global.Modeliks.DriversStore.getItem(
                      `${global.Modeliks.Tables.Finance_Personnel.TableName}-${driver.Ref_ID}-${burdenID}`,
                  );

                  if(BurdenChangeableDriver){
                    row.children.push(this.revenue_only_create_Profit_and_Loss(BurdenChangeableDriver, true, true));

                  }else {
                    row.children.push(this.revenue_only_create_Profit_and_Loss(driver, true, true));

                  }
                }else {
                  row.children.push(this.revenue_only_create_Profit_and_Loss(driver, true, true));
                }
              }
            });
          }
        }
      }
    }

    const hideDrivers = ["interest_expense_driver", "sale_of_property_and_equipment_driver"];

    if (
      (displayDriver || displayChildrenDrivers) &&
      !hideDrivers.includes(revenue.Ref_ID) &&
      !stopChildren
    ) {
      const children = revenue.getChildDrivers();
      const allowedChildrenTables =
        revenue.Ref_Table === global.Modeliks.Tables.Finance_Personnel.TableName ||
        revenue.Ref_Table === global.Modeliks.Tables.Finance_Revenues.TableName ||
        revenue.Ref_Table === global.Modeliks.Tables.Finance_CostSales.TableName ||
        revenue.Ref_Table === global.Modeliks.Tables.Finance_Expenses.TableName;
      if (children) {
        children.map((driver) => {
          if (
            driver.Ref_Table === global.Modeliks.Tables.Finance_Personnel.TableName &&
              (driver.Ref_Field === "total")
          ) {
            const burdenID = "burden-changeable-driver";
            const BurdenChangeableDriver = global.Modeliks.DriversStore.getItem(
              `${global.Modeliks.Tables.Finance_Personnel.TableName}-${driver.Ref_ID}-${burdenID}`,
            );
            if (BurdenChangeableDriver) {
              if (!this.props.financialMonths.showAllDrivers) {
                row.children.push(
                  this.revenue_only_create_Profit_and_Loss(BurdenChangeableDriver, true, true),
                );
              } else {
                const nextRow = this.revenue_only_create_Profit_and_Loss(
                  BurdenChangeableDriver,
                  true,
                  true,
                );
                const parentStream = driver.getParentStream();
                try {
                  nextRow.children.push(
                    this.revenue_only_create_Profit_and_Loss(parentStream.EmployeeCost, true, true),
                  );
                  nextRow.children.push(
                    this.revenue_only_create_Profit_and_Loss(
                      parentStream.EmployeeNumber,
                      true,
                      true,
                    ),
                  );
                  nextRow.children.push(
                    this.revenue_only_create_Profit_and_Loss(
                      parentStream.EmployeeBenefits,
                      true,
                      true,
                    ),
                  );
                } catch (e) {
                  console.log("Error");
                }
                row.children.push(nextRow);
              }
            }
          } else {
            if (
              driver &&
              allowedChildrenTables &&
              !(
                revenue.Ref_ID === 0 &&
                revenue.Ref_Table === global.Modeliks.Tables.Finance_Revenues.TableName &&
                isChild === true
              )
            ) {
              row.children.push(
                this.revenue_only_create_Profit_and_Loss(
                  driver,
                  true,
                  !this.props.financialMonths.showAllDrivers &&
                    driver.Ref_Table === global.Modeliks.Tables.Finance_Personnel.TableName &&
                    driver.Ref_Field === "total",
                ),
              );
            }
          }
        });
      }
    }
    if (row.data.Ref_Table === global.Modeliks.Tables.Finance_Personnel.TableName) {
      row.disabledEdit = true;
      row.hasEdit = false;
      row.IsEditable = false;
      row.isNotEditable = true;
    }

    if (row.data.Ref_Field === "employee_benefits") {
      row.header = "Other Benefits per Employee";
    }

    if (row.data.Ref_Field === "employee_cost") {
      row.header = "Cost per Employee";
    }
    if (row.data.Ref_Field === "employee_number") {
      row.header = "Employee Number";
    }

    return row;
  };
  sub_row_single_SubscriptionPlans = (sub, isMulti = false) => {
    return [
      {
        id: "qty_" + sub.Quantity.ID,
        unit: null,
        header: "Signups",
        hasEdit: false,
        isEditable: true,
        field: "Quantity",
        data: sub.Quantity,
      },
      {
        id: "cr_" + sub.ChurnRate.ID,
        header: "Churn Rate",
        hasEdit: false,
        unit: "%",
        isEditable: true,
        field: "ChurnRate",
        data: sub.ChurnRate,
      },
      {
        id: "cc_" + sub.ChurnRate.ID,
        header: "Churned Customers",
        hasEdit: false,
        unit: null,
        isEditable: true,
        field: "ChurnedCustomers",
        data: sub.ChurnedCustomers,
      },
      {
        id: "rf_" + sub.Refund.ID,
        header: "Refund",
        hasEdit: false,
        unit: "%",
        isEditable: true,
        field: "Refund",
        data: sub.Refund,
        children: !sub.Refund.IsSimple ? this.get_subscription_drivers(sub.Refund) : [],
      },
      {
        id: "sp_" + sub.SinglePrice.ID,
        header: "Subscription Price",
        hasEdit: false,
        unit: global.Modeliks.CompanyInfo.Currency.value,
        isEditable: true,
        field: "SinglePrice",
        data: sub.SinglePrice,
      },
      {
        id: "cs_" + sub.CustomersAtStart.ID,
        header: "Customers at Start",
        hasEdit: false,
        unit: global.Modeliks.CompanyInfo.Currency.value,
        isEditable: true,
        field: "CustomersAtStart",
        data: sub.CustomersAtStart,
      },
      {
        id: "caeop_" + sub.CustomersAtEndOfPeriod.ID,
        unit: null,
        header: "Customers at End of Period",
        isEditable: false,
        field: "calc_CustomersAtEndOfPeriod",
        data: sub.CustomersAtEndOfPeriod,
      },
      {
        id: "cdpc_" + sub.TotalCustomersDueForPayment.ID,
        unit: global.Modeliks.CompanyInfo.Currency.value,
        header: `Customers Due For Payment`,
        isEditable: false,
        field: "calc_TotalCustomersDueForPayment",
        data: sub.TotalCustomersDueForPayment,
      },
      {
        id: "cc_" + sub.CashCollection.ID,
        unit: global.Modeliks.CompanyInfo.Currency.value,
        header: `Cash Collections`,
        isEditable: false,
        field: "calc_CashCollection",
        data: sub.CashCollection,
      },
    ];
  };

  setDataBalanceSheet = () => {
    this.gridRows = BalanceSheet.getReport()
      .getReportTotals()
      .map((tax) => this.row_create_balance_sheet(tax));
  };

  row_create_balance_sheet = (revenue, isChild = false, level = 0) => {
    if (!revenue) {
      return;
    }
    const displayDriver = !revenue.IsSimple && !isChild;
    const row = {
      id: revenue.ID,
      header: revenue.Name,
      // revenue: revenue,
      hasEdit: false,
      isExpanded: false,
      disabledEdit: true,
      boldRowHeader: false,
      field: "Actual",
      data: revenue,
      children: [],
    };

    let displayChildrenDrivers = false;

    if (!isChild) {
      row.isExpanded = true;
      row.boldRowHeader = true;
    } else {
      row.isExpanded = revenue.Ref_ID !== "retained_earnings";

      if (
        (!revenue.IsSimple && this.props.financialMonths.showAllDrivers) ||
        (!revenue.IsSimple && level < 2)
      ) {
        displayChildrenDrivers = true;
      }

      if (
        revenue.DriverName === "Current Liabilities" ||
        revenue.DriverName === "Long Term Liabilities"
      ) {
        displayChildrenDrivers = true;
      }

      //
      if (revenue.Ref_Table === "Finance_Assets") {
        const parent = revenue.getParentStream();
        if (
          parent &&
          this.props.financialMonths.showAllDrivers &&
          !parent.hasOwnProperty("GrossBookValue") &&
          !parent.hasOwnProperty("AccumulatedDepreciation")
        ) {
          row.children.push({
            id: parent.GrossBookValue.ID,
            header: parent.GrossBookValue.Name,
            // revenue: revenue,
            hasEdit: false,
            isExpanded: false,
            disabledEdit: true,
            boldRowHeader: false,
            field: "Actual",
            data: parent.GrossBookValue,
            children: [],
          });

          row.children.push({
            id: parent.AccumulatedDepreciation.ID,
            header: parent.AccumulatedDepreciation.Name,
            // revenue: revenue,
            hasEdit: false,
            isExpanded: false,
            disabledEdit: true,
            boldRowHeader: false,
            field: "Actual",
            data: parent.AccumulatedDepreciation,
            children: [],
          });
        }
      }
    }

    const hideDrivers = ["asset-payable-balance-current-term", "asset-payable-balance-long-term"];
    const hideDriversByName = ["Shareholder Equity", "Long term asset purchases payable"];

    if (
      (displayDriver || displayChildrenDrivers) &&
      !hideDrivers.includes(revenue.Ref_ID) &&
      revenue.Ref_ID !== "shareholder_eq_driver" &&
      !hideDriversByName.includes(revenue.DriverName)
    ) {
      const children = revenue.getChildDrivers();
      if (children && children.length > 0) {
        children.map((driver) => {
          if (
            driver &&
            driver.hasOwnProperty("DriverName") &&
            !(
              driver.DriverName === "Current asset purchases payable" ||
              driver.DriverName === "Long term asset purchases payable"
            )
          ) {
            row.children.push(this.row_create_balance_sheet(driver, true, level + 1));
          }
        });
      }
    }
    return row;
  };

  setDataCashFlow = () => {
    this.gridRows = CashFlow.getReport()
      .getReportTotals()
      .map((tax) => this.revenue_only_create_Cash_flow(tax));
  };

  revenue_only_create_Cash_flow = (revenue, isChild = false, level = 0) => {
    if (!revenue) {
      return;
    }

    const displayDriver = !revenue.IsSimple && !isChild;
    const row = {
      id: revenue.ID,
      header: revenue.Name,
      // revenue: revenue,
      hasEdit: false,
      disabledEdit: true,
      boldRowHeader: false,
      isExpanded: true,
      field: "Actual",
      data: revenue,
      children: [],
    };

    let displayChildrenDrivers = false;
    //
    // if(revenue.DriverName === 'Asset Purchases'){
    //     row.isNotEditable = true;
    // }

    if (!isChild) {
      row.boldRowHeader = true;
    } else {
      if (
        (!revenue.IsSimple && this.props.financialMonths.showAllDrivers) ||
        (!revenue.IsSimple && level < 2)
      ) {
        displayChildrenDrivers = true;
      }
    }
    const displayOnlyParent = [
      "gain_or_loss_from_sale_of_ppe",
      "prepaid-change_in_sales_tax_payable-closing-balance",
      // "change_in_income_tax_payable" ,
      // "change_in_sales_tax_payable",
      // "change_in_vat_tax_payable"
    ];

    if (
      (displayDriver || displayChildrenDrivers) &&
      !displayOnlyParent.includes(revenue.Ref_Field) &&
      revenue.DriverName !== "Equity Investments"
    ) {
      const children = revenue.getChildDrivers();

      children.map((driver) => {
        // driver.isValidActual
        if (driver) {
          row.children.push(this.revenue_only_create_Cash_flow(driver, true, level + 1));
        }
      });
    }

    return row;
  };

  renderActualReport = (report) => {
    let selected = this.props.selectedSubsection;
    if (report) {
      selected = report;
    }

    if (selected.key === "ProfitAndLoss") {
      return this.setDataProfitAndLoss();
    } else if (selected.key === "BalanceSheet") {
      return this.setDataBalanceSheet();
    } else if (selected.key === "EmployeeSchedule") {
      return this.setDataEmployeeSchedule();
    } else {
      return this.setDataCashFlow();
    }
  };

  reloadAfterInsert = () => {
    this.forceUpdate();
  };

  revenue_only_create_number = (personnel, parentRevenue, level = 0, stopRecursive = false) => {
    let row = {};
    if (personnel) {
      if (level === 0 && personnel.getChildDrivers() && personnel.getChildDrivers().length === 0) {
        return null;
      }
      if (level > 1) {
        row = {
          id: personnel.ID,
          header: personnel.Name,
          revenue: personnel,
          hasEdit: true,
          isNotEditable: false,
          isExpanded: true,
          data: personnel,
          field: "Actual",
          children: [],
        };
      } else if (level === 1) {
        let header = personnel.Name;
        header = personnel.DriverName.split("_")[0]
          ? personnel.DriverName.split("_")[1]
          : personnel.DriverName.split("_")[1];
        row = {
          id: personnel.ID,
          header: header,
          revenue: personnel,
          hasEdit: false,
          disabledEdit: true,
          isExpanded: true,
          field: "Actual",
          data: personnel,
          children: [],
        };
      } else {
        row = {
          id: personnel.ID,
          header: personnel.Name,
          revenue: personnel,
          hasEdit: false,
          disabledEdit: true,
          boldRowHeader: true,
          field: "Actual",
          data: personnel,
          isExpanded: true,
          children: [],
        };
      }
      let children = personnel.getChildDrivers();
      if (
        children &&
        !stopRecursive &&
        personnel.Ref_Field !== Personnel.DriversDesc.EmployeeBenefits.driverID &&
        personnel.Ref_ID !== 0
      ) {
        children.map((driver) => {
          if (
            driver.Ref_Field === "total" ||
            (driver.Ref_Field === "employee_number" && level === 0)
          ) {
            row.children.push(
              this.revenue_only_create_number(
                driver,
                personnel,
                level + 1,
                personnel.PeriodType === DriverPeriodTypes.Previous,
              ),
            );
          } else if (driver.Ref_Field === "employee_number") {
            row.data = driver;
          }
        });
      }

      return row;
    } else {
      return null;
    }
  };
  revenue_only_create_salary = (personnel, parentRevenue, level = 0, stopRecursive = false) => {
    let row = {};
    if (personnel) {
      if (level > 1) {
        row = {
          id: personnel.ID,
          header: personnel.Name,
          revenue: personnel,
          hasEdit: false,
          disabledEdit: true,
          field: "Actual",
          isNotEditable: true,
          data: personnel,
          children: [],
        };
      } else if (level === 1) {
        let header = personnel.Name;
        if (personnel.Ref_Field == "employee_cost") {
          header = personnel.DriverName.split("_")[0]
            ? personnel.DriverName.split("_")[1]
            : personnel.DriverName.split("_")[1];
        }
        row = {
          id: personnel.ID,
          header: header,
          revenue: personnel,
          hasEdit: false,
          disabledEdit: true,
          field: "Actual",
          data: personnel,
          children: [],
        };
      } else {
        row = {
          id: personnel.ID,
          header: personnel.Name,
          revenue: personnel,
          hasEdit: false,
          disabledEdit: true,
          boldRowHeader: true,
          field: "Actual",
          data: personnel,
          isExpanded: true,
          children: [],
        };
      }
      let children = personnel.getChildDrivers();
      if (
        children &&
        !stopRecursive &&
        personnel.Ref_Field !== Personnel.DriversDesc.EmployeeBenefits.driverID &&
        personnel.Ref_ID !== 0 &&
        level < 1
      ) {
        children.map((driver) => {
          if (driver.Ref_Field === "employee_cost") {
            row.children.push(
              this.revenue_only_create_salary(
                driver,
                personnel,
                level + 1,
                personnel.PeriodType === DriverPeriodTypes.Previous,
              ),
            );
          }
        });
      }

      if (
        !this.props.financialMonths.showAllDrivers &&
        row.data.Ref_Table === global.Modeliks.Tables.Finance_Personnel.TableName
      ) {
        row.disabledEdit = true;
        row.hasEdit = false;
        row.IsEditable = false;
        row.isNotEditable = true;
      }

      return row;
    } else {
      return null;
    }
  };
  revenue_only_create_salary_driver_level = (
    personnel,
    parentRevenue,
    level = 0,
    stopRecursive = false,
  ) => {
    let row = {};
    if (personnel) {
      if (level > 1) {
        row = {
          id: personnel.ID,
          header: personnel.Name,
          revenue: personnel,
          hasEdit: false,
          disabledEdit: true,
          field: "Actual",
          isNotEditable: true,
          isExpanded: true,
          data: personnel,
          children: [],
        };
      } else if (level === 1) {
        let header = personnel.Name;

        row = {
          id: personnel.ID,
          header: header,
          revenue: personnel,
          hasEdit: false,
          disabledEdit: true,
          field: "Actual",
          data: personnel,
          isExpanded: true,
          children: [],
        };
      } else {
        row = {
          id: personnel.ID,
          header: personnel.Name,
          revenue: personnel,
          hasEdit: false,
          disabledEdit: true,
          boldRowHeader: true,
          field: "Actual",
          data: personnel,
          isExpanded: true,
          children: [],
        };
      }
      let children = personnel.getChildDrivers();
      if (children && level < 2) {
        children.map((driver) => {
          row.children.push(
            this.revenue_only_create_salary_driver_level(
              driver,
              personnel,
              level + 1,
              personnel.PeriodType === DriverPeriodTypes.Previous,
            ),
          );
        });
      }

      if (
        row.data.Ref_Table === global.Modeliks.Tables.Finance_Personnel.TableName &&
        row.data.Ref_Field === "total"
      ) {
        row.disabledEdit = true;
        row.hasEdit = false;
        row.IsEditable = false;
        row.isNotEditable = true;
      }

      if (
        row.data.Ref_Table === global.Modeliks.Tables.Finance_Personnel.TableName &&
        (row.data.Ref_Field === "employee_benefits" || row.data.Ref_Field === "employee_cost")
      ) {
        row.disabledEdit = false;
        row.IsEditable = true;
        row.isNotEditable = false;
      }

      if (row.data.Ref_Field === "employee_benefits") {
        row.header = "Other Benefits per Employee";
      }

      if (row.data.Ref_Field === "employee_cost") {
        row.header = "Cost per Employee";
      }
      return row;
    } else {
      return null;
    }
  };

  setDataEmployeeSchedule = () => {
    this.gridRows = {
      NumberOfEmployees: Personnel.getPersonnelCategoriesTotalsNumbersOfEmployees()
        .map((stream) => this.revenue_only_create_number(stream))
        .filter((c) => c != null),
      SalaryPerEmployee: Personnel.getPersonnelCategoriesEmployeesCostTotals()
        .map((stream) => this.revenue_only_create_salary(stream))
        .filter((c) => c != null),
      SalaryPerEmployeeDriverLevel:
        Personnel.getPersonnelCategoriesEmployeesCostTotalsBurdenRateActuals()
          .map((stream) => this.revenue_only_create_salary_driver_level(stream))
          .filter((c) => c != null),
    };
  };

  render() {
    if (this.state.loading) {
      return this.props.loader;
    }

    let mMonths = this.state.months;
    let yYears = this.state.years;

    if (
      this.props.activeOpeningBalances &&
      this.state.selectedSubsection &&
      this.state.selectedSubsection.key === "CashFlow"
    ) {
      mMonths = [this.state.openingBalanceMonths];
      yYears = [this.state.openingBalanceYears];
    }

    if (this.state.selectedSubsection && this.state.selectedSubsection.key === "EmployeeSchedule") {
      return (
        <React.Fragment>
          <div className="psdf_menu_navigation">
            <Menu
              arrow
              menuClassName="psdf_menu"
              menuButton={
                <MuiButton endIcon={<ArrowDown />} className="psdf_menu_section_button">
                  {this.state.selectedSubsection.name}
                </MuiButton>
              }
            >
              {Object.values(this.props.FinancialStatements.subsections).map((c, index) => (
                <MenuItem key={"dash_" + index} onClick={() => this.changeSubsection(c, index)}>
                  {c.name}
                </MenuItem>
              ))}
            </Menu>
            <div className="psdf_switch">
              <Mx_Switch
                checked={!this.props.financialMonths.showMonthly}
                onChange={this.changeMonthlyAnnually}
              />
            </div>

            <NavigationActualFilters
              disableImport={true}
              useActual={true}
              rows={this.gridRows.NumberOfEmployees}
              periods={this.props.financialMonths.showMonthly ? mMonths : yYears}
              financialMonths={this.props.financialMonths}
              getMonths={this.getMonths}
              getYears={this.getYears}
              hideLevelSelection
              showMonthly={this.props.financialMonths.showMonthly}
              months={this.props.allMonths}
              years={this.props.allYears}
              selectedReport={this.state.selectedSubsection}
              limitToCurYear={true}
              reload={this.reloadAfterInsert}
              setOpeningBalanceDates={this.props.setOpeningBalanceDates}
              activeOpeningBalances={this.props.activeOpeningBalances}
              selectedSubsection={this.state.selectedSubsection.name}
            />
          </div>

          <div className={"pfr_main_cont"}>
            <div className="main_table_container psf_upper_table_cont">
              {this.gridRows && (
                <div className="psf_reports_export_pdf dashboard" id="report_table">
                  <CollapseTableComponent
                    title="Number of Employees"
                    showMonthlyOnChange={() =>
                      this.setState({ showMonthly: !this.state.showMonthly })
                    }
                    useCustomizableTable={true}
                    showCheckBox={true}
                    hideCheckBox={true}
                    IsReport
                    showActualMessage
                    ActualMessage={"Please input your number of employees."}
                    updateTable={() => this.forceUpdate()}
                    disabledEdit={this.EditPermission}
                    disableTruncateNumbers
                  >
                    <FinanceTableMain
                      rows={this.gridRows.NumberOfEmployees}
                      totalsRows={this.totalsRows}
                      headers={this.props.financialMonths.showMonthly ? mMonths : yYears}
                      indexTab={0}
                      showRowHeaders={true}
                      useDateIndexes={true}
                      useCustomizableTable={true}
                      useActual={true}
                      IsReport
                      unitDisplay
                      IsNumberFormatDisabled
                      useDashboards={false}
                      expandedAll={true}
                      disabledEdit={
                        !global.Modeliks.PERMISSIONS.Dashboards.restrictions.edit.active
                      }
                      // changeDriverName={true}
                      wrapperClassName={"psf_main_streams_wrapper_div"}
                      makeDuplicate={() => console.log("this.makeDuplicate")}
                      // handeMoveRow={this.handeMoveRow}
                      // useDrag={true}
                      disabledMove={true}
                      title="Number of Employees"
                      onDataChanged={this.handleGridDataChanged}
                    />
                  </CollapseTableComponent>
                </div>
              )}
            </div>

            <div className="main_table_container psf_upper_table_cont">
              {this.gridRows && (
                <div className="psf_reports_export_pdf dashboard" id="report_table">
                  <CollapseTableComponent
                    title="Cost per Employee"
                    showMonthlyOnChange={() =>
                      this.setState({ showMonthly: !this.state.showMonthly })
                    }
                    useCustomizableTable={true}
                    disableTruncateNumbers
                    showCheckBox={true}
                    IsReport
                    showActualMessage
                    ActualMessage={"Please input your costs per employee."}
                    hideCheckBox={true}
                    updateTable={() => this.forceUpdate()}
                    disabledEdit={this.EditPermission}
                  >
                    <FinanceTableMain
                      rows={this.gridRows.SalaryPerEmployeeDriverLevel}
                      totalsRows={this.totalsCostPerEmployee}
                      headers={this.props.financialMonths.showMonthly ? mMonths : yYears}
                      indexTab={2}
                      showRowHeaders={true}
                      useDateIndexes={true}
                      useCustomizableTable={true}
                      useActual={true}
                      useDashboards={false}
                      unitDisplay
                      IsReport
                      IsNumberFormatDisabled
                      disabledEdit={
                        !global.Modeliks.PERMISSIONS.Dashboards.restrictions.edit.active
                      }
                      // changeDriverName={true}
                      wrapperClassName={"psf_main_streams_wrapper_div"}
                      makeDuplicate={() => console.log("this.makeDuplicate")}
                      // handeMoveRow={this.handeMoveRow}
                      // useDrag={true}
                      disabledMove={true}
                      title="Cost per Employee"
                      onDataChanged={this.handleGridDataChanged}
                    />
                  </CollapseTableComponent>
                </div>
              )}
            </div>

            <div className="main_table_container psf_upper_table_cont">
              {this.gridRows && (
                <div className="psf_reports_export_pdf dashboard" id="report_table">
                  <CollapseTableComponent
                    title="Total Employee Cost"
                    showMonthlyOnChange={() =>
                      this.setState({ showMonthly: !this.state.showMonthly })
                    }
                    useCustomizableTable={true}
                    disableTruncateNumbers
                    showCheckBox={true}
                    IsReport
                    hideCheckBox={true}
                    updateTable={() => this.forceUpdate()}
                    disabledEdit={this.EditPermission}
                  >
                    <FinanceTableMain
                      rows={this.gridRowsEmployeeCostBurdenRate}
                      totalsRows={[
                        {
                          boldRowHeader: this.totalsRowsCost[0].boldRowHeader,
                          cssClass: this.totalsRowsCost[0].boldRowHeader,
                          data: global.Modeliks.PersonnelStore.dataGridRows.totalsRowsBurdenRate[0]
                            .data,
                          id: this.totalsRowsCost[0].data.ID,
                          unit: this.totalsRowsCost[0].unit,
                          header: "Total Employees Cost",
                        },
                      ]}
                      headers={this.props.financialMonths.showMonthly ? mMonths : yYears}
                      indexTab={3}
                      showRowHeaders={true}
                      useDateIndexes={true}
                      useCustomizableTable={true}
                      useActual={true}
                      useDashboards={false}
                      unitDisplay
                      IsReport
                      IsNumberFormatDisabled
                      disabledEdit={
                        !global.Modeliks.PERMISSIONS.Dashboards.restrictions.edit.active
                      }
                      // changeDriverName={true}
                      wrapperClassName={"psf_main_streams_wrapper_div"}
                      makeDuplicate={() => console.log("this.makeDuplicate")}
                      // handeMoveRow={this.handeMoveRow}
                      // useDrag={true}
                      disabledMove={true}
                      title="Total Employee Cost"
                      onDataChanged={this.handleGridDataChanged}
                    />
                  </CollapseTableComponent>
                </div>
              )}
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className="psdf_menu_navigation">
            <Menu
              arrow
              menuClassName="psdf_menu"
              menuButton={
                <MuiButton endIcon={<ArrowDown />} className="psdf_menu_section_button">
                  {this.state.selectedSubsection.name}
                </MuiButton>
              }
            >
              {Object.values(this.props.FinancialStatements.subsections).map((c, index) => (
                <MenuItem key={"dash_" + index} onClick={() => this.changeSubsection(c, index)}>
                  {c.name}
                </MenuItem>
              ))}
            </Menu>
            <div className="psdf_switch">
              <Mx_Switch
                checked={!this.props.financialMonths.showMonthly}
                onChange={this.changeMonthlyAnnually}
              />
            </div>

            <NavigationActualFilters
              useActual={true}
              rows={this.gridRows}
              periods={this.props.financialMonths.showMonthly ? mMonths : yYears}
              financialMonths={this.props.financialMonths}
              getMonths={this.getMonths}
              getYears={this.getYears}
              showMonthly={this.props.financialMonths.showMonthly}
              months={this.props.allMonths}
              years={this.props.allYears}
              selectedReport={this.state.selectedSubsection}
              limitToCurYear={true}
              reload={this.reloadAfterInsert}
              setOpeningBalanceDates={this.props.setOpeningBalanceDates}
              activeOpeningBalances={this.props.activeOpeningBalances}
              changeDriversView={this.changeDriversView}
              selectedSubsection={this.state.selectedSubsection.name}
            />
          </div>

          <FooterContent
            showCheckBox={true}
            disableTruncateNumbers
            disabled={this.EditPermission}
            disablePrev={true}
            // showMonthly={this.state.showMonthly}
            updateTable={() => this.forceUpdate()}
            showCashFlowMessage={
              this.state.selectedSubsection && this.state.selectedSubsection.key === "CashFlow"
            }
            useCustomizableTable={true}
            hideCheckBox={true}
            IsReport
            showMonthlyOnChange={() => this.setState({ showMonthly: !this.state.showMonthly })}
          />

          <div className="main_table_container report_table ">
            {this.gridRows && (
              <div className="psf_reports_export_pdf" id="report_table">
                <FinanceTableMain
                  rows={this.gridRows}
                  wrapperClassName={"max_height"}
                  headers={this.props.financialMonths.showMonthly ? mMonths : yYears}
                  showRowHeaders={true}
                  useActual={true}
                  disabledEdit={!global.Modeliks.PERMISSIONS.Dashboards.restrictions.edit.active}
                  disabledMove={true}
                  applyItalic={true}
                  IsReport
                  unitDisplay
                  IsNumberFormatDisabled
                  useExpandCollapseStore={true}
                  ExpandCollapseID={ExpandCollapseTablesID.Dashboards.Actuals}
                  useDashboards={false}
                  useDateIndexes={true}
                  useCustomizableTable={true}
                  editableColor={true}
                  title={this.state.selectedSubsection.name}
                  // handeMoveRow={this.handeMoveRow}
                  onDataChanged={this.handleGridDataChanged}
                  // onDataChanged={this.handleGridDataChanged}
                />
              </div>
            )}
          </div>
        </React.Fragment>
      );
    }
  }
}
