import React, { useContext } from "react";
import { DashboardElementType } from "../../../components/constants/charts";
import FinancialStatements from "../../../pages/Secure/Dashboards/FinancialStatements";
import { MyContext } from "../../../pages/Secure/Dashboards/ManagementReports";
import CustomKPIMain from "../../../pages/Secure/Dashboards/CustomKPI";

const MainFinanceTable = (props) => {
  let { properties, DataManager } = useContext(MyContext);

  if (!DataManager) {
    DataManager = global.Modeliks;
  }

  if (!properties) {
    return null;
  }
  switch (props.type) {
    case DashboardElementType.financial_statements:
      return (
        <FinancialStatements
          {...properties}
          titleDate={true}
          defaultSelectedReport={props.defaultSelectedReport}
          defaultView={props.defaultView}
        />
      );
    case DashboardElementType.custom_kpis:
      let obj = {
        kpi: DataManager.CustomKPIStore.getItem(props.ID),
        props: { defaultView: props.defaultView },
      };
      if (obj.kpi) {
        return (
          <CustomKPIMain
            {...properties}
            titleDate={true}
            {...obj}
            CustomKPI={{ selectedKPI: obj.kpi }}
          />
        );
      }
  }
};

export default MainFinanceTable;
