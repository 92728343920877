import React from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import ButtonMui from "../../buttons/buttonMui/buttonMui";
import { DialogTypes } from "../../../data/Finance/constants";
import { WarningSimpleText } from "./components/WarningSimpleText";
import { WarningActions } from "./components/WarningActions";
import { WarningActionTitle } from "./components/WarningActionTitle";
import ExceededSubscriptionsLimit from "./components/ExceededSubscriptionsLimit";
import DowngradeSubscription from "./components/DowngradeSubscription";
import UpgradeSubscription from "./components/UpgradeSubscription";
import CancelSubscription from "./components/CancelSubscription";
import { SessionExpired } from "./components/SessionExpired";
import { AppUpdate } from "./components/AppUpdate";
import SimpleDialog2 from "./components/SimpleDialog2";
import CircleRefDrivers from "../../../pages/Secure/Financials/components/CalculatedDriver/CalculatedFormulaEdit/CircleRefDriversForm";
import SendRefferalEmailDialog from "./components/SendRefferalEmailDialog";
import "./components/appDialog.scss";
import NoAccountDialog from "./components/NoAccountDialog";

class AppDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      link: null,
      type: DialogTypes.WarningSimpleText,
    };

    if (this.props.functionObject) {
      this.props.functionObject.showDialog = this.showDialog;
    } else {
      global.Modeliks.showDialog = this.showDialog;
      global.Modeliks.showDialog.handleClose = this.handleClose;
      global.Modeliks.showDialog.IsActive = false;
    }
    this.message = null;
    this.disableExit = false;
    this.className = "";
    this.dialogSize = "sm";
  }

  showDialog = (
    msg,
    type = DialogTypes.WarningSimpleText,
    callBackClose,
    onAccept,
    title,
    disableExit = false,
    className = "",
    config = {},
  ) => {
    global.Modeliks.showDialog.IsActive = true;
    this.setState({ open: true, type: type });
    this.callBackClose = callBackClose;
    this.onAccept = onAccept;
    this.message = msg;
    this.disableExit = disableExit;
    this.className = className;
    this.title = title;
    this.config = config;
  };

  handleClose = () => {
    if (this.disableExit) {
      return;
    }
    this.setState({ open: false }, () => {
      if (this.callBackClose) {
        this.callBackClose();
      }
      global.Modeliks.showDialog.IsActive = false;
      this.className = "";
      this.callBackClose = null;
      this.onAccept = null;
      this.message = null;
    });
  };

  getDialogType = (type) => {
    switch (type) {
      case DialogTypes.SimpleDialog:
        return (
          <SimpleDialog2
            config={this.config}
            onCancel={this.handleClose}
            onAccept={() => {
              this.onAccept();
              this.handleClose();
            }}
          />
        );
      case DialogTypes.WarningSimpleText:
        return <WarningSimpleText message={this.message} />;
      case DialogTypes.WarningActions:
        return (
          <WarningActions
            message={this.message}
            onCancel={this.handleClose}
            onAccept={() => {
              this.onAccept();
              this.handleClose();
            }}
          />
        );
      case DialogTypes.WarningActionTitle:
        return (
          <WarningActionTitle
            message={this.message}
            onCancel={this.handleClose}
            title={this.title}
            onAccept={() => {
              this.onAccept();
              this.handleClose();
            }}
          />
        );

      case DialogTypes.ExceededSubscriptionsLimit:
        return <ExceededSubscriptionsLimit limit={this.message} />;
      case DialogTypes.DowngradeSubscription:
        return (
          <DowngradeSubscription
            subscriptionPlans={this.message}
            onCancel={this.handleClose}
            onAccept={(args) => {
              this.onAccept(args);
              this.handleClose();
            }}
          />
        );
      case DialogTypes.UpgradeSubscription:
        return (
          <UpgradeSubscription
            subscriptionPlans={this.message}
            onCancel={this.handleClose}
            onAccept={(args) => {
              this.onAccept(args);
              this.handleClose();
            }}
          />
        );
      case DialogTypes.CancelSubscription:
        return (
          <CancelSubscription
            message={this.message}
            onCancel={this.handleClose}
            onAccept={(args) => {
              this.onAccept(args);
              this.handleClose();
            }}
          />
        );
      case DialogTypes.SessionExpired:
        return <SessionExpired onAccept={this.handleClose} onCancel={this.handleClose}/>;
      case DialogTypes.AppUpdate:
        return <AppUpdate onAccept={this.onAccept}/>;

      case DialogTypes.RefErrorAllDrivers:
        return <CircleRefDrivers drivers={this.message}/>;
      case DialogTypes.ReferAFriend:
        return <SendRefferalEmailDialog onCancel={this.handleClose}/>;
      case DialogTypes.NoAccount:
        return <NoAccountDialog onAccept={this.handleClose}/>
    }
  };

  render() {
    if (this.state.open) {
      const type = this.getDialogType(this.state.type);
      return (
        <Dialog
          maxWidth={this.dialogSize}
          sx={{ ".MuiDialogContent-root": { padding: 0 } }}
          open={this.state.open}
          className={
            `company_limit_dialog ${this.state.type === DialogTypes.RefErrorAllDrivers ? "full_screen_dialog" : ""}` +
            this.className
          }
          onClose={this.handleClose}
        >
          {type}
        </Dialog>
      );
    }
  }
}

export default AppDialog;
