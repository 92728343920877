import React, { Fragment, useState, useEffect } from "react";
import "../HeaderTextEditor.scss";
import "../HeaderDefault.scss";
import { Spacing } from "../../components/IconButtons/SubMenuIcons";
import { Menu, MenuItem, SubMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";

class SpacingSubMenuComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lineSpacing: "",
      beforeItemSpacing: "",
      afterItemSpacing: "",
    };
  }

  componentDidMount() {
    if (this.props.objType && (this.props.objType === "table" || this.props.objType === "shape")) {
      this.setState({
        lineSpacing:
          this.props.style && this.props.style.lineHeight
            ? parseFloat(this.props.style.lineHeight)
            : 1,
      });
      this.setState({
        beforeItemSpacing:
          this.props.style && this.props.style.paddingTop
            ? parseFloat(this.props.style.paddingTop.replace("px", ""))
            : 0,
      });
      this.setState({
        afterItemSpacing:
          this.props.style && this.props.style.paddingBottom
            ? parseFloat(this.props.style.paddingBottom.replace("px", ""))
            : 0,
      });
    } else {
      this.setState({
        lineSpacing:
          this.props.rowStyle && this.props.rowStyle.lineHeight
            ? parseFloat(this.props.rowStyle.lineHeight)
            : 1,
      });
      this.setState({
        beforeItemSpacing:
          this.props.rowStyle && this.props.rowStyle.paddingTop
            ? parseFloat(this.props.rowStyle.paddingTop.replace("px", ""))
            : 0,
      });
      this.setState({
        afterItemSpacing:
          this.props.rowStyle && this.props.rowStyle.paddingBottom
            ? parseFloat(this.props.rowStyle.paddingBottom.replace("px", ""))
            : 0,
      });
    }
  }

  changeLineSpacing = (e) => {
    if (parseFloat(e.target.value) && parseFloat(e.target.value) !== 0) {
      this.setState({ lineSpacing: parseFloat(e.target.value) });
    } else {
      this.setState({ lineSpacing: 1 });
    }
  };

  changeBeforeItem = (e) => {
    if (e.target.value) {
      this.setState({ beforeItemSpacing: e.target.value });
    } else {
      this.setState({ beforeItemSpacing: "0px" });
    }
  };

  changeAfterItem = (e) => {
    if (e.target.value) {
      this.setState({ afterItemSpacing: e.target.value });
    } else {
      this.setState({ afterItemSpacing: "0px" });
    }
  };

  handleFocus = (event) => {
    if (event.target) {
      event.target.select();
    }
  };

  onSet = () => {
    if (this.props.objType && (this.props.objType === "table" || this.props.objType === "shape")) {
      this.props.onChange({
        lineHeight: this.state.lineSpacing,
        paddingTop: this.state.beforeItemSpacing + "px",
        paddingBottom: this.state.afterItemSpacing + "px",
      });
    } else {
      this.props.changeCustomSpacing(
        this.state.lineSpacing,
        this.state.beforeItemSpacing + "px",
        this.state.afterItemSpacing + "px",
      );
    }
  };

  render() {
    return (
      <SubMenu className="custom" label="Custom spacing">
        <div className="row_txt">Line spacing</div>
        <div className="row_txt">
          <input
            onFocus={this.handleFocus}
            value={this.state.lineSpacing}
            onChange={this.changeLineSpacing}
            type={"number"}
            className="input"
          />
        </div>
        <div className="row_txt">Line item spacing</div>
        <div className="row">
          <div>
            <div>Before</div>
            <input
              onFocus={this.handleFocus}
              value={this.state.beforeItemSpacing}
              onChange={this.changeBeforeItem}
              type={"number"}
              className="input"
            />
          </div>
          <div>
            <div>After</div>
            <input
              onFocus={this.handleFocus}
              value={this.state.afterItemSpacing}
              onChange={this.changeAfterItem}
              type={"number"}
              className="input"
            />
          </div>
        </div>

        <div className="row">
          <Button variant="text">Cancel</Button>
          <Button variant="text" onClick={this.onSet}>
            Set
          </Button>
        </div>
      </SubMenu>
    );
  }
}

export default SpacingSubMenuComponent;
