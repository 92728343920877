import React, { Fragment, useState } from "react";
// import RightMenuPortal from "./RightMenuPortal.js";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import InputBox from "../InputBoxComponent/InputBox";
import { FlipHorizontally, FlipVertically, Rotate90 } from "../IconButtons/SubMenuIcons";

function Position(props) {
  const [openPosition, setOpenPosition] = React.useState(false);

  const handleClickPosition = () => {
    if (openPosition) {
      setOpenPosition(false);
    } else {
      setOpenPosition(true);
      props.rightMenuFunc.closePostion = null;
      Object.values(props.rightMenuFunc).map((value) => {
        if (typeof value === "function") {
          value.call();
        }
      });
    }
  };

  const handleTopChange = (top) => {
    props.onChange(props.left, Number(top));
  };
  const handleLeftChange = (left) => {
    props.onChange(Number(left), props.top);
  };

  const closeMenu = () => {
    setOpenPosition(false);
  };

  props.rightMenuFunc.closePostion = closeMenu;

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      onKeyDown={(e) => {
        e.stopPropagation();
      }}
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
    >
      <ListItemButton id={"listItem"} onClick={handleClickPosition}>
        <ListItemText primary="Position" />
        {openPosition ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <div style={{ width: "230px", border: "1px solid #F0F1F7", marginLeft: "18px" }} />
      <Collapse in={openPosition} timeout="auto" unmountOnExit>
        <div className={"collapse"}>
          <div className={"listSubHeading"}>From Top Left</div>
          <div className={"listRow"}>
            <div className={"listRowElement"}>X</div>
            <div className={"listRowElement"}>Y</div>
          </div>
          <div className={"listRow"}>
            <div className={"listRowElement"}>
              <InputBox
                onChange={(e) => handleLeftChange(e.target.value)}
                value={props.left}
                onUpClick={() => handleLeftChange(props.left + 1)}
                onDownClick={() => handleLeftChange(props.left - 1)}
              />
            </div>
            <div className={"listRowElement"}>
              <InputBox
                onChange={(e) => handleTopChange(e.target.value)}
                value={props.top}
                onUpClick={() => handleTopChange(props.top + 1)}
                onDownClick={() => handleTopChange(props.top - 1)}
              />
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
}
export default Position;
