import "./AuthComponents.scss";

export const ValidationTextPassword = (props) => {
  return (
    <ul className="spspacv_validation_div">
      <span className="spspacv_span">Minimum password requirement: at least 6 characters.</span>
      <p className="spspacv_span">For a strong password we suggest to have:</p>
      <li>a combination of upper and lower case letters</li>
      <li>at least 1 number</li>
      <li>at least 1 special character (e.g., §, ~, %, ^, !, ?, &, @, #, etc.)</li>
    </ul>
  );
};
