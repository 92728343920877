const MxMath = {
  Max: (a, b) => Math.max(a, b),
  Min: (a, b) => Math.min(a, b),
  MaxOfArr: (...arr) => Math.max(...arr),
  MinOfArr: (...arr) => Math.min(...arr),
  IF: (a, b) => (a === 0 ? a : b),
  IFELSE: (condition, a, b) => (condition ? a : b),
  Pow: (a, b) => Math.pow(a, b),
  Negative: (value) => {
    if (!value) {
      return 0;
    } else {
      return Math.abs(value) * -1;
    }
  },
  Positive: (value) => {
    if (!value) {
      return 0;
    } else {
      return Math.abs(value);
    }
  },
  PositiveOrNegative: (value) => {
    if (!value) {
      return 0;
    } else {
      return value * -1;
    }
  },
  Round: (value) => Math.round(value * 100) / 100,
  FormulaRound: (decimalNum, value) => {
    return parseFloat(Number.parseFloat(value).toFixed(decimalNum));
  },
  FormulaRoundUp: (value) => {
    return Math.ceil(value);
  },
  FormulaRoundDown: (value) => {
    return Math.floor(value);
  },
  FormulaMin: (items) => {
    return Math.min(...items);
  },
  FormulaMax: (items) => {
    return Math.max(...items);
  },
  Ceil: (value) => Math.ceil(value),
  Median: (...arr) => {
    if (arr.length === 0) {
      return 0;
    }
    if (arr.length === 1) {
      return arr[0];
    }
    const sortedArr = arr.sort((a, b) => a - b);
    const middleIndex = Math.floor(sortedArr.length / 2);
    if (sortedArr.length % 2 === 0) {
      const leftNum = sortedArr[middleIndex - 1];
      const rightNum = sortedArr[middleIndex];
      return (leftNum + rightNum) / 2;
    } else {
      return sortedArr[middleIndex];
    }
  },
  Average: (items, field = null, shouldIgnoreNaN = true, shouldRound = true, decimalNumber = 4) => {
    let total = 0;
    let count = 0;
    items.forEach((item) => {
      const value = field ? item[field] : item;
      if (Number.isNaN(value) == false && value != null) {
        total += value;
        count++;
      }
    });

    if (shouldIgnoreNaN) {
      total = total / count;
    } else {
      total = total / items.length;
    }

    if (shouldRound) {
      let powerOften = Math.pow(10, decimalNumber);
      return Math.round(total * powerOften) / powerOften;
      // return MxMath.Round(total);
    } else {
      return total;
    }
  },
  Sum: (items, field = null) => {
    let total = 0;
    items.forEach((item) => {
      const value = field ? item[field] : item;
      if (Number.isNaN(value) == false && value != null) {
        total += value;
      }
    });
    return total;
  },
  calculation_CalculateChange: (item, prevItem) => {
    if (prevItem) {
      if (prevItem.Value == 0) {
        return null;
      } else if (item.Value == 0) {
        return -100;
      } else {
        if (prevItem) {
          const val = MxMath.Round(((item.Value - prevItem.Value) / prevItem.Value) * 100);
          if (prevItem.Value < item.Value) {
            return Math.abs(val);
          }
          return val;
        }
        return null;
      }
    }
    return null;
  },
};

window.MxMath = MxMath;

export default MxMath;
