import React from "react";
import styled from "styled-components";
import { Theme } from "../../theme/styles/theme";

const StyledDiv = styled.div`
  display: grid;
  grid-template-rows: 68px auto;
  height: 100%;
  grid-auto-columns: 100%;
  overflow: hidden;
  overflow: auto !important;
  min-width: 1024px !important;
`;

export const MainContainer = ({ ...props }) => {
  return <StyledDiv id={props.id}>{props.children}</StyledDiv>;
};
