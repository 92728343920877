import React from "react";
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-dragdata";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { DataTypes } from "../ChartComponents/DataTypes";
import "./RevenueChart.css";

import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

import {
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartTable from "../../components/ChartTable";

// const plugin = {
//     id: 'custom_canvas_background_color',
//     beforeDraw: (chart) => {
//         const ctx = chart.canvas.getContext('2d');
//         ctx.save();
//         ctx.globalCompositeOperation = 'destination-over';
//         ctx.fillStyle = 'black';
//         ctx.fillRect(0, 0, chart.width, chart.height);
//         ctx.restore();
//     }
// };

ChartJS.register(
  CategoryScale,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
);

class StackedBarChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sales: false,
      subscription: true,
      services: true,
      production: true,
      data: {
        labels: [],
        datasets: [],
      },
      options: this.buildOptions(this.props),
    };

    this.ref = null;
    this.defaultColor = "blue";
  }

  componentDidMount() {
    this.buildData();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps != this.props && !this.imgSrc) {
      this.ref.options = this.buildOptions(nextProps);
      this.buildData(nextProps);
      this.ref.update();
    }

    return true;
  }

  buildOptions = (nextProps) => {
    if (this.props.preview && this.props.businessPlan) {
      if (window.chartsForRender.indexOf(this.buildOptions) === -1) {
        window.chartsForRender.push(this.buildOptions);
      }
    }

    let options = {
      type: "bar",
      barRoundness: 0.3,
      maintainAspectRatio: nextProps.lockedAspect,
      animation: {
        duration: 1,
        onComplete: (animation) => {
          if (this.props.preview && this.props.businessPlan && this.ref) {
            this.imgSrc = this.ref.toBase64Image();
            this.forceUpdate(() => {
              window.amountOfChartsRendered++;
              global.Modeliks.NotifySubsctiptions("onChartRenderFinish");
            });
          }
        },
      },
      // title:{
      //     display:true,
      //     text:'Average Rainfall per month',
      //     fontSize:20
      // },
      layout: {
        padding: {
          top: nextProps.chartOptions.showTitle || nextProps.chartOptions.showSubtitle ? 5 : 45,
        },
      },
      scales: {
        x: {
          stacked: true,
          grid: {
            display: nextProps.chartOptions.xGrid ? nextProps.chartOptions.xGrid : false,
            drawBorder: nextProps.chartOptions.xGrid ? nextProps.chartOptions.xGrid : true,
          },
          ticks: {
            display: nextProps.chartOptions.showHorizontalAxis,
            color: "#889299",
            font: {
              weight: 400,
              style: "normal",
              size: nextProps.chartOptions.axisFontSize,
            },
          },
        },
        y: {
          stacked: true,
          grid: {
            display: nextProps.chartOptions.yGrid ? nextProps.chartOptions.yGrid : false,
            drawBorder: nextProps.chartOptions.xGrid ? nextProps.chartOptions.xGrid : false,
          },
          ticks: {
            display: nextProps.chartOptions.showVerticalAxis,
            color: "#889299",
            maxTicksLimit: 7,
            // stepSize: 250000,
            font: {
              weight: 400,
              style: "normal",
              size: nextProps.chartOptions.axisFontSize,
              lineHeight: "160%",
            },
            callback: function (value) {
              if (value >= 1000 && value < 1000000) {
                return value / 1000 + "k";
              } else if (value >= 1000000) {
                return value / 1000000 + "mil";
              } else {
                return value;
              }
            },
          },
        },
      },
      plugins: {
        tooltip: {
          titleFont: {
            size: 14,
          },
          bodyFont: {
            size: 14,
          },
        },
        datalabels: {
          anchor: "end",
          align: "end",
          formatter: function (value, ctx) {
            if (nextProps.chartOptions.showDataLabels) {
              if (nextProps.chartOptions.hideSeriesDataLabels) {
                const sumValue = ctx.chart.config.data.datasets.map((datapoint) => {
                  if (datapoint.data[ctx.dataIndex]) {
                    return datapoint.data[ctx.dataIndex];
                  } else {
                    return 0;
                  }
                });

                function totalSum(total, datapoint) {
                  return total + parseInt(datapoint);
                }

                let sum = sumValue.reduce(totalSum, 0);

                let datasets = ctx.chart.config.data.datasets;

                if (ctx.datasetIndex === datasets.length - 1) {
                  return (
                    (nextProps.chartOptions.dataType === "currency"
                      ? DataTypes[nextProps.chartOptions.dataType]
                      : "") +
                    sum.toLocaleString(undefined, {
                      minimumFractionDigits: nextProps.chartOptions.datalabelsDecimalPoints,
                      maximumFractionDigits: nextProps.chartOptions.datalabelsDecimalPoints,
                    }) +
                    (nextProps.chartOptions.dataType === "percent"
                      ? DataTypes[nextProps.chartOptions.dataType]
                      : "")
                  );
                } else {
                  return "";
                }
              } else {
                return (
                  (nextProps.chartOptions.dataType === "currency"
                    ? DataTypes[nextProps.chartOptions.dataType]
                    : "") +
                  value.toLocaleString(undefined, {
                    minimumFractionDigits: nextProps.chartOptions.datalabelsDecimalPoints,
                    maximumFractionDigits: nextProps.chartOptions.datalabelsDecimalPoints,
                  }) +
                  (nextProps.chartOptions.dataType === "percent"
                    ? DataTypes[nextProps.chartOptions.dataType]
                    : "")
                );
              }
            } else {
              return "";
            }
          },
          color: "#252525",
          font: {
            size: nextProps.chartOptions.dataLabelsFontSize,
            weight: "700",
            lineHeight: "17px",
          },
        },
        title: {
          display: nextProps.chartOptions.showTitle ? nextProps.chartOptions.showTitle : false,
          text:
            nextProps.chartOptions.title &&
            nextProps.chartOptions.titleAlign &&
            nextProps.chartOptions.titleAlign === "start"
              ? nextProps.chartOptions.title
              : nextProps.chartOptions.title
                ? nextProps.chartOptions.title
                : "",
          align: nextProps.chartOptions.titleAlign ? nextProps.chartOptions.titleAlign : "center",
          color: "#000000",
          padding: {
            bottom:
              nextProps.chartOptions.showSubtitle && nextProps.chartOptions.showSubtitle === true
                ? 0
                : 45,
          },
          font: {
            size: nextProps.chartOptions.titleFontSize,
          },
        },
        subtitle: {
          display: nextProps.chartOptions.showSubtitle
            ? nextProps.chartOptions.showSubtitle
            : false,
          text:
            nextProps.chartOptions.subtitle &&
            nextProps.chartOptions.titleAlign &&
            nextProps.chartOptions.titleAlign === "start"
              ? nextProps.chartOptions.subtitle
              : nextProps.chartOptions.subtitle
                ? nextProps.chartOptions.subtitle
                : "",
          align: nextProps.chartOptions.titleAlign ? nextProps.chartOptions.titleAlign : "center",
          font: {
            size: nextProps.chartOptions.subTitleFontSize,
          },
          padding: {
            bottom: 45,
          },
        },
        legend: {
          display: nextProps.chartOptions.displayLegend
            ? nextProps.chartOptions.displayLegend
            : false,
          position: nextProps.chartOptions.legendPosition
            ? nextProps.chartOptions.legendPosition
            : "bottom",
          align: "center",
          maxWidth: 1000,
          labels: {
            usePointStyle: true,
            fontSize: nextProps.chartOptions.legendFontSize,
          },
        },
      },
    };

    return options;
  };

  buildData = (nextProps = null) => {
    let labels = [];
    let datasets = [];
    let colors = this.props.colors;
    let rows = this.props.data;

    rows[0].forEach((label, index) => {
      if (index !== 0) {
        labels.push(label);
      }
    });

    rows.forEach((row, index) => {
      if (index !== 0) {
        if (index >= colors.length) {
          colors = [...colors, ...colors];
          this.props.changeColors(colors);
        }
        let obj = {
          label: "",
          backgroundColor: colors[index - 1],
          categoryPercentage: 1,
          barPercentage:
            nextProps && nextProps.chartOptions.gapWidth
              ? nextProps.chartOptions.gapWidth / 10
              : this.props.chartOptions.gapWidth
                ? this.props.chartOptions.gapWidth / 10
                : 0.3,
          // barThickness: this.props.chartOptions.gapWidth ? this.props.chartOptions.gapWidth : 80,
          // maxBarThickness: this.props.chartOptions.gapWidth ? this.props.chartOptions.gapWidth : 80,
          minBarLength: 2,
          borderRadius: index === rows.length - 1 ? 5 : 0,
          data: [],
        };
        row.forEach((data, indexData) => {
          if (indexData === 0) {
            obj.label = data;
          } else {
            let parsedData = parseFloat(data);
            if (parsedData % 1 === 0) {
              obj.data.push(parseInt(data));
            } else {
              obj.data.push(parsedData);
            }
          }
        });
        datasets.push(obj);
      }
    });

    this.state.data.labels = labels;
    this.state.data.datasets = datasets;
  };

  setRef = (ref) => {
    this.ref = ref;
  };

  render() {
    if (this.imgSrc) {
      return <img src={this.imgSrc} style={{ height: "100%", width: "100%" }} />;
    }
    return (
      <>
        <Bar
          type={"bar"}
          data={this.state.data}
          options={this.state.options}
          ref={this.setRef}
          style={{
            background: this.props.chartOptions.backgroundColor
              ? this.props.chartOptions.backgroundColor
              : "",
            border: `2px solid ${this.props.chartOptions.borderColor}`,
          }}
          responsive={true}
        />
      </>
    );
  }
}

StackedBarChart.config = {
  data: [
    ["", "label1", "label2", "label3"],
    ["ser1", "1", "2", "3"],
    ["ser2", "1", "2", "3"],
  ],
  colors: ["red", "green", "blue", "yellow"],
  dataGridConfig: {
    title: "blabla",
    subtitle: "blabla",
    canAddRows: true,
    canAddColumns: true,
    hasColumnHeaders: true,
    hasRowHeaders: true,
    rowSeries: false,
  },
  dataLalbelsConfig: {
    hideSeries: true,
    dataLabelsOptions: false,
  },
  legendAndGridlinesConfig: {
    hasGridlines: true,
  },
  gapWidthConfig: {
    haveGapWidth: true,
  },
  defaultConfig: {
    gapWidth: 3,
  },
};

StackedBarChart.propTypes = {
  data: PropTypes.array.isRequired,
  colors: PropTypes.array.isRequired,
};

export default StackedBarChart;
