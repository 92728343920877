import React, { Fragment, useState } from "react";
// import RightMenuPortal from "./RightMenuPortal.js";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import InputBox from "../InputBoxComponent/InputBox";
import { FlipHorizontally, FlipVertically, Rotate90 } from "../IconButtons/SubMenuIcons";
import MaterialSelect from "../Select/MaterialSelect";
import { FontSizeOptions } from "../../SlideObjects/ChartComponents/FontSizeOptions";

function ChartTitleAndSubtitle(props) {
  const [openChartTitleAndSubtitle, setOpenChartTitleAndSubtitle] = React.useState(false);

  const handleClickChartTitleAndSubtitle = () => {
    if (openChartTitleAndSubtitle) {
      setOpenChartTitleAndSubtitle(false);
    } else {
      setOpenChartTitleAndSubtitle(true);
      props.rightMenuFunc.closeTitleAndSubtitle = null;
      Object.values(props.rightMenuFunc).map((value) => {
        if (typeof value === "function") {
          value.call();
        }
      });
    }
  };

  const closeMenu = () => {
    setOpenChartTitleAndSubtitle(false);
  };

  props.rightMenuFunc.closeTitleAndSubtitle = closeMenu;

  const handleTopChange = (top) => {
    props.onChange(props.left, Number(top));
  };
  const handleLeftChange = (left) => {
    props.onChange(Number(left), props.top);
  };

  const handleShowTitle = (e) => {
    props.onPropsChange({ showTitle: e.target.checked });
  };

  const handleTitlePosition = (e) => {
    props.onPropsChange({ titleAlign: e.target.value });
  };

  const handleShowSubtitle = (e) => {
    props.onPropsChange({ showSubtitle: e.target.checked });
  };

  const handleTitleFontSizeChange = (e) => {
    props.onPropsChange({ titleFontSize: e.target.value });
  };

  const handleSubTitleFontSizeChange = (e) => {
    props.onPropsChange({ subTitleFontSize: e.target.value });
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      onKeyDown={(e) => {
        e.stopPropagation();
      }}
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
    >
      <ListItemButton id={"listItem"} onClick={handleClickChartTitleAndSubtitle}>
        <ListItemText
          primary="ChartTitleAndSubtitle
			"
        />
        {openChartTitleAndSubtitle ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <div style={{ width: "230px", border: "1px solid #F0F1F7", marginLeft: "18px" }} />
      <Collapse in={openChartTitleAndSubtitle} timeout="auto" unmountOnExit>
        <div className={"collapse"}>
          <div className={"listSubHeading"}>Chart title & subtitle</div>
          <div className={"listRow"}>
            <input
              type="checkbox"
              id={"chk"}
              style={{ margin: 0, marginRight: "4px" }}
              onChange={handleShowTitle}
              checked={props.showTitle}
            />
            <div className={"listSubHeading"}>Show chart title</div>
          </div>
          {/*<div className={'listRow'}>*/}
          {/*<label className={'listSubHeading'}>Title font size: </label>*/}
          {/*<MaterialSelect value={props.titleFontSize} options={FontSizeOptions} onChange={handleTitleFontSizeChange} />*/}
          {/*</div>*/}
          <div className={"listRow"}>
            <MaterialSelect
              value={"start"}
              options={[
                { value: "start", name: "Left" },
                { value: "middle", name: "Middle" },
                { value: "end", name: "Right" },
              ]}
              onChange={handleTitlePosition}
            />
          </div>
          <div className={"listRow"}>
            <input
              type="checkbox"
              style={{ margin: 0, marginRight: "4px" }}
              onChange={handleShowSubtitle}
              checked={props.showSubtitle}
            />
            <div className={"listSubHeading"}>Show subtitle</div>
          </div>
          <div className={"listRow listColumn"}>
            <label className={"listSubHeading"}>Title font size: </label>
            <MaterialSelect
              value={props.titleFontSize}
              options={FontSizeOptions}
              onChange={handleTitleFontSizeChange}
            />
          </div>
          <div className={"listRow listColumn"}>
            <label className={"listSubHeading"}>Subtitle font size: </label>
            <MaterialSelect
              value={props.subTitleFontSize}
              options={FontSizeOptions}
              onChange={handleSubTitleFontSizeChange}
            />
          </div>
        </div>
      </Collapse>
    </div>
  );
}
export default ChartTitleAndSubtitle;
