import CalculatedDriver from "../../CalculatedDriver/index";
import { DriverCategories, SpecialChar, UnitTypes } from "../../CalculatedDriver/constants";
import Financing from "../../Financing";
import { FinancingTypes } from "../constants";
import CalculatedDriver_Values from "../../CalculatedDriver/CalculatedDriver_Values";

class OtherLiability extends Financing {
  FinanceType = FinancingTypes.OtherLiability;
  InterestRate;

  constructor(db_record) {
    super(db_record);
    if (this.db_record) {
      this.clean();
      this.setDriversFromDataStorageSub();
      // this.buildPeriodsData()
    }
    this.SaveRevenue = this.Save;
    this.Save = this.SaveFinancing;
  }

  clean = (cleanDrivers = false) => {
    if (this.db_record && this.Totals) {
      this.Name = this.db_record.Name;
      this.FinanceType = this.db_record.FinanceType;
      this.ID_CompanyScenario = this.db_record.ID_CompanyScenario;
      this.InterestDuringGracePeriod = this.db_record.InterestDuringGracePeriod;
      this.TermType = this.db_record.TermType;
      if (cleanDrivers) {
        this.InterestRate.cleanValue();
        this.cleanDrivers();
      }
    }
  };

  static DriversDesc = Object.assign({
    Total: {
      driverName: "$Name",
      fieldName: "Totals",
      driverID: "total",
      unit: UnitTypes.Price,
      category: DriverCategories.LastPeriod,
    },
    OpenningBalance: {
      driverName: `Opening Balance${SpecialChar.DriverNameESCChar}$Name`,
      fieldName: "OpenningBalance",
      driverID: "openning_balance",
      unit: UnitTypes.Price,
      category: DriverCategories.FirstPeriod,
    },
    Receipts: {
      driverName: `Receipts${SpecialChar.DriverNameESCChar}$Name`,
      fieldName: "Receipts",
      driverID: "receipts",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    Repayments: {
      driverName: `Repayments${SpecialChar.DriverNameESCChar}$Name`,
      fieldName: "Repayments",
      driverID: "repayments",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    OtherLiabilityBalance: {
      driverName: `Other Liability Balance${SpecialChar.DriverNameESCChar}$Name`,
      fieldName: "OtherLiabilityBalance",
      driverID: "other_liability_balance",
      unit: UnitTypes.Price,
      category: DriverCategories.LastPeriod,
    },
    InterestExpense: {
      driverName: `Interest Expense${SpecialChar.DriverNameESCChar}$Name`,
      fieldName: "InterestExpense",
      driverID: "interest_expense",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
  });

  Save = (callBack, saveDrivers = true) => {
    if (!this.isNew && !this.IsCreated) {
      global.Modeliks.put(this.constructor.TableName, null, this, (res) => {
        if (saveDrivers) {
          this.SaveDrivers(callBack);
        } else {
          callBack();
        }
      });
    } else {
      global.Modeliks.put(this.constructor.TableName, null, this, (res) => {
        if (saveDrivers) {
          this.SaveDrivers(callBack);
        } else {
          callBack();
        }
      });
    }
  };

  SaveFinancing = (callBack, saveDrivers = true) => {
    this.SaveRevenue((newID) => {
      this.InterestRate.Save();
      if (saveDrivers) {
        this.SaveDrivers(callBack, this.ID);
      } else {
        callBack(this.ID);
      }
    }, false);
  };

  Delete = (callBack) => {
    this.DeleteFunc(this.ID, () => {
      global.Modeliks.del(
        global.Modeliks.Tables.Finance_CalculatedDriver_Values.TableName,
        {
          ID: this.getInterestRateID(),
          ID_CompanyScenario: global.Modeliks.CompanyScenarioInfo.ID,
        },
        (res) => {
          if (callBack) {
            callBack();
          }
        },
      );
    });
  };

  changeDriversName = () => {
    Object.keys(this.constructor.DriversDesc).forEach((key) => {
      const driverDesc = this.constructor.DriversDesc[key];
      if (this[driverDesc.fieldName]) {
        this[driverDesc.fieldName].DriverName = driverDesc.driverName.replace("$Name", this.Name);
      } else {
        console.log("driver not found", this, driverDesc, this[driverDesc.fieldName]);
      }
    });
  };
  setDriversFromDataStorageSub = () => {
    this.InterestRate = global.Modeliks.DriverValuesStore.getItem(this.getInterestRateID());
  };
  periodsData = {};

  buildPeriodsData = () => {
    const allPeriods = this.OpenningBalance.Values.map((c) => c.Date);
    allPeriods.forEach((period) => {
      this.periodsData[period.dateID] = {};
      Object.values(this.constructor.DriversDesc).forEach(
        (driver) =>
          (this.periodsData[period.dateID][driver.fieldName] = this[
            driver.fieldName
          ].getItemByDateSufix(period.sufix)),
      );
      this.periodsData[period.dateID].Date = period;
    });
  };

  getInterestRateID = () => {
    return `${OtherLiability.TableName}-${this.ID}-interest_rate`;
  };

  getMonthDatesAll = () => {
    return [...global.Modeliks.DateHelper.months];
  };

  getYearDatesAll = () => {
    return global.Modeliks.DateHelper.years_all;
  };

  createExtraPeriods = () => {
    // Object.values(this.constructor.DriversDesc).forEach(driver => {
    //     this[driver.fieldName].addMonths(0, true);
    // })

    this.buildPeriodsData();
  };

  createMonthsFormulas = () => {
    const months = this.getMonthDatesAll();
    this.Totals.Formula = `${this.OtherLiabilityBalance}+${this.InterestExpense}+${this.OpenningBalance}+${this.Receipts}+${this.Repayments}`;

    months.forEach((month) => {
      const prevMonthDate = months.find((c) => c.Order == month.Order - 1);
      const curMonth = this.periodsData[month.dateID];
      const prevMonth = prevMonthDate ? this.periodsData[prevMonthDate.dateID] : null;

      if (prevMonth) {
        curMonth.OpenningBalance.Formula = `${prevMonth.OtherLiabilityBalance}`;
      }
      curMonth.OtherLiabilityBalance.Formula = `${curMonth.OpenningBalance}+${curMonth.Receipts}-${curMonth.Repayments}`;
      curMonth.InterestExpense.Formula = `MxMath.Max(${curMonth.OtherLiabilityBalance} * ${this.InterestRate} / 12, 0)`;
      curMonth.Totals.Formula = `${curMonth.OtherLiabilityBalance}`;
    });
  };

  createYearsFormulas = () => {
    const years = this.getYearDatesAll();
    years.forEach((year) => {
      const curYear = this.periodsData[year.dateID];
      const prevYearDate = years.find((c) => c.Order === year.Order - 1);
      const prevYear = prevYearDate ? this.periodsData[prevYearDate.dateID] : null;
      const months = this.periodsData[year.dateID].Date.monthIndexes.map(
        (index) => this.periodsData[index],
      );
      const lastMonth = months[months.length - 1];
      const firstMonth = months[0];

      if (!year.Active) {
        curYear.OpenningBalance.Formula = `${firstMonth.OpenningBalance}`;
        curYear.Receipts.Formula = `MxMath.Sum([${months.map((c) => c.Receipts.ID_f).join(",")}])`;
        curYear.Repayments.Formula = `MxMath.Sum([${months.map((c) => c.Repayments.ID_f).join(",")}])`;
        curYear.OtherLiabilityBalance.Formula = `${lastMonth.OtherLiabilityBalance}`;
        curYear.InterestExpense.Formula = `MxMath.Sum([${months.map((c) => c.InterestExpense.ID_f).join(",")}])`;
        curYear.Totals.Formula = `${curYear.OtherLiabilityBalance}`;
      } else {
        curYear.OpenningBalance.Formula = `${prevYear.OtherLiabilityBalance}`;
        curYear.OtherLiabilityBalance.Formula = `${curYear.OpenningBalance}+${curYear.Receipts}-${curYear.Repayments}`;
        curYear.InterestExpense.Formula = `MxMath.Max(${curYear.OtherLiabilityBalance} * ${this.InterestRate}, 0)`;
        curYear.Totals.Formula = `${curYear.OtherLiabilityBalance}`;
      }
    });
  };

  setFinance = () => {
    this.buildPeriodsData();
    this.createMonthsFormulas();
    this.createYearsFormulas();
  };

  static convert_Finance = (finance) => {
    const newFinancing = new OtherLiability();
    newFinancing.ID = finance.ID;
    newFinancing.Name = finance.Name;
    newFinancing.IsCreated = true;
    newFinancing.ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
    newFinancing.Totals = CalculatedDriver.createDriverFromTable(
      newFinancing,
      Financing.DriversDesc.Total.driverID,
      UnitTypes.Price,
      DriverCategories.Sum,
    );
    newFinancing.InterestRate = new CalculatedDriver_Values(
      null,
      newFinancing.getInterestRateID(),
      null,
      UnitTypes.Percentage,
    );
    newFinancing.InterestRate.Value = 0;
    newFinancing.createDrivers();
    newFinancing.createExtraPeriods();

    return newFinancing;
  };
}

export default OtherLiability;
