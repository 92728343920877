import { useEffect, useState, useRef } from "react";
import "./previewScale.scss";
//cx3507

function BPPreviewScalePanel(props) {
  const scalePanelRef = useRef(null);
  const scaleParentPanelRef = useRef(null);
  const midContainerRef = useRef(null);

  const [panelSize, setPanelSize] = useState({
    width: undefined,
    scale: undefined,
  });

  const resizeBusinessPlanScale = () => {
    if (scalePanelRef.current && scaleParentPanelRef.current) {
      const pSize = scaleParentPanelRef.current.getBoundingClientRect();
      let ratio = pSize.width / props.width;
      setPanelSize({
        width: pSize.width,
        // height: ratio * props.height,
        scale: ratio,
      });
      window.panelScale = ratio;
    }
  };

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      resizeBusinessPlanScale();
    }

    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    if (props.scale) {
      props.scale.handleResize = handleResize;
    }
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  const panelStyle = {};
  const midPanelStyle = {};

  if (panelSize.width) {
    panelStyle.transform = "scale(" + panelSize.scale + ")";
  }

  if (props.fixedHeight) {
    Object.assign(midPanelStyle, {
      height: 1154 * window.panelScale,
    });
  }

  useEffect(() => {
    if (scalePanelRef.current && !props.fixedHeight) {
      midContainerRef.current.style.height =
        scalePanelRef.current.getBoundingClientRect().height + "px";
    }
  });

  return (
    <div
      id={"preview_scale_panel_container"}
      style={props.style}
      className={props.fixedHeight || props.splitPage ? "split_page" : ""}
      ref={scaleParentPanelRef}
    >
      <div
        className={`preview_mid_container ${props.fixedHeight ? "fixed_height" : ""}`}
        style={midPanelStyle}
        ref={midContainerRef}
      >
        <div
          className={`preview_se_panel ${[props.className ? props.className : ""]} ${props.fixedHeight ? "fixed_height" : ""}`}
          id="preview_se_scale_panel"
          ref={scalePanelRef}
          style={panelStyle}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
}

export default BPPreviewScalePanel;
