import React from "react";
import RevenueAddDialog from "./components/RevenueAddDialog";
import RevenueInfoDialog from "./components/RevenueInfoDialog";
import UnitSales from "../../../../data/Finance/Revenue/UnitSales";
import Revenue from "../../../../data/Finance/Revenue";
import BillableHours from "../../../../data/Finance/Revenue/BillableHours";
import { RevenueTypes } from "../../../../components/constants/finance";
import Subscription from "../../../../data/Finance/Revenue/Subscription";
import DialogLoader from "../../../../components/Loaders/DialogLoader/DialogLoader";
import { DialogTypes } from "../../../../data/Finance/constants";

class RevenueDetails extends React.Component {
  savingInProgress = false;

  constructor(props) {
    super(props);
    this.state = {
      revenue: props.revenue
        ? global.Modeliks.RevenuesStore.getItem(props.revenue)
        : this.newRevenue(),
      step: props.step !== null ? 1 : 0,
      TypeDialog: "general",
      deleteSubscriptions: [],
      loading: false,
    };

    this.changes = {
      new: {
        changedType: false,
        latestType: null,
      },
      existing: {
        changedType: false,
        latestType: null,
      },
    };

    this.isDeletedRecord = {
      delete: false,
    };
  }

  newRevenue = () => {
    Revenue.GetEmptyRevenue((revenue) => {
      if (revenue) {
        this.setState({ revenue });
      } else {
        this.setState({ loading: false }, () => this.props.onClose());
      }
    });
  };

  handleAddDialogNext = () => this.setState({ step: 1 });

  handleGeneralClick = () => this.setState({ step: 0 });

  changeNewRevenue = (revenue = this.state.revenue) => {
    let isSubs = false;
    this.cleanRevenueStreams();
    switch (revenue.RevenueType) {
      case RevenueTypes.RevenueOnly:
        revenue = Revenue.convert_Revenue(revenue);
        break;
      case RevenueTypes.UnitSales:
        revenue = UnitSales.convert_Revenue(revenue);
        break;
      case RevenueTypes.BillableHours:
        revenue = BillableHours.convert_Revenue(revenue);
        break;
      case RevenueTypes.Subscriptions:
        isSubs = true;
        Subscription.convert_Revenue(revenue, (newRevenue) => {
          this.setState({ revenue: newRevenue, step: 1, loading: false });
        });
        break;
    }

    if (revenue && revenue.hasOwnProperty("RevenueType")) {
      this.changes.new.latestType = revenue.RevenueType;
    }

    if (!isSubs) {
      this.setState({ revenue, step: 1, loading: false });
    }
  };

  changeExistingRevenue = () => {
    //todo: delete after create new with type
    const deleteDrivers = this.state.revenue.Totals.getDriversToDelete();
    if (deleteDrivers.length > 0) {
      console.log("delete this drivers", deleteDrivers);
    } else {
      this.state.revenue.Delete(() => {
        Revenue.GetEmptyRevenue((revenue) => {
          revenue.RevenueType = this.state.revenue.RevenueType;
          revenue.RevenueName = this.state.revenue.RevenueName;
          if (this.state.revenue.RevenueType === RevenueTypes.Subscriptions) {
            revenue.subscriptionplans = this.state.revenue.subscriptionplans;
          }
          this.changeNewRevenue(revenue);
        });
      });
    }
  };

  save = () => {
    this.state.revenue.changeDriversName();
    this.state.revenue.Save((RevenueID) => {
      this.props.onClose(true, true, true);
    });
  };

  changeRevenue = () => {
    if (this.state.revenue.isNew) {
      if (this.state.revenue.Totals) {
        if (this.changes.new.latestType === this.state.revenue.RevenueType) {
          this.handleAddDialogNext();
        } else {
          global.Modeliks.showDialog(
            "Please note that your previous changes will be lost if you change the type.",
            DialogTypes.WarningActions,
            () => {
              this.state.revenue.RevenueType = this.changes.new.latestType;
              this.forceUpdate();
            },
            this.changeNewRevenue,
            () => alert("no click 2"),
          );
        }
      } else {
        this.changes.new.changedType = false;
        this.setState({ loading: true }, this.changeNewRevenue);
      }
    } else {
      this.changes.existing.changedType = false;
      this.setState({ loading: true }, this.changeExistingRevenue);
    }
  };

  SaveRevenue = () => {
    this.state.revenue.changeDriversName();
    this.state.revenue.Save((RevenueID) => {
      this.props.onClose(true, true, true);
    });
  };
  //

  deleteSubscription = (callBack) => {
    this.state.deleteSubscriptions.forEach((sub, index) => {
      sub.Delete(() => {
        const drivers = sub.getAllDrivers();
        drivers.forEach((d) => global.Modeliks.DriversStore.remove(d));
        if (index + 1 === this.state.deleteSubscriptions.length) {
          this.save();
        }
      });
    });
  };

  saveRevenue = () => {
    if (this.savingInProgress) {
      return;
    }
    this.savingInProgress = true;
    this.props.showLoading(() => {
      if (this.state.deleteSubscriptions.length > 0) {
        this.deleteSubscription();
      } else {
        this.SaveRevenue();
      }
    });
  };

  cleanRevenueStreams = () => {
    const drivers = this.state.revenue.getAllDrivers();
    drivers.forEach((d) => {
      let children = d.getChildDrivers();
      if (children && children.length > 0) {
        children.forEach((c) => {
          if (c) {
            if (c.db_record) {
              c.cleanDriver();
            } else {
              global.Modeliks.DriversStore.getDeleteDrivers(d);
              global.Modeliks.DriversStore.remove(c);
            }
          }
        });
      }
      global.Modeliks.DriversStore.remove(d);
    });

    if (this.state.revenue.hasOwnProperty("children") && this.state.revenue.children.length > 0) {
      this.state.revenue.children.forEach((r) => {
        const childrenDrivers = r.getAllDrivers();
        childrenDrivers.forEach((d) => {
          global.Modeliks.DriversStore.getDeleteDrivers(d);
          global.Modeliks.DriversStore.remove(d);
        });
      });
    }
  };

  onCloseAddDialog = () => {
    if (!this.state.revenue.db_record) {
      this.cleanRevenueStreams();
    } else {
      const drivers = this.state.revenue.getAllDrivers();
      drivers.forEach((d) => global.Modeliks.DriversStore.getDeleteDrivers(d));
      if (this.state.revenue.hasOwnProperty("children") && this.state.revenue.children.length > 0) {
        this.state.revenue.children.forEach((r) => {
          const childrenDrivers = r.getAllDrivers();
          childrenDrivers.forEach((d) => global.Modeliks.DriversStore.getDeleteDrivers(d));
        });
      }
      this.state.revenue.clean(true, this.state.deleteSubscriptions);
    }
    this.props.onClose(true, true, this.isDeletedRecord.delete);
  };

  addSubscriptionPlan = () => {
    this.state.revenue.addNewSubscriptionPlan((revenue) => {
      // revenue.setPortfolioFormulas();
      this.setState({ revenue, loading: false, step: 1 });
    });
  };

  setSubscription = () => {
    this.setState({ loading: true }, this.addSubscriptionPlan);
  };

  removeNewPlans = () => {
    this.state.revenue.addNewSubscriptionPlan((revenue) => {
      this.setState({ revenue, loading: false, step: 1 });
    });
  };

  setNewSubscription = () => {
    this.setState({ loading: true }, this.removeNewPlans);
  };

  render() {
    if (!this.state.revenue || this.state.loading) {
      return <DialogLoader />;
    }

    return (
      <>
        <RevenueAddDialog
          changes={this.changes}
          deleteSubscriptions={this.state.deleteSubscriptions}
          open={this.state.step === 0}
          onClose={this.onCloseAddDialog}
          revenue={this.state.revenue}
          isDeletedRecord={this.isDeletedRecord}
          onChangeRevenue={this.changeRevenue}
          onNextClick={this.handleAddDialogNext}
          setNewSubscription={this.setNewSubscription}
          setSubscription={this.setSubscription}
        />
        {this.state.step === 1 && this.state.revenue && (
          <RevenueInfoDialog
            totalDriver={this.props.totalDriver}
            revenue={this.state.revenue}
            tabIndex={this.props.step}
            addRevenueStream={this.handleAddDialogNext}
            open={this.state.step === 1}
            onClose={this.onCloseAddDialog}
            onGeneralClick={this.handleGeneralClick}
            saveRevenue={this.saveRevenue}
          />
        )}
      </>
    );
  }
}

export default RevenueDetails;
