import React from "react";
import InfoDialog from "../../../../../components/dialogs/financials/InfoDialog/InfoDialog";
import InfoDialogActions from "../../../../../components/dialogs/financials/InfoDialog/InfoDialogActions";
import PropTypes from "prop-types";
import FinanceDetailsTabTitles from "./FinanceDetailsTabTitles";
import SingleFinanceTab from "./SingleFinanceTab";
import CalculatedDriver from "../CalculatedDriver/CalculatedDriver";
import DialogContent from "@mui/material/DialogContent";

class FinanceDetailsDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: props.tabs[props.tabIndex ? props.tabIndex : 0],
      CalculatedDriver: false,
      Formula: [],
    };

    if (props.enableDrivers || this.hasSignupsTab()) {
      this.getDrivers();
    }

    this.gridPeriodHeaders = [
      ...global.Modeliks.DateHelper.months,
      ...global.Modeliks.DateHelper.gridPeriodHeaders_Years(),
    ];
  }

  hasSignupsTab = () => {
    return this.props.multiple && this.state.selectedTab.isTabSignups;
  };

  onTabChange = (tab, index, tabName, callBack) => {
    if (this.props.onTabChange && tabName !== "general") {
      const canChange = this.props.onTabChange(tab);
      if (canChange === false) {
        return;
      }
    }

    if (tabName == "general" || index == -1) {
      this.state.selectedTab = this.props.tabs[0];
      this.props.onGeneral();
    } else {
      this.setState({ selectedTab: tab, CalculatedDriver: false });
      if (this.props.onTabChange) {
        this.props.onTabChange(tab);
      }
    }
    if (callBack) {
      callBack(true);
    }
  };

  onPrevTab = () => {
    const curIndex = this.props.tabs.indexOf(this.state.selectedTab) - 1;
    if (curIndex == -1) {
      this.onTabChange(null, curIndex);
    } else {
      this.onTabChange(this.props.tabs[curIndex], curIndex);
    }
  };

  onNextTab = () => {
    const curIndex = this.props.tabs.indexOf(this.state.selectedTab) + 1;
    this.onTabChange(this.props.tabs[curIndex], curIndex);
  };

  Driver = (driver, isTabSignups = false) => {
    return Object.assign({
      key: driver.ID,
      title: driver.DriverName,
      subtitle: driver.DriverName,
      field: "Value",
      growthEnabled: true,
      isTabSignups,
      checkedGrowth: false,
      changeEnabled: false,
      isDriver: true,
      allowDisplayActuals:
        this.props.tabs.length > 0 ? this.props.tabs[0].allowDisplayActuals : false,
      showActuals: false,
      dataGridRow: {
        data: driver,
        drivers: [],
      },
      chart: {
        monthlyChartData: {
          data: driver.Values.filter((c) => global.Modeliks.DateHelper.months.indexOf(c.Date) > -1),
          labels: global.Modeliks.DateHelper.headers_Months(),
        },
        yearlyChartData: {
          data: driver.Values.filter(
            (c) => global.Modeliks.DateHelper.years_all.indexOf(c.Date) > -1,
          ),
          labels: global.Modeliks.DateHelper.headers_Years(true),
        },
      },
    });
  };

  recalculate_tabs = () => {
    if (this.props.enableDrivers || this.hasSignupsTab()) {
      const DefaultTABS = this.props.tabs.filter((d) => !d.hasOwnProperty("isDriver"));
      this.props.tabs.splice(0, this.props.tabs.length, ...DefaultTABS);
      this.props.tabs.forEach((tab) => {
        tab.dataGridRow.drivers = [];
      });
      this.getDrivers();
    }
  };

  getDrivers = (driver = false, i = 0) => {
    let tabCounter = i;
    for (tabCounter; tabCounter < this.props.tabs.length; tabCounter++) {
      if (this.hasSignupsTab()) {
        if (
          !this.props.tabs[tabCounter].dataGridRow.data.IsSimple &&
          this.props.tabs[tabCounter].isTabSignups
        ) {
          let ChildDrivers = this.props.tabs[tabCounter].dataGridRow.data.getChildDrivers();
          if (ChildDrivers && ChildDrivers.length > 0 && !ChildDrivers[0].ID.includes("quantity")) {
            this.childDrivers(this.props.tabs[tabCounter], ChildDrivers, tabCounter);
          }
        }
      } else {
        if (!this.props.tabs[tabCounter].dataGridRow.data.IsSimple) {
          let ChildDrivers = this.props.tabs[tabCounter].dataGridRow.data.getChildDrivers();
          this.childDrivers(this.props.tabs[tabCounter], ChildDrivers, tabCounter);
        }
      }
    }
  };

  childDrivers = (tab, ChildDrivers, tabCounter) => {
    ChildDrivers.forEach((driver) => {
      if (driver.DriverName !== "Growth") {
        if (driver.IsSimple) {
          if (!tab.dataGridRow.drivers.find((d) => d.ID === driver.ID)) {
            tab.dataGridRow.drivers.push(driver);
          }
        } else {
          if (!tab.dataGridRow.drivers.find((d) => d.ID === driver.ID)) {
            tab.dataGridRow.drivers.push(driver);
          }
          if (
            !this.props.tabs.find((d) => d.dataGridRow.data.ID === driver.ID) &&
            driver.DriverName !== "Number Of New Employees (delta)" &&
            driver.Ref_Table === global.Modeliks.Tables.Finance_CalculatedDrivers.TableName &&
            !driver.hasOwnProperty("GrowthDriver")
          ) {
            tabCounter++;
            this.props.tabs.splice(tabCounter, 0, this.Driver(driver, tab.isTabSignups));
          }
        }
      }
    });
  };

  setFormula = (formula) => {
    if (this.state.selectedTab.dataGridRow.data.hasOwnProperty("GrowthDriver")) {
      this.state.selectedTab.dataGridRow.data.removeGrowth();
    }

    if (global.Modeliks.showDialog.IsActive) {
      global.Modeliks.showDialog.handleClose();
    }

    if (formula !== null) {
      this.state.selectedTab.dataGridRow.data.setFormula(formula);
    } else {
      this.state.selectedTab.dataGridRow.data.removeFormula();
      // if(this.state.selectedTab.isTabSignups){
      //     this.props.revenue.setFormulasAfterRemovingSetFormulaOnSignsUps();
      // }
    }
    this.recalculate_tabs();
    this.openCalculatedDriver();
  };

  openCalculatedDriver = (close) => {
    if (close === true && this.state.selectedTab && this.state.selectedTab.hasOwnProperty("key")) {
      this.recalculate_tabs();
      this.state.selectedTab = this.props.tabs.find(
        (t) =>
          t.dataGridRow.data.Formula &&
          t.dataGridRow.data.Formula.indexOf(this.state.selectedTab.key) !== -1,
      );
    }
    if (this.state.selectedTab.isTabSignups && this.props.revenue) {
      if (!this.props.revenue.hasQuantitySplit()) {
        this.props.revenue.useQuantitySplit();
      }
    }

    this.setState({ CalculatedDriver: !this.state.CalculatedDriver });
  };

  onSave = () => {
    if (!this.checkDivideWithZero()) {
      this.props.onSave();
    } else {
      global.Modeliks.showDialog("You cannot divide by zero. Please check your input!");
    }
  };

  checkDivideWithZero = () => {
    let hasZeroInput = false;

    this.props.tabs.forEach((tab, index) => {
      const dataGridRow = tab.dataGridRow;
      if (dataGridRow.data.Formula && dataGridRow.data.Formula.includes("|/|")) {
        const drivers = dataGridRow.drivers;
        if (drivers.length > 0) {
          const findDivider = drivers.find((d) => dataGridRow.data.Formula.includes(`|/${d.ID_f}`));
          if (findDivider) {
            this.gridPeriodHeaders.forEach((d) => {
              const val = findDivider.getItemByDateSufix(d.sufix);
              if (val.Value > 0) {
              } else {
                hasZeroInput = true;
                val.error = [];
                val.error.push(dataGridRow.data.Ref_Field);
                val.error.push(findDivider.Ref_Field);
              }
            });

            if (hasZeroInput) {
              this.onTabChange(tab);
            }
          }
        }
      }
    });

    return hasZeroInput;
  };

  render() {
    if (this.state.selectedTab == null) {
      return null;
    }

    return (
      <>
        <InfoDialog open={this.props.open}>
          <FinanceDetailsTabTitles
            id={"singlefinancetabtitle_" + this.state.selectedTab.dataGridRow.data.ID}
            title={this.props.title}
            selectedTab={this.state.selectedTab.dataGridRow.data}
            tabs={this.props.tabs}
            onChange={this.onTabChange}
            saveDisabled={!this.props.saveEnabled}
            onClose={this.props.onClose}
            onSave={this.props.onSave}
            CalculatedDriver={this.state.CalculatedDriver}
          />
          <DialogContent className="pscf_single_finance_tab" dividers>
            <SingleFinanceTab
              CalculatedDriver={this.state.CalculatedDriver}
              openCalculatedDriver={this.openCalculatedDriver}
              onTabChange={this.onTabChange}
              tabs={this.props.tabs}
              key={"singlefinancetab_" + this.state.selectedTab.dataGridRow.data.ID}
              tab={this.state.selectedTab}
              Driver={this.Driver}
              chartTitle={this.state.selectedTab.chartTitle}
              title={this.state.selectedTab.title}
              selectedTab={this.state.selectedTab}
              subtitle={this.state.selectedTab.subtitle}
              dataGridRow={this.state.selectedTab.dataGridRow}
              headers={this.props.headers}
              addTab={this.addTab}
              onDataChanged={this.props.onDataChanged}
              enableDrivers={this.props.enableDrivers}
              multiple={this.props.multiple}
              hasDriver={
                this.state.selectedTab.dataGridRow.drivers.length > 0 &&
                !this.state.selectedTab.dataGridRow.data.IsSimple
              }
            />

            {this.state.CalculatedDriver && (
              <CalculatedDriver
                enableClose={true}
                closeCalcululatedDriver={this.openCalculatedDriver}
                totalDriver={this.props.totalDriver}
                CalculatedDriver={this.state.CalculatedDriver}
                key={"calculated_driver_" + this.state.selectedTab.dataGridRow.data.ID}
                id={this.state.selectedTab}
                drivers={this.state.selectedTab.dataGridRow.drivers}
                formula={this.state.selectedTab.dataGridRow.data.Formula}
                setFormula={this.setFormula}
                field={this.state.selectedTab.field}
                tab={this.state.selectedTab.selectedTab}
                title={this.state.selectedTab.title}
                multiple={this.props.multiple && !this.hasSignupsTab()}
              />
            )}
          </DialogContent>
          <InfoDialogActions
            CalculatedDriver={this.state.CalculatedDriver}
            saveDisabled={!this.props.saveEnabled}
            onSave={this.onSave}
            // onSave={() => alert(' there is 0 ')}
            setFormula={this.setFormula}
            nextDisabled={this.nextDisabled}
            onNextClick={this.onNextTab}
            onPreviousClick={this.onPrevTab}
            isCreatedStream={this.state.selectedTab.dataGridRow.data.db_record}
            isNextDisabled={
              this.props.tabs.indexOf(this.state.selectedTab) === this.props.tabs.length - 1
            }
            isPrevDisabled={
              this.props.tabs.indexOf(this.state.selectedTab) <= this.props.tabs.length - 1
            }
          />
        </InfoDialog>
      </>
    );
  }
}

FinanceDetailsDialog.propTypes = {
  open: PropTypes.bool,
  tabs: PropTypes.array,
  monthlyChartData: PropTypes.object,
  yearlyChartData: PropTypes.object,
  headers: PropTypes.array,
  saveEnabled: PropTypes.bool,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  onGeneral: PropTypes.func,
  onDataChanged: PropTypes.func,
  enableDrivers: PropTypes.bool,
  multiple: PropTypes.bool,
};

FinanceDetailsDialog.defaultProps = {
  enableDrivers: true,
  multiple: false,
};

export default FinanceDetailsDialog;
