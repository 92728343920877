import React, { Component } from "react";
import PropTypes from "prop-types";
import ResizableRect from "./ResizableRect";

class AsyncResizer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: this.props.width,
      height: this.props.height,
      top: this.props.top,
      left: this.props.left,
    };

    this.lastStyle = { ...this.state };
    this.lastShiftKey = null;
    this.lastType = null;

    this.isResizing = false;
    this.resizerRef = null;

    this.resizerFunctionsObject = {};

    this.diff_width = 0;
    this.diff_height = 0;
  }

  setDiffs = () => {
    if (this.resizerRef) {
      const clientRect = this.resizerRef.getBoundingClientRect();
      this.setDiff("width", clientRect);
      this.setDiff("height", clientRect);
    }
  };

  setDiff = (key, clientRect) => {
    if (clientRect[key] / window.panelScale - this.props[key] > 0) {
      this["diff_" + key] = clientRect[key] / window.panelScale - this.props[key];
    } else {
      this["diff_" + key] = 0;
    }
  };

  getRealValue = (key) => {
    if (this.isResizing) {
      return this.state[key];
    }

    return this.props[key];
  };

  getSize = (type, size, delimiter = ["r", "l"], key) => {
    let newSize = size;
    if (delimiter.find((c) => c == type)) {
      return size + this["diff_" + key];
    }
    return Math.round(newSize);
  };

  handleResize = (style, isShiftKey, type) => {
    let { top, left, width, height } = style;
    top = Math.round(top);
    left = Math.round(left);
    width = this.getSize(type, width, ["r", "l"], "width");
    height = this.getSize(type, height, ["t", "b"], "height");
    const newProps = {
      top: type.indexOf("t") !== -1 ? top : this.state.top,
      left: type.indexOf("l") !== -1 ? left : this.state.left,
      width,
      height,
    };

    if (width > 0 && height > 0) {
      this.setState(newProps);
      this.lastStyle = newProps;
      this.lastShiftKey = isShiftKey;
      this.lastType = type;
    }
  };

  handleResizeStart = () => {
    this.setDiffs();
    this.isResizing = true;
    this.props.onResizeStart();
    const clientRect = this.resizerRef.getBoundingClientRect();
    this.setState({
      width: clientRect.width / window.panelScale,
      height: clientRect.height / window.panelScale,
      top: this.props.top,
      left: this.props.left,
    });
  };

  handleResizeEnd = () => {
    this.isResizing = false;
    this.props.onResize(this.lastStyle, this.lastShiftKey, this.lastType);
    this.props.onResizeEnd();
  };

  getClassName = (className) => {
    if (this.isResizing) {
      return className + " async";
    }
    return className + " hidden";
  };

  setRef = (ref) => {
    this.resizerRef = ref;
  };

  render() {
    const {
      left,
      top,
      borderMove,
      width,
      height,
      rotateAngle,
      scaleX,
      scaleY,
      zIndex,
      aspectRatio,
      minHeight,
      moveHandle,
      onRotateStart,
      onRotate,
      onRotateEnd,
      onDragStart,
      onDrag,
      onDragEnd,
      className,
      onDoubleClick,
      onDelete,
      onKeyDown,
      publishMode,
      preview,
      children,
    } = this.props;

    return (
      <>
        <ResizableRect
          left={this.getRealValue("left")}
          top={this.getRealValue("top")}
          borderMove={false}
          width={this.getRealValue("width")}
          height={this.getRealValue("height")}
          rotateAngle={rotateAngle}
          scaleX={scaleX}
          scaleY={scaleY}
          zIndex={zIndex}
          aspectRatio={aspectRatio}
          minHeight={0}
          onResizeStart={this.handleResizeStart}
          onResize={this.handleResize}
          onResizeEnd={this.handleResizeEnd}
          className={this.getClassName(className)}
          publishMode={publishMode}
          parentFunctionsObject={this.resizerFunctionsObject}
          preview={preview}
          // zoomable='n, w, s, e, nw, ne, se, sw'
        />
        <ResizableRect
          left={left}
          top={top}
          borderMove={borderMove}
          width={width}
          height={minHeight > height ? minHeight : height}
          rotateAngle={rotateAngle}
          scaleX={scaleX}
          scaleY={scaleY}
          zIndex={zIndex}
          minWidth={width}
          aspectRatio={aspectRatio}
          minHeight={0}
          moveHandle={moveHandle}
          onRotateStart={onRotateStart}
          onRotate={onRotate}
          onRotateEnd={onRotateEnd}
          onResizeStart={this.handleResizeStart}
          onResizeEnd={this.handleResizeEnd}
          onDragStart={onDragStart}
          onDrag={onDrag}
          onDragEnd={onDragEnd}
          className={className + " async-auto"}
          onDoubleClick={onDoubleClick}
          onDelete={onDelete}
          onKeyDown={onKeyDown}
          publishMode={publishMode}
          preview={preview}
          reference={this.setRef}
          resizerFunctionsObject={this.resizerFunctionsObject}
          zoomable="n, w, s, e, nw, ne, se, sw"
        >
          {children}
        </ResizableRect>
      </>
    );
  }
}

AsyncResizer.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  top: PropTypes.number,
  left: PropTypes.number,
};

export default AsyncResizer;
