import React from "react";
import PropTypes from "prop-types";
import Mx_NumInput from "../../../../../../components/actions/Mx_NumInput";
import { Label } from "../../../../../../components/typography/label/Label";
import FinanceTable from "../../../../../../components/tables/FinanceTable";
import "./CalculatedDriverGrowth.scss";
import MaterialSelect from "../../../../../../components/actions/SelectM";
import { UnitTypes } from "../../../../../../data/Finance/CalculatedDriver/constants";
import { Vector } from "../../../../../../components/dividers/Vector";
import CalculatedDriverGrowthInputs from "./CalculatedDriverGrowthInputs";
import Mx_Tooltip from "../../../../../../components/icons/Mx_Tooltip_Click";

class CalculatedDriverGrowthDataGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: new Date().getTime(),
      growthValue:
        global.Modeliks.DateHelper.months[0].Year +
        "-" +
        (global.Modeliks.DateHelper.months[0].Month + 1),
      growthMonths: [
        ...global.Modeliks.DateHelper.months,
        ...global.Modeliks.DateHelper.gridPeriodHeaders_Years().filter((d) => d.Active),
      ],
    };
  }

  getMonth = (Header) => {
    return this.state.growthMonths.find((m) => m.Header === Header);
  };

  changeToDate = (e, driver) => {
    if (e && e.target.value) {
      const Order = this.getMonth(e.target.value);
      driver.changeGrowthDriverStartingDate(Order);
      this.setState({ key: new Date().getTime() }, () => this.props.onDataChanged());
    }
  };

  changeSingleValue = (value, driverValue) => {
    driverValue.Value = value;
    this.props.onDataChanged();
  };

  render() {
    const {
      title,
      growthDriverTableID,
      dataGridRow,
      onScroll,
      headers,
      onDataChanged,
      hasChildren,
      childrenData,
      showRowHeaders,
      className,
    } = this.props;

    return (
      <div className="psffcc_growth_container">
        {hasChildren ? (
          <div className="psffcc_multi_div">
            {childrenData.map((children) => {
              const findGrowthOrderValue = children.data.Values.find(
                (d) => d.Unit === UnitTypes.GrowthValue,
              );

              return (
                <div
                  className="psffcc_growth_child_container"
                  key={`children_growth-${children.ID}`}
                >
                  <div className="psffcc_growth_label">
                    <Label color={"black"} size="label3" label={children.header} />
                    <Vector color={true} />
                  </div>

                  <div className="psffcc_growth_inputs_container">
                    <div className="psffcc_input_date">
                      <div className="psffcc_input_label_divider">
                        <Label
                          className="psffcc_title_input"
                          color={"black"}
                          size="label3"
                          label="Start period"
                        />
                        <Mx_Tooltip
                          description={
                            "Select the date when the forecast should begin. For example, if you are forecasting revenue that will only start in the third month of your forecast period, select the month when you will start earning this revenue."
                          }
                        />
                      </div>
                      <MaterialSelect
                        valuePlaceholder="Header"
                        minWidth="120px"
                        valueDescription="Header"
                        onChange={(e, s) => this.changeToDate(e, children.driver)}
                        options={this.state.growthMonths}
                        value={findGrowthOrderValue.DateHeader}
                      />
                    </div>
                    <div className="psffcc_input_width">
                      <div className="psffcc_input_label_divider">
                        <Label
                          className="psffcc_title_input"
                          color={"black"}
                          size="label3"
                          label="Start value"
                        />
                        <Mx_Tooltip
                          description={
                            "Input the value of the Start period. For example, if you are forecasting Unit Prices, input here the unit price that you expect to charge in the month that you already selected in Start Period. \n" +
                            "In all following fields in the table, input the percent growth you expect in comparison to the previous period."
                          }
                        />
                      </div>
                      <Mx_NumInput
                        isDialogInputSmall={true}
                        singleInput={true}
                        selected={true}
                        isMultipleSelected={false}
                        isEditable={true}
                        label={"label"}
                        name={"label"}
                        data={findGrowthOrderValue.Value}
                        onChange={(e) => this.changeSingleValue(e, findGrowthOrderValue)}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <CalculatedDriverGrowthInputs
            changeToDate={this.changeToDate}
            changeSingleValue={this.changeSingleValue}
            dataGridRow={dataGridRow}
            growthMonths={this.state.growthMonths}
          />
        )}

        <div
          className="dialog_table_container"
          id={`${growthDriverTableID}${dataGridRow.GrowthDriver.ID}`}
          onScroll={onScroll}
        >
          <FinanceTable
            key={`growthFinanceTable_sft - ${this.state.key}`}
            indexTab={5000}
            rows={hasChildren ? childrenData : [{ data: dataGridRow.GrowthDriver }]}
            useDateIndexes={true}
            className={className}
            useCustomizableTable={true}
            showRowHeaders={showRowHeaders}
            title={title}
            headers={headers}
            onDataChanged={onDataChanged}
          />
        </div>
      </div>
    );
  }
}

CalculatedDriverGrowthDataGrid.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  isHidden: PropTypes.bool,
};

CalculatedDriverGrowthDataGrid.defaultProps = {
  label: "% growth",
  onChange: PropTypes.func,
  isHidden: true,
};

export default CalculatedDriverGrowthDataGrid;
