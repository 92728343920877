const RefBaseTypes = {
  TTM: {
    value: 0,
    key: "TTM",
  },
  LY: {
    value: 1,
    key: "Last Year",
  },
  CY: {
    value: 2,
    key: "Current Year",
  },
  LFY: {
    value: 3,
    key: "Last Forecast Year",
  },
};

export { RefBaseTypes };
