import React from "react";
import "@szhsin/react-menu/dist/index.css";
import "../../FinancialStatements/index.scss";
import "./CustomKPIMenu.scss";
import { Menu, MenuItem } from "@szhsin/react-menu";
import {
  CopyIcon,
  DeleteIcon,
  EditIcon,
  Tick,
} from "../../../../../SlideEditor/components/components/menus/LeftMenu/components/Icons/LeftMenuIcons";
import { default as MuiButton } from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import {
  ArrowDown,
  IconPlus,
  SmallPlusIcon,
  TrashCan,
} from "../../../../../components/icons/svgIcons";
import Button from "../../../../../components/actions/Button";
import ConfirmDeleteDialog from "../../../../../components/dialogs/ConfirmDeleteDialog";
import {
  ReusableMenu,
  ReusableMenuItem,
} from "../../../../../components/menus/ReusableMenu/ReusableMenu";
import "../../../../../SlideEditor/components/components/menus/LeftMenu/components/SectionTab.scss";
import ButtonMui from "../../../../../components/buttons/buttonMui/buttonMui";
import Tooltip from "@mui/material/Tooltip";

const getDashButton = (title, icon, btnFunction) => {
  if (global.Modeliks.PERMISSIONS.Dashboards.restrictions.edit.active) {
    return (
      <Tooltip placement={"right"} title={title}>
        <IconButton
          disableFocusRipple
          className="mpscc_menu_item_icon"
          style={{ paddingLeft: "5px", paddingRigth: "5px" }}
          onClick={(e) => {
            e.stopPropagation();
            btnFunction && btnFunction();
          }}
        >
          {icon}
        </IconButton>
      </Tooltip>
    );
  }
};

const DashboardButtons = (props) => {
  const [name, setName] = React.useState(props.kpi.Name);
  const [editName, setEditName] = React.useState(false);

  const SaveKPI = () => {
    props.kpi.Name = name;
    props.kpi.Save(() => {
      setEditName(false);
      props.refresh();
    });
  };

  if (editName) {
    return (
      <div
        className={"input_container no_padding mpsccc_menu_item"}
        onClick={(e) => e.stopPropagation()}
      >
        <input
          className={"section_name_input section_name_input mpscc_menu_item_name"}
          value={name}
          onKeyDown={(e) => e.stopPropagation()}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
        {getDashButton("Save", <Tick />, () => {
          if (props.kpi.Name != name) {
            SaveKPI();
          } else {
            setEditName(false);
          }
        })}
      </div>
    );
  }
  return (
    <div className={"input_container no_padding mpsccc_menu_item "}>
      {name}
      <div>
        {getDashButton("Rename", <EditIcon />, () => setEditName(true))}
        {getDashButton("Duplicate", <CopyIcon />, () => props.createKPI(props.kpi))}
        {getDashButton("Delete", <DeleteIcon />, () => props.onChangeKPI(props.kpi, true))}
      </div>
    </div>
  );
};

export default class CustomKPIMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      key: new Date().getTime(),
      section: null,
    };

    this.disabledEdit = !global.Modeliks.PERMISSIONS.Dashboards.restrictions.edit.active;
  }

  handleDelete = () => {
    this.props.onChangeKPI(this.state.section, true);
    this.setState({ section: null });
  };

  createKPI = (kpi) => {
    this.props.createNewKPI((newkpi) => {
      newkpi.Name = kpi.Name;
      newkpi.Totals.Formula = kpi.Totals.Formula;
      newkpi.Totals.Values.forEach((v) => {
        if (v.Date && !v.Date.Active) {
          v.Formula = kpi.Totals.getItemByDateSufix(v.Date.sufix).Formula;
        }
      });
      this.props.createCustomKPI(newkpi);
    });
  };

  getColor = () => {
    if (global.Modeliks.PERMISSIONS.Dashboards.restrictions.edit.active) {
      return {
        color: "#01AFFD",
      };
    }
    return {};
  };

  render() {
    if (this.props.IsKPI) {
      const { CustomKPI, onOpenDialog, onChangeKPI } = this.props;

      return (
        <React.Fragment>
          <ReusableMenu
            arrow
            menuButton={
              <Tooltip
                arrow
                enterNextDelay={500}
                enterDelay={500}
                title={CustomKPI.selectedKPI && CustomKPI.selectedKPI.Name}
              >
                <MuiButton className="psdf_menu_section_button">
                  <span>{CustomKPI.selectedKPI && CustomKPI.selectedKPI.Name}</span> <ArrowDown />
                </MuiButton>
              </Tooltip>
            }
            menuItems={[
              ...CustomKPI.allCustomKPI.map(
                (c, index) =>
                  new ReusableMenuItem(
                    (
                      <DashboardButtons
                        createKPI={this.createKPI}
                        onChangeKPI={onChangeKPI}
                        kpi={c}
                        refresh={(k) => {
                          onChangeKPI(c);
                        }}
                      />
                    ),
                    () => onChangeKPI(c),
                    c.ID === CustomKPI.selectedKPI.ID,
                    [],
                    null,
                    null,
                    this.disabledEdit,
                    true,
                  ),
              ),
              new ReusableMenuItem(
                (
                  <div style={{ display: "flex", alignItems: "center", ...this.getColor() }}>
                    Add new KPI List
                  </div>
                ),
                () => onOpenDialog(),
                false,
                [],
                null,
                null,
                this.disabledEdit,
                true,
              ),
            ]}
          />
          <ConfirmDeleteDialog
            handleDeleteConfirm={this.handleDelete}
            handleClose={() => this.setState({ section: false })}
            open={this.state.section}
            text="Are you sure you want to delete the KPI list?"
          />
        </React.Fragment>
      );
    } else {
      const { addIndicator, addCustomIndicator } = this.props;

      return (
        <React.Fragment>
          <Menu
            arrow
            menuClassName="psdf_menu"
            menuButton={
              <ButtonMui
                width={160}
                disabled={this.disabledEdit}
                variant={"contained"}
                label={"Add KPI"}
                endIcon={<IconPlus />}
              />
            }
          >
            <MenuItem onClick={addCustomIndicator} className="mpsccc_menu_item_add_new small">
              <span>{"Add Custom Indicator"}</span>
              <SmallPlusIcon className="mpscc_menu_item_trash_icon" />
            </MenuItem>
            <MenuItem onClick={addIndicator} className="mpsccc_menu_item_add_new small">
              <span>{"Add Existing Indicator"}</span>
              <SmallPlusIcon className="mpscc_menu_item_trash_icon" />
            </MenuItem>
          </Menu>
        </React.Fragment>
      );
    }
  }
}
