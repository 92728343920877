import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import { Search } from "../IconButtons/SubMenuIcons";
import "./SearchInput.scss";
import Button from "@mui/material/Button";

export default function SearchInput(props) {
  const [values, setValues] = React.useState({
    link: "",
  });
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const toLink = () => {
    let value =
      values.link.indexOf("https://") > -1 || values.link.indexOf("http://") > -1
        ? values.link
        : "//" + values.link;
    let selectedObject = props.getSelectedObject();

    selectedObject.forEach((p) => {
      if (Object.prototype.toString.call(p) == "[object Array]") {
        p.forEach((obj) => {
          obj.href = value;
        });
      } else {
        p.href = value;
      }
    });

    props.groupStruct();
    props.handleLink();
  };
  return (
    <div className="menu_item_search">
      <FormControl variant="outlined">
        <OutlinedInput
          id="outlined-adornment-link"
          value={values.link}
          onChange={handleChange("link")}
          endAdornment={
            <InputAdornment position="end">
              <Search />
            </InputAdornment>
          }
          inputProps={{
            "aria-label": "link",
          }}
          placeholder={"Search or paste link"}
        />
      </FormControl>
      <Button variant="text" onClick={toLink}>
        Set
      </Button>
    </div>
  );
}
