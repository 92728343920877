import React, { Component } from "react";
import CircleCheckboxM from "../../../components/actions/CircleCheckboxM";

export default class CheckBoxWrapperComponent extends Component {
  constructor(props) {
    super(props);
  }

  onChange = (e) => {
    this.props.onChange(e, () => {
      this.forceUpdate();
    });
  };

  render() {
    return (
      <CircleCheckboxM
        label={this.props.label}
        checked={this.props.object[this.props.option][this.props.optionKey]}
        onChange={this.onChange}
      />
    );
  }
}
