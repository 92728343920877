import CalculatedDriver from "../CalculatedDriver";
import { CostOfSalesTypes, CostOfSalesGeneral } from "../../../components/constants/costofsales";
import { RevenueTypes } from "../../../components/constants/finance";
import Revenue from "../Revenue/index";
import { DriverCategories, UnitTypes, SpecialChar } from "../CalculatedDriver/constants";
import datastructure from "../../datastructure.json";
import FinanceGeneric from "../FinanceGeneric";
import Personnel from "../Personnel/index";
import Expense from "../Expense/index";
import { WorkingCapitalTypes } from "../WorkingCapital/constants";
import { PERMISSIONS } from "../../Permissions/Permissions";
import { DialogTypes, ExpenseValues } from "../constants";

class CostSales extends FinanceGeneric {
  Name = "";
  ID_Revenue = CostOfSalesGeneral.General;
  CostMetric = CostOfSalesTypes.UnitSales;
  UnitSales;
  UnitCost;

  constructor(db_record = null) {
    super(db_record);
    if (this.db_record) {
      this.clean();
    }
  }

  clean = (cleanDrivers = false) => {
    if (this.db_record && this.Totals) {
      this.Name = this.db_record.Name;
      this.CostMetric = this.db_record.CostMetric;
      this.ID_Revenue = this.db_record.ID_Revenue;
      this.ID_CompanyScenario = this.db_record.ID_CompanyScenario;
      if (cleanDrivers) {
        this.cleanDrivers();
      }
    }
  };

  static DriversDesc = {
    Total: {
      driverName: "$Name",
      fieldName: "Totals",
      driverID: "total",
    },
    UnitSales: {
      driverName: `Unit Sales${SpecialChar.DriverNameESCChar}$Name`,
      fieldName: "UnitSales",
      driverID: "unit_sales",
    },
    UnitCost: {
      driverName: `Unit Cost${SpecialChar.DriverNameESCChar}$Name`,
      fieldName: "UnitCost",
      driverID: "unit_cost",
    },
  };

  static TableName = datastructure.Finance_CostSales.TableName;

  changeDriversName = () => {
    Object.keys(this.constructor.DriversDesc).forEach((key) => {
      const driverDesc = this.constructor.DriversDesc[key];
      if (this[driverDesc.fieldName]) {
        if (!this[driverDesc.fieldName].DriverName) {
          this[driverDesc.fieldName].DriverName = driverDesc.driverName.replace("$Name", this.Name);
        } else {
          if (driverDesc.driverID === "total") {
            this[driverDesc.fieldName].DriverName = driverDesc.driverName.replace(
              "$Name",
              this.Name,
            );
          }
        }
      } else {
        console.log("driver not found", this, driverDesc, this[driverDesc.fieldName]);
      }
    });
  };
  setDriverName = () => {
    if (this.UnitCost) {
      this.UnitCost.DriverName = (
        global.Modeliks.RevenuesStore.getItem(this.ID_Revenue)
          ? global.Modeliks.RevenuesStore.getItem(this.ID_Revenue).RevenueType === "revenue-only"
          : false
      )
        ? "Cost of Goods Sold"
        : this.CostMetric === "totals"
          ? this.ID_Revenue === "general-cost"
            ? "Cost of Goods Sold as % of total revenue"
            : `Unit Cost as % of ${
                !isNaN(this.ID_Revenue)
                  ? global.Modeliks.RevenuesStore.getItem(this.ID_Revenue).RevenueName
                  : ""
              }`
          : "Unit Cost";
    }
  };
  Save = (callBack, saveDrivers = true) => {
    if (!this.isNew && !this.IsCreated) {
      global.Modeliks.put(this.constructor.TableName, null, this, (res) => {
        if (saveDrivers) {
          this.SaveDrivers(callBack);
        } else {
          callBack();
        }
      });
    } else {
      global.Modeliks.put(this.constructor.TableName, null, this, (res) => {
        global.Modeliks.DriversStore.createDriversFrom(
          this.ID,
          this.constructor.TableName,
          this.Totals,
          this,
        );
        if (saveDrivers) {
          this.setDriverName();
          this.SaveDrivers(callBack);
        } else {
          callBack();
        }
      });
    }
  };

  getYearDatesAll = () => {
    return global.Modeliks.DateHelper.years_all;
  };

  getMonthDatesAll = () => {
    return [...global.Modeliks.DateHelper.months, ...global.Modeliks.DateHelper.months_after];
  };

  setTotalFormula = (secondDriver, selectedRevenue) => {
    if (secondDriver) {
      if (
        this.ID_Revenue !== CostOfSalesGeneral.General &&
        this.CostMetric === CostOfSalesTypes.UnitSales &&
        selectedRevenue.RevenueType === RevenueTypes.RevenueOnly
      ) {
        this.Totals.setFormula(`${this.UnitCost.ID_f}`);
      } else {
        this.Totals.setFormula(`${secondDriver.ID_f}*${this.UnitCost.ID_f}`);
      }

      const totalYears = global.Modeliks.DateHelper.years_before;
      totalYears.forEach((year) => {
        const curYearUnitCost = this.UnitCost.getItemByDateSufix(year.sufix);
        const curYearSecondDriver = secondDriver.getItemByDateSufix(year.sufix);
        const curYearTotal = this.Totals.getItemByDateSufix(year.sufix);

        if (curYearUnitCost.Unit === UnitTypes.Percentage) {
          curYearTotal.Formula = `(${curYearSecondDriver}*${curYearUnitCost}) * 100`;
        } else {
          curYearTotal.Formula = `(${curYearSecondDriver}*${curYearUnitCost})`;
        }
      });

      const years = this.getYearDatesAll();
      years.forEach((year) => {
        if (!year.Active) {
          this.Totals.getItemByDateSufix(year.sufix).Formula =
            `${year.months.map((m) => this.Totals.getItemByDateSufix(m.sufix)).join("+")}`;

          if (
            this.ID_Revenue !== CostOfSalesGeneral.General &&
            this.CostMetric === CostOfSalesTypes.UnitSales &&
            selectedRevenue.RevenueType === RevenueTypes.RevenueOnly
          ) {
            this.UnitCost.getItemByDateSufix(year.sufix).Formula =
              `${year.months.map((m) => this.UnitCost.getItemByDateSufix(m.sufix)).join("+")}`;
          } else {
            if (this.CostMetric === CostOfSalesTypes.Totals) {
              this.UnitCost.getItemByDateSufix(year.sufix).Formula =
                `(${this.Totals.getItemByDateSufix(year.sufix)} / ${secondDriver.getItemByDateSufix(year.sufix)}) * 100`;
            } else {
              this.UnitCost.getItemByDateSufix(year.sufix).Formula =
                `${this.Totals.getItemByDateSufix(year.sufix)} / ${secondDriver.getItemByDateSufix(year.sufix)}`;
            }
          }
        }
      });
    } else {
      this.Totals.setFormula(this.UnitCost.ID_f);
    }
  };

  setRevenue = () => {
    let secondDriver = null;
    let selectedRevenue = null;
    const UnitType =
      this.CostMetric == CostOfSalesTypes.Totals ? UnitTypes.Percentage : UnitTypes.Price;
    if (this.ID_Revenue === CostOfSalesGeneral.General) {
      if (this.CostMetric == CostOfSalesTypes.UnitSales) {
        if (!this.UnitSales) {
          this.UnitSales = CalculatedDriver.createDriverFromTable(
            this,
            CostSales.DriversDesc.UnitSales.driverID,
            UnitTypes.Units,
            DriverCategories.Sum,
            this.Name ? `Unit Sales${SpecialChar.DriverNameESCChar}${this.Name}` : "",
          );
        }
        secondDriver = this.UnitSales;
      } else {
        secondDriver = Revenue.getRevenueTotals();
      }
    } else {
      selectedRevenue = global.Modeliks.RevenuesStore.getItem(this.ID_Revenue);
      if (this.CostMetric == CostOfSalesTypes.Totals) {
        secondDriver = selectedRevenue.Totals;
      } else {
        switch (selectedRevenue.RevenueType) {
          case RevenueTypes.UnitSales:
            secondDriver = selectedRevenue.UnitSales;
            break;
          case RevenueTypes.BillableHours:
            secondDriver = selectedRevenue.BillableHours;
            break;
          case RevenueTypes.Subscriptions:
            // secondDriver = Revenue.Totals;
            secondDriver = selectedRevenue.Quantity;
            break;
          case RevenueTypes.RevenueOnly:
            secondDriver = selectedRevenue.Totals;
            // nothing, should be connected only
            break;
          default:
            break;
        }
      }
    }

    if (!this.UnitCost) {
      this.UnitCost = CalculatedDriver.createDriverFromTable(
        this,
        CostSales.DriversDesc.UnitCost.driverID,
        UnitType,
        DriverCategories.Average,
        this.Name ? `Unit Cost${SpecialChar.DriverNameESCChar}${this.Name} ` : "",
      );
    } else {
      this.setDriverName();
    }

    if (this.UnitCost.UnitType !== UnitType) {
      this.UnitCost.changeDriverTypeAndCategory(UnitType, this.UnitCost.DriverCategory);
      this.setDriverName();
    }

    const TotalDriverName = this.Name
      ? `Unit Sales${SpecialChar.DriverNameESCChar}${this.Name}`
      : "";
    const UnitSalesDriverName = this.Name
      ? `Unit Sales${SpecialChar.DriverNameESCChar}${this.Name}`
      : "";

    if (this.Totals.DriverName !== TotalDriverName) {
      this.Totals.DriverName = TotalDriverName;
    }

    if (this.UnitSales.DriverName !== UnitSalesDriverName) {
      this.UnitSales.DriverName = UnitSalesDriverName;
    }

    this.setTotalFormula(secondDriver, selectedRevenue);
  };

  Delete = (callBack) => {
    this.DeleteFunc(this.ID, () => {
      global.Modeliks.CostSaleStore.removeItemByID(this.ID);
      this.getAllDrivers().forEach((d) => global.Modeliks.DriversStore.removeItemByID(d.ID));
      const workingCapitalDrivers = global.Modeliks.WorkingCapitalStore.filter(
        (d) => d.Type !== WorkingCapitalTypes.AccountReceivable,
      );
      workingCapitalDrivers.forEach((driver, index) => {
        driver.cleanWorkingCapital(this.ID, CostSales.TableName, () => {
          if (callBack && workingCapitalDrivers.length === index + 1) {
            callBack();
          }
        });
      });
    });
  };

  SaveNew = (callBack) => {
    let limit =
      global.Modeliks.CostSaleStore.length >=
      global.Modeliks.PERMISSIONS.Financials.restrictions.MaxStreamsCount.TotalCostOfSales;
    if (limit) {
      global.Modeliks.showDialog(
        `
            You have reached the maximum number of [${global.Modeliks.PERMISSIONS.Financials.restrictions.MaxStreamsCount.TotalCostOfSales}] streams in Cost of Goods Sold. 
            Please reorganize your streams in Cost of Goods Sold. \n Click outside of the box to continue with your work.`,
        DialogTypes.WarningSimpleText,
        () => {
          callBack(null);
        },
      );
    } else {
      global.Modeliks.post(this.constructor.TableName, this, (res) => {
        this.ID = res.id;
        this.Totals = CalculatedDriver.createDriverFromTable(
          this,
          CostSales.DriversDesc.Total.driverID,
          UnitTypes.Price,
          DriverCategories.Sum,
        );
        this.UnitSales = CalculatedDriver.createDriverFromTable(
          this,
          CostSales.DriversDesc.UnitSales.driverID,
          UnitTypes.Units,
          DriverCategories.Sum,
          this.Name ? `Unit Sales${SpecialChar.DriverNameESCChar}${this.Name}` : "",
        );
        this.UnitCost = CalculatedDriver.createDriverFromTable(
          this,
          CostSales.DriversDesc.UnitCost.driverID,
          this.CostMetric === CostOfSalesTypes.Totals ? UnitTypes.Percentage : UnitTypes.Price,
          DriverCategories.Average,
          this.Name ? `Unit Cost${SpecialChar.DriverNameESCChar}${this.Name} ` : "",
        );

        callBack(this);
      });
    }
  };

  static getEmptyCost = (callBack) => {
    const cost = CostSales.createNewEmpty();
    cost.SaveNew((record) => {
      return callBack(record);
    });
  };

  static createNewEmpty = () => {
    const newCostSales = new CostSales();
    newCostSales.ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
    newCostSales.Name = "";
    newCostSales.CostMetric = CostOfSalesTypes.UnitSales;
    newCostSales.ID_Revenue = CostOfSalesGeneral.General;
    return newCostSales;
  };

  static createNew = (cost = false) => {
    const newCostSales = new CostSales();
    newCostSales.ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
    if (cost) {
      newCostSales.ID = cost.ID;
      newCostSales.Name = cost.Name;
      newCostSales.ID_Revenue = cost.ID_Revenue;
      newCostSales.CostMetric = cost.CostMetric;
    }

    newCostSales.Totals = CalculatedDriver.createDriverFromTable(
      newCostSales,
      CostSales.DriversDesc.Total.driverID,
      UnitTypes.Price,
      DriverCategories.Sum,
    );

    return newCostSales;
  };

  static getCostSalesGrouped = () => {
    const driverID = "cost_group_totals";
    let driver = global.Modeliks.DriversStore.getItem(
      CostSales.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        CostSales.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.Sum,
        "Direct Costs",
        true,
        true,
        false,
      );
      driver.isExpense = true;
    }

    if (global.Modeliks.CostSaleStore.length > 0) {
      const formula = global.Modeliks.CostSaleStore.map((c) => c.Totals.ID_f).join("+");
      driver.setFormula(formula);
    }

    return driver;
  };

  static getCostSalesTotals = () => {
    const driverID = 0;
    let driver = global.Modeliks.DriversStore.getItem(`${CostSales.TableName}-${driverID}-totals`);
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        CostSales.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.Sum,
        "Cost of Goods Sold",
        true,
        true,
        false,
      );
      driver.isExpense = true;
    }

    const DirectLaborTotals = Personnel.getPersonnelDirectLaborTotals();
    const DirectCostTotals = Expense.getExpensesDirectCostTotals();
    const costs = global.Modeliks.DriversStore.filter(
      (d) =>
        d.Ref_Table === CostSales.TableName && d.Ref_Field === "total" && d.IsTemporary === false,
    );
    const drivers = costs.concat(DirectLaborTotals).concat(DirectCostTotals);
    if (drivers.length > 0) {
      const lastCheck = drivers.filter((d) => d);
      if (lastCheck && lastCheck.length > 0) {
        driver.setFormula_Sum(drivers);
        driver.Formula = lastCheck.map((d) => d.ID_f).join("+");
        // driver.setFormula_Sum(lastCheck)
      }
    }
    return driver;
  };

  static getCostSalesMarginFromRevenue = () => {
    const driverID = "cost_margin_from_revenue";
    let driver = global.Modeliks.DriversStore.getItem(
      CostSales.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        CostSales.TableName,
        driverID,
        "totals",
        UnitTypes.Percentage,
        DriverCategories.Average,
        "Cost of Goods Sold Margin",
        true,
        true,
        false,
      );
    }

    driver.setFormula(
      `(${Revenue.getRevenueTotals()} - ${CostSales.getCostSalesTotals()}) / ${Revenue.getRevenueTotals()} * 100`,
    );

    return driver;
  };

  static getCostSaleAndExpenses = () => {
    const driverID = "cost_and_expense_total_d";
    let driver = global.Modeliks.DriversStore.getItem(
      CostSales.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        CostSales.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.Sum,
        "CostSale and Expense Totals",
        true,
        false,
        false,
      );
      driver.isExpense = true;
    }
    const totalDrivers = global.Modeliks.DriversStore.filter(
      (d) =>
        (d.Ref_Table === "Finance_Expenses" || d.Ref_Table === CostSales.TableName) &&
        d.Ref_Field === "total",
    );
    if (totalDrivers.length > 0) {
      driver.setFormula_Sum(totalDrivers);
    }

    return driver;
  };

  static getCostSaleAndDirectExpenses = () => {
    const driverID = "cost_and_direct_ex_driver";
    let driver = global.Modeliks.DriversStore.getItem(
      CostSales.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        CostSales.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.Sum,
        "CostSale and Direct Expense Totals",
        true,
        false,
        false,
      );
    }
    driver.Formula = null;
    const totalDrivers = global.Modeliks.CostSaleStore.map((d) => d.Totals).concat(
      Expense.getExpensesDirectCostTotals().getChildDrivers(),
    );
    if (totalDrivers.length > 0) {
      const lastCheck = totalDrivers.filter((d) => d);
      if (lastCheck && lastCheck.length > 0) {
        driver.setFormula_Sum(lastCheck);
      }
    } else {
      driver.removeFormula();
    }

    return driver;
  };
}

export default CostSales;
