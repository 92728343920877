import React, { useEffect } from "react";
import { Dialog, DialogContent, DialogTitle, Tabs, Tab } from "@mui/material";
import PropTypes from "prop-types";
import "./instructionsPopup.scss";
import { Menu, MenuItem } from "@szhsin/react-menu";
import { useLocation } from "react-router-dom";

class InstructionalPopup extends React.Component {
  content = global.Modeliks.InstructionsContent;
  currentContent = {};
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      route: this.props.route ? this.props.route : window.location.pathname,
    };

    this.setActionButton();
  }

  setActionButton = () => {
    let btnProps = {};
    if (this.props.data_key === "video_links") {
      Object.assign(btnProps, {
        onClick: () => {
          this.initializeComponent();
          this.setState({
            open: true,
            tabKey: this.getTabKey(this.getSecondElement(Object.keys(this.currentContent))),
          });
        },
      });
    }
    this.ActionButton = React.cloneElement(this.props.actionButton, btnProps);
  };

  getSecondElement = (arr) => {
    let startIndex = 1;
    if (this.props.hasOwnProperty("startIndex")) {
      startIndex = this.props.startIndex;
    }
    if (arr.length > startIndex) {
      return arr[startIndex];
    }
    return arr[0];
  };

  initializeComponent = () => {
    this.currentContent = {};
    this.getTabCombinations()
      .flatMap((c) => {
        if (this.content[c]) {
          return this.content[c][this.props.data_key];
        }
        return null;
      })
      .filter((c) => !!c)
      .forEach((c) => Object.assign(this.currentContent, c));
  };

  handleChange = (e) => {
    this.setState({ tabKey: e.target.id });
  };

  getTabKey = (key) => {
    return `t_${key}`;
  };

  getTabCombinations = () => {
    let route = window.location.pathname;
    if (this.props.route) {
      route = this.props.route;
    }
    let routeParts = route.split("/");
    let routes = [];
    let initialRoute = "";
    routeParts.forEach((r) => {
      if (r) {
        if (initialRoute) {
          initialRoute += "/";
        }
        initialRoute += r;
        routes.push(initialRoute);
      }
    });
    return routes.filter((c) => c.length);
  };
  getTabs = () => {
    return this.getTabCombinations()
      .flatMap((c) => {
        if (this.content[c]) {
          return this.content[c][this.props.data_key].map((instruction, index) =>
            this.getTab(instruction, index),
          );
        }
        return null;
      })
      .filter((c) => !!c);
  };

  getTab = (tabObj, index) => {
    const name = Object.keys(tabObj)[0];
    if (this.props.data_key === "video_links") {
      return (
        <Tab
          label={name}
          value={this.getTabKey(name)}
          key={"tab_" + index}
          id={this.getTabKey(name)}
        />
      );
    }
    return (
      <MenuItem
        style={{ padding: "0.375rem 1.1rem" }}
        key={"tab_" + index}
        onClick={() => window.open(tabObj[name], "_blank")}
      >
        {name}
      </MenuItem>
    );
  };

  getVideoElement = (tabObj, index) => {
    let tabKey = Object.keys(tabObj)[0];
    if (this.props.data_key === "video_links") {
      return (
        <Tab
          label={<iframe src={tabObj[tabKey]} allowFullScreen />}
          value={tabKey}
          key={"tab_" + index}
        />
      );
    }
  };

  getKeyFromTabKey = (tabKey) => {
    return tabKey.split("_")[1];
  };

  setLocation = () => {
    if (window.location.pathname !== this.state.route && !this.props.route) {
      this.setState({ route: window.location.pathname });
    }
  };

  getDialogContent = () => {
    if (this.props.data_key === "video_links") {
      if (this.currentContent) {
        return (
          <div className={"iframe_container"}>
            <iframe
              src={this.currentContent[this.getKeyFromTabKey(this.state.tabKey)]}
              allowFullScreen
            />
          </div>
        );
      }
    }
  };

  getDialog = () => {
    if (this.props.data_key === "video_links") {
      if (this.state.open) {
        return (
          <>
            {this.ActionButton}
            <Dialog
              className={"instructions_popup"}
              onClose={() => this.setState({ open: false })}
              open={true}
            >
              <DialogTitle>
                <Tabs
                  value={this.state.tabKey}
                  onChange={this.handleChange}
                  variant="fullWidth"
                  aria-label="scrollable auto tabs example"
                >
                  {this.getTabs()}
                </Tabs>
              </DialogTitle>
              <DialogContent>{this.getDialogContent()}</DialogContent>
            </Dialog>
          </>
        );
      }
      return this.ActionButton;
    }
    let tabs = this.getTabs();
    return (
      <>
        <MenuItemsHook onUpdateParent={this.setLocation} />
        {tabs.length ? (
          <Menu
            arrow
            align={"center"}
            menuStyle={{ padding: "0.375rem 0", zIndex: 999 }}
            menuButton={this.ActionButton}
          >
            {tabs}
          </Menu>
        ) : (
          ""
        )}
      </>
    );
  };

  getSlider = () => {
    let tabContents = this.getTabCombinations().flatMap(
      (c) => this.content[c][this.props.data_key],
    );
    return (
      <div className={"slider_container"}>
        <Tabs
          scrollButtons={true}
          value={this.state.tabKey}
          onChange={this.handleChange}
          variant="scrollable"
          selectionFollowsFocus
          aria-label="scrollable force tabs example"
        >
          {tabContents.map((tab, index) => this.getVideoElement(tab, index))}
        </Tabs>
      </div>
    );
  };

  render = () => {
    if (this.props.slider) {
      return this.getSlider();
    }
    return this.getDialog();
  };
}

function MenuItemsHook(props) {
  const location = useLocation();

  useEffect(() => {
    if (props.onUpdateParent) {
      props.onUpdateParent();
    }
  }, [location]);
}

InstructionalPopup.propTypes = {
  actionButton: PropTypes.element.isRequired,
  data_key: PropTypes.oneOf(["video_links", "instructions_contents"]).isRequired,
  route: PropTypes.string,
  startIndex: PropTypes.number,
  slider: PropTypes.bool,
};

export default InstructionalPopup;
