import React from "react";
import FinanceDetailsDialog from "../../../components/FinanceDetails/FinanceDetailsDialog";
import { PeriodTypes, ShortMonths } from "../../../../../../data/dates";
import PropTypes from "prop-types";
import Revenue_SingleQuantity from "../../../../../../data/Finance/Revenue/Revenue_SingleQuantity";

class BillableHours extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      revenue: this.props.revenue,
      data: null,
      monthlyChartData: null,
      yearlyChartData: null,
      itemChanged: false,
    };

    this.monthHeaders = global.Modeliks.DateHelper.months;
    this.yearHeaders = global.Modeliks.DateHelper.gridPeriodHeaders_Years();
    this.gridHeaders = [...this.monthHeaders, ...this.yearHeaders];

    this.tabs = [
      {
        title: this.state.revenue.BillableHours.Name,
        subtitle: `${this.state.revenue.BillableHours.Name}`,
        field: "Quantity",
        description:
          "Billable hours is the number of hours (or days, or months) that you plan to sell of this type of service",
        growthEnabled: true,
        checkedGrowth: false,
        changeEnabled: true,
        allowDisplayActuals: true,
        showActuals: false,
        changeDriverNameEnabled: true,
        dataGridRow: {
          data: this.state.revenue.BillableHours,
          drivers: [],
        },
        chart: {
          monthlyChartData: {
            data: this.state.revenue.BillableHours.Values.filter(
              (c) => this.monthHeaders.indexOf(c.Date) > -1,
            ),
            labels: global.Modeliks.DateHelper.headers_Months(),
          },
          yearlyChartData: {
            data: this.state.revenue.BillableHours.Values.filter(
              (c) => this.yearHeaders.indexOf(c.Date) > -1,
            ),
            labels: global.Modeliks.DateHelper.headers_Years(true),
          },
        },
      },
      {
        title: this.state.revenue.HourlyRate.Name,
        subtitle: `${this.state.revenue.HourlyRate.Name}`,
        field: "SinglePrice",
        description: "Hourly rate is the price at which you plan to sell each Billable Hour.",
        growthEnabled: true,
        checkedGrowth: false,
        changeEnabled: true,
        allowDisplayActuals: true,
        showActuals: false,
        changeDriverNameEnabled: true,
        dataGridRow: {
          data: this.state.revenue.HourlyRate,
          drivers: [],
        },
        chart: {
          monthlyChartData: {
            data: this.state.revenue.HourlyRate.Values.filter(
              (c) => this.monthHeaders.indexOf(c.Date) > -1,
            ),
            labels: global.Modeliks.DateHelper.headers_Months(),
          },
          yearlyChartData: {
            data: this.state.revenue.HourlyRate.Values.filter(
              (c) => this.yearHeaders.indexOf(c.Date) > -1,
            ),
            labels: global.Modeliks.DateHelper.headers_Years(true),
          },
        },
      },
    ];
  }

  componentDidMount() {
    // this.getDrivers();
  }

  onSave = () => {
    this.props.saveRevenue();
  };

  onDataChanged = (items) => {
    // items.forEach(item=>{
    //     if(item.cache){
    //         item.cache.resetCache();
    //     }
    // })
    this.setState({ itemChanged: true });
  };

  render = () => {
    if (this.state.revenue == null) {
      return null;
    }

    return (
      <FinanceDetailsDialog
        addTab={this.addTab}
        open={this.props.open}
        title={this.state.revenue.RevenueName}
        tabs={this.tabs}
        headers={this.gridHeaders}
        // monthlyChartData={this.state.monthlyChartData}
        // yearlyChartData={this.state.yearlyChartData}
        saveEnabled={this.state.itemChanged}
        onSave={this.onSave}
        onClose={this.props.onClose}
        table={this.state.revenue.tableName}
        setFormula={this.setFormula}
        parentID={this.state.revenue.ID}
        totalDriver={this.props.totalDriver}
        onGeneral={this.props.onGeneral}
        onDataChanged={this.onDataChanged}
        tabIndex={this.props.tabIndex}
      />
    );
  };
}

BillableHours.propTypes = {
  revenue: PropTypes.object,
  onGeneral: PropTypes.func,
  onClose: PropTypes.func,
};

export default BillableHours;
