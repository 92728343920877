import React from "react";
import styled from "styled-components";
import { Theme } from "../../../theme/styles/theme";
import { IconPlus } from "../../icons/svgIcons";

const StyledButton = styled.button`
  ${(props) => (props.size === "large" ? Theme.button.large : Theme.button.small)}
  ${Theme.effect.elevation}
    background: ${(props) =>
    props.primary ? Theme.colors.secondary.purple[100] : Theme.colors.primary.lightblue[300]};
  border: none;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 6px 12px;
`;
const Typography = styled.label`
  font-family: Inter;
  ${(props) => (props.size === "large" ? Theme.text.label.label2 : Theme.text.label3)}
  color: ${Theme.colors.greyscale.white}
`;

export const Button = ({ color, size, disabled, ...props }) => {
  return (
    <StyledButton color={color} size={size}>
      <Typography size={size}>{props.children}</Typography>
      <IconPlus />
    </StyledButton>
  );
};
