import React from "react";
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-dragdata";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "../actions/CheckBoxMaterial";

import {
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { InputFormats } from "../constants/finance";
import { NumberFormatTypes } from "../constants/charts";
import ChartHelper from "../../pages/Secure/Financials/components/Charts/ChartHelpers";

ChartJS.register(
  CategoryScale,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
);

class FinancialBarStackedChart extends React.Component {
  constructor(props) {
    super(props);

    this.chartReference = React.createRef();
    ChartJS.defaults.font.family = "Inter";
    ChartJS.defaults.isItalic = props.isItalic;
    ChartJS.defaults.plugins.datalabels.font.style = props.isItalic ? "italic" : "normal";
    this.state = {
      sales: true,
      subscription: true,
      services: true,
      production: true,
      data: {
        // labels: props.labels,
        datasets: this.props.data,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        parsing: {
          xAxisKey: "Header",
          yAxisKey: "Value",
        },
        type: "bar",
        barRoundness: 0.3,
        layout: {
          padding: {
            top: 40,
            right: 20,
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              color: "#889299",
              font: {
                weight: 400,
                style: "normal",
                size: 14,
                lineHeight: "160%",
              },
            },
            stacked: true,
          },
          y: {
            grid: {
              drawBorder: false,
            },
            ticks: {
              display: false,
              minTicksLimit: 4,
              maxTicksLimit: 4,
              color: "#889299",
              beginAtZero: true,
              font: {
                weight: 400,
                style: "normal",
                size: 14,
                lineHeight: "160%",
              },
              callback: function (value) {
                return null;
                if (value <= -1000000) {
                  return value / 1000000 + "M";
                } else if (value <= -1000) {
                  return value / 1000 + "K";
                } else if (value >= 1000 && value < 1000000) {
                  return value / 1000 + "K";
                } else if (value >= 1000000) {
                  return value / 1000000 + "M";
                } else {
                  return value.toFixed(2);
                }
              },
              stepSize: 750000,
            },
            stacked: true,
          },
        },
        plugins: {
          datalabels: {
            // anchor: 'start',
            // align: 'bottom',
            anchor: "end",
            align: "top",
            textAlign: "center",
            rotation: (ctx) => {
              let IsAnnualChart = true;
              if (ctx.dataset.data.length > 5) {
                IsAnnualChart = false;
              }

              if (!IsAnnualChart && ctx.chart.width < 1080) {
                return -20;
              }

              if (IsAnnualChart && ctx.chart.width < 341) {
                return -20;
              }

              return 0;
            },
            formatter: function (value, ctx) {
              const sumValue = ctx.chart.config.data.datasets.map((datapoint) => {
                if (datapoint.data[ctx.dataIndex] && datapoint.hidden !== true) {
                  return datapoint.data[ctx.dataIndex].Value;
                } else {
                  return 0;
                }
              });
              function totalSum(total, datapoint) {
                if (isNaN(datapoint)) {
                  return total;
                } else {
                  return total + datapoint;
                }
              }

              let sum = sumValue.reduce(totalSum, 0);
              let newSum = 0.0;

              if (isNaN(sum)) {
                newSum = "-";
              } else {
                newSum = sum / NumberFormatTypes[props.unitValue].splitBy;
              }

              newSum = ChartHelper.setTextOverflow(newSum, ctx);
              let val = "";

              let datasets = props.data.filter((c) => c.hidden !== true);

              if (
                ctx.chart.config.data.datasets[ctx.datasetIndex].revenue.ID ===
                datasets[datasets.length - 1].revenue.ID
              ) {
                if (props.growth && props.valueLabels) {
                  val =
                    (isNaN(newSum)
                      ? ""
                      : props.hideCurrency
                        ? ""
                        : global.Modeliks.CompanyInfo.Currency.value) +
                    ChartHelper.setTextOverflow(InputFormats.formatNumber(newSum), ctx) +
                    (isNaN(props.growth[ctx.dataIndex].Value)
                      ? ""
                      : props.growth[ctx.dataIndex].Value !== null
                        ? isFinite(props.growth[ctx.dataIndex].Value)
                          ? "\n" +
                            InputFormats.formatNumber(props.growth[ctx.dataIndex].Value) +
                            `${props.isPercentage ? "%" : ""}`
                          : "\n∞"
                        : "\n-");
                } else if (props.growth) {
                  val = isNaN(props.growth[ctx.dataIndex].Value)
                    ? ""
                    : props.growth[ctx.dataIndex].Value !== null
                      ? isFinite(props.growth[ctx.dataIndex].Value)
                        ? "\n" +
                          InputFormats.formatNumber(props.growth[ctx.dataIndex].Value) +
                          `${props.isPercentage ? "%" : ""}`
                        : "\n∞"
                      : "";
                } else if (props.valueLabels) {
                  val =
                    (isNaN(newSum)
                      ? ""
                      : props.hideCurrency
                        ? ""
                        : global.Modeliks.CompanyInfo.Currency.value) +
                    ChartHelper.setTextOverflow(InputFormats.formatNumber(newSum), ctx);
                } else {
                  val = "";
                }

                return val;
              } else {
                return "";
              }
            },
            color: "#252525",
            font: function (context, text = this) {
              let width = context.chart.width;
              let size = Math.round(width / (width > 700 ? 120 : 38));
              let min = 9;
              let max = 14;

              if (size < min) {
                size = min;
              }

              if (size > max) {
                size = max;
              }

              return {
                weight: "bold",
                size: size,
                style: `${ChartJS.defaults.isItalic ? "italic" : "normal"}`,
              };
            },
          },
          tooltip: {
            callbacks: {
              title: function (context) {
                if (context.length > 0) {
                  let title = context[0].raw.RevenueName || "";
                  return title;
                }
              },
              label: function (context) {
                return global.Modeliks.CompanyInfo.Currency.value + context.formattedValue;
              },
            },
          },
          legend: {
            display: false,
          },
        },
      },
    };
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    if (this.chartReference.current.data.datasets != nextProps.data) {
      this.chartReference.current.data.datasets = nextProps.data;
    }

    // if(this.state.options.plugins.datalabels.align != nextProps.align){
    //     // this.state.options.plugins.datalabels.align = nextProps.align;
    // }

    this.chartReference.current.update();

    return false;
  };

  render() {
    return (
      <Bar
        ref={this.chartReference}
        type="bar"
        data={this.state.data}
        options={this.state.options}
        height={this.props.height ? this.props.height : "60"}
        responsive="true"
        redraw
      />
    );
  }
}

export default FinancialBarStackedChart;
