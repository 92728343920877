import React from "react";
import "./RecentReports.scss";
import { Logo } from "../../../../../components/icons/svgIcons";
import RecentReport from "./RecentReport/RecentReport";
import MxDataArray from "../../../../../data/MxDataArray";
import CircleLoader from "../../../../../components/Loaders/CircleLoader/CircleLoader";

export const groupByUniversal = (items, key) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {},
  );

class RecentReports extends React.Component {
  Sections = [];
  ReportsBySection = [];

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };

    this.Reports = MxDataArray.create(this.getReports());

    global.Modeliks.get(
      "mr_sections",
      { ReportID: this.Reports.map((report) => report.ID) },
      (sections) => {
        const SECTIONSGROUPED = groupByUniversal(sections, "ReportID");
        const { Reports } = this;
        Object.keys(SECTIONSGROUPED).forEach((c) => {
          SECTIONSGROUPED[c] = MxDataArray.create(SECTIONSGROUPED[c].sort(this.sortSections));
          Reports.getItem(c).first_section_id = SECTIONSGROUPED[c][0].ID;
        });
        this.ReportsBySection = MxDataArray.create(Reports, "first_section_id");
        global.Modeliks.get(
          "mr_templates",
          { SectionID: Object.keys(SECTIONSGROUPED).map((key) => SECTIONSGROUPED[key][0].ID) },
          (templates) => {
            const TEMPLATESGROUPED = groupByUniversal(templates, "SectionID");
            Object.keys(TEMPLATESGROUPED).forEach(
              (key) => (this.ReportsBySection.getItem(key).Template = TEMPLATESGROUPED[key][0]),
            );
            this.setState({ loading: false });
          },
        );
      },
    );
  }

  sortSections = (a, b) => a.order - b.order;

  sortFunction = (a, b) => new Date(b.UpdatedAt) - new Date(a.UpdatedAt);

  getReports = () => {
    let reports = [];

    this.props.management_reports.forEach((c) => {
      reports.push(c);
    });

    reports.sort(this.sortFunction);

    return reports.filter((c, index) => index < 8);
  };

  render() {
    return (
      <div className={"recent_reports_container"}>
        <div className={"rrc_heading"}>Recently Modified Reports</div>
        <div className={"rrc_report_cards_container"}>
          {this.state.loading
            ? this.Reports.map((c) => {
                return (
                  <div className={"rrc_card"} onClick={() => this.props.onSelect(c)}>
                    <div className={"sc_light"}>
                      <div className={"loader_cont"}>
                        <CircleLoader />
                      </div>
                    </div>
                  </div>
                );
              })
            : this.ReportsBySection.map((c) => (
                <div className={"rrc_card"} onClick={() => this.props.onSelect(c)}>
                  <RecentReport report={c} />
                </div>
              ))}
        </div>
      </div>
    );
  }
}

export default RecentReports;
