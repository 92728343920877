import React, { Component } from "react";
import "./AppSumoUpgradeDialog.scss";
import Dialog from "@mui/material/Dialog";
import ButtonMui from "../buttons/buttonMui/buttonMui";
import { Input2 } from "../actions/Input";
import UpgradeLifeTimeDialog from "../../pages/Secure/ProfileSettings/AccountDetails/components/UpgradeLifeTimeDialog";
import request from "superagent";

class AppSumoUpgradeDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coupon: "",
      couponError: "",
    };
  }

  validate = () => {
    if (this.state.coupon.length) {
      return true;
    }
    return false;
  };

  handleSubmit = () => {
    if (this.validate()) {
      request
        .put("/api/UpgradeLifeTimeSubscription")
        .set("Content-Type", "application/x-www-form-urlencoded")
        .set("authorization", "Bearer " + window.localStorage.getItem("token"))
        .send({ coupon: this.state.coupon, subscriptionID: 9, free_trial: true })
        .then((res) => {
          if (res) {
            console.log("res", res);
            if (res.body === "Invalid coupon") {
              this.setState({
                couponError:
                  "Invalid code. Please enter a valid code. You can find the correct codes in your AppSumo account. ",
              });
            } else if (res.body === "Used coupon") {
              this.setState({
                couponError:
                  "This code has already been used. Please enter a valid code. You can find the correct codes in your AppSumo account.",
              });
            } else {
              window.location.reload();
            }
          }
        });
    } else {
      this.setState({ couponError: "Please enter AppSumo code" });
    }
  };

  handleClose = () => {
    this.props.handleClose();
  };

  render() {
    return (
      <React.Fragment>
        {
          <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            open={this.props.open ? this.props.open : false}
            className={"payment_failed_dialog"}
            onClose={this.handleClose}
          >
            <>
              <div className={"mslt_container"}>
                <p className={"p1Strong"}>Upgrade to LifeTime Fund Plan</p>
                <p>Enter AppSumo Code</p>
                <div style={{ width: "100%" }}>
                  <Input2
                    value={this.state.coupon}
                    onChange={(e) => {
                      this.setState({ coupon: e.target.value, couponError: "" });
                    }}
                    errorMessage={this.state.couponError}
                  />
                </div>
                <ButtonMui label={"SUBMIT"} variant={"contained"} onClick={this.handleSubmit} />

                <UpgradeLifeTimeDialog
                  open={this.state.openUpdareDialog}
                  planName={this.nextPlan ? this.nextPlan.name : ""}
                  handleClose={this.handleCloseUpdateDialog}
                />
              </div>
            </>
          </Dialog>
        }
      </React.Fragment>
    );
  }
}

export default AppSumoUpgradeDialog;
