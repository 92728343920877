import React from "react";
import { Dialog, DialogActions } from "@mui/material";
import PropTypes from "prop-types";
import Button from "../../actions/Button";
import styled from "styled-components";
import IconButton from "../../../components/actions/IconButton";
import Input from "../../../components/actions/Input";
import CircleCheckboxM from "../../../components/actions/CircleCheckboxM";
import { Vector } from "../../dividers/Vector";
import { IconX, TrashCan } from "../../icons/svgIcons";
import "./AddUserDialog.scss";
import request from "superagent";
import { PermissionTypes } from "../../constants/permissions";
import UsersLimit from "../LimitDialogs/UsersLimit";
import ButtonMui from "../../buttons/buttonMui/buttonMui";
import { AccessControl } from "../../../data/Permissions/AccessControl";

const P1Strong = styled.div`
  font-weight: 700;
  font-size: 20px;
  font-family: Inter;
`;

const StiledDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: "250px";
`;

const P3Strong = styled.div`
  font-weight: 700;
  font-size: 14px;
  font-family: Inter;
`;
const StyledD = styled.div`
  width: 100%;
`;

const StyledTitle = styled.div`
     -moz-box-sizing:border-box;
    -webkit-box-sizing:border-box;
    box-sizing:border-box;
    width: 100%;
    height: 100%
    display: flex;
    flex-direction:column;
    justify-content:center;
    overflow:hidden;
    padding-left: 15px;
    padding-right: 20px;
    padding-top: 35px;
`;

function AddUserDialog({
  user,
  disabled,
  open,
  onClose,
  companies,
  editor,
  existingUser,
  closeDialog,
  id,
  setOpenUsersLimit,
  ...props
}) {
  const [UserType, setUserType] = React.useState(0);
  const [UserName, setUserName] = React.useState("");
  const [Email, setEmail] = React.useState("");
  const [Password, setPassword] = React.useState("");
  const [FullName, setFullName] = React.useState("");
  const [Pitch, setPitch] = React.useState(null);
  const [BusinessPlan, setBusinessPlan] = React.useState(null);
  const [Financials, setFinancials] = React.useState(null);
  const [Dashboards, setDashboards] = React.useState(null);
  const [EditDashboards, setEditDashboards] = React.useState(null);
  const [Valuation, setValuation] = React.useState(null);

  const [ManageCompanies, setManageCompanies] = React.useState(null);
  const [ManageUsers, setManageUsers] = React.useState(null);
  const [ManageSubscriptions, setManageSubscriptions] = React.useState(null);
  const [userCompanies, setUserCompanies] = React.useState([]);
  const [ID, setID] = React.useState();
  const [emailError, setEmailError] = React.useState("");
  const [fullNameError, setFullNameError] = React.useState("");
  const [checkBoxesError, setCheckBoxesError] = React.useState("");

  let PermissionsMap = new Map();

  const checkUpperCheckboxes = () => {
    if (Pitch) return true;
    if (BusinessPlan) return true;
    if (Financials) return true;
    if (Dashboards) return true;
    if (Valuation) return true;
    if (ManageCompanies) return true;
    if (ManageUsers) return true;
    if (ManageSubscriptions) return true;
    return false;
  };

  const checkLowerCheckboxes = () => {
    if (userCompanies.length > 0) return true;
    else return false;
  };

  const validate = () => {
    let curCheckboxesError = "";
    let curEmailError = "";
    let curFullNameError = "";
    let fullNameRegex = /^[a-zA-Z ]+$/;
    let emailRegex =
      /^([+\w-]+(?:\.[+\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    switch (true) {
      case !emailRegex.test(Email):
        curEmailError = "Please enter a valid email address";
        break;
    }
    switch (true) {
      case !fullNameRegex.test(FullName):
        curFullNameError = "name error";
        break;
    }
    switch (true) {
      case !checkUpperCheckboxes():
        curCheckboxesError = "please select access level ";
        break;
    }
    switch (true) {
      case !checkLowerCheckboxes():
        curCheckboxesError += curCheckboxesError
          ? "and company to give access to"
          : "please select company to give access to";
        break;
    }
    if (curEmailError || curFullNameError || curCheckboxesError) {
      setEmailError(curEmailError);
      setFullNameError(curFullNameError);
      setCheckBoxesError(curCheckboxesError);
      return false;
    } else {
      setEmailError("");
      setFullNameError("");
      setCheckBoxesError("");
    }
    return true;
  };

  const changeUserType = (type) => {
    if (type === 3 && UserType === 2) {
      setPitch(false);
      setBusinessPlan(false);
      setFinancials(false);
      setDashboards(false);
      setValuation(false);
      setManageCompanies(false);
      setManageUsers(false);
      setManageSubscriptions(false);
      setDashboards(false);
    } else if (type === 2 && UserType === 3) {
      setPitch(false);
      setBusinessPlan(false);
      setFinancials(false);
      setDashboards(false);
      setValuation(false);
    }
    setUserType(type);
  };

  const saveEmail = (e) => {
    setEmail(e.target.value);
  };
  const saveFullName = (e) => {
    setFullName(e.target.value);
  };

  const giveAccessToCompanies = (company) => {
    if (userCompanies.some((comp) => comp.Company_ID === company.ID)) {
      //(userCompanies.includes(company.ID)) {
      let tmp = [];
      tmp = userCompanies.filter((item) => item.Company_ID !== company.ID);
      setUserCompanies(tmp);
    } else {
      setUserCompanies((oldArray) => [...oldArray, { Company_ID: company.ID }]); //company.ID]);
    }
  };

  const getPermissions = () => {
    let permissions = [];
    if (Pitch) permissions.push(PermissionTypes.Pitch);
    if (BusinessPlan) permissions.push(PermissionTypes.BusinessPlan);
    if (Financials) permissions.push(PermissionTypes.Financials);
    if (Dashboards) permissions.push(PermissionTypes.Dashboards);
    if (Valuation) permissions.push(PermissionTypes.Valuation);
    if (ManageCompanies) permissions.push(PermissionTypes.Companies);
    if (ManageUsers) permissions.push(PermissionTypes.Users);
    if (ManageSubscriptions) permissions.push(PermissionTypes.Subscriptions);
    return permissions;
  };

  const saveUser = () => {
    if (global.Modeliks.limitInfo.ActiveUsers < global.Modeliks.limitInfo.TotalUsers) {
      if (validate()) {
        request
          .post("/api/inviteUser")
          .set("authorization", "Bearer " + window.localStorage.getItem("token"))
          .send({
            users: [
              {
                user: {
                  Username: "",
                  Name: FullName,
                  Password: "",
                  Email: Email,
                  UserType: UserType,
                },
                permissions: getPermissions(),
                companies: [...userCompanies],
              },
            ],
            name: global.Modeliks.User.Name,
            email: global.Modeliks.User.Email,
            User_ID: global.Modeliks.User.ID,
          })
          .then((data) => {
            if (data.text === "limit reached") {
              closeDialog(data);
              setOpenUsersLimit();
            } else {
              global.Modeliks.GetAccountUsers(() => {
                closeDialog(data);
              });
            }
          })
          .catch((err) => {
            setEmailError("account is already added");
          });
      }
    } else {
      closeDialog();
      setOpenUsersLimit();
    }
  };

  const handleCheckboxChange = (e) => {
    PermissionsMap.set(e.target.value, { checked: e.target.checked, userType: UserType });
  };

  return (
    <>
      <Dialog
        fullWidth={false}
        open={open}
        onClose={onClose}
        scroll="paper"
        maxWidth={"800px"}
        sx={{
          zIndex: 2000,
          ".MuiBackdrop-root": {
            backgroundColor: `rgb(255 255 255 / 90%)`,
          },
          "& .MuiDialog-container": {
            justifyContent: "center",
            alignItems: "center",
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "800px", // Set your width here
              height: "100%",
              maxHeight: "600px",
            },
          },
        }}
        PaperProps={{
          sx: {
            marginLeft: "25px",
            marginRight: "38px",
            boxShadow: `0px 0px 10px rgba(0, 0, 0, 0.15)`,
          },
        }}
        className="usr_dialog"
      >
        <StyledTitle>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <P1Strong>Add User</P1Strong>
            <IconButton size="small" onClick={closeDialog}>
              <IconX />
            </IconButton>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                width: "100%",
                gap: "5px",
                alignItems: "center",
                // marginBottom: '17px',
                marginTop: "19px",
              }}
            >
              <Input
                placeholder={"Enter Name"}
                error={!!fullNameError}
                name="FullName"
                onChange={(e) => saveFullName(e)}
              />
              <Input
                placeholder={"User Email"}
                error={!!emailError}
                name="Email"
                onChange={(e) => saveEmail(e)}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  width: "100%",
                  paddingLeft: "20px",
                  alignItems: "center",
                }}
              >
                <CircleCheckboxM
                  label={"Editor"}
                  onChange={() => changeUserType(1)}
                  checked={UserType === 1}
                  size={"medium"}
                />
                <CircleCheckboxM
                  label={"Viewer"}
                  onChange={() => changeUserType(0)}
                  checked={UserType === 0}
                  size={"medium"}
                />
              </div>
            </div>
            <div className="usr_error_container">
              <div className={fullNameError ? "error" : "error invisible"}>
                Plese enter a valid name
              </div>
              <div className={emailError ? "error" : "error invisible"}>{emailError}</div>
            </div>
            <Vector />
            <div style={{ display: "flex", justifyContent: "left", marginTop: "28px" }}>
              <P3Strong>
                Please select the sections for which you would like to grant the user view rights.
              </P3Strong>
            </div>
            {UserType === 1 && (
              <div style={{ width: "100%", marginTop: "20px" }}>
                <StiledDiv>
                  <StyledD>
                    <CircleCheckboxM
                      label={"Financial Plan"}
                      checked={Financials}
                      onChange={() => setFinancials(!Financials)}
                      size={"medium"}
                    />
                  </StyledD>
                  <StyledD>
                    <CircleCheckboxM
                      label={"Pitch"}
                      checked={Pitch}
                      size={"medium"}
                      onChange={() => setPitch(!Pitch)}
                    />
                  </StyledD>

                  {/*<StyledD><CircleCheckboxM label={'Valuation'}*/}
                  {/*checked={Valuation}*/}
                  {/*onChange={() => setValuation(!Valuation)}*/}
                  {/*size={'medium'}/></StyledD>*/}
                  <StyledD>
                    <CircleCheckboxM
                      label={"Add & Manage Users"}
                      checked={ManageUsers}
                      onChange={() => setManageUsers(!ManageUsers)}
                      size={"medium"}
                    />
                  </StyledD>
                </StiledDiv>
                <StiledDiv style={{ marginTop: "5px" }}>
                  <StyledD>
                    <CircleCheckboxM
                      label={"Business Plan"}
                      checked={BusinessPlan}
                      onChange={() => setBusinessPlan(!BusinessPlan)}
                      size={"medium"}
                    />
                  </StyledD>

                  <StyledD>
                    <CircleCheckboxM
                      label={"Manage Subscriptions"}
                      checked={ManageSubscriptions}
                      onChange={() => setManageSubscriptions(!ManageSubscriptions)}
                      size={"medium"}
                    />
                  </StyledD>

                  <AccessControl
                    allowedPermissions={[
                      {
                        [global.Modeliks.PERMISSIONS.Dashboards.key]:
                          global.Modeliks.PERMISSIONS.Financials.restrictions.overAll.key,
                      },
                    ]}
                    renderNoAccess={
                      <StyledD>
                        <CircleCheckboxM
                          label={"Dashboards"}
                          checked={Dashboards}
                          disabled={true}
                          onChange={(e) => e.preventDefault()}
                          size={"medium"}
                        />
                      </StyledD>
                    }
                  >
                    <StyledD>
                      <CircleCheckboxM
                        label={"Dashboards"}
                        checked={EditDashboards}
                        onChange={() => {
                          if (!Dashboards) {
                            setDashboards(!Dashboards);
                          }
                          setEditDashboards(!EditDashboards);
                        }}
                        size={"medium"}
                      />
                    </StyledD>
                  </AccessControl>
                </StiledDiv>
                <StiledDiv style={{ marginTop: "5px" }}>
                  <StyledD>
                    <CircleCheckboxM
                      label={"Add & Manage Companies"}
                      checked={ManageCompanies}
                      onChange={() => setManageCompanies(!ManageCompanies)}
                      size={"medium"}
                    />
                  </StyledD>
                  <StyledD></StyledD>
                </StiledDiv>
              </div>
            )}

            {UserType === 0 && (
              <div>
                <StiledDiv style={{ marginTop: "20px" }}>
                  <StyledD>
                    <CircleCheckboxM
                      label={"Financial Plan"}
                      checked={Financials}
                      onChange={() => setFinancials(!Financials)}
                      size={"medium"}
                    />
                  </StyledD>
                  <StyledD>
                    <CircleCheckboxM
                      label={"Pitch"}
                      checked={Pitch}
                      onChange={() => setPitch(!Pitch)}
                      size={"medium"}
                    />
                  </StyledD>
                  {/*<StyledD><CircleCheckboxM label={'Valuation'}*/}
                  {/*checked={Valuation}*/}
                  {/*onChange={() => setValuation(!Valuation)}*/}
                  {/*size={'medium'}/></StyledD>*/}
                </StiledDiv>
                <StiledDiv style={{ marginTop: "5px" }}>
                  <StyledD>
                    <CircleCheckboxM
                      label={"Business Plan"}
                      checked={BusinessPlan}
                      onChange={() => setBusinessPlan(!BusinessPlan)}
                      size={"medium"}
                    />
                  </StyledD>
                  <AccessControl
                    allowedPermissions={[
                      {
                        [global.Modeliks.PERMISSIONS.Dashboards.key]:
                          global.Modeliks.PERMISSIONS.Financials.restrictions.overAll.key,
                      },
                    ]}
                    renderNoAccess={
                      <StyledD>
                        <CircleCheckboxM
                          label={"Dashboards"}
                          checked={Dashboards}
                          disabled={true}
                          onChange={(e) => e.preventDefault()}
                          size={"medium"}
                        />
                      </StyledD>
                    }
                  >
                    <StyledD>
                      <CircleCheckboxM
                        label={"Dashboards"}
                        checked={Dashboards}
                        onChange={() => setDashboards(!Dashboards)}
                        size={"medium"}
                      />
                    </StyledD>
                  </AccessControl>
                </StiledDiv>
                {/*<StiledDiv>*/}
                {/*<div style={{width: '100%', marginBottom: '10px', marginTop: '5px'}}><CircleCheckboxM*/}
                {/*label={'Financial Plan'} checked={Financials}*/}
                {/*onChange={() => setFinancials(!Financials)}*/}
                {/*size={'medium'}/></div>*/}
                {/*</StiledDiv>*/}
              </div>
            )}
            <div
              className={checkBoxesError ? "divider error" : "divider"}
              style={{ width: "100%", borderBottom: "1px solid #F0F1F7", marginTop: "10px" }}
            />
            <div className="check_box_error_container">
              <span className={checkBoxesError ? "check_box_error" : "check_box_error invisible"}>
                {checkBoxesError}
              </span>
            </div>
            <div style={{ display: "flex", justifyContent: "left", marginTop: "25px" }}>
              <P3Strong>
                Please select the companies for which you would like to grant access rights to the
                user.
              </P3Strong>
            </div>
            <StiledDiv>
              {companies.length > 0 &&
                companies.map((comp) => (
                  <div style={{ width: "100%", marginTop: "18px" }}>
                    <CircleCheckboxM
                      checked={userCompanies.some((company) => company.Company_ID === comp.ID)}
                      onChange={() => giveAccessToCompanies(comp)}
                      label={comp.CompanyName}
                      size={"medium"}
                    />
                  </div>
                ))}
              {companies.length === 0 && (
                <>
                  <div style={{ width: "100%", marginTop: "18px" }}>
                    <CircleCheckboxM label={"Company 2"} checked={false} size={"medium"} />
                  </div>
                  <div style={{ width: "100%", marginTop: "18px" }}>
                    <CircleCheckboxM label={"Company 3"} checked={false} size={"medium"} />
                  </div>
                </>
              )}
            </StiledDiv>
            <DialogActions
              style={{
                justifyContent: "flex-end",
                background: "white",
                marginRight: "10px",
                marginBottom: "10px",
                marginTop: "57px",
              }}
            >
              {/*<Button size='small' outlined={'red150'} onClick={()=>deleteUser(id)} noElevation={true} disable={false} padding='15px'*/}
              {/*label='Delete User'/>*/}
              <div style={{ display: "flex", gap: "20px" }}>
                <ButtonMui
                  style={{ height: "30px" }}
                  label={"Cancel"}
                  variant={"contained"}
                  onClick={closeDialog}
                />
                <ButtonMui
                  style={{ height: "30px" }}
                  label={"Save & Send Invite"}
                  variant={"contained"}
                  onClick={saveUser}
                />
              </div>
            </DialogActions>
          </div>
        </StyledTitle>
      </Dialog>
    </>
  );
}

AddUserDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  changeUserType: PropTypes.func,
  closeDialog: PropTypes.func,
  companies: PropTypes.array,
  existingUser: PropTypes.bool,
  id: PropTypes.number,
  user: PropTypes.object,
  setOpenUsersLimit: PropTypes.func.isRequired,
};
export default AddUserDialog;
