import React from "react";
import AuthPage from "../components/AuthPage";
import request from "superagent";
import RegisterPage from "../Register/RegisterPage";
import Mx_MainLoader from "../../../../components/Loaders/MainLoader/Mx_MainLoader";

class NewPassword extends RegisterPage {
  disabledEmailEdit = true;

  constructor(props) {
    super(props);
    this.state = {
      requiredPasswordChange: false,
      email: null,
      isMarketingSubscribed: true,
    };
    this.guid = "";
    this.accountID = "";
  }

  register = () => {
    if (this.validate()) {
      this.updateInvitedUser();
    }
  };

  loginWithAccount = (index, data) => {
    const data_body = JSON.parse(JSON.stringify(data.body));
    global.Modeliks.isAdmin = data_body.Accounts[index].isAdmin;
    // this.setState({chooseAccount: false, loading: true});
    window.localStorage.setItem("User_ID", data_body.ID);
    window.localStorage.setItem("token", data_body.Accounts[index].token);
    let shouldInit = true;
    if (data.body.Accounts[index].ID) {
      window.localStorage.setItem("Account_ID", data_body.Accounts[index].ID);
      global.Modeliks.Account = { ID: data_body.Accounts[index].ID };
      request
        .get(`/api/checkIfShouldInit?AccountID=${data_body.Accounts[index].ID}`)
        .then((result) => {
          global.Modeliks.Login(data.body, result.body, this.finishLogin);
        })
        .catch((e) => {
          console.error("error adding account", e);
        });
    } else {
      global.Modeliks.Login(data.body, false, this.finishLogin);
    }
  };

  finishLogin = () => global.Modeliks.NavigateTo("/");

  login = (password, email, keepMeLoggedIn = false) => {
    request
      .post("/api/auth")
      .send({
        Email: email,
        Password: password,
        TimeStamp: new Date().getTime(),
        keepLoggedIn: keepMeLoggedIn,
      })
      .then((newData) => {
        this.loginWithAccount(0, newData);
      })
      .catch((err) => {
        this.setState({
          secondPasswordError: "Please try registering again, or contact our support team!",
          loading: false,
        });
        console.error(err);
      });
  };

  componentDidMount() {
    let arr = window.location.pathname.split("/");
    this.user_id = arr.pop();
    this.guid = arr.pop();
    request
      .get("/api/getEmail")
      .query({ guid: this.guid.toString(), User_ID: this.user_id })
      .then((res) => {
        if (res) {
          let result = JSON.parse(res.body);
          this.setState({ email: result.userEmail, mainUserEmail: result.mainUserEmail });
        }
      });
  }

  updateInvitedUser = () => {
    this.setState({ loading: true });

    const { name, surname, password, isMarketingSubscribed } = this.state;
    request
      .put("/api/updateInvitedUser")
      .query({ guid: this.guid.toString() })
      .send({
        name,
        surname,
        password,
        isMarketingSubscribed,
      })
      .then((res) => {
        if (res) {
          this.login(password, this.state.email, false);
          // window.location = '/';
          // if(global.Modeliks && global.Modeliks.isAuth){
          //     this.unmount();
          //     global.Modeliks.LogOut();
          // }else {
          //     window.location='/'
          // }
        } else {
          this.setState({
            secondPasswordError: "Please try registering again, or contact our support team!",
            loading: false,
          });
          console.error("error");
          // callback()
        }
      })
      .catch((e) => {
        this.setState({
          secondPasswordError: "Please try registering again, or contact our support team!",
          loading: false,
        });
        console.error("bad req", e);
      });
  };

  handleLogin = (data) => {
    this.setState({loading: true});
    this.loginWithAccount(0, data)
    // handleLogin(0, data);
  }

  client = global.Modeliks.googleClient((response) => {
    this.setState({loading: true});
    request
      .post("/api/auth-code")
      .send({
        code: response.code,
        TimeStamp: new Date().getTime(),
        isNew: true
      })
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("X-Requested-With", "XmlHttpRequest")
      .then(this.handleLogin)
      .catch((e) => {
        this.setState({loading: false});
      });
    // Set custom header for CRSF
  });

  render() {
    if (this.state.email) {
      return (
        <>
          {this.state.loading && <Mx_MainLoader secondLoader={true} className={"blurred"}/>}
          {super.render()}
        </>
      );
    }
  }
}

export default NewPassword;
