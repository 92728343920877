import React from "react";
import { PermissionTypes } from "../../../../../components/constants/permissions";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableBody";
import "./company_table.scss";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Mx_NumInput from "../../../../../components/actions/Mx_NumInput";
import CheckBoxMaterial from "../../../../../components/actions/CheckBoxMaterial";
import Input from "../../../../../components/actions/Input";
import ExportPortal from "../../../Dashboards/FinancialStatements/components/ExportPortal";
import ExportReport from "../../../Financials/components/Export/ExportReport";
import ShowHideMenu from "../../../Dashboards/FinancialStatements/components/SowHideMenu";
import { MainAdd, AddCompanyShowHideDiv } from "../ValuationContainer";
import CircleLoader from "../../../../../components/Loaders/CircleLoader/CircleLoader";

class OverviewTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
    this.EditPermission = global.Modeliks.UserPermissions[PermissionTypes.Financials] == 0;
    this.title = "Opening Balance";
    this.gridRows = [];
    this.headers = [
      { Header: "Include in evaluation", checked: true },
      { Header: "Company Name", checked: true },
      { Header: "Country", checked: true },
      { Header: "Revenue", checked: true },
      { Header: "Revenue Growth", checked: true },
      { Header: "EBITDA", checked: true },
      { Header: "EBITDA Margin", checked: true },
      { Header: "Net Profit", checked: true },
      { Header: "Net Profit Margin", checked: true },
      { Header: "Revenue Multiple", checked: true },
      { Header: "EBITDA Multiple", checked: true },
      { Header: "P/E Multiple", checked: true },
      { Header: "Company Description", checked: true },
      { Header: "Note", checked: true },
    ];
    this.fields = [
      "Revenue",
      "RevenueGrowth",
      "EBITDA",
      "EBITDAMargin",
      "NetProfit",
      "NetProfitMargin",
      "RevenueMultiple",
      "EBITDAMultiple",
      "PEMultiple",
    ];
  }

  componentDidMount() {
    this.setData();
  }

  setData = () => {
    this.props.handleStreamLoad && this.props.handleStreamLoad(false);

    this.setState({
      loading: false,
    });
  };

  TableHeader = (title, index) => {
    return (
      <TableCell
        component="th"
        scope="row"
        align="center"
        key={`opening_company-${index}`}
        className="psfco_company_table_cell bold"
      >
        {title}
      </TableCell>
    );
  };
  tableHead = () => {
    return (
      <TableHead>
        <TableRow className="psfco_company_table_header ">
          {this.headers.map(
            (header, index) => header.checked === true && this.TableHeader(header.Header, index),
          )}
        </TableRow>
      </TableHead>
    );
  };
  cellInput = (row = "", align = "right") => {
    return (
      <TableCell
        align={align}
        component="th"
        scope="row"
        className={`psfco_company_table_cell bold`}
      >
        {row}
      </TableCell>
    );
  };
  cellInputMx = (row, field) => {
    return (
      <TableCell
        align="right"
        component="th"
        scope="row"
        className={`psfco_company_table_cell bold`}
      >
        <Mx_NumInput
          isDialogInput={true}
          singleInput={true}
          selected={true}
          disabled={false}
          boldRow={true}
          isMultipleSelected={false}
          isEditable={false}
          key={"p_br_taxes_sales_tax_rate"}
          // onChange={(e) => this.saveValue(e,row.Revenue)}
          unit={row[field] && row[field].UnitParsed}
          data={row[field] && row[field].Value}
        />
      </TableCell>
    );
  };
  cellInputMxEditable = (row, field) => {
    return (
      <TableCell align={"right"} component="th" scope="row" className={`psfco_company_table_cell`}>
        <Mx_NumInput
          isDialogInput={true}
          singleInput={true}
          selected={true}
          disabled={false}
          isMultipleSelected={false}
          isEditable={true}
          key={"p_br_taxes_sales_tax_rate"}
          onChange={(e) => this.saveValue(e, row[field])}
          unit={row[field] && row[field].UnitParsed}
          data={row[field] && row[field].Value}
        />
      </TableCell>
    );
  };
  tableRowDefault = (row, index) => {
    return (
      <React.Fragment key={`def_2${index}__`}>
        <TableRow key={`def__2${index}_`} className={`psfco_company_table_header `}>
          {this.headers[0].checked && this.cellInput(row.CompanyName, "left")}
          {this.headers[1].checked && this.cellInput()}
          {this.headers[2].checked && this.cellInput()}
          {this.fields.map(
            (field, index) => this.headers[3 + index].checked && this.cellInputMx(row, field),
          )}
          {this.headers[this.headers.length - 2].checked &&
            this.cellInput(row.CompanyDescription, "left")}
          {this.headers[this.headers.length - 1].checked && this.cellInput(row.Note, "left")}
        </TableRow>
      </React.Fragment>
    );
  };
  saveValue = (e, value) => {
    if (value && value.Save) {
      value.Value = e;
      value.Save();
      this.forceUpdate();
      this.props.setTmp();
    }
  };
  onChangeInput = (e, row, value) => {
    row[value] = e.target.value;
    this.forceUpdate();
  };
  saveInputValue = (row) => {
    row.Save(
      () => {
        // this.forceUpdate();
      },
      true,
      false,
    );
  };
  onChangeInputCheckBox = (row) => {
    row.IncludeInValuation = !row.IncludeInValuation;
    this.setState({ loading: true }, () =>
      row.Save(
        () => {
          this.props.setTmp(() => {
            this.setState({ loading: false });
          });
        },
        false,
        false,
      ),
    );
  };

  tableRow = (row, index, level = 0, align = "right") => {
    return (
      <React.Fragment key={`${index}_${level}_${row.ID}`}>
        <TableRow key={`${index}_${level}_${row.ID}`} className={`psfco_company_table_header `}>
          {this.headers[0].checked && (
            <TableCell
              align="center"
              component="th"
              scope="row"
              className={`psfco_company_table_cell`}
            >
              <CheckBoxMaterial
                size="medium"
                onChange={() => this.onChangeInputCheckBox(row)}
                checked={row.IncludeInValuation}
              />
            </TableCell>
          )}
          {this.headers[1].checked && (
            <TableCell
              align={align}
              component="th"
              scope="row"
              className={`psfco_company_table_cell`}
            >
              <Input
                onBlur={() => this.saveInputValue(row)}
                onChange={(e) => this.onChangeInput(e, row, "CompanyName")}
                value={row.CompanyName}
              />
            </TableCell>
          )}
          {this.headers[2].checked && (
            <TableCell
              align={align}
              component="th"
              scope="row"
              className={`psfco_company_table_cell`}
            >
              <Input
                onBlur={() => this.saveInputValue(row)}
                onChange={(e) => this.onChangeInput(e, row, "Country")}
                value={row.Country}
              />
            </TableCell>
          )}

          {this.fields.map(
            (field, index) =>
              this.headers[3 + index].checked && this.cellInputMxEditable(row, field),
          )}

          {this.headers[this.headers.length - 2].checked && (
            <TableCell component="th" scope="row" className={`psfco_company_table_cell`}>
              <Input
                onBlur={() => this.saveInputValue(row)}
                onChange={(e) => this.onChangeInput(e, row, "CompanyDescription")}
                value={row.CompanyDescription}
              />
            </TableCell>
          )}
          {this.headers[this.headers.length - 1].checked && (
            <TableCell component="th" scope="row" className={`psfco_company_table_cell`}>
              <Input
                onBlur={() => this.saveInputValue(row)}
                onChange={(e) => this.onChangeInput(e, row, "Note")}
                value={row.Note}
              />
            </TableCell>
          )}
        </TableRow>
      </React.Fragment>
    );
  };
  changeRows = (c) => {
    this.forceUpdate();
  };

  render() {
    if (this.state.loading) {
      return (
        <div className="loader_chart_container loader_tr_m_td_m">
          <CircleLoader />
        </div>
      );
    }

    return (
      <>
        <AddCompanyShowHideDiv>
          <MainAdd onClick={this.props.onMainAdd} />
          <ShowHideMenu
            className="spsvs_show_hide"
            isCompany={true}
            changeColumns={this.changeRows}
            getHeaders={this.headers}
            name={"Show / Hide Column"}
          />
        </AddCompanyShowHideDiv>

        <div className="pfr_main_revenue_content">
          <ExportPortal>
            <ExportReport showExcelExport={false} />
          </ExportPortal>

          <div className="psfco_main_container">
            <div className="psfco_opening_company" key="first_table">
              <Table className="psfco_company_table" stickyHeader={true} id="report_table">
                {this.tableHead()}
                <TableBody>
                  {this.props.gridRows.length > 0 &&
                    this.props.gridRows.map((row, index) => this.tableRow(row, index))}
                  {this.props.gridRowsTmp.length > 0 &&
                    this.props.gridRowsTmp.map((row, index) => this.tableRowDefault(row, index))}
                </TableBody>
              </Table>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default OverviewTable;
