import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
// import {Theme} from '../../theme/styles/theme'
// import Input from '../actions/Input'
import Card from "../../../../components/cards/Card";
import Input from "../../../../components/actions/Input";
import { GreyCard } from "../../../../components/cards/GreyCard";
import { AlertCircle } from "../../../../components/icons/svgIcons";
import "./LoginCard.scss";
import request from "superagent";
import ButtonMui from "../../../../components/buttons/buttonMui/buttonMui";
import StrengthMeter from "../../../../components/actions/inputs/StrengthMeter";
import MxIDUseID from "../../../../helpers/MxIUniqueD";
import { DialogTypes } from "../../../../data/Finance/constants";

const StyledDiv = styled.div`
  margin-top: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 28px;
`;
const P4 = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: red;
`;

const StyledSpan = styled.div`
  font-weight: 400;
  font-size: 14px;
  font-family: Inter;
  text-overflow: ellipsis;
`;

const ErrorContainer = styled.div`
  font-family: Inter;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin-top: 2px;
`;

const P2Strong = styled.div`
  font-weight: 700;
  line-height: 25.6px;
  font-size: 16px;
  font-family: Inter;
`;

function LoginCard({ verifiedEmail = false, ...props }) {
  let User = global.Modeliks.User;

  const [email, setEmail] = React.useState(User.Email ? User.Email : "");
  const [changeEmail, setChangeEmail] = React.useState(false);
  const [changePassword, setChangePassword] = React.useState(false);
  // const [oldPasswordValid, setOldPasswordValid] = React.useState(true);
  const [errorPassword, setErrorPassword] = React.useState(false);
  const [oldPassword, setOldPassword] = React.useState("");
  const [newPassword1, setNewPassword1] = React.useState("");
  const [newPassword2, setNewPassword2] = React.useState("");
  const [newPassword1Error, setNewPassword1Error] = React.useState("");
  const [newPassword2Error, setNewPassword2Error] = React.useState("");
  const [oldPasswordError, setOldPasswordError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");

  const password = "********";

  const validatePasswords = () => {
    let oldPasswordError = "";
    let curPassword1Error = "";
    let curPassword2Error = "";
    // let emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    if (newPassword1.length < 6) curPassword1Error = "your password must be at least 6 characters";
    else if (newPassword1 !== newPassword2) {
      curPassword1Error = "passwords do not match";
      curPassword2Error = "passwords do not match";
    }
    setNewPassword1Error(curPassword1Error);
    setNewPassword2Error(curPassword2Error);
    setOldPasswordError(oldPasswordError);
    if (curPassword1Error || oldPasswordError) {
      return false;
    }
    return true;
  };
  const validateEmail = () => {
    let curEmailError = "";
    let emailRegex =
      /^([+\w-]+(?:\.[+\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    switch (true) {
      case !emailRegex.test(email):
        curEmailError = "please enter valid email";
        break;
    }

    if (curEmailError) {
      setEmailError(curEmailError);
      return false;
    } else {
      setEmailError("");
    }
    return true;
  };

  const onSaveEmail = () => {
    if (validateEmail()) {
      if (email == User.Email) {
        setChangeEmail(false);
      } else {
        request
          .put("/api/changeEmail")
          .set("authorization", "Bearer " + window.localStorage.getItem("token"))
          .send({ Email: email.trim().toLowerCase(), name: global.Modeliks.User.Name })
          .then((res, e) => {
            User.Email = email.trim().toLowerCase();
            User.isVerified = false;
            setChangeEmail(false);
          })
          .catch((e) => {
            if (e.response.text) {
              setEmailError(JSON.parse(e.response.text).error);
            }
          });
      }
    }
  };

  const onSavePassword = () => {
    if (validatePasswords()) {
      request
        .put("/api/updatePassword")
        .send({ newPassword: newPassword1, oldPassword: oldPassword, GUID: User.GUID })
        .then((res) => {
          if (res.text == "true") {
            setChangePassword(!changePassword);
          }
        })
        .catch((e) => setOldPasswordError("wrong password"));
    }
  };

  return (
    <>
      <Card
          className={"pfl_email"}
          size={"small"}
          height={"600px"}
          hasTitle={true}
          title={"Login Details"}
      >
        {/*{!User.isVerified && <GreyCard height='43px' width='100%' margin='0 0 20px 0' padding='5px 10px 5px 10px'>*/}
        {/*    <ErrorContainer>*/}
        {/*        <span style={{display: 'inline-flex', columnGap: '15px'}}>*/}
        {/*              <AlertCircle/>*/}
        {/*            <P4>We have sent you a verification email.<br/>Please verify your new email.</P4>*/}
        {/*        </span>*/}

        {/*        /!*<Button color='red150' width='100px' padding='0 0 0 0' noElevation={true} label='Verify Email'*!/*/}
        {/*                /!*size='small'/>*!/*/}
        {/*    </ErrorContainer>*/}
        {/*</GreyCard>}*/}

        <P2Strong>Email</P2Strong>

        {!changeEmail && (
          <>
            <div>
              <StyledSpan style={{ marginTop: "8px" }}>{email}</StyledSpan>
              <ButtonMui
                style={{ height: "34px", marginTop: "19px" }}
                label="Change Email"
                variant={"contained"}
                onClick={() => setChangeEmail(!changeEmail)}
              />
            </div>
            <div className={"error invisible"}>{emailError}</div>
          </>
        )}
        {changeEmail && (
          <>
            <div>
              <Input
                error={!!emailError}
                placeholder={email}
                onChange={(event) => setEmail(event.target.value)}
                value={email}
              />
              <ButtonMui
                style={{ height: "34px", marginTop: "9px" }}
                label="Save"
                variant={"contained"}
                onClick={() => onSaveEmail()}
              />
            </div>
            <div className={emailError ? "error" : "error invisible"}>{emailError}</div>
          </>
        )}
        <P2Strong style={{ marginTop: "20px" }}>Password</P2Strong>

        {!changePassword && (
          <div>
            <StyledSpan style={{ marginTop: "8px" }} type={"password"}>
              {password}
            </StyledSpan>
            <ButtonMui
              style={{ height: "34px", marginTop: "14px" }}
              label="Change Password"
              variant={"contained"}
              onClick={() => {
                let guid = MxIDUseID();
                global.Modeliks.put(
                  "/api/update_self_user",
                  { ID: global.Modeliks.User.ID },
                  { GUID: guid },
                  (successUpdate, errorUpdate) => {
                    if (successUpdate) {
                      User.GUID = guid;
                      global.Modeliks.User.GUID = guid;
                      setChangePassword(!changePassword);
                    }
                  },
                  undefined,
                  undefined,
                  true,
                );
              }}
            />
          </div>
        )}
        {changePassword && (
          <>
            <div
              className="pfl_login_card_inputs"
              style={{
                display: "flex",
                flexDirection: "column",
                width: "75%",
                gap: "4px",
                marginTop: "11px",
              }}
            >
              <Input
                error={!!oldPasswordError}
                type={"password"}
                placeholder={"Enter current password"}
                onChange={(event) => setOldPassword(event.target.value)}
              />
              <div className={oldPasswordError ? "error" : "error invisible"}>
                {oldPasswordError}
              </div>
              <Input
                type={"password"}
                error={!!newPassword1Error}
                placeholder={"New Password"}
                onChange={(event) => setNewPassword1(event.target.value)}
              />
              <div className={newPassword1Error ? "error" : "error invisible"}>
                {newPassword1Error}
              </div>
              <Input
                error={!!newPassword2Error}
                type={"password"}
                placeholder={"Confirm New Password"}
                onChange={(event) => setNewPassword2(event.target.value)}
              />
              <div className={newPassword2Error ? "error" : "error invisible"}>
                {newPassword2Error}
              </div>
              <StrengthMeter password={newPassword1} />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <ButtonMui
                style={{ height: "34px" }}
                label="Cancel"
                color="red"
                variant={"contained"}
                onClick={() => setChangePassword(!changePassword)}
              />
              <ButtonMui
                style={{ height: "34px" }}
                label="Save New Password"
                variant={"contained"}
                onClick={() => onSavePassword()}
              />
            </div>
          </>
        )}
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "column",
            paddingBottom: "10px",
          }}
        >
          {/*<div>*/}
          {/*    Refer a friend*/}
          {/*</div>*/}
          <div
            style={{ display: "flex", columnGap: "10px", justifyContent: "center", width: "100%" }}
          >
            {/*<ButtonMui*/}
            {/*    onClick={() => {*/}
            {/*        request.get('/api/get_referral_link')*/}
            {/*            .set('authorization', 'Bearer ' + window.localStorage.getItem('token'))*/}
            {/*            .then((res) => {*/}
            {/*                setClipboard(res.body, () => {*/}
            {/*                    global.Modeliks.showNotification && global.Modeliks.showNotification('The link was successfully copied to the clipboard')*/}
            {/*                })*/}

            {/*            })*/}
            {/*            .catch(console.error)*/}
            {/*    }}*/}
            {/*    // onClick={() => setClipboard(`https://www.modeliks.com?irclickid=modeliks-${global.Modeliks.User.MyReferralID}`)}*/}
            {/*    label={'Copy Link'} variant={'contained'}/>*/}
            {/*{!global.Modeliks.NoAccount && <ButtonMui*/}
            {/*    label={"Earn Credits"}*/}
            {/*    variant={"contained"}*/}
            {/*    onClick={() =>*/}
            {/*        global.Modeliks.showDialog(*/}
            {/*            "",*/}
            {/*            DialogTypes.ReferAFriend,*/}
            {/*            undefined,*/}
            {/*            undefined,*/}
            {/*            undefined,*/}
            {/*            undefined,*/}
            {/*            " refer_a_friend",*/}
            {/*        )*/}
            {/*    }*/}
            {/*/>}*/}
          </div>
        </div>
      </Card>
    </>
  );
}

export function setClipboard(text, callBack) {
  const type = "text/plain";
  const blob = new Blob([text], { type });
  const data = [new ClipboardItem({ [type]: blob })];

  navigator.clipboard
    .write(data)
    .then(
      () => {
        callBack && callBack();
      },
      () => {
        /* failure */
      },
    )
    .catch((e) => {});
}

LoginCard.propTypes = {
  password: PropTypes.string,
  hasTitle: PropTypes.bool,
  size: PropTypes.oneOf(["small", "medium"]),
  email: PropTypes.string,
  User: PropTypes.object,
};

export default LoginCard;
