import MxDriversStore from "../../MxDriversStore";
import CalculatedDriver from "./index";
import datastructure from './../../datastructure'
import {TaxesTypes} from "../Taxes/constants";
import {WorkingCapitalTypes} from "../WorkingCapital/constants";
import {ExpenseTypes} from "../constants";
import ProfitLoss from "../Reports/ProfitLoss";
import CashFlow from "../Reports/CashFlow";
import BalanceSheet from "../Reports/BalanceSheet";
import {ResellType} from "../Assets/constants";
import {DriverCategories, temporary_api_route} from "./constants";
import CalculatedDriver_Values from "./CalculatedDriver_Values";
import request from "superagent";

class DriversStorage extends MxDriversStore {
    dataManager = null;
    driverValuesStorage = null;
    loaded = false;
    data = [];

    setData = (callBack, data = this.data) => {
        this.pushArray(
            data.filter((c) => !this.dataManager.deletedObj[c._id]).map((c) => new CalculatedDriver(c)),
        );
        console.log("this setData", this);
        this.forEach((driver) => {
            if (driver.ID.endsWith("_growth")) {
                const setGrowth =
                    this._indexes[driver.ID.substring(0, driver.ID.length - "_growth".length)];
                if (setGrowth && setGrowth.Formula === driver.ID_f) {
                    setGrowth.GrowthDriver = driver;
                } else {
                    this.dataManager.del(
                        this.dataManager.Tables.Finance_CalculatedDriver_Values.TableName,
                        {
                            ID_Driver: driver.ID,
                            ID_CompanyScenario: driver.ID_CompanyScenario,
                        },
                        () => {
                            this.dataManager.del(
                                this.dataManager.Tables.Finance_CalculatedDrivers.TableName,
                                {
                                    ID: driver.ID,
                                    ID_CompanyScenario: driver.ID_CompanyScenario,
                                },
                                () => {},
                                () => {},
                            );
                        },
                        () => {},
                    );
                }
            }
        });

        //todo: Viktor to make changes here
        // debugger
        this.driverValuesStorage.data.forEach((driverValue) => {
            if (
                this._indexes[driverValue.ID_Driver] &&
                !this._indexes[driverValue.ID_Driver].Values._indexes.hasOwnProperty(driverValue.ID)
            ) {
                this._indexes[driverValue.ID_Driver].Values.push(new CalculatedDriver_Values(driverValue));
            } else if (driverValue.ID_Driver == null) {
                new CalculatedDriver_Values(driverValue);
            }
        });

        //todo: missing growth and sorting of drivers
        // this.forEach(driver => {
        //     // const vals = this.driverValuesStorage.filter(c => c.ID_Driver == driver.ID).sort((a, b) => (this.dataManager.DateHelper.all_periods.indexOf(a.Date) > this.dataManager.DateHelper.all_periods.indexOf(b.Date)) ? 1 : -1);
        //     // driver.Values.pushArray(vals);
        //
        //     if (driver.ID.endsWith('_growth') == false) {
        //         const growhtDriver = this.find(c => c.ID == driver.ID + '_growth');
        //         if (growhtDriver) {
        //             if (driver.Formula == growhtDriver.ID_f) {
        //                 driver.GrowthDriver = growhtDriver;
        //             }
        //             else {
        //                 console.log("deleting old", driver.GrowthDriver);
        //                 this.dataManager.del(this.dataManager.Tables.Finance_CalculatedDriver_Values.TableName, {ID_Driver: growhtDriver.ID, ID_CompanyScenario: growhtDriver.ID_CompanyScenario}, () => {
        //                     this.dataManager.del(this.dataManager.Tables.Finance_CalculatedDrivers.TableName, {ID: growhtDriver.ID, ID_CompanyScenario: growhtDriver.ID_CompanyScenario}, () => {
        //                     }, () => {
        //                     });
        //                 }, () => {
        //                 });
        //             }
        //         }
        //     }
        // });

        // Revenue.getRevenueTotals();
        ProfitLoss.getReport();
        CashFlow.getReport();
        BalanceSheet.getReport();

        // Financing.getOpenningBalanceRetainedEarnings();
        // Financing.getDividendsTotals();
        // CostSale.getCostSaleAndDirectExpenses();
        // CostSale.getCostSaleAndExpenses();
        // Personnel.getPersonnelEmployeeTotals();
        // Financing.getPrepaidRevenueClosingBalance();
        //
        // Assets.getAssetsTotals();
        // Assets.getCurrentAssetsTotals();
        // Assets.getAssetPayableLongTerm();
        //

        // Expense.getDirectCostDrivers();
        // Expense.getTotalOperatingExpenses();
        // Expense.getExpensesDirectCostTotals();
        // Expense.getResearchAndDevelopmentTotals();

        // this.driverValuesStorage.forEach(driverValue => driverValue.IsSimple == false && driverValue.buildFormula());
        this.loaded = true;
        callBack();
    };

    loadDrivers = (callBack, shouldFinish = true) => {
        this.clear();
        this.dataManager.get(
            this.dataManager.Tables.Finance_CalculatedDrivers.TableName,
            { ID_CompanyScenario: this.dataManager.CompanyScenarioInfo.ID },
            (data) => {
                this.data.push(...data);
                if (shouldFinish) {
                    this.setData(callBack);
                } else {
                    callBack();
                }
            },
            null,
            false,
        );
    };

    loadTemporaryCDVs = async (callBack, shouldFinish = true) => {
        let data = await this.getAllTemporary();
        if (!data) {
            console.log("I don't have the data");
            this.dataManager.shouldSaveTemporary = true;
        } else {
            this.dataManager.DriverValuesStore.setTemporaryDriverValues(
                data[this.dataManager.Tables.Finance_CalculatedDriver_Values.TableName.toLowerCase()],
            );
            this.setTemporaryDrivers(
                data[this.dataManager.Tables.Finance_CalculatedDrivers.TableName.toLowerCase()],
            );
        }
        if (shouldFinish) {
            this.setData(callBack);
        } else {
            callBack();
        }
    };

    getAllTemporary = () =>
        new Promise((resolve) => {
            const driversTimeStamp = this.dataManager.cache_routes["/api/cdv/finance_calculateddrivers"];
            const driverValuesTimeStamp =
                this.dataManager.cache_routes["/api/cdv/finance_calculateddriver_values"];
            const driversTableName =
                this.dataManager.Tables.Finance_CalculatedDrivers.TableName.toLowerCase();
            const driverValuesTableName =
                this.dataManager.Tables.Finance_CalculatedDriver_Values.TableName.toLowerCase();

            let lastTimeStamp =
                driversTimeStamp > driverValuesTimeStamp ? driversTimeStamp : driverValuesTimeStamp;
            request
                .get(temporary_api_route)
                .set("Access-Control-Allow-Origin", window.location.protocol + "//" + window.location.host)
                .set("Accept", "application/json")
                .set("authorization", "Bearer " + window.localStorage.getItem("token"))
                .query({
                    ID_CompanyScenario: global.Modeliks.CompanyScenarioInfo.ID,
                    lastTimeStamp,
                    cache: true,
                })
                .then(({ body }) => {
                    if (
                        typeof body == "object" &&
                        body.hasOwnProperty(driversTableName) &&
                        body.hasOwnProperty(driverValuesTableName)
                    ) {
                        resolve({
                            [driversTableName]: body[driversTableName],
                            [driverValuesTableName]: body[driverValuesTableName],
                        });
                    } else {
                        resolve(null);
                    }
                })
                .catch((err) => {
                    resolve(null);
                });
        });

    setTemporaryDrivers = (data) => {
        this.data.push(...data);
    };

    cleanCDVs = (data) => {
        if (Array.isArray(data)) {
            const res = [];
            data.forEach((d) => res.push(this.cleanCDVs(d)));
            return res;
        }

        const tmpData = {};

        Object.keys(data).forEach((key) => {
            if (
                typeof data[key] == "string" ||
                typeof data[key] == "number" ||
                typeof data[key] == "boolean" ||
                data[key] == null
            ) {
                tmpData[key] = data[key];
            }
        });

        return tmpData;
    };

    postAllTemporary = () => {
        const drivers = this.filter((c) => c.IsTemporary && !c._id);
        const driverValues = this.cleanCDVs(
            drivers
                .map((c) =>
                    c.Values.map((c) => ({
                        ...c.cur_record,
                        Value: null,
                        ID_f: c.ID_f,
                    })),
                )
                .flat(),
        );

        const cleanDrivers = this.cleanCDVs(drivers);

        const driversTimeStamp = this.dataManager.cache_routes["/api/cdv/finance_calculateddrivers"];
        const driverValuesTimeStamp =
            this.dataManager.cache_routes["/api/cdv/finance_calculateddriver_values"];

        let lastTimeStamp =
            driversTimeStamp > driverValuesTimeStamp ? driversTimeStamp : driverValuesTimeStamp;

        global.Modeliks.put(
            temporary_api_route,
            {
                ID_CompanyScenario: global.Modeliks.CompanyScenarioInfo.ID,
                lastTimeStamp,
            },
            {
                [this.dataManager.Tables.Finance_CalculatedDrivers.TableName.toLowerCase()]: cleanDrivers,
                [this.dataManager.Tables.Finance_CalculatedDriver_Values.TableName.toLowerCase()]:
                driverValues,
            },
            (res) => {},
            undefined,
            undefined,
            true,
        );
    };

    postAll = (callBack, drivers) => {
        global.Modeliks.post(
            this.dataManager.Tables.Finance_CalculatedDrivers.TableName,
            drivers,
            (res) => {
                const driverValues = drivers.map((c) => c.Values.map((v) => v.cur_record)).flat();
                // let counter = 0;
                // var results = [];
                // while (driverValues.length) {
                //     counter++;
                //     results.push(driverValues.splice(0, 1000));
                // }

                global.Modeliks.post(
                    global.Modeliks.Tables.Finance_CalculatedDriver_Values.TableName,
                    driverValues,
                    (res) => {
                        console.log("created", driverValues);
                        callBack && callBack();
                    },
                );

                // results.forEach((data, i) => {
                //     global.Modeliks.post(global.Modeliks.Tables.Finance_CalculatedDriver_Values.TableName, data, (res) => {
                //         if (callBack && i + 1 === counter) {
                //             callBack();
                //         }
                //     })
                // })
            },
        );
    };

    putAll = (callBack, drivers) => {
        let driverCounter = 0;
        drivers.forEach((driver) => {
            driver.Save(() => {
                driverCounter++;
                if (drivers.length == driverCounter) {
                    if (callBack) {
                        callBack();
                    }
                }
            });
        });
    };

    saveAll = (callBack) => {
        // const driversToSave = this.filter(c => c.hasChanged && !(c.Ref_Field.endsWith('growth') && c.DriverName !== 'Growth') && !c.Ref_ID.toString().startsWith('new') && c.Ref_Field !== 'totals' && c.Ref_Field !== 'temp_totals' && c.Ref_Table !== this.dataManager.Tables.Finance_Reports.TableName);
        const driversToSave = this.filter(
            (c) =>
                c.hasChanged &&
                !c.IsTemporary &&
                c.Ref_Table !== this.dataManager.Tables.Finance_Reports.TableName &&
                c.Ref_Field !== "totals" &&
                c.Ref_Table !== "Valuation_Settings" &&
                !c.IsDisabledSave,
        );
        console.log("driversToSave", driversToSave);

        if (driversToSave.length > 0) {
            const driversToPost = driversToSave.filter((d) => d.db_record === null);
            const driversToPut = driversToSave.filter((d) => d.db_record);
            if (driversToPost.length > 0) {
                this.postAll(() => {
                    if (driversToPut.length > 0) {
                        this.putAll(callBack, driversToPut);
                    } else {
                        callBack();
                    }
                }, driversToPost);
            } else {
                this.putAll(callBack, driversToPut);
            }

            // global.Modeliks.post(this.dataManager.Tables.Finance_CalculatedDrivers.TableName,   driversToSave.map(c => c), (res) => {
            //     const driverValues = driversToSave.map(c => c.Values.map( v => v.cur_record)).flat();
            //     let counter = 0;
            //     var results = [];
            //     while (driverValues.length) {
            //         counter++;
            //         results.push(driverValues.splice(0, 1000));
            //     }
            //     results.forEach((data, i) => {
            //         global.Modeliks.post(global.Modeliks.Tables.Finance_CalculatedDriver_Values.TableName, data, (res) => {
            //             if (callBack && i + 1 === counter ) {
            //                 callBack();
            //             }
            //         })
            //     })
            // console.log('drivers', driversToSave);
            // let driverCounter = 0;
            // driversToSave.forEach(driver => {
            //     driver.Save(() => {
            //         driverCounter++;
            //         if (driversToSave.length == driverCounter) {
            //             if (callBack) {
            //                 callBack()
            //             }
            //         }
            //     })
            // });
        } else {
            if (callBack) {
                callBack();
            }
        }
    };

    updateIDs = (refID, refTable, oldID) => {
        const driversToChange = this.filter((c) => c.Ref_ID == oldID && c.Ref_Table == refTable);
        console.log("updateIDs", driversToChange, refID, refTable, oldID);
        driversToChange.forEach((driver) => driver.changeID(refID));
    };

    createDriversFrom = (ID, TableName, Totals, Stream) => {
        if(TableName === datastructure.Finance_Assets.TableName){
            const SalesTax = global.Modeliks.TaxesStore.find(d => d.TaxType === TaxesTypes.SalesTax);
            const VatTax = global.Modeliks.TaxesStore.find(d => d.TaxType === TaxesTypes.VAT);
            if (SalesTax) {
                SalesTax.createDriversForAssets(Stream.AssetSales, true);
            }
            if (VatTax) {
                VatTax.createDriversForAsset(Stream.AssetPurchases, true);

                if(Stream.ResellType === ResellType.Yes){
                    VatTax.createDriversForAssetResell(Stream.AssetSales, true);
                }
            }
        }

        if (TableName === datastructure.Finance_Revenues.TableName && !Stream.ID_ParentRevenue) {
            // revenue
            const SalesTax = global.Modeliks.TaxesStore.find(d => d.TaxType === TaxesTypes.SalesTax);
            const AccountReceivable = global.Modeliks.WorkingCapitalStore.find(d => d.Type === WorkingCapitalTypes.AccountReceivable);
            const VatTax = global.Modeliks.TaxesStore.find(d => d.TaxType === TaxesTypes.VAT);

            if (SalesTax) {
                SalesTax.createDriversForRevenue(Totals);
                // SalesTax.setTax();
            }

            if (VatTax) {
                VatTax.createDriversForRevenue(Totals);
                // VatTax.setTax();
            }

            if (AccountReceivable) {
                AccountReceivable.getRevenueCreditSalesValue(ID, datastructure.Finance_Revenues.TableName);
                AccountReceivable.getRevenueReceivableDaysValue(ID, datastructure.Finance_Revenues.TableName);

            }
        }

        if (TableName === datastructure.Finance_CostSales.TableName) {
            const Inventory = global.Modeliks.WorkingCapitalStore.find(d => d.Type === WorkingCapitalTypes.Inventory);
            const AccountPayable = global.Modeliks.WorkingCapitalStore.find(d => d.Type === WorkingCapitalTypes.AccountPayable);
            const VatTax = global.Modeliks.TaxesStore.find(d => d.TaxType === TaxesTypes.VAT);

            if (VatTax) {
                VatTax.createDriversForCost(Totals)
                // VatTax.setTax();
            }

            if (Inventory) {
                Inventory.getCostCreditSalesValue(ID, datastructure.Finance_CostSales.TableName);
                Inventory.getCostInventoryDaysValue(ID, datastructure.Finance_CostSales.TableName);

            }


            if (AccountPayable) {
                AccountPayable.getCostExpenseCreditPurchasesValue(ID, datastructure.Finance_CostSales.TableName);
                AccountPayable.getCostExpensePayableDaysValue(ID, datastructure.Finance_CostSales.TableName);
            }

        }

        if (TableName === datastructure.Finance_Expenses.TableName) {

            const Inventory = global.Modeliks.WorkingCapitalStore.find(d => d.Type === WorkingCapitalTypes.Inventory);
            const AccountPayable = global.Modeliks.WorkingCapitalStore.find(d => d.Type === WorkingCapitalTypes.AccountPayable);
            const VatTax = global.Modeliks.TaxesStore.find(d => d.TaxType === TaxesTypes.VAT);

            if (VatTax) {
                VatTax.createDriversForCost(Totals)
                // VatTax.setTax();
            }


            if (AccountPayable) {
                AccountPayable.getCostExpenseCreditPurchasesValue(ID, datastructure.Finance_Expenses.TableName);
                AccountPayable.getCostExpensePayableDaysValue(ID, datastructure.Finance_Expenses.TableName);
            }

            if (Inventory && Stream.ExpenseType === ExpenseTypes.DirectCost) {
                Inventory.getCostCreditSalesValue(ID, datastructure.Finance_Expenses.TableName);
                Inventory.getCostInventoryDaysValue(ID, datastructure.Finance_Expenses.TableName);
            }


        }
    };

    getDeleteDrivers = (driver = null, arrDrivers = []) => {
        let drs = null;
        if (driver && driver.hasOwnProperty("getChildDrivers") && !arrDrivers.includes(driver)) {
            const drivers = driver.getChildDrivers();
            if (drivers && drivers.length > 0) {
                drs = drivers;
            }
        }

        if (drs) {
            drs.forEach((d) => {
                if (d && d.Ref_Field === "value" && d.db_record === null) {
                    global.Modeliks.DriversStore.remove(d);
                }
                arrDrivers.push(d);
                this.getDeleteDrivers(d, arrDrivers);
            });
        }
    };

    getCircleRefDrivers = (objectOfDrivers, editMode = false) => {
        const searchRefTableNames = [
            global.Modeliks.Tables.Finance_WorkingCapital.TableName,
            global.Modeliks.Tables.Finance_Reports.TableName,
        ];

        const searchRefID = ["reporting_drivers"];

        const drivers = Object.values(objectOfDrivers).filter((driver) => {
            if (!driver) {
                return false;
            }
            if (driver.IsSimple) {
                return false;
            }

            if (
                driver.IsExisting === false ||
                driver.IsTemporary ||
                driver.Ref_Field === "totals" ||
                driver.Ref_Field === "total" ||
                driver.IsFormulaEditable === false ||
                driver.DriverName === "Number Of New Employees (delta)"
            ) {
                return false;
            }
            if (searchRefTableNames.includes(driver.Ref_Table) || searchRefID.includes(driver.Ref_ID)) {
                return false;
            }

            return driver;
        });

        return drivers;
    };

    GetParentDriversRecursion = (value, foundedDrivers = []) => {
        if (value && value.parentRels.length > 0 && !foundedDrivers[value.ID_Driver]) {
            foundedDrivers[value.ID_Driver] = global.Modeliks.DriversStore.getItem(value.ID_Driver);
            // value.parentRels.forEach(v => this.GetParentDriversRecursion(v, foundedDrivers))
        }
    };

    CircularRefDriversByStreamType = (driver, streamType) => {
        if (streamType) {
            const revenuesDrivers = [
                "Finance_Revenues-cash_collections-totals",
                "Finance_Revenues-billablehours_totals-totals",
                "Finance_Revenues-unitsales_totals-totals",
                "Finance_Revenues-subscription_report-total",
                "Finance_Revenues-total_customers_due_payment-totals",
                "Finance_Revenues-customers_at_end_of_period_reports_totals-totals",
                "Finance_Revenues-customers_at_start_of_period_reports_totals-totals",
                "Finance_Revenues-churned_customers_reports_totals-totals",
                "Finance_Revenues-number_of_refunds_totals-totals",
                "Finance_Revenues-signups_totals-totals",
                "Finance_Revenues-single_price_totals-totals",
                "Finance_Revenues-churn_rate_totals-totals",
            ];
            const costSalesDrivers = [
                "Finance_CostSales-cost_group_totals-totals",
                "Finance_CostSales-cost_margin_from_revenue-totals",
                "Finance_Revenues-0-totals",
            ];

            if (streamType.Ref_Table === global.Modeliks.Tables.Finance_Revenues.TableName) {
                return revenuesDrivers.map((d) => this._indexes[d]);
            }

            if (streamType.Ref_Table === global.Modeliks.Tables.Finance_CostSales.TableName) {
                return [...revenuesDrivers, ...costSalesDrivers].map((d) => this._indexes[d]);
            }

            if (streamType.Ref_Table === global.Modeliks.Tables.Finance_Personnel.TableName) {
                const personnelDrivers = [
                    "Finance_CostSales-0-totals",
                    "Finance_Personnel-total_number_of_emp-totals",
                    "Finance_Personnel-direct-labor-totals",
                    "Finance_Personnel-sales-and-marketing-totals",
                    "Finance_Personnel-research-and-development-totals",
                    "Finance_Personnel-general-and-administrative-totals",
                    "Finance_Personnel-direct-labor-number-totals",
                    "Finance_Personnel-sales-and-marketing-number-totals",
                    "Finance_Personnel-research-and-development-number-totals",
                    "Finance_Personnel-general-and-administrative-number-totals",
                    "Finance_Personnel-direct-labor-cost-totals",
                    "Finance_Personnel-sales-and-marketing-cost-totals",
                    "Finance_Personnel-research-and-development-cost-totals",
                    "Finance_Personnel-general-and-administrative-cost-totals",
                    "Finance_Personnel-total_emp_cost_divided-totals",
                ];

                return [...revenuesDrivers, ...personnelDrivers, ...costSalesDrivers].map(
                    (d) => this._indexes[d],
                );
            }

            if (streamType.Ref_Table === global.Modeliks.Tables.Finance_Expenses.TableName) {
                const expensesDrivers = [
                    "Finance_CostSales-0-totals",
                    "Finance_Expenses-personnel_expenses-totals",
                    "Finance_Expenses-operating_expenses-totals",
                    "Finance_Expenses-direct_cost_driver-totals",
                    "Finance_Expenses-sales_and_marketing_totals-totals",
                    "Finance_Expenses-general_and_administrative_totals-totals",
                    "Finance_Expenses-research_and_development_totals-totals",
                    "Finance_Expenses-sales-and-marketing-expenses-totals",
                    "Finance_Expenses-research-and-development-expenses-totals",
                    "Finance_Expenses-general-and-administrative-expenses-totals",
                ];

                return [...revenuesDrivers, ...expensesDrivers, ...costSalesDrivers].map(
                    (d) => this._indexes[d],
                );
            }

            if (streamType.Ref_Table === global.Modeliks.Tables.Finance_Assets.TableName) {
                const assetsDrivers = [
                    "Finance_Assets-depreciation_driver-totals",
                    "Finance_Assets-other_assets_driver-totals",
                    "Finance_Assets-long_term_assets_driver-totals",
                ];

                return [...assetsDrivers].map((d) => this._indexes[d]);
            }

            if (streamType.Ref_Table === global.Modeliks.Tables.Finance_Financing.TableName) {
                const financingDrivers = [
                    "Finance_Financing-interest_expense_driver-totals",
                    "Finance_Financing-shareholder_eq_driver-totals",
                    "Finance_Financing-current_other_liability-totals",
                    "Finance_Financing-total_principal_repayments-totals",
                ];

                return [...financingDrivers].map((d) => this._indexes[d]);
            }

            return [];
        }

        return [];
    };

    getFormulaBuildDrivers = (driverID, streamType, selectedDrivers = []) => {
        const searchRefTableNames = [global.Modeliks.Tables.Finance_WorkingCapital.TableName];
        const searchRefID = ["reporting_drivers"];
        const searchRefField = ["growth"];
        const connectedDrivers = [];
        const AllValues = [];
        const AllowedCategories = [DriverCategories.LastPeriod, DriverCategories.FirstPeriod];
        let selectionDrivers = [
            ...selectedDrivers,
            ...this.CircularRefDriversByStreamType(driverID, streamType),
        ];

        if (driverID) {
            AllValues.push(...this._indexes[driverID].Values);
        }

        if (streamType && streamType.Values) {
            AllValues.push(...streamType.Values);
        }

        if (selectionDrivers && selectionDrivers.length > 0) {
            AllValues.push(...selectionDrivers.flatMap((c) => c.Values));
        }

        if (AllValues && AllValues.length > 0) {
            AllValues.forEach((v) => this.GetParentDriversRecursion(v, connectedDrivers));
        }

        return this.filter((driver, index) => {
            if (driver.ID === driverID) {
                return false;
            }
            if (
                connectedDrivers[driver.ID] &&
                !AllowedCategories.includes(connectedDrivers[driver.ID].DriverCategory)
            ) {
                return false;
            }
            if (streamType && streamType.DriverName === driver.DriverName) {
                return false;
            }
            if (
                driver.IsExisting === false ||
                searchRefTableNames.includes(driver.Ref_Table) ||
                searchRefID.includes(driver.Ref_ID) ||
                searchRefField.includes(driver.Ref_Field)
            ) {
                return false;
            }

            return driver;
        });
    };

    static create = () => {
        const newArr = new DriversStorage();

        newArr.pushOld = newArr.push;
        newArr.push = newArr.pushThis;

        return newArr;
    };
}

export default DriversStorage;
