import { lastUpdatedKeyWord } from "../data/DataManager";

export const versioningActionTypes = {
  refresh: "refresh",
  clearLocalStorage: "clearLocalStorage",
  logout: "logout",
};

export const versioningActions = {
  refresh: () => window.location.reload(),

  clearLocalStorage: () => {
    const keysForDeleting = Object.keys(window.localStorage).filter((c) =>
      c.includes(lastUpdatedKeyWord),
    );
    keysForDeleting.forEach((key) => window.localStorage.removeItem(key));
  },

  logout: () => global.Modeliks.LogOut(),
};

export const versioningActionsCaller = (keys = []) => {
  keys.forEach((key) => {
    if (versioningActions.hasOwnProperty(key)) {
      versioningActions[key]();
    }
  });
};
