const PermissionTypes = {
  Pitch: "Pitch",
  BusinessPlan: "BusinessPlan",
  Financials: "Financials",
  Dashboards: "Dashboards",
  Valuation: "Valuation",
  Companies: "Companies",
  Users: "Users",
  Subscriptions: "Subscriptions",
};

const RoutesPerPermission = {
  [PermissionTypes.Pitch]: "/pitch",
  [PermissionTypes.BusinessPlan]: "/business_plan",
  [PermissionTypes.Financials]: "/forecast",
  [PermissionTypes.Dashboards]: "/dashboards",
  [PermissionTypes.Valuation]: "/valuation",
  [PermissionTypes.Companies]: "/profile/companies",
  [PermissionTypes.Users]: "/profile/users",
  [PermissionTypes.Subscriptions]: "/profile/account_details",
};

const UserTypes = {
  Viewer: "Viewer",
  Editor: "Editor",
  Owner: "Owner",
};

const PermissionLevels = {};

export { PermissionTypes, UserTypes, RoutesPerPermission };
