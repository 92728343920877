import React from "react";
import "./VerificationSentCheckInbox.scss";
import {
  LoginBottomRightCornerBigShape,
  LoginTopLeftCornerBigShape,
  MobileTitleShape,
} from "../../../../../components/icons/svgIcons";
import ButtonMui from "../../../../../components/buttons/buttonMui/buttonMui";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BackgroundBalls from "../../components/BackgroundBalls";
import request from "superagent";
import { sendReqForPasswordReset } from "../../Reset/ForgottenPassword";
import Mx_MainLoader from "../../../../../components/Loaders/MainLoader/Mx_MainLoader";
import { CopyrightMsg, ModeliksHeaderLogo } from "../../Register/RegisterPage";

function VerificationSentCheckInbox({ secondTextOption }) {
  const [loading, setLoading] = React.useState(false);
  const resendVerification = () => {
    setLoading(true);
    request
      .post("/api/resendVerification")
      .send({ User_ID: parseInt(window.localStorage.getItem("User_ID")) })
      .then(() => {
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.error("error adding account", e);
      });
  };

  const resendForgottenPassword = () => {
    if (global.Modeliks.ForgottenPasswordEmail) {
      setLoading(true);
      sendReqForPasswordReset(
        { Email: global.Modeliks.ForgottenPasswordEmail },
        () => {
          setLoading(false);
        },
        (err) => {
          global.Modeliks.NavigateTo("/reset");
        },
      );
    }
  };

  const getContent = () => {
    if (secondTextOption) {
      return (
        <>
          <div className="check-inbox-title">
            <span>Email Sent</span>
          </div>

          <div className="check-inbox-desc-text">
            We sent you an email containing a link to reset your password.
          </div>
          <div className="check-inbox-desc-text">
            {/*Once activated, you’ll be able to continue.{" "}*/}
          </div>
          <div className="tip-desc-text">
            Tip: Check your spam folder in case the email was incorrectly identified.{" "}
          </div>

          <div className="sign-up-content">
            <div className="no-acc">
              <span className="no-acc-text">Didn’t receive an email?</span>
              <ButtonMui
                label="Resend"
                width={90}
                variant={"contained"}
                onClick={resendForgottenPassword}
              />
            </div>
          </div>

          <div className="check-inbox-action-btns-wrapper">
            <div className="check-inbox-btn">
              <ButtonMui
                label="Back to log In"
                width="100%"
                variant={"contained"}
                onClick={() => global.Modeliks.NavigateTo("/login")}
                startIcon={<ArrowBackIcon />}
              />
            </div>
          </div>
        </>
      );
    }
    return (
      <>
        <div className="check-inbox-title">
          <span>Check Your </span> <span> Email Inbox</span>
        </div>

        <div className="check-inbox-desc-text">
          We sent you an email containing a link to activate your account.
        </div>
        <div className="check-inbox-desc-text">Once activated, you’ll be able to continue. </div>
        <div className="tip-desc-text">
          Tip: Check your spam folder in case the email was incorrectly identified.{" "}
        </div>

        <div className="sign-up-content">
          <div className="no-acc">
            <span className="no-acc-text">Didn’t receive an email?</span>
            <ButtonMui
              label="Resend"
              width={90}
              variant={"contained"}
              onClick={resendVerification}
            />
          </div>
        </div>

        <div className="check-inbox-action-btns-wrapper">
          <div className="check-inbox-btn">
            <ButtonMui
              label="Back to log In"
              width="100%"
              variant={"contained"}
              onClick={() => global.Modeliks.NavigateTo("/login")}
              startIcon={<ArrowBackIcon />}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="check-inbox-wrapper">
      {loading && <Mx_MainLoader className={"blurred"} />}
      <div className="check-inbox-content-wrapper">
        <div className="content">
          <div className="logo">
            <ModeliksHeaderLogo />
          </div>

          <div className="check-inbox-content">{getContent()}</div>
          <CopyrightMsg />
        </div>
      </div>
      <div className="top-left-shape">
        <LoginTopLeftCornerBigShape></LoginTopLeftCornerBigShape>
      </div>
      <div className="bottom-right-shape">
        <LoginBottomRightCornerBigShape></LoginBottomRightCornerBigShape>
      </div>
      <div className="mobile-title-shape">
        <MobileTitleShape></MobileTitleShape>
      </div>

      <BackgroundBalls
        bigRightTopBall
        smallRightTopBall
        bigLeftTopBall
        smallLeftTopBall
        smallCenterMidBall
        smallLeftCenterBall
        smallRightCenterBall
        bigRightCenterBall
        smallCenterTopBall
        bigLeftBottomBall
        smallRightBottomBall
      />
    </div>
  );
}

export default VerificationSentCheckInbox;
