import React, { useState, useRef } from "react";
import "../HeaderTextEditor.scss";
import "../HeaderDefault.scss";
import { Tables, Columns, Rows } from "../../components/IconButtons/SubMenuIcons";
import "./InsertTableComponent.css";
import { useMenuState, MenuButton, ControlledMenu, SubMenu, MenuItem } from "@szhsin/react-menu";
import Tooltip from "@mui/material/Tooltip";
function InsertTableComponent(props) {
  const ref = useRef(null);
  const [menuProps, toggleMenu] = useMenuState({ transition: false });

  const [rows, setRows] = React.useState(1);
  const [cols, setCols] = React.useState(1);
  const column = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const row = [1, 2, 3, 4, 5, 6, 7, 8];
  const [tempRows, setTempRows] = React.useState(1);
  const [tempCols, setTempCols] = React.useState(1);
  const [isTableSizeSet, setIsTableSet] = React.useState(false);

  const setTempTableSize = (i, j) => {
    setTempCols(j);
    setTempRows(i);
  };

  const setTableSize = (i, j) => {
    setCols(j);
    setRows(i);
  };

  const checkForSelected = (i, j) => {
    if (tempRows >= i && tempCols >= j) {
      return "#01AFFD";
    } else if (rows >= i && cols >= j) {
      return "#01AFFD";
    } else return "#D4D5DE";
  };

  const handleClose = () => {
    toggleMenu(false);
  };

  const AddNewTable = (i, j) => {
    // handleClose();
    props.onAddTable(j, i);
  };

  // const handleClick = () => {
  //     if(menuProps.state == 'open'){
  //         toggleMenu(false)
  //     } else {
  //         toggleMenu(true)
  //     }
  // }
  const TableInsert = () => {
    return (
      <>
        {/*<Tooltip disableHoverListener={props.disableEdit} arrow title={props.tooltipLabel}><span>*/}
        {/**/}
        {/*</span></Tooltip>*/}
        <SubMenu
          label={({ open }) => (
            <span>
              <Tables active={open} />
            </span>
          )}
          onClose={() => handleClose()}
        >
          <div className={"tableInsert"}>
            {row.map((elem, i) => {
              return (
                <div className={"tableInsertGridRow"} key={i + 1}>
                  {column.map((el, j) => {
                    return (
                      <MenuItem
                        className={"tableInsertSquare"}
                        style={{ borderColor: checkForSelected(i + 1, j + 1) }}
                        key={[i + 1, j + 1]}
                        onClick={(e) => {
                          AddNewTable(i + 1, j + 1);
                        }}
                        onMouseOver={() => setTempTableSize(i + 1, j + 1)}
                      />
                    );
                  })}
                </div>
              );
            })}

            {/*<div className={'tableInsertRow'}>*/}
            {/*<div className={'CRInsert'}>*/}
            {/*<Columns/>*/}
            {/*<p>Columns</p>*/}
            {/*<input className={'tableInput'} onChange={(e) => setCols(e.target.value)} value={cols}/>*/}
            {/*</div>*/}
            {/*<div className={'CRInsert'}>*/}
            {/*<Rows/>*/}
            {/*<p>Rows</p>*/}
            {/*<input className={'tableInput'} onChange={(e) => setRows(e.target.value)} value={rows}/>*/}
            {/*</div>*/}
            {/*</div>*/}
            {/*<div className={'tableInsertRow'}>*/}
            {/*<MenuItem className={'bottomRow'}  onClick={()=>{*/}
            {/*setTableSize(1,1)*/}
            {/*}}>Cancel</MenuItem>*/}
            {/*<MenuItem className={'bottomRow'} style={{color:'#01AFFD'}} onClick={()=>{*/}
            {/*AddNewTable()*/}
            {/*}}>Insert</MenuItem>*/}
            {/*</div>*/}
          </div>
        </SubMenu>

        {/*<Menu menuButton={({ open }) =>}>*/}

        {/*</Menu>*/}
      </>
    );
  };

  return <>{TableInsert()}</>;
}

export default InsertTableComponent;
