import React from "react";
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-dragdata";
import ChartDataLabels from "chartjs-plugin-datalabels";
import PropTypes from "prop-types";
import { DataTypes } from "../ChartComponents/DataTypes";

import {
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import StackedBarChart from "./StackedBarChart";
import HorizontalClusteredBarChart from "./HorizontalClusteredBarChart";

ChartJS.register(
  CategoryScale,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
);

class HorizontalBarChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        labels: props.labels,
        datasets: [
          {
            label: props.dataset ? props.dataset : "series1",
            backgroundColor: props.color ? props.color : "#01AFFD",
            categoryPercentage: 0.7,
            barPercentage: 0.4,
            minBarLength: 2,
            borderRadius: 5,
            data: props.data,
          },
        ],
      },
      options: this.buildOptions(this.props),
    };

    this.ref = null;
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    if (JSON.stringify(nextProps) !== JSON.stringify(this.props) && !this.imgSrc) {
      this.ref.options = this.buildOptions(nextProps);
      this.buildData(nextProps);
      this.ref.update();
      return true;
    }

    return false;
  };

  componentDidMount() {
    this.buildData();
  }

  buildOptions = (nextProps) => {
    if (this.props.preview && this.props.businessPlan) {
      if (window.chartsForRender.indexOf(this.buildOptions) === -1) {
        window.chartsForRender.push(this.buildOptions);
      }
    }

    let options = {
      type: "bar",
      barRoundness: 0.3,
      devicePixelRatio: 2.0,
      maintainAspectRatio: nextProps.chartOptions.lockedAspect
        ? nextProps.chartOptions.lockedAspect
        : false,
      indexAxis: "y",
      layout: {
        padding: {
          right: 80,
          bottom: 5,
        },
      },
      animation: {
        duration: 1,
        onComplete: (animation) => {
          if (this.props.preview && this.props.businessPlan && this.ref) {
            this.imgSrc = this.ref.toBase64Image();
            this.forceUpdate(() => {
              window.amountOfChartsRendered++;
              global.Modeliks.NotifySubsctiptions("onChartRenderFinish");
            });
          }
        },
      },
      scales: {
        x: {
          grid: {
            display: nextProps.chartOptions.xGrid ? nextProps.chartOptions.xGrid : false,
            drawBorder: nextProps.chartOptions.xGrid ? nextProps.chartOptions.xGrid : true,
          },
          ticks: {
            display: nextProps.chartOptions.showHorizontalAxis,
            maxTicksLimit: 6,
            color: "#889299",
            font: {
              weight: 400,
              style: "normal",
              size: nextProps.chartOptions.axisFontSize,
              lineHeight: "160%",
            },
          },
        },
        y: {
          grid: {
            display: nextProps.chartOptions.yGrid ? nextProps.chartOptions.yGrid : false,
            drawBorder: nextProps.chartOptions.xGrid ? nextProps.chartOptions.xGrid : true,
          },
          ticks: {
            display: nextProps.chartOptions.showVerticalAxis,
            color: "#889299",
            font: {
              weight: 400,
              style: "normal",
              size: nextProps.chartOptions.axisFontSize,
              lineHeight: "160%",
            },
          },
        },
      },
      plugins: {
        tooltip: {
          titleFont: {
            size: 14,
          },
          bodyFont: {
            size: 14,
          },
        },
        title: {
          display: nextProps.chartOptions.showTitle ? nextProps.chartOptions.showTitle : false,
          text:
            nextProps.chartOptions.title &&
            nextProps.chartOptions.titleAlign &&
            nextProps.chartOptions.titleAlign === "start"
              ? nextProps.chartOptions.title
              : nextProps.chartOptions.title
                ? nextProps.chartOptions.title
                : "",
          align: nextProps.chartOptions.titleAlign ? nextProps.chartOptions.titleAlign : "center",

          color: "#000000",

          font: {
            size: nextProps.chartOptions.titleFontSize,
          },
          padding: {
            top: 5,
            bottom:
              nextProps.chartOptions.showSubtitle && nextProps.chartOptions.showSubtitle === true
                ? 0
                : 5,
          },
        },
        subtitle: {
          display: nextProps.chartOptions.showSubtitle
            ? nextProps.chartOptions.showSubtitle
            : false,
          text:
            nextProps.chartOptions.subtitle &&
            nextProps.chartOptions.titleAlign &&
            nextProps.chartOptions.titleAlign === "start"
              ? nextProps.chartOptions.subtitle
              : nextProps.chartOptions.subtitle
                ? nextProps.chartOptions.subtitle
                : "",
          align: nextProps.chartOptions.titleAlign ? nextProps.chartOptions.titleAlign : "center",
          padding: {
            bottom: 5,
          },
          font: {
            size: nextProps.chartOptions.subTitleFontSize,
          },
        },
        datalabels: {
          anchor: "end",
          align: "end",
          formatter: function (value, ctx) {
            if (nextProps.chartOptions.showDataLabels) {
              let val = -1;
              let sign = "";

              if (value > 999999999) {
                val = value / 1000000000;
                sign = "B";
              } else if (value > 999999) {
                val = value / 1000000;
                sign = "M";
              } else if (value > 999) {
                val = value / 1000;
                sign = "K";
              } else {
                val = value;
              }

              return (
                (nextProps.chartOptions.dataType === "currency"
                  ? DataTypes[nextProps.chartOptions.dataType]
                  : "") +
                val.toLocaleString(undefined, {
                  minimumFractionDigits: nextProps.chartOptions.datalabelsDecimalPoints,
                  maximumFractionDigits: nextProps.chartOptions.datalabelsDecimalPoints,
                }) +
                sign +
                (nextProps.chartOptions.dataType === "percent"
                  ? DataTypes[nextProps.chartOptions.dataType]
                  : "")
              );
            } else {
              return "";
            }
          },
          color: "#252525",
          font: {
            size: nextProps.chartOptions.dataLabelsFontSize,
            weight: "700",
            lineHeight: "17px",
          },
        },
        legend: {
          display: nextProps.chartOptions.displayLegend
            ? nextProps.chartOptions.displayLegend
            : false,
          position: nextProps.chartOptions.legendPosition
            ? nextProps.chartOptions.legendPosition
            : "bottom",
          align: "center",
          labels: {
            usePointStyle: true,
            boxWidth: 12,
            fontSize: nextProps.chartOptions.legendFontSize,
          },
        },
      },
    };

    return options;
  };

  buildData = (nextProps = null) => {
    let labels = [];
    let datasets = [];
    let colors = this.props.colors;
    let rows = this.props.data;

    rows.forEach((row, index) => {
      if (index !== 0) {
        labels.push(row[0]);
      }
    });

    rows[0].forEach((ser, index) => {
      if (index !== 0) {
        let obj = {
          label: "",
          backgroundColor: "",
          categoryPercentage: 1,
          barPercentage:
            nextProps && nextProps.chartOptions.gapWidth
              ? nextProps.chartOptions.gapWidth / 10
              : this.props.chartOptions.gapWidth
                ? this.props.chartOptions.gapWidth / 10
                : 0.3,
          minBarLength: 2,
          borderRadius: 5,
          data: [],
        };
        obj.label = ser;
        datasets.push(obj);
      }
    });

    for (let i = 1; i < rows[0].length; i++) {
      let data = [];
      rows.forEach((row, index) => {
        if (index !== 0) {
          let parsedData = parseFloat(row[i]);
          if (parsedData % 1 === 0) {
            data.push(parseInt(row[i]));
          } else {
            data.push(parsedData);
          }
        }
      });
      datasets[i - 1].data = data;
      datasets[i - 1].backgroundColor = colors[0];
    }

    this.state.data.datasets = datasets;
    this.state.data.labels = labels;
  };

  setRef = (ref) => {
    this.ref = ref;
  };

  render() {
    if (this.imgSrc) {
      return <img src={this.imgSrc} style={{ height: "100%", width: "100%" }} />;
    }
    return (
      <Bar
        type={"bar"}
        data={this.state.data}
        options={this.state.options}
        ref={this.setRef}
        style={{
          background: this.props.chartOptions.backgroundColor,
          border: `2px solid ${this.props.chartOptions.borderColor}`,
        }}
      />
    );
  }
}

HorizontalBarChart.config = {
  data: [
    ["", "ser1"],
    ["Label 1", "1"],
    ["Label 2", "2"],
    ["Label 3", "3"],
    ["Label 4", "4"],
  ],
  colors: ["red"],
  dataGridConfig: {
    title: "",
    subtitle: "",
    canAddRows: true,
    canAddColumns: false,
    hasColumnHeaders: true,
    hasRowHeaders: true,
    RowHeader: "New label",
    rowSeries: true,
  },
  dataLalbelsConfig: {
    hideSeries: false,
    dataLabelsOptions: false,
  },
  legendAndGridlinesConfig: {
    hasGridlines: true,
  },
  gapWidthConfig: {
    haveGapWidth: true,
  },
  defaultConfig: {
    gapWidth: 5,
  },
};

HorizontalBarChart.propTypes = {
  data: PropTypes.array.isRequired,
  colors: PropTypes.array.isRequired,
};

export default HorizontalBarChart;
