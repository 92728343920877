const CostOfSalesTypes = {
  UnitSales: "unit",
  Totals: "totals",
};

const CostOfSalesGeneral = {
  General: "general-cost",
};

export { CostOfSalesTypes, CostOfSalesGeneral };
