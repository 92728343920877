import React, { useState } from "react";
import styled, { keyframes, css } from "styled-components";
import PropTypes from "prop-types";
import { Theme } from "../../Theme/Theme";

import { ArrowLeft, ArrowRight, IconPlus, InfoCircle, PlayCircle } from "../Icons/Icons";
import {
  DownwardArrow,
  DownwardArrowBlue,
  UpwardArrow,
  UpwardArrowBlue,
} from "../IconButtons/SubMenuIcons";

const flash = keyframes`
  from {
      opacity: 0;
      }

      to {
      opacity: 1;
      }
`;

const styles = css`
  background-color: pink;
  animation: ${flash} 0.3s linear 3;
`;

const Typography = styled.label`
  color: ${(props) => (props.color ? props.colors[props.color] : Theme.colors.greyscale.white)};
  ${(props) => (props.size === "large" ? Theme.text.label.label2 : Theme.text.label.label3)};
  cursor: pointer;
  font-family: Inter;
  animation: ${(props) => (props.animate ? styles : "")};

  text-decoration: ${(props) => (props.underlined ? "underline" : "")};
`;

const IconWrapper = styled.div`
  ${(props) => props.iconLeft && `margin-right: 17px`};
  self-align: center;
  align: center;
  ${(props) => props.iconRight && `margin-left: 19px;`};
  margin-top: 2px;
  color: #ffffff;
`;
const StyledButton = styled.button`
  ${(props) => props.size && Theme.button[props.size]};
  ${(props) => !props.noElevation && Theme.effect.elevation};
  background: ${(props) => (props.backgroundColor ? props.colors[props.backgroundColor] : "none")};
  border: ${(props) =>
    props.outlined !== false ? `1px solid ${props.colors[props.outlined]} !important` : "none"};
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${(props) => (props.width !== false ? `width: ${props.width}` : "")};
  align-items: center;
  white-space: nowrap;
  text-align: center;
  padding: ${(props) => (props.padding ? props.padding : `6px 12px 6px 30px`)};
  cursor: pointer;

  ${(props) =>
    props.disable === false &&
    `
       &:active {
          background-color: white;
          box-shadow: 0 0.01px #white;
          transform: translateY(1px);
        }
        
       
        &:hover {
                background: #FFFFFF;
                color: ${props.colors[props.backgroundColor]};
                background: ${props.noElevation ? props.colors[props.color + "light"] : `#FFFFFF`};
                transition: 2;
                ${Typography}{
                    color: ${props.disable === false ? props.colors[props.backgroundColor] : props.outlined !== false ? props.colors[props.outlined] : "#FFFFFF"};
                }
                ${IconWrapper}{
                    color: ${props.disable === false ? props.colors[props.backgroundColor] : "#FFFFFF"};
                }
        }`};

  ${(props) =>
    props.disable === true &&
    `
    
        background: ${props.colors[props.backgroundColor + "light"]};
        box-shadow: none;
        &:hover{
           background: ${props.colors[props.backgroundColor + "light"]};
        }
         
         `};

  ${Typography} {
    color: ${(props) => (props.outlined !== false ? props.colors[props.outlined] : "#FFFFFF")};
    color: ${(props) => (props.noElevation === true ? props.colors[props.color] : "#FFFFFF")};
  }
  ${IconWrapper} {
    color: ${(props) => (props.outlined !== false ? props.colors[props.outlined] : "#FFFFFF")};
    color: ${(props) => (props.noElevation === true ? props.colors[props.color] : "#FFFFFF")};
  }
`;

let colors = {
  primary: Theme.colors.primary.lightblue[300],
  primarylight: Theme.colors.primary.lightblue[50],
  secondarylight: Theme.colors.primary.lightblue[50],
  secondary: Theme.colors.secondary.purple[100],
  warninglight: Theme.colors.accent.red[50],
  warning: Theme.colors.accent.red[500],
  successlight: Theme.colors.accent.green[50],
  success: Theme.colors.accent.green[300],
  purple: Theme.colors.secondary.purple[100],
  purplelight: Theme.colors.primary.lightgrey[300],
  secondaryLightblue100: Theme.colors.secondary.lightblue[100],
  red150: Theme.colors.accent.red[150],
  red150light: Theme.colors.accent.red[50],
  black: Theme.colors.greyscale.black,
  blacklight: Theme.colors.greyscale[300],
  gray: Theme.colors.primary.darkgrey[50],
  graylight: Theme.colors.primary.lightgrey[150],
  lightgrey: Theme.colors.primary.lightgrey[500],
  lightgreylight: Theme.colors.primary.lightgrey[600],
  white: Theme.colors.greyscale.white,
};

function ButtonJ({
  className = null,
  underlined = false,
  label,
  backgroundColor,
  size = "small",
  width = null,
  color,
  onClick,
  icon = "IconPlus",
  iconColor,
  noElevation,
  outlined = false,
  iconLeft = false,
  iconRight = false,
  disable = false,
  style,
  padding,
}) {
  const [clickAnimate, setClickAnimate] = useState(false);

  const icons = {
    IconPlus: (
      <IconPlus
        color={colors[iconColor]}
        width={size === "small" ? "8.17" : "14"}
        height={size === "small" ? "8.17" : "14"}
      />
    ),
    ArrowRight: (
      <ArrowRight
        color={colors[iconColor]}
        width={size === "small" ? "8.17" : "14"}
        height={size === "small" ? "8.17" : "14"}
      />
    ),
    ArrowLeft: (
      <ArrowLeft
        color={colors[iconColor]}
        width={size === "small" ? "8.17" : "14"}
        height={size === "small" ? "8.17" : "14"}
      />
    ),
    ArrowTop: (
      <UpwardArrowBlue
        color={colors[iconColor]}
        width={size === "small" ? "8.17" : "14"}
        height={size === "small" ? "8.17" : "14"}
      />
    ),
    ArrowBottom: (
      <DownwardArrowBlue
        color={colors[iconColor]}
        width={size === "small" ? "8.17" : "14"}
        height={size === "small" ? "8.17" : "14"}
      />
    ),
    InfoCircle: <InfoCircle style={{ marginTop: "4px" }} />,
    PlayCircle: <PlayCircle style={{ marginTop: "4px" }} />,
  };

  // Color=Primary, Size=Large, Icon Left=False, Icon Right=False, Hover=False, Disabled=False

  return (
    <StyledButton
      style={style}
      className={className ? className : ""}
      width={width}
      padding={padding}
      onClick={onClick}
      colors={colors}
      color={color}
      disable={disable}
      noElevation={noElevation}
      outlined={outlined}
      size={size}
      backgroundColor={backgroundColor}
    >
      <IconWrapper iconLeft={iconLeft}>{iconLeft && icons[icon]}</IconWrapper>
      <Typography
        underlined={underlined}
        colors={colors}
        animate={clickAnimate}
        size={size}
        color={color}
      >
        {label}
      </Typography>
      <IconWrapper colors={colors} iconRight={iconRight}>
        {iconRight && icons[icon]}
      </IconWrapper>
    </StyledButton>
  );
}

ButtonJ.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  iconLeft: PropTypes.bool,
  iconRight: PropTypes.bool,
  backgroundColor: PropTypes.oneOf([
    "primary",
    "primarylight",
    "secondary",
    "secondarylight",
    "warning",
    "warninglight",
    "success",
    "successlight",
    "lightblue",
    "red150",
    "secondaryLightblue100",
    "black",
    "gray",
    "lightgrey",
  ]),
  disable: PropTypes.bool,
  noElevation: PropTypes.bool,
  size: PropTypes.oneOf(["small", "large"]),
  iconColor: PropTypes.string,
  color: PropTypes.oneOf([
    "primary",
    "purple",
    "primarylight",
    "secondary",
    "secondarylight",
    "warning",
    "warninglight",
    "success",
    "successlight",
    "lightblue",
    "red150",
    "secondaryLightblue100",
    "black",
    "gray",
    "lightgrey",
  ]),
  onClick: PropTypes.func,
  outlined: PropTypes.string,
  padding: PropTypes.string,
  width: PropTypes.string,
  to: PropTypes.string,
  underlined: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.string,
};

export default ButtonJ;
