import React from "react";

export const EditIcon = (props) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 11.6666H12.25M9.625 2.04164C9.85706 1.80957 10.1718 1.6792 10.5 1.6792C10.6625 1.6792 10.8234 1.71121 10.9735 1.77339C11.1237 1.83558 11.2601 1.92673 11.375 2.04164C11.4899 2.15654 11.5811 2.29296 11.6432 2.44309C11.7054 2.59322 11.7374 2.75413 11.7374 2.91664C11.7374 3.07914 11.7054 3.24005 11.6432 3.39018C11.5811 3.54032 11.4899 3.67673 11.375 3.79164L4.08333 11.0833L1.75 11.6666L2.33333 9.3333L9.625 2.04164Z"
        stroke="#585858"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const MoveIcon = (props) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.9165 5.24996L1.1665 6.99996M1.1665 6.99996L2.9165 8.74996M1.1665 6.99996L12.8332 6.99996M5.24984 2.91663L6.99984 1.16663M6.99984 1.16663L8.74984 2.91663M6.99984 1.16663L6.99984 12.8333M8.74984 11.0833L6.99984 12.8333M6.99984 12.8333L5.24984 11.0833M11.0832 5.24996L12.8332 6.99996M12.8332 6.99996L11.0832 8.74996"
        stroke="#585858"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const CopyIcon = (props) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.9165 8.74996H2.33317C2.02375 8.74996 1.72701 8.62704 1.50821 8.40825C1.28942 8.18946 1.1665 7.89271 1.1665 7.58329L1.1665 2.33329C1.1665 2.02387 1.28942 1.72713 1.50821 1.50833C1.72701 1.28954 2.02375 1.16663 2.33317 1.16663L7.58317 1.16663C7.89259 1.16663 8.18934 1.28954 8.40813 1.50833C8.62692 1.72713 8.74984 2.02387 8.74984 2.33329V2.91663M6.4165 5.24996L11.6665 5.24996C12.3108 5.24996 12.8332 5.77229 12.8332 6.41663V11.6666C12.8332 12.311 12.3108 12.8333 11.6665 12.8333H6.4165C5.77217 12.8333 5.24984 12.311 5.24984 11.6666L5.24984 6.41663C5.24984 5.77229 5.77217 5.24996 6.4165 5.24996Z"
        stroke="#585858"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const HideIcon = (props) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.77516 2.47337C6.17669 2.37939 6.58778 2.3324 7.00016 2.33337C11.0835 2.33337 13.4168 7.00004 13.4168 7.00004C13.0627 7.66248 12.6404 8.28614 12.1568 8.86087M8.23683 8.23671C8.07662 8.40864 7.88342 8.54655 7.66875 8.6422C7.45409 8.73784 7.22236 8.78927 6.98738 8.79342C6.75241 8.79757 6.51901 8.75434 6.3011 8.66633C6.0832 8.57831 5.88525 8.44731 5.71907 8.28113C5.5529 8.11495 5.42189 7.91701 5.33388 7.6991C5.24586 7.4812 5.20264 7.24779 5.20678 7.01282C5.21093 6.77785 5.26236 6.54612 5.35801 6.33145C5.45366 6.11679 5.59156 5.92359 5.7635 5.76337M0.583496 0.583374L13.4168 13.4167M10.4652 10.465C9.46801 11.2251 8.25381 11.6462 7.00016 11.6667C2.91683 11.6667 0.583496 7.00004 0.583496 7.00004C1.3091 5.64781 2.31549 4.46639 3.53516 3.53504L10.4652 10.465Z"
        stroke="#585858"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const UnhideIcon = (props) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.583496 7.00004C0.583496 7.00004 2.91683 2.33337 7.00016 2.33337C11.0835 2.33337 13.4168 7.00004 13.4168 7.00004C13.4168 7.00004 11.0835 11.6667 7.00016 11.6667C2.91683 11.6667 0.583496 7.00004 0.583496 7.00004Z"
        stroke="#585858"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.00016 8.75004C7.96666 8.75004 8.75016 7.96654 8.75016 7.00004C8.75016 6.03354 7.96666 5.25004 7.00016 5.25004C6.03366 5.25004 5.25016 6.03354 5.25016 7.00004C5.25016 7.96654 6.03366 8.75004 7.00016 8.75004Z"
        stroke="#585858"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const DeleteIcon = (props) => {
  return (
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.75 3.49996H1.91667M1.91667 3.49996L11.25 3.49996M1.91667 3.49996L1.91667 11.6666C1.91667 11.976 2.03958 12.2728 2.25838 12.4916C2.47717 12.7104 2.77391 12.8333 3.08333 12.8333H8.91667C9.22609 12.8333 9.52283 12.7104 9.74162 12.4916C9.96042 12.2728 10.0833 11.976 10.0833 11.6666V3.49996L1.91667 3.49996ZM3.66667 3.49996V2.33329C3.66667 2.02387 3.78958 1.72713 4.00838 1.50833C4.22717 1.28954 4.52391 1.16663 4.83333 1.16663L7.16667 1.16663C7.47609 1.16663 7.77283 1.28954 7.99162 1.50833C8.21042 1.72713 8.33333 2.02387 8.33333 2.33329V3.49996M4.83333 6.41663L4.83333 9.91663M7.16667 6.41663L7.16667 9.91663"
        stroke="#585858"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const Tick = (props) => {
  return (
    <svg width="20" height="16" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.6668 0.5L4.25016 6.91667L1.3335 4"
        stroke="#01AFFD"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
