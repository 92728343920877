import React, { useEffect } from "react";
import "./CompanyMenu.scss";
import { ArrowDown } from "../../icons/svgIcons";
import { ReusableMenu, ReusableMenuItem } from "../ReusableMenu/ReusableMenu";
import Button from "@mui/material/Button";

export default function CompanyMenu(props) {
  const [key, setVal] = React.useState(new Date().getTime());

  useEffect(() => {
    global.Modeliks.Subscribe("onCompanyNameChange", updateCompany);
  }, []);

  const updateCompany = () => {
    setVal(new Date().getTime());
  };

  const getCompanies = () => {
    return global.Modeliks.Companies.filter((c) => !!c.Status);
  };

  const companies = getCompanies();

  return (
    <ReusableMenu
      key={key}
      menuButton={
        <Button
          disabled={companies.length === 1}
          className={"company_btn"}
          startIcon={companies.length > 1 ? <ArrowDown /> : null}
        >
          <span className={"cmpny_name"}>{global.Modeliks.CompanyInfo.CompanyName}</span>
        </Button>
      }
      menuItems={companies.map(
        (company, index) =>
          new ReusableMenuItem(
            company.CompanyName,
            () => {
              props.onCompanyLoad && props.onCompanyLoad();
              global.Modeliks.ChangeCompany(company, () => props.onCompanyLoadFinish());
            },
            global.Modeliks.CompanyInfo.ID == company.ID,
          ),
      )}
    />
  );
}
