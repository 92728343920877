import React from "react";
import Dialog from "@mui/material/Dialog";
import "./CompanyLimit.scss";
import { Exit } from "../../../SlideEditor/components/components/IconButtons/SubMenuIcons";
import IconButton from "@mui/material/IconButton";
import Button from "../../actions/Button";

class SuccessDialog extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { onClose, onChange, open } = this.props;

    return (
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={open}
        className={"company_limit_dialog"}
        onClose={onClose}
      >
        <div className={"scdls_success_content"}>Successfully Saved!</div>

        {/*<div className={'cld_typography2_buttons scdlw_warning_actions'}>*/}
        {/*<Button label={'Cancel'} padding={'0 0 0 0'} backgroundColor={'red150'} onClick={onClose}/>*/}
        {/*<Button label={'Confirm Forecast Period Change'} width={'220px'} padding={'0 0 0 0'} backgroundColor={'primary'} onClick={onChange}/>*/}
        {/*</div>*/}
      </Dialog>
    );
  }
}

export default SuccessDialog;
