import React from "react";
import { Menu, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import { default as MuiButton } from "@mui/material/Button";
import { ArrowDown } from "../../../../../components/icons/svgIcons";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Divider } from "@mui/material";
import "../index.scss";
import CheckBoxMaterial from "../../../../../components/actions/CheckBoxMaterial";

export default class ShowHideMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      key: "psdf_menu_navigation",
      loading: false,
    };
  }

  onChangeParent = (index) => {
    const header = this.props.getHeaders[index];
    if (header) {
      let values = false;
      if (!header.checked) {
        values = true;
      }

      this.props.getHeaders[index].checked = values;
      this.props.getHeaders[index - 1].checked = values;
      this.props.getHeaders[index - 2].checked = values;
      this.props.changeColumns();
    }
  };

  onChangeChildren = (index) => {
    const header = this.props.getHeaders[index];

    if (header) {
      this.props.getHeaders[index].checked = !this.props.getHeaders[index].checked;
      this.props.changeColumns();
    }
  };

  onChangeParentValuation = (index) => {
    const header = this.props.getHeaders[index];
    if (header) {
      let values = false;
      if (!header.checked) {
        values = true;
      }

      if (
        !this.props.getHeaders[index + 1].checked &&
        !this.props.getHeaders[index + 1].checked &&
        !this.props.getHeaders[index + 1].checked
      ) {
        values = true;
      }

      this.props.getHeaders[index].checked = values;
      this.props.getHeaders[index + 1].checked = values;
      this.props.getHeaders[index + 2].checked = values;
      this.props.getHeaders[index + 3].checked = values;
      this.props.changeColumns();
    }
  };

  ValuationOverviewShowHide = (getHeaders) => {
    return getHeaders.map((header, index) => {
      let isGroupFounded = false;
      if (header.parent) {
        isGroupFounded = true;
      }

      if (header.single) {
        return (
          <React.Fragment key={index}>
            {index !== 0 && <Divider />}
            <CheckBoxMaterial
              label={getHeaders[index].Header}
              checked={header.checked}
              onChange={() => this.onChangeChildren(index)}
              size="medium"
              elevation={false}
              labelPlacement={"start"}
            />
            <Divider />
          </React.Fragment>
        );
      }

      if (isGroupFounded) {
        return (
          <React.Fragment key={index}>
            <CheckBoxMaterial
              label={getHeaders[index].Header}
              checked={
                getHeaders[index + 2].checked &&
                getHeaders[index + 1].checked &&
                getHeaders[index + 3].checked &&
                header.checked
              }
              onChange={() => this.onChangeParentValuation(index)}
              size="medium"
              elevation={false}
              labelPlacement={"start"}
            />
            <Divider />
            <div className="psdf_menu_children_div">
              <CheckBoxMaterial
                label={getHeaders[index + 1].Header}
                checked={getHeaders[index + 1].checked}
                onChange={() => this.onChangeChildren(index + 1)}
                size="medium"
                elevation={false}
                labelPlacement={"start"}
              />
              <CheckBoxMaterial
                label={getHeaders[index + 2].Header}
                checked={getHeaders[index + 2].checked}
                onChange={() => this.onChangeChildren(index + 2)}
                size="medium"
                elevation={false}
                labelPlacement={"start"}
              />
              <CheckBoxMaterial
                label={getHeaders[index + 3].Header}
                checked={getHeaders[index + 3].checked}
                onChange={() => this.onChangeChildren(index + 3)}
                size="medium"
                elevation={false}
                labelPlacement={"start"}
              />
            </div>
          </React.Fragment>
        );
      }
    });
  };

  DashboardShowHide = (getHeaders) => {
    return getHeaders.map((header, index) => {
      let isGroupFounded = false;
      if (index > 0 && !(index % 3)) {
        isGroupFounded = true;
      }

      if (isGroupFounded) {
        return (
          <React.Fragment key={index}>
            <CheckBoxMaterial
              label={getHeaders[index - 2].Header}
              checked={
                getHeaders[index - 2].checked && getHeaders[index - 1].checked && header.checked
              }
              onChange={() => this.onChangeParent(index)}
              size="medium"
              elevation={false}
              labelPlacement={"start"}
            />
            <Divider />
            <div className="psdf_menu_children_div">
              <CheckBoxMaterial
                label={"$ value"}
                checked={getHeaders[index - 2].checked}
                onChange={() => this.onChangeChildren(index - 2)}
                size="medium"
                elevation={false}
                labelPlacement={"start"}
              />
              <CheckBoxMaterial
                label={getHeaders[index - 1].Header}
                checked={getHeaders[index - 1].checked}
                onChange={() => this.onChangeChildren(index - 1)}
                size="medium"
                elevation={false}
                labelPlacement={"start"}
              />
              <CheckBoxMaterial
                label={header.Header}
                checked={header.checked}
                onChange={() => this.onChangeChildren(index)}
                size="medium"
                elevation={false}
                labelPlacement={"start"}
              />
            </div>
          </React.Fragment>
        );
      }
    });
  };

  CompanyShowHide = (getHeaders) => {
    return (
      <React.Fragment key={""}>
        {getHeaders.map((header, index) => {
          return (
            <CheckBoxMaterial
              label={header.Header}
              checked={getHeaders[index].checked && header.checked}
              onChange={() => this.onChangeChildren(index)}
              size="medium"
              elevation={false}
              labelPlacement={"start"}
            />
          );
        })}
      </React.Fragment>
    );
  };

  render() {
    const { getHeaders, isValuation, className, name, isCompany } = this.props;

    return (
      <div key={this.state.key} className="psdf_menu_navigation_show_hide_div">
        <Menu
          menuClassName={`psdf_menu_navigation_show_hide ${className}`}
          arrow
          menuButton={
            this.props.menuButton ? (
              this.props.menuButton
            ) : (
              <MuiButton
                className={!isValuation ? "psdf_menu_button" : "psdf_menu_button_overview"}
                size={"small"}
                variant="outlined"
                endIcon={<ArrowDown width="8px" height="8px" />}
              >
                {name ? name : "Show / Hide columns"}
              </MuiButton>
            )
          }
        >
          {isValuation
            ? this.ValuationOverviewShowHide(getHeaders)
            : isCompany
              ? this.CompanyShowHide(getHeaders)
              : this.DashboardShowHide(getHeaders)}
        </Menu>
      </div>
    );
  }
}
