import React, { useState, useEffect, useMemo } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Active } from "../dividers/ActiveTab";
import "./tabs.scss";
import { useResolvedPath, useNavigate } from "react-router-dom";

const SubTabs = (props) => {
  const navigate = useNavigate();
  const resolved = useResolvedPath();
  const [tabs, setTabs] = useState(props.children.tabs);
  const findTab = useMemo(() => tabs.find((child) => resolved.pathname.includes(child.link)));
  const [tab, setTab] = useState(findTab ? findTab : false);
  const newExisting = useMemo(() => tabs.find((child) => resolved.pathname.includes(child.link)));
  const activeMultipleTabs = useMemo(() =>
    props.multipleTabsLink
      ? tabs.find(
          (t) =>
            resolved.pathname.includes(props.multipleTabsLink) &&
            t.link.includes(props.multipleTabsLink),
        )
      : false,
  );

  const onClickNavigate = (e, newTab) => {
    if (newTab !== tab || !findTab) {
      if (newTab.hasOwnProperty("key")) {
        props.defaultUrl[newTab.key] = newTab.link;
      }
      if (newTab.hasOwnProperty("defaultReport")) {
        props.defaultSelectedReport[newTab.key] = newTab.defaultReport;
      }
      setTab(newTab);
      navigate(newTab.link);
    }
  };

  useEffect(() => {
    if (findTab && findTab !== tab) {
      setTab(findTab);
    }
  }, [resolved, findTab, tab]);

  return (
    <Tabs
      className={`mctabs_tabs sub_tabs ${props.tabsClassName} `}
      value={activeMultipleTabs ? activeMultipleTabs : findTab ? findTab : false}
      centered
      TabIndicatorProps={{
        children: (
          <div className="mctabs_indicator selected">
            {(activeMultipleTabs || newExisting) && <Active />}
          </div>
        ),
      }}
      onChange={onClickNavigate}
      aria-label="basic tabs example selected"
    >
      {tabs.map((child) => {
        const { label, link, size, className } = child;
        return (
          <Tab
            disableRipple
            key={"tabsub_" + child.link}
            label={label}
            value={child}
            className={`mctabs_tab ${size} ${(activeMultipleTabs || (newExisting && link === newExisting.link)) && "selected"} ${className}`}
          />
        );
      })}
    </Tabs>
  );
};

export default SubTabs;
