import request from "superagent";

// TODO: consolidate headers and test request abort
const httpService = (config = {}) => {
  const defaultConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${window.localStorage.getItem("token")}`,
    },
  };

  const mergedConfig = {
    ...defaultConfig,
    ...config,
    headers: {
      ...defaultConfig.headers,
      ...config.headers,
    },
  };

  const { headers: globalHeaders = {} } = mergedConfig;

  let _request = null;

  const get = async (url, params = {}) => {
    try {
      _request = await request.get(url).query(params).set(globalHeaders);
      return _request.body;
    } catch (error) {
      throw error.response ? error.response.body : error;
    }
  };

  const download = async (url, params = {}) => {
    try {
      _request = await request.get(url).responseType("blob").query(params).set(globalHeaders);
      return _request.body;
    } catch (error) {
      throw error.response ? error.response.body : error;
    }
  };

  const post = async (url, data = {}, urlParams = {}) => {
    try {
      _request = await request.post(url).set(globalHeaders).query(urlParams).send(data);
      return _request.body;
    } catch (error) {
      throw error.response ? error.response.body : error;
    }
  };

  const put = async (url, data = {}, queryParams = {}) => {
    try {
      _request = await request.put(url).set(globalHeaders).query(queryParams).send(data);
      return _request.body;
    } catch (error) {
      throw error.response ? error.response.body : error;
    }
  };

  const patch = async (url, data = {}) => {
    try {
      _request = await request.patch(url).set(globalHeaders).send(data);
      return _request.body;
    } catch (error) {
      throw error.response ? error.response.body : error;
    }
  };

  const deleteReq = async (url, data = {}) => {
    try {
      _request = await request.del(url).set(globalHeaders).query(data);
      return _request.body;
    } catch (error) {
      throw error.response ? error.response.body : error;
    }
  };

  const cancel = () => {
    _request.abort();
  };

  return { get, post, put, patch, deleteReq, cancel, download };
};

export default httpService;
