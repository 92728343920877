import React from "react";
import styled from "styled-components";
import { Paragraph } from "../typography/paragraph/Paragraph";
import { Box } from "./Box";
import BurdenRate from "../../pages/Secure/Financials/Personnel/components/BurdenRate";
import RetainedEarnings from "../../pages/Secure/Financials/Financing/components/RetainedEarnings";
import CheckBoxMaterial from "../actions/CheckBoxMaterial";
import ExportReport from "../../pages/Secure/Financials/components/Export/ExportReport";
import OpeningCashBalance from "../../pages/Secure/Financials/Assets/components/OpeningCashBalance";
import "./naviagtion_actions.scss";
import InstructionalPopup from "../dialogs/instructionsPopup/instructionalPopup";
import ButtonMui from "../buttons/buttonMui/buttonMui";
import { ArrowLeft, ArrowRight, ExportIcon, IconPlus, InfoIcon, PlayIcon } from "../icons/svgIcons";
import { AccessControl } from "../../data/Permissions/AccessControl";
import ForecastReportsMenu from "../../pages/Secure/Financials/Reports/components/ForecastReportsMenu";
import UpdatePlan from "../dialogs/LimitDialogs/UpdatePlan";

const StyledNavigationActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 15px;
  margin-right: 15px;
  height: 55px;
  align-items: center;
  @media (min-width: 950px) and (max-width: 1200px) {
    column-gap: 5px !important;
  }

  @media (min-width: 1201px) {
    column-gap: 25px !important;
  }
`;

class NavigationActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showBurdenRateModal: false,
      showRetainedEarningModal: false,
      showOpeningCashBalance: false,
    };
  }

  manageBurdenRate = () => {
    this.setState({ showBurdenRateModal: !this.state.showBurdenRateModal });
  };

  manageRetainedEarnings = () => {
    this.setState({ showRetainedEarningModal: !this.state.showRetainedEarningModal });
  };

  manageOpeningCashBalance = () => {
    this.setState({ showOpeningCashBalance: !this.state.showOpeningCashBalance });
  };

  toggleUpdatePlan = () => {
    this.setState({ updatePlan: !this.state.updatePlan });
  };
  render() {
    if (this.props.showStreamNavigation) {
      const { prevLink, disablePrev, nextLink, nextLinkText, prevLinkText, disabled } = this.props;

      return (
        <StyledNavigationActions className={this.props.className ? this.props.className : ""}>
          <Box className="sccn_nav_first_div" display="flex" gap="24px" alignItems="center">
            {!this.props.NoButton && (
              <div>
                <ButtonMui
                  disabled={disabled}
                  label={this.props.buttonLabel}
                  onClick={this.props.onClick}
                  endIcon={<IconPlus />}
                  variant={"contained"}
                  style={{ minWidth: "200px" }}
                />
              </div>
            )}
            {this.props.BurdenRate && (
              <ButtonMui
                disabled={disabled}
                label="Burden Rate"
                onClick={this.manageBurdenRate}
                variant={"contained"}
                color={"secondary"}
              />
            )}

            {(this.props.OpeningCashBalance || this.props.RetainedEarning) && (
              <ButtonMui
                linkTo="/forecast/opening_balance"
                label={
                  global.Modeliks.PERMISSIONS.Financials.restrictions.edit.active
                    ? "Set Opening Balances"
                    : "View Opening Balances"
                }
                variant={"contained"}
                color={"secondary"}
              />
            )}
          </Box>

          <Box className="sccn_nav_second_div" display="flex" gap="0px" alignItems="center">
            <ButtonMui
              label={<ArrowLeft />}
              style={{ minWidth: 0 }}
              disabled={disablePrev}
              width={36}
              linkTo={prevLink}
              color="primary"
              variant={"text"}
              tooltipText={prevLinkText ? `Go to ${prevLinkText} ` : ""}
            />
            <Paragraph
              className={"sccn_nav_center sccn_nav_paragraph"}
              color={"black"}
              size="p1_strong"
              label={this.props.label}
            />
            <ButtonMui
              label={<ArrowRight />}
              style={{ minWidth: 0 }}
              disabled={!nextLink}
              width={36}
              linkTo={nextLink}
              color="primary"
              variant={"text"}
              tooltipText={`Go to ${nextLinkText} `}
            />
          </Box>

          <Box
            className="sccn_nav_first_div sccn_nav_content_end"
            display="flex"
            alignItems="center"
          >
            <InstructionalPopup
              actionButton={
                <ButtonMui
                  style={{ minWidth: "unset", justifySelf: "center", width: "150px" }}
                  label="Videos"
                  variant={"text"}
                  endIcon={<PlayIcon />}
                  color={"secondary"}
                />
              }
              data_key={"video_links"}
            />
            <InstructionalPopup
              actionButton={
                <ButtonMui
                  style={{ minWidth: "unset", justifySelf: "center", width: "150px" }}
                  label="Instructions"
                  variant={"text"}
                  endIcon={<InfoIcon />}
                  color={"secondary"}
                />
              }
              data_key={"instructions_contents"}
            />
          </Box>
          {this.props.BurdenRate && (
            <BurdenRate
              onChange={this.props.onChange}
              open={this.state.showBurdenRateModal}
              onClick={this.manageBurdenRate}
              onClose={this.manageBurdenRate}
            />
          )}
        </StyledNavigationActions>
      );
    }

    return (
      <StyledNavigationActions className={this.props.className ? this.props.className : ""}>
        <Box display="flex" gap="24px" alignItems="center">
          {this.props.showMenu ? (
            <div className="sccn_reporst_menu_container">
              <ForecastReportsMenu label={this.props.label} />
            </div>
          ) : (
            <div className="sccn_nav_p">
              <Paragraph color={"black"} size="p1_strong" label={this.props.label} />
              {/*{this.props.icon && this.props.icon}*/}
            </div>
          )}

          {!this.props.NoButton && (
            <div style={{ marginLeft: "8px" }}>
              <ButtonMui
                disabled={this.props.disabled}
                label={this.props.buttonLabel}
                onClick={this.props.onClick}
                endIcon={<IconPlus />}
                variant={"contained"}
                style={{ minWidth: "200px" }}
              />
            </div>
          )}
          {this.props.BurdenRate && (
            <ButtonMui
              disabled={this.props.disabled}
              label="Burden Rate"
              onClick={this.manageBurdenRate}
              variant={"contained"}
              color={"secondary"}
            />
          )}

          {(this.props.OpeningCashBalance || this.props.RetainedEarning) && (
            <ButtonMui
              linkTo="/forecast/opening_balance"
              label={
                global.Modeliks.PERMISSIONS.Financials.restrictions.edit.active
                  ? "Set Opening Balances"
                  : "View Opening Balances"
              }
              variant={"contained"}
              color={"secondary"}
            />
          )}
        </Box>
        {this.props.showClearOpeningBalance && (
          <Box display="flex" gap="31px" alignItems="center" style={{ marginLeft: "20px" }}>
            <ButtonMui
              disabled={this.props.disabled}
              label="Clear all Values"
              onClick={this.props.onClearValues}
              variant={"contained"}
              color={"secondary"}
            />
          </Box>
        )}

        <Box display="flex" gap="31px" alignItems="center" style={{ width: "100%" }}>
          {this.props.rightPortal && <div id={"reports_right_portal"} />}
          {/*{this.props.showReportsFooter && <ReportsFooter defaultSelectedReport={this.props.defaultSelectedReport} defaultUrl={this.props.defaultUrl}/>}*/}
          {/*<Label color={'black'} size='label3' label={'Video'} />*/}
          {/*<Label color={'black'} size='label3' label={'Instructions'} />*/}
        </Box>
        {this.props.OpeningCashBalance && (
          <OpeningCashBalance
            open={this.state.showOpeningCashBalance}
            onClick={this.manageOpeningCashBalance}
            onClose={this.manageOpeningCashBalance}
          />
        )}
        {this.props.BurdenRate && (
          <BurdenRate
            onChange={this.props.onChange}
            open={this.state.showBurdenRateModal}
            onClick={this.manageBurdenRate}
            onClose={this.manageBurdenRate}
          />
        )}
        {this.props.RetainedEarning && (
          <RetainedEarnings
            onChange={this.props.onChange}
            open={this.state.showRetainedEarningModal}
            onClick={this.manageRetainedEarnings}
            onClose={this.manageRetainedEarnings}
          />
        )}
        {!this.props.rightPortal && (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            {this.props.showCheckBox && (
              <CheckBoxMaterial
                label={"Show Monthly"}
                checked={this.props.showMonthly}
                onChange={this.props.showMonthlyOnChange}
                size="medium"
                elevation={false}
              />
            )}
            {this.props.showExport && (
              <>
                {this.state.updatePlan && (
                  <UpdatePlan
                    open={true}
                    handleClose={this.toggleUpdatePlan}
                    onCancel={this.toggleUpdatePlan}
                  />
                )}
                <AccessControl
                  allowedPermissions={[
                    {
                      [global.Modeliks.PERMISSIONS.Financials.key]:
                        global.Modeliks.PERMISSIONS.Financials.restrictions.ExportExcel.key,
                    },
                    {
                      [global.Modeliks.PERMISSIONS.Financials.key]:
                        global.Modeliks.PERMISSIONS.Financials.restrictions.ExportPDF.key,
                    },
                  ]}
                  renderNoAccess={
                    <ButtonMui
                      onClick={this.toggleUpdatePlan}
                      label={"Export"}
                      color={"grey"}
                      variant={"text"}
                      style={{ fontWeight: 600 }}
                      width={100}
                      endIcon={<ExportIcon />}
                    />
                  }
                >
                  <ExportReport
                    disabled={!global.Modeliks.PERMISSIONS.Financials.restrictions.edit.active}
                    periods={this.props.headers}
                    rows={this.props.rows}
                    title={this.props.title}
                    IsExportAllReports={true}
                    exportReport={this.props.exportReport}
                    showExcelExportAllReports={true}
                    exportAllReports={this.props.exportAllReports}
                    reportName={this.props.label}
                    field={"Forecast"}
                    pdfSuffix={this.props.showMonthly ? "monthly" : "annual"}
                    pdfReportType={this.props.pdfReportType}
                  />
                </AccessControl>
              </>
            )}
          </div>
        )}
        <InstructionalPopup
          actionButton={
            <ButtonMui
              style={{ minWidth: "105px", justifySelf: "center", width: "105px" }}
              label="Videos"
              variant={"text"}
              endIcon={<PlayIcon />}
              color={"secondary"}
            />
          }
          data_key={"video_links"}
        />
        <InstructionalPopup
          actionButton={
            <ButtonMui
              style={{ minWidth: "120px", justifySelf: "center", width: "120px" }}
              label="Instructions"
              variant={"text"}
              endIcon={<InfoIcon />}
              color={"secondary"}
            />
          }
          data_key={"instructions_contents"}
        />
      </StyledNavigationActions>
    );
  }
}

export default NavigationActions;
