import * as React from "react";
import { Dialog, DialogActions, DialogContent } from "@mui/material";

import ButtonJ from "../Buttons/Button";
import TabsMenu from "../menus/TabsMenu/TabsMenu";
import "./SectionDialog.scss";
import IconButton from "@mui/material/IconButton";
import { Exit } from "../IconButtons/SubMenuIcons";
import Button from "../../../../components/actions/Button";
import DeleteForeverSharpIcon from "@mui/icons-material/DeleteForeverSharp";
import CalculatedDriver from "../../../../data/Finance/CalculatedDriver";
import { TrashCan } from "../../../../components/icons/svgIcons";
import AdminAddSectionDialog from "./AdminAddSectionDialog";
import AdminUpdateSectionDialog from "./AdminUpdateSectionDialog";
import BusinessPlanAddNewSectionDialog from "./BusinessPlanAddNewSectionDialog";
import { ReportsTableSizes, ReportsTypes } from "../../../../data/Finance/Reports/constants";
import { SlideObject, SlideTypes } from "../../SlideObjects/SlideStructures";
import { tableDateType } from "../../SlideObjects/FinanceTable/FinanceTable";
import { Label } from "../../../../components/typography/label/Label";
import DatePickerYM from "../../../../components/actions/DatePickers/DatePickerYM";
import ButtonMui from "../../../../components/buttons/buttonMui/buttonMui";
import { IconPlus } from "../Icons/Icons";
import Tooltip from "@mui/material/Tooltip";
import InfoDialogActions from "../../../../components/dialogs/financials/InfoDialog/InfoDialogActions";
import { AddDialogTitle } from "../../../../components/dialogs/financials/AddDialog/AddDialogTitle";
import { useEffect } from "react";

export default function TemplatesDialog(props) {
  const [templates, setTemplates] = React.useState(props.templates);
  const [selectedTemplate, setSelectedTemplate] = React.useState(props.templates[0]);

  useEffect(() => {
    setSelectedTemplate(props.templates[0]);
  }, [props.templates]);
  const handleClose = () => {
    props.handleClose();
  };

  const selectTemplate = () => {
    props.selectTemplate(selectedTemplate);
    props.handleClose();
  };

  const getSections = () => {
    return props.templates.map((template, index) => {
      return (
        <div
          key={"template_" + index}
          onClick={() => setSelectedTemplate(template)}
          className={template.ID === selectedTemplate.ID ? "section selected" : "section"}
        >
          <img
            className={"section_thumbnail"}
            src={`/api/img?tmp=${template.MasterTemplateID ?? template.ID}&v=${template.UpdatedAt}`}
          />
        </div>
      );
    });
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={props.open}
        scroll={"paper"}
        className={"section_dialog"}
        onClose={handleClose}
      >
        <AddDialogTitle title={"Select Template"} onClose={() => handleClose()} />

        <DialogContent>
          <div className={"dialog_content"}>
            <div className={"sections_container"}>{getSections()}</div>
          </div>
        </DialogContent>

        <DialogActions disableSpacing={true}>
          <div style={{ display: "flex", justifyContent: "space-between", columnGap: 20 }}>
            <ButtonMui
              width={136}
              style={{ height: 42 }}
              label={"Cancel"}
              variant={"text"}
              onClick={handleClose}
              color={"black"}
            />

            <ButtonMui
              width={136}
              style={{ height: 42 }}
              disabled={!selectedTemplate}
              label={"OK"}
              variant={"contained"}
              onClick={selectTemplate}
              color={"primary"}
            />
          </div>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
