import Financing from "./index";
import { FinancingTypes } from "./constants";
import { DriverPeriodTypes } from "../CalculatedDriver/constants";
import { maxRows } from "../../../components/constants/finance";

const FinancingDataGridRows = (Storage) => {
  const financingTotals = Financing.getFinanceTotals();

  Storage.dataGridRows = {
    streamTotals: financingTotals,
    streamTotalsGrowth: Financing.getTotalsGrowth(financingTotals),
    gridRows: [],
    totalsRows: [],
    maxRows,
    totalRows: 0,
  };
  let totalRows = Storage.dataGridRows.totalRows;

  const revenue_only_create = (asset, parentRevenue, level = 0, stopRecursive = false) => {
    let row = {};
    if (asset) {
      totalRows = totalRows + 1;
      if (level > 1) {
        row = {
          id: asset.ID,
          header: asset.Name,
          revenue: asset,
          hasEdit: asset.Ref_Field === "total",
          onEditClick: () => {},
          onDeleteClick: () => {},
          data: asset,
          children: [],
        };
      } else if (level === 1) {
        row = {
          id: asset.ID,
          header: asset.Name,
          revenue: asset,
          hasEdit:
            asset.DriverName === "Shareholder Equity" ||
            asset.Ref_Table !== "Finance_Financing" ||
            asset.IsTemporary ||
            asset.DriverName === "Prepaid Revenue" ||
            asset.DriverName === "Unearned Revenue" ||
            asset.DriverName === "Other Current Liability" ||
            asset.DriverName === "Other Long Term Liability"
              ? false
              : true,
          onEditClick: () => {},
          onDeleteClick: () => {},
          data: asset,
          children: [],
        };

        let driver = Storage.getItem(asset.Ref_ID);
        if (
          driver &&
          asset.Ref_Table !== "Finance_Taxes" &&
          (driver.FinanceType === FinancingTypes.Loan ||
            driver.FinanceType === FinancingTypes.LineOfCredit ||
            driver.FinanceType === driver.OtherLiability)
        ) {
          let children = driver.getAllDrivers();
          if (driver.FinanceType === FinancingTypes.Loan && driver.PaymentStart.Value !== "0") {
            children = children.filter((d) => d.Ref_Field !== "openning_balance");
          }
          if (children) {
            children.map((driver) => {
              if (totalRows <= maxRows) {
                row.children.push({
                  id: driver.ID,
                  header: driver.Name,
                  revenue: driver,
                  hasEdit: false,
                  // onEditClick: () => this.handleOnEditFinance(driver.Ref_ID),
                  onDeleteClick: () => {},
                  data: driver,
                  children: [],
                });
              }
            });
          }
        }
      } else {
        row = {
          id: asset.ID,
          header: asset.Name,
          revenue: asset,
          hasEdit: false,
          boldRowHeader: true,
          onDeleteClick: () => {},
          data: asset,
          isExpanded: true,
          children: [],
        };
      }
      let children = asset.getChildDrivers();
      if (children && !stopRecursive) {
        children.map((driver) => {
          if (
            driver &&
            !driver.isValid &&
            asset.DriverName !== "Long Term Liabilities" &&
            driver.DriverName !== "Shareholder Equity" &&
            driver.Ref_Field !== "total"
          ) {
            return null;
          }
          if (totalRows <= maxRows) {
            driver &&
              row.children.push(
                revenue_only_create(
                  driver,
                  asset,
                  level + 1,
                  driver.PeriodType === DriverPeriodTypes.Previous,
                ),
              );
          }
        });
      }
      return row;
    } else {
      return null;
    }
  };

  const row_totals = (totals = financingTotals) => {
    totalRows = totalRows + 1;
    return {
      id: totals.ID,
      unit: global.Modeliks.CompanyInfo.Currency.value,
      header: "Total Liabilities and Equity",
      cssClass: "totals",
      boldRowHeader: true,
      data: totals,
    };
  };

  const createGridRows = () => {
    Storage.dataGridRows.gridRows = financingTotals
      .getChildDrivers()
      .map((finance) => revenue_only_create(finance))
      .filter((c) => c != null);
    const totals = row_totals();
    if (totals) {
      Storage.dataGridRows.totalsRows = [totals];
    }
    Storage.dataGridRows.totalRows = totalRows;
  };

  createGridRows();
};

export default FinancingDataGridRows;
