export const bluePallete = [
  "#004D8C",
  "#0088F7",
  "#01AFFD",
  "#80D7FE",
  "#004D8C",
  "#0088F7",
  "#01AFFD",
  "#80D7FE",
  "#004D8C",
  "#0088F7",
];
export const greenPallete = [
  "#00807E",
  "#00B3B0",
  "#00E6E2",
  "#ADF6F7",
  "#00807E",
  "#00B3B0",
  "#00E6E2",
  "#ADF6F7",
  "#00807E",
  "#00B3B0",
];
