import React from "react";
import styled from "styled-components";
import { Theme } from "../.././theme/styles/theme";
import PropTypes from "prop-types";

const StyledGreyCard = styled.div`
  font-family: Inter;
  //height: props.height;
  height: ${(props) => (props.height ? props.height : "auto")};

  //width: '-webkit-fill-available';
  background: #fbfbfb;
  display: flex;
  flex-direction: column;
  border: 1px solid ${(props) => (props.error ? "red" : "#F0F1F7")};
  flex-direction: column;
  align-items: center;
  justify-content: left;
  border-radius: 8px;
  padding: ${(props) => (props.padding ? props.padding : "10px")};
  margin: ${(props) => (props.margin ? props.margin : "")};
  width: ${(props) => (props.width ? props.width : "")};
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
`;

export const GreyCard = ({ height, error, onClick, ...props }) => {
  return (
    <>
      <StyledGreyCard
        onClick={onClick}
        height={height}
        error={error}
        width={props.width}
        padding={props.padding}
        margin={props.margin}
      >
        {props.children}
      </StyledGreyCard>
    </>
  );
};

GreyCard.propTypes = {
  height: PropTypes.string,
  error: PropTypes.bool,
};
