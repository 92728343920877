import React from "react";
import "./onboarding.scss";

export const NumberIcon = (props) => {
  return (
    <div className="spsocb_number_icon_div">
      <span className="spsocb_number">{props.number}</span>
    </div>
  );
};
