import React from "react";
import AuthPage from "../components/AuthPage";
import request from "superagent";
import {
  LoginBottomRightCornerBigShape,
  LoginTopLeftCornerBigShape,
  MobileTitleShape,
} from "../../../../components/icons/svgIcons";
import ButtonMui from "../../../../components/buttons/buttonMui/buttonMui";
import BackgroundBalls from "../components/BackgroundBalls";
import RegisterPage, { CopyrightMsg, ModeliksHeaderLogo } from "../Register/RegisterPage";
import { handleKeyDown } from "../Login";
import LoginInput from "../../../../components/inputs/loginInput/LoginInput";
import Mx_MainLoader from "../../../../components/Loaders/MainLoader/Mx_MainLoader";
import StrengthMeter from "../../../../components/actions/inputs/StrengthMeter";

class NewPassword extends RegisterPage {
  constructor(props) {
    super(props);
    this.state = {
      requiredPasswordChange: false,
      loading: true,
    };
    this.guid = "";
  }

  componentDidMount() {
    this.guid = window.location.pathname.split("/").pop();
    request
      .get("/api/PasswordChange")
      .query({ GUID: this.guid.toString() })
      .then((res) => {
        if (res.text == "true") {
          this.setState({ requiredPasswordChange: true, loading: false });
        } else {
          this.setState({ loading: false });
        }
      });

    document.title = "Reset Password | Modeliks Small Business Financial Planning Software";

    const metaDescription = document.createElement("meta");
    metaDescription.name = "description";
    metaDescription.content =
      "Forgot your Modeliks password? Reset it Easily for Access to the Best Financial & Business Planning Tools Designed Specifically for Small Businesses & Startups.";
    document.head.appendChild(metaDescription);

    const metaKeywords = document.createElement("meta");
    metaKeywords.name = "keywords";
    metaKeywords.content =
      "modeliks, small business financial planning software, best financial software for small business, best business software for small business, business software for small business, small business financial forecasting software, small business financial software, small business planning software, financial management tools for small business";
    document.head.appendChild(metaKeywords);
  }

  componentWillUnmount() {
    document.title = "Modeliks";

    const metaDescription = document.querySelector('meta[name="description"]');
    const metaKeywords = document.querySelector('meta[name="keywords"]');

    document.head.removeChild(metaDescription);
    document.head.removeChild(metaKeywords);
  }

  sendNewPassword = () => {
    if (this.validatePasswords()) {
      this.setState({ loading: true });
      request
        .put("/api/newPassword")
        .send({ Password: this.state.password, GUID: this.guid })
        .then((res) => {
          if (res.text == "true") {
            global.Modeliks.NavigateTo("/login");
            // window.location='/'
          } else {
            this.setState({ passwordError: "Couldn't set password" });
          }
        })
        .catch((e) => console.error("bad req", e));
    }
  };

  getContent = () => {
    const {
      password,
      secondPassword,
      passwordError,
      secondPasswordError,
      loading,
      requiredPasswordChange,
    } = this.state;
    const loader = loading ? <Mx_MainLoader secondLoader className={"blurred"} /> : null;

    const headerText = requiredPasswordChange ? (
      <>
        <span>Create New Password</span>
      </>
    ) : (
      <span>Invalid Link</span>
    );

    const extraContent = requiredPasswordChange ? (
      <>
        <div className="input-field" style={{ marginBottom: "15px" }}>
          <LoginInput
            value={password}
            type="password"
            name="password"
            label="Password"
            hideLabel
            onKeyDown={(e) => handleKeyDown(e, this.register)}
            placeholder="Enter your new password"
            onChange={this.onPasswordChange}
            errorMessage={passwordError}
          />
        </div>
        <div className="input-field">
          <LoginInput
            value={secondPassword}
            type="password"
            name="secondPassword"
            hideLabel
            onKeyDown={(e) => handleKeyDown(e, this.register)}
            placeholder="Confirm your new password"
            onChange={this.onPasswordChange}
            errorMessage={secondPasswordError}
          />
          <StrengthMeter password={password ?? ""} />
        </div>
        <div className="create-pass-action-btns-wrapper">
          <div className="create-new-pass-btn">
            <ButtonMui
              label="Save"
              width="100%"
              variant={"contained"}
              onClick={this.sendNewPassword}
            />
          </div>
        </div>
      </>
    ) : null;

    return (
      <>
        {loader}
        <div className="create-new-pass-title">{headerText}</div>
        {extraContent}
      </>
    );
  };

  render() {
    const { password, secondPassword, passwordError, secondPasswordError, loading } = this.state;

    if (loading) {
    }
    return (
      <div className="create-new-pass-wrapper">
        <h1 style={{ display: "none" }}>
          Reset Password | Modeliks Small Business Financial Planning Software
        </h1>
        <div className="create-new-pass-content-wrapper">
          <div className="content">
            <div className="logo">
              <ModeliksHeaderLogo />
            </div>

            <div className="create-new-pass-content">{this.getContent()}</div>
            <CopyrightMsg />
          </div>
        </div>
        <div className="top-left-shape">
          <LoginTopLeftCornerBigShape></LoginTopLeftCornerBigShape>
        </div>
        <div className="bottom-right-shape">
          <LoginBottomRightCornerBigShape></LoginBottomRightCornerBigShape>
        </div>
        <div className="mobile-title-shape">
          <MobileTitleShape></MobileTitleShape>
        </div>

        <BackgroundBalls
          bigRightTopBall
          smallRightTopBall
          bigLeftTopBall
          smallLeftTopBall
          smallCenterMidBall
          smallLeftCenterBall
          smallRightCenterBall
          bigRightCenterBall
          smallCenterTopBall
          bigLeftBottomBall
          smallRightBottomBall
        />
      </div>
      // <AuthPage type='newPassword' sendNewPassword={this.sendNewPassword}/>
    );
  }
}

export default NewPassword;
