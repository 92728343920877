import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import React from "react";
import ButtonMui from "../../../../components/buttons/buttonMui/buttonMui";
import {
  LoginBottomRightCornerBigShape,
  LoginTopLeftCornerBigShape,
  Logo,
  MobileTitleShape,
} from "../../../../components/icons/svgIcons";
import BackgroundBalls from "../components/BackgroundBalls";
import "./RegisterPage.scss";
import {handleKeyDown, handleLogin} from "../Login";
import request from "superagent";
import { checkForMaliciousCharacters, EmailRegex } from "../components/AuthPage";
import LoginInput from "../../../../components/inputs/loginInput/LoginInput";
import Mx_MainLoader from "../../../../components/Loaders/MainLoader/Mx_MainLoader";
import "../Reset/CreateNewPassword/CreateNewPassword.scss";
import StrengthMeter from "../../../../components/actions/inputs/StrengthMeter";
import GoogleButton from "../../../../components/buttons/googleButton";
import {modeliksLinks} from "../../../../Enums/ModeliksEnum";

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      emailError: "",
      password: "",
      passwordError: "",
      secondPassword: "",
      secondPasswordError: "",
      name: "",
      surname: "",
      nameError: "",
      surnameError: "",

      isMarketingSubscribed: true,
    };
  }

  handleInputChange = (e, additionalStateItems) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + "Error"]: "",
      ...additionalStateItems,
    });
  };

  handleCheckboxChange = () => {
    this.setState((prevState) => ({
      isMarketingSubscribed: !prevState.isMarketingSubscribed,
    }));
  };

  register = () => {
    const {
      isMarketingSubscribed,
      name,
      surname,
      email,
      password,
      secondPassword,
      nameError,
      surnameError,
      emailError,
      passwordError,
      secondPasswordError,
    } = this.state;

    if (this.validate()) {
      this.setState({ loading: true });
      request
        .post("/api/register")
        .send({
          Email: email.trim().toLowerCase(),
          Password: password,
          Name: name.trim(),
          Surname: surname.trim(),
          isMarketingSubscribed,
          subscriptionID: this.props.SelectedSubscriptionID,
          ReferralID: this.props.referralId,
          ReferralSource: this.props.referralSource,
        })
        .then((data) => this.handleRegisterResponse(data, email))
        .catch(({response}) => {
          if (response.body.error == "already a mail") {
            // setLoading(false);
            this.setState({
              emailError: "There is already an account linked to this email",
              loading: false,
            });
          }
        })
    }
  };

  handleGoogleSignUpResponse = (data) => {
    if (data.body.Accounts) {
      let account = data.body.Accounts[0];
      if (account) {
        window.localStorage.setItem("User_ID", data.body.ID);
        window.localStorage.setItem("token", account.token);
        window.localStorage.setItem("Account_ID", account.ID);
        global.Modeliks.Account = {ID: account.ID};
        global.Modeliks.User = {ID: data.body.ID};
      }
    }
    this.handleRegisterResponse(data, undefined, true);
  }

  handleRegisterResponse = (data, email, reload = false) => {
    if (data.body.freeTrial) {
      const res = data.body;

      if (global.onUserRegisterPending) {
        global.onUserRegisterPending(res.User_ID, email);
      }

      if (global.onSubscriptionUpdate) {
        global.onSubscriptionUpdate(
          res.Account_ID,
          res.User_ID,
          res.Subscription_ID,
          "Free_Trial",
          true,
        );
      }

      window.localStorage.setItem("User_ID", res.User_ID);
      //removed success payment page
      // window.location.href = '/SuccessPayment/1'
      if (reload) {
        window.location.reload();
      } else {
        global.Modeliks.NavigateTo("/VerificationSent");
      }
    } else {
      const res = data.body;
      if (!email) {
        email = res.email;
      }

      window.localStorage.setItem("Account_ID", res.Account_ID);
      window.localStorage.setItem("isVerified", false);
      window.localStorage.setItem("Email", email);
      window.localStorage.setItem("User_ID", res.User_ID);

      if (this.props.referralId) {
        window.localStorage.setItem("clickid", this.props.referralId);
      }

      if (this.props.referralSource) {
        window.localStorage.setItem("referralSource", this.props.referralSource);
      }

      if (global.onUserRegisterPending) {
        global.onUserRegisterPending(res.User_ID, email);
      }
      // window.open(res.url, '_self');
      window.Paddle.Checkout.open({
        items: [
          {
            priceId: this.props.paddle_price,
            quantity: 1,
          },
        ],
        customer: {
          email,
        },
        customData: {
          account_id: res.Account_ID,
          subscription_id: this.props.SelectedSubscriptionID,
        },
      });
    }
  }

  validate = () => {
    const validName = this.validateName("name");
    const validSurname = this.validateName("surname");
    const validateEmail = this.validateEmail();
    const validatePasswords = this.validatePasswords();
    if (validName && validSurname && validateEmail && validatePasswords) {
      return true;
    }
    return false;
  };

  validateEmail = () => {
    if (!EmailRegex.test(this.state.email)) {
      this.setState({emailError: "Please enter a valid email address"});
      return false;
    }
    return true;
  };

  client = global.Modeliks.googleClient((response) => {
    this.setState({loading: true});
    request
      .post("/api/auth-code")
      .send({
        code: response.code,
        TimeStamp: new Date().getTime(),
        subscriptionID: this.props.SelectedSubscriptionID,
        isRegister: true
      })
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("X-Requested-With", "XmlHttpRequest")
      .then(this.handleGoogleSignUpResponse)
      .catch((e) => {
        this.setState({loading: false});
      });
    // Set custom header for CRSF
  });

  validatePasswords = () => {
    const {password, secondPassword, email} = this.state;
    let passwordError = "";
    let secondPasswordError = "";
    switch (true) {
      case password.length < 6:
        passwordError = "Your password must be at least 6 characters";
        break;
      case password !== secondPassword:
        passwordError = "Passwords do not match";
        secondPasswordError = "Passwords do not match";
        break;

      // case !specialCharPassword.test(password):
      //     passwordError = 'Special characters are required';
      //     break;

      case password === email:
        passwordError = "Email and Password cannot be the same";
        break;
    }

    if (passwordError.length) {
      this.setState({ passwordError, secondPasswordError });
      return false;
    }
    return true;
  };

  validateName = (key) => {
    const Name = this.state[key];
    if (checkForMaliciousCharacters(Name)) {
      this.setState({ [key + "Error"]: "Invalid characters" });
      return;
    }
    if (Name.toString().length) {
      return true;
    }
    this.setState({ [key + "Error"]: "Required field" });
    return false;
  };

  onPasswordChange = (e) =>
    this.handleInputChange(e, { passwordError: "", secondPasswordError: "" });

  render() {
    const {
      isMarketingSubscribed,
      name,
      surname,
      email,
      password,
      secondPassword,
      nameError,
      surnameError,
      emailError,
      passwordError,
      secondPasswordError,
      loading,
      mainUserEmail,
    } = this.state;

    return (
      <div className="register-wrapper">
        <BackgroundWrapper>
          {loading && <Mx_MainLoader secondLoader className={"blurred"} />}
          <div className="register-content-wrapper">
            <div className="content">
              <div className="logo">
                <ModeliksHeaderLogo />
              </div>
              <div className="register-content">
                <div className={`register-title${mainUserEmail ? " invited-acc" : ""}`}>
                  {mainUserEmail ? (
                    `${mainUserEmail} has invited you to Create a Modeliks Account`
                  ) : (
                    <>
                      <span>Create </span> <span> Account</span>
                    </>
                  )}
                </div>
                <div className="name-inputs">
                  <div className="name-input-field">
                    <LoginInput
                      value={name}
                      type="text"
                      name="name"
                      label="Name"
                      onKeyDown={(e) => handleKeyDown(e, this.register)}
                      placeholder="First Name"
                      onChange={this.handleInputChange}
                      errorMessage={nameError}
                    />
                  </div>
                  <div className="surname-input-field">
                    <LoginInput
                      value={surname}
                      type="text"
                      name="surname"
                      label=""
                      onKeyDown={(e) => handleKeyDown(e, this.register)}
                      placeholder="Last Name"
                      onChange={this.handleInputChange}
                      errorMessage={surnameError}
                    />
                  </div>
                </div>
                <div className="input-field" style={{ marginBottom: "16px" }}>
                  <LoginInput
                    value={email}
                    type="email"
                    name="email"
                    label="Email"
                    disabled={this.disabledEmailEdit}
                    onKeyDown={(e) => handleKeyDown(e, this.register)}
                    placeholder="Enter your email"
                    onChange={this.disabledEmailEdit ? () => {} : this.handleInputChange}
                    errorMessage={emailError}
                  />
                </div>
                <div className="input-field" style={{ marginBottom: "10px" }}>
                  <LoginInput
                    value={password}
                    type="password"
                    name="password"
                    label="Password"
                    onKeyDown={(e) => handleKeyDown(e, this.register)}
                    placeholder="Enter your password"
                    onChange={this.onPasswordChange}
                    errorMessage={passwordError}
                  />
                </div>
                <div className="input-field">
                  <LoginInput
                    value={secondPassword}
                    type="password"
                    name="secondPassword"
                    hideLabel
                    onKeyDown={(e) => handleKeyDown(e, this.register)}
                    placeholder="Confirm your password"
                    onChange={this.onPasswordChange}
                    errorMessage={secondPasswordError}
                  />
                  <StrengthMeter password={password} />
                </div>
                <div className="toc">
                  By clicking Sign Up you confirm that you have read and agree to our{" "}
                  <a
                    href={modeliksLinks.termsOfService}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms and Conditions
                  </a>
                  ,{" "}
                  <a
                    href={modeliksLinks.privacyPolicy}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>{" "}
                  and receiving product onboarding instructions vie email.
                </div>
                <div className="recieve-emails">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isMarketingSubscribed}
                        onChange={this.handleCheckboxChange}
                        defaultChecked
                        sx={{
                          color: "#01affd",
                          "&.Mui-checked": {
                            color: "#01affd",
                          },
                        }}
                      />
                    }
                    label="Modeliks may keep me informed via email about products and services."
                  />
                </div>
                <div className="signup-btn">
                  <ButtonMui
                    label="Sign Up"
                    width="100%"
                    style={{height: '44px'}}
                    variant={"contained"}
                    onClick={this.register}
                  />
                </div>
                <div className="have-acc signup-btn">
                  <GoogleButton label={'Sign up with Google'} onClick={() => this.client.requestCode()}/>
                </div>
                {/*<div className="line-wrapper">*/}
                {/*	<div className="line"></div>*/}
                {/*	<div className="text">or</div>*/}
                {/*	<div className="line"></div>*/}
                {/*</div>*/}
                {/*<div className="socials-login">*/}
                {/*	<div className="facebook-login">*/}
                {/*		<svg*/}
                {/*			width="38"*/}
                {/*			height="38"*/}
                {/*			viewBox="0 0 24 24"*/}
                {/*			fill="none"*/}
                {/*			xmlns="http://www.w3.org/2000/svg"*/}
                {/*		>*/}
                {/*			<path*/}
                {/*				d="M22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12C2 16.84 5.44 20.87 10 21.8V15H8V12H10V9.5C10 7.57 11.57 6 13.5 6H16V9H14C13.45 9 13 9.45 13 10V12H16V15H13V21.95C18.05 21.45 22 17.19 22 12Z"*/}
                {/*				fill="#3EA5DD"*/}
                {/*			/>*/}
                {/*		</svg>*/}
                {/*	</div>*/}
                {/*	<div className="google-login">*/}
                {/*		<svg*/}
                {/*			width="38"*/}
                {/*			height="38"*/}
                {/*			viewBox="0 0 24 24"*/}
                {/*			fill="none"*/}
                {/*			xmlns="http://www.w3.org/2000/svg"*/}
                {/*		>*/}
                {/*			<path*/}
                {/*				d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM15.9141 16.35C14.9625 17.2266 13.6641 17.7422 12.1148 17.7422C9.87188 17.7422 7.93125 16.4555 6.98672 14.5805C6.59766 13.8047 6.375 12.9281 6.375 12C6.375 11.0719 6.59766 10.1953 6.98672 9.41953C7.93125 7.54219 9.87188 6.25547 12.1148 6.25547C13.6617 6.25547 14.9602 6.825 15.9562 7.75078L14.3109 9.39844C13.7156 8.82891 12.9586 8.54062 12.1172 8.54062C10.6219 8.54062 9.35625 9.55078 8.90391 10.9078C8.78906 11.2523 8.72344 11.6203 8.72344 12C8.72344 12.3797 8.78906 12.7477 8.90391 13.0922C9.35625 14.4492 10.6219 15.4594 12.1148 15.4594C12.8883 15.4594 13.5445 15.2555 14.0578 14.9109C14.6672 14.5031 15.0703 13.8961 15.2039 13.1766H12.1148V10.9547H17.5219C17.5898 11.332 17.625 11.7234 17.625 12.1289C17.625 13.8797 16.9992 15.3492 15.9141 16.35Z"*/}
                {/*				fill="#3EA5DD"*/}
                {/*			/>*/}
                {/*		</svg>*/}
                {/*	</div>*/}
                {/*</div>*/}
              </div>
              <div className="login-content">
                <div className="have-acc">
                  <span className="have-acc-text">Already have an account?</span>
                  <ButtonMui
                    label="Log In"
                    width={90}
                    variant={"contained"}
                    onClick={() => global.Modeliks.NavigateTo("/login")}
                  />
                </div>
              </div>
              <CopyrightMsg />
            </div>
          </div>
        </BackgroundWrapper>
      </div>
    );
  }
}

export const CopyrightMsg = () => (
  <div className="copyright">
    <span>&copy; </span>Copyright - Modeliks Ltd 2024. All Rights Reserved.
  </div>
);

export const BackgroundWrapper = ({ children }) => (
  <>
    {children}
    <div className="top-left-shape">
      <LoginTopLeftCornerBigShape></LoginTopLeftCornerBigShape>
    </div>
    <div className="bottom-right-shape">
      <LoginBottomRightCornerBigShape></LoginBottomRightCornerBigShape>
    </div>
    <div className="mobile-title-shape">
      <MobileTitleShape></MobileTitleShape>
    </div>

    <BackgroundBalls
      bigRightTopBall
      smallRightTopBall
      bigLeftTopBall
      smallLeftTopBall
      smallCenterMidBall
      smallLeftCenterBall
      smallRightCenterBall
      bigRightCenterBall
      smallCenterTopBall
      bigLeftBottomBall
      smallRightBottomBall
    />
  </>
);

export const ModeliksHeaderLogo = () => {
  const handleClick = () => (window.location.href = "https://www.modeliks.com");
  return (
    <>
      <div onClick={handleClick}>
        <Logo width={"50px"} height={"50px"} />
      </div>
      <span onClick={handleClick} className="modeliks">
        modeliks
      </span>
    </>
  );
};

export default RegisterPage;
