import React from "react";
import Dialog from "@mui/material/Dialog";
import "./CompanyLimit.scss";
import { Exit } from "../../../SlideEditor/components/components/IconButtons/SubMenuIcons";
import IconButton from "@mui/material/IconButton";
import Button from "../../actions/Button";

class WarningDialog extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { onClose, onChange, open } = this.props;

    return (
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={open}
        className={"company_limit_dialog"}
        onClose={onClose}
      >
        <IconButton onClick={onClose} className={"cld_close_button"}>
          <Exit />
        </IconButton>

        <div className={"p1_strong"}>{this.props.title}</div>

        <div className={"cld_typography"}>
          <p>
            You are about to change your forecast period, which will affect your existing forecast.
            After confirming the forecast period change, please carefully review your financial
            model inputs to reflect the forecast period adjustment.
          </p>
        </div>

        <div className={"cld_typography2_buttons scdlw_warning_actions"}>
          <Button
            label={"Cancel"}
            padding={"0 0 0 0"}
            backgroundColor={"red150"}
            onClick={onClose}
          />
          <Button
            label={"Confirm Forecast Period Change"}
            width={"220px"}
            padding={"0 0 0 0"}
            backgroundColor={"primary"}
            onClick={onChange}
          />
        </div>
      </Dialog>
    );
  }
}

export default WarningDialog;
