import React from "react";
import NavigationActions from "../../../../components/containers/NavigationActions";
import Footer from "../components/Footer";
import FinanceTableMain from "../../../../components/tables/FinanceTable/FinanceTableMain";
import FinancialMainCharts from "../components/Charts/FinancialMainCharts";
import { bluePallete, greenPallete } from "../Revenue/components/ColorPalette";
import { FinancialBlank } from "../../../../components/containers/FinancialBlank";
import Expense from "../../../../data/Finance/Expense/index";
import ExpensesDetails from "./ExpensesDetails";
import { ExpandCollapseTablesID, ExpenseTypes } from "../../../../data/Finance/constants";
import { PermissionTypes } from "../../../../components/constants/permissions";
import { DriverPeriodTypes } from "../../../../data/Finance/CalculatedDriver/constants";
import CircleLoader from "../../../../components/Loaders/CircleLoader/CircleLoader";
import CollapseTableComponent from "../../../../components/tables/FinanceTable/components/CollapseTableComponent";

const ExpensesCategoryName = {
  ResearchAndDevelopment: "Research & Development",
  GeneralAndAdministrative: "General & Administrative",
  SalesAndMarketing: "Sales & Marketing",
  DirectCost: "Direct Cost",
};
class Expenses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      AddNew: false,
      EditExpense: false,
      lines: [],
      rows: [],
      columns: [],
      periodHeaders: [],
      monthDatasets: [],
      yearDatasets: [],
      monthValues: [],
      yearValues: [],
      chartKey: "chart_123422",
      showMonthly: true,
      step: null,
    };
    this.toggleActualsObj = {
      toggled: false,
      callBack: this.updateCollapsedValue,
    };
    this.EditPermission = global.Modeliks.UserPermissions[PermissionTypes.Financials] == 0;

    this.expense = [];
    this.allExpense = [];
    this.totalsRows = [];

    this.colors = bluePallete;
    this.colorsYear = greenPallete;
  }

  componentDidMount() {
    this.setData();
  }
  updateCollapsedValue = () => {
    this.toggleActualsObj.toggled = !this.toggleActualsObj.toggled;
    this.setState({ key: new Date().getTime() });
  };

  setData = () => {
    this.expense = global.Modeliks.ExpensesStore.dataGridRows.expense;
    this.allExpense = global.Modeliks.ExpensesStore;
    this.totalsRows = global.Modeliks.ExpensesStore.dataGridRows.totalsRows;
    this.expenseTotals = global.Modeliks.ExpensesStore.dataGridRows.streamTotals;
    this.expenseGrowthTotals = global.Modeliks.ExpensesStore.dataGridRows.streamTotalsGrowth;
    this.gridRows = global.Modeliks.ExpensesStore.dataGridRows.gridRows;

    setTimeout(() => {
      this.props.handleStreamLoad && this.props.handleStreamLoad(false);
      this.setState({
        loading: false,
      });
    }, this.props.time);
  };

  handleOnEditExpense = (row) => {
    this.setState({ EditExpense: row });
  };
  handleOnEditExpenseChildren = (expense, step) => {
    this.setState({ EditExpense: expense.data.Ref_ID, step: step });
  };

  addExpense = () => {
    this.setState({ AddNew: true });
  };

  closeExpense = (clickEvent = false, backDropClick = false, shouldRefreshData = false) => {
    this.state.AddNew = false;
    this.state.EditExpense = false;
    this.state.step = null;
    if (shouldRefreshData) {
      this.costsales = [];
      this.setState({ loading: true });
      this.props.handleStreamLoad && this.props.handleStreamLoad(true);
      global.Modeliks.LoadDrivers(this.setData);
    } else {
      this.setState({ loading: false });
    }
  };

  showLoading = (callback) => {
    this.props.handleStreamLoad && this.props.handleStreamLoad(true);
    this.setState({ loading: true }, callback());
  };

  handleGridDataChanged = (items) => {
    this.forceUpdate();

    items.forEach((item) => {
      setTimeout(() => {
        if (item.Save) {
          setTimeout(item.Save, 100);
        }
      }, 1);
    });
    this.forceUpdate();
  };

  handeMoveRow = (rows) => {
    this.gridRows = rows;
    this.forceUpdate();
  };
  render() {
    if (this.state.loading) {
      return (
        <div className="loader_chart_container">
          <CircleLoader />
        </div>
      );
    }

    if (this.allExpense.length === 0) {
      return (
        <React.Fragment>
          <NavigationActions
            prevLink="/forecast/employees"
            nextLink="/forecast/assets"
            nextLinkText={"Assets"}
            prevLinkText={"Employees"}
            className={"blank"}
            showStreamNavigation={true}
            label="Expenses"
            buttonLabel="Add Expense"
            disabled={this.EditPermission}
            onClick={this.addExpense}
          />
          <FinancialBlank
            onAdd={this.addExpense}
            disabled={this.EditPermission}
            headerText="Add your first Expense"
            buttonLabel="Add Expense"
          />
          {this.state.AddNew && (
            <ExpensesDetails
              totalDriver={this.expenseTotals}
              showLoading={this.showLoading}
              step={this.state.step}
              onClose={this.closeExpense}
            />
          )}
        </React.Fragment>
      );
    } else {
      return (
        <div className="pfr_main_revenue_content streams forecast_streams">
          <NavigationActions
            prevLink="/forecast/employees"
            nextLink="/forecast/assets"
            nextLinkText={"Assets"}
            prevLinkText={"Employees"}
            showStreamNavigation={true}
            label="Expenses"
            buttonLabel="Add Expense"
            disabled={this.EditPermission}
            onClick={this.addExpense}
          />
          <div className={"pfr_main_cont"}>
            <FinancialMainCharts
              totals={this.expenseTotals}
              periodHeaders={this.props.financialProps.chartPeriodHeaders}
              revenues={this.expense.filter((e) => e.Formula)}
              breakdownName="Name"
              updateTable={() => this.forceUpdate()}
              useShowHideCurrency={true}
              headers={this.props.financialProps.gridPeriodHeaders}
              growthDriver={this.expenseGrowthTotals}
            />

            <div className="main_table_container psf_upper_table_cont">
              {this.gridRows && (
                <div className="psf_reports_export_pdf" id="report_table">
                  <CollapseTableComponent
                    toggleActuals={
                      global.Modeliks.PERMISSIONS.Dashboards.restrictions.overAll.active
                        ? this.toggleActualsObj
                        : false
                    }
                    title="Forecast"
                    showMonthly={this.state.showMonthly}
                    showMonthlyOnChange={() =>
                      this.setState({ showMonthly: !this.state.showMonthly })
                    }
                    useCustomizableTable={true}
                    updateTable={() => this.forceUpdate()}
                    showHideCurrency={true}
                    disabledEdit={this.EditPermission}
                  >
                    <FinanceTableMain
                      rows={this.gridRows}
                      totalsRows={this.totalsRows}
                      headers={
                        this.state.showMonthly
                          ? this.props.financialProps.gridPeriodHeaders
                          : this.props.financialProps.gridPeriodHeaders_Years
                      }
                      indexTab={0}
                      showRowHeaders={true}
                      disabledEdit={this.EditPermission}
                      useDateIndexes={true}
                      handleOnEdit={this.handleOnEditExpense}
                      hasRenameDriverName={true}
                      useCustomizableTable={true}
                      useShowHideCurrency={true}
                      changeDriverName={true}
                      unitDisplay={true}
                      handleReload={() => this.closeExpense(false, false, true)}
                      useExpandCollapseStore={true}
                      ExpandCollapseID={`${ExpandCollapseTablesID.Expense}`}
                      wrapperClassName={"psf_main_streams_wrapper_div"}
                      makeDuplicate={() => console.log("this.makeDuplicate")}
                      checkIsDividedWithZero={true}
                      // handeMoveRow={this.handeMoveRow}
                      // useDrag={true}
                      disabledMove={true}
                      title="Expenses"
                      onDataChanged={this.handleGridDataChanged}
                    />
                  </CollapseTableComponent>
                </div>
              )}
            </div>

            {global.Modeliks.PERMISSIONS.Dashboards.restrictions.overAll.active && (
              <div className="main_table_container psf_upper_table_cont ">
                {this.gridRows && (
                  <div className="psf_reports_export_pdf " id="report_table">
                    <CollapseTableComponent
                      title="Actuals"
                      showActualTitle={true}
                      showMonthlyOnChange={() =>
                        this.setState({ showMonthly: !this.state.showMonthly })
                      }
                      useCustomizableTable={true}
                      updateTable={() => this.forceUpdate()}
                      showMonthly={this.state.showMonthly}
                      disabledEdit={this.EditPermission}
                      showHideCurrency={true}
                      toggleActualsObj={this.toggleActualsObj}
                      collapsed={!this.toggleActualsObj.toggled}
                    >
                      <FinanceTableMain
                        rows={this.gridRows}
                        totalsRows={this.totalsRows}
                        headers={
                          this.state.showMonthly
                            ? this.props.financialProps.gridPeriodHeaders_Actual
                            : this.props.financialProps.gridPeriodHeaders_Years_Actual
                        }
                        indexTab={0}
                        showRowHeaders={true}
                        disabledEdit={true}
                        useDateIndexes={true}
                        useShowHideCurrency={true}
                        useActual={true}
                        useExpandCollapseStore={true}
                        ExpandCollapseID={`actuals_${ExpandCollapseTablesID.Expense}`}
                        useDashboards={true}
                        useCustomizableTable={true}
                        wrapperClassName={"psf_main_streams_wrapper_div"}
                        disabledMove={true}
                        unitDisplay={true}
                        // wrapperClassName={'max_height'}
                        title="Actual"
                      />
                    </CollapseTableComponent>
                  </div>
                )}
              </div>
            )}
          </div>
          {this.state.AddNew && (
            <ExpensesDetails
              totalDriver={this.expenseTotals}
              showLoading={this.showLoading}
              step={this.state.step}
              onClose={this.closeExpense}
              open={this.state.AddNew}
            />
          )}
          {this.state.EditExpense && (
            <ExpensesDetails
              totalDriver={this.expenseTotals}
              step={this.state.step}
              showLoading={this.showLoading}
              onClose={this.closeExpense}
              expense={this.state.EditExpense}
            />
          )}
        </div>
      );
    }
  }
}

export default Expenses;
