import React from "react";
import FinanceDetailsDialog from "../../../../components/FinanceDetails/FinanceDetailsDialog";
import PropTypes from "prop-types";
import Signups from "./childrens/Signups";
import ChurnRate from "./childrens/ChurnRate";
import SubscriptionPrice from "./childrens/SubscriptionPrice";
import "./MultiSubscriptionsContent.scss";
import SimpleValues from "./childrens/SimpleValues";

class SubscriptionMultiple extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      revenue: this.props.revenue,
      itemChanged: false,
      curTab: null,
    };

    this.monthHeaders = global.Modeliks.DateHelper.months;
    this.yearHeaders = global.Modeliks.DateHelper.gridPeriodHeaders_Years();
    this.gridHeaders = [...this.monthHeaders, ...this.yearHeaders];

    this.tabs = [
      {
        title: "Signups",
        subtitle: "Total Signups",
        field: "Quantity",
        description:
          "Total signups is the number of total new customers that subscribe to your service in a specific period of time.",
        childrenFunc: this.renderSignups,
        growthEnabled: true,
        checkedGrowth: false,
        changeEnabled: true,
        allowDisplayActuals: true,
        showActuals: false,
        isTabSignups: true,
        errors: {
          simpleValue: false,
          customersAtStart: false,
          simpleValueUpdated: new Date().getTime(),
        },
        unit: "%",
        dataGridRow: {
          data: this.state.revenue.Quantity,
          drivers: [],
        },
        chart: {
          monthlyChartData: {
            data: this.state.revenue.Quantity.Values.filter(
              (c) => this.monthHeaders.indexOf(c.Date) > -1,
            ),
            labels: global.Modeliks.DateHelper.headers_Months(),
          },
          yearlyChartData: {
            data: this.state.revenue.Quantity.Values.filter(
              (c) => this.yearHeaders.indexOf(c.Date) > -1,
            ),
            labels: global.Modeliks.DateHelper.headers_Years(true),
          },
        },
        customers: {
          CustomersAtStart: 0,
        },
      },
      {
        title: "Churn Rate",
        subtitle: "Churn Rate % at renewal of plan",
        field: "ChurnRate",
        chartTitle: "Average monthly churn rate",
        description: `Churn rate % at renewal is the percentage of your customers or subscribers who canceled subsription or didn't renew the subscription.`,
        childrenFunc: this.renderSimpleValues,
        growthEnabled: true,
        checkedGrowth: false,
        changeEnabled: true,
        allowDisplayActuals: true,
        isActualTop: true,
        showActuals: false,
        errors: {
          simpleValue: false,
          customersAtStart: false,
          simpleValueUpdated: new Date().getTime(),
        },
        hideDataGridRow: true,
        unit: "%",
        dataGridRow: {
          data: this.state.revenue.ChurnRate,
          drivers: [],
        },
        chart: {
          monthlyChartData: {
            data: this.state.revenue.ChurnRate.Values.filter(
              (c) => this.monthHeaders.indexOf(c.Date) > -1,
            ),
            labels: global.Modeliks.DateHelper.headers_Months(),
          },
          yearlyChartData: {
            data: this.state.revenue.ChurnRate.Values.filter(
              (c) => this.yearHeaders.indexOf(c.Date) > -1,
            ),
            labels: global.Modeliks.DateHelper.headers_Years(true),
          },
        },
      },
      {
        title: "Refund",
        subtitle: "Refunds as % of signups",
        field: "Refund",
        description:
          "Refunds as % of signups is the percentage of new customers (signups) that request a refund of their payment. ",
        growthEnabled: false,
        checkedGrowth: false,
        changeEnabled: false,
        allowDisplayActuals: true,
        showActuals: false,
        errors: {
          simpleValue: false,
          customersAtStart: false,
          simpleValueUpdated: new Date().getTime(),
        },
        dataGridRow: {
          data: this.state.revenue.Refund,
          drivers: [],
        },
        chart: {
          monthlyChartData: {
            data: this.state.revenue.Refund.Values.filter(
              (c) => this.monthHeaders.indexOf(c.Date) > -1,
            ),
            labels: global.Modeliks.DateHelper.headers_Months(),
          },
          yearlyChartData: {
            data: this.state.revenue.Refund.Values.filter(
              (c) => this.yearHeaders.indexOf(c.Date) > -1,
            ),
            labels: global.Modeliks.DateHelper.headers_Years(true),
          },
        },
      },
      {
        title: "Subscription Price",
        subtitle: "Subscription Price",
        field: "SinglePrice",
        chartTitle: "Average Subscription Price",
        description:
          "Subscription price is the price a user pays for using your service for the duration of the subscription period.",
        childrenFunc: this.renderSimpleValues,
        growthEnabled: true,
        checkedGrowth: false,
        changeEnabled: true,
        hideDataGridRow: true,
        allowDisplayActuals: true,
        isActualTop: true,
        showActuals: false,
        errors: {
          simpleValue: false,
          customersAtStart: false,
          simpleValueUpdated: new Date().getTime(),
        },
        breakDownTitle: "Subscription Price per Plan",
        unit: global.Modeliks.CompanyInfo.Currency.value,
        dataGridRow: {
          data: this.state.revenue.SinglePrice,
          drivers: [],
        },
        chart: {
          monthlyChartData: {
            data: this.state.revenue.SinglePrice.Values.filter(
              (c) => this.monthHeaders.indexOf(c.Date) > -1,
            ),
            labels: global.Modeliks.DateHelper.headers_Months(),
          },
          yearlyChartData: {
            data: this.state.revenue.SinglePrice.Values.filter(
              (c) => this.yearHeaders.indexOf(c.Date) > -1,
            ),
            labels: global.Modeliks.DateHelper.headers_Years(true),
          },
        },
      },
    ];

    this.state.curTab = this.tabs[0];
  }

  renderSignups = (tab, scroll) => {
    return (
      <Signups
        tab={tab}
        onScroll={scroll}
        revenue={this.state.revenue}
        childrens={this.state.revenue.children}
        field={tab.field}
        headers={this.gridHeaders}
        onDataChanged={this.onDataChanged}
      />
    );
  };
  renderSimpleValues = (tab, scroll) => {
    return (
      <SimpleValues
        tab={tab}
        onScroll={scroll}
        title={tab.breakDownTitle}
        revenue={this.state.revenue}
        childrens={this.state.revenue.children}
        field={tab.field}
        headers={this.gridHeaders}
        onDataChanged={this.onDataChanged}
      />
    );
  };

  onTabChange = (tab) => {
    if (this.state.curTab == this.tabs[0]) {
      return this.validate();
    } else {
      return true;
    }
  };

  onSave = () => {
    this.props.saveRevenue();
  };

  onDataChanged = (item) => {
    this.setState({ itemChanged: !this.state.itemChanged });
  };

  validate = () => {
    const isValidPercentage = this.validatePercentage();
    if (isValidPercentage) {
      this.forceUpdate();
      return true;
    } else {
      const element = document.getElementById("pscf_single_finance_tab_content_div_bottom_scroll");
      element.scrollIntoView();
      this.forceUpdate();
      return false;
    }
  };

  validatePercentage = () => {
    const Field = "Quantity";
    const Field_Split = Field + "_Split";
    let isValid = true;
    const ceilNumber = (number) => {
      return Math.ceil(number);
    };
    const filterValidFields = (Values) => {
      return Values.filter(
        (c) =>
          this.monthHeaders.indexOf(c.Date) > -1 ||
          (this.yearHeaders.indexOf(c.Date) > -1 && c.Date.Active),
      );
    };
    this.tabs[0].errors.simpleValue = false;
    if (this.state.revenue.hasQuantitySplit()) {
      if (this.state.revenue.children[0].hasSingleValue(Field_Split)) {
        let totalPercent = 0;
        const Values = filterValidFields(this.state.revenue.children[0][Field_Split].Values);
        for (let rowIndex = 0; rowIndex < Values.length; rowIndex++) {
          totalPercent = 0;
          const curValue = Values[rowIndex];

          for (let colIndex = 0; colIndex < this.state.revenue.children.length; colIndex++) {
            totalPercent += this.state.revenue.children[colIndex][Field_Split].getItemByDateSufix(
              curValue.Date.sufix,
            ).Value;
            this.state.revenue.children[colIndex][Field_Split].getItemByDateSufix(
              curValue.Date.sufix,
            ).error = [];
          }
          if (ceilNumber(totalPercent) !== 100) {
            for (let colIndex = 0; colIndex < this.state.revenue.children.length; colIndex++) {
              isValid = false;
              this.state.revenue.children[colIndex][Field_Split].getItemByDateSufix(
                curValue.Date.sufix,
              ).error.push(Field_Split);
              this.tabs[0].errors.simpleValue = true;
              this.tabs[0].errors.simpleValueUpdated = new Date().getTime();
            }
          }
        }
        return isValid;
      } else {
        let totals = this.state.revenue.children.map(
          (c) => c[Field_Split].getSingleDriverValue().Value,
        );
        let sum = totals.reduce(function (a, b) {
          return a + b;
        }, 0);
        if (ceilNumber(sum) !== 100) {
          isValid = false;
          this.tabs[0].errors.simpleValue = true;
          this.tabs[0].errors.simpleValueUpdated = new Date().getTime();
        }
        return isValid;
      }
    } else {
      return true;
    }
  };

  render = () => {
    if (this.state.revenue == null) {
      return null;
    }
    return (
      <FinanceDetailsDialog
        open={this.props.open}
        title={this.props.revenue.RevenueName}
        tabs={this.tabs}
        onTabChange={this.onTabChange}
        saveEnabled={this.state.itemChanged}
        headers={this.gridHeaders}
        onSave={this.onSave}
        onClose={this.props.onClose}
        enableDrivers={false}
        totalDriver={this.props.totalDriver}
        onGeneral={this.props.onGeneral}
        revenue={this.props.revenue}
        onDataChanged={this.onDataChanged}
        multiple={true}
        tabIndex={this.props.tabIndex}
      />
    );
  };
}

SubscriptionMultiple.propTypes = {
  revenue: PropTypes.object,
  onGeneral: PropTypes.func,
  onClose: PropTypes.func,
};

export default SubscriptionMultiple;
