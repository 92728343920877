import CalculatedDriver from "../../CalculatedDriver/index";
import { DriverCategories, UnitTypes } from "../../CalculatedDriver/constants";
import Reports from "../index";
import { ReportsTypes } from "../constants";
import ProfitLoss from "../ProfitLoss";
import AccountReceivable from "../../WorkingCapital/AccountReceivable";
import AccountPayable from "../../WorkingCapital/AccountPayable";
import Inventory from "../../WorkingCapital/Inventory";
import Assets from "../../Assets";
import Financing from "../../Financing";
import Taxes from "../../Taxes";
import CalculatedDriver_Values from "../../CalculatedDriver/CalculatedDriver_Values";
import Subscription from "../../Revenue/Subscription";

class CashFlow extends Reports {
  Name = "";
  ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
  ReportType = ReportsTypes.CashFlow;
  ReportsType = ReportsTypes.CashFlow;

  constructor(db_record) {
    super(db_record);
    if (this.db_record) {
      this.Name = this.db_record.Name;
      this.ReportType = this.db_record.ReportType;
      // this.setReport();
    }
  }

  static ChangeDriversDesc = Object.assign({
    ChangeInAccountsReceivable: {
      driverName: "Change In Accounts Receivable",
      fieldName: "ChangeInAccountsReceivable",
      driverID: "change_in_accounts_receivable",
      unit: UnitTypes.PriceNegative,
      category: DriverCategories.Sum,
    },
    ChangeInAccountsPayable: {
      driverName: "Change In Accounts Payable",
      fieldName: "ChangeInAccountsPayable",
      driverID: "change_in_accounts_payable",
      unit: UnitTypes.PriceNegative,
      category: DriverCategories.Sum,
    },
    ChangeInInventory: {
      driverName: "Change In Inventory",
      fieldName: "ChangeInInventory",
      driverID: "change_in_inventory",
      unit: UnitTypes.PriceNegative,
      category: DriverCategories.Sum,
    },
    ChangeInOtherCurrentAssets: {
      driverName: "Change In Other Current Assets",
      fieldName: "ChangeInOtherCurrentAssets",
      driverID: "change_in_other_current_assets",
      unit: UnitTypes.PriceNegative,
      category: DriverCategories.Sum,
    },
    ChangeInOtherCurrentLiabilities: {
      driverName: "Change In Other Current Liabilities",
      fieldName: "ChangeInOtherCurrentLiabilities",
      driverID: "change_in_other_current_liabilities",
      unit: UnitTypes.PriceNegative,
      category: DriverCategories.Sum,
    },
    ChangeInOtherLongTermLiabilities: {
      driverName: "Change In Other Long Term Liabilities",
      fieldName: "ChangeInOtherLongTermLiabilities",
      driverID: "change_in_other_long_term_liabilities",
      unit: UnitTypes.PriceNegative,
      category: DriverCategories.Sum,
    },
    CashAtBeginningOfThePeriod: {
      driverName: "Cash At Beginning Of The Period",
      fieldName: "CashAtBeginningOfThePeriod",
      driverID: "cash_at_beginning_of_the_period",
      unit: UnitTypes.PriceNegative,
      category: DriverCategories.FirstPeriod,
    },
    SubscriptionsCashFlowAdjustment: {
      driverName: "Change in Unearned Revenue",
      fieldName: "SubscriptionsCashFlowAdjustment",
      driverID: "subscriptions_cash_flow_adjustment",
      unit: UnitTypes.PriceNegative,
      category: DriverCategories.Sum,
    },
  });

  static DriversDesc = Object.assign({
    Total: {
      driverName: "$Name",
      fieldName: "Totals",
      driverID: "total",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    NetCashFromOperatingActivities: {
      driverName: "Net Cash From Operating Activities",
      fieldName: "NetCashFromOperatingActivities",
      driverID: "net_cash_from_operating_activities",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    ChangeInCurrentAssetsAndLiabilities: {
      driverName: "Change In Working Capital",
      fieldName: "ChangeInCurrentAssetsAndLiabilities",
      driverID: "change_in_current_assets_and_liabilities",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    NetCashFromInvestingActivities: {
      driverName: "Net Cash From Investing Activities",
      fieldName: "NetCashFromInvestingActivities",
      driverID: "net_cash_from_investing_activities",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    NetCashFromFinancingActivities: {
      driverName: "Net Cash From Financing Activities",
      fieldName: "NetCashFromFinancingActivities",
      driverID: "net_cash_from_financing_activities",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    ChangeInDebt: {
      driverName: "Change In Debt",
      fieldName: "ChangeInDebt",
      driverID: "change_in_debt",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    GainOrLossFromSaleOfPPE: {
      driverName: "Sale of PPE",
      fieldName: "GainOrLossFromSaleOfPPE",
      driverID: "gain_or_loss_from_sale_of_ppe",
      unit: UnitTypes.PriceNegative,
      category: DriverCategories.Sum,
    },
    NetChangeInCash: {
      driverName: "Net increase (decrease) in cash",
      fieldName: "NetChangeInCash",
      driverID: "net_change_in_cash",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    CashAtEndOfThePeriod: {
      driverName: "Cash Balance",
      fieldName: "CashAtEndOfThePeriod",
      driverID: "cash_at_end_of_the_period",
      unit: UnitTypes.Price,
      category: DriverCategories.LastPeriod,
    },
  });
  getMonthDatesAll = () => {
    return [
      ...global.Modeliks.DateHelper.months_before_actual,
      ...global.Modeliks.DateHelper.months,
    ];
  };

  getYearDatesAll = () => {
    return [
      ...global.Modeliks.DateHelper.years_before_actual,
      ...global.Modeliks.DateHelper.years_all,
    ];
  };

  periodsData = {};

  buildPeriodsData = () => {
    const allPeriods = this.Totals.Values.map((c) => c.Date);
    allPeriods.forEach((period) => {
      this.periodsData[period.dateID] = {};
      Object.values(this.constructor.DriversDesc).forEach(
        (driver) =>
          (this.periodsData[period.dateID][driver.fieldName] = this[
            driver.fieldName
          ].getItemByDateSufix(period.sufix)),
      );
      Object.values(this.constructor.ChangeDriversDesc).forEach(
        (driver) =>
          (this.periodsData[period.dateID][driver.fieldName] = this[
            driver.fieldName
          ].getItemByDateSufix(period.sufix)),
      );
      this.periodsData[period.dateID].Date = period;
    });
  };

  setReport = () => {
    this.buildPeriodsData();
    this.createMonthsFormulas();
    this.createYearsFormulas();
  };

  createMonthsFormulas = () => {
    const months = this.getMonthDatesAll();

    const accountReceivableOpeningBalance = global.Modeliks.DriversStore.find(
      (d) => d.Ref_Field === AccountReceivable.DriversDesc.OpeningBalance.driverID,
    );
    const accountReceivableClosingBalance = global.Modeliks.DriversStore.find(
      (d) => d.Ref_Field === AccountReceivable.DriversDesc.ClosingBalance.driverID,
    );
    const accountPayableOpeningBalance = global.Modeliks.DriversStore.find(
      (d) => d.Ref_Field === AccountPayable.DriversDesc.OpeningBalance.driverID,
    );
    const accountPayableClosingBalance = global.Modeliks.DriversStore.find(
      (d) => d.Ref_Field === AccountPayable.DriversDesc.ClosingBalance.driverID,
    );
    const inventoryOpeningBalance = global.Modeliks.DriversStore.find(
      (d) => d.Ref_Field === Inventory.DriversDesc.OpeningBalance.driverID,
    );
    const inventoryClosingBalance = global.Modeliks.DriversStore.find(
      (d) => d.Ref_Field === Inventory.DriversDesc.ClosingBalance.driverID,
    );
    const TotalCashCollections = Subscription.getSubscriptionsCashCollections();
    const TotalSubscriptionRevenue = Subscription.getSubscriptionsTotals();

    const CurrentAssetsOpenningBalanceAssetPurchases =
      Assets.getAssetPurchasesFromOpeningBalanceDrivers();
    const CurrentAssetPurchasesFromDrivers = Assets.getAssetPurchasesFromDrivers();
    const CurrentAssetSales = Assets.getAssetSalesFromDrivers();
    const AssetsGainAndLoss = Assets.getAssetGainAndLoss();
    const FinancingCurrentOpeningBalance = Financing.getCurrentOtherLiabilitiesOpeningBalance();
    const FinancingCurrentClosingBalance =
      Financing.getCurrentOtherLiabilitiesOtherLiabilityBalance();
    const FinancingLongTermOpeningBalance = Financing.getLongTermOtherLiabilitiesOpeningBalance();
    const FinancingLongTermClosingBalance =
      Financing.getLongTermOtherLiabilitiesOtherLiabilityBalance();
    const FinancingLongTermOtherLiabilities = Financing.getLongTermOtherLiabilities();
    const changeInIncomeTaxPayable = Taxes.getChangeInIncomeTaxPayable();
    const changeInSalesTaxPayable = Taxes.getChangeInSalesTaxPayable();
    const changeInVATTaxPayable = Taxes.getChangeInVATTaxPayable();
    const NetIncome = global.Modeliks.DriversStore.find(
      (d) => d.Ref_Field === ProfitLoss.DriversDesc.NetIncome.driverID,
    );
    const totalDepreciation = Assets.getDepreciation();
    const longTermAssetPayments = Assets.getLongTermAssetPaymentsFromDriversNegative();
    const longTermAssetSales = Assets.getLongTermAssetSalesFromDrivers();
    const EquityInvestments = Financing.getEquityInvestmentsOnlyEquity();
    const DividendsTotals = Financing.getDividendsTotals();
    const ChangeInLoan = Financing.getChangeInLone();
    const ChangeInLineOfCredit = Financing.getChangeInLineOfCredit();
    const otherCurrentAssets = Assets.getAssetsCurrent();
    const otherCurrentLiabilities = Financing.getCurrentOtherLiabilities();
    const PrepaidRevenueClosingBalance = Financing.getPrepaidRevenueClosingBalance();

    this.NetCashFromOperatingActivities.Formula = `${NetIncome} + ${totalDepreciation} + ${this.SubscriptionsCashFlowAdjustment} + ${this.GainOrLossFromSaleOfPPE} + ${this.ChangeInCurrentAssetsAndLiabilities}`;
    this.ChangeInCurrentAssetsAndLiabilities.Formula = `${this.ChangeInAccountsReceivable} + ${this.ChangeInAccountsPayable} + ${this.ChangeInInventory} + ${this.ChangeInOtherCurrentAssets} + ${this.ChangeInOtherCurrentLiabilities} + ${changeInIncomeTaxPayable} + ${changeInSalesTaxPayable} + ${changeInVATTaxPayable}`;
    this.NetCashFromInvestingActivities.Formula = `${longTermAssetPayments} + ${longTermAssetSales}`;
    this.NetCashFromFinancingActivities.Formula = `${this.ChangeInOtherLongTermLiabilities} + ${this.ChangeInDebt} + ${EquityInvestments} + ${DividendsTotals}`;
    this.GainOrLossFromSaleOfPPE.Formula = `${AssetsGainAndLoss}`;
    this.ChangeInDebt.Formula = `${ChangeInLoan} + ${ChangeInLineOfCredit}`;

    months.forEach((month) => {
      const prevMonthDate = months.find((c) => c.Order == month.Order - 1);
      const curMonth = this.periodsData[month.dateID];
      const prevMonth = prevMonthDate ? this.periodsData[prevMonthDate.dateID] : null;

      curMonth.GainOrLossFromSaleOfPPE.Formula = `MxMath.PositiveOrNegative(${AssetsGainAndLoss.getItemByDateSufix(month.sufix)})`;
      curMonth.SubscriptionsCashFlowAdjustment.Formula = `${TotalCashCollections.getItemByDateSufix(month.sufix)} - ${TotalSubscriptionRevenue.getItemByDateSufix(month.sufix)}`;

      if (prevMonth && month.Order !== global.Modeliks.DateHelper.months_before_actual[0].Order) {
        curMonth.ChangeInOtherCurrentAssets.Formula = `${CurrentAssetSales.getItemByDateSufix(month.sufix)} - ${CurrentAssetPurchasesFromDrivers.getItemByDateSufix(month.sufix)}`;
        curMonth.CashAtBeginningOfThePeriod.Formula = `${prevMonth.CashAtEndOfThePeriod}`;
      } else {
        curMonth.ChangeInOtherCurrentAssets.Formula = `${CurrentAssetsOpenningBalanceAssetPurchases.getItemByDateSufix(month.sufix)} - ${CurrentAssetPurchasesFromDrivers.getItemByDateSufix(month.sufix)} + ${CurrentAssetSales.getItemByDateSufix(month.sufix)}`;
        curMonth.CashAtBeginningOfThePeriod.Formula = `${CashFlow.getOpeningCashBalance()}`;
      }

      curMonth.ChangeInCurrentAssetsAndLiabilities.Formula = `${curMonth.ChangeInAccountsReceivable} + ${curMonth.ChangeInAccountsPayable} + ${curMonth.ChangeInInventory} + ${curMonth.ChangeInOtherCurrentAssets} + ${curMonth.ChangeInOtherCurrentLiabilities} + ${changeInIncomeTaxPayable.getItemByDateSufix(month.sufix)} + ${changeInSalesTaxPayable.getItemByDateSufix(month.sufix)} + ${changeInVATTaxPayable.getItemByDateSufix(month.sufix)}`;

      curMonth.ChangeInOtherCurrentLiabilities.Formula = `${FinancingCurrentClosingBalance.getItemByDateSufix(month.sufix)} - ${FinancingCurrentOpeningBalance.getItemByDateSufix(month.sufix)}`;
      curMonth.ChangeInOtherLongTermLiabilities.Formula = `${FinancingLongTermClosingBalance.getItemByDateSufix(month.sufix)} - ${FinancingLongTermOpeningBalance.getItemByDateSufix(month.sufix)}`;
      curMonth.ChangeInDebt.Formula = `${ChangeInLoan.getItemByDateSufix(month.sufix)} + ${ChangeInLineOfCredit.getItemByDateSufix(month.sufix)}`;
      curMonth.NetCashFromOperatingActivities.Formula = `${NetIncome.getItemByDateSufix(month.sufix)} + ${totalDepreciation.getItemByDateSufix(month.sufix)} + ${curMonth.GainOrLossFromSaleOfPPE} + ${curMonth.ChangeInAccountsReceivable} + ${curMonth.ChangeInAccountsPayable} + ${curMonth.ChangeInInventory} + ${curMonth.ChangeInOtherCurrentAssets} + ${curMonth.ChangeInOtherCurrentLiabilities} + ${curMonth.SubscriptionsCashFlowAdjustment} + ${changeInIncomeTaxPayable.getItemByDateSufix(month.sufix)} + ${changeInSalesTaxPayable.getItemByDateSufix(month.sufix)} + ${changeInVATTaxPayable.getItemByDateSufix(month.sufix)}`;
      curMonth.NetCashFromInvestingActivities.Formula = `${longTermAssetPayments.getItemByDateSufix(month.sufix)} + ${longTermAssetSales.getItemByDateSufix(month.sufix)}`;
      curMonth.NetCashFromFinancingActivities.Formula = `${curMonth.ChangeInOtherLongTermLiabilities} + ${curMonth.ChangeInDebt} + ${EquityInvestments.getItemByDateSufix(month.sufix)} + ${DividendsTotals.getItemByDateSufix(month.sufix)}`;
      curMonth.NetChangeInCash.Formula = `${curMonth.NetCashFromOperatingActivities} + ${curMonth.NetCashFromInvestingActivities} + ${curMonth.NetCashFromFinancingActivities}`;
      curMonth.CashAtEndOfThePeriod.Formula = `${curMonth.CashAtBeginningOfThePeriod} + ${curMonth.NetChangeInCash}`;

      //actuals formulas

      if (prevMonth) {
        curMonth.ChangeInAccountsReceivable.Formula =
          `${accountReceivableOpeningBalance.getItemByDateSufix(month.sufix)} - ${accountReceivableClosingBalance.getItemByDateSufix(month.sufix)}` +
          ` Actual(${accountReceivableClosingBalance.getItemByDateSufix(prevMonthDate.sufix).ID_f_actual} - ${accountReceivableClosingBalance.getItemByDateSufix(month.sufix).ID_f_actual})`;
        curMonth.ChangeInAccountsPayable.Formula =
          `${accountPayableClosingBalance.getItemByDateSufix(month.sufix)} - ${accountPayableOpeningBalance.getItemByDateSufix(month.sufix)}` +
          ` Actual(${accountPayableClosingBalance.getItemByDateSufix(month.sufix).ID_f_actual} - ${accountPayableClosingBalance.getItemByDateSufix(prevMonthDate.sufix).ID_f_actual})`;
        curMonth.ChangeInInventory.Formula =
          `${inventoryOpeningBalance.getItemByDateSufix(month.sufix)} - ${inventoryClosingBalance.getItemByDateSufix(month.sufix)}` +
          ` Actual(${inventoryClosingBalance.getItemByDateSufix(prevMonthDate.sufix).ID_f_actual} - ${inventoryClosingBalance.getItemByDateSufix(month.sufix).ID_f_actual})`;
        curMonth.ChangeInOtherCurrentAssets.Formula =
          `${curMonth.ChangeInOtherCurrentAssets.Formula ? curMonth.ChangeInOtherCurrentAssets.Formula : ""}` +
          ` Actual(${otherCurrentAssets.getItemByDateSufix(prevMonthDate.sufix).ID_f_actual} - ${otherCurrentAssets.getItemByDateSufix(month.sufix).ID_f_actual})`;
        curMonth.ChangeInOtherCurrentLiabilities.Formula =
          `${curMonth.ChangeInOtherCurrentLiabilities.Formula ? curMonth.ChangeInOtherCurrentLiabilities.Formula : ""}` +
          ` Actual(${otherCurrentLiabilities.getItemByDateSufix(month.sufix).ID_f_actual} - ${otherCurrentLiabilities.getItemByDateSufix(prevMonthDate.sufix).ID_f_actual})`;
        curMonth.ChangeInOtherLongTermLiabilities.Formula =
          `${curMonth.ChangeInOtherLongTermLiabilities.Formula ? curMonth.ChangeInOtherLongTermLiabilities.Formula : ""}` +
          ` Actual(${FinancingLongTermOtherLiabilities.getItemByDateSufix(month.sufix).ID_f_actual} - ${FinancingLongTermOtherLiabilities.getItemByDateSufix(prevMonthDate.sufix).ID_f_actual})`;
        curMonth.CashAtBeginningOfThePeriod.Formula =
          `${curMonth.CashAtBeginningOfThePeriod.Formula ? curMonth.CashAtBeginningOfThePeriod.Formula : ""}` +
          ` Actual(${prevMonth.CashAtEndOfThePeriod.ID_f_actual})`;
        curMonth.SubscriptionsCashFlowAdjustment.Formula =
          `${curMonth.SubscriptionsCashFlowAdjustment.Formula ? curMonth.SubscriptionsCashFlowAdjustment.Formula : ""}` +
          ` Actual(${PrepaidRevenueClosingBalance.getItemByDateSufix(month.sufix).ID_f_actual} - ${PrepaidRevenueClosingBalance.getItemByDateSufix(prevMonthDate.sufix).ID_f_actual})`;
      } else {
        curMonth.ChangeInAccountsReceivable.Formula = `${accountReceivableOpeningBalance.getItemByDateSufix(month.sufix)} - ${accountReceivableClosingBalance.getItemByDateSufix(month.sufix)}`;
        curMonth.ChangeInAccountsReceivable.evalFormulaActual = null;
        curMonth.ChangeInAccountsPayable.Formula = `${accountPayableClosingBalance.getItemByDateSufix(month.sufix)} - ${accountPayableOpeningBalance.getItemByDateSufix(month.sufix)}`;
        curMonth.ChangeInAccountsPayable.evalFormulaActual = null;
        curMonth.ChangeInInventory.Formula = `${inventoryOpeningBalance.getItemByDateSufix(month.sufix)} - ${inventoryClosingBalance.getItemByDateSufix(month.sufix)}`;
        curMonth.ChangeInInventory.evalFormulaActual = null;
        curMonth.ChangeInOtherCurrentAssets.evalFormulaActual = null;
        curMonth.ChangeInOtherCurrentLiabilities.evalFormulaActual = null;
        curMonth.ChangeInOtherLongTermLiabilities.evalFormulaActual = null;
        // curMonth.SubscriptionsCashFlowAdjustment.Unit = UnitTypes.PriceNegative;
      }
    });
  };

  createYearsFormulas = () => {
    const years = this.getYearDatesAll();

    const accountReceivableOpeningBalance = global.Modeliks.DriversStore.find(
      (d) => d.Ref_Field === AccountReceivable.DriversDesc.OpeningBalance.driverID,
    );
    const accountReceivableClosingBalance = global.Modeliks.DriversStore.find(
      (d) => d.Ref_Field === AccountReceivable.DriversDesc.ClosingBalance.driverID,
    );
    const accountPayableOpeningBalance = global.Modeliks.DriversStore.find(
      (d) => d.Ref_Field === AccountPayable.DriversDesc.OpeningBalance.driverID,
    );
    const accountPayableClosingBalance = global.Modeliks.DriversStore.find(
      (d) => d.Ref_Field === AccountPayable.DriversDesc.ClosingBalance.driverID,
    );
    const inventoryOpeningBalance = global.Modeliks.DriversStore.find(
      (d) => d.Ref_Field === Inventory.DriversDesc.OpeningBalance.driverID,
    );
    const inventoryClosingBalance = global.Modeliks.DriversStore.find(
      (d) => d.Ref_Field === Inventory.DriversDesc.ClosingBalance.driverID,
    );
    const TotalCashCollections = Subscription.getSubscriptionsCashCollections();
    const TotalSubscriptionRevenue = Subscription.getSubscriptionsTotals();
    const CurrentAssetPurchasesFromDrivers = Assets.getAssetPurchasesFromDrivers();
    const CurrentAssetSales = Assets.getAssetSalesFromDrivers();
    const AssetsGainAndLoss = Assets.getAssetGainAndLoss();
    const FinancingCurrentOpeningBalance = Financing.getCurrentOtherLiabilitiesOpeningBalance();
    const FinancingCurrentClosingBalance =
      Financing.getCurrentOtherLiabilitiesOtherLiabilityBalance();
    const FinancingLongTermOpeningBalance = Financing.getLongTermOtherLiabilitiesOpeningBalance();
    const FinancingLongTermClosingBalance =
      Financing.getLongTermOtherLiabilitiesOtherLiabilityBalance();
    const changeInIncomeTaxPayable = Taxes.getChangeInIncomeTaxPayable();
    const changeInSalesTaxPayable = Taxes.getChangeInSalesTaxPayable();
    const changeInVATTaxPayable = Taxes.getChangeInVATTaxPayable();
    const NetIncome = global.Modeliks.DriversStore.find(
      (d) => d.Ref_Field === ProfitLoss.DriversDesc.NetIncome.driverID,
    );
    const totalDepreciation = Reports.getDepreciation();
    const longTermAssetPayments = Assets.getLongTermAssetPaymentsFromDriversNegative();
    const longTermAssetSales = Assets.getLongTermAssetSalesFromDrivers();
    const EquityInvestments = Financing.getEquityInvestmentsOnlyEquity();
    const DividendsTotals = Financing.getDividendsTotals();
    const ChangeInLoan = Financing.getChangeInLone();
    const ChangeInLineOfCredit = Financing.getChangeInLineOfCredit();
    const otherCurrentAssets = Assets.getAssetsCurrent();
    const otherCurrentLiabilities = Financing.getCurrentOtherLiabilities();
    const FinancingLongTermOtherLiabilities = Financing.getLongTermOtherLiabilities();
    const PrepaidRevenueClosingBalance = Financing.getPrepaidRevenueClosingBalance();

    years.forEach((year) => {
      const curYear = this.periodsData[year.dateID];
      const months = this.periodsData[year.dateID].Date.monthIndexes.map(
        (index) => this.periodsData[index],
      );
      const prevYearDate = years.find((c) => c.Order === year.Order - 1);
      const prevYear = prevYearDate ? this.periodsData[prevYearDate.dateID] : null;
      const firstMonth = months[0];

      curYear.GainOrLossFromSaleOfPPE.Formula = `MxMath.PositiveOrNegative(${AssetsGainAndLoss.getItemByDateSufix(year.sufix)})`;
      curYear.ChangeInAccountsReceivable.Formula = `${accountReceivableOpeningBalance.getItemByDateSufix(year.sufix)} - ${accountReceivableClosingBalance.getItemByDateSufix(year.sufix)}`;
      curYear.ChangeInAccountsPayable.Formula = `${accountPayableClosingBalance.getItemByDateSufix(year.sufix)} - ${accountPayableOpeningBalance.getItemByDateSufix(year.sufix)}`;
      curYear.ChangeInInventory.Formula = `${inventoryOpeningBalance.getItemByDateSufix(year.sufix)} - ${inventoryClosingBalance.getItemByDateSufix(year.sufix)}`;
      curYear.SubscriptionsCashFlowAdjustment.Formula = `${TotalCashCollections.getItemByDateSufix(year.sufix)} - ${TotalSubscriptionRevenue.getItemByDateSufix(year.sufix)}`;

      if (!year.Active && firstMonth) {
        curYear.ChangeInOtherCurrentAssets.Formula = `MxMath.Sum([${months.map((c) => c.ChangeInOtherCurrentAssets.ID_f).join(",")}])`;
        curYear.CashAtBeginningOfThePeriod.Formula = `${firstMonth.CashAtBeginningOfThePeriod}`;
        curYear.CashAtBeginningOfThePeriod.evalFormulaActual = null;
      } else {
        curYear.ChangeInOtherCurrentAssets.Formula = `${CurrentAssetPurchasesFromDrivers.getItemByDateSufix(year.sufix)} + ${CurrentAssetSales.getItemByDateSufix(year.sufix)}`;
        curYear.CashAtBeginningOfThePeriod.Formula = prevYear
          ? `${prevYear.CashAtEndOfThePeriod}`
          : null;
        curYear.CashAtBeginningOfThePeriod.evalFormulaActual = null;
      }

      curYear.ChangeInCurrentAssetsAndLiabilities.Formula = `${curYear.ChangeInAccountsReceivable} + ${curYear.ChangeInAccountsPayable} + ${curYear.ChangeInInventory} + ${curYear.ChangeInOtherCurrentAssets} + ${curYear.ChangeInOtherCurrentLiabilities} + ${changeInIncomeTaxPayable.getItemByDateSufix(year.sufix)} + ${changeInSalesTaxPayable.getItemByDateSufix(year.sufix)} + ${changeInVATTaxPayable.getItemByDateSufix(year.sufix)}`;
      curYear.ChangeInOtherCurrentLiabilities.Formula = `${FinancingCurrentClosingBalance.getItemByDateSufix(year.sufix)} - ${FinancingCurrentOpeningBalance.getItemByDateSufix(year.sufix)}`;
      curYear.ChangeInOtherLongTermLiabilities.Formula = `${FinancingLongTermClosingBalance.getItemByDateSufix(year.sufix)} - ${FinancingLongTermOpeningBalance.getItemByDateSufix(year.sufix)}`;
      curYear.ChangeInDebt.Formula = `${ChangeInLoan.getItemByDateSufix(year.sufix)} + ${ChangeInLineOfCredit.getItemByDateSufix(year.sufix)}`;
      curYear.NetCashFromOperatingActivities.Formula = `${NetIncome.getItemByDateSufix(year.sufix)} + ${totalDepreciation.getItemByDateSufix(year.sufix)} + ${curYear.GainOrLossFromSaleOfPPE} + ${curYear.ChangeInAccountsReceivable} + ${curYear.ChangeInAccountsPayable} + ${curYear.ChangeInInventory} + ${curYear.ChangeInOtherCurrentAssets} + ${curYear.ChangeInOtherCurrentLiabilities} +  ${curYear.SubscriptionsCashFlowAdjustment} + ${changeInIncomeTaxPayable.getItemByDateSufix(year.sufix)} + ${changeInSalesTaxPayable.getItemByDateSufix(year.sufix)} + ${changeInVATTaxPayable.getItemByDateSufix(year.sufix)}`;
      curYear.NetCashFromInvestingActivities.Formula = `${longTermAssetPayments.getItemByDateSufix(year.sufix)} + ${longTermAssetSales.getItemByDateSufix(year.sufix)}`;
      curYear.NetCashFromFinancingActivities.Formula = `${curYear.ChangeInOtherLongTermLiabilities} + ${curYear.ChangeInDebt} + ${EquityInvestments.getItemByDateSufix(year.sufix)} + ${DividendsTotals.getItemByDateSufix(year.sufix)}`;
      curYear.NetChangeInCash.Formula = `${curYear.NetCashFromOperatingActivities} + ${curYear.NetCashFromInvestingActivities} + ${curYear.NetCashFromFinancingActivities}`;
      curYear.CashAtEndOfThePeriod.Formula = `${curYear.CashAtBeginningOfThePeriod} + ${curYear.NetChangeInCash}`;

      //actual formulas
      if (prevYear) {
        curYear.ChangeInAccountsReceivable.Formula =
          `${curYear.ChangeInAccountsReceivable.Formula ? curYear.ChangeInAccountsReceivable.Formula : ""}` +
          ` Actual(${accountReceivableClosingBalance.getItemByDateSufix(prevYearDate.sufix).ID_f_actual} - ${accountReceivableClosingBalance.getItemByDateSufix(year.sufix).ID_f_actual})`;
        curYear.ChangeInAccountsPayable.Formula =
          `${curYear.ChangeInAccountsPayable.Formula ? curYear.ChangeInAccountsPayable.Formula : ""}` +
          ` Actual(${accountPayableClosingBalance.getItemByDateSufix(year.sufix).ID_f_actual} - ${accountPayableClosingBalance.getItemByDateSufix(prevYearDate.sufix).ID_f_actual})`;
        curYear.ChangeInInventory.Formula =
          `${curYear.ChangeInInventory.Formula ? curYear.ChangeInInventory.Formula : ""}` +
          ` Actual(${inventoryClosingBalance.getItemByDateSufix(prevYearDate.sufix).ID_f_actual} - ${inventoryClosingBalance.getItemByDateSufix(year.sufix).ID_f_actual})`;
        curYear.ChangeInOtherCurrentAssets.Formula =
          `${curYear.ChangeInOtherCurrentAssets.Formula ? curYear.ChangeInOtherCurrentAssets.Formula : ""}` +
          ` Actual(${otherCurrentAssets.getItemByDateSufix(prevYearDate.sufix).ID_f_actual} - ${otherCurrentAssets.getItemByDateSufix(year.sufix).ID_f_actual})`;
        curYear.ChangeInOtherCurrentLiabilities.Formula =
          `${curYear.ChangeInOtherCurrentLiabilities.Formula ? curYear.ChangeInOtherCurrentLiabilities.Formula : ""}` +
          ` Actual(${otherCurrentLiabilities.getItemByDateSufix(year.sufix).ID_f_actual} - ${otherCurrentLiabilities.getItemByDateSufix(prevYearDate.sufix).ID_f_actual})`;
        curYear.ChangeInOtherLongTermLiabilities.Formula =
          `${curYear.ChangeInOtherLongTermLiabilities.Formula ? curYear.ChangeInOtherLongTermLiabilities.Formula : ""}` +
          ` Actual(${FinancingLongTermOtherLiabilities.getItemByDateSufix(year.sufix).ID_f_actual} - ${FinancingLongTermOtherLiabilities.getItemByDateSufix(prevYearDate.sufix).ID_f_actual})`;
        curYear.CashAtBeginningOfThePeriod.Formula =
          `${curYear.CashAtBeginningOfThePeriod.Formula ? curYear.CashAtBeginningOfThePeriod.Formula : ""}` +
          ` Actual(${prevYear.CashAtEndOfThePeriod.ID_f_actual})`;
        curYear.SubscriptionsCashFlowAdjustment.Formula =
          `${curYear.SubscriptionsCashFlowAdjustment.Formula ? curYear.SubscriptionsCashFlowAdjustment.Formula : ""}` +
          ` Actual(${PrepaidRevenueClosingBalance.getItemByDateSufix(year.sufix).ID_f_actual} - ${PrepaidRevenueClosingBalance.getItemByDateSufix(prevYearDate.sufix).ID_f_actual})`;
      }
    });
  };

  getReportTotals = () => {
    const driver = CashFlow.getReport();

    return [
      driver.NetCashFromOperatingActivities,
      driver.NetCashFromInvestingActivities,
      driver.NetCashFromFinancingActivities,
      driver.NetChangeInCash,
      driver.CashAtBeginningOfThePeriod,
      driver.CashAtEndOfThePeriod,
    ];
  };

  static getOpeningCashBalanceID = () => {
    return `${global.Modeliks.Tables.CompanyScenarios.TableName}-${global.Modeliks.CompanyScenarioInfo.ID}-opening_cash_balance`;
  };
  static getOpeningCashBalance = (callBack) => {
    let OpeningCashBalance = global.Modeliks.DriverValuesStore.getItem(
      CashFlow.getOpeningCashBalanceID(),
    );

    if (!OpeningCashBalance) {
      OpeningCashBalance = new CalculatedDriver_Values(
        null,
        CashFlow.getOpeningCashBalanceID(),
        null,
        UnitTypes.Price,
      );
      OpeningCashBalance.Value = 0;
      if (!global.Modeliks.isAdmin) {
        OpeningCashBalance.Save(() => {
          callBack && callBack();
        });
      }
      return OpeningCashBalance;
    } else {
      if (callBack) {
        callBack(OpeningCashBalance);
      } else {
        return OpeningCashBalance;
      }
    }
  };
  static getReport = (setReport = true) => {
    const driver = global.Modeliks.ReportsStore.find((d) => d.ReportType === ReportsTypes.CashFlow);
    if (driver) {
      driver.setReport();
      return driver;
    } else {
      const newReport = new CashFlow();
      newReport.ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
      newReport.ID = "cash_flow_report";
      newReport.Name = "Cash Flow";
      newReport.ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
      newReport.ReportType = ReportsTypes.CashFlow;
      newReport.ReportsType = ReportsTypes.CashFlow;
      newReport.Totals = CalculatedDriver.createDriverFromTable(
        newReport,
        CashFlow.DriversDesc.Total.driverID,
        UnitTypes.Price,
        DriverCategories.Sum,
        true,
      );
      newReport.createDrivers();
      newReport.createChangeDrivers();
      newReport.changeDriversName();
      if (setReport) {
        newReport.setReport();
      } else {
        Taxes.getChangeInIncomeTaxPayable();
        Taxes.getChangeInSalesTaxPayable();
        Taxes.getChangeInVATTaxPayable();
      }

      newReport.NetCashFromOperatingActivities.IsExisting = true;
      newReport.NetCashFromInvestingActivities.IsExisting = true;
      newReport.NetCashFromFinancingActivities.IsExisting = true;
      newReport.GainOrLossFromSaleOfPPE.IsExisting = true;
      newReport.NetChangeInCash.IsExisting = true;
      newReport.CashAtEndOfThePeriod.IsExisting = true;
      newReport.CashAtBeginningOfThePeriod.IsExisting = true;
      global.Modeliks.ReportsStore.push(newReport);
      return newReport;
    }
  };

  createChangeDrivers = () => {
    Object.values(CashFlow.ChangeDriversDesc).forEach((driver) => {
      let findDriver = global.Modeliks.DriversStore.getItem(
        this.constructor.TableName + "-" + this.ID + "-" + driver.driverID,
      );
      if (findDriver && findDriver.DriverName === "Change in Prepaid Revenue") {
        findDriver.DriverName = "Change in Unearned Revenue";
        findDriver.Save();
      }

      if (this.hasOwnProperty(driver.fieldName) === false && !findDriver) {
        this[driver.fieldName] = CalculatedDriver.createDriverFromTable(
          this,
          driver.driverID,
          driver.unit,
          driver.category,
          driver.driverName,
          false,
        );
        this[driver.fieldName].Save();
      } else {
        this[driver.fieldName] = findDriver;
      }
    });
  };
}

export default CashFlow;
