import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import Chart from "./Chart";
import { MenuItem } from "@szhsin/react-menu";
import NumberInputAction from "../../../components/actions/inputs/NumberInput";

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { InputFormats } from "../../../components/constants/finance";
import ChartTitle from "./ChartActual";
import { ChartTypes } from "../../../components/constants/charts";
import { UnitTypes } from "../../../data/Finance/CalculatedDriver/constants";

ChartJS.register(
  CategoryScale,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
);

export default class BarChart extends Chart {
  constructor(props) {
    super(props);
    this.chartClassName = "BarChart";
    this.setClassName();

    this.chartOptions.haveCategoryGapWidth = true;

    this.rightMenuFunc = {};
    this.buildData();
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return false;
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  getAdditionalChartSettings() {
    return (
      <>
        <MenuItem
          onClick={(e) => {
            e.keepOpen = true;
          }}
        >
          <NumberInputAction
            value={this.chartOptions.datalabelsDecimalPoints}
            max={3}
            label={"Show decimals"}
            onChange={(decPointsVal) => {
              this.chartOptions.datalabelsDecimalPoints = decPointsVal;
              this.setChartRightPadding();
              this.buildData();
              this.forceUpdate(() => {
                this.saveCurrentSettings();
                if (this.chartRef) {
                  this.chartRef.data.datasets = this.getData();
                  // this.chartRef.update();
                }
              });
            }}
          />
        </MenuItem>
      </>
    );
  }
  render() {
    if (this.getImageSrc()) {
      return this.getChartElementAsImage();
    }

    const ChartData = this.getData();
    const ChartOptions = this.getChartOptions();
      ChartOptions.scales.x.ticks.font = 11;
      ChartOptions.scales.y.ticks.font = 11;
      ChartOptions.plugins.datalabels.font.size = 12;


      if (ChartData && ChartData.length > 0) {
      let left = 25;
      let right = 25;
      let fv = 5;
      let lv = 4;
      let fvm = 5;
      let lvm = 4;

      if (this.chartOptions.unitType === UnitTypes.Percentage) {
        fv = 3;
        lv = 3;
        fvm = 2.5;
        lvm = 2.5;
      }

      if (this.maxDatasetsFirstValue > fv) {
        left = (this.maxDatasetsFirstValue - fvm) * 2;
      }

      if (this.maxDatasetsLastValue > lv) {
        right = (this.maxDatasetsLastValue - lvm) * 2;
      }

      if (ChartOptions && ChartOptions.hasOwnProperty("layout")) {
        ChartOptions.layout.padding.left = left;
        ChartOptions.layout.padding.right = right;
      }
    }

    return (
      <div
        className={"c_c_chart_container"}
        style={{ width: `${this.props.grid ? 100 : this.chartConfig.width}%` }}
        ref={this.setChartContainerRef}
      >
        {this.getTitleElement()}

        <Bar
          key={this.state.key}
          ref={this.setChartRef}
          data={{
            labels: this.getChartLabels(),
            datasets: ChartData,
          }}
          plugins={this.getPlugins()}
          options={ChartOptions}
          type={"bar"}
        />
      </div>
    );
  }
}
