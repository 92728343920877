import React from "react";
import "./App.scss";
import SecureApp from "./pages/Secure";
import PublicApp from "./pages/Public";
import Onboarding from "./pages/Secure/Onboarding";
import CircleLoader from "./components/Loaders/CircleLoader/CircleLoader";
import Admin from "./pages/Admin";
import Subscriptions from "./pages/Public/Auth/Register/Subscriptions";
import request from "superagent";
import { datadogLogs } from "@datadog/browser-logs";

import { Upgrade_Downgrade_types } from "./data/SubscriptionEnum";
import NetworkDetecor from "./pages/Secure/NetworkDetecor";
import ErrorBoundary from "./components/errors/errorBoundary";
import { DialogTypes, ExceededLimitTypes } from "./data/Finance/constants";
import initHelpHero from "helphero";
import { useNavigate } from "react-router-dom";
import NoCompanyAccess from "./components/no_company_access/NoCompanyAccess";
import Accountless from './pages/Accountless/index'
import httpService from "./services/api/httpService";

var hlp = initHelpHero("N2uAEy0tRn");

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuth: this.checkToken(),
      restrictedAccessToCompany: false,
    };
    this.mounted = false;
    global.Modeliks.Subscribe("onAuthChange", this.onAuthChange);
    global.Modeliks.toggleRestrictedAccessToCompany = this.toggleRestrictedAccessToCompany;

    global.m_version = process.env.REACT_APP_VERSION;
    global.fe_version = process.env.REACT_APP_FE_VERSION;
  }

  toggleRestrictedAccessToCompany = (
    restrictedAccessToCompany = !this.state.restrictedAccessToCompany,
  ) => {
    if (this.state.restrictedAccessToCompany != restrictedAccessToCompany) {
      this.setState({ restrictedAccessToCompany });
    }
  };

  checkToken = () => {
    if (window.localStorage.getItem("token")) {
      if (window.localStorage.getItem("token") == "false") {
        return false;
      } else {
        return true;
      }
    }
  };

  componentDidMount = () => {
    if (process.env.REACT_APP_DATADOG_CLIENT_TOKEN && process.env.REACT_APP_DATADOG_SITE) {
      datadogLogs.init({
        clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
        site: process.env.REACT_APP_DATADOG_SITE,
        forwardErrorsToLogs: true,
        sessionSampleRate: 100,
      });
    }
    this.mounted = true;
  };

  setClarityTag = (tag, value) => {
    if (global.clarity) {
      global.clarity("set", tag, value);

      console.log(tag, value);
    }
  };

  onAuthChange = (data) => {
    if (this.mounted) {
      this.setState({ isAuth: data }, () => {
        if (global.Modeliks.subscriptionInfo) {
          if (
            global.Modeliks.Companies.filter((c) => c.Status).length >
            global.Modeliks.subscriptionInfo.TotalCompanies
          ) {
            global.Modeliks.showDialog &&
              global.Modeliks.showDialog(
                ExceededLimitTypes.Companies,
                DialogTypes.ExceededSubscriptionsLimit,
                undefined,
                undefined,
                undefined,
                true,
              );
          }
        }
      });
    } else {
      this.state.isAuth = data;
    }

    if (global.Modeliks.User) {
      if (hlp) {
        hlp.identify(global.Modeliks.User.ID);
      }

      datadogLogs.addLoggerGlobalContext("user", global.Modeliks.User.ID);
      this.setClarityTag("userId", global.Modeliks.User.ID.toString());

      if (global.clarity) {
        if (global.Modeliks.isInternal) {
          global.clarity("stop");
        }
      }

      if (global.Modeliks.Account) {
        datadogLogs.addLoggerGlobalContext("account", global.Modeliks.Account.ID);
        this.setClarityTag("accountID", global.Modeliks.Account.ID.toString());
      }

      if (global.CompanyInfo) {
        datadogLogs.addLoggerGlobalContext("company", global.CompanyInfo.ID);
        this.setClarityTag("companyID", global.CompanyInfo.ID.toString());
      }
      if (global.CompanyScenarioInfo) {
        datadogLogs.addLoggerGlobalContext("scenario", global.CompanyScenarioInfo.ID);
        this.setClarityTag("scenarioID", global.CompanyScenarioInfo.ID.toString());
      }

      if (global.ire) {
        global.ire("identify", {customerId: global.Modeliks.User.ID, customerEmail: ""});
      }
    }
  };

  onCardClick = async (id, price, paddle_price, isFreeTrial = false) => {
    if (isFreeTrial) {
      try {
        let freeTrialResult = await httpService()
          .post('/api/createFreeTrial', {
            accountID: global.Modeliks.Account.ID,
            subscriptionID: id
          })
        console.log('freeTrialResult', freeTrialResult)
        window.location.reload()
      } catch (e) {
        window.location.reload()

      }
    } else {
      window.Paddle.Checkout.open({
        items: [
          {
            priceId: paddle_price,
            quantity: 1,
          },
        ],
        customer: {
          email: global.Modeliks.User.Email,
        },
        customData: {
          account_id: global.Modeliks.Account.ID,
          subscription_id: id,
        },
      });
    }
  };

  returnUnpaid = () => {
    global.Modeliks.shouldLogoutOnCancel = true;

    const currentSubscription = global.Modeliks.subscriptionPlans.find(
      (c) => c.ID === global.Modeliks.subscriptionInfo.SubscriptionID,
    );

    const items = [];

    items.push({ priceId: currentSubscription.paddle_id, quantity: 1 });

    if (currentSubscription.users_number < global.Modeliks.subscriptionInfo.TotalUsers) {
      const quantity =
        global.Modeliks.subscriptionInfo.TotalUsers - currentSubscription.users_number;
      items.push({ priceId: currentSubscription.stripe_extra_user, quantity: quantity });
    }

    if (currentSubscription.companies_number < global.Modeliks.subscriptionInfo.TotalCompanies) {
      const quantity =
        (global.Modeliks.subscriptionInfo.TotalCompanies - currentSubscription.companies_number) /
        5;
      items.push({ priceId: currentSubscription.stripe_extra_company, quantity: quantity });
    }

    window.Paddle.Checkout.open({
      items: items,
      customer: {
        email: global.Modeliks.User.Email,
      },
      customData: {
        account_id: global.Modeliks.Account.ID,
        subscription_id: currentSubscription.ID,
      },
    });
  };

  getSubscriptionDuration = () => {
    if (global.Modeliks.subscriptionInfo) {
      let months;
      let d1 = new Date();
      let d2 = new Date(global.Modeliks.subscriptionInfo.DateEnd);
      months = (d1.getFullYear() - d2.getFullYear()) * 12;
      months -= d1.getMonth();
      months += d2.getMonth();
      return months <= 0 ? 0 : months;
    } else {
      return null;
    }
  };

  getSubscriptionPlan = () => {
    if (global.Modeliks.subscriptionInfo && global.Modeliks.subscriptionPlans) {
      let subscription = global.Modeliks.subscriptionPlans.find(
        (c) => c.ID === global.Modeliks.subscriptionInfo.SubscriptionID,
      );
      if (subscription) {
        return subscription.name;
      }
    }
    return null;
  };

  render = () => {
    if (this.state.restrictedAccessToCompany) {
      return <NoCompanyAccess/>;
    }
    if (this.state.isAuth == null) {
      return <PublicApp/>;
    }

    if (this.state.isAuth) {
      if (global.Modeliks.User != null && !this.state.loading) {
        if (global.Modeliks.isAdmin) {
          return <Admin/>
        }

        if (global.Modeliks.NoAccount) {
          return <Accountless/>
        }

        if (!global.Modeliks.subscriptionInfo) {
          return <Subscriptions dontShowFreeTrial={global.Modeliks.Account.accounts_number > 1} CancelAccount
                                onCardClick={this.onCardClick}/>;
        } else if (
            global.Modeliks.subscriptionInfo.isStatic &&
            new Date(global.Modeliks.subscriptionInfo.DateEnd).getTime() < new Date().getTime()
        ) {
          return (
              <Subscriptions
                  subscriptions={global.Modeliks.subscriptionPlans}
              dontShowFreeTrial
              onCardClick={this.onCardClick}
            />
          );
        } else if (
          global.Modeliks.subscriptionInfo.Status &&
          global.Modeliks.subscriptionInfo.Status !== "active" &&
          global.Modeliks.subscriptionInfo.Status !== "trialing"
        ) {
          return this.returnUnpaid();
        } else if (
          global.Modeliks.subscriptionInfo.Status &&
          global.Modeliks.subscriptionInfo.Status === "trialing" &&
          new Date(global.Modeliks.subscriptionInfo.DateEnd).getTime() < new Date().getTime()
        ) {
          return (
            <Subscriptions
              subscriptions={global.Modeliks.subscriptionPlans}
              dontShowFreeTrial
              onCardClick={this.onCardClick}
            />
          );
        } else if (global.Modeliks.CompanyScenarioInfo && global.Modeliks.CompanyInfo) {
          return <SecureApp />;
        } else {
          return <Onboarding />;
        }
      } else {
        return (
          <div className={"main_circle_loader_container"}>
            <CircleLoader />
          </div>
        );
      }
    }

    return <PublicApp />;
  };
}

export default NetworkDetecor(App);
