import { KeyboardEventKeys } from "./enums";

export class KeyEventsHandler {
  constructor(props) {
    this.props = props;
    this._keyCtrl = false;
    this._shiftKey = false;

    this.keyHandlers = new Map();
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
  }

  addKeyHandler(keyCombination, callback) {
    if (Array.isArray(keyCombination.keyCode)) {
      keyCombination.keyCode.forEach((keyCode) => {
        const keyHash = this.generateKeyHash({
          ...keyCombination,
          keyCode,
        });
        this.keyHandlers.set(keyHash, { keyCombination, callback });
      });
      return;
    }
    const keyHash = this.generateKeyHash(keyCombination);
    this.keyHandlers.set(keyHash, { keyCombination, callback });
  }

  removeKeyHandler(keyCombination) {
    const keyHash = this.generateKeyHash(keyCombination);
    this.keyHandlers.delete(keyHash);
  }

  generateKeyHash(keyCombination) {
    return JSON.stringify(keyCombination);
  }

  handleKeyDown(e) {
    if (e.keyCode === KeyboardEventKeys.CTRL) {
      this._keyCtrl = true;
      document.addEventListener("keyup", this.handleKeyUp);
    } else if (e.keyCode === KeyboardEventKeys.SHIFT) {
      this._shiftKey = true;
      document.addEventListener("keyup", this.handleKeyUp);
    } else {
      const keyCombination = {
        ctrlKey: this._keyCtrl || e.metaKey,
        shiftKey: this._shiftKey,
        keyCode: e.keyCode,
      };

      const keyHash = this.generateKeyHash(
        Object.keys(keyCombination)
          .filter((key) => !!keyCombination[key])
          .reduce((prev, key) => {
            prev[key] = keyCombination[key];
            return prev;
          }, {}),
      );
      const handler = this.keyHandlers.get(keyHash);

      if (handler) {
        e.stopPropagation();
        this.preventableEvent(e);
        handler.callback(e);
      }
    }
  }

  handleKeyUp(e) {
    if (e.keyCode === KeyboardEventKeys.CTRL) {
      this._keyCtrl = false;
      document.removeEventListener("keyup", this.handleKeyUp);
    } else if (e.keyCode === KeyboardEventKeys.SHIFT) {
      this._shiftKey = false;
      document.removeEventListener("keyup", this.handleKeyUp);
    }
  }

  attachEventListeners() {
    document.addEventListener("keydown", this.handleKeyDown);
  }

  removeEventListeners() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  preventableEvent(e) {
    if (
      [KeyboardEventKeys.BACKSPACE, KeyboardEventKeys.DELETE, KeyboardEventKeys.ESCAPE].includes(
        e.keyCode,
      )
    ) {
      return;
    }
    e.preventDefault();
  }

  get keyCtrl() {
    return this._keyCtrl;
  }

  get keyShift() {
    return this._keyShift;
  }
}
