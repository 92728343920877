import React, { useState, useEffect } from "react";
import "../../../../../components/dialogs/financials/CashBalanceDialog/index.scss";

const message =
  "Please note that you have to set your  cash flow opening balances. After  setting the opening balances the cash flow will be calculated automatically.";

function CashFlowMessage(props) {
  if (props.selectedReport) {
    return (
      <>
        <div className="scdfci_cash_balance_div scdfci_cash_balance_actuals">
          <span className="scdfci_text_balance scdfci_black_color">{message}</span>
        </div>
      </>
    );
  }
}

export default CashFlowMessage;
