export class Command {
  args;
  constructor(args) {
    if (this.constructor === Command) {
      throw new Error("Class is of abstract type and can't be instantiated");
    }

    if (this.execute === undefined) {
      throw new Error("execute method must be implemented");
    }

    if (this.undo === undefined) {
      throw new Error("undo method must be implemented");
    }

    if (this.getInfo === undefined) {
      throw new Error("getInfo method must be implemented");
    }

    this.args = args;
  }
}
