import React, { Component } from "react";
import ButtonMui from "./buttonMui";
import PropTypes from "prop-types";

class PitchHeaderButton extends Component {
  constructor(props) {
    super(props);
  }
  render = () => {
    return (
      <ButtonMui
        ref={this.props.innerRef}
        className={this.props.className}
        tooltipText={this.props.tooltipText}
        disabled={this.props.disabled}
        onClick={this.props.onClick}
        variant={"text"}
        isIconButton
        roundnessType={"squared"}
        color={"black"}
        label={this.props.label}
      />
    );
  };
}

PitchHeaderButton.propTypes = {
  tooltipText: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  label: PropTypes.any,
  className: PropTypes.string,
};

export default React.forwardRef((props, ref) => <PitchHeaderButton innerRef={ref} {...props} />);
