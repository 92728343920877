import React, { useEffect } from "react";
import "../../../../../components/menus/ScenarioMenu/ScenarioMenu.scss";
import { DialogActions } from "@mui/material";
import AddDialog from "../../../../../components/dialogs/financials/AddDialog/AddDialog";
import { AddDialogTitle } from "../../../../../components/dialogs/financials/AddDialog/AddDialogTitle";
import { AddDialogContent } from "../../../../../components/dialogs/financials/AddDialog/AddDialogContent";
import Input from "../../../../../components/actions/Input";
import Button from "../../../../../components/actions/Button";
import AutocompleteInput from "../../../../../components/actions/inputs/AutocompleteInput";
import CancelDialog from "../../../../../components/dialogs/settings/CancelDialog";

export default class AddNewCustomKPIDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      key: new Date().getTime(),
      CustomKPIName: null,
      error: false,
      errorMessage: "Name of KPI is required",
      driver: null,
    };
  }

  onChangeName = (e) => {
    this.props.newKPI.Name = e.target.value;
    this.setState({ CustomKPIName: e.target.value });
  };

  validate = () => {
    if (this.state.CustomKPIName === null || this.state.CustomKPIName.length < 3) {
      return false;
    }
    return true;
  };

  createCustomKPI = () => {
    const isValid = this.validate();
    if (isValid && this.props.newKPI) {
      this.props.newKPI.Name = this.state.CustomKPIName;
      this.setState({ error: false }, () => this.props.createCustomKPI(this.props.newKPI));
    } else {
      this.setState({ error: true });
    }
  };

  setDriverFromExisting = (e, driver) => {
    this.setState({ driver });
  };

  addIndicatorToKPI = () => {
    if (!this.state.driver) {
      return;
    }
    this.props.createIndicator(this.state.driver);
  };

  newCustomKPIForm = () => {
    return (
      <AddDialog onClose={this.props.onClose} open={this.props.open}>
        <AddDialogTitle title="Create New Custom KPI List" onClose={this.props.onClose} />
        <AddDialogContent>
          <div className="scenario_add_content">
            <div className="sad_heading">Name of Custom KPI List</div>
            <div className="sad_input">
              <Input
                placeholder="Please enter..."
                value={this.state.CustomKPIName}
                error={this.state.error}
                max={18}
                errorMessage={this.state.error && this.state.errorMessage}
                onChange={this.onChangeName}
              />
            </div>
            <div className="spsdcc_custom_text_area">
              In the custom KPI list, you can either add existing KPIs from a drop-down menu, such
              as gross profit margin, or create own custom KPIs to track. For example, a custom KPI
              might be sales per square foot.
            </div>
          </div>
        </AddDialogContent>
        <DialogActions>
          <Button
            color={"primary"}
            size="large"
            backgroundColor={"primary"}
            width="120px"
            padding="0 0 0 0"
            label="Create"
            onClick={this.createCustomKPI}
          />
        </DialogActions>
      </AddDialog>
    );
  };
  newIndicatorForm = () => {
    const validDrivers = global.Modeliks.DriversStore.filter((d) => d.IsExisting);
    return (
      <AddDialog onClose={this.props.onClose} open={this.props.open}>
        <AddDialogTitle title="Add Existing Indicator" onClose={this.props.onClose} />
        <AddDialogContent>
          <div className="scenario_add_content">
            <div className="sad_heading">Select Indicator</div>
            <div className="sad_input">
              <AutocompleteInput
                placeholder="Search..."
                className="fdd_cd_driver_autocomplete_input_style"
                getOptionDisabled={(option) => {
                  if (this.props.KPI) {
                    return (
                      this.props.KPI.getChildDrivers() &&
                      this.props.KPI.getChildDrivers().some((el) => el.ID === option.ID)
                    );
                  }
                }}
                options={validDrivers}
                value={this.state.driver}
                onChange={(e, driver) => this.setDriverFromExisting(e, driver)}
              />
            </div>
            <div className="spsdcc_custom_text_area">
              Select existing indicators from the list, like gross profit margin, to add to your
              custom KPI list. You can also search by starting to type.
            </div>
          </div>
        </AddDialogContent>
        <DialogActions>
          <Button
            color={"primary"}
            size="large"
            backgroundColor={"primary"}
            width="120px"
            padding="0"
            label="Add Indicator"
            onClick={this.addIndicatorToKPI}
          />
        </DialogActions>
      </AddDialog>
    );
  };

  render() {
    if (this.props.isCustomKPI) {
      return this.newCustomKPIForm();
    }
    return this.newIndicatorForm();
  }
}
