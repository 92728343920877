import {Menu, MenuItem, SubMenu} from "@szhsin/react-menu";
import React, { useEffect, useMemo, useState } from "react";
import { SketchPicker } from "react-color";
import ButtonMui from "../../../../components/buttons/buttonMui/buttonMui";
import MostUsedColors from "../../components/ColorPicker/MostUsedColors";
import {Exit, TextColor} from "../../components/IconButtons/SubMenuIcons";
import "../HeaderDefault.scss";
import "../HeaderTextEditor.scss";
import { rgbToRgba } from "../../../../helpers/utils/color";
import {Tick} from "../../components/menus/LeftMenu/components/Icons/LeftMenuIcons";

const DEFAULT_TEXT_COLOR = "#585858";

export const presetColors = ['#D0021B', '#F5A623', '#F8E71C', '#8B572A', '#7ED321', '#417505', '#BD10E0', '#9013FE', '#4A90E2', '#50E3C2', '#B8E986', '#000000', '#4A4A4A', '#9B9B9B', '#FFFFFF']

function TextColorEditComponent(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentColor, setCurrentColor] = React.useState(props.color ? props.color : DEFAULT_TEXT_COLOR);

  const initialColor = props.color ? props.color : DEFAULT_TEXT_COLOR

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const checkForSelection = () => {
    if (document.getSelection()) {
      let selection = document.getSelection();
      if (selection.anchorOffset === selection.focusOffset) return false;
      else return true;
    } else return false;
  };

  const toColor = (color) => {
    var sel = window.getSelection(); // Gets selection
    if (sel.rangeCount) {
      var e;
      if (sel.extentNode.parentElement.innerText != sel.toString())
        e = document.createElement("span");
      e.style = "color:" + color + ";";
      e.innerHTML = sel.toString();
      var range = sel.getRangeAt(0);
      range.deleteContents();
      range.insertNode(e);
    }
  };

  function onChange(color) {
    // FIXME: just dispatch color value from here. Appropriate parents should handle their own structure
    if (props.objType && props.objType === "tableObject") {
      props.onChange({ color: color });
    } else {
      props.onChange("color", color, false);
    }

    setCurrentColor(color)
    global.Modeliks.addColor(color);
  }

  const itemsToRender = () => (
    <>
      <div>
        <SketchPicker
          color={currentColor}
          /* onChangeComplete={(event) => {
            
          }} */
          onChange={(event) => {
            onChange(rgbToRgba(event.rgb));
            setCurrentColor(rgbToRgba(event.rgb));
          }}
        />
      </div>
      <div>
        <MostUsedColors onClick={onChange} />
      </div>
    </>
  );

  const itemsToRenderWithConfirm = () => {
    return <>
      <div>
        <SketchPicker
            color={currentColor}
            /* onChangeComplete={(event) => {

            }} */
            onChange={(event) => {
              if(presetColors.includes(event.hex.toUpperCase()) && (event.rgb.a == 1)){
                onChange(rgbToRgba(event.rgb));
                setCurrentColor(rgbToRgba(event.rgb));
              }
              else {
                setCurrentColor(rgbToRgba(event.rgb));
              }
            }}
        />
      </div>
      <div>
        {(initialColor != currentColor) && <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '10px', paddingBottom: '10px', gap: '10px'}}>
        <MenuItem onClick={handleConfirm}>
          <Tick />
        </MenuItem>
        <MenuItem style={{color: 'red'}} onClick={() => {
          setCurrentColor(initialColor);
          props.renderHeader()
        }}>
          <Exit />
        </MenuItem>
        </div>}
        <MostUsedColors onClick={onChange} />
      </div>
    </>
  }

  const handleConfirm = () => {
    onChange(currentColor);
  }

  const TextColorEdit = () => {
    if (props.header) {
      return (
        <Menu
          className={"color_menu"}
          menuButton={({ open }) => (
            <ButtonMui
              label={<TextColor active={open} activeColor={currentColor} />}
              tooltipText={"Text Color"}
              className={props.className ? props.className : ""}
              variant={"text"}
              isIconButton
              roundnessType={"squared"}
              color={"black"}
              activeColor={currentColor}
            />
          )}
        >
          {props.shouldConfirm ? itemsToRenderWithConfirm() : itemsToRender()}
        </Menu>
      );
    }
    return (
      <SubMenu
        className={`color_menu block ${props.className ? props.className : ""}`}
        label={({ open }) => <TextColor active={open} activeColor={currentColor} />}
        openTrigger="clickOnly"
      >
        {props.shouldConfirm ? itemsToRenderWithConfirm() : itemsToRender()}
      </SubMenu>
    );
  };

  return <>{TextColorEdit()}</>;
}

export default TextColorEditComponent;
