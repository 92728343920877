import React from "react";
import { Dialog, DialogContent } from "@mui/material";
import "./notesEditor.scss";
import ButtonMui from "../../../../components/buttons/buttonMui/buttonMui";
class NotesEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasChanged: false,
    };

    this.company = JSON.parse(JSON.stringify(this.props.company));
    if (!this.company.hasOwnProperty("Notes")) {
      this.company.Notes = "";
    }
  }

  handleOnChange = (e) => {
    this.company.Notes = e.target.innerHTML;
  };

  handleSave = () => {
    if (this.props.handleSave) {
      this.props.handleSave(this.company);
    }
    this.setState({ open: false });
  };

  render() {
    return (
      <>
        {this.props.company.Notes ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <ButtonMui
              onClick={() => this.setState({ open: true })}
              color={"black"}
              variant={"text"}
              style={{ height: "32px" }}
              label={<NotesIcon />}
              isIconButton={true}
            />
            <div
              style={{ maxHeight: "40px", overflow: "hidden", textOverflow: "ellipsis" }}
              dangerouslySetInnerHTML={{ __html: this.company.Notes }}
            />
          </div>
        ) : (
          <ButtonMui
            onClick={() => this.setState({ open: true })}
            color={"black"}
            variant={"text"}
            style={{ height: "32px" }}
            label={<NotesIcon />}
            isIconButton={true}
          />
        )}
        <Dialog
          className={"notes_editor"}
          open={this.state.open}
          onClose={() => this.setState({ open: false })}
        >
          <DialogContent>
            <div className={"text_editor_container"}>
              <div
                data-text="Enter Note here"
                id={editorID}
                contentEditable={true}
                className={"text_editor"}
                onInput={this.handleOnChange}
                dangerouslySetInnerHTML={{ __html: this.company.Notes }}
              />
            </div>
            <ButtonMui
              width={150}
              variant={"contained"}
              label={"Save"}
              onClick={this.handleSave}
              roundnessType={"squared"}
            />
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

const TruncatedHtml = ({ content, maxLength, onClick }) => {
  if (content.length <= maxLength) {
    return <div onClick={onClick} dangerouslySetInnerHTML={{ __html: content }} />;
  }

  const truncatedContent = content.substring(0, maxLength);

  return (
    <div onClick={onClick}>
      <span
        style={{ wordBreak: "break-all", fontSize: "16px !important" }}
        dangerouslySetInnerHTML={{ __html: truncatedContent }}
      />
      <span style={{ color: "#01AFFD" }}>&nbsp;show more...</span>
    </div>
  );
};

const editorID = "notes_text_editor";

const NotesIcon = () => (
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.94483 14V12.0167L12.6234 7.07L14.4993 9.05333L9.82069 14H7.94483ZM0 9.33333V7.46667H6.17931V9.33333H0ZM15.1172 8.4L13.2414 6.41667L13.8814 5.74C14.0432 5.56889 14.2492 5.48333 14.4993 5.48333C14.7494 5.48333 14.9554 5.56889 15.1172 5.74L15.7572 6.41667C15.9191 6.58778 16 6.80556 16 7.07C16 7.33444 15.9191 7.55222 15.7572 7.72333L15.1172 8.4ZM0 5.6V3.73333H9.71034V5.6H0ZM0 1.86667V0H9.71034V1.86667H0Z"
      fill="currentColor"
    />
  </svg>
);

export default NotesEditor;
