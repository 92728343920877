function getDraggableElementRect(element) {
  const boundingClientRect = element.getBoundingClientRect();
  const hasWidth = element.offsetWidth;
  const scaler = hasWidth ? 1 : window.panelScale;

  const rect = hasWidth
    ? {
        width: element.offsetWidth,
        height: element.offsetHeight,
        left: boundingClientRect.left,
        top: boundingClientRect.top,
      }
    : boundingClientRect;
  const draggingElementHeight = `${rect.height / scaler}px`;
  const draggingElementWidth = `${rect.width / scaler}px`;

  return {
    width: draggingElementWidth,
    height: draggingElementHeight,
    top: rect.top,
    left: rect.left,
  };
}

function isAbove(nodeA, nodeB) {
  // Get the bounding rectangle of nodes
  const rectA = nodeA.getBoundingClientRect();
  const rectB = nodeB.getBoundingClientRect();

  return rectA.top + rectA.height / 2 < rectB.top + rectB.height / 2;
}

export { getDraggableElementRect, isAbove };
