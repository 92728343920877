import Revenue from "../../Revenue/index";
import CalculatedDriver_Values from "../../CalculatedDriver/CalculatedDriver_Values";
import FinanceGeneric from "../../FinanceGeneric";
import datastructure from "../../../datastructure.json";
import { UnitTypes } from "../../CalculatedDriver/constants";

class TradingMultiples extends FinanceGeneric {
  CompanyName = "";
  Country = "";
  CompanyDescription = "";
  Note = "";
  IncludeInValuation = true;
  IsTmpData = false;

  constructor(db_record) {
    super(db_record);
    if (this.db_record) {
      this.CompanyName = this.db_record.CompanyName;
      this.Country = this.db_record.Country;
      this.CompanyDescription = this.db_record.CompanyDescription;
      this.Note = this.db_record.Note;
      this.IsTmpData = false;
      this.IncludeInValuation = this.db_record.IncludeInValuation;
      this.setDriversFromDataStorageSub();
    }
    this.SaveRevenue = this.Save;
    this.Save = this.SaveTradingMultiples;
  }

  SaveNew = (callBack) => {
    global.Modeliks.post(this.constructor.TableName, this, (res) => {
      this.ID = res.id;
      this.createDriverValues(this.ID);
      // this.Totals = CalculatedDriver.createDriverFromTable(this, CostSales.DriversDesc.Total.driverID, UnitTypes.Price, DriverCategories.Sum);
      this.Save(() => callBack(this));
    });
  };

  static createNewTradingMultiples = (callBack) => {
    const newTradingMultiples = new TradingMultiples();
    newTradingMultiples.CompanyName = "CompanyName";
    newTradingMultiples.Country = "Country";
    newTradingMultiples.CompanyDescription = "CompanyDescription";
    newTradingMultiples.Note = "Note";
    newTradingMultiples.IsTmpData = false;
    newTradingMultiples.SaveNew(callBack);
  };

  static getTradingMultiples = (callBack) => {
    if (!global.Modeliks.TradingMultiplesStore.length > 0) {
      TradingMultiples.createNewTradingMultiples((newRec) => {
        callBack();
      });
    } else {
      return global.Modeliks.TradingMultiplesStore.filter((t) => t.IsTmpData === false);
    }
  };

  static DriverValuesDesc = Object.assign({
    Revenue: {
      fieldName: "Revenue",
      driverID: "tm_revenue",
      unit: UnitTypes.Price,
    },
    RevenueGrowth: {
      fieldName: "RevenueGrowth",
      driverID: "tm_revenue_growth",
      unit: UnitTypes.Percentage,
    },
    EBITDA: {
      fieldName: "EBITDA",
      driverID: "tm_ebitda",
      unit: UnitTypes.Price,
    },
    EBITDAMargin: {
      fieldName: "EBITDAMargin",
      driverID: "tm_ebitda_margin",
      unit: UnitTypes.Percentage,
    },
    NetProfit: {
      fieldName: "NetProfit",
      driverID: "tm_net_profit",
      unit: UnitTypes.Price,
    },
    NetProfitMargin: {
      fieldName: "NetProfitMargin",
      driverID: "tm_net_profit_margin",
      unit: UnitTypes.Percentage,
    },
    RevenueMultiple: {
      fieldName: "RevenueMultiple",
      driverID: "tm_revenue_multiple",
      unit: UnitTypes.Multiple,
    },
    EBITDAMultiple: {
      fieldName: "EBITDAMultiple",
      driverID: "tm_ebitda_multiple",
      unit: UnitTypes.Multiple,
    },
    PEMultiple: {
      fieldName: "PEMultiple",
      driverID: "tm_pe_multiple",
      unit: UnitTypes.Multiple,
    },
  });

  static TableName = datastructure.Valuation_Trading_Multiples.TableName;

  createDriverValues = (driverID = null) => {
    Object.values(this.constructor.DriverValuesDesc).forEach((driverVal) => {
      if (this.hasOwnProperty(driverVal.fieldName) == false) {
        this[driverVal.fieldName] = new CalculatedDriver_Values(
          null,
          driverID
            ? `${TradingMultiples.TableName}-${driverID}-${driverVal.driverID}`
            : driverVal.driverID,
          null,
          driverVal.unit,
        );
      }
    });
  };

  SaveTradingMultiples = (callBack, saveDrivers = true, saveValues = true) => {
    global.Modeliks.put(this.constructor.TableName, null, this, (res) => {
      global.Modeliks.post(
        global.Modeliks.Tables.Finance_CalculatedDriver_Values.TableName,
        Object.values(this.constructor.DriverValuesDesc).map(
          (driverVal) => this[driverVal.fieldName].cur_record,
        ),
        (res) => {
          callBack();
        },
      );
    });
  };

  getTradingMultiplesID = (valID) => {
    return `${TradingMultiples.TableName}-${this.ID}-${valID}`;
  };

  setDriversFromDataStorageSub = () => {
    Object.values(this.constructor.DriverValuesDesc).forEach((driverVal) => {
      if (!this.hasOwnProperty(driverVal.fieldName)) {
        const findVal = global.Modeliks.DriverValuesStore.getItem(
          this.getTradingMultiplesID(driverVal.driverID),
        );
        if (findVal) {
          this[driverVal.fieldName] = findVal;
        }
      }
    });
  };

  static tmpTypes = Object.assign({
    Median: {
      fieldName: "Median",
      driverID: "tdm_median_tmp",
    },
    Average: {
      fieldName: "Average",
      driverID: "tdm_average_tmp",
    },
    Minimum: {
      fieldName: "Minimum",
      driverID: "tdm_minimum_tmp",
    },
    Maximum: {
      fieldName: "Maximum",
      driverID: "tdm_maximum_tmp",
    },
  });

  createTmpFormulas = (driverValues, type = null) => {
    const hasDriverValues = driverValues.length > 0;
    Object.values(TradingMultiples.DriverValuesDesc).forEach((driverVal) => {
      const curDriverValues = driverValues.map((d) => d[driverVal.fieldName]).join(",");
      switch (type) {
        case TradingMultiples.tmpTypes.Minimum.driverID:
          this[driverVal.fieldName].Formula = hasDriverValues
            ? `MxMath.MinOfArr(${curDriverValues})`
            : null;
          break;
        case TradingMultiples.tmpTypes.Maximum.driverID:
          this[driverVal.fieldName].Formula = hasDriverValues
            ? `MxMath.MaxOfArr(${curDriverValues})`
            : null;
          break;
        case TradingMultiples.tmpTypes.Median.driverID:
          this[driverVal.fieldName].Formula = hasDriverValues
            ? `MxMath.Median(${curDriverValues})`
            : null;
          break;
        default:
          this[driverVal.fieldName].Formula = hasDriverValues
            ? `MxMath.Average([${curDriverValues}])`
            : null;
      }
      if (driverVal.unit === UnitTypes.Percentage && hasDriverValues) {
        this[driverVal.fieldName].Formula = `(${this[driverVal.fieldName].Formula}) * 100`;
      }
    });
  };

  static tmpTotalsTradingMultiples = (callBack) => {
    const driverValues = global.Modeliks.TradingMultiplesStore.filter(
      (d) => d.IsTmpData === false && d.IncludeInValuation === true,
    );
    callBack(
      Object.values(TradingMultiples.tmpTypes).map((driverVal) => {
        let tmpTradingMultiple = global.Modeliks.TradingMultiplesStore.getItem(driverVal.driverID);
        if (!tmpTradingMultiple) {
          const newTMP = new TradingMultiples();
          newTMP.createDriverValues(driverVal.driverID);
          newTMP.createTmpFormulas(driverValues, driverVal.driverID);
          newTMP.ID = driverVal.driverID;
          newTMP.IsTmpData = true;
          newTMP.CompanyName = driverVal.fieldName;
          newTMP.CompanyDescription = "software/hardware";
          newTMP.Note = "comments";
          global.Modeliks.TradingMultiplesStore.push(newTMP);
          return newTMP;
        }
        tmpTradingMultiple.createTmpFormulas(driverValues, driverVal.driverID);
        return tmpTradingMultiple;
      }),
    );
  };
}

export default TradingMultiples;
