import React from "react";
import { Dialog, DialogActions } from "@mui/material";
import PropTypes from "prop-types";
import Button from "../../../actions/Button";

function AddDialog({ disabled, onClose, open, onClick, ...props }) {
  return (
    <>
      <Dialog
        open={open}
        // onClose={onClose}
        scroll={"paper"}
        maxWidth={"md"}
        sx={{
          zIndex: 1000,
          ".MuiBackdrop-root": {
            backgroundColor: `rgb(255 255 255 / 90%)`,
          },

          "& .MuiDialog-container": {
            justifyContent: "center",
            alignItems: "center",
            bottom: 0,
          },
        }}
        PaperProps={{
          sx: {
            width: `${props.width ? props.width : "686px"}`,
            // position: 'absolute',
            backgroundColor: `${props.color ? props.color : "#F5F5F5"}`,
            borderRadius: "12px",
            // bottom: -32
          },
        }}
      >
        {props.children}
      </Dialog>
    </>
  );
}

AddDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};
export default AddDialog;
