import React, { Fragment, useState } from "react";
import "../HeaderTextEditor.scss";
import "../HeaderDefault.scss";
import { FillColor } from "../../components/IconButtons/SubMenuIcons";
import { SketchPicker } from "react-color";
import Tooltip from "@mui/material/Tooltip";
import { Menu, MenuButton, ControlledMenu, MenuItem, SubMenu } from "@szhsin/react-menu";
import MostUsedColors from "../../components/ColorPicker/MostUsedColors";
import PitchHeaderButton from "../../../../components/buttons/buttonMui/pitchHeaderButton";
import ButtonMui from "../../../../components/buttons/buttonMui/buttonMui";

function FillColorEditComponent(props) {
  const preventEvent = (e) => {
    // e.preventDefault()
    e.stopPropagation();
  };
  const onChange = (color) => {
    props.onChange({ backgroundColor: color });
    global.Modeliks.addColor(color);
  };
  const rgbToRgba = (event) => {
    return "rgba(" + event.rgb.r + "," + event.rgb.g + "," + event.rgb.b + "," + event.rgb.a + ")";
  };
  const FillColorEdit = () => {
    return (
      <Menu
        onClick={preventEvent}
        className={"color_menu"}
        menuButton={({ open }) => (
          <ButtonMui
            color={"black"}
            variant={"text"}
            roundnessType={"squared"}
            isIconButton
            tooltipText={"Fill Color"}
            label={
              <FillColor
                active={open}
                activeColor={
                  !props.style ||
                  !props.style.backgroundColor ||
                  props.style.backgroundColor === undefined
                    ? "#585858"
                    : props.style.backgroundColor
                }
              />
            }
          />
        )}
      >
        <SketchPicker
          disableAlpha={props.disableAlpha}
          color={
            props.style && props.style.backgroundColor ? props.style.backgroundColor : "#585858"
          }
          onChange={(event) => onChange(rgbToRgba(event))}
        />
        <div>
          <MostUsedColors
            onClick={onChange}
            disableAlpha={props.disableAlpha ? props.disableAlpha : false}
          />
        </div>
      </Menu>
    );
  };
  return <>{FillColorEdit()}</>;
}

export default FillColorEditComponent;
