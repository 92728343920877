const Currencies = [
  { description: "US Dollar", value: "$" },
  { description: "Euro", value: "€" },
  { description: "Japanese Yen", value: "¥" },
  { description: "Sterling", value: "£" },
  { description: "Renminbi", value: "¥" },
  { description: "Australian Dollar", value: "$A" },
  { description: "Canadian Dollar", value: "Can$" },
  { description: "Swiss Franc", value: "CHF" },
  { description: "Hong Kong Dollar", value: "HK$" },
  { description: "Singapore Dollar", value: "S$" },
  { description: "Swedish Krona", value: "Skr" },
  { description: "South Korean Won", value: "₩" },
  { description: "Norwegian Krone", value: "Nkr" },
  { description: "New Zealand Dollar", value: "$NZ" },
  { description: "Indian Rupee", value: "₹" },
  { description: "Mexican Peso", value: "Mex$" },
  { description: "New Taiwan Dollar", value: "NT$" },
  { description: "South African Rand", value: "R" },
  { description: "Brazilian Real", value: "R$" },
  { description: "Danish Krone", value: "Dkr" },
  { description: "Macedonian Denar", value: "MKD" },
];

const AllCurrencies = {
  AED: {
    code: "AED",
    value: "د.إ",
    description: "United Arab Emirates dirham",
    countryKeywords: ["AED", "AE", "are", "United Arab Emirates"],
    supportsDecimals: true,
  },
  AFN: {
    code: "AFN",
    value: "؋",
    description: "Afghanistan afghani",
    countryKeywords: ["Afghanistan", "afg", "AF", "AFN"],
    supportsDecimals: true,
  },
  ALL: {
    code: "ALL",
    value: "Lek",
    description: "Albanian lek",
    countryKeywords: ["ALL", "alb", "AL", "Albania"],
    supportsDecimals: true,
  },
  AMD: {
    code: "AMD",
    value: "֏",
    description: "Armenian dram",
    countryKeywords: ["Armenia", "AMD", "AM", "arm"],
    supportsDecimals: true,
  },
  ANG: {
    code: "ANG",
    value: "ƒ",
    description: "Netherlands Antillean guilder",
    countryKeywords: [
      "CW",
      "cuw",
      "Sint Maarten (Dutch part)",
      "SX",
      "ant",
      "Netherlands Antilles",
      "sxm",
      "ANG",
      "Curaçao",
      "AN",
    ],
    supportsDecimals: true,
  },
  AOA: {
    code: "AOA",
    value: "Kz",
    description: "Angolan kwanza",
    countryKeywords: ["AOA", "Angola", "ago", "AO"],
    supportsDecimals: true,
  },
  ARS: {
    code: "ARS",
    value: "$",
    description: "Argentine peso",
    countryKeywords: ["AR", "ARS", "Argentina", "arg"],
    supportsDecimals: true,
  },
  AUD: {
    code: "AUD",
    value: "A$",
    description: "Australian dollar",
    countryKeywords: [
      "TV",
      "NR",
      "HM",
      "Christmas Island",
      "cck",
      "Kiribati",
      "AUD",
      "tuv",
      "kir",
      "Cocos (Keeling) Islands",
      "cxr",
      "KI",
      "CC",
      "Heard Island and McDonald Islands",
      "Tuvalu",
      "Norfolk Island",
      "nru",
      "nfk",
      "AU",
      "CX",
      "NF",
      "Australia",
      "Nauru",
      "aus",
      "hmd",
    ],
    supportsDecimals: true,
  },
  AWG: {
    code: "AWG",
    value: "AWG",
    description: "Aruban florin",
    countryKeywords: ["AWG", "AW", "abw", "Aruba"],
    supportsDecimals: true,
  },
  AZN: {
    code: "AZN",
    value: "man.",
    description: "Azerbaijani manat",
    countryKeywords: ["Azerbaijan", "AZN", "AZ", "aze"],
    supportsDecimals: true,
  },
  BAM: {
    code: "BAM",
    value: "KM",
    description: "Bosnia and Herzegovina convertible mark",
    countryKeywords: ["bih", "Bosnia and Herzegovina", "BAM", "BA"],
    supportsDecimals: true,
  },
  BBD: {
    code: "BBD",
    value: "BBD",
    description: "Barbadian dollar",
    countryKeywords: ["BB", "brb", "Barbados", "BBD"],
    supportsDecimals: true,
  },
  BDT: {
    code: "BDT",
    value: "Tk",
    description: "Bangladeshi taka",
    countryKeywords: ["BD", "Bangladesh", "BDT", "bgd"],
    supportsDecimals: true,
  },
  BGN: {
    code: "BGN",
    value: "лв",
    description: "Bulgarian lev",
    countryKeywords: ["BG", "BGN", "bgr", "Bulgaria"],
    supportsDecimals: true,
  },
  BHD: {
    code: "BHD",
    value: "BHD",
    description: "Bahraini dinar",
    countryKeywords: ["BH", "bhr", "Bahrain", "BHD"],
    supportsDecimals: true,
  },
  BIF: {
    code: "BIF",
    value: "BIF",
    description: "Burundian franc",
    countryKeywords: ["Burundi", "bdi", "BI", "BIF"],
    supportsDecimals: false,
  },
  BMD: {
    code: "BMD",
    value: "BMD",
    description: "Bermudian dollar",
    countryKeywords: ["BMD", "Bermuda", "BM", "bmu"],
    supportsDecimals: true,
  },
  BND: {
    code: "BND",
    value: "BND",
    description: "Brunei dollar",
    countryKeywords: ["BND", "BN", "Brunei Darussalam", "brn"],
    supportsDecimals: true,
  },
  BOB: {
    code: "BOB",
    value: "BOB",
    description: "Bolivian boliviano",
    countryKeywords: ["BOB", "Bolivia", "BO", "bol"],
    supportsDecimals: true,
  },
  BRL: {
    code: "BRL",
    value: "R$",
    description: "Brazilian real",
    countryKeywords: ["BR", "bra", "Brazil", "BRL"],
    supportsDecimals: true,
  },
  BSD: {
    code: "BSD",
    value: "BSD",
    description: "Bahamian dollar",
    countryKeywords: ["BS", "BSD", "bhs", "Bahamas"],
    supportsDecimals: true,
  },
  BTN: {
    code: "BTN",
    value: "BTN",
    description: "Bhutanese ngultrum",
    countryKeywords: ["BT", "Bhutan", "BTN", "btn"],
    supportsDecimals: true,
  },
  BWP: {
    code: "BWP",
    value: "BWP",
    description: "Botswana pula",
    countryKeywords: ["BW", "Botswana", "BWP", "bwa"],
    supportsDecimals: true,
  },
  BYN: {
    code: "BYN",
    value: "BYN",
    description: "Belarusian ruble",
    countryKeywords: ["BYN", "BY", "blr", "Belarus"],
    supportsDecimals: true,
  },
  BZD: {
    code: "BZD",
    value: "BZ$",
    description: "Belizean dollar",
    countryKeywords: ["blz", "BZ", "Belize", "BZD"],
    supportsDecimals: true,
  },
  CAD: {
    code: "CAD",
    value: "C$",
    description: "Canadian dollar",
    countryKeywords: ["can", "Canada", "CAD", "CA"],
    supportsDecimals: true,
  },
  CDF: {
    code: "CDF",
    value: "CDF",
    description: "Congolese franc",
    countryKeywords: ["CD", "CDF", "cod", "Congo, the Democratic Republic of the"],
    supportsDecimals: true,
  },
  CHF: {
    code: "CHF",
    value: "SFr.",
    description: "Swiss franc",
    countryKeywords: ["CHF", "che", "CH", "Liechtenstein", "lie", "Switzerland", "LI"],
    supportsDecimals: true,
  },
  CLP: {
    code: "CLP",
    value: "$",
    description: "Chilean peso",
    countryKeywords: ["CLP", "chl", "CL", "Chile"],
    supportsDecimals: false,
  },
  CNY: {
    code: "CNY",
    value: "¥",
    description: "Chinese yuan",
    countryKeywords: ["China", "chn", "CN", "CNY"],
    supportsDecimals: true,
  },
  COP: {
    code: "COP",
    value: "$",
    description: "Colombian peso",
    countryKeywords: ["col", "Colombia", "COP", "CO"],
    supportsDecimals: true,
  },
  CRC: {
    code: "CRC",
    value: "₡",
    description: "Costa Rican colón",
    countryKeywords: ["CRC", "cri", "CR", "Costa Rica"],
    supportsDecimals: true,
  },
  CUC: {
    code: "CUC",
    value: "CUC$",
    description: "Cuban Convertible peso",
    countryKeywords: [],
    supportsDecimals: true,
  },
  CUP: {
    code: "CUP",
    value: "CUP",
    description: "Cuban peso",
    countryKeywords: ["cub", "Cuba", "CU", "CUP"],
    supportsDecimals: true,
  },
  CVE: {
    code: "CVE",
    value: "CVE",
    description: "Cape Verdean escudo",
    countryKeywords: ["Cape Verde", "CVE", "CV", "cpv"],
    supportsDecimals: true,
  },
  CZK: {
    code: "CZK",
    value: "Kč",
    description: "Czech koruna",
    countryKeywords: ["Czech Republic", "CZK", "CZ", "cze"],
    supportsDecimals: true,
  },
  DJF: {
    code: "DJF",
    value: "DJF",
    description: "Djiboutian franc",
    countryKeywords: ["Djibouti", "DJF", "dji", "DJ"],
    supportsDecimals: false,
  },
  DKK: {
    code: "DKK",
    value: "kr",
    description: "Danish krone",
    countryKeywords: [
      "grl",
      "dnk",
      "fro",
      "DKK",
      "GL",
      "Faroe Islands",
      "DK",
      "Denmark",
      "FO",
      "Greenland",
    ],
    supportsDecimals: true,
  },
  DOP: {
    code: "DOP",
    value: "$",
    description: "Dominican peso",
    countryKeywords: ["dom", "DOP", "Dominican Republic", "DO"],
    supportsDecimals: true,
  },
  DZD: {
    code: "DZD",
    value: "DZD",
    description: "Algerian dinar",
    countryKeywords: ["DZ", "dza", "Algeria", "DZD"],
    supportsDecimals: true,
  },
  EGP: {
    code: "EGP",
    value: "E£",
    description: "Egyptian pound",
    countryKeywords: ["EG", "egy", "Egypt", "EGP"],
    supportsDecimals: true,
  },
  ERN: {
    code: "ERN",
    value: "ERN",
    description: "Eritrean nakfa",
    countryKeywords: ["ERN", "Eritrea", "ER", "eri"],
    supportsDecimals: true,
  },
  ETB: {
    code: "ETB",
    value: "Br",
    description: "Ethiopian birr",
    countryKeywords: ["ETB", "eth", "Ethiopia", "ET"],
    supportsDecimals: true,
  },
  EUR: {
    code: "EUR",
    value: "€",
    description: "Euro",
    countryKeywords: [
      "Cambodia",
      "fin",
      "cck",
      "Paraguay",
      "lux",
      "uga",
      "iot",
      "Solomon Islands",
      "Montserrat",
      "deu",
      "egy",
      "Guadeloupe",
      "lva",
      "hnd",
      "nzl",
      "AD",
      "AE",
      "Falkland Islands",
      "fji",
      "AG",
      "Seychelles",
      "AI",
      "glp",
      "AL",
      "AM",
      "Bahrain",
      "AO",
      "wlf",
      "AR",
      "AS",
      "AT",
      "AU",
      "AW",
      "Finland",
      "AX",
      "Faroe Islands",
      "AZ",
      "gmb",
      "BA",
      "BB",
      "BD",
      "BE",
      "BF",
      "BG",
      "BH",
      "mys",
      "BJ",
      "myt",
      "BL",
      "BM",
      "BN",
      "BO",
      "BS",
      "BT",
      "BW",
      "Cocos (Keeling) Islands",
      "Liechtenstein",
      "gnb",
      "Bulgaria",
      "sen",
      "CA",
      "CC",
      "irl",
      "flk",
      "qat",
      "CH",
      "CI",
      "CK",
      "Côte d'Ivoire",
      "CL",
      "United Arab Emirates",
      "CN",
      "tha",
      "Kenya",
      "French Polynesia",
      "CR",
      "CV",
      "Saint Lucia",
      "CX",
      "CY",
      "CZ",
      "abw",
      "Mayotte",
      "San Marino",
      "Israel",
      "Tajikistan",
      "DE",
      "isl",
      "Gibraltar",
      "Cyprus",
      "DK",
      "bel",
      "kwt",
      "DM",
      "isr",
      "ben",
      "Malaysia",
      "DO",
      "Armenia",
      "DZ",
      "ita",
      "Turks and Caicos Islands",
      "pan",
      "bfa",
      "ukr",
      "sgp",
      "EC",
      "EE",
      "Ecuador",
      "che",
      "EG",
      "reu",
      "chl",
      "chn",
      "hrv",
      "ES",
      "ET",
      "tjk",
      "vnm",
      "shn",
      "Italy",
      "bgd",
      "Haiti",
      "FI",
      "FJ",
      "FK",
      "FM",
      "American Samoa",
      "FO",
      "bgr",
      "FR",
      "Vatican City",
      "civ",
      "grd",
      "grc",
      "GA",
      "GB",
      "hti",
      "GD",
      "Kyrgyzstan",
      "GE",
      "GF",
      "GG",
      "grl",
      "GH",
      "GI",
      "GL",
      "GM",
      "GN",
      "Uzbekistan",
      "GP",
      "GR",
      "bhs",
      "GT",
      "bhr",
      "GW",
      "GY",
      "dma",
      "tls",
      "Dominica",
      "bih",
      "hun",
      "Benin",
      "HK",
      "HN",
      "ago",
      "HR",
      "HT",
      "Portugal",
      "HU",
      "Grenada",
      "wsm",
      "Mongolia",
      "Latvia",
      "Morocco",
      "fra",
      "Guatemala",
      "ID",
      "nam",
      "IE",
      "per",
      "dnk",
      "gtm",
      "fro",
      "IL",
      "IM",
      "IN",
      "IO",
      "Tanzania",
      "slb",
      "IS",
      "Ghana",
      "IT",
      "sle",
      "India",
      "Canada",
      "Maldives",
      "Kosovo, Republic of",
      "Taiwan",
      "aia",
      "guf",
      "JE",
      "slv",
      "Germany",
      "fsm",
      "dom",
      "JM",
      "Fiji",
      "United States",
      "Guinea",
      "JP",
      "Micronesia, Federated States of",
      "Sao Tome and Principe",
      "guy",
      "mac",
      "Costa Rica",
      "ton",
      "Vietnam",
      "maf",
      "ncl",
      "smr",
      "KE",
      "KG",
      "KH",
      "mar",
      "blm",
      "KN",
      "KR",
      "Timor-Leste",
      "KW",
      "Switzerland",
      "KY",
      "Samoa",
      "Spain",
      "KZ",
      "LA",
      "phl",
      "LB",
      "LC",
      "Palau",
      "ury",
      "LI",
      "cok",
      "Estonia",
      "LK",
      "usa",
      "esp",
      "LR",
      "est",
      "LS",
      "LT",
      "LU",
      "LV",
      "bmu",
      "Niue",
      "Mozambique",
      "MA",
      "El Salvador",
      "vut",
      "MC",
      "alb",
      "MD",
      "ala",
      "ME",
      "mco",
      "eth",
      "MF",
      "ner",
      "lao",
      "MH",
      "Lesotho",
      "MK",
      "Tonga",
      "ML",
      "MN",
      "MO",
      "EUR",
      "MQ",
      "MR",
      "MS",
      "MT",
      "cpv",
      "MU",
      "MV",
      "MW",
      "mda",
      "MX",
      "MY",
      "MZ",
      "Mauritius",
      "Norfolk Island",
      "spm",
      "nfk",
      "NA",
      "NC",
      "NE",
      "NF",
      "lbn",
      "NI",
      "lbr",
      "NL",
      "mdv",
      "bol",
      "NO",
      "NP",
      "NR",
      "NU",
      "lca",
      "NZ",
      "Malta",
      "Cape Verde",
      "and",
      "Ireland",
      "rou",
      "cri",
      "Lithuania",
      "France",
      "OM",
      "mex",
      "srb",
      "kaz",
      "tto",
      "PA",
      "Nicaragua",
      "Norway",
      "PE",
      "PF",
      "PG",
      "Macao",
      "PH",
      "Uganda",
      "Mexico",
      "plw",
      "Suridescription",
      "PL",
      "PM",
      "Saint Helena",
      "Greenland",
      "Papua New Guinea",
      "PT",
      "PW",
      "nic",
      "PY",
      "Kazakhstan",
      "tun",
      "Åland Islands",
      "Bahamas",
      "QA",
      "tur",
      "brb",
      "tuv",
      "Marshall Islands",
      "Mali",
      "Panama",
      "jam",
      "niu",
      "Laos",
      "zaf",
      "brn",
      "Argentina",
      "png",
      "Zambia",
      "stp",
      "mhl",
      "Guinea-Bissau",
      "RE",
      "Macedonia, Former Yugoslav Republic of",
      "Namibia",
      "Georgia",
      "RO",
      "Saint Kitts and Nevis",
      "RS",
      "RW",
      "twn",
      "Aruba",
      "pol",
      "SA",
      "SB",
      "SC",
      "omn",
      "sur",
      "SE",
      "Sweden",
      "SG",
      "Malawi",
      "are",
      "SH",
      "ken",
      "Andorra",
      "SI",
      "arg",
      "Poland",
      "SK",
      "SL",
      "SM",
      "SN",
      "uzb",
      "Tunisia",
      "arm",
      "btn",
      "SR",
      "ST",
      "SV",
      "nld",
      "svk",
      "svn",
      "Tuvalu",
      "TC",
      "Lebanon",
      "Azerbaijan",
      "TF",
      "Czech Republic",
      "Mauritania",
      "TH",
      "Guernsey",
      "TJ",
      "TL",
      "asm",
      "TN",
      "TO",
      "Australia",
      "TR",
      "swe",
      "TT",
      "TV",
      "TW",
      "TZ",
      "mkd",
      "lie",
      "dza",
      "UA",
      "Iceland",
      "Oman",
      "Gabon",
      "UG",
      "atf",
      "atg",
      "Luxembourg",
      "Algeria",
      "tza",
      "Jersey",
      "Slovenia",
      "cxr",
      "US",
      "kgz",
      "jey",
      "Antigua and Barbuda",
      "UY",
      "UZ",
      "Moldova",
      "VA",
      "mli",
      "bwa",
      "VC",
      "khm",
      "VG",
      "idn",
      "Vanuatu",
      "prt",
      "mlt",
      "cym",
      "pry",
      "VN",
      "Honduras",
      "cyp",
      "Nauru",
      "syc",
      "rwa",
      "aus",
      "VU",
      "aut",
      "Singapore",
      "lka",
      "French Guiana",
      "gab",
      "Christmas Island",
      "cze",
      "WF",
      "nor",
      "Netherlands",
      "China",
      "Martinique",
      "WS",
      "Saint Pierre and Miquelon",
      "Bhutan",
      "Romania",
      "mne",
      "Philippines",
      "mng",
      "npl",
      "XK",
      "gbr",
      "British Virgin Islands",
      "British Indian Ocean Territory",
      "Montenegro",
      "Indonesia",
      "Angola",
      "Brunei Darussalam",
      "New Caledonia",
      "Cayman Islands",
      "Greece",
      "Guyana",
      "moz",
      "YT",
      "Chile",
      "Nepal",
      "Isle of Man",
      "Ukraine",
      "ZA",
      "vat",
      "Anguilla",
      "nru",
      "ZM",
      "Turkey",
      "Belgium",
      "South Africa",
      "Trinidad and Tobago",
      "Bermuda",
      "Jamaica",
      "Peru",
      "Hong Kong",
      "Thailand",
      "aze",
      "geo",
      "Saint Martin (French part)",
      "Kuwait",
      "Croatia",
      "kna",
      "Cook Islands",
      "Uruguay",
      "Sri Lanka",
      "vct",
      "United Kingdom",
      "mrt",
      "Liberia",
      "Burkina Faso",
      "Saint Barthélemy",
      "pyf",
      "Wallis and Futuna",
      "msr",
      "zmb",
      "kor",
      "South Korea",
      "Austria",
      "Monaco",
      "ecu",
      "ggy",
      "gha",
      "Réunion",
      "Hungary",
      "Japan",
      "mtq",
      "Albania",
      "New Zealand",
      "Senegal",
      "Ethiopia",
      "gib",
      "hkg",
      "Egypt",
      "lso",
      "mus",
      "Sierra Leone",
      "imn",
      "Bolivia",
      "gin",
      "Saudi Arabia",
      "vgb",
      "can",
      "tca",
      "Gambia",
      "Qatar",
      "Slovakia",
      "Serbia",
      "Bosnia and Herzegovina",
      "ind",
      "xkx",
      "Niger",
      "sau",
      "Rwanda",
      "jpn",
      "French Southern Territories",
      "ltu",
      "Bangladesh",
      "Barbados",
      "Botswana",
      "Saint Vincent and the Grenadines",
      "Dominican Republic",
      "Denmark",
      "mwi",
    ],
    supportsDecimals: true,
  },
  FJD: {
    code: "FJD",
    value: "FJD",
    description: "Fijian dollar",
    countryKeywords: ["FJD", "Fiji", "FJ", "fji"],
    supportsDecimals: true,
  },
  FKP: {
    code: "FKP",
    value: "£",
    description: "Falkland Islands pound",
    countryKeywords: [
      "South Georgia and the South Sandwich Islands",
      "flk",
      "Falkland Islands",
      "FK",
      "FKP",
      "GS",
      "sgs",
    ],
    supportsDecimals: true,
  },
  GBP: {
    code: "GBP",
    value: "£",
    description: "British pound",
    countryKeywords: [
      "PT",
      "fin",
      "Kazakhstan",
      "tun",
      "lux",
      "QA",
      "tur",
      "deu",
      "lva",
      "AD",
      "AE",
      "Seychelles",
      "AL",
      "Bahrain",
      "stp",
      "AT",
      "Macedonia, Former Yugoslav Republic of",
      "Finland",
      "Faroe Islands",
      "AZ",
      "Georgia",
      "RO",
      "BA",
      "RS",
      "BE",
      "BG",
      "BH",
      "pol",
      "SA",
      "SC",
      "SE",
      "Sweden",
      "are",
      "SI",
      "Andorra",
      "Liechtenstein",
      "Poland",
      "SK",
      "SM",
      "Bulgaria",
      "Tunisia",
      "ST",
      "irl",
      "qat",
      "CH",
      "nld",
      "svk",
      "svn",
      "United Arab Emirates",
      "CR",
      "Lebanon",
      "Azerbaijan",
      "Czech Republic",
      "Mauritania",
      "Saint Lucia",
      "Guernsey",
      "CY",
      "CZ",
      "TL",
      "TN",
      "Israel",
      "San Marino",
      "TR",
      "swe",
      "DE",
      "isl",
      "Gibraltar",
      "Cyprus",
      "mkd",
      "DK",
      "bel",
      "lie",
      "isr",
      "kwt",
      "DO",
      "UA",
      "Iceland",
      "Luxembourg",
      "ita",
      "Jersey",
      "Slovenia",
      "ukr",
      "jey",
      "EE",
      "che",
      "Moldova",
      "hrv",
      "ES",
      "VG",
      "prt",
      "mlt",
      "Italy",
      "cyp",
      "syc",
      "aut",
      "FI",
      "FO",
      "bgr",
      "FR",
      "cze",
      "nor",
      "Netherlands",
      "GBP",
      "grc",
      "GB",
      "GE",
      "GG",
      "grl",
      "GI",
      "Romania",
      "mne",
      "GL",
      "GR",
      "GT",
      "bhr",
      "gbr",
      "British Virgin Islands",
      "tls",
      "Montenegro",
      "hun",
      "bih",
      "HR",
      "HU",
      "Portugal",
      "Greece",
      "Latvia",
      "fra",
      "Guatemala",
      "IE",
      "dnk",
      "gtm",
      "fro",
      "IL",
      "IM",
      "Isle of Man",
      "Ukraine",
      "IS",
      "IT",
      "Turkey",
      "Belgium",
      "JE",
      "Germany",
      "dom",
      "Sao Tome and Principe",
      "aze",
      "Costa Rica",
      "geo",
      "smr",
      "Kuwait",
      "Croatia",
      "Timor-Leste",
      "United Kingdom",
      "KW",
      "Switzerland",
      "mrt",
      "KZ",
      "Spain",
      "LB",
      "LC",
      "LI",
      "Estonia",
      "esp",
      "est",
      "LT",
      "LU",
      "LV",
      "Austria",
      "Monaco",
      "alb",
      "MC",
      "MD",
      "ggy",
      "ME",
      "mco",
      "MK",
      "Hungary",
      "MR",
      "MT",
      "MU",
      "mda",
      "Mauritius",
      "Albania",
      "lbn",
      "NL",
      "gib",
      "NO",
      "mus",
      "imn",
      "lca",
      "Malta",
      "Saudi Arabia",
      "vgb",
      "Qatar",
      "Ireland",
      "and",
      "rou",
      "Slovakia",
      "cri",
      "Serbia",
      "Lithuania",
      "France",
      "Bosnia and Herzegovina",
      "srb",
      "kaz",
      "sau",
      "ltu",
      "Norway",
      "Denmark",
      "Dominican Republic",
      "PL",
      "Greenland",
    ],
    supportsDecimals: true,
  },
  GEL: {
    code: "GEL",
    value: "GEL",
    description: "Georgian lari",
    countryKeywords: ["geo", "Georgia", "GE", "GEL"],
    supportsDecimals: true,
  },
  GGP: {
    code: "GGP",
    value: "£",
    description: "Guernsey pound",
    countryKeywords: [],
    supportsDecimals: true,
  },
  GHS: {
    code: "GHS",
    value: "GH¢",
    description: "Ghanaian cedi",
    countryKeywords: ["GH", "gha", "GHS", "Ghana"],
    supportsDecimals: true,
  },
  GIP: {
    code: "GIP",
    value: "£",
    description: "Gibraltar pound",
    countryKeywords: ["gib", "GI", "Gibraltar", "GIP"],
    supportsDecimals: true,
  },
  GMD: {
    code: "GMD",
    value: "GMD",
    description: "Gambian dalasi",
    countryKeywords: ["Gambia", "GM", "gmb", "GMD"],
    supportsDecimals: true,
  },
  GNF: {
    code: "GNF",
    value: "GNF",
    description: "Guinean franc",
    countryKeywords: ["Guinea", "GN", "gin", "GNF"],
    supportsDecimals: false,
  },
  GTQ: {
    code: "GTQ",
    value: "Q",
    description: "Guatemalan quetzal",
    countryKeywords: ["gtm", "GTQ", "Guatemala", "GT"],
    supportsDecimals: true,
  },
  GYD: {
    code: "GYD",
    value: "GYD",
    description: "Guyanese dollar",
    countryKeywords: ["GY", "GYD", "Guyana", "guy"],
    supportsDecimals: true,
  },
  HKD: {
    code: "HKD",
    value: "$",
    description: "Hong Kong dollar",
    countryKeywords: ["HKD", "HK", "Hong Kong", "hkg"],
    supportsDecimals: true,
  },
  HNL: {
    code: "HNL",
    value: "L",
    description: "Honduran lempira",
    countryKeywords: ["HN", "HNL", "Honduras", "hnd"],
    supportsDecimals: true,
  },
  HTG: {
    code: "HTG",
    value: "HTG",
    description: "Haitian gourde",
    countryKeywords: ["Haiti", "HTG", "HT", "hti"],
    supportsDecimals: true,
  },
  HUF: {
    code: "HUF",
    value: "Ft",
    description: "Hungarian forint",
    countryKeywords: ["hun", "Hungary", "HUF", "HU"],
    supportsDecimals: false,
  },
  IDR: {
    code: "IDR",
    value: "Rp",
    description: "Indonesian rupiah",
    countryKeywords: ["idn", "IDR", "ID", "Indonesia"],
    supportsDecimals: false,
  },
  ILS: {
    code: "ILS",
    value: "₪",
    description: "Israeli shekel",
    countryKeywords: ["ILS", "IL", "PS", "pse", "isr", "Israel", "Palestine"],
    supportsDecimals: true,
  },
  IMP: {
    code: "IMP",
    value: "£",
    description: "Isle of Man pound",
    countryKeywords: [],
    supportsDecimals: true,
  },
  INR: {
    code: "INR",
    value: "₹",
    description: "Indian rupee",
    countryKeywords: ["IN", "INR", "ind", "India"],
    supportsDecimals: true,
  },
  IQD: {
    code: "IQD",
    value: "IQD",
    description: "Iraqi dinar",
    countryKeywords: ["IQ", "irq", "Iraq", "IQD"],
    supportsDecimals: true,
  },
  IRR: {
    code: "IRR",
    value: "﷼",
    description: "Iranian rial",
    countryKeywords: ["irn", "Iran", "IRR", "IR"],
    supportsDecimals: true,
  },
  ISK: {
    code: "ISK",
    value: "kr",
    description: "Icelandic króna",
    countryKeywords: ["ISK", "isl", "IS", "Iceland"],
    supportsDecimals: false,
  },
  JEP: {
    code: "JEP",
    value: "£",
    description: "Jersey pound",
    countryKeywords: [],
    supportsDecimals: true,
  },
  JMD: {
    code: "JMD",
    value: "J$",
    description: "Jamaican dollar",
    countryKeywords: ["JMD", "JM", "jam", "Jamaica"],
    supportsDecimals: true,
  },
  JOD: {
    code: "JOD",
    value: "JD",
    description: "Jordanian dinar",
    countryKeywords: ["JO", "jor", "Jordan", "JOD"],
    supportsDecimals: true,
  },
  JPY: {
    code: "JPY",
    value: "¥",
    description: "Japanese yen",
    countryKeywords: ["JPY", "JP", "Japan", "jpn"],
    supportsDecimals: false,
  },
  KES: {
    code: "KES",
    value: "Ksh",
    description: "Kenyan shilling",
    countryKeywords: ["ken", "KES", "KE", "Kenya"],
    supportsDecimals: false,
  },
  KGS: {
    code: "KGS",
    value: "KGS",
    description: "Kyrgyzstani som",
    countryKeywords: ["KGS", "KG", "Kyrgyzstan", "kgz"],
    supportsDecimals: true,
  },
  KHR: {
    code: "KHR",
    value: "៛",
    description: "Cambodian riel",
    countryKeywords: ["khm", "KHR", "Cambodia", "KH"],
    supportsDecimals: true,
  },
  KMF: {
    code: "KMF",
    value: "KMF",
    description: "Comorian franc",
    countryKeywords: ["com", "KM", "KMF", "Comoros"],
    supportsDecimals: false,
  },
  KPW: {
    code: "KPW",
    value: "KPW",
    description: "North Korean won",
    countryKeywords: ["KPW", "KP", "Korea, Democratic People's Republic of", "prk"],
    supportsDecimals: true,
  },
  KRW: {
    code: "KRW",
    value: "₩",
    description: "South Korean won",
    countryKeywords: ["KRW", "South Korea", "KR", "kor"],
    supportsDecimals: false,
  },
  KWD: {
    code: "KWD",
    value: "ك",
    description: "Kuwaiti dinar",
    countryKeywords: ["KWD", "kwt", "KW", "Kuwait"],
    supportsDecimals: true,
  },
  KYD: {
    code: "KYD",
    value: "$",
    description: "Cayman Islands dollar",
    countryKeywords: ["Cayman Islands", "cym", "KYD", "KY"],
    supportsDecimals: true,
  },
  KZT: {
    code: "KZT",
    value: "₸",
    description: "Kazakhstani tenge",
    countryKeywords: ["KZT", "kaz", "Kazakhstan", "KZ"],
    supportsDecimals: true,
  },
  LAK: {
    code: "LAK",
    value: "LAK",
    description: "Lao kip",
    countryKeywords: ["LA", "LAK", "lao", "Laos"],
    supportsDecimals: true,
  },
  LBP: {
    code: "LBP",
    value: "LBP",
    description: "Lebanon Pound",
    countryKeywords: ["Lebanon", "LB", "lbn", "LBP"],
    supportsDecimals: true,
  },
  LKR: {
    code: "LKR",
    value: "Sr",
    description: "Sri Lankan rupee",
    countryKeywords: ["LKR", "lka", "Sri Lanka", "LK"],
    supportsDecimals: true,
  },
  LRD: {
    code: "LRD",
    value: "L$",
    description: "Liberian dollar",
    countryKeywords: ["Liberia", "LR", "lbr", "LRD"],
    supportsDecimals: true,
  },
  LSL: {
    code: "LSL",
    value: "LSL",
    description: "Lesotho loti",
    countryKeywords: ["LSL", "LS", "lso", "Lesotho"],
    supportsDecimals: true,
  },
  LYD: {
    code: "LYD",
    value: "LYD",
    description: "Libyan dinar",
    countryKeywords: ["lby", "LYD", "LY", "Libya"],
    supportsDecimals: true,
  },
  MAD: {
    code: "MAD",
    value: "د.م.",
    description: "Moroccan dirham",
    countryKeywords: ["MAD", "Western Sahara", "EH", "MA", "Morocco", "esh", "mar"],
    supportsDecimals: true,
  },
  MDL: {
    code: "MDL",
    value: "MDL",
    description: "Moldovan leu",
    countryKeywords: ["MDL", "MD", "Moldova", "mda"],
    supportsDecimals: true,
  },
  MGA: {
    code: "MGA",
    value: "MGA",
    description: "Malagasy ariary",
    countryKeywords: ["mdg", "MGA", "MG", "Madagascar"],
    supportsDecimals: true,
  },
  MKD: {
    code: "MKD",
    value: "ден",
    description: "Macedonian denar",
    countryKeywords: ["Macedonia, Former Yugoslav Republic of", "MKD", "mkd", "MK"],
    supportsDecimals: true,
  },
  MMK: {
    code: "MMK",
    value: "K",
    description: "Myanmar kyat",
    countryKeywords: ["MM", "Myanmar", "mmr", "MMK"],
    supportsDecimals: true,
  },
  MNT: {
    code: "MNT",
    value: "MNT",
    description: "Mongolian tugrik",
    countryKeywords: ["MN", "MNT", "Mongolia", "mng"],
    supportsDecimals: true,
  },
  MOP: {
    code: "MOP",
    value: "MOP",
    description: "Macanese pataca",
    countryKeywords: ["MO", "Macao", "mac", "MOP"],
    supportsDecimals: true,
  },
  MRU: {
    code: "MRU",
    value: "UM",
    description: "MRU",
    countryKeywords: ["MRU", "Mauritania", "MR", "mrt"],
    supportsDecimals: true,
  },
  MUR: {
    code: "MUR",
    value: "₨",
    description: "Mauritian rupee",
    countryKeywords: ["MU", "mus", "MUR", "Mauritius"],
    supportsDecimals: true,
  },
  MVR: {
    code: "MVR",
    value: "MVR",
    description: "Maldivian rufiyaa",
    countryKeywords: ["Maldives", "MVR", "MV", "mdv"],
    supportsDecimals: true,
  },
  MWK: {
    code: "MWK",
    value: "MWK",
    description: "Malawian kwacha",
    countryKeywords: ["MWK", "Malawi", "MW", "mwi"],
    supportsDecimals: true,
  },
  MXN: {
    code: "MXN",
    value: "Mex$",
    description: "Mexican peso",
    countryKeywords: ["mex", "MXN", "Mexico", "MX"],
    supportsDecimals: true,
  },
  MYR: {
    code: "MYR",
    value: "RM",
    description: "Malaysian ringgit",
    countryKeywords: ["mys", "MYR", "Malaysia", "MY"],
    supportsDecimals: true,
  },
  MZN: {
    code: "MZN",
    value: "MT",
    description: "Mozambican metical",
    countryKeywords: ["MZN", "Mozambique", "moz", "MZ"],
    supportsDecimals: true,
  },
  NAD: {
    code: "NAD",
    value: "$",
    description: "Namibian dollar",
    countryKeywords: ["NAD", "NA", "Namibia", "nam"],
    supportsDecimals: true,
  },
  NGN: {
    code: "NGN",
    value: "₦",
    description: "Nigerian naira",
    countryKeywords: ["NGN", "NG", "nga", "Nigeria"],
    supportsDecimals: true,
  },
  NIO: {
    code: "NIO",
    value: "NIO",
    description: "Nicaraguan córdoba",
    countryKeywords: ["Nicaragua", "NIO", "nic", "NI"],
    supportsDecimals: true,
  },
  NOK: {
    code: "NOK",
    value: "kr",
    description: "Norwegian krone",
    countryKeywords: [
      "nor",
      "NO",
      "bvt",
      "BV",
      "Norway",
      "SJ",
      "sjm",
      "NOK",
      "Bouvet Island",
      "Svalbard and Jan Mayen",
    ],
    supportsDecimals: true,
  },
  NPR: {
    code: "NPR",
    value: "₨",
    description: "Nepalese rupee",
    countryKeywords: ["NPR", "NP", "npl", "Nepal"],
    supportsDecimals: false,
  },
  NZD: {
    code: "NZD",
    value: "$",
    description: "New Zealand dollar",
    countryKeywords: [
      "nzl",
      "Tokelau",
      "Cook Islands",
      "NU",
      "CK",
      "Pitcairn Islands",
      "NZ",
      "Niue",
      "New Zealand",
      "tkl",
      "TK",
      "NZD",
      "pcn",
      "niu",
      "PN",
      "cok",
    ],
    supportsDecimals: true,
  },
  OMR: {
    code: "OMR",
    value: "OMR",
    description: "Omani rial",
    countryKeywords: ["omn", "OMR", "OM", "Oman"],
    supportsDecimals: true,
  },
  PAB: {
    code: "PAB",
    value: "B/.",
    description: "Panamanian balboa",
    countryKeywords: ["PAB", "PA", "Panama", "pan"],
    supportsDecimals: true,
  },
  PEN: {
    code: "PEN",
    value: "S/.",
    description: "Peruvian sol",
    countryKeywords: ["PE", "PEN", "per", "Peru"],
    supportsDecimals: true,
  },
  PGK: {
    code: "PGK",
    value: "PGK",
    description: "Papua New Guinean kina",
    countryKeywords: ["Papua New Guinea", "PGK", "PG", "png"],
    supportsDecimals: true,
  },
  PHP: {
    code: "PHP",
    value: "₱",
    description: "Philippine peso",
    countryKeywords: ["phl", "Philippines", "PH", "PHP"],
    supportsDecimals: true,
  },
  PKR: {
    code: "PKR",
    value: "₨",
    description: "Pakistani rupee",
    countryKeywords: ["Pakistan", "PKR", "PK", "pak"],
    supportsDecimals: true,
  },
  PLN: {
    code: "PLN",
    value: "zł",
    description: "Polish złoty",
    countryKeywords: ["PLN", "Poland", "PL", "pol"],
    supportsDecimals: true,
  },
  PYG: {
    code: "PYG",
    value: "₲",
    description: "Paraguay guaraní",
    countryKeywords: ["pry", "PY", "Paraguay", "PYG"],
    supportsDecimals: false,
  },
  QAR: {
    code: "QAR",
    value: "QR",
    description: "Qatari riyal",
    countryKeywords: ["QA", "QAR", "qat", "Qatar"],
    supportsDecimals: true,
  },
  RON: {
    code: "RON",
    value: "L",
    description: "Romanian leu",
    countryKeywords: ["RON", "Romania", "rou", "RO"],
    supportsDecimals: true,
  },
  RSD: {
    code: "RSD",
    value: "РСД",
    description: "Serbian dinar",
    countryKeywords: ["RS", "RSD", "srb", "Serbia"],
    supportsDecimals: true,
  },
  RUB: {
    code: "RUB",
    value: "руб",
    description: "Russian rouble",
    countryKeywords: ["rus", "Russian Federation", "RU", "RUB"],
    supportsDecimals: true,
  },
  RWF: {
    code: "RWF",
    value: "RWF",
    description: "Rwandan franc",
    countryKeywords: ["RWF", "RW", "Rwanda", "rwa"],
    supportsDecimals: false,
  },
  SAR: {
    code: "SAR",
    value: "SR",
    description: "Saudi riyal",
    countryKeywords: ["Saudi Arabia", "SAR", "sau", "SA"],
    supportsDecimals: true,
  },
  SBD: {
    code: "SBD",
    value: "SI$",
    description: "Solomon Islands dollar",
    countryKeywords: ["SBD", "slb", "SB", "Solomon Islands"],
    supportsDecimals: true,
  },
  SCR: {
    code: "SCR",
    value: "₨",
    description: "Seychellois rupee",
    countryKeywords: ["SC", "SCR", "Seychelles", "syc"],
    supportsDecimals: true,
  },
  SDG: {
    code: "SDG",
    value: "SDG",
    description: "Sudanese Pound",
    countryKeywords: ["SD", "SDG", "Sudan", "sdn"],
    supportsDecimals: true,
  },
  SEK: {
    code: "SEK",
    value: "kr",
    description: "Swedish krona",
    countryKeywords: ["swe", "SE", "Sweden", "SEK"],
    supportsDecimals: true,
  },
  SGD: {
    code: "SGD",
    value: "S$",
    description: "Singapore dollar",
    countryKeywords: ["SGD", "Singapore", "SG", "sgp"],
    supportsDecimals: true,
  },
  SHP: {
    code: "SHP",
    value: "£",
    description: "Saint Helena pound",
    countryKeywords: ["SH", "shn", "SHP", "Saint Helena"],
    supportsDecimals: true,
  },
  SLL: {
    code: "SLL",
    value: "SLL",
    description: "Sierra Leonean leone",
    countryKeywords: ["SLL", "SL", "Sierra Leone", "sle"],
    supportsDecimals: true,
  },
  SLE: {
    code: "SLE",
    value: "SLE",
    description: "SLE",
    countryKeywords: [],
    supportsDecimals: true,
  },
  SOS: {
    code: "SOS",
    value: "Sh.So.",
    description: "Somali shilling",
    countryKeywords: ["som", "SOS", "Somalia", "SO"],
    supportsDecimals: true,
  },
  SRD: {
    code: "SRD",
    value: "$",
    description: "Suridescriptionse dollar",
    countryKeywords: ["sur", "SRD", "Suridescription", "SR"],
    supportsDecimals: true,
  },
  SSP: {
    code: "SSP",
    value: "SSP",
    description: "South Sudanese pound",
    countryKeywords: ["SS", "SSP", "South Sudan", "ssd"],
    supportsDecimals: true,
  },
  STD: {
    code: "STD",
    value: "STD",
    description: "São Tomé & Príncipe dobra",
    countryKeywords: [],
    supportsDecimals: true,
  },
  STN: {
    code: "STN",
    value: "STN",
    description: "STN",
    countryKeywords: ["ST", "Sao Tome and Principe", "STN", "stp"],
    supportsDecimals: true,
  },
  SVC: {
    code: "SVC",
    value: "₡",
    description: "Salvadoran colón",
    countryKeywords: [],
    supportsDecimals: true,
  },
  SYP: {
    code: "SYP",
    value: "£",
    description: "Syrian pound",
    countryKeywords: ["Syrian Arab Republic", "SY", "SYP", "syr"],
    supportsDecimals: true,
  },
  SZL: {
    code: "SZL",
    value: "SZL",
    description: "Swazi lilangeni",
    countryKeywords: ["SZL", "Swaziland", "SZ", "swz"],
    supportsDecimals: true,
  },
  THB: {
    code: "THB",
    value: "฿",
    description: "Thai baht",
    countryKeywords: ["TH", "tha", "Thailand", "THB"],
    supportsDecimals: true,
  },
  TJS: {
    code: "TJS",
    value: "TJS",
    description: "Tajikistani somoni",
    countryKeywords: ["tjk", "TJ", "TJS", "Tajikistan"],
    supportsDecimals: true,
  },
  TMT: {
    code: "TMT",
    value: "TMT",
    description: "Turkmenistani manat",
    countryKeywords: ["tkm", "TM", "TMT", "Turkmenistan"],
    supportsDecimals: true,
  },
  TND: {
    code: "TND",
    value: "TND",
    description: "Tunisian dinar",
    countryKeywords: ["TN", "TND", "tun", "Tunisia"],
    supportsDecimals: true,
  },
  TOP: {
    code: "TOP",
    value: "TOP",
    description: "Tongan pa'anga",
    countryKeywords: ["ton", "TOP", "TO", "Tonga"],
    supportsDecimals: true,
  },
  TRY: {
    code: "TRY",
    value: "TL",
    description: "Turkish lira",
    countryKeywords: ["tur", "Turkey", "TRY", "TR"],
    supportsDecimals: true,
  },
  TTD: {
    code: "TTD",
    value: "TTD",
    description: "Trinidad and Tobago dollar",
    countryKeywords: ["TT", "TTD", "Trinidad and Tobago", "tto"],
    supportsDecimals: true,
  },
  TWD: {
    code: "TWD",
    value: "NT$",
    description: "Taiwanese New Taiwan dollar",
    countryKeywords: ["TWD", "TW", "Taiwan", "twn"],
    supportsDecimals: true,
  },
  TZS: {
    code: "TZS",
    value: "tzs",
    description: "Tanzanian shilling",
    countryKeywords: ["TZ", "Tanzania", "tza", "TZS"],
    supportsDecimals: true,
  },
  UAH: {
    code: "UAH",
    value: "₴",
    description: "Ukrainian hryvnia",
    countryKeywords: ["Ukraine", "UA", "UAH", "ukr"],
    supportsDecimals: true,
  },
  UGX: {
    code: "UGX",
    value: "Ush",
    description: "Ugandan shilling",
    countryKeywords: ["UG", "UGX", "Uganda", "uga"],
    supportsDecimals: false,
  },
  USD: {
    code: "USD",
    value: "$",
    description: "United States dollar",
    countryKeywords: [
      "Cambodia",
      "fin",
      "cck",
      "Paraguay",
      "lux",
      "uga",
      "iot",
      "Solomon Islands",
      "Montserrat",
      "deu",
      "Guadeloupe",
      "lva",
      "vir",
      "hnd",
      "nzl",
      "AD",
      "AE",
      "Falkland Islands",
      "fji",
      "AG",
      "Seychelles",
      "AI",
      "glp",
      "AL",
      "AM",
      "Bahrain",
      "AO",
      "AQ",
      "wlf",
      "AR",
      "AS",
      "AT",
      "AU",
      "Finland",
      "AX",
      "Faroe Islands",
      "AZ",
      "gmb",
      "BA",
      "BB",
      "Puerto Rico",
      "BE",
      "BF",
      "BG",
      "BH",
      "mys",
      "BJ",
      "myt",
      "BL",
      "BN",
      "BO",
      "BQ",
      "BS",
      "BT",
      "BW",
      "Cocos (Keeling) Islands",
      "Liechtenstein",
      "United States Virgin Islands",
      "gnb",
      "Bulgaria",
      "sen",
      "CA",
      "CC",
      "irl",
      "flk",
      "CH",
      "CI",
      "CK",
      "Côte d'Ivoire",
      "CL",
      "United Arab Emirates",
      "CN",
      "tha",
      "Kenya",
      "French Polynesia",
      "CR",
      "Saint Lucia",
      "CX",
      "CY",
      "CZ",
      "Mayotte",
      "San Marino",
      "Israel",
      "Tajikistan",
      "DE",
      "isl",
      "Gibraltar",
      "Cyprus",
      "DK",
      "bel",
      "kwt",
      "DM",
      "isr",
      "Northern Mariana Islands",
      "ben",
      "Malaysia",
      "DO",
      "Armenia",
      "bes",
      "DZ",
      "ita",
      "Turks and Caicos Islands",
      "pan",
      "bfa",
      "ukr",
      "sgp",
      "EC",
      "sgs",
      "EE",
      "Ecuador",
      "che",
      "reu",
      "EH",
      "chl",
      "chn",
      "hrv",
      "ES",
      "ET",
      "United States Minor Outlying Islands",
      "tjk",
      "vnm",
      "shn",
      "Italy",
      "Haiti",
      "FI",
      "FJ",
      "FK",
      "FM",
      "American Samoa",
      "FO",
      "bgr",
      "FR",
      "Vatican City",
      "umi",
      "civ",
      "tkl",
      "grc",
      "GA",
      "pcn",
      "GB",
      "hti",
      "Kyrgyzstan",
      "GE",
      "GF",
      "GG",
      "grl",
      "GH",
      "GI",
      "GL",
      "GM",
      "GN",
      "Uzbekistan",
      "GP",
      "GR",
      "bhs",
      "GS",
      "GT",
      "bhr",
      "GU",
      "GW",
      "GY",
      "dma",
      "tls",
      "Dominica",
      "bih",
      "hun",
      "Benin",
      "HK",
      "HN",
      "ago",
      "HR",
      "HT",
      "Portugal",
      "HU",
      "wsm",
      "Mongolia",
      "Latvia",
      "Morocco",
      "fra",
      "Guatemala",
      "ID",
      "nam",
      "IE",
      "per",
      "dnk",
      "gtm",
      "fro",
      "IL",
      "IM",
      "IN",
      "IO",
      "Tanzania",
      "slb",
      "IS",
      "Ghana",
      "IT",
      "sle",
      "India",
      "Canada",
      "Taiwan",
      "aia",
      "guf",
      "JE",
      "slv",
      "gum",
      "Germany",
      "fsm",
      "dom",
      "JM",
      "Fiji",
      "United States",
      "Guinea",
      "JP",
      "Micronesia, Federated States of",
      "Sao Tome and Principe",
      "guy",
      "mac",
      "Costa Rica",
      "ton",
      "Vietnam",
      "ncl",
      "smr",
      "KE",
      "KG",
      "KH",
      "mar",
      "blm",
      "KN",
      "USD",
      "KR",
      "Timor-Leste",
      "KW",
      "Switzerland",
      "KY",
      "Samoa",
      "Spain",
      "KZ",
      "LA",
      "phl",
      "LB",
      "LC",
      "Palau",
      "esh",
      "ury",
      "LI",
      "cok",
      "Estonia",
      "LK",
      "usa",
      "esp",
      "LR",
      "est",
      "LT",
      "LU",
      "LV",
      "Niue",
      "MA",
      "El Salvador",
      "vut",
      "MC",
      "alb",
      "ala",
      "ME",
      "mco",
      "Guam",
      "eth",
      "ner",
      "lao",
      "MH",
      "MK",
      "Tonga",
      "ML",
      "MN",
      "Western Sahara",
      "MO",
      "MP",
      "MQ",
      "MR",
      "MS",
      "MT",
      "MU",
      "MW",
      "MX",
      "MY",
      "Mauritius",
      "Norfolk Island",
      "spm",
      "nfk",
      "NA",
      "Sint Maarten (Dutch part)",
      "NC",
      "NE",
      "NF",
      "lbn",
      "NI",
      "lbr",
      "NL",
      "bol",
      "NO",
      "NP",
      "NR",
      "NU",
      "lca",
      "NZ",
      "Malta",
      "and",
      "Ireland",
      "rou",
      "cri",
      "Lithuania",
      "France",
      "mex",
      "srb",
      "kaz",
      "tto",
      "PA",
      "Nicaragua",
      "Norway",
      "PE",
      "PF",
      "PG",
      "Macao",
      "PH",
      "Uganda",
      "Mexico",
      "plw",
      "Suridescription",
      "PL",
      "PM",
      "Saint Helena",
      "Greenland",
      "PN",
      "PR",
      "Papua New Guinea",
      "PT",
      "PW",
      "nic",
      "PY",
      "Kazakhstan",
      "tun",
      "Åland Islands",
      "Bahamas",
      "tur",
      "brb",
      "tuv",
      "Marshall Islands",
      "Mali",
      "Panama",
      "jam",
      "niu",
      "Laos",
      "zaf",
      "brn",
      "Argentina",
      "png",
      "Zambia",
      "stp",
      "mhl",
      "Guinea-Bissau",
      "RE",
      "Macedonia, Former Yugoslav Republic of",
      "Namibia",
      "Georgia",
      "RO",
      "Saint Kitts and Nevis",
      "RS",
      "RW",
      "twn",
      "pol",
      "SB",
      "SC",
      "sur",
      "SE",
      "Sweden",
      "South Georgia and the South Sandwich Islands",
      "SG",
      "Malawi",
      "are",
      "SH",
      "ken",
      "Andorra",
      "SI",
      "arg",
      "Poland",
      "SK",
      "SL",
      "SM",
      "SN",
      "uzb",
      "Tunisia",
      "arm",
      "btn",
      "SR",
      "ST",
      "SV",
      "SX",
      "nld",
      "svk",
      "svn",
      "Tuvalu",
      "TC",
      "Lebanon",
      "Azerbaijan",
      "TF",
      "Czech Republic",
      "Mauritania",
      "TH",
      "Guernsey",
      "TJ",
      "TK",
      "TL",
      "asm",
      "TN",
      "TO",
      "Australia",
      "Bonaire",
      "TR",
      "swe",
      "TT",
      "TV",
      "TW",
      "TZ",
      "mkd",
      "lie",
      "dza",
      "UA",
      "Iceland",
      "ata",
      "Gabon",
      "UG",
      "atf",
      "atg",
      "Luxembourg",
      "UM",
      "Algeria",
      "tza",
      "Jersey",
      "Slovenia",
      "cxr",
      "US",
      "kgz",
      "jey",
      "Antigua and Barbuda",
      "UY",
      "UZ",
      "pri",
      "sxm",
      "VA",
      "mli",
      "bwa",
      "VC",
      "khm",
      "VG",
      "idn",
      "Vanuatu",
      "prt",
      "VI",
      "mlt",
      "cym",
      "pry",
      "VN",
      "Honduras",
      "Antarctica",
      "cyp",
      "Nauru",
      "syc",
      "rwa",
      "aus",
      "VU",
      "aut",
      "Singapore",
      "lka",
      "French Guiana",
      "gab",
      "Christmas Island",
      "cze",
      "WF",
      "nor",
      "Netherlands",
      "China",
      "Martinique",
      "WS",
      "Saint Pierre and Miquelon",
      "Bhutan",
      "Romania",
      "mne",
      "Philippines",
      "mng",
      "npl",
      "mnp",
      "gbr",
      "British Virgin Islands",
      "British Indian Ocean Territory",
      "Montenegro",
      "Indonesia",
      "Angola",
      "Brunei Darussalam",
      "New Caledonia",
      "Cayman Islands",
      "Greece",
      "Guyana",
      "YT",
      "Chile",
      "Nepal",
      "Isle of Man",
      "Ukraine",
      "ZA",
      "vat",
      "Anguilla",
      "nru",
      "ZM",
      "Turkey",
      "Belgium",
      "South Africa",
      "Trinidad and Tobago",
      "Jamaica",
      "Peru",
      "Tokelau",
      "Hong Kong",
      "Pitcairn Islands",
      "Thailand",
      "aze",
      "geo",
      "Kuwait",
      "Croatia",
      "kna",
      "Cook Islands",
      "Uruguay",
      "Sri Lanka",
      "vct",
      "United Kingdom",
      "mrt",
      "Liberia",
      "Burkina Faso",
      "Saint Barthélemy",
      "pyf",
      "Wallis and Futuna",
      "msr",
      "zmb",
      "kor",
      "South Korea",
      "Austria",
      "Monaco",
      "ecu",
      "ggy",
      "gha",
      "Réunion",
      "Hungary",
      "Japan",
      "mtq",
      "Albania",
      "New Zealand",
      "Senegal",
      "Ethiopia",
      "gib",
      "hkg",
      "mus",
      "Sierra Leone",
      "imn",
      "Bolivia",
      "gin",
      "vgb",
      "can",
      "tca",
      "Gambia",
      "Slovakia",
      "Serbia",
      "Bosnia and Herzegovina",
      "ind",
      "Niger",
      "Rwanda",
      "jpn",
      "French Southern Territories",
      "ltu",
      "Barbados",
      "Botswana",
      "Saint Vincent and the Grenadines",
      "Dominican Republic",
      "Denmark",
      "mwi",
    ],
    supportsDecimals: true,
  },
  UYU: {
    code: "UYU",
    value: "$U",
    description: "Uruguayan peso",
    countryKeywords: ["UYU", "UY", "Uruguay", "ury"],
    supportsDecimals: true,
  },
  UYW: {
    code: "UYW",
    value: "UYW",
    description: "UYW",
    countryKeywords: [],
    supportsDecimals: true,
  },
  UZS: {
    code: "UZS",
    value: "UZS",
    description: "Uzbekistani som",
    countryKeywords: ["UZ", "Uzbekistan", "uzb", "UZS"],
    supportsDecimals: true,
  },
  VES: {
    code: "VES",
    value: "Bs.",
    description: "VES",
    countryKeywords: [],
    supportsDecimals: true,
  },
  VND: {
    code: "VND",
    value: "VND",
    description: "Vietdescriptionse dong",
    countryKeywords: ["Vietnam", "vnm", "VN", "VND"],
    supportsDecimals: false,
  },
  VUV: {
    code: "VUV",
    value: "VUV",
    description: "Vanuatu vatu",
    countryKeywords: ["Vanuatu", "vut", "VUV", "VU"],
    supportsDecimals: false,
  },
  WST: {
    code: "WST",
    value: "WST",
    description: "Samoan tala",
    countryKeywords: ["wsm", "WST", "WS", "Samoa"],
    supportsDecimals: true,
  },
  XAF: {
    code: "XAF",
    value: "XAF",
    description: "Central African CFA franc",
    countryKeywords: [
      "CF",
      "CG",
      "Cameroone",
      "Chad",
      "gab",
      "caf",
      "cmr",
      "CM",
      "GQ",
      "gnq",
      "Equatorial Guinea",
      "Congo",
      "TD",
      "Gabon",
      "tcd",
      "GA",
      "cog",
      "Central African Republic",
      "XAF",
    ],
    supportsDecimals: false,
  },
  XCD: {
    code: "XCD",
    value: "$",
    description: "East Caribbean dollar",
    countryKeywords: [
      "DM",
      "lca",
      "msr",
      "Grenada",
      "Montserrat",
      "atg",
      "grd",
      "GD",
      "kna",
      "Antigua and Barbuda",
      "KN",
      "MS",
      "AG",
      "vct",
      "AI",
      "Anguilla",
      "VC",
      "Saint Lucia",
      "Saint Vincent and the Grenadines",
      "LC",
      "XCD",
      "aia",
      "dma",
      "Saint Kitts and Nevis",
      "Dominica",
    ],
    supportsDecimals: true,
  },
  XOF: {
    code: "XOF",
    value: "XOF",
    description: "West African CFA franc",
    countryKeywords: [
      "Benin",
      "BF",
      "BJ",
      "nga",
      "ben",
      "civ",
      "Mali",
      "gnb",
      "ner",
      "SN",
      "Nigeria",
      "bfa",
      "tgo",
      "sen",
      "ML",
      "Togo",
      "Niger",
      "CI",
      "Côte d'Ivoire",
      "mli",
      "XOF",
      "GW",
      "Guinea-Bissau",
      "Burkina Faso",
      "TG",
      "Senegal",
      "NE",
      "NG",
    ],
    supportsDecimals: false,
  },
  XPF: {
    code: "XPF",
    value: "XPF",
    description: "CFP franc",
    countryKeywords: [
      "wlf",
      "NC",
      "PF",
      "ncl",
      "pyf",
      "Wallis and Futuna",
      "New Caledonia",
      "XPF",
      "WF",
      "French Polynesia",
    ],
    supportsDecimals: false,
  },
  YER: {
    code: "YER",
    value: "﷼",
    description: "Yemeni rial",
    countryKeywords: ["yem", "YER", "YE", "Yemen"],
    supportsDecimals: true,
  },
  ZAR: {
    code: "ZAR",
    value: "R",
    description: "South African rand",
    countryKeywords: ["ZA", "South Africa", "ZAR", "zaf"],
    supportsDecimals: true,
  },
  ZMW: {
    code: "ZMW",
    value: "ZMW",
    description: "Zambian kwacha",
    countryKeywords: ["ZM", "ZMW", "Zambia", "zmb"],
    supportsDecimals: true,
  },
};

export { Currencies, AllCurrencies };
