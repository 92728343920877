import React from "react";
import Slider from "@mui/material/Slider";
import Cropper from "react-easy-crop";
import "./image_cropper.scss";
import Button from "@mui/material/Button";
import getCroppedImg from "./canvasUtils";
const minZoom = 0.4;
import ButtonMui from "../../../../components/buttons/buttonMui/buttonMui";

class ImageCropper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageSrc: null,
      crop: { x: 0, y: 0 },
      zoom: minZoom,
      aspect: 3 / 2,
      croppedAreaPixels: null,
      croppedImage: null,
    };
  }

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedArea, croppedAreaPixels });
  };

  onZoomChange = (zoom) => {
    if (zoom >= minZoom) {
      this.setState({ zoom });
    }
  };

  showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        this.props.img,
        this.state.croppedAreaPixels,
        this.props.imageType,
      );
      this.props.changeImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    return (
      <div className="Crop">
        <div className="crop-container">
          <Cropper
            minZoom={minZoom}
            image={this.props.img}
            crop={this.state.crop}
            zoomWithScroll={false}
            zoom={this.state.zoom}
            aspect={this.state.aspect}
            restrictPosition={false}
            onCropChange={this.onCropChange}
            onCropComplete={this.onCropComplete}
            onZoomChange={this.onZoomChange}
            cropSize={{ height: 150, width: 150 }}
          />
        </div>
        <div className="controls">
          <div className="spssic_cropper_actions">
            <Slider
              value={this.state.zoom}
              min={minZoom}
              max={4}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e, zoom) => this.onZoomChange(zoom)}
              classes={{ container: "slider" }}
            />
            <ButtonMui
              width={170}
              style={{ height: "34px" }}
              label="Crop & Save"
              variant={"outlined"}
              onClick={this.showCroppedImage}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ImageCropper;
