const toggleChatBot = (e) => {
	let styleElem = document.getElementById('style_chat_bot');
	if (styleElem.disabled) {
		styleElem.disabled = false;
		e.stopPropagation();
	} else {
		if (window.HubSpotConversations && window.HubSpotConversations.widget) {
			window.HubSpotConversations.widget.open()
			styleElem.disabled = true;
		}
	}
}

export {toggleChatBot}