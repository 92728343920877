import React from "react";
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-dragdata";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bubble } from "react-chartjs-2";
import PropTypes from "prop-types";
import { DataTypes } from "../ChartComponents/DataTypes";

import {
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import HorizontalClusteredBarChart from "./HorizontalClusteredBarChart";

ChartJS.register(
  CategoryScale,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
);

class BubbleChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        labels: [],
        datasets: [],
      },
      options: this.buildOptions(this.props.chartOptions),
    };

    this.ref = null;
  }

  componentDidMount() {
    this.buildData();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps != this.props && !this.imgSrc) {
      this.ref.options = this.buildOptions(nextProps.chartOptions);
      this.buildData();
      this.ref.update();
    }

    return true;
  }

  buildOptions = (props) => {
    if (this.props.preview && this.props.businessPlan) {
      if (window.chartsForRender.indexOf(this.buildOptions) === -1) {
        window.chartsForRender.push(this.buildOptions);
      }
    }

    let options = {
      type: "bubble",
      devicePixelRatio: 2.0,
      maintainAspectRatio: props.lockedAspect ? props.lockedAspect : false,
      layout: {
        padding: {
          top: props.showTitle || props.showSubtitle ? 5 : 50,
          right: 60,
        },
      },
      animation: {
        duration: 1,
        onComplete: (animation) => {
          if (this.props.preview && this.props.businessPlan && this.ref) {
            this.imgSrc = this.ref.toBase64Image();
            this.forceUpdate(() => {
              window.amountOfChartsRendered++;
              global.Modeliks.NotifySubsctiptions("onChartRenderFinish");
            });
          }
        },
      },
      scales: {
        x: {
          beginAtZero: true,
          grid: {
            display: props.xGrid ? props.xGrid : false,
            drawBorder: props.xGrid ? props.xGrid : true,
            borderWidth: 2,
          },
          ticks: {
            display: props.showHorizontalAxis,
            font: {
              weight: 400,
              style: "normal",
              size: props.axisFontSize,
              lineHeight: "160%",
            },
          },
        },
        y: {
          beginAtZero: true,
          grid: {
            display: props.yGrid ? props.yGrid : false,
            drawBorder: props.xGrid ? props.xGrid : false,
          },
          ticks: {
            display: props.showVerticalAxis,
            maxTicksLimit: 8,
            minTicksLimit: 8,
            font: {
              weight: 400,
              style: "normal",
              size: props.axisFontSize,
              lineHeight: "160%",
            },
            callback: function (value) {
              if (value >= 1000 && value < 1000000) {
                return value / 1000 + "K";
              } else if (value >= 1000000) {
                return value / 1000000 + "M";
              } else {
                return value;
              }
            },
          },
        },
      },
      plugins: {
        tooltip: {
          titleFont: {
            size: 14,
          },
          bodyFont: {
            size: 14,
          },
        },
        title: {
          display: props.showTitle ? props.showTitle : false,
          text:
            props.title && props.titleAlign && props.titleAlign === "start"
              ? props.title
              : props.title
                ? props.title
                : "",
          align: props.titleAlign ? props.titleAlign : "center",
          color: "#000000",

          padding: {
            bottom: props.showSubtitle && props.showSubtitle === true ? 0 : 45,
          },
          font: {
            size: props.titleFontSize,
          },
        },
        subtitle: {
          display: props.showSubtitle ? props.showSubtitle : false,
          text:
            props.subtitle && props.titleAlign && props.titleAlign === "start"
              ? props.subtitle
              : props.subtitle
                ? props.subtitle
                : "",
          align: props.titleAlign ? props.titleAlign : "center",
          padding: {
            bottom: 45,
          },
          font: {
            size: props.subTitleFontSize,
          },
        },
        datalabels: {
          anchor: "end",
          align: "end",
          formatter: function (value, ctx) {
            if (props.showDataLabels) {
              return (
                (props.dataType === "currency" ? DataTypes[props.dataType] : "") +
                value.r.toLocaleString(undefined, {
                  minimumFractionDigits: props.datalabelsDecimalPoints,
                  maximumFractionDigits: props.datalabelsDecimalPoints,
                }) +
                (props.dataType === "percent" ? DataTypes[props.dataType] : "")
              );
            } else {
              return "";
            }
          },
          color: "#252525",
          font: {
            size: props.dataLabelsFontSize,
            weight: "700",
            lineHeight: "17px",
          },
        },
        legend: {
          display: props.displayLegend ? props.displayLegend : false,
          position: props.legendPosition ? props.legendPosition : "bottom",
          align: "center",
          labels: {
            usePointStyle: true,
            fontSize: props.legendFontSize,
          },
        },
      },
    };

    return options;
  };

  buildData = () => {
    let labels = [];
    let datasets = [];
    let colors = this.props.colors;
    let rows = this.props.data;

    rows.forEach((row, index) => {
      if (index !== 0) {
        labels.push(row[0]);
      }
    });

    rows[0].forEach((ser, index) => {
      if (index !== 0) {
        let obj = {
          label: "",
          backgroundColor: "",
          borderColor: "",
          borderWidth: 2,
          clip: { left: false, top: false, right: false, bottom: false },
          data: [],
        };
        obj.label = ser;
        datasets.push(obj);
      }
    });

    for (let i = 1; i < rows[0].length; i++) {
      if (i - 1 >= colors.length) {
        colors = [...colors, ...colors];
        this.props.changeColors(colors);
      }
      let point = [{ x: "", y: "", r: "" }];
      rows.forEach((row, index) => {
        if (index !== 0) {
          if (index === 1) {
            point[0].x = parseFloat(row[i]);
          } else if (index === 2) {
            point[0].y = parseFloat(row[i]);
          } else if (index === 3) {
            point[0].r = parseFloat(row[i]);
          }
        }
      });
      datasets[i - 1].data = point;
      datasets[i - 1].backgroundColor = colors[i - 1];
      datasets[i - 1].borderColor = colors[i - 1];
    }

    this.state.data.datasets = datasets;
    this.state.data.labels = labels;
  };

  setRef = (ref) => {
    this.ref = ref;
  };

  render() {
    if (this.imgSrc) {
      return <img src={this.imgSrc} style={{ height: "100%", width: "100%" }} />;
    }
    return (
      <Bubble
        type={"bubble"}
        data={this.state.data}
        options={this.state.options}
        ref={this.setRef}
        style={{
          background: this.props.chartOptions.backgroundColor,
          border: `2px solid ${this.props.chartOptions.borderColor}`,
        }}
      />
    );
  }
}

BubbleChart.config = {
  data: [
    ["Value column", "ser1", "ser2", "ser3"],
    ["X-Values", "1", "2", "3"],
    ["Y-Values", "1", "2", "3"],
    ["Size", "5", "7", "9"],
  ],
  colors: ["red", "green", "blue", "yellow"],
  dataGridConfig: {
    title: "blabla",
    subtitle: "blabla",
    canAddRows: false,
    canAddColumns: true,
    hasColumnHeaders: true,
    hasRowHeaders: true,
    ColumnHeader: "New series",
    rowSeries: true,
  },
  dataLalbelsConfig: {
    hideSeries: false,
    dataLabelsOptions: false,
  },
  legendAndGridlinesConfig: {
    hasGridlines: true,
  },
  gapWidthConfig: {
    haveGapWidth: false,
  },
};

BubbleChart.propTypes = {
  data: PropTypes.array.isRequired,
  colors: PropTypes.array.isRequired,
};

export default BubbleChart;
