import React from "react";
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-dragdata";
import ChartDataLabels from "chartjs-plugin-datalabels";

import {
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { InputFormats } from "../constants/finance";
import ChartHelper from "../../pages/Secure/Financials/components/Charts/ChartHelpers";
import { UnitTypes } from "../../data/Finance/CalculatedDriver/constants";

ChartJS.register(
  CategoryScale,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
);

class UnitSalesChart extends React.Component {
  constructor(props) {
    super(props);
    ChartJS.defaults.font.family = "Inter";
    ChartJS.defaults.isItalic = props.isItalic;
    ChartJS.defaults.plugins.datalabels.font.style = props.isItalic ? "italic" : "normal";
    this.state = {
      data: {
        labels: props.labels,
        datasets: [
          {
            // label: 'Rainfall',
            backgroundColor: props.color ? props.color : "#80D7FE",
            barPercentage: 0.7,
            maxBarThickness: 50,
            minBarLength: 2,
            borderRadius: 5,
            data: props.data,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        type: "bar",
        barRoundness: 0.3,
        parsing: {
          xAxisKey: "DateHeader",
          yAxisKey: "Value",
        },
        layout: {
          boxSizing: "border-box",
          width: "100%",
          padding: {
            top: 40,
            right: 20,
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              color: "#889299",
              font: {
                weight: 400,
                style: "normal",
                size: 14,
                lineHeight: "160%",
              },
            },
            // stacked: true
          },
          y: {
            grid: {
              drawBorder: false,
              display: false,
            },
            ticks: {
              display: false,
              maxTicksLimit: 4,
              color: "#889299",
              font: {
                weight: 400,
                style: "normal",
                size: 10,
                lineHeight: "160%",
              },
              callback: function (value) {
                if (value >= 1000 && value < 1000000) {
                  return (value / 1000).toFixed(2) + "K";
                } else if (value >= 1000000) {
                  return (value / 1000000).toFixed(2) + "M";
                } else {
                  return value.toFixed(2);
                }
              },
              stepSize: 750000,
            },
          },
        },
        plugins: {
          dragData: {
            round: 0,
            showTooltip: true,
            onDragStart: (e) => {
              return this.canChangeValue();
            },
            onDrag: (e, datasetIndex, index, value) => {
              e.target.style.cursor = "grabbing";
            },
            onDragEnd: (e, datasetIndex, index, value) => {
              // console.log(datasetIndex);

              e.target.style.cursor = "default";
              props.changeValue(index, value);
              if (props.renderValues) {
                props.renderValues();
              }
              // console.log(datasetIndex, index, value)
            },
          },
          datalabels: {
            anchor: "end",
            align: "top",
            textAlign: "center",
            rotation: (ctx) => {
              let IsAnnualChart = true;
              if (ctx.dataset.data.length > 5) {
                IsAnnualChart = false;
              }

              if (!IsAnnualChart && ctx.chart.width < 1080) {
                return -20;
              }

              if (IsAnnualChart && ctx.chart.width < 341) {
                return -20;
              }

              return 0;
            },
            formatter: function (value, ctx) {
              if (!props.dummyValues) {
                let newval = "";

                if (typeof value !== "object") {
                  if (!isNaN(value)) {
                    newval = value;
                  }
                } else {
                  if (!isNaN(props.data[ctx.dataIndex].Value)) {
                    newval = props.data[ctx.dataIndex].Value;
                  }
                }

                if (props.valueLabelsTotal) {
                  newval =
                    `${props.valueLabelsTotal.UnitType === UnitTypes.Percentage ? "%" : global.Modeliks.CompanyInfo.Currency.value}${ChartHelper.setTextOverflow(InputFormats.formatNumber(props.valueLabelsTotal[ctx.dataIndex].Value), ctx)}` +
                    "\n" +
                    ChartHelper.setTextOverflow(
                      InputFormats.formatNumber(newval, props.displayOfDecimals),
                      ctx,
                    );
                } else {
                  newval = ChartHelper.setTextOverflow(
                    InputFormats.formatNumber(newval, props.displayOfDecimals),
                    ctx,
                  );
                }

                if (newval !== "") {
                  return (
                    (props.unit === "%" ? "" : props.unit) +
                    newval +
                    (props.unit !== "%" ? "" : props.unit)
                  );
                } else {
                  return "";
                }
              } else {
                return "";
              }
            },
            color: "#252525",
            font: function (context, text = this) {
              let width = context.chart.width;
              let size = Math.round(width / (width > 700 ? 120 : 38));
              let min = 9;
              let max = 14;

              if (size < min) {
                size = min;
              }

              if (size > max) {
                size = max;
              }

              return {
                weight: "bold",
                size: size,
                style: `${ChartJS.defaults.isItalic ? "italic" : "normal"}`,
              };
            },
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                let label = context.dataset.label || "";

                if (label) {
                  label += ": ";
                }
                if (context.parsed.y !== null) {
                  label +=
                    (props.unit ? props.unit : "") +
                    InputFormats.formatNumber(context.parsed.y, props.displayOfDecimals);
                }
                return label;
              },
            },
          },
          legend: {
            display: false,
          },
        },
      },
    };
  }

  canChangeValue = () => {
    return this.props.changeValueEnabled;
  };

  componentWillReceiveProps(nextProps, nextState) {
    if (this.state != nextState) {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          datasets: [
            {
              ...prevState.data.datasets[0],
              data: nextProps.field
                ? nextProps.data.map((c) => c[nextProps.field])
                : nextProps.data,
            },
          ],
        },
        options: {
          ...prevState.options,
          plugins: {
            ...prevState.options.plugins,
            datalabels: {
              anchor: "end",
              align: "top",
              textAlign: "center",
              formatter: function (value, ctx) {
                if (!nextProps.dummyValues) {
                  let newval = "";
                  if (typeof value !== "object") {
                    newval = value;
                  } else {
                    newval = nextProps.data[ctx.dataIndex].Value;
                  }
                  if (nextProps.valueLabelsTotal) {
                    newval =
                      `${nextProps.valueLabelsTotal.UnitType === UnitTypes.Percentage ? "%" : global.Modeliks.CompanyInfo.Currency.value}${ChartHelper.setTextOverflow(InputFormats.formatNumber(nextProps.valueLabelsTotal[ctx.dataIndex].Value, nextProps.displayOfDecimals), ctx)}` +
                      "\n" +
                      ChartHelper.setTextOverflow(
                        InputFormats.formatNumber(newval, nextProps.displayOfDecimals),
                        ctx,
                      );
                  } else {
                    newval = ChartHelper.setTextOverflow(
                      InputFormats.formatNumber(newval, nextProps.displayOfDecimals),
                      ctx,
                    );
                  }

                  if (newval !== "" && newval !== null) {
                    return (
                      (nextProps.unit === "%" ? "" : nextProps.unit) +
                      newval +
                      (nextProps.unit !== "%" ? "" : nextProps.unit)
                    );
                  } else {
                    return "";
                  }
                } else {
                  return "";
                }
              },
              color: "#252525",
              font: function (context, text = this) {
                let width = context.chart.width;
                let size = Math.round(width / (width > 700 ? 120 : 38));
                let min = 9;
                let max = 14;

                if (size < min) {
                  size = min;
                }

                if (size > max) {
                  size = max;
                }

                return {
                  weight: "bold",
                  size: size,
                  style: `${ChartJS.defaults.isItalic ? "italic" : "normal"}`,
                };
              },
            },
          },
        },
      }));
    }
  }

  render() {
    return (
      <Bar
        type={"bar"}
        data={this.state.data}
        options={this.state.options}
        height={this.props.height ? this.props.height : "60"}
        width={"100%"}
      />
    );
  }
}

export default UnitSalesChart;
