import ReactDOM from "react-dom";

const FormatPainterPortal = ({ children, visible }) => {
  const el = document.getElementById("format_painter");
  if (el) {
    return ReactDOM.createPortal(children, el);
  } else {
    return null;
  }
  return ReactDOM.createPortal(children, el);
};

export default FormatPainterPortal;
