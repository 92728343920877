import React from "react";
import styled from "styled-components";
import { Theme } from "../../../theme/styles/theme";
import PropTypes from "prop-types";
import { IconI } from "../../icons/svgIcons";

const StyledLabel = styled.label`
  font-family: Inter;
  ${(props) => props.size && LabelSize[props.size]};
  color: ${(props) => (props.color ? props.color : "black")};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "15px")};
  cursor: ${(props) => (props.pointer ? "pointer" : "")};
`;

const LabelSize = {
  smalll: Theme.text.paragraph.p3,
  small: Theme.text.paragraph.p3_strong,
  medium: Theme.text.paragraph.p2_strong,
  large: Theme.text.paragraph.p1_strong,
  label1: Theme.text.label.label1,
  label2: Theme.text.label.label2,
  label3: Theme.text.label.label3,
  label4: Theme.text.label.label4,
  label_strong: Theme.text.label.label_strong,
};

export const Label = ({
  color,
  size,
  label,
  hasInfoIcon,
  onClick,
  style,
  pointer,
  className,
  ...props
}) => {
  return (
    <StyledLabel
      className={className}
      style={style}
      size={size}
      color={color}
      pointer={pointer}
      marginLeft={props.marginLeft}
      onClick={onClick}
    >
      {label}
      {hasInfoIcon && <IconI />}
    </StyledLabel>
  );
};

Label.propTypes = {
  label: PropTypes.string,
  hasInfoIcon: PropTypes.bool,
  size: PropTypes.oneOf(["small", "medium", "large", "label3", "label2"]),
  style: PropTypes.object,
};
