import PropTypes from "prop-types";
import React, { PureComponent, useState } from "react";
import { getLength, getAngle, getCursor } from "../utils";
import { Rotate, Move, RotateWithArrow } from "../../components/IconButtons/SubMenuIcons";
import StyledRect from "./StyledRect";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import RotateImage from "../../components/IconButtons/images/Rotate.png";

const zoomableMap = {
  n: "t",
  s: "b",
  e: "r",
  w: "l",
  ne: "tr",
  nw: "tl",
  se: "br",
  sw: "bl",
};

export default class Rect extends PureComponent {
  static propTypes = {
    styles: PropTypes.object,
    zoomable: PropTypes.string,
    rotatable: PropTypes.bool,
    moveHandle: PropTypes.bool,
    onDoubleClick: PropTypes.func,
    onResizeStart: PropTypes.func,
    onResize: PropTypes.func,
    onResizeEnd: PropTypes.func,
    onRotateStart: PropTypes.func,
    onRotate: PropTypes.func,
    onRotateEnd: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrag: PropTypes.func,
    onDragEnd: PropTypes.func,
    onDelete: PropTypes.func,
    parentRotateAngle: PropTypes.number,
    publishMode: PropTypes.bool,
    reference: PropTypes.any,
  };

  constructor() {
    super();
    this.state = {
      rotate: false,
    };
  }

  setElementRef = (ref) => {
    this.$element = ref;
    if (this.props.reference) {
      this.props.reference(ref);
    }
  };

  // Drag
  startDrag = (e) => {
    if (e.buttons !== 1) {
      return;
    }
    e.stopPropagation();
    this.props.onDragStart && this.props.onDragStart(e);
    if (!this.props.className.includes("sp_cp_mainpanel_bp")) {
      let elemRect = this.$element.getBoundingClientRect();
      let SlideComp = document.getElementById("scale_panel_container").getBoundingClientRect();
      let { clientX: startX, clientY: startY } = e;
      this._isMouseDown = true;
      let leftFunc = () => elemRect.left;
      let topFunc = () => elemRect.top;
      if (
        this.props.styles.transform.rotateAngle !== 0 &&
        this.props.styles.transform.rotateAngle !== 180
      ) {
        leftFunc = () =>
          (elemRect.width -
            Number(getComputedStyle(this.$element)["width"].slice(0, -2)) * window.panelScale) /
            2 +
          elemRect.left;
        topFunc = () =>
          (elemRect.height -
            Number(getComputedStyle(this.$element)["height"].slice(0, -2)) * window.panelScale) /
            2 +
          elemRect.top;
      }
      const onMove = (e) => {
        let computedLeft = leftFunc();
        let computedTop = topFunc();
        if (!this._isMouseDown) return; // patch: fix windows press win key during mouseup issue
        e.stopImmediatePropagation();
        const { clientX, clientY } = e;
        const deltaX = clientX - SlideComp.left - (startX - computedLeft);
        const deltaY = clientY - SlideComp.top - (startY - computedTop);
        this.props.onDrag(deltaX, deltaY, computedLeft, computedTop);
      };
      const onUp = () => {
        document.removeEventListener("mousemove", onMove);
        document.removeEventListener("mouseup", onUp);
        if (!this._isMouseDown) return;
        this._isMouseDown = false;
        this.props.onDragEnd && this.props.onDragEnd();
      };
      document.addEventListener("mousemove", onMove);
      document.addEventListener("mouseup", onUp);
    }
  };

  // Rotate
  startRotate = (e) => {
    if (e.button !== 0) return;
    const { clientX, clientY } = e;
    const {
      styles: {
        transform: { rotateAngle: startAngle, scaleX: scaleX, scaleY: scaleY },
      },
    } = this.props;
    const rect = this.$element.getBoundingClientRect();
    const center = {
      x: rect.left + rect.width / 2,
      y: rect.top + rect.height / 2,
    };
    const startVector = {
      x: clientX - center.x,
      y: clientY - center.y,
    };
    this.props.onRotateStart && this.props.onRotateStart();
    this._isMouseDown = true;
    const onMove = (e) => {
      if (!this.state.rotate) {
        this.setState({ rotate: true });
      }
      if (!this._isMouseDown) return; // patch: fix windows press win key during mouseup issue
      e.stopImmediatePropagation();
      const { clientX, clientY } = e;
      const rotateVector = {
        x: clientX - center.x,
        y: clientY - center.y,
      };
      const angle = getAngle(startVector, rotateVector);
      this.props.onRotate(angle, startAngle, scaleX, scaleY);
    };
    const onUp = () => {
      if (this.state.rotate) {
        this.setState({ rotate: false });
      }
      document.removeEventListener("mousemove", onMove);
      document.removeEventListener("mouseup", onUp);
      if (!this._isMouseDown) return;
      this._isMouseDown = false;
      this.props.onRotateEnd && this.props.onRotateEnd();
    };
    document.addEventListener("mousemove", onMove);
    document.addEventListener("mouseup", onUp);
  };

  // Resize
  startResize = (e, cursor) => {
    const scaledRect = document.getElementById("se_scale_panel").getBoundingClientRect();
    const offsetMouse = 1 / window.panelScale;
    if (e.button !== 0) return;
    document.body.style.cursor = cursor;
    const {
      styles: {
        position: { centerX, centerY },
        size: { width, height },
        transform: { rotateAngle, scaleX, scaleY },
      },
    } = this.props;
    const { clientX: startX, clientY: startY } = e;
    const rect = { width, height, centerX, centerY, rotateAngle, scaleX, scaleY };
    const type = e.target.getAttribute("class").split(" ")[0];
    this.props.onResizeStart && this.props.onResizeStart();
    e.stopPropagation();
    this._isMouseDown = true;
    const onMove = (e) => {
      if (!this._isMouseDown) return; // patch: fix windows press win key during mouseup issue
      e.stopImmediatePropagation();
      const { clientX, clientY } = e;
      const deltaX = (clientX - startX) * offsetMouse;
      const deltaY = (clientY - startY) * offsetMouse;
      const alpha = Math.atan2(deltaY, deltaX);
      const deltaL = getLength(deltaX, deltaY);
      const isShiftKey = e.shiftKey;
      this.props.onResize(deltaL, alpha, rect, type, isShiftKey);
    };

    const onUp = () => {
      document.body.style.cursor = "auto";
      document.removeEventListener("mousemove", onMove);
      document.removeEventListener("mouseup", onUp);
      if (!this._isMouseDown) return;
      this._isMouseDown = false;
      this.props.onResizeEnd && this.props.onResizeEnd();
    };
    document.addEventListener("mousemove", onMove);
    document.addEventListener("mouseup", onUp);
  };

  render() {
    const {
      styles: {
        position: { centerX, centerY },
        size: { width, height },
        transform: { rotateAngle, scaleX, scaleY },
        zIndex: zIndex,
      },
      zoomable,
      rotatable,
      moveHandle,
      borderMove,
      parentRotateAngle,
      onDoubleClick,
      onClick,
      onDelete,
      id,
      minWidth,
      disableTopMoveHandle,
    } = this.props;
    const style = {
      width: Math.abs(width),
      height: typeof height === "number" ? Math.abs(height) : "auto",
      minWidth: Math.abs(minWidth),
      transform: `rotate(${rotateAngle}deg) scaleX(${scaleX}) scaleY(${scaleY})`,
      left: centerX - Math.abs(width) / 2,
      top: typeof height === "number" ? centerY - Math.abs(height) / 2 : undefined,
      zIndex: zIndex,
    };

    const direction = zoomable
      .split(",")
      .map((d) => d.trim())
      .filter((d) => d); // TODO: may be speed up
    if (!this.props.publishMode && !this.props.preview) {
      return (
        <StyledRect
          id={id}
          ref={this.setElementRef}
          onMouseDown={moveHandle ? null : this.startDrag}
          onDoubleClick={onDoubleClick}
          onClick={onClick}
          className={"rect single-resizer " + this.props.className}
          style={style}
        >
          {this.props.children}
          {/*{onDelete &&*/}
          {/*<div className="rotate" onClick={onDelete} style={{marginLeft: '-40px'}}>*/}
          {/*<DeleteForeverIcon style={{height: 20, fill: '#00C4CC'}}/>*/}
          {/*</div>*/}
          {/*}*/}

          {moveHandle && (
            <>
              {!disableTopMoveHandle && (
                <div
                  className="rotate"
                  onMouseDown={this.startDrag}
                  onDragStart={(event) => event.preventDefault()}
                  style={{ marginLeft: "3px", cursor: "move" }}
                >
                  <Move />
                </div>
              )}
              {borderMove && (
                <>
                  <div
                    className="move_border_t"
                    onMouseDown={this.startDrag}
                    onDragStart={(event) => event.preventDefault()}
                  />
                  <div
                    className="move_border_r"
                    onMouseDown={this.startDrag}
                    onDragStart={(event) => event.preventDefault()}
                  />
                  <div
                    className="move_border_b"
                    onMouseDown={this.startDrag}
                    onDragStart={(event) => event.preventDefault()}
                  />
                  <div
                    className="move_border_l"
                    onMouseDown={this.startDrag}
                    onDragStart={(event) => event.preventDefault()}
                  />
                </>
              )}
            </>
          )}
          {rotatable && (
            <div className="rotate" onMouseDown={this.startRotate} style={{ marginLeft: "-27px" }}>
              {this.state.rotate && (
                <span
                  style={{
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "10px",
                    lineHeight: "160%",
                  }}
                  id="RotateTooltip"
                >
                  {rotateAngle}
                </span>
              )}
              {this.state.rotate ? <RotateWithArrow rotate={rotateAngle} /> : <Rotate />}
              {/*<Rotate />*/}
            </div>
          )}

          {direction.map((d) => {
            const cursor = `${getCursor(rotateAngle + parentRotateAngle, d)}-resize`;
            return (
              <div
                key={d}
                style={{ cursor }}
                className={`${zoomableMap[d]} resizable-handler`}
                onMouseDown={(e) => this.startResize(e, cursor)}
              />
            );
          })}

          {/*{*/}
          {/*    direction.map(d => {*/}
          {/*        let curClass = this.props.className;*/}
          {/*        let classSelected = 'sp_cp_mainpanel sp_cp_mainpanel_selected sp_cp_mainpanel_autoheight';*/}
          {/*        let typeClass = d.length === 2 ? 'circle' : (d === 's' || d === 'n' ? 'squareRotated': 'square');*/}

          {/*        return (*/}
          {/*            <div key={d} onMouseDown={(e)=>this.startResize(e)} className={`${zoomableMap[d]} ${curClass.includes('selected') ? typeClass : 'square'}`}/>*/}
          {/*        )*/}
          {/*    })*/}
          {/*}*/}
        </StyledRect>
      );
    } else {
      return (
        <div className={this.props.className} style={{ ...style }}>
          {this.props.children}
        </div>
      );
    }
  }
}
