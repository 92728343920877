import React from "react";
import Input from "../../../../../components/actions/Input";
import AddDialog from "../../../../../components/dialogs/financials/AddDialog/AddDialog";
import { AddDialogTitle } from "../../../../../components/dialogs/financials/AddDialog/AddDialogTitle";
import { AddDialogContent } from "../../../../../components/dialogs/financials/AddDialog/AddDialogContent";
import CircleCheckboxM from "../../../../../components/actions/CircleCheckboxM";
import MaterialSelect from "../../../../../components/actions/SelectM";
import { ExpenseTypes, ExpenseValues } from "../../../../../data/Finance/constants";
import "./AddExpense.scss";
import ButtonsActions from "../../../../../components/dialogs/financials/AddDialog/ButtonsActions";
import Personnel from "../../../../../data/Finance/Personnel";
import Expense from "../../../../../data/Finance/Expense";

class AddExpense extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      showSlectedRevenueError: false,
      slectedRevenueError: null,
    };
  }

  validate = () => {
    let expenseName = this.props.expense.Name;
    let NameError = null;
    let showNameError = false;

    let slectedRevenue = this.props.expense.ID_Revenue;
    let slectedRevenueError = null;
    let showSlectedRevenueError = false;

    if (expenseName === "" && expenseName.length < 3) {
      NameError = "Name is required min 3 characters";
      showNameError = true;
    }

    if (!slectedRevenue && this.props.expense.ExpenseMetric === ExpenseValues.SpecificStream) {
      slectedRevenueError = "Select revenue stream";
      showSlectedRevenueError = true;
    }

    if (NameError !== null || slectedRevenueError !== null) {
      this.setState({
        NameError: NameError,
        showNameError,
        slectedRevenueError: slectedRevenueError,
        showSlectedRevenueError,
      });
      return false;
    }
    return true;
  };

  onNextClick = (error = null, expense) => {
    const isValid = this.validate();
    if (isValid && !error) {
      if (this.checkStream()) {
        this.props.changeExpense();
      } else {
        this.props.onNextClick();
      }
    }
  };

  onChangeExpenseType = (e) => {
    this.props.expense.ExpenseType = e.target.value;
    this.forceUpdate();
  };

  onChangeExpenseMetrics = (e) => {
    if (e.target.value !== ExpenseValues.SpecificStream && this.props.expense.ID_Revenue) {
      this.props.expense.ID_Revenue = null;
    }
    this.props.expense.ExpenseMetric = e.target.value;
    this.forceUpdate();
  };

  onChangeRevenueStream = (e) => {
    this.props.expense.ID_Revenue = e.target.value;
    this.forceUpdate();
  };

  handleChangeName = (e) => {
    this.props.expense.Name = e.target.value;
    this.forceUpdate();
  };
  checkStream = () => {
    if (this.props.expense.db_record) {
      return (
        this.props.expense.db_record.ExpenseMetric !== this.props.expense.ExpenseMetric ||
        this.props.expense.db_record.ID_Revenue !== this.props.expense.ID_Revenue
      );
    } else {
      return true;
    }
  };

  converter = (callBack) => {
    return callBack(Expense.createNew(this.props.expense));
  };

  render() {
    return (
      <>
        <AddDialog open={this.props.open} onClose={this.props.onClose}>
          <AddDialogTitle title="Expenses Info" onClose={this.props.onClose} />
          <AddDialogContent closeOnEscapeClick={true} onClose={this.props.onClose}>
            <div className={"content_container"}>
              <div className="subtitle">Name of expense</div>
              <div className="input_container">
                <Input
                  validateSpecialChar={true}
                  max={45}
                  id="expensesNameInput"
                  value={this.props.expense.Name}
                  error={this.state.showNameError}
                  errorMessage={this.state.showNameError && this.state.NameError}
                  name="Name"
                  onChange={this.handleChangeName}
                  placeholder="Please enter..."
                />
              </div>
              <div className="status_title">Expense function</div>
              <div className="checkbox_container">
                <CircleCheckboxM
                  size="medium"
                  value={ExpenseTypes.SalesAndMarketing}
                  checked={this.props.expense.ExpenseType === ExpenseTypes.SalesAndMarketing}
                  onChange={this.onChangeExpenseType}
                  label="Sales and marketing"
                />
                <CircleCheckboxM
                  size="medium"
                  value={ExpenseTypes.ResearchAndDevelopment}
                  checked={this.props.expense.ExpenseType === ExpenseTypes.ResearchAndDevelopment}
                  onChange={this.onChangeExpenseType}
                  label="Research and development"
                />
                <CircleCheckboxM
                  size="medium"
                  value={ExpenseTypes.GeneralAndAdministrative}
                  checked={this.props.expense.ExpenseType === ExpenseTypes.GeneralAndAdministrative}
                  onChange={this.onChangeExpenseType}
                  label="General and administrative"
                />
                <CircleCheckboxM
                  size="medium"
                  value={ExpenseTypes.DirectCost}
                  checked={this.props.expense.ExpenseType === ExpenseTypes.DirectCost}
                  onChange={this.onChangeExpenseType}
                  label="Direct cost (part of Cost of Goods Sold)"
                />
              </div>

              <div className={"status_title"}>How will you enter value for this expense?</div>
              <div className={"checkbox_container_2"}>
                <CircleCheckboxM
                  size="medium"
                  disabled={!this.props.expense.isNew}
                  value={ExpenseValues.Value}
                  checked={this.props.expense.ExpenseMetric === ExpenseValues.Value}
                  onChange={this.onChangeExpenseMetrics}
                  label="$ value"
                />
                <CircleCheckboxM
                  size="medium"
                  disabled={!this.props.expense.isNew}
                  value={ExpenseValues.Totals}
                  checked={this.props.expense.ExpenseMetric === ExpenseValues.Totals}
                  onChange={this.onChangeExpenseMetrics}
                  label="% of total revenue"
                />

                <div className="checkbox_row">
                  <CircleCheckboxM
                    disabled={!this.props.expense.isNew}
                    size="medium"
                    value={ExpenseValues.SpecificStream}
                    checked={this.props.expense.ExpenseMetric === ExpenseValues.SpecificStream}
                    onChange={this.onChangeExpenseMetrics}
                    label="% of specific revenue stream"
                  />
                  <MaterialSelect
                    width="227px"
                    label="Select revenue stream"
                    onChange={this.onChangeRevenueStream}
                    valuePlaceholder="ID"
                    disabled={this.props.expense.ExpenseMetric !== ExpenseValues.SpecificStream}
                    error={this.state.showSlectedRevenueError}
                    errorMessage={
                      this.state.showSlectedRevenueError && this.state.slectedRevenueError
                    }
                    valueDescription="RevenueName"
                    value={this.props.expense.ID_Revenue ? this.props.expense.ID_Revenue : ""}
                    options={this.props.RevenueStreams}
                  />
                </div>
              </div>
            </div>
          </AddDialogContent>
          <ButtonsActions
            onNextClick={this.onNextClick}
            close={this.props.onClose}
            enableDelete={false}
            convertNone={true}
            converter={this.converter}
            checkStream={this.checkStream}
            isDeletedRecord={this.props.isDeletedRecord}
            RevenueName={this.props.expense.Name}
            stream={this.props.expense}
            deleteName="Expense"
          />
        </AddDialog>
      </>
    );
  }
}

export default AddExpense;
