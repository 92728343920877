import React from "react";

export default function (ComposedComponent) {
  class NetworkDetector extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isDisconnected: false,
      };
    }

    componentDidMount() {
      this.handleConnectionChange();
      window.addEventListener("online", this.handleConnectionChange);
      window.addEventListener("offline", this.handleConnectionChange);
    }

    componentWillUnmount() {
      window.removeEventListener("online", this.handleConnectionChange);
      window.removeEventListener("offline", this.handleConnectionChange);
    }

    handleConnectionChange = () => {
      const condition = navigator.onLine ? "online" : "offline";
      if (condition === "online") {
        // const webPing = setInterval(
        // () => {
        //     fetch('//google.com', {
        //         mode: 'no-cors',
        //     })
        //         .then(() => {
        //             this.setState({ isDisconnected: false }, () => {
        //                 return clearInterval(webPing)
        //             });
        //         }).catch(() => this.setState({ isDisconnected: true }) )
        // }, 2000);
        this.setState({ isDisconnected: false });
        return;
      }

      return this.setState({ isDisconnected: true });
    };

    render() {
      const { isDisconnected } = this.state;
      if (isDisconnected) {
        return <h1>Internet connection lost</h1>;
      } else {
        return <ComposedComponent />;
      }
    }
  }

  return NetworkDetector;
}
