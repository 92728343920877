import React, { Component } from "react";
import { FinancialBlank } from "../../../../components/containers/FinancialBlank";
import CustomDashboardDetails from "./CustomDashboardDetails";
import DashboardsBlank from "../components/DashboardsBlank";
import { default as MuiButton } from "@mui/material/Button/Button";
import CircleLoader from "../../../../components/Loaders/CircleLoader/CircleLoader";
import { ArrowDown } from "../../../../components/icons/svgIcons";
import IconButton from "@mui/material/IconButton";
import {
  ReusableMenu,
  ReusableMenuItem,
} from "../../../../components/menus/ReusableMenu/ReusableMenu";
import {
  CopyIcon,
  DeleteIcon,
  EditIcon,
  Tick,
} from "../../../../SlideEditor/components/components/menus/LeftMenu/components/Icons/LeftMenuIcons";
import request from "superagent";
import "../../../../SlideEditor/components/components/menus/LeftMenu/components/SectionTab.scss";
import { Tooltip } from "@mui/material";
import Mx_Tooltip_Hover from "../../../../components/icons/Mx_Tooltip_Hover";

window.compareTwoObjects = (obj1, obj2) => {
  if (typeof obj1 !== typeof obj2) {
    return false;
  }
  if (typeof obj1 != "object" || obj1 === null || obj2 === null) {
    return obj1 === obj2;
  }

  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false;
  }
  for (let i = 0; i < Object.keys(obj1).length; i++) {
    if (!window.compareTwoObjects(obj1[Object.keys(obj1)[i]], obj2[Object.keys(obj1)[i]])) {
      return false;
    }
  }
  return true;
};

class CustomDashboards extends Component {
  constructor(props) {
    super(props);
    this.datastructure = require("../../../../data/datastructure.json");

    this.state = {
      openCreateDialog: false,
      selectedDashboard: null,
      loading: false,
      key: new Date().getTime(),
    };

    this.CustomDashboards = [];
    this.ChartsIDs = [];
    this.setCustomDashboards();
    this.setSelectedDashboard(0, false);

    this.force_save_obj = {};
  }

  setSelectedDashboard = (index, setState = true) => {
    if (this.CustomDashboards.length && this.CustomDashboards[index]) {
      if (setState) {
        this.setState({
          selectedDashboard: this.CustomDashboards[index],
          key: new Date().getTime(),
        });
      } else {
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state.selectedDashboard = this.CustomDashboards[index];
      }
    } else if (setState) {
      this.setState({ selectedDashboard: null });
    }
  };

  handleDialog = (value = false) => {
    if (value != this.state.openCreateDialog) {
      this.setState({ openCreateDialog: value });
    }
  };

  setCustomDashboards = () => {
    this.CustomDashboards = global.Modeliks.CustomDashboards;
  };

  getCurrentCharts = () => {};

  handleNewCustomDashboard = (dashboardName = "") => {
    global.Modeliks.post(
      this.datastructure.CustomDashboards.TableName,
      {
        Name: dashboardName,
        Settings: JSON.stringify({ charts: {}, financial_statements: [], custom_kpis: [] }),
        CompanyID: global.Modeliks.CompanyInfo.ID,
      },
      (success) => {
        global.Modeliks.GetCustomDashboards(() => {
          this.setCustomDashboards();
          this.setSelectedDashboard(this.CustomDashboards.length - 1, true);
        });
      },
    );
    this.handleDialog(false);
  };

  customDashboardsDetails = () => {
    if (this.state.openCreateDialog) {
      return (
        <CustomDashboardDetails
          heading={"Name of Custom Dashboard"}
          addNewCustomDashboard={this.handleNewCustomDashboard}
          handleClose={this.handleDialog}
        />
      );
    }
    return null;
  };

  getColor = () => {
    if (global.Modeliks.PERMISSIONS.Dashboards.restrictions.edit.active) {
      return { color: "#01AFFD" };
    }
    return {};
  };

  handleDeleteDashboard = (index) => {
    let shouldChangeDashboard = false;
    if (this.CustomDashboards[index].ID === this.state.selectedDashboard.ID) {
      shouldChangeDashboard = true;
    }
    global.Modeliks.del("customdashboards", { ID: this.CustomDashboards[index].ID }, () =>
      global.Modeliks.GetCustomDashboards(() => {
        this.setCustomDashboards();
        shouldChangeDashboard && this.setSelectedDashboard(0, true);
      }),
    );
  };

  dashboardChanger = () => {
    return (
      <div>
        <ReusableMenu
          arrow
          menuButton={
            <Tooltip
              arrow
              enterNextDelay={500}
              enterDelay={500}
              title={this.state.selectedDashboard.Name}
            >
              <MuiButton className="psdf_menu_section_button custom_dash">
                <span>{this.state.selectedDashboard.Name}</span> <ArrowDown />
              </MuiButton>
            </Tooltip>
          }
          menuItems={[
            ...this.CustomDashboards.map(
              (c, index) =>
                new ReusableMenuItem(
                  (
                    <DashboardButtons
                      dashboard={c}
                      onDeleteDashboard={() => this.handleDeleteDashboard(index)}
                      refresh={() => {
                        this.setCustomDashboards();
                        this.forceUpdate();
                      }}
                    />
                  ),
                  () => this.setSelectedDashboard(index),
                  c === this.state.selectedDashboard,
                  false,
                  null,
                  null,
                  !global.Modeliks.PERMISSIONS.Dashboards.restrictions.edit.active,
                  true,
                ),
            ),
            new ReusableMenuItem(
              (
                <div style={{ display: "flex", alignItems: "center", ...this.getColor() }}>
                  Add new dashboard
                </div>
              ),
              () => this.handleDialog(true),
              false,
              [],
              null,
              null,
              !global.Modeliks.PERMISSIONS.Dashboards.restrictions.edit.active,
              true,
            ),
          ]}
        />
      </div>
    );
  };

  componentWillUnmount() {
    this.force_save_obj.Settings && this.saveDashboard(this.force_save_obj.Settings, null, true);
  }

  saveDashboard = (settings, callBack, force = false) => {
    if (force || !window.compareTwoObjects(settings, this.state.selectedDashboard.Settings)) {
      delete this.force_save_obj.Settings;
      this.state.selectedDashboard.Settings = JSON.stringify(settings);
      this.state.selectedDashboard.Save(() => {});
      this.state.selectedDashboard.Settings = settings;
      callBack && callBack();
    }
  };

  render() {
    if (this.state.loading || global.Modeliks.isLoading) {
      return (
        <div className={"main_circle_loader_container"}>
          <CircleLoader />
        </div>
      );
    }
    if (this.CustomDashboards.length && this.state.selectedDashboard) {
      return (
        <>
          <DashboardsBlank
            saveSettings={this.props.saveSettings}
            key={this.state.key}
            isCustomDashboard
            defaultSelectedReport={this.props.defaultSelectedReport}
            FinancialStatements={this.props.FinancialStatements}
            titleButton
            enableDrag
            showMonthly
            saveDashboard={this.saveDashboard}
            unitChanger
            customDashboard
            forceSaveObj={this.force_save_obj}
            dashProps={this.props}
            subSection={JSON.parse(JSON.stringify(this.state.selectedDashboard.Settings))}
            curMonths={this.props.curMonths}
            dashboardChanger={this.dashboardChanger()}
          />
          {this.customDashboardsDetails()}
        </>
      );
    }
    return (
      <>
        <FinancialBlank
          disabled={!global.Modeliks.PERMISSIONS.Dashboards.restrictions.edit.active}
          onAdd={() => {
            this.setState({ openCreateDialog: true });
          }}
          headerText="Create your first Custom Dashboard"
          buttonLabel="Create Custom Dashboard"
        />
        {this.customDashboardsDetails()}
      </>
    );
  }
}

const getDashButton = (icon, title = "", btnFunction) => {
  if (global.Modeliks.PERMISSIONS.Dashboards.restrictions.edit.active) {
    return (
      <Mx_Tooltip_Hover
        icon={icon}
        title={title}
        onClick={(e) => {
          e.stopPropagation();
          btnFunction && btnFunction();
        }}
      />
    );
  }
};

const DashboardButtons = (props) => {
  const [name, setName] = React.useState(props.dashboard.Name);
  const [editName, setEditName] = React.useState(false);

  if (editName) {
    return (
      <div className={"input_container no_padding"} onClick={(e) => e.stopPropagation()}>
        <input
          className={"section_name_input"}
          value={name}
          onKeyDown={(e) => e.stopPropagation()}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
        {getDashButton(<Tick />, "Save", () => {
          if (props.dashboard.Name != name) {
            props.dashboard.Name = name;
            if (props.dashboard.Save) {
              props.dashboard.Settings = JSON.stringify(props.dashboard.Settings);
              props.dashboard.Save(() => {
                props.dashboard.Settings = JSON.parse(props.dashboard.Settings);
                props.refresh && props.refresh();
                setEditName(false);
              });
            }
          } else {
            setEditName(false);
          }
        })}
      </div>
    );
  }
  return (
    <div className={"input_container no_padding"}>
      {name}
      <div className="input_container_actions">
        {getDashButton(<EditIcon />, "Rename", () => setEditName(true))}
        {getDashButton(<CopyIcon />, "Duplicate", () => {
          request
            .post("/api/duplicate_dashboard")
            .set("authorization", "Bearer " + window.localStorage.getItem("token"))
            .send({ dashboardID: props.dashboard.ID })
            .then(() => global.Modeliks.GetCustomDashboards(props.refresh));
        })}
        {getDashButton(<DeleteIcon />, "Delete", props.onDeleteDashboard)}
      </div>
    </div>
  );
};

export default CustomDashboards;
