import React from "react";
import "./mx_numinput.scss";
import PropTypes from "prop-types";
import { InputFormats } from "../../constants/finance";
import { UnitTypes } from "../../../data/Finance/CalculatedDriver/constants";
import { NumberFormatTypes } from "../../constants/charts";
import Mx_Tooltip_Hover from "../../icons/Mx_Tooltip_Hover";
import LocalStorage from "../../../helpers/LocalStorage";
import {
  tableConfig,
  tableConfigTypes,
  tableObject,
} from "../../tables/FinanceTable/components/constants";

const notValidData = [-Infinity, Infinity, NaN, undefined, null];

const notValidDataActual = [
  // -Infinity,
  // Infinity,
  undefined,
  null,
];

const Mx_NumInput = ({
  disabled,
  isEditable,
  data,
  onChange,
  hideCurrency,
  unit,
  unitNegative,
  unitDisplay,
  min,
  hideUnit,
  max,
  IsReport,
  EditStream,
  displayOfDecimals,
  allowNaNValue,
  showNaNValue,
  IsNumberFormatDisabled,
  customStyle,
  autoFocus,
  tabIndex,
  isMultipleSelected,
  onKeyDown,
  lastMouseSelected,
  selected,
  update,
  shouldBeEditable,
  hidden,
  singleInput = false,
  boldRow = false,
  isDialogInput,
  isDialogInputSmall,
  level,
  field,
  error,
  color,
  editableColor,
  hasGrowthValue,
  name,
  mouseOver,
}) => {
  const [value, setValue] = React.useState(notValidData.includes(data) ? 0 : data.toString());
  const [leftUnit, setLeftUnit] = React.useState(
    hideUnit ? "" : unit && unit !== "%" && unit !== "x" && !hideCurrency ? unit + "" : "",
  );
  const [rightUnit] = React.useState(unit === "%" ? " " + unit : unit === "x" ? "" + unit : "");
  const [isSelected, setIsSelected] = React.useState(selected);
  const decimalsEnabled = unit != null;
  let unitDisplayTmpValue = 0;
  const onInputChange = (value) => {
    const newVal = decimalsEnabled ? parseFloat(value) : parseInt(value);
    if (!decimalsEnabled || unit !== "%") {
      if (!unitNegative) {
        min = 0;
      }
    }

    if (field === "Actual" && min !== null) {
      min = null;
    }

    if (Number.isNaN(newVal) == false) {
      if (Number.isInteger(max) && newVal > max) {
        setValue(max);
      } else if (Number.isInteger(min) && newVal < min) {
        setValue(min);
      } else {
        setValue(newVal);
      }
    } else if (value.trim().length == 0) {
      setValue(null);
    }

    global.startPerform = 0;
  };

  React.useEffect(() => {
    setValue(notValidData.includes(data) ? 0 : data);
    if (hideCurrency && leftUnit !== "") {
      setLeftUnit("");
    } else {
      let val = hideUnit
        ? ""
        : unit && unit !== "%" && unit !== "x" && !hideCurrency
          ? unit + ""
          : "";
      if (leftUnit !== val) {
        setLeftUnit(val);
      }
    }
  }, [data, selected, hideCurrency]);

  if (hidden) {
    return (
      <div className="mx_numinput">
        <div className="mx_numinput_input">-</div>
      </div>
    );
  }

  const handleOnFocus = (e) => {
    if (e.target) {
      e.target.value = value;
      e.target.select();
    }
    setTimeout(e.target.select(), 100);
  };

  const handleCopy = () => {
    let selection = window.getSelection().toString();
    if (selection.length) {
      navigator.clipboard.writeText(selection);
    } else {
      navigator.clipboard.writeText(value);
    }
  };

  const handlePaste = (e) => {
    let data = e.clipboardData.getData("text");
    let tempArr = [];
    if (data.length > 1) {
      let temp1 = data.replace(/\r/g, "");
      let newArr = temp1.split(/\n/);
      newArr.forEach((el) => (tempArr = tempArr.concat(el.split(/\t/))));
      if (tempArr[tempArr.length - 1] === "") tempArr.pop();
    } else tempArr = data;
    onInputChange(window.getRealValue(tempArr[0]));
  };

  const props = {};
  if (tabIndex) {
    props.tabIndex = tabIndex;
  }

  const { className, classNameMxInput, classNameInput } = getClassName(
    "",
    "",
    "",
    level,
    color,
    boldRow,
    disabled,
    isEditable,
    isDialogInput,
    isDialogInputSmall,
    editableColor,
    error,
    customStyle,
  );

  const getNumberFormatType = (
    type = global.Modeliks.DashboardSettings.Value.financialDates.numberFormatType,
  ) => {
    return NumberFormatTypes[type];
  };

  const setFormatNum = (num = { splitBy: 1 }, decimals = null, IsReport = false) => {
    if (!IsNumberFormatDisabled) {
      return InputFormats.formatNumber(data / num.splitBy, decimals, IsReport);
    }
    return InputFormats.formatNumber(data, decimals, IsReport);
  };

  const getUnitDisplay = () => {
    if (unitDisplay && !rightUnit) {
      if (IsReport) {
        const NumberType = getNumberFormatType(
          LocalStorage.get(tableObject, tableConfig, tableConfigTypes.unitDisplayReportFormat),
        );
        return `${leftUnit}${!IsNumberFormatDisabled ? (hideUnit ? "" : NumberType.shortName) : ""} ${setFormatNum(NumberType, null, true)} `;
      }

      const NumberType = getNumberFormatType();
      return `${leftUnit}${!IsNumberFormatDisabled ? (hideUnit ? "" : NumberType.shortName) : ""} ${setFormatNum(NumberType, EditStream ? displayOfDecimals : null)} `;
    }
    return `${leftUnit} ${InputFormats.formatNumber(data, decimalsEnabled ? 2 : 0)}`;
  };

  const getValueDisplay = () => {
    if (showNaNValue && notValidDataActual.includes(data)) {
      return NaN;
    }
    return `${((field === "Actual" && allowNaNValue) || showNaNValue ? notValidDataActual.includes(data) : notValidData.includes(data)) ? `${leftUnit} 0` : getUnitDisplay()}${rightUnit}`;
  };

  return (
    <div className={classNameMxInput}>
      {mouseOver ? (
        <Mx_Tooltip_Hover className={className} singleCell={true} title={`${getValueDisplay()}`}>
          <div className={className}>&nbsp;{getValueDisplay()}</div>
        </Mx_Tooltip_Hover>
      ) : (
        <div className={className}>&nbsp;{getValueDisplay()}</div>
      )}

      {!disabled && selected && isEditable && !isMultipleSelected && (
        <input
          onBlur={() => onChange(value)}
          onWheel={(e) => document.activeElement.blur()}
          onCopy={(e) => {
            e.stopPropagation();
            handleCopy();
          }}
          name={name}
          onPaste={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handlePaste(e);
          }}
          onDragStart={(e) => e.preventDefault()}
          onKeyDown={(e) => {
            if (value !== "" && data !== value && e.key !== "Tab") {
              e.stopPropagation();
            } else if (
              e.keyCode === 37 ||
              e.keyCode === 38 ||
              e.keyCode === 39 ||
              e.keyCode === 40
            ) {
              e.preventDefault();
            }
          }}
          onFocus={handleOnFocus}
          onChange={(e) => onInputChange(e.target.value)}
          autoFocus={!singleInput}
          className={classNameInput}
          type="number"
          step={decimalsEnabled ? ".01" : "1"}
          value={value <= 0 || value >= 0 ? value : ""}
          {...props}
        />
      )}
    </div>
  );
};

Mx_NumInput.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  isEditable: PropTypes.bool,
  unit: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  isMultipleSelected: PropTypes.bool,
  autoFocus: PropTypes.bool,
  onKeyDown: PropTypes.func,
  lastMouseSelected: PropTypes.bool,
  selected: PropTypes.bool,
  update: PropTypes.func,
  shouldBeEditable: PropTypes.bool,
  hidden: PropTypes.bool,
  singleInput: PropTypes.bool,
  boldRow: PropTypes.any,
  isDialogInput: PropTypes.bool,
  isDialogInputSmall: PropTypes.bool,
  level: PropTypes.number,
  error: PropTypes.bool,
  color: PropTypes.any,
  hasGrowthValue: PropTypes.bool,
  customStyle: PropTypes.string,
  IsReport: PropTypes.bool,
  IsNumberFormatDisabled: PropTypes.bool,
  EditStream: PropTypes.bool,
  displayOfDecimals: PropTypes.number,
  hideUnit: PropTypes.bool,
  field: PropTypes.any,
  allowNaNValue: PropTypes.bool,
  hideCurrency: PropTypes.bool,
  showNaNValue: PropTypes.bool,
};

Mx_NumInput.defaultProps = {
  disabled: false,
  hidden: false,
  boldRow: 0,
  isDialogInput: false,
  isDialogInputSmall: false,
  level: 0,
  error: false,
  hasGrowthValue: false,
  customStyle: "",
  IsReport: false,
  IsNumberFormatDisabled: false,
  EditStream: false,
  displayOfDecimals: 0,
  hideUnit: false,
  field: false,
  allowNaNValue: false,
  hideCurrency: false,
  showNaNValue: false,
};

export default Mx_NumInput;

const getClassName = (
  className,
  classNameMxInput,
  classNameInput,
  level,
  color,
  boldRow,
  disabled,
  isEditable,
  isDialogInput,
  isDialogInputSmall,
  editableColor,
  error,
  customStyle,
) => {
  className = `mx_numinput_input mct_ft_editable ${boldRow ? `mct_ft_bold-0` : `mct_ft_bold-${level !== 0 ? level : 1}`} ${color ? color : ""} `;
  classNameMxInput = `mx_numinput `;
  classNameInput = `mx_numinput_input `;

  if (disabled) {
    className = "mx_numinput_input mct_ft_disabled";
    classNameInput = `mx_numinput_input mct_ft_disabled`;
  }

  if (!isEditable) {
    className = `mx_numinput_input   ${boldRow ? `mct_ft_bold-0` : `mct_ft_bold-${level !== 0 ? level : 1}`}`;
  }

  if (isDialogInput) {
    classNameMxInput = `mx_numinput mct_ft_dialog_input`;
  }
  if (isDialogInputSmall) {
    classNameMxInput = `mx_numinput mct_ft_dialog_input_small`;
  }

  if (editableColor && isEditable) {
    className = className + " blue_color";
  }

  if (error) {
    classNameMxInput = classNameMxInput + " mct_ft_dialog_input_error";
  }

  className = className + " " + customStyle;
  classNameMxInput = classNameMxInput + " " + customStyle;
  classNameInput = classNameInput + " " + customStyle;

  return { className, classNameMxInput, classNameInput };
};
