import Revenue from "../Revenue/index";
import CalculatedDriver_Values from "../CalculatedDriver/CalculatedDriver_Values";
import datastructure from "../../datastructure.json";
import { UnitTypes } from "../CalculatedDriver/constants";
import FinanceGeneric from "../FinanceGeneric";
import TradingMultiples from "./TradingMultiples";

class Valuation extends FinanceGeneric {
  DiscountedCashFlow;
  TradingMultipleValuation;
  TrasanctionMultiplesValuation;
  BookValue;
  TerminalValueType;
  ExitMultipleType;

  constructor(db_record) {
    super(db_record);
    if (this.db_record) {
      this.DiscountedCashFlow = this.db_record.DiscountedCashFlow;
      this.TradingMultipleValuation = this.db_record.TradingMultipleValuation;
      this.TrasanctionMultiplesValuation = this.db_record.TrasanctionMultiplesValuation;
      this.BookValue = this.db_record.BookValue;
      this.TerminalValueType = this.db_record.TerminalValueType;
      this.ExitMultipleType = this.db_record.ExitMultipleType;
      this.setDriversFromDataStorageSub();
    }
    this.SaveRevenue = this.Save;
    this.Save = this.SaveValuation;
  }

  static getValuationSettings = (callBack) => {
    if (!global.Modeliks.ValuationSettingsStore.length > 0) {
      Valuation.createNewValuation(() => {
        callBack && callBack();
      });
    } else {
      return global.Modeliks.ValuationSettingsStore[0];
    }
  };

  SaveNew = (callBack) => {
    global.Modeliks.post(this.constructor.TableName, this, (res) => {
      this.ID = res.id;
      this.createDriverValues(this.ID);
      this.ReferenceBaseValue.Value = 0;
      this.Save(() => callBack(this));
    });
  };

  static createNewValuation = (callBack) => {
    const newValuation = new Valuation();
    newValuation.DiscountedCashFlow = true;
    newValuation.TradingMultipleValuation = false;
    newValuation.TrasanctionMultiplesValuation = false;
    newValuation.BookValue = false;
    newValuation.TerminalValueType = "ebitda_multiple";
    newValuation.SaveNew(callBack);
  };

  static DriverValuesDesc = Object.assign({
    FirstPeriodMed: {
      fieldName: "FirstPeriodMed",
      driverID: "first_period_median",
      unit: UnitTypes.Percentage,
    },
    FirstPeriodMin: {
      fieldName: "FirstPeriodMin",
      driverID: "first_period_min",
      unit: UnitTypes.Percentage,
    },
    FirstPeriodMax: {
      fieldName: "FirstPeriodMax",
      driverID: "first_period_max",
      unit: UnitTypes.Percentage,
    },
    SecondPeriodMed: {
      fieldName: "SecondPeriodMed",
      driverID: "second_period_median",
      unit: UnitTypes.Percentage,
    },
    SecondPeriodMin: {
      fieldName: "SecondPeriodMin",
      driverID: "second_period_min",
      unit: UnitTypes.Percentage,
    },
    SecondPeriodMax: {
      fieldName: "SecondPeriodMax",
      driverID: "second_period_max",
      unit: UnitTypes.Percentage,
    },
    EBITDAMed: {
      fieldName: "EBITDAMed",
      driverID: "ebitda_median",
    },
    EBITDAMin: {
      fieldName: "EBITDAMin",
      driverID: "ebitda_min",
    },
    EBITDAMax: {
      fieldName: "EBITDAMax",
      driverID: "ebitda_max",
    },
    NetProfitMed: {
      fieldName: "NetProfitMed",
      driverID: "net_profit_median",
    },
    NetProfitMin: {
      fieldName: "NetProfitMin",
      driverID: "net_profit_min",
    },
    NetProfitMax: {
      fieldName: "NetProfitMax",
      driverID: "net_profit_max",
    },
    RevenueMed: {
      fieldName: "RevenueMed",
      driverID: "revenue_median",
    },
    RevenueMin: {
      fieldName: "RevenueMin",
      driverID: "revenue_min",
    },
    RevenueMax: {
      fieldName: "RevenueMax",
      driverID: "revenue_max",
    },
    ReferenceBaseValue: {
      fieldName: "ReferenceBaseValue",
      driverID: "reference_base_value",
    },
  });

  static TableName = datastructure.Valuation_Settings.TableName;

  createDriverValues = (ID) => {
    Object.values(this.constructor.DriverValuesDesc).forEach((driverVal) => {
      if (this.hasOwnProperty(driverVal.fieldName) == false) {
        this[driverVal.fieldName] = new CalculatedDriver_Values(
          null,
          `${Valuation.TableName}-${ID}-${driverVal.driverID}`,
          null,
          driverVal.unit,
        );
      }
    });
  };

  SaveValuation = (callBack, saveDrivers = true, saveDriverValues = true) => {
    global.Modeliks.put(this.constructor.TableName, null, this, (res) => {
      global.Modeliks.post(
        global.Modeliks.Tables.Finance_CalculatedDriver_Values.TableName,
        Object.values(this.constructor.DriverValuesDesc).map(
          (driverVal) => this[driverVal.fieldName].cur_record,
        ),
        (res) => {
          callBack();
        },
      );
    });
  };

  getValuationValueID = (valID) => {
    return `${Valuation.TableName}-${this.ID}-${valID}`;
  };

  setDriversFromDataStorageSub = () => {
    Object.values(this.constructor.DriverValuesDesc).forEach((driverVal) => {
      this[driverVal.fieldName] = global.Modeliks.DriverValuesStore.getItem(
        this.getValuationValueID(driverVal.driverID),
      );
    });
  };
}

export default Valuation;
