import React from "react";
import { MenuItem, SubMenu } from "@szhsin/react-menu";
import {
  LowerCase,
  SubScript,
  SuperScript,
  TitleCase,
  UpperCase,
} from "../../IconButtons/SubMenuIcons";

function CapitalizationSubMenu(props) {
  const setTitleCase = () => {
    let selectedObject = props.getSelectedObject();

    selectedObject.forEach((p) => {
      if (Object.prototype.toString.call(p) == "[object Array]") {
        p.forEach((obj) => {
          obj.char = obj.char.toLowerCase();
          let tmpStyle = Object.assign({}, obj.style);
          tmpStyle["textTransform"] = "capitalize";
          obj.style = tmpStyle;
        });
      } else {
        p.char = p.char.toLowerCase();
        let tmpStyle = Object.assign({}, p.style);
        tmpStyle["textTransform"] = "capitalize";
        p.style = tmpStyle;
      }
    });

    props.groupStruct();
  };

  const setStyle = (key, value, check) => {
    let selectedObject = props.getSelectedObject();

    let elementCounter = 0;
    let styleCounter = 0;

    if (check) {
      selectedObject.forEach((p) => {
        if (Object.prototype.toString.call(p) == "[object Array]") {
          p.forEach((obj) => {
            elementCounter++;
            if (obj.style.hasOwnProperty(key) && obj.style[key] === value) {
              styleCounter++;
            }
          });
        } else {
          elementCounter++;
          if (p.style.hasOwnProperty(key) && p.style[key] === value) {
            styleCounter++;
          }
        }
      });
    }

    if (styleCounter !== elementCounter || !check) {
      selectedObject.forEach((p) => {
        if (Object.prototype.toString.call(p) == "[object Array]") {
          p.forEach((obj) => {
            if (!obj.style.hasOwnProperty(key) || obj.style[key] !== value) {
              let tmpStyle = Object.assign({}, obj.style);
              tmpStyle[key] = value;
              obj.style = tmpStyle;
              obj.style = Object.keys(obj.style)
                .sort()
                .reduce(function (result, key) {
                  result[key] = obj.style[key];
                  return result;
                }, {});
            }
          });
        } else {
          if (!p.style.hasOwnProperty(key) || p.style[key] !== value) {
            let tmpStyle = Object.assign({}, p.style);
            tmpStyle[key] = value;
            p.style = tmpStyle;
            p.style = Object.keys(p.style)
              .sort()
              .reduce(function (result, key) {
                result[key] = p.style[key];
                return result;
              }, {});
          }
        }
      });
    } else {
      selectedObject.forEach((p) => {
        if (Object.prototype.toString.call(p) == "[object Array]") {
          p.forEach((obj) => {
            delete obj.style[key];
            obj.style = Object.keys(obj.style)
              .sort()
              .reduce(function (result, key) {
                result[key] = obj.style[key];
                return result;
              }, {});
          });
        } else {
          delete p.style[key];
          p.style = Object.keys(p.style)
            .sort()
            .reduce(function (result, key) {
              result[key] = p.style[key];
              return result;
            }, {});
        }
      });
    }

    props.groupStruct();
  };

  const setSuperscript = () => {
    let selectedObject = props.getSelectedObject();
    let styleCounter = 0;
    let elementCounter = 0;

    selectedObject.forEach((p) => {
      if (Object.prototype.toString.call(p) == "[object Array]") {
        p.forEach((obj) => {
          elementCounter++;
          if (obj.sup) {
            styleCounter++;
          }
        });
      } else {
        elementCounter++;
        if (p.sup) {
          styleCounter++;
        }
      }
    });

    selectedObject.forEach((p) => {
      if (Object.prototype.toString.call(p) == "[object Array]") {
        p.forEach((obj) => {
          if (elementCounter !== styleCounter) {
            obj.sup = true;
            obj.beforeFontSize = obj.style.fontSize;
            let tmpStyle = Object.assign({}, obj.style);
            tmpStyle.fontSize = "50%";
            obj.style = tmpStyle;
            obj.sub = false;
          } else {
            let tmpStyle = Object.assign({}, obj.style);
            tmpStyle.fontSize = obj.beforeFontSize;
            obj.style = tmpStyle;
            obj.beforeFontSize = "";
            obj.sup = false;
            obj.sub = false;
          }
        });
      } else {
        if (elementCounter !== styleCounter) {
          p.sup = true;
          p.beforeFontSize = p.style.fontSize;
          let tmpStyle = Object.assign({}, p.style);
          tmpStyle.fontSize = "50%";
          p.style = tmpStyle;
          p.sub = false;
        } else {
          let tmpStyle = Object.assign({}, p.style);
          tmpStyle.fontSize = p.beforeFontSize;
          p.style = tmpStyle;
          p.beforeFontSize = "";
          p.sup = false;
          p.sub = false;
        }
      }
    });

    props.groupStruct();
  };

  const setSubscript = () => {
    let selectedObject = props.getSelectedObject();
    let styleCounter = 0;
    let elementCounter = 0;

    selectedObject.forEach((p) => {
      if (Object.prototype.toString.call(p) == "[object Array]") {
        p.forEach((obj) => {
          elementCounter++;
          if (obj.sub) {
            styleCounter++;
          }
        });
      } else {
        elementCounter++;
        if (p.sub) {
          styleCounter++;
        }
      }
    });

    selectedObject.forEach((p) => {
      if (Object.prototype.toString.call(p) == "[object Array]") {
        p.forEach((obj) => {
          if (elementCounter !== styleCounter) {
            obj.beforeFontSize = obj.style.fontSize;
            let tmpStyle = Object.assign({}, obj.style);
            tmpStyle.fontSize = "50%";
            obj.style = tmpStyle;
            obj.sup = false;
            obj.sub = true;
          } else {
            let tmpStyle = Object.assign({}, obj.style);
            tmpStyle.fontSize = obj.beforeFontSize;
            obj.style = tmpStyle;
            obj.beforeFontSize = "";
            obj.sup = false;
            obj.sub = false;
          }
        });
      } else {
        if (elementCounter !== styleCounter) {
          p.beforeFontSize = p.style.fontSize;
          let tmpStyle = Object.assign({}, p.style);
          tmpStyle.fontSize = "50%";
          p.style = tmpStyle;
          p.sup = false;
          p.sub = true;
        } else {
          let tmpStyle = Object.assign({}, p.style);
          tmpStyle.fontSize = p.beforeFontSize;
          p.style = tmpStyle;
          p.beforeFontSize = "";
          p.sup = false;
          p.sub = false;
        }
      }
    });

    props.groupStruct();
  };

  const CapitalizationSubMenu = () => {
    return (
      <SubMenu label="Capitalization">
        <MenuItem
          className="cm_icon_helper"
          onClick={() => setStyle("textTransform", "lowercase", false)}
        >
          <LowerCase className="cm_icon" />
          <span className="cm_btn_name">lowercase</span>
        </MenuItem>
        <MenuItem
          className="cm_icon_helper"
          onClick={() => setStyle("textTransform", "uppercase", false)}
        >
          <UpperCase className="cm_icon" />
          <span className="cm_btn_name">UPPERCASE</span>
        </MenuItem>
        <MenuItem className="cm_icon_helper" onClick={setTitleCase}>
          <TitleCase className="cm_icon" />
          <span className="cm_btn_name">Title Case</span>
        </MenuItem>
        <div className="cm_divider" />
        <MenuItem className="cm_icon_helper" onClick={setSuperscript}>
          <SuperScript className="cm_icon" />
          <span className="cm_btn_name">Superscript</span>
          <span className="helper_text">Ctrl+.</span>
        </MenuItem>
        <MenuItem className="cm_icon_helper" onClick={setSubscript}>
          <SubScript className="cm_icon" />
          <span className="cm_btn_name">Subscript</span>
          <span className="helper_text">Ctrl+,</span>
        </MenuItem>
      </SubMenu>
    );
  };
  return <>{CapitalizationSubMenu()}</>;
}

export default CapitalizationSubMenu;
