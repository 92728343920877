import React from "react";
import "./errorBoundary.scss";
import { ErrorLogo, ModeliksLogo } from "../icons/svgIcons";
import ButtonMui from "../buttons/buttonMui/buttonMui";

const reloadedKey = "error_reloaded";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: "",
    };
  }

  componentDidMount() {
    global.Modeliks.Subscribe("onError", this.handleError);
  }

  componentWillUnmount() {
    global.Modeliks.Unsubscribe("onError", this.handleError);
  }

  handleError = (value) => {
    this.setState({ hasError: true, errorMessage: value });
  };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
    // logErrorToMyService(error, info.componentStack);
  }

  handleRefresh = () => {
    if (this.hasUserReloadedError()) {
      window.localStorage.removeItem(reloadedKey);
      window.history.back();
      setTimeout(() => {
        window.location.reload();
      }, 50);
    } else {
      window.localStorage.setItem(reloadedKey, "reloaded");
      window.location.reload();
    }
  };

  getButtonText = () => {
    if (this.hasUserReloadedError()) {
      return "Return";
    } else {
      return "Refresh Page";
    }
  };

  hasUserReloadedError = () => {
    return !!window.localStorage.getItem(reloadedKey);
  };

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="error_boundary">
          <div className="header">
            <div className="logo_container">
              <ModeliksLogo />
              modeliks
            </div>
          </div>
          <div className="content">
            <ErrorLogo />
            <div className="r_1">Ooops, something went wrong.</div>
            <div className="r_2 m_t">There was a problem connecting to...</div>
            <div className="r_2">Please refresh the page or check your connection.</div>
            <div className="r_3">Error: {this.state.errorMessage}</div>

            <div className="r_4">
              <ButtonMui
                label={this.getButtonText()}
                width={194}
                variant={"contained"}
                onClick={this.handleRefresh}
              />
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
