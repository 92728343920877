export class PitchStrategy {
  getHistoryItem(item) {
    return {
      ...item,
      itemIndex: null,
    };
  }
  getSlideComponentClassName() {
    return "";
  }

  getMovableObjects(state) {
    return [];
  }

  getSlideObjects(state) {
    return [];
  }
}
