import React, { Fragment, useState } from "react";
import "./HeaderTextEditor.scss";
import HeaderPortal from "./HeaderPortal";
import all_icons from "../../all_icons";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FormatAlignCenter from "../components/IconButtons/FormatAlignCenter";
import FormatAlignRight from "../components/IconButtons/FormatAlignRight";
import FormatAlignLeft from "../components/IconButtons/FormatAlignLeft";
import FormatBold from "../components/IconButtons/FormatBold";
import FormatItalic from "../components/IconButtons/FormatItalic";
import FormatUnderline from "../components/IconButtons/FormatUnderline";
import MaterialSelect from "../components/Select/MaterialSelect";
import HeaderTableEditor from "./HeaderTableEditor";
import Border from "../components/IconButtons/Border";
import BorderTop from "../components/IconButtons/BorderTop";
import BorderBottom from "../components/IconButtons/BorderBottom";
import BorderLeft from "../components/IconButtons/BorderLeft";
import BorderRight from "../components/IconButtons/BorderRight";
import ColorPicker from "../components/ColorPicker/ColorPicker";
import RemoveColor from "../components/IconButtons/RemoveColor";
//import BorderWeight from "../components/IconButtons/BorderWeight";
import { Box, Slider } from "@mui/material";
import FormatColorPick from "../components/IconButtons/FormatColorPick";
import { SketchPicker } from "react-color";
import {
  Separator,
  Bold,
  Italic,
  Underline,
  FormatColor,
} from "../components/IconButtons/SubMenuIcons.js";
import FontFamilyEditComponent from "../SlideHeader/EditorComponents/FontFamilyEditComponent";
import FontSizeEditComponent from "../SlideHeader/EditorComponents/FontSizeEditComponent";
import BorderWeightEditComponent from "../SlideHeader/EditorComponents/BorderWeightEditComponent";
import BorderDashEditComponent from "../SlideHeader/EditorComponents/BorderDashEditComponent";
import BorderFillComponent from "../SlideHeader/EditorComponents/BorderFillComponent";
import BorderColorEditComponent from "../SlideHeader/EditorComponents/BorderColorEditComponent";
import FillColorEditComponent from "../SlideHeader/EditorComponents/FillColorEditComponent";
import TextColorEditComponent from "../SlideHeader/EditorComponents/TextColorEditComponent";
import HighlightColorEditComponent from "../SlideHeader/EditorComponents/HighlightColorEditComponent";
import MoreOptionsMenuComponent from "../SlideHeader/EditorComponents/MoreOptionsMenuComponent";
import Tooltip from "@mui/material/Tooltip";
import ButtonJ from "../components/Buttons/Button";
import AlignEditComponent from "./EditorComponents/AlignEditComponent";
import SpacingEditComponent from "./EditorComponents/SpacingEditComponent";
import BulletingEditComponent from "./EditorComponents/BulletingEditComponent";
import NumberingEditComponent from "./EditorComponents/NumberingEditComponent";
import {
  ClearFormatting,
  DecreaseIndent,
  IncreaseIndent,
} from "../components/IconButtons/SubMenuIcons";
import ButtonMui from "../../../components/buttons/buttonMui/buttonMui";

function HeaderTable(props) {
  const selected = props.visible;

  const onChange = (style) => {
    props.onChange(style);
  };
  const [anchorE2, setAnchorE2] = React.useState(null);
  const [anchorE15, setAnchorE15] = React.useState(null);
  const openColor = Boolean(anchorE2);
  const openMoreOptions = Boolean(anchorE15);

  const handleClick2 = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorE2(null);
  };
  const handleClick15 = (event) => {
    if (!anchorE15) setAnchorE15(event.currentTarget);
  };
  const handleClose15 = () => {
    setAnchorE15(null);
  };

  const arr = [];

  const removeStyle = () => {
    props.onStyleRemove();
  };

  const setStyle = (key, value, check) => {};

  const setBullet = (type, curType) => {
    props.handleBullet(type, curType);
  };

  const increaseIndent = () => {
    if (props.style && props.style.marginLeft) {
      let padding = parseInt(props.style.marginLeft.replace("px", "").replace("%", ""));
      if (padding + 20 <= 160) {
        onChange({ marginLeft: padding + 20 + "px" });
      }
    } else {
      onChange({ marginLeft: "20px" });
    }
  };

  const decreaseIndent = () => {
    if (props.style && props.style.marginLeft) {
      let padding = parseInt(props.style.marginLeft.replace("px", "").replace("%", ""));
      if (padding >= 20) {
        onChange({ marginLeft: padding - 20 + "px" });
      } else {
        onChange({ marginLeft: "0px" });
      }
    }
  };

  const changeLineHeight = (value) => {};

  const toBoldExperimental = () => {
    if (props.style && props.style.fontWeight && props.style.fontWeight === "bold") {
      onChange({ fontWeight: "normal" });
    } else {
      onChange({ fontWeight: "bold" });
    }
  };

  const toItalicExperimental = () => {
    if (props.style && props.style.fontStyle && props.style.fontStyle === "italic") {
      onChange({ fontStyle: "normal" });
    } else {
      onChange({ fontStyle: "italic" });
    }
  };

  const toUnderlineExperimental = () => {
    if (props.style && props.style.textDecoration && props.style.textDecoration === "underline") {
      onChange({ textDecoration: "none" });
    } else {
      onChange({ textDecoration: "underline" });
    }
  };

  const checkForSelection = () => {
    if (document.getSelection()) {
      let selection = document.getSelection();
      if (selection.anchorOffset === selection.focusOffset) return false;
      else return true;
    } else return false;
  };
  const TextEditGroup = (className = "xl") => {
    return (
      <>
        <ButtonMui
          tooltipText={"Bold"}
          isIconButton
          className={className}
          onClick={toBoldExperimental}
          variant={"text"}
          roundnessType={"squared"}
          color={"black"}
          label={
            <Bold
              active={
                props.style && props.style.fontWeight ? props.style.fontWeight === "bold" : false
              }
            />
          }
        />
        <ButtonMui
          tooltipText={"Italic"}
          isIconButton
          className={className}
          onClick={toItalicExperimental}
          variant={"text"}
          roundnessType={"squared"}
          color={"black"}
          label={
            <Italic
              active={
                props.style && props.style.fontStyle ? props.style.fontStyle === "italic" : false
              }
            />
          }
        />
        <ButtonMui
          tooltipText={"Underline"}
          isIconButton
          className={className}
          onClick={toUnderlineExperimental}
          variant={"text"}
          roundnessType={"squared"}
          color={"black"}
          label={
            <Underline
              active={
                props.style && props.style.textDecoration
                  ? props.style.textDecoration === "underline"
                  : false
              }
            />
          }
        />
      </>
    );
  };

  const Color = () => {
    return (
      <>
        <FormatColorPick
          open={openColor}
          style={{ marginRight: "15px", backgroundColor: openColor && "lightblue" }}
          aria-controls={openColor ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openColor ? "true" : undefined}
          onClick={handleClick2}
        />
        <Menu
          id="basic-menu"
          anchorEl={anchorE2}
          open={openColor}
          onClose={handleClose2}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <div style={{ padding: "8px", fontSize: "15px" }}>
            <span style={{ display: "block" }}>text color</span>
            <div style={{ display: "flex", paddingTop: "2px" }}>
              <input
                style={{ marginRight: "5px", marginTop: "-2px", width: "32px", height: "25px" }}
                className={"button"}
                id={"fontColor"}
                type={"color"}
                value={props.style.color ? props.style.color : null}
                onChange={(event) => setStyle("color", event.target.value, false)}
              />
              <div
                style={{
                  marginRight: "5px",
                  width: "30px",
                  height: "20px",
                  backgroundColor: "#000000",
                  border: "1px solid",
                }}
                onClick={(event) => props.onChange({ color: "#000000" })}
              />
              <div
                style={{
                  marginRight: "5px",
                  width: "30px",
                  height: "20px",
                  backgroundColor: "#ffffff",
                  border: "1px solid",
                }}
                onClick={(event) => props.onChange({ color: "#ffffff" })}
              />
              <div
                style={{
                  marginRight: "5px",
                  width: "30px",
                  height: "20px",
                  backgroundColor: "#ffffff",
                  border: "1px solid",
                  textAlign: "center",
                }}
                onClick={(event) => props.onStyleRemove(["color"])}
              >
                <span style={{ color: "red", fontWeight: 500 }}>/</span>
              </div>
            </div>

            <div
              style={{
                height: "1px",
                backgroundColor: "gray",
                marginTop: "10px",
                marginBottom: "4px",
              }}
            />
            <span style={{ display: "block", marginBottom: "4px" }}>background color</span>
            <div style={{ display: "flex" }}>
              <input
                style={{ marginRight: "5px", marginTop: "-2px", width: "32px", height: "25px" }}
                className={"button"}
                id={"fontColor"}
                type={"color"}
                value={props.style.backgroundColor ? props.style.backgroundColor : null}
                onChange={(event) => onChange({ backgroundColor: event.target.value })}
              />
              <div
                style={{
                  marginRight: "5px",
                  width: "30px",
                  height: "20px",
                  backgroundColor: "#000000",
                  border: "1px solid",
                }}
                onClick={(event) => props.onChange({ backgroundColor: "#000000" })}
              />
              <div
                style={{
                  marginRight: "5px",
                  width: "30px",
                  height: "20px",
                  backgroundColor: "#ffffff",
                  border: "1px solid",
                }}
                onClick={(event) => props.onChange({ backgroundColor: "#ffffff" })}
              />
              <div
                style={{
                  marginRight: "5px",
                  width: "30px",
                  height: "20px",
                  backgroundColor: "#ffffff",
                  border: "1px solid",
                  textAlign: "center",
                }}
                onClick={(event) => props.onStyleRemove(["backgroundColor"])}
              >
                <span style={{ color: "red", fontWeight: 500 }}>/</span>
              </div>
            </div>

            <div style={{ display: "block", marginTop: "4px", marginBottom: "4px" }} />
            <div style={{ display: "flex" }}>
              <div
                style={{
                  marginRight: "5px",
                  width: "30px",
                  height: "20px",
                  backgroundColor: "#BAE4E6",
                  border: "1px solid",
                }}
                onClick={(event) => props.onChange({ backgroundColor: "#BAE4E6" })}
              />
              <div
                style={{
                  marginRight: "5px",
                  width: "30px",
                  height: "20px",
                  backgroundColor: "#76E6A6",
                  border: "1px solid",
                }}
                onClick={(event) => props.onChange({ backgroundColor: "#76E6A6" })}
              />
              <div
                style={{
                  marginRight: "5px",
                  width: "30px",
                  height: "20px",
                  backgroundColor: "#488EED",
                  border: "1px solid",
                }}
                onClick={(event) => props.onChange({ backgroundColor: "#488EED" })}
              />
              <div
                style={{
                  marginRight: "5px",
                  width: "30px",
                  height: "20px",
                  backgroundColor: "#FFC875",
                  border: "1px solid",
                }}
                onClick={(event) => props.onChange({ backgroundColor: "#FFC875" })}
              />
            </div>
          </div>
        </Menu>
      </>
    );
  };

  return (
    <Fragment>
      <FillColorEditComponent onChange={onChange} style={props.style} />

      <BorderColorEditComponent objType={"tableObject"} onChange={onChange} style={props.style} />

      <BorderWeightEditComponent objType={"tableObject"} onChange={onChange} style={props.style} />

      <BorderDashEditComponent objType={"tableObject"} onChange={onChange} style={props.style} />

      <BorderFillComponent
        objType={"tableObject"}
        setCellsBorder={props.setCellsBorder}
        onChange={onChange}
        style={props.style}
      />

      <Separator />

      <FontFamilyEditComponent onChange={onChange} objType={"tableObject"} style={props.style} />

      <FontSizeEditComponent
        className={"xxxs"}
        onChange={onChange}
        objType={"tableObject"}
        style={
          props.style && props.style.fontSize
            ? parseInt(props.style.fontSize.replace("px", "").replace("pt", ""))
            : ""
        }
      />
      {TextEditGroup("xxs")}

      <TextColorEditComponent
        header
        className={"xs"}
        onChange={onChange}
        objType={"tableObject"}
        color={props.style.color}
      />

      <HighlightColorEditComponent
        header
        className={"xs"}
        onValueChange={props.onValueChange}
        objType={"table"}
        value={props.value}
        onChange={onChange}
        style={props.style}
      />
      {/*<Separator/>*/}
      <AlignEditComponent
        className={"s"}
        menu
        onChange={onChange}
        style={props.style}
        objType={"table"}
      />
      <SpacingEditComponent
        className={"s"}
        objType={"table"}
        onChange={onChange}
        menu
        style={props.style}
        rowStyle={props.selectedRowsStyle}
      />
      <BulletingEditComponent
        className={"m"}
        menu
        onValueChange={props.onValueChange}
        objType={"table"}
        value={props.value}
        onChange={onChange}
        style={props.style}
        setBullet={setBullet}
      />
      <NumberingEditComponent
        className={"m"}
        menu
        onValueChange={props.onValueChange}
        objType={"table"}
        value={props.value}
        onChange={onChange}
        style={props.style}
        setBullet={setBullet}
      />
      <ButtonMui
        isIconButton
        tooltipText={"Decrease Indent"}
        className={"l"}
        onClick={(e) => decreaseIndent()}
        variant={"text"}
        roundnessType={"squared"}
        color={"black"}
        label={<DecreaseIndent />}
      />
      <ButtonMui
        isIconButton
        tooltipText={"Increase Indent"}
        className={"xl"}
        onClick={(e) => increaseIndent()}
        variant={"text"}
        roundnessType={"squared"}
        color={"black"}
        label={<IncreaseIndent />}
      />
      <ButtonMui
        isIconButton
        tooltipText={"Clear Formatting"}
        className={"xl"}
        onClick={(e) => removeStyle()}
        variant={"text"}
        roundnessType={"squared"}
        color={"black"}
        label={<ClearFormatting />}
      />
      <Separator className={"xxs"} />
      <ButtonMui
        tooltipText={"Format options"}
        className={"xxl"}
        onClick={props.toggleFormatOptions}
        variant={"text"}
        roundnessType={"squared"}
        color={"black"}
        label={"Format options"}
      />

      <MoreOptionsMenuComponent
        onStyleRemove={props.onStyleRemove}
        onValueChange={props.onValueChange}
        value={props.value}
        onChange={onChange}
        style={props.style}
        increaseIndent={increaseIndent}
        decreaseIndent={decreaseIndent}
        changeLineHeight={changeLineHeight}
        removeStyle={removeStyle}
        objType={"table"}
        setBullet={setBullet}
        width={props.width}
        toggleFormatOptions={props.toggleFormatOptions}
      >
        <FontSizeEditComponent
          className={"m_o_xxxs"}
          onChange={onChange}
          objType={"tableObject"}
          style={
            props.style && props.style.fontSize
              ? parseInt(props.style.fontSize.replace("px", "").replace("pt", ""))
              : ""
          }
        />
        {TextEditGroup("m_o_xxs")}

        <TextColorEditComponent
          className={"m_o_xs"}
          onChange={onChange}
          objType={"tableObject"}
          color={props.style.color}
        />

        <HighlightColorEditComponent
          className={"m_o_xs"}
          onValueChange={props.onValueChange}
          objType={"table"}
          value={props.value}
          onChange={onChange}
          style={props.style}
        />
      </MoreOptionsMenuComponent>
      <Separator />
    </Fragment>
  );
}

export default HeaderTable;
