import React from "react";
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-dragdata";
import ChartDataLabels from "chartjs-plugin-datalabels";
import PropTypes from "prop-types";
import { DataTypes } from "../ChartComponents/DataTypes";

import {
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { ClusteredHorizontalBar } from "../../components/IconButtons/SubMenuIcons";
import HorizontalBarChart from "./HorizontalBarChart";
import StackedBarChart from "./StackedBarChart";

ChartJS.register(
  CategoryScale,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
);

class HorizontalClusteredBarChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        labels: [],
        datasets: [],
      },
      options: this.buildOptions(this.props.chartOptions),
    };

    this.ref = null;
  }

  componentDidMount() {
    this.buildData();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps != this.props && !this.imgSrc) {
      this.ref.options = this.buildOptions(nextProps.chartOptions);
      this.buildData(nextProps);
      this.ref.update();
    }

    return true;
  }

  buildOptions = (props) => {
    if (this.props.preview && this.props.businessPlan) {
      if (window.chartsForRender.indexOf(this.buildOptions) === -1) {
        window.chartsForRender.push(this.buildOptions);
      }
    }

    let options = {
      type: "bar",
      barRoundness: 0.3,
      devicePixelRatio: 2.0,
      maintainAspectRatio: props.lockedAspect ? props.lockedAspect : false,
      indexAxis: "y",
      layout: {
        padding: {
          right: 80,
          left: 5,
          bottom: 5,
        },
      },
      animation: {
        duration: 1,
        onComplete: (animation) => {
          if (this.props.preview && this.props.businessPlan && this.ref) {
            this.imgSrc = this.ref.toBase64Image();
            this.forceUpdate(() => {
              window.amountOfChartsRendered++;
              global.Modeliks.NotifySubsctiptions("onChartRenderFinish");
            });
          }
        },
      },
      scales: {
        x: {
          grid: {
            display: props.xGrid ? props.xGrid : false,
            drawBorder: props.xGrid ? props.xGrid : true,
          },
          ticks: {
            display: props.showHorizontalAxis,
            color: "#889299",
            font: {
              weight: 400,
              style: "normal",
              size: props.axisFontSize,
              lineHeight: "160%",
            },
          },
        },
        y: {
          grid: {
            display: props.yGrid ? props.yGrid : false,
            drawBorder: props.xGrid ? props.xGrid : true,
          },
          ticks: {
            display: props.showVerticalAxis,
            color: "#889299",
            font: {
              weight: 400,
              style: "normal",
              size: props.axisFontSize,
              lineHeight: "160%",
            },
          },
        },
      },
      plugins: {
        tooltip: {
          titleFont: {
            size: 14,
          },
          bodyFont: {
            size: 14,
          },
        },
        title: {
          display: props.showTitle ? props.showTitle : false,
          text: props.title ? props.title : "",
          align: props.titleAlign ? props.titleAlign : "center",
          color: "#000000",

          font: {
            size: props.titleFontSize,
          },
          padding: {
            top: 5,
            bottom: props.showSubtitle && props.showSubtitle === true ? 0 : 5,
          },
        },
        subtitle: {
          display: props.showSubtitle ? props.showSubtitle : false,
          text: props.subtitle ? props.subtitle : "",
          align: props.titleAlign ? props.titleAlign : "center",
          padding: {
            bottom: 5,
          },
          font: {
            size: props.subTitleFontSize,
          },
        },
        datalabels: {
          anchor: "end",
          align: "end",
          formatter: function (value, ctx) {
            if (props.showDataLabels) {
              let val = -1;
              let sign = "";

              if (value > 999999999) {
                val = value / 1000000000;
                sign = "B";
              } else if (value > 999999) {
                val = value / 1000000;
                sign = "M";
              } else if (value > 999) {
                val = value / 1000;
                sign = "K";
              } else {
                val = value;
              }

              return (
                (props.dataType === "currency" ? DataTypes[props.dataType] : "") +
                val.toLocaleString(undefined, {
                  minimumFractionDigits: props.datalabelsDecimalPoints,
                  maximumFractionDigits: props.datalabelsDecimalPoints,
                }) +
                sign +
                (props.dataType === "percent" ? DataTypes[props.dataType] : "")
              );
            } else {
              return "";
            }
          },
          color: "#252525",
          font: {
            size: props.dataLabelsFontSize,
            weight: "700",
            lineHeight: "17px",
          },
        },
        legend: {
          display: props.displayLegend ? props.displayLegend : false,
          position: props.legendPosition ? props.legendPosition : "bottom",
          align: "center",
          labels: {
            usePointStyle: true,
            fontSize: props.legendFontSize,
          },
        },
      },
    };

    return options;
  };

  buildData = (nextProps = null) => {
    let labels = [];
    let datasets = [];
    let colors = this.props.colors;
    let rows = this.props.data;

    rows.forEach((row, index) => {
      if (index !== 0) {
        labels.push(row[0]);
      }
    });

    rows[0].forEach((ser, index) => {
      if (index !== 0) {
        let obj = {
          label: "",
          backgroundColor: "",
          categoryPercentage:
            nextProps && nextProps.chartOptions.categoryGapWidth
              ? nextProps.chartOptions.categoryGapWidth / 10
              : this.props.chartOptions.categoryGapWidth
                ? this.props.chartOptions.categoryGapWidth / 10
                : 0.6,
          barPercentage:
            nextProps && nextProps.chartOptions.gapWidth
              ? nextProps.chartOptions.gapWidth / 10
              : this.props.chartOptions.gapWidth
                ? this.props.chartOptions.gapWidth / 10
                : 0.3,
          minBarLength: 2,
          borderRadius: 5,
          data: [],
        };
        obj.label = ser;
        datasets.push(obj);
      }
    });

    for (let i = 1; i < rows[0].length; i++) {
      if (i - 1 >= colors.length) {
        colors = [...colors, ...colors];
        this.props.changeColors(colors);
      }
      let data = [];
      rows.forEach((row, index) => {
        if (index !== 0) {
          let parsedData = parseFloat(row[i]);
          if (parsedData % 1 === 0) {
            data.push(parseInt(row[i]));
          } else {
            data.push(parsedData);
          }
          // data.push(row[i]);
        }
      });
      datasets[i - 1].data = data;
      datasets[i - 1].backgroundColor = colors[i - 1];
    }

    this.state.data.datasets = datasets;
    this.state.data.labels = labels;
  };

  setRef = (ref) => {
    this.ref = ref;
  };

  render() {
    if (this.imgSrc) {
      return <img src={this.imgSrc} style={{ height: "100%", width: "100%" }} />;
    }
    return (
      <Bar
        type={"bar"}
        data={this.state.data}
        ref={this.setRef}
        options={this.state.options}
        style={{
          background: this.props.chartOptions.backgroundColor,
          border: `2px solid ${this.props.chartOptions.borderColor}`,
        }}
      />
    );
  }
}

HorizontalClusteredBarChart.config = {
  data: [
    ["", "ser1", "ser2", "ser3"],
    ["Label 1", "1", "2", "3"],
    ["Label 2", "1", "2", "3"],
  ],
  colors: ["red", "green", "blue", "yellow"],
  dataGridConfig: {
    title: "blabla",
    subtitle: "blabla",
    canAddRows: true,
    canAddColumns: true,
    hasColumnHeaders: true,
    hasRowHeaders: true,
    RowHeader: "New label",
    ColumnHeader: "New series",
    rowSeries: true,
  },
  dataLalbelsConfig: {
    hideSeries: false,
    dataLabelsOptions: false,
  },
  legendAndGridlinesConfig: {
    hasGridlines: true,
  },
  gapWidthConfig: {
    haveGapWidth: true,
    haveCategoryGapWidth: true,
  },
  defaultConfig: {
    gapWidth: 5,
    categoryGapWidth: 7,
  },
};

HorizontalClusteredBarChart.propTypes = {
  data: PropTypes.array.isRequired,
  colors: PropTypes.array.isRequired,
};

export default HorizontalClusteredBarChart;
