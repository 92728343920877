import React from "react";
import { IconX, IconI, TrashCan, TrashCan2 } from "../../../../../../../components/icons/svgIcons";
import BorderedWhiteCard from "../BorderedWhiteCard";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Paragraph } from "../../../../../../../components/typography/paragraph/Paragraph";
import Mx_NumInput from "../../../../../../../components/actions/Mx_NumInput";
import {
  DriverDescriptions,
  UnitTypes,
} from "../../../../../../../data/Finance/CalculatedDriver/constants";
import Mx_Tooltip from "../../../../../../../components/icons/Mx_Tooltip_Click";
import { DialogTypes } from "../../../../../../../data/Finance/constants";
import { PERMISSIONS } from "../../../../../../../data/Permissions/Permissions";

class FormulaBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dragItem: null,
    };
  }

  addToFormula = (index, driver) => {
    let limit =
      this.props.Formula.filter((d) => d.ID).length >=
      PERMISSIONS.Financials.restrictions.MaxStreamsCount.TotalDriverPerFormula;
    if (limit) {
      global.Modeliks.showDialog(
        `
            You have reached the maximum number of [${PERMISSIONS.Financials.restrictions.MaxStreamsCount.TotalDriverPerFormula}] Drivers in Formula. 
            Please reorganize your Drivers in Formula. \n Click outside of the box to continue with your work.`,
        DialogTypes.WarningSimpleText,
        () => {
          // this.setState({AddForm: false})
        },
      );
    } else {
      if (driver.isArray) {
        this.props.Formula.push(driver);
        this.props.Formula.push(this.props.HiddenOperators[0]);
        this.forceUpdate();
      } else {
        this.props.Formula.push(driver);
        this.forceUpdate();
      }
    }
  };

  onDragEnd = (result) => {
    if (result.destination) {
      let arr = this.props.Formula;
      if (result.destination.index < result.source.index) {
        this.props.Formula.splice(result.destination.index, 0, arr[result.source.index]);
        this.props.Formula.splice(result.source.index + 1, 1);
        this.forceUpdate();
      } else {
        let temp = arr[result.source.index];
        this.props.Formula.splice(result.source.index, 1);
        this.props.Formula.splice(result.destination.index, 0, temp);
        this.forceUpdate();
      }
    } else {
      this.props.Formula.splice(result.source.index, 1);
      this.forceUpdate();
    }
  };

  getItemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,
    ...(isDragging && {
      background: "rgb(235,235,235)",
    }),
  });

  onChangeComplexInput = (item, value) => {
    item.value = value;
    this.forceUpdate();
  };

  removeComplexOperator = (item, index) => {
    if (item && (index !== null || index !== undefined)) {
      this.props.Formula.splice(
        index,
        this.props.Formula.findIndex((f, i) => i > index && f.isDragDisabled) + 1,
      );
      this.forceUpdate();
    }
  };

  complexOperator = (item, index) => {
    if (item.hasInput) {
      return (
        <div className="spsccc_complex_operator_div">
          {item.Name}
          <Mx_NumInput
            isDialogInputSmall={false}
            error={false}
            isDialogInput={true}
            singleInput={true}
            selected={true}
            unit={null}
            isMultipleSelected={false}
            isEditable={true}
            onChange={(e) => this.onChangeComplexInput(item, e)}
            data={item.value}
          />
        </div>
      );
    } else if (item.isArray) {
      return (
        <div className="spsccc_operator_icon">
          <TrashCan2
            className="spscc_trash_icon"
            onClick={() => this.removeComplexOperator(item, index)}
            width={"12px"}
            height={"12px"}
          />
          <div className={`spscc_formula_name ${item.className}`}>{item.Name}</div>
        </div>
      );
    }

    return item.Name;
  };

  render() {
    return (
      <>
        <div className="psfccc_formulabox_title">
          <BorderedWhiteCard
            visible={true}
            className="create_formula parent no_white no_margin_padding"
          >
            <div className="formula_buttons_row">
              <Paragraph
                size="p1_strong"
                label={
                  <span>
                    Create Formula
                    <Mx_Tooltip description={DriverDescriptions.SetFormula} />
                  </span>
                }
              />
              <div className="formula_buttons">
                <span className="buttons_names">Operators</span>
                {this.props.Operators.map((driver, index) => {
                  return (
                    <div
                      key={`operator_${driver.DriverName}_${index}`}
                      onClick={() => this.addToFormula(index, driver)}
                      className={`button white small ${driver.complex && "complex"}`}
                    >
                      {driver.DriverName}
                    </div>
                  );
                })}
              </div>
            </div>
          </BorderedWhiteCard>
        </div>
        <BorderedWhiteCard visible={true} className="create_formula parent">
          <div className="formula_buttons_row">
            <div className="formula_buttons">
              <span className="buttons_names">Drivers</span>
              {this.props.drivers.map((driver, index) => (
                <div
                  key={`driver_to_add_${driver.ID}`}
                  onClick={() => this.addToFormula(index, driver)}
                  className="button"
                >
                  {driver.DriverName}
                </div>
              ))}
            </div>
          </div>
          <div className="formula_row">
            <span className="buttons_names revenue_type">{this.props.title}=</span>
            <DragDropContext onDragEnd={(e) => this.onDragEnd(e)}>
              <Droppable droppableId="droppable" direction="horizontal">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className={this.props.error ? "blue_container error" : "blue_container"}
                  >
                    {this.props.Formula.map((item, index) => {
                      return (
                        <Draggable
                          key={`draggable_${item.DriverName}_${index}`}
                          draggableId={`dropId_${item.DriverName}_${index}`}
                          index={index}
                          isDragDisabled={item.isDragDisabled}
                        >
                          {(provided, snapshot) => (
                            <div
                              key={`formula_${item.DriverName}_${index}`}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...this.getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style,
                                ),
                              }}
                              className={`${item.className} ${item.hasOwnProperty("ID") || item.hasOwnProperty("complex") ? "button " : "small button"}`}
                            >
                              {!item.complex ? item.DriverName : this.complexOperator(item, index)}
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </BorderedWhiteCard>
      </>
    );
  }
}

export default FormulaBox;
