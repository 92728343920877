import CalculatedDriver from "../../CalculatedDriver/index";
import { DriverCategories, UnitTypes } from "../../CalculatedDriver/constants";
import Reports from "../index";
import { ReportsTypes } from "../constants";
import Revenue from "../../Revenue";
import CostSales from "../../CostSales";
import Assets from "../../Assets";
import Financing from "../../Financing";
import Expense from "../../Expense";
import IncomeTax from "../../Taxes/IncomeTax";

class ProfitLoss extends Reports {
  Name = "";
  ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
  ReportType = ReportsTypes.ProfitLoss;
  ReportsType = ReportsTypes.ProfitLoss;

  constructor(db_record) {
    super(db_record);
    if (this.db_record) {
      this.Name = this.db_record.Name;
      this.ReportType = this.db_record.ReportType;
      // this.setReport()
    }
  }

  static DriversDesc = Object.assign({
    Total: {
      driverName: "Profit and Loss",
      fieldName: "Totals",
      driverID: "total",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    GrossProfit: {
      driverName: "Gross Profit",
      fieldName: "GrossProfit",
      driverID: "gross_profit",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    EBITDA: {
      driverName: "EBITDA",
      fieldName: "EBITDA",
      driverID: "ebitda",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    OperatingProfit: {
      driverName: "Operating Profit",
      fieldName: "OperatingProfit",
      driverID: "operating_profit",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    ProfitBeforeTax: {
      driverName: "Profit Before Tax",
      fieldName: "ProfitBeforeTax",
      driverID: "profit_before_tax",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    NetIncome: {
      driverName: "Net Income",
      fieldName: "NetIncome",
      driverID: "net_income",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    OperatingExpenses: {
      driverName: "Operating Expenses",
      fieldName: "OperatingExpenses",
      driverID: "operating_expenses",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    RevenueGrowth: {
      driverName: "Revenue Growth",
      fieldName: "RevenueGrowth",
      driverID: "revenue_growth",
      unit: UnitTypes.Percentage,
      category: DriverCategories.Average,
    },
    GrossProfitMargin: {
      driverName: "Gross Profit Margin",
      fieldName: "GrossProfitMargin",
      driverID: "margin_gross_profit",
      unit: UnitTypes.Percentage,
      category: DriverCategories.Average,
    },
    EBITDAMargin: {
      driverName: "EBITDA Margin",
      fieldName: "EBITDAMargin",
      driverID: "margin_ebitda",
      unit: UnitTypes.Percentage,
      category: DriverCategories.Average,
    },
    OperatingProfitMargin: {
      driverName: "Operating Profit Margin",
      fieldName: "OperatingProfitMargin",
      driverID: "margin_operating_profit",
      unit: UnitTypes.Percentage,
      category: DriverCategories.Average,
    },
    ProfitBeforeTaxMargin: {
      driverName: "Profit Before Tax Margin",
      fieldName: "ProfitBeforeTaxMargin",
      driverID: "margin_profit_before_tax",
      unit: UnitTypes.Percentage,
      category: DriverCategories.Average,
    },
    NetIncomeMargin: {
      driverName: "Net Income Margin",
      fieldName: "NetIncomeMargin",
      driverID: "margin_net_income",
      unit: UnitTypes.Percentage,
      category: DriverCategories.Average,
    },
    TotalCOGSAndOPEX: {
      driverName: "Total COGS and OPEX",
      fieldName: "TotalCOGSAndOPEX",
      driverID: "total_cogs_and_opex",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    TotalCostsAndExpenses: {
      driverName: "Total Costs and Expenses",
      fieldName: "TotalCostsAndExpenses",
      driverID: "total_costs_and_expenses",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
  });
  getMonthDatesAll = () => {
    return [
      ...global.Modeliks.DateHelper.months,
      ...global.Modeliks.DateHelper.months_before_actual,
    ];
  };

  getYearDatesAll = () => {
    return [
      ...global.Modeliks.DateHelper.years_all,
      ...global.Modeliks.DateHelper.years_before_actual,
    ];
  };
  periodsData = {};

  buildPeriodsData = () => {
    const allPeriods = this.Totals.Values.map((c) => c.Date);
    allPeriods.forEach((period) => {
      this.periodsData[period.dateID] = {};
      Object.values(this.constructor.DriversDesc).forEach(
        (driver) =>
          (this.periodsData[period.dateID][driver.fieldName] = this[
            driver.fieldName
          ].getItemByDateSufix(period.sufix)),
      );
      this.periodsData[period.dateID].Date = period;
    });
  };

  setReport = () => {
    this.buildPeriodsData();
    this.setFormulas();
  };

  setFormulas = () => {
    this.createMonthsFormulas();
    this.createYearsFormulas();
  };

  createMonthsFormulas = () => {
    const months = this.getMonthDatesAll();
    const totalRevenues = Revenue.getRevenueTotals();
    const totalCostSale = CostSales.getCostSalesTotals();
    const totalOperatingExpenses = Expense.getOperatingExpenses();
    const totalDepreciation = Assets.getDepreciation();
    const totalInterestExpense = Financing.getInterestExpense();
    const IncomeTax = global.Modeliks.DriversStore.find(
      (d) => d.Ref_Field === "accrued_income_tax",
    );
    const AssetGainAndLoss = Assets.getAssetGainAndLoss();

    months.forEach((month) => {
      const curMonth = this.periodsData[month.dateID];
      curMonth.GrossProfit.Formula = `${totalRevenues.getItemByDateSufix(month.sufix)} - ${totalCostSale.getItemByDateSufix(month.sufix)}`;
      curMonth.GrossProfitMargin.Formula = `(${curMonth.GrossProfit} / ${totalRevenues.getItemByDateSufix(month.sufix)}) * 100`;
      curMonth.EBITDA.Formula = `${curMonth.GrossProfit} - ${totalOperatingExpenses.getItemByDateSufix(month.sufix)} + ${AssetGainAndLoss.getItemByDateSufix(month.sufix)} `;
      curMonth.EBITDAMargin.Formula = `(${curMonth.EBITDA} / ${totalRevenues.getItemByDateSufix(month.sufix)}) * 100`;
      curMonth.OperatingProfit.Formula = `${curMonth.EBITDA} - ${totalDepreciation.getItemByDateSufix(month.sufix)}`;
      curMonth.OperatingProfitMargin.Formula = `(${curMonth.OperatingProfit} / ${totalRevenues.getItemByDateSufix(month.sufix)}) * 100`;
      curMonth.ProfitBeforeTax.Formula = `${curMonth.OperatingProfit} - ${totalInterestExpense.getItemByDateSufix(month.sufix)}`;
      curMonth.ProfitBeforeTaxMargin.Formula = `(${curMonth.ProfitBeforeTax} / ${totalRevenues.getItemByDateSufix(month.sufix)}) * 100`;

      if (IncomeTax) {
        curMonth.NetIncome.Formula = `${curMonth.ProfitBeforeTax} - ${IncomeTax.getItemByDateSufix(month.sufix)}`;
      } else {
        curMonth.NetIncome.Formula = `${curMonth.ProfitBeforeTax}`;
      }

      curMonth.NetIncomeMargin.Formula = `(${curMonth.NetIncome} / ${totalRevenues.getItemByDateSufix(month.sufix)}) * 100`;

      curMonth.TotalCOGSAndOPEX.Formula = `${curMonth.GrossProfit} + ${totalOperatingExpenses.getItemByDateSufix(month.sufix)}`;
      curMonth.TotalCostsAndExpenses.Formula = `${totalRevenues.getItemByDateSufix(month.sufix)} - ${curMonth.NetIncome}`;
    });
  };

  createYearsFormulas = () => {
    const years = this.getYearDatesAll();
    const totalRevenues = Revenue.getRevenueTotals();
    const totalCostSale = CostSales.getCostSalesTotals();
    const totalOperatingExpenses = Expense.getOperatingExpenses();
    const totalDepreciation = Assets.getDepreciation();
    const totalInterestExpense = Financing.getInterestExpense();
    const IncomeTax = global.Modeliks.DriversStore.find(
      (d) => d.Ref_Field === "accrued_income_tax",
    );
    const AssetGainAndLoss = Assets.getAssetGainAndLoss();

    years.forEach((year) => {
      const curYear = this.periodsData[year.dateID];

      curYear.GrossProfit.Formula = `${totalRevenues.getItemByDateSufix(year.sufix)} - ${totalCostSale.getItemByDateSufix(year.sufix)}`;
      curYear.GrossProfitMargin.Formula = `(${curYear.GrossProfit} / ${totalRevenues.getItemByDateSufix(year.sufix)}) * 100`;
      curYear.EBITDA.Formula = `${curYear.GrossProfit} - ${totalOperatingExpenses.getItemByDateSufix(year.sufix)} + ${AssetGainAndLoss.getItemByDateSufix(year.sufix)} `;
      curYear.EBITDAMargin.Formula = `(${curYear.EBITDA} / ${totalRevenues.getItemByDateSufix(year.sufix)}) * 100`;
      curYear.OperatingProfit.Formula = `${curYear.EBITDA} - ${totalDepreciation.getItemByDateSufix(year.sufix)}`;
      curYear.OperatingProfitMargin.Formula = `(${curYear.OperatingProfit} / ${totalRevenues.getItemByDateSufix(year.sufix)}) * 100`;
      curYear.ProfitBeforeTax.Formula = `${curYear.OperatingProfit} - ${totalInterestExpense.getItemByDateSufix(year.sufix)}`;
      curYear.ProfitBeforeTaxMargin.Formula = `(${curYear.ProfitBeforeTax} / ${totalRevenues.getItemByDateSufix(year.sufix)}) * 100`;

      if (IncomeTax) {
        curYear.NetIncome.Formula = `${curYear.ProfitBeforeTax} - ${IncomeTax.getItemByDateSufix(year.sufix)}`;
      } else {
        curYear.NetIncome.Formula = `${curYear.ProfitBeforeTax}`;
      }
      curYear.NetIncomeMargin.Formula = `(${curYear.NetIncome} / ${totalRevenues.getItemByDateSufix(year.sufix)}) * 100`;

      curYear.TotalCOGSAndOPEX.Formula = `${curYear.GrossProfit} + ${totalOperatingExpenses.getItemByDateSufix(year.sufix)}`;
      curYear.TotalCostsAndExpenses.Formula = `${totalRevenues.getItemByDateSufix(year.sufix)} - ${curYear.NetIncome}`;
    });
  };

  getReportTotals = () => {
    const driver = ProfitLoss.getReport();

    return [
      Revenue.getRevenueTotals(),
      Revenue.getRevenueGrowth(Revenue.getRevenueTotals()),
      CostSales.getCostSalesTotals(),
      driver.GrossProfit,
      driver.GrossProfitMargin,
      Expense.getOperatingExpenses(),
      Assets.getAssetGainAndLoss(),
      driver.EBITDA,
      driver.EBITDAMargin,
      Assets.getDepreciation(),
      driver.OperatingProfit,
      driver.OperatingProfitMargin,
      Financing.getInterestExpense(),
      driver.ProfitBeforeTax,
      driver.ProfitBeforeTaxMargin,
      global.Modeliks.DriversStore.find(
        (d) => d.Ref_Field === IncomeTax.DriversDesc.AccruedIncomeTax.driverID,
      ),
      driver.NetIncome,
      driver.NetIncomeMargin,
    ].filter((d) => d);
  };

  static createProfitBeforeTax = () => {
    const newReport = new ProfitLoss();
    newReport.ID = "new" + 10009;
    CalculatedDriver.createDriverFromTable(
      newReport,
      ProfitLoss.DriversDesc.ProfitBeforeTax.driverID,
      UnitTypes.Price,
      DriverCategories.Sum,
    );
  };

  static createNetIncome = () => {
    const newReport = new ProfitLoss();
    newReport.ID = "new" + 10009;
    CalculatedDriver.createDriverFromTable(
      newReport,
      ProfitLoss.DriversDesc.NetIncome.driverID,
      UnitTypes.Price,
      DriverCategories.Sum,
    );
  };

  static getReport = () => {
    const driver = global.Modeliks.ReportsStore.find(
      (d) => d.ReportsType === ReportsTypes.ProfitLoss,
    );
    if (driver) {
      driver.setFormulas();
      return driver;
    } else {
      const newReport = new ProfitLoss();
      newReport.ID = "profit_and_loss_report";
      newReport.Name = "Profit and Loss";
      newReport.ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
      newReport.ReportType = ReportsTypes.ProfitLoss;
      newReport.ReportsType = ReportsTypes.ProfitLoss;
      newReport.Totals = CalculatedDriver.createDriverFromTable(
        newReport,
        ProfitLoss.DriversDesc.Total.driverID,
        UnitTypes.Price,
        DriverCategories.Sum,
        true,
      );
      newReport.createDrivers();
      newReport.changeDriversName();
      newReport.setReport();

      Object.keys(ProfitLoss.DriversDesc).forEach((key) => {
        if (key !== "Total" && key !== "RevenueGrowth" && key !== "OperatingExpenses") {
          newReport[key].IsExisting = true;
        }
      });

      global.Modeliks.ReportsStore.push(newReport);
      return newReport;
    }
  };
}

export default ProfitLoss;
