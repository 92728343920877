import React from "react";
import { Paragraph } from "../../../../../../components/typography/paragraph/Paragraph";
import PropTypes from "prop-types";
import "./CalculatedDriverTitle.scss";
import ButtonMui from "../../../../../../components/buttons/buttonMui/buttonMui";
import UpdatePlan from "../../../../../../components/dialogs/LimitDialogs/UpdatePlan";
import { AccessControl } from "../../../../../../data/Permissions/AccessControl";
import CalculatedDriver from "../../CalculatedDriver/CalculatedDriver";
import CheckBoxMaterial from "../../../../../../components/actions/CheckBoxMaterial";

const FormulaBar = (props) => {
  const { driver } = props;
  if (driver && driver.Formula && !driver.Formula.includes("growth")) {
    return (
      <div style={{ display: "flex", columnGap: "10px", alignItems: "center" }}>
        <div style={{ paddingBottom: "4px" }}>=</div>
        <CalculatedDriver
          onClickSingleTabFormula={(d) => props.scroll(d)}
          formula={driver.Formula}
          SingleTabFormula={true}
        />
      </div>
    );
  }
};

class CalculatedDriverTitle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      limitActive: false,
    };
  }

  changeActuals = () => {
    this.props.selectedTab.showActuals = !this.props.selectedTab.showActuals;
    this.props.onDataChanged();
  };

  render() {
    return (
      <>
        {this.state.limitActive && (
          <UpdatePlan
            handleClose={() => this.setState({ limitActive: false })}
            open={this.state.limitActive}
          />
        )}
        <div className="pscfc_single_finance_calculated_driver_title">
          <div
            style={{
              maxWidth: "calc(100% - 264px)",
              paddingRight: "20px",
              boxSizing: "border-box",
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "auto auto",
                overflow: "hidden",
                columnGap: "10px",
                maxWidth: "100%",
              }}
            >
              {this.props.componentChange ? (
                this.props.componentChange
              ) : (
                <div
                  style={{
                    whiteSpace: "nowrap",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    paddingBottom: "8px",
                  }}
                  className={"p2Strong"}
                >
                  <span id={this.props.driver && this.props.driver.ID}>
                    <span>{this.props.title}</span>
                  </span>
                </div>
              )}
              <div className={"formula_bar_container p2Strong"}>
                <FormulaBar scroll={this.props.scroll} driver={this.props.driver} />
              </div>
            </div>
          </div>
          {/*<Paragraph size='p2_strong' label={<span id={this.props.driver && this.props.driver.ID}><span >{this.props.title}</span><FormulaBar scroll={this.props.scroll} driver={this.props.driver} /></span>}/>*/}
          <div
            className={`psfcf_single_finance_calculated_driver_button ${this.props.displayActuals && this.props.selectedTab && this.props.selectedTab.allowDisplayActuals ? "psfcf_single_finance_calculated_driver_button_actual" : ""}`}
          >
            {global.Modeliks.PERMISSIONS.Dashboards.restrictions.overAll.active &&
              this.props.displayActuals &&
              this.props.selectedTab &&
              this.props.selectedTab.allowDisplayActuals && (
                <CheckBoxMaterial
                  label={"Show Actuals"}
                  checked={this.props.selectedTab.showActuals}
                  onChange={this.changeActuals}
                  size="medium"
                  elevation={false}
                />
              )}

            <AccessControl
              allowedPermissions={[
                {
                  [global.Modeliks.PERMISSIONS.Financials.key]:
                    global.Modeliks.PERMISSIONS.Financials.restrictions.FinancialDriverFeature.key,
                },
              ]}
              renderNoAccess={
                <ButtonMui
                  width={125}
                  disabled={this.props.disabled}
                  onClick={() => this.setState({ limitActive: true })}
                  label={this.props.button}
                  color="secondary"
                  variant={"contained"}
                />
              }
            >
              <ButtonMui
                width="125px"
                label={this.props.button}
                color="secondary"
                onClick={this.props.onClick}
                variant={"contained"}
                disabled={this.props.disabled}
              />
            </AccessControl>
          </div>
        </div>
      </>
    );
  }
}

CalculatedDriverTitle.propTypes = {
  title: PropTypes.string,
  button: PropTypes.string,
  onClick: PropTypes.func,
  width: PropTypes.string,
};

export default CalculatedDriverTitle;
