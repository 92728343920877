import MxDataArray from "../../MxDataArray";
import Assets from "./index";
import AssetsDataGridRows from "./AssetsDataGridRows";
import FinancingDataGridRows from "../Financing/FinancingDataGridRows";

class AssetsStorage extends MxDataArray {
  dataManager = null;
  driverStorage = null;
  loaded = false;

  setData = (callBack, data = this.data) => {
    this.pushArray(
      data.map((item) => {
        const newItem = new Assets(item);
        if (newItem.Totals) {
          return newItem;
        } else {
          // this.dataManager.del(this.dataManager.Tables.Finance_Assets.TableName, {ID: newItem.ID}, () => {
          //     console.log('deleting',newItem);
          // }, () => {
          // });
        }
      }),
    );

    Assets.getAssetsTotals();
    Assets.getCurrentAssetsTotals();
    Assets.getAssetsCurrent();
    Assets.getAssetPayableLongTerm();
    Assets.getAssetPayableCurrent();
    Assets.getAssetPurchasesFromOpeningBalanceDrivers();
    Assets.getAssetPurchasesFromDrivers();
    Assets.getAssetSalesFromDrivers();
    Assets.getLongTermAssetPurchasesFromDrivers();
    Assets.getLongTermAssetPaymentsFromDriversNegative();
    Assets.getLongTermAssetSalesFromDrivers();
    Assets.getLongTermAssetsTotals();
    Assets.getDepreciation();
    Assets.getAssetGainAndLoss();
    Assets.getDeferredIncomeTax();
    Assets.getTotalIntangiblesDrivers();
    Assets.getTotalInvestmentsDrivers();

    this.loaded = true;
    callBack();
  };

  setGridRows = () => AssetsDataGridRows(this);

  loadAssets = (callBack, shouldFinish = true) => {
    this.clear();
    this.dataManager.get(
      this.dataManager.Tables.Finance_Assets.TableName,
      { ID_CompanyScenario: this.dataManager.CompanyScenarioInfo.ID },
      (data) => {
        if (shouldFinish) {
          this.setData(callBack, data);
        } else {
          this.data = data;
          callBack();
        }
      },
      null,
      false,
    );
  };

  static create = () => {
    const newArr = new AssetsStorage();
    newArr.pushOld = newArr.push;
    newArr.push = newArr.pushThis;

    return newArr;
  };
}

export default AssetsStorage;
