import React, { Fragment, useState } from "react";
import "../HeaderTextEditor.scss";
import "../HeaderDefault.scss";
import {
  Align,
  AlignLeft,
  AlignRight,
  AlignTop,
  AlignBottom,
  AlignCenter,
  AlignJustify,
  AlignVerticalCenter,
  Numbered,
} from "../../components/IconButtons/SubMenuIcons";
import { Menu, MenuItem, SubMenu } from "@szhsin/react-menu";
import Tooltip from "@mui/material/Tooltip";
import ButtonMui from "../../../../components/buttons/buttonMui/buttonMui";

function AlignEditComponent(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openAlign = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onChange = (style) => {
    props.onChange(style);
  };
  const AlignEdit = () => {
    if (props.menu) {
      return (
        <Menu
          className={props.className ? "align " + props.className : "align"}
          menuButton={({ open }) => (
            <ButtonMui
              tooltipText={"Align"}
              className={props.className ? props.className : ""}
              variant={"text"}
              isIconButton
              roundnessType={"squared"}
              color={"black"}
              label={<Align active={open} />}
            />
          )}
        >
          <MenuItems />
        </Menu>
      );
    } else {
      return (
        <SubMenu
          className={props.className ? "align " + props.className : "align"}
          label={
            <Tooltip arrow title="align">
              <Align />
            </Tooltip>
          }
          openTrigger="clickOnly"
          direction={"bottom"}
        >
          <MenuItems />
        </SubMenu>
      );
    }
  };

  const MenuItems = () => {
    return (
      <>
        <MenuItem
          onClick={(event) =>
            onChange({ textAlign: props.style.textAlign === "left" ? "" : "left" })
          }
        >
          <AlignLeft active={props.style.textAlign === "left"} />
        </MenuItem>
        <MenuItem
          onClick={(event) =>
            onChange({ textAlign: props.style.textAlign === "center" ? "" : "center" })
          }
        >
          <AlignCenter active={props.style.textAlign === "center"} />
        </MenuItem>
        <MenuItem
          onClick={(event) =>
            onChange({ textAlign: props.style.textAlign === "right" ? "" : "right" })
          }
        >
          <AlignRight active={props.style.textAlign === "right"} />
        </MenuItem>
        <MenuItem
          onClick={(event) =>
            onChange({ textAlign: props.style.textAlign === "justify" ? "" : "justify" })
          }
        >
          <AlignJustify active={props.style.textAlign === "justify"} />
        </MenuItem>
        <MenuItem
          onClick={(event) =>
            onChange(
              props.objType && props.objType === "table"
                ? { justifyContent: "start" }
                : { display: "flex", flexDirection: "column", justifyContent: "start" },
            )
          }
        >
          <AlignTop active={props.style.justifyContent === "start"} />
        </MenuItem>
        <MenuItem
          onClick={(event) =>
            onChange(
              props.objType && props.objType === "table"
                ? { justifyContent: "center" }
                : { display: "flex", flexDirection: "column", justifyContent: "center" },
            )
          }
        >
          <AlignVerticalCenter active={props.style.justifyContent === "center"} />
        </MenuItem>
        <MenuItem
          onClick={(event) =>
            onChange(
              props.objType && props.objType === "table"
                ? { justifyContent: "end" }
                : { display: "flex", flexDirection: "column", justifyContent: "end" },
            )
          }
        >
          <AlignBottom active={props.style.justifyContent === "end"} />
        </MenuItem>
      </>
    );
  };

  return <>{AlignEdit()}</>;
}

export default AlignEditComponent;
