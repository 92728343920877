import "../index.scss";
import CircleCheckboxM from "../../../../components/actions/CircleCheckboxM";
import Mx_NumInput from "../../../../components/actions/Mx_NumInput";
import Mx_Tooltip from "../../../../components/icons/Mx_Tooltip_Click";
import MaterialSelect from "../../../../components/actions/SelectM";
import { Paragraph } from "../../../../components/typography/paragraph/Paragraph";
import Button from "../../../../components/actions/Button";
import React, { memo } from "react";
import ButtonMui from "../../../../components/buttons/buttonMui/buttonMui";
import { IconPlus } from "../../../../components/icons/svgIcons";

const Container = (props) => (
  <div className={`spsvc_val_container${props.className ? props.className : ""}`}>
    {props.children}
  </div>
);
const ValContainer = (props) => <div className="spsvc_val_overall_container">{props.children}</div>;
const CardsContainer = (props) => <div className="spsvc_cards_container">{props.children}</div>;

const LGrayContainer = (props) => (
  <div className="spsvc_large_gray_container">
    <P>
      <Paragraph size="p4" label={props.label} />
    </P>
  </div>
);
const SGrayContainer = (props) => (
  <div className="spsvc_large_gray_container spsvc_margin_top">
    <PS>{props.children}</PS>
  </div>
);
const DInputsContainer = (props) => (
  <div className="spsvc_double_text_large_div spsvc_inputs_container">{props.children}</div>
);

const P = (props) => <div className="spsvc_padding">{props.children}</div>;
const PS = (props) => <div className="spsvc_padding_small">{props.children}</div>;
const PCB = (props) => <div className="spsvc_padding_check_box">{props.children}</div>;
const MW = (props) => <div className="spsvc_max_width_input">{props.children}</div>;

const CenterDiv = (props) => <div className="spsvc_center_div">{props.children}</div>;
const SubCenterDiv = (props) => <div className="spsvc_sub_center_div">{props.children}</div>;
const DTextDiv = (props) => <div className="spsvc_double_text_div">{props.children}</div>;
const AddCompanyShowHideDiv = (props) => (
  <div className="spsvc_add_company_show_hide_div">{props.children}</div>
);
const DTextDivL = (props) => <div className="spsvc_double_text_large_div">{props.children}</div>;
const DTextDivLP = (props) => (
  <div className="spsvc_double_text_large_div_padding">{props.children}</div>
);
const PIconH = (props) => (
  <div className="spsvc_input_icon">
    {props.title}
    <Mx_Tooltip description={props.description} />
  </div>
);

const MBox = (props) => <div className="spsvc_multi_checkboxes">{props.children}</div>;
const MarginD = (props) => <div className="spsvc_sub_center_div_margin">{props.children}</div>;

const MainHeader = (props) => <div className="spsvc_main_header">{props.children}</div>;
const Header = (props) => <div className="spsvc_hight_light_text">{props.children}</div>;
const SubHeader = (props) => <div className="spsvc_sub_light_text">{props.children}</div>;
const SubSubHeader = (props) => <div className="spsvc_sub_sub_light_text">{props.children}</div>;
const SubSubHeaderNormalText = (props) => (
  <div className="spsvc_sub_sub_light_text_normal">{props.children}</div>
);

const CB = (props) => (
  <PCB>
    <CircleCheckboxM
      checked={props.checked ? props.checked : props.value}
      onChange={props.onChange}
      name={props.name}
      value={props.value}
      label={props.label}
    />
  </PCB>
);
const CBInput = (props) => (
  <PCB>
    <CircleCheckboxM
      value={props.value}
      onChange={props.onChange}
      name={props.name}
      checked={props.checked ? props.checked : props.value}
      label={props.label}
    />
    <MW>
      <NumInput {...props.Input} />
    </MW>
  </PCB>
);
const PSInput = (props) => (
  <p className={props.className ? props.className : "spsvc_input_label_div"}>
    {props.label}
    <MW>
      <NumInput {...props.Input} />
    </MW>
  </p>
);

const NumInput = memo((props) => (
  <Mx_NumInput
    isDialogInputSmall={false}
    disabled={props.disabled}
    error={false}
    isDialogInput={true}
    singleInput={true}
    selected={true}
    isMultipleSelected={false}
    isEditable={true}
    onChange={props.onChange}
    unit={"%"}
    data={props.value}
  />
));

const PSelect = (props) => (
  <p>
    <MaterialSelect
      value={props.value}
      name={props.name}
      label={"Please Select"}
      onChange={props.onChange}
      width="292px"
      options={props.options}
    />
  </p>
);

const Card = (props) => (
  <div className="spsvc_card_body">
    <p className="spsvc_card_title">{props.title}</p>
    <p className="spsvc_card_value"> {props.value}</p>
  </div>
);

const MainAdd = (props) => (
  <div className="spsvc_main_add_div">
    <p className="spsvc__main_add_title">Peer Company List</p>
    <ButtonMui
      style={{ height: "30px", fontSize: "14px" }}
      width={200}
      disabled={props.disabled}
      endIcon={<IconPlus />}
      label={"Add New Company"}
      variant={"contained"}
      onClick={props.onClick}
    />
  </div>
);

export {
  AddCompanyShowHideDiv,
  DTextDivLP,
  SubSubHeaderNormalText,
  MainAdd,
  Card,
  CardsContainer,
  ValContainer,
  Container,
  LGrayContainer,
  Header,
  P,
  PCB,
  CenterDiv,
  SubHeader,
  SGrayContainer,
  DTextDiv,
  SubCenterDiv,
  SubSubHeader,
  MBox,
  MarginD,
  MW,
  DTextDivL,
  DInputsContainer,
  CBInput,
  CB,
  PIconH,
  PSelect,
  PSInput,
  MainHeader,
};
