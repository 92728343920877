import React, { Fragment, useState } from "react";
import { Line, Bar, Pie, Doughnut, PolarArea, Radar, Scatter, Bubble } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import { Chart } from "react-chartjs-2";
import ContentEditorPortal from "../components/Portals/ContentEditorPortal";
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";
import HeaderPortal from "../SlideHeader/HeaderPortal";
import ChartPortal from "../components/Portals/ChartEditorPortal";
import StackedBarChart from "./Charts/StackedBarChart";
import ClustedVerticalBarChart from "./Charts/ClustredVerticalBarChart";
import HorizontalClusteredBarChart from "./Charts/HorizontalClusteredBarChart";
import BarChart from "./Charts/BarChart";
import HorizontalBarChart from "./Charts/HorizontalBarChart";
import WaterfallBarChart from "./Charts/WaterfallBarChart";
import LineChart from "./Charts/LineChart";
import BubbleChart from "./Charts/BubbleChart";
import CombinedBarLineChart from "./Charts/CombinedBarLineChart";
import DoughnoutChart from "./Charts/DoughnutChart";
import PieChart from "./Charts/PieChart";
import ScatterChart from "./Charts/ScatterChart";
import StackedBarLineChart from "./Charts/StackedBarLineChart";
import RightMenuPortal from "../components/Portals/RightMenuPortal";
// import {List, Box} from "@mui/material";
import TextFitting from "../components/RightMenuComponents/TextFittingComponent";
import AdditionalOptions from "../components/RightMenuComponents/AdditionalOptionsComponent";
import { ClusteredVerticalBar, Separator } from "../components/IconButtons/SubMenuIcons";
import ChartTitleAndSubtitle from "../components/RightMenuComponents/ChartTitleAndSubtitleComponent";
import LegendAndGridlines from "../components/RightMenuComponents/LegendAndGridlines";
import DataLabels from "../components/RightMenuComponents/DataLabelsComponent";
import AxisComponent from "../components/RightMenuComponents/AxisComponent";
import ColorComponent from "../components/RightMenuComponents/ColorComponent";
import { AddRows, AddColumns, Exit } from "../components/IconButtons/SubMenuIcons";
import ChartTable from "../components/ChartTable/index";
import SizeAndRotation from "../components/RightMenuComponents/SizeAndRotationComponent";
import Tooltip from "@mui/material/Tooltip";
import Position from "../components/RightMenuComponents/PositionComponent";
import ListSubheader from "@mui/material/ListSubheader";
import { IconButton } from "@mui/material";
import List from "@mui/material/List";
import ButtonJ from "../components/Buttons/Button";
import GapWidthComponent from "../components/RightMenuComponents/GapWidthComponent";
import HeaderMidPortal from "../components/Portals/HeaderMidPortal";
import ButtonMui from "../../../components/buttons/buttonMui/buttonMui";

class ChartObject extends React.Component {
  constructor(props) {
    super(props);

    this.rightMenuFunc = {};

    this.state = {
      mounted: false,
      chartData: {
        labels: [],
        chartKey: "chart_key" + new Date().getTime(),
        data: [],
      },
      chartOptions: this.props.chartOptions ? { ...this.props.chartOptions } : {},
    };

    global.pasteListener = this.handlePaste;
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (nextProps.multipleSeleced != this.props.multipleSeleced) {
      if (nextProps.multipleSeleced) {
        global.pasteListener = this.handlePaste;
      }
    }

    if (nextProps.selected != this.props.selected) {
      if (nextProps.selected) {
        global.pasteListener = this.handlePaste;
      }
    }

    return true;
  }

  componentDidMount() {
    this.getChartConfig(this.props.chartType);
  }

  handlePaste = (event) => {
    event.preventDefault();
    this.props.slideComponentPaste(event, true);
  };

  getChartConfig = (type) => {
    let string = "";
    let obj = {};
    switch (type) {
      case 1:
        string = JSON.stringify(StackedBarChart.config);
        obj = JSON.parse(string);
        this.chartConfig = obj;
        obj.defaultConfig && this.setDefaultConfig(obj.defaultConfig);
        this.state.data = obj.data;
        this.state.colors = obj.colors;
        // this.props.onPropsChange({data: obj.data, colors: obj.colors});
        this.forceUpdate();
        break;
      case 2:
        string = JSON.stringify(ClustedVerticalBarChart.config);
        obj = JSON.parse(string);
        this.chartConfig = obj;
        obj.defaultConfig && this.setDefaultConfig(obj.defaultConfig);
        this.state.data = obj.data;
        this.state.colors = obj.colors;
        this.forceUpdate();
        break;
      case 3:
        string = JSON.stringify(HorizontalClusteredBarChart.config);
        obj = JSON.parse(string);
        this.chartConfig = obj;
        obj.defaultConfig && this.setDefaultConfig(obj.defaultConfig);
        this.state.data = obj.data;
        this.state.colors = obj.colors;
        this.forceUpdate();
        break;
      case 4:
        string = JSON.stringify(BarChart.config);
        obj = JSON.parse(string);
        this.chartConfig = obj;
        obj.defaultConfig && this.setDefaultConfig(obj.defaultConfig);
        this.state.data = obj.data;
        this.state.colors = obj.colors;
        this.forceUpdate();
        break;
      case 5:
        string = JSON.stringify(BarChart.config);
        obj = JSON.parse(string);
        this.chartConfig = obj;
        obj.defaultConfig && this.setDefaultConfig(obj.defaultConfig);
        this.state.data = obj.data;
        this.state.colors = obj.colors;
        this.forceUpdate();
        break;
      case 6:
        string = JSON.stringify(HorizontalBarChart.config);
        obj = JSON.parse(string);
        this.chartConfig = obj;
        obj.defaultConfig && this.setDefaultConfig(obj.defaultConfig);
        this.state.data = obj.data;
        this.state.colors = obj.colors;
        this.forceUpdate();
        break;
      case 7:
        string = JSON.stringify(BubbleChart.config);
        obj = JSON.parse(string);
        this.chartConfig = obj;
        obj.defaultConfig && this.setDefaultConfig(obj.defaultConfig);
        this.state.data = obj.data;
        this.state.colors = obj.colors;
        this.forceUpdate();
        break;
      case 8:
        string = JSON.stringify(CombinedBarLineChart.config);
        obj = JSON.parse(string);
        this.chartConfig = obj;
        obj.defaultConfig && this.setDefaultConfig(obj.defaultConfig);
        this.state.data = obj.data;
        this.state.colors = obj.colors;
        this.forceUpdate();
        break;
      case 9:
        string = JSON.stringify(DoughnoutChart.config);
        obj = JSON.parse(string);
        this.chartConfig = obj;
        obj.defaultConfig && this.setDefaultConfig(obj.defaultConfig);
        this.state.data = obj.data;
        this.state.colors = obj.colors;
        this.forceUpdate();
        break;
      case 10:
        string = JSON.stringify(PieChart.config);
        obj = JSON.parse(string);
        this.chartConfig = obj;
        obj.defaultConfig && this.setDefaultConfig(obj.defaultConfig);
        this.state.data = obj.data;
        this.state.colors = obj.colors;
        this.forceUpdate();
        break;
      case 11:
        string = JSON.stringify(ScatterChart.config);
        obj = JSON.parse(string);
        this.chartConfig = obj;
        obj.defaultConfig && this.setDefaultConfig(obj.defaultConfig);
        this.state.data = obj.data;
        this.state.colors = obj.colors;
        this.forceUpdate();
        break;
      case 12:
        string = JSON.stringify(StackedBarLineChart.config);
        obj = JSON.parse(string);
        this.chartConfig = obj;
        obj.defaultConfig && this.setDefaultConfig(obj.defaultConfig);
        this.state.data = obj.data;
        this.state.colors = obj.colors;
        this.forceUpdate();
        break;
      case 13:
        string = JSON.stringify(LineChart.config);
        obj = JSON.parse(string);
        this.chartConfig = obj;
        obj.defaultConfig && this.setDefaultConfig(obj.defaultConfig);
        this.state.data = obj.data;
        this.state.colors = obj.colors;
        this.forceUpdate();
        break;
      case 14:
        string = JSON.stringify(WaterfallBarChart.config);
        obj = JSON.parse(string);
        this.chartConfig = obj;
        obj.defaultConfig && this.setDefaultConfig(obj.defaultConfig);
        this.state.data = obj.data;
        this.state.colors = obj.colors;
        this.forceUpdate();
        break;
    }

    if (this.props.colors) {
      this.state.colors = this.props.colors;
    }
    if (this.props.data) {
      this.state.data = this.props.data;
    }
    this.forceUpdate();

    this.setState({ mounted: true });
  };

  // shouldComponentUpdate = (nextProps, nextState) => {
  //     if (nextProps.dragging || nextProps.resizing || nextProps.rotating) {
  //         return false;
  //     }
  //
  //     return true;
  // }

  setDefaultConfig = (obj) => {
    let keys = Object.keys(obj);
    keys.forEach((key) => {
      if (!this.props.chartOptions[key]) {
        this.state.chartOptions[key] = obj[key];
      }
    });
  };

  handleChartOptionsChange = (props, shouldPushChange = true) => {
    const newOptions = Object.assign({ ...this.state.chartOptions }, props);
    this.props.onPropsChange({ chartOptions: newOptions }, "props", shouldPushChange, {
      prevChange: { ...this.state.chartOptions },
      nextChange: newOptions,
    });
    this.setState({ chartOptions: newOptions });
  };

  handleTableOpen = () => {
    // document.getElementById('chart_editor').style.display = this.state.chartOptions ? 'none' : 'block';
    this.setState({ openTable: !this.state.openTable });
  };

  changeData = (data, oldData) => {
    this.state.data = data;
    this.props.onPropsChange({ data: data }, "props", true, {
      nextChange: data,
      prevChange: oldData,
    });
    this.setState({ chartKey: "chart" + new Date().getTime() });
    this.forceUpdate();
  };

  changeColors = (colors) => {
    this.state.colors = colors;
    this.props.onPropsChange({ colors: colors });
    this.forceUpdate();
  };

  changeColorAtIndex = (index, color) => {
    let colors = [...this.state.colors];
    colors[index] = color;
    this.state.colors = colors;
    this.props.onPropsChange({ colors: colors });
    this.forceUpdate();
  };

  render() {
    return (
      <Fragment>
        {
          <ChartPortal visible={this.state.openTable && this.props.selected}>
            <div
              className="chart_editor_contaier"
              onMouseDown={(e) => {
                e.stopPropagation();
              }}
            >
              {this.state.mounted && (
                <ChartTable
                  update={() => this.forceUpdate()}
                  handleChartOptions={this.handleChartOptionsChange}
                  changeData={this.changeData}
                  handleTableOpen={this.handleTableOpen}
                  colors={this.state.colors}
                  dataType={this.props.chartOptions.dataType}
                  chartTitle={this.props.chartOptions.title}
                  chartSubtitle={this.props.chartOptions.subtitle}
                  changeColorAtIndex={this.changeColorAtIndex}
                  data={this.state.data}
                  {...this.chartConfig.dataGridConfig}
                />
              )}
            </div>
          </ChartPortal>
        }
        {this.props.formatOptions && this.props.selected && (
          <RightMenuPortal>
            <div className={"right_menu_mask"} onMouseDown={(e) => e.stopPropagation()}>
              <List
                style={{
                  paddingLeft: "0",
                  position: "absolute",
                  width: "100%",
                  height: "auto",
                  maxWidth: 250,
                  bgcolor: "background.paper",
                  paddingBottom: "0",
                  paddingTop: "0",
                  // overflow:'scroll',
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <span style={{ fontWeight: 700, fontSize: "16px", textAlign: "left" }}>
                        Format Options
                      </span>
                      <IconButton onClick={this.props.toggleFormatOptions}>
                        <Exit />
                      </IconButton>
                    </div>
                  </ListSubheader>
                }
              >
                <SizeAndRotation
                  changeLockedAspect={this.props.changeLockedAspect}
                  rightMenuFunc={this.rightMenuFunc}
                  lockedAspect={this.props.lockedAspect}
                  onChange={this.props.onUpdateSizeAndRotation}
                  style={this.props.style}
                  width={this.props.width}
                  height={this.props.height}
                  rotateAngle={this.props.rotateAngle}
                  scaleX={this.props.scaleX}
                  scaleY={this.props.scaleY}
                />
                <Position
                  onChange={this.props.onUpdatePosition}
                  style={this.props.style}
                  rightMenuFunc={this.rightMenuFunc}
                  top={this.props.top}
                  left={this.props.left}
                />
                {this.state.mounted && (
                  <ChartTitleAndSubtitle
                    {...this.state.chartOptions}
                    rightMenuFunc={this.rightMenuFunc}
                    onPropsChange={this.handleChartOptionsChange}
                  />
                )}
                {this.state.mounted && (
                  <LegendAndGridlines
                    {...this.state.chartOptions}
                    rightMenuFunc={this.rightMenuFunc}
                    {...this.chartConfig.legendAndGridlinesConfig}
                    onPropsChange={this.handleChartOptionsChange}
                  />
                )}
                {this.state.mounted && (
                  <DataLabels
                    {...this.state.chartOptions}
                    rightMenuFunc={this.rightMenuFunc}
                    {...this.chartConfig.dataLalbelsConfig}
                    onPropsChange={this.handleChartOptionsChange}
                  />
                )}
                {this.state.mounted && (
                  <AxisComponent
                    {...this.state.chartOptions}
                    rightMenuFunc={this.rightMenuFunc}
                    onPropsChange={this.handleChartOptionsChange}
                  />
                )}
                <ColorComponent
                  {...this.state.chartOptions}
                  rightMenuFunc={this.rightMenuFunc}
                  onPropsChange={this.handleChartOptionsChange}
                />
                {this.state.mounted && this.chartConfig.gapWidthConfig.haveGapWidth && (
                  <GapWidthComponent
                    rightMenuFunc={this.rightMenuFunc}
                    {...this.state.chartOptions}
                    onPropsChange={this.handleChartOptionsChange}
                    {...this.chartConfig.gapWidthConfig}
                  />
                )}
              </List>
            </div>
          </RightMenuPortal>
        )}
        {this.props.selected && !this.props.multipleSeleced && (
          <HeaderMidPortal>
            <Separator className="xxxxs" />
            <ButtonMui
              tooltipText={"Chart values"}
              onClick={() => this.handleTableOpen()}
              variant={"text"}
              roundnessType={"squared"}
              color={"black"}
              label={"Chart values"}
            />
            <Separator className="xxxxs" />
            <ButtonMui
              tooltipText={"Format options"}
              onClick={this.props.toggleFormatOptions}
              variant={"text"}
              roundnessType={"squared"}
              color={"black"}
              label={"Format options"}
            />
            <Separator className="xxxxs" />
          </HeaderMidPortal>
        )}
        {this.props.chartType === 1 && this.state.mounted && (
          <div
            onContextMenu={(e) => {
              this.state.openTable && this.setState({ openTable: false });
            }}
            style={{ width: "100%", height: this.props.height ? this.props.height : "100%" }}
            key={this.state.chartKey}
          >
            <StackedBarChart
              chartOptions={{ ...this.state.chartOptions }}
              preview={this.props.preview}
              businessPlan={this.props.businessPlan}
              addChartImage={this.props.addChartImage}
              staticKey={this.props.staticKey}
              lockedAspect={this.props.lockedAspect}
              data={this.state.data}
              colors={this.state.colors}
              changeColors={this.changeColors}
            />{" "}
          </div>
        )}
        {this.props.chartType === 2 && this.state.mounted && (
          <div
            onContextMenu={(e) => {
              this.state.openTable && this.setState({ openTable: false });
            }}
            style={{ width: "100%", height: this.props.height ? this.props.height : "100%" }}
            key={this.state.chartKey}
          >
            <ClustedVerticalBarChart
              chartOptions={{ ...this.state.chartOptions }}
              preview={this.props.preview}
              businessPlan={this.props.businessPlan}
              addChartImage={this.props.addChartImage}
              staticKey={this.props.staticKey}
              data={this.state.data}
              colors={this.state.colors}
              changeColors={this.changeColors}
            />{" "}
          </div>
        )}
        {this.props.chartType === 3 && this.state.mounted && (
          <div
            onContextMenu={(e) => {
              this.state.openTable && this.setState({ openTable: false });
            }}
            style={{ width: "100%", height: this.props.height ? this.props.height : "100%" }}
            key={this.state.chartKey}
          >
            <HorizontalClusteredBarChart
              chartOptions={{ ...this.state.chartOptions }}
              preview={this.props.preview}
              businessPlan={this.props.businessPlan}
              addChartImage={this.props.addChartImage}
              staticKey={this.props.staticKey}
              data={this.state.data}
              colors={this.state.colors}
              changeColors={this.changeColors}
            />{" "}
          </div>
        )}
        {this.props.chartType === 4 && this.state.mounted && (
          <div
            onContextMenu={(e) => {
              this.state.openTable && this.setState({ openTable: false });
            }}
            style={{ width: "100%", height: this.props.height ? this.props.height : "100%" }}
            key={this.state.chartKey}
          >
            <BarChart
              chartOptions={{ ...this.state.chartOptions }}
              preview={this.props.preview}
              businessPlan={this.props.businessPlan}
              addChartImage={this.props.addChartImage}
              staticKey={this.props.staticKey}
              data={this.state.data}
              colors={this.state.colors}
              changeColors={this.changeColors}
            />
          </div>
        )}
        {this.props.chartType === 5 && this.state.mounted && (
          <div
            onContextMenu={(e) => {
              this.state.openTable && this.setState({ openTable: false });
            }}
            style={{ width: "100%", height: this.props.height ? this.props.height : "100%" }}
            key={this.state.chartKey}
          >
            <BarChart
              chartOptions={{ ...this.state.chartOptions }}
              preview={this.props.preview}
              businessPlan={this.props.businessPlan}
              addChartImage={this.props.addChartImage}
              staticKey={this.props.staticKey}
              data={this.state.data}
              colors={this.state.colors}
              changeColors={this.changeColors}
            />
          </div>
        )}
        {this.props.chartType === 6 && this.state.mounted && (
          <div
            onContextMenu={(e) => {
              this.state.openTable && this.setState({ openTable: false });
            }}
            style={{ width: "100%", height: this.props.height ? this.props.height : "100%" }}
            key={this.state.chartKey}
          >
            <HorizontalBarChart
              chartOptions={{ ...this.state.chartOptions }}
              preview={this.props.preview}
              businessPlan={this.props.businessPlan}
              addChartImage={this.props.addChartImage}
              staticKey={this.props.staticKey}
              data={this.state.data}
              colors={this.state.colors}
              changeColors={this.changeColors}
            />
          </div>
        )}
        {this.props.chartType === 7 && this.state.mounted && (
          <div
            onContextMenu={(e) => {
              this.state.openTable && this.setState({ openTable: false });
            }}
            style={{ width: "100%", height: this.props.height ? this.props.height : "100%" }}
            key={this.state.chartKey}
          >
            <BubbleChart
              chartOptions={{ ...this.state.chartOptions }}
              preview={this.props.preview}
              businessPlan={this.props.businessPlan}
              addChartImage={this.props.addChartImage}
              staticKey={this.props.staticKey}
              data={this.state.data}
              colors={this.state.colors}
              changeColors={this.changeColors}
            />
          </div>
        )}
        {this.props.chartType === 8 && this.state.mounted && (
          <div
            onContextMenu={(e) => {
              this.state.openTable && this.setState({ openTable: false });
            }}
            style={{ width: "100%", height: this.props.height ? this.props.height : "100%" }}
            key={this.state.chartKey}
          >
            <CombinedBarLineChart
              chartOptions={{ ...this.state.chartOptions }}
              preview={this.props.preview}
              businessPlan={this.props.businessPlan}
              addChartImage={this.props.addChartImage}
              staticKey={this.props.staticKey}
              data={this.state.data}
              colors={this.state.colors}
              changeColors={this.changeColors}
            />
          </div>
        )}
        {this.props.chartType === 9 && this.state.mounted && (
          <div
            onContextMenu={(e) => {
              this.state.openTable && this.setState({ openTable: false });
            }}
            style={{ width: "100%", height: this.props.height ? this.props.height : "100%" }}
            key={this.state.chartKey}
          >
            <DoughnoutChart
              chartOptions={{ ...this.state.chartOptions }}
              preview={this.props.preview}
              businessPlan={this.props.businessPlan}
              addChartImage={this.props.addChartImage}
              staticKey={this.props.staticKey}
              data={this.state.data}
              colors={this.state.colors}
              changeColors={this.changeColors}
            />
          </div>
        )}
        {this.props.chartType === 10 && this.state.mounted && (
          <div
            onContextMenu={(e) => {
              this.state.openTable && this.setState({ openTable: false });
            }}
            style={{ width: "100%", height: this.props.height ? this.props.height : "100%" }}
            key={this.state.chartKey}
          >
            <PieChart
              chartOptions={{ ...this.state.chartOptions }}
              preview={this.props.preview}
              businessPlan={this.props.businessPlan}
              addChartImage={this.props.addChartImage}
              staticKey={this.props.staticKey}
              data={this.state.data}
              colors={this.state.colors}
              changeColors={this.changeColors}
            />
          </div>
        )}
        {this.props.chartType === 11 && this.state.mounted && (
          <div
            onContextMenu={(e) => {
              this.state.openTable && this.setState({ openTable: false });
            }}
            style={{ width: "100%", height: this.props.height ? this.props.height : "100%" }}
            key={this.state.chartKey}
          >
            <ScatterChart
              chartOptions={{ ...this.state.chartOptions }}
              preview={this.props.preview}
              businessPlan={this.props.businessPlan}
              addChartImage={this.props.addChartImage}
              staticKey={this.props.staticKey}
              data={this.state.data}
              colors={this.state.colors}
              changeColors={this.changeColors}
            />
          </div>
        )}
        {this.props.chartType === 12 && this.state.mounted && (
          <div
            onContextMenu={(e) => {
              this.state.openTable && this.setState({ openTable: false });
            }}
            style={{ width: "100%", height: this.props.height ? this.props.height : "100%" }}
            key={this.state.chartKey}
          >
            <StackedBarLineChart
              chartOptions={{ ...this.state.chartOptions }}
              preview={this.props.preview}
              businessPlan={this.props.businessPlan}
              addChartImage={this.props.addChartImage}
              staticKey={this.props.staticKey}
              data={this.state.data}
              colors={this.state.colors}
              changeColors={this.changeColors}
            />
          </div>
        )}
        {this.props.chartType === 13 && this.state.mounted && (
          <div
            onContextMenu={(e) => {
              this.state.openTable && this.setState({ openTable: false });
            }}
            style={{ width: "100%", height: this.props.height ? this.props.height : "100%" }}
            key={this.state.chartKey}
          >
            <LineChart
              chartOptions={{ ...this.state.chartOptions }}
              preview={this.props.preview}
              businessPlan={this.props.businessPlan}
              addChartImage={this.props.addChartImage}
              staticKey={this.props.staticKey}
              data={this.state.data}
              colors={this.state.colors}
              changeColors={this.changeColors}
            />
          </div>
        )}
        {this.props.chartType === 14 && this.state.mounted && (
          <div
            onContextMenu={(e) => {
              this.state.openTable && this.setState({ openTable: false });
            }}
            style={{ width: "100%", height: this.props.height ? this.props.height : "100%" }}
            key={this.state.chartKey}
          >
            <WaterfallBarChart
              chartOptions={{ ...this.state.chartOptions }}
              preview={this.props.preview}
              businessPlan={this.props.businessPlan}
              addChartImage={this.props.addChartImage}
              staticKey={this.props.staticKey}
              data={this.state.data}
              colors={this.state.colors}
              changeColors={this.changeColors}
            />
          </div>
        )}
      </Fragment>
    );
  }
}

ChartObject.config = {
  minWidth: "300",
};

ChartObject.defaultProps = {
  chartOptions: {
    backgroundColor: "#FFFFFF",
    borderColor: "#FFFFFF",
    showVerticalAxis: true,
    showHorizontalAxis: true,
    showTitle: false,
    titleAlign: "start",
    showSubtitle: false,
    title: "Title",
    subtitle: "Subtitle",
    xGrid: false,
    yGrid: false,
    displayLegend: false,
    dataLabelsFontSize: 14,
    datalabelsDecimalPoints: 1,
    axisFontSize: 14,
    legendFontSize: 100,
    gapWidth: "",
    categoryGapWidth: "",
    titleFontSize: 14,
    subTitleFontSize: 14,
    dataType: "number",
    minimumVerticalAxisValue: "",
    maximumVerticalAxisValue: "",
    minimumHorizontalAxisValue: "",
    maximumHorizontalAxisValue: "",
    legendPosition: "bottom",
    showDataLabels: false,
    hideSeriesDataLabels: false,
    DataLabelsPosition: "end",
  },
  chartData: {
    labels: [],
    datasets: [],
  },
};

export default ChartObject;
