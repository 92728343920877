import React, { Component } from "react";
import ButtonMui from "../../../../../components/buttons/buttonMui/buttonMui";
import {
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { GreyCard, parseDate } from "../index";
import request from "superagent";
import { Upgrade_Downgrade_types } from "../../../../../data/SubscriptionEnum";
import Subscriptions from "../../../../Public/Auth/Register/Subscriptions";
import Mx_MainLoader from "../../../../../components/Loaders/MainLoader/Mx_MainLoader";
import { Exit } from "../../../../../SlideEditor/components/components/IconButtons/SubMenuIcons";
import { ArrowRight, IconMinus, IconPlus } from "../../../../../components/icons/svgIcons";
import { DialogTypes } from "../../../../../data/Finance/constants";
import "../account_details.scss";
import { PermissionTypes } from "../../../../../components/constants/permissions";
import RestrictedAccess from "../../../restrictedAccess/RestrictedAccess";
const CompaniesInAPackage = 5;
const ProfileAccountUrl = "/profile/account_details/manage_subscription";
import { useNavigate } from "react-router-dom/dist/index";
import PaymentFailedDialog from "../../../../../components/dialogs/PaymentFailedDialog";
import { Payment } from "@mui/icons-material";
import AppSumoUpgradeDialog from "../../../../../components/dialogs/AppSumoUpgradeDialog";
class ManageSubscription extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newAmount: 0,
      upgrade_plan: props.upgrade_plan ? props.upgrade_plan : false,
      openAppSumoUpgradeDialog: false,
    };

    this.subscription_plan = global.Modeliks.subscriptionPlans.find(
      (c) => c.ID == global.Modeliks.subscriptionInfo.SubscriptionID,
    );
    this.plan_name = this.subscription_plan.name;
  }

  openAppSumoUpgradeDialog = () => {
    this.setState({ openAppSumoUpgradeDialog: true });
  };

  updateSubscriptionStatus = (callBack) => {
    request
      .put("/api/updateSubscriptionPauseStatus")
      .set("Content-Type", "application/x-www-form-urlencoded")
      .send({
        AccountID: global.Modeliks.Account.ID,
      })
      .then((res) => {
        if (res) {
          if (
            global.Modeliks.subscriptionInfo.isCanceled === null ||
            global.Modeliks.subscriptionInfo.isCanceled === false
          ) {
            if (global.onUserCancelSubscription) {
              global.onUserCancelSubscription(
                global.Modeliks.User.ID,
                global.Modeliks.subscriptionInfo.PaddleSubscriptionID,
              );
            }
            global.Modeliks.subscriptionInfo.isCanceled = true;
          } else {
            global.Modeliks.subscriptionInfo.isCanceled = false;
          }
          this.forceUpdate();
        }
      });
  };

  cancelSubscription = () => {
    if (global.Modeliks.subscriptionInfo.isCanceled) {
      this.updateSubscriptionStatus();
    } else {
      global.Modeliks.showDialog(
        "Subscription",
        DialogTypes.CancelSubscription,
        () => {},
        this.updateSubscriptionStatus,
        "",
        false,
        "cancel_subscription",
      );
    }
  };

  setNumberOfEditors = (e) => {
    this.setState({ numberOfEditors: e.target.value });
  };

  setNumberOfCompanies = (e) => {
    this.setState({ numberOfCompanies: e.target.value });
  };

  getCurrentCompanyPrice = () => {
    let date_1 = new Date(global.Modeliks.subscriptionInfo.DateEnd);
    let date_2 = new Date();
    let difference = date_1.getTime() - date_2.getTime();
    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    return parseFloat(
      (((this.state.numberOfCompanies - global.Modeliks.limitInfo.TotalCompanies) *
        global.Modeliks.subscriptionInfo.price) /
        30) *
        TotalDays,
    ).toFixed(2);
  };

  getCurrentUserPrice = () => {
    let date_1 = new Date(global.Modeliks.subscriptionInfo.DateEnd);
    let date_2 = new Date();
    let difference = date_1.getTime() - date_2.getTime();
    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    return parseFloat(
      (((this.state.numberOfEditors - global.Modeliks.limitInfo.TotalUsers) *
        global.Modeliks.subscriptionInfo.price_extra_user) /
        30) *
        TotalDays,
    ).toFixed(2);
  };

  getSubscriptionType = (subscription_id) => {
    let currentSubscription = Modeliks.subscriptionPlans.find((c) => c.ID === subscription_id);
    let subscriptionType = currentSubscription.billing_type === "month" ? "monthly" : "yearly";

    return subscriptionType;
  };
  getCurrentSubscriptionEcommerce = (subscription_id, total_users, total_companies, type) => {
    let items = [];
    let currentSubscription = Modeliks.subscriptionPlans.find((c) => c.ID === subscription_id);
    let subscriptionType = this.getSubscriptionType(subscription_id);
    let total = 0;

    if (type === Upgrade_Downgrade_types.Subscription) {
      items.push({
        item_id: currentSubscription.paddle_id,
        item_name: currentSubscription.name,
        price: currentSubscription.recurring_price,
        item_variant: subscriptionType,
        item_category: "Subscription",
      });

      total += currentSubscription.recurring_price;
    }

    if (type === Upgrade_Downgrade_types.Users) {
      let extraUsersNumber = total_users;
      items.push({
        item_id: currentSubscription.stripe_extra_user,
        item_name: "Extra User",
        quantity: extraUsersNumber,
        price: currentSubscription.price_extra_user,
        item_variant: subscriptionType,
        item_category: "Add-on",
        item_category2: "User",
      });
      total += currentSubscription.price_extra_user * extraUsersNumber;
    }

    if (type === Upgrade_Downgrade_types.Companies) {
      let extraCompaniesNumber = total_companies;
      items.push({
        item_id: currentSubscription.stripe_extra_company,
        item_name: "Extra Company",
        quantity: extraCompaniesNumber,
        price: currentSubscription.price_extra_company,
        item_variant: subscriptionType,
        item_category: "Add-on",
        item_category2: "Company",
      });
      total += currentSubscription.price_extra_company * extraCompaniesNumber;
    }

    return {
      transaction_id: "transaction_" + new Date().getTime(),
      value: total,
      currency: "USD",
      items: items,
    };
  };

  ConfirmDowngradeCompanies = () => {
    this.setState({ loading: true });
    request
      .post("/api/DowngradeSubscription")
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("authorization", "Bearer " + window.localStorage.getItem("token"))
      .send({
        AccountID: global.Modeliks.Account.ID,
        type: Upgrade_Downgrade_types.Companies,
        numberOfCompanies: this.state.additionalItems + this.state.newAmount, // * CompaniesInAPackage
      })
      .then((res) => {
        if (res) {
          window.location.reload(false);
        }
      });
  };

  ConfirmUpgradeCompanies = () => {
    this.setState({ loading: true });
    request
      .post("/api/UpgradeSubscription")
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("authorization", "Bearer " + window.localStorage.getItem("token"))
      .send({
        AccountID: global.Modeliks.Account.ID,
        type: Upgrade_Downgrade_types.Companies,
        numberOfCompanies: this.state.additionalItems + this.state.newAmount, // * CompaniesInAPackage
        clickid: global.Modeliks.referralId,
        referralSource: global.Modeliks.referralSource,
      })
      .then((res) => {
        if (res) {
          if (res.body === "OK") {
            if (global.onSubscriptionUpdate) {
              const subscriptionType = this.getSubscriptionType(
                global.Modeliks.subscriptionInfo.SubscriptionID,
              );
              const ecommerce = this.getCurrentSubscriptionEcommerce(
                global.Modeliks.subscriptionInfo.SubscriptionID,
                global.Modeliks.subscriptionInfo.TotalUsers,
                this.state.additionalItems + this.state.newAmount,
                Upgrade_Downgrade_types.Companies,
              );
              console.log("ecommerce", ecommerce);
              global.onSubscriptionUpdate(
                global.Modeliks.Account.ID,
                global.Modeliks.User.ID,
                global.Modeliks.subscriptionInfo.SubscriptionID,
                subscriptionType,
                false,
                ecommerce,
                "Extra_Companies",
              );
            }

            window.location.reload(false);
          } else {
            this.setState({
              openPaymentFailedDialog: true,
              loading: false,
              upgrade_plan: false,
              upgradeType: Upgrade_Downgrade_types.Companies,
              quantity: this.state.additionalItems + this.state.newAmount,
            });
          }
        }
      });
  };

  ConfirmUpgradeUsers = () => {
    this.setState({ loading: true });
    request
      .post("/api/UpgradeSubscription")
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("authorization", "Bearer " + window.localStorage.getItem("token"))
      .send({
        AccountID: global.Modeliks.Account.ID,
        type: Upgrade_Downgrade_types.Users,
        numberOfUsers: this.state.additionalItems + this.state.newAmount,
        clickid: global.Modeliks.referralId,
        referralSource: global.Modeliks.referralSource,
      })
      .then((res) => {
        if (res) {
          if (res.body === "OK") {
            if (global.onSubscriptionUpdate) {
              const subscriptionType = this.getSubscriptionType(
                global.Modeliks.subscriptionInfo.SubscriptionID,
              );
              const ecommerce = this.getCurrentSubscriptionEcommerce(
                global.Modeliks.subscriptionInfo.SubscriptionID,
                this.state.additionalItems + this.state.newAmount,
                global.Modeliks.subscriptionInfo.TotalCompanies,
                Upgrade_Downgrade_types.Users,
              );
              console.log("ecommerce", ecommerce);
              global.onSubscriptionUpdate(
                global.Modeliks.Account.ID,
                global.Modeliks.User.ID,
                global.Modeliks.subscriptionInfo.SubscriptionID,
                subscriptionType,
                false,
                ecommerce,
                "Extra_Users",
              );
            }

            window.location.reload(false);
          } else {
            this.setState({
              openPaymentFailedDialog: true,
              loading: false,
              upgrade_plan: false,
              upgradeType: Upgrade_Downgrade_types.Users,
              quantity: this.state.additionalItems + this.state.newAmount,
            });
          }
        }
      });
  };

  ConfirmDowngradeUsers = () => {
    this.setState({ loading: true });
    request
      .post("/api/DowngradeSubscription")
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("authorization", "Bearer " + window.localStorage.getItem("token"))
      .send({
        AccountID: global.Modeliks.Account.ID,
        type: Upgrade_Downgrade_types.Users,
        numberOfUsers: this.state.additionalItems + this.state.newAmount,
      })
      .then((res) => {
        if (res) {
          window.location.reload(false);
        }
      });
  };

  changeUsersAmount = () => {
    const TotalPlans =
      global.Modeliks.subscriptionInfo.TotalCompanies / this.subscription_plan.companies_number;
    if (
      this.state.additionalItems + this.state.newAmount >
      global.Modeliks.subscriptionInfo.TotalUsers -
        global.Modeliks.subscriptionInfo.users_number * TotalPlans
    ) {
      this.ConfirmUpgradeUsers();
    } else if (
      this.state.additionalItems + this.state.newAmount <
      global.Modeliks.subscriptionInfo.TotalUsers -
        global.Modeliks.subscriptionInfo.users_number * TotalPlans
    ) {
      this.ConfirmDowngradeUsers();
    } else {
      this.setState({ addOnChangeDialog: false });
      return;
    }
    this.setState({ loading: true });
  };

  changeCompaniesAmount = () => {
    if (this.state.newAmount > 0) {
      this.ConfirmUpgradeCompanies();
    } else if (this.state.newAmount < 0) {
      this.ConfirmDowngradeCompanies();
    } else {
      this.setState({ addOnChangeDialog: false });
      return;
    }
    this.setState({ loading: true });
  };

  ConfirmDowngradePlan = (ID) => {
    global.Modeliks.showDialog(
      {
        oldPlan: global.Modeliks.subscriptionPlans.find(
          (c) => c.ID === global.Modeliks.subscriptionInfo.SubscriptionID,
        ),
        newPlan: global.Modeliks.subscriptionPlans.find((c) => c.ID === ID),
      },
      DialogTypes.DowngradeSubscription,
      null,
      () => {
        this.setState({ loading: true });

        request
          .post("/api/DowngradeSubscription")
          .set("Content-Type", "application/x-www-form-urlencoded")
          .set("authorization", "Bearer " + window.localStorage.getItem("token"))
          .send({
            AccountID: global.Modeliks.Account.ID,
            type: Upgrade_Downgrade_types.Subscription,
            selectedSubscriptionID: ID,
          })
          .then((res) => {
            if (res) {
              window.location.href = ProfileAccountUrl;
            }
          });
      },
    );
  };

  updatePaymentMethod = () => {
    request
      .get(
        `/api/getUpdatedPaymentMethodLink?subscription_id=${global.Modeliks.subscriptionInfo.PaddleSubscriptionID}`,
      )
      .then((res) => {
        if (res) {
          window.open(res.body, "_self");
        }
      });
  };

  ConfirmUpgradePlan = (ID) => {
    global.Modeliks.showDialog(
      {
        oldPlan: global.Modeliks.subscriptionPlans.find(
          (c) => c.ID === global.Modeliks.subscriptionInfo.SubscriptionID,
        ),
        newPlan: global.Modeliks.subscriptionPlans.find((c) => c.ID === ID),
      },
      DialogTypes.UpgradeSubscription,
      null,
      () => {
        if (global.Modeliks.subscriptionInfo.price != 0) {
          this.setState({ loading: true });
          request
            .post("/api/UpgradeSubscription")
            .set("Content-Type", "application/x-www-form-urlencoded")
            .set("authorization", "Bearer " + window.localStorage.getItem("token"))
            .send({
              AccountID: global.Modeliks.Account.ID,
              type: Upgrade_Downgrade_types.Subscription,
              selectedSubscriptionID: ID,
              email: global.Modeliks.User.Email,
              clickid: global.Modeliks.referralId,
              referralSource: global.Modeliks.referralSource,
            })
            .then((res) => {
              if (res && res.body === "OK") {
                if (global.onSubscriptionUpdate) {
                  const subscriptionType = this.getSubscriptionType(ID);
                  const ecommerce = this.getCurrentSubscriptionEcommerce(
                    ID,
                    global.Modeliks.subscriptionInfo.TotalUsers,
                    global.Modeliks.subscriptionInfo.TotalCompanies,
                    Upgrade_Downgrade_types.Subscription,
                  );
                  console.log("ecommerce", ecommerce);
                  global.onSubscriptionUpdate(
                    global.Modeliks.Account.ID,
                    global.Modeliks.User.ID,
                    ID,
                    subscriptionType,
                    false,
                    ecommerce,
                    global.Modeliks.subscriptionPlans.find((c) => c.ID === ID).data_key,
                  );
                }
                window.location.href = ProfileAccountUrl;
              } else {
                this.setState({
                  openPaymentFailedDialog: true,
                  loading: false,
                  upgrade_plan: false,
                  upgradeType: Upgrade_Downgrade_types.Subscription,
                  subscription_id: ID,
                });
              }
            });
        } else {
          console.log("setting redirectUrl");
          global.Modeliks.redirectUrl = "/profile/account_details/general_info";

          window.Paddle.Checkout.open({
            items: [
              {
                priceId: global.Modeliks.filteredSubscriptionPlans().find((c) => c.ID === ID)
                  .paddle_id,
                quantity: 1,
              },
            ],
            customer: {
              email: global.Modeliks.User.Email,
            },
            customData: {
              account_id: global.Modeliks.Account.ID,
              subscription_id: ID,
            },
          });
        }
      },
      "",
      false,
      " upgrade_plan",
    );
  };

  getSubscriptionPlansOptions = () => {
    let options = [];
    global.Modeliks.subscriptionPlans.map((c) =>
      options.push({ value: c.ID, description: c.name }),
    );
    return options;
  };

  changeSubscriptionPlan = (e) => {
    this.setState({ selectedSubscriptionID: e.target.value });
  };

  shouldShowUpgradeSubscription = () => {
    let currentSubscriptionID = global.Modeliks.subscriptionInfo.SubscriptionID;
    if (this.state.selectedSubscriptionID !== currentSubscriptionID) {
      let selectedSubscription = global.Modeliks.subscriptionPlans.find(
        (c) => c.ID === this.state.selectedSubscriptionID,
      );
      let currentSubscription = global.Modeliks.subscriptionPlans.find(
        (c) => c.ID === currentSubscriptionID,
      );

      if (selectedSubscription.strength > currentSubscription.strength) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  shouldShowDowngradeSubscription = () => {
    let currentSubscriptionID = global.Modeliks.subscriptionInfo.SubscriptionID;
    if (this.state.selectedSubscriptionID !== currentSubscriptionID) {
      let selectedSubscription = global.Modeliks.subscriptionPlans.find(
        (c) => c.ID === this.state.selectedSubscriptionID,
      );
      let currentSubscription = global.Modeliks.subscriptionPlans.find(
        (c) => c.ID === currentSubscriptionID,
      );

      if (selectedSubscription.strength < currentSubscription.strength) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  toggleChangePlan = (value = !this.state.upgrade_plan) => {
    this.setState({ upgrade_plan: value });
  };

  handleCardClick = (id, price, stripe_price, strength, label) => {
    if (this.subscription_plan.ID == id) {
      this.toggleChangePlan();
    } else if (label == "DOWNGRADE") {
      this.ConfirmDowngradePlan(id);
    } else {
      this.ConfirmUpgradePlan(id);
    }
  };

  openAddOnChangeDialog = (
    name,
    additionalItems,
    includedItems,
    pricePerItem,
    callBackFunction,
    multiplier = 1,
  ) => {
    this.setState({
      addOnChangeDialog: true,
      name: name,
      additionalItems: additionalItems,
      includedItems: includedItems,
      pricePerItem: pricePerItem,
      newAmount: 0,
      callBackFunction: callBackFunction,
      multiplier: multiplier,
    });
  };
  closeAddOnChangeDialog = () => {
    this.setState({ addOnChangeDialog: false, name: "" });
  };

  getDialogTitle = () => {
    return {
      Users: "Change the number of users to accommodate your needs.",
      Companies: <>Change the number of companies to accommodate your needs.</>,
    };
  };

  getPrice = (price) => {
    if (
      this.subscription_plan.billing_type == "free_trial" ||
      this.subscription_plan.billing_type == "free_trial_custom"
    ) {
      return "$0";
    }
    return `$${price} / ${this.subscription_plan.billing_type}`;
  };

  getStatus = (items) => {
    if (
      this.subscription_plan.billing_type == "free_trial" ||
      this.subscription_plan.billing_type == "free_trial_custom"
    ) {
      if (global.Modeliks.subscriptionInfo.Status == "trialing") {
        return "active";
      }
    } else if (global.Modeliks.subscriptionInfo.isCanceled) {
      if (global.Modeliks.subscriptionInfo.Status == "active") {
        return "cancelled";
      }
      return "inactive";
    }
    return global.Modeliks.subscriptionInfo.Status;
  };

  getButton = (button) => {
    if (
      this.subscription_plan.billing_type == "free_trial" ||
      this.subscription_plan.billing_type == "free_trial_custom"
    ) {
      return (
        <ButtonMui
          onClick={this.toggleChangePlan}
          variant={"contained"}
          width={170}
          label={"Unlock"}
        />
      );
    }
    return button;
  };

  render() {
    const TotalPlans = 1; //(global.Modeliks.subscriptionInfo.TotalCompanies/this.subscription_plan.companies_number);

    const ExtraCompanies =
      global.Modeliks.subscriptionInfo.TotalCompanies - this.subscription_plan.companies_number;

    const ExtraUsers =
      global.Modeliks.subscriptionInfo.TotalUsers - global.Modeliks.subscriptionInfo.users_number;

    const PlanPrice = global.Modeliks.subscriptionInfo.price;
    const UsersPrice = ExtraUsers * global.Modeliks.subscriptionInfo.price_extra_user;
    const ExtraCompanyPackagesPrice =
      ExtraCompanies * global.Modeliks.subscriptionInfo.price_extra_company;

    const TotalPrice = ExtraCompanyPackagesPrice + UsersPrice + PlanPrice;
    const TotalCompanies = global.Modeliks.subscriptionInfo.TotalCompanies;
    const TotalUsers = global.Modeliks.subscriptionInfo.TotalUsers;

    const billing_type = this.subscription_plan.billing_type;
    const newAmount = this.state.newAmount * this.state.multiplier;
    const additionalItems = this.state.additionalItems * this.state.multiplier;

    const headers = [
      "Type",
      "Subscription",
      "Status",
      "Users",
      "Companies",
      "Active until",
      "Price",
    ];
    const rows = [
      {
        Type: "Plan",
        Subscription: this.plan_name,
        Status: this.getStatus(),
        Users: global.Modeliks.subscriptionInfo.users_number,
        Companies: global.Modeliks.subscriptionInfo.companies_number,
        "Active until": parseDate(global.Modeliks.subscriptionInfo.DateEnd),
        Price: (
          <div className={"cell_btn_container"}>
            <span>{this.getPrice(PlanPrice)}</span>
            <ButtonMui
              onClick={this.toggleChangePlan}
              variant={"contained"}
              width={170}
              label={
                this.subscription_plan.billing_type == "free_trial" ||
                this.subscription_plan.billing_type == "free_trial_custom"
                  ? "Upgrade Plan"
                  : "Change Plan"
              }
            />
          </div>
        ),
      },
      {
        Type: "Add-ons",
        Subscription: "Users",
        Status: ExtraUsers === 0 ? "-" : this.getStatus(),
        Users: ExtraUsers,
        Companies: "",
        "Active until":
          ExtraUsers === 0 ? "-" : parseDate(global.Modeliks.subscriptionInfo.DateEnd),
        Price: (
          <div className={"cell_btn_container"}>
            <span>{this.getPrice(UsersPrice)}</span>
            {this.getButton(
              <ButtonMui
                onClick={() =>
                  this.openAddOnChangeDialog(
                    "Users",
                    ExtraUsers,
                    TotalUsers - ExtraUsers,
                    global.Modeliks.subscriptionInfo.price_extra_user,
                    this.changeUsersAmount,
                  )
                }
                variant={"contained"}
                width={170}
                label={"Change Users"}
              />,
            )}
          </div>
        ),
      },
      {
        Type: "Add-ons",
        Subscription: "Companies",
        Status: ExtraCompanies === 0 ? "-" : this.getStatus(),
        Users: "-",
        Companies: ExtraCompanies,
        "Active until":
          ExtraCompanies === 0 ? "-" : parseDate(global.Modeliks.subscriptionInfo.DateEnd),
        Price: (
          <div className={"cell_btn_container"}>
            <span>{this.getPrice(ExtraCompanyPackagesPrice)}</span>
            {this.getButton(
              <ButtonMui
                onClick={() =>
                  this.openAddOnChangeDialog(
                    "Companies",
                    ExtraCompanies,
                    global.Modeliks.subscriptionInfo.companies_number,
                    global.Modeliks.subscriptionInfo.price_extra_company,
                    this.changeCompaniesAmount,
                  )
                }
                variant={"contained"}
                width={170}
                label={"Change Companies"}
              />,
            )}
          </div>
        ),
        className: "blue_border",
      },
      {
        Type: "Total",
        Subscription: "",
        Status: "",
        Users: TotalUsers,
        Companies: TotalCompanies,
        "Active until": "",
        Price: this.getPrice(TotalPrice),
        className: "blue_border bold",
      },
    ];
    if (this.state.loading) {
      return <Mx_MainLoader />;
    }

    if (
      !(
        global.Modeliks.UserPermissions.hasOwnProperty(PermissionTypes.Subscriptions) &&
        global.Modeliks.UserPermissions[PermissionTypes.Subscriptions] >= 1
      )
    ) {
      return <RestrictedAccess />;
    }

    if (this.state.upgrade_plan) {
      return (
        <div className={"subscriptions_container"}>
          <Subscriptions
            dontShowFreeTrial
            goBackLabel={"Go Back"}
            goBackLink={ProfileAccountUrl}
            goBack={() => this.setState({ upgrade_plan: false })}
            changeSubscriptionPlan
            onCardClick={this.handleCardClick}
          />
        </div>
      );
    }
    return (
      <>
        <Table stickyHeader={true}>
          <TableHead>
            <TableRow>
              {headers.map((h, i) => (
                <TableCell key={`fc_${i}`}>{h}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((r, i) => (
              <TableRow key={`r_${i}`} className={r.className ? r.className : ""}>
                {headers.map((h, i) => (
                  <TableCell key={`c_${i}`}>{r[h]}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <div className={"manage_subscription_btns_cont"}>
          {this.subscription_plan.recurring_price != 0 && (
            <ButtonMui
              variant={"contained"}
              label={
                global.Modeliks.subscriptionInfo.isCanceled
                  ? "Continue Subscription"
                  : "Cancel Subscription"
              }
              onClick={this.cancelSubscription}
            />
          )}
        </div>

        <div className={"appsumo_container"}>
          {this.subscription_plan.billing_type == "free_trial" ? (
            <ButtonMui
              onClick={this.openAppSumoUpgradeDialog}
              className={"appsumo_link"}
              variant={"text"}
              fontSize={12}
              label={"AppSumo"}
            />
          ) : (
            ""
          )}
        </div>
        {this.state.addOnChangeDialog && (
          <Dialog
            className={"add_on_change_dialog"}
            onClose={this.closeAddOnChangeDialog}
            open={true}
          >
            <DialogTitle>
              Add-On: Change Number of {this.state.name}
              <ButtonMui
                onClick={this.closeAddOnChangeDialog}
                isIconButton
                width={36}
                variant={"text"}
                label={<Exit />}
                color={"black"}
              />
            </DialogTitle>
            <DialogContent>
              <div className={"subtitle"}>{this.getDialogTitle()[this.state.name]}</div>
              <div className={"content"}>
                <ButtonGroup variant="text">
                  <ButtonMui
                    variant={"text"}
                    onClick={() => {
                      if (this.state.additionalItems + this.state.newAmount > 0) {
                        this.setState({ newAmount: this.state.newAmount - 1 });
                      }
                    }}
                    isIconButton
                    roundnessType={"squared"}
                    label={<IconMinus />}
                    width={50}
                  />
                  <div className={"value"}>{this.state.newAmount}</div>
                  <ButtonMui
                    variant={"text"}
                    onClick={() => this.setState({ newAmount: this.state.newAmount + 1 })}
                    isIconButton
                    roundnessType={"squared"}
                    label={<IconPlus />}
                    width={50}
                  />
                </ButtonGroup>
              </div>

              <div className={"content grid"}>
                <div className={"content s_b big"}>
                  <GreyCard>
                    <div className={"small_title"}>Current Add-on subscription</div>
                    <div className={"small_text content s_b"}>
                      <span>Additional paid {this.state.name.toLowerCase()}:</span>
                      <span>{additionalItems}</span>
                    </div>
                    <div className={"small_text content s_b"}>
                      <span>{this.state.name} included in plan:</span>
                      <span>{this.state.includedItems}</span>
                    </div>
                    <div className={"small_text content s_b"}>
                      <span>Total paid {this.state.name.toLowerCase()}:</span>
                      <span>{additionalItems + this.state.includedItems}</span>
                    </div>

                    <div className={"small_title"}>
                      {additionalItems} Additional paid {this.state.name.toLowerCase()}: $
                      {this.state.additionalItems * this.state.pricePerItem} / {billing_type}
                    </div>
                  </GreyCard>
                </div>
                <div className={"svg_cont"}>
                  <ArrowRight />
                </div>
                <div className={"content s_b big"}>
                  <GreyCard>
                    <div className={"small_title"}>After update</div>
                    <div className={"small_text content s_b"}>
                      <span>Additional paid {this.state.name.toLowerCase()}:</span>
                      <span>{additionalItems + newAmount}</span>
                    </div>
                    <div className={"small_text content s_b"}>
                      <span>{this.state.name} included in plan:</span>
                      <span>{this.state.includedItems}</span>
                    </div>
                    <div className={"small_text content s_b"}>
                      <span>Total paid {this.state.name.toLowerCase()}:</span>
                      <span>{additionalItems + newAmount + this.state.includedItems}</span>
                    </div>

                    <div className={"small_title"}>
                      {additionalItems + newAmount} Additional paid {this.state.name.toLowerCase()}:
                      $
                      {(this.state.additionalItems + this.state.newAmount) *
                        this.state.pricePerItem}{" "}
                      / {billing_type}
                    </div>
                  </GreyCard>
                </div>
              </div>
              <div className={"bottom_text"}>
                These changes will be effective <b>immediately</b>. We will charge your credit card
                stored under your profile.
              </div>
            </DialogContent>
            <DialogActions>
              <ButtonMui label={"Cancel"} width={155} onClick={this.closeAddOnChangeDialog} />
              <ButtonMui
                label={"Confirm Changes"}
                variant={"contained"}
                onClick={this.state.callBackFunction}
              />
            </DialogActions>
          </Dialog>
        )}
        <PaymentFailedDialog
          open={this.state.openPaymentFailedDialog}
          updatePaymentMethod={this.updatePaymentMethod}
          handleClose={() => {
            this.setState({ openPaymentFailedDialog: false });
          }}
        />
        <AppSumoUpgradeDialog
          open={this.state.openAppSumoUpgradeDialog}
          handleClose={() => {
            this.setState({ openAppSumoUpgradeDialog: false });
          }}
        />
      </>
    );
  }
}

export default ManageSubscription;
