import React from "react";
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-dragdata";
import ChartDataLabels from "chartjs-plugin-datalabels";

import {
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { InputFormats } from "../constants/finance";
import { NumberFormatTypes } from "../constants/charts";
import ChartHelper from "../../pages/Secure/Financials/components/Charts/ChartHelpers";

ChartJS.register(
  CategoryScale,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
);

class FinancialBarChart extends React.Component {
  checkIfValue = (val, ctx, checkForDashValue = false) => {
    let showValue = this.props.driverValues;
    let str = "";
    if (!isNaN(val)) {
      str += global.Modeliks.CompanyInfo.Currency.value;
    }
    str += ChartHelper.setTextOverflow(InputFormats.formatNumber(val), ctx);
    if (showValue[ctx.dataIndex] && !isNaN(showValue[ctx.dataIndex].Value)) {
      if (showValue[ctx.dataIndex].Value !== null) {
        if (isFinite(showValue[ctx.dataIndex].Value)) {
          if (InputFormats.formatNumber(showValue[ctx.dataIndex].Value)) {
            str +=
              "\n" +
              InputFormats.formatNumber(showValue[ctx.dataIndex].Value) +
              `${this.props.isPercentage ? "%" : ""}`;
          }
        } else {
          str += "\n∞";
        }
      } else {
        str += "\n" + "-";
      }
    }

    return str;
  };
  setTextOverflow = (val, ctx) => {
    const ActiveYears = global.Modeliks.CompanyInfo.ActiveYears;
    let IsAnnualChart = true;
    let NumYears = 3;
    if (ctx.dataset.data.length > 5) {
      IsAnnualChart = false;
    }
    let MaxChartLength = 12;

    if (IsAnnualChart && ActiveYears === 3) {
      MaxChartLength = 19;
    }

    if (typeof val === "string" && val.length > MaxChartLength) {
      if (!IsAnnualChart && ctx.chart.width < 1080) {
        return `${val.substring(0, MaxChartLength + 3)}...`;
      }

      if (IsAnnualChart && ctx.chart.width < 341) {
        return `${val.substring(0, MaxChartLength + 3)}...`;
      }
      return `${val.substring(0, MaxChartLength)}...`;
    }

    return val;
  };

  constructor(props) {
    super(props);
    this.yAxisKey = props.yAxisAccessor ? props.yAxisAccessor : "Value";
    ChartJS.defaults.font.family = "Inter";
    ChartJS.defaults.isItalic = props.isItalic;
    ChartJS.defaults.plugins.datalabels.font.style = props.isItalic ? "italic" : "normal";
    this.state = {
      data: {
        labels: props.labels,
        datasets: [
          {
            backgroundColor: props.color ? props.color : "#80D7FE",
            // barThickness: 37,
            barPercentage: 0.49,
            maxBarThickness: 37,
            minBarLength: 2,
            borderRadius: 5,
            data: props.data,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        type: "bar",
        barRoundness: 0.3,
        parsing: {
          xAxisKey: "ID",
          yAxisKey: "Value",
        },
        layout: {
          padding: {
            top: 40,
            right: 20,
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              color: "#889299",
              font: {
                fontFamily: "Inter",
                weight: 400,
                style: "normal",
                size: 12,
                lineHeight: "160%",
              },
            },
            // stacked: true
          },
          y: {
            beginAtZero: true,
            grid: {
              drawBorder: false,
              display: false,
            },
            ticks: {
              display: false,
              minTicksLimit: 4,
              maxTicksLimit: 4,
              color: "#889299",
              font: {
                fontFamily: "Inter",
                weight: 400,
                style: "normal",
                size: 12,
                lineHeight: "160%",
              },
              callback: function (value) {
                if (value <= -1000000) {
                  return value / 1000000 + "M";
                } else if (value <= -1000) {
                  return value / 1000 + "K";
                } else if (value >= 1000 && value < 1000000) {
                  return value / 1000 + "K";
                } else if (value >= 1000000) {
                  return value / 1000000 + "M";
                } else {
                  return value.toFixed(2);
                }
              },
              stepSize: 750000,
            },
          },
        },
        plugins: {
          datalabels: {
            enabled: true,
            anchor: "end",
            align: "end",
            tooltips: {
              mode: "index",
              intersect: false,
            },
            hover: {
              mode: "index",
              intersect: false,
            },
            rotation: (ctx) => {
              let IsAnnualChart = true;
              if (ctx.dataset.data.length > 5) {
                IsAnnualChart = false;
              }

              if (!IsAnnualChart && ctx.chart.width < 1080) {
                return -20;
              }

              if (IsAnnualChart && ctx.chart.width < 341) {
                return -20;
              }

              return 0;
            },
            textAlign: "center",
            formatter: (value, ctx) => {
              let val = 0;

              val = value / NumberFormatTypes[props.unitValue].splitBy;
              if (this.props.showDriver && props.valueLabels) {
                val = this.checkIfValue(val, ctx, true);
              } else if (this.props.showDriver) {
                val = this.checkIfValue(val, ctx);
              } else if (props.valueLabels) {
                val =
                  (isNaN(val)
                    ? ""
                    : this.props.hideCurrency
                      ? ""
                      : global.Modeliks.CompanyInfo.Currency.value) +
                  InputFormats.formatNumber(val);
                val = ChartHelper.setTextOverflow(val, ctx);
              } else {
                val = "";
              }

              return val;
            },
            color: "#252525",
            font: function (context, text = this) {
              let width = context.chart.width;
              let size = Math.round(width / (width > 700 ? 120 : 38));
              let min = 9;
              let max = 14;

              if (size < min) {
                size = min;
              }

              if (size > max) {
                size = max;
              }

              return {
                weight: "bold",
                size: size,
                style: `${ChartJS.defaults.isItalic ? "italic" : "normal"}`,
              };
            },
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                let label = context.dataset.label || "";
                if (label) {
                  label += ": ";
                }
                if (context.parsed.y !== null) {
                  label +=
                    global.Modeliks.CompanyInfo.Currency.value +
                    InputFormats.formatNumber(context.parsed.y);
                }
                return label;
              },
            },
          },
          legend: {
            display: false,
          },
        },
      },
    };
  }

  componentWillReceiveProps(nextProps, nextState) {
    if (this.state != nextState) {
      this.setState((prevState) => ({
        data: {
          ...prevState.data,
          datasets: [
            {
              ...prevState.data.datasets[0],
              data: nextProps.data,
            },
          ],
        },
      }));
    }
  }

  getData = () => {
    return {
      labels: this.props.labels,
      datasets: [
        {
          backgroundColor: this.props.color ? this.props.color : "#0088F7",
          barPercentage: 0.49,
          // barThickness: 'flex',
          // categoryPercentage: 0.9,
          maxBarThickness: 37,
          minBarLength: 2,
          borderRadius: 5,
          data: this.props.data.map((c) => c[this.yAxisKey]),
        },
      ],
    };
  };

  render() {
    return (
      <Bar
        type={"bar"}
        data={this.getData()}
        options={this.state.options}
        height={this.props.height ? this.props.height : "60"}
      />
    );
  }
}

export default FinancialBarChart;
