import React, { Fragment, useEffect } from "react";
import ContextMenuPortal from "../Templates/ContextMenuPortal";
import RightMenuPortal from "../components/Portals/RightMenuPortal.js";
import SCContextMenuPortal from "../components/menus/newContextMenu/SCContextMenuPortal";
import { MenuItem, SubMenu } from "@szhsin/react-menu";
import ContextMenu from "../components/menus/newContextMenu/ContextMenu";
import OrderSubMenu from "../components/menus/newContextMenu/OrderSubMenu";
import { Exit } from "../components/IconButtons/SubMenuIcons";
import SizeAndRotation from "../components/RightMenuComponents/SizeAndRotationComponent";
import Position from "../components/RightMenuComponents/PositionComponent";
import ListSubheader from "@mui/material/ListSubheader";
import { IconButton } from "@mui/material";
import List from "@mui/material/List";

function HtmlObject({ value, onChangedSize, selected, onPropsChange, ...props }) {
  const [menuProps, setMenuProps] = React.useState(undefined);
  const [anchorPoint, setAnchorPoint] = React.useState({ x: 0, y: 0 });

  const htmlEl = React.useRef();

  useEffect(() => {
    if (props.style && props.style.width && props.style.height)
      onChangedSize(props.style.width, props.style.height);
    htmlEl.current.style.height = "100%";
    htmlEl.current.style.width = "100%";
  }, []);

  const handleContextClick = (e) => {
    if (!this.props.disableEdit && !props.moreThanOneSelected) {
      e.preventDefault();
      e.stopPropagation();
      setAnchorPoint({ x: e.pageX, y: e.pageY });
      setMenuProps(true);
    }
  };

  const onLoad = () => {
    onChangedSize(htmlEl.current.width, htmlEl.current.height);
    htmlEl.current.style.height = "100%";
    htmlEl.current.style.width = "100%";
  };
  const handleContextClose = () => {
    setAnchorPoint({ x: 0, y: 0 });
    setMenuProps(undefined);
  };

  return (
    <Fragment>
      {menuProps && (
        <SCContextMenuPortal>
          <ContextMenu
            handleClose={() => handleContextClose()}
            anchorPoint={anchorPoint}
            menuProps={menuProps}
            onDelete={props.onDelete}
          >
            <MenuItem>
              <span className="cm_btn_name">Format options</span>
            </MenuItem>
            <OrderSubMenu
              sentToFront={props.sentToFront}
              sentToBack={props.sentToBack}
              bringForword={props.bringForword}
              bringBackward={props.bringBackward}
            />
          </ContextMenu>
        </SCContextMenuPortal>
      )}
      {props.formatOptions && selected && (
        <RightMenuPortal>
          <div className={"right_menu_mask"}>
            <List
              style={{
                paddingLeft: "0",
                position: "absolute",
                width: "100%",
                height: "auto",
                maxWidth: 250,
                bgcolor: "background.paper",
                paddingBottom: "0",
                paddingTop: "0",
                // overflow:'scroll',
              }}
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <span style={{ fontWeight: 700, fontSize: "16px", textAlign: "left" }}>
                      Format Options
                    </span>
                    <IconButton onClick={props.toggleFormatOptions}>
                      <Exit />
                    </IconButton>
                  </div>
                </ListSubheader>
              }
            >
              <SizeAndRotation
                changeLockedAspect={props.changeLockedAspect}
                onChange={props.onUpdateSizeAndRotation}
                style={props.style}
                width={props.width}
                height={props.height}
                rotateAngle={props.rotateAngle}
                scaleX={props.scaleX}
                scaleY={props.scaleY}
              />
              <Position
                onChange={props.onUpdatePosition}
                style={props.style}
                top={props.top}
                left={props.left}
              />
            </List>
          </div>
        </RightMenuPortal>
      )}
      {typeof value === "string" ? (
        <div
          onContextMenu={(e) => handleContextClick(e)}
          style={props.style}
          onLoad={onLoad}
          ref={htmlEl}
          dangerouslySetInnerHTML={{ __html: value }}
        />
      ) : (
        <div
          onContextMenu={(e) => handleContextClick(e)}
          style={props.style}
          onLoad={onLoad}
          ref={htmlEl}
        >
          {value}
        </div>
      )}
    </Fragment>
  );
}

export default HtmlObject;
