const TableFontSizes = {
  ["0"]: {
    value: 0,
    fontSize: 10,
  },
  ["50"]: {
    value: 50,
    fontSize: 12,
  },
  ["100"]: {
    value: 100,
    fontSize: 14,
  },
  ["150"]: {
    value: 150,
    fontSize: 16,
  },
  ["200"]: {
    value: 200,
    fontSize: 18,
  },
};

const LimitCellWidth = {
  On: true,
  Off: false,
};

const tableConfig = "table_config";
const tableObject = "table_object";

const tableConfigTypes = {
  fontSize: "fontSize",
  limitCellWidth: "limitCellWidth",
  showFullNumbers: "showFullNumbers",
  displayOfDecimals: "displayOfDecimals",
  showCurrency: "showCurrency",
  displayOfDecimalsReport: "displayOfDecimalsReport",
  unitDisplayReportFormat: "unitDisplayReportFormat",
};

const chartConfig = "chart_config";
const chartObject = "chart_object";

const chartConfigTypes = {
  showGrowth: "showGrowth",
  showMargin: "showMargin",
  showBreakdown: "showBreakdown",
  unitValue: "unitValue",
  showNumberOfEmployees: "showNumberOfEmployees",
};

export {
  TableFontSizes,
  LimitCellWidth,
  tableConfig,
  tableObject,
  tableConfigTypes,
  chartConfig,
  chartObject,
  chartConfigTypes,
};
