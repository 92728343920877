import { Command } from "../../models/Command";

export class Swap extends Command {
  constructor({ actionForward, actionBackward, list, nodeA, nodeB, draggingIndex, droppingIndex }) {
    super({ actionForward, actionBackward, list, nodeA, nodeB, draggingIndex, droppingIndex });
  }

  async execute() {
    const { actionForward, nodeA, nodeB, list, draggingIndex, droppingIndex } = this.args;
    //await actionForward(nodeA, nodeB, draggingIndex, droppingIndex);
    await actionForward(list, draggingIndex, droppingIndex);
  }

  async undo() {
    console.log("Swap undo command");
    const { actionBackward, nodeA, nodeB, list, draggingIndex, droppingIndex } = this.args;
    //await actionBackward(nodeB, nodeA, droppingIndex, draggingIndex);
    await actionBackward(list, droppingIndex, draggingIndex);
  }

  getInfo() {
    return "This is AddBlankSection command";
  }
}
