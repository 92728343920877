import React, { Fragment, useState } from "react";
import "./HeaderTextEditor.scss";
import HeaderPortal from "../SlideHeader/HeaderPortal";
import all_icons from "../../all_icons";
import MaterialSelect from "../components/Select/MaterialSelect";
// import BorderWeight from '../components/IconButtons/BorderWeight'
import { Box, Slider, Menu } from "@mui/material";
import LineStartEdit from "../SlideHeader/EditorComponents/LineStartEditComponent";
import LineEndEdit from "../SlideHeader/EditorComponents/LineEndEditComponent";

import BorderColorEditComponent from "./EditorComponents/BorderColorEditComponent";
import BorderWeightEditComponent from "../SlideHeader/EditorComponents/BorderWeightEditComponent";
import BorderDashEditComponent from "../SlideHeader/EditorComponents/BorderDashEditComponent";
import {
  BorderColor,
  BorderDash,
  BorderWeight,
  LineEnd,
  Separator,
} from "../components/IconButtons/SubMenuIcons";
import ButtonJ from "../components/Buttons/Button";
import HeaderMidPortal from "../components/Portals/HeaderMidPortal";
import ButtonMui from "../../../components/buttons/buttonMui/buttonMui";

function HeaderLine(props) {
  const selected = props.visible;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openBorderWidth = Boolean(anchorEl);

  const onStyleChange = (style, value) => {
    props.onChange(style, value);
  };
  const handleClick1 = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const SelectLineType = () => {
    const options = [
      { name: "Normal", value: "solid" },
      { name: "Dashed", value: "dashed" },
      { name: "Dotted", value: "dotted" },
    ];

    return (
      <MaterialSelect
        options={options}
        value={"solid"}
        minWidth={"55px"}
        onChange={(e) => props.handleLineType(e)}
      />
    );
  };

  const lineborderWidth = () => {
    return (
      <>
        {/*<BorderWeight aria-controls={openBorderWidth ? 'basic-menu' : undefined}*/}
        {/*              aria-haspopup="true"*/}
        {/*              aria-expanded={openBorderWidth ? 'true' : undefined}*/}
        {/*              onClick={handleClick1}  />*/}
        {/*<Menu*/}
        {/*    id="basic-menu"*/}
        {/*    anchorEl={anchorEl}*/}
        {/*    open={openBorderWidth}*/}
        {/*    onClose={handleClose1}*/}
        {/*    MenuListProps={{*/}
        {/*        'aria-labelledby': 'basic-button',*/}
        {/*    }}*/}
        {/*>*/}
        {/*    hello*/}
        {/*</Menu>*/}
      </>
    );
  };

  return (
    <HeaderMidPortal>
      <BorderColorEditComponent onChange={props.onStyleChange} style={props.style} />

      <BorderWeightEditComponent onChange={props.onStyleChange} style={props.style} />

      <BorderDashEditComponent onChange={props.onStyleChange} style={props.style} />

      <LineStartEdit slideObjKey={props.slideObjKey} onChange={props.onStyleChange} />

      <LineEndEdit slideObjKey={props.slideObjKey} onChange={props.onStyleChange} />

      <Separator />

      <ButtonMui
        tooltipText={"Format options"}
        onClick={props.toggleFormatOptions}
        variant={"text"}
        roundnessType={"squared"}
        color={"black"}
        label={"Format options"}
      />
    </HeaderMidPortal>
  );
}

export default HeaderLine;
