import ReactDOM from "react-dom";

const HeaderPortal = ({ children, visible }) => {
  if (visible == false) {
    return null;
  }
  const el = document.getElementById("se_panel_header");
  if (el) {
    return ReactDOM.createPortal(children, el);
  } else {
    return null;
  }
};

export default HeaderPortal;
