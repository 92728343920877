import styled from "styled-components";

const StyledLogoWrapper = styled.div`
  align-content: center;
  align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-bottom: 36px;
  margin-top: 47px;
`;

export const LogoWrapper = (props) => {
  return <StyledLogoWrapper>{props.children}</StyledLogoWrapper>;
};
