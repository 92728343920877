import CalculatedDriver from "../../CalculatedDriver/index";
import { DriverCategories, SpecialChar, UnitTypes } from "../../CalculatedDriver/constants";
import MxIDHelper from "../../../MxIDHelper";
import Financing from "../../Financing";
import { FinancingTypes } from "../constants";
import CalculatedDriver_Values from "../../CalculatedDriver/CalculatedDriver_Values";

class LineOfCredit extends Financing {
  FinanceType = FinancingTypes.LineOfCredit;
  CreditLimit;
  InterestRate;

  constructor(db_record) {
    super(db_record);
    if (this.db_record) {
      this.clean();
      this.setDriversFromDataStorageSub();
      // this.buildPeriodsData();
    }
    this.SaveRevenue = this.Save;
    this.Save = this.SaveFinancing;
  }

  clean = (cleanDrivers = false) => {
    if (this.db_record && this.Totals) {
      this.Name = this.db_record.Name;
      this.FinanceType = this.db_record.FinanceType;
      this.ID_CompanyScenario = this.db_record.ID_CompanyScenario;
      if (cleanDrivers) {
        this.CreditLimit.cleanValue();
        this.InterestRate.cleanValue();
        this.cleanDrivers();
      }
    }
  };

  static DriversDesc = Object.assign({
    Total: {
      driverName: "$Name",
      fieldName: "Totals",
      driverID: "total",
      unit: UnitTypes.Price,
      category: DriverCategories.LastPeriod,
    },
    OpenningBalance: {
      driverName: `Opening Balance${SpecialChar.DriverNameESCChar}$Name`,
      fieldName: "OpenningBalance",
      driverID: "openning_balance",
      unit: UnitTypes.Price,
      category: DriverCategories.FirstPeriod,
    },
    Withdrawals: {
      driverName: `Withdrawals${SpecialChar.DriverNameESCChar}$Name`,
      fieldName: "Withdrawals",
      driverID: "withdrawals",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    Repayments: {
      driverName: `Repayments${SpecialChar.DriverNameESCChar}$Name`,
      fieldName: "Repayments",
      driverID: "repayments",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    LineOfCreditBalance: {
      driverName: `Line Of Credit Balance${SpecialChar.DriverNameESCChar}$Name`,
      fieldName: "LineOfCreditBalance",
      driverID: "line_of_credit_balance",
      unit: UnitTypes.Price,
      category: DriverCategories.LastPeriod,
    },
    InterestExpense: {
      driverName: `Interest Expense${SpecialChar.DriverNameESCChar}$Name`,
      fieldName: "InterestExpense",
      driverID: "interest_expense",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
  });
  Save = (callBack, saveDrivers = true) => {
    if (!this.isNew && !this.IsCreated) {
      global.Modeliks.put(this.constructor.TableName, null, this, (res) => {
        if (saveDrivers) {
          this.SaveDrivers(callBack);
        } else {
          callBack();
        }
      });
    } else {
      global.Modeliks.put(this.constructor.TableName, null, this, (res) => {
        if (saveDrivers) {
          this.SaveDrivers(callBack);
        } else {
          callBack();
        }
      });
    }
  };
  SaveFinancing = (callBack, saveDrivers = true) => {
    this.SaveRevenue((newID) => {
      this.CreditLimit.Save();
      this.InterestRate.Save();

      if (saveDrivers) {
        this.SaveDrivers(callBack, this.ID);
      } else {
        callBack(this.ID);
      }
    }, false);
  };

  Delete = (callBack) => {
    this.DeleteFunc(this.ID, () => {
      global.Modeliks.del(
        global.Modeliks.Tables.Finance_CalculatedDriver_Values.TableName,
        { ID: this.getCreditLimitID(), ID_CompanyScenario: global.Modeliks.CompanyScenarioInfo.ID },
        (res) => {
          global.Modeliks.del(
            global.Modeliks.Tables.Finance_CalculatedDriver_Values.TableName,
            {
              ID: this.getInterestRateID(),
              ID_CompanyScenario: global.Modeliks.CompanyScenarioInfo.ID,
            },
            (res) => {
              if (callBack) {
                callBack();
              }
            },
          );
        },
      );
    });
  };

  changeDriversName = () => {
    Object.keys(this.constructor.DriversDesc).forEach((key) => {
      const driverDesc = this.constructor.DriversDesc[key];
      if (this[driverDesc.fieldName]) {
        this[driverDesc.fieldName].DriverName = driverDesc.driverName.replace("$Name", this.Name);
      } else {
        console.log("driver not found", this, driverDesc, this[driverDesc.fieldName]);
      }
    });
  };
  setDriversFromDataStorageSub = () => {
    this.CreditLimit = global.Modeliks.DriverValuesStore.getItem(this.getCreditLimitID());
    this.InterestRate = global.Modeliks.DriverValuesStore.getItem(this.getInterestRateID());
  };

  periodsData = {};

  buildPeriodsData = () => {
    const allPeriods = this.OpenningBalance.Values.map((c) => c.Date);
    allPeriods.forEach((period) => {
      this.periodsData[period.dateID] = {};
      Object.values(this.constructor.DriversDesc).forEach(
        (driver) =>
          (this.periodsData[period.dateID][driver.fieldName] = this[
            driver.fieldName
          ].getItemByDateSufix(period.sufix)),
      );
      this.periodsData[period.dateID].Date = period;
    });
  };

  getCreditLimitID = () => {
    return `${LineOfCredit.TableName}-${this.ID}-credit_limit`;
  };
  getInterestRateID = () => {
    return `${LineOfCredit.TableName}-${this.ID}-interest_rate`;
  };

  getMonthDatesAll = () => {
    return [...global.Modeliks.DateHelper.months];
  };

  getYearDatesAll = () => {
    return global.Modeliks.DateHelper.years_all;
  };

  createExtraPeriods = () => {
    // Object.values(this.constructor.DriversDesc).forEach(driver => {
    //     this[driver.fieldName].addMonths(0, true);
    // })

    this.buildPeriodsData();
  };

  createMonthsFormulas = () => {
    const months = this.getMonthDatesAll();

    months.forEach((month) => {
      const prevMonthDate = months.find((c) => c.Order == month.Order - 1);
      const curMonth = this.periodsData[month.dateID];
      const prevMonth = prevMonthDate ? this.periodsData[prevMonthDate.dateID] : null;

      if (prevMonth) {
        curMonth.OpenningBalance.Formula = `${prevMonth.LineOfCreditBalance}`;
      }
      curMonth.LineOfCreditBalance.Formula = `${curMonth.OpenningBalance}+${curMonth.Withdrawals}-${curMonth.Repayments}`;
      curMonth.InterestExpense.Formula = `MxMath.Max(${curMonth.LineOfCreditBalance} * ${this.InterestRate} / 12, 0)`;
      curMonth.Totals.Formula = `${curMonth.LineOfCreditBalance}`;
    });
  };

  createYearsFormulas = () => {
    const years = this.getYearDatesAll();

    years.forEach((year) => {
      const curYear = this.periodsData[year.dateID];
      const prevYearDate = years.find((c) => c.Order === year.Order - 1);
      const prevYear = prevYearDate ? this.periodsData[prevYearDate.dateID] : null;
      const months = this.periodsData[year.dateID].Date.monthIndexes.map(
        (index) => this.periodsData[index],
      );
      const lastMonth = months[months.length - 1];
      const firstMonth = months[0];

      if (!year.Active) {
        curYear.OpenningBalance.Formula = `${firstMonth.OpenningBalance}`;
        curYear.Withdrawals.Formula = `MxMath.Sum([${months.map((c) => c.Withdrawals.ID_f).join(",")}])`;
        curYear.Repayments.Formula = `MxMath.Sum([${months.map((c) => c.Repayments.ID_f).join(",")}])`;
        curYear.LineOfCreditBalance.Formula = `${lastMonth.LineOfCreditBalance}`;
        curYear.InterestExpense.Formula = `MxMath.Sum([${months.map((c) => c.InterestExpense.ID_f).join(",")}])`;
        curYear.Totals.Formula = `${curYear.LineOfCreditBalance}`;
      } else {
        curYear.OpenningBalance.Formula = `${prevYear.LineOfCreditBalance}`;
        curYear.LineOfCreditBalance.Formula = `${curYear.OpenningBalance}+${curYear.Withdrawals}-${curYear.Repayments}`;
        curYear.InterestExpense.Formula = `MxMath.Max(${curYear.LineOfCreditBalance} * ${this.InterestRate}, 0)`;
        curYear.Totals.Formula = `${curYear.LineOfCreditBalance}`;
      }
    });
  };

  setFinance = () => {
    this.buildPeriodsData();
    this.createMonthsFormulas();
    this.createYearsFormulas();
  };

  static convert_Finance = (finance) => {
    const newFinancing = new LineOfCredit();
    newFinancing.Name = finance.Name;
    newFinancing.ID = finance.ID;
    newFinancing.IsCreated = true;
    newFinancing.ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
    newFinancing.Totals = CalculatedDriver.createDriverFromTable(
      newFinancing,
      Financing.DriversDesc.Total.driverID,
      UnitTypes.Price,
      DriverCategories.Sum,
    );
    newFinancing.CreditLimit = new CalculatedDriver_Values(null, newFinancing.getCreditLimitID());
    newFinancing.InterestRate = new CalculatedDriver_Values(
      null,
      newFinancing.getInterestRateID(),
      null,
      UnitTypes.Percentage,
    );
    newFinancing.createDrivers();
    newFinancing.createExtraPeriods();

    return newFinancing;
  };
}

export default LineOfCredit;
