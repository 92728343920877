import React from "react";
import { Dialog, DialogActions } from "@mui/material";
import PropTypes from "prop-types";
import Button from "../../actions/Button";
import styled from "styled-components";
import IconButton from "../../actions/IconButton";
import { IconPlus, IconX } from "../../icons/svgIcons";
import FinanceDetailsDialog from "../../../pages/Secure/Financials/components/FinanceDetails/FinanceDetailsDialog";
import ButtonMui from "../../buttons/buttonMui/buttonMui";

const P1Strong = styled.div`
  padding-left: 7px;
  font-weight: 700;
  font-size: 20px;
  font-family: Inter;
`;

function CancelDialog({ disabled, open, onExit, onDelete, ...props }) {
  return (
    <>
      <Dialog
        open={open}
        onClose={onExit}
        scroll="paper"
        maxWidth={"md"}
        sx={{
          zIndex: 2000,
          ".MuiBackdrop-root": {
            backgroundColor: `rgb(255 255 255 / 90%)`,
          },
          "& .MuiDialog-container": {
            justifyContent: "center",
            alignItems: "center",
          },
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "800px", // Set your width here
            maxHeight: props.maxHeight ? props.maxHeight : "260px",
          },
        }}
        PaperProps={{
          sx: {
            position: "fixed",
            marginLeft: "25px",
            marginRight: "38px",
            boxShadow: `0px 0px 10px rgba(0, 0, 0, 0.15)`,
          },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingRight: "10px",
            paddingTop: "35px",
            paddingLeft: "13px",
          }}
        >
          <P1Strong>{props.title ? props.title : "Cancel your account"}</P1Strong>
          <IconButton size="small" onClick={onExit}>
            <IconX />
          </IconButton>
        </div>
        <div
          style={{
            paddingLeft: "20px",
            paddingRight: "50px",
            paddingTop: "20px",
            fontFamily: "Inter",
            fontSize: "14px",
            lineHeight: "28px",
            overflowY: "scroll",
          }}
        >
          {props.children}
        </div>
        <DialogActions
          sx={{
            paddingBottom: "15px",
          }}
        >
          {props.showFirstBtn && (
            <ButtonMui
              label={props.firstBtnName ? props.firstBtnName : "Go Back"}
              variant={"contained"}
              onClick={onExit}
            />
          )}
          <ButtonMui
            label={props.btnName ? props.btnName : "Cancel Account"}
            variant={"contained"}
            color={"red"}
            onClick={() => onDelete()}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}

CancelDialog.propTypes = {
  open: PropTypes.bool,
  onDelete: PropTypes.func,
  onExit: PropTypes.func,
  onPreviousClick: PropTypes.func,
  showFirstBtn: PropTypes.bool,
};

CancelDialog.defaultProps = {
  showFirstBtn: true,
};

export default CancelDialog;
