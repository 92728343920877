import React, { useState, useEffect, memo } from "react";
import { ControlledMenu, MenuItem, useMenuState, SubMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "./ContextMenu.scss";
import { Copy, Cut, Paste } from "../../IconButtons/SubMenuIcons";

const ContextMenu = (props) => {
  const [menuProps, toggleMenu] = useMenuState();
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });

  if (menuProps.state !== props.menuProps) {
    toggleMenu(props.menuProps);
    if (anchorPoint)
      if (anchorPoint.x !== props.anchorPoint.x || anchorPoint.y !== props.anchorPoint.y) {
        setAnchorPoint({ x: props.anchorPoint.x, y: props.anchorPoint.y });
      }
  }

  return (
    <ControlledMenu
      {...menuProps}
      anchorPoint={anchorPoint}
      onClose={() => {
        toggleMenu(false);
        props.handleClose();
      }}
      className={props.borderMenu ? "border_menu" : "context_menu"}
    >
      {props.children}
    </ControlledMenu>
  );
};
export default memo(ContextMenu);
