export const swearWords = [
  "arse",
  "ass",
  "asshole",
  "bastard",
  "bitch",
  "bastard",
  "bollocks",
  "bullshit",
  "crap",
  "cunt",
  "cock",
  "dick",
  "fuck",
  "fanny",
  "motherfucker",
  "pussy",
  "prick",
  "shit",
];
