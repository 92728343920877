import * as React from "react";
import Dialog from "@mui/material/Dialog";
import ButtonJ from "../Buttons/Button";
import TabsMenu from "../menus/TabsMenu/TabsMenu";
import "./SectionDialog.scss";
import IconButton from "@mui/material/IconButton";
import { BluePlus, Exit } from "../IconButtons/SubMenuIcons";
import "./SectionName.scss";
import CircleCheckboxM from "../../../../components/actions/CircleCheckboxM";
import Button from "../../../../components/actions/Button";

export default function AddInstructionDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [name, setName] = React.useState(props.instructions);

  const handleClickOpen = () => {
    setName(props.instructions);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    props.onSave(name, props.isLong);
    setOpen(false);
  };

  return (
    <React.Fragment>
      {
        <Button
          color="primary"
          noElevation={true}
          disable={props.disableEdit}
          padding={"6px 0 6px 0"}
          size={"medium"}
          width={"200px"}
          onClick={handleClickOpen}
          label={props.label}
          iconRight={true}
          icon={"IconPlus"}
        />
      }
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        className={"name_dialog"}
        onClose={handleClose}
      >
        {success ? (
          <>
            <div className={"checkbox_container"}>
              <CircleCheckboxM size="large" checked={true} />
            </div>
            <div className="Label_2">Successfully saved</div>
            <div className="Paragraph_P4">Your instructions is successfully saved</div>
            <ButtonJ
              size="large"
              width="118px"
              padding="0 0 0 0"
              backgroundColor="primary"
              label={"OK"}
              onClick={() => handleClose()}
            />
          </>
        ) : (
          <>
            <div className={"label"}>Save instructions</div>
            <div className={"input_container"}>
              <input
                className={"input"}
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder={""}
              />
            </div>
            <div className={"btns_container"}>
              <ButtonJ
                size="large"
                width="118px"
                padding="0 0 0 0"
                color="black"
                noElevation={true}
                onClick={handleClose}
                label={"Cancel"}
              />
              <ButtonJ
                size="large"
                width="118px"
                padding="0 0 0 0"
                backgroundColor="primary"
                label={"Save instructions"}
                onClick={() => handleSave()}
              />
            </div>
          </>
        )}
      </Dialog>
    </React.Fragment>
  );
}
