import React from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import ButtonMui from "../../../buttons/buttonMui/buttonMui";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { IconX } from "../../../icons/svgIcons";

export const WarningActionTitle = ({ ...props }) => {
  return (
    <>
      <DialogTitle
        sx={{
          fontWeight: 700,
          padding: 0,
          fontFamily: "Inter",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span>{props.title}</span>
        <IconButton onClick={props.onCancel}>
          <IconX />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          textAlign: "left",
          minHeight: "50px",
          display: "flex",
          alignItems: "center",
          padding: "20px",
          paddingTop: "10px !important",
          paddingBottom: "10px !important",
        }}
      >
        {props.message}
      </DialogContent>
      <DialogActions disableSpacing={false} sx={{ justifyContent: "center" }}>
        <ButtonMui width={125} label="Cancel" onClick={props.onCancel} variant={"contained"} />
        <ButtonMui
          width={125}
          label="Confirm"
          color="red"
          onClick={props.onAccept}
          variant={"contained"}
        />
      </DialogActions>
    </>
  );
};
