import React from "react";
import "@szhsin/react-menu/dist/index.css";
import "../../../Admin/Dashboards/components/dashboardsBlankAdmin.scss";
import {
  ArrowDown,
  StarChartIcon,
  TrashCan,
  TrashCan2,
} from "../../../../components/icons/svgIcons";
import request from "superagent";
import CircleLoader from "../../../../components/Loaders/CircleLoader/CircleLoader";
import DraggableGrid from "../../../Admin/Dashboards/components/DraggableGrid/DraggableGrid";
import ChartsComponent from "../../../../SlideEditor/components/ChartsComponent";
import { Label } from "../../../../components/typography/label/Label";
import DatePickerYM from "../../../../components/actions/DatePickers/DatePickerYM";
import dataStructure from "../../../../data/datastructure";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import {
  ComparisonPeriod,
  NumberFormatTypes,
  ReportFrequency,
  StreamTypes,
} from "../../../../components/constants/charts";
import MaterialSelect from "../../../../components/actions/SelectM";
import DriverInsertionTool from "../../../../SlideEditor/Admin/components/DriverInsertTemporary/DriverInsertionTool";
import IconButton from "@mui/material/IconButton";
import AddChartDialog from "../CustomDashboards/AddChartDialog";
import ReactDOM from "react-dom";
import { DateGetMonth, DateGetYear, DateMonthChange } from "../../../../helpers/DateSettings";
import ButtonMui from "../../../../components/buttons/buttonMui/buttonMui";
import "../../Financials/Reports/Summary/reports_summary.scss";
import FinancialStatements from "../FinancialStatements";
import CustomKPIMain from "../CustomKPI";
import { DialogTypes } from "../../../../data/Finance/constants";

const deleteElText = "Are you sure you want to delete the element?";

export default class DashboardsBlank extends React.Component {
  allCharts = [];
  didMount = true;
  allChartSettings = null;
  sharedChartFunctionsObj = {};

  constructor(props) {
    super(props);
    const ActualDates = require("../../../Secure/Financials/Actuals/constants").ActualDates;

    this.state = {
      key: new Date().getTime(),
      loading: true,
      selectedSubsection: this.setSubsection(),
      openChartDialog: false,
      minDate: props.curMonths.minDate ? props.curMonths.minDate : ActualDates.minDate,
      maxDate: props.curMonths.maxDate ? props.curMonths.maxDate : ActualDates.maxDate,
      minYear: props.curMonths.minYear ? props.curMonths.minYear : ActualDates.minYear,
      maxYear: props.curMonths.maxYear ? props.curMonths.maxYear : ActualDates.maxYear,
      months: ActualDates.allMonths,
      years: ActualDates.allYears,
      allMonths: ActualDates.allMonths,
      allYears: ActualDates.allYears,
      startMonth: props.curMonths.startMonth,
      endMonth: props.curMonths.endMonth,

      startYear: props.curMonths.startYear,
      endYear: props.curMonths.endYear,
      startMonthOrder: props.curMonths.startMonthOrder,
      endMonthOrder: props.curMonths.endMonthOrder,
      startYearOrder: props.curMonths.startYearOrder,
      endYearOrder: props.curMonths.endYearOrder,

      reportFrequency: props.curMonths.reportFrequency,
      comparisonPeriod: props.curMonths.comparisonPeriod,
      showMonthly: props.showMonthly,
      numberFormatType: props.curMonths.numberFormatType,
    };

    this.setUpSubsection();
    this.getChartSettings(() => this.getAllCharts());

    this.draggableItems = [];
  }

  init = () => {
    this.currentCharts = this.getCurrentCharts();
    this.currentFS = this.getCurrentFinancialStatementTables();
    this.currentKPIs = this.getCurrentCustomKPITables();
    this.currentCustomKPIs = this.currentKPIs
      .map((kpi) => {
        let obj = {
          kpi: global.Modeliks.CustomKPIStore.getItem(kpi.ID),
          props: { defaultView: kpi.defaultView },
        };
        if (kpi.hasOwnProperty("y")) {
          Object.assign(obj, { y: kpi.y });
        }
        return obj;
      })
      .filter((c) => !!c.kpi);

    this.draggableItems = [
      ...this.currentCharts.map((chart, index) => {
        return (
          <ChartsComponent
            isSaasDashboard={this.props.prefix_route === "saas_dashboard"}
            isFirstDriverName={this.isFirstDriverName()}
            titleButton={this.getTitleButton(chart.ID)}
            showIndicatorBoxes={this.props.dataKey === "SingleIndicators"}
            sharedChartFunctionsObj={this.sharedChartFunctionsObj}
            saveChartOptions={(value) => this.saveChartSettings(value, chart)}
            dateRange={this.getDateRange()}
            months={this.state.months}
            years={this.state.years}
            reportFrequency={this.state.reportFrequency}
            comparisonPeriod={this.state.comparisonPeriod}
            numberFormat={this.state.numberFormatType}
            grid
            selectedDashboardChart={chart}
          />
        );
      }),
      ...this.currentFS.map((financial_statement, index) => {
        return (
          <FinancialStatements
            key={"c_d_fs_" + index}
            {...this.getFSProps(financial_statement)}
            onDeleteTable={() => this.handleDeleteFinancialStatement(index)}
            FinancialStatements={this.props.FinancialStatements}
            defaultSelectedReport={
              financial_statement.defaultSelectedReport
                ? financial_statement.defaultSelectedReport
                : this.props.defaultSelectedReport
            }
          />
        );
      }),
      ...this.currentCustomKPIs.map((kpiObj, index) => {
        kpiObj.props.onDeleteTable = () => this.handleDeleteKpi(index);
        const CustomKPI = {
          selectedKPI: kpiObj.kpi,
          onDeleteTable: () => this.handleDeleteKpi(index),
        };
        return (
          <CustomKPIMain
            key={"c_d_kpi_" + index}
            {...{ ...this.props.dashProps, ...kpiObj.props }}
            CustomKPI={CustomKPI}
          />
        );
      }),
    ];
  };

  setUpSubsection = () => {
    if (this.props.curCharts && this.state.selectedSubsection) {
      this.props.curCharts.selectedSubsection = this.state.selectedSubsection;
    }
  };

  setSubsection = () => {
    if (this.props.dataKey) {
      if (this.props.data[this.props.dataKey]) {
        return this.props.data[this.props.dataKey].subsections[
          Object.keys(this.props.data[this.props.dataKey].subsections)[0]
        ];
      }
    }
    if (this.props.subSection) {
      return this.props.subSection;
    }
    return null;
  };

  getChartSettings = (callBack) => {
    if (global.Modeliks.ChartSettings) {
      this.allChartSettings = global.Modeliks.ChartSettings;
      console.log(JSON.parse(this.allChartSettings.Value));
      callBack && callBack();
    } else {
      global.Modeliks.get(
        dataStructure.Settings_Client.TableName,
        { Key: "ChartSettings" },
        (success, err) => {
          if (success) {
            if (success.length) {
              this.allChartSettings = success[0];
            }
            callBack && callBack();
          } else {
            console.error(err);
          }
        },
      );
    }
  };

  saveChartSettings = (value, chart) => {
    if (this.allChartSettings) {
      let newValue = JSON.parse(this.allChartSettings.Value);

      if (!newValue[chart.ID]) {
        newValue[chart.ID] = {};
      }
      Object.assign(newValue[chart.ID], value);
      this.allChartSettings.Value = JSON.stringify(newValue);
      const findChartToUpdate = this.currentCharts.find((d) => d.ID === chart.ID);
      if (findChartToUpdate) {
        if (typeof value === "object" && value !== null) {
          Object.keys(value).forEach((key) => {
            findChartToUpdate.Data.chartOptions[key] = value[key];
          });
        }
      }
      this.allChartSettings.Save((success, err) => {
        global.Modeliks.GetChartSettings(this.allChartSettings);
        if (!success) {
          console.error(err);
        }
      });
    } else {
      let newValue = {};
      newValue[chart.ID] = value;
      global.Modeliks.post(
        dataStructure.Settings_Client.TableName,
        { Key: "ChartSettings", Value: JSON.stringify(newValue) },
        (success, err) => {
          if (!success) {
            console.error(err);
          }
        },
      );
    }
  };

  getAllCharts = () => {
    request
      .get(`/api/master/dashboardcharts`)
      .set("Access-Control-Allow-Origin", window.location.protocol + "//" + window.location.host)
      .set("Accept", "application/json")
      .set("authorization", "Bearer " + window.localStorage.getItem("token"))
      .query(this.props.chartIDs && this.props.chartIDs)
      .then((res, err) => {
        this.allCharts = res.body.map((c) => this.getChartParsedData(c));
        if (this.props.curCharts) {
          this.props.curCharts.allCharts = this.allCharts;
        }
        this.init();
        if (this.didMount) {
          this.setState({ loading: false });
        } else {
          this.state.loading = false;
        }
        this.props.handleStreamLoad && this.props.handleStreamLoad(false);
      });
  };

  SaveChart = (chart, callBack) => {
    request
      .put(`/api/master/dashboardcharts`)
      .query({ ID: chart.ID })
      .send({ Data: JSON.stringify(chart.Data) })
      .set("Access-Control-Allow-Origin", window.location.protocol + "//" + window.location.host)
      .set("Accept", "application/json")
      .set("authorization", "Bearer " + window.localStorage.getItem("token"))
      .then((res, err) => {
        callBack && callBack();
      });
  };

  getChartParsedData = (chart) => {
    let chartSettings = null;
    if (this.allChartSettings) {
      chartSettings = JSON.parse(this.allChartSettings.Value);
    }
    let chartData = JSON.parse(chart.Data);

    if (this.props.dataKey === "SingleIndicators") {
      if (this.props.curMonths.driverKeys.length) {
        chartData.chartData.driverKeys = this.props.curMonths.driverKeys;
      }
    }

    delete chartData.chartOptions.numberFormat;

    if (chartSettings && chartSettings[chart.ID]) {
      if (chartSettings[chart.ID].chartClassType) {
        chartData.chartClassType = chartSettings[chart.ID].chartClassType;
      }

      Object.assign(chartData.chartOptions, chartSettings[chart.ID]);
    }
    return { ...chart, Data: chartData };
  };

  getCurrentCharts = () => {
    if (this.props.getCurrentCharts) {
      return this.props.getCurrentCharts();
    }
    return this.allCharts.filter((c) => this.state.selectedSubsection.charts.hasOwnProperty(c.ID));
  };

  getCurrentFinancialStatementTables = () => {
    if (
      this.state.selectedSubsection.financial_statements &&
      this.state.selectedSubsection.financial_statements.length
    ) {
      return this.state.selectedSubsection.financial_statements;
    }
    return [];
  };
  getCurrentCustomKPITables = () => {
    if (
      this.state.selectedSubsection.custom_kpis &&
      this.state.selectedSubsection.custom_kpis.length
    ) {
      return this.state.selectedSubsection.custom_kpis;
    }
    return [];
  };

  handleLayoutChange = (positions) => {
    let charts = this.state.selectedSubsection.charts;
    let financial_statements = this.state.selectedSubsection.financial_statements || [];
    let custom_kpis = this.state.selectedSubsection.custom_kpis || [];

    if (Object.keys(positions).length) {
      Object.keys(charts).forEach((k, index) => {
        if (positions[index]) {
          Object.assign(charts[k], positions[index]);
        }
      });
      financial_statements.forEach((k, index) => {
        let position_index = index + Object.keys(charts).length;
        if (positions[position_index]) {
          Object.assign(financial_statements[index], positions[position_index]);
        }
      });
      custom_kpis.forEach((k, index) => {
        let position_index = index + financial_statements.length + Object.keys(charts).length;
        if (positions[position_index]) {
          Object.assign(custom_kpis[index], positions[position_index]);
        }
      });
    }

    this.props.saveDashboard &&
      this.props.saveDashboard(this.state.selectedSubsection, null, this.props.customDashboard);
  };

  getMonth = (Year, Month) => DateGetMonth(this.state.months, Month, Year);

  getYear = (Header) => DateGetYear(this.state.years, Header);

  changeFromDate = (e) => {
    const Year = e.getFullYear();
    const Month = e.getMonth();
    const MonthParsed = Month + 1;
    const startMonthOrder = this.getMonth(Year, Month);
    if (startMonthOrder) {
      this.props.curMonths.startMonthOrder = startMonthOrder.Order;
      this.props.curMonths.startMonth = `${Year}-${MonthParsed}`;
      this.props.saveSettings && this.props.saveSettings();
      this.state.startMonthOrder = startMonthOrder.Order;
      this.state.startMonth = `${Year}-${MonthParsed}`;
      this.init();
      this.setState({ key: new Date().getTime() });
    }
  };

  changeToDate = (e) => {
    const Year = e.getFullYear();
    const Month = e.getMonth();
    const MonthParsed = Month + 1;
    const endMonthOrder = this.getMonth(Year, Month);
    if (endMonthOrder) {
      this.props.curMonths.endMonthOrder = endMonthOrder.Order;
      this.props.curMonths.endMonth = `${Year}-${MonthParsed}`;
      this.props.saveSettings && this.props.saveSettings();
      this.state.endMonthOrder = endMonthOrder.Order;
      this.state.endMonth = `${Year}-${MonthParsed}`;
      this.init();
      this.setState({ key: new Date().getTime() });
    }
  };

  getMonthByYear = (Year) => {
    let month = this.state.months.find((m) => m.Year === Year && m.Order % 12 === 0);
    if (month) {
      return month;
    }
    return this.state.months.find((m) => m.Order === 0);
  };

  changeFromDateYear = (e) => {
    const Year = e.getFullYear();
    const startYear = this.getYear(Year);
    // const startMonth = this.getMonthByYear(Year);
    // const Month = this.state.months.find(c => c.Order === startMonth.Order).Order;
    if (startYear) {
      // this.props.curMonths.startMonthOrder = startMonth.Order;
      // this.props.curMonths.startMonth = `${Year}-${Month+1}`;
      this.props.curMonths.startYearOrder = startYear.Order;
      this.props.curMonths.startYear = `${startYear.Header}`;
      this.props.saveSettings && this.props.saveSettings();
      this.state.startYearOrder = startYear.Order;
      this.state.startYear = `${startYear.Header}`;
      this.init();
      this.setState({ key: new Date().getTime() });
    }
  };

  changeToDateYear = (e) => {
    const Year = e.getFullYear();
    const endYear = this.getYear(Year);
    // const endMonth = this.getMonthByYear(Year);
    // const Month = this.state.months.find(c => c.Order === endMonth.Order);
    if (endYear) {
      // this.props.curMonths.endMonthOrder = endMonth.Order;
      // this.props.curMonths.endMonth = `${Year}-${Month+1}-1`;
      this.props.curMonths.endYearOrder = endYear.Order;
      this.props.curMonths.endYear = `${endYear.Header}`;
      this.props.saveSettings && this.props.saveSettings();
      this.state.endYearOrder = endYear.Order;
      this.state.endYear = `${endYear.Header}`;
      this.init();
      this.setState({ key: new Date().getTime() });
    }
  };

  componentDidMount() {
    this.didMount = true;
  }

  getDateRange = () => {
    return {
      dateFrom: {
        month: this.state.allMonths.find((c) => c.Order == this.props.curMonths.startMonthOrder),
        year: this.state.allYears.find((c) => c.Order == this.props.curMonths.startYearOrder),
      },
      dateTo: {
        month: this.state.allMonths.find((c) => c.Order == this.props.curMonths.endMonthOrder),
        year: this.state.allYears.find((c) => c.Order == this.props.curMonths.endYearOrder),
      },
    };
  };

  changeReportFrequency = (e, value) => {
    this.state.reportFrequency = value;
    this.init();
    this.setState({ reportFrequency: value, key: new Date().getTime() });
  };

  changeComparisonPeriod = (value, index) => {
    let newArr = this.state.comparisonPeriod;
    newArr[index] = value;
    this.state.comparisonPeriod = newArr;
    this.init();
    this.setState({ comparisonPeriod: newArr, key: new Date().getTime() }, () => {
      this.props.curMonths.comparisonPeriod = this.state.comparisonPeriod;
      this.props.saveSettings && this.props.saveSettings();
    });
  };

  changeFormatType = (value) => {
    this.state.numberFormatType = value;
    this.props.curMonths.numberFormatType = value;
    this.init();
    this.currentCharts.forEach((chart) => (chart.Data.chartOptions.numberFormat = value));
    this.props.saveSettings && this.props.saveSettings("numberFormatType");
    this.setState({ key: new Date().getTime() });
  };

  insertDriver = (driverKey) => {
    this.props.curMonths.driverKeys = [driverKey];
    if (global.Modeliks.PERMISSIONS.Dashboards.restrictions.edit.active) {
      this.props.saveSettings && this.props.saveSettings();
    }
    this.currentCharts.forEach(
      (chart) => (chart.Data.chartData.driverKeys = this.props.curMonths.driverKeys),
    );
    this.setState({ key: new Date().getTime() });
  };

  getSectionItem = () => {
    if (this.props.dashboardChanger) {
      return (
        <>
          <div />
          <div className={"psfac_actuals_single_filter vertical gap"}>
            <Label style={{ marginBottom: 0, marginLeft: "5px" }} color={"black"} size="label3" />
            {this.props.dashboardChanger}
          </div>
        </>
      );
    }
    if (this.props.dataKey === "SingleIndicators") {
      return (
        <>
          <div />
          <div className={"psfac_actuals_single_filter vertical gap"}>
            <Label
              style={{ marginBottom: 0, marginLeft: "11px" }}
              color={"black"}
              size="label3"
              label={this.state.selectedSubsection.name}
            />
            <DriverInsertionTool
              value={this.props.curMonths.driverKeys[0]}
              validDrivers={StreamTypes}
              insertExistingDriver={this.insertDriver}
            />
          </div>
        </>
      );
    }
    return <div />;
  };

  getReportFrequencyChanger = () => {
    if (
      this.props.dataKey === "SingleIndicators" ||
      this.props.dataKey === "Ratios" ||
      this.props.dataKey === "ForecastSummary" ||
      this.props.dataKey === "ForecastSummaryMonthly" ||
      this.props.dataKey === "SaasDashboardMonthly" ||
      this.props.dataKey === "SaasDashboard"
    ) {
      return null;
    }
    return (
      <div style={{ display: "flex", flexDirection: "column", rowGap: 7 }}>
        <Label
          style={{ marginBottom: 0 }}
          color={"black"}
          size="label3"
          label={"Report Frequency"}
        />
        <Tabs
          value={this.state.reportFrequency}
          textColor="secondary"
          className="psdf_navigation_menu_buttons apply_color"
          onChange={this.changeReportFrequency}
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          {Object.entries(ReportFrequency)
            .filter((c, index) => Object.values(ReportFrequency).length - 1 > index)
            .map(([key, value]) => {
              return (
                <Tab
                  key={`${key} ${value}`}
                  label={value.name.replace(/([A-Z])/g, " $1").trim()}
                  value={key}
                  className="psdf_navigation_menu_tab"
                />
              );
            })}
        </Tabs>
      </div>
    );
  };

  getComparisonPeriodChanger = () => {
    if (
      this.props.dataKey === "SingleIndicators" ||
      this.props.dataKey === "ForecastSummary" ||
      this.props.dataKey === "ForecastSummaryMonthly" ||
      this.props.dataKey === "SaasDashboard" ||
      this.props.dataKey === "SaasDashboardMonthly"
    ) {
      return null;
    }
    return (
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          rowGap: "7px",
          flexDirection: "column",
        }}
      >
        <Label
          style={{ marginBottom: 0 }}
          color={"black"}
          size="label3"
          label={"Comparison Periods"}
        />
        <div style={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
          <MaterialSelect
            zIndex={1}
            width="150px"
            onChange={(e) => {
              this.changeComparisonPeriod(e.target.value, 0);
            }}
            value={this.state.comparisonPeriod[0]}
            options={Object.values(ComparisonPeriod)
              .filter((c, index) => Object.values(ComparisonPeriod).length - 1 > index)
              .map((c) => {
                return { description: c, value: c };
              })}
          />
          <Label style={{ margin: 0 }} color={"black"} size="label3" label={"vs."} />
          <MaterialSelect
            zIndex={1}
            width="150px"
            onChange={(e) => {
              this.changeComparisonPeriod(e.target.value, 1);
            }}
            value={this.state.comparisonPeriod[1]}
            options={Object.values(ComparisonPeriod)
              .filter((c, index) => Object.values(ComparisonPeriod).length - 1 > index)
              .map((c) => {
                return { description: c, value: c };
              })}
          />
        </div>
      </div>
    );
  };

  getUnitChanger = () => {
    if (
      this.props.dataKey === "Charts" ||
      this.props.dataKey === "ForecastSummary" ||
      this.props.dataKey === "ForecastSummaryMonthly" ||
      this.props.unitChanger ||
      this.props.dataKey === "SaasDashboard" ||
      this.props.dataKey === "SaasDashboardMonthly"
    ) {
      return (
        <div style={{ display: "flex", flexDirection: "column", rowGap: 7 }}>
          <Label style={{ marginBottom: 0 }} color={"black"} size="label3" label={"Unit display"} />
          <MaterialSelect
            zIndex={1}
            width="150px"
            onChange={(e) => {
              this.changeFormatType(e.target.value);
            }}
            value={this.state.numberFormatType}
            options={Object.values(NumberFormatTypes).map((c) => {
              return {
                description: `${c.name.length < 2 ? global.Modeliks.CompanyInfo.Currency.value : ""} ${c.name}`,
                value: c.key,
              };
            })}
          />
        </div>
      );
    }
    return null;
  };

  handleChartAddToCustomDashboard = (ID) => {
    this.setState({ openChartDialog: ID });
  };

  handleDeleteChart = (ID) => {
    global.Modeliks.showDialog(deleteElText, DialogTypes.WarningActions, null, () => {
      delete this.state.selectedSubsection.charts[ID];
      this.init();
      this.setState({ key: new Date().getTime() });
      if (this.props.hasOwnProperty("forceSaveObj")) {
        this.props.forceSaveObj.Settings = this.state.selectedSubsection;
      }
    });
  };

  handleDeleteKpi = (index) => {
    global.Modeliks.showDialog(deleteElText, DialogTypes.WarningActions, null, () => {
      this.state.selectedSubsection.custom_kpis.splice(index, 1);
      if (this.props.hasOwnProperty("forceSaveObj")) {
        this.props.forceSaveObj.Settings = this.state.selectedSubsection;
      }
      this.init();
      this.setState({ key: new Date().getTime() });
    });

    // this.props.saveDashboard({...this.state.selectedSubsection}, ()=>{
    //     this.forceUpdate();
    // }, true)

    // this.setState({key: new Date().getTime()});
  };

  handleDeleteFinancialStatement = (index) => {
    global.Modeliks.showDialog(deleteElText, DialogTypes.WarningActions, null, () => {
      this.state.selectedSubsection.financial_statements.splice(index, 1);
      if (this.props.hasOwnProperty("forceSaveObj")) {
        this.props.forceSaveObj.Settings = this.state.selectedSubsection;
      }
      this.init();
      this.setState({ key: new Date().getTime() });
    });

    // this.props.saveDashboard({...this.state.selectedSubsection}, ()=>{
    //     this.forceUpdate();
    // }, true)

    // this.setState({key: new Date().getTime()});
    // this.forceUpdate();
  };

  getTitleButton = (ID) => {
    if (global.Modeliks.PERMISSIONS.Dashboards.restrictions.edit.active) {
      if (this.props.titleButton) {
        if (this.props.isCustomDashboard) {
          return (
            <IconButton onClick={() => this.handleDeleteChart(ID)}>
              <TrashCan2 height={16} width={16} stroke={"#979797"} />
            </IconButton>
          );
        }
        return (
          <IconButton onClick={() => this.handleChartAddToCustomDashboard(ID)}>
            <StarChartIcon />
          </IconButton>
        );
      }
    }
    return null;
  };

  isFirstDriverName = () => {
    if (this.props.dataKey === "SingleIndicators") {
      return true;
    }
    return false;
  };

  getAddChartDialog = () => {
    if (this.state.openChartDialog) {
      return (
        <AddChartDialog
          chartID={this.state.openChartDialog}
          handleClose={() => this.setState({ openChartDialog: false })}
        />
      );
    }
    return null;
  };

  getChartsPeriodChange = () => {
    if (this.props.showChangePeriodButtons) {
      return (
        <div style={{ display: "flex", flexDirection: "column", rowGap: 7 }}>
          <Label color={"black"} size="label3" label={"Report Frequency"} />
          <Tabs
            textColor="secondary"
            className="psdf_navigation_menu_buttons apply_color spsfrs_report_summary"
            onChange={this.props.changeDataKey}
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            {[
              { link: `/forecast/reports/${this.props.prefix_route}_monthly`, label: "Monthly" },
              { link: `/forecast/reports/${this.props.prefix_route}_annually`, label: "Annual" },
            ].map((row) => {
              return (
                <ButtonMui
                  className={`${window.location.href.includes(row.link) ? "spsfrs_selected_charts" : ""}`}
                  style={{ height: "34px" }}
                  roundnessType={"squared"}
                  width="74px"
                  linkTo={row.link}
                  variant={"text"}
                  label={row.label}
                  color={"secondary"}
                />
              );
            })}
          </Tabs>
        </div>
      );
    }
  };

  headerItems = () => {
    const {
      minDate,
      maxDate,
      minYear,
      maxYear,
      showMonthly,
      startMonth,
      startYear,
      endMonth,
      endYear,
    } = this.state;

    let datePickerMinDate = showMonthly ? new Date(minDate) : new Date(minYear);
    let datePickerMaxDate = showMonthly ? new Date(maxDate) : new Date(maxYear);

    let dateFrom = showMonthly ? startMonth.toString().padStart(2, "0") : startYear;
    let dateTo = showMonthly ? endMonth.toString().padStart(2, "0") : endYear;

    return (
      <>
        {this.getSectionItem()}

        <div className={`psfac_actuals_single_filter vertical gap`}>
          <Label style={{ marginBottom: 0 }} color={"black"} size="label3" label={"From"} />
          <DatePickerYM
            minDate={datePickerMinDate}
            maxDate={datePickerMaxDate}
            views={showMonthly ? ["year", "month"] : ["year"]}
            value={dateFrom}
            endDateValue={dateTo}
            doMultipleDateValidation={true}
            monthValidation={true}
            onChange={showMonthly ? this.changeFromDate : this.changeFromDateYear}
          />
        </div>

        <div className={`psfac_actuals_single_filter vertical gap`}>
          <Label style={{ marginBottom: 0 }} color={"black"} size="label3" label={"To"} />
          <DatePickerYM
            minDate={datePickerMinDate}
            maxDate={datePickerMaxDate}
            views={showMonthly ? ["year", "month"] : ["year"]}
            value={dateTo}
            startDateValue={dateFrom}
            doMultipleDateValidation={true}
            monthValidation={true}
            onChange={showMonthly ? this.changeToDate : this.changeToDateYear}
          />
        </div>

        {this.getReportFrequencyChanger()}
        {this.getComparisonPeriodChanger()}
        {this.getUnitChanger()}
        {this.getChartsPeriodChange()}

        <div />
      </>
    );
  };

  getHeaderContainer = (headerItems) => {
    if (
      this.props.dataKey === "ForecastSummary" ||
      this.props.dataKey === "ForecastSummaryMonthly" ||
      this.props.dataKey === "SaasDashboard" ||
      this.props.dataKey === "SaasDashboardMonthly"
    ) {
      return <ReportsPortal>{headerItems}</ReportsPortal>;
    }
    return <div className={"d_row"}>{headerItems}</div>;
  };

  getFSProps = (financial_statement) => {
    let fs_props = { ...this.props.dashProps };

    if (financial_statement.defaultView) {
      fs_props.defaultView = financial_statement.defaultView;
    }

    return fs_props;
  };

  render() {
    if (this.state.loading || global.Modeliks.isLoading) {
      return (
        <div className={"main_circle_loader_container"}>
          <CircleLoader />
        </div>
      );
    }
    return (
      <div className={`dashboard_container ${this.props.class ? this.props.class : ""}`}>
        {this.getHeaderContainer(this.headerItems())}
        <div className={"charts_grid"}>
          <DraggableGrid
            key={"d_g_" + this.state.key}
            enableDrag={!!this.props.enableDrag}
            enableResize={false}
            onLayoutChange={this.handleLayoutChange}
            items={this.draggableItems}
            widths={this.currentCharts.map((chart) => chart.Data.chartConfig.width)}
            positions={[
              ...this.currentCharts.map((chart) => this.state.selectedSubsection.charts[chart.ID]),
              ...this.currentFS,
              ...this.currentCustomKPIs,
            ]}
          />
        </div>
        {this.getAddChartDialog()}
      </div>
    );
  }
}

const ReportsPortal = ({ children }) => {
  const el = document.getElementById("reports_right_portal");
  if (children && el) {
    return ReactDOM.createPortal(children, el);
  }
};
