import React, { Component } from "react";
import request from "superagent";

class LogoComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      src: null,
    };

    this.style = {};

    if (this.props.notAbsolute) {
      this.style = {
        zIndex: 999999,
        width: "0px",
        height: "0px",
      };
    } else {
      this.style = {
        position: "absolute",
        top: 20,
        right: 20,
        zIndex: 999999,
        width: "0px",
        height: "0px",
      };
    }

    this.logoConatiner = React.createRef();
  }

  getLogo = () => {
    request
      .get(
        `/api/logo?account_id=${global.Modeliks.Account.ID}&company_id=${global.Modeliks.CompanyInfo.ID}&v=${new Date().getTime()}`,
      )
      .then((res) => {
        if (res.text) {
          if (this.state.src != res.text) {
            this.setState({ src: res.text });
          }
        }
      })
      .catch((err) => {
        // err.message, err.response
      });
  };

  onLoad = (e) => {
    const height = e.target.naturalHeight;
    const width = e.target.naturalWidth;
    let ratio = width / height;

    if (this.props.businessPlan) {
      if (height > width) {
        this.logoConatiner.current.style.height = "17px";
        this.logoConatiner.current.style.width = 17 * ratio + "px";
      } else {
        this.logoConatiner.current.style.height = (17 * 1) / ratio + "px";
        this.logoConatiner.current.style.width = "17px";
      }
    } else {
      if (height > width) {
        this.logoConatiner.current.style.height = "90px";
        this.logoConatiner.current.style.width = 90 * ratio + "px";
      } else {
        this.logoConatiner.current.style.height = (90 * 1) / ratio + "px";
        this.logoConatiner.current.style.width = "90px";
      }
    }
  };

  render() {
    return (
      <div ref={this.logoConatiner} style={this.style}>
        <img
          onLoad={this.onLoad}
          src={`/api/logo?account_id=${global.Modeliks.Account.ID}&company_id=${global.Modeliks.CompanyInfo.ID}&v=${global.Modeliks.currentLogoVersion ? global.Modeliks.currentLogoVersion : new Date().getTime()}`}
          alt=""
          style={{ width: "100%", height: "100%" }}
        />
      </div>
    );
  }
}

export default LogoComponent;
