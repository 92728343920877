import MxDataArray from "../../MxDataArray";
import { RevenueTypes } from "../../../components/constants/finance";
import UnitSales from "./UnitSales";
import BillableHours from "./BillableHours";
import Revenue from "./index";
import Subscription from "./Subscription";
import RevenueDataGridRows from "./RevenueDataGridRows";

class RevenuesStorage extends MxDataArray {
  dataManager = null;
  driverStorage = null;
  loaded = false;

  getType = (item) => {
    switch (item.RevenueType) {
      case RevenueTypes.RevenueOnly:
        return new Revenue(item);
      case RevenueTypes.BillableHours:
        return new BillableHours(item);
      case RevenueTypes.UnitSales:
        return new UnitSales(item);
      case RevenueTypes.Subscriptions:
        return new Subscription(item);
    }
  };

  setExistingDrivers = (item) => {
    item.getAllDrivers().forEach((d) => (d.IsExisting = d.Ref_Field !== "quantity_split"));
  };

  setData = (callBack, data = this.data) => {
    const revenueIndexes = [];
    data.map((item) => {
      if (item.RevenueType == RevenueTypes.Subscriptions && item.ID_ParentRevenue === null) {
        item.children = [];
        revenueIndexes[item.ID] = item;
      }

      if (item.ID_ParentRevenue !== null && item.RevenueType === RevenueTypes.Subscriptions) {
        revenueIndexes[item.ID_ParentRevenue].children.push(new Subscription(item));
      }
    });

    const newData = data.filter((data) => data.ID_ParentRevenue === null);
    this.pushArray(
      newData.map((item) => {
        const newItem = this.getType(item);
        if (newItem.Totals) {
          this.setExistingDrivers(newItem);
          return newItem;
        } else {
          // this.dataManager.del(this.dataManager.Tables.Finance_Revenues.TableName, {ID: newItem.ID}, () => {
          //     console.log('deleting',newItem);
          //
          // }, () => {
          // });
        }
      }),
    );

    Revenue.getRevenueTotals();
    Revenue.getRevenueGrowth(Revenue.getRevenueTotals());
    Revenue.getBillableHoursTotals();
    Revenue.getUnitSalesTotals();
    Subscription.getSubscriptionsCashCollections();
    Subscription.getSubscriptionsTotals();
    Subscription.getSubscriptionsTotalsReport();
    Subscription.getTotalCustomersDueForPayment();
    Subscription.getCustomersAtEndOfPeriodTotals();
    Subscription.getCustomersAtStartTotals();
    Subscription.getChurnedCustomersTotals();
    Subscription.getNumberOfRefundsTotals();
    Subscription.getSingupsTotals();
    Subscription.getSinglePriceTotals();
    Subscription.getChurnRateTotals();
    // Subscription.getCumulativeTotalSubscriptionRevenue();
    // Subscription.getCumulativeSubscriptionCashColections();
    this.loaded = true;

    console.log("RevenueStorage", this);
    callBack();
  };
  setGridRows = () => RevenueDataGridRows(this);

  loadRevenues = (callBack, shouldFinish = true) => {
    this.clear();
    this.dataManager.get(
      this.dataManager.Tables.Finance_Revenues.TableName,
      { ID_CompanyScenario: this.dataManager.CompanyScenarioInfo.ID },
      (data) => {
        if (shouldFinish) {
          this.setData(callBack, data);
        } else {
          this.data = data;
          callBack();
        }
      },
      null,
      false,
    );
  };

  static create = () => {
    const newArr = new RevenuesStorage();

    newArr.pushOld = newArr.push;
    newArr.push = newArr.pushThis;

    return newArr;
  };
}

export default RevenuesStorage;
