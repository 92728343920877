import * as React from "react";
import Dialog from "@mui/material/Dialog";
import ButtonJ from "../../SlideEditor/components/components/Buttons/Button";
import "./ConfirmDeleteDialog.scss";
import ButtonMui from "../buttons/buttonMui/buttonMui";
import { TrashCan } from "../icons/svgIcons";

export default function ConfirmDeleteDialog(props) {
  const handleDelete = () => {
    props.handleDeleteConfirm();
  };

  const handleClose = () => {
    props.handleClose();
  };

  return (
    <React.Fragment>
      {
        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          open={props.open ? props.open : false}
          className={"delete_dialog"}
          onClose={handleClose}
        >
          <>
            <div className="Label_2">
              {props.text ?? "Are you sure you want to delete the section?"}
            </div>
            {/*<div className='Paragraph_P4'>Your section is available under <br/> custom sections in Add new*/}
            {/*    section view*/}
            {/*</div>*/}
            <div className={"delete_dialog_buttons"}>
              <ButtonMui
                width={118}
                label={"Delete"}
                color={"red"}
                variant={"contained"}
                onClick={() => handleDelete()}
                style={{ height: "30px" }}
                startIcon={<TrashCan />}
              />
              <ButtonMui
                width={118}
                label={"Cancel"}
                onClick={() => handleClose()}
                style={{ height: "30px" }}
              />
            </div>
          </>
        </Dialog>
      }
    </React.Fragment>
  );
}
