import React from "react";
import { FirstSection } from "../FirstSection";
import { ButtonsWrapper } from "../ButtonsWrapper";
import styled from "styled-components";
import { Theme } from "../../../../../theme/styles/theme";
import RadioButton from "../../../../../components/actions/RadioButton";
import ButtonMui from "../../../../../components/buttons/buttonMui/buttonMui";
import { ArrowLeft, ArrowRight } from "../../../../../components/icons/svgIcons";
import { AccessControl } from "../../../../../data/Permissions/AccessControl";

const SelectWrapperStep2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 10px;
  padding-left: 15px;
`;

export default function NewStep3(props) {
  return (
    <>
      <div className="spsocwn2_container">
        <FirstSection className="spsocwn2_checkbox_div">
          <div className="p title strong center p_b">
            Do you want to start with a predefined financial plan tailored to your industry?
          </div>
          <AccessControl
            allowedPermissions={[
              {
                [global.Modeliks.PERMISSIONS.Financials.key]:
                  global.Modeliks.PERMISSIONS.Financials.restrictions.FinancialModelTemplates.key,
              },
            ]}
            renderNoAccess={
              <SelectWrapperStep2 className="spsocwn2_checkbox_div">
                <RadioButton
                  size="small"
                  disabled
                  value={"financialPlanData"}
                  placeholder="Yes (not available in current plan)"
                  checked={props.financialPlanData}
                  name={"financialPlanData"}
                  onChange={(e) => e.preventDefault()}
                />
                <RadioButton
                  size="small"
                  disabled
                  value={"financialPlanData"}
                  placeholder="No, I want to develop a financial plan from scratch"
                  checked={!props.financialPlanData}
                  name={"financialPlanData"}
                  defaultChecked={false}
                  onChange={(e) => e.preventDefault()}
                />
              </SelectWrapperStep2>
            }
          >
            <SelectWrapperStep2 className="spsocwn2_checkbox_div">
              <RadioButton
                size="small"
                value={"financialPlanData"}
                placeholder="Yes"
                checked={props.financialPlanData}
                name={"financialPlanData"}
                onChange={props.setFinancialPlanData}
                defaultChecked
              />
              <RadioButton
                size="small"
                value={"financialPlanData"}
                placeholder="No, I want to develop a financial plan from scratch"
                checked={!props.financialPlanData}
                name={"financialPlanData"}
                defaultChecked={false}
                onChange={props.setFinancialPlanData}
              />
            </SelectWrapperStep2>
          </AccessControl>
        </FirstSection>
      </div>
      <ButtonsWrapper>
        <div className="o_btn_container">
          <ButtonMui
            startIcon={<ArrowLeft />}
            label={"Back"}
            width={120}
            variant={"contained"}
            onClick={props.BackStep}
          />
        </div>
        <div className="o_btn_container start">
          <ButtonMui
            label={"Next"}
            width={120}
            variant={"contained"}
            endIcon={<ArrowRight />}
            onClick={props.NextStep}
          />
        </div>
      </ButtonsWrapper>
    </>
  );
}
