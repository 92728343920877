import React from "react";
import "./IntroScreen.scss";
import { FinancialBlank } from "../../../../components/containers/FinancialBlank";
import CreateManagementReportDialog from "./components/CreateManagementReportDialog";
import { DialogTypes } from "../../../../data/Finance/constants";
import ButtonMui from "../../../../components/buttons/buttonMui/buttonMui";

class IntroScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openCreateDialog: false,
    };
  }

  openCreateDialog = () => {
    if (global.Modeliks.CustomDashboards.length) {
      this.setState({ openCreateDialog: true });
    } else {
      this.props.openDialog();
    }
  };

  openReadMoreDialog = () => {
    global.Modeliks.showDialog(
      "To create a new Management report, you have to first create a Custom Dashboard that will be the base for your management report. Come back to the Management Reports section, create report by choosing a custom dashboard as your report basis. After the report is created start adding comments to provide further explanations of your financial performance, if required. Once done, you can share your report as a PDF or simply share a link with your audience for easy online access on any device.\n",
      DialogTypes.WarningSimpleText,
    );
  };

  render() {
    return (
      <div className={"intro_screen_container"}>
        <div className={"i_s_c_content"}>
          <p className={"i_s_c_c_desription"}>
            <b>Welcome to the Management Reports section.</b>
            <br />
            This section will hold all your weekly, monthly, annual or ad-hoc financial reports that
            you will create over time and share with your management, investors, board of directors,
            employees and other stakeholders.{" "}
            <ButtonMui
              label={"Read more..."}
              onClick={this.openReadMoreDialog}
              variant={"text"}
              style={{ padding: "6px", height: "20px" }}
            />
          </p>
          <p className={"i_s_c_c_desription"}>
            <b>Steps to create a new Management report:</b>
            <br />
            1. Create a Custom Dashboard - that will be the basis for your management report.
            <br />
            2. Come back to the Management Reports and press “create report”
            <br />
            3. Start adding comments to provide further explanations of your financial performance,
            if required
            <br />
            4. Share your report as a PDF file or a link.
            <br />
          </p>

          <div className={"financial_blank"}>
            <FinancialBlank
              onAdd={this.openCreateDialog}
              headerText="Create your first Management Report"
              buttonLabel="Create Report"
              disabled={this.EditPermission}
            />
          </div>
        </div>
        {this.state.openCreateDialog && (
          <CreateManagementReportDialog
            Folders={this.props.Folders}
            open={this.state.openCreateDialog}
            onAdd={(data) =>
              this.props.onAdd(data, () => this.setState({ openCreateDialog: false }))
            }
            onClose={() => {
              this.setState({ openCreateDialog: false });
            }}
            customDashboards={this.props.customDashboards}
            keyField={this.props.keyField}
          />
        )}
      </div>
    );
  }
}

export default IntroScreen;
