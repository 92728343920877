import React from "react";
import styled from "styled-components";
import { Theme } from "../../../../../theme/styles/theme";
import Input from "../../../../../components/actions/Input";
import AddDialog from "../../../../../components/dialogs/financials/AddDialog/AddDialog";
import { AddDialogTitle } from "../../../../../components/dialogs/financials/AddDialog/AddDialogTitle";
import { AddDialogContent } from "../../../../../components/dialogs/financials/AddDialog/AddDialogContent";
import SubscriptionPlans from "../../../../../components/dialogs/components/SubscriptionPlans";
import CircleCheckboxM from "../../../../../components/actions/CircleCheckboxM";
import "./RevenueAddDialog.scss";
import { RevenueTypes } from "../../../../../components/constants/finance";
import ButtonsActions from "../../../../../components/dialogs/financials/AddDialog/ButtonsActions";
import Subscription from "../../../../../data/Finance/Revenue/Subscription";
import BillableHours from "../../../../../data/Finance/Revenue/BillableHours";
import UnitSales from "../../../../../data/Finance/Revenue/UnitSales";
import Revenue from "../../../../../data/Finance/Revenue";

const SyledP = styled.p`
  ${Theme.text.paragraph.p2_strong};
  color: #252525;
  margin-bottom: ${(props) => props.margin && 0};
`;

const StyledTextArea = styled.div`
  background: #f5f5f5;
  border: 1px solid #f0f1f7;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 16px 35px 16px 32px;
  ${Theme.text.paragraph.p4};
  color: #252525;
`;

const CheckBoxDiv = styled.div`
  padding: 7px 0 7px 5px;
`;

const SubscriptionPeriodMonths = [
  { value: "1", description: "1 month" },
  { value: "2", description: "2 months" },
  { value: "3", description: "3 months" },
  { value: "4", description: "4 months" },
  { value: "5", description: "5 months" },
  { value: "6", description: "6 months" },
  { value: "7", description: "7 months" },
  { value: "8", description: "8 months" },
  { value: "9", description: "9 months" },
  { value: "10", description: "10 months" },
  { value: "11", description: "11 months" },
  { value: "12", description: "12 months" },
  { value: "13", description: "13 months" },
  { value: "14", description: "14 months" },
  { value: "15", description: "15 months" },
  { value: "16", description: "16 months" },
  { value: "17", description: "17 months" },
  { value: "18", description: "18 months" },
  { value: "19", description: "19 months" },
  { value: "20", description: "20 months" },
  { value: "21", description: "21 months" },
  { value: "22", description: "22 months" },
  { value: "23", description: "23 months" },
  { value: "24", description: "24 months" },
  { value: "12", description: "1 year" },
  { value: "24", description: "2 years" },
  { value: "36", description: "3 years" },
  { value: "48", description: "4 years" },
  { value: "60", description: "Lifetime" },
];

const ErrorMessage = styled.span`
  ${Theme.text.paragraph["p5"]};
  color: ${Theme.colors.accent.red[150]};
  margin-top: 0;
  margin-left: 8px;
`;

class RevenueAddDialog extends React.Component {
  constructor(props) {
    super(props);
    if (!props.revenue.hasOwnProperty("subscriptionplans")) {
      props.revenue.subscriptionplans = [
        {
          SubscriptionName: "",
          SubscriptionPeriodMonths: 1,
          ID: null,
          Delete: false,
        },
      ];
    }

    this.state = {
      open: false,
      RevenueName: "",
      RevenueNameError: null,
      subscriptionPlansErr: false,
      changedSubscriptionplans: false,
      validate: false,
    };
  }

  validate = () => {
    let isRevenueName = this.props.revenue.RevenueName.length < 3;

    let RevenueNameError = this.state.RevenueNameError;
    let subscriptionPlansErr = false;
    if (this.props.revenue.RevenueType === RevenueTypes.Subscriptions) {
      let plans = this.props.revenue.subscriptionplans.filter((row) => row.SubscriptionName === "");
      if (plans.length > 0) {
        subscriptionPlansErr = true;
      }
    }

    if (isRevenueName) {
      RevenueNameError = "Required Field min 3 characters";
      let element = document.getElementById("revenueNameInput");
      element.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }

    if (subscriptionPlansErr || isRevenueName) {
      this.setState({ RevenueNameError, subscriptionPlansErr });
      return false;
    }

    return true;
  };

  onChangeCheckbox = (e) => {
    if (this.props.revenue.isNew && !this.props.changes.new.changedType) {
      this.props.changes.new.changedType = true;
    }

    if (!this.props.revenue.isNew && !this.props.changes.existing.changedType) {
      this.props.changes.existing.changedType = true;
      this.props.changes.latestType = this.props.revenue.RevenueType;
    }

    if (e.target.value === RevenueTypes.Subscriptions) {
      this.props.revenue.subscriptionplans = [];
      this.props.revenue.subscriptionplans = [
        {
          SubscriptionName: "",
          SubscriptionPeriodMonths: 1,
          ID: null,
          Delete: false,
        },
      ];
    }
    this.props.revenue.RevenueType = e.target.value;
    this.forceUpdate();
  };

  handleChange = (e) => {
    this.state.RevenueNameError = null;
    this.props.revenue.RevenueName = e.target.value;
    this.forceUpdate();
  };

  handleOnNext = (error = null, revenue) => {
    const isValid = this.validate();

    if (isValid) {
      if (this.props.revenue.isNew) {
        if (
          this.props.revenue.RevenueType === RevenueTypes.Subscriptions &&
          this.props.revenue.hasOwnProperty("children") &&
          this.props.revenue.children.length !== this.props.revenue.subscriptionplans
        ) {
          this.props.setNewSubscription();
        } else {
          this.props.onChangeRevenue();
        }
      } else {
        if (this.props.changes.existing.changedType) {
          this.props.onChangeRevenue();
        } else {
          if (
            this.props.revenue.RevenueType === RevenueTypes.Subscriptions &&
            this.props.revenue.hasOwnProperty("children") &&
            this.props.revenue.children.length > 0
          ) {
            if (
              this.props.revenue.children.length !== this.props.revenue.subscriptionplans.length
            ) {
              this.props.setSubscription();
            } else if (
              this.props.revenue.children.some(
                (d) =>
                  d.SubscriptionPeriod.cur_record.Value !== d.SubscriptionPeriod.db_record.Value,
              )
            ) {
              const changedChildren = this.props.revenue.children.filter(
                (d) =>
                  d.SubscriptionPeriod.cur_record.Value !== d.SubscriptionPeriod.db_record.Value,
              );
              changedChildren.forEach((c) => {
                c.buildPeriodsData();
                c.createMonthsFormulas();
              });
              this.props.onNextClick();
              // this.props.setSubscription();
            } else {
              this.props.onNextClick();
            }
          } else {
            this.props.onNextClick();
          }
        }
      }
    }
  };
  removeSubscription = (subscription, db_record = false, rows) => {
    if (db_record) {
      let subscriptionplan = this.props.revenue.subscriptionplans.find(
        (s) => s.ID === subscription.ID,
      );
      let childrenSubscription = this.props.revenue.children.find((s) => s.ID === subscription.ID);
      if (subscriptionplan) {
        subscriptionplan.Delete = true;
      }
      this.props.deleteSubscriptions.push(childrenSubscription);
      this.props.revenue.removeSubscriptionPlan(childrenSubscription);
      this.forceUpdate();
    } else {
      this.state.subscriptionPlansErr = false;
      this.forceUpdate();
    }
  };

  converter = (callBack) => {
    let revenue = this.props.revenue;
    switch (revenue.RevenueType) {
      case RevenueTypes.RevenueOnly:
        revenue = Revenue.createNew(revenue);
        break;
      case RevenueTypes.UnitSales:
        revenue = UnitSales.convert_Revenue(revenue);
        break;
      case RevenueTypes.BillableHours:
        revenue = BillableHours.convert_Revenue(revenue);
        break;
      case RevenueTypes.Subscriptions:
        Subscription.convert_Revenue(revenue, (newSubs) => {
          this.handleOnNext(false, newSubs);
        });
        break;
    }
    callBack(revenue);
  };

  getCheckBox = (label, type = RevenueTypes.UnitSales, desc) => {
    if (
      !this.props.revenue.isNew &&
      this.props.revenue &&
      this.props.revenue.RevenueType !== type
    ) {
      return;
    }

    return (
      <>
        <CheckBoxDiv>
          <CircleCheckboxM
            disabled={!this.props.revenue.isNew}
            size="medium"
            value={type}
            checked={this.props.revenue && this.props.revenue.RevenueType === type}
            onChange={this.onChangeCheckbox}
            label={label}
          />
        </CheckBoxDiv>
        {desc}
      </>
    );
  };

  getCheckBoxDesc = (text) => {
    return <StyledTextArea>{text}</StyledTextArea>;
  };

  render() {
    return (
      <>
        <AddDialog open={this.props.open} onClose={this.props.onClose}>
          <AddDialogTitle title="General Revenue Info" onClose={this.props.onClose} />

          <AddDialogContent closeOnEscapeClick="true" onClose={this.props.onClose}>
            <SyledP>Name of revenue</SyledP>
            <Input
              width="427px"
              id="revenueNameInput"
              validateSpecialChar={true}
              max={45}
              value={this.props.revenue ? this.props.revenue.RevenueName : ""}
              error={this.state.RevenueNameError && this.state.RevenueNameError.length > 0}
              errorMessage={this.state.RevenueNameError}
              name="revenueName"
              onChange={this.handleChange}
              placeholder="Enter name for your revenue stream"
            />
            <SyledP margin={true}>Type of revenue</SyledP>
            <ErrorMessage className="error_invisible" />
            {this.getCheckBox(
              "Product Sales",
              RevenueTypes.UnitSales,
              this.getCheckBoxDesc(
                "Best when selling products. Calculate your revenue by entering the number of products you will sell (Unit Sales) multiplied by the sales price (Unit Price).",
              ),
            )}
            {this.getCheckBox(
              "Service Revenue",
              RevenueTypes.BillableHours,
              this.getCheckBoxDesc(
                "Best for service business priced on time sales. Calculate your revenue by entering the number of hours you will sell (Billable Hours) multiplied by its price (Hourly Rate).",
              ),
            )}
            {this.getCheckBox(
              "Subscription",
              RevenueTypes.Subscriptions,
              <StyledTextArea>
                {this.props.revenue.RevenueType === RevenueTypes.Subscriptions && (
                  <SubscriptionPlans
                    error={this.state.subscriptionPlansErr}
                    removeSubscription={this.removeSubscription}
                    revenue={this.props.revenue}
                    SubscriptionPlans={this.props.revenue.subscriptionplans}
                    SubscriptionPeriodMonths={SubscriptionPeriodMonths}
                    setSubscriptions={this.setSubscriptions}
                  />
                )}
                Best for businesses that charge customers on periodic bases (monthly, annually,
                etc), such as, subscriptions, rentals, memberships.
              </StyledTextArea>,
            )}

            {this.getCheckBox(
              "Revenue Only",
              RevenueTypes.RevenueOnly,
              this.getCheckBoxDesc(
                "In case none of the previous revenue models are applicable, you can enter revenue without any details.",
              ),
            )}
          </AddDialogContent>

          <ButtonsActions
            onNextClick={this.handleOnNext}
            close={this.props.onClose}
            stream={this.props.revenue}
            deleteSubscriptions={this.props.deleteSubscriptions}
            RevenueName={this.props.revenue.RevenueName}
            revenue={true}
            isDeletedRecord={this.props.isDeletedRecord}
            converter={this.converter}
            enableDelete={true}
            deleteName={this.props.revenue.RevenueName}
          />
        </AddDialog>
      </>
    );
  }
}

export default RevenueAddDialog;
