import React, { useEffect } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import { IconX } from "../../../../../components/icons/svgIcons";
import { Paragraph } from "../../../../../components/typography/paragraph/Paragraph";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Active } from "../../../../../components/dividers/ActiveTab";
import "./FinanceDetailsTabTitles.scss";
import IconButton from "@mui/material/IconButton";
import { DialogTypes } from "../../../../../data/Finance/constants";

const FinanceDetailsTabTitles = (props) => {
  if (!props.tabs) {
    return null;
  }
  const CloseDialog = () => {
    if (props.tabs && props.tabs.length > 0) {
      props.tabs.forEach((t) => {
        if (t.dataGridRow.drivers && t.dataGridRow.drivers.length > 0) {
          t.dataGridRow.drivers.forEach((d) => d.cleanDriver());
        }
      });
    }
    props.onClose(false, true);
  };

  useEffect(() => {
    document.addEventListener("keyup", escapeButtonClick);

    return () => {
      document.removeEventListener("keyup", escapeButtonClick);
    };
  });

  const escapeButtonClick = (event) => {
    if (event.key === "Escape") {
      global.Modeliks.showDialog(
        "Are you sure you want to close without saving?",
        DialogTypes.WarningActions,
        null,
        () => props.onClose(),
      );
    }
  };

  return (
    <>
      <DialogTitle
        key={props.id + props.selectedTab.ID}
        className="psfcf_finance_details_tab_titles_div"
      >
        <div className="psfcf_finance_details_tab_titles_content">
          <Paragraph size="p2_strong" label={props.title} />
        </div>
        <Tabs
          variant="scrollable"
          className="psfcf_finance_details_tab_titles_tabs"
          TabIndicatorProps={{
            children: (
              <div className="psfcf_finance_details_tab_titles_tabs_indicator">
                <Active />
              </div>
            ),
          }}
          value={props.selectedTab.ID}
        >
          <Tab
            onClick={() => props.onChange(null, 0, "general")}
            value="general"
            className="psfcf_finance_details_tab_titles_single_tab_general"
            label={
              <Paragraph
                className="psfcf_finance_details_tab_paragraph"
                color={"black"}
                size="p2"
                label={"General"}
              />
            }
          />
          {props.tabs.map((tab, index) => {
            return (
              <Tab
                key={"tab_" + tab.dataGridRow.data.ID}
                onClick={() => props.onChange(tab, index, tab.field)}
                value={tab.dataGridRow.data.ID}
                className="psfcf_finance_details_tab_titles_single_tab_general"
                label={
                  <div className="psfcf_finance_details_single_tab_title">
                    <Paragraph
                      className={`psfcf_finance_details_tab_titles_single_tab_general ${
                        props.selectedTab.ID === tab.dataGridRow.data.ID && "active"
                      }`}
                      color={"black"}
                      size={"p2"}
                      label={tab.title.length > 39 ? `${tab.title.substring(0, 40)}...` : tab.title}
                    />
                    {/*{(tab.dataGridRow.data.IsNew && tab.dataGridRow.data.isValid === false) &&*/}
                    {/*<div style={{display: 'block', background: 'red'}}><Tooltip className='Tooltip' title='Data inputs yet to be completed' placement='right-start'><i><IconNotification/></i></Tooltip></div>}*/}
                  </div>
                }
              />
            );
          })}
        </Tabs>

        <div className="psfcf_finance_details_tab_titles_single_save_close">
          {!props.CalculatedDriver && <span />}
          <IconButton
            onClick={() =>
              global.Modeliks.showDialog(
                "Are you sure you want to close without saving?",
                DialogTypes.WarningActions,
                null,
                () => CloseDialog(),
              )
            }
          >
            <IconX />
          </IconButton>
          {/*<IconX onClick={CloseDialog} />*/}
        </div>
      </DialogTitle>
    </>
  );
};

export default FinanceDetailsTabTitles;
