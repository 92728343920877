import React, { Component } from "react";
import {
  DefaultChartOptions,
  ChartWidth,
  ReportFrequency,
  ChartDataTypes,
  ComparisonPeriod,
  StreamTypes,
  ChartTypes,
  NumberOfMonths,
} from "../../components/constants/charts";
import "./chartscomponent.scss";
import MaterialSelect from "../../components/actions/SelectM";
import DatePickerYM from "../../components/actions/DatePickers/DatePickerYM";
import BarChart from "../Admin/Charts/BarChart";
import LineChart from "../Admin/Charts/LineChart";
import StackedBarChart from "../Admin/Charts/StackedBarChart";
import HorizontalBarChart from "../Admin/Charts/HorizontalBarChart";
import WaterfallChart, { WaterfallChartTypes } from "../Admin/Charts/WaterfallChart";
import FormatOptionComponent from "../Admin/Charts/FormatOptionComponent";
import CheckBoxWrapperComponent from "../Admin/Charts/CheckBoxWrapperComponent";
import DriverInsertionTool from "../Admin/components/DriverInsertTemporary/DriverInsertionTool";
import DraggableComponent from "../Admin/components/DraggableDrivers/DraggableContainer";
import Button from "../../components/actions/Button";
import Input from "../../components/actions/Input";
import CircleCheckboxM from "../../components/actions/CircleCheckboxM";
import { UnitTypes } from "../../data/Finance/CalculatedDriver/constants";
import PropTypes from "prop-types";
import AreaChart from "../Admin/Charts/AreaChart";
import { IconPlus } from "../../components/icons/svgIcons";
import ButtonMui from "../../components/buttons/buttonMui/buttonMui";

class DummyDriver {
  DriverName = "";
  constructor(props) {
    this.DriverName = props.DriverName;
  }
}

export default class ChartsComponent extends Component {
  chartOptionsObj = {};
  chartFunctionsObj = {};
  chartClassName = {};
  chartConfigObj = {};
  didMount = false;

  constructor(props) {
    super(props);
    this.rightMenuFunc = {};
    this.validDrivers = this.getValidDrivers();
    this.state = {
      driver: this.validDrivers[0],
      key: "chart_comp" + new Date().getTime(),
      width: ChartWidth.fullScreen.value,
    };
  }

  componentWillUnmount() {
    if (this.props.sharedChartFunctionsObj && this.props.selectedDashboardChart.ID) {
      delete this.props.sharedChartFunctionsObj[this.props.selectedDashboardChart.ID];
    }
  }

  getValidDrivers = () => {
    return StreamTypes;
  };

  componentDidMount() {
    this.didMount = true;
  }

  getChartObject = (
    grid = false,
    type = ChartTypes.BarChart.name,
    ID,
    Name,
    chartData = null,
    chartOptions = null,
    chartConfig = {},
    snapShotData = {},
  ) => {
    if (this.props.reportFrequency) {
      if (!chartData) {
        chartData = {};
      }
      Object.assign(chartData, { reportFrequency: this.props.reportFrequency });
    }
    if (this.props.numberFormat) {
      if (!chartOptions) {
        chartOptions = {};
      }
      if (!chartOptions.hasOwnProperty("numberFormat")) {
        chartOptions.numberFormat = this.props.numberFormat;
      }
    }
    if (this.props.comparisonPeriod) {
      if (!chartData) {
        chartData = {};
      }
      if (type !== ChartTypes.StackedBarChart.name || !this.props.showIndicatorBoxes) {
        //check me
        Object.assign(chartData, { comparisonPeriod: this.props.comparisonPeriod });
      }
    }
    const chartProps = {
      ID: ID,
      snapShotData: snapShotData,
      isSaasDashboard: this.props.isSaasDashboard,
      handleSnapShotData: this.props.handleSnapShotData,
      saveChartOptions: this.props.saveChartOptions,
      showIndicatorBoxes: this.props.showIndicatorBoxes,
      changeChartType: this.changeChartType,
      months: this.props.months,
      years: this.props.years,
      Name: Name,
      chartData: chartData,
      chartOptions: chartOptions,
      chartConfig: { ...chartConfig, type: type },
      dateRange: this.props.dateRange,
      chartConfigObj: this.chartConfigObj,
      chartFunctionsObj: this.chartFunctionsObj,
      chartOptionsObj: this.chartOptionsObj,
      chartClassName: this.chartClassName,
      handleChartMount: () => this.forceUpdate(),
      titleButton: this.props.titleButton,
      isFirstDriverName: this.props.isFirstDriverName,
      isScaled: this.props.isScaled,
      preview: this.props.preview,
      businessPlan: this.props.businessPlan,
    };

    switch (type) {
      case ChartTypes.BarChart.name:
        return <BarChart grid={grid} {...chartProps} />;
      case ChartTypes.StackedBarChart.name:
        return <StackedBarChart grid={grid} {...chartProps} />;
      case ChartTypes.HorizontalBarChart.name:
        return <HorizontalBarChart grid={grid} {...chartProps} />;
      case ChartTypes.LineChart.name:
        return <LineChart grid={grid} {...chartProps} />;
      case ChartTypes.WaterfallChart.name:
        return <WaterfallChart grid={grid} {...chartProps} />;
      case ChartTypes.AreaChart.name:
        return <AreaChart grid={grid} {...chartProps} />;
    }
  };

  changeChartType = (type, callBack) => {
    this.props.selectedDashboardChart.Data.chartClassType = type;
    this.forceUpdate(() => {
      callBack && callBack();
      // this.chartFunctionsObj.SaveChart();
    });

    // let newObj = this.createNewChartObject(type, this.chartFunctionsObj.ID, this.chartFunctionsObj.Name, chartData, this.chartFunctionsObj.chartOptions, this.chartConfigObj)
    // this.props.charts.splice(this.props.charts.findIndex(c => c == this.chartObj), 1, newObj)
    // this.props.setSelectedChart(newObj);
    // this.chartObj = newObj;
    // this.forceUpdate;
  };

  handleChangeDate = (e, name) => {
    if (e) {
      this.chartFunctionsObj.changeDate(e.getMonth(), e.getFullYear(), name);
      this.forceUpdate();
    }
  };

  getDate = (month, year) => {
    return `${year}-${(month + 1).toString().padStart(2, "0")}`;
  };

  handleChartOptionsChange = (props, callBack) => {
    if (this.props.handleChartOptionsChange) {
      this.props.handleChartOptionsChange(props);
    }
    this.chartFunctionsObj.handleChartOptionsChange(props, callBack);
  };

  handleChartDataOptionsChange = (props, callBack) => {
    if (this.props.handleChartDataOptionsChange) {
      this.props.handleChartDataOptionsChange(props);
    }
    this.chartFunctionsObj.handleChartDataOptionsChange(props, callBack);
  };

  handleStackedChartDifferencesChange = (key, callBack) => {
    this.chartFunctionsObj.handleChartDataOptionsChange(
      { [key]: !this.chartOptionsObj.chartOptions[key] },
      callBack,
    );
  };
  setDriverFromExisting = (e, driver) => {
    this.setState({ driver: driver });
  };

  insertNewDriver = (driver) => {
    console.log("driverName", driver);
  };

  onColorChange = (driverKey, key, color) => {
    let savingFunc = () => {};
    if (this.props.handleChartOptionsChange) {
      savingFunc = this.props.handleChartOptionsChange;
    }
    this.chartFunctionsObj.handleColorChange(driverKey, [key, color], savingFunc);
  };

  onSingleColorChange = (color) => {
    let savingFunc = () => {};
    if (this.props.handleChartOptionsChange) {
      savingFunc = this.props.handleChartOptionsChange;
    }
    this.chartFunctionsObj.handleSingleColorChange(color, savingFunc);
  };

  getDatePickerElement = (chartData) => {
    return (
      <div className="c_c_row">
        <DatePickerYM
          label="From"
          onChange={(e) => this.handleChangeDate(e, "dateFrom")}
          value={this.getDate(chartData.dateFrom.month, chartData.dateFrom.year)}
          className="psoc_custom_date_year_datepicker"
        />
        <DatePickerYM
          label="To"
          onChange={(e) => this.handleChangeDate(e, "dateTo")}
          value={this.getDate(chartData.dateTo.month, chartData.dateTo.year)}
          className="psoc_custom_date_year_datepicker"
        />
      </div>
    );
  };

  onWeightedAverageChange = (e) => {
    this.chartFunctionsObj.onWeightedAverageChange(e, () => {
      this.forceUpdate();
    });
  };

  getChartEditingTools() {
    if (Object.keys(this.chartOptionsObj).length && Object.keys(this.chartFunctionsObj).length) {
      const { chartData, chartOptions, chartConfig, chartClassName } = this.chartOptionsObj;
      return (
        <>
          {/*{this.getDatePickerElement(chartData)}*/}
          <div
            className={`c_c_row ${this.chartOptionsObj.chartOptions.isWeightedAverage ? "split" : "left_align"}`}
          >
            <div className="c_c_r_select_container">
              <div className="Label_3">Chart Title</div>
              <Input
                placeholder={"Chart Title"}
                value={this.chartOptionsObj.chartOptions.title}
                onChange={(e) =>
                  this.handleChartOptionsChange({ title: e.target.value }, () => this.forceUpdate())
                }
              />
            </div>
            {this.chartOptionsObj.chartOptions.isWeightedAverage && (
              <div className="c_c_r_select_container">
                <div className="Label_3">Unit Type</div>
                <MaterialSelect
                  zIndex={1}
                  width="180px"
                  onChange={(e) => {
                    this.handleChartOptionsChange({ unitType: e.target.value }, () => {
                      this.chartFunctionsObj.rebuildChartData(
                        [],
                        null,
                        () => this.forceUpdate(),
                        false,
                      );
                    });
                  }}
                  value={this.chartOptionsObj.chartOptions.unitType}
                  options={Object.keys(UnitTypes)
                    .filter((c) => UnitTypes[c] != "hide" && UnitTypes[c] != "integer")
                    .map((c) => {
                      return { description: c, value: UnitTypes[c] };
                    })}
                />
              </div>
            )}
          </div>
          <div className="c_c_row split">
            <div className="c_c_r_container">
              <div className="c_c_r_select_container">
                <div className="Label_3">Chart type</div>
                <MaterialSelect
                  zIndex={1}
                  width="180px"
                  onChange={(e) => {
                    this.changeChartType(e.target.value);
                  }}
                  value={
                    ChartTypes[this.chartClassName.name]
                      ? ChartTypes[this.chartClassName.name].name
                      : "Bar Chart"
                  }
                  options={Object.keys(ChartTypes).map((c) => {
                    return { description: ChartTypes[c].name, value: ChartTypes[c].name };
                  })}
                />
              </div>
              <div className="c_c_r_select_container">
                <div className="Label_3">Chart width</div>
                <MaterialSelect
                  zIndex={1}
                  width="180px"
                  onChange={(e) => {
                    this.chartFunctionsObj.setChartWidth(e.target.value);
                    this.forceUpdate();
                  }}
                  value={this.chartOptionsObj.chartConfig.width}
                  options={Object.values(ChartWidth).map((c) => {
                    return { description: c.name, value: c.value };
                  })}
                />
              </div>
              {this.chartClassName.name &&
                ChartTypes[this.chartClassName.name].name == ChartTypes.WaterfallChart.name && (
                  <div className="c_c_r_select_container">
                    <div className="Label_3">Waterfall chart type</div>
                    <MaterialSelect
                      zIndex={1}
                      width="180px"
                      onChange={(e) => {
                        this.forceUpdate();
                        this.chartFunctionsObj.changeWaterfallChartType(e.target.value);
                      }}
                      value={this.chartOptionsObj.chartConfig.waterFallType}
                      options={Object.values(WaterfallChartTypes).map((c) => {
                        return { description: c, value: c };
                      })}
                    />
                  </div>
                )}
            </div>

            {ChartTypes[this.chartClassName.name].name != ChartTypes.WaterfallChart.name && (
              <CircleCheckboxM
                checked={this.chartOptionsObj.chartOptions.isWeightedAverage}
                onChange={this.onWeightedAverageChange}
                label={"Weighted Average"}
                on
              />
            )}
            <ButtonMui
              style={{ height: "30px", fontSize: "14px" }}
              endIcon={<IconPlus />}
              label={"Save"}
              variant={"contained"}
              onClick={() => {
                this.chartFunctionsObj.SaveChart(() => {
                  this.props.selectedDashboardChart.Data =
                    this.chartFunctionsObj.getDataForObjSaving();
                });
              }}
            />
          </div>
          <div className="separator" />

          {this.chartOptionsObj.chartOptions.isWeightedAverage && (
            <>
              <div className="c_c_row left_align">
                <div className="Label_3">Main Drivers</div>
              </div>
              <DraggableComponent
                items={chartData.mainDriverKeys}
                onChange={(items) => {
                  this.chartFunctionsObj.rebuildChartData([], { mainDriverKeys: items });
                  this.forceUpdate();
                }}
                insertNewDriver={this.insertNewDriver}
                insertExistingDriver={(e) => {
                  this.chartFunctionsObj.rebuildChartData([], {
                    mainDriverKeys: [...chartData.mainDriverKeys, e],
                  });
                  this.forceUpdate();
                }}
                selectedDriverKeys={chartData.driverKeys}
                validDrivers={this.validDrivers}
                setDriverFromExisting={(driver) => this.setDriverFromExisting(null, driver)}
                subTotals={
                  this.chartClassName.name &&
                  ChartTypes[this.chartClassName.name].name == ChartTypes.WaterfallChart.name &&
                  this.chartConfigObj.waterFallType == WaterfallChartTypes.SingleComparisonPeriod
                }
              />
              <div className="c_c_row left_align">
                <div className="Label_3">Weighting Drivers</div>
              </div>
              <DraggableComponent
                items={chartData.weightingDriverKeys}
                onChange={(items) => {
                  this.chartFunctionsObj.rebuildChartData([], { weightingDriverKeys: items });
                  this.forceUpdate();
                }}
                insertNewDriver={this.insertNewDriver}
                insertExistingDriver={(e) => {
                  this.chartFunctionsObj.rebuildChartData([], {
                    weightingDriverKeys: [...chartData.weightingDriverKeys, e],
                  });
                  this.forceUpdate();
                }}
                selectedDriverKeys={chartData.driverKeys}
                validDrivers={this.validDrivers}
                setDriverFromExisting={(driver) => this.setDriverFromExisting(null, driver)}
                subTotals={
                  this.chartClassName.name &&
                  ChartTypes[this.chartClassName.name].name == ChartTypes.WaterfallChart.name &&
                  this.chartConfigObj.waterFallType == WaterfallChartTypes.SingleComparisonPeriod
                }
              />
            </>
          )}

          {!this.chartOptionsObj.chartOptions.isWeightedAverage && (
            <>
              <div className="c_c_row left_align">
                <div className="Label_3">Drivers</div>
              </div>
              <DraggableComponent
                items={chartData.driverKeys}
                onChange={(items) => {
                  this.chartFunctionsObj.rebuildChartData(items);
                  this.forceUpdate();
                }}
                insertNewDriver={this.insertNewDriver}
                insertExistingDriver={(e) => {
                  this.chartFunctionsObj.rebuildChartData([...chartData.driverKeys, e]);
                  this.forceUpdate();
                }}
                selectedDriverKeys={chartData.driverKeys}
                validDrivers={this.validDrivers}
                setDriverFromExisting={(driver) => this.setDriverFromExisting(null, driver)}
                subTotals={
                  this.chartClassName.name &&
                  ChartTypes[this.chartClassName.name].name == ChartTypes.WaterfallChart.name &&
                  this.chartConfigObj.waterFallType == WaterfallChartTypes.SingleComparisonPeriod
                }
              />
            </>
          )}

          <div className="separator" />
          <div className="c_c_row split">
            <div className="c_c_r_select_container_vertical">
              <div className="Label_3">Report Frequency</div>
              <MaterialSelect
                zIndex={1}
                width="180px"
                onChange={(e) => {
                  this.forceUpdate();
                  // this.forceUpdate();
                  this.chartFunctionsObj.changeReportFrequency(e.target.value);
                }}
                value={chartData.reportFrequency}
                options={Object.keys(ReportFrequency).map((c) => {
                  return { description: ReportFrequency[c].name, value: c };
                })}
              />
            </div>
            <div className={"c_c_r_container"}>
              <div className="c_c_r_select_container_vertical">
                <div className="Label_3">1st Comparison Period</div>
                <MaterialSelect
                  zIndex={1}
                  width="180px"
                  onChange={(e) => {
                    this.chartFunctionsObj.changeComparisonPeriod(e.target.value, 0);
                    this.forceUpdate();
                    // this.forceUpdate();
                  }}
                  value={chartData.comparisonPeriod[0]}
                  options={[
                    {
                      description: "choose",
                      value: null,
                    },
                    ...Object.values(ComparisonPeriod).map((c) => {
                      return { description: c, value: c };
                    }),
                  ]}
                />
              </div>
              <div className="c_c_r_select_container_vertical">
                <div className="Label_3">2nd Comparison Period</div>
                <MaterialSelect
                  zIndex={1}
                  width="180px"
                  onChange={(e) => {
                    this.chartFunctionsObj.changeComparisonPeriod(e.target.value, 1);
                    this.forceUpdate();
                    // this.forceUpdate();
                  }}
                  value={chartData.comparisonPeriod[1]}
                  options={[
                    {
                      description: "choose",
                      value: null,
                    },
                    ...Object.values(ComparisonPeriod).map((c) => {
                      return { description: c, value: c };
                    }),
                  ]}
                />
              </div>
              <div className="c_c_r_select_container_vertical">
                <div className="Label_3">3rd Comparison Period</div>
                <MaterialSelect
                  zIndex={1}
                  width="180px"
                  disabled={this.chartClassName.name === "AreaChart"}
                  onChange={(e) => {
                    this.chartFunctionsObj.changeComparisonPeriod(e.target.value, 2);
                    this.forceUpdate();
                    // this.forceUpdate();
                  }}
                  value={chartData.comparisonPeriod[2]}
                  options={[
                    {
                      description: "choose",
                      value: null,
                    },
                    ...Object.values(ComparisonPeriod).map((c) => {
                      return { description: c, value: c };
                    }),
                  ]}
                />
              </div>
              <div className="c_c_r_select_container_vertical">
                <div className="Label_3">4th Comparison Period</div>
                <MaterialSelect
                  zIndex={1}
                  width="180px"
                  disabled={this.chartClassName.name === "AreaChart"}
                  onChange={(e) => {
                    this.chartFunctionsObj.changeComparisonPeriod(e.target.value, 3);
                    this.forceUpdate();
                    // this.forceUpdate();
                  }}
                  value={chartData.comparisonPeriod[3]}
                  options={[
                    {
                      description: "choose",
                      value: null,
                    },
                    ...Object.values(ComparisonPeriod).map((c) => {
                      return { description: c, value: c };
                    }),
                  ]}
                />
              </div>
            </div>
          </div>
          <div className="separator" />
          {(!this.chartClassName.name ||
            ChartTypes[this.chartClassName.name].name != ChartTypes.WaterfallChart.name) && (
            <div className="c_c_row split">
              <div className={"c_c_r_container gap"}>
                <div className="c_c_r_select_container_vertical">
                  <div className="Label_3">Categories</div>
                  <MaterialSelect
                    zIndex={1}
                    width="180px"
                    disabled={this.chartClassName.name === "AreaChart"}
                    onChange={(e) => {
                      this.chartFunctionsObj.changeCategories(e.target.value);
                      this.forceUpdate();
                    }}
                    value={chartData.categories}
                    options={Object.values(ChartDataTypes).map((c) => {
                      return { description: c, value: c };
                    })}
                  />
                </div>
                <div className="c_c_r_select_container_vertical">
                  <div className="Label_3">Series</div>
                  <MaterialSelect
                    zIndex={1}
                    width="180px"
                    disabled={this.chartClassName.name === "AreaChart"}
                    onChange={(e) => {
                      this.chartFunctionsObj.changeSeries(e.target.value);
                      this.forceUpdate();
                    }}
                    value={chartData.series}
                    options={Object.values(ChartDataTypes).map((c) => {
                      return { description: c, value: c };
                    })}
                  />
                </div>
                {this.chartClassName.name &&
                  ChartTypes[this.chartClassName.name].name == ChartTypes.AreaChart.name && (
                    <div className="c_c_r_select_container_vertical">
                      <div className="Label_3">Number of months trend</div>
                      <MaterialSelect
                        zIndex={1}
                        width="180px"
                        onChange={(e) => {
                          this.chartFunctionsObj.changeNumberOfMonths(e.target.value);
                          this.forceUpdate();
                        }}
                        value={chartOptions.numberOfMonths}
                        options={NumberOfMonths.map((c) => {
                          return { description: c, value: c };
                        })}
                      />
                    </div>
                  )}
              </div>
              <CheckBoxWrapperComponent
                label={"Growth"}
                object={this.chartOptionsObj}
                option={"chartData"}
                optionKey={"growth"}
                onChange={this.chartFunctionsObj.changeGrowth}
              />
              {this.chartClassName.name &&
                (ChartTypes[this.chartClassName.name].name == ChartTypes.StackedBarChart.name ||
                  ChartTypes[this.chartClassName.name].name ==
                    ChartTypes.HorizontalBarChart.name) && (
                  <div className="c_c_r_select_container_vertical">
                    <div className="Label_3">Categories</div>
                    <div className={"c_c_r_container gap"}>
                      <CheckBoxWrapperComponent
                        label={"$ difference"}
                        object={this.chartOptionsObj}
                        option={"chartOptions"}
                        optionKey={"dollarDifference"}
                        onChange={(e, callBack) => {
                          this.handleStackedChartDifferencesChange("dollarDifference", callBack);
                        }}
                      />
                      <CheckBoxWrapperComponent
                        label={"% difference"}
                        object={this.chartOptionsObj}
                        option={"chartOptions"}
                        optionKey={"percentDifference"}
                        onChange={(e, callBack) => {
                          this.handleStackedChartDifferencesChange("percentDifference", callBack);
                        }}
                      />
                    </div>
                  </div>
                )}
            </div>
          )}
          {this.getFormatOptionsComponent(true)}
        </>
      );
    }

    return null;
  }
  shouldComponentUpdate(prevProps, prevState) {
    if (prevProps.selectedDashboardChart.ID !== this.props.selectedDashboardChart) {
      this.newChart = true;
    }
    return true;
  }

  handleNewChart = () => {
    this.newChart = false;
    this.setState({ key: new Date().getTime() });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.didMount) {
      if (prevProps.selectedDashboardChart.ID !== this.props.selectedDashboardChart.ID) {
        this.setState({ key: new Date().getTime() });
      }
    }
    if (this.props.sharedChartFunctionsObj) {
      this.props.sharedChartFunctionsObj[this.props.selectedDashboardChart.ID] = {
        changeReportFrequency: this.chartFunctionsObj.changeReportFrequency,
        changeComparisonPeriod: this.chartFunctionsObj.changeComparisonPeriod,
      };
    }
  }

  getFormatOptionsComponent = (check = true) => {
    const formatOptions = (
      <FormatOptionComponent
        onChangeColor={this.onColorChange}
        onSingleColorChange={this.onSingleColorChange}
        handleChartDataOptionsChange={this.handleChartDataOptionsChange}
        chartFunctionsObj={this.chartFunctionsObj}
        children={this.props.children}
        type={this.props.selectedDashboardChart.Data.chartClassType}
        chartOptionsObj={this.chartOptionsObj}
        rightMenuFunc={this.props.rightMenuFunc ? this.props.rightMenuFunc : this.rightMenuFunc}
        handleChartOptionsChange={this.handleChartOptionsChange}
      />
    );
    if (check) {
      return formatOptions;
    }
    return null;
  };

  render() {
    const chartPropsObj = this.props.selectedDashboardChart;

    if (this.props.grid) {
      if (chartPropsObj.Data) {
        return (
          <>
            {this.getChartObject(
              true,
              chartPropsObj.Data.chartClassType,
              chartPropsObj.ID,
              chartPropsObj.Name,
              chartPropsObj.Data.chartData,
              chartPropsObj.Data.chartOptions,
              chartPropsObj.Data.chartConfig,
              chartPropsObj.Data.snapShotData,
            )}
            {this.getFormatOptionsComponent(this.props.formatOptions)}
          </>
        );
      }
      return (
        <>
          {this.getChartObject(true)}
          {this.getFormatOptionsComponent(this.props.formatOptions)}
        </>
      );
    }
    return (
      <div key={this.state.key} className="charts_component">
        <div className="c_c_row">
          {chartPropsObj.Data
            ? this.getChartObject(
                false,
                chartPropsObj.Data.chartClassType,
                chartPropsObj.ID,
                chartPropsObj.Name,
                chartPropsObj.Data.chartData,
                chartPropsObj.Data.chartOptions,
                chartPropsObj.Data.chartConfig,
              )
            : this.getChartObject(false)}
        </div>
        {this.getChartEditingTools()}
      </div>
    );
  }
}

ChartsComponent.propTypes = {
  sharedChartFunctionsObj: PropTypes.object,
  showIndicatorBoxes: PropTypes.bool,
};
