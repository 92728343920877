import React, { Fragment, useState } from "react";
// import RightMenuPortal from "./RightMenuPortal.js";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import PropTypes from "prop-types";
import InputBox from "../InputBoxComponent/InputBox";
import { FlipHorizontally, FlipVertically, Rotate90 } from "../IconButtons/SubMenuIcons";
import MaterialSelect from "../Select/MaterialSelect";
import { FontSizeOptions } from "../../SlideObjects/ChartComponents/FontSizeOptions";

function DataLabels(props) {
  const [openDataLabels, setOpenDataLabels] = React.useState(false);

  const handleClickDataLabels = () => {
    if (openDataLabels) {
      setOpenDataLabels(false);
    } else {
      setOpenDataLabels(true);
      props.rightMenuFunc.closeDataLabels = null;
      Object.values(props.rightMenuFunc).map((value) => {
        if (typeof value === "function") {
          value.call();
        }
      });
    }
  };

  const closeMenu = () => {
    setOpenDataLabels(false);
  };

  props.rightMenuFunc.closeDataLabels = closeMenu;

  const handleTopChange = (top) => {
    props.onChange(props.left, Number(top));
  };
  const handleLeftChange = (left) => {
    props.onChange(Number(left), props.top);
  };

  const handleFontChange = (e) => {
    props.onPropsChange({ dataLabelsFontSize: e.target.value });
  };

  const handleDecimalPointsChange = (e) => {
    props.onPropsChange({ datalabelsDecimalPoints: e.target.value });
  };

  const handleShowDataLabels = (e) => {
    props.onPropsChange({ showDataLabels: e.target.checked });
  };

  const handleHideSeriesDataLabels = (e) => {
    props.onPropsChange({ hideSeriesDataLabels: e.target.checked });
  };

  const handlePositionChange = (e, val) => {
    if (e.target.value !== "Automatic") {
      props.onPropsChange({ DataLabelsPosition: e.target.value });
    } else {
      props.onPropsChange({ DataLabelsPosition: "end" });
    }
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      onKeyDown={(e) => {
        e.stopPropagation();
      }}
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
    >
      <ListItemButton id={"listItem"} onClick={handleClickDataLabels}>
        <ListItemText primary="DataLabels" />
        {openDataLabels ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <div style={{ width: "230px", border: "1px solid #F0F1F7", marginLeft: "18px" }} />
      <Collapse in={openDataLabels} timeout="auto" unmountOnExit>
        <div className={"collapse"}>
          <div className={"listRow"}>
            <input
              type="checkbox"
              checked={props.showDataLabels}
              onChange={handleShowDataLabels}
              style={{ margin: 0, marginRight: "4px" }}
            />
            <div className={"listSubHeading"}>Show data labels</div>
          </div>
          {props.dataLabelsOptions ? (
            <div className={"listRow"}>
              <MaterialSelect
                value={props.DataLabelsPosition}
                options={props.dataLabelsOptions}
                onChange={handlePositionChange}
              />
            </div>
          ) : (
            ""
          )}
          {props.hideSeries ? (
            <div className={"listRow"}>
              <input
                type="checkbox"
                checked={props.hideSeriesDataLabels}
                onChange={handleHideSeriesDataLabels}
                style={{ margin: 0, marginRight: "4px" }}
              />
              <div className={"listSubHeading"}>Hide series data labels</div>
            </div>
          ) : (
            ""
          )}
          <div className={"listRow listColumn"}>
            <label className={"listSubHeading"}>Font size: </label>
            <MaterialSelect
              value={props.dataLabelsFontSize}
              options={FontSizeOptions}
              onChange={handleFontChange}
            />
          </div>
          <div className={"listRow listColumn"}>
            <label className={"listSubHeading"}>Decimal Points: </label>
            <MaterialSelect
              value={props.datalabelsDecimalPoints}
              options={[
                { value: 0, name: "0" },
                { value: 1, name: "1" },
                { value: 2, name: "2" },
                { value: 3, name: "3" },
                { value: 4, name: "4" },
              ]}
              onChange={handleDecimalPointsChange}
            />
          </div>
        </div>
      </Collapse>
    </div>
  );
}

DataLabels.propTypes = {
  hideSeries: PropTypes.bool,
  dataLabelsOptions: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  onPropsChange: PropTypes.func,
};

export default DataLabels;
