import React from "react";
import styled from "styled-components";
import { Theme } from "../../../theme/styles/theme";
import { Link } from "react-router-dom";

const Sizes = {
  p1_strong: Theme.text.paragraph.p1_strong,
  p2_strong: Theme.text.paragraph.p2_strong,
  p3_strong: Theme.text.paragraph.p3_strong,
  p4_strong: Theme.text.paragraph.p4_strong,
  p1: Theme.text.paragraph.p1,
  p2: Theme.text.paragraph.p2,
  p3: Theme.text.paragraph.p3,
  p4: Theme.text.paragraph.p4,
};

const StyledParagraph = styled.p`
  font-family: Inter;
  color: ${(props) => (props.color ? props.color : Theme.colors.greyscale.black)};
  ${(props) => Sizes[props.size]};
  ${(props) => props.active && `color: #01AFFD; white-space: nowrap;`};
  ${(props) => props.active === false && "white-space: nowrap"};
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`;

export const Paragraph = ({ color, size, label, style, link, path, ...props }) => {
  return (
    <StyledDiv className={props.className ? props.className : ""} onClick={props.onClick}>
      <StyledParagraph active={props.active} style={style} color={color} size={size}>
        {label}
      </StyledParagraph>
    </StyledDiv>
  );
};
