import React from "react";
import Button from "./../../../../../components/actions/Button";
import { FirstSection } from "../FirstSection";
import { ButtonsWrapper } from "../ButtonsWrapper";
import { ParagraphP2Strong } from "../PargaraphP2Strong";
import styled from "styled-components";
import { Theme } from "./../../../../../theme/styles/theme";
import Input from "./../../../../../components/actions/Input";
import "./../onboarding.scss";
import { ArrowLeft, ArrowRight, IconPlus } from "../../../../../components/icons/svgIcons";
import ButtonMui from "../../../../../components/buttons/buttonMui/buttonMui";

export const ParagraphP3 = styled.p`
  ${Theme.text.paragraph.p3};
  color: #252525;
  font-family: Inter;
  margin-top: 18px;
`;

export const SelectWrapperStep2 = styled.div`
  display: block;
  maxwidth: 135px;
  minwidth: 135px;
  margin-bottom: 15px;
`;

export default function Step2(props) {
  const [companyName, setCompanyName] = React.useState(props.company.companyName);
  // const [userSurName,setUserSurName]=React.useState(props.User.Surname);
  // const [userName,setUserName]=React.useState(props.User.Name);
  const [companyNameError, setCompanyNameError] = React.useState("");

  const validate = () => {
    let curCompanyNameError = "";
    switch (true) {
      case companyName.length < 3:
        curCompanyNameError = "company name error";
        break;
    }
    if (curCompanyNameError) {
      setCompanyNameError(curCompanyNameError);
      return false;
    }
    return true;
  };

  const changeCompanyName = (e) => {
    if (!e) {
      setCompanyName("");
    } else {
      setCompanyName(e.target.value);
    }
  };

  const changeStep = (callBack) => {
    const valid = validate();
    if (valid) {
      props.setCompanyName(companyName, callBack);
    } else {
      setCompanyNameError(true);
    }
  };

  // const changeUserName = (e) => {
  //     setUserName(e.target.value)
  //     props.setUserName(e.target.value, () => {});
  // }
  //
  // const changeUserSurName = (e) => {
  //     setUserSurName(e.target.value)
  //     props.setUserSurName(e.target.value, () => {});
  //
  // }

  return (
    <>
      <div className="spsocwn2_container_plan">
        <div style={{ display: "block" }}>
          <FirstSection>
            <span className="p p1 strong title">What is your company name?</span>
            <div className="p p3 p_b p_t p_l">
              Don't worry if you have not decided yet on your company name - you can change it
              later.
            </div>
            {/*<SelectWrapperStep2 >*/}
            {/*<Input onChange={changeUserName} value={userName} placeholder='First Name' />*/}
            {/*</SelectWrapperStep2>*/}
            {/*<SelectWrapperStep2 >*/}
            {/*<Input onChange={changeUserSurName} value={userSurName} placeholder='Last Name' />*/}
            {/*</SelectWrapperStep2>*/}
            <SelectWrapperStep2>
              <Input
                error={companyNameError}
                onChange={changeCompanyName}
                value={companyName}
                placeholder="My Company"
              />
            </SelectWrapperStep2>
          </FirstSection>
        </div>
      </div>
      <ButtonsWrapper>
        <div className="o_btn_container">
          <ButtonMui
            disabled
            startIcon={<ArrowLeft />}
            label={"Back"}
            width={120}
            variant={"contained"}
          />
        </div>
        <div className="o_btn_container start">
          <ButtonMui
            label={"Next"}
            width={120}
            variant={"contained"}
            endIcon={<ArrowRight />}
            onClick={() => changeStep(() => props.NextStep())}
          />
        </div>
      </ButtonsWrapper>
    </>
  );
}
