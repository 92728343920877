import React from "react";
import { Paragraph } from "../../../../../../components/typography/paragraph/Paragraph";
import PropTypes from "prop-types";
import "./ChangeEnabled.scss";
import Tooltip from "@mui/material/Tooltip";
import Mx_Tooltip from "../../../../../../components/icons/Mx_Tooltip_Click";
import { EditIcon } from "../../../../../../SlideEditor/components/components/menus/LeftMenu/components/Icons/LeftMenuIcons";
import IconButton from "@mui/material/IconButton";
import FinanceTableHeader from "../../../../../../components/tables/FinanceTable/components/FinanceTableHeader";
import CheckBoxMaterial from "../../../../../../components/actions/CheckBoxMaterial";

class ChangeEnabled extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      editMode: false,
      name: props.subtitle,
    };
  }

  closeTooltip = () => {
    if (this.state.open) {
      this.setState({ open: false });
    }
  };

  openTooltip = () => {
    if (!this.state.open) {
      this.setState({ open: true });
    }
  };

  SaveDriverName = (name) => {
    if (name.length > 0 && name.toString().trim() !== "" && !name.includes("    ")) {
      this.props.selectedTab.dataGridRow.data.DriverName =
        this.props.selectedTab.dataGridRow.data.DriverName.replace(
          this.props.selectedTab.dataGridRow.data.Name,
          name + " ",
        );
      this.props.selectedTab.subtitle = name;
      this.props.selectedTab.title = name;
      this.state.name = name;
      this.state.editMode = false;
      this.props.onDataChanged();
    } else {
      this.state.name = this.props.selectedTab.dataGridRow.data.Name;
      this.state.editMode = false;
      this.props.onDataChanged();
    }
  };

  changeActuals = () => {
    this.props.selectedTab.showActuals = !this.props.selectedTab.showActuals;
    this.props.onDataChanged();
  };

  render() {
    return (
      <>
        <div className="pscfc_single_finance_tab_subtitle_buttons_content">
          <div
            style={{
              display: "flex",
              gap: "15px",
              alignItems: "center",
              justifyContent: "center",
              height: "58px",
            }}
          >
            {this.state.editMode ? (
              <div
                style={{
                  height: "25px",
                }}
              >
                <input
                  className={"section_name_input"}
                  onMouseDown={(e) => e.stopPropagation()}
                  onClick={(e) => e.stopPropagation()}
                  defaultValue={this.state.name}
                  onKeyDown={(e) => e.stopPropagation()}
                  onChange={(e) => this.setState({ name: e.target.value })}
                  placeholder={this.state.name}
                  onBlur={this.SaveDriverName}
                />
              </div>
            ) : (
              <>
                <FinanceTableHeader
                  editScreen={true}
                  row={{ header: this.state.name }}
                  changeDriverName={(name) => this.SaveDriverName(name)}
                  changeEnabled={this.props.selectedTab.changeDriverNameEnabled}
                >
                  <Paragraph size="p2_strong" label={this.state.name} />
                </FinanceTableHeader>

                {/*{this.props.selectedTab.changeDriverNameEnabled && <Tooltip title={'Rename'}><IconButton className={'svg_btn'} onClick={() => this.setState({editMode: true})}><EditIcon/></IconButton></Tooltip>}*/}
              </>
            )}

            {!this.props.disableInfoIcon && <Mx_Tooltip description={this.props.description} />}
          </div>

          {global.Modeliks.PERMISSIONS.Dashboards.restrictions.overAll.active &&
            this.props.selectedTab.allowDisplayActuals &&
            !this.props.disableActuals && (
              <CheckBoxMaterial
                label={"Show Actuals"}
                checked={this.props.selectedTab.showActuals}
                onChange={this.changeActuals}
                size="medium"
                elevation={false}
              />
            )}

          {/*{this.props.changeEnabled &&*/}
          {/*<div hidden={!(this.props.changeEnabled)}*/}
          {/*style={{*/}
          {/*display: 'flex',*/}
          {/*gap: '0px',*/}
          {/*alignItems: 'center'*/}
          {/*}}*/}
          {/*>*/}
          {/*<Paragraph style={{fontFamily: 'Inter'}} size='p4' color='#585858' label='Change'/>*/}
          {/*<div style={{width: '62px', marginLeft: '10px'}}>*/}
          {/*<InputM*/}
          {/*style={{width: '100%', padding: '6px', textAlign: 'right'}}*/}
          {/*type='number'*/}
          {/*value={this.props.percentage}*/}
          {/*onChange={this.props.handeChangePercentage}*/}
          {/*name='percentage'*/}
          {/*min="-100"*/}
          {/*/>*/}
          {/*</div>*/}
          {/*<div style={{width: '78px'}}>*/}
          {/*<Button width={'65px'} padding={'0px'} size='small' noElevation={true}*/}
          {/*color='primary' label='apply' onClick={this.props.onApplyClick}/>*/}
          {/*</div>*/}

          {/*</div>}*/}
        </div>
      </>
    );
  }
}

ChangeEnabled.propTypes = {
  subtitle: PropTypes.string,
  percentage: PropTypes.any,
  changeEnabled: PropTypes.bool,
  handeChangePercentage: PropTypes.func,
  onApplyClick: PropTypes.func,
};

export default ChangeEnabled;
