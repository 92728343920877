import React from "react";
import { Bar } from "react-chartjs-2";
import Chart from "./Chart";
import { ChartDataTypes, ChartTypes, ChartWidth } from "../../../components/constants/charts";
import ChartTableComponent from "./ChartTableComponent";
import { Theme } from "../../../theme/styles/theme";
import { MenuItem } from "@szhsin/react-menu";
import CircleCheckboxM from "../../../components/actions/CircleCheckboxM";
import MxMath from "../../../data/MxMath";
import NumberInputAction from "../../../components/actions/inputs/NumberInput";
import ChartSelectSeries from "../../../components/chart/components/ChartSelectSeries";

const positiveArrow = (
  <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 11.5L11 2.5L2 11.5"
      stroke="#66D151"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const negativeArrow = (
  <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 2.5L11 11.5L20 2.5"
      stroke="#EA0000"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default class StackedBarChart extends Chart {
  chartResizerRef = null;
  tableRef = null;
  subscriptions = [];

  constructor(props) {
    super(props);
    this.chartClassName = "StackedBarChart";

    this.chartOptions.hasCustomColor = false;
    if (props.chartOptionsObj) {
      props.chartOptionsObj.chartOptions = this.chartOptions;
    }
    this.setClassName();
    this.buildData();
  }

  getThisChartOptions = () => {
    let options = this.getChartOptions();
    return {
      ...options,
      scales: {
        ...options.scales,
        x: {
          ...options.scales.x,
          stacked: true,
        },
        y: {
          ...options.scales.y,
          stacked: true,
        },
      },
    };
  };

  getStartingColorIndex(keys = []) {
    let Obj = {};
    keys.forEach((key, index) => {
      let color = [
        Object.keys(Theme.colors.chart)[index % Object.keys(Theme.colors.chart).length],
        500,
      ];
      if (this.chartOptions.barColorKeys[key]) {
        color = this.chartOptions.barColorKeys[key];
      }
      Object.assign(Obj, {
        [key]: Object.keys(Theme.colors.chart[color[0]])
          .reverse()
          .findIndex((c) => {
            return c == color[1];
          }),
      });
    });
    return Obj;
  }

  getRandomColor(random_key, singleColor = null) {
    let colors = this.chartLabels.map((key, index) => {
      let color = [
        Object.keys(Theme.colors.chart)[index % Object.keys(Theme.colors.chart).length],
        500,
      ];
      if (singleColor) {
        color = this.chartOptions.singleColor;
      } else if (this.chartOptions.barColorKeys[key]) {
        color = this.chartOptions.barColorKeys[key];
      }
      let palette = Object.values(Theme.colors.chart[color[0]]).reverse();
      return palette[this.currentColorIndex[key]++ % palette.length];
    });
    return colors;
  }

  handleChartDataOptionsChange = (props, callBack) => {
    this.chartOptions = Object.assign({ ...this.chartOptions }, props);
    if (props.gapWidth) {
      this.chartRef.data.datasets.forEach(
        (c) => (c.barPercentage = this.chartOptions.gapWidth / 10),
      );
    } else if (props.categoryGapWidth) {
      this.chartRef.data.datasets.forEach(
        (c) => (c.categoryPercentage = this.chartOptions.categoryGapWidth / 10),
      );
    }
    if (props.hasOwnProperty("dollarDifference") || props.hasOwnProperty("percentDifference")) {
      if (this.chartOptions.dollarDifference || this.chartOptions.percentDifference) {
        this.tableRef.style.display = "flex";
      } else {
        this.tableRef.style.display = "none";
      }
      this.chartContainerRef.style.width = `${this.getChartWidth()}%`;
      this.chartResizerRef.style.width = `${this.getChartContainerWidth()}%`;
      this.subscriptions.forEach((s) => s());
    }
    this.chartRef.update();
    this.forceUpdate();
    callBack && callBack();
  };

  changeNumberFormatting(numberFormat) {
    super.changeNumberFormatting(numberFormat);
    this.forceUpdate();
  }

  shouldComponentUpdate(prevProps, prevState) {
    if (this.chartConfig.width !== this.props.chartConfig.width) {
      // this.chartConfig.width = this.props.chartConfig.width;
      this.chartOptions.numberFormat = this.props.chartOptions.numberFormat;
      return true;
    }
    return this.props != prevProps;
  }

  handleChartOptionsChange = (props, callBack) => {
    this.chartOptions = Object.assign({ ...this.chartOptions }, props);
    if (
      ChartTypes[this.chartClassName].name == ChartTypes.StackedBarChart.name ||
      ChartTypes[this.chartClassName].name == ChartTypes.HorizontalBarChart.name
    ) {
      this.chartRef.options = this.getThisChartOptions();
    } else {
      this.chartRef.options = this.getChartOptions();
    }
    if (props.hasOwnProperty("showTitle")) {
      if (props.showTitle) {
        this.chartTitleRef.style.visibility = "visible";
      } else {
        this.chartTitleRef.style.visibility = "hidden";
      }
    }
    this.chartRef.update();
    callBack && callBack();
  };

  getDifferences = () => {
    return this.getArrayDatasets().map((c, index) => {
      return {
        data: c.data,
        backgroundColor:
          c.backgroundColor && c.backgroundColor.length > 0
            ? c.backgroundColor[0]
            : this.getRandomColor(index)[0],
      };
    });
  };

  setTableRef = (ref) => {
    this.tableRef = ref;
  };

  setChartResizerRef = (ref) => {
    this.chartResizerRef = ref;
  };

  getChartContainerWidth = () => {
    if (this.chartOptions.dollarDifference || this.chartOptions.percentDifference) {
      if (this.chartConfig.width == ChartWidth.fullScreen.value) {
        return this.chartConfig.width;
      } else {
        return this.chartConfig.width + ChartWidth.quarterScreen.value;
      }
    }
    return this.chartConfig.width;
  };

  getChartWidth = () => {
    if (this.chartOptions.dollarDifference || this.chartOptions.percentDifference) {
      if (
        this.props.chartConfig.width == ChartWidth.fullScreen.value ||
        this.props.chartConfig.width == ChartWidth.threeQuartersScreen.value
      ) {
        return ChartWidth.threeQuartersScreen.value;
      } else if (this.props.chartConfig.width == ChartWidth.halfScreen.value) {
        return 67;
      } else {
        return ChartWidth.halfScreen.value;
      }
    }
    return ChartWidth.fullScreen.value;
  };

  setChartWidth = (width) => {
    this.chartConfig.width = width;
    this.chartResizerRef.style.width = `${this.getChartContainerWidth()}%`;
    this.chartContainerRef.style.width = `${this.getChartWidth()}%`;
    if (this.tableRef) {
      this.tableRef.style.width = `${this.getTableWidth()}%`;
    }
    this.SaveChart();
  };

  getTableWidth = () => {
    if (this.props.chartConfig.width == ChartWidth.quarterScreen.value) {
      return ChartWidth.halfScreen.value;
    } else if (this.props.chartConfig.width == ChartWidth.halfScreen.value) {
      return 33;
    } else {
      return ChartWidth.quarterScreen.value;
    }
  };

  setChartRightPadding() {
    super.setChartRightPadding();
    if (this.chartOptions.showContribution || this.chartOptions.showDetailedNumbers) {
      this.ChartRightPadding += 50;
    }
  }

  getContribution = (ctx) => {
    if (this.chartOptions.showContribution) {
      let data = ctx.chart.data.datasets;
      let value =
        (ctx.dataset.data[ctx.dataIndex] / data.reduce((x, y) => x + y.data[ctx.dataIndex], 0)) *
        100;
      if (isNaN(value)) {
        return "";
      }
      return `${this.getPercentageFormat(value)}%`;
    }
    return "";
  };

  getGrowthValue(value, ctx) {
    if (this.chartData.categories !== ChartDataTypes.comparisonPeriod) {
      return super.getGrowthValue(value, ctx);
    }

    const getSumValue = (dataIndex) => {
      return ctx.chart.config.data.datasets.map((datapoint) => {
        if (datapoint.data[dataIndex] && datapoint.hidden !== true) {
          return datapoint.data[dataIndex];
        } else {
          return 0;
        }
      });
    };
    const sumValue1 = getSumValue(ctx.dataIndex);
    const sumValue2 = getSumValue(ctx.dataIndex + 1);

    let growthValue = "-";

    function totalSum(total, datapoint) {
      return total + datapoint;
    }

    let sum1 = sumValue1.reduce(totalSum, 0);
    let sum2 = sumValue2.reduce(totalSum, 0);

    if (isNaN(sum1)) {
      this.chartValueSums.push(0);
    } else {
      this.chartValueSums.push(sum1);
      if (ctx.dataIndex < ctx.dataset.data.length - 1) {
        growthValue = MxMath.Round((sum1 / sum2 - 1) * 100);
      }
    }
    if (ctx.datasetIndex == ctx.chart.config.data.datasets.length - 1) {
      return growthValue;
    } else {
      return "";
    }
  }

  getDetailedNumbers = (value) => {
    let string = "";
    if (this.chartOptions.showDetailedNumbers) {
      string +=
        this.getCurrencyIfPrice() + this.DataLabelFormat(value) + this.getPercentIfPercent();
      if (this.chartOptions.showContribution) {
        string += ";";
      }
    }

    return string;
  };

  getAdditionalDataSettings() {
    return {
      datalabels: {
        labels: {
          index: {
            display:
              this.chartOptions.showDetailedNumbers || this.chartOptions.showContribution
                ? "auto"
                : false,
            align: "right",
            anchor: "center",
            color: "black",
            formatter: (value, ctx) => {
              return this.getDetailedNumbers(value) + this.getContribution(ctx);
            },
            offset: function (ctx) {
              if (ctx.chart.getDatasetMeta(0) && ctx.chart.getDatasetMeta(0).data[0]) {
                return (ctx.chart.getDatasetMeta(0).data[0].width * 1) / 2;
              }
              return 0;
            },
            opacity: 1,
          },
          value: {},
          growth: this.getGrowthDataLabel(),
        },
        font: {
          fontFamily: "Inter",
          weight: 700,
          style: "bold",
          size: this.chartOptions.axisFontSize - 1, //10,
          lineHeight: "160%",
        },
      },
    };
  }

  getAdditionalChartSettings() {
    return (
      <>
        <MenuItem
          onClick={(e) => {
            e.keepOpen = true;
          }}
        >
          <CircleCheckboxM
            label={"Show detailed numbers "}
            size="medium"
            checked={this.chartOptions.showDetailedNumbers}
            onChange={(e) => {
              this.chartOptions.showDetailedNumbers = e.target.checked;
              this.setChartRightPadding();
              this.buildData();
              this.forceUpdate(() => {
                this.saveCurrentSettings();
                if (this.chartRef) {
                  this.chartRef.data.datasets = this.getData();
                  this.chartRef.update();
                }
              });
            }}
          />
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.keepOpen = true;
          }}
        >
          <CircleCheckboxM
            label={"Show contribution "}
            size="medium"
            checked={this.chartOptions.showContribution}
            onChange={(e) => {
              this.chartOptions.showContribution = e.target.checked;
              this.setChartRightPadding();
              if (this.chartOptions.showContribution) {
                this.buildData();
                this.forceUpdate(() => {
                  this.saveCurrentSettings();
                  if (this.chartRef) {
                    this.chartRef.data.datasets = this.getData();
                    this.chartRef.update();
                  }
                });
              } else {
                this.forceUpdate(() => {
                  this.saveCurrentSettings();
                });
              }
            }}
          />
        </MenuItem>
        <ChartSelectSeries
          onResetColor={() =>
            this.handleResetColor(() => {
              this.forceUpdate(() => {
                if (this.chartRef) {
                  this.chartRef.update();
                }
              });
            })
          }
          onSaveBarColorKeys={(color, index) => {
            this.handleUpdateColor(color, index, (chartDatasets) => {
              this.forceUpdate(() => {
                if (this.chartRef) {
                  this.chartRef.update();
                }
              });
            });
          }}
          chartLabels={this.chartLabels}
          series={this.chartDatasets[0]}
        />

        <MenuItem
          onClick={(e) => {
            e.keepOpen = true;
          }}
        >
          <NumberInputAction
            value={this.chartOptions.datalabelsDecimalPoints}
            label={"Show decimals"}
            onChange={(decPointsVal) => {
              this.chartOptions.datalabelsDecimalPoints = decPointsVal;
              this.setChartRightPadding();
              this.buildData();
              this.forceUpdate(() => {
                this.saveCurrentSettings();
                if (this.chartRef) {
                  this.chartRef.data.datasets = this.getData();
                  // this.chartRef.update();
                }
              });
            }}
          />
        </MenuItem>
      </>
    );
  }

  getBottomItemsContainer() {
    if (this.props.showIndicatorBoxes) {
      return (
        <div className={"bottom_item_container"}>
          {this.chartData.comparisonPeriod.map((c, index) => {
            if (index === 0) {
              return (
                <div className={"box"} key={`getBottomItemsContainerKey_${index}`}>
                  <div className={"period_name"}>{c}</div>
                  <div className={"big_value"}>
                    {this.getCurrency(this.DataLabelFormat(this.chartValueSums[index]))}
                  </div>
                </div>
              );
            }
            let percentageValue = (this.chartValueSums[0] / this.chartValueSums[index] - 1) * 100;
            if (
              (this.chartValueSums[0] > 0 && this.chartValueSums[index] < 0) ||
              (this.chartValueSums[0] < 0 && this.chartValueSums[index] > 0)
            ) {
              percentageValue = NaN;
            }

            let differenceValue = this.chartValueSums[0] - this.chartValueSums[index];
            let arrow = percentageValue > 0 ? positiveArrow : negativeArrow;
            return (
              <div className={"box colored"} key={`getBottomItemsContainerKey_${index}`}>
                <div className={"period_name"}>vs {c}</div>
                <div className={`percentage_diff ${percentageValue > 0 ? "positive" : "negative"}`}>
                  {(percentageValue > 0 ? "+" : "") + this.getPercentageFormat(percentageValue)}%{" "}
                  {arrow}
                </div>
                <div className={`small_value`}>
                  {this.getCurrency(
                    (differenceValue > 0 ? "+" : "") + this.DataLabelFormat(differenceValue),
                  )}
                </div>
              </div>
            );
          })}
        </div>
      );
    }
    return null;
  }

  getTopPadding() {
    let ultimatePadding = 0;
    if (this.props.showIndicatorBoxes) {
      return 230;
    }
    if (this.props.isScaled) {
      ultimatePadding += 10;
    }
    return ultimatePadding + super.getTopPadding();
  }

  render() {
    if (this.getImageSrc()) {
      return (
        <div
          className={"chart_row"}
          style={{ width: `${this.props.grid ? 100 : this.getChartContainerWidth()}%` }}
          ref={this.setChartResizerRef}
        >
          {this.getChartElementAsImage()}
        </div>
      );
    }
      const datasets = this.getData();
      datasets.forEach(d => {
          d.datalabels.font.size = 12;
          d.datalabels.labels.growth.font.size = 12;
      })
    return (
      <div
        className={"chart_row"}
        style={{ width: `${this.props.grid ? 100 : this.getChartContainerWidth()}%` }}
        ref={this.setChartResizerRef}
      >
        <div
          className={"c_c_chart_container"}
          style={{ width: `${this.getChartWidth()}%` }}
          ref={this.setChartContainerRef}
        >
          {/*<div ref={this.setChartTitleRef} style={{visibility: this.chartOptions.showTitle?'visible':'hidden'}} className={'chart_title_wrapper'}><ChartTitle title={this.getChartTitle(this.chartOptions.comparisonBar)} change={this.getChartTitleChange()} unit={this.getChartTitleGrowthUnit()}/></div>*/}
          {this.getTitleElement()}
          {/*<div className={'c_c_chart_resizer'} ref={this.setChartResizerRef}>*/}
          <Bar
            key={this.state.key}
            ref={this.setChartRef}
            data={{
              labels: this.getChartLabels(),
              datasets,
            }}
            plugins={this.getPlugins()}
            options={this.getThisChartOptions()}
            type={"bar"}
          />
        </div>

        <div
          className={"chart_table_container"}
          style={{
            width: `${this.getTableWidth()}%`,
            display:
              this.chartOptions.dollarDifference || this.chartOptions.percentDifference
                ? "block"
                : "none",
          }}
          ref={this.setTableRef}
        >
          <ChartTableComponent
            numberFormat={this.chartOptions.numberFormat}
            TitleObject={
              <div className={"table_title"}>
                {this.chartData.comparisonPeriod[0]} vs {this.chartData.comparisonPeriod[1]}
              </div>
            }
            data={{
              rows: this.getDifferences().reverse(),
              subscriptions: this.subscriptions,
              chart: this,
            }}
          />
        </div>
      </div>
    );
  }
}
StackedBarChart.defaultProps = {
  chartOptions: {
    dollarDifference: false,
    percentDifference: false,
  },
};
