import React, { Fragment, useState } from "react";
import "../HeaderTextEditor.scss";
import "../HeaderDefault.scss";
import {
  ClearFormatting,
  Numbered,
  NumberedList1,
  NumberedList2,
  NumberedList3,
  NumberedList4,
  NumberedList5,
  NumberedList6,
} from "../../components/IconButtons/SubMenuIcons";
import { Menu, MenuItem, SubMenu } from "@szhsin/react-menu";
import Tooltip from "@mui/material/Tooltip";
import ButtonMui from "../../../../components/buttons/buttonMui/buttonMui";

function NumberingEditComponent(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openNumbering = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onChange = (style) => {
    props.onChange(style);
  };
  const Numbering1 = () => {
    const list = props.value.split("<div>");
    let all = [];
    all.push(
      `<ol>${list.map((row) => {
        if (row.includes("        ")) {
          return `<ol><ol><li>${row.toString().trim()}</li></ol></ol>`;
        } else if (row.includes("    ")) {
          return `<ol style="list-style-type: 'lower-alpha)' "><li>${row.toString().trim()}</li></ol>`;
        } else {
          return `<li>${row}</li>`;
        }
      })} </ol>`,
    );
    props.onValueChange(all.toString().split(",").join(""));
  };
  const Numbering2 = () => {
    const list = props.value.split("<div>");
    let all = [];
    all.push(
      `<ol>${list.map((row) => {
        if (row.includes("        ")) {
          return `<ol><ol><li>${row.toString().trim()}</li></ol></ol>`;
        } else if (row.includes("    ")) {
          return `<ol style="list-style-type: lower-alpha"><li>${row.toString().trim()}</li></ol>`;
        } else {
          return `<li>${row}</li>`;
        }
      })} </ol>`,
    );
    props.onValueChange(all.toString().split(",").join(""));
  };
  const Numbering3 = () => {
    const list = props.value.split("<div>");
    let all = [];
    all.push(
      `<ol>${list.map((row) => {
        if (row.includes("        ")) {
          return `<ol><ol><li>${row.toString().trim()}</li></ol></ol>`;
        } else if (row.includes("    ")) {
          return `<ol style="list-style-type: lower-alpha"><li>${row.toString().trim()}</li></ol>`;
        } else {
          return `<li>${row}</li>`;
        }
      })} </ol>`,
    );
    props.onValueChange(all.toString().split(",").join(""));
  };
  const Numbering4 = () => {
    const list = props.value.split("<div>");
    let all = [];
    all.push(
      `<ol>${list.map((row) => {
        if (row.includes("        ")) {
          return `<ol><ol><li>${row.toString().trim()}</li></ol></ol>`;
        } else if (row.includes("    ")) {
          return `<ol style="list-style-type: lower-alpha"><li>${row.toString().trim()}</li></ol>`;
        } else {
          return `<li>${row}</li>`;
        }
      })} </ol>`,
    );
    props.onValueChange(all.toString().split(",").join(""));
  };
  const Numbering5 = () => {
    const list = props.value.split("<div>");
    let all = [];
    all.push(
      `<ol>${list.map((row) => {
        if (row.includes("        ")) {
          return `<ol><ol><li>${row.toString().trim()}</li></ol></ol>`;
        } else if (row.includes("    ")) {
          return `<ol style="list-style-type: lower-alpha"><li>${row.toString().trim()}</li></ol>`;
        } else {
          return `<li>${row}</li>`;
        }
      })} </ol>`,
    );
    props.onValueChange(all.toString().split(",").join(""));
  };
  const Numbering6 = () => {
    const list = props.value.split("<div>");
    let all = [];
    all.push(
      `<ol>${list.map((row) => {
        if (row.includes("        ")) {
          return `<ol><ol><li>${row.toString().trim()}</li></ol></ol>`;
        } else if (row.includes("    ")) {
          return `<ol style="list-style-type: lower-alpha"><li>${row.toString().trim()}</li></ol>`;
        } else {
          return `<li>${row}</li>`;
        }
      })} </ol>`,
    );
    props.onValueChange(all.toString().split(",").join(""));
  };

  const NumberingEdit = () => {
    if (props.menu) {
      return (
        <Menu
          className={props.className ? "bulleting " + props.className : "bulleting"}
          menuButton={({ open }) => (
            <ButtonMui
              tooltipText={"Numbering"}
              className={props.className ? props.className : ""}
              variant={"text"}
              isIconButton
              roundnessType={"squared"}
              color={"black"}
              label={<Numbered active={open} />}
            />
          )}
          openTrigger="clickOnly"
          direction={"bottom"}
        >
          <MenuItems />
        </Menu>
      );
    } else {
      return (
        <SubMenu
          className={props.className ? "bulleting " + props.className : "bulleting"}
          label={
            <Tooltip title="Numbering" arrow>
              <Numbered
              // activeColor={props.style.backgroundColor === undefined ? '#585858' : props.style.backgroundColor}
              />
            </Tooltip>
          }
          openTrigger="clickOnly"
          direction={"bottom"}
        >
          <MenuItems />
        </SubMenu>
      );
    }
  };

  const MenuItems = () => {
    return (
      <>
        <MenuItem onClick={() => props.setBullet("number", "decimal")}>
          <NumberedList1
            id={"MenuItems"}
            active={
              props.objType && props.objType === "table"
                ? props.style &&
                  props.style.bullet &&
                  props.style.curType &&
                  props.style.curType === "decimal"
                : props.rowStyle &&
                  props.rowStyle.bullet &&
                  props.rowStyle.curType &&
                  props.rowStyle.curType === "decimal"
            }
          />
        </MenuItem>
        <MenuItem onClick={() => props.setBullet("number", "lower-alpha")}>
          <NumberedList2
            id={"MenuItems"}
            active={
              props.objType && props.objType === "table"
                ? props.style &&
                  props.style.bullet &&
                  props.style.curType &&
                  props.style.curType === "lower-alpha"
                : props.rowStyle &&
                  props.rowStyle.bullet &&
                  props.rowStyle.curType &&
                  props.rowStyle.curType === "lower-alpha"
            }
          />
        </MenuItem>
        <MenuItem onClick={() => props.setBullet("number", "lower-roman")}>
          <NumberedList3
            id={"MenuItems"}
            active={
              props.objType && props.objType === "table"
                ? props.style &&
                  props.style.bullet &&
                  props.style.curType &&
                  props.style.curType === "lower-roman"
                : props.rowStyle &&
                  props.rowStyle.bullet &&
                  props.rowStyle.curType &&
                  props.rowStyle.curType === "lower-roman"
            }
          />
        </MenuItem>
        <MenuItem onClick={() => props.setBullet("number", "upper-alpha")}>
          <NumberedList4
            id={"MenuItems"}
            active={
              props.objType && props.objType === "table"
                ? props.style &&
                  props.style.bullet &&
                  props.style.curType &&
                  props.style.curType === "upper-alpha"
                : props.rowStyle &&
                  props.rowStyle.bullet &&
                  props.rowStyle.curType &&
                  props.rowStyle.curType === "upper-alpha"
            }
          />
        </MenuItem>
        <MenuItem onClick={() => props.setBullet("number", "upper-roman")}>
          <NumberedList5
            id={"MenuItems"}
            active={
              props.objType && props.objType === "table"
                ? props.style &&
                  props.style.bullet &&
                  props.style.curType &&
                  props.style.curType === "upper-roman"
                : props.rowStyle &&
                  props.rowStyle.bullet &&
                  props.rowStyle.curType &&
                  props.rowStyle.curType === "upper-roman"
            }
          />
        </MenuItem>
        <MenuItem onClick={() => props.setBullet("number", "decimal-leading-zero")}>
          <NumberedList6
            id={"MenuItems"}
            active={
              props.objType && props.objType === "table"
                ? props.style &&
                  props.style.bullet &&
                  props.style.curType &&
                  props.style.curType === "decimal-leading-zero"
                : props.rowStyle &&
                  props.rowStyle.bullet &&
                  props.rowStyle.curType &&
                  props.rowStyle.curType === "decimal-leading-zero"
            }
          />
        </MenuItem>
      </>
    );
  };

  return <>{NumberingEdit()}</>;
}

export default NumberingEditComponent;
