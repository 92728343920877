import React, { Fragment, useState } from "react";
// import RightMenuPortal from "./RightMenuPortal.js";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ColorPicker from "../ChartTable/ColorPicker";
import InputBox from "../InputBoxComponent/InputBox";
import { FlipHorizontally, FlipVertically, Rotate90 } from "../IconButtons/SubMenuIcons";

function GapWidthComponent(props) {
  const [openGapWidth, setOpenGapWidth] = React.useState(false);

  const handleGapChange = (val) => {
    props.onPropsChange({ gapWidth: val });
  };

  const handleCategoryGapChange = (val) => {
    props.onPropsChange({ categoryGapWidth: val });
  };

  const handleClickGapWidth = () => {
    if (openGapWidth) {
      setOpenGapWidth(false);
    } else {
      setOpenGapWidth(true);
      props.rightMenuFunc.closeGapWidth = null;
      Object.values(props.rightMenuFunc).map((value) => {
        if (typeof value === "function") {
          value.call();
        }
      });
    }
  };

  const closeMenu = () => {
    setOpenGapWidth(false);
  };

  props.rightMenuFunc.closeGapWidth = closeMenu;

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      onKeyDown={(e) => {
        e.stopPropagation();
      }}
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
    >
      <ListItemButton id={"listItem"} onClick={handleClickGapWidth}>
        <ListItemText primary="Gap width" />
        {openGapWidth ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <div style={{ width: "230px", border: "1px solid #F0F1F7", marginLeft: "18px" }} />
      <Collapse in={openGapWidth} timeout="auto" unmountOnExit>
        <div className={"collapse"}>
          <div className={"listRow"}>
            <div className={"listRowElement"}>Column width</div>
          </div>
          <div className={"listRow"}>
            <div className={"listRowElement"}>
              <InputBox
                value={props.gapWidth}
                upLimit={10}
                downLimit={1}
                onUpClick={() => handleGapChange(props.gapWidth + 1)}
                onDownClick={() => handleGapChange(props.gapWidth - 1)}
              />
            </div>
          </div>

          {props.haveCategoryGapWidth && (
            <div className={"listRow"}>
              <div className={"listRowElement"}>Catgeory gap width</div>
            </div>
          )}
          {props.haveCategoryGapWidth && (
            <div className={"listRow"}>
              <div className={"listRowElement"}>
                <InputBox
                  value={props.categoryGapWidth}
                  upLimit={10}
                  downLimit={1}
                  onUpClick={() => handleCategoryGapChange(props.categoryGapWidth + 1)}
                  onDownClick={() => handleCategoryGapChange(props.categoryGapWidth - 1)}
                />
              </div>
            </div>
          )}
        </div>
      </Collapse>
    </div>
  );
}
export default GapWidthComponent;
