import React from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import ButtonMui from "../../../buttons/buttonMui/buttonMui";

export const WarningActions = ({ ...props }) => {
  return (
    <>
      <DialogContent sx={{ textAlign: "center" }}>{props.message}</DialogContent>
      <DialogActions disableSpacing={false} sx={{ justifyContent: "center" }}>
        <ButtonMui
          width={125}
          label="No"
          color="red"
          onClick={props.onCancel}
          variant={"contained"}
        />
        <ButtonMui width={125} label="Yes" onClick={props.onAccept} variant={"contained"} />
      </DialogActions>
    </>
  );
};
