import React from "react";
import "./MainTable.scss";
import Button from "../../../../../components/actions/Button";
import Tooltip from "@mui/material/Tooltip";
import {
  EditIcon,
  CopyIcon,
  DeleteIcon,
  HideIcon,
  MoveIcon,
  Tick,
} from "../../../../../SlideEditor/components/components/menus/LeftMenu/components/Icons/LeftMenuIcons";
import ButtonMui from "../../../../../components/buttons/buttonMui/buttonMui";
import { IconPlus, IconX } from "../../../../../components/icons/svgIcons";
import CreateManagementReportDialog from "./CreateManagementReportDialog";
import { parseDate } from "../../../ProfileSettings/AccountDetails";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import Input from "../../../../../components/actions/Input";

class MainTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getHoursDifference = (date1, date2) =>
    Math.round((new Date(date1) - new Date(date2)) / 1000 / 60 / 60);

  getUpdatedAt = (UpdatedAt, date) => {
    const difference = this.getHoursDifference(date, UpdatedAt);
    if (difference >= 12) {
      return parseDate(UpdatedAt);
    }
    return `Last edit: ${difference} hours ago`;
  };

  handleReportEditMode = (ID) => {
    this.props.management_reports.forEach((r) => {
      if (r.ID != ID) {
        r.toggleEditMode && r.toggleEditMode();
      }
    });
  };

  render() {
    return (
      <div className={"main_table_container_mr"}>
        <div className={"mtc_header_container"}>
          <div className={"mtc_hc_left"}>
            <p className={"mtc_hc_left_heading"}>{this.props.selectedFolder.Name}</p>

            {this.state.openCreateDialog && (
              <CreateManagementReportDialog
                Folders={this.props.Folders}
                open={this.state.openCreateDialog}
                onAdd={this.props.onAdd}
                selectedFolder={this.props.selectedFolder}
                onClose={() => this.setState({ openCreateDialog: false })}
                customDashboards={this.props.customDashboards}
                keyField={this.props.keyField}
              />
            )}
          </div>
          <div className={"mtc_hc_right"}>
            <ButtonMui
              label={"Create Report"}
              variant={"contained"}
              onClick={() => {
                this.props.customDashboards.length
                  ? this.setState({ openCreateDialog: true })
                  : this.props.openDialog();
              }}
              endIcon={<IconPlus />}
            />
          </div>
        </div>
        <div className={"mtc_table_container"}>
          <Table stickyHeader className={"mtc_tc_table"}>
            <TableHead>
              <TableRow>
                <TableCell>Report name</TableCell>
                <TableCell>Last Modified</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.management_reports &&
                this.props.management_reports.map((c, index) => {
                  return (
                    <ReportTableRow
                      handleReportEditMode={this.handleReportEditMode}
                      report={c}
                      index={index}
                      deleteReport={this.props.deleteReport}
                      onSelect={this.props.onSelect}
                      getUpdatedAt={this.getUpdatedAt}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }
}

class ReportTableRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      Name: this.props.report.Name,
    };
    this.props.report.toggleEditMode = () => this.toggleEditMode(false, true);
  }

  handleOnChange = (e) => {
    this.setState({ Name: e.target.value });
  };

  toggleEditMode = (editMode = !this.state.editMode, isFromOutside = false) => {
    if (editMode && !isFromOutside) {
      this.props.handleReportEditMode(this.props.report.ID);
    }
    this.setState({ editMode, Name: this.props.report.Name });
  };

  saveName = () => {
    this.props.report.Name = this.state.Name;
    this.props.report.Save && this.props.report.Save();
    this.toggleEditMode();
  };

  nameComponent = () => {
    if (this.state.editMode) {
      return (
        <div className={"flex"}>
          <Input value={this.state.Name} onChange={this.handleOnChange} />
          <ButtonMui
            label={<Tick />}
            variant={"text"}
            style={{ height: "28px", padding: "2px" }}
            width={28}
            onClick={this.saveName}
            isIconButton
            tooltipText={"Edit"}
          />
          <ButtonMui
            label={<IconX />}
            variant={"text"}
            style={{ height: "28px", padding: "2px" }}
            color={"red"}
            width={28}
            onClick={() => this.toggleEditMode()}
            isIconButton
            tooltipText={"Edit"}
          />
        </div>
      );
    }
    return this.props.report.Name;
  };

  getActions = () => {
    if (this.state.editMode) {
      return null;
    }
    return (
      <>
        <ButtonMui
          label={<EditIcon />}
          variant={"text"}
          style={{ height: "24px", padding: 0 }}
          width={24}
          onClick={() => this.toggleEditMode()}
          isIconButton
          tooltipText={"Edit"}
        />
        {/*<ButtonMui label={<CopyIcon/>} variant={'text'}*/}
        {/*           style={{height: '24px', padding: 0}}*/}
        {/*           width={24} onClick={this.props.handleDuplicate} isIconButton*/}
        {/*           tooltipText={'Duplicate'}/>*/}
        <ButtonMui
          label={<DeleteIcon />}
          color={"red"}
          variant={"text"}
          style={{ height: "24px", padding: 0 }}
          width={24}
          onClick={(e) => this.props.deleteReport(e, this.props.report, this.props.index)}
          isIconButton
          tooltipText={"Delete"}
        />
      </>
    );
  };

  render() {
    return (
      <TableRow>
        <TableCell
          onClick={() => {
            !this.state.editMode && this.props.onSelect(this.props.report);
          }}
          className={"mtc_tc_table_name"}
        >
          {this.nameComponent()}
        </TableCell>
        <TableCell className={"mtc_tc_table_text"}>
          {this.props.getUpdatedAt(this.props.report.UpdatedAt, new Date())}
        </TableCell>
        <TableCell className={"mtc_tc_table_text"}>
          {parseDate(this.props.report.CreatedAt)}
        </TableCell>
        <TableCell className={"mtc_tc_table_text_actions"}>
          <div className={"actions_cont"}>{this.getActions()}</div>
        </TableCell>
      </TableRow>
    );
  }
}

export default MainTable;
