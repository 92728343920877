import React from "react";
import Dialog from "@mui/material/Dialog";
import "./CantShareDialog.scss";
import { Exit } from "../../../SlideEditor/components/components/IconButtons/SubMenuIcons";
import IconButton from "@mui/material/IconButton";
import Button from "../../actions/Button";
import { Navigate } from "react-router-dom";
import ButtonMui from "../../buttons/buttonMui/buttonMui";

class CantShareDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: true,
      link: null,
    };
  }

  handleClose = () => {
    this.props.handleClose();
  };

  render() {
    if (this.state.link) {
      return <Navigate to={this.state.link} />;
    }
    return (
      <React.Fragment>
        <Dialog
          fullWidth={true}
          maxWidth={"lg"}
          open={this.props.open ? this.props.open : false}
          className={"cant_share_dialog"}
          onClose={this.handleClose}
        >
          <IconButton onClick={this.handleClose} className={"cld_close_button"}>
            <Exit />
          </IconButton>

          <div className={"p1_strong cld_heading"}>
            {`We are sorry, but there are no visible ${this.props.businessPlan ? "sections" : "slides"} to ${this.props.functionality ? this.props.functionality : "share"}`}
          </div>

          <div className={"cld_typography"}>
            <p>{`Please unhide at least 1 ${this.props.businessPlan ? "section" : "slide"} to use the ${this.props.functionality ? this.props.functionality : "share"} functionality.`}</p>
          </div>
          <div className={"cld_typography2_buttons"}>
            <ButtonMui
              label={"OK"}
              width={"100px"}
              padding={"0 0 0 0"}
              color={"primary"}
              variant={"contained"}
              onClick={this.handleClose}
            />
          </div>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default CantShareDialog;
