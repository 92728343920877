import React, { Component } from "react";
import { Menu, MenuItem } from "@szhsin/react-menu";
import Button from "@mui/material/Button";
import { ArrowRight, SmallPlusIcon, TrashCan } from "../../../components/icons/svgIcons";
import IconButton from "@mui/material/IconButton";
import {
  EditIcon,
  HideIcon,
  UnhideIcon,
} from "../../components/components/menus/LeftMenu/components/Icons/LeftMenuIcons";
import "./InudstriesMenu.scss";
import AddNewIndustryDialog from "./AddNewIndustryDialog";
import EditIndustryDialog from "./EditIndustryDialog";

class IndustriesMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openCreateIndustry: false,
    };
  }

  render() {
    return (
      <div>
        <Menu
          className={"categories_menu pitch_scenario_menu"}
          direction={"right"}
          menuButton={
            <Button
              variant="text"
              className="section_name button"
              endIcon={<ArrowRight color={"#000"} />}
              disableElevation
            >
              {this.props.selectedIndustry.Name}
            </Button>
          }
          transition
        >
          {this.props.Industries &&
            this.props.Industries.map((industry, index) => {
              return (
                <MenuItem
                  key={"left_menu_scenarios_" + index}
                  className={this.props.selectedIndustry.ID === industry.ID ? "selected" : ""}
                  onClick={() => {
                    this.props.onIndustryChange(industry.ID);
                  }}
                >
                  {industry.Name}
                  {industry.ID !== null ? (
                    <span>
                      <IconButton
                        className={"edit_icon"}
                        onClick={(e) => {
                          e.stopPropagation();
                          this.selectedIndustryName = industry.Name;
                          this.setState({
                            openEditIndustry: true,
                            selectedIndustryID: industry.ID,
                          });
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        className={"edit_icon"}
                        onClick={(e) => {
                          e.stopPropagation();
                          this.props.updateIndustryVisibility(industry.ID);
                        }}
                      >
                        {industry.Hidden ? <UnhideIcon /> : <HideIcon />}
                      </IconButton>
                      <IconButton
                        className={"trash_can"}
                        onClick={(e) => {
                          e.stopPropagation();
                          this.props.deleteIndustry(industry.ID);
                        }}
                      >
                        <TrashCan />
                      </IconButton>
                    </span>
                  ) : (
                    ""
                  )}
                </MenuItem>
              );
            })}
          <MenuItem
            onClick={() => {
              this.setState({ openCreateIndustry: true });
            }}
            className={"add_btn"}
            style={{ fontWeight: 600 }}
          >
            Create New Industry
            <SmallPlusIcon />
          </MenuItem>
        </Menu>

        <AddNewIndustryDialog
          open={this.state.openCreateIndustry}
          closeDialog={() => {
            this.setState({ openCreateIndustry: false });
          }}
          addNewIndustry={this.props.addNewIndustry}
        />

        <EditIndustryDialog
          open={this.state.openEditIndustry}
          IndustryName={this.selectedIndustryName}
          closeDialog={() => {
            this.setState({ openEditIndustry: false });
          }}
          editIndustry={this.props.editIndustry}
          selectedIndustryID={this.state.selectedIndustryID}
        />
      </div>
    );
  }
}

export default IndustriesMenu;
