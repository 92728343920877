import React from "react";
import Checkbox from "../../../../../../../components/actions/Checkbox";
import { Paragraph } from "../../../../../../../components/typography/paragraph/Paragraph";

class SP_Signups_Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="psfrrc_input_types_div">
        <div className="psfrrc_input_types_content">
          <div className="psfrrc_input_types_pargraph">
            <Paragraph size="p2_strong" label="Breakdown per subscription plan" />
          </div>
          <div className="psfrrc_input_types_checkboxes_div">
            <Checkbox
              size="large"
              value="percentage"
              checked={this.props.checked}
              onChange={this.props.onChange}
              placeholder="Percentage"
            />

            {this.props.revenue &&
              (!this.props.revenue.Quantity.Formula ||
                this.props.revenue.Quantity.Formula.includes("quantity")) && (
                <Checkbox
                  size="large"
                  value="per-unit"
                  checked={!this.props.checked}
                  onChange={this.props.onChange}
                  placeholder="Per Unit"
                />
              )}
          </div>
        </div>
      </div>
    );
  }
}

export default SP_Signups_Component;
