import ReactDOM from "react-dom";

const PrintablePortal = ({ children, elemId = "printableContainer" }) => {
  const el = document.getElementById(elemId);
  if (el) {
    return ReactDOM.createPortal(children, el);
  } else {
    return null;
  }
  return ReactDOM.createPortal(children, el);
};

export default PrintablePortal;
