import React from 'react';
import {functions} from "../components/constants/functionNameMappings";
import SwitchAccountDialog from "../components/dialogs/SwitchAccount/SwitchAccountDialog";
import ConditionalWrapper from "../components/conditional_wrapper";
import ReferralTermsAndConditionsDialog from "../components/dialogs/ReferralTermsAndConditions";

function SwitchAccountDialogConditional(props) {
	return (
		<ConditionalWrapper function_name={functions.toggle_switch_acc_dialog}>
			<SwitchAccountDialog open disableClose/>
		</ConditionalWrapper>
	);
}

function TOSDialogConditional(props) {
	return (
		<ConditionalWrapper function_name={functions.toggle_referral_tos_dialog}>
			<ReferralTermsAndConditionsDialog/>
		</ConditionalWrapper>
	);
}

export {SwitchAccountDialogConditional, TOSDialogConditional};