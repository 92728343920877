import CalculatedDriver_Values from "./CalculatedDriver_Values";
import { PeriodTypes } from "../../dates";
import { DriverCategories, SpecialChar, UnitTypes, DriverPeriodTypes } from "./constants";
import datastructure from "../../datastructure.json";
import MxDataArray from "../../MxDataArray";
import MxIDHelper from "../../MxIDHelper";
import {ResellType} from "../Assets/constants";

const growth_props = {
    value_states: {
        before_growth: "before_growth",
        with_growth: "with_growth",
    },
    driver_states: {
        growth_active: "GrowthDriver",
        growth_inactive: "OldGrowthDriver",
    },
};

const calculated_driver_value = "value";

const single_value_props = {
    value_states: {
        before_single: "before_single",
        with_single: "with_single",
    },
    single_value_sufix: "_single_value",
    getDriverID: (driver) => `${driver.ID}_${single_value_props.single_value_sufix}`,
};

class CalculatedDriver {
    ID = null;
    DriverName = null;
    UnitType = null;
    DriverCategory = null;
    PeriodType = DriverPeriodTypes.Current;

    LastPeriodOnly = false;

    _id = null;

    get IsSimple() {
        return this.Formula == null;
    }

    Formula = null;
    ID_CompanyScenario = null;
    MissingDriverValues = [];
    RefErrDriverValues = false;

    IsNew = false;
    IsTemporary = false;
    IsExisting = false;
    IsFormulaEditable = true;

    Values = null;

    Ref_ID = null;
    Ref_Table = null;
    Ref_Field = null;

    db_record = null;

    get ID_f() {
        return "|" + this.ID + "|";
    }

    get Name() {
        if (
            this.DriverName &&
            this.DriverName.includes(`${SpecialChar.DriverNameESCChar}`) &&
            this.Ref_Field !== "total" &&
            this.Ref_Field !== "value"
        ) {
            return this.DriverName.substring(0, this.DriverName.indexOf(SpecialChar.DriverNameESCChar));
        } else {
            return this.DriverName;
        }
    }

    get hasChanged() {
        if (this.db_record == null) {
            return true;
        }

        if (this.db_record.DriverName != this.DriverName) {
            return true;
        }

        if (this.db_record.Formula !== this.Formula) {
            return true;
        }

        //todo: add all fields

        const changedValue = this.Values.find((c) => c.hasChanged);
        if (changedValue != null) {
            return true;
        }

        return false;
    }

    get isValid() {
        // return true;
        return this.Values.some(
            (c) =>
                c.Value !== null &&
                c.Value !== 0 &&
                c.Value !== "undefined" &&
                !isNaN(c.Value) &&
                Number.isFinite(c.Value),
        );
    }

    get isValidActual() {
        // return true;
        return this.Values.some(
            (c) =>
                c.Actual !== null &&
                c.Actual !== 0 &&
                c.Actual !== "undefined" &&
                !isNaN(c.Actual) &&
                Number.isFinite(c.Actual),
        );
    }

    constructor(db_record, Ref_Table = null, Ref_Field = null, Ref_ID = null) {
        if (db_record) {
            this.ID = db_record.ID;
            this.DriverCategory = db_record.DriverCategory;
            this.DriverName = db_record.DriverName;
            this.ID_CompanyScenario = db_record.ID_CompanyScenario;
            this.UnitType = db_record.UnitType;
            // this.IsSimple = db_record.IsSimple;
            this.Formula = db_record.Formula;
            this.ID_CompanyScenario = db_record.ID_CompanyScenario;
            this.Ref_ID = db_record.Ref_ID;
            this.Ref_Table = db_record.Ref_Table;
            this.Ref_Field = db_record.Ref_Field;
            this.PeriodType = db_record.PeriodType;
            this.IsTemporary = !!db_record.IsTemporary;
            this.IsNew = false;
            if (db_record.Ref_Field === calculated_driver_value) {
                this.IsExisting = true;
            }
            this._id = db_record._id;
            this.Values = MxDataArray.create();
            this.db_record = db_record;

            if (db_record.IsTemporary && !db_record._id) {
                Object.assign(this, db_record);
            }
        } else {
            this.ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
            this.IsNew = true;
        }
    }

    cleanDriver = () => {
        if (this.db_record) {
            this.cur_record = this.db_record;
            this.DriverCategory = this.db_record.DriverCategory;
            this.DriverName = this.db_record.DriverName;
            // this.ID_CompanyScenario = this.db_record.ID_CompanyScenario;
            this.UnitType = this.db_record.UnitType;
            // // this.IsSimple = db_record.IsSimple;
            this.Formula = this.db_record.Formula;
            // this.ID_CompanyScenario = this.db_record.ID_CompanyScenario;
            // this.Ref_ID = this.db_record.Ref_ID;
            // this.Ref_Table = this.db_record.Ref_Table;
            // this.Ref_Field = this.db_record.Ref_Field;
            // this.IsNew = false;
            this.Values.forEach((value) => value.cleanValue());

            // this.Values = MxDataArray.create();
        }
    };

    cloneDriver = (ID = MxIDHelper.newID(), callBack) => {
        const clonedDriver = CalculatedDriver.createDriver(
            this.Ref_Table,
            ID,
            this.Ref_Field,
            this.UnitType,
            this.DriverCategory,
            this.DriverName,
        );
        clonedDriver.ID = this.ID.replace(this.Ref_ID, ID);
        clonedDriver.Ref_ID = ID;
        clonedDriver.ID_CompanyScenario = this.ID_CompanyScenario;
        if (this.Formula) {
            clonedDriver.Formula = this.Formula.replace(this.Ref_ID, ID);
        }
        clonedDriver.Values.forEach((value) => {
            let DateValueItem = this.getItemByDateSufix(value.Date.sufix);
            value.Value = DateValueItem.Value;
            if (DateValueItem.Formula) {
                value.Formula = DateValueItem.Formula.replace(this.Ref_ID, ID);
            }
        });

        return clonedDriver;
    };

    setPreviousPeriodFormula = (dates, drivers) => {
        // console.log('this prev formula',this )
        // global.Modeliks.DateHelper.years_before.forEach(y => {
        //     const year = this.getItemByDateSufix(y.sufix);
        //     year.Formula = null
        // });

        dates.forEach((m) => {
            const value = this.getItemByDateSufix(m.sufix);
            if (!value) {
                console.log(this, m);
            } else {
                if (m.dateID === global.Modeliks.DateHelper.months[0].dateID) {
                    value.Value = null;
                    value.Formula = null;
                } else {
                    const prevDate = [...dates, ...global.Modeliks.DateHelper.years_before].find(
                        (d) => d.Order === m.Order - 1 && d.PeriodType === m.PeriodType,
                    );
                    value.Value = null;
                    let tmpFormula = this.Formula;
                    drivers.forEach((d) => (tmpFormula = tmpFormula.replaceAll(d, d + "_" + prevDate.sufix)));
                    value.Formula = tmpFormula;
                }
            }
        });
    };

    setFormula = (
        formula,
        useAllMonths = false,
        shouldApplyToInactive = true,
        setFormulaActual = false,
    ) => {
        this.Formula = formula;
        const drivers = [
            ...new Set(this.Formula.split("|").filter((c) => c.length > 10 && c.indexOf("Math") == -1)),
        ];
        // this.IsSimple = false;
        const allYearsMonths = global.Modeliks.DateHelper.all_periods;
        const regularMonths = global.Modeliks.DateHelper.all;
        const Months = useAllMonths ? allYearsMonths : regularMonths;
        if (this.PeriodType === DriverPeriodTypes.Previous) {
            this.setPreviousPeriodFormula(Months, drivers);
        } else {
            Months.forEach((m) => {
                const value = this.getItemByDateSufix(m.sufix);
                if (!value) {
                    console.log(this, m);
                } else {
                    value.Value = null;
                    let tmpFormula = this.Formula;
                    drivers.forEach((d) => (tmpFormula = tmpFormula.replaceAll(d, d + "_" + m.sufix)));
                    value.Formula = tmpFormula;
                }
            });
        }

        if (this.IsTemporary || setFormulaActual) {
            this.setFormulaActual(drivers);
        }
    };

    setFormulaActual = (drivers) => {
        const editableDriversIDs = [
            "interest_expense_driver",
            "depreciation_driver",
            "sale_of_property_and_equipment_driver",
            "prepaid-revenue-closing-balance",
        ];

        const allYearsMonths = global.Modeliks.DateHelper.months_before_actual.concat(
            ...global.Modeliks.DateHelper.years_before_actual,
            ...global.Modeliks.DateHelper.months,
            ...global.Modeliks.DateHelper.years_all,
        );
        // if(this.Ref_Table !== global.Modeliks.Tables.Dashboards_CustomKPI.TableName){
        //     allYearsMonths.concat(global.Modeliks.DateHelper.years_before)
        // }

        allYearsMonths.forEach((m) => {
            const value = this.getItemByDateSufix(m.sufix);

            if (!value) {
                console.log("no value", this, m);
                return;
            }
            value.Actual = null;

            if (editableDriversIDs.includes(this.Ref_ID)) {
                value.Formula = null;
            } else {
                let tmpFormula = this.Formula;
                drivers.forEach((d) => (tmpFormula = tmpFormula.replaceAll(d, d + "_" + m.sufix)));
                const actualFormula = `Actual(${tmpFormula.replaceAll("|", ";")} )`;
                if (value.Formula && value.Formula.includes("Actual(")) {
                    value.Formula = `${value.Formula.substring(0, value.Formula.indexOf("Actual("))} ${actualFormula}`;
                } else {
                    value.Formula = `${value.Formula} ${actualFormula}`;
                }
                // if(!m.Active && m.PeriodType === PeriodTypes.year){
                //     let tmpFormula = this.Formula;
                //     drivers.forEach(d => tmpFormula = tmpFormula.replaceAll(d, d + '_' + m.sufix))
                //     const actualFormula = `Actual(${tmpFormula.replaceAll('|', ';')} )`;
                //     if(value.Formula && value.Formula.includes('Actual(')){
                //         value.Formula = `${value.Formula.substring(0,value.Formula.indexOf('Actual('))} ${actualFormula}`
                //     }else{
                //         value.Formula = `${value.Formula} ${actualFormula}`
                //     }
                //
                // }else {
                //     let tmpFormula = this.Formula;
                //     drivers.forEach(d => tmpFormula = tmpFormula.replaceAll(d, d + '_' + m.sufix))
                //     value.Formula = tmpFormula;
                // }

                // value.buildFormula();
            }
        });
    };

    getChildDrivers = () => {
        if (this.IsSimple) {
            return null;
        }

        const driverIDs = this.Formula.split("|").filter(
            (c) => c.length > 10 && c.indexOf("Math") === -1,
        );
        const drivers = driverIDs.map((c) => global.Modeliks.DriversStore.getItem(c));
        return drivers;
    };

    form = (f, f2) => {
        //todo check valid formula
        // this.Formula = f;
        const years = global.Modeliks.DateHelper.years_all;
        let deepLevel = 0;
        let deepStartingIndexes = [];
        let capsuleCalculation = false;
        let capsuleFormulas = [];

        for (let atIndex = 0; atIndex < f.length; atIndex++) {
            const curFormulaIndex = f[atIndex];

            if (curFormulaIndex.DriverName === "(") {
                deepStartingIndexes.push(atIndex);
                deepLevel++;
            }

            if (curFormulaIndex.DriverName === ")") {
                capsuleCalculation = true;
                const defineCalculation = [];
                const deepStart = deepStartingIndexes.pop();

                f.filter((item, index) => {
                    if (index < atIndex && index > deepStart) {
                        defineCalculation.push(item);
                    }
                });

                const capsuleDriver = CalculatedDriver.createDriver(
                    "Finance_CalculatedDrivers",
                    `${new Date().getTime()}`,
                    "value",
                    UnitTypes.Price,
                    DriverCategories.Average,
                    "Capsule Driver",
                );

                let Formula = [];

                //todo set to work from formula bar arr
                defineCalculation.map((f) => {
                    if (f.DriverName.length > 3) {
                        Formula += f.DriverName;
                    } else {
                        Formula += f.DriverName;
                    }
                });

                capsuleDriver.setFormula(Formula);

                capsuleFormulas.push(capsuleDriver);
            }

            if (f[atIndex].DriverName === "*" || f[atIndex].DriverName === "/") {
                if (capsuleCalculation) {
                    years.forEach((year) => {
                        if (!year.Active) {
                            const nextDriver =
                                global.Modeliks.DriversStore._indexes[f[atIndex + 1].DriverName.slice(1, -1)];
                            const months = year.months;
                            //todo change to work with bar
                            const curYear = nextDriver.getItemByDateSufix(year.sufix);
                            console.log("curYear", curYear, capsuleFormulas);

                            const monthsFormula = `MxMath.Sum([${months.map((m) => `(${nextDriver.getItemByDateSufix(m.sufix)} * ${capsuleFormulas[capsuleFormulas.length - 1].getItemByDateSufix(m.sufix)})`).join(",")}])`;
                            const fullFormula = `MxMath.Sum([${months.map((m) => `${capsuleFormulas[capsuleFormulas.length - 1].getItemByDateSufix(m.sufix)}`).join(",")}])`;
                            curYear.cur_record.Formula = `(${monthsFormula}) / ${fullFormula}`;
                        }
                    });
                }
                capsuleCalculation = false;
            }
        }
    };

    removeFormula = () => {
        this.Formula = null;
        this.Values.forEach((v) => {
            if (
                v.Date.PeriodType === PeriodTypes.month ||
                (v.Date.PeriodType === PeriodTypes.year && v.Date.Active)
            ) {
                v.Value = null;
                v.Formula = this.Formula;
                v.evalFormula = null;
            }
        });
    };

    setFormula_Sum = (drivers) => {
        const formula = drivers.map((c) => c.ID_f).join("+");
        this.setFormula(formula);
    };

    changeID = (newID) => {
        this.Ref_ID = newID;
        const oldID = this.ID;
        // console.log("changing ID", newID, oldID);
        this.ID = this.getTableRefID();
        global.Modeliks.DriversStore.updateID(this.ID, oldID);
        this.Values.forEach((val) => val.changeID(this.ID, oldID));
    };

    getItemByDateIndex = (dateIndex) => {
        this.getItemByDateSufix(global.Modeliks.DateHelper.all_periods.getItem(dateIndex).sufix);
    };

    getItemByDateSufix = (sufix) => {
        return this.Values.getItem(this.ID + "_" + sufix);
    };

    getTableRefID = () => {
        return CalculatedDriver.getTableRefID(this.Ref_Table, this.Ref_ID, this.Ref_Field);
    };

    Save = (callBack) => {
        if (this.IsNew) {
            global.Modeliks.post(this.constructor.TableName, this, (res) => {
                global.Modeliks.post(
                    global.Modeliks.Tables.Finance_CalculatedDriver_Values.TableName,
                    this.Values.map((c) => c.cur_record),
                    (res) => {
                        if (callBack) {
                            callBack();
                        }
                    },
                );
            });
        } else {
            global.Modeliks.put(this.constructor.TableName, null, this, (res) => {
                const changes = this.Values.map((c) => c.GetChanges()).filter((c) => c != null);
                if (changes && changes.length > 0) {
                    global.Modeliks.put(
                        global.Modeliks.Tables.Finance_CalculatedDriver_Values.TableName,
                        null,
                        changes,
                        (res) => {
                            if (callBack) {
                                callBack();
                            }
                        },
                    );
                } else {
                    if (callBack) {
                        setTimeout(callBack, 100);
                    }
                }
            });
        }
    };

    addMonths = (monthsBefore = 0, monthsAfter = false, UnitType = false) => {
        if (monthsBefore) {
            const monthsBeforeArr = CalculatedDriver_Values.create_Months_Before(
                this.ID,
                this.UnitType,
                monthsBefore,
            );
            monthsBeforeArr.forEach((c) => c.validateThis(c));
            this.Values.pushArray(monthsBeforeArr);
            this.Values.forEach((c) => c.validateThis(c));
        }
        if (monthsAfter) {
            const monthsAfterArr = CalculatedDriver_Values.create_Months_After(this.ID, this.UnitType);
            this.Values.pushArray(monthsAfterArr);
        }

        this.Values.sort(CalculatedDriver_Values.sortValuesFunc);
    };

    changeApply = (percent = 0) => {
        this.Values.forEach((driverValue) => {
            driverValue.Value = Math.round(driverValue.Value * percent);
        });
    };

    changeGrowthDriverStartingDate = (Value) => {
        if (!Value) {
            return;
        }

        const growthDriverDates = this.getGrowthDriverDates();

        growthDriverDates.forEach((growth) => {
            const curDriver = this.getItemByDateSufix(growth.sufix);
            const curGrowthDriver = this.GrowthDriver.getItemByDateSufix(growth.sufix);
            const prevDriver =
                growth.Order > 0
                    ? this.Values.find(
                    (c) => c.PeriodType == growth.PeriodType && c.Order == growth.Order - 1,
                    )
                    : null;

            if (
                curGrowthDriver.Order === Value.Order &&
                curGrowthDriver.PeriodType === Value.PeriodType
            ) {
                curDriver.Formula = `${curGrowthDriver.ID_f}`;
                curGrowthDriver.Unit = UnitTypes.GrowthValue;
                curGrowthDriver.Value = 0;
            } else {
                if (Value.PeriodType === PeriodTypes.year) {
                    if (
                        curGrowthDriver.PeriodType !== PeriodTypes.year ||
                        (curGrowthDriver.Order < Value.Order && curGrowthDriver.PeriodType === Value.PeriodType)
                    ) {
                        curGrowthDriver.Unit = UnitTypes.Hide;
                        curGrowthDriver.Value = 0;
                    } else {
                        if (
                            curGrowthDriver.Unit !== UnitTypes.Percentage &&
                            curGrowthDriver.PeriodType === Value.PeriodType
                        ) {
                            curGrowthDriver.Unit = UnitTypes.Percentage;
                            curGrowthDriver.Value = 0;
                        }

                        if (prevDriver) {
                            const value = (curDriver.Value / prevDriver.Value - 1) * 100;
                            if (Number.isNaN(value)) {
                                curGrowthDriver.Value = null;
                            } else {
                                curGrowthDriver.Value = value;
                            }
                            curDriver.Formula = `${prevDriver.ID_f}*(1+${curGrowthDriver.ID_f})`;

                            if (curDriver.Unit === UnitTypes.Percentage) {
                                curDriver.Formula = `(${prevDriver.ID_f}*(1+${curGrowthDriver.ID_f})) * 100`;
                            }
                        }
                    }
                } else {
                    if (
                        curGrowthDriver.Order < Value.Order &&
                        curGrowthDriver.PeriodType === Value.PeriodType
                    ) {
                        curGrowthDriver.Unit = UnitTypes.Hide;
                        curGrowthDriver.Value = 0;
                    } else {
                        if (curGrowthDriver.PeriodType !== Value.PeriodType) {
                            curGrowthDriver.Unit = UnitTypes.Percentage;
                            curGrowthDriver.Value = 0;
                        }

                        // if(curGrowthDriver.PeriodType !== Value.PeriodType){
                        //     console.log('t')
                        //     // curGrowthDriver.Unit = UnitTypes.Percentage;
                        //     // curGrowthDriver.Value = 0;
                        // }

                        if (
                            curGrowthDriver.Unit !== UnitTypes.Percentage &&
                            curGrowthDriver.PeriodType === Value.PeriodType
                        ) {
                            curGrowthDriver.Unit = UnitTypes.Percentage;
                            curGrowthDriver.Value = 0;
                        }
                        if (prevDriver) {
                            const value = (curDriver.Value / prevDriver.Value - 1) * 100;
                            if (Number.isNaN(value)) {
                                curGrowthDriver.Value = null;
                            } else {
                                curGrowthDriver.Value = value;
                            }
                            curDriver.Formula = `${prevDriver.ID_f}*(1+${curGrowthDriver.ID_f})`;

                            if (curDriver.Unit === UnitTypes.Percentage) {
                                curDriver.Formula = `(${prevDriver.ID_f}*(1+${curGrowthDriver.ID_f})) * 100`;
                            }
                        }
                    }
                }
            }
        });

        return this.GrowthDriver;
    };

    getGrowthDriverDates = () => {
        return [...global.Modeliks.DateHelper.months, ...global.Modeliks.DateHelper.years];
    };

    convertToGrowthDriver = () => {
        if (this.hasOwnProperty(growth_props.driver_states.growth_active) == false) {
            if (this.hasOwnProperty(growth_props.driver_states.growth_inactive)) {
                this.GrowthDriver = this[growth_props.driver_states.growth_inactive];
            } else {
                this.GrowthDriver = CalculatedDriver.createDriver(
                    this.Ref_Table,
                    this.Ref_ID,
                    this.Ref_Field + "_growth",
                    UnitTypes.Percentage,
                    DriverCategories.Average,
                    "Growth",
                    false,
                    false,
                    true,
                );
                this.GrowthDriver.getItemByDateSufix(global.Modeliks.DateHelper.years_all[0].sufix).Unit =
                    UnitTypes.Hide;
            }

            const growthDriverDates = this.getGrowthDriverDates();

            growthDriverDates.forEach((growth) => {
                const curGrowthDriver = this.GrowthDriver.getItemByDateSufix(growth.sufix);
                const curDriver = this.getItemByDateSufix(growth.sufix);
                curDriver.SaveState(growth_props.value_states.before_growth);

                if (curDriver.StateExists(growth_props.value_states.with_growth)) {
                    curDriver.PopState(growth_props.value_states.with_growth);
                    curDriver.buildFormula();
                } else {
                    if (curGrowthDriver.IsSimple) {
                        const prevDriver =
                            growth.Order > 0
                                ? this.Values.find(
                                (c) => c.PeriodType == growth.PeriodType && c.Order == growth.Order - 1,
                                )
                                : null;
                        if (prevDriver) {
                            const value = (curDriver.Value / prevDriver.Value - 1) * 100;
                            if (Number.isNaN(value)) {
                                curGrowthDriver.Value = null;
                            } else {
                                curGrowthDriver.Value = value;
                            }
                            curDriver.Formula = `${prevDriver.ID_f}*(1+${curGrowthDriver.ID_f})`;

                            if (curDriver.Unit === UnitTypes.Percentage) {
                                curDriver.Formula = `(${prevDriver.ID_f}*(1+${curGrowthDriver.ID_f})) * 100`;
                            }
                        } else {
                            curGrowthDriver.Value = curDriver.Value;
                            curDriver.Formula = `${curGrowthDriver.ID_f}`;
                            // growth.Unit = curDriver.Unit;
                            curGrowthDriver.Unit = UnitTypes.GrowthValue;

                            // if (curDriver.Unit === UnitTypes.Percentage) {
                            //     curDriver.Formula = `${curGrowthDriver.ID_f} * 100`;
                            // }
                        }
                    } else {
                        curGrowthDriver.Formula = curDriver.ID_f;
                        curGrowthDriver.Unit = UnitTypes.Hide;
                    }
                }
            });

            this.Formula = this.GrowthDriver.ID_f;

            // this.IsSimple = false;
        }

        return this.GrowthDriver;
    };

    removeGrowth = () => {
        if (this.GrowthDriver) {
            const growthDriverDates = this.getGrowthDriverDates();
            growthDriverDates.forEach((driver) => {
                const curGrowthDriver = this.GrowthDriver.getItemByDateSufix(driver.sufix);
                const curDriver = this.getItemByDateSufix(driver.sufix);
                curDriver.SaveState(growth_props.value_states.with_growth);
                if (curDriver.StateExists(growth_props.value_states.before_growth)) {
                    curDriver.PopState(growth_props.value_states.before_growth);
                    curDriver.buildFormula();
                } else {
                    if (curDriver.Formula && curDriver.Formula.indexOf("_growth") > -1) {
                        const value = curDriver.Value;
                        curDriver.Formula = null;
                        curDriver.Value = value;
                    }
                }
            });
            this.Formula = null;
            this[growth_props.driver_states.growth_inactive] = this.GrowthDriver;
            delete this.GrowthDriver;
        }
    };

    resetSavedProps = () => {
        this.Values.forEach((driver) => {
            const curDriver = this.Values.find(
                (c) => c.PeriodType == driver.PeriodType && c.Order == driver.Order,
            );
            if (curDriver.StateExists(growth_props.value_states.with_growth)) {
                curDriver.RemoveState(growth_props.value_states.with_growth);
            } else if (curDriver.StateExists(growth_props.value_states.before_growth)) {
                curDriver.RemoveState(growth_props.value_states.before_growth);
            }
        });
    };

    toString = () => {
        return this.ID_f;
    };

    convertToSingleValue = (shouldSaveState = true) => {
        if (this.IsSimple == false) {
            return;
        }

        let singleDriverValue = this.getSingleDriverValue();
        if (singleDriverValue == null) {
            singleDriverValue = new CalculatedDriver_Values(
                null,
                single_value_props.getDriverID(this),
                this.ID,
                this.UnitType,
            );
            this.Values.push(singleDriverValue);
        } else {
            singleDriverValue.Delete = false;
        }

        let initValue = null;

        [...global.Modeliks.DateHelper.months, ...global.Modeliks.DateHelper.years_all].forEach((m) => {
            const driverValue = this.getItemByDateSufix(m.sufix);
            if (driverValue.Date && driverValue.Date.Active) {
                if (initValue == null) {
                    initValue = driverValue.Value;
                }
                if (shouldSaveState) {
                    driverValue.SaveState(single_value_props.value_states.before_single);
                }

                if (this.UnitType == UnitTypes.Percentage) {
                    driverValue.Formula = `${singleDriverValue} * 100`;
                } else {
                    driverValue.Formula = `${singleDriverValue}`;
                }
            }
        });

        if (singleDriverValue == null) {
            singleDriverValue.Value = initValue;
        }
        this.Formula = "";
    };

    removeSingleValue = () => {
        if (this.IsSimple) {
            return;
        }
        let singleDriverValue = this.getSingleDriverValue();

        this.Values.forEach((driverValue) => {
            if (driverValue.Date && driverValue.Date.Active) {
                driverValue.Formula = null;

                if (driverValue.StateExists(single_value_props.value_states.before_single)) {
                    driverValue.PopState(single_value_props.value_states.before_single);
                } else {
                    driverValue.Value = singleDriverValue ? singleDriverValue.Value : 0;
                }
            }
        });

        if (singleDriverValue) {
            singleDriverValue.Delete = true;
        }

        this.Formula = null;
    };

    getSingleDriverValue = () => {
        return this.Values.getItem(single_value_props.getDriverID(this));
    };
    isChecked = false;

    checkIfDriverIsUsed = (ID) => {
        //if same driver return TRUE
        if (this.ID == ID) {
            return true;
        }

        for (let i = 0; i < this.Values.length; i++) {
            if (
                this.Values[i].Formula &&
                this.Values[i].Formula.indexOf(
                    ID.includes(this.Values[i].Date.sufix) ? ID : `${ID}_${this.Values[i].Date.sufix}`,
                ) > -1
            ) {
                return true;
            }
        }

        const childDrivers =
            this.PeriodType === DriverPeriodTypes.Previous
                ? null
                : !this.isChecked && this.getChildDrivers();
        if (childDrivers) {
            this.isChecked = true;
            for (let i = 0; i < childDrivers.length; i++) {
                if (childDrivers[i] && childDrivers[i].checkIfDriverIsUsed(ID)) {
                    return true;
                }
            }
        }

        return false;
    };

    deleteTaxesDrivers = (callBack, Stream) => {
        const Tax = global.Modeliks.TaxesStore.getItem(this.Ref_ID);
        if(Tax){
            this.removeFormula();
            Tax.setTax();
            Tax.Save(() => {
                callBack && callBack();
            })
            // if (this.Ref_Field.includes('sales_tax')) {
            //
            //     if((Stream.hasOwnProperty('Totals') && Stream.Totals.Ref_Table === global.Modeliks.Tables.Finance_Assets.TableName)){
            //         if(Tax.TotalSalesIncludesID(Stream.AssetSales,'asset')){
            //             Tax.setTotalAccruedSalesFormula(Stream.AssetSales, 'asset');
            //             hasChange = true;
            //         }
            //     }else {
            //         Tax.setTotalAccruedSalesFormula(Stream.hasOwnProperty('Totals') ? Stream.Totals : Stream);
            //         hasChange = true;
            //     }
            //
            //     if(Tax){
            //         console.log('stream deleteTaxesDrivers', Stream)
            //     }

            // } else {
            //     if(this.Ref_ID === 'income_tax_rate_driver'){
            //         this.removeFormula();
            //         hasChange = true;
            //     }else {
            //         if (Stream.hasOwnProperty('RevenueType') || (Stream.hasOwnProperty('Ref_Table') && Stream.Ref_Table === global.Modeliks.Tables.Finance_Revenues.TableName)) {
            //             Tax.setTotalAccruedSalesFormula(Stream.hasOwnProperty('Totals') ? Stream.Totals : Stream);
            //             hasChange = true;
            //         }else if((Stream.hasOwnProperty('Totals') && Stream.Totals.Ref_Table === global.Modeliks.Tables.Finance_Assets.TableName)){
            //             if(Stream.ResellType === ResellType.Yes && Tax.TotalSalesIncludesAssetResellID(Stream.AssetSales)){
            //                 Tax.setTotalAccruedSalesFormulaAssetResell(Stream.AssetSales);
            //                 hasChange = true;
            //             }
            //             if(Tax.TotalSalesIncludesAssetID(Stream.AssetPurchases)){
            //                 Tax.setTotalAccruedSalesFormulaAsset(Stream.AssetPurchases);
            //                 hasChange = true;
            //             }
            //         }
            //         else {
            //             Tax.setTotalAccruedSalesFormulaCost(Stream.hasOwnProperty('Totals') ? Stream.Totals : Stream);
            //             hasChange = true;
            //         }
            //     }
            // }

        }else {
            callBack && callBack();
        }
    };

    Delete = (callBack, Stream = null) => {
        if (this.Ref_Table === global.Modeliks.Tables.Finance_Taxes.TableName) {
            this.deleteTaxesDrivers(() => {
                if (callBack) {
                    callBack()
                }
            }, Stream)

        } else {
            global.Modeliks.del(CalculatedDriver.TableName, {
                ID: this.ID,
                Ref_Table: this.Ref_Table
            }, (res) => {
                global.Modeliks.del(global.Modeliks.Tables.Finance_CalculatedDriver_Values.TableName, {ID_Driver: this.ID,ID_CompanyScenario: global.Modeliks.CompanyScenarioInfo.ID}, (res) => {
                    if(callBack){
                        callBack();
                    }
                });

            });
        }

    }

    checkDriverToDelete = (arr = [], stop = false) => {
        const DriverStore = global.Modeliks.DriversStore;
        const CostStore = global.Modeliks.CostSaleStore;
        for (let i = 0; i < DriverStore.length; i++) {
            if (!DriverStore[i].IsTemporary && DriverStore[i].Formula) {
                if (DriverStore[i].Formula.indexOf(this.Ref_Table + "-" + this.Ref_ID) > -1) {
                    if (
                        this.Ref_ID !== DriverStore[i].Ref_ID ||
                        this.Ref_Table !== DriverStore[i].Ref_Table
                    ) {
                        if (arr.indexOf(DriverStore[i]) === -1 && DriverStore[i].Ref_Field !== "quantity") {
                            arr.push(DriverStore[i]);
                            if (!stop) {
                                DriverStore[i].checkDriverToDelete(
                                    arr,
                                    DriverStore[i].PeriodType === DriverPeriodTypes.Previous,
                                );
                            }
                        }
                    }
                }
            }
        }

        if (this.Ref_Table === global.Modeliks.Tables.Finance_Revenues.TableName) {
            CostStore.filter((d) => d.ID_Revenue).forEach((d) => {
                if (parseInt(d.ID_Revenue) === this.Ref_ID) {
                    arr.push(d.Totals);
                }
            });
        }
    };
    getDriversToDelete = () => {
        let DriverCanDeleteArr = [];
        this.checkDriverToDelete(DriverCanDeleteArr);
        return DriverCanDeleteArr;
    };

    getParentStream = () => {
        switch (this.Ref_Table) {
            case global.Modeliks.Tables.Finance_Revenues.TableName:
                return global.Modeliks.RevenuesStore.getItem(this.Ref_ID);
            case global.Modeliks.Tables.Finance_CostSales.TableName:
                return global.Modeliks.CostSaleStore.getItem(this.Ref_ID);
            case global.Modeliks.Tables.Finance_Personnel.TableName:
                return global.Modeliks.PersonnelStore.getItem(this.Ref_ID);
            case global.Modeliks.Tables.Finance_Expenses.TableName:
                return global.Modeliks.ExpensesStore.getItem(this.Ref_ID);
            case global.Modeliks.Tables.Finance_Assets.TableName:
                return global.Modeliks.AssetsStore.getItem(this.Ref_ID);
            case global.Modeliks.Tables.Finance_Financing.TableName:
                return global.Modeliks.FinancingStore.getItem(this.Ref_ID);
            // case global.Modeliks.Tables.Finance_Taxes.TableName:
            //     return global.Modeliks.TaxesStore.getItem(driver.Ref_ID);
            default:
                return null;
        }
    };

    static getTableRefID = (tableName, ID, fieldName) => {
        return `${tableName}-${ID}-${fieldName}`;
    };

    static TableName = datastructure.Finance_CalculatedDrivers.TableName;

    static createDriver = (
        Ref_Table = "TempDriver",
        Ref_ID = null,
        Ref_Field = "tmp_field",
        unitType = UnitTypes.Price,
        category = DriverCategories.Sum,
        DriverName = null,
        IsTemporary = false,
        IsExisting = false,
        IsFormulaEditable = true,
    ) => {
        const newDriver = new CalculatedDriver(null, Ref_Table, Ref_Field, Ref_ID);

        newDriver.Ref_Table = Ref_Table;
        newDriver.Ref_Field = Ref_Field;
        newDriver.Ref_ID = Ref_ID != null ? Ref_ID : "new_" + (new Date().getTime() - 1654300000000);

        newDriver.IsNew = true;
        newDriver.IsTemporary = IsTemporary;
        newDriver.ID = newDriver.getTableRefID();
        newDriver.DriverCategory = category;
        newDriver.UnitType = unitType;
        newDriver.DriverName = DriverName;
        newDriver.IsExisting = IsExisting;
        newDriver.IsFormulaEditable = IsFormulaEditable;
        newDriver.Values = CalculatedDriver_Values.create_Periods_Active(
            newDriver.ID,
            newDriver.UnitType,
        );

        global.Modeliks.DateHelper.years_before.forEach((y) => {
            const year = newDriver.getItemByDateSufix(y.sufix);
            const months = y.months.map((m) => newDriver.getItemByDateSufix(m.sufix));
            const monthsArr = months.map((c) => c.ID_f).join(",");

            if (unitType === UnitTypes.Percentage) {
                if (category === DriverCategories.Average) {
                    year.Formula = `MxMath.Average([${monthsArr}]) * 100`;
                } else if (category === DriverCategories.FirstPeriod) {
                    year.Formula = `${newDriver.getItemByDateSufix(y.months[0].sufix)} * 100`;
                } else if (category === DriverCategories.LastPeriod) {
                    year.Formula = `${newDriver.getItemByDateSufix(y.months[y.months.length - 1].sufix)} * 100`;
                } else {
                    year.Formula = months.map((c) => c.ID_f + `* 100`).join("+");
                }
            } else {
                if (category === DriverCategories.Average) {
                    year.Formula = `MxMath.Average([${monthsArr}])`;
                } else if (category === DriverCategories.FirstPeriod) {
                    year.Formula = `${newDriver.getItemByDateSufix(y.months[0].sufix)}`;
                } else if (category === DriverCategories.LastPeriod) {
                    year.Formula = `${newDriver.getItemByDateSufix(y.months[y.months.length - 1].sufix)}`;
                } else {
                    year.Formula = `MxMath.Sum([${monthsArr}])`;
                }
            }
        });

        global.Modeliks.DriversStore.push(newDriver);
        return newDriver;
    };

    changeDriverTypeAndCategory = (unitType, category) => {
        this.DriverCategory = category;
        this.UnitType = unitType;
        this.Values.forEach((v) => (v.Unit = unitType));

        global.Modeliks.DateHelper.years_before.forEach((y) => {
            const year = this.getItemByDateSufix(y.sufix);
            const months = y.months.map((m) => this.getItemByDateSufix(m.sufix));
            const monthsArr = months.map((c) => c.ID_f).join(",");

            if (unitType === UnitTypes.Percentage) {
                if (category === DriverCategories.Average) {
                    year.Formula = `MxMath.Average([${monthsArr}]) * 100`;
                } else if (category === DriverCategories.FirstPeriod) {
                    year.Formula = `${this.getItemByDateSufix(y.months[0].sufix)} * 100`;
                } else if (category === DriverCategories.LastPeriod) {
                    year.Formula = `${this.getItemByDateSufix(y.months[y.months.length - 1].sufix)} * 100`;
                } else {
                    year.Formula = months.map((c) => c.ID_f + `* 100`).join("+");
                }
            } else {
                if (category === DriverCategories.Average) {
                    year.Formula = `MxMath.Average([${monthsArr}])`;
                } else if (category === DriverCategories.FirstPeriod) {
                    year.Formula = `${this.getItemByDateSufix(y.months[0].sufix)}`;
                } else if (category === DriverCategories.LastPeriod) {
                    year.Formula = `${this.getItemByDateSufix(y.months[y.months.length - 1].sufix)}`;
                } else {
                    year.Formula = `MxMath.Sum([${monthsArr}])`;
                }
            }
        });
    };

    createFinancialStatementsValues = (ID, unit) => {
        const driverValues = MxDataArray.create();
        driverValues.pushArray(CalculatedDriver_Values.create_Periods_Quarterly(ID, unit));
        driverValues.pushArray(CalculatedDriver_Values.create_Periods_Comparative(ID, unit));
        return driverValues;
    };

    createDiscountCashFlowValues = (ID, unit) => {
        const driverValues = MxDataArray.create();
        driverValues.pushArray(CalculatedDriver_Values.create_Periods_Discounted_Cash_Flow(ID, unit));
        return driverValues;
    };

    static createFinancialStatementsDriver = (driver, ID) => {
        const newDriver = new CalculatedDriver(null, driver.Ref_Table, driver.Ref_Field, ID);
        newDriver.IsNew = true;
        newDriver.IsTemporary = true;
        newDriver.UnitType = driver.UnitType;
        newDriver.DriverCategory = driver.DriverCategory;
        newDriver.DriverName = driver.DriverName;
        newDriver.ID = ID;
        newDriver.Values = newDriver.createFinancialStatementsValues(newDriver.ID, newDriver.UnitType);
        global.Modeliks.DriversStore.push(newDriver);
        return newDriver;
    };

    static createDiscountCashFlowDriver = (
        driver,
        ID,
        driverID,
        unitType = UnitTypes.Price,
        Ref_Field = "totals",
        driverName = null,
    ) => {
        const newDriver = new CalculatedDriver();
        newDriver.IsNew = true;
        newDriver.IsTemporary = true;
        newDriver.UnitType = unitType;
        newDriver.DriverName = driverName;
        newDriver.Ref_Table = driver.Ref_Table;
        newDriver.Ref_Field = driverID;
        newDriver.Ref_ID = ID;
        newDriver.ID = newDriver.getTableRefID();
        newDriver.Values = newDriver.createDiscountCashFlowValues(newDriver.ID, unitType);
        global.Modeliks.DriversStore.push(newDriver);
        return newDriver;
    };

    static createDriverFromTable = (
        reference,
        fieldName,
        unitType,
        category,
        driverName,
        isTemporary = false,
    ) => {
        if (fieldName == null) {
            fieldName = reference.constructor.FieldName;
        }

        const driver = CalculatedDriver.createDriver(
            reference.constructor.TableName,
            reference.ID,
            fieldName,
            unitType,
            category,
            driverName,
            isTemporary,
        );

        return driver;
    };
}

export default CalculatedDriver;
