import React from "react";
import request from "superagent";
import Mx_MainLoader from "../../../../components/Loaders/MainLoader/Mx_MainLoader";
import EmailVerified from "./EmailVerified/EmailVerified";

class Verification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      verified: false,
      failedVerify: false,
      awaitLogin: false,
    };

    const lastSegment = window.location.pathname.split("/").pop();
    request
      .put("/api/verifyAccount")
      .send({ ActivationID: lastSegment, TimeStamp: new Date().getTime() })
      .then((res) => {
        window.localStorage.setItem("accepted_invite", "true");

        let result = JSON.parse(res.body);
        if (global.onUserRegister) {
          global.onUserRegister(result.ID);
        }

        if (global.onUserEmailVerify) {
          global.onUserEmailVerify(result.ID, result.Email);
        }

        if (global.trackingListener && window.is_live_app) {
          console.log("sending captera event");
          global.trackingListener();
        }

        if (result.token) {
          window.localStorage.setItem("token", result.token);
        }

        this.setState({ verified: true, awaitLogin: true }, () => {
          setTimeout(() => {
            window.location.href = location.origin ?? process.env.REACT_APP_PUBLIC_URL;
          }, 2000);
        });
      })
      .catch(() => {
        this.setState({ failedVerify: true });
      });
  }

  render() {
    if (this.state.verified || this.state.failedVerify) {
      return <EmailVerified success={this.state.verified} awaitLogin={this.state.awaitLogin} />;
    }

    return <Mx_MainLoader secondLoader={true} className={"blurred"} />;
  }
}

export default Verification;
