import React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import PropTypes from "prop-types";
import Button from "../../actions/Button";
import styled from "styled-components";
import IconButton from "../../../components/actions/IconButton";
import Input, { Input2 } from "../../../components/actions/Input";
import CircleCheckboxM from "../../../components/actions/CircleCheckboxM";
import { Vector } from "../../dividers/Vector";
import { IconX, TrashCan } from "../../icons/svgIcons";
import CheckBoxMaterial from "../../actions/CheckBoxMaterial";
import { PermissionTypes } from "../../constants/permissions";
import ButtonMui from "../../buttons/buttonMui/buttonMui";
import request from "superagent";
import { validateMail } from "../../../helpers/EmailHelper";

const P1Strong = styled.div`
  font-weight: 700;
  font-size: 20px;
  font-family: Inter;
`;

const StiledDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: "250px";
`;

const StyledWrapDiv = styled.div`
  padding: 5px 10px 20px;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 8px;
  overflow: auto;
  justify-content: space-between;
  box-sizing: border-box;
  max-height: 180px;
`;

const P3Strong = styled.div`
  font-weight: 700;
  font-size: 14px;
  font-family: Inter;
`;

const StyledD = styled.div`
  width: 100%;
`;

const StyledTitle = styled.div`
     -moz-box-sizing:border-box;
    -webkit-box-sizing:border-box;
    box-sizing:border-box;
    width: 100%;
    height: 100%
    display: flex;
    flex-direction:column;
    justify-content:center;
    overflow:hidden;
    padding-left: 15px;
    padding-right: 20px;
    padding-top: 35px;
`;

class Permission {
  constructor(Permission_Key, User_ID, Permission) {
    this.Permission_Key = Permission_Key;
    this.User_ID = User_ID;
    this.Permission = Permission;
  }
}

class CompanyUser {
  constructor(Company_ID, User_ID) {
    this.Company_ID = Company_ID;
    this.User_ID = User_ID;
  }
}

class EditUserDialog extends React.Component {
  constructor(props) {
    super(props);

    this.Permissions = new Map();
    if (props.User) {
      this.User = props.User;
      props.User.userPermissions.map((permission) =>
        this.Permissions.set(permission.Permission_Key, permission),
      );
      this.UserCompanies = props.User.companyUsers;
    } else {
      this.User = global.Modeliks.Tables.Users.BlankObject;
      this.User.UserType = 0;
    }
    this.ChangeablePermissions = new Map(this.Permissions);
    this.ChangeableUserCompanies = new Map(this.UserCompanies);
    this.Companies = global.Modeliks.Companies;

    this.state = {
      permissionsForDeletion: [],
      permissionsForInsertion: [],
      companiesForDeletion: [],
      companiesForInsertion: [],
      nameError: false,
      name: this.User.Name,
      email: this.User.Email ? this.User.Email : "",
      emailError: false,
      checkBoxesError: false,
      checkBoxesCompanyError: false,
      allSelected: false,
      allSelectedCompanies: false,
      checkboxKey: new Date().getTime(),
    };
  }

  viewerRestrictions = {
    [PermissionTypes.Users]: PermissionTypes.Users,
    [PermissionTypes.Companies]: PermissionTypes.Companies,
    [PermissionTypes.Subscriptions]: PermissionTypes.Subscriptions,
  };

  deleteUser = () => {
    global.Modeliks.del("companyusers", { User_ID: this.User.ID }, () => {
      global.Modeliks.del(
        "accountusers",
        { User_ID: this.User.ID, Account_ID: global.Modeliks.Account.ID },
        () => {
          global.Modeliks.del("userpermissions", { User_ID: this.User.ID }, () => {
            global.Modeliks.GetAccountUsers(() => {
              this.props.closeDialog();
            });
          });
        },
      );
    });
  };

  changeUserType = (type) => {
    this.User.UserType = type;
    this.ChangeablePermissions = new Map();
    this.setState({allSelected: false,checkboxKey: new Date().getTime() });
  };

  saveEmail = (e) => {};

  getAddPermissions = () => {
    let arr = [];
    this.ChangeablePermissions.forEach((value, key) => arr.push(key));
    return arr;
  };

  getAddCompanies = () => {
    let arr = [];
    this.ChangeableUserCompanies.forEach((value, key) => {
      arr.push({ Company_ID: key });
    });

    return arr;
  };

  saveUser = () => {
    let errState = {
      emailError: false,
      emailErrorMessage: false,
      checkBoxesError: false,
      checkBoxesCompanyError: false,
    };

    if (this.ChangeablePermissions.size === 0) {
      errState.checkBoxesError = true;
    }
    if (this.ChangeableUserCompanies.size === 0) {
      errState.checkBoxesCompanyError = true;
    }

    if (this.props.add) {
      if (!validateMail(this.state.email)) {
        errState.emailError = true;
        errState.emailErrorMessage = "Please enter a valid email";
      }
      if (Object.values(errState).filter((c) => !!c).length > 0) {
        this.setState(errState);
        return;
      }
      if (global.Modeliks.limitInfo.ActiveUsers < global.Modeliks.limitInfo.TotalUsers) {
        request
          .post("/api/inviteUser")
          .set("authorization", "Bearer " + window.localStorage.getItem("token"))
          .send({
            users: [
              {
                user: {
                  Username: "",
                  Name: this.state.name,
                  Password: "",
                  Email: this.state.email,
                  UserType: this.User.UserType,
                },
                permissions: this.getAddPermissions(),
                companies: this.getAddCompanies(),
              },
            ],
            name: global.Modeliks.User.Name,
            email: global.Modeliks.User.Email,
            User_ID: global.Modeliks.User.ID,
          })
          .then((data) => {
            if (data.text === "limit reached") {
              this.props.closeDialog(data);
              this.props.setOpenUsersLimit();
            } else {
              global.Modeliks.GetAccountUsers(() => {
                this.props.closeDialog(data);
              });
            }
          })
          .catch((err) => {
            this.setState({ emailError: true, emailErrorMessage: "This email is already added" });
          });
      }
    } else {
      if (Object.values(errState).filter((c) => !!c).length > 0) {
        this.setState(errState);
        return;
      }
      this.Permissions.forEach((p) => {
        if (this.ChangeablePermissions.has(p.Permission_Key)) {
          p.Permission = this.ChangeablePermissions.get(p.Permission_Key).Permission;
          this.ChangeablePermissions.delete(p.Permission_Key);
          p.Save((success, error) => {
            if (error) {
              console.error(error);
            }
          });
        } else {
          global.Modeliks.del(p.tableName, { ID: p.ID }, () => {
            this.Permissions.delete(p.Permission_Key);
          });
        }
      });

      if (this.ChangeablePermissions.size) {
        let newPermissions = [];
        this.ChangeablePermissions.forEach((p) => newPermissions.push(p));

        global.Modeliks.post("userpermissions", newPermissions, (success, error) => {
          if (error) {
            console.error(error);
          }
        });
      }

      this.UserCompanies.forEach((c) => {
        if (this.ChangeableUserCompanies.has(c.Company_ID)) {
          this.ChangeableUserCompanies.delete(c.Company_ID);
        } else {
          global.Modeliks.del(
            c.tableName,
            { Company_ID: c.Company_ID, User_ID: this.User.ID },
            () => {
              this.UserCompanies.delete(c.Company_ID);
            },
          );
        }
      });

      if (this.ChangeableUserCompanies.size) {
        let newCompanies = [];
        this.ChangeableUserCompanies.forEach((c) => newCompanies.push(c));

        global.Modeliks.post("companyusers", newCompanies, (success, error) => {
          if (error) {
            console.error(error);
          }
        });
      }
      global.Modeliks.put(
        "accountusers",
        { User_ID: this.User.ID, Account_ID: global.Modeliks.Account.ID },
        { UserType: this.User.UserType },
        () => {
          global.Modeliks.GetAccountUsers(() => {
            this.props.closeDialog();
          });
        },
      );
    }
  };

  changePermission = (e) => {
    if (this.ChangeablePermissions.has(e.target.value)) {
      this.ChangeablePermissions.delete(e.target.value);
      this.setState({ allSelected: false, checkboxKey: new Date().getTime() });
    } else {
      this.ChangeablePermissions.set(
        e.target.value,
        new Permission(e.target.value, this.User.ID, this.User.UserType),
      );
    }
    this.setState({ checkBoxesError: false });
  };

  editCompanyPermission = (e) => {
    if (this.ChangeableUserCompanies.has(parseInt(e.target.value))) {
      this.ChangeableUserCompanies.delete(parseInt(e.target.value));
      this.setState({ allSelectedCompanies: false, checkboxKey: new Date().getTime() });
    } else {
      this.ChangeableUserCompanies.set(
        parseInt(e.target.value),
        new CompanyUser(parseInt(e.target.value), this.User.ID),
      );
    }
    this.setState({ checkBoxesCompanyError: false });
  };

  selectAll = (e) => {
    if (!this.state.allSelected) {
      let permissions = Object.values(PermissionTypes);
      if (this.User.UserType == 0) {
        permissions = permissions.filter((c) => !this.viewerRestrictions.hasOwnProperty(c));
      }
      permissions.forEach((c) => {
        this.ChangeablePermissions.set(c, new Permission(c, this.User.ID, this.User.UserType));
      });
    } else {
      this.ChangeablePermissions = new Map();
    }
    this.setState({ allSelected: !this.state.allSelected, checkBoxesError: false });
  };

  selectAllCompanies = () => {
    if (!this.state.allSelectedCompanies) {
      this.Companies.forEach((c) => {
        this.ChangeableUserCompanies.set(
          parseInt(c.ID),
          new CompanyUser(parseInt(c.ID), this.User.ID),
        );
      });
    } else {
      this.ChangeableUserCompanies = new Map();
    }
    this.setState({
      allSelectedCompanies: !this.state.allSelectedCompanies,
      checkBoxesCompanyError: false,
    });
  };

  render() {
    return (
      <>
        <Dialog
          fullWidth={false}
          open={true}
          scroll="paper"
          maxWidth={"800px"}
          sx={{
            zIndex: 2000,
            ".MuiBackdrop-root": {
              backgroundColor: `rgb(255 255 255 / 90%)`,
            },
            "& .MuiDialog-container": {
              justifyContent: "center",
              alignItems: "center",
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "800px", // Set your width here
                // height: '100%',
                // maxHeight: '600px',
              },
            },
          }}
          onClose={() => this.props.closeDialog()}
          PaperProps={{
            sx: {
              marginLeft: "25px",
              marginRight: "38px",
              boxShadow: `0px 0px 10px rgba(0, 0, 0, 0.15)`,
            },
          }}
          className="usr_dialog"
        >
          <DialogTitle>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <P1Strong>{this.props.add ? "Add new user" : "Edit user"}</P1Strong>
              <IconButton size="small" onClick={this.props.closeDialog}>
                <IconX />
              </IconButton>
            </div>
          </DialogTitle>

          <DialogContent>
            <div
              style={{
                display: "flex",
                width: "100%",
                gap: "5px",
                alignItems: "center",
                // marginBottom: '17px',
                marginTop: "19px",
              }}
            >
              {!this.props.add && (
                <Input
                  disabled
                  allowEmpty
                  readOnly={!this.props.add}
                  value={
                    this.User.Name
                      ? this.User.Name
                      : "" + " " + this.User.Surname
                        ? this.User.Surname
                        : ""
                  }
                  placeholder={"Name"}
                />
              )}
              <Input2
                parentStyle={{ width: "100%" }}
                errorMessage={this.state.emailErrorMessage}
                readOnly={!this.props.add}
                onChange={(e) =>
                  this.setState({
                    email: e.target.value,
                    emailError: false,
                    emailErrorMessage: "",
                  })
                }
                value={this.state.email}
                placeholder={"User email"}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  width: "100%",
                  paddingLeft: "20px",
                  alignItems: "center",
                }}
              >
                <CircleCheckboxM
                  label={"Editor"}
                  onChange={() => this.changeUserType(1)}
                  checked={this.User.UserType == 1}
                  size={"medium"}
                />
                <CircleCheckboxM
                  label={"Viewer"}
                  onChange={() => this.changeUserType(0)}
                  checked={this.User.UserType == 0}
                  size={"medium"}
                />
              </div>
            </div>
            <div className="usr_error_container">
              <div className={"error invisible"}>Plese enter a valid name</div>
              <div className={"error invisible"}>{this.state.emailErrorMessage}</div>
            </div>
            <div
              className={this.state.checkBoxesError ? "divider error" : "divider"}
              style={{ width: "100%", borderBottom: "1px solid #F0F1F7", marginTop: "10px" }}
            />
            <div className="check_box_error_container">
              <span
                className={
                  this.state.checkBoxesError ? "check_box_error" : "check_box_error invisible"
                }
              >
                Please select at least one Section
              </span>
            </div>
            <div style={{ display: "flex", justifyContent: "left" }}>
              <P3Strong>
                Please select the sections for which you would like to grant the user{" "}
                {this.User.UserType == 1 ? "edit" : "view"} rights
              </P3Strong>
            </div>
            {this.User.UserType == 1 && (
              <div style={{ width: "100%", marginTop: "20px" }}>
                <StiledDiv>
                  <StyledD>
                    <CircleCheckboxM
                      label={"Pitch"}
                      checked={this.ChangeablePermissions.has(PermissionTypes.Pitch)}
                      value={PermissionTypes.Pitch}
                      size={"medium"}
                      onChange={this.changePermission}
                    />
                  </StyledD>
                  {/*<StyledD><CircleCheckboxM label={'Valuation'}*/}
                  {/*                          checked={this.ChangeablePermissions.has(PermissionTypes.Valuation)}*/}
                  {/*                          value={PermissionTypes.Valuation}*/}
                  {/*                          onChange={this.changePermission}*/}
                  {/*                          size={'medium'}/></StyledD>*/}
                  <StyledD>
                    <CircleCheckboxM
                      label={"Dashboards"}
                      checked={this.ChangeablePermissions.has(PermissionTypes.Dashboards)}
                      value={PermissionTypes.Dashboards}
                      onChange={this.changePermission}
                      size={"medium"}
                    />
                  </StyledD>
                  <StyledD>
                    <CircleCheckboxM
                      label={"Add & Manage users"}
                      checked={this.ChangeablePermissions.has(PermissionTypes.Users)}
                      onChange={this.changePermission}
                      value={PermissionTypes.Users}
                      size={"medium"}
                    />
                  </StyledD>
                </StiledDiv>
                <StiledDiv style={{ marginTop: "5px" }}>
                  <StyledD>
                    <CircleCheckboxM
                      label={"Business Plan"}
                      checked={this.ChangeablePermissions.has(PermissionTypes.BusinessPlan)}
                      value={PermissionTypes.BusinessPlan}
                      onChange={this.changePermission}
                      size={"medium"}
                    />
                  </StyledD>
                  <StyledD>
                    <CircleCheckboxM
                      label={"Manage Subscriptions"}
                      checked={this.ChangeablePermissions.has(PermissionTypes.Subscriptions)}
                      value={PermissionTypes.Subscriptions}
                      onChange={this.changePermission}
                      size={"medium"}
                    />
                  </StyledD>
                  <StyledD>
                    {/*<CircleCheckboxM label={'Add & Manage users'}*/}
                    {/*                      checked={this.ChangeablePermissions.has(PermissionTypes.Users)}*/}
                    {/*                      onChange={this.changePermission}*/}
                    {/*                      value={PermissionTypes.Users}*/}
                    {/*                      size={'medium'}/>*/}
                  </StyledD>
                </StiledDiv>
                <StiledDiv style={{ marginTop: "5px" }}>
                  <StyledD>
                    <CircleCheckboxM
                      label={"Financial Plan"}
                      checked={this.ChangeablePermissions.has(PermissionTypes.Financials)}
                      value={PermissionTypes.Financials}
                      onChange={this.changePermission}
                      size={"medium"}
                    />
                  </StyledD>
                  <StyledD>
                    <CircleCheckboxM
                      label={"Add & Manage Companies"}
                      checked={this.ChangeablePermissions.has(PermissionTypes.Companies)}
                      value={PermissionTypes.Companies}
                      onChange={this.changePermission}
                      size={"medium"}
                    />
                  </StyledD>
                  <StyledD>
                    {/*<CircleCheckboxM label={'Dashboards'}*/}
                    {/*                      checked={this.ChangeablePermissions.has(PermissionTypes.Dashboards)}*/}
                    {/*                      value={PermissionTypes.Dashboards}*/}
                    {/*                      onChange={this.changePermission}*/}
                    {/*                      size={'medium'}/>*/}
                  </StyledD>
                </StiledDiv>
              </div>
            )}

            {this.User.UserType == 0 && (
              <div>
                <StiledDiv style={{ marginTop: "20px" }}>
                  <StyledD>
                    <CircleCheckboxM
                      label={"Pitch"}
                      checked={this.ChangeablePermissions.has(PermissionTypes.Pitch)}
                      value={PermissionTypes.Pitch}
                      onChange={this.changePermission}
                      size={"medium"}
                    />
                  </StyledD>
                  {/*<StyledD><CircleCheckboxM label={'Valuation'}*/}
                  {/*                          checked={this.ChangeablePermissions.has(PermissionTypes.Valuation)}*/}
                  {/*                          value={PermissionTypes.Valuation}*/}
                  {/*                          onChange={this.changePermission}*/}
                  {/*                          size={'medium'}/></StyledD>*/}
                  <StyledD>
                    <CircleCheckboxM
                      label={"Dashboards"}
                      checked={this.ChangeablePermissions.has(PermissionTypes.Dashboards)}
                      value={PermissionTypes.Dashboards}
                      onChange={this.changePermission}
                      size={"medium"}
                    />
                  </StyledD>
                </StiledDiv>
                <StiledDiv style={{ marginTop: "5px" }}>
                  <StyledD>
                    <CircleCheckboxM
                      label={"Business Plan"}
                      checked={this.ChangeablePermissions.has(PermissionTypes.BusinessPlan)}
                      value={PermissionTypes.BusinessPlan}
                      onChange={this.changePermission}
                      size={"medium"}
                    />
                  </StyledD>
                  {/*<StyledD><CircleCheckboxM label={'Dashboards'}*/}
                  {/*                          checked={this.ChangeablePermissions.has(PermissionTypes.Dashboards)}*/}
                  {/*                          value={PermissionTypes.Dashboards}*/}
                  {/*                          onChange={this.changePermission}*/}
                  {/*                          size={'medium'}/></StyledD>*/}
                </StiledDiv>
                <StiledDiv>
                  <div style={{ width: "100%", marginTop: "5px" }}>
                    <CircleCheckboxM
                      value={PermissionTypes.Financials}
                      label={"Financial Plan"}
                      checked={this.ChangeablePermissions.has(PermissionTypes.Financials)}
                      onChange={this.changePermission}
                      size={"medium"}
                    />
                  </div>
                </StiledDiv>
              </div>
            )}
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <CheckBoxMaterial
                key={this.state.checkboxKey + "usrs"}
                onChange={this.selectAll}
                checked={this.state.selectAll}
                label={"select all"}
              />
            </div>
            <div
              className={this.state.checkBoxesCompanyError ? "divider error" : "divider"}
              style={{ width: "100%", borderBottom: "1px solid #F0F1F7", marginTop: "10px" }}
            />
            <div className="check_box_error_container">
              <span
                className={
                  this.state.checkBoxesCompanyError
                    ? "check_box_error"
                    : "check_box_error invisible"
                }
              >
                Please select at least one Company
              </span>
            </div>
            <div style={{ display: "flex", justifyContent: "left" }}>
              <P3Strong>
                Please select the companies for which you would like to grant access rights to the
                user.
              </P3Strong>
            </div>
            <StyledWrapDiv>
              {this.Companies.length > 0 &&
                this.Companies.map((comp, key) => (
                  <div key={"comp_edit_" + key}>
                    <CircleCheckboxM
                      checked={this.ChangeableUserCompanies.has(comp.ID)}
                      value={comp.ID}
                      onChange={this.editCompanyPermission}
                      label={comp.CompanyName}
                      size={"medium"}
                    />
                  </div>
                ))}
            </StyledWrapDiv>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <CheckBoxMaterial
                key={this.state.checkboxKey + "companies"}
                onChange={this.selectAllCompanies}
                checked={this.state.allSelectedCompanies}
                label={"select all"}
              />
            </div>
          </DialogContent>
          <DialogActions
            style={{
              justifyContent: "space-between",
              background: "white",
              marginRight: "10px",
              marginBottom: "10px",
              marginTop: "20px",
            }}
          >
            {!this.props.add ? (
              <ButtonMui
                style={{ height: "30px" }}
                color={"red"}
                label={"Delete User"}
                startIcon={<TrashCan />}
                variant={"contained"}
                onClick={() => this.deleteUser()}
              />
            ) : (
              <div />
            )}

            <div style={{ display: "flex", gap: "20px" }}>
              <ButtonMui
                style={{ height: "30px" }}
                label={"Cancel"}
                variant={"contained"}
                onClick={() => this.props.closeDialog()}
              />
              <ButtonMui
                style={{ height: "30px" }}
                label={this.props.add ? "Add user" : "Save"}
                variant={"contained"}
                onClick={this.saveUser}
              />
            </div>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default EditUserDialog;
