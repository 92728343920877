import React from "react";
import { DialogContent, DialogActions, DialogTitle } from "@mui/material";
import ButtonMui from "../../../buttons/buttonMui/buttonMui";
import { useNavigate } from "react-router-dom";
export const SessionExpired = ({ ...props }) => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate("/login");
    props.onAccept();
  };

  return (
    <div className={"info_dialog_container"}>
      <DialogTitle>Session Expired</DialogTitle>
      <DialogContent>
        For security reasons inactive session are automatically logged out.
        <br />
        To continue your session please log-in.
      </DialogContent>
      <DialogActions disableSpacing={false}>
        <ButtonMui label="OK" onClick={handleOnClick} variant={"contained"}/>
      </DialogActions>
    </div>
  );
};
