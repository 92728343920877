import CalculatedDriver from "../../CalculatedDriver/index";
import { DriverCategories, UnitTypes } from "../../CalculatedDriver/constants";
import MxIDHelper from "../../../MxIDHelper";
import WorkingCapital from "../index";
import CalculatedDriver_Values from "../../CalculatedDriver/CalculatedDriver_Values";
import Revenue from "../../Revenue";
import { WorkingCapitalTypes } from "../constants";
import CostSales from "../../CostSales";
import { ExpenseTypes } from "../../constants";
import AccountReceivable from "../AccountReceivable";

class AccountPayable extends WorkingCapital {
  Name = "";
  Type = WorkingCapitalTypes.AccountPayable;
  CreditPurchases;
  PayableDays;
  IsDetailed = false;

  constructor(db_record) {
    super(db_record);
    if (this.db_record) {
      this.Name = this.db_record.Name;
      this.Type = this.db_record.Type;
      this.IsDetailed = this.db_record.IsDetailed;
      this.setDriversFromDataStorageSub();
      this.setWorkingCapital();
    }
    this.SaveRevenue = this.Save;
    this.Save = this.SaveWorkingCapital;
  }

  static DriversDesc = Object.assign({
    Total: {
      driverName: "$Name",
      fieldName: "Totals",
      driverID: "total",
      unit: UnitTypes.Price,
      category: DriverCategories.LastPeriod,
    },
    CreditPurchasesTerms: {
      driverName: "Credit Purchases",
      fieldName: "CreditPurchasesTerms",
      driverID: "credit_purchases_terms",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    CumulativeCreditPurchases: {
      driverName: "Cumulative Credit Purchases",
      fieldName: "CumulativeCreditPurchases",
      driverID: "cumulative_credit_purchases",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    OpeningBalance: {
      driverName: "Opening Balance",
      fieldName: "OpeningBalance",
      driverID: "account_payable_opening_balance",
      unit: UnitTypes.Price,
      category: DriverCategories.LastPeriod,
    },
    OpeningBalanceReduction: {
      driverName: "Opening Balance Reduction",
      fieldName: "OpeningBalanceReduction",
      driverID: "opening_balance_reduction",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    OpeningBalanceResidual: {
      driverName: "Opening Balance Residual",
      fieldName: "OpeningBalanceResidual",
      driverID: "opening_balance_residual",
      unit: UnitTypes.Price,
      category: DriverCategories.LastPeriod,
    },
    ClosingBalance: {
      driverName: "Accounts Payable",
      fieldName: "ClosingBalance",
      driverID: "account_payable_closing_balance",
      unit: UnitTypes.Price,
      category: DriverCategories.LastPeriod,
    },
  });

  SaveWorkingCapital = (callBack, saveDrivers = true) => {
    console.log("SaveWorkingCapital", this);
    this.SaveRevenue((newID) => {
      this.CreditPurchases.Save();
      this.PayableDays.Save();

      if (saveDrivers) {
        this.SaveDrivers(callBack, this.ID);
      } else {
        callBack(this.ID);
      }
    }, false);
  };
  get hasChange() {
    return this.IsDetailed !== this.db_record.IsDetailed;
  }
  setDriversFromDataStorageSub = () => {
    this.CreditPurchases = global.Modeliks.DriverValuesStore.getItem(this.getCreditPurchasesID());
    this.PayableDays = global.Modeliks.DriverValuesStore.getItem(this.getPayableDaysID());
  };

  getCreditPurchasesID = () => {
    return `${AccountPayable.TableName}-${this.ID}-credit_purchases`;
  };
  getPayableDaysID = () => {
    return `${AccountPayable.TableName}-${this.ID}-payable_days`;
  };

  getCostExpenseCreditPurchasesID = (ID, TableName) => {
    return `${TableName}-${ID}-cost-expense-${AccountPayable.TableName}-${this.ID}-credit_purchases`;
  };
  getCostExpensePayableDaysID = (ID, TableName) => {
    return `${TableName}-${ID}-cost-expense-${AccountPayable.TableName}-${this.ID}-payable_days`;
  };

  getCostExpenseCreditPurchasesValue = (ID, TableName) => {
    let value = global.Modeliks.DriverValuesStore.getItem(
      this.getCostExpenseCreditPurchasesID(ID, TableName),
    );
    if (value) {
      return value;
    }
    value = new CalculatedDriver_Values(
      null,
      this.getCostExpenseCreditPurchasesID(ID, TableName),
      null,
      UnitTypes.Percentage,
    );
    value.Value = 0;
    value.Save(() => value);
  };
  getCostExpensePayableDaysValue = (ID, TableName) => {
    let value = global.Modeliks.DriverValuesStore.getItem(
      this.getCostExpensePayableDaysID(ID, TableName),
    );
    if (value) {
      return value;
    }

    value = new CalculatedDriver_Values(
      null,
      this.getCostExpensePayableDaysID(ID, TableName),
      null,
      UnitTypes.Units,
    );
    value.Value = 0;
    value.Save(() => value);
  };

  getRevenueDrivers = () => {
    const CostAndExpenses = CostSales.getCostSaleAndExpenses();
    if (this.IsDetailed) {
      if (CostAndExpenses.getChildDrivers() && CostAndExpenses.getChildDrivers().length > 0) {
        return CostAndExpenses.getChildDrivers();
      }
      return [CostAndExpenses];
    } else {
      return CostAndExpenses;
    }
  };

  getMonthDatesAll = () => {
    return [...global.Modeliks.DateHelper.months];
  };

  getYearDatesAll = () => {
    return global.Modeliks.DateHelper.years_all;
  };
  periodsData = {};

  buildPeriodsData = () => {
    const allPeriods = this.CreditPurchasesTerms.Values.map((c) => c.Date);
    allPeriods.forEach((period) => {
      if (!this.periodsData[period.dateID]) {
        this.periodsData[period.dateID] = {};
        Object.values(this.constructor.DriversDesc).forEach(
          (driver) =>
            (this.periodsData[period.dateID][driver.fieldName] = this[
              driver.fieldName
            ].getItemByDateSufix(period.sufix)),
        );
        this.periodsData[period.dateID].Date = period;
      }
    });
  };

  setWorkingCapital = () => {
    this.buildPeriodsData();
    this.createMonthsFormulas();
    this.createYearsFormulas();
  };

  createMonthsFormulas = () => {
    const months = this.getMonthDatesAll();
    const years = this.getYearDatesAll();
    const revenueDrivers = this.getRevenueDrivers();
    let revenueCreditAndDaysTotals = [];
    if (this.IsDetailed) {
      revenueCreditAndDaysTotals = `MxMath.Sum([${revenueDrivers.map((revenue) => `${this.getCostExpenseCreditPurchasesValue(revenue.Ref_ID, revenue.Ref_Table)} * ${this.getCostExpensePayableDaysValue(revenue.Ref_ID, revenue.Ref_Table)}`).join(",")}])`;
    }

    months.forEach((month) => {
      const prevMonthDate = months.find((c) => c.Order == month.Order - 1);
      const curMonth = this.periodsData[month.dateID];
      const prevMonth = prevMonthDate ? this.periodsData[prevMonthDate.dateID] : null;
      const firstMonth = this.periodsData[months[0].dateID];

      if (prevMonth) {
        curMonth.CumulativeCreditPurchases.Formula = `${prevMonth.CumulativeCreditPurchases} + ${curMonth.CreditPurchasesTerms}`;
        curMonth.OpeningBalance.Formula = `${prevMonth.ClosingBalance}`;
        curMonth.OpeningBalanceResidual.Formula = `${prevMonth.OpeningBalanceResidual} - ${curMonth.OpeningBalanceReduction}`;
      } else {
        curMonth.CumulativeCreditPurchases.Formula = `${curMonth.CreditPurchasesTerms}`;
        curMonth.OpeningBalanceResidual.Formula = `${curMonth.OpeningBalance} - ${curMonth.OpeningBalanceReduction}`;
      }

      if (this.IsDetailed) {
        curMonth.CreditPurchasesTerms.Formula = `MxMath.Sum([${revenueDrivers.map((revenue) => revenue.getItemByDateSufix(month.sufix) + "*" + this.getCostExpenseCreditPurchasesValue(revenue.Ref_ID, revenue.Ref_Table)).join(",")}])`;
        const revenueReduction = `MxMath.Sum([${revenueDrivers.map((revenue) => `MxMath.IFELSE(${month.Order + 1} <=  MxMath.Ceil(${this.getCostExpensePayableDaysValue(revenue.Ref_ID, revenue.Ref_Table)} / 31),(${firstMonth.OpeningBalance} * ${revenue.getItemByDateSufix(months[0].sufix)} * ${this.getCostExpenseCreditPurchasesValue(revenue.Ref_ID, revenue.Ref_Table)} / ${firstMonth.CreditPurchasesTerms}) / MxMath.Ceil(${this.getCostExpensePayableDaysValue(revenue.Ref_ID, revenue.Ref_Table)} / 31), 0)`).join(",")}])`;
        curMonth.OpeningBalanceReduction.Formula = `MxMath.IFELSE(${revenueCreditAndDaysTotals} === 0 , ${curMonth.OpeningBalance} , ${revenueReduction})`;
        curMonth.ClosingBalance.Formula = `MxMath.Min(${revenueDrivers.map((revenue) => `(${revenue.getItemByDateSufix(month.sufix)} * ${this.getCostExpenseCreditPurchasesValue(revenue.Ref_ID, revenue.Ref_Table)} / 30 * ${this.getCostExpensePayableDaysValue(revenue.Ref_ID, revenue.Ref_Table)})`).join("+")}, ${curMonth.CumulativeCreditPurchases}) + ${curMonth.OpeningBalanceResidual}`;
      } else {
        curMonth.CreditPurchasesTerms.Formula = `${revenueDrivers.getItemByDateSufix(month.sufix)} * ${this.CreditPurchases}`;
        curMonth.ClosingBalance.Formula = `MxMath.Min(${curMonth.CreditPurchasesTerms} / 30 * ${this.PayableDays} , ${curMonth.CumulativeCreditPurchases} ) + ${curMonth.OpeningBalanceResidual}`;
        curMonth.OpeningBalanceReduction.Formula = `MxMath.IFELSE(${this.CreditPurchases} * ${this.PayableDays} > 0,MxMath.IFELSE(${month.Order + 1} <=  MxMath.Ceil(${this.PayableDays} / 31),${firstMonth.OpeningBalance} / MxMath.Ceil(${this.PayableDays} / 31), 0), ${curMonth.OpeningBalance})`;
      }

      curMonth.Totals.Formula = `${curMonth.ClosingBalance}`;
    });

    years.forEach((year) => {
      const curYear = this.periodsData[year.dateID];
      const months = this.periodsData[year.dateID].Date.monthIndexes.map(
        (index) => this.periodsData[index],
      );
      const prevYearDate = years.find((c) => c.Order === year.Order - 1);
      const prevYear = prevYearDate ? this.periodsData[prevYearDate.dateID] : null;
      const lastMonth = months[months.length - 1];
      const firstMonth = months[0];

      curYear.CumulativeCreditPurchases.Formula = "";
      curYear.OpeningBalanceReduction.Formula = "";
      curYear.OpeningBalanceResidual.Formula = "";

      if (!year.Active) {
        if (this.IsDetailed) {
          curYear.CreditPurchasesTerms.Formula = `MxMath.Sum([${months.map((c) => c.CreditPurchasesTerms.ID_f).join(",")}])`;
        } else {
          curYear.CreditPurchasesTerms.Formula = `${revenueDrivers.getItemByDateSufix(year.sufix)} * ${this.CreditPurchases}`;
        }

        curYear.OpeningBalance.Formula = `${firstMonth.OpeningBalance}`;
        curYear.ClosingBalance.Formula = `${lastMonth.ClosingBalance}`;
      } else {
        if (this.IsDetailed) {
          curYear.CreditPurchasesTerms.Formula = `${revenueDrivers.map((revenue) => `${revenue.getItemByDateSufix(year.sufix)} * ${this.getCostExpenseCreditPurchasesValue(revenue.Ref_ID, revenue.Ref_Table)}`).join("+")}`;
          curYear.ClosingBalance.Formula = `MxMath.Sum([${revenueDrivers.map((revenue) => `${revenue.getItemByDateSufix(year.sufix)} * ${this.getCostExpenseCreditPurchasesValue(revenue.Ref_ID, revenue.Ref_Table)} / 365 * ${this.getCostExpensePayableDaysValue(revenue.Ref_ID, revenue.Ref_Table)}`).join(",")}])`;
        } else {
          curYear.CreditPurchasesTerms.Formula = `${revenueDrivers.getItemByDateSufix(year.sufix)} * ${this.CreditPurchases}`;
          curYear.ClosingBalance.Formula = `${this.PayableDays} / 365 * ${curYear.CreditPurchasesTerms}`;
        }

        curYear.OpeningBalance.Formula = `${prevYear.ClosingBalance}`;
      }

      curYear.Totals.Formula = `${curYear.ClosingBalance}`;
    });
  };

  createYearsFormulas = () => {};

  cleanWorkingCapital = (ID, TableName, callBack) => {
    this.ClosingBalance.Values.forEach((v) => v.Formula === null);
    this.CreditPurchasesTerms.Values.forEach((v) => v.Formula === null);
    this.OpeningBalanceReduction.Values.forEach((v) => v.Formula === null);
    this.ClosingBalance.Values.forEach((v) => v.evalFormula === null);
    this.CreditPurchasesTerms.Values.forEach((v) => v.evalFormula === null);
    this.OpeningBalanceReduction.Values.forEach((v) => v.evalFormula === null);
    global.Modeliks.del(
      global.Modeliks.Tables.Finance_CalculatedDriver_Values.TableName,
      {
        ID: this.getCostExpenseCreditPurchasesID(ID, TableName),
        ID_CompanyScenario: global.Modeliks.CompanyScenarioInfo.ID,
      },
      (res) => {
        global.Modeliks.del(
          global.Modeliks.Tables.Finance_CalculatedDriver_Values.TableName,
          {
            ID: this.getCostExpensePayableDaysID(ID, TableName),
            ID_CompanyScenario: global.Modeliks.CompanyScenarioInfo.ID,
          },
          (res) => {
            if (callBack) {
              this.setWorkingCapital();
              this.Save(() => {
                callBack();
              });
            }
          },
        );
      },
    );
  };

  static create_AccountPayable = (Name, record) => {
    const newAccountPayable = new AccountPayable();
    newAccountPayable.ID = record.ID;
    newAccountPayable.Name = Name;
    newAccountPayable.Type = WorkingCapitalTypes.AccountPayable;
    newAccountPayable.ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
    newAccountPayable.Totals = CalculatedDriver.createDriverFromTable(
      newAccountPayable,
      WorkingCapital.DriversDesc.Total.driverID,
      UnitTypes.Price,
      DriverCategories.Sum,
    );
    newAccountPayable.CreditPurchases = new CalculatedDriver_Values(
      null,
      newAccountPayable.getCreditPurchasesID(),
      null,
      UnitTypes.Percentage,
    );
    newAccountPayable.CreditPurchases.Value = 0;
    newAccountPayable.PayableDays = new CalculatedDriver_Values(
      null,
      newAccountPayable.getPayableDaysID(),
      null,
      UnitTypes.Units,
    );
    newAccountPayable.PayableDays.Value = 0;
    newAccountPayable.createDrivers();
    newAccountPayable.setWorkingCapital();
    global.Modeliks.WorkingCapitalStore.push(newAccountPayable);
    return newAccountPayable;
  };

  static getAccountPayableDriver = (callBack) => {
    let driver = global.Modeliks.WorkingCapitalStore.find(
      (driver) => driver.Type === WorkingCapitalTypes.AccountPayable,
    );
    if (driver) {
      driver.LastPeriodOnly = true;
      driver.isExpense = true;
      if (callBack) {
        callBack(driver);
      } else {
        return driver;
      }
    } else {
      WorkingCapital.getEmptyWorkingCapital((record) => {
        driver = AccountPayable.create_AccountPayable("Account Payable", record);
        driver.changeDriversName();
        driver.LastPeriodOnly = true;
        driver.isExpense = true;
        driver.Save(() => {
          global.Modeliks.DriversStore.clear();
          callBack();
        });
      });
    }
  };
}

export default AccountPayable;
