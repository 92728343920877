import React, { Component } from "react";
import "./PaymentFailedDialog.scss";
import Dialog from "@mui/material/Dialog";
import ButtonMui from "../buttons/buttonMui/buttonMui";

class PaymentFailedDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleClose = () => {
    this.props.handleClose();
  };

  render() {
    return (
      <React.Fragment>
        {
          <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            open={this.props.open ? this.props.open : false}
            className={"payment_failed_dialog"}
            onClose={this.handleClose}
          >
            <>
              <div className="pfd_label">Upgrade Failed</div>

              <div className={"pfd_label_2"}>
                Please update your payment method and try again. <br />
                Please note that you need to wait up to 1 minute after <br /> the payment method is
                changed in order to upgrade again.
              </div>

              <div className={"pfd_button"}>
                <ButtonMui
                  label={"Update Payment Method"}
                  variant={"contained"}
                  onClick={this.props.updatePaymentMethod}
                />
              </div>
            </>
          </Dialog>
        }
      </React.Fragment>
    );
  }
}

export default PaymentFailedDialog;
