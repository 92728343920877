import { DriverCategories, UnitTypes } from "../../CalculatedDriver/constants";
import { FinancingTypes } from "../../constants";
import CalculatedDriver_Values from "../../CalculatedDriver/CalculatedDriver_Values";
import Taxes from "../index";
import { TaxesTypes, RateChange } from "../constants";
import { PayBackOptions } from "../../constants";
import CalculatedDriver from "../../CalculatedDriver";
import ProfitLoss from "../../Reports/ProfitLoss";
import Expense from "../../Expense";

class IncomeTax extends Taxes {
  Name = "";
  ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
  TaxType = TaxesTypes.IncomeTax;
  RateChange = RateChange.false;
  PayableOpenningBalance;
  TaxPaymentFrequency;

  constructor(db_record) {
    super(db_record);
    if (this.db_record) {
      this.clean();
      // this.setTax && this.setTax();
    }
    this.SaveRevenue = this.Save;
    this.Save = this.SaveTax;
  }

  clean = (cleanDrivers = false) => {
    if (this.db_record && this.Totals) {
      this.Name = this.db_record.Name;
      this.TaxType = this.db_record.TaxType;
      this.RateChange = this.db_record.RateChange;
      this.ID_CompanyScenario = this.db_record.ID_CompanyScenario;
      this.setDriversFromDataStorageSub();

      if (cleanDrivers) {
        this.TaxPaymentFrequency.cleanValue();
        this.PayableOpenningBalance.cleanValue();
        this.cleanDrivers();
        // this.setTax && this.setTax();
      }
    }
  };

  static DriversDesc = Object.assign({
    Total: {
      driverName: "$Name",
      fieldName: "Totals",
      driverID: "total",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    IncomeTaxRate: {
      driverName: "Income Tax Rate",
      fieldName: "IncomeTaxRate",
      driverID: "income_tax_rate",
      unit: UnitTypes.Percentage,
      category: DriverCategories.Average,
    },
    CalculatedAccruedIncomeTax: {
      driverName: "Calculated Accrued Income Tax",
      fieldName: "CalculatedAccruedIncomeTax",
      driverID: "calculated_accrued_income_tax",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    CumulativeCalculatedAccruedIncomeTax: {
      driverName: "Cumulative Calculated Accrued Income Tax",
      fieldName: "CumulativeCalculatedAccruedIncomeTax",
      driverID: "cumulative_calculated_accrued_income_tax",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    AccruedIncomeTax: {
      driverName: "Income Tax",
      fieldName: "AccruedIncomeTax",
      driverID: "accrued_income_tax",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    EarningsBeforeTax: {
      driverName: "Profit Before Tax",
      fieldName: "EarningsBeforeTax",
      driverID: "earnings_before_tax",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    IncomeTaxPayableOpenningBalance: {
      driverName: "Income Tax Payable Opening Balance",
      fieldName: "IncomeTaxPayableOpenningBalance",
      driverID: "income_tax_payable_openning_balance",
      unit: UnitTypes.Price,
      category: DriverCategories.FirstPeriod,
    },
    IncomeTaxPayments: {
      driverName: "Income Tax Payments",
      fieldName: "IncomeTaxPayments",
      driverID: "income_tax_payments",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    IncomeTaxPayableClosingBalance: {
      driverName: "Income Tax Payable",
      fieldName: "IncomeTaxPayableClosingBalance",
      driverID: "income_tax_payable_cosing_balance",
      unit: UnitTypes.Price,
      category: DriverCategories.LastPeriod,
    },
  });

  SaveTax = (callBack, saveDrivers = true) => {
    console.log("SaveTax", this);
    this.SaveRevenue((newID) => {
      const oldID = this.ID;
      if (newID) {
        this.ID = newID;
        this.TaxPaymentFrequency.changeID(this.getTaxPaymentFrequencyID());
        this.PayableOpenningBalance.changeID(this.getPayableOpenningBalanceID());
      }
      this.TaxPaymentFrequency.Save();
      this.PayableOpenningBalance.Save();

      if (saveDrivers) {
        this.SaveDrivers(callBack, this.ID);
      } else {
        callBack(this.ID);
      }
    }, false);
  };

  Delete = (callBack) => {
    this.DeleteFunc(this.ID, () => {
      global.Modeliks.del(
        global.Modeliks.Tables.Finance_CalculatedDriver_Values.TableName,
        {
          ID: this.getTaxPaymentFrequencyID(),
          ID_CompanyScenario: global.Modeliks.CompanyScenarioInfo.ID,
        },
        (res) => {
          global.Modeliks.del(
            global.Modeliks.Tables.Finance_CalculatedDriver_Values.TableName,
            {
              ID: this.getPayableOpenningBalanceID(),
              ID_CompanyScenario: global.Modeliks.CompanyScenarioInfo.ID,
            },
            (res) => {
              if (callBack) {
                callBack();
              }
            },
          );
        },
      );
    });
  };

  setDriversFromDataStorageSub = () => {
    this.TaxPaymentFrequency = global.Modeliks.DriverValuesStore.getItem(
      this.getTaxPaymentFrequencyID(),
    );
    this.PayableOpenningBalance = global.Modeliks.DriverValuesStore.getItem(
      this.getPayableOpenningBalanceID(),
    );
  };

  periodsData = {};
  createExtraPeriods = () => {
    Object.values(this.constructor.DriversDesc).forEach((driver) => {
      this[driver.fieldName].addMonths(0, true, true);
    });

    this.buildPeriodsData();
  };

  buildPeriodsData = () => {
    const allPeriods = this.AccruedIncomeTax.Values.map((c) => c.Date);
    allPeriods.forEach((period) => {
      this.periodsData[period.dateID] = {};
      Object.values(this.constructor.DriversDesc).forEach(
        (driver) =>
          (this.periodsData[period.dateID][driver.fieldName] = this[
            driver.fieldName
          ].getItemByDateSufix(period.sufix)),
      );
      this.periodsData[period.dateID].Date = period;
    });
  };
  getTaxPaymentFrequencyID = () => {
    return `${IncomeTax.TableName}-${this.ID}-tax_payment_frequency`;
  };
  getPayableOpenningBalanceID = () => {
    return `${IncomeTax.TableName}-${this.ID}-payable_openning_balance`;
  };

  getMonthDatesAll = () => {
    return [...global.Modeliks.DateHelper.months, ...global.Modeliks.DateHelper.months_after];
  };
  getYearDatesAll = () => {
    return global.Modeliks.DateHelper.years_all;
  };

  createMonthsFormulas = () => {
    const months = this.getMonthDatesAll();
    const reportProfitLoss = global.Modeliks.DriversStore.find(
      (d) => d.Ref_Field === ProfitLoss.DriversDesc.ProfitBeforeTax.driverID,
    );
    const OperatingProfit = global.Modeliks.DriversStore.find(
      (d) => d.Ref_Field === ProfitLoss.DriversDesc.OperatingProfit.driverID,
    );
    this.EarningsBeforeTax.Formula = `${reportProfitLoss} + ${OperatingProfit} + ${Expense.getOperatingExpenses()}`;
    months.forEach((month) => {
      const prevMonthDate = months.find((c) => c.Order === month.Order - 1);
      const curMonth = this.periodsData[month.dateID];
      const firstMonth = this.periodsData[months[0].dateID];
      const prevMonth = prevMonthDate ? this.periodsData[prevMonthDate.dateID] : null;
      const prevMonthsDates = (month, prevMonthsNumber) =>
        months.filter((c) => c.Order <= month.Order && c.Order > month.Order - prevMonthsNumber);
      const prevSingleMonthDate = (month, prevMonths) =>
        this.periodsData[months.find((c) => c.Order === month.Order - prevMonths).dateID];

      if (prevMonth) {
        if (this.RateChange === RateChange.false) {
          curMonth.IncomeTaxRate.Formula = `${firstMonth.IncomeTaxRate} * 100`;
        } else {
          const tmpValue = curMonth.IncomeTaxRate.Value;
          curMonth.IncomeTaxRate.Formula = null;
          curMonth.IncomeTaxRate.Value = tmpValue;
        }
        curMonth.IncomeTaxPayableOpenningBalance.Formula = `${prevMonth.IncomeTaxPayableClosingBalance}`;
        curMonth.CumulativeCalculatedAccruedIncomeTax.Formula = `${prevMonth.CumulativeCalculatedAccruedIncomeTax} + ${curMonth.CalculatedAccruedIncomeTax}`;
        curMonth.AccruedIncomeTax.Formula = `MxMath.IFELSE(${prevMonth.CumulativeCalculatedAccruedIncomeTax} < 0 && ${curMonth.CumulativeCalculatedAccruedIncomeTax} < 0,0, MxMath.IFELSE(${prevMonth.CumulativeCalculatedAccruedIncomeTax} < 0 && ${curMonth.CumulativeCalculatedAccruedIncomeTax} >= 0,${curMonth.CumulativeCalculatedAccruedIncomeTax},MxMath.IFELSE(${prevMonth.CumulativeCalculatedAccruedIncomeTax} >= 0 && ${curMonth.CumulativeCalculatedAccruedIncomeTax} < 0,${prevMonth.CumulativeCalculatedAccruedIncomeTax} * -1, MxMath.IFELSE(${prevMonth.CumulativeCalculatedAccruedIncomeTax} >= 0 && ${curMonth.CumulativeCalculatedAccruedIncomeTax} >= 0,${curMonth.CumulativeCalculatedAccruedIncomeTax} - ${prevMonth.CumulativeCalculatedAccruedIncomeTax}, 0))))`;
      } else {
        curMonth.IncomeTaxPayableOpenningBalance.Formula = `${this.PayableOpenningBalance}`;
        curMonth.CumulativeCalculatedAccruedIncomeTax.Formula = `${curMonth.CalculatedAccruedIncomeTax}`;
        curMonth.AccruedIncomeTax.Formula = `MxMath.IFELSE(${curMonth.CumulativeCalculatedAccruedIncomeTax} > 0,${curMonth.CumulativeCalculatedAccruedIncomeTax},0 )`;
      }

      if (month.Order === 0) {
        curMonth.IncomeTaxPayments.Formula = `MxMath.IFELSE(${curMonth.IncomeTaxPayableOpenningBalance} > 0, ${curMonth.IncomeTaxPayableOpenningBalance}, null)`;
      } else if (month.Order >= 12 / this.TaxPaymentFrequency.Value) {
        const BeforeDate = prevSingleMonthDate(month, 12 / this.TaxPaymentFrequency.Value);
        if (BeforeDate && BeforeDate.IncomeTaxPayments.Formula) {
          curMonth.IncomeTaxPayments.Formula = `MxMath.IFELSE(${curMonth.IncomeTaxPayableOpenningBalance} > 0, ${curMonth.IncomeTaxPayableOpenningBalance}, null)`;
        } else {
          curMonth.IncomeTaxPayments.Formula = null;
          curMonth.IncomeTaxPayments.Value = 0;
        }
      } else {
        curMonth.IncomeTaxPayments.Formula = null;
        curMonth.IncomeTaxPayments.Value = 0;
      }

      curMonth.EarningsBeforeTax.Formula = `${reportProfitLoss.getItemByDateSufix(month.sufix)}`;
      curMonth.CalculatedAccruedIncomeTax.Formula = `${curMonth.IncomeTaxRate} * ${curMonth.EarningsBeforeTax}`;
      curMonth.IncomeTaxPayableClosingBalance.Formula = `${curMonth.AccruedIncomeTax} + ${curMonth.IncomeTaxPayableOpenningBalance} - ${curMonth.IncomeTaxPayments}`;
      curMonth.Totals.Formula = `${curMonth.AccruedIncomeTax}`;
    });
  };

  createYearsFormulas = () => {
    const years = this.getYearDatesAll();
    const reportProfitLoss = global.Modeliks.DriversStore.find(
      (d) => d.Ref_Field === ProfitLoss.DriversDesc.ProfitBeforeTax.driverID,
    );

    years.forEach((year) => {
      const curYear = this.periodsData[year.dateID];
      const months = this.periodsData[year.dateID].Date.monthIndexes.map(
        (index) => this.periodsData[index],
      );
      const lastMonth = months[months.length - 1];
      const firstMonth = months[0];

      curYear.AccruedIncomeTax.Formula = `MxMath.Sum([${months.map((c) => c.AccruedIncomeTax.ID_f).join(",")}])`;
      curYear.CalculatedAccruedIncomeTax.Formula = `MxMath.Sum([${months.map((c) => c.CalculatedAccruedIncomeTax.ID_f).join(",")}])`;
      curYear.CumulativeCalculatedAccruedIncomeTax.Formula = `${lastMonth.CumulativeCalculatedAccruedIncomeTax}`;
      curYear.IncomeTaxPayableOpenningBalance.Formula = `${firstMonth.IncomeTaxPayableOpenningBalance}`;
      curYear.IncomeTaxPayments.Formula = `MxMath.Sum([${months.map((c) => c.IncomeTaxPayments.ID_f).join(",")}])`;
      curYear.IncomeTaxPayableClosingBalance.Formula = `${lastMonth.IncomeTaxPayableClosingBalance}`;
      curYear.EarningsBeforeTax.Formula = `${reportProfitLoss.getItemByDateSufix(year.sufix)}`;
      curYear.Totals.Formula = `${curYear.AccruedIncomeTax}`;

      if (year.Active) {
        months.forEach((month) => {
          month.EarningsBeforeTax.Formula = `${curYear.EarningsBeforeTax} / 12`;
        });

        if (this.RateChange === RateChange.false) {
          curYear.IncomeTaxRate.Formula = `(${curYear.AccruedIncomeTax} / ${curYear.EarningsBeforeTax}) * 100`;
        } else {
          const tempValue = curYear.IncomeTaxRate.Value;
          curYear.IncomeTaxRate.Formula = null;
          curYear.IncomeTaxRate.Value = tempValue;

          months.forEach((month) => {
            month.IncomeTaxRate.Formula = `${curYear.IncomeTaxRate} * 100`;
          });
        }
      } else {
        curYear.IncomeTaxRate.Formula = `(${curYear.AccruedIncomeTax} / ${curYear.EarningsBeforeTax}) * 100`;
      }
    });
  };

  setTax = () => {
    this.buildPeriodsData();
    this.createMonthsFormulas();
    this.createYearsFormulas();
  };

  static convert_Tax = (tax) => {
    const driver = global.Modeliks.TaxesStore.find((d) => d.TaxType === TaxesTypes.IncomeTax);

    if (driver) {
      return driver;
    } else {
      const newTax = new IncomeTax();
      newTax.ID = tax.ID;
      newTax.Name = tax.Name;
      newTax.Totals = CalculatedDriver.createDriverFromTable(
        newTax,
        IncomeTax.DriversDesc.Total.driverID,
        UnitTypes.Price,
        DriverCategories.Sum,
      );
      newTax.ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
      newTax.TaxPaymentFrequency = new CalculatedDriver_Values(
        null,
        newTax.getTaxPaymentFrequencyID(),
        null,
        UnitTypes.Integer,
      );
      newTax.TaxPaymentFrequency.Value = PayBackOptions[0].value;
      newTax.PayableOpenningBalance = new CalculatedDriver_Values(
        null,
        newTax.getPayableOpenningBalanceID(),
        null,
        UnitTypes.Price,
      );
      newTax.createDrivers();
      newTax.createExtraPeriods();
      newTax.IsCreated = true;
      newTax.TaxType = TaxesTypes.IncomeTax;
      return newTax;
    }
  };
}

export default IncomeTax;
