import React from "react";
import { DialogActions } from "@mui/material";
import ButtonDelete from "./ButtonDelete";
import ButtonNext from "./ButtonNext";
import CancelDialog from "../../settings/CancelDialog";
import "./ButtonsActions.scss";
import PropTypes from "prop-types";
import Revenue from "../../../../data/Finance/Revenue";
import Mx_MainLoader from "../../../Loaders/MainLoader/Mx_MainLoader";
import { AssetCategories } from "../../../../data/Finance/Assets/constants";
import { DriverPeriodTypes } from "../../../../data/Finance/CalculatedDriver/constants";
import { TrashCan2 } from "../../../icons/svgIcons";
import Mx_Tooltip_Hover from "../../../icons/Mx_Tooltip_Hover";

const notAllowedToDeleteDriverNames = [
  "Other Long Term Liability",
  "Long term asset purchases payable",
];

class ButtonsActions extends React.Component {
  deletingInProgress = false;

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      cancelDialog: false,
      deleteDialog: false,
      cancelRevenues: false,
      loading: false,
      driversToDelete: [],
    };
    this.isVat = "total_accrued_vat_tax";
    this.isSalesTax = "total_accrued_sales_tax";
    this.shouldCloseDialog = true;
  }

  manageDialog = () => {
    if (this.props.stream.db_record) {
      this.setState({
        deleteDialog: !this.state.deleteDialog,
        cancelRevenues: this.getDriversToDelete(this.props.stream.Totals),
      });
    }
  };

  deleteStream = (buttonEffect = null, shouldOnlyDelete = false) => {
    this.props.stream.Delete(() => {
      global.Modeliks.DriverValuesStore.filter((c) => c.db_record && c.db_record._id)[0].Save(
          undefined,
          true,
      );
      if (this.shouldCloseDialog || this.props.tableActions) {
        this.state.cancelDialog = false;
        this.props.close(true);
      } else {
        this.setStream();
        this.forceUpdate();
      }
    });
  };

  checkForDelete = (driver) => {
    const checkDriversForDelete = this.getDriversToDelete(driver);
    if (checkDriversForDelete) {
      checkDriversForDelete.forEach((d) => {
        if (d.Ref_Table === global.Modeliks.Tables.Finance_Taxes.TableName) {
          if (d.Formula.includes(driver.Ref_Table) && d.Formula.includes(driver.Ref_ID)) {
            d.deleteTaxesDrivers(null, driver);
          }
        }
      });
      this.state.cancelRevenues.push(
        ...checkDriversForDelete.filter((d) => {
          if (d.PeriodType !== DriverPeriodTypes.Previous) {
            if (
              d.Ref_Table === global.Modeliks.Tables.Dashboards_CustomKPI.TableName ||
              this.state.cancelRevenues.indexOf(d) === -1
            ) {
              return d;
            }
          }
        }),
      );
    }
  };

  checkCustomKpiDelete = (driver) => {
    const checkDriversForDelete = this.getDriversToDelete(driver);
  };

  getDriversToDelete = (stream) => stream.getDriversToDelete();

  deleteAllUsedDrivers = () => {
    let counter = 0;

    for (let i = 0; i < this.state.cancelRevenues.length; i++) {
      const curStreamParent = this.state.cancelRevenues[i].getParentStream();
      switch (curStreamParent) {
        case null:
          const curStream = this.state.cancelRevenues[i];

          switch (curStream.Ref_Table) {
            case global.Modeliks.Tables.Finance_Taxes.TableName:
              if (
                curStream.Formula &&
                curStream.Formula.includes(this.props.stream.Totals.Ref_Table) &&
                curStream.Formula.includes(this.props.stream.Totals.Ref_ID)
              ) {
                curStream.Delete(() => {
                  counter = counter + 1;

                  if (this.state.cancelRevenues.length === counter) {
                    this.deleteStream(null, true);
                  }
                }, this.props.stream);
              } else {
                counter = counter + 1;
                if (this.state.cancelRevenues.length === counter) {
                  this.deleteStream(null, true);
                }
              }

              break;
            case global.Modeliks.Tables.Dashboards_CustomKPI.TableName:
              const CustomKPI = global.Modeliks.CustomKPIStore.getItem(curStream.Ref_ID);
              if (curStream.Ref_Field === "custom_indicator") {
                let newFormula = null;
                let driversBefore = CustomKPI.Totals.getChildDrivers()
                  ? CustomKPI.Totals.getChildDrivers()
                  : [];
                if (driversBefore.length > 0) {
                  newFormula = driversBefore.filter((d) => d.Ref_ID !== curStream.Ref_ID);
                  if (newFormula.length > 0) {
                    CustomKPI.Totals.setFormula_Sum(newFormula);
                  } else {
                    CustomKPI.Totals.removeFormula();
                  }
                }
                this.checkForDelete(curStream);
                curStream.Delete(() => {
                  CustomKPI.Totals.Save(() => {
                    counter = counter + 1;
                    if (this.state.cancelRevenues.length === counter) {
                      this.deleteStream(null, true);
                    }
                  });
                });
              } else if (curStream.Ref_Field === "total") {
                let newFormula = null;
                let driversBefore = CustomKPI.Totals.getChildDrivers()
                  ? CustomKPI.Totals.getChildDrivers()
                  : [];
                if (driversBefore.length > 0) {
                  newFormula = driversBefore.filter(
                    (d) =>
                      d.Ref_ID !== this.props.stream.Totals.Ref_ID &&
                      !this.state.cancelRevenues.map((d) => d.Ref_ID).includes(d.Ref_ID),
                  );
                  if (newFormula.length > 0) {
                    CustomKPI.Totals.setFormula_Sum(newFormula);
                  } else {
                    CustomKPI.Totals.Formula = null;
                    CustomKPI.Totals.removeFormula();
                  }
                  CustomKPI.Totals.Save(() => {
                    counter = counter + 1;
                    if (this.state.cancelRevenues.length === counter) {
                      this.deleteStream(null, true);
                    }
                  }, true);
                } else {
                  counter = counter + 1;
                  if (this.state.cancelRevenues.length === counter) {
                    this.deleteStream(null, true);
                  }
                }
              }

              break;
            default:
              this.checkForDelete(this.state.cancelRevenues[i]);
              if (this.state.cancelRevenues[i].Ref_Field === "value") {
                this.state.cancelRevenues[i].Delete(() => {
                  counter = counter + 1;
                  if (this.state.cancelRevenues.length === counter) {
                    this.deleteStream(null, true);
                  }
                });
              } else {
                counter = counter + 1;
                if (this.state.cancelRevenues.length === counter) {
                  this.deleteStream(null, true);
                }
              }
          }
          break;
        default:
          this.checkForDelete(this.state.cancelRevenues[i]);
          counter = counter + 1;
          if (this.state.cancelRevenues.length === counter) {
            this.deleteStream(null, true);
          }
          curStreamParent.Delete(() => {
            counter = counter + 1;
            if (this.state.cancelRevenues.length === counter) {
              this.deleteStream(null, true);
            }
          });
      }
    }
  };

  manageDelete = () => {
    if (this.deletingInProgress) {
      return;
    }
    this.deletingInProgress = true;

    if (this.state.cancelRevenues && this.state.cancelRevenues.length > 0) {
      this.deleteAllUsedDrivers();
    } else {
      this.deleteStream();
    }
  };

  notAllowedStreams = () => {
    const validDrivers = [
      ...new Set(
        this.state.cancelRevenues.filter(
          (d) =>
            !notAllowedToDeleteDriverNames.includes(d.DriverName) &&
            !Object.values(AssetCategories).some((cat) => d.Ref_ID.toString().includes(cat)),
        ),
      ),
    ];
    let cancelDrivers = "";

    if (validDrivers.length > 0) {
      cancelDrivers = validDrivers.map((d) => (
        <p className="scdfab_dialog_list" key={d.ID}>
          <p className="scdfab_dialog_first_item">{d.DriverName + " "}</p>-<p>{d.Ref_Table}</p>
        </p>
      ));
      return (
        <div className="scdfab_dialog_paragraph">
          {`Deleting ${this.props.deleteName} will also delete the following inputs which directly depend on ${this.props.deleteName}:`}
          {cancelDrivers}
        </div>
      );
    }
  };

  onNextClick = () => {
    if (this.props.enableDelete) {
      if (this.props.stream.db_record) {
        if (
          this.props.finance &&
          this.props.stream.db_record.FinanceType !== this.props.finance.FinanceType
        ) {
          this.shouldCloseDialog = false;
          this.manageDialog();
        } else {
          this.props.onNextClick();
        }
      } else {
        if (this.props.revenue || this.props.finance) {
          this.props.onNextClick();
        } else {
          this.setStream();
        }
      }
    } else {
      if (this.props.stream.db_record && !this.props.convertNone) {
        this.props.checkStream((hasChange = false) => {
          if (hasChange) {
            this.shouldCloseDialog = false;
            this.manageDialog();
          } else {
            this.props.onNextClick();
          }
        });
      } else {
        if (this.props.convertNone) {
          this.props.onNextClick();
        } else {
          this.setStream();
        }
      }
    }
  };

  setStream = () => {
    this.props.converter((stream, isSameStream = false) => {
      if (this.props.revenue) {
        const drivers = this.props.stream.getAllDrivers();
        drivers.forEach((d) => global.Modeliks.DriversStore.remove(d));
        if (this.props.stream.hasOwnProperty("children") && this.props.stream.children.length > 0) {
          this.props.stream.children.forEach((c) => {
            c.getAllDrivers().forEach((d) => global.Modeliks.DriversStore.remove(d));
          });
        }
      } else if (this.props.financing && !isSameStream) {
        const drivers = this.props.finance.getAllDrivers();
        drivers.forEach((d) => global.Modeliks.DriversStore.remove(d));
      } else if (!isSameStream) {
        const drivers = this.props.stream.getAllDrivers();
        drivers.forEach((d) => global.Modeliks.DriversStore.remove(d));
      }

      // const drivers = this.props.stream.getAllDrivers();
      // drivers.forEach(d => global.Modeliks.DriversStore.remove(d))
      this.props.onNextClick(false, stream);
    });
  };

  onDeleteStream = (e) => {
    this.props.isDeletedRecord.delete = true;
    this.setState({ loading: true }, this.manageDelete);
  };

  render() {
    if (this.props.tableActions) {
      if (this.state.loading) {
        return (
          <CancelDialog
            maxHeight={
              this.state.cancelRevenues.filter(
                (d) =>
                  !notAllowedToDeleteDriverNames.includes(d.DriverName) &&
                  !Object.values(AssetCategories).some((cat) => d.Ref_ID.toString().includes(cat)),
              ).length > 0 && `500px`
            }
            title={`Delete ${this.props.RevenueName}`}
            open={this.state.loading}
          >
            {<Mx_MainLoader />}
          </CancelDialog>
        );
      }

      return (
        <>
          <Mx_Tooltip_Hover
            singleCell
            disableStyle
            title="Delete"
            placement="top"
            arrow
            onClick={this.manageDialog}
          >
            <TrashCan2 width={"12px"} height={"12px"} />
          </Mx_Tooltip_Hover>

          {!this.state.loading && this.state.deleteDialog && this.props.stream && (
            <CancelDialog
              btnName="Confirm"
              firstBtnName="Cancel"
              maxHeight={
                this.state.cancelRevenues.filter(
                  (d) =>
                    !notAllowedToDeleteDriverNames.includes(d.DriverName) &&
                    !Object.values(AssetCategories).some((cat) =>
                      d.Ref_ID.toString().includes(cat),
                    ),
                ).length > 0 && `500px`
              }
              title={`Delete ${this.props.RevenueName}`}
              onDelete={this.onDeleteStream}
              open={this.state.deleteDialog}
              onExit={this.manageDialog}
            >
              Are you sure that you want to delete {this.props.RevenueName}?
              {this.state.cancelRevenues.length > 0 && this.notAllowedStreams()}
            </CancelDialog>
          )}
        </>
      );
    }

    return (
      <>
        <DialogActions className="scdfab_dialog_actions_div">
          <ButtonDelete
            close={this.props.close}
            stream={this.props.stream}
            onClick={this.manageDialog}
          />
          <ButtonNext nextButtonTitle={this.props.nextButtonTitle} onClick={this.onNextClick} />
        </DialogActions>

        {this.state.loading && <Mx_MainLoader />}
        {!this.state.loading && this.state.deleteDialog && (
          <CancelDialog
            btnName="Confirm"
            firstBtnName="Cancel"
            maxHeight={
              this.state.cancelRevenues.filter(
                (d) =>
                  !notAllowedToDeleteDriverNames.includes(d.DriverName) &&
                  !Object.values(AssetCategories).some((cat) => d.Ref_ID.toString().includes(cat)),
              ).length > 0 && `500px`
            }
            title={`Delete ${this.props.RevenueName}`}
            onDelete={this.onDeleteStream}
            open={this.state.deleteDialog}
            onExit={this.manageDialog}
          >
            Are you sure that you want to delete {this.props.RevenueName}?
            {this.state.cancelRevenues.length > 0 && this.notAllowedStreams()}
          </CancelDialog>
        )}
      </>
    );
  }
}

ButtonsActions.propTypes = {
  RevenueName: PropTypes.string,
  onNextClick: PropTypes.func,
  deleteName: PropTypes.string,
  onClose: PropTypes.func,
  enableDelete: PropTypes.bool,
  revenue: PropTypes.bool,
  financing: PropTypes.bool,
  onChangeShouldDeleteStream: PropTypes.bool,
  tableActions: PropTypes.bool,
};
ButtonsActions.defaultProps = {
  name: "revenue",
  RevenueName: "Revenue",
  deleteName: "",
  onChangeShouldDeleteStream: true,
  enableDelete: false,
  revenue: false,
  financing: false,
  tableActions: false,
};

export default ButtonsActions;
