import React, { Component } from "react";
import CircleLoader from "../Loaders/CircleLoader/CircleLoader";
import { referrals } from "../../Enums/ModeliksEnum";

class SuccessPayment extends Component {
  componentDidMount() {
    const transaction_id = window.location.href.split("/").pop();
    console.log("transaction_id", transaction_id);

    (async () => {
      const rawResponse = await fetch("/api/init", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          AccountID: window.localStorage.getItem("token")
            ? window.Modeliks.Account.ID
            : window.localStorage.getItem("Account_ID"),
          transaction_id: transaction_id,
        }),
      });
      const content = await rawResponse.json();
      console.log("content", content);
      if (content === "OK") {
        let isImpactUser = false;
        let clickid = null;

        if (window.localStorage.getItem("token")) {
          if (window.Modeliks.referralId) {
            isImpactUser = true;
            clickid = window.Modeliks.referralId;
          }

          console.log("logged in", global.Modeliks.redirectUrl);

          if (global.Modeliks.redirectUrl) {
            let url = global.Modeliks.redirectUrl;
            global.Modeliks.redirectUrl = null;
            console.log("enters here redirect", url);
            window.location.href = url;
          } else {
            console.log("enters else");
            window.location.href = "/";
          }
        } else {
          window.location.href = "/VerificationSent";
          console.log("isImpactUser", window.localStorage.getItem("clickid"));
          if (
            window.localStorage.getItem("clickid") &&
            window.localStorage.getItem("referralSource") &&
            window.localStorage.getItem("referralSource") == referrals.imapct
          ) {
            console.log("enters impact user");
            isImpactUser = true;
            clickid = window.localStorage.getItem("clickid");
          }
        }

        if (isImpactUser) {
          console.log("impact userrrr");
          const rawResponse = await fetch("/api/sendImpactEvent", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              account_id: window.localStorage.getItem("token")
                ? window.Modeliks.Account.ID
                : window.localStorage.getItem("Account_ID"),
              transaction_id: transaction_id,
              clickid: clickid,
              email: window.localStorage.getItem("token")
                ? window.Modeliks.User.Email
                : window.localStorage.getItem("Email"),
            }),
          });
          const content = await rawResponse.json();
        }
      }
    })();
  }

  render() {
    return <CircleLoader />;
  }
}

export default SuccessPayment;
