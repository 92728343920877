import React from "react";
import "./ButtonsActions.scss";
import { TrashCan } from "../../../icons/svgIcons";
import ButtonMui from "../../../buttons/buttonMui/buttonMui";

export default function ButtonDelete(props) {
  if (props.stream && !props.stream.db_record) {
    return (
      <ButtonMui
        label={"Cancel"}
        width={130}
        variant={"contained"}
        color={"red"}
        onClick={props.close}
      />
    );
  } else {
    return (
      <ButtonMui
        label={"Delete"}
        width={130}
        variant={"contained"}
        color={"red"}
        startIcon={<TrashCan />}
        onClick={props.onClick}
      />
    );
  }
}
