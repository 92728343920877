export const DashboardSectionsObj = {
  DashboardSections: {
    FinancialStatements: {
      name: "Financial Statements",
      subsections: {
        ProfitAndLoss: {
          name: "Profit And Loss",
          charts: {},
        },
        BalanceSheet: {
          name: "Balance Sheet",
          charts: {},
        },
        CashFlow: {
          name: "Cash Flow",
          charts: {},
        },
      },
    },
    Charts: {
      name: "Charts",
      subsections: {
        FinancialSummary: {
          name: "Financial Summary",
          charts: {},
        },
        ProfitAndLoss: {
          name: "Profit And Loss",
          charts: {},
        },
        BalanceSheet: {
          name: "Balance Sheet",
          charts: {},
        },
        CashFlow: {
          name: "Cash Flow",
          charts: {},
        },
      },
    },
    SingleIndicators: {
      name: "Single Indicators",
      subsections: {
        Indicator: {
          name: "Indicator",
          charts: {},
        },
      },
    },
    Ratios: {
      name: "Ratios",
      subsections: {
        ProfitabilityRatios: {
          name: "Profitability Ratios",
          charts: {},
        },
        LiquidityRatios: {
          name: "Liquidity Ratios",
          charts: {},
        },
        LeverageRatios: {
          name: "Leverage Ratios",
          charts: {},
        },
        EfficiencyRatios: {
          name: "Efficiency Ratios",
          charts: {},
        },
      },
    },
    ForecastSummary: {
      name: "Summary Annual",
      subsections: {
        Subsection1: {
          name: "Charts",
          charts: {},
        },
      },
    },
    ForecastSummaryMonthly: {
      name: "Summary Monthly",
      subsections: {
        Subsection1: {
          name: "Charts",
          charts: {},
        },
      },
    },
    SaasDashboard: {
      name: "Saas Dashboard",
      subsections: {
        Subsection1: {
          name: "Saas Dashboard",
          charts: {},
        },
      },
    },
    SaasDashboardMonthly: {
      name: "Saas Dashboard Monthly",
      subsections: {
        Subsection1: {
          name: "Saas Dashboard Monthly",
          charts: {},
        },
      },
    },
  },
  SecondComparisonPeriod: "Forecast",
};
