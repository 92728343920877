import React, { Fragment, useState } from "react";
import "../HeaderTextEditor.scss";
import "../HeaderDefault.scss";
import { FillColor } from "../../components/IconButtons/SubMenuIcons";
import { SketchPicker } from "react-color";
import Tooltip from "@mui/material/Tooltip";
import { Menu, ControlledMenu, MenuItem, SubMenu } from "@szhsin/react-menu";
import { FillIcon } from "../../../../components/icons/svgIcons";

function FillColorEditComponent(props) {
  const preventEvent = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const onChange = (color) => {
    props.update(color);
  };

  const FillColorEdit = () => {
    return (
      <SubMenu
        onClick={preventEvent}
        label={({ open }) => (
          <Tooltip arrow title={""} placement={"bottom"}>
            <span className={"color_menu block o_i_btn text"}>
              <div>{props.name}</div>
              {/*<FillIcon active={open} color={!props.businessPlan ? !props.PitchScenarioInfo.BackgroundColor ? '#FFFFFF' : props.PitchScenarioInfo.BackgroundColor : !global.Modeliks.BusinessPlanConfig.BackgroundColor ? '#FFFFFF' : global.Modeliks.BusinessPlanConfig.BackgroundColor}/>*/}
            </span>
          </Tooltip>
        )}
      >
        <SketchPicker
          disableAlpha
          color={
            !props.businessPlan
              ? !props.PitchScenarioInfo.BackgroundColor
                ? "#FFFFFF"
                : props.PitchScenarioInfo.BackgroundColor
              : !global.Modeliks.BusinessPlanConfig.BackgroundColor
                ? "#FFFFFF"
                : global.Modeliks.BusinessPlanConfig.BackgroundColor
          }
          onChange={(event) => onChange(event.hex)}
        />
      </SubMenu>
    );
  };
  return <>{FillColorEdit()}</>;
}

export default FillColorEditComponent;
