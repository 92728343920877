import * as React from "react";
import Dialog from "@mui/material/Dialog";
import ButtonJ from "../Buttons/Button";
import TabsMenu from "../menus/TabsMenu/TabsMenu";
import "./SectionDialog.scss";
import IconButton from "@mui/material/IconButton";
import { BluePlus, Exit } from "../IconButtons/SubMenuIcons";
import "./SectionName.scss";
import CircleCheckboxM from "../../../../components/actions/CircleCheckboxM";
import Button from "../../../../components/actions/Button";

export default function PitchScenarioLimitDialog(props) {
  const [open, setOpen] = React.useState(true);
  const [success, setSuccess] = React.useState(false);
  const [name, setName] = React.useState("Custom Section");

  const handleClose = () => {
    props.onClose();
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        className={"name_dialog"}
        onClose={handleClose}
      >
        <>
          {/*<div className={'checkbox_container'}>*/}
          {/*<CloseIcon size='large' background={'red'}/>*/}
          {/*</div>*/}
          <div className="Label_2">You reached the limit</div>
          <div className="Paragraph_P4">
            You can add maximum 3 <br /> {props.label ? props.label : "pitch scenarios"}
          </div>
          <ButtonJ
            size="large"
            width="118px"
            padding="0 0 0 0"
            backgroundColor="primary"
            label={"OK"}
            onClick={() => handleClose()}
          />
        </>
      </Dialog>
    </React.Fragment>
  );
}
