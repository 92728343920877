import * as React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import styled from "styled-components";
import { Theme } from "../../theme/styles/theme";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { CircleCheckboxUnselected } from "../icons/svgIcons";
import CustomUnChecked from "./Checkboxes/CustomUnChecked";
import PropTypes from "prop-types";

const Typography = styled.span`
  cursor: pointer;
  font-family: Inter;
  font-weight: 400;
  font-size: 14px;
`;

function CircleCheckboxM(props) {
  return (
    <FormControlLabel
      value={props.value ? props.value : ""}
      control={
        <Checkbox
          id={props.id}
          name={props.name}
          onChange={props.onChange}
          value={props.value ? props.value : ""}
          icon={<CustomUnChecked size={props.size ? props.size : "small"} />}
          style={{
            transform: "scale(1.15)",
          }}
          checkedIcon={
            <CheckCircleIcon
              style={{
                color: Theme.colors.primary.lightblue["300"],
                width: "24px",
                height: "24px",
              }}
            />
          }
          sx={{
            "&.MuiSvgIcon-root": {
              color: "#FBFBFB",
              border: `1px solid red`,
              boxSizing: `border-box`,
              boxShadow: `inset 0px 0px 0px rgba(0, 0, 0, 0.2)`,
              borderRadius: `2px`,
            },
          }}
          checked={props.checked}
          disabled={props.disabled}
          size={props.size ? props.size : "small"}
          inputProps={{ "aria-label": "controlled" }}
        />
      }
      label={<Typography onChange={props.onChange}>{props.label}</Typography>}
      labelPlacement={"end"}
    />
  );
}

CircleCheckboxM.propTypes = {
  size: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  name: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
};

export default CircleCheckboxM;
