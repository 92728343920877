import React, { Fragment, useState } from "react";
import "./HeaderTextEditor.scss";
import HeaderPortal from "./HeaderPortal";
import all_icons from "../../all_icons";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FormatAlignCenter from "../components/IconButtons/FormatAlignCenter";
import FormatAlignRight from "../components/IconButtons/FormatAlignRight";
import FormatAlignLeft from "../components/IconButtons/FormatAlignLeft";
import FormatBold from "../components/IconButtons/FormatBold";
import FormatItalic from "../components/IconButtons/FormatItalic";
import FormatUnderline from "../components/IconButtons/FormatUnderline";
import MaterialSelect from "../components/Select/MaterialSelect";
import HeaderTableEditor from "./HeaderTableEditor";
import Border from "../components/IconButtons/Border";
import BorderTop from "../components/IconButtons/BorderTop";
import BorderBottom from "../components/IconButtons/BorderBottom";
import BorderLeft from "../components/IconButtons/BorderLeft";
import BorderRight from "../components/IconButtons/BorderRight";
import ColorPicker from "../components/ColorPicker/ColorPicker";
import RemoveColor from "../components/IconButtons/RemoveColor";
//import BorderWeight from "../components/IconButtons/BorderWeight";
import { Box, Slider } from "@mui/material";
import FormatColorPick from "../components/IconButtons/FormatColorPick";
import { SketchPicker } from "react-color";
import {
  Separator,
  Bold,
  Italic,
  Underline,
  FormatColor,
  Redo,
} from "../components/IconButtons/SubMenuIcons.js";
import { MenuItem as MenuItemSzhsin, SubMenu } from "@szhsin/react-menu";
import FontFamilyEditComponent from "../SlideHeader/EditorComponents/FontFamilyEditComponent";
import FontSizeEditComponent from "../SlideHeader/EditorComponents/FontSizeEditComponent";
import BorderWeightEditComponent from "../SlideHeader/EditorComponents/BorderWeightEditComponent";
import BorderDashEditComponent from "../SlideHeader/EditorComponents/BorderDashEditComponent";
import BorderColorEditComponent from "../SlideHeader/EditorComponents/BorderColorEditComponent";
import FillColorEditComponent from "../SlideHeader/EditorComponents/FillColorEditComponent";
import TextColorEditComponent from "../SlideHeader/EditorComponents/TextColorEditComponent";
import HighlightColorEditComponent from "../SlideHeader/EditorComponents/HighlightColorEditComponent";
import MoreOptionsMenuComponent from "../SlideHeader/EditorComponents/MoreOptionsMenuComponent";
import Tooltip from "@mui/material/Tooltip";
import ButtonJ from "../components/Buttons/Button";
import HeaderMidPortal from "../components/Portals/HeaderMidPortal";
import AlignEditComponent from "./EditorComponents/AlignEditComponent";
import SpacingEditComponent from "./EditorComponents/SpacingEditComponent";
import BulletingEditComponent from "./EditorComponents/BulletingEditComponent";
import NumberingEditComponent from "./EditorComponents/NumberingEditComponent";
import {
  ClearFormatting,
  DecreaseIndent,
  IncreaseIndent,
} from "../components/IconButtons/SubMenuIcons";
import ButtonMui from "../../../components/buttons/buttonMui/buttonMui";
import PitchHeaderButton from "../../../components/buttons/buttonMui/pitchHeaderButton";

function HeaderTextEditor(props) {
  const selected = props.visible;
  const [value, setValue] = useState(0);
  const onChange = (style) => {
    props.onChange(style);
  };
  const [anchorE2, setAnchorE2] = React.useState(null);
  const [anchorE15, setAnchorE15] = React.useState(null);
  const [style, setStyle] = React.useState(props.style);
  const [rowStyle, setRowStyle] = React.useState(props.selectedRowsStyle);
  const openColor = Boolean(anchorE2);
  const openMoreOptions = Boolean(anchorE15);

  const handleClick2 = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorE2(null);
  };
  const handleClick15 = (event) => {
    if (!anchorE15) setAnchorE15(event.currentTarget);
  };
  const handleClose15 = () => {
    setAnchorE15(null);
  };

  const renderHeader = () => {
    setStyle(props.getSelectedStyle());
    setRowStyle(props.getSelectedRowsStyle());

    setValue(value + 1);
  };

  props.headerFunc.renderHeader = renderHeader;

  const arr = [];

  const removeStyle = () => {
    let oldStruct = props.getOldStruct();
    let selectedRows = props.getSelectedRows();

    let selectedObject = props.getSelectedObject();
    if (selectedObject.length > 0) {
      selectedObject.forEach((p) => {
        if (Object.prototype.toString.call(p) == "[object Array]") {
          p.forEach((obj) => {
            let tmpObject = Object.assign({}, { fontSize: props.defaultFontSize });
            obj.style = tmpObject;
          });
        } else {
          let tmpObject = Object.assign({}, { fontSize: props.defaultFontSize });
          p.style = tmpObject;
        }
      });
    }

    if (selectedRows.length > 0) {
      selectedRows.forEach((row) => {
        row.bullet = false;
        row.characters.forEach((charachter) => {
          let tmpObject = Object.assign({}, { fontSize: props.defaultFontSize });
          charachter.style = tmpObject;
        });
      });
    }

    props.groupStruct(oldStruct);

    renderHeader();
  };

  const setBullet = (type, curType) => {
    let oldStruct = props.getOldStruct();

    let selectedRows = props.getSelectedRows();
    let counter = 0;
    let key = null;

    selectedRows.forEach((row) => {
      if (
        row.bullet &&
        (key === null || row.key === key) &&
        row.curType.replaceAll("'", "").replaceAll('"', "") ==
          curType.replaceAll("'", "").replaceAll('"', "")
      ) {
        key = row.key;
        counter++;
      }
    });

    if (counter !== selectedRows.length) {
      let key = "bullet_" + new Date().getTime();
      selectedRows.forEach((row) => {
        row.bullet = true;
        row.curType = curType;
        row.key = key;
        row.bulletType = type;
      });
    } else {
      selectedRows.forEach((row) => {
        row.bullet = false;
      });
    }

    props.groupStruct(oldStruct);

    renderHeader();
  };

  const increaseIndent = () => {
    let oldStruct = props.getOldStruct();

    let selectedRows = props.getSelectedRows();

    selectedRows.forEach((row) => {
      if (row.hasOwnProperty("style") && row.style.hasOwnProperty("marginLeft")) {
        let padding = parseInt(row.style.marginLeft.replace("px", "").replace("%", "")) + 20;
        if (padding < 160) {
          row.style.marginLeft = padding + "px";
        }
      } else {
        if (row.style) {
          row.style["marginLeft"] = "20px";
        } else {
          row["style"] = { marginLeft: "20px" };
        }
      }
    });

    props.groupStruct(oldStruct);
  };

  const decreaseIndent = () => {
    let oldStruct = props.getOldStruct();

    let selectedRows = props.getSelectedRows();

    selectedRows.forEach((row) => {
      if (row.hasOwnProperty("style") && row.style.hasOwnProperty("marginLeft")) {
        let padding = parseInt(row.style.marginLeft.replace("px", "").replace("%", ""));
        if (padding >= 20) {
          row.style.marginLeft = padding - 20 + "px";
        } else {
          row.style.marginLeft = "0px";
        }
      }
    });

    props.groupStruct(oldStruct);
  };

  const changeLineHeight = (value) => {
    let oldStruct = props.getOldStruct();

    let selectedRows = props.getSelectedRows();

    selectedRows.forEach((row) => {
      if (row.hasOwnProperty("style")) {
        let style = { ...row.style, lineHeight: value };
        row.style = style;
      } else {
        row.style = { lineHeight: value };
      }
    });

    props.groupStruct(oldStruct);
  };

  const changeSpacingBeforeItem = (value) => {
    let oldStruct = props.getOldStruct();

    let selectedRows = props.getSelectedRows();

    selectedRows.forEach((row) => {
      if (row.hasOwnProperty("style")) {
        let style = { ...row.style, paddingTop: value };
        row.style = style;
      } else {
        row.style = { paddingTop: value };
      }
    });

    props.groupStruct(oldStruct);
  };

  const changeSpacingAfterItem = (value) => {
    let oldStruct = props.getOldStruct();

    let selectedRows = props.getSelectedRows();

    selectedRows.forEach((row) => {
      if (row.hasOwnProperty("style")) {
        let style = { ...row.style, paddingBottom: value };
        row.style = style;
      } else {
        row.style = { paddingBottom: value };
      }
    });

    props.groupStruct(oldStruct);
  };

  const changeCustomSpacing = (lineSpacing, beforeSpacing, afterSpacing) => {
    let oldStruct = props.getOldStruct();

    let selectedRows = props.getSelectedRows();

    selectedRows.forEach((row) => {
      if (row.hasOwnProperty("style")) {
        let style = {
          ...row.style,
          lineHeight: lineSpacing,
          paddingTop: beforeSpacing,
          paddingBottom: afterSpacing,
        };
        row.style = style;
      } else {
        row.style = {
          lineHeight: lineSpacing,
          paddingTop: beforeSpacing,
          paddingBottom: afterSpacing,
        };
      }
    });

    props.groupStruct(oldStruct);
  };

  const toBoldExperimental = () => {
    props.setStyle("fontWeight", "bold", true);
  };

  const toItalicExperimental = () => {
    props.setStyle("fontStyle", "italic", true);
  };

  const toUnderlineExperimental = () => {
    props.setStyle("textDecoration", "underline", true);
  };

  const checkForSelection = () => {
    if (document.getSelection()) {
      let selection = document.getSelection();
      if (selection.anchorOffset === selection.focusOffset) return false;
      else return true;
    } else return false;
  };
  const TextEditGroup = (className = "s") => {
    return (
      <>
        <PitchHeaderButton
          tooltipText={"Bold"}
          className={className}
          label={<Bold active={style && style.fontWeight ? style.fontWeight === "bold" : false} />}
          onClick={toBoldExperimental}
        />
        <PitchHeaderButton
          tooltipText={"Italic"}
          className={className}
          label={
            <Italic active={style && style.fontStyle ? style.fontStyle === "italic" : false} />
          }
          onClick={toItalicExperimental}
        />
        <PitchHeaderButton
          tooltipText={"Underline"}
          className={className}
          label={
            <Underline
              active={style && style.textDecoration ? style.textDecoration === "underline" : false}
            />
          }
          onClick={toUnderlineExperimental}
        />
      </>
    );
  };
  const TextEditGroupMenu = (className = "s") => {
    return (
      <>
        <Tooltip arrow title={"Bold"}>
          <MenuItemSzhsin
            className={"SubMenuButton " + className}
            style={{ height: "auto", display: "flex", alignItems: "center" }}
          >
            <Bold
              active={style && style.fontWeight ? style.fontWeight === "bold" : false}
              onClick={toBoldExperimental}
            />
          </MenuItemSzhsin>
        </Tooltip>
        <Tooltip arrow title={"Italic"}>
          <MenuItemSzhsin
            className={"SubMenuButton " + className}
            style={{ height: "auto", display: "flex", alignItems: "center" }}
          >
            <Italic
              active={style && style.fontStyle ? style.fontStyle === "italic" : false}
              onClick={toItalicExperimental}
            />
          </MenuItemSzhsin>
        </Tooltip>
        <Tooltip arrow title={"Underline"}>
          <MenuItemSzhsin
            className={"SubMenuButton " + className}
            style={{ height: "auto", display: "flex", alignItems: "center" }}
          >
            <Underline
              active={style && style.textDecoration ? style.textDecoration === "underline" : false}
              onClick={toUnderlineExperimental}
            />
          </MenuItemSzhsin>
        </Tooltip>
      </>
    );
  };

  const Color = () => {
    return (
      <>
        <FormatColorPick
          open={openColor}
          style={{ marginRight: "15px", backgroundColor: openColor && "lightblue" }}
          aria-controls={openColor ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openColor ? "true" : undefined}
          onClick={handleClick2}
        />
        <Menu
          id="basic-menu"
          anchorEl={anchorE2}
          open={openColor}
          onClose={handleClose2}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <div style={{ padding: "8px", fontSize: "15px" }}>
            <span style={{ display: "block" }}>text color</span>
            <div style={{ display: "flex", paddingTop: "2px" }}>
              <input
                style={{ marginRight: "5px", marginTop: "-2px", width: "32px", height: "25px" }}
                className={"button"}
                id={"fontColor"}
                type={"color"}
                value={style.color ? style.color : null}
                onChange={(event) => props.setStyle("color", event.target.value, false)}
              />
              <div
                style={{
                  marginRight: "5px",
                  width: "30px",
                  height: "20px",
                  backgroundColor: "#000000",
                  border: "1px solid",
                }}
                onClick={(event) => props.onChange({ color: "#000000" })}
              />
              <div
                style={{
                  marginRight: "5px",
                  width: "30px",
                  height: "20px",
                  backgroundColor: "#ffffff",
                  border: "1px solid",
                }}
                onClick={(event) => props.onChange({ color: "#ffffff" })}
              />
              <div
                style={{
                  marginRight: "5px",
                  width: "30px",
                  height: "20px",
                  backgroundColor: "#ffffff",
                  border: "1px solid",
                  textAlign: "center",
                }}
                onClick={(event) => props.onStyleRemove(["color"])}
              >
                <span style={{ color: "red", fontWeight: 500 }}>/</span>
              </div>
            </div>

            <div
              style={{
                height: "1px",
                backgroundColor: "gray",
                marginTop: "10px",
                marginBottom: "4px",
              }}
            />
            <span style={{ display: "block", marginBottom: "4px" }}>background color</span>
            <div style={{ display: "flex" }}>
              <input
                style={{ marginRight: "5px", marginTop: "-2px", width: "32px", height: "25px" }}
                className={"button"}
                id={"fontColor"}
                type={"color"}
                value={style.backgroundColor ? style.backgroundColor : null}
                onChange={(event) => onChange({ backgroundColor: event.target.value })}
              />
              <div
                style={{
                  marginRight: "5px",
                  width: "30px",
                  height: "20px",
                  backgroundColor: "#000000",
                  border: "1px solid",
                }}
                onClick={(event) => props.onChange({ backgroundColor: "#000000" })}
              />
              <div
                style={{
                  marginRight: "5px",
                  width: "30px",
                  height: "20px",
                  backgroundColor: "#ffffff",
                  border: "1px solid",
                }}
                onClick={(event) => props.onChange({ backgroundColor: "#ffffff" })}
              />
              <div
                style={{
                  marginRight: "5px",
                  width: "30px",
                  height: "20px",
                  backgroundColor: "#ffffff",
                  border: "1px solid",
                  textAlign: "center",
                }}
                onClick={(event) => props.onStyleRemove(["backgroundColor"])}
              >
                <span style={{ color: "red", fontWeight: 500 }}>/</span>
              </div>
            </div>

            <div style={{ display: "block", marginTop: "4px", marginBottom: "4px" }} />
            <div style={{ display: "flex" }}>
              <div
                style={{
                  marginRight: "5px",
                  width: "30px",
                  height: "20px",
                  backgroundColor: "#BAE4E6",
                  border: "1px solid",
                }}
                onClick={(event) => props.onChange({ backgroundColor: "#BAE4E6" })}
              />
              <div
                style={{
                  marginRight: "5px",
                  width: "30px",
                  height: "20px",
                  backgroundColor: "#76E6A6",
                  border: "1px solid",
                }}
                onClick={(event) => props.onChange({ backgroundColor: "#76E6A6" })}
              />
              <div
                style={{
                  marginRight: "5px",
                  width: "30px",
                  height: "20px",
                  backgroundColor: "#488EED",
                  border: "1px solid",
                }}
                onClick={(event) => props.onChange({ backgroundColor: "#488EED" })}
              />
              <div
                style={{
                  marginRight: "5px",
                  width: "30px",
                  height: "20px",
                  backgroundColor: "#FFC875",
                  border: "1px solid",
                }}
                onClick={(event) => props.onChange({ backgroundColor: "#FFC875" })}
              />
            </div>
          </div>
        </Menu>
      </>
    );
  };

  return (
    <div
      onMouseDown={(e) => {
        if (e.target.className !== "input") {
          // e.preventDefault();
          e.stopPropagation();
        }
      }}
    >
      <HeaderMidPortal>
        <FillColorEditComponent
          className={"xxxxs"}
          onChange={onChange}
          style={props.tbStyle}
          setRestoreSelection={() => {}}
        />

        <BorderColorEditComponent
          className={"xxxxs"}
          onChange={onChange}
          style={props.tbStyle}
          setRestoreSelection={() => {}}
        />

        <BorderWeightEditComponent
          className={"xxxxs"}
          objType={"textObject"}
          onChange={onChange}
          style={props.tbStyle}
          setRestoreSelection={() => {}}
        />

        <BorderDashEditComponent
          className={"xxxxs"}
          objType={"textObject"}
          onChange={onChange}
          style={props.tbStyle}
          setRestoreSelection={() => {}}
        />

        <Separator className={"xxxxs"} />

        <FontFamilyEditComponent className={"xxxxs"} onChange={props.setStyle} style={style} />

        <FontSizeEditComponent
          className={"xxxs"}
          onChange={props.setStyle}
          style={
            style && style.fontSize
              ? parseInt(style.fontSize.replace("px", "").replace("pt", ""))
              : ""
          }
        />
        {TextEditGroup("xxs")}

        <TextColorEditComponent
          header
          shouldConfirm
          renderHeader={renderHeader}
          className={"xs"}
          onChange={props.setStyle}
          color={props.style.color}
          setRestoreSelection={() => {}}
        />

        <HighlightColorEditComponent
          header
          shouldConfirm
          renderHeader={renderHeader}
          className={"xs"}
          onValueChange={props.onValueChange}
          value={props.value}
          onChange={props.setStyle}
          style={style}
          setRestoreSelection={() => {}}
        />
        <AlignEditComponent
          className={"s"}
          menu
          onChange={onChange}
          style={style}
          objType={"textObject"}
        />
        <SpacingEditComponent
          className={"s"}
          changeCustomSpacing={changeCustomSpacing}
          objType={"textObject"}
          changeSpacingBeforeItem={changeSpacingBeforeItem}
          changeSpacingAfterItem={changeSpacingAfterItem}
          onChange={changeLineHeight}
          menu
          style={style}
          rowStyle={rowStyle}
        />
        <BulletingEditComponent
          className={"m"}
          menu
          onValueChange={props.onValueChange}
          rowStyle={rowStyle}
          value={props.value}
          renderHeader={renderHeader}
          getSelectedRowsStyle={props.getSelectedRowsStyle}
          onChange={onChange}
          style={style}
          setBullet={setBullet}
        />
        <NumberingEditComponent
          className={"m"}
          menu
          onValueChange={props.onValueChange}
          rowStyle={rowStyle}
          value={props.value}
          renderHeader={renderHeader}
          getSelectedRowsStyle={props.getSelectedRowsStyle}
          onChange={onChange}
          style={style}
          setBullet={setBullet}
        />
        <ButtonMui
          tooltipText={"Decrease Indent"}
          className={"l"}
          onClick={(e) => {
            decreaseIndent();
          }}
          variant={"text"}
          isIconButton
          roundnessType={"squared"}
          color={"black"}
          label={<DecreaseIndent />}
        />
        <ButtonMui
          tooltipText={"Increase Indent"}
          className={"l"}
          onClick={(e) => {
            increaseIndent();
          }}
          variant={"text"}
          isIconButton
          roundnessType={"squared"}
          color={"black"}
          label={<IncreaseIndent />}
        />
        <ButtonMui
          tooltipText={"Clear Formatting"}
          className={"xl"}
          onClick={(e) => {
            removeStyle();
          }}
          variant={"text"}
          isIconButton
          roundnessType={"squared"}
          color={"black"}
          label={<ClearFormatting />}
        />
        <Separator className={"xxs"} />
        <ButtonMui
          tooltipText={"Format options"}
          className={"xxl"}
          onClick={props.toggleFormatOptions}
          variant={"text"}
          roundnessType={"squared"}
          color={"black"}
          label={"Format options"}
        />
        <MoreOptionsMenuComponent
          onStyleRemove={props.onStyleRemove}
          setRestoreSelection={() => {}}
          onValueChange={props.onValueChange}
          value={props.value}
          onChange={onChange}
          style={style}
          rowStyle={rowStyle}
          objType={"textObject"}
          tbStyle={props.tbStyle}
          increaseIndent={increaseIndent}
          decreaseIndent={decreaseIndent}
          changeLineHeight={changeLineHeight}
          getSelectedRowsStyle={props.getSelectedRowsStyle}
          changeSpacingBeforeItem={changeSpacingBeforeItem}
          changeSpacingAfterItem={changeSpacingAfterItem}
          changeCustomSpacing={changeCustomSpacing}
          removeStyle={removeStyle}
          setBullet={setBullet}
          width={props.width}
          toggleFormatOptions={props.toggleFormatOptions}
        >
          <FontSizeEditComponent
            className={"m_o_xxxs"}
            onChange={props.setStyle}
            style={
              style && style.fontSize
                ? parseInt(style.fontSize.replace("px", "").replace("pt", ""))
                : ""
            }
          />
          {TextEditGroupMenu("m_o_xxs")}

          <TextColorEditComponent
            className={"m_o_xs"}
            shouldConfirm
            renderHeader={renderHeader}
            onChange={props.setStyle}
            color={props.style.color}
            setRestoreSelection={() => {}}
          />

          <HighlightColorEditComponent
            className={"m_o_xs"}
            shouldConfirm
            renderHeader={renderHeader}
            onValueChange={props.onValueChange}
            value={props.value}
            onChange={props.setStyle}
            style={style}
            setRestoreSelection={() => {}}
          />
        </MoreOptionsMenuComponent>
        <Separator />
      </HeaderMidPortal>
    </div>
  );
}

export default HeaderTextEditor;
