import React from "react";
import Button from "./../../../../../components/actions/Button";
import { FirstSection } from "../FirstSection";
import { ButtonsWrapper } from "../ButtonsWrapper";
import { ParagraphP2Strong } from "../PargaraphP2Strong";
import ButtonMui from "../../../../../components/buttons/buttonMui/buttonMui";
import { ArrowLeft, ArrowRight } from "../../../../../components/icons/svgIcons";

export default function Step6(props) {
  return (
    <>
      <FirstSection>
        <div className="p title strong">Get familiar with Modeliks</div>
      </FirstSection>
      <div className="spsocwn2_container">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100% - 40px)",
            paddingTop: "10px",
            boxSizing: "border-box",
          }}
        >
          <iframe
            style={{ aspectRatio: "16/9", height: "100%" }}
            src="https://www.youtube.com/embed/z6N_scPsZaE"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <ButtonsWrapper>
        <div className="o_btn_container">
          <ButtonMui
            startIcon={<ArrowLeft />}
            label={"Back"}
            width={120}
            variant={"contained"}
            onClick={props.BackStep}
          />
        </div>
        <div className="o_btn_container start">
          <ButtonMui
            label={"Get started"}
            width={120}
            color="secondary"
            variant={"contained"}
            endIcon={<ArrowRight />}
            onClick={props.saveCompany}
          />
        </div>
      </ButtonsWrapper>
    </>
  );
}
