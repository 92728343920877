import CalculatedDriver from "../../CalculatedDriver/index";
import { DriverCategories, SpecialChar, UnitTypes } from "../../CalculatedDriver/constants";
import Financing from "../../Financing";
import { FinancingTypes } from "../constants";
import CalculatedDriver_Values from "../../CalculatedDriver/CalculatedDriver_Values";

class Loan extends Financing {
  Name = "";
  ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
  FinanceType = FinancingTypes.Loan;
  LoanAmount;
  LoanTerm;
  InterestRate;
  PaymentStart;
  PayBack;
  GracePeriod;
  LoanReplaymentAmount;
  InterestDuringGracePeriod;
  ExtraPayments;

  constructor(db_record) {
    super(db_record);
    if (this.db_record) {
      this.clean();
      this.setDriversFromDataStorageSub();
      // this.buildPeriodsData();
      // this.setFinance();
    }
    this.SaveRevenue = this.Save;
    this.Save = this.SaveFinancing;
  }

  clean = (cleanDrivers = false) => {
    if (this.db_record && this.Totals) {
      this.Name = this.db_record.Name;
      this.FinanceType = this.db_record.FinanceType;
      this.ID_CompanyScenario = this.db_record.ID_CompanyScenario;
      this.InterestDuringGracePeriod = this.db_record.InterestDuringGracePeriod;
      this.TermType = this.db_record.TermType;
      if (cleanDrivers) {
        this.LoanAmount.cleanValue();
        this.LoanTerm.cleanValue();
        this.InterestRate.cleanValue();
        this.PaymentStart.cleanValue();
        this.PayBack.cleanValue();
        this.GracePeriod.cleanValue();
        this.LoanReplaymentAmount.cleanValue();
        this.cleanDrivers();
      }
    }
  };

  static DriversDesc = Object.assign({
    Total: {
      driverName: "$Name",
      fieldName: "Totals",
      driverID: "total",
      unit: UnitTypes.Price,
      category: DriverCategories.LastPeriod,
    },
    OpenningBalance: {
      driverName: `Openning Balance${SpecialChar.DriverNameESCChar}$Name`,
      fieldName: "OpenningBalance",
      driverID: "openning_balance",
      unit: UnitTypes.Price,
      category: DriverCategories.FirstPeriod,
    },
    LoanReceipt: {
      driverName: `Loan Receipt${SpecialChar.DriverNameESCChar}$Name`,
      fieldName: "LoanReceipt",
      driverID: "loan_receipt",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    InterestExpense: {
      driverName: `Interest Expense${SpecialChar.DriverNameESCChar}$Name`,
      fieldName: "InterestExpense",
      driverID: "interest_expense",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    InterestPayment: {
      driverName: `Interest Payment${SpecialChar.DriverNameESCChar}$Name`,
      fieldName: "InterestPayment",
      driverID: "interest_payment",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    LoanRepayments: {
      driverName: `Loan Repayments${SpecialChar.DriverNameESCChar}$Name`,
      fieldName: "LoanRepayments",
      driverID: "loan_repayments",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    PrincipalPayment: {
      driverName: `Principal Payment${SpecialChar.DriverNameESCChar}$Name`,
      fieldName: "PrincipalPayment",
      driverID: "principal_payment",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    ExtraPaymentAmount: {
      driverName: `Extra Payment Amount${SpecialChar.DriverNameESCChar}$Name`,
      fieldName: "ExtraPaymentAmount",
      driverID: "extra_payment_amount",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    LoanBalance: {
      driverName: `Loan Balance${SpecialChar.DriverNameESCChar}$Name`,
      fieldName: "LoanBalance",
      driverID: "loan_balance",
      unit: UnitTypes.Price,
      category: DriverCategories.LastPeriod,
    },
  });

  Save = (callBack, saveDrivers = true) => {
    if (!this.isNew && !this.IsCreated) {
      global.Modeliks.put(this.constructor.TableName, null, this, (res) => {
        if (saveDrivers) {
          this.SaveDrivers(callBack);
        } else {
          callBack();
        }
      });
    } else {
      global.Modeliks.put(this.constructor.TableName, null, this, (res) => {
        if (saveDrivers) {
          this.SaveDrivers(callBack);
        } else {
          callBack();
        }
      });
    }
  };

  SaveFinancing = (callBack, saveDrivers = true) => {
    this.SaveRevenue((newID) => {
      this.LoanAmount.Save();
      this.LoanTerm.Save();
      this.InterestRate.Save();
      this.PaymentStart.Save();
      this.PayBack.Save();
      this.GracePeriod.Save();
      this.LoanReplaymentAmount.Save();
      if (saveDrivers) {
        this.SaveDrivers(callBack, this.ID);
      } else {
        callBack(this.ID);
      }
    }, false);
  };

  Delete = (callBack) => {
    this.DeleteFunc(this.ID, () => {
      global.Modeliks.del(
        global.Modeliks.Tables.Finance_CalculatedDriver_Values.TableName,
        { ID: this.getLoanAmountID(), ID_CompanyScenario: global.Modeliks.CompanyScenarioInfo.ID },
        (res) => {
          global.Modeliks.del(
            global.Modeliks.Tables.Finance_CalculatedDriver_Values.TableName,
            {
              ID: this.getLoanTermID(),
              ID_CompanyScenario: global.Modeliks.CompanyScenarioInfo.ID,
            },
            (res) => {
              global.Modeliks.del(
                global.Modeliks.Tables.Finance_CalculatedDriver_Values.TableName,
                {
                  ID: this.getInterestRateID(),
                  ID_CompanyScenario: global.Modeliks.CompanyScenarioInfo.ID,
                },
                (res) => {
                  global.Modeliks.del(
                    global.Modeliks.Tables.Finance_CalculatedDriver_Values.TableName,
                    {
                      ID: this.getPaymentStartID(),
                      ID_CompanyScenario: global.Modeliks.CompanyScenarioInfo.ID,
                    },
                    (res) => {
                      global.Modeliks.del(
                        global.Modeliks.Tables.Finance_CalculatedDriver_Values.TableName,
                        {
                          ID: this.getPayBackID(),
                          ID_CompanyScenario: global.Modeliks.CompanyScenarioInfo.ID,
                        },
                        (res) => {
                          global.Modeliks.del(
                            global.Modeliks.Tables.Finance_CalculatedDriver_Values.TableName,
                            {
                              ID: this.getGracePeriodID(),
                              ID_CompanyScenario: global.Modeliks.CompanyScenarioInfo.ID,
                            },
                            (res) => {
                              global.Modeliks.del(
                                global.Modeliks.Tables.Finance_CalculatedDriver_Values.TableName,
                                {
                                  ID: this.getLoanReplaymentAmountID(),
                                  ID_CompanyScenario: global.Modeliks.CompanyScenarioInfo.ID,
                                },
                                (res) => {
                                  if (callBack) {
                                    callBack();
                                  }
                                },
                              );
                            },
                          );
                        },
                      );
                    },
                  );
                },
              );
            },
          );
        },
      );
    });
  };

  setDriversFromDataStorageSub = () => {
    this.LoanAmount = global.Modeliks.DriverValuesStore.getItem(this.getLoanAmountID());
    this.LoanTerm = global.Modeliks.DriverValuesStore.getItem(this.getLoanTermID());
    this.InterestRate = global.Modeliks.DriverValuesStore.getItem(this.getInterestRateID());
    this.PaymentStart = global.Modeliks.DriverValuesStore.getItem(this.getPaymentStartID());
    this.PayBack = global.Modeliks.DriverValuesStore.getItem(this.getPayBackID());
    this.GracePeriod = global.Modeliks.DriverValuesStore.getItem(this.getGracePeriodID());
    this.LoanReplaymentAmount = global.Modeliks.DriverValuesStore.getItem(
      this.getLoanReplaymentAmountID(),
    );
  };

  get hasExtraPayments() {
    return this.ExtraPaymentAmount.Values.some((v) => v.Value > 0);
  }

  periodsData = {};

  createExtraPeriods = () => {
    Object.values(this.constructor.DriversDesc).forEach((driver) => {
      this[driver.fieldName].addMonths(0, true);
    });

    this.buildPeriodsData();
  };

  buildPeriodsData = () => {
    const allPeriods = this.OpenningBalance.Values.map((c) => c.Date);
    allPeriods.forEach((period) => {
      this.periodsData[period.dateID] = {};
      Object.values(this.constructor.DriversDesc).forEach(
        (driver) =>
          (this.periodsData[period.dateID][driver.fieldName] = this[
            driver.fieldName
          ].getItemByDateSufix(period.sufix)),
      );
      this.periodsData[period.dateID].Date = period;
    });
  };

  getLoanAmountID = () => {
    return `${Loan.TableName}-${this.ID}-loan_amount`;
  };
  getLoanTermID = () => {
    return `${Loan.TableName}-${this.ID}-loan_term`;
  };
  getInterestRateID = () => {
    return `${Loan.TableName}-${this.ID}-interest_rate`;
  };
  getPaymentStartID = () => {
    return `${Loan.TableName}-${this.ID}-payment_start`;
  };
  getPayBackID = () => {
    return `${Loan.TableName}-${this.ID}-pay_back`;
  };
  getGracePeriodID = () => {
    return `${Loan.TableName}-${this.ID}-grace_period`;
  };
  getLoanReplaymentAmountID = () => {
    return `${Loan.TableName}-${this.ID}-loan_replayment_amount`;
  };

  getMonthDatesAll = () => {
    return [...global.Modeliks.DateHelper.months, ...global.Modeliks.DateHelper.months_after];
  };

  getYearDatesAll = () => {
    return global.Modeliks.DateHelper.years_all;
  };

  createMonthsFormulas = () => {
    const months = this.getMonthDatesAll();
    this.LoanReplaymentAmount.Formula = `(${this.LoanAmount} * (${this.InterestRate} / ${this.PayBack.Value}) ) / ( 1 - MxMath.Pow( 1 + (${this.InterestRate.Value / 100} / ${this.PayBack.Value}),(-${this.LoanTerm.Value} / 12 * ${this.PayBack.Value}))     )`;
    const StartInterestDateOrder =
      Number(this.PaymentStart.Value) - 1 + this.GracePeriod.Value + 12 / this.PayBack.Value;
    const InterestDuringGracePeriod = this.InterestDuringGracePeriod;

    months.forEach((month) => {
      const prevMonthDate = months.find((c) => c.Order == month.Order - 1);
      const curMonth = this.periodsData[month.dateID];
      const prevMonth = prevMonthDate ? this.periodsData[prevMonthDate.dateID] : null;
      const prevMonthsDates = (month, prevMonthsNumber) =>
        months.filter((c) => c.Order <= month.Order && c.Order > month.Order - prevMonthsNumber);
      const prevSingleMonthDate = (month, prevMonths) =>
        this.periodsData[months.find((c) => c.Order === month.Order - prevMonths).dateID];

      if (prevMonth) {
        curMonth.OpenningBalance.Formula = `${prevMonth.LoanBalance}`;
      } else {
        if (this.PaymentStart.Value === "0" || this.PaymentStart.Value === 0) {
          if (curMonth.OpenningBalance.Unit === UnitTypes.Hide) {
            curMonth.OpenningBalance.Unit = UnitTypes.Price;
          }
          const tmpValue = curMonth.OpenningBalance.Value;
          curMonth.OpenningBalance.Formula = null;
          curMonth.OpenningBalance.Value = tmpValue;
        } else {
          curMonth.OpenningBalance.Formula = null;
          curMonth.OpenningBalance.Unit = UnitTypes.Hide;
        }
      }

      if (this.PaymentStart.Value === "0" || this.PaymentStart.Value === 0) {
        curMonth.LoanReceipt.Formula = null;
        curMonth.LoanReceipt.Unit = UnitTypes.Price;
        curMonth.LoanReceipt.Value = 0;
        if (!prevMonth) {
          curMonth.OpenningBalance.Value = `${this.LoanAmount.Value}`;
        }
      } else {
        if (month.Order === Number(this.PaymentStart.Value) - 1) {
          curMonth.LoanReceipt.Formula = `${this.LoanAmount}`;
          curMonth.LoanReceipt.Unit = UnitTypes.Price;
        } else {
          curMonth.LoanReceipt.Formula = null;
          curMonth.LoanReceipt.Unit = UnitTypes.Hide;
        }
      }

      if (month.Order === StartInterestDateOrder) {
        let InterestPaymentMonths = prevMonthsDates(month, 12 / this.PayBack.Value);
        curMonth.InterestPayment.Formula = `MxMath.Sum([${InterestPaymentMonths.map((month) => this.periodsData[month.dateID].InterestExpense.ID_f).join(",")}])`;
        curMonth.LoanRepayments.Formula = `MxMath.IFELSE((${curMonth.OpenningBalance} + ${curMonth.InterestPayment}) > ${this.LoanReplaymentAmount} ,${this.LoanReplaymentAmount},${curMonth.OpenningBalance} + ${curMonth.InterestPayment})`;
        curMonth.LoanRepayments.Unit = UnitTypes.Price;
      } else if (month.Order > StartInterestDateOrder) {
        const BeforePaymentDate = prevSingleMonthDate(month, 12 / this.PayBack.Value);
        if (BeforePaymentDate && BeforePaymentDate.InterestPayment.Formula) {
          let InterestPaymentMonths = prevMonthsDates(month, 12 / this.PayBack.Value);
          curMonth.InterestPayment.Formula = `MxMath.Sum([${InterestPaymentMonths.map((month) => this.periodsData[month.dateID].InterestExpense.ID_f).join(",")}])`;
          curMonth.LoanRepayments.Formula = `MxMath.IFELSE((${curMonth.OpenningBalance} + ${curMonth.InterestPayment}) > ${this.LoanReplaymentAmount} ,${this.LoanReplaymentAmount},${curMonth.OpenningBalance} + ${curMonth.InterestPayment})`;
          curMonth.LoanRepayments.Unit = UnitTypes.Price;
        } else {
          curMonth.InterestPayment.Formula = null;
          curMonth.LoanRepayments.Formula = null;
          curMonth.LoanRepayments.Unit = UnitTypes.Hide;
        }
      } else {
        // Hide
        curMonth.InterestPayment.Formula = null;
        curMonth.LoanRepayments.Unit = UnitTypes.Hide;
        curMonth.LoanRepayments.Formula = null;
      }

      curMonth.PrincipalPayment.Formula = `${curMonth.LoanRepayments} - ${curMonth.InterestPayment}`;

      if (month.Order + 1 <= this.GracePeriod.Value + Number(this.PaymentStart.Value)) {
        curMonth.InterestPayment.Formula = `${curMonth.InterestExpense}`;
        curMonth.PrincipalPayment.Formula = null;
        curMonth.PrincipalPayment.Unit = UnitTypes.Hide;
      } else {
        curMonth.PrincipalPayment.Formula = `${curMonth.LoanRepayments} - ${curMonth.InterestPayment}`;
        curMonth.PrincipalPayment.Unit = UnitTypes.Price;
      }

      if (
        month.Order + 1 <= this.GracePeriod.Value + Number(this.PaymentStart.Value) &&
        InterestDuringGracePeriod
      ) {
        curMonth.InterestExpense.Value = 0;
      } else {
        curMonth.InterestExpense.Formula = `(${curMonth.OpenningBalance} * ${this.InterestRate}) / 12`;
      }

      curMonth.LoanBalance.Formula = `${curMonth.OpenningBalance} + ${curMonth.LoanReceipt} - ${curMonth.PrincipalPayment} - ${curMonth.ExtraPaymentAmount}`;
      curMonth.Totals.Formula = `${curMonth.LoanBalance}`;
    });
  };

  createYearsFormulas = () => {
    const years = this.getYearDatesAll();

    years.forEach((year) => {
      const curYear = this.periodsData[year.dateID];
      const months = this.periodsData[year.dateID].Date.monthIndexes.map(
        (index) => this.periodsData[index],
      );
      const lastMonth = months[months.length - 1];
      const firstMonth = months[0];

      curYear.OpenningBalance.Formula = `${firstMonth.OpenningBalance}`;
      curYear.LoanReceipt.Formula = `MxMath.Sum([${months.map((c) => c.LoanReceipt.ID_f).join(",")}])`;
      curYear.InterestExpense.Formula = `MxMath.Sum([${months.map((c) => c.InterestExpense.ID_f).join(",")}])`;
      curYear.InterestPayment.Formula = `MxMath.Sum([${months.map((c) => c.InterestPayment.ID_f).join(",")}])`;
      curYear.LoanRepayments.Formula = `MxMath.Sum([${months.map((c) => c.LoanRepayments.ID_f).join(",")}])`;
      curYear.PrincipalPayment.Formula = `MxMath.Sum([${months.map((c) => c.PrincipalPayment.ID_f).join(",")}])`;
      curYear.ExtraPaymentAmount.Formula = `MxMath.Sum([${months.map((c) => c.ExtraPaymentAmount.ID_f).join(",")}])`;
      curYear.LoanBalance.Formula = `${lastMonth.LoanBalance}`;
      curYear.Totals.Formula = `${curYear.LoanBalance}`;
    });
  };

  setFinance = () => {
    this.buildPeriodsData();
    this.createMonthsFormulas();
    this.createYearsFormulas();
  };

  static convert_Finance = (financing) => {
    const newFinancing = new Loan();
    newFinancing.ID = financing.ID;
    newFinancing.Name = financing.Name;
    newFinancing.ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
    newFinancing.Totals = CalculatedDriver.createDriverFromTable(
      newFinancing,
      Financing.DriversDesc.Total.driverID,
      UnitTypes.Price,
      DriverCategories.Sum,
    );
    newFinancing.LoanAmount = new CalculatedDriver_Values(
      null,
      newFinancing.getLoanAmountID(),
      null,
      UnitTypes.Price,
    );
    newFinancing.LoanTerm = new CalculatedDriver_Values(
      null,
      newFinancing.getLoanTermID(),
      null,
      UnitTypes.Integer,
    );
    newFinancing.InterestRate = new CalculatedDriver_Values(
      null,
      newFinancing.getInterestRateID(),
      null,
      UnitTypes.Percentage,
    );
    newFinancing.PaymentStart = new CalculatedDriver_Values(null, newFinancing.getPaymentStartID());
    newFinancing.PayBack = new CalculatedDriver_Values(
      null,
      newFinancing.getPayBackID(),
      null,
      UnitTypes.Integer,
    );
    newFinancing.PayBack.Value = 12;
    newFinancing.GracePeriod = new CalculatedDriver_Values(
      null,
      newFinancing.getGracePeriodID(),
      null,
      UnitTypes.Integer,
    );
    newFinancing.GracePeriod.Value = 0;
    newFinancing.LoanReplaymentAmount = new CalculatedDriver_Values(
      null,
      newFinancing.getLoanReplaymentAmountID(),
      null,
      UnitTypes.Integer,
    );
    newFinancing.IsCreated = true;
    newFinancing.createDrivers();
    newFinancing.createExtraPeriods();
    return newFinancing;
  };
}

export default Loan;
