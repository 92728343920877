import { BusinessPlanStrategy } from "./slideObjectStrategies/businessPlan";
import { PitchStrategy } from "./slideObjectStrategies/pitch";

export const SlideObjectStrategyTypes = Object.freeze({
  BusinessPlan: "businessPlan",
  Pitch: "pitch",
});

// TODO: move all logic for different modules from SlideComponent to strategy

export class SlideObjectStrategy {
  constructor(type) {
    this.strategy = this.createStrategy(type);
  }

  createStrategy(strategyType) {
    if (strategyType === SlideObjectStrategyTypes.BusinessPlan) {
      return new BusinessPlanStrategy();
    } else if (strategyType === SlideObjectStrategyTypes.Pitch) {
      return new PitchStrategy();
    } else {
      throw new Error("Invalid strategy type");
    }
  }

  getHistoryItem(item, itemIndex) {
    return this.strategy.getHistoryItem(item, itemIndex);
  }
  
  getSlideComponentClassName() {
    return this.strategy.getSlideComponentClassName();
  }

  getMovableObjects(state) {
    return this.strategy.getMovableObjects(state);
  }
  
  getSlideObjects(state) {
    return this.strategy.getSlideObjects(state);
  }
}
