import React, { Component } from "react";
import SlideHeaderContainer from "../../SlideEditor/components/components/SlideHeaderContainer";
import CircleLoader from "../../components/Loaders/CircleLoader/CircleLoader";
import request from "superagent";
import SlideComponent from "../../SlideEditor/components/SlideComponent";
import "../../SlideEditor/App.scss";
import { SlideTypes } from "../../SlideEditor/components/SlideObjects/SlideStructures";

class Preview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };

    this.scale = {};
  }

  componentDidMount() {
    this.getPages();
  }

  getPages = () => {
    request
      .get(
        `/api/BusinessPlanPreview?client_id=${global.Modeliks.Account.ID}&company_id=${global.Modeliks.CompanyInfo.ID}&business_plan_scenario_id=${global.Modeliks.BusinessPlanScenarioInfo.ID}`,
      )
      .then((res2) => {
        if (res2.text !== "0") {
          let res = JSON.parse(res2.text);
          let templatesArr = res.jsonStringArr;
          let pageNames = res.pageNames;
          this.slideSections = res.slideSections;
          console.log("templates", this.slideSections);

          this.setState({
            loadedData: true,
            previewData: templatesArr,
            pageNames: pageNames,
          });
        }
      });
  };

  checkIfChartsHaveFinished = () => {
    return window.amountOfChartsToRender === window.amountOfChartsRendered;
  };

  resetPrintingValues = () => {
    window.chartsForRender = [];
    window.amountOfChartsRendered = 0;
    window.amountOfChartsToRender = 0;
  };

  returnSlideComp = () => {
    let counter = 0;
    window.iscurrentlyPrinting = true;
    this.resetPrintingValues();

    function checkFinishedWrapper() {
      checkFinished(true);
    }

    const checkFinished = (fromChart = false) => {
      if (!fromChart) {
        counter++;
      }

      if (counter == this.state.previewData.length && this.checkIfChartsHaveFinished()) {
        global.Modeliks.Unsubscribe2("onChartRenderFinish", checkFinishedWrapper);

        setTimeout(() => {
          console.log("test", global.Modeliks.BusinessPlanConfig);

          const children = document
            .getElementsByClassName("preview_hidden_container")[0]
            .childNodes.forEach((c, i) => (this.slideSections[i].html = c.outerHTML));
          // let el = document.getElementsByClassName('preview_hidden_container')[0].innerHTML;
          request
            .post("/api-pdf/createpdf")
            .set("Content-Type", "application/json")
            .set("authorization", "Bearer " + window.localStorage.getItem("token"))
            .send({
              config: {
                ...global.Modeliks.BusinessPlanConfig,
                companyName: global.Modeliks.CompanyInfo.CompanyName,
                logoUrl: `${window.location.protocol + "//" + window.location.host}/api/logo?account_id=${global.Modeliks.Account.ID}&company_id=${global.Modeliks.CompanyInfo.ID}`,
              },
              sections: this.slideSections,
            })
            .then((res) => {
              window.iscurrentlyPrinting = false;
              this.setState({ loading: false, fileName: res.body });
            })
            .catch((e) => console.error(e));
        }, 600);
      }
    };
    global.Modeliks.Subscribe("onChartRenderFinish", checkFinishedWrapper);

    return (
      <div className={"preview_hidden_container"}>
        {this.state.previewData &&
          this.state.previewData.map((c, index) => {
            let jsonString = JSON.parse(c);
            window.amountOfChartsToRender += jsonString.slideObjects.filter(
              (c) => c.type === SlideTypes.chartObject || c.type === SlideTypes.financialChart,
            ).length;
            return (
              <SlideComponent
                onPreview={() => this.onPreview()}
                scale={this.scale}
                pageBreakAfter={index === this.state.previewData.length - 1 ? false : true}
                showLogo={true}
                didMount={() => checkFinished()}
                disableHeader
                preview={true}
                width={2480}
                height={3057}
                dontShowHeader={true}
                dontShowFooter={true}
                showPageName={index !== 0}
                isFirstPage={index === 0}
                pageName={this.state.pageNames[index]}
                businessPlan={true}
                isBpPrinting={true}
                onUnmount={true}
                jsonData={c}
                openDialog={true}
                className={this.saving && " hidden"}
                key={"sc_" + index.toString()}
              />
            );
          })}
      </div>
    );
  };

  render() {
    if (this.state.loading) {
      return (
        <>
          <div className={"main_circle_loader_container"}>
            <CircleLoader message={"We are preparing your business plan for preview..."} />
          </div>
          {this.state.loadedData && this.returnSlideComp()}
        </>
      );
    }
    return (
      <div className={"App slide_editor"}>
        <SlideHeaderContainer
          route={"/business_plan"}
          label={"Back To Plan"}
          canPreview={true}
          businessPlan
        />
        <div style={{ width: "100%" }}>
          <iframe
            style={{ height: "100%", width: "100%" }}
            src={`/api-pdf/getpdf/${this.state.fileName}?v=${new Date().getTime()}`}
            frameBorder="0"
          ></iframe>
        </div>
      </div>
    );
  }
}

export default Preview;
