import React from "react";
import "../App.scss";
import SlideComponent from "../components/SlideComponent";
import { SlideObject, SlideTypes } from "../components/SlideObjects/SlideStructures";
import Box from "@mui/material/Box";
import Footer from "../components/components/Footer/ScalePanelFooter";
import request from "superagent";

import "../components/MenuObject.scss";
import html2canvas from "html2canvas";

import RightMenu from "../components/components/RightMenuComponents/RightMenu";
import ButtonJ from "../components/components/Buttons/Button";
import LeftMenu from "../components/components/menus/LeftMenu/LeftMenu";
import PrintablePortal from "../components/components/Portals/printablePortal";
import { Logo } from "../../components/icons/svgIcons";
import CircleLoader from "../../components/Loaders/CircleLoader/CircleLoader";
import { PermissionTypes } from "../../components/constants/permissions";
import SlideHeaderContainer from "../components/components/SlideHeaderContainer";
import { CategoryTypes } from "../utils";
import Mx_MainLoader from "../../components/Loaders/MainLoader/Mx_MainLoader";
import LogoComponent from "../components/LogoComponent";
import FreeTrialDialog from "../../components/dialogs/FreeTrialDialog/FreeTrialDialog";
import Presentation from "../components/Presentation/Presentation";
import { callback } from "chart.js/helpers";
import pitchFlows from "../services/pitchFlows";
import { AddSection } from "../services/commands/AddSection";
import { CommandHistoryManager } from "../services/commands/CommandManager";
import { RemoveSection } from "../services/commands/RemoveSection";
import { AddBlankSection } from "../services/commands/AddBlankSection";
import { CommandManagerContext } from "../services/commands/CommandManagerProvider";

class Index extends React.Component {
  CompanyInfo = [];
  Account = null;
  saving = false;
  LogoObj = null;
  SummaryDateSettings = null;
  SummaryDateSettingsValue = {};
  TemplatesTableName = "slidetemplates_client";
  SlideSectionsTableName = "slidesections_client";
  TemplateNamePrefix = "";
  SectionNameKey = "SectionName";

  scale = {
    enabled: false,
    rightMenu: true,
    textObjs: [],
  };

  constructor(props) {
    super(props);

    this.jsonFunc = {};
    this.Categories = [];
    this.SlideSections = [];
    this.DefaultTemplates = [];
    this.shouldPasteInPlace = false;
    this.timesPasted = 0;
    this.shouldMakeScreenshot = false;
    this.PitchScenarioInfo = props.PitchScenarioInfo
      ? props.PitchScenarioInfo
      : global.Modeliks.PitchScenarioInfo;
    this.PitchScenarioIDKey = "PitchScenario_ID";
    this.PDFTimeout = 500;
    this.AdditionalClassName = "";

    this.currentVersion = new Date().getTime();
    global.Modeliks.currentLogoVersion = this.currentVersion;

    this.PitchFlows = pitchFlows({
      SlideSectionsTableName: this.SlideSectionsTableName,
      TemplatesTableName: this.TemplatesTableName,
    });

    if (!this.props.publishMode) {
      this.EditPermission = global.Modeliks.UserPermissions[PermissionTypes.Pitch] > 0;
    }

    if (this.props.publishMode) {
      this.isLinkValid();
    } else {
      this.LoadingFunc();
    }
    this.addBlankSection = this.addBlankSection.bind(this);
    this.LoadingFunc = this.LoadingFunc.bind(this);
    this.createSection = this.createSection.bind(this);
    this.getSecondaryHeaderButton = this.getSecondaryHeaderButton.bind(this);
    this.getAllCategories = this.getAllCategories.bind(this);
    this.afterSlidePrint = this.afterSlidePrint.bind(this);
    this.handleSlideObjectsBeforePrint = this.handleSlideObjectsBeforePrint.bind(this);
    this.beforeSlidePrint = this.beforeSlidePrint.bind(this);
    this.addSection = this.addSection.bind(this);

    this.state = {
      slideConfig: "",
      selectedCategory: "",
      sections: [],
      selectedSection: "",
      templates: [],
      open: false,
      zoom: 1,
      footerZoomVal: 1,
      preview: false,
      Counter: !this.props.publishMode ? this.PitchScenarioInfo.Counter : "",
      selectedTemplate: null,
      templateKey: "template_" + new Date().getTime(),
      slideKey: "slideComponent_" + new Date().getTime(),
      jsonData: "",
      printEnabled: false,
      loading: true,
      key: new Date().getTime(),
    };

    if (!this.props.publishMode) {
      this.AccountID = global.Modeliks.Account.ID;
      this.ActualDates = require("../../pages/Secure/Financials/Actuals/constants").ActualDates;

      Object.assign(this.state, {
        minDate: this.ActualDates.minDate,
        maxDate: this.ActualDates.maxDate,
        minYear: this.ActualDates.minYear,
        maxYear: this.ActualDates.maxYear,

        startMonth: `${global.Modeliks.DateHelper.months_before_actual[0].Year}-${global.Modeliks.DateHelper.months_before_actual[0].Month + 1}`,
        endMonth: `${global.Modeliks.DateHelper.months[global.Modeliks.DateHelper.months.length - 1].Year}-${global.Modeliks.DateHelper.months[global.Modeliks.DateHelper.months.length - 1].Month + 1}`,
        startYear: global.Modeliks.DateHelper.years_before_actual[0].Header,
        endYear:
          global.Modeliks.DateHelper.years_all[global.Modeliks.DateHelper.years_all.length - 1]
            .Header,

        startMonthOrder: global.Modeliks.DateHelper.months_before_actual[0].Order,
        endMonthOrder:
          global.Modeliks.DateHelper.months[global.Modeliks.DateHelper.months.length - 1].Order,
        startYearOrder: global.Modeliks.DateHelper.years_before_actual[0].Order,
        endYearOrder:
          global.Modeliks.DateHelper.years_all[global.Modeliks.DateHelper.years_all.length - 1]
            .Order,

        months: this.ActualDates.allMonths,
        years: global.Modeliks.DateHelper.gridPeriodHeaders_Years(),
      });
    }
    if (!this.SummaryDateSettingsValue.hasOwnProperty("from")) {
      Object.assign(this.state, this.SummaryDateSettingsValue);
    }

    this.MainFinanceTableMapping = [];
  }

  setSummaryDateSettingsValue = () => {
    if (this.SummaryDateSettings && this.SummaryDateSettings.Value) {
      this.SummaryDateSettingsValue = JSON.parse(this.SummaryDateSettings.Value);
    }
  };

  addSectionInObject = (index, section, isCustom) => {
    if (index >= this.SlideSections.length) {
      this.SlideSections.push(section);
      if (isCustom) {
        this.Categories[4].Sections.push(section);
      }
    } else {
      this.SlideSections.splice(index, 0, section);
      if (isCustom) {
        this.Categories[4].Sections.splice(index, 0, section);
      }
    }
    this.updateOrder();
  };

  addMissingTemplates = (templates, index, callBack) => {
    if (index < templates.length) {
      global.Modeliks.post(this.TemplatesTableName, templates[index], (succ, err) => {
        if (succ) {
          templates[index].ID = succ.id;
          this.SlideSectionIndexes[templates[index].SectionID].Templates.push(templates[index]);
          this.addMissingTemplates(templates, index + 1, callBack);
        }
      });
    } else {
      callBack();
    }
  };
  getMissingTemplates = (callBack) => {
    let missingTemplates = [];

    this.SlideSections.filter((c) => c.MasterSectionID).forEach((section) => {
      let templates = section.Templates ?? [];
      let masterSection = this.Categories.find((c) => c.ID === section.CategoryID).Sections.find(
        (c) => c.ID === section.MasterSectionID,
      );

      if (masterSection) {
        masterSection.Templates.filter((c) => c.isPublished).forEach((template) => {
          if (!templates.find((c) => c.MasterTemplateID === template.ID)) {
            template.MasterTemplateID = template.ID;
            template.SectionID = section.ID;
            template.AccountID = global.Modeliks.Account.ID;
            delete template.CreatedBy;
            delete template.UpdatedBy;
            delete template.ID;
            delete template.isPublished;

            missingTemplates.push(template);
          }
        });
      }
    });

    console.log("missing templates", missingTemplates);

    if (missingTemplates.length) {
      this.addMissingTemplates(missingTemplates, 0, callBack);
    } else {
      callBack();
    }
  };

  LoadingFunc(callBack, shouldSave = false, pitchScenarioID = null) {
    if (shouldSave) {
      this.saveContent(
        this.state.selectedTemplate.ID,
        this.jsonFunc.returnJsonString(),
        () => {
          this.getAllCategories(() => {
            this.getSections(() => {
              this.getTemplates(() => {
                this.getMissingTemplates(() => {
                  this.getTemplateContent(callBack && callBack());
                  this.updateOrder(false);
                });
              });
            });
          });
        },
        this.AccountID,
        global.Modeliks.CompanyInfo.ID,
        pitchScenarioID,
        false,
        true,
        undefined,
        false,
      );
    } else {
      this.getAllCategories(() => {
        this.getSections(() => {
          this.getTemplates(() => {
            this.getMissingTemplates(() => {
              this.getTemplateContent(callBack && callBack());
              this.updateOrder(false);
            });
          });
        });
      });
    }
  }

  onCompanyChange = () => {
    this.setState({ loading: true });
    this.LoadingFunc(() => {});
  };

  onWheelListener = (e) => {
    if (e.ctrlKey) {
      e.preventDefault();
      let changer = -1;
      if (e.wheelDelta > 0) {
        changer = 1;
      }
      this.onZoomChange(this.state.footerZoomVal + changer);
    }
  };

  componentDidMount() {
    global.wheelListener = this.onWheelListener;
    global.Modeliks.Subscribe("onCompanyChange", this.onCompanyChange);
    this.setState({ mounted: true });
  }

  getTemplates = (callBack, tableName = undefined) => {
    let queryObj = { section_id: this.SlideSections.map((c) => c.ID) };
    if (tableName) {
      queryObj.tableName = tableName;
    }
    request
      .get("/api/SlideTemplates")
      .set("authorization", "Bearer " + window.localStorage.getItem("token"))
      .query(queryObj)
      .then((templates) => {
        templates.body.forEach((c) => {
          if (!this.SlideSectionIndexes[c.SectionID].Templates) {
            this.SlideSectionIndexes[c.SectionID].Templates = [];
          }
          if (!this.SlideSectionIndexes[c.SectionID].TemplateIndexes) {
            this.SlideSectionIndexes[c.SectionID].TemplateIndexes = {};
          }
          this.SlideSectionIndexes[c.SectionID].Templates.push(c);
          Object.assign(this.SlideSectionIndexes[c.SectionID].TemplateIndexes, {
            [c.ID]: c,
          });
        });
        let selectedTemplate = this.state.selectedSection.Templates.filter(
          (template) => template.isDefault,
        )[0];
        if (!selectedTemplate) {
          selectedTemplate = this.state.selectedSection.Templates[0];
        }
        this.state.selectedTemplate = selectedTemplate;
        callBack();
      })
      .catch((err) => console.error(err));
  };

  sortSections = (sections) => {
    sections.sort(function (a, b) {
      if (a.order > b.order) {
        return 1;
      } else if (a.order < b.order) {
        return -1;
      } else {
        return a.ID > b.ID ? 1 : -1;
      }
    });
    return sections;
  };

  isLinkValid = () => {
    const account_guid = window.location.href.split("/")[4];
    const client_guid = window.location.href.split("/")[5];

    request
      .get(`/api/publish?account_guid=${account_guid}&client_guid=${client_guid}`)
      .then((res) => {
        if (res.text !== "0") {
          let obj = JSON.parse(res.text);
          request.get("/api/getPublishCharts").then((chartsRes) => {
            global.Modeliks.PitchFinancialChartsYearly = chartsRes.body;
            [
              ...global.Modeliks.PitchFinancialChartsYearly,
              ...global.Modeliks.PitchFinancialChartsMonthly,
            ].forEach((c) => {
              if (c && c.Data) {
                try {
                  c.Data = JSON.parse(c.Data);
                } catch (e) {}
              }
            });

            request
              .get(
                `/api/getPublishTemplates?client_id=${obj.client_id}&company_id=${obj.company_id}&pitch_scenario_id=${obj.pitch_scenario_id}&type=${obj.type}&ID=${obj.ID}&editor_type=${this.type ?? "pitch"}`,
              )
              .then((res2) => {
                if (res2.text !== "0") {
                  let templatesArr = JSON.parse(res2.text);
                  let config = templatesArr[0];
                  let backgroundColors = templatesArr[1];
                  templatesArr.splice(0, 2);
                  this.setState({
                    index: 0,
                    templatesArray: templatesArr,
                    slideConfig: JSON.parse(config),
                    backgroundColors,
                    open: true,
                    loading: false,
                  });
                }
              });
          });
        } else {
          this.setState({
            open: false,
            loading: false,
          });
        }
      });
  };

  setShouldPasteInPlace = (val) => {
    this.shouldPasteInPlace = val;
    this.forceUpdate();
  };

  setTimesPasted = (val) => {
    this.timesPasted = val;
    this.forceUpdate();
  };

  getSections(callBack, tableName = this.SlideSectionsTableName, queryObj = null) {
    if (!queryObj) {
      queryObj = {
        PitchScenario_ID: this.PitchScenarioInfo.ID,
      };
    }

    global.Modeliks.get(tableName, queryObj, (sections) => {
      const filteredSections = sections.filter((c) => c.isDeleted !== true);
      this.SlideSections = this.sortSections(filteredSections);
      this.SlideSectionIndexes = {};
      this.SlideSections.forEach((c) => {
        Object.assign(this.SlideSectionIndexes, {
          [c.ID]: c,
        });
      });
      if (
        this.props.previouslySelectedSlide &&
        this.SlideSectionIndexes[this.props.previouslySelectedSlide]
      ) {
        this.state.selectedSection = this.SlideSectionIndexes[this.props.previouslySelectedSlide];
        this.props.setPreviouslySelectedSlide && this.props.setPreviouslySelectedSlide(null);
      } else {
        this.state.selectedSection = this.SlideSections[0];
      }
      if (callBack) {
        callBack();
      }
    });
  }

  getAllCategories(callBack) {
    global.Modeliks.get(
      "/api/getAdminCategories",
      {},
      (data) => {
        this.Categories = data.categories;
        this.Categories.forEach((c) => {
          c.Sections = c.Sections.filter((c) => c.IndustryID === this.PitchScenarioInfo.IndustryID);
        });
        request
          .get(`/api/getUserCategories?pithcScenarioID=${this.PitchScenarioInfo.ID}`)
          .set("authorization", "Bearer " + window.localStorage.getItem("token"))
          .then((sections) => {
            this.Categories[4].Sections = sections.body;
            callBack();
          });
      },
      undefined,
      false,
      false,
      true,
    );
  }

  updateState = () => {
    this.forceUpdate();
  };

  componentWillUnmount() {
    if (
      global.Modeliks &&
      global.Modeliks.CompanyInfo &&
      this.PitchScenarioInfo &&
      this.state.selectedTemplate &&
      this.jsonFunc.returnJsonString
    ) {
      this.saveContent(
        this.state.selectedTemplate.ID,
        this.jsonFunc.returnJsonString(),
        () => {},
        this.AccountID,
        global.Modeliks.CompanyInfo.ID,
        this.PitchScenarioInfo.ID,
        false,
        true,
        undefined,
        false,
      );
    }
    global.wheelListener = null;

    global.Modeliks.currentLogoVersion = null;
    document.removeEventListener("keydown", this.handleKeyDown);
    global.Modeliks.Unsubscribe("onCompanyChange", this.onCompanyChange);

    this.context.commandHistoryManager.reset();
  }

  appendSection = (section, template, callBack) => {
    this.setTemplates(section, [template]);
    const index = this.SlideSections.indexOf(this.state.selectedSection) + 1;
    // this.SlideSections.splice(index, 0, section);
    this.addSectionInObject(index, section, true);
    this.SlideSectionIndexes[section.ID] = section;
    // this.Categories[this.Categories.findIndex(c => c.CategoryName == CategoryTypes.Custom)].Sections.splice(index,0,section);
    // this.updateOrder();
    if (callBack) {
      callBack();
    }
  };

  setSaving = (val) => {
    this.saving = val;
    this.forceUpdate();
  };

  findDifference = (oldObjects = [], currentObjects = []) => {
    let updateObjects = [];
    let deleteKeys = [];
    let newObjects = [];

    oldObjects.forEach((obj) => {
      let newObject = currentObjects.find((c) => c.props.staticKey === obj.props.staticKey);

      if (newObject) {
        let tmpNewObject = newObject;
        let tmpOldObject = obj;

        tmpNewObject.key = -1;
        tmpNewObject.oldKey = -1;
        tmpNewObject.height = -1;
        tmpNewObject.props.oldKey = -1;
        tmpNewObject.props.replacementImage = null;
        tmpNewObject.replacementImage = null;

        tmpOldObject.key = -1;
        tmpOldObject.oldKey = -1;
        tmpOldObject.height = -1;
        tmpOldObject.props.oldKey = -1;
        tmpOldObject.props.replacementImage = null;
        tmpOldObject.replacementImage = null;

        if (JSON.stringify(tmpNewObject) !== JSON.stringify(tmpOldObject)) {
          updateObjects.push(newObject);
        }
      } else {
        deleteKeys.push(obj.props.staticKey);
      }
    });

    currentObjects.forEach((obj) => {
      let oldObject = oldObjects.find((c) => c.props.staticKey === obj.props.staticKey);

      if (!oldObject) {
        newObjects.push(obj);
      }
    });

    if (updateObjects.length > 0 || deleteKeys.length > 0 || newObjects.length > 0) {
      return { updateObjects, deleteKeys, newObjects };
    } else {
      return false;
    }
  };

  saveCurrentSection = (callBack) => {
    this.saveContent(
      this.state.selectedTemplate.ID,
      this.jsonFunc.returnJsonString(),
      callBack,
      this.AccountID,
      global.Modeliks.CompanyInfo.ID,
      this.PitchScenarioInfo.ID,
    );
  };

  saveContent = (
    id,
    jsonString,
    callBack,
    accID,
    compID,
    pitchScenID,
    shouldLoad = true,
    shouldMakeScreenshot = true,
    selectedSection = this.state.selectedSection,
    shouldSetState = true,
  ) => {
    if (this.EditPermission && !this.state.printEnabled) {
      this.objectLoad = !shouldLoad;
      this.saving = shouldLoad;
      let jsonData = JSON.parse(this.state.jsonData);
      let tmpJsonString = JSON.parse(jsonString);

      let selectedTemplate =
        selectedSection && selectedSection.TemplateIndexes && selectedSection.TemplateIndexes[id];
      if (!selectedTemplate) {
        if (callBack) {
          callBack();
        }
        return;
      }
      if (selectedTemplate.isCustom) {
        let diff = this.findDifference(jsonData.slideObjects, tmpJsonString.slideObjects);
        if (diff) {
          if (this.props.onSave) {
            this.props.onSave();
          }
          selectedTemplate.UpdatedAt = new Date().getTime();
          global.Modeliks.SaveMostUsedColors();
          let api = "/api/";
          request
            .post(api + "slideObjectUser")
            .set("authorization", "Bearer " + window.localStorage.getItem("token"))
            .query({
              account_id: accID,
              company_id: compID,
              pitch_scenario_id: pitchScenID,
              templatePrefix: this.TemplateNamePrefix,
              tableName: this.TemplatesTableName,
            })
            .send({ TemplateID: id, difference: JSON.stringify(diff) })
            .then((err, res) => {
              this.objectLoad = false;
              this.saving = false;
              this.shouldMakeScreenshot = true;
              this.setState({ jsonData: jsonString }, () => {
                if (callBack) {
                  callBack();
                }
              });
            })
            .catch((error) => console.error("error", error));
        } else {
          this.objectLoad = false;
          if (callBack) {
            callBack();
          }
        }
      } else {
        jsonData.slideBg = jsonData.slideBg ? jsonData.slideBg.toLowerCase() : "#ffffff";
        tmpJsonString.slideBg = tmpJsonString.slideBg.toLowerCase();

        jsonData.slideObjects.forEach((obj) => {
          obj.key = -1;
          obj.oldKey = -1;
          obj.height = -1;
          obj.props.oldKey = -1;
          obj.props.replacementImage = null;
          obj.replacementImage = null;
        });

        tmpJsonString.slideObjects.forEach((obj) => {
          obj.key = -1;
          obj.oldKey = -1;
          obj.height = -1;
          obj.props.oldKey = -1;
          obj.props.replacementImage = null;
          obj.replacementImage = null;
        });

        if (JSON.stringify(jsonData) != JSON.stringify(tmpJsonString)) {
          global.Modeliks.SaveMostUsedColors();
          if (this.props.onSave) {
            this.props.onSave();
          }
          let api = "/api/";
          global.Modeliks.put(
            this.TemplatesTableName,
            { ID: id },
            { isCustom: true },
            (success) => {
              if (success) {
                this.state.selectedSection.TemplateIndexes[id].isCustom = true;
                this.state.selectedSection.TemplateIndexes[id].UpdatedAt = new Date().toISOString();
                request
                  .post(api + "slideObject")
                  .set("authorization", "Bearer " + window.localStorage.getItem("token"))
                  .query({
                    account_id: accID,
                    company_id: compID,
                    pitch_scenario_id: pitchScenID,
                    templatePrefix: this.TemplateNamePrefix,
                    tableName: this.TemplatesTableName,
                  })
                  .send({ TemplateID: id, jsonString: jsonString })
                  .then((err, res) => {
                    this.objectLoad = false;
                    this.saving = false;
                    this.setState({ jsonData: jsonString });
                    if (callBack) {
                      callBack();
                    }
                  })
                  .catch((error) => console.error("error", error));
              }
            },
          );
        } else {
          this.objectLoad = false;
          this.saving = false;
          if (callBack) {
            callBack();
          }
        }
      }
    }
  };

  saveNewSection = (id, jsonString, name, callBack) => {
    let section = {
      [this.SectionNameKey]: name,
      CategoryID: 5,
      order: this.SlideSections.length,
      isCustom: true,
      isDeleted: true,
      [this.PitchScenarioIDKey]: this.PitchScenarioInfo.ID,
      CreatedAt: new Date().toISOString(),
      UpdatedAt: new Date().toISOString(),
      CreatedBy: 1,
      UpdatedBy: 1,
    };

    let oldSection = this.state.selectedSection;
    let template = {
      TemplateName: "template",
      order: 1,
      isDefault: true,
      isCustom: true,
      CreatedAt: new Date().toISOString(),
      UpdatedAt: new Date().toISOString(),
      CreatedBy: 1,
      UpdatedBy: 1,
      MasterTemplateID: null,
    };

    if (this.props.onSave) {
      this.props.onSave();
    }

    request
      .post("/api/AddCustomSection")
      .query({
        account_id: global.Modeliks.Account.ID,
        company_id: global.Modeliks.CompanyInfo.ID,
        pitch_scenario_id: this.PitchScenarioInfo.ID,
      })
      .set("authorization", "Bearer " + window.localStorage.getItem("token"))
      .send({ section: section })
      .then((res) => {
        section.ID = res.body.id;
        template.SectionID = res.body.id;
        callBack();
        request
          .post("api/AddTemplate")
          .set("authorization", "Bearer " + window.localStorage.getItem("token"))
          .query({
            account_id: global.Modeliks.Account.ID,
            company_id: global.Modeliks.CompanyInfo.ID,
            pitch_scenario_id: this.PitchScenarioInfo.ID,
          })
          .send({
            section_id: res.body.id,
            MasterTemplateID: null,
          })
          .then((res1) => {
            template.ID = res1.body.id;
            request
              .post("api/slideObject")
              .set("authorization", "Bearer " + window.localStorage.getItem("token"))
              .query({
                account_id: global.Modeliks.Account.ID,
                company_id: global.Modeliks.CompanyInfo.ID,
                pitch_scenario_id: this.PitchScenarioInfo.ID,
              })
              .send({
                TemplateID: res1.body.id,
                jsonString: jsonString,
              })
              .then((result1) => {
                html2canvas(document.getElementById("se_scale_panel"), {
                  allowTaint: true,
                  useCORS: true,
                  dpi: 10,
                  scale: 0.5,
                }).then((canvas) => {
                  // It will return a canvas element
                  let image = canvas.toDataURL("image/png", 0.5);
                  request
                    .post("/api/" + "uploadImage")
                    .set("authorization", "Bearer " + window.localStorage.getItem("token"))
                    .query({
                      account_id: global.Modeliks.Account.ID,
                      company_id: global.Modeliks.CompanyInfo.ID,
                      pitch_scenario_id: this.PitchScenarioInfo.ID,
                    })
                    .send({ TemplateID: res1.body.id, base64str: image })
                    .then((err, res3) => {
                      this.appendSection(section, template, () => this.scale.refreshLeft());
                      this.SlideSections = this.SlideSections.filter((c) => c.isDeleted !== true);
                    });
                });

                // section.ID = res.body.id;
                // // this.setSave(section, 'slidesections');
              })
              .catch((err) => console.error(err));
          })
          .catch((err) => console.error(err));
      })
      .catch((err) => console.error(err));
  };

  changeCategory = (name, callBack) => {
    let category = this.Categories.filter((c) => c.CategoryName === name);
    let sections = category[0].Sections;
    let templates = sections[0].Templates;
    let selectedTemplateID = templates.filter((c) => c.isDefault === true)[0].ID;
    delete this.scale.textObjs;
    this.scale.textObjs = [];
    this.state.selectedCategory = name;
    this.state.sections = sections;
    this.state.selectedSection = this.state.sections[0].ID;
    this.state.templates = templates;
    this.state.templateKey = "template_" + new Date().getTime();
    this.forceUpdate();
    this.getTemplateContent();
    if (callBack) {
      callBack();
    }
  };

  getAllTemplatesData = (callBack) => {
    request
      .get(
        `/api/getPublishTemplates?client_id=${global.Modeliks.Account.ID}&company_id=${global.Modeliks.CompanyInfo.ID}&editor_type=${this.type ?? "pitch"}`,
      )
      .set("authorization", "Bearer " + window.localStorage.getItem("token"))
      .then((res2) => {
        if (res2.text !== "0") {
          let templatesArr = JSON.parse(res2.text);
          this.DefaultTemplates = templatesArr;
          callBack();
        }
      })
      .catch((err) => console.error(err));
  };

  selectPrevSlide = () => {
    let index = this.SlideSections.indexOf(this.state.selectedSection);

    if (index !== 0) {
      this.selectSectionByIndex(index - 1);
    } else {
      this.selectSectionByIndex(this.SlideSections.length - 1);
    }
  };

  selectNextSlide = () => {
    let index = this.SlideSections.indexOf(this.state.selectedSection);

    if (index !== this.SlideSections.length - 1) {
      this.selectSectionByIndex(index + 1);
    } else {
      this.selectSectionByIndex(0);
    }
  };

  selectSectionByIndex = (index) => {
    this.setState({ selectedSection: this.SlideSections[index] }, () => {
      let selectedTemplate = this.state.selectedSection.Templates.filter(
        (template) => template.isDefault,
      )[0];
      if (!selectedTemplate) {
        selectedTemplate = this.state.selectedSection.Templates[0];
      }
      this.state.selectedTemplate = selectedTemplate;
      this.getTemplateContent();
    });
  };

  selectSection = (index) => {
    if (index === 0) {
      this.setState({ selectedSection: this.SlideSections[0] }, () => {
        let selectedTemplate = this.state.selectedSection.Templates.filter(
          (template) => template.isDefault,
        )[0];
        if (!selectedTemplate) {
          selectedTemplate = this.state.selectedSection.Templates[0];
        }
        this.state.selectedTemplate = selectedTemplate;
        this.getTemplateContent();
      });
    } else {
      this.setState({ selectedSection: this.SlideSections[index - 1] }, () => {
        let selectedTemplate = this.state.selectedSection.Templates.filter(
          (template) => template.isDefault,
        )[0];
        if (!selectedTemplate) {
          selectedTemplate = this.state.selectedSection.Templates[0];
        }
        this.state.selectedTemplate = selectedTemplate;
        this.getTemplateContent();
      });
    }
  };

  deleteSection = (section_id) => {
    if (this.state.sections.length > 1) {
      let section_index = this.state.sections.findIndex((s) => s.ID === section_id);
      let section = this.state.sections[section_index];
      if (section.CategoryID === 5) {
        if (this.props.onSave) {
          this.props.onSave();
        }

        request
          .del(`/api/slidesections`)
          .query({ ID: section_id })
          .set(
            "Access-Control-Allow-Origin",
            window.location.protocol + "//" + window.location.host,
          )
          .set("Accept", "application/json")
          .then((res) => {
            if (this.state.selectedSection === section) {
              this.selectSection(section_index);
            }

            this.state.sections.splice(section_index, 1);
            if (this.state.sections.length === 0) {
              this.changeCategory(this.Categories[0].CategoryName, () => this.scale.refreshLeft());
            } else {
              this.scale.refreshLeft();
            }
          });
      }
    }
  };

  executeDeleteSectionFlow = async (section, index) => {
    // custom section
    if (section.isCustom) {
      try {
        const {
          slideSections,
          section: newSection,
          template,
        } = await this.PitchFlows.deleteCustomSectionWithId(index, section, this.SlideSections);

        this.SlideSections = slideSections;

        // section was the last one deleted, so we expect a new one here to append
        if (newSection && template) {
          this.appendSection(newSection, template);
          this.selectSection(index);
        }

        // not sure about the flow with condition of the selectedSection and section ids
        // leave for now
        if (this.state.selectedSection.ID == section.ID) {
          this.selectSection(index);
        } else {
          this.selectSection(index);
        }
      } catch (error) {
        console.error(error);
      }
      return;
    }

    // non custom section
    try {
      const {
        slideSections,
        section: newSection,
        template,
      } = await this.PitchFlows.deleteNonCustomSectionWithId(index, section.ID, this.SlideSections);

      this.SlideSections = slideSections;

      // section was the last one deleted, so we expect a new one here to append
      if (newSection && template) {
        this.appendSection(newSection, template);
        this.selectSection(index);
      }

      // not sure about the flow with condition of the selectedSection and section ids
      // leave for now
      if (this.state.selectedSection.ID == section.ID) {
        this.selectSection(index);
      } else {
        this.selectSection(index);
      }
    } catch (error) {
      console.error(error);
    }
  };

  deleteSectionFlow = async (section, index, callBack) => {
    try {
      // this is primitive implementation of command pattern
      // invoker class should execute commands
      const removeSectionCommand = new RemoveSection({
        actionForward: this.executeDeleteSectionFlow.bind(this),
        actionBackward: this.constructSection.bind(this),
        index,
        section,
        callBack,
      });

      await this.context.commandHistoryManager.executeCommand(removeSectionCommand);
      this.onAddToHistory();
    } catch (error) {
      console.error(error);
    }
  };

  setSectionsFromIndexes = () => {
    this.SlideSections = this.sortSections(Object.values(this.SlideSectionIndexes));
  };

  deleteSections = (ids = []) => {
    this.Categories[4].Sections = this.Categories[4].Sections.filter((c) => !ids.includes(c.ID));
    ids.forEach((c) => delete this.SlideSectionIndexes[c]);
    this.setSectionsFromIndexes();
    if (ids.includes(this.state.selectedSection.ID)) {
      this.state.selectedSection = this.SlideSections[0];
    }
    if (this.props.onSave) {
      this.props.onSave();
    }

    // this.SlideSections = this.SlideSections.filter(c => c.ID)
    this.forceUpdate();
  };

  handleChange = (e, val, callBack) => {
    if (this.EditPermission) {
      if (navigator.clipboard && navigator.clipboard.readText) {
        navigator.clipboard
          .readText()
          .then((clipText) => {
            this.timesPasted = 0;
            this.shouldPasteInPlace =
              clipText.includes("copyObject") || clipText.includes("copyObjects");
          })
          .catch(() => {});
      }

      let jsonString = this.jsonFunc.returnJsonString();
      this.loading = true;

      this.forceUpdate(() => {
        setTimeout(() => {
          this.saveContent(
            this.state.selectedTemplate.ID,
            jsonString,
            () => {
              delete this.scale.textObjs;
              this.scale.textObjs = [];
              this.state.selectedSection = this.SlideSectionIndexes[val];
              this.state.selectedTemplate = this.state.selectedSection.Templates.filter(
                (c) => c.isDefault === true,
              )[0];
              if (!this.state.selectedTemplate) {
                this.state.selectedTemplate = this.state.selectedSection.Templates[0];
              }
              if (this.state.selectedTemplate.hasOwnProperty("isCustom")) {
                this.getTemplateContent(callBack, false);
              } else {
                this.getTemplateContent(callBack, false);
              }
            },
            this.AccountID,
            global.Modeliks.CompanyInfo.ID,
            this.PitchScenarioInfo.ID,
            true,
            true,
            undefined,
            false,
          );
        }, 0);
      });
    } else {
      this.state.selectedSection = this.SlideSectionIndexes[val];
      // this.getTemplates(() => {
      this.state.selectedTemplate = this.SlideSectionIndexes[val].Templates.filter(
        (c) => c.isDefault === true,
      )[0];
      if (!this.state.selectedTemplate) {
        this.state.selectedTemplate = this.SlideSectionIndexes[val].Templates[0];
      }
      delete this.scale.textObjs;
      this.scale.textObjs = [];
      if (this.state.selectedTemplate.hasOwnProperty("isCustom")) {
        this.getTemplateContent(callBack);
      } else {
        this.getTemplateContent(callBack);
      }
      // })
    }
  };

  selectTemplate = (template) => {
    if (this.EditPermission) {
      this.loading = true;
      this.forceUpdate();
      let jsonString = this.jsonFunc.returnJsonString();
      this.saveContent(
        this.state.selectedTemplate.ID,
        jsonString,
        () => {
          if (template.ID !== this.state.selectedTemplate.ID) {
            template.isDefault = true;
          }
          this.state.selectedTemplate.isDefault = false;
          global.Modeliks.put(
            this.TemplatesTableName,
            { ID: [template.ID, this.state.selectedTemplate.ID] },
            [template, this.state.selectedTemplate],
            (success) => {
              this.state.selectedTemplate = template;
              this.getTemplateContent();
            },
          );
        },
        this.AccountID,
        global.Modeliks.CompanyInfo.ID,
        this.PitchScenarioInfo.ID,
      );
    } else {
      this.state.selectedTemplate = template;
      this.getTemplateContent();
    }
  };

  updateTemplate = (id, query, callBack) => {
    let section_index = this.state.sections.findIndex((s) => s.ID === this.state.selectedSection);
    let section = this.state.sections[section_index];
    if (this.state.selectedSection.isCustom) {
      request
        .put("/api/updateTemplate")
        .query({ ID: id })
        .send(query)
        .then((err, res) => {
          if (callBack) {
            callBack();
          }
        });
    } else {
      if (callBack) {
        callBack();
      }
    }
  };

  checkForChange(val, callBack) {
    let newSection = this.findNextSection(this.state.selectedSection.ID, val);
    if (newSection != null && newSection != this.state.selectedSection) {
      this.handleChange(null, newSection.ID, () => {
        setTimeout(() => this.scale.handleResize(), 1);
      });
    } else if (callBack) {
      callBack();
    }
  }

  handleKeyDown = (e) => {
    let index = this.SlideSections.findIndex(
      (section) => section.ID === this.state.selectedSection.ID,
    );
    let newSection = null;
    switch (e.key) {
      case "ArrowRight":
        this.checkForChange(1);
        break;
      case "ArrowLeft":
        this.checkForChange(-1);
        break;
    }
  };

  onPreview = () => {
    this.saveContent(
      this.state.selectedTemplate.ID,
      this.jsonFunc.returnJsonString(),
      () => {
        this.setState({
          preview: !this.state.preview,
          slideKey: "slideComponent_" + new Date().getTime(),
        });
      },
      global.Modeliks.Account.ID,
      global.Modeliks.CompanyInfo.ID,
      this.PitchScenarioInfo.ID,
      false,
      false,
    );
  };

  dontShowTemplates() {
    return false;
  }

  getTemplateContent = (callBack, shouldSetState = true) => {
    // this.state.zoom = 1;
    // this.state.footerZoomVal = 1;

    // let section = this.state.sections.filter(s => s.ID === this.state.selectedSection)[0]
    // this.state.selectedTemplate.isCustom ? this.state.selectedTemplate.ID : this.state.selectedTemplate.MasterTemplateID,

    if (navigator.clipboard && navigator.clipboard.readText && navigator.clipboard.readText) {
      navigator.clipboard
        .readText()
        .then((clipText) => {
          this.timesPasted = 0;
          this.shouldPasteInPlace =
            clipText.includes("copyObject") || clipText.includes("copyObjects");
          shouldSetState && this.forceUpdate();
        })
        .catch(() => {});
    }
    let queryString = "/api/serveStatic/";
    if (this.state.selectedTemplate.isCustom) {
      queryString += `ClientData/${global.Modeliks.Account.ID}/${global.Modeliks.CompanyInfo.ID}/${this.TemplateNamePrefix}${this.PitchScenarioInfo.ID}/${this.TemplateNamePrefix}template${this.state.selectedTemplate.ID}.json?v=${this.state.selectedTemplate.UpdatedAt}`;
    } else {
      queryString += `TemplatesData/template${this.state.selectedTemplate.MasterTemplateID}.json?v=${new Date().toISOString()}`;
    }

    // queryString += `.json?v=${this.state.selectedTemplate.UpdatedAt}`;

    const afterFileCallBack = (result) => {
      if (result) {
        this.saving = false;
        this.loading = false;
        this.setState(
          {
            jsonData: JSON.stringify(result.body),
            slideKey: "slideComponent_" + new Date().getTime(),
            loading: false,
          },
          callBack && callBack(),
        );
      } else {
        this.saving = false;
        this.loading = false;
        this.setState(
          {
            jsonData: [],
            slideKey: "slideComponent_" + new Date().getTime(),
            loading: false,
          },
          callBack && callBack(),
        );
      }
    };

    request
      .get(queryString)
      .then((result) => afterFileCallBack(result))
      .catch((error) => {
        request
          .get("/api/public/BlankTemplate.json")
          .then((result) => afterFileCallBack(result))
          .catch((error) => {
            console.error("error", error);
          });
      });
  };

  checkIfCustomTemplates = (templates, oldTemplates, index, callBack) => {
    if (index < templates.length) {
      if (templates[index].isCustom) {
        request
          .post("/api/copyFile")
          .set("authorization", "Bearer " + window.localStorage.getItem("token"))
          .query({
            old_template: oldTemplates[index].ID,
            new_template: templates[index].ID,
            company_id: global.Modeliks.CompanyInfo.ID,
            pitch_scenario_id: this.PitchScenarioInfo.ID,
            is_custom: true,
            templatePrefix: this.TemplateNamePrefix,
          })
          .then((res) => {
            if (res.body == "success") {
              this.checkIfCustomTemplates(templates, oldTemplates, index + 1, callBack);
            }
          });
      } else {
        this.checkIfCustomTemplates(templates, oldTemplates, index + 1, callBack);
      }
    } else {
      if (this.props.onSave) {
        this.props.onSave();
      }

      callBack();
    }
  };

  handleDuplicate = (section, callBack) => {
    this.saveContent(
      this.state.selectedTemplate.ID,
      this.jsonFunc.returnJsonString(),
      () => {
        section.order = section.order + 1;
        const index = this.SlideSections.indexOf(section) + 1;
        global.Modeliks.get(this.TemplatesTableName, { SectionID: section.ID }, (oldTemplates) => {
          if (section.isCustom) {
            section = global.Modeliks.cleanData([section], this.SlideSectionsTableName)[0];
            this.setTemplates(
              section,
              global.Modeliks.cleanData(oldTemplates, this.TemplatesTableName),
            );
            this.addSection(
              section,
              () => {
                this.scale.refreshLeft();
              },
              true,
            );
            callBack && callBack();
          } else {
            section = global.Modeliks.cleanData([section], this.SlideSectionsTableName, true)[0];
            let templates = global.Modeliks.cleanData(oldTemplates, this.TemplatesTableName, true);
            section.isCustom = false;
            templates.forEach((template) => {
              delete template.ID;
            });
            let counter = 0;

            const checkFinished = (templates, checkIfCustom, sections) => {
              counter++;
              if (counter === templates.length) {
                checkIfCustom(templates, oldTemplates, 0, () => {
                  this.setTemplates(section, templates);
                  // sections.splice(index, 0, section);
                  this.addSectionInObject(index, section, false);
                  // this.updateOrder();
                  this.setSlideSections(sections);
                  this.scale.refreshLeft();
                });
                callBack && callBack();
              }
            };

            global.Modeliks.post(this.SlideSectionsTableName, section, (data) => {
              section.ID = data.id;
              templates.forEach((template) => {
                template.SectionID = data.id;
                global.Modeliks.post(this.TemplatesTableName, template, (dataTemplates) => {
                  template.ID = dataTemplates.id;
                  checkFinished(templates, this.checkIfCustomTemplates, this.SlideSections);
                });
              });
            });
          }
        });
      },
      global.Modeliks.Account.ID,
      global.Modeliks.CompanyInfo.ID,
      this.PitchScenarioInfo.ID,
      false,
      false,
    );
  };

  setSlideSections = (sections = this.SlideSections) => {
    this.SlideSections = this.sortSections(sections);
    this.SlideSectionIndexes = {};
    this.SlideSections.forEach((c) => {
      Object.assign(this.SlideSectionIndexes, {
        [c.ID]: c,
      });
    });
  };
  setTemplates = (section, templates) => {
    section.Templates = templates;
    section.TemplateIndexes = {};
    templates.forEach((c) => {
      Object.assign(section.TemplateIndexes, {
        [c.ID]: c,
      });
    });
  };

  updateOrder = (update = true, callBack) => {
    this.SlideSections.forEach((c, index) => {
      if (c.order != index) {
        c.order = index;
        global.Modeliks.put(this.SlideSectionsTableName, { ID: c.ID }, { order: index }, () => {});
      }
    });

    if (update) {
      this.forceUpdate(() => {
        callBack && callBack();
      });
    } else {
      callBack && callBack();
    }
  };

  constructSection = async (index, section, callBack) => {
    let is_custom = section.CategoryID == 5;

    if (section.isCustom) {
      let oldSection = section;
      let newSection = { ...oldSection };
      delete newSection.ID;
      delete newSection.tableName;
      newSection[this.PitchScenarioIDKey] = this.PitchScenarioInfo.ID;
      newSection.order = this.state.selectedSection.order;
      newSection.isDeleted = false;
      newSection.dontShowInDialog = true;

      if (is_custom) {
        newSection.isCustom = true;

        let templates = JSON.parse(JSON.stringify(newSection.Templates));
        templates.forEach((template) => {
          delete template.tableName;
          template.isCustom = true;
        });
        delete newSection.Templates;
        delete newSection.TemplateIndexes;

        if (section.ID) {
          return request
            .post(`/api/addToCustomSection`)
            .set("authorization", "Bearer " + window.localStorage.getItem("token"))
            .query({
              company_id: global.Modeliks.CompanyInfo.ID,
              pitch_scenario_id: this.PitchScenarioInfo.ID,
              is_custom: is_custom,
            })
            .send({ section: newSection, templates: templates })
            .then((response) => {
              newSection.ID = response.body.section.id;
              templates.forEach((c, index) => {
                templates[index].ID = response.body.templates[index].id;
              });
              this.setTemplates(newSection, templates);
              // this.SlideSections.splice(index, 0, newSection);
              this.addSectionInObject(index, newSection, true);
              this.SlideSectionIndexes[newSection.ID] = newSection;
              // selectSection has {this.SlideSections[index - 1]} for selection actual section
              // adding 1 on our index here selects the correct section when it is newly added
              this.selectSection(index + 1);
              // this.Categories[4].Sections.splice(index, 0, newSection);
              this.scale.refreshLeft();
              // this.updateOrder();
              callBack();
              return newSection.ID;
            });
        }
      } else {
        const modeliksSection = await this.PitchFlows.createCustomSection(section);
        newSection.ID = modeliksSection.id;
        newSection.order = this.state.selectedSection.order;
        return request
          .get("/api/master/slidetemplates")
          .set("authorization", "Bearer " + window.localStorage.getItem("token"))
          .query({ SectionID: oldSection.ID })
          .then((templatesResult) => {
            if (templatesResult.body.length > 0) {
              let IDs = templatesResult.body.map((template) => template.ID);
              let newTemplates = [...templatesResult.body];
              let counter = 0;

              const checkFinished = (SlideSections) => {
                counter++;
                if (counter === 4) {
                  this.setTemplates(newSection, newTemplates);
                  // SlideSections.splice(index, 0, newSection);
                  this.addSectionInObject(index, newSection, false);
                  this.SlideSectionIndexes[newSection.ID] = newSection;
                  // selectSection has {this.SlideSections[index - 1]} for selection actual section
                  // adding 1 on our index here selects the correct section when it is newly added
                  this.selectSection(index + 1);
                  this.scale.refreshLeft();
                  // this.updateOrder();
                  callBack();
                }
              };

              newTemplates.forEach((template, index) => {
                delete template.ID;
                template.SectionID = modeliksSection.id;
                template.MasterTemplateID = IDs[index];
                template.isCustom = false;
                global.Modeliks.post(this.TemplatesTableName, template, (newTemplateResult) => {
                  template.ID = newTemplateResult.id;
                  template.UpdatedAt = new Date().toISOString();
                  checkFinished(this.SlideSections);
                });
              });

              return newSection.ID;
            }
          });
      }
    } else {
      let newSection = {
        ...section,
        MasterSectionID: section.MasterSectionID || section.ID,
        [this.PitchScenarioIDKey]: this.PitchScenarioInfo.ID,
        order: this.state.selectedSection.order + 1,
      };

      // keep this for the moment
      delete newSection.ID;

      if (is_custom) {
        newSection.isCustom = true;

        let templates = [...newSection.Templates];
        templates.forEach((template) => {
          template.isCustom = true;
        });
        delete newSection.Templates;
        delete newSection.TemplateIndexes;

        if (section.ID) {
          return request
            .post(`/api/addToCustomSection`)
            .set("authorization", "Bearer " + window.localStorage.getItem("token"))
            .query({
              company_id: global.Modeliks.CompanyInfo.ID,
              pitch_scenario_id: global.Modeliks.CompanyInfo.ID,
              is_custom: is_custom,
            })
            .send({ section: newSection, templates: templates })
            .then((response) => {
              templates.forEach((template, index) => {
                template.ID = response.body.templates[index].id;
              });
              newSection.ID = response.body.section.id;
              this.setTemplates(newSection, templates);
              // this.SlideSections.splice(index, 0, newSection);
              this.addSectionInObject(index, newSection, true);
              this.SlideSectionIndexes[newSection.ID] = newSection;
              // this.Categories[4].Sections.splice(index, 0, newSection);
              this.scale.refreshLeft();
              // selectSection has {this.SlideSections[index - 1]} for selection actual section
              // adding 1 on our index here selects the correct section when it is newly added
              this.selectSection(index + 1);
              // this.updateOrder();
              callBack();
              return newSection.ID;
            });
        }
      } else {
        try {
          const { updatedNewSection, newTemplates } = await this.PitchFlows.createNonCustomSection(
            newSection,
            section.ID,
          );

          this.setTemplates(updatedNewSection, newTemplates);
          this.addSectionInObject(index, updatedNewSection, false);
          this.SlideSectionIndexes[updatedNewSection.ID] = updatedNewSection;
          this.scale.refreshLeft();
          // selectSection has {this.SlideSections[index - 1]} for selection actual section
          // adding 1 on our index here selects the correct section when it is newly added
          this.selectSection(index + 1);
          callBack();
          return updatedNewSection.ID;
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  async createSection(section, callBack, duplicate) {
    try {
      let index = duplicate
        ? section.order - 1
        : this.SlideSections.indexOf(this.state.selectedSection) + 1;

      // this is primitive implementation of command pattern
      // invoker class should execute commands
      const addSectionCommand = new AddSection({
        actionForward: this.constructSection.bind(this),
        actionBackward: this.executeDeleteSectionFlow.bind(this),
        index,
        section,
        callBack,
      });

      await this.context.commandHistoryManager.executeCommand(addSectionCommand);
      this.onAddToHistory();
    } catch (error) {
      console.error(error);
    }
  }

  onAddToHistory = () => {
    if (global.slideHistory) {
      global.slideHistory.pushNewChange(this.context.commandHistoryManager, null, null, null);
    }
  };

  constructBlankSection = async (index, callBack) => {
    const { section, template } = await this.PitchFlows.createBlankSection({
      key: this.PitchScenarioIDKey,
      value: this.PitchScenarioInfo.ID,
      order: this.state.selectedSection.order + 1,
    });

    let currentSection = section[0];
    let currentTemplate = template[0];
    this.setTemplates(currentSection, [currentTemplate]);

    this.addSectionInObject(index, currentSection, true);
    // selectSection has {this.SlideSections[index - 1]} for selection actual section
    // adding 1 on our index here selects the correct section when it is newly added
    this.selectSection(index + 1);
    this.SlideSectionIndexes[currentSection.ID] = currentSection;

    this.forceUpdate(() => {
      if (this.props.onSave) {
        this.props.onSave();
      }

      callBack && callBack();
    });

    return currentSection;
  };

  async addBlankSection(callBack) {
    try {
      const index = this.SlideSections.indexOf(this.state.selectedSection) + 1;

      // this is primitive implementation of command pattern
      // invoker class should execute commands
      const addBlankSectionCommand = new AddBlankSection({
        actionForward: this.constructBlankSection.bind(this),
        actionBackward: this.executeDeleteSectionFlow.bind(this),
        index,
        callBack,
      });

      await this.context.commandHistoryManager.executeCommand(addBlankSectionCommand);
      this.onAddToHistory();
    } catch (error) {
      console.error(error);
    }
  }

  addSection(section, callBack, duplicate = false) {
    if (section.ID === this.state.selectedSection.ID && !duplicate) {
      this.saveContent(
        this.state.selectedTemplate.ID,
        this.jsonFunc.returnJsonString(),
        () => {
          this.createSection(section, callBack, duplicate);
        },
        global.Modeliks.Account.ID,
        global.Modeliks.CompanyInfo.ID,
        this.PitchScenarioInfo.ID,
        false,
        false,
      );
    } else {
      this.createSection(section, callBack, duplicate);
    }
  }

  updateSection = (id, query, callBack) => {
    let section_index = this.state.sections.findIndex((s) => s.ID === id);
    let section = this.state.sections[section_index];
    if (section.CategoryID === 5) {
      request
        .put("/api/updateSection")
        .query({ ID: id })
        .send(query)
        .then((err, res) => {
          if (callBack) {
            callBack();
          }
        });
    }
  };

  hideSection = (id, hidden, callBack) => {
    let section_index = this.state.sections.findIndex((s) => s.ID === id);
    let section = this.state.sections[section_index];
    if (section.CategoryID === 5) {
      request
        .put("/api/updateSection")
        .query({ ID: id })
        .send({ Hidden: hidden })
        .then((err, res) => {
          section.Hidden = hidden;
          if (callBack) {
            callBack();
          }
        });
    }
  };

  findNextSection = (sectionID, val) => {
    let previousSectionIndex = this.SlideSections.findIndex((section) => section.ID == sectionID);
    if (previousSectionIndex + val >= 0 && previousSectionIndex + val < this.SlideSections.length) {
      let newSection = this.SlideSections[previousSectionIndex + val];
      if (newSection.hasOwnProperty("Hidden")) {
        if (newSection.Hidden) {
          return this.findNextSection(newSection.ID, val);
        }
      }
      return newSection;
    }
    return null;
  };

  resetTemplate = () => {
    if (this.state.selectedTemplate.MasterTemplateID) {
      let id = this.state.selectedTemplate.ID;
      global.Modeliks.put(
        this.TemplatesTableName,
        { ID: id },
        { isCustom: false, isDefault: true },
        (success) => {
          if (success) {
            this.state.selectedTemplate.isCustom = false;
            this.state.selectedTemplate.UpdatedAt = new Date().toISOString();
            // this.state.zoom = 1;
            // this.state.footerZoomVal = 1;
            this.getTemplateContent();
          }
        },
      );
    }
  };

  getTemplatesForPrint = (callBack) => {
    request
      .get("/api/printTemplates")
      .set("authorization", "Bearer " + window.localStorage.getItem("token"))
      .query({
        company_id: global.Modeliks.CompanyInfo.ID,
        pitch_scenario_id: this.PitchScenarioInfo.ID,
        type: this.editor_type ?? undefined,
      })
      .then((data) => {
        let backgroundColors = data.body.splice(0, 1)[0];
        this.DefaultTemplates = data.body;
        this.setSlidesInfo();
        this.setState({ printEnabled: true, backgroundColors }, () => {
          setTimeout(() => {
            this.handleSlideObjectsBeforePrint(callBack);
          }, this.PDFTimeout);
        });
      });
  };

  findChildElement = (parent, targetTag = "table") => {
    if (parent) {
      if (parent.tagName.toLowerCase() == targetTag) {
        return parent;
      } else {
        for (let i = 0; i < parent.children.length; i++) {
          let result = this.findChildElement(parent.children[i], targetTag);
          if (result) {
            return result;
          }
        }
      }
    }
    return null;
  };

  handleSlideObjectsBeforePrint(callBack) {
    callBack && callBack();
    return;
  }

  setSlidesInfo = () => {
    this.DefaultTemplates.forEach((template, index) => {
      const curTemplate = JSON.parse(template);
      const currentSlideObjectIndex = curTemplate.slideObjects.findIndex(
        (obj) => obj.type == SlideTypes.mainFinanceTable,
      );
      if (currentSlideObjectIndex > -1) {
        this.MainFinanceTableMapping.push({
          templateIndex: index,
          slideObjectIndex: currentSlideObjectIndex,
        });
      }
    });
  };

  beforeSlidePrint(callBack) {
    document.getElementById("style1").media = "print";
    this.getTemplatesForPrint(callBack);
  }

  setSave = (item, tablename) => {
    item.tableName = tablename;
    item.Save = (success, error) => {
      global.Modeliks.put(item.tableName, null, item, success, error);
    };
  };

  afterSlidePrint(callBack) {
    document.getElementById("style1").media = "false";
    this.DefaultTemplates = [];
    this.setState({ printEnabled: false }, callBack && callBack());
  }

  onZoomChange = (val) => {
    let calcVal = val / 100;

    this.setState({ zoom: 1 + calcVal, footerZoomVal: val });
  };

  changeSelectedSection = () => {
    this.state.selectedSection = this.SlideSections[0];
  };

  getZoom = () => {
    return this.state.zoom;
  };

  openFreeTrialDialog = () => {
    this.setState({ openFreeTrialDialog: true });
  };

  getSelectedSection = () => {
    return this.state.selectedSection;
  };

  getSecondaryHeaderButton() {
    return {
      onClick: this.onPreview,
      canPreview: this.SlideSections.findIndex((c) => !c.Hidden) > -1,
      label: "Presentation Mode",
    };
  }

  handleScenarioChange = (ID) => {
    if (ID === global.Modeliks.PitchScenarioInfo.ID) {
      return 0;
    } else {
      let oldID = global.Modeliks.PitchScenarioInfo.ID;
      global.Modeliks.PitchScenarioInfo = global.Modeliks.PitchScenarios.getItem(ID);
      this.PitchScenarioInfo = global.Modeliks.PitchScenarioInfo;
      window.localStorage.setItem("PitchScenarioID", ID);
      this.LoadingFunc(() => {}, true, oldID);
    }
  };

  changeTemplateColor = (color, callBack) => {
    global.Modeliks.put(
      this.TemplatesTableName,
      { ID: this.state.selectedTemplate.ID },
      { BackgroundColor: color },
      (succ, err) => {
        if (succ) {
          this.state.selectedTemplate.BackgroundColor = color;
          this.forceUpdate(() => {
            callBack && callBack();
          });
        }
      },
    );
  };

  changeDeckColor = (color, callback) => {
    let counter = 0;
    let counterSucc = 0;

    this.SlideSections.forEach((c) => {
      c.Templates.forEach((t) => {
        if (t.BackgroundColor) {
          counter++;
          global.Modeliks.put(
            this.TemplatesTableName,
            { ID: t.ID },
            { BackgroundColor: null },
            (succ, err) => {
              if (succ) {
                counterSucc++;

                if (this.state.selectedTemplate.ID === t.ID) {
                  this.state.selectedTemplate.BackgroundColor = null;
                } else {
                  t.BackgroundColor = null;
                }

                if (counterSucc === counter) {
                  this.forceUpdate(() => {
                    callback && callback();
                  });
                }
              }
            },
          );
        }
      });
    });

    if (counter === 0) {
      callback && callback();
    }
  };

  hideTemplateButtons() {
    return false;
  }

  toggleFormatOptions = (formatOptions) => this.setState({ formatOptions });

  render() {
    if (this.PitchScenarioInfo && this.PitchScenarioInfo.Logo && this.state.printEnabled) {
      this.DateNow = new Date().getTime();
      this.LogoObj = <LogoComponent version={this.DateNow} />;
    } else {
      this.LogoObj = null;
    }

    if (this.state.loading) {
      return (
        <div className={"main_circle_loader_container"}>
          <CircleLoader />
        </div>
      );
    } else {
      return (
        <>
          {this.state.preview && (
            <Presentation
              selectedSectionIndex={this.SlideSections.findIndex(
                (c) => c.ID === this.state.selectedSection.ID,
              )}
              onPreviewExit={this.onPreview}
            />
          )}
          {!this.props.publishMode && this.SlideSections.length > 0 ? (
            <div className={"App slide_editor" + this.AdditionalClassName}>
              {/*{this.saving && <div className={'main_circle_loader_container_mask'}>*/}
              {/*    {m("enters loader", this.saving, this.loading)}*/}
              {/*    <CircleLoader/>*/}
              {/*</div>}*/}
              <SlideHeaderContainer
                goBack={this.props.goBack ? () => this.props.goBack(this.SlideSections) : undefined}
                {...this.getSecondaryHeaderButton(this.state.selectedSection)}
              />
              <div className={"se_panel_wrapper"}>
                <LeftMenu
                  updateSection={this.updateSection}
                  hideSection={this.hideSection}
                  offsetHeight={this.leftMenuOffset}
                  deleteSection={this.deleteSection}
                  saveCurrentSection={this.saveCurrentSection}
                  deleteSectionFlow={this.deleteSectionFlow}
                  addSection={this.addSection}
                  scale={this.scale}
                  hideTopButton={this.hideTopButton}
                  topButtonText={this.topButtonText}
                  disableEdit={!this.EditPermission}
                  handleDeleteSection={this.handleDeleteSection}
                  setSaving={this.setSaving}
                  addBlankSection={this.addBlankSection}
                  leftMenuTopOffset={this.leftMenuTopOffset ?? undefined}
                  user={true}
                  tabKey={this.SectionNameKey}
                  onScenarioChange={this.handleScenarioChange}
                  deleteSections={this.deleteSections}
                  handleDuplicate={this.handleDuplicate}
                  changeSelectedSection={this.selectSection}
                  update={(val) => this.scale.handleResize(val)}
                  updateOrder={this.updateOrder}
                  updateIndex={() => {
                    this.forceUpdate();
                  }}
                  SlideSectionsTableName={this.SlideSectionsTableName}
                  TemplatesTableName={this.TemplatesTableName}
                  render={() => this.forceUpdate()}
                  isAdmin={false}
                  tabs={this.SlideSections ? this.SlideSections : []}
                  sectionIndexes={this.SlideSectionIndexes}
                  selectedSection={this.state.selectedSection}
                  handleChange={this.handleChange}
                  appendSection={this.appendSection}
                  categories={this.Categories}
                  CurrentScreen={0}
                  onSectionMove={() => this.scale.updateSlideComp()}
                />
                <div
                  className={"se_panel_container"}
                  id={"se_panel_container"}
                  key={this.state.key}
                >
                  <div style={{ width: "100%", overflow: "hidden" }}>
                    <div
                      style={{
                        display: "grid",
                        overflow: "auto",
                        height: "100%",
                        width: "100%",
                        gridTemplateRows: "100% 0",
                        gridAutoColumns: "100%",
                        position: "relative",
                      }}
                    >
                      {(this.saving || this.loading) && (
                        <div className={"loader_container_on_top_sc"}>
                          <CircleLoader />
                        </div>
                      )}
                      {this.state.mounted && (
                        <SlideComponent
                          onPreview={() => this.onPreview()}
                          scale={this.scale}
                          jsonFunc={this.jsonFunc}
                          getDateRange={this.getDateRange}
                          documentTitle={this.documentTitle}
                          ScenarioNamePrefix={this.ScenarioNamePrefix}
                          hideTemplateButtons={this.hideTemplateButtons()}
                          onUnmount={true}
                          type={this.type ?? "pitch"}
                          PitchScenarioInfo={this.PitchScenarioInfo}
                          canShare={this.SlideSections.findIndex((c) => !c.Hidden) > -1}
                          preview={this.state.preview}
                          selectPrevSlide={this.selectPrevSlide}
                          selectNextSlide={this.selectNextSlide}
                          beforeSlidePrint={this.beforeSlidePrint}
                          setSaving={this.setSaving}
                          openFreeTrialDialog={this.openFreeTrialDialog}
                          setShouldPasteInPlace={this.setShouldPasteInPlace}
                          shouldPasteInPlace={this.shouldPasteInPlace}
                          setTimesPasted={this.setTimesPasted}
                          timesPasted={this.timesPasted}
                          getZoom={() => this.getZoom()}
                          afterPrint={this.afterSlidePrint}
                          selectedSection={this.state.selectedSection}
                          changeDeckColor={this.changeDeckColor}
                          setCounter={() => this.setState({ Counter: !this.state.Counter })}
                          SlideSections={this.SlideSections}
                          pageNumber={this.PitchScenarioInfo.Counter}
                          toggleFormatOptions={this.toggleFormatOptions}
                          disableEdit={!this.EditPermission}
                          saveNewSection={this.saveNewSection}
                          saveContent={this.saveContent}
                          selectedTemplateID={this.state.selectedTemplate.ID}
                          changeTemplateColor={this.changeTemplateColor}
                          selectedTemplateColor={
                            this.state.selectedTemplate.BackgroundColor ?? false
                          }
                          jsonData={this.state.jsonData}
                          openDialog={true}
                          className={this.saving && " hidden"}
                          key={this.state.slideKey}
                        />
                      )}
                    </div>
                  </div>

                  <div className={"se_panel_chart_editor"} id="chart_editor_portal"></div>
                  <Footer
                    handleZoomEnd={() => {
                      this.scale.handleResize && this.scale.handleResize();
                    }}
                    enableZoom
                    onPreviewMode={() => this.onPreview()}
                    onZoomChange={this.onZoomChange}
                    objectLoad={this.objectLoad}
                    zoomValue={this.state.footerZoomVal}
                    zoomRange={[-49, 100]}
                    disablePreviousButton={
                      this.SlideSections.indexOf(this.state.selectedSection) === 0
                    }
                    disableNextButton={
                      this.SlideSections.indexOf(this.state.selectedSection) ===
                      this.SlideSections.length - 1
                    }
                    changeSection={(val) => {
                      this.checkForChange(val);
                    }}
                  />
                </div>
                {this.EditPermission && (
                  <RightMenu
                    scale={this.scale}
                    update={(val) => {
                      if (this.state.zoom !== 1) {
                        this.setState({ zoom: 1, footerZoomVal: 1 }, () =>
                          this.scale.handleResize(val),
                        );
                      } else {
                        this.scale.handleResize(val);
                      }
                    }}
                    // ManagementReport={this.ManagementReports}
                    dontShowTemplates={this.dontShowTemplates()}
                    hideInstructions={this.hideInstructions}
                    rightExpandedMenuClass={this.rightExpandedMenuClass}
                    className={"options_menu"}
                    selectTemplate={this.selectTemplate}
                    selectedTemplateID={this.state.selectedTemplate.ID}
                    resetTemplate={this.resetTemplate}
                    selectedTemplate={this.state.selectedTemplate}
                    templates={this.state.selectedSection.Templates}
                    selectedSection={this.state.selectedSection}
                    sections={this.SlideSections}
                  />
                )}
              </div>
            </div>
          ) : this.state.open ? (
            <div className={"preview_slide"}>
              {this.state.templatesArray.map((c, index) => {
                return (
                  <SlideComponent
                    onPreview={() => this.onPreview()}
                    scale={this.scale}
                    preview={this.state.preview}
                    documentTitle={this.documentTitle}
                    publishMode={this.props.publishMode}
                    slideConfig={{
                      ...this.state.slideConfig,
                      Logo: index == 0 ? false : this.state.slideConfig.Logo,
                      BackgroundColor: this.state.backgroundColors[index]
                        ? this.state.backgroundColors[index]
                        : this.state.slideConfig.BackgroundColor,
                    }}
                    canShare={this.SlideSections.findIndex((c) => !c.Hidden) > -1}
                    pageNumber={index + 1}
                    saveNewSection={this.saveNewSection}
                    saveContent={this.saveContent}
                    // selectedTemplateID={this.state.selectedTemplate.ID}
                    jsonData={c}
                    index={index}
                    key={"sc_publish" + index.toString() + new Date().getTime()}
                  ></SlideComponent>
                );
              })}
            </div>
          ) : (
            <div className={"se_invalid_link_container"}>
              <Logo width={"30%"} height={"30%"} />
              <h1>Your access was revoked</h1>
            </div>
          )}
          {this.state.printEnabled && (
            <PrintablePortal>
              {this.DefaultTemplates.map((c, index) => {
                return (
                  <SlideComponent
                    printMode={true}
                    publishMode={true}
                    preview={this.state.preview}
                    scale={{ enabled: false }}
                    jsonData={c}
                    canShare={this.SlideSections.findIndex((c) => !c.Hidden) > -1}
                    slideConfig={{
                      BackgroundColor: this.state.backgroundColors[index]
                        ? this.state.backgroundColors[index]
                        : this.PitchScenarioInfo.BackgroundColor,
                      Counter: this.PitchScenarioInfo.Counter,
                      FooterText: this.PitchScenarioInfo.FooterText,
                    }}
                    afterPrint={this.afterSlidePrint}
                    pageNumber={index > 0 ? index + 1 : ""}
                    index={index}
                    key={"sc_print" + index.toString() + new Date().getTime()}
                  >
                    {index != 0 && this.LogoObj && this.LogoObj}
                  </SlideComponent>
                );
              })}
            </PrintablePortal>
          )}
          <FreeTrialDialog
            open={this.state.openFreeTrialDialog}
            handleClose={() => {
              this.setState({ openFreeTrialDialog: false });
            }}
          />
        </>
      );
    }
  }
}

Index.contextType = CommandManagerContext;

export default Index;
