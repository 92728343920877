import React from "react";
import styled from "styled-components";
import { Theme } from "../../theme/styles/theme";

const StyledFooter = styled.div`
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
  margin-bottom: 4px;
  padding-left: 16px;
  padding-right: calc(10% - 16px);
  margin-top: 5px;
  box-sizing: border-box;
`;
const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  > * {
    flex: 1;
  }
`;

export const Footer = ({ ...props }) => {
  return (
    <StyledFooter style={props.style}>
      <StyledContainer>{props.children}</StyledContainer>
    </StyledFooter>
  );
};
