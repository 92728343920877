import React, { Fragment } from "react";
class Selector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      style: {
        position: "absolute",
        height: 0,
        width: 0,
        display: "none",
        background: "#FAFBFE",
        border: "1px solid #E1F2F6",
        opacity: 0.4,
        zIndex: 999999999,
      },
    };
  }

  setStyle = (style) => {
    this.setState({
      style: style,
    });
  };

  getStyle = () => {
    return this.state.style;
  };

  render() {
    return (
      <Fragment>
        <div style={this.state.style}></div>
      </Fragment>
    );
  }
}
export default Selector;
