import React from "react";
import PropTypes from "prop-types";
import Button from "./Button";

function UploadButton({ label, icon, handleFile, width }) {
  const hiddenFileInput = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    handleFile(fileUploaded);
  };
  return (
    <>
      <Button
        size="large"
        padding="7.5px 25px 7.5px 25px"
        backgroundColor="primary"
        icon={icon}
        label={label}
        width={width}
        onClick={handleClick}
        iconLeft={true}
      />
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: "none" }}
      />
    </>
  );
}

UploadButton.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  handleFile: PropTypes.func,
};

export default UploadButton;
