import React, { Fragment, useState, useEffect } from "react";
import HeaderPortal from "../SlideHeader/HeaderPortal.js";
import HeaderImageEditor from "../SlideHeader/HeaderImageEditor";
import Cropper from "../components/Cropper/index";
import SCContextMenuPortal from "../components/menus/newContextMenu/SCContextMenuPortal";
import { MenuItem, SubMenu } from "@szhsin/react-menu";
import ContextMenu from "../components/menus/newContextMenu/ContextMenu";
import RotateSubMenu from "../components/menus/newContextMenu/RotateSubMenu";
import DialogM from "../components/Dialog/DialogM";
import OrderSubMenu from "../components/menus/newContextMenu/OrderSubMenu";
import CenterOnPageSubMenu from "../components/menus/newContextMenu/CenterOnPageSubMenu";
import {
  Copy,
  Cut,
  Exit,
  LinkFromExternalSource,
  Paste,
  SearchWeb,
  Search,
  UploadFromDrive,
  UploadFromPC,
} from "../components/IconButtons/SubMenuIcons";
import ListSubheader from "@mui/material/ListSubheader";
import { IconButton } from "@mui/material";
import List from "@mui/material/List";
import RightMenuPortal from "../components/Portals/RightMenuPortal";
import SizeAndRotation from "../components/RightMenuComponents/SizeAndRotationComponent";
import Position from "../components/RightMenuComponents/PositionComponent";
import HeaderMidPortal from "../components/Portals/HeaderMidPortal";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";

async function pasteImage() {
  try {
    const permission = await navigator.permissions.query({ name: "clipboard-read" });
    if (permission.state === "denied") {
      throw new Error("Not allowed to read clipboard.");
    }
    const clipboardContents = await navigator.clipboard.read();
    for (const item of clipboardContents) {
      if (!item.types.includes("image/png")) {
        throw new Error("Clipboard contains non-image data.");
      }
      const blob = await item.getType("image/png");
      // destinationImage.src = URL.createObjectURL(blob);
    }
  } catch (error) {
    console.error(error.message);
  }
}

class ImageObject extends React.Component {
  constructor(props) {
    super(props);

    this.originalImageSrc = props.value;
    this.imageEl = null;
    this.ref = React.createRef();
    this.loaded = false;
    this.rightMenuFunc = {};

    this.state = {
      style: props.style
        ? props.style
        : {
            borderColor: undefined,
            borderWeight: undefined,
            borderDash: undefined,
            height: "100%",
            width: "100%",
          },
      currentImageSrc: props.currentImageSrc ? props.currentImageSrc : this.originalImageSrc,
      crop: false,
      lockedAspect: props.lockedAspect,
      link: props.link ? props.link : "",
      menuProps: undefined,
      anchorPoint: { x: 0, y: 0 },
      open: null,
      // crop: false,
      // crop: false,
    };

    global.pasteListener = this.handlePaste;
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (nextProps.multipleSeleced != this.props.multipleSeleced) {
      if (nextProps.multipleSeleced) {
        global.pasteListener = this.handlePaste;
      }
    }

    if (nextProps.selected != this.props.selected) {
      if (nextProps.selected) {
        global.pasteListener = this.handlePaste;
      }
    }

    return true;
  }

  handlePaste = (event) => {
    event.preventDefault();
    this.props.slideComponentPaste(event, true);
  };
  setImageSrc = (src, width = "100%", height = "100%", replacementImage = false) => {
    // this.imageEl.style.height = width;
    // this.imageEl.style.width = height;
    // const newStyle = {};
    // Object.assign(newStyle, this.state.style);
    // Object.assign(newStyle, {width, height});
    // this.props.onChangedSize(width, height);

    this.props.onPropsChange({
      currentImageSrc: src,
      width: width,
      height: height,
      style: { ...this.state.style },
    });
    this.setState({ currentImageSrc: src });
    if (!replacementImage) {
      this.props.onChangedSize(width, height);
    }
  };

  imageLoaded = (e) => {
    this.imageEl.width = e.target.naturalWidth;
    this.imageEl.height = e.target.naturalHeight;

    if (this.props.originalSize) {
      this.props.onChangedSize(
        this.props.width ? this.props.width : this.imageEl.width,
        this.props.height ? this.props.height : this.imageEl.height,
      );
    } else {
      this.props.onChangedSize(e.target.naturalWidth, e.target.naturalHeight);
    }
    if (!this.props.originalSize) {
      this.props.onPropsChange(
        { originalSize: [this.imageEl.height, this.imageEl.width] },
        "props",
        false,
      );
    }

    this.imageEl.style.height = "100%";
    this.imageEl.style.width = "100%";
  };

  handleCrop = () => {
    this.props.setCrop(!this.props.crop);
    // this.setState({
    //     crop: !this.props.crop
    // })
  };

  handleStyleChanged = (changedStyle) => {
    const newStyle = {};
    Object.assign(newStyle, this.state.style);
    Object.assign(newStyle, changedStyle);
    this.props.onPropsChange({ style: newStyle });
    this.setState({
      style: newStyle,
    });
  };

  handleContextClick = (e) => {
    if (!this.props.disableEdit && !this.props.moreThanOneSelected) {
      e.preventDefault();
      e.stopPropagation();
      this.setState(
        {
          anchorPoint: { x: e.pageX, y: e.pageY },
          menuProps: true,
        },
        () => this.props.onSelect(),
      ); //vidi moze nema da raboti (izvadi ja od callback)
    }
  };

  closeModal = () => {
    this.setState({ open: false });
  };

  openModal = () => {
    this.setState({ open: true });
  };

  handleUploadImage = (file) => {
    this.closeModal();
    var reader = new FileReader();
    reader.readAsDataURL(file);
    var base64data;
    reader.onloadend = () => {
      base64data = reader.result;
      this.setImageSrc(base64data, this.imageEl.width, this.imageEl.height, true);
    };
  };

  handleContextClose = () => {
    this.setState({
      anchorPoint: { x: 0, y: 0 },
      menuProps: undefined,
    });
  };

  b64toBlob = (b64Data, contentType = null, sliceSize = null) => {
    contentType = contentType || "image/png";
    sliceSize = sliceSize || 512;
    let byteCharacters = atob(b64Data);
    let byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);
      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  };

  clip = (b64Data) => {
    if (b64Data.slice(11, 14) === "jpe") {
      return {
        "image/png": this.b64toBlob(
          b64Data.replace("data:image/jpeg;base64,", ""),
          "image/png",
          512,
        ),
      };
    } else if (b64Data.slice(11, 14) === "png") {
      return {
        "image/png": this.b64toBlob(
          b64Data.replace("data:image/png;base64,", ""),
          "image/png",
          512,
        ),
      };
    } else if (b64Data.slice(11, 14) === "jpg") {
      return {
        "image/png": this.b64toBlob(
          b64Data.replace("data:image/jpg;base64,", ""),
          "image/png",
          512,
        ),
      };
    }
  };

  toLink = () => {
    this.state.link = this.ref.current.value;
    this.props.onPropsChange({ link: this.ref.current.value });
    this.handleContextClose();
  };

  setImageRef = (ref) => {
    this.imageEl = ref;
  };

  render() {
    return (
      <>
        {!this.state.link ? (
          <img
            src={this.state.currentImageSrc}
            style={{
              ...this.state.style,
              display: this.props.crop ? "none" : "block",
              position: "absolute",
              top: 0,
              left: 0,
            }}
            onLoad={this.imageLoaded}
            ref={this.setImageRef}
            onDragStart={(event) => event.preventDefault()}
            onContextMenu={(e) => this.handleContextClick(e)}
          />
        ) : (
          <a
            className={"img_obj_disabled_link"}
            href={this.state.link}
            target={"_blank"}
            rel="noreferrer"
          >
            <img
              src={this.state.currentImageSrc}
              style={{
                ...this.state.style,
                display: this.props.crop ? "none" : "block",
              }}
              onLoad={this.imageLoaded}
              ref={this.setImageRef}
              onDragStart={(event) => event.preventDefault()}
              onContextMenu={(e) => this.handleContextClick(e)}
            />
          </a>
        )}
        {this.state.menuProps && (
          <SCContextMenuPortal>
            <ContextMenu
              onMouseDown={(e) => {
                e.stopPropagation();
              }}
              handleClose={() => this.handleContextClose()}
              anchorPoint={this.state.anchorPoint}
              menuProps={this.state.menuProps}
              onDelete={this.props.onDelete}
            >
              <MenuItem
                className="cm_icon_helper"
                onClick={(event) => {
                  // navigator.clipboard.write([new ClipboardItem(clip(currentImageSrc))]);
                  this.props.cutObjects();
                }}
              >
                <Cut className="cm_icon" />
                <span className="cm_btn_name">Cut</span>
                <span className="cm_helper_text">Ctrl+X</span>
              </MenuItem>
              <MenuItem className="cm_icon_helper" onClick={this.props.copyObjects}>
                <Copy className="cm_icon" />
                <span className="cm_btn_name">Copy</span>
                <span className="cm_helper_text">Ctrl+C</span>
              </MenuItem>
              <MenuItem className="cm_icon_helper" onClick={this.props.handleDuplicateObject}>
                <Paste className="cm_icon" />
                <span className="cm_btn_name">Duplicate</span>
                <span className="cm_helper_text">Ctrl+D</span>
              </MenuItem>
              <MenuItem onClick={this.props.onDelete}>
                <span className="cm_btn_name">Delete</span>
              </MenuItem>
              {this.props.isGrouped && (
                <MenuItem onClick={() => this.props.ungroupObjects(this.props.groupKey)}>
                  <span className="cm_btn_name">Ungroup Objects</span>
                </MenuItem>
              )}
              <div className="cm_divider" />
              <OrderSubMenu
                sentToFront={this.props.sentToFront}
                sentToBack={this.props.sentToBack}
                bringForword={this.props.bringForword}
                bringBackward={this.props.bringBackward}
              />
              <CenterOnPageSubMenu
                {...this.props}
                horizontally={() =>
                  this.props.updatePosition(640 - this.props.width / 2, this.props.top)
                }
                vertically={() =>
                  this.props.updatePosition(this.props.left, 360 - this.props.height / 2)
                }
              />
              <RotateSubMenu
                height={this.props.height}
                width={this.props.width}
                rotateAngle={this.props.rotateAngle}
                scaleX={this.props.scaleX}
                scaleY={this.props.scaleY}
                onChange={this.props.onChange}
              />
              <div className="cm_divider" />
              <SubMenu label="Link">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <FormControl variant="outlined">
                    <input
                      defaultValue={this.state.link}
                      type={"text"}
                      ref={this.ref}
                      placeholder={"Search or paste link"}
                    />
                  </FormControl>
                  <Button variant="text" onClick={this.toLink}>
                    Set
                  </Button>
                </div>
              </SubMenu>
              <MenuItem onClick={this.props.toggleFormatOptions}>
                <span className="cm_btn_name">Format options</span>
              </MenuItem>
              <div className="cm_divider" />
              <MenuItem onClick={this.handleCrop}>
                <span className="cm_btn_name">Crop image</span>
              </MenuItem>
              <SubMenu label="Replace Image">
                <MenuItem className="cm_icon_helper" onClick={() => this.openModal()}>
                  <UploadFromPC />
                  <span className="cm_btn_name">Upload from computer</span>
                  <span className="cm_helper_text">Shift+Alt</span>
                </MenuItem>
              </SubMenu>
              <MenuItem
                onClick={() =>
                  this.setImageSrc(
                    this.originalImageSrc,
                    parseInt(this.props.originalSize[1]),
                    parseInt(this.props.originalSize[0]),
                  )
                }
              >
                <span className="cm_btn_name">Reset Image</span>
              </MenuItem>
            </ContextMenu>
          </SCContextMenuPortal>
        )}
        {this.props.selected && !this.props.multipleSeleced && (
          <HeaderMidPortal>
            <HeaderImageEditor
              style={this.state.style}
              crop={this.crop}
              onChange={this.handleStyleChanged}
              onReplaceImage={() => this.openModal()}
              originalSrc={this.originalImageSrc}
              originalSize={this.props.originalSize ? this.props.originalSize : null}
              handleCrop={this.handleCrop}
              setImageSrc={this.setImageSrc}
              toggleFormatOptions={this.props.toggleFormatOptions}
              onPreview={this.props.onPreview}
            />
          </HeaderMidPortal>
        )}
        {this.props.formatOptions && this.props.selected && (
          <RightMenuPortal>
            <div className={"right_menu_mask"} onMouseDown={(e) => e.stopPropagation()}>
              <List
                style={{
                  paddingLeft: "0",
                  position: "absolute",
                  width: "100%",
                  height: "auto",
                  maxWidth: 250,
                  bgcolor: "background.paper",
                  paddingBottom: "0",
                  paddingTop: "0",
                  // overflow:'scroll',
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 700,
                          fontSize: "16px",
                          textAlign: "left",
                        }}
                      >
                        Format Options
                      </span>
                      <IconButton onClick={this.props.toggleFormatOptions}>
                        <Exit />
                      </IconButton>
                    </div>
                  </ListSubheader>
                }
              >
                <SizeAndRotation
                  lockedAspect={this.props.lockedAspect}
                  rightMenuFunc={this.rightMenuFunc}
                  changeLockedAspect={this.props.changeLockedAspect}
                  onChange={this.props.onUpdateSizeAndRotation}
                  style={this.props.style}
                  width={this.props.width}
                  height={this.props.height}
                  rotateAngle={this.props.rotateAngle}
                  scaleX={this.props.scaleX}
                  scaleY={this.props.scaleY}
                />
                <Position
                  onChange={this.props.onUpdatePosition}
                  rightMenuFunc={this.rightMenuFunc}
                  style={this.props.style}
                  top={this.props.top}
                  left={this.props.left}
                />
              </List>
            </div>
          </RightMenuPortal>
        )}
        {this.state.open && (
          <DialogM open handleUpload={this.handleUploadImage} onClose={this.closeModal} />
        )}
        {this.props.crop && (
          <Cropper
            onDragStart={(e) => e.stopPropagation()}
            value={this.state.currentImageSrc}
            rotateAngle={this.props.rotateAngle}
            setImageSrc={this.setImageSrc}
            style={this.state.style}
            handleCrop={this.handleCrop}
          />
        )}
      </>
    );
  }
}

export default ImageObject;
