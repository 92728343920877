const TaxesTypes = {
  IncomeTax: "income-tax",
  SalesTax: "sales-tax",
  VAT: "vat",
};

const RateChange = {
  true: true,
  false: false,
};

const RateVery = {
  true: true,
  false: false,
};

export { TaxesTypes, RateChange, RateVery };
