export const Theme = {
  colors: {
    primary: {
      background: `#FBFBFB`,
      green: {
        25: `#ADF6F6`,
        50: `#00F3EF`,
        100: `#00E6E2`,
        150: `#00D9D6`,
        300: `#00B3B0`,
        500: `#00807E`,
        600: `#006160`,
      },
      blue: {
        50: `#1796FF`,
        150: `#0088F7`,
        300: `#006FC9`,
        500: `#004D8C`,
        600: `#003968`,
      },
      lightblue: {
        50: `#E1F2F6`,
        150: `#80D7FE`,
        300: `#01AFFD`,
      },
      lightgrey: {
        50: `#FAFBFE`,
        150: `#F0F1F7`,
        300: `#E9EAF0`,
        500: `#D4D5DE`,
        600: `#E5E5EB`,
      },
      darkgrey: {
        50: `#979797`,
        150: `#585858`,
        300: `#3A3A3A`,
        500: `#252525`,
      },
    },
    secondary: {
      purple: {
        50: `#C37FD4`,
        100: `#BC6FCF`,
        150: `#B560CA`,
        300: `#9A3CB1`,
        500: `#652774`,
        600: `#471B52`,
      },
      lightblue: {
        50: `#E1EEF4`,
        100: `#D7E8F1`,
        150: `#CEE3ED`,
        300: `#B2D3E3`,
        500: `#8CBED6`,
        600: `#75B1CE`,
      },
    },
    greyscale: {
      white: `#FFFFFF`,
      50: `#F7F7F8`,
      100: `#F0F1F2`,
      150: `#E9EBEC`,
      300: `#D4D8DB`,
      500: `#B9BFC3`,
      600: `#A9B0B5`,
      800: `#889299`,
      black: "#000000",
    },
    accent: {
      red: {
        50: `#FBE8E8`,
        100: `#EEC2C2`,
        150: `#EA0000`,
        300: `#FF3D3D`,
        500: `#FF7A7A`,
        600: `#BC0000`,
      },
      green: {
        25: `#F0FCED`,
        50: `#C6EEBE`,
        150: `#66D151`,
        300: `#91DE81`,
        500: `#B0E7A6`,
        600: `#43AD2E`,
      },
    },
  },
  effect: {
    innerShadow: {
      background: `#C4C4C4`,
      boxShadow: `inset 0px 0px 6px rgba(0, 0, 0, 0.2)`,
    },
    elevation: {
      background: "#C4C4C4",
      boxShadow: `0px 2px 4px rgba(0, 0, 0, 0.1)`,
    },
    elevation2: {
      background: "#C4C4C4",
      boxShadow: `0px 5px 20px rgba(0, 0, 0, 0.06)`,
    },
  },
  text: {
    heading: {
      h1: {
        fontStyle: `normal`,
        fontWeight: `400`,
        fontSize: `48px`,
        lineHeight: `140%`,
      },
      h1_strong: {
        fontStyle: `normal`,
        fontWeight: `700`,
        fontSize: `48px`,
        lineHeight: `140%`,
      },
      h2: {
        fontStyle: `normal`,
        fontWeight: `400`,
        fontSize: `40px`,
        lineHeight: `140%`,
      },
      h2_strong: {
        fontStyle: `normal`,
        fontWeight: `700`,
        fontSize: `40px`,
        lineHeight: `140%`,
      },
      h3: {
        fontStyle: `normal`,
        fontWeight: `400`,
        fontSize: `32px`,
        lineHeight: `140%`,
      },
      h3_strong: {
        fontStyle: `normal`,
        fontWeight: `700`,
        fontSize: `32px`,
        lineHeight: `140%`,
      },
      h4: {
        fontStyle: `normal`,
        fontWeight: `400`,
        fontSize: `24px`,
        lineHeight: `140%`,
      },
      h4_strong: {
        fontStyle: `normal`,
        fontWeight: `700`,
        fontSize: `24px`,
        lineHeight: `140%`,
      },
    },
    paragraph: {
      p1: {
        fontStyle: `normal`,
        fontWeight: `400`,
        fontSize: `20px`,
        lineHeight: `160%`,
      },
      p1_strong: {
        fontStyle: `normal`,
        fontWeight: `700`,
        fontSize: `20px`,
        lineHeight: `160%`,
      },
      p2: {
        fontStyle: `normal`,
        fontWeight: `400`,
        fontSize: `16px`,
        lineHeight: `160%`,
      },
      p2_strong: {
        fontStyle: `normal`,
        fontWeight: `700`,
        fontSize: `16px`,
        lineHeight: `160%`,
      },
      p3: {
        fontStyle: `normal`,
        fontWeight: `400`,
        fontSize: `14px`,
        lineHeight: `160%`,
      },
      p3_strong: {
        fontStyle: `normal`,
        fontWeight: `700`,
        fontSize: `14px`,
        lineHeight: `160%`,
      },
      p4: {
        fontStyle: `normal`,
        fontWeight: `400`,
        fontSize: `12px`,
        lineHeight: `160%`,
      },
      p4_strong: {
        fontStyle: `normal`,
        fontWeight: `700`,
        fontSize: `12px`,
        lineHeight: `160%`,
      },
      p5: {
        fontStyle: `normal`,
        fontWeight: `400`,
        fontSize: `10px`,
        lineHeight: `160%`,
      },
      p5_strong: {
        fontStyle: `normal`,
        fontWeight: `700`,
        fontSize: `10px`,
        lineHeight: `160%`,
      },
      p6: {
        fontStyle: `normal`,
        fontWeight: `400`,
        fontSize: `8px`,
        lineHeight: `120%`,
      },
    },
    label: {
      label1: {
        fontStyle: `normal`,
        fontWeight: `600`,
        fontSize: `20px`,
        lineHeight: `150%`,
      },
      label2: {
        fontStyle: `normal`,
        fontWeight: `600`,
        fontSize: `14px`,
        lineHeight: `150%`,
      },
      label3: {
        fontStyle: `normal`,
        fontWeight: `600`,
        fontSize: `12px`,
        lineHeight: `100%`,
      },
      label4: {
        fontStyle: `normal`,
        fontWeight: `600`,
        fontSize: `10px`,
        lineHeight: `100%`,
      },
      label_strong: {
        fontStyle: `normal`,
        fontWeight: `700`,
        fontSize: `10px`,
        lineHeight: `100%`,
      },
    },
  },
  button: {
    large: {
      // width: `200px`,
      height: `36px`,
    },
    small: {
      width: `136px`,
      height: `32px`,
    },
  },
};
