import React, { useState, useEffect, useMemo } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Active } from "../dividers/ActiveTab";
import "./tabs.scss";
import { useResolvedPath } from "react-router-dom";
import { useNavigate } from "react-router-dom/dist/index";
import UpdatePlan from "../dialogs/LimitDialogs/UpdatePlan";

const TabsM = (props) => {
  const navigate = useNavigate();
  const resolved = useResolvedPath();
  const [tabs, setTabs] = useState(props.children.tabs);
  const findTab = useMemo(() => tabs.find((child) => resolved.pathname.includes(child.parent)));
  const [tab, setTab] = useState(findTab ? findTab : false);
  const newExisting = useMemo(() => tabs.find((child) => resolved.pathname.includes(child.parent)));
  const [updatePlan, setUpdatePlan] = useState(false);

  const onClickNavigate = (e, newTab) => {
    if (
      newTab.key === "dashboards" &&
      !global.Modeliks.PERMISSIONS.Dashboards.restrictions.overAll.active
    ) {
      setUpdatePlan(true);
    }

    if (newTab !== tab || !findTab) {
      setTab(newTab);
      if (newTab.hasOwnProperty("key")) {
        navigate(props.defaultUrl[newTab.key]);
      } else {
        navigate(newTab.link);
      }
    }
  };

  useEffect(() => {
    if (findTab && findTab !== tab) {
      setTab(findTab);
    }
  }, [resolved, findTab, tab]);

  return (
    <>
      {updatePlan && (
        <UpdatePlan open={updatePlan} dashboards handleClose={() => setUpdatePlan(false)} />
      )}
      <Tabs
        className="mctabs_tabs"
        value={findTab ? findTab : false}
        centered
        TabIndicatorProps={{
          children: <div className="mctabs_indicator">{newExisting && <Active />}</div>,
        }}
        onChange={onClickNavigate}
        aria-label="basic tabs example"
      >
        {tabs.map((child, index) => {
          const { label, link, size, className } = child;
          return (
            <Tab
              disableRipple
              key={"tab_main" + child.link}
              label={
                child.startIcon ? (
                  <div className={"mc_tabs_tab_cont"}>
                    {child.startIcon}
                    {label}
                  </div>
                ) : (
                  label
                )
              }
              value={child}
              className={`mctabs_tab ${size} ${newExisting && link === newExisting.link && "selected"} ${className}`}
            />
          );
        })}
      </Tabs>
    </>
  );
};

export default TabsM;
