import Revenue from "../index";
import CalculatedDriver from "../../CalculatedDriver";
import { RevenueTypes } from "../../../../components/constants/finance";
import { DriverCategories, UnitTypes, SpecialChar } from "../../CalculatedDriver/constants";

class BillableHours extends Revenue {
  BillableHours;
  HourlyRate;

  RevenueType = RevenueTypes.BillableHours;

  constructor(db_record) {
    super(db_record);
  }

  static DriversDesc = Object.assign(
    {
      BillableHours: {
        driverName: `Billable Hours${SpecialChar.DriverNameESCChar}$RevenueName`,
        fieldName: "BillableHours",
        driverID: "billable_hours",
      },
      HourlyRate: {
        driverName: `Hourly Rate${SpecialChar.DriverNameESCChar}$RevenueName`,
        fieldName: "HourlyRate",
        driverID: "hourly_rate",
      },
    },
    Revenue.DriversDesc,
  );

  static convert_Revenue = (revenue) => {
    const newBillableHours = new BillableHours();
    newBillableHours.ID = revenue.ID;
    newBillableHours.IsCreated = true;
    newBillableHours.RevenueName = revenue.RevenueName;
    newBillableHours.Totals = CalculatedDriver.createDriverFromTable(
      newBillableHours,
      Revenue.DriversDesc.Total.driverID,
      UnitTypes.Price,
      DriverCategories.Sum,
    );
    newBillableHours.RevenueType = RevenueTypes.BillableHours;
    newBillableHours.BillableHours = CalculatedDriver.createDriverFromTable(
      newBillableHours,
      BillableHours.DriversDesc.BillableHours.driverID,
      UnitTypes.Units,
      DriverCategories.Sum,
    );
    newBillableHours.HourlyRate = CalculatedDriver.createDriverFromTable(
      newBillableHours,
      BillableHours.DriversDesc.HourlyRate.driverID,
      UnitTypes.Price,
      DriverCategories.Average,
    );
    newBillableHours.Totals.setFormula(
      `|${newBillableHours.BillableHours.ID}|*|${newBillableHours.HourlyRate.ID}|`,
    );

    global.Modeliks.DateHelper.years_all.forEach((y) => {
      if (!y.Active) {
        const curYearHourlyRate = newBillableHours.HourlyRate.getItemByDateSufix(y.sufix);
        const curYearBillableHours = newBillableHours.BillableHours.getItemByDateSufix(y.sufix);
        const curYearTotals = newBillableHours.Totals.getItemByDateSufix(y.sufix);
        curYearHourlyRate.Formula = `${curYearTotals} / ${curYearBillableHours}`;
      }
    });

    newBillableHours.changeDriversName();

    return newBillableHours;
  };
}

export default BillableHours;
