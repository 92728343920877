// import HeaderPortal from './HeaderPortal'
import React, { Fragment, useState } from "react";
import "../HeaderTextEditor.scss";
import "../HeaderDefault.scss";
import { Charts } from "../../components/IconButtons/SubMenuIcons";
import {
  BubbleChart,
  ClusteredHorizontalBar,
  ClusteredVerticalBar,
  Combo,
  DoughnutChart,
  FloatingBarChart,
  HorizontalBar,
  LineChart,
  PieChart,
  ScatterChart,
  StackedBarChart,
  StackedBarLine,
  VerticalBar,
} from "../../components/IconButtons/SubMenuIcons";
import { SlideObject, SlideTypes } from "../../SlideObjects/SlideStructures";
import { Menu, MenuItem, SubMenu, MenuButton } from "@szhsin/react-menu";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

function InsertChartComponent(props) {
  const handleNewChartObj = (chartType, top, left, width, height, e) => {
    let showTitle = false;
    let showSubtitle = false;
    let titleAlign = "start";
    let displayLegend = false;
    let legendPosition = "bottom";
    let xGrid = false;
    let yGrid = false;

    props.setInsertMode(
      SlideTypes.chartObject,
      (top, left) => {
        const props1 = {
          chartType,
          top,
          left,
          width,
          height,
          showTitle,
          showSubtitle,
          titleAlign,
          displayLegend,
          legendPosition,
          xGrid,
          yGrid,
        };
        const nObj = new SlideObject(SlideTypes.chartObject, null, props1);
        props.onNewObject(nObj);
      },
      e,
    );
  };

  //<Tooltip disableHoverListener={props.disableEdit}  arrow title={'Insert Chart'}><span>
  // </span></Tooltip>
  const ChartInsert = () => {
    return (
      <SubMenu
        className="header_menu"
        label={({ open }) => (
          <span>
            <Charts active={open} />
          </span>
        )}
      >
        <MenuItem onClick={(e) => handleNewChartObj(6, 100, 20, 600, 300, e)}>
          <HorizontalBar />
          <span className="item_name">Horizontal Bar</span>
          {/*<span className='helper_text'>Shift+Alt</span>*/}
        </MenuItem>
        <MenuItem onClick={(e) => handleNewChartObj(3, 100, 20, 600, 300, e)}>
          <ClusteredHorizontalBar />
          <span className="item_name">Clustered Horizontal Bar</span>
          {/*<span className='helper_text'>Shift+Alt</span>*/}
        </MenuItem>
        <MenuItem onClick={(e) => handleNewChartObj(4, 100, 20, 600, 300, e)}>
          <VerticalBar />
          <span className="item_name">Vertical Bar</span>
          {/*<span className='helper_text'>Shift+Alt</span>*/}
        </MenuItem>
        <MenuItem onClick={(e) => handleNewChartObj(2, 100, 20, 600, 300, e)}>
          <ClusteredVerticalBar />
          <span className="item_name">Clustered Vertical Bar</span>
          {/*<span className='helper_text'>Shift+Alt</span>*/}
        </MenuItem>
        <MenuItem onClick={(e) => handleNewChartObj(1, 100, 20, 600, 300, e)}>
          <StackedBarChart />
          <span className="item_name">Stacked Bar Chart</span>
          {/*<span className='helper_text'>Shift+Alt</span>*/}
        </MenuItem>
        <MenuItem onClick={(e) => handleNewChartObj(14, 100, 20, 600, 300, e)}>
          <FloatingBarChart />
          <span className="item_name">Waterfall Chart</span>
          {/*<span className='helper_text'>Shift+Alt</span>*/}
        </MenuItem>
        <MenuItem onClick={(e) => handleNewChartObj(13, 100, 20, 600, 300, e)}>
          <LineChart />
          <span className="item_name">Line Chart</span>
          {/*<span className='helper_text'>Shift+Alt</span>*/}
        </MenuItem>
        <MenuItem onClick={(e) => handleNewChartObj(7, 100, 20, 600, 300, e)}>
          <BubbleChart />
          <span className="item_name">Bubble Chart</span>
          {/*<span className='helper_text'>Shift+Alt</span>*/}
        </MenuItem>
        <MenuItem onClick={(e) => handleNewChartObj(8, 100, 20, 600, 300, e)}>
          <Combo />
          <span className="item_name">Combo (bar/line)</span>
          {/*<span className='helper_text'>Shift+Alt</span>*/}
        </MenuItem>
        <MenuItem onClick={(e) => handleNewChartObj(9, 100, 20, 600, 300, e)}>
          <DoughnutChart />
          <span className="item_name">Doughnut Chart</span>
          {/*<span className='helper_text'>Shift+Alt</span>*/}
        </MenuItem>
        <MenuItem onClick={(e) => handleNewChartObj(10, 100, 20, 600, 300, e)}>
          <PieChart />
          <span className="item_name">Pie Chart</span>
          {/*<span className='helper_text'>Shift+Alt</span>*/}
        </MenuItem>
        <MenuItem onClick={(e) => handleNewChartObj(11, 100, 20, 600, 300, e)}>
          <ScatterChart />
          <span className="item_name">Scatter Chart</span>
          {/*<span className='helper_text'>Shift+Alt</span>*/}
        </MenuItem>
        <MenuItem onClick={(e) => handleNewChartObj(12, 100, 20, 600, 300, e)}>
          <StackedBarLine />
          <span className="item_name">Stacked Bar/Line</span>
          {/*<span className='helper_text'>Shift+Alt</span>*/}
        </MenuItem>
      </SubMenu>
    );
  };

  return <>{ChartInsert()}</>;
}
export default InsertChartComponent;
