import React, { useState, useEffect } from "react";
import "../CashBalanceDialog/index.scss";
import { FinancingTypes, Messages } from "../../../../data/Finance/Financing/constants";
import Mx_Tooltip from "../../../icons/Mx_Tooltip_Click";
import Mx_Tooltip_Hover from "../../../icons/Mx_Tooltip_Hover";

function LineOfCreditDialog(props) {
  const [monthlyRepayments, setValidateMonthlyRepayments] = useState(false);
  const [creditLimit, setValidateCreditLimit] = useState(false);
  const [otherLiability, setOtherLiability] = useState(false);

  useEffect(() => {
    if (props.editMode) {
      if (props.lineOfCredit) {
        validateCreditLimit();
      } else {
        validateOtherLiability();
      }
    } else {
      validate();
    }
  }, [props.itemChanged, props.rows]);

  const validate = () => {
    const lineOfCreditStreams = global.Modeliks.FinancingStore.filter(
      (f) =>
        f.FinanceType === FinancingTypes.LineOfCredit ||
        f.FinanceType === FinancingTypes.OtherLiability,
    );
    if (lineOfCreditStreams.length > 0) {
      lineOfCreditStreams.forEach((d) => {
        if (d.FinanceType === FinancingTypes.LineOfCredit) {
          validateTotalRepayments(d);
          validateCreditLimit(d);
        } else {
          validateTotalRepaymentsOtherLiab(d);
          validateOtherLiability(d);
        }
      });
    }
  };

  const validateTotalRepaymentsOtherLiab = (stream) => {
    let error = false;
    for (let columnIndex = 0; columnIndex < stream.Receipts.Values.length; columnIndex++) {
      let sumRepayments = 0;
      let sumWithdrawals = 0;
      for (let targetIndex = 0; targetIndex < columnIndex; targetIndex++) {
        sumWithdrawals += stream.Receipts.Values[targetIndex].Value;
        sumRepayments += stream.Repayments.Values[targetIndex].Value;
        if (sumRepayments > sumWithdrawals) {
          stream.Repayments.Values[targetIndex].mainTableError = true;
          stream.Receipts.Values[targetIndex].mainTableError = true;
        } else {
          stream.Repayments.Values[targetIndex].mainTableError = false;
          stream.Receipts.Values[targetIndex].mainTableError = false;
        }
      }
      if (sumRepayments > sumWithdrawals) {
        error = true;
      }
    }

    if (error) {
      if (!monthlyRepayments) {
        setValidateMonthlyRepayments(true);
      }
    } else {
      if (monthlyRepayments) {
        setValidateMonthlyRepayments(false);
      }
    }
  };
  const validateTotalRepayments = (stream) => {
    let error = false;
    for (let columnIndex = 0; columnIndex < stream.Withdrawals.Values.length; columnIndex++) {
      let sumRepayments = 0;
      let sumWithdrawals = 0;
      for (let targetIndex = 0; targetIndex < columnIndex; targetIndex++) {
        sumWithdrawals += stream.Withdrawals.Values[targetIndex].Value;
        sumRepayments += stream.Repayments.Values[targetIndex].Value;
        if (sumRepayments > sumWithdrawals) {
          stream.Repayments.Values[targetIndex].mainTableError = true;
          stream.Withdrawals.Values[targetIndex].mainTableError = true;
        } else {
          stream.Repayments.Values[targetIndex].mainTableError = false;
          stream.Withdrawals.Values[targetIndex].mainTableError = false;
        }
      }
      if (sumRepayments > sumWithdrawals) {
        error = true;
      }
    }

    if (error) {
      if (!monthlyRepayments) {
        setValidateMonthlyRepayments(true);
      }
    } else {
      if (monthlyRepayments) {
        setValidateMonthlyRepayments(false);
      }
    }
  };
  const validateOtherLiability = (stream) => {
    let Receipts = null;
    let Repayments = null;

    if (props.editMode) {
      Receipts = props.Receipts.Values.reduce(function (a, b) {
        const val = b.IsSimple ? b.Value : 0;
        return a + val;
      }, 0);
      Repayments = props.Repayments.Values.reduce(function (a, b) {
        {
          const val = b.IsSimple ? b.Value : 0;
          return a + val;
        }
      }, 0);
    } else {
      Receipts = stream.Receipts.Values.reduce(function (a, b) {
        const val = b.IsSimple ? b.Value : 0;
        return a + val;
      }, 0);
      Repayments = stream.Repayments.Values.reduce(function (a, b) {
        {
          const val = b.IsSimple ? b.Value : 0;
          return a + val;
        }
      }, 0);
    }

    if (Repayments > Receipts) {
      if (!otherLiability) {
        setOtherLiability(true);
      }
    } else {
      if (otherLiability) {
        setOtherLiability(false);
      }
    }
  };
  const validateCreditLimit = (stream) => {
    let Withdrawals = null;
    let Repayments = null;

    if (props.editMode) {
      Withdrawals = props.Withdrawals.Values.reduce(function (a, b) {
        const val = b.IsSimple ? b.Value : 0;
        return a + val;
      }, 0);
      Repayments = props.Repayments.Values.reduce(function (a, b) {
        {
          const val = b.IsSimple ? b.Value : 0;
          return a + val;
        }
      }, 0);
    } else {
      Withdrawals = stream.Withdrawals.Values.reduce(function (a, b) {
        const val = b.IsSimple ? b.Value : 0;
        return a + val;
      }, 0);
      Repayments = stream.Repayments.Values.reduce(function (a, b) {
        {
          const val = b.IsSimple ? b.Value : 0;
          return a + val;
        }
      }, 0);
    }

    if (Repayments > Withdrawals) {
      if (!creditLimit) {
        setValidateCreditLimit(true);
      }
    } else {
      if (creditLimit) {
        setValidateCreditLimit(false);
      }
    }
  };

  if (!creditLimit && !otherLiability) {
    return;
  }

  const Message =
    creditLimit && otherLiability ? (
      <span>
        In <b>Line of Credit</b> and <b>Other Liability</b>, you are repaying more than you owe.
        Please reduce the repayments or increase the withdrawals.
      </span>
    ) : creditLimit ? (
      <span>
        In <b>Line of Credit</b> you are repaying more than you owe the bank. Please reduce the
        repayments or increase the withdrawals.
      </span>
    ) : otherLiability ? (
      <span>
        In <b>Other Liability</b> you are repaying more than you owe. Please reduce the repayments
        or increase the withdrawals.
      </span>
    ) : (
      ""
    );

  return (
    <>
      <div className="scdfci_cash_balance_div">
        <Mx_Tooltip_Hover
          icon={<span className="scdfci_text_balance">{Message}</span>}
          title={Message}
        />
      </div>
    </>
  );
}

export default LineOfCreditDialog;
