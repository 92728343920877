import React from "react";
import { FirstSection } from "../FirstSection";
import { ButtonsWrapper } from "../ButtonsWrapper";
import styled from "styled-components";
import { Theme } from "./../../../../../theme/styles/theme";
import Input from "./../../../../../components/actions/Input";
import RadioButton from "./../../../../../components/actions/RadioButton";
import { validateMail } from "../../../../../helpers/EmailHelper";
import ButtonMui from "../../../../../components/buttons/buttonMui/buttonMui";
import {
  ArrowLeft,
  ArrowRight,
  IconPlus,
  TrashCan,
} from "../../../../../components/icons/svgIcons";
import { Collaborator } from "../../index";
import Dialog from "@mui/material/Dialog";
import Mx_Tooltip from "../../../../../components/icons/Mx_Tooltip_Click";
import Button from "../../../../../components/actions/Button";

const SelectWrapperStep2 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  max-height: 200px;
`;
const InputLabelWrapper = styled.div`
  display: flex;
  gap: 15px;
  justify-content: center;
  align-content: center;
`;

const StyledP3Normal = styled.div`
  ${Theme.text.paragraph.p3};
  color: #252525;
  font-family: Inter;
  text-align: center;
  margin-top: 10px;
`;
const FlexDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${(props) => props.marginBottom && "10px"};
`;

export default class Step4 extends React.Component {
  constructor(props) {
    super(props);

    this.Collaborators = props.collaborators;

    this.state = {
      key: new Date().getTime(),
    };
  }

  onChangeStep = (step) => {
    let err = false;
    this.Collaborators.forEach((collaborator, index) => {
      if (collaborator.email == "") {
        collaborator.mailError = null;
        collaborator.validated = false;
      } else if (!validateMail(collaborator.email)) {
        collaborator.mailError = "invalid mail";
        err = true;
      } else {
        if (this.Collaborators.findIndex((c) => c.email == collaborator.email) === index) {
          collaborator.mailError = null;
          collaborator.validated = true;
        } else {
          collaborator.mailError = "already invited collaborator";
          collaborator.validated = false;
          err = true;
        }
      }
    });

    this.setState({ key: new Date().getTime() });
    if (!err) {
      step();
    }
  };

  deleteCollaborator = (index) => {
    this.Collaborators.splice(index, 1);
    this.forceUpdate();
  };

  dialog = () => {
    return (
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={true}
        className={"company_limit_dialog"}
        onClose={() => this.setState({ errorDialog: false })}
      >
        <div className="p p2 center p_t  p_t">
          {" "}
          To add more users, it is necessary to upgrade your current subscription plan. Once you
          have finished the onboarding process navigate to the "Account Settings" section, where you
          will have the option to upgrade your plan. Once the upgrade is complete, you can proceed
          to invite and manage additional users by accessing the "Users" section.
        </div>

        <FlexDiv marginBottom>
          <Button
            label={"Close"}
            padding={"0 0 0 0"}
            backgroundColor={"red150"}
            onClick={() => this.setState({ errorDialog: false })}
          />
          {/*<Button label={'Confirm Forecast Period Change'} width={'220px'} padding={'0 0 0 0'} backgroundColor={'primary'} onClick={onChange}/>*/}
        </FlexDiv>
      </Dialog>
    );
  };

  render() {
    return (
      <>
        {this.state.errorDialog && this.dialog()}
        <div className="p title strong center">Invite users</div>
        <div className="p p2 center p_t">
          Are you working with partners, accountants, or advisors? Enter their e-mail below and
          we'll send them an invitation.
        </div>
        <div className="spsocwn2_container_plan">
          <FirstSection>
            <div className="collaborators_container">
              {this.Collaborators.map((collaborator, key) => {
                return (
                  <SelectWrapperStep2 key={"collaborator_" + key}>
                    <InputLabelWrapper>
                      <div>
                        <Input
                          placeholder="collaborator@example.com"
                          value={collaborator.email}
                          onChange={(e) => {
                            this.forceUpdate();
                            collaborator.email = e.target.value;
                          }}
                          error={!!collaborator.mailError}
                          errorMessage={collaborator.mailError}
                        />
                      </div>
                      <div />
                      <FlexDiv>
                        <RadioButton
                          size="small"
                          value={1}
                          placeholder="Editor"
                          checked={collaborator.userType == 1}
                          name={"user_type_" + key}
                          onChange={(e) => {
                            collaborator.userType = parseInt(e.target.value);
                            this.forceUpdate();
                          }}
                        />
                      </FlexDiv>
                      <div />
                      <FlexDiv>
                        <RadioButton
                          size="small"
                          value={0}
                          placeholder="Viewer"
                          checked={collaborator.userType == 0}
                          name={"user_type_" + key}
                          onChange={(e) => {
                            collaborator.userType = parseInt(e.target.value);
                            this.forceUpdate();
                          }}
                        />
                      </FlexDiv>
                      <FlexDiv>
                        <ButtonMui
                          onClick={() => this.deleteCollaborator(key)}
                          color="red"
                          variant="text"
                          label={<TrashCan />}
                          isIconButton
                        />
                        <Mx_Tooltip />
                      </FlexDiv>
                    </InputLabelWrapper>
                  </SelectWrapperStep2>
                );
              })}
            </div>
            <FlexDiv marginBottom={true}>
              <ButtonMui
                variant="contained"
                startIcon={<IconPlus />}
                label="Add User"
                onClick={() => {
                  if (
                    this.Collaborators.length <
                    global.Modeliks.limitInfo.TotalUsers - global.Modeliks.limitInfo.ActiveUsers
                  ) {
                    this.Collaborators.push(new Collaborator());
                    this.setState({ key: new Date().getTime() });
                  } else {
                    this.setState({ errorDialog: true });
                  }
                }}
              />
            </FlexDiv>
            <StyledP3Normal className="sppaco_sub_title">
              You can also invite users later on and set up their permissions.
            </StyledP3Normal>
          </FirstSection>
        </div>
        <ButtonsWrapper>
          <div className="o_btn_container">
            <ButtonMui
              startIcon={<ArrowLeft />}
              label={"Back"}
              width={120}
              variant={"contained"}
              onClick={() => this.onChangeStep(this.props.BackStep)}
            />
          </div>
          <div className="o_btn_container start">
            <ButtonMui
              label={"Next"}
              width={120}
              variant={"contained"}
              endIcon={<ArrowRight />}
              onClick={() => this.onChangeStep(this.props.NextStep)}
            />
          </div>
        </ButtonsWrapper>
      </>
    );
  }
}
