import {DriverCategories, UnitTypes} from "../../CalculatedDriver/constants";
import {FinancingTypes} from '../../constants'
import CalculatedDriver_Values from "../../CalculatedDriver/CalculatedDriver_Values";
import Taxes from "../index";
import { TaxesTypes, RateChange, RateVery } from "../constants";
import { PayBackOptions } from "../../constants";
import CalculatedDriver from "../../CalculatedDriver";
import {ResellType} from "../../Assets/constants";

class SalesTax extends Taxes {
    Name = "";
    ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
    TaxType = TaxesTypes.SalesTax;
    RateChange = RateChange.false;
    RateVery = RateVery.false;
    PayableOpenningBalance;
    TaxPaymentFrequency;

    accrued_revenues = [];

    constructor(db_record) {
        super(db_record);
        if (this.db_record) {
            global.Modeliks.AssetsStore.filter(r => r.ResellType === ResellType.Yes).map(d => d.AssetSales).forEach(d => this.createDriversForAssets(d,false))
            this.clean(true);
        }
        this.SaveRevenue = this.Save;
        this.Save = this.SaveTax;
    }

    clean = (cleanDrivers = false) => {
        if (this.db_record && this.Totals) {
            this.Name = this.db_record.Name;
            this.TaxType = this.db_record.TaxType;
            this.RateChange = this.db_record.RateChange;
            this.RateVery = this.db_record.RateVery;
            this.ID_CompanyScenario = this.db_record.ID_CompanyScenario;
            this.setDriversFromDataStorageSub();
            this.setAccuredRevenues();
            if (cleanDrivers) {
                this.TaxPaymentFrequency.cleanValue();
                this.PayableOpenningBalance.cleanValue();
                this.cleanDrivers();
                // this.setAccuredRevenues();
                this.setTax && this.setTax();
            }
        }
    };

    static DriversDesc = Object.assign({
        Total: {
            driverName: "$Name",
            fieldName: "Totals",
            driverID: "total",
            unit: UnitTypes.Price,
            category: DriverCategories.Sum,
        },
        SalesTaxRate: {
            driverName: "Sales Tax Rate",
            fieldName: "SalesTaxRate",
            driverID: "sales_tax_rate",
            unit: UnitTypes.Percentage,
            category: DriverCategories.Average,
        },
        TotalAccruedSalesTax: {
            driverName: "Total Accrued Sales Tax",
            fieldName: "TotalAccruedSalesTax",
            driverID: "total_accrued_sales_tax",
            unit: UnitTypes.Price,
            category: DriverCategories.Sum,
        },
        SalesTaxPayableOpenningBalance: {
            driverName: "Sales Tax Payable Opening Balance",
            fieldName: "SalesTaxPayableOpenningBalance",
            driverID: "sales_tax_payable_openning_balance",
            unit: UnitTypes.Price,
            category: DriverCategories.FirstPeriod,
        },
        SalesTaxPayments: {
            driverName: "Sales Tax Payments",
            fieldName: "SalesTaxPayments",
            driverID: "sales_tax_payments",
            unit: UnitTypes.Price,
            category: DriverCategories.Sum,
        },
        SalesTaxPayableClosingBalance: {
            driverName: "Sales Tax Payable",
            fieldName: "SalesTaxPayableClosingBalance",
            driverID: "sales_tax_payable_cosing_balance",
            unit: UnitTypes.Price,
            category: DriverCategories.LastPeriod,
        },
    });

    SaveTax = (callBack, saveDrivers = true) => {
        console.log("SaveTax", this);
        this.SaveRevenue((newID) => {
            this.TaxPaymentFrequency.Save();
            this.PayableOpenningBalance.Save();
            if (saveDrivers) {
                this.SaveDrivers(callBack, this.ID);
            } else {
                callBack(this.ID);
            }
        }, false);
    };
    createExtraPeriods = () => {
        Object.values(this.constructor.DriversDesc).forEach((driver) => {
            this[driver.fieldName].addMonths(0, true, true);
        });

        this.buildPeriodsData();
    };
    periodsData = {};
    buildPeriodsData = () => {
        const allPeriods = this.SalesTaxRate.Values.map((c) => c.Date);
        allPeriods.forEach((period) => {
            this.periodsData[period.dateID] = {};
            Object.values(this.constructor.DriversDesc).forEach(
                (driver) =>
                    (this.periodsData[period.dateID][driver.fieldName] = this[
                        driver.fieldName
                        ].getItemByDateSufix(period.sufix)),
            );
            this.periodsData[period.dateID].Date = period;
        });
    };

    Delete = (callBack) => {
        this.DeleteFunc(this.ID, () => {
            global.Modeliks.del(global.Modeliks.Tables.Finance_CalculatedDrivers.TableName, {Ref_Field: this.getSalesDriverID(null,'asset'),ID_CompanyScenario: global.Modeliks.CompanyScenarioInfo.ID}, (res) => {
                global.Modeliks.del(global.Modeliks.Tables.Finance_CalculatedDrivers.TableName, {Ref_Field: this.getRateDriverID(null,'asset'),ID_CompanyScenario: global.Modeliks.CompanyScenarioInfo.ID}, (res) => {
                    global.Modeliks.del(global.Modeliks.Tables.Finance_CalculatedDrivers.TableName, {Ref_Field: this.getSalesDriverID(),ID_CompanyScenario: global.Modeliks.CompanyScenarioInfo.ID}, (res) => {
                        global.Modeliks.del(global.Modeliks.Tables.Finance_CalculatedDrivers.TableName, {Ref_Field: this.getRateDriverID(),ID_CompanyScenario: global.Modeliks.CompanyScenarioInfo.ID}, (res) => {
                            global.Modeliks.del(global.Modeliks.Tables.Finance_CalculatedDriver_Values.TableName, {ID: this.getTaxPaymentFrequencyID(),ID_CompanyScenario: global.Modeliks.CompanyScenarioInfo.ID}, (res) => {
                                global.Modeliks.del(global.Modeliks.Tables.Finance_CalculatedDriver_Values.TableName, {ID: this.getPayableOpenningBalanceID(),ID_CompanyScenario: global.Modeliks.CompanyScenarioInfo.ID}, (res) => {
                                    if(callBack){
                                        callBack();
                                    }
                                });
                            });
                        });
                    });
                });
            });


        })
    }

    setDriversFromDataStorageSub = () => {
        this.TaxPaymentFrequency = global.Modeliks.DriverValuesStore.getItem(
            this.getTaxPaymentFrequencyID(),
        );
        this.PayableOpenningBalance = global.Modeliks.DriverValuesStore.getItem(
            this.getPayableOpenningBalanceID(),
        );
    };
    getTaxPaymentFrequencyID = () => {
        return `${SalesTax.TableName}-${this.ID}-tax_payment_frequency`;
    };
    getPayableOpenningBalanceID = () => {
        return `${SalesTax.TableName}-${this.ID}-payable_openning_balance`;
    };
    setAccuredRevenues = () => {
        if (this.TotalAccruedSalesTax.Formula) {
            this.accrued_revenues = this.TotalAccruedSalesTax.getChildDrivers().filter(d => d);
        } else {
            this.accrued_revenues = [];
        }
    }
    getMonthDatesAll = () => {
        return [...global.Modeliks.DateHelper.months, ...global.Modeliks.DateHelper.months_after]
    }
    getYearDatesAll = () => {
        return global.Modeliks.DateHelper.years_all;
    }

    getSalesRevenueID = (stream,type = 'revenue') => {return `|${stream.Ref_Table}-${stream.Ref_ID}-${this.getSalesDriverID(null,type)}|`};
    getRateDriverID = (revenue = null, type = 'revenue') => {
        const rateID = `accrued_sales_${type}_rate`;
        if(revenue){
            return global.Modeliks.DriversStore.getItem(`${revenue.Ref_Table}-${revenue.Ref_ID}-${rateID}`);
        }

        return rateID;
    };
    getSalesDriverID = (revenue = null, type = 'revenue') => {
        const salesID = `accrued_sales_${type}`;
        if(revenue){
            return global.Modeliks.DriversStore.getItem(`${revenue.Ref_Table}-${revenue.Ref_ID}-${salesID}`);
        }

        return salesID;
    };
    TotalSalesIncludesID = (revenue, type = 'revenue') => {
        return this.TotalAccruedSalesTax.Formula && this.TotalAccruedSalesTax.Formula.includes(this.getSalesRevenueID(revenue, type));
    }
    addToTotalFormula = (stream, isAsset = false) => {
        const TotalsTax = this.TotalAccruedSalesTax;
        const streamID = isAsset ? this.getSalesRevenueID(stream,isAsset) : this.getSalesRevenueID(stream);
        if(TotalsTax.IsSimple && TotalsTax.Formula === null){
            TotalsTax.setFormula(streamID)
        }else {
            TotalsTax.Formula = TotalsTax.Formula+` + ${streamID}`;
        }
        const rateDriver = isAsset ? this.getRateDriverID(stream,isAsset) : this.getRateDriverID(stream);
        if(rateDriver){
            rateDriver.removeFormula();
        }


    }
    removeRevenueFromTotal = (Revenue, type = 'revenue') => {
        let drivers = this.TotalAccruedSalesTax.getChildDrivers().filter(d => d);
        let alldrivers = drivers.filter(d => d.ID_f !== this.getSalesRevenueID(Revenue,type));
        let removedDriver = this.getRateDriverID(Revenue,type);
        removedDriver.removeFormula();
        this.TotalAccruedSalesTax.removeFormula();
        if(alldrivers.length > 0){
            this.TotalAccruedSalesTax.Formula = `${alldrivers.map(d => d.ID_f).join('+')}`;
        }else {
            this.TotalAccruedSalesTax.Formula = null;
        }

    }
    setTotalAccruedSalesFormula = (revenue, type = 'revenue') => {
        this.TotalSalesIncludesID(revenue,type) ? this.removeRevenueFromTotal(revenue,type) : type === 'revenue' ? this.addToTotalFormula(revenue) : this.addToTotalFormula(revenue, 'asset');
    }

    createDriversForRevenue = (revenue, type = 'revenue') => {
        let newSales = this.getSalesDriverID(revenue);
        let newRate = this.getRateDriverID(revenue);
        if(!newRate && !newSales){
            newSales = CalculatedDriver.createDriver(revenue.Ref_Table, revenue.Ref_ID, this.getSalesDriverID(),UnitTypes.Price, DriverCategories.Sum, `Accured Sales Tax ${revenue.DriverName}`);
            newSales.addMonths(0, true, true);
            newRate = CalculatedDriver.createDriver(revenue.Ref_Table,revenue.Ref_ID, this.getRateDriverID(),UnitTypes.Percentage, DriverCategories.Average, `Sales Tax Rate ${revenue.DriverName}`);
            newRate.addMonths(0, true, true);
            newSales.setFormula(`${revenue.ID_f} * ${newRate.ID_f}`);
            this.createMonthsFromYearFormula(newSales, newRate, revenue);
        }

        this.addToTotalFormula(revenue);
    }

    createDriversForAssets = (asset, addToFormula = true) => {
        let newSales = this.getSalesDriverID(asset, 'asset');
        let newRate = this.getRateDriverID(asset, 'asset');
        if(!newRate && !newSales){
            newSales = CalculatedDriver.createDriver(asset.Ref_Table, asset.Ref_ID, this.getSalesDriverID(null, 'asset'),UnitTypes.Price, DriverCategories.Sum, `Accured Sales Tax ${asset.DriverName}`);
            newSales.addMonths(0, true, true);
            newRate = CalculatedDriver.createDriver(asset.Ref_Table,asset.Ref_ID, this.getRateDriverID(null, 'asset'),UnitTypes.Percentage, DriverCategories.Average, `Sales Tax Rate ${asset.DriverName}`);
            newRate.addMonths(0, true, true);
            newSales.setFormula(`${asset.ID_f} * ${newRate.ID_f}`);
            this.createMonthsFromYearFormula(newSales, newRate, asset);
        }
        if(addToFormula){
            this.addToTotalFormula(asset, 'asset');
        }

    }

    createMonthsFromYearFormula = (newSales ,newRate,revenue) => {
        const years = this.getYearDatesAll();
        years.forEach(year => {
            const months = year.months.map(m => newSales.getItemByDateSufix(m.sufix));
            const yearRevenue = revenue.getItemByDateSufix(year.sufix);
            if(year.Active){
                months.forEach(month =>  month.Formula = `(${yearRevenue} / 12) * ${newRate.getItemByDateSufix(year.sufix)}`)
            }
        })
    }


    SelectAllRevenues = () => {
        let revenues = global.Modeliks.RevenuesStore.map(r => r.Totals);
        let assets = global.Modeliks.AssetsStore.filter(r => r.ResellType === ResellType.Yes).map(d => d.AssetSales);

        this.TotalAccruedSalesTax.Formula = null;
        if(revenues.length > 0){
            revenues.forEach(r => this.createDriversForRevenue(r))
        }
        if(assets.length > 0){
            assets.forEach(r => this.createDriversForAssets(r))

        }

    }

    getAllRateDrivers = () => {
        if (this.accrued_revenues.length > 0) {
            return this.accrued_revenues.map((a) => a.getChildDrivers()[1]);
        } else {
            return [];
        }
    };
    getAllRevenueDrivers = () => {
        if (this.accrued_revenues.length > 0) {
            return this.accrued_revenues.map((a) => a.getChildDrivers()[0]);
        } else {
            return [];
        }
    };

    createMonthsFormulas = () => {
        const months = this.getMonthDatesAll();
        const rateDrivers = this.getAllRateDrivers();
        const revenueDrivers = this.getAllRevenueDrivers();

        months.forEach((month) => {
            const prevMonthDate = months.find((c) => c.Order === month.Order - 1);
            const curMonth = this.periodsData[month.dateID];
            const firstMonth = this.periodsData[months[0].dateID];
            const prevMonth = prevMonthDate ? this.periodsData[prevMonthDate.dateID] : null;
            const prevSingleMonthDate = (month, prevMonths) =>
                this.periodsData[months.find((c) => c.Order === month.Order - prevMonths).dateID];

            if (prevMonth) {
                curMonth.SalesTaxPayableOpenningBalance.Formula = `${prevMonth.SalesTaxPayableClosingBalance}`;
                curMonth.SalesTaxPayments.Formula = null;
                curMonth.SalesTaxPayments.Value = 0;
            } else {
                curMonth.SalesTaxPayableOpenningBalance.Formula = `${this.PayableOpenningBalance}`;
                curMonth.SalesTaxPayments.Formula = `MxMath.IFELSE(${curMonth.SalesTaxPayableOpenningBalance} > 0, ${curMonth.SalesTaxPayableOpenningBalance}, null)`;
            }

            if (month.Order >= 12 / this.TaxPaymentFrequency.Value) {
                const prevDate = prevSingleMonthDate(month, 12 / this.TaxPaymentFrequency.Value);
                if (prevDate && prevDate.SalesTaxPayments.Formula) {
                    curMonth.SalesTaxPayments.Formula = `MxMath.IFELSE(${curMonth.SalesTaxPayableOpenningBalance} > 0, ${curMonth.SalesTaxPayableOpenningBalance}, null)`;
                }
            }

            curMonth.SalesTaxPayableClosingBalance.Formula = `${curMonth.TotalAccruedSalesTax} + ${curMonth.SalesTaxPayableOpenningBalance} - ${curMonth.SalesTaxPayments}`;

            if (this.accrued_revenues.length > 0) {
                curMonth.TotalAccruedSalesTax.Formula = `MxMath.Sum([${this.accrued_revenues.map((d) => d.getItemByDateSufix(month.sufix).ID_f).join(",")}])`;
            } else {
                curMonth.TotalAccruedSalesTax.Formula = null;
            }

            curMonth.Totals.Formula = `${curMonth.TotalAccruedSalesTax}`;

            if (this.RateVery === RateVery.false) {
                if (this.RateChange === RateChange.false) {
                    if (prevMonth) {
                        curMonth.SalesTaxRate.Formula = `${firstMonth.SalesTaxRate.ID_f} * 100`;
                    } else {
                        const tmpValue = firstMonth.SalesTaxRate.Value;
                        firstMonth.SalesTaxRate.Formula = null;
                        firstMonth.SalesTaxRate.Value = tmpValue;
                    }
                    rateDrivers.forEach(
                        (rate) =>
                            (rate.getItemByDateSufix(month.sufix).Formula = `${firstMonth.SalesTaxRate} * 100`),
                    );
                } else {
                    const tmpValue = curMonth.SalesTaxRate.Value;
                    curMonth.SalesTaxRate.Formula = null;
                    curMonth.SalesTaxRate.Value = tmpValue;
                    rateDrivers.forEach(
                        (rate) =>
                            (rate.getItemByDateSufix(month.sufix).Formula = `${curMonth.SalesTaxRate} * 100`),
                    );
                }
            } else {
                //todo SalesTaxRate formulas--
                if (this.RateChange === RateChange.false) {
                    if (prevMonth) {
                        rateDrivers.forEach(
                            (rate) =>
                                (rate.getItemByDateSufix(month.sufix).Formula =
                                    `${rate.getItemByDateSufix(months[0].sufix)} * 100`),
                        );
                    } else {
                        rateDrivers.forEach((rate) => {
                            const RateDriver = rate.getItemByDateSufix(months[0].sufix);
                            const tmpValue = RateDriver.Value;
                            RateDriver.Formula = null;
                            RateDriver.Value = tmpValue;
                        });
                    }
                } else {
                    rateDrivers.forEach((rate) => {
                        const RateDriver = rate.getItemByDateSufix(month.sufix);
                        const tmpValue = RateDriver.Value;
                        RateDriver.Formula = null;
                        RateDriver.Value = tmpValue;
                    });
                }
                if (month.Active) {
                    if (this.accrued_revenues.length > 0) {
                        curMonth.SalesTaxRate.Formula = `(${curMonth.TotalAccruedSalesTax} / MxMath.Sum([${revenueDrivers.map((r) => r.getItemByDateSufix(month.sufix).ID_f).join(",")}])) * 100`;
                    } else {
                        curMonth.SalesTaxRate.Formula = null;
                    }
                }
            }
        });
    };

    createYearsFormulas = () => {
        const years = this.getYearDatesAll();
        const rateDrivers = this.getAllRateDrivers();
        const revenueDrivers = this.getAllRevenueDrivers();

        years.forEach((year) => {
            const curYear = this.periodsData[year.dateID];
            const months = this.periodsData[year.dateID].Date.monthIndexes.map(
                (index) => this.periodsData[index],
            );
            const lastMonth = months[months.length - 1];
            const firstMonth = months[0];

            curYear.TotalAccruedSalesTax.Formula = `MxMath.Sum([${months.map((c) => c.TotalAccruedSalesTax.ID_f).join(",")}])`;
            curYear.SalesTaxPayableOpenningBalance.Formula = `${firstMonth.SalesTaxPayableOpenningBalance}`;
            curYear.SalesTaxPayments.Formula = `MxMath.Sum([${months.map((c) => c.SalesTaxPayments.ID_f).join(",")}])`;
            curYear.SalesTaxPayableClosingBalance.Formula = `${lastMonth.SalesTaxPayableClosingBalance}`;
            curYear.Totals.Formula = `${curYear.TotalAccruedSalesTax}`;

            if (this.RateVery === RateVery.false) {
                if (this.RateChange === RateChange.false) {
                    curYear.SalesTaxRate.Formula = `${firstMonth.SalesTaxRate} * 100`;
                    rateDrivers.forEach(
                        (rate) =>
                            (rate.getItemByDateSufix(year.sufix).Formula = `${curYear.SalesTaxRate} * 100`),
                    );
                } else {
                    if (year.Active) {
                        const tempValue = curYear.SalesTaxRate.Value;
                        curYear.SalesTaxRate.Formula = null;
                        curYear.SalesTaxRate.Value = tempValue;
                        months.forEach((m) => {
                            m.SalesTaxRate.Formula = `${curYear.SalesTaxRate} * 100`;
                        });
                        rateDrivers.forEach(
                            (rate) =>
                                (rate.getItemByDateSufix(year.sufix).Formula = `${curYear.SalesTaxRate} * 100`),
                        );
                    } else {
                        curYear.SalesTaxRate.Formula = `(${curYear.TotalAccruedSalesTax} / MxMath.Sum([${revenueDrivers.map((c) => c.getItemByDateSufix(year.sufix).ID_f).join(",")}])) * 100`;
                    }
                }
            } else {
                if (this.RateChange === RateChange.false) {
                    rateDrivers.forEach(
                        (rate) =>
                            (rate.getItemByDateSufix(year.sufix).Formula =
                                `${rate.getItemByDateSufix(years[0].months[0].sufix)} * 100`),
                    );
                    curYear.SalesTaxRate.Formula = `(${curYear.TotalAccruedSalesTax} / MxMath.Sum([${revenueDrivers.map((c) => c.getItemByDateSufix(year.sufix).ID_f).join(",")}])) * 100`;
                } else {
                    if (year.Active) {
                        const tempValue = curYear.SalesTaxRate.Value;
                        curYear.SalesTaxRate.Formula = null;
                        curYear.SalesTaxRate.Value = tempValue;
                        months.forEach((m) => {
                            m.SalesTaxRate.Formula = `${curYear.SalesTaxRate} * 100`;
                        });
                        rateDrivers.forEach((rate) => {
                            const tmpValue = rate.getItemByDateSufix(year.sufix).Value;
                            rate.getItemByDateSufix(year.sufix).Formula = null;
                            rate.getItemByDateSufix(year.sufix).Value = tmpValue;
                        });
                    } else {
                        rateDrivers.forEach((rate) => {
                            const Sale = this.accrued_revenues.find((d) => d.Formula.includes(rate.ID));
                            rate.getItemByDateSufix(year.sufix).Formula =
                                `(${Sale.getItemByDateSufix(year.sufix)} / ${Sale.getChildDrivers()[0].getItemByDateSufix(year.sufix)}) * 100`;
                        });
                    }
                    curYear.SalesTaxRate.Formula = `(${curYear.TotalAccruedSalesTax} / MxMath.Sum([${revenueDrivers.map((c) => c.getItemByDateSufix(year.sufix).ID_f).join(",")}])) * 100`;
                }
            }
        });
    };

    setTax = () => {
        this.buildPeriodsData();
        this.setAccuredRevenues();
        this.createMonthsFormulas();
        this.createYearsFormulas();
    };

    getSalesTaxStream = () => {
        return global.Modeliks.TaxesStore.find((d) => d.TaxType === TaxesTypes.SalesTax);
    };

    static convert_Tax = (tax) => {
        const driver = global.Modeliks.TaxesStore.find((d) => d.TaxType === TaxesTypes.SalesTax);
        if (driver) {
            return driver;
        } else {
            const newTax = new SalesTax();
            newTax.ID = tax.ID;
            newTax.Name = tax.Name;
            newTax.Totals = CalculatedDriver.createDriverFromTable(
                newTax,
                SalesTax.DriversDesc.Total.driverID,
                UnitTypes.Price,
                DriverCategories.Sum,
            );
            newTax.ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
            newTax.TaxPaymentFrequency = new CalculatedDriver_Values(
                null,
                newTax.getTaxPaymentFrequencyID(),
                null,
                UnitTypes.Integer,
            );
            newTax.TaxPaymentFrequency.Value = PayBackOptions[0].value;
            newTax.PayableOpenningBalance = new CalculatedDriver_Values(
                null,
                newTax.getPayableOpenningBalanceID(),
                null,
                UnitTypes.Price,
            );
            newTax.PayableOpenningBalance.Value = 0;
            newTax.createDrivers();
            newTax.createExtraPeriods();
            newTax.SelectAllRevenues();
            newTax.IsCreated = true;
            return newTax;
        }
    };
}

export default SalesTax;
