import MxDataArray from "../../MxDataArray";
import ProfitLoss from "./ProfitLoss";
import CashFlow from "./CashFlow";
import BalanceSheet from "./BalanceSheet";
import Expenses from "./../Expense/index";
import ReportingDrivers from "./ReportingDrivers";
import Reports from "./index";
import OpeningBalanceCalc from "./OpeningBalance";
import SaaSReportingSubscription from "./SaaSReportingSubscription";
import { ReportsTypes } from "./constants";
import FinancialStatementsReport from "../Dashboards/FinancialStatements";

class ReportsStorage extends MxDataArray {
  dataManager = null;
  driverStorage = null;
  loaded = false;

  getAllDates = (dates = global.Modeliks.DashboardSettings.Value.financialDates) => {
    return {
      ...dates,
      allMonths: [
        ...global.Modeliks.DateHelper.months_before_actual,
        ...global.Modeliks.DateHelper.months,
      ],
      allYears: [
        ...global.Modeliks.DateHelper.years_before_actual,
        ...global.Modeliks.DateHelper.gridPeriodHeaders_Years(),
      ],
    };
  };

  setData = (callBack, data = this.data) => {
    // CashFlow.getReport();
    // BalanceSheet.getReport();
    // ProfitLoss.getReport();
    Expenses.getTotalOperatingExpenses();
    ReportingDrivers.getReportingDrivers();
    SaaSReportingSubscription.getReportingDrivers();

    Reports.getOperatingExpenses();
    Reports.getDepreciation();
    Reports.getNumberOfDaysPerMonthAndYear();
    Reports.getNumberOneDriver();
    Reports.getOperatingExpenses();
    Reports.getOperatingExpenses();
    OpeningBalanceCalc.getOpeningBalance();

    const RepTypes = {
      [ReportsTypes.ProfitLoss.key]: {
        key: ReportsTypes.ProfitLoss.key,
        report: ProfitLoss.getReport().getReportTotals(),
      },
      [ReportsTypes.BalanceSheet.key]: {
        key: ReportsTypes.BalanceSheet.key,
        report: BalanceSheet.getReport().getReportTotals(),
      },
      [ReportsTypes.CashFlow.key]: {
        key: ReportsTypes.CashFlow.key,
        report: CashFlow.getReport().getReportTotals(),
      },
    };

    const AllReports = global.Modeliks.ReportsStore.filter((r) =>
      Object.keys(RepTypes).includes(r.ReportType.key),
    );
    AllReports.forEach((report) => {
      report.data = {
        dataGrid: [],
        changeDates: (dates = this.getAllDates()) => {
          return (report.data.dataGrid = FinancialStatementsReport.getDashboardDrivers(
            RepTypes[report.ReportType.key].report,
            dates,
            false,
            RepTypes[report.ReportType.key].key === ReportsTypes.BalanceSheet.key,
            RepTypes[report.ReportType.key].key === ReportsTypes.ProfitLoss.key,
          ));
        },
      };
      report.data.changeDates();
      return report;
    });

    this.loaded = true;
    callBack();
  };

  loadReports = (callBack, shouldFinish = true) => {
    // this.clear();
    this.dataManager.get(
      this.dataManager.Tables.Finance_Reports.TableName,
      { ID_CompanyScenario: this.dataManager.CompanyScenarioInfo.ID },
      (data) => {
        if (shouldFinish) {
          this.setData(callBack, data);
        } else {
          this.data = data;
          callBack();
        }
      },
      null,
      false,
    );
  };

  static create = () => {
    const newArr = new ReportsStorage();

    newArr.pushOld = newArr.push;
    newArr.push = newArr.pushThis;

    return newArr;
  };
}

export default ReportsStorage;
