import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Theme } from "../../../../theme/styles/theme";
import EditUserDialog from "../../../../components/dialogs/settings/EditUserDialog";

const StyledDiv = styled.div`
  display: flex;
  gap: 20px;
`;
const P3StrongLabel = styled.div`
  font-size: 14px;
  font-family: Inter;
  font-weight: 700;
`;
const P3Label = styled.div`
  font-size: 14px;
  font-family: Inter;
  font-weight: 400;
  display: flex;
  align-items: center;
  vertical-align: middle;
`;
const StyledUserType = styled.span`
  font-family: Inter;
  vertical-align: center;
  ${Theme.text.paragraph.p3_strong}
  color: ${Theme.colors.primary.lightgrey["500"]}
`;

function UserSmall({ name, email, userType = 1, onUserEdit, id, companies, onClose, date, user }) {
  const [dialog, setDialog] = React.useState(false);

  const closeDialogUser = () => {
    setDialog(false);
    onClose();
  };

  return (
    <>
      <div style={{ marginTop: "12px", width: "100%" }}>
        <P3StrongLabel>{user.Name}</P3StrongLabel>
      </div>
      <div style={{ width: "100%", marginTop: "7px" }}>
        <StyledDiv>
          <P3Label style={{ width: "80%", overflow: "hidden", textOverflow: "ellipsis" }}>
            {email}
          </P3Label>
          <div
            style={{
              display: "flex",
              width: "20%",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {user.UserType === 2 && <StyledUserType>Owner</StyledUserType>}
            {user.UserType === 1 && <StyledUserType>Editor</StyledUserType>}
            {user.UserType === 0 && <StyledUserType>Viewer</StyledUserType>}
          </div>
        </StyledDiv>
      </div>
      <div
        style={{
          marginTop: "5px",
          width: "100%",
          borderBottom: "1px solid #F0F1F7",
          marginBottom: "",
        }}
      />
      {dialog && (
        <EditUserDialog
          open={dialog}
          user={user}
          date={date}
          companies={companies}
          closeDialog={closeDialogUser}
        />
      )}
    </>
  );
}

UserSmall.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  userType: PropTypes.number,
  hasEditIcon: PropTypes.bool,
  onUserEdit: PropTypes.func,
  id: PropTypes.number,
  companies: PropTypes.array,
  onClose: PropTypes.func,
  date: PropTypes.string,
  user: PropTypes.object,
};
export default UserSmall;
