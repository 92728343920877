import React, { useEffect } from "react";
import { Dialog, DialogActions } from "@mui/material";
import PropTypes from "prop-types";
import "./info_dialog.scss";
import Button from "../../../actions/Button";
import ButtonMui from "../../../buttons/buttonMui/buttonMui";
import { ArrowLeft, ArrowRight } from "../../../icons/svgIcons";

const InfoDialogActions = (props) => {
  const onNextCheck = () => {
    if (!props.isNextDisabled) {
      props.onNextClick();
    }
  };

  return (
    <>
      <DialogActions className="scadfi_dialog_container">
        <div></div>
        <div className="scadfi_action_middle_buttons">
          {!props.CalculatedDriver && (
            <>
              <ButtonMui
                width={125}
                color={props.isPrevDisabled ? "grey" : "primary"}
                label={"Previous"}
                startIcon={<ArrowLeft />}
                onClick={props.onPreviousClick}
                variant={"contained"}
              />
              <ButtonMui
                width={125}
                disabled={props.isNextDisabled}
                label={"Next"}
                onClick={() => onNextCheck()}
                endIcon={<ArrowRight />}
                variant={"contained"}
              />
            </>
          )}
        </div>
        <div id="save_bottom_button" className="scadfi_save_btn">
          {!props.CalculatedDriver && (
            <ButtonMui
              width="125px"
              color={!props.isNextDisabled && !props.isCreatedStream ? "grey" : "primary"}
              label="Save & Close"
              onClick={props.onSave}
              variant={"contained"}
            />
          )}
        </div>
      </DialogActions>
    </>
  );
};

InfoDialogActions.propTypes = {
  saveDisabled: PropTypes.bool,
  onSave: PropTypes.func,
  onNextClick: PropTypes.func,
  onPreviousClick: PropTypes.func,
};

export default InfoDialogActions;
