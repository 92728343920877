import React from "react";
import CircleLoader from "../../../../components/Loaders/CircleLoader/CircleLoader";
import { MainHeader, ValContainer } from "../components/ValuationContainer";
import OverviewTable from "../components/table/OverviewTable";
import BubbleChart, { storeTypes } from "../components/charts/bubbleChart/BubbleChart";
import HorizontalFloatingChart from "../components/charts/horizontalFloatingChart/HorizontalFloatingChart";
import TradingMultiples from "../../../../data/Finance/Valuation/TradingMultiples";
import TransactionMultiples from "../../../../data/Finance/Valuation/TransactionMultiples";
import Revenue from "../../../../data/Finance/Revenue";
import Valuation from "../../../../data/Finance/Valuation";
import Overview from "../../../../data/Finance/Valuation/Overview";

const Loader = (
  <div className={"main_circle_loader_container"}>
    <CircleLoader />
  </div>
);

export default class OverviewMain extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      key: new Date().getTime(),
      loading: true,
    };

    this.ReferenceBaseObjectValues = Overview.GetReferenceBaseValues();
    TransactionMultiples.tmpTotalsTransactionMultiples((tmp) => (this.TransactionMultiples = tmp));
    TradingMultiples.tmpTotalsTradingMultiples((tmp) => (this.TradingMultiples = tmp));
  }

  componentDidMount() {
    this.setState({ loading: false });
  }

  render() {
    if (this.state.loading) {
      return Loader;
    }

    return (
      <ValContainer key={this.state.key}>
        <MainHeader>Overview</MainHeader>
        <OverviewTable
          Overview={this.props.Overview}
          ReferenceBaseObjectValues={this.ReferenceBaseObjectValues}
          tradingMultiples={this.TradingMultiples}
          transactionMultiples={this.TransactionMultiples}
        />
        <HorizontalFloatingChart />
        <BubbleChart storeKey={Object.keys(storeTypes)[0]} />
        <BubbleChart storeKey={Object.keys(storeTypes)[1]} />
      </ValContainer>
    );
  }
}
