import React, { Component } from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { parseDate } from "../index";

class BillingHistory extends Component {
  render() {
    return (
      <div className={"table_container"}>
        <Table stickyHeader={true}>
          <TableHead>
            <TableRow>
              <TableCell>Billing Date</TableCell>
              <TableCell align={"right"}>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {global.Modeliks.transactionsInfo &&
              global.Modeliks.transactionsInfo.map((c) => {
                return (
                  <TableRow>
                    <TableCell>{parseDate(new Date(c.created * 1000))}</TableCell>
                    <TableCell align={"right"}>
                      {Number(c.amount_paid / 100).toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }) + global.Modeliks.CompanyInfo.Currency.value}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </div>
    );
  }
}

export default BillingHistory;
