import React, { Fragment, useState } from "react";
import MenuPortal from "./MenuPortal.js";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "./MenuObject.scss";
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import Button from "@mui/material/Button";

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    iconPosition: "top",
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function MenuObject({ onNewObject, onSave, onApplyTemplate, onNewTableObj }) {
  const [value, setValue] = React.useState(0);
  const [selectedCategory, setCategory] = React.useState("default");

  const defaultSections = [
    "Company",
    "Headline",
    "Roadmap",
    "Solution",
    "Financials",
    "Target market",
    "Competition",
    "Sales channels",
    "Marketing activities",
    "Problem",
    "Team",
  ];

  const alternative = [
    "Executive summary",
    "Traction",
    "Value Proposition",
    "Business model",
    "Mission",
    "Contact us",
    "Barriers to entry",
    "Company history",
    "Exit strategy",
    "Funding needs",
    "Intellectual property",
    "Key assumptions",
    "Market trends",
    "Pricing",
    "Regulatory requirements",
    "Technology",
    "Sales plan",
    "Risks and mitigation",
  ];

  const tables = [
    "Profit and Loss",
    "Balance sheet",
    "Cashflow statement",
    "Free cash flow",
    "WACC",
    "Personnel",
    "Custom KPI",
  ];

  const charts = [
    "Cash flow",
    "Cashflow by month",
    "Cashflow by year",
    "Expenses",
    "Expenses by month",
    "Expenses by year",
    "Financial highlights",
    "Gross margin by month",
    "Gross margin by year",
    "EBITDA by month",
    "EBITA by year",
    "Net Profit",
    "Net Profit by month",
    "Net Profit by year",
    "Revenue",
    "Revenue by month",
    "Revenue ny year",
    "Football field",
  ];

  const custom = [];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <MenuPortal>
      {/*<div className='left_menu'>*/}
      {/*<span className='section_name'>Pitch sections</span>*/}
      {/*<Menu className={'categories_menu'} direction={'right'} menuButton={<Button variant='text' className='section_name button' disableElevation >Categories</Button>} transition>*/}
      {/*<MenuItem className={selectedCategory==='default'?'selected':''} onClick={()=>setCategory('default')}>Default</MenuItem>*/}
      {/*<MenuItem className={selectedCategory==='alternative'?'selected':''} onClick={()=>setCategory('alternative')}>Alternative</MenuItem>*/}
      {/*<MenuItem className={selectedCategory==='tables'?'selected':''} onClick={()=>setCategory('tables')}>Tables</MenuItem>*/}
      {/*<MenuItem className={selectedCategory==='charts'?'selected':''} onClick={()=>setCategory('charts')}>Charts</MenuItem>*/}
      {/*<MenuItem className={selectedCategory==='custom'?'selected':''} onClick={()=>setCategory('custom')}>Custom</MenuItem>*/}
      {/*</Menu>*/}
      {/*<Box*/}
      {/*sx={{flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: "100%", width: '230px'}}*/}
      {/*>*/}
      {/*<Tabs*/}
      {/*orientation="vertical"*/}
      {/*variant="scrollable"*/}
      {/*value={value}*/}
      {/*onChange={handleChange}*/}
      {/*aria-label="Vertical tabs example"*/}
      {/*sx={{width: '100%'}}*/}
      {/*>*/}
      {/*{selectedCategory ==='default' && defaultSections.map((tab, index)=>{*/}
      {/*return<Tab label={tab} {...a11yProps(index)} />*/}
      {/*})}*/}
      {/*{selectedCategory === 'alternative' && alternative.map((tab, index)=>{*/}
      {/*return<Tab label={tab} {...a11yProps(index)} />*/}
      {/*})}*/}
      {/*{selectedCategory === 'tables' && tables.map((tab, index)=>{*/}
      {/*return<Tab label={tab} {...a11yProps(index)} />*/}
      {/*})}*/}
      {/*{selectedCategory === 'charts' && charts.map((tab, index)=>{*/}
      {/*return<Tab label={tab} {...a11yProps(index)} />*/}
      {/*})}*/}
      {/*{selectedCategory === 'custom' && custom.map((tab, index)=>{*/}
      {/*return<Tab label={tab} {...a11yProps(index)} />*/}
      {/*})}*/}
      {/*</Tabs>*/}
      {/*</Box>*/}
      {/*</div>*/}
    </MenuPortal>
  );
}

export default MenuObject;
