import { SlideTypes } from "../../SlideEditor/components/SlideObjects/SlideStructures";
import {
  copyPageFile,
  createCustomPage,
  createCustomPageForBusinessScenario,
  createNewSectionFolder,
  deleteBusinessPlanSection,
} from "../../services/api/Repositories/root";

async function updateThemeBackgroundWithPitchScenario(pitchScenarioInfo, color) {
  // FIXME: stop direct changes on references
  pitchScenarioInfo.BackgroundColor = color;
  return new Promise((resolve, reject) =>
    pitchScenarioInfo.Save(
      () => {
        resolve();
      },
      (error) => reject(error),
    ),
  );
}

async function updateThemeBackgroundForBusinessPlan(businessPlanConfig, color) {
  // FIXME: stop direct changes on references
  businessPlanConfig.BackgroundColor = color;
  return new Promise((resolve, reject) =>
    businessPlanConfig.Save(
      () => {
        resolve();
      },
      (error) => reject(error),
    ),
  );
}

// keep for the moment if the usage is needed:
// Usage:
// const updatedSlideObjects = slideObjects.map((slideObject) => {
//     return {
//       ...slideObject,
//       props: {
//         ...slideObject.props,
//         ...slideObjectBackgroundPropsStrategy(slideObject, color),
//       },
//     };
//   });
function slideObjectBackgroundPropsStrategy(slideObject, color) {
  switch (slideObject.type) {
    case SlideTypes.chartObject:
      return {
        chartOptions: {
          ...slideObject.props.chartOptions,
          backgroundColor: color,
        },
      };
    case SlideTypes.tableObject:
      return {
        style: {
          ...slideObject.props.style,
          backgroundColor: color,
        },
      };
    default:
      return {};
  }
}
// keep for the moment if the usage is needed:
function updateBackgroundOnAllSlideObjects(currentState, color) {
  const { style = {} } = currentState;
  const updatedStyle = {
    ...style,
    background: color,
  };

  return {
    ...currentState,
    style: updatedStyle,
  };
}

async function createCustomCategorySectionForBusinessScenario(newSection) {
  return await createCustomPageForBusinessScenario(newSection);
}

async function copyPageFileForBusinessScenario(oldId, newId) {
  return await copyPageFile(oldId, newId);
}

async function createCustomCategorySection(newSection) {
  // TODO: add the flow here, not just request
  return await createCustomPage(newSection);
}

async function createNonCustomSection(section) {
  return new Promise((resolve, reject) => {
    global.Modeliks.post(
      "businessplanpages_client",
      {
        ...section,
        isCustom: false,
        BusinessPlanScenario_ID: global.Modeliks.BusinessPlanScenarioInfo.ID,
      },
      (section) => {
        return resolve(section);
      },
    );
  });
}

async function deleteSectionWithId(sectionId) {
  return await deleteBusinessPlanSection(sectionId);
}

function modeliksDelete(id) {
  return new Promise((resolve, reject) => {
    global.Modeliks.del("businessplanpages_client", { ID: id }, () => {
      return resolve();
    });
  });
}

function modeliksCreate(section) {
  return new Promise((resolve, reject) => {
    global.Modeliks.post("businessplanpages_client", section, (newSection, error) => {
      if (newSection) {
        return resolve(newSection.id);
      } else {
        return reject();
      }
    });
  });
}

function modeliksDeleteCustom(id) {
  return new Promise((resolve, reject) => {
    global.Modeliks.put("businessplanpages_client", { ID: id }, { isDeleted: true }, () => {
      return resolve();
    });
  });
}

function modeliksGetAllSections() {
  return new Promise((resolve, reject) => {
    global.Modeliks.get("businessplanpages_client", {}, (sections) => {
      return resolve(sections);
    });
  });
}

async function deleteNonCustomSectionWithId(index, sectionId, tabs) {
  const DEFAULT_EMPTY_SECTION = Object.freeze({
    PageName: "Blank Cover Page",
    order: 1,
    CategoryID: 5,
    CreatedAt: new Date().toISOString(),
    UpdatedAt: new Date().toISOString(),
    CreatedBy: global.Modeliks.User.ID,
    UpdatedBy: global.Modeliks.User.ID,
    Hidden: false,
    isCustom: true,
    isDeleted: false,
    MasterPageID: null,
    CompanyID: global.Modeliks.CompanyInfo.ID,
    AccountID: global.Modeliks.Account.ID,
    BusinessPlanScenario_ID: global.Modeliks.BusinessPlanScenarioInfo.ID,
  });

  await modeliksDelete(sectionId);
  let updatedTabs = tabs.filter((_, i) => i !== index);

  if (updatedTabs.length === 0) {
    const defaultSection = { ...DEFAULT_EMPTY_SECTION };
    const newSectionId = await modeliksCreate(defaultSection);
    updatedTabs.push({
      ...defaultSection,
      ID: newSectionId,
    });
  }

  return { slideSections: updatedTabs };
}

async function deleteCustomSectionWithId(index, sectionId, categoryId, tabs) {
  const DEFAULT_EMPTY_SECTION = Object.freeze({
    PageName: "Blank Cover Page",
    order: 1,
    CategoryID: 5,
    CreatedAt: new Date().toISOString(),
    UpdatedAt: new Date().toISOString(),
    CreatedBy: global.Modeliks.User.ID,
    UpdatedBy: global.Modeliks.User.ID,
    Hidden: false,
    isCustom: true,
    isDeleted: false,
    MasterPageID: null,
    CompanyID: global.Modeliks.CompanyInfo.ID,
    AccountID: global.Modeliks.Account.ID,
    BusinessPlanScenario_ID: global.Modeliks.BusinessPlanScenarioInfo.ID,
  });

  // TODO: map category
  const modeliksFunctionCall = categoryId === 5 ? modeliksDeleteCustom : modeliksDelete;
  await modeliksFunctionCall(sectionId);
  let updatedTabs = tabs.filter((_, i) => i !== index);

  if (updatedTabs.length === 0) {
    const defaultSection = { ...DEFAULT_EMPTY_SECTION };
    const newSectionId = await modeliksCreate(defaultSection);
    updatedTabs.push({
      ...defaultSection,
      ID: newSectionId,
    });
  }

  return { slideSections: updatedTabs };
}

async function createNewSection(name) {
  const allSections = await modeliksGetAllSections();

  const order = Math.max(...allSections.map((o) => o.order)) + 1;
  let section = {
    PageName: name,
    order: order,
    CategoryID: 5,
    Hidden: false,
    isCustom: true,
    isDeleted: false,
    MasterPageID: null,
    BusinessPlanScenario_ID: global.Modeliks.BusinessPlanScenarioInfo.ID,
    CompanyID: global.Modeliks.CompanyInfo.ID,
    CreatedAt: new Date().toISOString(),
    UpdatedAt: new Date().toISOString(),
  };

  const newSectionFolder = await createNewSectionFolder();
  if (!newSectionFolder) {
    throw new Error("No section folder created");
  }

  const newSectionId = await modeliksCreate(section);
  return {
    ...section,
    ID: newSectionId,
  };
}

export {
  updateThemeBackgroundWithPitchScenario,
  updateThemeBackgroundForBusinessPlan,
  updateBackgroundOnAllSlideObjects,
  createNonCustomSection,
  createCustomCategorySection,
  createCustomCategorySectionForBusinessScenario,
  copyPageFileForBusinessScenario,
  deleteSectionWithId,
  deleteNonCustomSectionWithId,
  deleteCustomSectionWithId,
  createNewSection,
};
