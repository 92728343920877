import React from "react";

import "@szhsin/react-menu/dist/index.css";
import "../../../../Admin/Dashboards/components/dashboardsBlankAdmin.scss";
import DatePickerYM from "../../../../../components/actions/DatePickers/DatePickerYM";
import "../../FinancialStatements/index.scss";
import ExportPortal from "../../FinancialStatements/components/ExportPortal";
import MaterialSelect from "../../../../../components/actions/SelectM";
import ImportActuals from "../../../Financials/Actuals/components/ImportActuals";
import { Label } from "../../../../../components/typography/label/Label";
import { NumberFormatTypes } from "../../../../../components/constants/charts";
import { DateGetMonth, DateGetYear, DateMonthChange } from "../../../../../helpers/DateSettings";
import ButtonMui from "../../../../../components/buttons/buttonMui/buttonMui";

export default class NavigationActualFilters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: new Date().getTime(),
      loading: false,
      minDate: props.financialMonths.minDate,
      maxDate: this.props.limitToCurYear
        ? this.props.months[this.props.months.length - 1].Year +
          "-" +
          (this.props.months[this.props.months.length - 1].Month + 1) +
          "-1"
        : props.financialMonths.maxDate,
      minYear: props.financialMonths.minYear,
      maxYear: this.props.limitToCurYear
        ? this.props.years[this.props.years.length - 1].Header.toString()
        : props.financialMonths.maxYear,
      from: props.financialMonths.startMonth,
      to: props.financialMonths.endMonth,
      fromYear: props.financialMonths.startYear,
      toYear: props.financialMonths.endYear,
      startMonth: props.financialMonths.startMonth,
      endMonth: props.financialMonths.endMonth,
      startYear: props.financialMonths.startYear,
      endYear: props.financialMonths.endYear,
      view: 1,
    };
    this.options = [
      { value: "stream-level", description: "Enter at Stream Level" },
      { value: "driver-level", description: "Enter at Driver Level" },
    ];
  }

  componentDidMount() {
    this.didMount = true;
  }

  getMonth = (Year, Month) => DateGetMonth(this.props.months, Month, Year);

  getYear = (Header) => DateGetYear(this.props.years, Header);

  changeFromDate = (e) => {
    const Year = e.getFullYear();
    const Month = e.getMonth();
    const MonthParsed = Month + 1;
    const startMonthOrder = this.getMonth(Year, Month);
    if (startMonthOrder) {
      this.props.financialMonths.startMonth = `${Year}-${MonthParsed}`;
      this.props.financialMonths.startMonthOrder = startMonthOrder.Order;
      this.state.startMonth = `${Year}-${MonthParsed}`;
      this.props.getMonths(true);
      this.forceUpdate();
    }
  };

  changeToDate = (e) => {
    const Year = e.getFullYear();
    const Month = e.getMonth();
    const MonthParsed = Month + 1;
    const endMonthOrder = this.getMonth(Year, Month);

    if (endMonthOrder) {
      this.props.financialMonths.endMonth = `${Year}-${MonthParsed}`;
      this.props.financialMonths.endMonthOrder = endMonthOrder.Order;
      this.state.endMonth = `${Year}-${MonthParsed}`;
      this.props.getMonths(true);
      this.forceUpdate();
    }
  };

  changeFromDateYear = (e) => {
    const Year = e.getFullYear();
    const startYearOrder = this.getYear(Year);
    if (startYearOrder) {
      this.props.financialMonths.startYear = `${startYearOrder.Header}`;
      this.props.financialMonths.startYearOrder = startYearOrder.Order;
      this.state.startYear = `${startYearOrder.Header}`;
      this.props.getMonths(true);
      this.forceUpdate();
    }
  };
  changeToDateYear = (e) => {
    const Year = e.getFullYear();
    const endYearOrder = this.getYear(Year);
    if (endYearOrder) {
      this.props.financialMonths.endYear = `${endYearOrder.Header}`;
      this.props.financialMonths.endYearOrder = endYearOrder.Order;
      this.state.endYear = `${endYearOrder.Header}`;
      this.props.getMonths(true);
      this.forceUpdate();
    }
  };

  changeDriversView = () => {
    this.props.changeDriversView();
    // this.forceUpdate();
  };

  showOpeningBalances = () => {
    this.props.setOpeningBalanceDates();
  };

  render() {
    const { minDate, maxDate, minYear, maxYear, startMonth, endMonth, startYear, endYear } =
      this.state;
    const {
      showMonthly,
      selectedSubsection,
      months,
      years,
      rows,
      periods,
      reload,
      selectedReport,
    } = this.props;
    return (
      <>
        {!this.props.disableImport && (
          <ExportPortal>
            <ImportActuals
              reload={reload}
              rows={rows}
              periods={periods}
              months={months}
              years={years}
              selectedSubsection={selectedSubsection}
              curMonths={this.props.financialMonths}
            />
          </ExportPortal>
        )}

        <div
          key={startYear + startMonth + startYear + showMonthly}
          className={`psfac_actuals_single_filter vertical align_label`}
        >
          <DatePickerYM
            label="From"
            minDate={showMonthly ? new Date(minDate) : new Date(minYear)}
            maxDate={showMonthly ? new Date(maxDate) : new Date(maxYear)}
            views={showMonthly ? ["year", "month"] : ["year"]}
            value={showMonthly ? startMonth.toString().padStart(2, "0") : startYear}
            endDateValue={showMonthly ? endMonth : endYear}
            doMultipleDateValidation={true}
            format={showMonthly ? "MMM yyyy" : "yyyy"}
            monthValidation={showMonthly}
            onChange={showMonthly ? this.changeFromDate : this.changeFromDateYear}
          />
        </div>
        <div
          key={showMonthly + endMonth + endYear + showMonthly}
          className={`psfac_actuals_single_filter vertical align_label`}
        >
          <DatePickerYM
            label="To"
            minDate={showMonthly ? new Date(minDate) : new Date(minYear)}
            maxDate={showMonthly ? new Date(maxDate) : new Date(maxYear)}
            views={showMonthly ? ["year", "month"] : ["year"]}
            value={showMonthly ? endMonth.toString().padStart(2, "0") : endYear}
            startDateValue={showMonthly ? startMonth : startYear}
            doMultipleDateValidation={true}
            monthValidation={showMonthly}
            onChange={showMonthly ? this.changeToDate : this.changeToDateYear}
          />
        </div>

        {this.props.hideLevelSelection ? (
          ""
        ) : (
          <div className="psfac_actuals_single_input_select">
            <MaterialSelect
              disabled={!global.Modeliks.PERMISSIONS.Dashboards.restrictions.edit.active}
              name="default_level"
              value={
                this.props.financialMonths.showAllDrivers
                  ? this.options[1].value
                  : this.options[0].value
              }
              onChange={this.changeDriversView}
              width="300px"
              options={this.options}
            />
          </div>
        )}

        {selectedReport && selectedReport.key === "CashFlow" && (
          <ButtonMui
            style={{ fontSize: "clamp(10px, 0.9vw, 14px)" }}
            onClick={this.showOpeningBalances}
            label={
              this.props.activeOpeningBalances ? "Save Opening Balances" : "Set Opening Balances"
            }
            variant={"contained"}
            color={"secondary"}
          />
        )}
      </>
    );
  }
}
