import React, { useState } from "react";
import PropTypes from "prop-types";
import { Dialog, DialogActions } from "@mui/material";
import Button from "../../actions/Button";

function SimpleDialog(props) {
  const [open, setOpen] = useState(false);

  if (props.functionObject) {
    props.functionObject.open = setOpen;
  }

  return (
    <Dialog
      PaperProps={{
        sx: {
          padding: "15px 15px 5px 15px",
          textAlign: "center",
          fontFamily: "Inter",
        },
      }}
      open={open}
      onClose={() => setOpen(false)}
    >
      {props.text}

      <DialogActions>
        {props.buttons.map((button, i) => {
          return (
            <Button
              key={i}
              label={button.label}
              backgroundColor={button.backgroundColor ? button.backgroundColor : "primary"}
              padding={"0 0 0 0"}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                button.onClick();
              }}
            />
          );
        })}
      </DialogActions>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  functionObject: PropTypes.object,
  text: PropTypes.string,
  buttons: PropTypes.array,
};

export default SimpleDialog;
