import React from "react";
import SlideComponent from "../SlideComponent";
import { SlideTypes } from "../SlideObjects/SlideStructures";
import ContentPanel2 from "../ContentPanel2";
import "./slide_light.scss";

class SlideComponentLight extends SlideComponent {
  componentDidMount() {}

  componentWillUnmount() {}

  recalculateSlideObjectBoundaries() {}

  buildBoundingRectangles() {}

  render() {
    const slideObjectsToBeRendered = this.state.slideObjects.filter(
      (c) => !c.props.groupKey || c.type === SlideTypes.GroupObject,
    );

    return (
      <div className={"sc_light se_panel"} id={"se_scale_panel"}>
        {this.props.children}
        {this.returnPageSetup()}
        {slideObjectsToBeRendered.map((obj, index) => (
          <ContentPanel2
            slideObject={obj}
            groupedObjects={
              obj.type === SlideTypes.GroupObject
                ? this.getGroupedObjects(obj.props.groupKey)
                : null
            }
            recalculateObjectsAgainstRatio={this.recalculateObjectsAgainstRatio}
            disableEdit
            left={this.state.left}
            onClick={() => {}}
            top={this.state.top}
            zIndex={obj.props.zIndeX}
            boundries={this.boundries}
            publishMode={true}
            scale={this.props.scale}
          ></ContentPanel2>
        ))}
      </div>
    );
  }
}

export default SlideComponentLight;
