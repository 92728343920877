import React, { useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import "./DashboardsDropDownMenu.scss";
import { MoreHorizontal, SmallPlusIcon } from "../../../../components/icons/svgIcons";
import { Link } from "react-router-dom";
import {
  ReusableMenu,
  ReusableMenuItem,
} from "../../../../components/menus/ReusableMenu/ReusableMenu";

export default function DashboardsDropDownMenu(props) {
  const menuItems = props.menuItems.tabs.map((tab, i) =>
    i > 5 ? new ReusableMenuItem(tab.label, true, false, [], Link, tab.link) : null,
  );

  return (
    <div className={props.className}>
      <ReusableMenu
        menuButton={
          <IconButton>
            <div className="svg_container">
              <MoreHorizontal width="24" />
            </div>
          </IconButton>
        }
        menuItems={[null]}
        menuElementItems={props.menuElementItems}
      />
    </div>
  );
}
