const AssetCategories = {
  PropertyPlantAndEquipment: "property-plant-equipment",
  Vehicles: "vehicles",
  Furniture: "furniture",
  Investments: "investments",
  Intangibles: "intangibles",
};

const AssetType = {
  CurrentAsset: "current",
  LongTerm: "long-therm",
};

const PurchaseType = {
  OneTime: "one-time",
  Multiple: "multiple-times",
};

const PaymentType = {
  FullPrice: "full",
  Installments: "installments",
};

const ResellType = {
  Yes: "yes",
  No: "no",
};

const currentLifeOfAsset = [
  { value: "0", description: "This asset does not depreciate" },
  { value: "1", description: "1 months" },
  { value: "2", description: "2 months" },
  { value: "3", description: "3 months" },
  { value: "4", description: "4 months" },
  { value: "5", description: "5 months" },
  { value: "6", description: "6 months" },
  { value: "7", description: "7 months" },
  { value: "8", description: "8 months" },
  { value: "9", description: "9 months" },
  { value: "10", description: "10 months" },
  { value: "11", description: "11 months" },
  { value: "12", description: "12 months" },
];

const longTermLifeOfAsset = [
  { value: 0, description: "This asset does not depreciate" },
  { value: 1, description: "1 years" },
  { value: 1.5, description: "1.5 years" },
  { value: 2, description: "2 years" },
  { value: 3, description: "3 years" },
  { value: 4, description: "4 years" },
  { value: 5, description: "5 years" },
  { value: 6, description: "6 years" },
  { value: 7, description: "7 years" },
  { value: 8, description: "8 years" },
  { value: 9, description: "9 years" },
  { value: 10, description: "10 years" },
];

export {
  AssetCategories,
  AssetType,
  PurchaseType,
  PaymentType,
  ResellType,
  currentLifeOfAsset,
  longTermLifeOfAsset,
};
