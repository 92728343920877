import React, { Component } from "react";
import PropTypes from "prop-types";
import image from "./rocket.png";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import ButtonMui from "../../../buttons/buttonMui/buttonMui";
import { subsTitleDictionary } from "./DowngradeSubscription";
class UpgradeSubscription extends Component {
  constructor(props) {
    super(props);

    this.subscriptionPlans = this.props.subscriptionPlans;
  }

  getContent = () => {
    const data_key =
      (this.subscriptionPlans.newPlan.strength % 3) +
      "_" +
      (this.subscriptionPlans.oldPlan.strength % 3);
    if (
      this.subscriptionPlans.oldPlan.strength % 3 === this.subscriptionPlans.newPlan.strength % 3 &&
      this.subscriptionPlans.oldPlan.strength != 0
    ) {
      return [];
    }
    return content(this.subscriptionPlans)[data_key];
  };

  getSavings = () => {
    let monthlySubscription = this.subscriptionPlans.oldPlan.recurring_price;
    let yearlySubscription = this.subscriptionPlans.newPlan.recurring_price / 12;
    if (monthlySubscription && yearlySubscription) {
      return (
        Math.round(
          (Math.abs(monthlySubscription - yearlySubscription) / monthlySubscription) * 100,
        ) + "%"
      );
    }

    return "";
  };

  dictionary = {
    year: "an annual",
    month: "a monthly",
  };
  render() {
    return (
      <>
        <DialogTitle className={"downgrade_subscription_title"}>
          Upgrade {this.subscriptionPlans.oldPlan.data_key.toUpperCase()}{" "}
          {subsTitleDictionary[this.subscriptionPlans.oldPlan.billing_type]} to{" "}
          <span className={"blue"}>
            {this.subscriptionPlans.newPlan.data_key.toUpperCase()}{" "}
            {subsTitleDictionary[this.subscriptionPlans.newPlan.billing_type]}
          </span>
        </DialogTitle>
        <DialogContent className={"upgrade_subscription"}>
          <div className={"p1"}>
            For upgrading your plan, you’ll be charged $
            {this.subscriptionPlans.newPlan.recurring_price}
            {this.subscriptionPlans.oldPlan.recurring_price != 0 ? (
              <span>
                , prorated for the remaining part of your current subscription.
                {this.subscriptionPlans.oldPlan.billing_type !=
                  this.subscriptionPlans.newPlan.billing_type &&
                  ` Also, any of your existing add-ons will be changed to ${this.dictionary[this.subscriptionPlans.newPlan.billing_type]} billing and charged prorated.`}
              </span>
            ) : (
              "."
            )}
          </div>

          {this.subscriptionPlans.oldPlan.billing_type !=
            this.subscriptionPlans.newPlan.billing_type &&
            this.subscriptionPlans.oldPlan.strength == this.subscriptionPlans.newPlan.strength && (
              <div className={"p1 bold"}>
                With the upgrade to the annual plan, you will save {this.getSavings()}!
              </div>
            )}
          {this.subscriptionPlans.oldPlan.strength != this.subscriptionPlans.newPlan.strength && (
            <div className={"p1 bold"}>
              {this.subscriptionPlans.oldPlan.recurring_price == 0
                ? "With the upgrade you will be able to continue to use Modeliks including:"
                : "With the upgrade you will unlock:"}
            </div>
          )}
          <div className={"item_cont"}>
            {this.getContent().map((c) => (
              <div className={"item"}>{c}</div>
            ))}
          </div>
        </DialogContent>
        <DialogActions className={"downgrade_subscription_actions"}>
          <ButtonMui
            width={132}
            variant={"outlined"}
            label={"Cancel"}
            onClick={this.props.onCancel}
          />
          <ButtonMui
            width={132}
            variant={"contained"}
            label={"Confirm"}
            onClick={this.props.onAccept}
          />
        </DialogActions>
        {/*<div className={'u_s_image_cont'}>*/}
        {/*    <Image/>*/}
        {/*</div>*/}
      </>
    );
  }
}
UpgradeSubscription.propTypes = {
  subscriptionPlans: PropTypes.object,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
};

const Image = () => <img src={image} />;

const content = (subscriptionPlans) => {
  const totalPlans =
    global.Modeliks.subscriptionInfo.TotalCompanies /
    global.Modeliks.subscriptionInfo.companies_number;
  const extraUsers =
    global.Modeliks.subscriptionInfo.TotalUsers -
    global.Modeliks.subscriptionInfo.users_number * totalPlans;
  const differenceUsers =
    subscriptionPlans.newPlan.users_number - subscriptionPlans.oldPlan.users_number;

  const differenceCompanies =
    subscriptionPlans.newPlan.companies_number - subscriptionPlans.oldPlan.companies_number;
  return {
    "0_2": [
      "• Plan vs. actual reporting",
      "• Predefined dashboards",
      "• Custom dashboards",
      "• One-click investor reports",
      "• Ratio analysis",
      `• ${differenceUsers} users`,
      `• ${differenceCompanies} companies`,
    ],
    "0_1": [
      "• Driver-based financial planning\n",
      "• Financial model templates by industry\n",
      "• Plan vs. actual reporting\n",
      "• Predefined dashboards\n",
      "• Custom dashboards\n",
      "• One-click investor reports\n",
      "• Ratio analysis\n",
      `• ${differenceUsers} users`,
      `• ${differenceCompanies} companies`,
    ],
    "2_1": [
      "• Driver-based financial planning\n",
      "• Financial model templates by industry\n",
      `• ${differenceUsers} users`,
      `• ${differenceCompanies} companies`,
    ],
    "0_0": [
      "• Pitch deck creator. 100+ template slides",
      "• Business plan. Lender approved format",
      "• Guided financial planning",
      "• Automated financial statements",
      "• Driver-based financial planning",
      "• Financial model templates by industry",
      "• Plan vs. actual reporting",
      "• Predefined dashboards",
      "• Custom dashboards",
      "• One-click investor reports",
      "• Ratio analysis",
      "• Sharing capability",
      `• ${differenceUsers + 1} users`,
      `• ${differenceCompanies + 1} companies`,
    ],
    "2_0": [
      "• Pitch deck creator. 100+ template slides",
      "• Business plan. Lender approved format",
      "• Guided financial planning",
      "• Automated financial statements",
      "• Driver-based financial planning",
      "• Financial model templates by industry",
      "• Sharing capability",
      `• ${differenceUsers + 1} users`,
      `• ${differenceCompanies + 1} companies`,
    ],
    "1_0": [
      "• Pitch deck creator. 100+ template slides",
      "• Business plan. Lender approved format",
      "• Guided financial planning",
      "• Automated financial statements",
      "• Sharing capability",
      `• ${differenceUsers + 1} users`,
      `• ${differenceCompanies + 1} companies`,
    ],
  };
};

export default UpgradeSubscription;
