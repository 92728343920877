import { DriverCategories, UnitTypes } from "../../CalculatedDriver/constants";
import Reports from "../index";
import Assets from "../../Assets";
import Financing from "../../Financing";
import CalculatedDriver_Values from "../../CalculatedDriver/CalculatedDriver_Values";
import CashFlow from "../CashFlow";

class OpeningBalanceCalc extends Reports {
  getFirstDateValue = (driver) => {
    return driver.getItemByDateSufix(global.Modeliks.DateHelper.months[0].sufix);
  };

  createEquityLiability = (drivers) => {
    return drivers.map((d) => this.setEquityLiability(d));
  };

  createAssets = (assets) => {
    return this.setAsset(assets);
  };

  setEquityLiability = (revenue, isChild = false, level = 0) => {
    if (!revenue) {
      return;
    }
    let row = {};

    const childDrivers = () => {
      let children = revenue.getChildDrivers();

      if (children !== null || (children !== undefined && !row.Value)) {
        row.hasTotalCalculation = true;
        let Value = global.Modeliks.DriverValuesStore.getItem(
          `${revenue.ID}-opening-balance-total-calc`,
        );
        if (!Value) {
          Value = new CalculatedDriver_Values(
            null,
            `${revenue.ID}-opening-balance-total-calc`,
            null,
            UnitTypes.Price,
          );
        }
        Value.Formula = "";
        row.Value = Value;

        if (children && children.length > 0) {
          children.map((driver) => {
            if (
              driver.Ref_ID === "asset-payable-balance-current-term" ||
              driver.Ref_ID === "asset-payable-balance-long-term" ||
              driver.DriverName === "Long term asset purchases payable" ||
              driver.DriverName === "Current asset purchases payable"
            ) {
              return;
            }
            if (driver.Ref_ID === "retained_earnings") {
              row.children.push({
                Name: driver.DriverName,
                Value: this.getFirstDateValue(Financing.getOpenningBalanceRetainedEarnings()),
                expanded: true,
                children: [],
              });
              row.Value.Formula = `${row.Value.Formula} ${row.Value.Formula.length > 3 ? "+" : ""} ${this.getFirstDateValue(Financing.getOpenningBalanceRetainedEarnings())}`;
              return;
            }

            const parentDriver = driver.getParentStream();
            if (parentDriver) {
              if (
                ((parentDriver.hasOwnProperty("OpeningBalance") && parentDriver.OpeningBalance) ||
                  (parentDriver.hasOwnProperty("OpenningBalance") &&
                    parentDriver.OpenningBalance)) &&
                revenue.DriverName !== "Shareholder Equity"
              ) {
                if (parentDriver.hasOwnProperty("OpeningBalance")) {
                  let OpeningBalanceValue = this.getFirstDateValue(parentDriver.OpenningBalance);
                  row.children.push({
                    Name: parentDriver.Name,
                    Value: OpeningBalanceValue,
                    expanded: true,
                    children: [],
                  });
                  row.Value.Formula = `${row.Value.Formula}${row.Value.Formula.length > 3 ? "+" : ""}${OpeningBalanceValue.ID_f}`;
                } else {
                  let OpeningBalanceValue = this.getFirstDateValue(parentDriver.OpenningBalance);
                  row.children.push({
                    Name: parentDriver.Name,
                    Value: OpeningBalanceValue,
                    expanded: true,
                    children: [],
                  });
                  row.Value.Formula = `${row.Value.Formula}${row.Value.Formula.length > 3 ? "+" : ""}${OpeningBalanceValue.ID_f}`;
                }
              } else {
                if (revenue.DriverName === "Shareholder Equity") {
                  let OpeningBalanceValue = this.getFirstDateValue(parentDriver.OpenningBalance);
                  row.children.push({
                    Name: parentDriver.Name,
                    Value: OpeningBalanceValue,
                    expanded: true,
                    children: [],
                  });
                  row.Value.Formula = `${row.Value.Formula}${row.Value.Formula.length > 3 ? "+" : ""}${OpeningBalanceValue.ID_f}`;
                }
              }

              // todo check for opening balance in assets and financing loan
            } else {
              if (driver.Ref_ID === "prepaid-revenue-closing-balance") {
                let newDriver = Financing.getPrepaidRevenueOpeningBalance();
                let Value = this.getFirstDateValue(newDriver);
                row.Value.Formula = `${row.Value.Formula}${row.Value.Formula.length > 3 ? "+" : ""}${Value}`;
                row.children.push({
                  id: newDriver.ID,
                  Name: newDriver.DriverName,
                  expanded: true,
                  Value: Value,
                  children: [],
                });
              } else {
                let add = this.setEquityLiability(driver, true, level + 1);
                if (add && add.hasOwnProperty("Value") && add.Value) {
                  row.Value.Formula = `${row.Value.Formula}${row.Value.Formula.length > 3 ? "+" : ""}${add.Value && add.Value.ID_f}`;
                }
                row.children.push(add);
              }
            }
          });
        }
      } else {
        if (revenue.IsTemporary) {
          row.hasTotalCalculation = true;
          let Value = global.Modeliks.DriverValuesStore.getItem(
            `${revenue.ID}-opening-balance-total-calc`,
          );
          if (!Value) {
            Value = new CalculatedDriver_Values(
              null,
              `${revenue.ID}-opening-balance-total-calc`,
              null,
              UnitTypes.Price,
            );
          }
          Value.cur_record.Formula = "";
          row.Value = Value;
        } else {
          if (revenue.Ref_Table === "Finance_WorkingCapital") {
            const WorkingCapitalDriver = global.Modeliks.WorkingCapitalStore.getItem(
              revenue.Ref_ID,
            );
            row.Value = this.getFirstDateValue(WorkingCapitalDriver.OpeningBalance);
          } else if (
            revenue.DriverName === "Prepaid Revenue" ||
            revenue.DriverName === "Unearned Revenue"
          ) {
            row.Value = this.getFirstDateValue(Financing.getPrepaidRevenueOpeningBalance());
          } else if (revenue.Ref_Table === global.Modeliks.Tables.Finance_Taxes.TableName) {
            // IncomeTaxPayableOpenningBalance
            // console.log('revenue', revenue);
          } else {
            let Value = global.Modeliks.DriverValuesStore.getItem(
              `${revenue.ID}-opening-balance-total-calc`,
            );
            if (!Value) {
              Value = new CalculatedDriver_Values(
                null,
                `${revenue.ID}-opening-balance-total-calc`,
                null,
                UnitTypes.Price,
              );
              Value.Formula = "";
            }
            row.Value = Value;
          }
        }
      }
    };

    row = {
      id: revenue.ID,
      Name: revenue.DriverName,
      expanded: true,
      Value: null,
      children: [],
    };

    if (revenue.Ref_Table === "Finance_WorkingCapital") {
      const WorkingCapitalDriver = global.Modeliks.WorkingCapitalStore.getItem(revenue.Ref_ID);
      row.Value = this.getFirstDateValue(WorkingCapitalDriver.OpeningBalance);
    }

    if (revenue.Ref_Table === global.Modeliks.Tables.Finance_Taxes.TableName) {
      const TaxesDriver = global.Modeliks.TaxesStore.getItem(revenue.Ref_ID);
      if (TaxesDriver) {
        row.Value = TaxesDriver.PayableOpenningBalance;
      } else {
        row.Value = null;
      }
    }

    childDrivers();

    return row;
  };
  setAsset = (revenue, isChild = false, level = 0) => {
    if (!revenue) {
      return null;
    }
    let row = {};

    const childDrivers = () => {
      let children = revenue.getChildDrivers();

      if (children !== null || (children !== undefined && !row.Value)) {
        row.hasTotalCalculation = true;
        let Value = global.Modeliks.DriverValuesStore.getItem(
          `${revenue.ID}-opening-balance-total-calc`,
        );
        if (!Value) {
          Value = new CalculatedDriver_Values(
            null,
            `${revenue.ID}-opening-balance-total-calc`,
            null,
            UnitTypes.Price,
          );
        }
        Value.cur_record.Formula = "";
        row.Value = Value;
        if (children && children.length > 0) {
          children.map((driver) => {
            const parentDriver = driver.getParentStream();
            if (parentDriver) {
              let childValue = global.Modeliks.DriverValuesStore.getItem(
                `${parentDriver.ID}-opening-balance-total-calc`,
              );
              if (!childValue) {
                childValue = new CalculatedDriver_Values(
                  null,
                  `${parentDriver.ID}-opening-balance-total-calc`,
                  null,
                  UnitTypes.Price,
                );
              }
              const GrossBookValue = parentDriver.GrossBookValueOpeningBalance;
              const AccumulatedDepreciation = parentDriver.AccumulatedDepreciationOpeningBalance;
              childValue.Formula = `${GrossBookValue} - ${AccumulatedDepreciation}`;

              row.children.push({
                Name: parentDriver.Name,
                Value: childValue,
                expanded: true,
                children: [
                  {
                    Name: parentDriver.GrossBookValue.DriverName,
                    Value: GrossBookValue,
                    children: [],
                  },
                  {
                    Name: parentDriver.AccumulatedDepreciation.DriverName,
                    Value: AccumulatedDepreciation,
                    children: [],
                  },
                ],
              });
              row.Value.Formula = `${row.Value.Formula}${row.Value.Formula.length > 3 ? "+" : ""}${childValue.ID_f}`;
            } else {
              let add = this.setAsset(driver, true, level + 1);
              if (add.Value) {
                row.Value.Formula = `${row.Value.Formula}${row.Value.Formula.length > 3 ? "+" : ""}${add ? add.Value.ID_f : ""}`;
              } else {
                row.Value.Formula = `${row.Value.Formula}`;
              }
              row.children.push(add);
            }
          });
        }
      }
    };

    row = {
      id: revenue.ID,
      Name: revenue.DriverName,
      expanded: true,
      Value: null,
      hasTotalCalculation: false,
      children: [],
    };

    if (revenue.Ref_Table === "Finance_WorkingCapital") {
      const WorkingCapitalDriver = global.Modeliks.WorkingCapitalStore.getItem(revenue.Ref_ID);
      row.Value = this.getFirstDateValue(WorkingCapitalDriver.OpeningBalance);
    } else if (revenue.Ref_Table === "Finance_Reports") {
      row.Value = CashFlow.getOpeningCashBalance();
    }

    childDrivers();
    return row;
  };

  static getOpeningBalance = () => {
    const newOpeningBalance = new OpeningBalanceCalc();
    const gridRowsAssets = newOpeningBalance.createAssets(Assets.getAssetsTotals());
    const gridRowsEquity = newOpeningBalance.createEquityLiability([
      Financing.getCurrentAndLongTermLiabilities(),
      Financing.getEquity(),
    ]);
    newOpeningBalance.getFirstDateValue(Financing.getOpenningBalanceRetainedEarnings()).Formula =
      `${gridRowsAssets.Value.ID_f}-${gridRowsEquity[0].Value.ID_f}-${gridRowsEquity[1].children[0].Value.ID_f}`;
    return {
      gridRowsAssets,
      gridRowsEquity,
    };
  };
}

export default OpeningBalanceCalc;
