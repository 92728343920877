import * as React from "react";
import Tooltip from "@mui/material/Tooltip";
import "./headerText.scss";
import { UnitTypes } from "../../../../data/Finance/CalculatedDriver/constants";
import IconButton from "@mui/material/IconButton";
import { tableConfig, tableConfigTypes, tableObject } from "./constants";
import LocalStorage from "../../../../helpers/LocalStorage";

const getChangeableFontSize = () => {
  return "sctfc_text_size-" + LocalStorage.get(tableObject, tableConfig, tableConfigTypes.fontSize);
};

const getUnitType = (type) => {
  switch (type) {
    case UnitTypes.PriceNegative:
    case UnitTypes.Price:
      return global.Modeliks.CompanyInfo.Currency.value;
    case UnitTypes.Units:
    case UnitTypes.Decimal:
    case UnitTypes.Integer:
      return "#";
    case UnitTypes.Growth:
    case UnitTypes.Percentage:
      return "%";
    default:
      return "";
  }
};

export default function HeaderText(props) {
  const { className, header, icon, disableRowType } = props;
  const showRowIconType = icon && !disableRowType;

  return (
    <div className="sctfc_header text">
      {showRowIconType && (
        <span className={`sctfc_header_icon ${getChangeableFontSize()}`}>
          <IconButton size="size">{getUnitType(icon)}</IconButton>
        </span>
      )}
      {showRowIconType && (
        <span className={`sctfc_header_icon_splitter ${getChangeableFontSize()}`}>|</span>
      )}
      <Tooltip title={header} enterDelay={500} enterNextDelay={500} placement="right-end" arrow>
        <div className={className}>{header}</div>
      </Tooltip>
    </div>
  );
}
