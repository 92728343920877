import { UnitTypes } from "./constants";
import { DateHelper, PeriodTypes } from "../../dates";
import Revenue from "../Revenue";
import MxDataArray from "../../MxDataArray";

const editableFields = ["Actual", "Value", "Formula", "Unit"];

class CalculatedDriver_Values {
  db_record = null;
  cur_record = null;
  saved_records = {};

  constructor(
    db_record = null,
    ID,
    ID_Driver = null,
    Unit = null,
    DateIndex = null,
    Month = null,
    Year = null,
    PeriodType = null,
  ) {
    if (db_record == null || (db_record && !db_record._id)) {
      this.cur_record = {
        ID: ID,
        ID_Driver: ID_Driver,
        ID_CompanyScenario: global.Modeliks.CompanyScenarioInfo.ID,
        // DateIndex: DateIndex,
        Order: null,
        // Month: Month,
        // Year: Year,
        PeriodType: PeriodType,
        Value: null,
        Actual: null,
        Unit: Unit,
        // Header: null,
        // Active: true,
        Formula: null,
        AccountID: null,
        // IsSimple: true,
      };

      if (db_record && !db_record._id) {
        Object.assign(this.cur_record, db_record);
        this.db_record = null;
      }
    } else {
      this.cur_record = {
        AccountID: db_record.AccountID,
        Actual: db_record.Actual,
        // DateIndex: db_record.DateIndex,
        Formula: db_record.Formula,
        ID: db_record.ID,
        ID_CompanyScenario: db_record.ID_CompanyScenario,
        ID_Driver: db_record.ID_Driver,
        // Month: db_record.Month,
        Order: db_record.Order,
        PeriodType: db_record.PeriodType,
        Unit: db_record.Unit,
        Value: db_record.Value,
        // Year: db_record.Year,
        _id: db_record._id,
      };

      // if (db_record.Formula) {
      //     db_record.Value = null;
      // }
      this.db_record = db_record;
      // this.cur_record = Object.assign({}, db_record);
      // this.cur_record.ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
      if (this.cur_record.Formula != null) {
        this.cur_record.Value = null;
        if (this.cur_record.Formula.includes("Actual(")) {
          this.cur_record.Actual = null;
        }
      }
    }

    global.Modeliks.DriverValuesStore.push(this);
  }

  get ID() {
    return this.cur_record.ID;
  }

  get ID_f() {
    return "|" + this.ID + "|";
  }

  get ID_f_actual() {
    return `;${this.ID};`;
  }

  get ID_Driver() {
    return this.cur_record.ID_Driver;
  }

  get ID_CompanyScenario() {
    return this.cur_record.ID_CompanyScenario;
  }

  get DateIndex() {
    return this.cur_record.DateIndex;
  }

  get Order() {
    return this.cur_record.Order;
  }

  get Month() {
    return this.cur_record.Month;
  }

  get Year() {
    return this.cur_record.Year;
  }

  get PeriodType() {
    return this.cur_record.PeriodType;
  }

  get Header() {
    return this.cur_record.Header;
  }

  get IsSimple() {
    return this.cur_record.Formula == null;
  }

  get Date() {
    return global.Modeliks.DateHelper.all_periods.getItem(`${this.Order}-${this.PeriodType}`);
  }

  get DateHeader() {
    return this.Date ? this.Date.Header : null;
  }

  get isNew() {
    return this.db_record == null;
  }

  get Unit() {
    return this.cur_record.Unit;
  }

  set Unit(value) {
    this.#unit = null;
    this.cur_record.Unit = value;
  }

  #unit = null;

  get UnitNegative() {
    return this.Unit === UnitTypes.PriceNegative;
  }

  get UnitParsed() {
    if (this.#unit == null) {
      if (this.Unit == UnitTypes.Percentage) {
        this.#unit = "%";
      } else if (this.Unit == UnitTypes.Price || this.Unit == UnitTypes.PriceNegative) {
        this.#unit = global.Modeliks.CompanyInfo.Currency.value;
      } else if (this.Unit == UnitTypes.Integer) {
        this.#unit = null;
      } else if (this.Unit == UnitTypes.Decimal) {
        this.#unit = "";
      } else if (this.Unit == UnitTypes.Multiple) {
        this.#unit = "x";
      } else {
        this.#unit = "";
      }
    }
    return this.#unit;
  }

  get Hidden() {
    return this.Unit == UnitTypes.Hide || this.Unit === UnitTypes.GrowthValue;
  }

  get Actual() {
    if (this.cur_record.Actual == null && this.evalFormulaActual) {
      try {
        if (
          this.Unit == UnitTypes.Percentage &&
          !this.evalFormulaActual.includes("* 100") &&
          !this.evalFormulaActual.includes("_growth")
        ) {
          this.cur_record.Actual = eval(this.evalFormulaActual) * 100;
        } else {
          this.cur_record.Actual = eval(this.evalFormulaActual);
        }
      } catch (e) {
        console.log(this.Formula, this);
        console.error("Bad Formulaki Actual " + this.evalFormulaActual);
        // throw e;
        return null
      }
    }
    return this.cur_record.Actual;
  }

  set Actual(value) {
    if ((this.evalFormulaActual && value != null) || this.cur_record.Actual == value) {
      return;
    }
    this.cur_record.Actual = value;
    global.Modeliks.DriverValuesStore.actualObjectUpdated(this);
  }

  CalculatingValue = false;

  get Value() {
    if (this.cur_record.Value == null && this.IsSimple == false) {
      try {
        if (this.CalculatingValue) {
          console.error("ref err " + this.evalFormula + "ID -> " + this.ID);
          this.cur_record.Value = 0;
          global.Modeliks.GetCircleReferenceDrivers(this);
          return;
        }
        this.CalculatingValue = true;

        // this.cur_record.Value = eval(this.evalFormula);

        if (
          this.Unit == UnitTypes.Percentage &&
          !this.evalFormula.includes("* 100") &&
          !this.evalFormula.includes("_growth")
        ) {
          this.cur_record.Value = eval(this.evalFormula) * 100;
        } else {
          this.cur_record.Value = eval(this.evalFormula);
        }

        this.CalculatingValue = false;
      } catch (e) {
        // console.log(this.Formula, this);
        console.error("Bad Formulaki " + this.evalFormula);
        // throw e;
        return null
      }
    }

    return this.cur_record.Value;
  }

  set Value(value) {
    if ((this.IsSimple == false && value != null) || this.cur_record.Value == value) {
      return;
    }
    this.cur_record.Value = value;
    global.Modeliks.DriverValuesStore.valueObjectUpdated(this);
  }

  get ValueFormula() {
    if (this.Value == null) {
      return 0;
    }
    if (this.Unit == UnitTypes.Percentage) {
      return this.Value / 100;
    }

    return this.Value;
  }

  get ActualFormula() {
    if (this.Actual == null) {
      return 0;
    }
    if (this.Unit == UnitTypes.Percentage) {
      return this.Actual / 100;
    }

    return this.Actual;
  }

  get Formula() {
    return this.cur_record.Formula;
  }

  evalFormula = "";
  evalFormulaActual = null;

  removeFromParentRels = () => {
    this.parentRels.forEach((driverValue) => {
      driverValue.childrenRels.remove(this);
    });
    this.parentRels.clear();
  };

  buildFormula = () => {
    this.removeFromParentRels();

    this.evalFormula = this.Formula;
    if (!this.evalFormula) {
      return;
    }

    const fields = this.evalFormula
      .split("|")
      .filter((c) => c.length > 10 && c.indexOf("Math") == -1 && c.indexOf(" ") === -1);

    fields.forEach((field) => {
      this.evalFormula = this.evalFormula.replace(
        "|" + field + "|",
        `valIndexes['${field}'].ValueFormula`,
      );
      const driver = global.Modeliks.DriverValuesStore.getItem(field);
      if (driver == null) {
        console.log("driver is null", field, this);
      } else {
        if (!this.parentRels.includes(driver)) {
          this.parentRels.push(driver);
        }

        if (!driver.childrenRels.includes(this)) {
          driver.childrenRels.push(this);
        }
      }
    });

    if (!this.db_record && !this.Formula.includes("Actual(")) {
      this.evalFormulaActual = this.evalFormula.replaceAll("ValueFormula", "ActualFormula");
    } else {
      this.evalFormulaActual = null;
    }

    if (this.Formula.includes("Actual(")) {
      this.evalFormulaActual = this.evalFormula.substring(
        this.evalFormula.indexOf("Actual(") + 7,
        this.evalFormula.lastIndexOf(")"),
      );
      this.evalFormula = this.evalFormula.substring(0, this.evalFormula.indexOf("Actual("));

      const fields = this.evalFormulaActual
        .split(";")
        .filter((c) => c.length > 10 && c.indexOf("Math") == -1 && c.indexOf(" ") === -1);
      fields.forEach((field) => {
        this.evalFormulaActual = this.evalFormulaActual.replace(
          ";" + field + ";",
          `valIndexes['${field}'].ActualFormula`,
        );
        const driver = global.Modeliks.DriverValuesStore.getItem(field);
        if (driver !== null) {
          if (!this.parentRels.includes(driver)) {
            this.parentRels.push(driver);
          }
          if (!driver.childrenRels.includes(this)) {
            driver.childrenRels.push(this);
          }
        }
      });
    }

    if (this.Formula.includes("() / MxMath.Sum([])")) {
      this.evalFormula = null;
    }
  };

  childrenRels = MxDataArray.create();
  parentRels = MxDataArray.create();

  set Formula(value) {
    this.Value = null;
    this.cur_record.Formula = value;
    if (value != null) {
      if (value.includes("Actual(")) {
        this.Actual = null;
      }
      this.buildFormula();
    } else {
      this.removeFromParentRels();
    }
  }

  get hasChanged() {
    if (this.db_record == null) {
      return true;
    }

    if (this.cur_record.Unit !== this.db_record.Unit) {
      return true;
    }
    if (this.Formula) {
      return this.cur_record.Formula !== this.db_record.Formula;
    } else {
      return (
        this.cur_record.Value !== this.db_record.Value ||
        this.cur_record.Actual !== this.db_record.Actual
      );
    }

    return false;
    // return this.db_record == null || JSON.stringify(this.db_record) !== JSON.stringify(this.cur_record)
  }

  changeID = (newID, oldID = null, updateRef = true) => {
    if (oldID == null) {
      if (this.ID_Driver != null) {
        oldID = this.ID_Driver;
      } else {
        oldID = this.ID;
      }
    }
    const prevID = this.ID;

    this.cur_record.ID = this.cur_record.ID.replace(oldID, newID);
    if (this.cur_record.ID_Driver != null) {
      this.cur_record.ID_Driver = newID;
    }

    if (updateRef) {
      global.Modeliks.DriverValuesStore.updateID(this.cur_record.ID, prevID);
    }
  };

  cleanValue = () => {
    if (this.db_record) {
      // this.cur_record = this.db_record;
      // //
      // // this.cur_record.DateIndex = this.db_record.DateIndex;
      // // this.cur_record.Formula = this.db_record.Formula;
      // // this.cur_record.ID = this.db_record.ID;
      // // this.cur_record.ID_Driver = this.db_record.ID_Driver;
      // // this.cur_record.ID_CompanyScenario = this.db_record.ID_CompanyScenario;
      // // this.cur_record.Month = this.db_record.Month;
      // // this.cur_record.Order = this.db_record.Order;
      // // this.cur_record.PeriodType = this.db_record.PeriodType;
      // // this.cur_record.Unit = this.db_record.Unit;
      // // this.cur_record.Year = this.db_record.Year;
      // // this.cur_record.Actual = this.db_record.Actual;
      // if (this.db_record.Formula != null) {
      //     this.db_record.Value = null;
      // }else {
      //     this.cur_record.Value = this.db_record.Value;
      // }
      const { db_record } = this;

      this.cur_record = {
        AccountID: db_record.AccountID,
        Actual: db_record.Actual,
        // DateIndex: db_record.DateIndex,
        Formula: db_record.Formula,
        ID: db_record.ID,
        ID_CompanyScenario: db_record.ID_CompanyScenario,
        ID_Driver: db_record.ID_Driver,
        // Month: db_record.Month,
        Order: db_record.Order,
        PeriodType: db_record.PeriodType,
        Unit: db_record.Unit,
        // Value: db_record.Value,
        // Year: db_record.Year,
        _id: db_record._id,
      };

      if (db_record.Formula && db_record.Formula.length > 3) {
        this.cur_record.Value = null;
      } else {
        this.cur_record.Value = db_record.Value;
      }

      try {
        this.buildFormula();
      } catch {
        console.error("err building form...");
      }
    }
  };

  GetChanges = () => {
    const obj = {};
    let changed = false;
    editableFields.forEach((field) => {
      if (this.db_record[field] != this.cur_record[field]) {
        obj[field] = this.cur_record[field];
        changed = true;
      }
    });
    if (changed) {
      if (this.cur_record["evalFormula"]) {
        delete obj["Value"];
      }

      if (this.cur_record["evalFormulaActual"]) {
        delete obj["Actual"];
      }

      if (Object.values(obj).length) {
        if (this.cur_record.hasOwnProperty("_id")) {
          obj["_id"] = this.cur_record["_id"];
        } else {
          obj["ID"] = this.cur_record["ID"];
        }
        return obj;
      }
    }

    return null;
  };

  Save = (callBack, force = false) => {
    if (this.isNew) {
      global.Modeliks.post(
        global.Modeliks.Tables.Finance_CalculatedDriver_Values.TableName,
        this.cur_record,
        callBack,
      );
    } else {
      const changes = this.GetChanges();

      if (changes) {
        global.Modeliks.put(
          global.Modeliks.Tables.Finance_CalculatedDriver_Values.TableName,
          null,
          changes,
          (success) => {
            this.db_record.Value = this.cur_record.Value;
            this.db_record.Actual = this.cur_record.Actual;
            callBack && callBack();
          },
        );
      } else if (force) {
        global.Modeliks.put(
          global.Modeliks.Tables.Finance_CalculatedDriver_Values.TableName,
          null,
          {
            Unit: this.Unit,
            _id: this.db_record._id,
          },
          () => {},
        );
      } else {
        callBack && setTimeout(callBack, 100);
      }
    }
  };

  Reset = (key) => {
    if (this.saved_records[key]) {
      this.Formula = null;
      if (this.IsSimple == false) {
        // this.Value = null;
      } else {
        // this.saved_records[key].Formula = null;
      }
      // global.Modeliks.DriverValuesStore.valueUpdated(this.ID);
    }
  };

  RemoveState = (key) => {
    delete this.saved_records[key];
  };

  SaveState = (key) => {
    this.saved_records[key] = Object.assign({}, this.cur_record);
  };

  PopState = (key) => {
    if (this.saved_records[key]) {
      this.cur_record = Object.assign({}, this.saved_records[key]);
      if (this.IsSimple == false) {
        this.Value = null;
      }
      global.Modeliks.DriverValuesStore.valueUpdated(this.ID);
    }
  };

  StateExists = (key) => {
    return this.saved_records.hasOwnProperty(key);
  };

  toString = () => {
    return this.ID_f;
  };

  cloneCalculatedDriver_Value = (ID_Driver, oldRef_ID) => {
    const newValue = new CalculatedDriver_Values(
      null,
      null,
      null,
      this.Unit,
      this.DateIndex,
      this.Month,
      this.Year,
      this.PeriodType,
    );
    newValue.cur_record.Value = this.Value;
    newValue.cur_record.Order = this.Order;
    newValue.cur_record.ID = this.ID.replace(oldRef_ID, ID_Driver);

    if (this.ID_Driver) {
      newValue.cur_record.ID_Driver = this.ID_Driver.replace(oldRef_ID, ID_Driver);
    }
    if (this.Formula) {
      newValue.cur_record.Formula = this.Formula.replaceAll(oldRef_ID, ID_Driver);
    }
    global.Modeliks.DriverValuesStore.push(newValue);
    return newValue;
  };

  validateThis = (obj) => {
    if (obj != this) {
      console.log("not valid", obj, this, obj.ID, this.ID);
    }
  };

  static create_Period = (ID_Driver, period, unit = null) => {
    const newValue = new CalculatedDriver_Values(null, `${ID_Driver}_${period.sufix}`, ID_Driver);

    newValue.cur_record.Month = period.Month;
    newValue.cur_record.Year = period.Year;
    newValue.cur_record.PeriodType = period.PeriodType;
    newValue.cur_record.Order = period.Order;
    newValue.cur_record.DateIndex = period.dateIndex;
    newValue.cur_record.Unit = unit;

    return newValue;
  };

  static create_Months_Active = (ID, unit = null) => {
    return global.Modeliks.DateHelper.months.map((c) => this.create_Period(ID, c, unit));
  };
  static create_Active_Months_Before_Actual = (ID, unit = null) => {
    return global.Modeliks.DateHelper.months_before_actual.map((c) =>
      this.create_Period(ID, c, unit),
    );
  };

  static create_Months_Before = (ID, unit = null, monthsBefore) => {
    const months = global.Modeliks.DateHelper.months_before.filter(
      (c) => c.Order >= -monthsBefore && c.Order < -12,
    );
    return months.map((month) => CalculatedDriver_Values.create_Period(ID, month, unit));
  };

  static create_Months_After = (ID, unit = null) => {
    return global.Modeliks.DateHelper.months_after.map((month) =>
      CalculatedDriver_Values.create_Period(ID, month, unit),
    );
  };

  static create_Years_All = (ID, unit = null) => {
    return global.Modeliks.DateHelper.years_all.map((c) => this.create_Period(ID, c, unit));
  };

  static create_Years_Actual = (ID, unit = null) => {
    return global.Modeliks.DateHelper.years_before_actual.map((c) =>
      this.create_Period(ID, c, unit),
    );
  };

  static create_QuarterlyView_Months = (ID, unit = null) => {
    return global.Modeliks.DateHelper.months_quarterly_view.map((c) =>
      this.create_Period(ID, c, unit),
    );
  };

  static create_ComparativeView_Months = (ID, unit = null) => {
    return global.Modeliks.DateHelper.months_comaprative.map((c) =>
      this.create_Period(ID, c, c.Header && c.Header.includes("%") ? UnitTypes.Percentage : unit),
    );
  };

  static create_Periods_Discounted_Cash_Flow = (ID, unit = null) => {
    return global.Modeliks.DateHelper.discount_cash_years.map((c) =>
      this.create_Period(ID, c, c.Header && c.Header.includes("%") ? UnitTypes.Percentage : unit),
    );
  };

  static create_Periods_Quarterly = (ID, unit = null) => {
    const driverValues = MxDataArray.create();
    driverValues.pushArray(this.create_QuarterlyView_Months(ID, unit));
    return driverValues;
  };

  static create_Periods_Comparative = (ID, unit = null) => {
    const driverValues = MxDataArray.create();
    driverValues.pushArray(this.create_ComparativeView_Months(ID, unit));
    return driverValues;
  };

  static create_Periods_Active = (ID, unit = null, IsGrowth = false) => {
    const driverValues = MxDataArray.create();

    driverValues.pushArray(this.create_Months_Active(ID, unit));
    driverValues.pushArray(this.create_Years_All(ID, unit));

    if (!IsGrowth) {
      driverValues.pushArray(this.create_Active_Months_Before_Actual(ID, unit));
      driverValues.pushArray(this.create_Years_Actual(ID, unit));
    }

    return driverValues;
  };

  static sortValuesFunc = (a, b) => {
    if (a.PeriodType == b.PeriodType) {
      return a.Order > b.Order ? 1 : -1;
    }

    return a.PeriodType == PeriodTypes.month ? -1 : 1;
  };
}

export default CalculatedDriver_Values;
