import styled from "styled-components";
import cursorRotate from "./Rotate.png";

export default styled.div`
  .square {
    position: absolute;
    width: 7px;
    height: 18px;
    background: #999999 !important;
    border: 1px solid #FFFFFF !important;
    border-radius: 1px;
    box-sizing: border-box;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-left: -1px;
    margin-right: -1px;

  }
  
  .squareRotated {
    position: absolute;
    width: 7px;
    height: 18px;
    background: #999999;
    border: 1px solid #FFFFFF !important;
    border-radius: 1px;
    box-sizing: border-box;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    transform: rotate(90deg);
    margin-top: -6px;
    margin-bottom: -6px;
  }
  
  .circle {
    position: absolute;
    width: 12px;
    height: 12px;
    background: #999999;
    border: 1px solid #FFFFFF !important;
    border-radius: 100px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    margin-right: -8px;
    margin-left: -8px;
    margin-top: -4px;
    margin-bottom: -4px;
    align: center;

  }

  .resizable-handler {
    position: absolute;
    width: 10px;
    height: 10px;
    cursor: pointer;
    z-index: 11;
    background: white;
    border: 1px solid #999999;
    border-radius: 50%;
    box-sizing: border-box;
    

    &.tl,
    &.t,
    &.tr {
      top: -6px;
    }

    &.tl,
    &.l,
    &.bl {
      left: -6px;
    }

    &.bl,
    &.b,
    &.br {
      //bottom: -6px;
    }

    &.br,
    &.r,
    &.tr {
      right: -6px;
    }

    &.l,
    &.r {
      //margin-top: -6px;
    }

    &.t,
    &.b {
      //margin-left: -6px;
    }
  }

  .rotate {
    position: absolute;
    left: 50%;
    top: -26px;
    width: 18px;
    height: 18px;
    margin-left: -9px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: -8px;
  }
  
  .move_border_t {
    position:absolute;
    top:-5px;
    width:100%;
    height:10px;
    
    &:hover {
      cursor:move;
    }
  }
  
  .move_border_r {
    position:absolute;
    right:-5px;
    height:100%;
    width:10px;
    top:0;
    
    &:hover {
      cursor:move;
    }
  }
  
  .move_border_b {
    position:absolute;
    bottom:-5px;
    width:100%;
    height:10px;
    
    &:hover {
      cursor:move;
    }
  }
  
  .move_border_l {
    position:absolute;
    left:-5px;
    height:100%;
    width:10px;
    top:0px;
    
    &:hover {
      cursor:move;
    }
  }
  
  
  .move {
    position: absolute;
    left: 50%;
    top: -26px;
    width: 18px;
    height: 18px;
    margin-left: -9px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: -5px;
  }

  .t,
  .tl,
  .tr {
    top: -6px;
  }

  .b,
  .bl,
  .br {
    bottom: -6px;
  }

  .r,
  .tr,
  .br {
    right: -6px;
  }

  .tl,
  .l,
  .bl {
    left: -6px;
  }

  .l,
  .r {
    top: calc(50% - 6px);
  }

  .t,
  .b {
    left: calc(50% - 6px);
  }

//
// .Rotate:hover #RotateTooltip{
//     visibility: visible;
// }
//
// .Rotate{
//     position: relative;
//     display: inline-block;

}
#RotateTooltip {
    visibility: visible;
    height: 20px;
    width: 100px;
    color: black;
    font-size: 10px;
    background-color: #FFFFFF;
    text-align: center;
    padding: 5px 0;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    left: -40px;
    top:-230%;
    z-index: 1;
    border: 1px solid #F0F1F7;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
}
#RotateTooltip:before {
    content: '';
    display: block;
    position: absolute;
    left:41px;
    top: 100%;
    width: 0;
    height: 0;
    border-bottom-color: 1px solid #F0F1F7;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    transform: rotate(180deg);
}
#RotateTooltip:after {
    content: '';
    display: block;
    position: absolute;
    left: 42px;
    top: 97%;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-bottom-color: white;
    transform: rotate(180deg);
}

`;
