class MxDataArray extends Array {
  idKey = "ID";

  getItem = (id) => {
    if (this.indexes[id]) {
      return this.indexes[id];
    }

    return null;
  };

  getFieldValue = (id, field) => {
    if (this.indexes[id]) {
      try {
        return this.indexes[id][field];
      } catch (e) {
        console.error(e, id, this.idKey, field, this.indexes);
      }
    }

    console.warn("MxDataArray: item not found", id, this.idKey, field, this.indexes);
    return null;
  };

  setIndex = (item) => {
    if (!item || this.indexes[item[this.idKey]]) {
      return;
    } else if (this.indexes[item[this.idKey]]) {
      Object.assign(this.indexes[item[this.idKey]], item);
    } else {
      this.indexes[item[this.idKey]] = item;
      this.pushOld(item);
    }
  };

  pushThis = (item) => {
    this.setIndex(item);
  };

  pushArray = (arr) => {
    if (arr && arr.forEach) {
      arr.forEach((item) => {
        this.setIndex(item);
      });
    }
  };

  _indexes = {};
  get indexes() {
    return this._indexes;
  }

  convertToMxDriver = () => {
    if (this.push != this.pushThis) {
      this.pushOld = this.push;
      this.push = this.pushOld;
    }

    this._indexes = {};
    this.forEach((i) => {
      this._indexes[i[this.idKey]] = i;
    });
  };

  exists = (item) => {
    return this.indexes[item[this.idKey]];
  };

  clear = () => {
    this.data = [];
    this.length = 0;
    const keys = Object.keys(this._indexes);
    keys.forEach((key) => delete this._indexes[key]);
  };

  remove = (item) => {
    this.removeItemByID(item[this.idKey]);
  };

  removeItemByID = (id) => {
    const item = this.getItem(id);
    if (item) {
      this.splice(this.indexOf(item), 1);
      delete this._indexes[item[this.idKey]];
    }
  };

  static create(arr = [], idKey = "ID") {
    const newArr = new MxDataArray();
    newArr.idKey = idKey;

    newArr.pushOld = newArr.push;
    newArr.push = newArr.pushThis;

    if (Array.isArray(arr) && arr.length > 0) {
      newArr.pushArray(arr);
    }

    return newArr;
  }
}

export default MxDataArray;
