import Reports from "../data/Finance/Reports";
import LocalStorage from "./LocalStorage";
import {
  tableConfig,
  tableConfigTypes,
  tableObject,
} from "../components/tables/FinanceTable/components/constants";

const createExportingRowsData = (rows, headers, field, isTmpData, isCustomKpi) => {
  const allRows = [];
  const getRowObj = (row) => {
    let data = row.data;
    if (field === "Actual") {
      if (isTmpData) {
        data = row.views.tmpData;
      } else {
        data = row.views.data;
      }
    }

    return {
      ID: data.ID,
      header: data.DriverName,
      UnitType: data.UnitType,
      IsTemporary: false,
      Ref_Table: row.Ref_Table,
      isExpense: row.isExpense,
      Values: headers.map((h) => {
        const date = data.getItemByDateSufix(h.sufix);
        return Object.assign({
          Value: date[field],
          IsEditable: date.db_record && !date.evalFormulaActual,
        });
      }),
      children: [],
    };
  };

  const setRowsObj = (row) => {
    let objRow = getRowObj(row);
    if (row.children && row.children.length > 0) {
      if (!isCustomKpi) {
        objRow.children = row.children.map((c) => setRowsObj(c));
      } else {
        objRow.children = [];
      }
    }
    return objRow;
  };

  rows.forEach((row) => allRows.push(setRowsObj(row)));

  return allRows;
};

const ReportExport = {
  getUrl: () => {
    let account_id = global.Modeliks.Account.ID;
    let company_id = global.Modeliks.CompanyInfo.ID;
    return `export-report?account_id=${account_id}&company_id=${company_id}`;
  },
  excelSingleReport: (
    rows,
    title,
    headers,
    field = "Value",
    isTmpData = false,
    isComparative = false,
    isCustomKpi = false,
  ) => {
    const data = createExportingRowsData(rows, headers, field, isTmpData, isCustomKpi);
    let type = global.Modeliks.DashboardSettings.Value.financialDates.numberFormatType;
    const fieldType = field !== "Value";
    if (!fieldType) {
      type = LocalStorage.get(tableObject, tableConfig, tableConfigTypes.unitDisplayReportFormat);
    }
    let displayDecimals = LocalStorage.get(
      tableObject,
      tableConfig,
      !fieldType ? tableConfigTypes.displayOfDecimalsReport : tableConfigTypes.displayOfDecimals,
    );

    global.Modeliks.post(
      ReportExport.getUrl(),
      {
        data,
        periods: headers,
        reportType: title,
        isActual: field !== "Value",
        currency: global.Modeliks.CompanyInfo.Currency.value,
        EditableFields: false,
        isComparative,
        CompanyName: global.Modeliks.CompanyInfo.CompanyName,
        ScenarioName: global.Modeliks.CompanyScenarioInfo.ScenarioName,
        NumberFormat: type,
        DecimalsNum: displayDecimals,
      },
      (path, error) => {
        if (path) {
          const link = document.createElement("a");
          document.body.appendChild(link);
          link.href = `/api/get_report?path=${path}&reportType=${encodeURIComponent(title)}`;
          link.click();
          link.remove();
        }
      },
    );
  },
  AllReports: (
    headers,
    field = "Value",
    isTmpData = false,
    FinancialStatementsData = [],
    isComparative = false,
  ) => {
    let data = [];
    let type = global.Modeliks.DashboardSettings.Value.financialDates.numberFormatType;
    const fieldType = field !== "Value";
    if (!fieldType) {
      type = LocalStorage.get(tableObject, tableConfig, tableConfigTypes.unitDisplayReportFormat);
    }
    let displayDecimals = LocalStorage.get(
      tableObject,
      tableConfig,
      !fieldType ? tableConfigTypes.displayOfDecimalsReport : tableConfigTypes.displayOfDecimals,
    );

    if (field === "Value") {
      data = [
        createExportingRowsData(Reports.getProfitLossDataGrid(), headers, field),
        createExportingRowsData(Reports.getBalanceSheetDataGrid(), headers, field),
        createExportingRowsData(Reports.getCashFlowDataGrid(), headers, field),
      ];
    } else {
      data = FinancialStatementsData.map((d) =>
        createExportingRowsData(d, headers, field, isTmpData),
      );
    }

    const reportsTitles = ["Profit & Loss", "Balance Sheet", "Cash Flow"];
    global.Modeliks.post(
      ReportExport.getUrl(),
      {
        data,
        reportsTitles,
        periods: headers,
        isActual: field !== "Value",
        currency: global.Modeliks.CompanyInfo.Currency.value,
        isAllReports: true,
        isComparative,
        EditableFields: false,
        CompanyName: global.Modeliks.CompanyInfo.CompanyName,
        ScenarioName: global.Modeliks.CompanyScenarioInfo.ScenarioName,
        NumberFormat: type,
        DecimalsNum: displayDecimals,
      },
      (path, error) => {
        if (path) {
          const link = document.createElement("a");
          document.body.appendChild(link);
          link.href = `/api/get_report?path=${path}&reportType=${encodeURIComponent(`Export All Reports - ${new Date().toLocaleDateString()}`)}`;
          link.click();
          link.remove();
        }
      },
    );
  },
};

export default ReportExport;
