import React, { Component } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import ButtonMui from "../../../../components/buttons/buttonMui/buttonMui";
import { IconPlus } from "../../../../components/icons/svgIcons";
import {
  DeleteIcon,
  EditIcon,
  MoveIcon,
  Tick,
} from "../../../../SlideEditor/components/components/menus/LeftMenu/components/Icons/LeftMenuIcons";
import { Stage } from "../index";
import MxIDUseID from "../../../../helpers/MxIUniqueD";
import { Exit } from "../../../../SlideEditor/components/components/IconButtons/SubMenuIcons";
import { SketchPicker } from "react-color";
import { Menu, MenuItem } from "@szhsin/react-menu";
import { array_move } from "../../../../helpers/utils/array";

let draggingEle;
let placeholder;
let isDraggingStarted = false;
let x = 0;
let y = 0;

function a11yProps(index) {
  return {
    id: `vertical-row-${index}`,
    iconPosition: "top",
    "aria-controls": `vertical-rowpanel-${index}`,
  };
}
class EditStagesDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.Stages = JSON.parse(JSON.stringify(props.Stages));
  }

  addStage = () => {
    if (this.Stages.length < 10) {
      this.Stages.push(new Stage("Custom Stage", 10, "#f5f5f5", MxIDUseID()));
      this.forceUpdate();
    }
  };

  mouseDownHandler = (e, id) => {
    // Attach the listeners to `document`
    this.mouseDown = true;
    document.addEventListener("mouseup", this.mouseUpHandler);
    document.addEventListener("mousemove", this.mouseMoveHandler);

    draggingEle = document.getElementById("vertical-row-" + id);
    this.draggingIndex = Number.parseInt(
      draggingEle.getAttribute("aria-controls").replace("vertical-rowpanel-", ""),
    );

    // Calculate the mouse position
    const parentRect = draggingEle.parentElement.getBoundingClientRect();
    const rect = draggingEle.getBoundingClientRect();
    x = e.pageX;
    y = parentRect.top + (e.pageY - rect.top);
  };

  mouseMoveHandler = (e) => {
    if (!this.mouseDown) {
      this.mouseUpHandler();
      document.removeEventListener("mousemove", this.mouseMoveHandler);
      document.removeEventListener("mouseup", this.mouseUpHandler);
      return;
    }
    const draggingRect = draggingEle.getBoundingClientRect();

    if (!isDraggingStarted) {
      // Update the flag
      isDraggingStarted = true;

      // Let the placeholder take the height of dragging element
      // So the next element won't move up
      placeholder = document.createElement("tr");
      placeholder.classList.add("placeholder");
      draggingEle.parentNode.insertBefore(placeholder, draggingEle.nextSibling);

      // Set the placeholder's height
      placeholder.style.height = `${draggingRect.height}px`;
    }

    // Set position for dragging element
    draggingEle.style.position = "absolute";
    draggingEle.style.top = `${e.pageY - y}px`;
    draggingEle.style.left = `${e.pageX - x}px`;

    const prevEle = draggingEle.previousElementSibling;
    const nextEle = placeholder.nextElementSibling;

    if (prevEle && this.isAbove(draggingEle, prevEle)) {
      // The current order    -> The new order
      // prevEle              -> placeholder
      // draggingEle          -> draggingEle
      // placeholder          -> prevEle
      this.swap(placeholder, draggingEle);
      this.swap(placeholder, prevEle);
      return;
    }

    if (nextEle && this.isAbove(nextEle, draggingEle)) {
      // The current order    -> The new order
      // draggingEle          -> nextEle
      // placeholder          -> placeholder
      // nextEle              -> draggingEle
      this.swap(nextEle, placeholder, true);
      this.swap(nextEle, draggingEle, true);
    }
  };

  swap = (nodeA, nodeB, isDownwards) => {
    const parentA = nodeA.parentNode;
    const siblingA = nodeA.nextSibling === nodeB ? nodeA : nodeA.nextSibling;

    let elem = nodeB;
    if (isDownwards) {
      elem = nodeA;
    }

    if (elem) {
      if (elem.hasAttribute("aria-controls")) {
        let val = Number.parseInt(
          elem.getAttribute("aria-controls").replace("vertical-rowpanel-", ""),
        );
        if (Number.isInteger(val) && val !== this.draggingIndex) {
          this.droppingIndex = val;
        }
      }
    }

    // Move `nodeA` to before the `nodeB`
    nodeB.parentNode.insertBefore(nodeA, nodeB);

    // Move `nodeB` to before the sibling of `nodeA`
    parentA.insertBefore(nodeB, siblingA);
  };

  mouseUpHandler = () => {
    this.mouseDown = false;

    document.removeEventListener("mousemove", this.mouseMoveHandler);
    document.removeEventListener("mouseup", this.mouseUpHandler);
    // Remove the placeholder
    placeholder && placeholder.parentNode.removeChild(placeholder);
    // Reset the flag
    isDraggingStarted = false;
    // Remove the position styles
    draggingEle.style.removeProperty("top");
    draggingEle.style.removeProperty("left");
    draggingEle.style.removeProperty("position");

    x = null;
    y = null;

    // Remove the handlers of `mousemove` and `mouseup`
    if (this.draggingIndex !== null && this.droppingIndex !== null) {
      array_move(this.Stages, this.draggingIndex, this.droppingIndex);
      this.Stages.forEach((stage, index) => (stage.Order = index));
    }

    this.draggingIndex = null;
    this.droppingIndex = null;

    draggingEle = null;
  };

  isAbove = (nodeA, nodeB) => {
    // Get the bounding rectangle of nodes
    const rectA = nodeA.getBoundingClientRect();
    const rectB = nodeB.getBoundingClientRect();

    return rectA.top + rectA.height / 2 < rectB.top + rectB.height / 2;
  };

  saveChanges = () => {
    this.props.saveStages(this.Stages);
  };
  sortByOrder = (a, b) => {
    return a.Order - b.Order;
  };

  deleteStage(stage) {
    const stageIndex = this.Stages.indexOf(stage);
    if (stageIndex > -1) {
      this.Stages.splice(stageIndex, 1);
      this.forceUpdate();
    }
  }

  toggleEditMode = (stage) => {
    const stageIndex = this.Stages.indexOf(stage);
    if (stageIndex > -1) {
      this.setState({ editingIndex: stageIndex, oldName: stage.Name });
    } else {
      this.setState({ editingIndex: null });
    }
  };

  changeName = (stage, event) => {
    stage.Name = event.target.value;
    this.forceUpdate();
  };

  cancelEditName = (stage) => {
    stage.Name = this.state.oldName;
    this.setState({
      editingIndex: null,
      oldName: null,
    });
  };

  handleColorChange = (stage, color) => {
    stage.Color = color;
    this.forceUpdate();
  };

  onColorMenuChange = (stage, event) => {
    if (event.open) {
      this.setState({ oldColor: stage.Color });
    } else {
      if (this.state.oldColor) {
        stage.Color = this.state.oldColor;
        this.setState({ oldColor: null });
      }
    }
  };

  render() {
    const Stages = this.Stages.sort(this.sortByOrder);

    return (
      <Dialog className={"edit_stages_dialog"} onClose={this.props.onClose} open={true}>
        <DialogTitle>
          Pipeline{" "}
          <ButtonMui
            onClick={this.addStage}
            disabled={this.Stages.length >= 10}
            label={"Add Stage"}
            endIcon={<IconPlus />}
            variant={"contained"}
          />
        </DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableCell>Pipeline Stage</TableCell>
              <TableCell>Color Code</TableCell>
            </TableHead>
            <TableBody>
              {Stages.map((c, index) => (
                <TableRow {...a11yProps(index)}>
                  <TableCell>
                    <div className={"flex"}>
                      {this.state.editingIndex === index ? (
                        <>
                          <input
                            className={"name_holder"}
                            value={c.Name}
                            onChange={(e) => this.changeName(c, e)}
                          />
                          <ButtonMui
                            color={"black"}
                            width={36}
                            label={<Tick />}
                            variant={"text"}
                            onClick={() => this.toggleEditMode(null)}
                            isIconButton={true}
                          />
                          <ButtonMui
                            width={36}
                            label={<Exit />}
                            color={"red"}
                            variant={"text"}
                            onClick={() => this.cancelEditName(c)}
                            isIconButton={true}
                          />
                        </>
                      ) : (
                        <>
                          <span className={"name_holder"}>{c.Name}</span>
                          <ButtonMui
                            color={"black"}
                            width={36}
                            label={<EditIcon />}
                            variant={"text"}
                            onClick={() => this.toggleEditMode(c, index)}
                            isIconButton={true}
                          />
                          <ButtonMui
                            color={"black"}
                            width={36}
                            label={<MoveIcon />}
                            onMouseDown={(e) => this.mouseDownHandler(e, index)}
                            variant={"text"}
                            isIconButton={true}
                          />
                          {c.Deletable ? (
                            <ButtonMui
                              color={"black"}
                              width={36}
                              onClick={() => this.deleteStage(c, index)}
                              disabled={!c.Deletable}
                              label={<DeleteIcon />}
                              variant={"text"}
                              isIconButton={true}
                            />
                          ) : (
                            <div className="blank_btn" />
                          )}
                        </>
                      )}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className={"flex"}>
                      <div className={"color_box"} style={{ backgroundColor: c.Color }}></div>
                      <Menu
                        className={"color_menu"}
                        onMenuChange={(e) => this.onColorMenuChange(c, e)}
                        menuButton={({ open }) => (
                          <ButtonMui
                            width={36}
                            color={"black"}
                            label={<FillIcon />}
                            variant={"text"}
                            isIconButton={true}
                          />
                        )}
                      >
                        <SketchPicker
                          disableAlpha
                          color={c.Color}
                          onChange={(event) => this.handleColorChange(c, event.hex)}
                        />
                        <div className={"flex end"}>
                          <MenuItem onClick={() => this.setState({ oldColor: null })}>
                            <Tick />
                          </MenuItem>
                          <MenuItem onClick={() => this.onColorMenuChange(c, {})}>
                            <Exit />
                          </MenuItem>
                        </div>
                      </Menu>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div className={"popup_headers_row"}></div>
        </DialogContent>
        <div className="dialog_actions">
          <ButtonMui
            label={"Cancel"}
            onClick={this.props.onClose}
            color={"red"}
            variant={"contained"}
            width={160}
          />
          <ButtonMui label={"Save"} onClick={this.saveChanges} variant={"contained"} width={160} />
        </div>
      </Dialog>
    );
  }
}

const FillIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.4935 8.9596C12.4242 9.00858 10.987 10.5538 10.987 11.6382C10.987 12.7816 11.7018 13.5117 12.4935 13.5515C13.1759 13.5852 14 12.8696 14 11.6382C14 10.4902 12.5628 9.00858 12.4935 8.9596ZM4.64909 13.5515C4.93382 13.8408 5.31195 14 5.71419 14C6.11643 14 6.49457 13.8408 6.7793 13.5515L12.0521 8.19428L11.5195 7.6532L5.71419 1.75488L3.98698 0L2.92188 1.08216L4.64909 2.83704L0.441407 7.11212C0.156677 7.40141 0 7.7856 0 8.19428C0 8.60296 0.156677 8.98715 0.441407 9.27644L4.64909 13.5515ZM5.71419 3.9192L9.92188 8.19428H1.50651L5.71419 3.9192Z"
      fill="#585858"
    />
  </svg>
);

export default EditStagesDialog;
