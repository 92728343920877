export const Theme = {
  colors: {
    primary: {
      background: `#FBFBFB`,
      green: {
        25: `#ADF6F6`,
        50: `#00F3EF`,
        100: `#00E6E2`,
        150: `#00D9D6`,
        300: `#00B3B0`,
        500: `#00807E`,
        600: `#006160`,
      },
      blue: {
        50: `#1796FF`,
        150: `#0088F7`,
        300: `#006FC9`,
        500: `#004D8C`,
        600: `#003968`,
      },
      lightblue: {
        50: `#E1F2F6`,
        150: `#80D7FE`,
        300: `#01AFFD`,
      },
      lightgrey: {
        50: `#FAFBFE`,
        150: `#F0F1F7`,
        300: `#E9EAF0`,
        500: `#D4D5DE`,
        600: `#E5E5EB`,
      },
      darkgrey: {
        50: `#979797`,
        150: `#585858`,
        300: `#3A3A3A`,
        500: `#252525`,
      },
    },
    secondary: {
      purple: {
        50: `#C37FD4`,
        100: `#BC6FCF`,
        150: `#B560CA`,
        300: `#9A3CB1`,
        500: `#652774`,
        600: `#471B52`,
      },
      lightblue: {
        50: `#E1EEF4`,
        100: `#D7E8F1`,
        150: `#CEE3ED`,
        300: `#B2D3E3`,
        500: `#8CBED6`,
        600: `#75B1CE`,
      },
    },
    greyscale: {
      white: `#FFFFFF`,
      50: `#F7F7F8`,
      100: `#F0F1F2`,
      150: `#E9EBEC`,
      300: `#D4D8DB`,
      500: `#B9BFC3`,
      600: `#A9B0B5`,
      800: `#889299`,
      black: "#000000",
    },
    accent: {
      red: {
        50: `#FBE8E8`,
        100: `#EEC2C2`,
        150: `#EA0000`,
        300: `#FF3D3D`,
        500: `#FF7A7A`,
        600: `#BC0000`,
      },
      green: {
        25: `#F0FCED`,
        50: `#C6EEBE`,
        150: `#66D151`,
        300: `#91DE81`,
        500: `#B0E7A6`,
        600: `#43AD2E`,
      },
    },
    monochromatic: {
      red: {
        50: `#FFE5E8`,
        100: `#FFCCD0`,
        200: `#FF99A1`,
        300: `#FF6673`,
        400: `#FF3344`,
        500: `#FF0015`,
      },
      orange: {
        50: `#FFF6E5`,
        100: `#FFEDCC`,
        200: `#FFDB99`,
        300: `#FFC966`,
        400: `#FFB833`,
        500: `#EB9B09`,
      },
      yellow: {
        50: `#FFFFE5`,
        100: `#FFFECC`,
        200: `#FFFD99`,
        300: `#FFFC66`,
        400: `#FFFC33`,
        500: `#E3E00D`,
      },
      light_green: {
        50: `#F1F9EC`,
        100: `#E3F3D8`,
        200: `#C7E7B1`,
        300: `#AADB8A`,
        400: `#8ECF63`,
        500: `#66BE2A`,
      },
      green: {
        50: `#E5FFF3`,
        100: `#CCFFE7`,
        200: `#99FFCF`,
        300: `#66FFB8`,
        400: `#1DEE8D`,
        500: `#1BD57E`,
      },
      light_blue: {
        50: `#E5F9FF`,
        100: `#CCF2FF`,
        200: `#99E6FF`,
        300: `#66D9FF`,
        400: `#33CCFF`,
        500: `#00B0EB`,
      },
      blue: {
        50: `#E5F5FF`,
        100: `#CCEBFF`,
        200: `#99D8FF`,
        300: `#66C4FF`,
        400: `#33B1FF`,
        500: `#0073BA`,
      },
      dark_blue: {
        50: `#C8DBFB`,
        100: `#A4BFEE`,
        200: `#7AA5F0`,
        300: `#3C83FD`,
        400: `#145AD1`,
        500: `#073177`,
      },
      purple: {
        50: `#F4ECF9`,
        100: `#E8D9F2`,
        200: `#D2B2E6`,
        300: `#BB8CD9`,
        400: `#A566CC`,
        500: `#73339A`,
      },
      violet: {
        50: `#E7E5FF`,
        100: `#D3CCFF`,
        200: `#B6ABFB`,
        300: `#957AFF`,
        400: `#874FFE`,
        500: `#6D18ED`,
      },
    },
    chart: {
      BelizeHole: {
        100: "#d4e6f1",
        200: "#a9cce3",
        300: "#7fb3d5",
        400: "#5499c7",
        500: "#2980b9",
        700: "#1f618d",
        900: "#154360",
      },
      PeterRiver: {
        100: "#d6eaf8",
        200: "#aed6f1",
        300: "#85c1e9",
        400: "#5dade2",
        500: "#3498db",
        700: "#2874a6",
        900: "#1b4f72",
      },
      Turquoise: {
        100: "#d1f2eb",
        200: "#a3e4d7",
        300: "#76d7c4",
        400: "#48c9b0",
        500: "#1abc9c",
        700: "#148f77",
        900: "#0e6251",
      },
      Emerald: {
        100: "#d5f5e3",
        200: "#abebc6",
        300: "#82e0aa",
        400: "#58d68d",
        500: "#2ecc71",
        700: "#239b56",
        900: "#186a3b",
      },
      Sunflower: {
        100: "#fcf3cf",
        200: "#f9e79f",
        300: "#f7dc6f",
        400: "#f4d03f",
        500: "#f1c40f",
        700: "#b7950b",
        900: "#7d6608",
      },
      Orange: {
        100: "#fdebd0",
        200: "#fad7a0",
        300: "#f8c471",
        400: "#f5b041",
        500: "#f39c12",
        700: "#b9770e",
        900: "#7e5109",
      },
      Alizarin: {
        100: "#fadbd8",
        200: "#f5b7b1",
        300: "#f1948a",
        400: "#ec7063",
        500: "#e74c3c",
        700: "#b03a2e",
        900: "#78281f",
      },
      Wisteria: {
        100: "#e8daef",
        200: "#d2b4de",
        300: "#bb8fce",
        400: "#a569bd",
        500: "#8e44ad",
        700: "#6c3483",
        900: "#4a235a",
      },
      Silver: {
        100: "#f2f3f4",
        200: "#e5e7e9",
        300: "#d7dbdd",
        400: "#cacfd2",
        500: "#bdc3c7",
        700: "#909497",
        900: "#626567",
      },
      Asbestos: {
        100: "#e5e8e8",
        200: "#ccd1d1",
        300: "#b2babb",
        400: "#99a3a4",
        500: "#7f8c8d",
        700: "#616a6b",
        900: "#424949",
      },
      WetAsphalt: {
        100: "#d6dbdf",
        200: "#aeb6bf",
        300: "#85929e",
        400: "#5d6d7e",
        500: "#34495e",
        700: "#283747",
        900: "#1b2631",
      },
    },
  },
  effect: {
    innerShadow: {
      background: `#C4C4C4`,
      boxShadow: `inset 0px 0px 6px rgba(0, 0, 0, 0.2)`,
    },
    elevation: {
      background: "#C4C4C4",
      boxShadow: `0px 2px 4px rgba(0, 0, 0, 0.1)`,
    },
    elevation2: {
      background: "#C4C4C4",
      boxShadow: `0px 5px 20px rgba(0, 0, 0, 0.06)`,
    },
  },
  text: {
    heading: {
      h1: {
        fontStyle: `normal`,
        fontWeight: `400`,
        fontSize: `48px`,
        lineHeight: `140%`,
      },
      h1_strong: {
        fontStyle: `normal`,
        fontWeight: `700`,
        fontSize: `48px`,
        lineHeight: `140%`,
      },
      h2: {
        fontStyle: `normal`,
        fontWeight: `400`,
        fontSize: `40px`,
        lineHeight: `140%`,
      },
      h2_strong: {
        fontStyle: `normal`,
        fontWeight: `700`,
        fontSize: `40px`,
        lineHeight: `140%`,
      },
      h3: {
        fontStyle: `normal`,
        fontWeight: `400`,
        fontSize: `32px`,
        lineHeight: `140%`,
      },
      h3_strong: {
        fontStyle: `normal`,
        fontWeight: `700`,
        fontSize: `32px`,
        lineHeight: `140%`,
      },
      h4: {
        fontStyle: `normal`,
        fontWeight: `400`,
        fontSize: `24px`,
        lineHeight: `140%`,
      },
      h4_strong: {
        fontStyle: `normal`,
        fontWeight: `700`,
        fontSize: `24px`,
        lineHeight: `140%`,
      },
    },
    paragraph: {
      p1: {
        fontStyle: `normal`,
        fontWeight: `400`,
        fontSize: `20px`,
        lineHeight: `160%`,
      },
      p1_strong: {
        fontStyle: `normal`,
        fontWeight: `700`,
        fontSize: `20px`,
        lineHeight: `160%`,
      },
      p2: {
        fontStyle: `normal`,
        fontWeight: `400`,
        fontSize: `16px`,
        lineHeight: `160%`,
      },
      p2_strong: {
        fontStyle: `normal`,
        fontWeight: `700`,
        fontSize: `16px`,
        lineHeight: `160%`,
      },
      p3: {
        fontStyle: `normal`,
        fontWeight: `400`,
        fontSize: `14px`,
        lineHeight: `160%`,
      },
      p3_strong: {
        fontStyle: `normal`,
        fontWeight: `700`,
        fontSize: `14px`,
        lineHeight: `160%`,
      },
      p4: {
        fontStyle: `normal`,
        fontWeight: `400`,
        fontSize: `12px`,
        lineHeight: `160%`,
      },
      p4_strong: {
        fontStyle: `normal`,
        fontWeight: `700`,
        fontSize: `12px`,
        lineHeight: `160%`,
      },
      p5: {
        fontStyle: `normal`,
        fontWeight: `400`,
        fontSize: `10px`,
        lineHeight: `160%`,
      },
      p5_strong: {
        fontStyle: `normal`,
        fontWeight: `700`,
        fontSize: `10px`,
        lineHeight: `160%`,
      },
      p6: {
        fontStyle: `normal`,
        fontWeight: `400`,
        fontSize: `8px`,
        lineHeight: `120%`,
      },
    },
    label: {
      label1: {
        fontStyle: `normal`,
        fontWeight: `600`,
        fontSize: `20px`,
        lineHeight: `150%`,
      },
      label2: {
        fontStyle: `normal`,
        fontWeight: `600`,
        fontSize: `14px`,
        lineHeight: `150%`,
      },
      label3: {
        fontStyle: `normal`,
        fontWeight: `600`,
        fontSize: `12px`,
        lineHeight: `100%`,
      },
      label4: {
        fontStyle: `normal`,
        fontWeight: `600`,
        fontSize: `10px`,
        lineHeight: `100%`,
      },
      label_strong: {
        fontStyle: `normal`,
        fontWeight: `700`,
        fontSize: `10px`,
        lineHeight: `100%`,
      },
    },
  },
  button: {
    large: {
      // width: `200px`,
      height: `36px`,
    },
    small: {
      width: `136px`,
      height: `32px`,
    },
  },
};
