import React from "react";
import "./lineObject.scss";
import HeaderLineEditor from "../SlideHeader/HeaderLineEditor";
import { RGBAToHexA } from "./ShapeObject";
import SizeAndRotation from "../components/RightMenuComponents/SizeAndRotationComponent";
import RightMenuPortal from "../components/Portals/RightMenuPortal";
import Position from "../components/RightMenuComponents/PositionComponent";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import { IconButton } from "@mui/material";
import { Exit } from "../components/IconButtons/SubMenuIcons";

export default class NewLineObject extends React.Component {
  constructor(props) {
    super(props);

    this.rightMenuFunc = {};

    this.state = {
      style: props.style
        ? {
            ...props.style,
            markerEnd: props.style.markerEnd ? props.style.markerEnd : null,
            markerStart: props.style.markerStart ? props.style.markerStart : null,
          }
        : {},
    };

    global.pasteListener = this.handlePaste;
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (nextProps.multipleSeleced != this.props.multipleSeleced) {
      if (nextProps.multipleSeleced) {
        global.pasteListener = this.handlePaste;
      }
    }

    if (nextProps.selected != this.props.selected) {
      if (nextProps.selected) {
        global.pasteListener = this.handlePaste;
      }
    }

    return true;
  }

  handlePaste = (event) => {
    event.preventDefault();
    this.props.slideComponentPaste(event, true);
  };

  handleColorChange = (event) => {
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          style: {
            ...prevState.style,
            borderColor: event.target.value,
          },
        };
      },
      () => this.props.onPropsChange({ style: this.state.style }),
    );
  };

  handleLineType = (event) => {
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          style: {
            ...prevState.style,
            borderTop: event.target.value,
          },
        };
      },
      () => this.props.onPropsChange({ style: this.state.style }),
    );
  };
  handleMinusLineWidth = () => {
    if (Number(this.state.style.borderWidth.slice(0, -2)) > 0) {
      this.setState(
        (prevState) => {
          return {
            ...prevState,
            style: {
              ...prevState.style,
              borderWidth: Number(prevState.style.borderWidth.slice(0, -2)) - 1 + "px",
            },
          };
        },
        () => this.props.onPropsChange({ style: this.state.style }),
      );
    }
  };
  handlePlusLineWidth = () => {
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          style: {
            ...prevState.style,
            borderWidth: Number(prevState.style.borderWidth.slice(0, -2)) + 1 + "px",
            height: Number(prevState.style.height.slice(0, -2)) + "px",
          },
        };
      },
      () => this.props.onPropsChange({ style: this.state.style }),
    );
  };

  onStyleChange = (newStyle) => {
    let curStyle = { ...this.state.style, ...newStyle };
    this.setState({ style: curStyle }, () => this.props.onPropsChange({ style: curStyle }));
  };

  render() {
    this.height = 30;
    return (
      <>
        {this.props.selected && !this.props.multipleSelected && (
          <HeaderLineEditor
            visible={true}
            handleColorChange={this.handleColorChange}
            handleLineType={this.handleLineType}
            onStyleChange={this.onStyleChange}
            handleMinusLineWidth={this.handleMinusLineWidth}
            handlePlusLineWidth={this.handlePlusLineWidth}
            slideObjKey={this.props.slideObjKey}
            style={this.state.style}
            toggleFormatOptions={this.props.toggleFormatOptions}
            onPreview={this.props.onPreview}
          />
        )}

        {this.props.formatOptions && this.props.selected && (
          <RightMenuPortal>
            <div className={"right_menu_mask"} onMouseDown={(e) => e.stopPropagation()}>
              <List
                style={{
                  paddingLeft: "0",
                  position: "absolute",
                  width: "100%",
                  height: "auto",
                  maxWidth: 250,
                  bgcolor: "background.paper",
                  paddingBottom: "0",
                  paddingTop: "0",
                  // overflow:'scroll',
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <span style={{ fontWeight: 700, fontSize: "16px", textAlign: "left" }}>
                        Format Options
                      </span>
                      <IconButton onClick={this.props.toggleFormatOptions}>
                        <Exit />
                      </IconButton>
                    </div>
                  </ListSubheader>
                }
              >
                <SizeAndRotation
                  changeLockedAspect={this.props.changeLockedAspect}
                  onChange={this.props.onUpdateSizeAndRotation}
                  rightMenuFunc={this.rightMenuFunc}
                  style={this.props.style}
                  width={this.props.width}
                  height={this.props.height}
                  rotateAngle={this.props.rotateAngle}
                  scaleX={this.props.scaleX}
                  scaleY={this.props.scaleY}
                />
                <Position
                  onChange={this.props.onUpdatePosition}
                  rightMenuFunc={this.rightMenuFunc}
                  style={this.props.style}
                  top={this.props.top}
                  left={this.props.left}
                />
              </List>
            </div>
          </RightMenuPortal>
        )}

        <div
          className={"line_object_container" + this.props.className}
          style={{
            position: "absolute",
            width: this.props.width,
            height: this.height,
            top: -this.height / 2,
            overflow: "visible",
            display: "flex",
            alignItems: "center",
          }}
          onContextMenu={this.props.onContextMenu}
        >
          <svg
            style={{ overflow: "visible" }}
            width={this.props.width}
            height={1}
            stroke={this.state.style.borderColor ? this.state.style.borderColor : "#000000"}
            fill={this.state.style.borderColor ? this.state.style.borderColor : "#000000"}
          >
            <defs>
              <marker
                fill={this.state.style.borderColor ? this.state.style.borderColor : "#000000"}
                id={this.props.slideObjKey + "arrowhead"}
                markerWidth="10"
                markerHeight="7"
                refX="10"
                refY="3.5"
                orient="auto-start-reverse"
              >
                >
                <polygon points="0 0, 10 3.5, 0 7" />
              </marker>

              <marker
                fill={this.state.style.borderColor ? this.state.style.borderColor : "#000000"}
                id={this.props.slideObjKey + "transparentArrowhead"}
                markerWidth="10"
                markerHeight="7"
                refX="0.5"
                refY="3.5"
                orient="auto-start-reverse"
              >
                >
                <polygon points="0 0, 10 3.5, 0 7" fill={"transparent"} />
              </marker>

              <marker
                fill={this.state.style.borderColor ? this.state.style.borderColor : "#000000"}
                id={this.props.slideObjKey + "circle"}
                markerWidth="8"
                markerHeight="8"
                refX="5"
                refY="5"
              >
                <circle cx="5" cy="5" r="2" />
              </marker>

              <marker
                fill={this.state.style.borderColor ? this.state.style.borderColor : "#000000"}
                id={this.props.slideObjKey + "transparentCircle"}
                markerWidth="8"
                markerHeight="8"
                refX="5"
                refY="5"
              >
                <circle cx="5" cy="5" r="2" fill={"transparent"} />
              </marker>

              <marker
                fill={this.state.style.borderColor ? this.state.style.borderColor : "#000000"}
                id={this.props.slideObjKey + "square"}
                markerWidth="7"
                markerHeight="7"
                refX="4"
                refY="4"
                orient="auto"
              >
                <rect x="1.25" y="1.25" width="5" height="5" />
              </marker>

              <marker
                fill={this.state.style.borderColor ? this.state.style.borderColor : "#000000"}
                id={this.props.slideObjKey + "transparentSquare"}
                markerWidth="7"
                markerHeight="7"
                refX="4"
                refY="4"
                orient="auto"
              >
                <rect x="1.25" y="1.25" width="5" height="5" fill={"transparent"} />
              </marker>

              <marker
                fill={this.state.style.borderColor ? this.state.style.borderColor : "#000000"}
                id={this.props.slideObjKey + "pointer"}
                markerWidth="10"
                markerHeight="8"
                refX="9.5"
                refY="5.1"
                orient="-45"
                markerUnits="userSpaceOnUse"
              >
                <polyline points="1 1, 9 5, 1 7" />
              </marker>
            </defs>
            <line
              x1="0"
              x2="100%"
              strokeLinecap="butt"
              fill="none"
              strokeWidth={this.state.style.borderWeight ? this.state.style.borderWeight : "3"}
              markerEnd={
                this.state.style.markerEnd
                  ? `url(#${this.props.slideObjKey}${this.state.style.markerEnd})`
                  : ""
              }
              markerStart={
                this.state.style.markerStart
                  ? `url(#${this.props.slideObjKey}${this.state.style.markerStart})`
                  : ""
              }
              strokeDasharray={this.state.style.strokeStyle ? this.state.style.strokeStyle : "0 0"}
            />
          </svg>
        </div>
      </>
    );
  }
}
