import { Command } from "../../models/Command";

export class AddCustomSection extends Command {
  _section = null;
  _isGlobal = true;
  constructor({ actionForward, actionBackward, index, name, callBack }) {
    super({ actionForward, actionBackward, index, name, callBack });
  }

  async execute() {
    const { actionForward, index, name, callBack } = this.args;
    const section = await actionForward(index, name, callBack);
    this._section = section;
  }

  async undo() {
    console.log("AddCustomSection undo command");
    if (!this._section) {
      throw new Error("No section provided");
    }
    const { actionBackward, index, callBack } = this.args;
    await actionBackward(this._section, index, callBack);
  }

  get isGlobal() {
    return this._isGlobal;
  }

  getInfo() {
    return "This is AddCustomSection command";
  }
}
