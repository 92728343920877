import CalculatedDriver from "../../CalculatedDriver/index";
import { DriverCategories, SpecialChar, UnitTypes } from "../../CalculatedDriver/constants";
import MxIDHelper from "../../../MxIDHelper";
import Financing from "../../Financing";
import { FinancingTypes } from "../constants";
import { PeriodTypes } from "../../../dates";
import CalculatedDriver_Values from "../../CalculatedDriver/CalculatedDriver_Values";

class Investments extends Financing {
  FinanceType = FinancingTypes.Investments;
  RetainedEarningsOpenningBalance;

  constructor(db_record) {
    super(db_record);
    if (this.db_record) {
      this.clean();
      this.setDriversFromDataStorageSub();
      // this.buildPeriodsData();
      // this.setFinance();
    }
    this.SaveRevenue = this.Save;
    this.Save = this.SaveFinancing;
  }

  clean = (cleanDrivers = false) => {
    if (this.db_record && this.Totals) {
      this.Name = this.db_record.Name;
      this.FinanceType = this.db_record.FinanceType;
      this.ID_CompanyScenario = this.db_record.ID_CompanyScenario;
      if (cleanDrivers) {
        this.cleanDrivers();
      }
    }
  };

  static DriversDesc = Object.assign({
    Total: {
      driverName: "$Name",
      fieldName: "Totals",
      driverID: "total",
      unit: UnitTypes.Price,
      category: DriverCategories.LastPeriod,
    },
    OpenningBalance: {
      driverName: `Opening Balance${SpecialChar.DriverNameESCChar}$Name`,
      fieldName: "OpenningBalance",
      driverID: "oppening_balance",
      unit: UnitTypes.Price,
      category: DriverCategories.FirstPeriod,
    },
    EquityInvestment: {
      driverName: `Equity Investment${SpecialChar.DriverNameESCChar}$Name`,
      fieldName: "EquityInvestment",
      driverID: "equity_investment",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    ClosingBalance: {
      driverName: `Closing Balance${SpecialChar.DriverNameESCChar}$Name`,
      fieldName: "ClosingBalance",
      driverID: "closing_balance",
      unit: UnitTypes.Price,
      category: DriverCategories.LastPeriod,
    },
  });

  Save = (callBack, saveDrivers = true) => {
    if (!this.isNew && !this.IsCreated) {
      global.Modeliks.put(this.constructor.TableName, null, this, (res) => {
        if (saveDrivers) {
          this.SaveDrivers(callBack);
        } else {
          callBack();
        }
      });
    } else {
      global.Modeliks.put(this.constructor.TableName, null, this, (res) => {
        if (saveDrivers) {
          this.SaveDrivers(callBack);
        } else {
          callBack();
        }
      });
    }
  };
  SaveFinancing = (callBack, saveDrivers = true) => {
    this.SaveRevenue((newID) => {
      if (saveDrivers) {
        this.SaveDrivers(callBack, this.ID);
      } else {
        callBack(this.ID);
      }
    }, false);
  };

  setDriversFromDataStorageSub = () => {
    // this.RetainedEarningsOpenningBalance = global.Modeliks.DriverValuesStore.getItem(this.getRetainedEarningsOpenningBalanceID());
  };
  // getRetainedEarningsOpenningBalanceID = () => {return `${this.ID}_retained_earning_openning_balance`};

  getMonthDatesAll = () => {
    return [...global.Modeliks.DateHelper.months];
  };

  getYearDatesAll = () => {
    return global.Modeliks.DateHelper.years_all;
  };
  periodsData = {};
  createExtraPeriods = () => {
    Object.values(this.constructor.DriversDesc).forEach((driver) => {
      this[driver.fieldName].addMonths(0, true);
    });

    this.buildPeriodsData();
  };

  buildPeriodsData = () => {
    const allPeriods = this.EquityInvestment.Values.map((c) => c.Date);
    allPeriods.forEach((period) => {
      this.periodsData[period.dateID] = {};
      Object.values(this.constructor.DriversDesc).forEach(
        (driver) =>
          (this.periodsData[period.dateID][driver.fieldName] = this[
            driver.fieldName
          ].getItemByDateSufix(period.sufix)),
      );

      this.periodsData[period.dateID].Date = period;
    });
  };
  setFinance = () => {
    this.buildPeriodsData();
    this.createMonthsFormulas();
    this.createYearsFormulas();
  };

  createMonthsFormulas = () => {
    const months = this.getMonthDatesAll();
    this.Totals.Formula = `${this.OpenningBalance}  + ${this.EquityInvestment}`;

    months.forEach((month) => {
      const prevMonthDate = months.find((c) => c.Order == month.Order - 1);
      const curMonth = this.periodsData[month.dateID];
      const prevMonth = prevMonthDate ? this.periodsData[prevMonthDate.dateID] : null;

      if (prevMonth) {
        curMonth.OpenningBalance.Formula = `${prevMonth.ClosingBalance}`;
      }

      curMonth.ClosingBalance.Formula = `${curMonth.OpenningBalance} + ${curMonth.EquityInvestment}`;
      curMonth.Totals.Formula = `${curMonth.ClosingBalance}`;
    });
  };

  createYearsFormulas = () => {
    const years = this.getYearDatesAll();

    years.forEach((year) => {
      const curYear = this.periodsData[year.dateID];
      const months = this.periodsData[year.dateID].Date.monthIndexes.map(
        (index) => this.periodsData[index],
      );
      const prevYearDate = years.find((c) => c.Order === year.Order - 1);
      const prevYear = prevYearDate ? this.periodsData[prevYearDate.dateID] : null;
      const lastMonth = months[months.length - 1];
      const firstMonth = months[0];

      if (!year.Active) {
        curYear.OpenningBalance.Formula = `${firstMonth.OpenningBalance}`;
        curYear.ClosingBalance.Formula = `${lastMonth.ClosingBalance}`;
        curYear.EquityInvestment.Formula = `MxMath.Sum([${months.map((c) => c.EquityInvestment.ID_f).join(",")}])`;
        curYear.Totals.Formula = `${lastMonth.ClosingBalance}`;
      } else {
        curYear.OpenningBalance.Formula = `${prevYear.ClosingBalance}`;
        curYear.ClosingBalance.Formula = `${curYear.OpenningBalance} + ${curYear.EquityInvestment}`;
        curYear.Totals.Formula = `${curYear.ClosingBalance}`;
      }
    });
  };

  static convert_Finance = (finance) => {
    const newFinancing = new Investments();
    newFinancing.ID = finance.ID;
    newFinancing.Name = finance.Name;
    newFinancing.IsCreated = true;
    newFinancing.ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
    newFinancing.Totals = CalculatedDriver.createDriverFromTable(
      newFinancing,
      Financing.DriversDesc.Total.driverID,
      UnitTypes.Price,
      DriverCategories.Sum,
    );
    // newFinancing.RetainedEarningsOpenningBalance = new CalculatedDriver_Values(null,  newFinancing.getRetainedEarningsOpenningBalanceID(), null,UnitTypes.Price);
    newFinancing.createDrivers();
    newFinancing.createExtraPeriods();

    return newFinancing;
  };
}

export default Investments;
