import React, { useState } from "react";
import styled from "styled-components";
import { Theme } from "../../../theme/styles/theme";
import MaterialSelect from "../../actions/SelectM";
import CheckBoxMaterial from "../../actions/CheckBoxMaterial";
import { NumberFormatTypes } from "../../constants/charts";
import { IconButton } from "@mui/material";
import { ArrowDown } from "../../icons/svgIcons";
import { ShowChartMenu } from "./ShowChartMenu";
import ShowHideTitle from "../../../pages/Secure/Financials/components/ShowHideTitle";

const StyledDiv = styled.div`
  display: flex;
  gap: 30px;
  justify-content: center;
  justify-content: space-between;
  align-items: center;
  font-family: Inter;
  height: 40px;
`;
const SyledP = styled.p`
  ${(props) => (props.strong ? Theme.text.paragraph.p4_strong : Theme.text.paragraph.p4)};
  font-family: Inter;
`;
const StyledLabel = styled.label`
  ${Theme.text.label.label3};
  font-family: Inter;
`;

const SyledContainer = styled.div`
  display: inline-flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
`;

export const ChartMenu = ({ ...props }) => {
  const [value, setValue] = useState(props.unitValue);

  const changeUnit = (e) => {
    setValue(e.target.value);
    props.setUnitValue(e.target.value);
  };

  if (!props.hideChart) {
    return <ShowHideTitle button={false} onChange={props.onChangeChartView} title={"Chart"} />;
  }

  return (
    <StyledDiv id={props.id}>
      <ShowHideTitle button={true} title={"Chart"} onChange={props.onChangeChartView} />
      {props.hideChart && (
        <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
          <CheckBoxMaterial
            label="Show Breakdown"
            size="medium"
            fontSize={"12px"}
            checked={props.brakedown}
            onChange={props.setBrakedown}
          />
          <CheckBoxMaterial
            label="Show Growth"
            size="medium"
            fontSize={"12px"}
            checked={props.growth}
            onChange={props.setGrowth}
          />
          {props.showMargin && (
            <CheckBoxMaterial
              label="Show Gross Margin"
              size="medium"
              fontSize={"12px"}
              checked={props.margin}
              onChange={props.setMargin}
            />
          )}

          {props.showNumberOfEmployees && (
            <CheckBoxMaterial
              label="Show # Of Employees"
              size="medium"
              fontSize={"12px"}
              checked={props.numberOfEmployees}
              onChange={props.setNumberOfEmployees}
            />
          )}

          <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
            <StyledLabel>Unit Display</StyledLabel>
            <MaterialSelect
              zIndex={1}
              width="150px"
              onChange={changeUnit}
              value={global.Modeliks.DashboardSettings.Value.financialDates.numberFormatType}
              options={Object.values(NumberFormatTypes).map((c) => {
                return {
                  description: `${c.name.length < 2 ? global.Modeliks.CompanyInfo.Currency.value : ""} ${c.name}`,
                  value: c.key,
                };
              })}
            />
          </div>
        </div>
      )}
    </StyledDiv>
  );
};
