import React, { Component } from "react";
import { Chart as ChartJS, LinearScale, PointElement, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import "./horizontalFloatingChart.scss";
ChartJS.register(LinearScale, PointElement, Tooltip, Legend);

const options = {
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      display: false,
      grid: {
        display: false,
      },
      beginAtZero: true,
    },
  },
  indexAxis: "y",
  layout: {
    // padding: 20,
    autoPadding: true,
  },
  responsive: true,
  plugins: {
    datalabels: {
      color: "#252525",
      font: {
        size: 14,
        weight: "700",
        lineHeight: "160%",
      },
    },
    legend: {
      position: "top",
      align: "end",
    },
  },
};

const data = {
  labels: ["1", "not", "2", "3", "4", "not2", "5", "6", "7"],
  datasets: [
    {
      label: "Red dataset",
      data: [
        [2.9, 16.7],
        null,
        [4.8, 9.5],
        [6.2, 12.1],
        [7.4, 10.8],
        null,
        [7.4, 13.3],
        [5.5, 8.5],
        [9.6, 12.3],
      ],
      backgroundColor: "#80D7FE",
      categoryPercentage: 1,
      barPercentage: 0.4,
      borderRadius: 5,
      borderSkipped: false,
      datalabels: {
        labels: {
          leftValue: {
            anchor: "start",
            align: "start",
            formatter: (value) => {
              if (value) {
                return value[0];
              }
              return null;
            },
          },
          rightValue: {
            anchor: "end",
            align: "end",
            formatter: (value) => {
              if (value) {
                return value[1];
              }
              return null;
            },
          },
        },
      },
    },
  ],
};

class HorizontalFloatingChart extends Component {
  constructor(props) {
    super(props);

    this.randomItemsArray = [];
    this.randomItemPerDataset();
  }

  randomItemPerDataset = () => {
    data.datasets[0].data.forEach((c) => {
      if (c) {
        this.randomItemsArray.push(Math.floor(Math.random() * (c[1] - 1 - c[0])) + c[0] + 1);
      } else {
        this.randomItemsArray.push(0);
      }
    });
  };

  bulletChart = {
    id: "bulletChart",
    afterDatasetDraw: (chart, args, options) => {
      const {
        ctx,
        chartArea: { top, bottom, left, right, width, height },
        scales: { x, y },
      } = chart;
      ctx.save();
      ctx.fillStyle = "#C37FD4";
      ctx.strokeStyle = "#C37FD4";
      if (chart.getDatasetMeta(0)) {
        if (chart.getDatasetMeta(0).data.length) {
          chart.getDatasetMeta(0).data.forEach((data, index) => {
            if (this.randomItemsArray[index] && data.height) {
              ctx.beginPath();
              ctx.roundRect(
                x.getPixelForValue(this.randomItemsArray[index]) - 22,
                y.getPixelForValue(index) - data.height / 2,
                44,
                data.height,
                5,
              );
              ctx.closePath();
              ctx.fill();

              ctx.beginPath();
              ctx.moveTo(
                x.getPixelForValue(this.randomItemsArray[index]),
                y.getPixelForValue(index) - data.height / 2 - 5,
              );
              ctx.lineTo(
                x.getPixelForValue(this.randomItemsArray[index]),
                y.getPixelForValue(index) + data.height / 2 + 5,
              );
              ctx.stroke();
            }
          });
        }
      }
    },
  };

  bulletChartText = {
    id: "bulletChartText",
    afterDatasetDraw: (chart, args, options) => {
      const {
        ctx,
        chartArea: { top, bottom, left, right, width, height },
        scales: { x, y },
      } = chart;
      ctx.save();
      ctx.fillStyle = "white";
      if (chart.getDatasetMeta(0)) {
        if (chart.getDatasetMeta(0).data.length) {
          chart.getDatasetMeta(0).data.forEach((data, index) => {
            if (this.randomItemsArray[index] && data.height) {
              ctx.font = "14px Inter";
              ctx.fillText(
                this.randomItemsArray[index],
                x.getPixelForValue(this.randomItemsArray[index]),
                y.getPixelForValue(index),
              );
              ctx.textAlign = "center";
            }
          });
        }
      }
    },
  };

  render() {
    return (
      <div className="hf_chart_container">
        <Bar options={options} data={data} plugins={[this.bulletChart, this.bulletChartText]} />
      </div>
    );
  }
}

export default HorizontalFloatingChart;
