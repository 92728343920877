import React, { Component } from "react";
import SlideComponentLight from "../../../../../../SlideEditor/components/SlideComponentLight/SlideComponentLight";
import CircleLoader from "../../../../../../components/Loaders/CircleLoader/CircleLoader";
import request from "superagent";

class RecentReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };

    this.getJsonData();
  }

  getJsonData = () => {
    const { Template } = this.props.report;
    let queryString = "/api/serveStatic/";
    if (Template.isCustom) {
      queryString += `ClientData/${global.Modeliks.Account.ID}/${global.Modeliks.CompanyInfo.ID}/mr_${this.props.report.ID}/mr_template${Template.ID}`;
    } else {
      queryString += `TemplatesData/template${Template.MasterTemplateID}`;
    }
    queryString += `.json?v=${Template.UpdatedAt}`;
    request.get(queryString).then((data) => {
      this.setState({ jsonData: JSON.stringify(data.body), loading: false });
    });
  };

  render() {
    if (this.state.loading) {
      return (
        <div className={"sc_light"}>
          <div className={"loader_cont"}>
            <CircleLoader />
          </div>
        </div>
      );
    }
    return (
      <>
        <SlideComponentLight
          PitchScenarioInfo={this.props.report}
          scale={{ enabled: false }}
          jsonData={this.state.jsonData}
        >
          {this.props.children}
        </SlideComponentLight>
        <div className={"rr_mask"} />
      </>
    );
  }
}

export default RecentReport;
