import CalculatedDriver from "../CalculatedDriver/index";
import { DriverCategories, UnitTypes } from "../CalculatedDriver/constants";
import datastructure from "../../datastructure.json";
import FinanceGeneric from "../FinanceGeneric";
import Personnel from "../Personnel";
import { ExpenseTypes, PersonnelFunctionTypes } from "../constants";
import Assets from "../Assets";
import { PeriodTypes } from "../../dates";
import { ReportsTypes } from "./constants";
import Financing from "../Financing";

class Reports extends FinanceGeneric {
  Name = "";
  ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;

  constructor(db_record) {
    super(db_record);
    if (this.db_record) {
      this.Name = this.db_record.Name;
    }
  }

  static DriversDesc = Object.assign({
    Total: {
      driverName: "$Name",
      fieldName: "Totals",
      driverID: "total",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
  });

  static TableName = datastructure.Finance_Reports.TableName;

  static getOperatingExpenses = () => {
    const driverID = "0-operating-expenses";
    let driver = global.Modeliks.DriversStore.getItem(
      Personnel.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Personnel.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.Sum,
        "Operating Expenses",
        true,
        false,
        false,
      );
      const drivers = global.Modeliks.PersonnelStore.filter(
        (c) => c.FunctionType !== PersonnelFunctionTypes.DirectLabor,
      ).concat(
        global.Modeliks.ExpensesStore.filter((c) => c.ExpenseType !== ExpenseTypes.DirectCost),
      );
      if (drivers && drivers.length > 0) {
        const totalDrivers = drivers.map((d) => d.Totals);
        driver.setFormula_Sum(totalDrivers);
      }
    }
    return driver;
  };

  static getDepreciation = () => {
    const driverID = "depreciation_r_driver";
    let driver = global.Modeliks.DriversStore.getItem(`${Assets.TableName}-${driverID}-totals`);
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Assets.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.Sum,
        "Depreciation",
        true,
        false,
        false,
      );
      driver.isExpense = true;
      driver.UseForCharts = false;
    }

    const drivers = global.Modeliks.DriversStore.filter(
      (asset) => asset.Ref_Field === Assets.DriversDesc.Depreciation.driverID,
    );
    if (drivers && drivers.length > 0) {
      driver.setFormula_Sum(drivers);
      driver.Formula = null;
    }

    return driver;
  };

  static getTotalLiabilitiesAndEquity = () => {
    const driverID = "total_liab_and_equity_t";
    let driver = global.Modeliks.DriversStore.getItem(`${Reports.TableName}-${driverID}-totals`);
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Reports.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.LastPeriod,
        "Total Liabilities and Equity",
        true,
        false,
        false,
      );
      driver.isExpense = true;
      const drivers = [Financing.getCurrentAndLongTermLiabilities(), Financing.getEquity()];
      driver.setFormula_Sum(drivers);
    }

    return driver;
  };

  static getNumberOfDaysPerMonthAndYear = () => {
    const driverID = "num_of_days_per_m_and_y";
    let driver = global.Modeliks.DriversStore.getItem(`${Reports.TableName}-${driverID}-value`);
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Reports.TableName,
        driverID,
        "value",
        UnitTypes.Units,
        DriverCategories.Sum,
        "Number of days",
        false,
        true,
        false,
      );
      driver.Values.forEach((item) => {
        if (item.PeriodType === PeriodTypes.month) {
          const monthValue = new Date(item.Year, item.Month + 1, 0).getDate();
          item.Value = monthValue;
          item.Actual = monthValue;
        } else {
          const yearValue =
            (item.Year % 4 == 0 && item.Year % 100 != 0) || item.Year % 400 == 0 ? 366 : 365;
          item.Value = yearValue;
          item.Actual = yearValue;
        }
      });
      driver.Save();
    }

    driver.IsExisting = true;
    driver.IsFormulaEditable = false;

    return driver;
  };

  static getNumberOneDriver = () => {
    const driverID = "number_one";
    let driver = global.Modeliks.DriversStore.getItem(`${Reports.TableName}-${driverID}-value`);
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Reports.TableName,
        driverID,
        "value",
        UnitTypes.Units,
        DriverCategories.Average,
        "Number 1",
        false,
        true,
        false,
      );
      driver.Values.forEach((item) => {
        item.Value = 1;
        item.Actual = 1;
      });
      driver.Save();
    }

    driver.IsExisting = true;
    driver.IsFormulaEditable = false;

    return driver;
  };

  changeDriversName = () => {
    Object.keys(this.constructor.DriversDesc).forEach((key) => {
      const driverDesc = this.constructor.DriversDesc[key];
      if (this[driverDesc.fieldName]) {
        this[driverDesc.fieldName].DriverName = driverDesc.driverName.replace("$Name", this.Name);
      } else {
        console.log("driver not found", this, driverDesc, this[driverDesc.fieldName]);
      }
    });
  };

  createDrivers = (recreate = false) => {
    Object.values(this.constructor.DriversDesc).forEach((driver) => {
      if (this.hasOwnProperty(driver.fieldName) == false || recreate) {
        this[driver.fieldName] = CalculatedDriver.createDriverFromTable(
          this,
          driver.driverID,
          driver.unit,
          driver.category,
          null,
          true,
        );
      }

      if (this[driver.fieldName]) {
        this[driver.fieldName].IsFormulaEditable = false;
        this[driver.fieldName].IsTemporary = true;
        if (driver.hasOwnProperty("isExpense")) {
          this[driver.fieldName].isExpense = driver.isExpense;
        }
        if (driver.hasOwnProperty("IsExisting")) {
          this[driver.fieldName].IsExisting = driver.IsExisting;
        }

        if (driver.hasOwnProperty("LastPeriodOnly")) {
          this[driver.fieldName].LastPeriodOnly = driver.LastPeriodOnly;
        }
      }
    });
  };

  get_export_report_data = (
    Type = {
      getGridData: true,
      isActual: false,
      data: [],
      isTmpData: true,
      isMultipleExport: false,
      EditableFields: false,
    },
    onlyValidDrivers = true,
    periods = [...global.Modeliks.DateHelper.months, ...global.Modeliks.DateHelper.years_all],
    defaultLevel = true,
    allowDeeperLevel = false,
    excelTitle = "",
  ) => {
    const { getGridData, isActual, data, isTmpData, isMultipleExport, EditableFields } = Type;

    const data_export = [];
    let realValue = "Value";
    let title = this.Name;
    let dataRow = [];

    if (Type.isActual) {
      realValue = "Actual";
      // dataRow = this.getReportTotals();
      dataRow = data;
    }

    if (!getGridData && !isActual) {
      realValue = "Actual";
      dataRow = data;
    }

    if (getGridData) {
      dataRow = data;
    }

    const createExportData = (childDriver, isParent = false, level = 0) => {
      if (!childDriver) {
        return;
      }
      let rowData = childDriver;
      if (childDriver.hasOwnProperty("data") && childDriver.data) {
        rowData = childDriver.data;
      } else if (childDriver.hasOwnProperty("views") && !childDriver.data) {
        if (isTmpData) {
          rowData = childDriver.views.tmpData;
        } else {
          rowData = childDriver.views.data;
        }
      }

      const dataRow = {
        ID: null,
        header: rowData.DriverName,
        UnitType: rowData.UnitType,
        IsTemporary: false,
        Values: periods.map((p) => {
          const period = rowData.getItemByDateSufix(p.sufix);
          const row = {};
          row.Value = period[realValue];
          if(rowData.DriverName === 'Direct Labor'){
            row.IsEditable = false;
          }else {
            row.IsEditable = period.db_record && !period.evalFormulaActual
          }
          return row;
        }),
        children: [],
      };
      //
      // const displayDriver = !rowData.IsSimple && isParent;
      // let displayChildrenDrivers = false;

      if (isActual && rowData.hasOwnProperty("ID")) {
        dataRow.ID = rowData.ID;
      }

      // if(!rowData.IsSimple && defaultLevel){
      //     displayChildrenDrivers =  true;
      // }else {
      //     if(!rowData.IsSimple && level <= 1){
      //         displayChildrenDrivers = false
      //     }
      //
      //     if(!rowData.IsSimple && (allowDeeperLevel && level < 2)){
      //         displayChildrenDrivers =  true;
      //     }
      //
      //     if(this.ReportType === ReportsTypes.BalanceSheet && level < 3){
      //         displayChildrenDrivers = true;
      //     }
      // }
      //
      // const displayOnlyParent = [
      //     'gain_or_loss_from_sale_of_ppe',
      //     'prepaid-change_in_sales_tax_payable-closing-balance',
      //     "asset-payable-balance-current-term",
      //     "asset-payable-balance-long-term"
      //     // "change_in_income_tax_payable" ,
      //     // "change_in_sales_tax_payable",
      //     // "change_in_vat_tax_payable"
      // ]
      //
      // const hideDriversByName = [
      //     'Shareholder Equity',
      //     'Long term asset purchases payable',
      //     'Equity Investments',
      //     'Other Long Term Liability'
      // ]

      let children = childDriver.hasOwnProperty("children") && childDriver.children;
      if (children && children.length > 0) {
        children.map((driver) => {
          if (driver.hasOwnProperty("data")) {
            if (!getGridData && !isActual) {
              if (isTmpData) {
                driver.data = driver.views.tmpData;
              } else {
                driver.data = driver.views.data;
              }
            }
            dataRow.children.push(createExportData(driver, false, level + 1));
          }
        });
      }
      // if(!isActual || EditableFields || (!isActual && !getGridData)){
      //     console.log('is herer', isActual,EditableFields,childDriver)
      //     let children = (childDriver.hasOwnProperty('children')) && childDriver.children;
      //     if(children && children.length > 0){
      //         children.map((driver) => {
      //             if(driver.hasOwnProperty('data')){
      //                 dataRow.children.push(createExportData(driver, false, level + 1))
      //             }
      //         })
      //     }
      // }else{
      //     console.log('not here')
      //     if((displayDriver || displayChildrenDrivers) && !(displayOnlyParent.includes(rowData.Ref_Field) || hideDriversByName.includes(rowData.DriverName))){
      //         let children = [];
      //
      //         if(!isActual){
      //             children = (childDriver.hasOwnProperty('children')) ? childDriver.children : childDriver.getChildDrivers();
      //             console.log('children',children, this,dataRow)
      //             if(children && children.length > 0){
      //                 children.map((driver) => {
      //                     if(driver.hasOwnProperty('data')){
      //                         dataRow.children.push(createExportData(driver.data, false, level + 1))
      //                     }else {
      //                         // if(driver.isValid){
      //                         //     dataRow.children.push(createExportData(driver, false, level + 1))
      //                         // }
      //                     }
      //                 })
      //             }
      //         }else {
      //             children = rowData.getChildDrivers()
      //             if(children && children.length > 0){
      //                 children.map((driver) => {
      //                     if(isActual && driver.isValidActual){
      //                         dataRow.children.push(createExportData(driver, false, level + 1))
      //                     }
      //                 })
      //
      //             }
      //         }
      //     }
      // }

      return dataRow;
    };

    dataRow.forEach((r) => data_export.push(createExportData(r, true)));

    if (isMultipleExport) {
      return { data_export, periods, title, isActual };
    } else {
      return this.#export(data_export, periods, title, isActual, EditableFields, excelTitle);
    }
  };

  exportAllReports = (arrReports = [], excelTitle = null) => {
    if (!arrReports || arrReports.length === 0) {
      return;
    }

    global.Modeliks.post(
      "export-report",
      {
        data: arrReports,
        periods: arrReports[0].periods,
        reportType: arrReports[0].title,
        isActual: arrReports[0].isActual,
        currency: global.Modeliks.CompanyInfo.Currency.value,
        isAllReports: true,
      },
      (path, error) => {
        if (path) {
          const link = document.createElement("a");
          document.body.appendChild(link);
          link.href = `/api/get_report?path=${path}&reportType=${encodeURIComponent(excelTitle ? excelTitle : `Export All Reports - ${new Date().toLocaleDateString()}`)}`;
          link.click();
          link.remove();
        }
      },
    );
  };

  #export = (data, periods, title, isActual = false, EditableFields = false, excelTitle = null) => {
    if (!data || !periods || !title) {
      return;
    }
    global.Modeliks.post(
      "export-report",
      {
        data,
        periods: periods,
        reportType: title,
        isActual: isActual,
        currency: global.Modeliks.CompanyInfo.Currency.value,
        EditableFields,
      },
      (path, error) => {
        if (path) {
          const link = document.createElement("a");
          document.body.appendChild(link);
          link.href = `/api/get_report?path=${path}&reportType=${encodeURIComponent(excelTitle)}`;
          link.click();
          link.remove();
        }
      },
    );
  };

  static getProfitLossDataGrid = () => {
    return global.Modeliks.ReportsStore.find((d) => d.ReportType === ReportsTypes.ProfitLoss)
      .getReportTotals()
      .map((stream) => Reports.ProfitLoss_CreateGrid(stream))
      .filter((c) => c != null);
  };

  static ProfitLoss_CreateGrid = (revenue, isChild = false, level = 0) => {
    let row = {};
    if (revenue) {
      if (level) {
        row = {
          id: revenue.ID,
          header: revenue.Name,
          // revenue: revenue,
          hasEdit: false,
          disabledEdit: true,
          boldRowHeader: false,
          data: revenue,
          children: [],
        };
        if (level < 1 && revenue.getChildDrivers()) {
          let children = revenue.getChildDrivers();
          if (children) {
            children.map((driver) => {
              const hiddenDrivers = ["Finance_Personnel"];

              if (!revenue.isValid || hiddenDrivers.includes(driver.Ref_Table)) {
                return null;
              }

              if (revenue.Ref_Field !== "employee_benefits") {
                row.children.push(Reports.ProfitLoss_CreateGrid(driver, true, level + 1));
              }
            });
          }
        }
      } else {
        // if(!revenue.isValid){
        //     return null;
        // }

        row = {
          id: revenue.ID,
          header: revenue.Name,
          // revenue: revenue,
          hasEdit: false,
          disabledEdit: true,
          boldRowHeader:
            revenue.Ref_ID === "operating_expenses" ||
            revenue.Ref_Field === "gross_profit" ||
            revenue.Ref_Field === "ebitda" ||
            revenue.Ref_Field === "operating_profit" ||
            revenue.Ref_Field === "profit_before_tax" ||
            revenue.Ref_Field === "net_income" ||
            revenue.Ref_ID === 0 ||
            revenue.Ref_Table === "Finance_CostSales",
          data: revenue,
          children: [],
        };
        let children = revenue.getChildDrivers();
        if (children) {
          children.map((driver) => {
            const hiddenDrivers = ["gain_loss_from_asset_sales", "interest_expense"];
            if (!driver.isValid || hiddenDrivers.includes(driver.Ref_Field)) {
              return null;
            }
            row.children.push(Reports.ProfitLoss_CreateGrid(driver, true, level + 1));
          });
        }
      }
      return row;
    } else {
      return null;
    }
  };

  static getBalanceSheetDataGrid = () => {
    return global.Modeliks.ReportsStore.find((d) => d.ReportType === ReportsTypes.BalanceSheet)
      .getReportTotals()
      .map((stream) => Reports.BalanceSheet_CreateGrid(stream))
      .filter((c) => c != null);
  };

  static BalanceSheet_CreateGrid = (revenue, isChild = false, level = 0) => {
    let row = {};
    if (revenue) {
      if (level) {
        row = {
          id: revenue.ID,
          header: revenue.Name,
          // revenue: revenue,
          hasEdit: false,
          boldRowHeader: false,
          isExpanded: revenue.Ref_ID === "retained_earnings" ? false : true,
          disabledEdit: true,
          data: revenue,
          children: level < 3 ? [] : null,
        };
        if (
          (level < 2 && revenue.getChildDrivers()) ||
          revenue.DriverName === "Long Term Liabilities" ||
          revenue.DriverName === "Current Liabilities"
        ) {
          let children = revenue.getChildDrivers();
          if (children && revenue.Ref_ID !== "shareholder_eq_driver") {
            children.map((driver) => {
              if (!driver.isValid) {
                return null;
              }

              row.children.push(Reports.BalanceSheet_CreateGrid(driver, true, level + 1));
            });
          }
        }
      } else {
        // if(!revenue.isValid){
        //     return null;
        // }

        row = {
          id: revenue.ID,
          header: revenue.Name,
          // revenue: revenue,
          isExpanded: true,
          hasEdit: false,
          disabledEdit: true,
          boldRowHeader: true,
          data: revenue,
          children: [],
        };
        let children = revenue.getChildDrivers();
        if (children) {
          children.map((driver) => {
            // if(!driver.isValid){
            //     return null;
            // }
            row.children.push(Reports.BalanceSheet_CreateGrid(driver, true, level + 1));
          });
        }
      }
      return row;
    } else {
      return null;
    }
  };

  static getCashFlowDataGrid = () => {
    return global.Modeliks.ReportsStore.find((d) => d.ReportType === ReportsTypes.CashFlow)
      .getReportTotals()
      .map((stream) => Reports.CashFlow_CreateGrid(stream))
      .filter((c) => c != null);
  };

  static CashFlow_CreateGrid = (revenue, isChild = false, level = 0) => {
    let row = {};
    if (revenue) {
      if (level) {
        row = {
          id: revenue.ID,
          header: revenue.Name,
          // revenue: revenue,
          hasEdit: false,
          boldRowHeader: false,
          isExpanded: true,
          disabledEdit: true,
          data: revenue,
          children: level < 2 ? [] : null,
        };

        if (revenue.Ref_Field === "change_in_current_assets_and_liabilities") {
          row.isExpanded = false;
        }

        if (level < 2 && isChild && revenue.Ref_Table !== "Finance_Financing") {
          let children = revenue.getChildDrivers();

          if (
            children &&
            children.length > 0 &&
            revenue.Ref_Field !== "gain_or_loss_from_sale_of_ppe"
          ) {
            children.map((driver) => {
              if (driver && driver.isValid) {
                row.children.push(Reports.CashFlow_CreateGrid(driver, true, level + 1));
              }
            });
          }
        }
      } else {
        // if(!revenue.isValid){
        //     return null;
        // }
        row = {
          id: revenue.ID,
          header: revenue.Name,
          // revenue: revenue,
          isExpanded: true,
          hasEdit: false,
          disabledEdit: true,
          boldRowHeader: true,
          data: revenue,
          children: [],
        };
        let children = revenue.getChildDrivers();
        if (children) {
          children.map((driver) => {
            // console.log('driver', driver);
            if (!driver.isValid) {
              return null;
            }
            row.children.push(Reports.CashFlow_CreateGrid(driver, true, level + 1));
          });
        }
      }
      return row;
    } else {
      return null;
    }
  };
}

export default Reports;
