import React, { Fragment, useState } from "react";
import "../HeaderTextEditor.scss";
import "../HeaderDefault.scss";
import {Exit, HighlightColor, Numbered, TextColor} from "../../components/IconButtons/SubMenuIcons";
import { SketchPicker } from "react-color";
import Tooltip from "@mui/material/Tooltip";
import { Menu, ControlledMenu, MenuItem, SubMenu } from "@szhsin/react-menu";
import MostUsedColors from "../../components/ColorPicker/MostUsedColors";
import ButtonMui from "../../../../components/buttons/buttonMui/buttonMui";
import {rgbToRgba} from "../../../../helpers/utils/color";
import {Tick} from "../../components/menus/LeftMenu/components/Icons/LeftMenuIcons";
import {presetColors} from "./TextColorEditComponent";

function HighlightColorEditComponent(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openHighlightColor = Boolean(anchorEl);
  const [color, setColor] = React.useState("black");
  const [currentColor, setCurrentColor] = React.useState(
    props.style && props.style.backgroundColor ? props.style.backgroundColor : "#585858",
  );

  const initialColor = props.style && props.style.backgroundColor ? props.style.backgroundColor : "#585858";

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onChange = (color) => {
    if (props.objType === "table") {
      props.onChange({ backgroundColor: color });
    } else {
      console.log("color", color);
      props.onChange("backgroundColor", color, false);
    }

    setCurrentColor(color);
    global.Modeliks.addColor(color);
  };

  const checkForSelection = () => {
    if (document.getSelection()) {
      let selection = document.getSelection();
      if (selection.anchorOffset === selection.focusOffset) return false;
      else return true;
    } else return false;
  };

  const toColor = (color) => {
    setColor(color);
    var sel = window.getSelection(); // Gets selection
    if (sel.rangeCount) {
      var e;
      if (sel.extentNode.parentElement.innerText != sel.toString())
        e = document.createElement("span");
      e.style = "background-color:" + color + ";";
      e.innerHTML = sel.toString();
      var range = sel.getRangeAt(0);
      range.deleteContents();
      range.insertNode(e);
    }
  };

  // const initialColor = props.style && props.style.backgroundColor ?  props.style.backgroundColor : '#585858';

  // props.onChange({backgroundColor: rgbToRgba(event)})
  // props.onChange('backgroundColor', rgbToRgba(event), false)

  const rgbToRgba = (event) => {
    return "rgba(" + event.rgb.r + "," + event.rgb.g + "," + event.rgb.b + "," + event.rgb.a + ")";
  };

  const itemsToRender = () => {
    return (
      <>
        <div>
          <SketchPicker
            color={currentColor}
            onChange={(event) => {
              setCurrentColor(rgbToRgba(event));
              onChange(rgbToRgba(event));
            }}
          />
        </div>
        <div>
          <MostUsedColors onClick={onChange} />
        </div>
      </>
    );
  };

  const itemsToRenderWithConfirm = () => {
    return <>
      <div>
        <SketchPicker
            color={currentColor}
            /* onChangeComplete={(event) => {

            }} */
            onChange={(event) => {
              if(presetColors.includes(event.hex.toUpperCase()) && (event.rgb.a == 1)){
                onChange(rgbToRgba(event));
                setCurrentColor(rgbToRgba(event));
              }
              else {
                setCurrentColor(rgbToRgba(event));
              }
            }}
        />
      </div>
      <div>
        {(currentColor != initialColor) && <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '10px', paddingBottom: '10px', gap: '10px'}}>
          <MenuItem onClick={handleConfirm}>
            <Tick />
          </MenuItem>
          <MenuItem style={{color: 'red'}} onClick={() => {
            setCurrentColor(initialColor);
            props.renderHeader()
          }}>
            <Exit />
          </MenuItem>
        </div>}
        <MostUsedColors onClick={onChange} />
      </div>
    </>
  }

  const handleConfirm = () => {
    onChange(currentColor);
  }

  const HighlightColorEdit = () => {
    if (props.header) {
      return (
        <Menu
          className={"color_menu"}
          menuButton={({ open }) => (
            <ButtonMui
              tooltipText={"Highlight Color"}
              className={props.className ? props.className : ""}
              variant={"text"}
              isIconButton
              roundnessType={"squared"}
              color={"black"}
              label={
                <HighlightColor
                  active={open}
                  activeColor={
                    props.style && props.style.backgroundColor
                      ? props.style.backgroundColor
                      : "#585858"
                  }
                />
              }
            />
          )}
        >
          {props.shouldConfirm ? itemsToRenderWithConfirm() : itemsToRender()}
        </Menu>
      );
    }
    return (
      <SubMenu
        className={`color_menu block ${props.className ? props.className : ""}`}
        label={({ open }) => (
          <HighlightColor
            active={open}
            activeColor={
              props.style && props.style.backgroundColor ? props.style.backgroundColor : "#585858"
            }
          />
        )}
        openTrigger="clickOnly"
      >
        {props.shouldConfirm ? itemsToRenderWithConfirm() : itemsToRender()}
      </SubMenu>
    );
  };

  return <>{HighlightColorEdit()}</>;
}

export default HighlightColorEditComponent;
