import React from "react";
import "./onboarding.scss";
import {
  IconPlus,
  ModeliksNumberIcon,
  ModeliksTextIcon,
  TrashCan,
} from "../../../../components/icons/svgIcons";
import { NumberIcon } from "./NumberIcon";
import Input from "../../../../components/actions/Input";
import MaterialSelect from "../../../../components/actions/SelectM";
import { IndustryTypes } from "../../../../components/constants/industries";
import AutocompleteInput from "../../../../components/actions/inputs/AutocompleteInput";
import DatePickerYM from "../../../../components/actions/DatePickers/DatePickerYM";
import ButtonMui from "../../../../components/buttons/buttonMui/buttonMui";
import { Collaborator } from "../index";
import Mx_Tooltip from "../../../../components/icons/Mx_Tooltip_Click";
import RadioButton from "../../../../components/actions/RadioButton";
import { validateMail } from "../../../../helpers/EmailHelper";
import { DialogTypes } from "../../../../data/Finance/constants";
import DialogM from "../../../../SlideEditor/components/components/Dialog/DialogM";
import AppDialog from "../../../../components/dialogs/AppDialog";
import Dialog from "@mui/material/Dialog";
import DropDownAutocomplete from "../../../../components/actions/inputs/DropDownAutocomplete";

const GetInput = (props) => {
  const [value, setValue] = React.useState(props.value);
  switch (props.inputType) {
    case "text":
      return (
        <Input
          placeholder={props.placeholder}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            props.onChange(props.name, e.target.value);
          }}
        />
      );
    case "select":
      return (
        <MaterialSelect
          label=""
          width="227px"
          value={value}
          options={
            props.definedOptions
              ? props.definedOptions
              : Object.values(props.options).map((c) => {
                  return {
                    description: c,
                    value: c,
                  };
                })
          }
          onChange={(e) => {
            if (props.disabled) {
              return;
            }
            setValue(e.target.value);
            props.onChange(props.name, e.target.value);
          }}
        />
      );
    case "datepicker":
      return (
        <DatePickerYM
          value={value}
          onChange={(e) => {
            setValue(`${e.getFullYear()}-${(e.getMonth() + 1).toString().padStart(2, "0")}`);
            props.onChange(e);
          }}
        />
      );
    case "autocomplete":
      return (
        <DropDownAutocomplete
          // CurrencyName={this.state.CurrencyName}
          placeholder="Search..."
          className="fdd_cd_driver_autocomplete_input_style"
          // disabled={!this.FinancialsEditPermission}
          options={props.options}
          value={value}
          onChange={(currency) => {
            setValue(currency);
            props.onChange(currency);
          }}
        />
      );
    // case 'autocomplete':
    //     return <AutocompleteInput
    //         // className='fdd_cd_driver_autocomplete_input_style'
    //         options={props.options}
    //         placeholder={props.placeholder}
    //         value={props.value}
    //         width='227px'
    //         onChange={(e,val) => props.onChange(props.name, e.target.value)}
    //     />;
    default:
      return;
  }
};

const deleteCollaborator = (collaborators, index) => {
  collaborators.splice(index, 1);
};

const validateCollaborators = (Collaborators) => {
  let err = false;
  Collaborators.forEach((collaborator, index) => {
    if (collaborator.email == "") {
      collaborator.mailError = null;
      collaborator.validated = false;
    } else if (!validateMail(collaborator.email)) {
      collaborator.mailError = "invalid mail";
      err = true;
    } else {
      if (Collaborators.findIndex((c) => c.email == collaborator.email) === index) {
        collaborator.mailError = null;
        collaborator.validated = true;
      } else {
        collaborator.mailError = "already invited collaborator";
        collaborator.validated = false;
        err = true;
      }
    }
  });

  if (!err) {
  }
};
const DialogWarning = (props) => {
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"lg"}
      open={true}
      className={"company_limit_dialog"}
      onClose={props.onClose}
    >
      <div className="p p2 center p_t  p_t">
        {" "}
        To add more users, it is necessary to upgrade your current subscription plan. Once you have
        finished the onboarding process navigate to the "Account Settings" section, where you will
        have the option to upgrade your plan. Once the upgrade is complete, you can proceed to
        invite and manage additional users by accessing the "Users" section.
      </div>
      <div style={{ textAlign: "center" }}>
        <ButtonMui
          label={"Close"}
          padding={"0 0 0 0"}
          backgroundColor={"red150"}
          onClick={props.onClose}
        />
      </div>
    </Dialog>
  );
};

const AddCollaborators = (props) => {
  const [collaborators, setCollaborators] = React.useState(props.collaborators);
  const [errorDialog, setErrorDialog] = React.useState(false);
  const { title, subtitle, number } = props;

  return (
    <>
      {errorDialog && <DialogWarning onClose={() => setErrorDialog(false)} />}
      <div className="spsocb_boarding_card display_block">
        <span>
          <span className="spsocb_boarding_card">
            <span className="spsocb_boarding_part_one">
              <NumberIcon number={number} />
              <span className="spsocb_boarding_card_text_div">
                <span className="spsocb_boarding_card_first_text">{title}</span>
                <span className="spsocb_boarding_card_second_text">{subtitle}</span>
              </span>
            </span>
            <span className="spsocb_boarding_card_input_div align_right">
              <ButtonMui
                label={"Add User"}
                width={120}
                variant={"outlined"}
                endIcon={<IconPlus />}
                onClick={() => {
                  if (
                    props.collaborators.length <
                    global.Modeliks.limitInfo.TotalUsers - global.Modeliks.limitInfo.ActiveUsers
                  ) {
                    props.addCollaborator(new Collaborator());
                    setCollaborators(props.collaborators);
                  } else {
                    setErrorDialog(true);
                  }
                }}
              />
            </span>
          </span>
        </span>

        <div className="collaborators_div">
          {props.collaborators.map((collaborator, key) => {
            return (
              <div className="spsocb_boarding_collaborators_div" key={"collaborator_" + key}>
                <div>
                  <Input
                    placeholder="collaborator@example.com"
                    value={collaborator.email}
                    width="227px"
                    onChange={(e) => {
                      const oldColl = [...collaborators];
                      oldColl[key].email = e.target.value;
                      setCollaborators(oldColl);
                    }}
                    error={!!collaborator.mailError}
                    errorMessage={collaborator.mailError}
                  />
                </div>
                <div className="spsocb_collaborators_inputs_div">
                  <div className="spsocb_collaborators_radio_div">
                    <RadioButton
                      size="small"
                      value={1}
                      placeholder="Editor"
                      checked={collaborator.userType == 1}
                      name={"user_type_" + key}
                      onChange={(e) => {
                        const oldColl = [...collaborators];
                        oldColl[key].userType = parseInt(e.target.value);
                        setCollaborators(oldColl);
                      }}
                    />
                    <RadioButton
                      size="small"
                      value={0}
                      placeholder="Viewer"
                      checked={collaborator.userType == 0}
                      name={"user_type_" + key}
                      onChange={(e) => {
                        const oldColl = [...collaborators];
                        oldColl[key].userType = parseInt(e.target.value);
                        setCollaborators(oldColl);
                      }}
                    />
                  </div>

                  <div>
                    <ButtonMui
                      onClick={() => props.deleteCollaborator(key)}
                      color="red"
                      variant="text"
                      label={<TrashCan />}
                      isIconButton
                    />
                    <Mx_Tooltip
                      description={
                        <React.Fragment>
                          <b>Editor Permission</b>: With editor permission, users have full editing
                          capabilities within Modeliks. <br />
                          <b>Viewer Permission</b>: Users with viewer permission have read-only
                          access.
                        </React.Fragment>
                      }
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export const BoardingCard = (props) => {
  const { title, subtitle, number } = props;
  if (props.addButton) {
    return AddCollaborators(props);
  }

  return (
    <div
      disabled={props.disabled}
      className={`spsocb_boarding_card ${props.disabled ? "disabled" : ""}`}
    >
      <span className="spsocb_boarding_part_one">
        <NumberIcon number={number} />
        <span className="spsocb_boarding_card_text_div">
          <span className="spsocb_boarding_card_first_text">{title}</span>
          <span className="spsocb_boarding_card_second_text">{subtitle}</span>
        </span>
      </span>

      {props.addButton ? (
        AddCollaborators(props)
      ) : (
        <div className="spsocb_boarding_card_input_div ">{GetInput(props)}</div>
      )}
    </div>
  );
};
