import React, { Fragment, useState } from "react";
// import RightMenuPortal from "./RightMenuPortal.js";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import InputBox from "../InputBoxComponent/InputBox";
import { FlipHorizontally, FlipVertically, Rotate90 } from "../IconButtons/SubMenuIcons";

function SizeAndRotation(props) {
  const [openSizeAndRotation, setOpenSizeAndRotation] = React.useState(false);

  const handleClickSizeAndRotation = () => {
    if (openSizeAndRotation) {
      setOpenSizeAndRotation(false);
    } else {
      setOpenSizeAndRotation(true);
      props.rightMenuFunc.closeSizeAndRotation = null;
      Object.values(props.rightMenuFunc).map((value) => {
        if (typeof value === "function") {
          value.call();
        }
      });
    }
  };

  const closeMenu = () => {
    setOpenSizeAndRotation(false);
  };

  if (props.rightMenuFunc) {
    props.rightMenuFunc.closeSizeAndRotation = closeMenu;
  }
  const handleWidthChange = (width, e) => {
    if (width) {
      if (props.lockedAspect) {
        let aspect = Number(props.width) / Number(props.height);
        props.onChange(Number(width), (Number(width) * 1) / aspect, props.rotateAngle);
      } else {
        props.onChange(Number(width), props.height, props.rotateAngle);
      }
    } else {
      if (props.lockedAspect) {
        let aspect = Number(props.width) / Number(props.height);
        props.onChange(Number(1), (Number(1) * 1) / aspect, props.rotateAngle);
      } else {
        props.onChange(Number(1), props.height, props.rotateAngle);
      }
    }
  };
  const handleHeightChange = (height, e) => {
    if (!height) {
      height = 1;
    }

    if (props.lockedAspect) {
      let aspect = Number(props.width) / Number(props.height);
      props.onChange(Number(height) * aspect, Number(height), props.rotateAngle);
    } else {
      props.onChange(props.width, Number(height), props.rotateAngle);
    }
  };
  const handleRotationChange = (rotateAngle) => {
    props.onChange(props.width, props.height, Number(rotateAngle));
  };
  const handleScaleXChange = (scaleX) => {
    props.onChange(props.width, props.height, props.rotateAngle, scaleX, props.scaleY);
  };
  const handleScaleYChange = (scaleY) => {
    props.onChange(props.width, props.height, props.rotateAngle, props.scaleX, scaleY);
  };
  const getRotateAngle = () => {
    if (props.rotateAngle < 90) {
      return 90;
    } else if (props.rotateAngle >= 90 && props.rotateAngle < 180) {
      return 180;
    } else if (props.rotateAngle >= 180 && props.rotateAngle < 270) {
      return 270;
    } else {
      return 0;
    }
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      onKeyDown={(e) => {
        e.stopPropagation();
      }}
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
    >
      <ListItemButton id={"listItem"} onClick={handleClickSizeAndRotation}>
        <ListItemText className="listButtonText" primary="Size & Rotation" />
        {openSizeAndRotation ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <div style={{ width: "230px", border: "1px solid #F0F1F7", marginLeft: "18px" }} />
      <Collapse in={openSizeAndRotation} timeout="auto" unmountOnExit>
        <div className={"collapse"}>
          <div className={"listSubHeading"}>Size</div>
          <div className={"listRow"}>
            <div className={"listRowElement"}>Width</div>
            <div className={"listRowElement"}>Height</div>
          </div>
          <div className={"listRow"}>
            <div className={"listRowElement"}>
              <InputBox
                onChange={(e) => handleWidthChange(e.target.value, e)}
                value={props.width}
                onUpClick={() => handleWidthChange(props.width + 1)}
                onDownClick={() => handleWidthChange(props.width - 1)}
              />
            </div>
            <div className={"listRowElement"}>
              <InputBox
                onChange={(e) => handleHeightChange(e.target.value, e)}
                value={props.height}
                onUpClick={() => handleHeightChange(props.height + 1)}
                onDownClick={() => handleHeightChange(props.height - 1)}
              />
            </div>
          </div>
          <div className={"listRow"}>
            <input
              type="checkbox"
              style={{ margin: 0, marginRight: "4px" }}
              checked={props.lockedAspect}
              onChange={props.changeLockedAspect}
            />
            <div className={"listSubHeading light"}>Lock aspect ratio</div>
          </div>
          <div className={"listSubHeading"}>Rotate</div>
          <div className={"listRow"}>
            <div className={"listRowElement"}>Angle</div>
            <div className={"listRowElement"}>Rotate 90</div>
          </div>
          <div className={"listRow"}>
            <div className={"listRowElement"}>
              <InputBox
                onChange={(e) => handleRotationChange(e.target.value)}
                value={props.rotateAngle}
                onUpClick={() => handleRotationChange(props.rotateAngle + 1)}
                onDownClick={() => handleRotationChange(props.rotateAngle - 1)}
              />
            </div>
            <div className={"listRowElement"}>
              <Rotate90 onClick={() => handleRotationChange(getRotateAngle())} />
            </div>
          </div>

          <div className={"listRow"}>
            <div className={"listRowElement"}>Flip horizontally</div>
            <div className={"listRowElement"}>Flip vertically</div>
          </div>
          <div className={"listRow"}>
            <div
              className={"listRowElement"}
              onClick={() => handleScaleXChange(-1 * Number(props.scaleX))}
            >
              <FlipHorizontally />
            </div>
            <div className={"listRowElement"}>
              <FlipVertically
                onClick={() => {
                  handleScaleYChange(-1 * Number(props.scaleY));
                }}
              />
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
}
// SizeAndRotation.PropTypes = {
//     handleCollapseMode:PropTypes.function,
// }
//
// SizeAndRotation.defaultProps ={
//     handleCollapseMode:handleClickSizeAndRotation,
// }

export default SizeAndRotation;
