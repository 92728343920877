import React from "react";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import { IconI } from "../icons/svgIcons";
import IconButton from "@mui/material/IconButton";
import "./Mx_Tooltip_Click.scss";

class Mx_Tooltip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  closeTooltip = () => {
    if (this.state.open) {
      this.setState({ open: false });
    }
  };

  openTooltip = () => {
    if (!this.state.open) {
      this.setState({ open: true });
    }
  };

  render() {
    return (
      <Tooltip
        open={this.state.open}
        onClose={this.closeTooltip}
        disableHoverListener={!this.state.open}
        className={`scim_tool_tip_click`}
        title={this.props.description}
        placement="right"
      >
        <IconButton tabIndex={-1} size="small" onClick={this.openTooltip}>
          <IconI />
        </IconButton>
      </Tooltip>
    );
  }
}

export default Mx_Tooltip;
