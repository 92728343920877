import React from "react";
import AuthPage from "../components/AuthPage";
import VerificationSentCheckInbox from "./VerificationSentCheckInbox/VerificationSentCheckInbox";

class VerificationSent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
  }

  componentDidMount() {
    if (window.localStorage.getItem("Account_ID")) {
      window.localStorage.removeItem("Account_ID");
    }

    if (window.localStorage.getItem("isVerified")) {
      window.localStorage.removeItem("isVerified");
    }

    if (window.localStorage.getItem("Email")) {
      window.localStorage.removeItem("Email");
    }

    if (window.localStorage.getItem("clickid")) {
      window.localStorage.removeItem("clickid");
    }

    if (window.localStorage.getItem("referralSource")) {
      window.localStorage.removeItem("referralSource");
    }
  }

  render() {
    return (
      <VerificationSentCheckInbox />
      // <AuthPage type='verificationSent' />
    );
  }
}

export default VerificationSent;
