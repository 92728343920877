import React from "react";
import styled from "styled-components";
import { Theme } from "../../../../../../theme/styles/theme";
import Input from "../../../../../../components/actions/Input";
import AddDialog from "../../../../../../components/dialogs/financials/AddDialog/AddDialog";
import { AddDialogTitle } from "../../../../../../components/dialogs/financials/AddDialog/AddDialogTitle";
import { AddDialogContent } from "../../../../../../components/dialogs/financials/AddDialog/AddDialogContent";
import { DialogActions } from "@mui/material";
import Button from "../../../../../../components/actions/Button";
import Mx_NumInput from "../../../../../../components/actions/Mx_NumInput";
import Financing from "../../../../../../data/Finance/Financing";

const SyledP = styled.p`
  ${Theme.text.paragraph.p2_strong};
  color: #252525;
`;
const StyledTextArea = styled.div`
  background: #f5f5f5;
  border: 1px solid #f0f1f7;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 16px 35px 16px 32px;
  ${Theme.text.paragraph.p2};
  color: #252525;
`;
class RetainedEarning extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      RetainedEarning: Financing.getOpenningBalanceRetainedEarnings(),
    };
  }

  componentDidMount() {}

  changeRetainedEarning = (value) => {
    if (!isNaN(parseInt(value))) {
      this.state.RetainedEarning.Values[0].Value = value;
      this.props.onChange([this.state.RetainedEarning]);
      this.forceUpdate();
    }
  };
  updateRetainedEarning = () => {
    this.state.RetainedEarning.Save(this.props.onClose(true));
  };

  render() {
    if (!this.state.RetainedEarning) {
      return null;
    }
    return (
      <>
        <AddDialog open={this.props.open} onClose={this.props.onClose}>
          <AddDialogTitle title="Retained Earning" onClose={this.props.onClose} />
          <AddDialogContent>
            <SyledP>What is Retained Earning?</SyledP>

            <StyledTextArea>
              Retained earnings are the cumulative net earnings or profits of a company after
              accounting for dividend payments.
            </StyledTextArea>

            <SyledP style={{ marginBottom: 10, marginLeft: 4 }}>
              Enter Retained Earnings openning balance
              <div
                style={{
                  maxWidth: "150px",
                  paddingBottom: "0px",
                  height: "45px",
                  background: Theme.colors.primary.lightblue["300"],
                }}
              >
                <Mx_NumInput
                  singleInput={true}
                  selected={true}
                  isMultipleSelected={false}
                  isEditable={true}
                  unit={global.Modeliks.CompanyInfo.Currency.value}
                  key={"retained_earnings" + this.state.RetainedEarning.ID}
                  onChange={this.changeRetainedEarning}
                  data={this.state.RetainedEarning.Values[0].Value}
                />
              </div>
            </SyledP>
          </AddDialogContent>

          <DialogActions
            style={{ justifyContent: "space-between", background: "#F5F5F5", marginLeft: "16px" }}
          >
            <Button
              onClick={this.props.onClose}
              size="small"
              padding="15px"
              noElevation={true}
              color="red150"
              label="Close"
            />
            <Button
              onClick={this.updateRetainedEarning}
              size="small"
              padding="15px"
              noElevation={true}
              color="primary"
              label="Save"
            />
          </DialogActions>
        </AddDialog>
      </>
    );
  }
}

export default RetainedEarning;
