import React, { Component } from "react";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import LegendAndGridlines from "../../components/components/RightMenuComponents/LegendAndGridlines";
import DataLabels from "../../components/components/RightMenuComponents/DataLabelsComponent";
import AxisComponent from "../../components/components/RightMenuComponents/AxisComponent";
import ColorComponent from "../../components/components/RightMenuComponents/ColorComponent";
import GapWidthComponent from "../../components/components/RightMenuComponents/GapWidthComponent";
import RightMenuPortal from "../../components/components/Portals/RightMenuPortal.js";
import BarColorComponent from "../components/FormatOptionComponents/BarColorComponent";
import ChartTitleAndSubtitle from "../components/FormatOptionComponents/ChartTitleAndSubtitle";
import { ChartDataTypes, ChartTypes } from "../../../components/constants/charts";
import SingleColorComponent from "../components/FormatOptionComponents/singleColorComponent";

export default class FormatOptionComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    this.chartOptions = this.props.chartOptionsObj.chartOptions;
    this.chartData = this.props.chartOptionsObj.chartData;
    this.chartFunctionsObj = this.props.chartFunctionsObj;
    this.type = this.props.type;

    return (
      <RightMenuPortal>
        <div className={"right_menu_mask"} onMouseDown={(e) => e.stopPropagation()}>
          <List
            style={{
              paddingLeft: "0",
              position: "absolute",
              width: "100%",
              height: "auto",
              maxWidth: 250,
              bgcolor: "background.paper",
              paddingBottom: "0",
              paddingTop: "0",
              // overflow:'scroll',
            }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <span style={{ fontWeight: 700, fontSize: "16px", textAlign: "left" }}>
                    Format Options
                  </span>
                </div>
              </ListSubheader>
            }
          >
            {this.props.children && this.props.children}
            <ChartTitleAndSubtitle
              {...this.chartOptions}
              rightMenuFunc={this.props.rightMenuFunc}
              onPropsChange={(e) =>
                this.props.handleChartOptionsChange(e, () => {
                  this.forceUpdate();
                })
              }
            />
            <LegendAndGridlines
              {...this.chartOptions}
              rightMenuFunc={this.props.rightMenuFunc}
              onPropsChange={(e) =>
                this.props.handleChartOptionsChange(e, () => {
                  this.forceUpdate();
                })
              }
            />
            <DataLabels
              {...this.chartOptions}
              rightMenuFunc={this.props.rightMenuFunc}
              onPropsChange={(e) =>
                this.props.handleChartOptionsChange(e, () => {
                  this.forceUpdate();
                })
              }
            />
            <AxisComponent
              {...this.chartOptions}
              rightMenuFunc={this.props.rightMenuFunc}
              onPropsChange={(e) =>
                this.props.handleChartOptionsChange(e, () => {
                  this.forceUpdate();
                })
              }
            />
            {((this.type != ChartTypes.StackedBarChart.name &&
              this.type != ChartTypes.WaterfallChart.name &&
              this.type != ChartTypes.AreaChart.name) ||
              this.chartData.categories != ChartDataTypes.reportFrequency) && (
              <BarColorComponent
                {...this.chartFunctionsObj}
                {...this.chartOptions}
                {...this.chartData}
                onChangeColor={this.props.onChangeColor}
                rightMenuFunc={this.props.rightMenuFunc}
                onPropsChange={(e) =>
                  this.props.handleChartOptionsChange(e, () => {
                    this.forceUpdate();
                  })
                }
              />
            )}
            {this.type == ChartTypes.StackedBarChart.name &&
              this.chartData.categories == ChartDataTypes.reportFrequency && (
                <SingleColorComponent
                  onSingleColorChange={this.props.onSingleColorChange}
                  {...this.chartFunctionsObj}
                  {...this.chartOptions}
                  {...this.chartData}
                  onChangeColor={this.props.onChangeColor}
                  rightMenuFunc={this.props.rightMenuFunc}
                  onPropsChange={(e) =>
                    this.props.handleChartOptionsChange(e, () => {
                      this.forceUpdate();
                    })
                  }
                />
              )}
            <GapWidthComponent
              rightMenuFunc={this.props.rightMenuFunc}
              {...this.chartOptions}
              onPropsChange={(e) =>
                this.props.handleChartDataOptionsChange(e, () => {
                  this.forceUpdate();
                })
              }
            />
          </List>
        </div>
      </RightMenuPortal>
    );
  }
}
