class FinanceCache {
  ts = null;

  constructor() {
    this.resetCache();
  }

  resetCache = () => {
    this.ts = new Date().getTime();
  };

  setCache = (obj) => {
    if (!obj.cache) {
      obj.cache = {
        financeCache: this,
        resetCache: this.resetCache,
        cacheTs: this.ts,
        values: {},
      };
    }
  };

  defineCalculation = (obj, calcName, func) => {
    this.setCache(obj);

    Object.defineProperty(obj, "cache_" + calcName, func);

    Object.defineProperty(obj, calcName, {
      get() {
        if (this.cache.financeCache.ts != this.cache.ts) {
          this.cache.ts = this.cache.financeCache.ts;
          this.cache.values = {};
        } else if (this.cache.values[calcName]) {
          // console.log("getting cache " + this.dateIndex + " " + calcName);
          return this.cache.values[calcName];
        }

        // console.log("calculating cache " + this.dateIndex + " " + calcName);
        this.cache.values[calcName] = this["cache_" + calcName];
        return this.cache.values[calcName];
      },
    });
  };

  static getFinanceCache = (data) => {
    data.forEach((item) => {
      if (item.cache) {
        return item.cache.financeCache;
      }
    });

    const financeCache = new FinanceCache();
    data.forEach((item) => {
      financeCache.setCache(item);
    });

    return financeCache;
  };
}

export default FinanceCache;
