import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Active } from "../dividers/ActiveTab";
import "./tabs.scss";
import { useMatch, useResolvedPath, Link } from "react-router-dom";

const ProfileTabs = (props) => {
  const [tab, setTab] = useState(props.children[0].props);
  let resolved = useResolvedPath(tab);
  let match = useMatch({ path: resolved.pathname + `/*`, end: true });
  let child = props.children.find((child) => match.pathname.indexOf(child.props.link) === 0);
  if (child && tab !== child.props) {
    setTab(child.props);
  }

  return (
    <>
      <Tabs
        className="mctabs_tabs profile_tabs"
        value={tab.label}
        centered
        TabIndicatorProps={{
          children: (
            <div className="mctabs_indicator">
              <Active />
            </div>
          ),
        }}
        // value={props.selectedTab.field}
        aria-label="basic tabs example"
      >
        {props.children.map((child, index) => {
          return (
            <Tab
              disableRipple
              component={Link}
              to={child.props.link}
              key={"tab_" + index}
              value={child.props.label}
              label={child.props.label}
              onClick={() => setTab(child.props)}
              className={`mctabs_tab ${child.props.size}`}
            />
          );
        })}
        {props.nonTabChildren && props.nonTabChildren}
      </Tabs>
    </>
  );
};

export default ProfileTabs;
