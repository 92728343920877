import { createContext } from "react";
import { CommandHistoryManager } from "./CommandManager";

export const CommandManagerContext = createContext();

export const CommandManagerProvider = ({ children }) => {
  const commandHistoryManager = CommandHistoryManager.getInstance();

  const addToGlobalSlideHistory = () => {
    if(!global.slideHistory) {
      return;
    }
    global.slideHistory.pushNewChange(commandHistoryManager, null, null, null);
  }

  const value = {
    commandHistoryManager,
    addToGlobalSlideHistory
  };

  return (
    <CommandManagerContext.Provider value={value}>
      {children}
    </CommandManagerContext.Provider>
  );
};
