const MxIDHelper = {
  curID: 10000,
  newID: () => {
    return global.MxID.curID++;
  },
};

if (global.hasOwnProperty("MxID") == false) {
  global.MxID = MxIDHelper;
}

const MxIDtmp = global.MxID;

export default MxIDtmp;
