import Reports from "../index";
import { ReportsTypes } from "../constants";
import CalculatedDriver from "../../CalculatedDriver/index";
import { DriverCategories, UnitTypes } from "../../CalculatedDriver/constants";
import { RevenueTypes } from "../../../../components/constants/finance";
import Subscription from "../../Revenue/Subscription";
import ProfitLoss from "../ProfitLoss";
import Expense from "../../Expense";
import Personnel from "../../Personnel";
import Revenue from "../../Revenue";

class SaaSReportingSubscription extends Reports {
  Name = "";
  ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
  ReportType = ReportsTypes.ReportingDrivers;
  constructor(db_record) {
    super(db_record);
    if (this.db_record) {
      this.Name = this.db_record.Name;
      this.ReportType = this.db_record.ReportType;
      // this.setReport()
    }
  }

  static DriversDesc = Object.assign({
    BookingsNewCustomers: {
      driverName: "Bookings (New Customers)",
      fieldName: "BookingsNewCustomers",
      driverID: "saas_bookings_new_customers",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
      IsExisting: true,
    },
    BookingsAllCustomers: {
      driverName: "Bookings (All Customers)",
      fieldName: "BookingsAllCustomers",
      driverID: "saas_bookings_all_customers",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
      IsExisting: true,
    },
    AverageContractLength: {
      driverName: "Average Contract Length (months)",
      fieldName: "AverageContractLength",
      driverID: "saas_average_contract_length",
      unit: UnitTypes.Units,
      category: DriverCategories.Average,
      IsExisting: true,
    },
    AverageMRRNewCustomers: {
      driverName: "Average MRR (New Customers)",
      fieldName: "AverageMRRNewCustomers",
      driverID: "saas_average_mrr_new_customers",
      unit: UnitTypes.Price,
      category: DriverCategories.Average,
      IsExisting: true,
    },
    AverageMRRAllCustomers: {
      driverName: "Average MRR (All Customers)",
      fieldName: "AverageMRRAllCustomers",
      driverID: "saas_average_mrr_all_customers",
      unit: UnitTypes.Price,
      category: DriverCategories.Average,
      IsExisting: true,
    },
    AverageARRNewCustomers: {
      driverName: "Average ARR (New Customers)",
      fieldName: "AverageARRNewCustomers",
      driverID: "saas_average_arr_new_customers",
      unit: UnitTypes.Price,
      category: DriverCategories.Average,
      IsExisting: true,
    },
    AverageARRAllCustomers: {
      driverName: "Average ARR (All Customers)",
      fieldName: "AverageARRAllCustomers",
      driverID: "saas_average_arr_all_customers",
      unit: UnitTypes.Price,
      category: DriverCategories.Average,
      IsExisting: true,
    },
    NewMRR: {
      driverName: "New MRR",
      fieldName: "NewMRR",
      driverID: "saas_new_mrr",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
      IsExisting: true,
    },
    ChurnedMRR: {
      driverName: "Churned MRR",
      fieldName: "ChurnedMRR",
      driverID: "saas_churned_mrr",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
      IsExisting: true,
    },
    TotalMRR: {
      driverName: "Total MRR",
      fieldName: "TotalMRR",
      driverID: "saas_total_mrr",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
      IsExisting: true,
    },
    TotalARR: {
      driverName: "Total ARR",
      fieldName: "TotalARR",
      driverID: "saas_total_arr",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
      IsExisting: true,
    },
    NetNewCustomers: {
      driverName: "Net New Customers",
      fieldName: "NetNewCustomers",
      driverID: "saas_net_new_customers",
      unit: UnitTypes.Units,
      category: DriverCategories.Sum,
      IsExisting: true,
    },
    PercentageMRRChurn: {
      driverName: "Percentage MRR Churn",
      fieldName: "PercentageMRRChurn",
      driverID: "saas_percentage_mrr_churn",
      unit: UnitTypes.Percentage,
      category: DriverCategories.Average,
      IsExisting: true,
    },
    LTV: {
      driverName: "LTV (Lifetime Value)",
      fieldName: "LTV",
      driverID: "saas_ltv_lifetime_value",
      unit: UnitTypes.Price,
      category: DriverCategories.Average,
      IsExisting: true,
    },
    CACFullyLoaded: {
      driverName: "CAC (Fully Loaded)",
      fieldName: "CACFullyLoaded",
      driverID: "saas_cac_fully_loaded",
      unit: UnitTypes.Price,
      category: DriverCategories.Average,
      IsExisting: true,
    },
    CACExpensesOnly: {
      driverName: "CAC (S&M expenses only)",
      fieldName: "CACExpensesOnly",
      driverID: "saas_cac_expenses_only",
      unit: UnitTypes.Price,
      category: DriverCategories.Average,
      IsExisting: true,
    },
    LTVToCACRatio: {
      driverName: "LTV to CAC ratio",
      fieldName: "LTVToCACRatio",
      driverID: "saas_ltv_to_cac_ratio",
      unit: UnitTypes.Units,
      category: DriverCategories.Average,
      IsExisting: true,
    },
    MonthsToRecoverCAC: {
      driverName: "Months to Recover CAC",
      fieldName: "MonthsToRecoverCAC",
      driverID: "saas_months_to_recover_cac",
      unit: UnitTypes.Units,
      category: DriverCategories.Average,
      IsExisting: true,
    },
    ProductivityPerSMEmployee: {
      driverName: "Productivity per S&M employee",
      fieldName: "ProductivityPerSMEmployee",
      driverID: "saas_productivity_per_sm_employee",
      unit: UnitTypes.Units,
      category: DriverCategories.Average,
      IsExisting: true,
    },
    CustomerLifetimeMonths: {
      driverName: "Customer Lifetime (months)",
      fieldName: "CustomerLifetimeMonths",
      driverID: "saas_customer_lifetime_months",
      unit: UnitTypes.Units,
      category: DriverCategories.Average,
      IsExisting: true,
    },
  });
  getMonthDatesAll = () => {
    return [
      ...global.Modeliks.DateHelper.months_before,
      ...global.Modeliks.DateHelper.months,
      ...global.Modeliks.DateHelper.months_after,
    ];
  };

  getYearDatesAll = () => {
    return [
      ...global.Modeliks.DateHelper.years_all,
      ...global.Modeliks.DateHelper.years_before_actual,
    ];
  };
  periodsData = {};

  buildPeriodsData = () => {
    const allPeriods = this.ProductivityPerSMEmployee.Values.map((c) => c.Date);
    allPeriods.forEach((period) => {
      this.periodsData[period.dateID] = {};
      Object.values(this.constructor.DriversDesc).forEach(
        (driver) =>
          (this.periodsData[period.dateID][driver.fieldName] = this[
            driver.fieldName
          ].getItemByDateSufix(period.sufix)),
      );
      this.periodsData[period.dateID].Date = period;
    });
  };

  setReport = () => {
    this.buildPeriodsData();
    this.setFormulas();
  };

  setFormulas = () => {
    this.createMonthsFormulas();
    this.createYearsFormulas();
  };

  createMonthsFormulas = () => {
    const months = this.getMonthDatesAll();
    const years = this.getYearDatesAll();
    const TotalStreamQuantitySignUps = global.Modeliks.RevenuesStore.filter(
      (d) => d.RevenueType === RevenueTypes.Subscriptions,
    );
    const TotalChildrenQuantitySignUps = TotalStreamQuantitySignUps.flatMap((d) => d.children);
    const TotalCashCollections = Subscription.getSubscriptionsCashCollections();
    const TotalCustomersAtEndOfPeriod = Subscription.getCustomersAtEndOfPeriodTotals();
    const TotalSubscriptionRevenue = Subscription.getSubscriptionsTotalsReport();
    const TotalSignUps = Subscription.getSingupsTotals();
    const TotalChurnedCustomers = Subscription.getChurnedCustomersTotals();
    const TotalChurnRate = Subscription.getChurnRateTotals();
    const ProfitLossReport = ProfitLoss.getReport().GrossProfitMargin;
    const SalesAndMarketingTotals = Expense.getSalesAndMarketingTotals();
    const SalesAndMarketingEmployeesTotals = Personnel.getSalesAndMarketingEmployeesTotals();
    const TotalRevenue = Revenue.getRevenueTotals();
    const TotalSalesAndMarketingEmployees = global.Modeliks.DriversStore.getItem(
      "Finance_Personnel-sales-and-marketing-number-totals",
    );
    const TotalSinglePrice = Subscription.getSinglePriceTotals();

    if (!TotalStreamQuantitySignUps || TotalStreamQuantitySignUps.length < 1) {
      this.getAllDrivers().forEach((d) => (d.IsExisting = false));
    }

    this.AverageMRRAllCustomers.Formula = `${TotalSubscriptionRevenue} / ${TotalCustomersAtEndOfPeriod}`;

    months.forEach((month) => {
      const prevMonthDate = months.find((c) => c.Order == month.Order - 1);
      const curMonth = this.periodsData[month.dateID];
      curMonth.BookingsNewCustomers.Formula = `${TotalChildrenQuantitySignUps.map((d) => `${d.Quantity.getItemByDateSufix(month.sufix)} * ${d.SinglePrice.getItemByDateSufix(month.sufix)}`).join("+")}  Actual(${TotalStreamQuantitySignUps.map((d) => `(${d.Quantity.getItemByDateSufix(month.sufix).ID_f_actual} * ${d.SinglePrice.getItemByDateSufix(month.sufix).ID_f_actual})`).join("+")})`;
      curMonth.BookingsAllCustomers.Formula = `${TotalCashCollections.getItemByDateSufix(month.sufix)}`;
      curMonth.AverageContractLength.Formula = `(${TotalChildrenQuantitySignUps.map((d) => `${d.Quantity.getItemByDateSufix(month.sufix)} * ${d.SubscriptionPeriod}`).join("+")}) / MxMath.Sum([${TotalChildrenQuantitySignUps.map((d) => `${d.Quantity.getItemByDateSufix(month.sufix)}`).join(",")}]) Actual( ${TotalCashCollections.getItemByDateSufix(month.sufix).ID_f_actual} / ${TotalSubscriptionRevenue.getItemByDateSufix(month.sufix).ID_f_actual} )`;
      curMonth.AverageMRRNewCustomers.Formula = `(${TotalChildrenQuantitySignUps.map((d) => `${d.Quantity.getItemByDateSufix(month.sufix)} * ${d.SinglePrice.getItemByDateSufix(month.sufix)} /  ${d.SubscriptionPeriod}`).join("+")}) / MxMath.Sum([${TotalChildrenQuantitySignUps.map((d) => `${d.Quantity.getItemByDateSufix(month.sufix)}`).join(",")}]) Actual( ${TotalSinglePrice.getItemByDateSufix(month.sufix).ID_f_actual} / ${curMonth.AverageContractLength.ID_f_actual})`;
      curMonth.AverageMRRAllCustomers.Formula = `${TotalSubscriptionRevenue.getItemByDateSufix(month.sufix)} / ${TotalCustomersAtEndOfPeriod.getItemByDateSufix(month.sufix)} `;
      curMonth.AverageARRNewCustomers.Formula = `${curMonth.AverageMRRNewCustomers} * 12`;
      curMonth.AverageARRAllCustomers.Formula = `${curMonth.AverageMRRAllCustomers} * 12`;
      curMonth.NewMRR.Formula = `${curMonth.AverageMRRNewCustomers} * MxMath.Sum([${TotalChildrenQuantitySignUps.map((d) => `${d.Quantity.getItemByDateSufix(month.sufix)}`).join(",")}]) Actual( ${curMonth.AverageMRRNewCustomers.ID_f_actual} * ${TotalSignUps.getItemByDateSufix(month.sufix).ID_f_actual})`;
      curMonth.ChurnedMRR.Formula = `${curMonth.AverageMRRAllCustomers} * ${TotalChurnedCustomers.getItemByDateSufix(month.sufix)}`;
      curMonth.TotalMRR.Formula = `${TotalSubscriptionRevenue.getItemByDateSufix(month.sufix)}`;
      curMonth.TotalARR.Formula = `${curMonth.TotalMRR} * 12`;
      curMonth.NetNewCustomers.Formula = `${TotalSignUps.getItemByDateSufix(month.sufix)} - ${TotalChurnedCustomers.getItemByDateSufix(month.sufix)}`;
      curMonth.PercentageMRRChurn.Formula = `${curMonth.ChurnedMRR} / ${TotalSubscriptionRevenue.getItemByDateSufix(month.sufix)}`;
      curMonth.LTV.Formula = `${curMonth.AverageMRRNewCustomers} * ${ProfitLossReport.getItemByDateSufix(month.sufix)} / ${TotalChurnRate.getItemByDateSufix(month.sufix)}`;
      curMonth.CACFullyLoaded.Formula = `(${SalesAndMarketingTotals.getItemByDateSufix(month.sufix)} + ${SalesAndMarketingEmployeesTotals.getItemByDateSufix(month.sufix)}) / ${TotalSignUps.getItemByDateSufix(month.sufix)}`;
      curMonth.CACExpensesOnly.Formula = `${SalesAndMarketingTotals.getItemByDateSufix(month.sufix)} / ${TotalSignUps.getItemByDateSufix(month.sufix)}`;
      curMonth.LTVToCACRatio.Formula = `${curMonth.LTV} / ${curMonth.CACFullyLoaded}`;
      curMonth.MonthsToRecoverCAC.Formula = `${curMonth.CACFullyLoaded} / (${curMonth.AverageMRRNewCustomers} * ${ProfitLossReport.getItemByDateSufix(month.sufix)})`;
      curMonth.ProductivityPerSMEmployee.Formula = `${TotalRevenue.getItemByDateSufix(month.sufix)} / ${TotalSalesAndMarketingEmployees.getItemByDateSufix(month.sufix)}`;
      curMonth.CustomerLifetimeMonths.Formula = `1 / ${TotalChurnRate.getItemByDateSufix(month.sufix)}`;
    });

    years.forEach((year) => {
      const curYear = this.periodsData[year.dateID];
      const yearMonths = year.months;
      const lastMonth = yearMonths[yearMonths.length - 1];
      const months = this.periodsData[year.dateID].Date.monthIndexes.map(
        (index) => this.periodsData[index],
      );
      curYear.BookingsNewCustomers.Formula = `MxMath.Sum([${months.map((c) => c.BookingsNewCustomers.ID_f).join(",")}]) Actual(${TotalStreamQuantitySignUps.map((d) => `${d.Quantity.getItemByDateSufix(year.sufix).ID_f_actual} * ${d.SinglePrice.getItemByDateSufix(year.sufix).ID_f_actual}`).join("+")})`;
      curYear.BookingsAllCustomers.Formula = `${TotalCashCollections.getItemByDateSufix(year.sufix)}`;
      curYear.AverageContractLength.Formula = `(${TotalChildrenQuantitySignUps.map((d) => `${d.Quantity.getItemByDateSufix(year.sufix)} * ${d.SubscriptionPeriod}`).join("+")}) / MxMath.Sum([${TotalChildrenQuantitySignUps.map((d) => `${d.Quantity.getItemByDateSufix(year.sufix)}`).join(",")}]) Actual( ${TotalCashCollections.getItemByDateSufix(year.sufix).ID_f_actual} / ${TotalSubscriptionRevenue.getItemByDateSufix(year.sufix).ID_f_actual} )`;
      curYear.AverageMRRNewCustomers.Formula = `(${TotalChildrenQuantitySignUps.map((d) => `${d.Quantity.getItemByDateSufix(year.sufix)} * ${d.SinglePrice.getItemByDateSufix(year.sufix)} /  ${d.SubscriptionPeriod}`).join("+")}) / MxMath.Sum([${TotalChildrenQuantitySignUps.map((d) => `${d.Quantity.getItemByDateSufix(year.sufix)}`).join(",")}]) Actual( ${TotalSinglePrice.getItemByDateSufix(year.sufix).ID_f_actual} / ${curYear.AverageContractLength.ID_f_actual})`;
      curYear.AverageMRRAllCustomers.Formula = `${TotalSubscriptionRevenue.getItemByDateSufix(year.sufix)} / MxMath.Sum([${yearMonths.map((m) => `${TotalCustomersAtEndOfPeriod.getItemByDateSufix(m.sufix)}`).join(",")}])`;
      curYear.AverageARRNewCustomers.Formula = `${curYear.AverageMRRNewCustomers} * 12`;
      curYear.AverageARRAllCustomers.Formula = `${curYear.AverageMRRAllCustomers} * 12`;
      curYear.NewMRR.Formula = `MxMath.Sum([${months.map((c) => c.NewMRR.ID_f).join(",")}])`;
      curYear.ChurnedMRR.Formula = `${curYear.AverageMRRAllCustomers} * ${TotalChurnedCustomers.getItemByDateSufix(year.sufix)}`;
      curYear.TotalMRR.Formula = `${TotalSubscriptionRevenue.getItemByDateSufix(lastMonth.sufix)}`;
      curYear.TotalARR.Formula = `${curYear.TotalMRR} * 12`;
      curYear.NetNewCustomers.Formula = `${TotalSignUps.getItemByDateSufix(year.sufix)} - ${TotalChurnedCustomers.getItemByDateSufix(year.sufix)}`;
      curYear.PercentageMRRChurn.Formula = `${curYear.ChurnedMRR} / ${TotalSubscriptionRevenue.getItemByDateSufix(year.sufix)}`;
      curYear.LTV.Formula = `${curYear.AverageMRRNewCustomers} * ${ProfitLossReport.getItemByDateSufix(year.sufix)} / ${TotalChurnRate.getItemByDateSufix(year.sufix)}`;
      curYear.CACFullyLoaded.Formula = `(${SalesAndMarketingTotals.getItemByDateSufix(year.sufix)} + ${SalesAndMarketingEmployeesTotals.getItemByDateSufix(year.sufix)}) / ${TotalSignUps.getItemByDateSufix(year.sufix)}`;
      curYear.CACExpensesOnly.Formula = `${SalesAndMarketingTotals.getItemByDateSufix(year.sufix)} / ${TotalSignUps.getItemByDateSufix(year.sufix)}`;
      curYear.LTVToCACRatio.Formula = `${curYear.LTV} / ${curYear.CACFullyLoaded}`;
      curYear.MonthsToRecoverCAC.Formula = `${curYear.CACFullyLoaded} / (${curYear.AverageMRRNewCustomers} * ${ProfitLossReport.getItemByDateSufix(year.sufix)})`;
      curYear.ProductivityPerSMEmployee.Formula = `${TotalRevenue.getItemByDateSufix(year.sufix)} / ${TotalSalesAndMarketingEmployees.getItemByDateSufix(year.sufix)}`;
      curYear.CustomerLifetimeMonths.Formula = `1 / ${TotalChurnRate.getItemByDateSufix(year.sufix)}`;
    });
  };

  createYearsFormulas = () => {
    // const years = this.getYearDatesAll();
    //
    // years.forEach(year => {
    //     const curYear = this.periodsData[year.dateID];
    //     const yearMonths = year.months;
    //     const lastMonth = yearMonths[yearMonths.length - 1];
    //
    //
    // });
  };
  createDrivers = (recreate = false) => {
    Object.values(this.constructor.DriversDesc).forEach((driver) => {
      if (this.hasOwnProperty(driver.fieldName) == false || recreate) {
        this[driver.fieldName] = CalculatedDriver.createDriverFromTable(
          this,
          driver.driverID,
          driver.unit,
          driver.category,
          null,
          true,
        );
        this[driver.fieldName].addMonths(48, true);
      }

      if (this[driver.fieldName] && driver.hasOwnProperty("IsExisting")) {
        this[driver.fieldName].IsExisting = driver.IsExisting;
      }
    });
  };
  static getReportingDrivers = () => {
    const driver = global.Modeliks.ReportsStore.find(
      (d) => d.ReportType === ReportsTypes.SaaSSubscriptionReportingDrivers,
    );
    if (driver) {
      driver.setFormulas();
      return driver;
    } else {
      const newReport = new SaaSReportingSubscription();
      newReport.ID = "reporting_saas_driver_subscription";
      newReport.Name = "Reporting SaaS Drivers Subscription";
      newReport.ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
      newReport.ReportType = ReportsTypes.SaaSSubscriptionReportingDrivers;
      newReport.createDrivers();
      newReport.setReport();
      newReport.changeDriversName();
      global.Modeliks.ReportsStore.push(newReport);
      return newReport;
    }
  };
}

export default SaaSReportingSubscription;
