import React from "react";
import CircleLoader from "../../../../components/Loaders/CircleLoader/CircleLoader";
import { Navigate } from "react-router-dom";
import request from "superagent";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import ButtonMui from "../../../../components/buttons/buttonMui/buttonMui";

export default class Invite extends React.Component {
  constructor(props) {
    super(props);

    this.windowLocation = window.location.href.split("/");
    this.user_id = this.windowLocation[this.windowLocation.length - 1];
    this.invite_guid = this.windowLocation[this.windowLocation.length - 2];

    request
      .post("/api/acceptInvite")
      .send({ Invite_GUID: this.invite_guid, User_ID: this.user_id })
      .then((data) => {
        if (data.body === "limit reached") {
          this.setState({ openLimit: true });
        } else {
          this.setState({ loading: false, route: data.body.toString() });
          window.localStorage.setItem("accepted_invite", "true");
        }
      })
      .catch((e) => {});

    this.state = {
      loading: true,
      route: "/",
    };
  }

  render() {
    if (this.state.openLimit) {
      return (
        <Dialog className={"company_limit_dialog"} open={true}>
          <DialogContent>
            <div className={"p1_strong cld_heading"}>
              Unable to accept invite: Users limit reached!
            </div>
          </DialogContent>
          <DialogActions>
            <ButtonMui
              label={"Go to Login"}
              variant={"contained"}
              onClick={() => this.setState({ route: "/", openLimit: false, loading: false })}
            />
          </DialogActions>
        </Dialog>
      );
    }
    if (this.state.loading) {
      return (
        <div className={"main_circle_loader_container"}>
          <CircleLoader />
        </div>
      );
    } else {
      return <Navigate replace to={this.state.route} />;
    }
  }
}
