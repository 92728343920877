import React from "react";
import styled from "styled-components";
import { DialogContent, IconButton } from "@mui/material";
import { Theme } from "../../../../theme/styles/theme";
import "./add_dialog.scss";
import { ArrowDown, ArrowUp } from "../../../icons/svgIcons";

const SyledMainContainer = styled.div`
  ${Theme.text.paragraph.p2_strong};
  color: #252525;
  background: #ffffff;
  border: 1px solid #f0f1f7;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 5px 20px 16px 10px;
  min-height: 300px;
`;
const scrollArrow = (top = true, onClick, className = "") => {
  const Icon = top ? <ArrowUp width="18" height="18" /> : <ArrowDown width="18" height="18" />;
  return (
    <div className="scdfa_add_dialog_scrollable">
      <div
        className={`scdfa_add_dialog_div ${
          top ? "scdfa_add_dialog_top" : "scdfa_add_dialog_bottom"
        } ${className}`}
      >
        <IconButton
          color="primary"
          onClick={() => onClick()}
          size="large"
          sx={{ color: "#01AFFD" }}
        >
          {Icon}
        </IconButton>
      </div>
    </div>
  );
};

export const AddDialogContent = ({ ...props }) => {
  return <ScrollerComponent {...props} />;
};

export const ScrollerComponent = (props) => {
  if (props.closeOnEscapeClick) {
    React.useEffect(() => {
      document.addEventListener("keyup", handleEscapeKeyPress);

      return () => {
        document.removeEventListener("keyup", handleEscapeKeyPress);
      };
    });

    const handleEscapeKeyPress = (event) => {
      if (event.key === "Escape") {
        props.onClose();
      }
    };
  }

  const [showTopBtn, setShowTopBtn] = React.useState(false);
  const [showBottomBtn, setShowBottomBtn] = React.useState(false);

  const divRef = React.useRef(null);

  React.useEffect(() => {
    if (divRef.current && divRef.current.scrollHeight > divRef.current.clientHeight) {
      setShowBottomBtn(true);
    } else {
      setShowBottomBtn(false);
    }
  }, [divRef]);

  const scrollToBottom = () => {
    divRef.current.scroll({
      top: divRef.current.scrollHeight,
      behavior: "smooth",
    });
  };

  if (props.toggleObj) {
    props.toggleObj.scrollToBottom = scrollToBottom;
  }

  const scrollToTop = () => {
    divRef.current.scroll({ top: 0, behavior: "smooth" });
  };
  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop - 10 <= e.target.clientHeight;

    if (bottom) {
      if (showBottomBtn) {
        setShowBottomBtn(false);
      }
    } else {
      if (!showBottomBtn) {
        setShowBottomBtn(true);
      }
    }

    if (e.target.scrollTop < 10) {
      if (showTopBtn) {
        setShowTopBtn(false);
      }
    } else {
      if (!showTopBtn) {
        setShowTopBtn(true);
      }
    }
  };

  if (divRef && divRef.current) {
    setTimeout(() => {
      if (divRef && divRef.current) {
        if (divRef.current.scrollHeight === divRef.current.clientHeight) {
          if (showBottomBtn) {
            setShowBottomBtn(false);
          }
          if (showTopBtn) {
            setShowTopBtn(false);
          }
        }
        if (divRef.current.scrollHeight > divRef.current.clientHeight) {
          if (
            !showBottomBtn &&
            divRef.current.scrollHeight - divRef.current.scrollTop - 10 >
              divRef.current.clientHeight
          ) {
            setShowBottomBtn(true);
          }
        }
      }
    }, 1000);
  }

  const getContent = () => {
    if (props.onboarding) {
      return (
        <div onScroll={handleScroll} ref={divRef} className={`${props.className}`}>
          {props.children}
        </div>
      );
    }
    return (
      <DialogContent
        onScroll={handleScroll}
        ref={divRef}
        dividers
        sx={{ borderBottom: 0, paddingBottom: 0 }}
        className="scdfa_add_dialog_content"
      >
        <SyledMainContainer>{props.children}</SyledMainContainer>
      </DialogContent>
    );
  };

  return (
    <>
      {showTopBtn && scrollArrow(true, scrollToTop)}
      {getContent()}
      {showBottomBtn &&
        scrollArrow(false, scrollToBottom, props.applyStyle && "apply_scroll_buttons")}
    </>
  );
};
