import React from "react";
import Dialog from "@mui/material/Dialog";
import "./ShareDialog.scss";
import { MenuItem } from "@szhsin/react-menu";
import { Exit } from "../IconButtons/SubMenuIcons";
import ButtonJ from "../Buttons/Button";
import IconButton from "@mui/material/IconButton";
import request from "superagent";
import CircleLoader from "../../../../components/Loaders/CircleLoader/CircleLoader";
import ButtonMui from "../../../../components/buttons/buttonMui/buttonMui";
import { TrashCan } from "../../../../components/icons/svgIcons";
import { parseDate } from "../../../../pages/Secure/ProfileSettings/AccountDetails";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import InfoDialog from "../../../../components/dialogs/financials/InfoDialog/InfoDialog";
import InfoShareDialog from "./InfoShareDialog";

class ShareDialog extends React.Component {
  constructor(props) {
    super(props);

    this.mailInput = React.createRef();
    this.state = {
      open: false,
      ExistingLink: null,
      mails: [],
      loading: false,
      showCopiedMessage: false,
    };
  }

  handleClickOpen = () => {
    request
      .get(`/api/checkIfFreeTrial`)
      .set("authorization", "Bearer " + window.localStorage.getItem("token"))
      .then((res) => {
        if (res.body.isFreeTrial) {
          this.props.openFreeTrialDialog();
        } else {
          this.props.saveContent &&
            this.props.saveContent(false, () => {
              this.state.open = true;
              this.getExistingLink();
              this.getMails();
            });
        }
      });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  getMails = () => {
    let url = this.props.businessPlan ? "/api/GetBusinessPlanMails" : "/api/getMails";
    let scenarioID = this.props.businessPlan
      ? global.Modeliks.BusinessPlanScenarioInfo.ID
      : this.props.ScenarioID ?? global.Modeliks.PitchScenarioInfo.ID;

    request
      .get(
        `${url}?client_id=${global.Modeliks.Account.ID}&company_id=${global.Modeliks.CompanyInfo.ID}&pitch_scenario_id=${scenarioID}&v=${new Date().getTime()}`,
      )
      .then((res) => {
        if (res.text !== "0") {
          let obj = JSON.parse(res.text);
          this.setState({ mails: obj.mails });
        }
      });
  };

  getExistingLink = () => {
    let url = this.props.businessPlan ? "/api/getBusinessPlanExistingLink" : "/api/getExistingLink";
    let scenarioID = this.props.businessPlan
      ? global.Modeliks.BusinessPlanScenarioInfo.ID
      : this.props.ScenarioID ?? global.Modeliks.PitchScenarioInfo.ID;
    let link = null;
    let createdAt = null;
    let companyScenarioName = null;
    request
      .get(
        `${url}?client_id=${global.Modeliks.Account.ID}&company_id=${global.Modeliks.CompanyInfo.ID}&pitch_scenario_id=${scenarioID}`,
      )
      .then((res) => {
        if (res.text !== "0") {
          let body = res.body;
          link = body.Account;
          createdAt = body.CreatedAt;
          companyScenarioName = body.CompanyScenarioID
            ? global.Modeliks.CompanyScenarios.find((c) => c.ID === body.CompanyScenarioID)
                .ScenarioName
            : null;
        }
        this.setState({ ExistingLink: link, createdAt, companyScenarioName });
      });
  };

  generateLink = () => {
    let url = this.props.businessPlan ? "/api/publishBusinessPlan" : "/api/publish";
    request
      .post(url)
      .set("Content-Type", "application/x-www-form-urlencoded")
      .send({
        client_id: global.Modeliks.Account.ID,
        company_id: global.Modeliks.CompanyInfo.ID,
        pitch_scenario_id: this.props.ScenarioID ?? global.Modeliks.PitchScenarioInfo.ID,
        business_plan_scenario_id: global.Modeliks.BusinessPlanScenarioInfo.ID,
        Company: global.Modeliks.CompanyInfo,
        CompanyScenario: global.Modeliks.CompanyScenarioInfo,
        type: this.props.type ?? "pitch",
      })
      .then((res) => {
        if (res) {
          this.setState({
            ExistingLink: res.body.account,
            createdAt: res.body.createdAt,
            companyScenarioName: global.Modeliks.CompanyScenarioInfo.ScenarioName,
          });
        }
      });
  };

  invite = () => {
    this.setState({ loading: true });

    let value = this.mailInput.current.value;

    if (value) {
      let url = this.props.businessPlan ? "/api/BusinessPlanMails" : "/api/mails";
      let mailArr = value.split(",");

      let emailRegexAllowPlus =
        /^([+\w-]+(?:\.[+\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
      let flag = false;

      for (let i = 0; i < mailArr.length; i++) {
        if (!emailRegexAllowPlus.test(mailArr[i].trim())) {
          flag = true;
          break;
        }
      }

      if (flag) {
        this.setState({ openInvalidMail: true, loading: false });
        return;
      }

      if (this.state.mails.length >= 200 || this.state.mails.length + mailArr.length > 200) {
        this.setState({ openLimitMails: true, loading: false });
        return;
      }

      mailArr.forEach((mail, index) => {
        if (!this.state.mails.some((el) => el.Account === mail)) {
          request
            .post(url)
            .set("Content-Type", "application/x-www-form-urlencoded")
            .send({
              client_id: global.Modeliks.Account.ID,
              company_id: global.Modeliks.CompanyInfo.ID,
              companyName: global.Modeliks.CompanyInfo.CompanyName,
              userEmail: global.Modeliks.User.Name,
              type: this.props.type ?? "pitch",
              pitch_scenario_id: this.props.ScenarioID ?? global.Modeliks.PitchScenarioInfo.ID,
              business_plan_scenario_id: global.Modeliks.BusinessPlanScenarioInfo.ID,
              mail: mail.trim(),
              Company: global.Modeliks.CompanyInfo,
              CompanyScenario: global.Modeliks.CompanyScenarioInfo,
            })
            .then((res) => {
              if (index === mailArr.length - 1) {
                this.mailInput.current.value = "";
                this.state.loading = false;
                this.getMails();
              }
            });
        } else {
          if (index === mailArr.length - 1) {
            this.mailInput.current.value = "";
            this.setState({ loading: false }, () => {
              this.getMails();
            });
          }
        }
      });
    }
  };

  resendMail = (mail, ID) => {
    let url = this.props.businessPlan ? "/api/ResendBusinessPlanMail" : "/api/resendMail";
    request
      .put(url)
      .set("Content-Type", "application/x-www-form-urlencoded")
      .send({
        client_id: global.Modeliks.Account.ID,
        company_id: global.Modeliks.CompanyInfo.ID,
        pitch_scenario_id: this.props.ScenarioID ?? global.Modeliks.PitchScenarioInfo.ID,
        business_plan_scenario_id: global.Modeliks.BusinessPlanScenarioInfo.ID,
        companyName: global.Modeliks.CompanyInfo.CompanyName,
        userEmail: global.Modeliks.User.Name,
        mail: mail,
        ID: ID,
        Company: global.Modeliks.CompanyInfo,
        CompanyScenario: global.Modeliks.CompanyScenarioInfo,
        type: this.props.type ?? "pitch",
      })
      .then((res) => {
        if (res.text === "1") {
          this.getMails();
        }
      });
  };

  removeMailAccess = (ID) => {
    let url = this.props.businessPlan ? "/api/RemoveBusinessPlanAccess" : "/api/removeAccess";
    request
      .put(url)
      .set("Content-Type", "application/x-www-form-urlencoded")
      .send({ client_id: global.Modeliks.Account.ID, ID: ID })
      .then((res) => {
        if (res.text === "1") {
          this.getMails();
        }
      });
  };

  removeAccessToLink = () => {
    let url = this.props.businessPlan ? "/api/BusinessPlanPublish" : "/api/publish";
    request
      .put(url)
      .set("Content-Type", "application/x-www-form-urlencoded")
      .send({
        client_id: global.Modeliks.Account.ID,
        company_id: global.Modeliks.CompanyInfo.ID,
        pitch_scenario_id: global.Modeliks.PitchScenarioInfo.ID,
      })
      .then((res) => {
        if (res.text === "1") {
          this.setState({ ExistingLink: null });
        }
      });
  };

  copyLink = () => {
    navigator.clipboard.writeText(this.state.ExistingLink);
    this.setState({ showCopiedMessage: true });
    setTimeout(() => {
      this.setState({ showCopiedMessage: false });
    }, 1000);
  };

  handleDeleteMail = (ID) => {
    global.Modeliks.del("publishaccounts", { ID: ID }, (successDel, errorDel) => {
      if (successDel) {
        this.getMails();
      }
    });
  };

  // handleSave = () => {
  //     props.addNewSection(name, () => handleClose())
  // }

  getTableCell = (cell, key) => {
    return (
      <TableCell className={`sd_mc_im_label ${!cell.isActive ? "inactive" : ""}`}>
        <span>{cell[key]}</span>
      </TableCell>
    );
  };

  getNakedCell = (isActive, content) => (
    <Tooltip enterDelay={500} enterNextDelay={500} leaveDelay={200} arrow title={content}>
      <TableCell className={`sd_mc_im_label ${!isActive ? "inactive" : ""}`}>{content}</TableCell>
    </Tooltip>
  );

  getCell = (item, key) => this.getNakedCell(item.isActive, item[key]);

  render() {
    return (
      <React.Fragment>
        <MenuItem onClick={() => this.handleClickOpen()} className={"hd_rc_sm_menu_item"}>
          Share
        </MenuItem>
        <Dialog
          fullWidth={true}
          maxWidth={"lg"}
          open={this.state.open}
          className={"share_dialog"}
          onClose={this.handleClose}
        >
          <div className={"share_dialog_main_container"}>
            <div className={"sd_mc_close_container"}>
              <span className={"p1Strong"}>Share File</span>

              <IconButton onClick={this.handleClose}>
                <Exit />
              </IconButton>
            </div>
            <div className={"sd_mc_invite_container"}>
              <span className={"p2Strong"}>Share via Email</span>
            </div>
            <div className={"sd_mc_message_container"}>
              <span className={"sd_mc_mc_paragraph"}>
                Anyone you invite must log in to view this version of the report. You can revoke
                access to any user at any time. Please note that any changes made after sharing the
                report will not be reflected in the shared version.
              </span>
            </div>
            <div className={"sd_mc_submit_invite_container"}>
              <div className={"sd_mc_sic_input_container"}>
                <input
                  ref={this.mailInput}
                  onKeyDown={(e) => {
                    e.stopPropagation();
                  }}
                  onPaste={(e) => {
                    e.stopPropagation();
                  }}
                  className={"sd_mc_sic_input"}
                  type="text"
                  placeholder={"Insert multiple Emails comma separated"}
                />
              </div>
              <div className={this.state.loading ? "loader_container" : "loader_container hidden"}>
                <div className={"loader_small"}>
                  <CircleLoader />
                </div>
              </div>
              <ButtonMui label={"Invite"} variant={"contained"} onClick={this.invite} width={136} />
            </div>
            {this.state.mails.length > 0 && (
              <div className={"sd_mc_invited_mails_container"}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow className={"sd_mc_im_headers"}>
                      <TableCell className={"sd_mc_im_label"}>
                        <span>Email</span>
                      </TableCell>
                      <TableCell className={"sd_mc_im_label"}>
                        <span>Name</span>
                      </TableCell>
                      <TableCell className={"sd_mc_im_label"}>
                        <span>Forecast Scenario</span>
                      </TableCell>
                      <TableCell className={"sd_mc_im_label"}>
                        <span>Date & Time</span>
                      </TableCell>
                      <TableCell className={"sd_mc_im_label"}>
                        <wbr />
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {this.state.mails.map((c) => {
                      return (
                        <TableRow className={"sd_mc_im_row"}>
                          {this.getCell(c, "Account")}
                          {this.getNakedCell(
                            c.isActive,
                            this.props.ScenarioName ||
                              (this.props.businessPlan
                                ? global.Modeliks.BusinessPlanScenarioInfo.Name
                                : global.Modeliks.PitchScenarioInfo.Name),
                          )}
                          {this.getNakedCell(
                            c.isActive,
                            c.CompanyScenarioID
                              ? global.Modeliks.CompanyScenarios.find(
                                  (d) => d.ID === c.CompanyScenarioID,
                                ).ScenarioName
                              : "",
                          )}
                          {this.getNakedCell(c.isActive, parseDate(c.CreatedAt))}
                          <TableCell className={`sd_mc_im_label`}>
                            <div className={"sd_mc_im_buttons_container"}>
                              <ButtonMui
                                variant={"text"}
                                style={{ fontSize: "12px" }}
                                label={c.isActive ? "Revoke Access" : "Resend Access"}
                                onClick={
                                  c.isActive
                                    ? () => this.removeMailAccess(c.ID)
                                    : () => this.resendMail(c.Account, c.ID)
                                }
                              />
                              <IconButton
                                disabled={c.isActive}
                                onClick={() => this.handleDeleteMail(c.ID)}
                              >
                                <TrashCan />
                              </IconButton>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>

                {/*{this.state.activeMails.map(c => {*/}
                {/*    return <div className={'sd_mc_im_mail_valid'}>*/}
                {/*        <span className={'sd_mc_im_mv_mail'}>{c.mail}</span>*/}
                {/*        <ButtonMui label={'Remove Access'} color={'black'} variant={'text'}*/}
                {/*                   onClick={() => this.removeMailAccess(c.ID)} width={136}/>*/}
                {/*    </div>*/}
                {/*})}*/}
                {/*{this.state.inactiveMails.map(c => {*/}
                {/*    return <div className={'sd_mc_im_mail_expired'}>*/}
                {/*        <div className={'sd_mc_im_me_mail_container'}>*/}
                {/*            <span className={'sd_mc_im_me_mail'}>{c.mail}</span>*/}
                {/*            <ButtonMui label={'Resend'} variant={'text'}*/}
                {/*                       onClick={() => this.resendMail(c.mail, c.ID)}/>*/}
                {/*        </div>*/}
                {/*        <ButtonMui label={'Remove Access'} color={'black'} variant={'text'} disabled*/}
                {/*                   width={136}/>*/}
                {/*    </div>*/}
                {/*})*/}
                {/*}*/}
              </div>
            )}
            <div className={"sd_mc_send_link"}>
              <div className={"sd_mc_invite_container"}>
                <span className={"p2Strong"}>Share via link</span>
              </div>
              <div className={"sd_mc_message_container"}>
                <span className={"sd_mc_mc_paragraph"}>
                  Anyone with the link can view this version of the report without logging in. You
                  can revoke access to the link at any time. Please note that any changes made to
                  the report after generating the link will not be reflected in the link version.
                </span>
              </div>
            </div>
            {!this.state.ExistingLink && (
              <div className={"sd_mc_button_container"}>
                <ButtonMui
                  label={"Generate Link"}
                  variant={"contained"}
                  onClick={this.generateLink}
                />
              </div>
            )}
            {this.state.ExistingLink != null && (
              <div>
                <div className={"sd_mc_copy_link_container"}>
                  <div className={"sd_mc_cl_input_container"}>
                    <input
                      className={"sd_mc_cl_ic_input"}
                      type="text"
                      disabled={true}
                      value={this.state.ExistingLink}
                    />
                  </div>
                  {this.state.ExistingLink != null && (
                    <div className={"sd_mc_sl_cb_container"}>
                      <IconButton onClick={this.removeAccessToLink}>
                        <TrashCan />
                      </IconButton>
                    </div>
                  )}
                  <ButtonMui
                    label={"Copy link"}
                    variant={"contained"}
                    onClick={this.copyLink}
                    width={136}
                  />
                </div>
                <span
                  className={"sd_mc_span"}
                >{`Link Created on ${parseDate(this.state.createdAt)},${this.props.ScenarioNamePrefix || ""} ${this.props.ScenarioName || (this.props.businessPlan ? global.Modeliks.BusinessPlanScenarioInfo.Name : global.Modeliks.PitchScenarioInfo.Name)} ${this.state.companyScenarioName}`}</span>
              </div>
            )}
            {this.state.showCopiedMessage && (
              <div className={"sd_mc_copied_message"}>copied to clipboard</div>
            )}
          </div>
          <InfoShareDialog
            open={this.state.openInvalidMail}
            handleClose={() => {
              this.setState({ openInvalidMail: false });
            }}
            text={"Please enter a valid E-mail adress"}
          />
          <InfoShareDialog
            open={this.state.openLimitMails}
            handleClose={() => {
              this.setState({ openLimitMails: false });
            }}
            text={
              "You have reached the maximum number of [200] invited Emails. Please reorganize your invitations."
            }
          />
        </Dialog>
      </React.Fragment>
    );
  }
}

export default ShareDialog;
