import * as React from "react";
import Dialog from "@mui/material/Dialog";
import ButtonJ from "../Buttons/Button";
import TabsMenu from "../menus/TabsMenu/TabsMenu";
import "./SectionDialog.scss";
import IconButton from "@mui/material/IconButton";
import { BluePlus, Exit } from "../IconButtons/SubMenuIcons";
import "./SectionName.scss";
import CircleCheckboxM from "../../../../components/actions/CircleCheckboxM";
import Button from "../../../../components/actions/Button";
import { IconPlus } from "../../../../components/icons/svgIcons";
import ButtonMui from "../../../../components/buttons/buttonMui/buttonMui";

export default function AddNewFileDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [name, setName] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setSuccess(false);
      setName("Folder name");
    }, 150);
  };

  const handleSave = () => {
    props.addNewSection(name, () => setSuccess(true));
  };

  return (
    <React.Fragment>
      <ButtonMui
        label={"Create Folder"}
        variant={"text"}
        width={148}
        onClick={handleClickOpen}
        endIcon={<IconPlus />}
      />
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        className={"name_dialog"}
        onClose={handleClose}
      >
        {success ? (
          <>
            <div className={"checkbox_container"}>
              <CircleCheckboxM size="large" checked={true} />
            </div>
            <div className="Label_2">Successfully created</div>
            <div className="Paragraph_P4">Your folder is successfully created</div>
            <ButtonJ
              size="large"
              width="118px"
              padding="0 0 0 0"
              backgroundColor="primary"
              label={"OK"}
              onClick={() => handleClose()}
            />
          </>
        ) : (
          <>
            <div className={"label"}>Create folder</div>
            <div className={"input_container"}>
              <input
                className={"input"}
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder={"Folder name"}
              />
            </div>
            <div className={"btns_container no_gap"}>
              <ButtonMui
                label={"Cancel"}
                variant={"text"}
                width={118}
                color={"black"}
                onClick={handleClose}
              />
              <ButtonMui
                label={"Create Folder"}
                variant={"contained"}
                onClick={handleSave}
                endIcon={<IconPlus />}
              />
            </div>
          </>
        )}
      </Dialog>
    </React.Fragment>
  );
}
