import React from "react";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import { Navigate } from "react-router-dom";
import ButtonMui from "../../../../../components/buttons/buttonMui/buttonMui";
import "./UpgradeLifeTimeDialog.scss";

class CantShareDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: true,
    };
  }

  handleClose = () => {
    this.props.handleClose();
  };

  render() {
    return (
      <React.Fragment>
        <Dialog
          fullWidth={true}
          maxWidth={"lg"}
          open={this.props.open ? this.props.open : false}
          className={"upgrade_lifetime_dialog"}
          onClose={this.handleClose}
        >
          <div className={"p1_strong_blue"}>Success!</div>

          <div className={"p1_strong cld_heading"}>
            {"You have successfully upgraded to a Modeliks " + this.props.planName + " Plan."}
          </div>

          <div className={"cld_typography2_buttons"}>
            <ButtonMui
              label={"OK"}
              width={"100px"}
              padding={"0 0 0 0"}
              color={"primary"}
              variant={"contained"}
              onClick={this.handleClose}
            />
          </div>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default CantShareDialog;
