import React, { useState, useEffect } from "react";
import "./actual_message.scss";
import {
  tableConfig,
  tableConfigTypes,
  tableObject,
} from "../../../../../components/tables/FinanceTable/components/constants";
import LocalStorage from "../../../../../helpers/LocalStorage";

const getSizeOfMessage = () => {
  return LocalStorage.get(tableObject, tableConfig, tableConfigTypes.fontSize);
};

function ActualMessage(props) {
  const size = getSizeOfMessage();
  return (
    <>
      <div className={`psdac_message_actual_div size-${size}`}>
        <span className="psdac_message_actual_div_text">{props.message}</span>
      </div>
    </>
  );
}

export default ActualMessage;
