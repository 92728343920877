// import HeaderPortal from './HeaderPortal'
import React, { Fragment, useState } from "react";
import "../HeaderTextEditor.scss";
import "../HeaderDefault.scss";
import { Charts, FinancialCharts } from "../../components/IconButtons/SubMenuIcons";
import {
  BubbleChart,
  ClusteredHorizontalBar,
  ClusteredVerticalBar,
  Combo,
  DoughnutChart,
  FloatingBarChart,
  HorizontalBar,
  LineChart,
  PieChart,
  ScatterChart,
  StackedBarChart,
  StackedBarLine,
  VerticalBar,
} from "../../components/IconButtons/SubMenuIcons";
import { SlideObject, SlideTypes } from "../../SlideObjects/SlideStructures";
import { Menu, MenuItem, SubMenu, MenuButton } from "@szhsin/react-menu";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Mx_Tooltip_Hover from "../../../../components/icons/Mx_Tooltip_Hover";

function InsertFinancialChartComponent(props) {
  const handleNewChartObj = (chartType, top, left, width, height) => {
    let showTitle = false;
    let showSubtitle = false;
    let titleAlign = "start";
    let displayLegend = false;
    let legendPosition = "bottom";
    let xGrid = false;
    let yGrid = false;
    const props1 = {
      chartType,
      top,
      left,
      width,
      height,
      showTitle,
      showSubtitle,
      titleAlign,
      displayLegend,
      legendPosition,
      xGrid,
      yGrid,
    };
    const nObj = new SlideObject(SlideTypes.chartObject, null, props1);
    props.onNewObject(nObj);
  };

  const chartMenuItem = (itemTitle, frequencyInput, displayFrequency, description) => {
    let frequency = null;
    if (frequencyInput === "m") {
      frequency = "monthly";
    } else {
      frequency = "annuallyY";
    }
    const items = [
      ...global.Modeliks.PitchFinancialChartsYearly,
      ...global.Modeliks.PitchFinancialChartsMonthly,
    ];

    const item = items.find(
      (data) =>
        data.Data.chartOptions.title === itemTitle &&
        data.Data.chartData.reportFrequency === frequency,
    );

    if (!item) return;

    return (
      <MenuItem
        onClick={() =>
          props.onNewObject(
            new SlideObject(SlideTypes.financialChart, null, {
              ID: item.ID,
              width: 1100,
              height: 530,
              top: 170,
              left: 90,
            }),
          )
        }
      >
        <span className={"item_name nested_item_name"}>
          {item.Data.chartOptions.title}{" "}
          {displayFrequency ? `(${frequencyInput === "m" ? "Monthly" : "Annual"})` : ""}
        </span>

        {description && (
          <Mx_Tooltip_Hover
            disableStyle={true}
            title={description}
            infoIcon={true}
          ></Mx_Tooltip_Hover>
        )}
      </MenuItem>
    );
  };

  const menuItems = () => {
    return (
      <>
        <SubMenu label={<span className="item_name">Revenue</span>}>
          <SubMenu className={"nested_sub_menu"} label={<span className="item_name">Monthly</span>}>
            {chartMenuItem("Revenue", "m", true)}
          </SubMenu>
          <SubMenu className={"nested_sub_menu"} label={<span className="item_name">Annual</span>}>
            {chartMenuItem("Revenue and Profits", "a", true)}
            {chartMenuItem("Revenue", "a", true)}
          </SubMenu>
        </SubMenu>

        <SubMenu label={<span className="item_name">Expenses</span>}>
          <SubMenu className={"nested_sub_menu"} label={<span className="item_name">Monthly</span>}>
            {chartMenuItem("Operating expenses", "m", true)}
          </SubMenu>
          <SubMenu className={"nested_sub_menu"} label={<span className="item_name">Annual</span>}>
            {chartMenuItem("Operating expenses", "a", true)}
          </SubMenu>
        </SubMenu>

        <SubMenu label={<span className="item_name">Profits / income</span>}>
          <SubMenu className={"nested_sub_menu"} label={<span className="item_name">Monthly</span>}>
            {chartMenuItem("Gross profit", "m", true)}
            {chartMenuItem("EBITDA", "m", true)}
            {chartMenuItem("Operating profit (EBIT)", "m", true)}
            {chartMenuItem("Net Income", "m", true)}
          </SubMenu>
          <SubMenu className={"nested_sub_menu"} label={<span className="item_name">Annual</span>}>
            {chartMenuItem("Gross profit", "a", true)}
            {chartMenuItem("EBITDA", "a", true)}
            {chartMenuItem("Operating profit (EBIT)", "a", true)}
            {chartMenuItem("Net Income", "a", true)}
          </SubMenu>
        </SubMenu>

        <SubMenu label={<span className="item_name">Profitability ratios</span>}>
          <SubMenu className={"nested_sub_menu"} label={<span className="item_name">Monthly</span>}>
            {chartMenuItem("EBITDA margin", "m", true)}
            {chartMenuItem("Gross profit margin", "m", true)}
            {chartMenuItem("Operating profit margin", "m", true)}
            {chartMenuItem("Net income margin", "m", true)}
          </SubMenu>
          <SubMenu className={"nested_sub_menu"} label={<span className="item_name">Annual</span>}>
            {chartMenuItem("Margins", "a", true)}
            {chartMenuItem(
              "Return on assets",
              "a",
              true,
              "Use the return on assets ratio to determine how much profits is generated from total assets or resources, including current and noncurrent assets.",
            )}
            {chartMenuItem(
              "Return on equity",
              "a",
              true,
              "The return on equity measures how much profit a business generates from shareholders’ equity.",
            )}
          </SubMenu>
        </SubMenu>

        <SubMenu label={<span className="item_name">Assets</span>}>
          <SubMenu className={"nested_sub_menu"} label={<span className="item_name">Monthly</span>}>
            {chartMenuItem("Cash balance", "m", true)}
          </SubMenu>
          <SubMenu className={"nested_sub_menu"} label={<span className="item_name">Annual</span>}>
            {chartMenuItem("Cash balance", "a", true)}
            {chartMenuItem("Assets", "a", true)}
          </SubMenu>
        </SubMenu>

        <SubMenu label={<span className="item_name">Cash flow</span>}>
          <SubMenu className={"nested_sub_menu"} label={<span className="item_name">Monthly</span>}>
            {chartMenuItem("Net cashflow", "m", true)}
            {chartMenuItem("Operating cash flow", "m", true)}
          </SubMenu>
          <SubMenu className={"nested_sub_menu"} label={<span className="item_name">Annual</span>}>
            {chartMenuItem("Net cash flow", "a", true)}
          </SubMenu>
        </SubMenu>

        <SubMenu label={<span className="item_name">Liquidity ratios</span>}>
          <SubMenu className={"nested_sub_menu"} label={<span className="item_name">Annual</span>}>
            {chartMenuItem(
              "Current ratio",
              "a",
              true,
              "The current ratio measures how a business’s current assets, such as cash, cash equivalents, accounts receivable, and inventories, are used to settle current liabilities such as accounts payable.",
            )}
            {chartMenuItem(
              "Operating cashflow ratio",
              "a",
              true,
              "The operating cash flow ratio (OCF) evaluates the near-term liquidity risk of a company by comparing its cash flows from operations, i.e. core business activities, and current liabilities.",
            )}
            {chartMenuItem(
              "Cash ratio",
              "a",
              true,
              "The cash ratio measures a business’s ability to use cash and cash equivalent to pay off short-term liabilities. This ratio shows how quickly a company can settle current obligations.",
            )}
          </SubMenu>
        </SubMenu>

        <SubMenu label={<span className="item_name">Leverage ratios</span>}>
          <SubMenu className={"nested_sub_menu"} label={<span className="item_name">Annual</span>}>
            {chartMenuItem(
              "Debt to equity ratio",
              "a",
              true,
              "The debt-to-equity ratio measures a company’s debt liability compared to shareholders’ equity. This ratio is important for investors because debt obligations often have a higher priority if a company goes bankrupt.",
            )}
            {chartMenuItem(
              "Interest coverage ratio",
              "a",
              true,
              "The interest coverage ratio shows if a company’s revenue after operating expenses can cover interest liabilities.",
            )}
          </SubMenu>
        </SubMenu>

        <SubMenu label={<span className="item_name">Operational</span>}>
          <SubMenu className={"nested_sub_menu"} label={<span className="item_name">Monthly</span>}>
            {chartMenuItem("Number of employees", "m", true)}
            {chartMenuItem("Number of customers", "m", true)}
          </SubMenu>
        </SubMenu>
      </>
    );
  };

  //<Tooltip disableHoverListener={props.disableEdit}  arrow title={'Insert Chart'}><span>
  // </span></Tooltip>
  const ChartInsert = () => {
    return (
      <SubMenu
        className="header_menu smaller_header_menu arrow"
        label={({ open }) => (
          <span>
            <FinancialCharts active={open} />
          </span>
        )}
      >
        {menuItems()}
        {/*<MenuItem onClick={()=>handleNewChartObj(6,100,20,600, 300)}>*/}
        {/*    <HorizontalBar/>*/}
        {/*    <span className='item_name'>Horizontal Bar</span>*/}
        {/*    /!*<span className='helper_text'>Shift+Alt</span>*!/*/}
        {/*</MenuItem>*/}
      </SubMenu>
    );
  };

  return <>{ChartInsert()}</>;
}

export default InsertFinancialChartComponent;
