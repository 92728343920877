import CalculatedDriver from "../../CalculatedDriver/index";
import { DriverCategories, UnitTypes } from "../../CalculatedDriver/constants";
import ProfitLoss from "../../Reports/ProfitLoss";
import Revenue from "../../Revenue";
import { ReportsTypes } from "../../Reports/constants";

class FinancialStatementsReport {
  static getBalanceSheetLastMonthsDrivers = (months) => {
    const lastMonths = months.map((v) => v && v[v.length - 1] && v[v.length - 1].ID_f).join(",");
    if (lastMonths) {
      return lastMonths;
    }
  };

  static getBalanceSheetFirstMonthDrivers = (months) => {
    const firstMonth = months.map((v) => v && v[0] && v[0].ID_f).join(",");
    if (firstMonth) {
      return firstMonth;
    }
  };
  static getBalanceSheetFirstMonth = (months) => {
    return [months[0]];
  };

  static getBalanceSheetLastMonth = (months) => {
    return [months[months.length - 1]];
  };
  static getDatesByOrder = (Dates) => {
    if (Dates.hasOwnProperty("ShowMonthly") && Dates.showMonthly === false) {
      return Dates.allYears.filter(
        (date) => date.Order >= Dates.startYearOrder && date.Order <= Dates.endYearOrder,
      );
    } else {
      return Dates.allMonths.filter(
        (date) => date.Order >= Dates.startMonthOrder && date.Order <= Dates.endMonthOrder,
      );
    }
  };
  static getDatesByPreviousOrder = (Dates, StartEndMonths) => {
    if (Dates.hasOwnProperty("ShowMonthly") && Dates.showMonthly === false) {
      return Dates.allYears.filter(
        (date) =>
          date.Order < Dates.startYearOrder &&
          date.Order >= Dates.startYearOrder - StartEndMonths.length,
      );
    } else {
      return Dates.allMonths.filter(
        (date) =>
          date.Order < Dates.startMonthOrder &&
          date.Order >= Dates.startMonthOrder - StartEndMonths.length,
      );
    }
  };
  static getDatesByPreviousYear = (Dates) => {
    if (Dates.hasOwnProperty("ShowMonthly") && Dates.showMonthly === false) {
      return Dates.allYears.filter(
        (date) => date.Order >= Dates.startYearOrder - 12 && date.Order <= Dates.endYearOrder - 12,
      );
    } else {
      return Dates.allMonths.filter(
        (date) =>
          date.Order >= Dates.startMonthOrder - 12 && date.Order <= Dates.endMonthOrder - 12,
      );
    }
  };
  static getDriversByDates = (driver, Dates) => {
    const Drivers = Dates.map((m) => {
      if (m) {
        return driver.getItemByDateSufix(m.sufix);
      } else {
        console.log("driver null by date", m, Dates);
      }
    });

    return Drivers.filter((d) => d);

    return Dates.map((m) => {
      if (m) {
        return driver.getItemByDateSufix(m.sufix);
      } else {
        console.log("driver", m, Dates);
      }
    });
  };
  static setPercentageFormulas = (
    driver,
    formula,
    Dates,
    DriverCategory = "Sum",
    MonthsByStartEndOrder,
    setActual = false,
    FullDriver,
    DatesPrevPeriod,
  ) => {
    if (!driver || !Dates) {
      return;
    }

    const ProfitLossDrivers = [
      "margin_gross_profit",
      "margin_ebitda",
      "margin_operating_profit",
      "margin_profit_before_tax",
      "margin_net_income",
    ];
    if (ProfitLossDrivers.some((d) => formula.includes(d))) {
      const RevenueTotals = FinancialStatementsReport.getDriversByDates(
        Revenue.getRevenueTotals(),
        Dates,
      );
      const ProfitLoss = global.Modeliks.ReportsStore.find(
        (r) => r.ReportType === ReportsTypes.ProfitLoss,
      );
      const driverToMap = {
        margin_gross_profit: ProfitLoss.GrossProfit,
        margin_ebitda: ProfitLoss.EBITDA,
        margin_operating_profit: ProfitLoss.OperatingProfit,
        margin_profit_before_tax: ProfitLoss.ProfitBeforeTax,
        margin_net_income: ProfitLoss.NetIncome,
      };

      ProfitLossDrivers.forEach((driverID) => {
        if (formula.includes(driverID)) {
          const driverValues = FinancialStatementsReport.getDriversByDates(
            driverToMap[driverID],
            Dates,
          );
          driver.Formula = `(MxMath.Sum([${driverValues.map((c) => c.ID_f).join(",")}]) / MxMath.Sum([${RevenueTotals.map((c) => c.ID_f).join(",")}])) * 100`;
        }
      });
    } else {
      if (Dates && driver && MonthsByStartEndOrder) {
        if (FullDriver.DriverName === "Revenue Growth") {
          const RevenueTotals = FinancialStatementsReport.getDriversByDates(
            Revenue.getRevenueTotals(),
            Dates,
          );
          const driverValues = FinancialStatementsReport.getDriversByDates(
            Revenue.getRevenueTotals(),
            DatesPrevPeriod,
          );
          driver.Formula = `(MxMath.Sum([${RevenueTotals.map((c) => c.ID_f).join(",")}]) / MxMath.Sum([${driverValues.map((c) => c.ID_f).join(",")}]) - 1) * 100`;
          if (setActual) {
            driver.evalFormulaActual = driver.evalFormula;
          }
        } else {
          if (driver.UnitType === UnitTypes.Percentage) {
            driver.Formula = `MxMath.${DriverCategory}([${MonthsByStartEndOrder}]) * 100`;
          } else {
            driver.Formula = `MxMath.${DriverCategory}([${MonthsByStartEndOrder}])`;
          }
          if (setActual) {
            driver.evalFormulaActual = driver.evalFormula;
          }
        }
      }
    }
  };

  static getDashboardDrivers = (
    reportList = [],
    months,
    customID = false,
    isBalanceSheetReport = false,
    isProfitLoss = false,
  ) => {
    let dashboardsDrivers = [];
    if (reportList && reportList.length > 0) {
      const showMonthly = months.hasOwnProperty("showMonthly") && months.showMonthly === false;
      const startMonth = showMonthly ? months.startMonthOrder : months.startYearOrder;
      const endMonth = showMonthly ? months.endMonthOrder : months.endYearOrder;
      const endYear = months.endYear;
      const MonthsByStartEndOrder = FinancialStatementsReport.getDatesByOrder(months);
      const MonthsPreviousPeriodOrder = FinancialStatementsReport.getDatesByPreviousOrder(
        months,
        MonthsByStartEndOrder,
      );
      const MonthsPreviousYearOrder = FinancialStatementsReport.getDatesByPreviousYear(
        months,
        MonthsByStartEndOrder,
      );
      dashboardsDrivers = reportList.map((driver) =>
        FinancialStatementsReport.createGridRows(
          driver,
          false,
          months,
          showMonthly,
          customID,
          startMonth,
          endMonth,
          { MonthsByStartEndOrder, MonthsPreviousPeriodOrder, MonthsPreviousYearOrder },
          isBalanceSheetReport,
          isProfitLoss,
        ),
      );
    }

    return dashboardsDrivers;
  };

  static createGridRows = (
    revenue,
    isChild = false,
    months,
    isMonthly = true,
    customID = false,
    startMonth,
    endMonth,
    endYear,
    isBalanceSheetReport,
    isProfitLoss,
  ) => {
    if (!revenue) {
      return;
    }

    const row = {
      id: revenue.ID,
      header: revenue.Name,
      revenue: revenue,
      hasEdit: false,
      disabledEdit: true,
      isExpanded: true,
      // isEditable: true,
      boldRowHeader: false,
      applyBoldWeight: true,
      field: isProfitLoss,
      data: null,
      children: [],
      colorType: {
        positiveGreen: true,
        negativeGreen: false,
      },
      views: {
        data: revenue,
        tmpData: null,
      },
    };

    const PositiveGreenFalseTables = [
      global.Modeliks.Tables.Finance_CostSales.TableName,
      global.Modeliks.Tables.Finance_Personnel.TableName,
      global.Modeliks.Tables.Finance_Financing.TableName,
      global.Modeliks.Tables.Finance_Expenses.TableName,
    ];

    const PositiveGreenFalseNames = [
      "Sale of Property, Plant & Equipment",
      "Depreciation",
      "Interest Expense",
    ];

    if (PositiveGreenFalseTables.includes(revenue.Ref_Table) || revenue.isExpense) {
      row.colorType.positiveGreen = false;
    }

    const displayDriver = !revenue.IsSimple && !isChild;
    const word = "_quarterly_view";
    const hideDrivers = ["interest_expense_driver", "sale_of_property_and_equipment_driver"];

    let driverID = null;
    let displayChildrenDrivers = false;

    if (customID) {
      driverID = `${revenue.ID}_${customID.toString()}_${word}_cstid`;
    } else {
      driverID = revenue.ID + word;
    }

    let tmpDriver = global.Modeliks.DriversStore.getItem(driverID);
    if (!tmpDriver) {
      row.views.tmpData = CalculatedDriver.createFinancialStatementsDriver(revenue, driverID);
    } else {
      row.views.tmpData = tmpDriver;
      row.views.tmpData.DriverName = revenue.DriverName;
    }

    if (!isChild) {
      const boldRowHeaderDrivers = [
        "gross_profit",
        "ebitda",
        "operating_profit",
        "net_income",
        "profit_before_tax",
        "net_cash_from_financing_activities",
        "net_cash_from_operating_activities",
        "net_cash_from_investing_activities",
        "net_change_in_cash",
      ];
      const boldRowHeaderDriverName = [
        "Revenue",
        // 'Cost of Goods Sold',
        "Gross Profit",
        "EBITDA",
        "Total Assets",
        "Total Liabilities and Equity",
        "Total Liabilities",
        // 'Equity',
        "Operating Profit",
        "Profit Before Tax",
        "Net Income",
        "Current Assets",
        "Cash At Beginning Of The Period",
        "Net increase (decrease) in cash",
      ];

      row.boldRowHeader =
        boldRowHeaderDriverName.includes(revenue.Name) ||
        boldRowHeaderDrivers.includes(revenue.Ref_Field);
    }

    const childrenDrivers =
      (displayDriver || displayChildrenDrivers) && !hideDrivers.includes(revenue.Ref_ID)
        ? revenue.getChildDrivers()
        : [];

    FinancialStatementsReport.setQuarterly(
      row.views.tmpData,
      revenue.ID_f,
      startMonth,
      endMonth,
      isMonthly,
      endYear,
      isBalanceSheetReport,
      childrenDrivers,
    );
    FinancialStatementsReport.setComparative(
      row.views.tmpData,
      revenue.ID_f,
      [revenue],
      startMonth,
      endMonth,
      isMonthly,
      endYear,
      isBalanceSheetReport,
      childrenDrivers,
    );
    if ((displayDriver || displayChildrenDrivers) && !hideDrivers.includes(revenue.Ref_ID)) {
      const children = revenue.getChildDrivers();
      if (
        children &&
        isChild === false &&
        (!customID || revenue.Ref_Table === global.Modeliks.Tables.Dashboards_CustomKPI.TableName)
      ) {
        children.map((driver) => {
          if (driver) {
            row.children.push(
              FinancialStatementsReport.createGridRows(
                driver,
                true,
                months,
                true,
                customID,
                startMonth,
                endMonth,
                endYear,
                isBalanceSheetReport,
              ),
            );
          }
        });
      }
    } else {
      const allowDeepLevelDriverNames = [
        "Long Term Assets",
        "Current Assets",
        "Current Liabilities",
        "Long Term Liabilities",
        "Retained Earnings",
        "Change In Working Capital",
        "Change In Debt",
        "Total Liabilities",
        "Equity",
      ];

      const boldRowHeaderDriverName = [
        // 'Equity',
        "Gross Profit",
        "EBITDA",
        "Operating Profit",
        "Profit Before Tax",
        "Cash At Beginning Of The Period",
        "Net increase (decrease) in cash",

        // 'Net Income',
      ];

      if (!isBalanceSheetReport) {
      }

      if (!isBalanceSheetReport) {
        boldRowHeaderDriverName.push("Cash Balance");
        boldRowHeaderDriverName.push("Total Liabilities");
      }

      row.boldRowHeader = boldRowHeaderDriverName.includes(revenue.Name);

      if (allowDeepLevelDriverNames.indexOf(revenue.DriverName) > -1) {
        const children = revenue.getChildDrivers();
        if (children && children.length > 0) {
          children.map((driver) => {
            if (
              driver &&
              (driver.DriverName !== "Prepaid Revenue" || driver.DriverName !== "Unearned Revenue")
            ) {
              row.children.push(
                FinancialStatementsReport.createGridRows(
                  driver,
                  true,
                  months,
                  true,
                  customID,
                  startMonth,
                  endMonth,
                  endYear,
                  isBalanceSheetReport,
                ),
              );
            }
          });
        }
      }
    }

    return row;
  };
  static setComparative = (
    driver,
    formula,
    realDrivers,
    startMonth = 0,
    endMonth = 11,
    showMonthly = false,
    datesObject,
    isBalanceSheetReport,
    children,
  ) => {
    driver.Formula = formula;

    const months_comaprative_view = [...global.Modeliks.DateHelper.months_comaprative];
    const MonthsByStartEndOrder = realDrivers.map((driver) =>
      datesObject.MonthsByStartEndOrder.map((m) => driver.getItemByDateSufix(m.sufix)),
    );
    const MonthsPreviousPeriodOrder = realDrivers.map((driver) =>
      datesObject.MonthsPreviousPeriodOrder.map((m) => driver.getItemByDateSufix(m.sufix)),
    );
    const MonthsPreviousYearOrder = realDrivers.map((driver) =>
      datesObject.MonthsPreviousYearOrder.map((m) => driver.getItemByDateSufix(m.sufix)),
    );

    months_comaprative_view.forEach((m, index) => {
      const curComparativeValue = driver.getItemByDateSufix(m.sufix);
      curComparativeValue.Actual = null;
      if (!curComparativeValue) {
        console.log(driver, m);
      }

      let isGroupFounded = false;
      if (index > 0 && !(index % 3)) {
        isGroupFounded = true;
      }

      if (index === 0) {
        if (
          driver.UnitType === UnitTypes.Percentage ||
          driver.DriverCategory === DriverCategories.Average
        ) {
          let dates = null;
          let months = null;

          if (
            (driver.DriverCategory === DriverCategories.WeightedAverage ||
              driver.DriverCategory === DriverCategories.Average) &&
            children &&
            children.length > 0
          ) {
            dates = datesObject.MonthsByStartEndOrder;
            const mainDriver = driver.getChildDrivers();
            let tempFormula = mainDriver[0].Formula;
            children.forEach(
              (c) =>
                (tempFormula = tempFormula.replaceAll(
                  c,
                  `MxMath.Sum([${dates.map((d) => `|${c.ID}_${d.sufix}|`).join(",")}])`,
                )),
            );
            if (tempFormula) {
              curComparativeValue.Formula = `${tempFormula}`;
            }
          } else {
            if (
              isBalanceSheetReport ||
              driver.DriverName === "Cash Balance" ||
              driver.DriverName === "Cash At Beginning Of The Period" ||
              driver.DriverCategory === DriverCategories.LastPeriod
            ) {
              dates = FinancialStatementsReport.getBalanceSheetLastMonth(
                datesObject.MonthsByStartEndOrder,
              );
              months =
                FinancialStatementsReport.getBalanceSheetLastMonthsDrivers(MonthsByStartEndOrder);
            } else if (driver.DriverCategory === DriverCategories.FirstPeriod) {
              months =
                FinancialStatementsReport.getBalanceSheetFirstMonthDrivers(MonthsByStartEndOrder);
            } else {
              dates = datesObject.MonthsByStartEndOrder;
              months = MonthsByStartEndOrder.map((v) => v.map((c) => c.ID_f).join(",")).flat();
            }

            FinancialStatementsReport.setPercentageFormulas(
              curComparativeValue,
              formula,
              dates,
              driver.DriverCategory === DriverCategories.Average ? "Average" : "Sum",
              months,
              false,
              driver,
              datesObject.MonthsPreviousPeriodOrder,
            );
          }
        } else {
          let months = null;
          if (
            isBalanceSheetReport ||
            driver.DriverName === "Cash Balance" ||
            driver.DriverCategory === DriverCategories.LastPeriod
          ) {
            months =
              FinancialStatementsReport.getBalanceSheetLastMonthsDrivers(MonthsByStartEndOrder);
          } else if (
            driver.DriverName === "Cash At Beginning Of The Period" ||
            driver.DriverCategory === DriverCategories.FirstPeriod
          ) {
            months =
              FinancialStatementsReport.getBalanceSheetFirstMonthDrivers(MonthsByStartEndOrder);
          } else {
            months = MonthsByStartEndOrder.map((v) => v.map((c) => c.ID_f).join(",")).flat();
          }
          curComparativeValue.Formula = `MxMath.${driver.DriverCategory === DriverCategories.Average ? "Average" : "Sum"}([${months}])`;
        }
      }

      if (isGroupFounded) {
        const [prevDate, prevPrevDate] = months_comaprative_view.slice(index - 2, index);
        const actualDriver = driver.getItemByDateSufix(months_comaprative_view[0].sufix);
        const prevDriver = driver.getItemByDateSufix(prevPrevDate.sufix);
        const prevPrevDriver = driver.getItemByDateSufix(prevDate.sufix);

        let date = null;
        let months = null;
        if (
          isBalanceSheetReport ||
          driver.DriverName === "Cash Balance" ||
          driver.DriverCategory === DriverCategories.LastPeriod
        ) {
          let dates = null;
          let monthsByGroup = null;

          if (index === 3) {
            dates = datesObject.MonthsByStartEndOrder;
            monthsByGroup = MonthsByStartEndOrder;
          } else if (index === 6) {
            dates = datesObject.MonthsPreviousPeriodOrder;
            monthsByGroup = MonthsPreviousPeriodOrder;
          } else {
            dates = datesObject.MonthsPreviousYearOrder;
            monthsByGroup = MonthsPreviousYearOrder;
          }

          date = FinancialStatementsReport.getBalanceSheetLastMonth(dates);
          months = FinancialStatementsReport.getBalanceSheetLastMonthsDrivers(monthsByGroup);
        } else if (
          driver.DriverName === "Cash At Beginning Of The Period" ||
          driver.DriverCategory === DriverCategories.FirstPeriod
        ) {
          let dates = null;
          let monthsByGroup = null;
          if (index === 3) {
            dates = datesObject.MonthsByStartEndOrder;
            monthsByGroup = MonthsByStartEndOrder;
          } else if (index === 6) {
            dates = datesObject.MonthsPreviousPeriodOrder;
            monthsByGroup = MonthsPreviousPeriodOrder;
          } else {
            dates = datesObject.MonthsPreviousYearOrder;
            monthsByGroup = MonthsPreviousYearOrder;
          }

          date = FinancialStatementsReport.getBalanceSheetFirstMonth(dates);
          months = FinancialStatementsReport.getBalanceSheetFirstMonthDrivers(monthsByGroup);
        } else {
          if (index === 3) {
            date = datesObject.MonthsByStartEndOrder;
            months = MonthsByStartEndOrder.map((v) => v.map((c) => c.ID_f).join(",")).flat();
          } else if (index === 6) {
            date = datesObject.MonthsPreviousPeriodOrder;
            months = MonthsPreviousPeriodOrder.map((v) => v.map((c) => c.ID_f).join(",")).flat();
          } else if (index === 9) {
            date = datesObject.MonthsPreviousYearOrder;
            months = MonthsPreviousYearOrder.map((v) => v.map((c) => c.ID_f).join(",")).flat();
          }
        }
        if (index === 3) {
          if (
            (driver.DriverCategory === DriverCategories.WeightedAverage ||
              driver.DriverCategory === DriverCategories.Average) &&
            children &&
            children.length > 0
          ) {
            let dates = date;
            const mainDriver = driver.getChildDrivers();
            let tempFormula = mainDriver[0].Formula;
            children.forEach(
              (c) =>
                (tempFormula = tempFormula.replaceAll(
                  c,
                  `MxMath.Sum([${dates.map((d) => `|${c.ID}_${d.sufix}|`).join(",")}])`,
                )),
            );
            if (tempFormula) {
              prevPrevDriver.Formula = `${tempFormula} Actual(${tempFormula})`;
              // prevPrevDriver.evalFormulaActual = prevPrevDriver.evalFormula;
              if (driver.UnitType === UnitTypes.Percentage) {
                prevDriver.Formula = `Actual((${actualDriver.ID_f_actual} - ${prevPrevDriver.ID_f_actual}) * 100)`;
                // prevDriver.Formula = `Actual((MxMath.IFELSE( (${actualDriver.ID_f_actual} > 0 && ${prevPrevDriver.ID_f_actual} < 0 || ${actualDriver.ID_f_actual} < 0 && ${prevPrevDriver.ID_f_actual} > 0), NaN, ${actualDriver.ID_f_actual} - ${prevPrevDriver.ID_f_actual})) * 100)`;
                // curComparativeValue.Formula = `Actual((${prevDriver.ID_f_actual} / ${prevPrevDriver.ID_f_actual}) * 100)`
                curComparativeValue.Formula = `Actual((MxMath.IFELSE( (${actualDriver.ID_f_actual} > 0 && ${prevPrevDriver.ID_f_actual} < 0 || ${actualDriver.ID_f_actual} < 0 && ${prevPrevDriver.ID_f_actual} > 0), NaN, ${prevDriver.ID_f_actual} / ${prevPrevDriver.ID_f_actual})) * 100)`;
              } else {
                prevDriver.Formula = `Actual(${actualDriver.ID_f_actual} - ${prevPrevDriver.ID_f_actual})`;
                // prevDriver.Formula = `Actual(MxMath.IFELSE( (${actualDriver.ID_f_actual} > 0 && ${prevPrevDriver.ID_f_actual} < 0 || ${actualDriver.ID_f_actual} < 0 && ${prevPrevDriver.ID_f_actual} > 0), NaN, ${actualDriver.ID_f_actual} - ${prevPrevDriver.ID_f_actual}))`;
                // curComparativeValue.Formula = `Actual(${prevDriver.ID_f_actual} / ${prevPrevDriver.ID_f_actual})`
                curComparativeValue.Formula = `Actual(MxMath.IFELSE( (${actualDriver.ID_f_actual} > 0 && ${prevPrevDriver.ID_f_actual} < 0 || ${actualDriver.ID_f_actual} < 0 && ${prevPrevDriver.ID_f_actual} > 0), NaN, ${prevDriver.ID_f_actual} / ${prevPrevDriver.ID_f_actual}))`;
              }
            }
          } else {
            if (driver.UnitType === UnitTypes.Percentage) {
              FinancialStatementsReport.setPercentageFormulas(
                prevPrevDriver,
                formula,
                date,
                driver.DriverCategory === DriverCategories.Average ? "Average" : "Sum",
                months,
                true,
                driver,
                datesObject.MonthsPreviousPeriodOrder,
              );
              prevPrevDriver.evalFormulaActual = prevPrevDriver.evalFormula;
              prevDriver.Formula = `Actual((${actualDriver.ID_f_actual} - ${prevPrevDriver.ID_f_actual}) * 100)`;
              // prevDriver.Formula = `Actual((MxMath.IFELSE( (${actualDriver.ID_f_actual} > 0 && ${prevPrevDriver.ID_f_actual} < 0 || ${actualDriver.ID_f_actual} < 0 && ${prevPrevDriver.ID_f_actual} > 0), NaN, ${actualDriver.ID_f_actual} - ${prevPrevDriver.ID_f_actual})) * 100)`;
              // curComparativeValue.Formula = `Actual((${prevDriver.ID_f_actual} / ${prevPrevDriver.ID_f_actual}) * 100)`
              curComparativeValue.Formula = `Actual((MxMath.IFELSE( (${actualDriver.ID_f_actual} > 0 && ${prevPrevDriver.ID_f_actual} < 0 || ${actualDriver.ID_f_actual} < 0 && ${prevPrevDriver.ID_f_actual} > 0), NaN, ${prevDriver.ID_f_actual} / ${prevPrevDriver.ID_f_actual})) * 100)`;
            } else {
              prevPrevDriver.Formula = `MxMath.${driver.DriverCategory === DriverCategories.Average ? "Average" : "Sum"}([${months}]) Actual(MxMath.${driver.DriverCategory === DriverCategories.Average ? "Average" : "Sum"}([${months}]))`;
              // prevPrevDriver.evalFormulaActual = prevPrevDriver.evalFormula;
              prevDriver.Formula = `Actual(${actualDriver.ID_f_actual} - ${prevPrevDriver.ID_f_actual})`;
              // prevDriver.Formula = `Actual(MxMath.IFELSE( (${actualDriver.ID_f_actual} > 0 && ${prevPrevDriver.ID_f_actual} < 0 || ${actualDriver.ID_f_actual} < 0 && ${prevPrevDriver.ID_f_actual} > 0), NaN, ${actualDriver.ID_f_actual} - ${prevPrevDriver.ID_f_actual}))`;
              // curComparativeValue.Formula = `Actual(${prevDriver.ID_f_actual} / ${prevPrevDriver.ID_f_actual})`
              curComparativeValue.Formula = `Actual(MxMath.IFELSE( (${actualDriver.ID_f_actual} > 0 && ${prevPrevDriver.ID_f_actual} < 0 || ${actualDriver.ID_f_actual} < 0 && ${prevPrevDriver.ID_f_actual} > 0), NaN, ${prevDriver.ID_f_actual} / ${prevPrevDriver.ID_f_actual}))`;
            }
          }
        }

        if (index === 6 || index === 9) {
          if (driver.DriverName === "Revenue Growth") {
            prevDriver.Unit = UnitTypes.Hide;
            prevPrevDriver.Unit = UnitTypes.Hide;
            curComparativeValue.Unit = UnitTypes.Hide;
          } else {
            if (
              (driver.DriverCategory === DriverCategories.WeightedAverage ||
                driver.DriverCategory === DriverCategories.Average) &&
              children &&
              children.length > 0
            ) {
              let dates = date;
              const mainDriver = driver.getChildDrivers();
              let tempFormula = mainDriver[0].Formula;
              children.forEach(
                (c) =>
                  (tempFormula = tempFormula.replaceAll(
                    c,
                    `MxMath.Sum([${dates.map((d) => `|${c.ID}_${d.sufix}|`).join(",")}])`,
                  )),
              );
              if (tempFormula) {
                prevPrevDriver.Formula = `${tempFormula}`;
                if (driver.UnitType === UnitTypes.Percentage) {
                  prevDriver.Formula = `Actual((${actualDriver.ID_f_actual} - ${prevPrevDriver.ID_f_actual}) * 100) `;
                  // prevDriver.Formula = `Actual((MxMath.IFELSE( (${actualDriver.ID_f_actual} > 0 && ${prevPrevDriver.ID_f_actual} < 0 || ${actualDriver.ID_f_actual} < 0 && ${prevPrevDriver.ID_f_actual} > 0), NaN, ${actualDriver.ID_f_actual} - ${prevPrevDriver.ID_f_actual})) * 100)`;
                  // curComparativeValue.Formula = `Actual((${prevDriver.ID_f_actual} / ${prevPrevDriver.ID_f_actual})  * 100)`
                  curComparativeValue.Formula = `Actual((MxMath.IFELSE( (${actualDriver.ID_f_actual} > 0 && ${prevPrevDriver.ID_f_actual} < 0 || ${actualDriver.ID_f_actual} < 0 && ${prevPrevDriver.ID_f_actual} > 0), NaN, ${prevDriver.ID_f_actual} / ${prevPrevDriver.ID_f_actual})) * 100)`;
                } else {
                  prevDriver.Formula = `Actual(${actualDriver.ID_f_actual} - ${prevPrevDriver.ID_f_actual}) `;
                  // prevDriver.Formula = `Actual(MxMath.IFELSE( (${actualDriver.ID_f_actual} > 0 && ${prevPrevDriver.ID_f_actual} < 0 || ${actualDriver.ID_f_actual} < 0 && ${prevPrevDriver.ID_f_actual} > 0), NaN, ${actualDriver.ID_f_actual} - ${prevPrevDriver.ID_f_actual}))`;
                  // curComparativeValue.Formula = `Actual(${prevDriver.ID_f_actual} / ${prevPrevDriver.ID_f_actual})`
                  curComparativeValue.Formula = `Actual(MxMath.IFELSE( (${actualDriver.ID_f_actual} > 0 && ${prevPrevDriver.ID_f_actual} < 0 || ${actualDriver.ID_f_actual} < 0 && ${prevPrevDriver.ID_f_actual} > 0), NaN, ${prevDriver.ID_f_actual} / ${prevPrevDriver.ID_f_actual}))`;
                }
              }
            } else {
              if (driver.UnitType === UnitTypes.Percentage) {
                FinancialStatementsReport.setPercentageFormulas(
                  prevPrevDriver,
                  formula,
                  date,
                  driver.DriverCategory === DriverCategories.Average ? "Average" : "Sum",
                  months,
                  false,
                  driver,
                  datesObject.MonthsPreviousPeriodOrder,
                );
                prevDriver.Formula = `Actual((${actualDriver.ID_f_actual} - ${prevPrevDriver.ID_f_actual}) * 100) `;
                // prevDriver.Formula = `Actual((MxMath.IFELSE( (${actualDriver.ID_f_actual} > 0 && ${prevPrevDriver.ID_f_actual} < 0 || ${actualDriver.ID_f_actual} < 0 && ${prevPrevDriver.ID_f_actual} > 0), NaN, ${actualDriver.ID_f_actual} - ${prevPrevDriver.ID_f_actual})) * 100)`;
                // curComparativeValue.Formula = `Actual((${prevDriver.ID_f_actual} / ${prevPrevDriver.ID_f_actual})  * 100)`
                curComparativeValue.Formula = `Actual((MxMath.IFELSE( (${actualDriver.ID_f_actual} > 0 && ${prevPrevDriver.ID_f_actual} < 0 || ${actualDriver.ID_f_actual} < 0 && ${prevPrevDriver.ID_f_actual} > 0), NaN, ${prevDriver.ID_f_actual} / ${prevPrevDriver.ID_f_actual})) * 100)`;
              } else {
                prevPrevDriver.Formula = `MxMath.${driver.DriverCategory === DriverCategories.Average ? "Average" : "Sum"}([${months}])`;
                prevDriver.Formula = `Actual(${actualDriver.ID_f_actual} - ${prevPrevDriver.ID_f_actual})`;
                // prevDriver.Formula = `Actual(MxMath.IFELSE( (${actualDriver.ID_f_actual} > 0 && ${prevPrevDriver.ID_f_actual} < 0 || ${actualDriver.ID_f_actual} < 0 && ${prevPrevDriver.ID_f_actual} > 0), NaN, ${actualDriver.ID_f_actual} - ${prevPrevDriver.ID_f_actual}))`;
                // curComparativeValue.Formula = `Actual(${prevDriver.ID_f_actual} / ${prevPrevDriver.ID_f_actual})`
                curComparativeValue.Formula = `Actual(MxMath.IFELSE( (${actualDriver.ID_f_actual} > 0 && ${prevPrevDriver.ID_f_actual} < 0 || ${actualDriver.ID_f_actual} < 0 && ${prevPrevDriver.ID_f_actual} > 0), NaN, ${prevDriver.ID_f_actual} / ${prevPrevDriver.ID_f_actual}))`;
              }
            }
          }
        }
      }
    });
  };
  static setQuarterly = (
    driver,
    formula,
    startMonth = 0,
    endMonth = 10,
    showMonthly,
    datesObject,
    isBalanceSheetReport,
    children,
  ) => {
    driver.Formula = formula;
    const drivers = [
      ...new Set(driver.Formula.split("|").filter((c) => c.length > 10 && c.indexOf("Math") == -1)),
    ];
    const months_quarterly_view = [...global.Modeliks.DateHelper.months_quarterly_view];
    const startMonthOrder = datesObject.MonthsByStartEndOrder[0].Order;
    const EndMonthOrder =
      datesObject.MonthsByStartEndOrder[datesObject.MonthsByStartEndOrder.length - 1].Order;

    months_quarterly_view.forEach((m) => {
      const value = driver.getItemByDateSufix(m.sufix);
      value.Formula = null;
      value.Actual = null;
      let tmpFormula = driver.Formula;
      // let tmpFormula2 = driver.Formula;
      drivers.forEach((d) => {
        const secondMonth = `|${d}_m_${m.Order + 1}|`;
        const thirdMonth = `|${d}_m_${m.Order + 2}|`;
        const displayFirstMonth = m.Order >= startMonthOrder && m.Order <= EndMonthOrder;
        const displaySecondMonth = m.Order + 1 >= startMonthOrder && m.Order + 1 <= EndMonthOrder;
        const displayThirdMonth = m.Order + 2 >= startMonthOrder && m.Order + 2 <= EndMonthOrder;
        if (isBalanceSheetReport || driver.DriverName === "Cash Balance") {
          if (displayFirstMonth && displaySecondMonth && displayThirdMonth) {
            tmpFormula = tmpFormula.replaceAll(d, d + "_m_" + (m.Order + 2));
          } else {
            if (displayThirdMonth) {
              tmpFormula = tmpFormula.replaceAll(d, d + "_m_" + (m.Order + 2));
            } else {
              if (displaySecondMonth) {
                tmpFormula = tmpFormula.replaceAll(d, d + "_m_" + (m.Order + 1));
              }

              if (!displaySecondMonth && !displayThirdMonth) {
                tmpFormula = tmpFormula.replaceAll(d, d + "_m_" + m.Order);
              }
            }
          }
        } else if (driver.DriverName === "Cash At Beginning Of The Period") {
          if (displayFirstMonth && displaySecondMonth && displayThirdMonth) {
            tmpFormula = tmpFormula.replaceAll(d, d + "_m_" + m.Order);
          } else {
            if (displayFirstMonth) {
              tmpFormula = tmpFormula.replaceAll(d, d + "_m_" + m.Order);
            } else {
              if (displaySecondMonth) {
                tmpFormula = tmpFormula.replaceAll(d, d + "_m_" + (m.Order + 1));
              }

              if (!displayFirstMonth && !displaySecondMonth) {
                tmpFormula = tmpFormula.replaceAll(d, d + "_m_" + (m.Order + 2));
              }
            }
          }
        } else {
          if (driver.UnitType === UnitTypes.Percentage) {
            let months = null;
            let dates = [
              displayFirstMonth &&
                datesObject.MonthsByStartEndOrder.find((d) => d.Order === m.Order),
              displaySecondMonth &&
                datesObject.MonthsByStartEndOrder.find((d) => d.Order === m.Order + 1),
              displayThirdMonth &&
                datesObject.MonthsByStartEndOrder.find((d) => d.Order === m.Order + 2),
            ];

            if (
              driver.DriverCategory === DriverCategories.Average &&
              children &&
              children.length > 0
            ) {
              const mainDriver = driver.getChildDrivers();
              const tempFormula3 = mainDriver[0].Formula;
              let tmpFormula2 = [];
              if (displayFirstMonth && displaySecondMonth && displayThirdMonth) {
                let t1 = tempFormula3;
                let t2 = tempFormula3;
                let t3 = tempFormula3;
                children.forEach((c) => (t1 = t1.replaceAll(c, "|" + c.ID + "_" + m.sufix + "|")));
                children.forEach(
                  (c) => (t2 = t2.replaceAll(c, "|" + c.ID + "_m_" + (m.Order + 1) + "|")),
                );
                children.forEach(
                  (c) => (t3 = t3.replaceAll(c, "|" + c.ID + "_m_" + (m.Order + 2) + "|")),
                );
                tmpFormula2 = `${t1},${t2},${t3}`;
              } else {
                if (displayFirstMonth) {
                  let t1 = tempFormula3;
                  children.forEach(
                    (c) => (t1 = t1.replaceAll(c, "|" + c.ID + "_" + m.sufix + "|")),
                  );
                  tmpFormula2 = `${t1}`;

                  if (displaySecondMonth) {
                    let t2 = tempFormula3;
                    children.forEach(
                      (c) => (t2 = t2.replaceAll(c, "|" + c.ID + "_m_" + (m.Order + 1) + "|")),
                    );
                    tmpFormula2 = `${tmpFormula2},${t2}`;
                  }
                  if (displayThirdMonth) {
                    let t3 = tempFormula3;
                    children.forEach(
                      (c) => (t3 = t3.replaceAll(c, "|" + c.ID + "_m_" + (m.Order + 2) + "|")),
                    );
                    tmpFormula2 = `${tmpFormula2},${t3}`;
                  }
                } else if (displaySecondMonth) {
                  let t2 = tempFormula3;
                  children.forEach(
                    (c) => (t2 = t2.replaceAll(c, "|" + c.ID + "_m_" + (m.Order + 1) + "|")),
                  );
                  tmpFormula2 = `${t2}`;
                  if (displayThirdMonth) {
                    let t3 = tempFormula3;
                    children.forEach(
                      (c) => (t3 = t3.replaceAll(c, "|" + c.ID + "_m_" + (m.Order + 2) + "|")),
                    );
                    tmpFormula2 = `${tmpFormula2},${t3}`;
                  }
                } else if (displayThirdMonth) {
                  let t3 = tempFormula3;
                  children.forEach(
                    (c) => (t3 = t3.replaceAll(c, "|" + c.ID + "_m_" + (m.Order + 2) + "|")),
                  );
                  tmpFormula2 = `${t3}`;
                  if (displaySecondMonth) {
                    let t2 = tempFormula3;
                    children.forEach(
                      (c) => (t2 = t2.replaceAll(c, "|" + c.ID + "_m_" + (m.Order + 1) + "|")),
                    );
                    tmpFormula2 = `${tmpFormula2},${t2}`;
                  }
                } else {
                  tmpFormula2 = null;
                }
              }
              value.Formula = `MxMath.Average([${tmpFormula2}])`;

              // Avg(Driver 1 month 1 / Driver 2 month1, Driver 1 month 2 / Driver 2 month 2, Driver 1 month 3 / Driver 2 month 3)
              // Sum(Driver 1 Month 1, Driver 1 Month 2, Driver 1 Month 3)/Sum(Driver 2 Month1, Driver 2 Month2, Driver 2 Month 3)
            } else if (
              driver.DriverCategory === DriverCategories.WeightedAverage &&
              children &&
              children.length > 0
            ) {
              const mainDriver = driver.getChildDrivers();
              const tempFormula3 = mainDriver[0].Formula;
              let tmpFormula2 = [];
              if (displayFirstMonth && displaySecondMonth && displayThirdMonth) {
                let t1 = tempFormula3;
                children.forEach(
                  (c) =>
                    (t1 = t1.replaceAll(
                      c,
                      `MxMath.Sum([|${c.ID}_${m.sufix}|,|${c.ID}_m_${m.Order + 1}|,|${c.ID}_m_${m.Order + 2}|])`,
                    )),
                );
                tmpFormula2 = `${t1}`;
              } else {
                if (displayFirstMonth) {
                  if (displaySecondMonth && !displayThirdMonth) {
                    let t2 = tempFormula3;
                    children.forEach(
                      (c) =>
                        (t2 = t2.replaceAll(
                          c,
                          `MxMath.Sum([|${c.ID}_${m.sufix}|,|${c.ID}_m_${m.Order + 1}|])`,
                        )),
                    );
                    tmpFormula2 = `${t2}`;
                  } else if (displayThirdMonth && !displaySecondMonth) {
                    let t3 = tempFormula3;
                    children.forEach(
                      (c) =>
                        (t3 = t3.replaceAll(
                          c,
                          `MxMath.Sum([|${c.ID}_${m.sufix}|,|${c.ID}_m_${m.Order + 2}|])`,
                        )),
                    );
                    tmpFormula2 = `${t3}`;
                  } else {
                    let t1 = tempFormula3;
                    children.forEach(
                      (c) => (t1 = t1.replaceAll(c, `MxMath.Sum([|${c.ID}_${m.sufix}|])`)),
                    );
                    tmpFormula2 = `${t1}`;
                  }
                } else if (displaySecondMonth) {
                  let t2 = tempFormula3;
                  children.forEach(
                    (c) => (t2 = t2.replaceAll(c, "|" + c.ID + "_m_" + (m.Order + 1) + "|")),
                  );
                  tmpFormula2 = `${t2}`;
                  if (displayThirdMonth) {
                    let t3 = tempFormula3;
                    children.forEach(
                      (c) =>
                        (t3 = t3.replaceAll(
                          c,
                          `MxMath.Sum([|${c.ID}_m_${m.Order + 1}|,|${c.ID}_m_${m.Order + 2}|])`,
                        )),
                    );
                    tmpFormula2 = `${t3}`;
                  } else {
                    let t2 = tempFormula3;
                    children.forEach(
                      (c) => (t2 = t2.replaceAll(c, `MxMath.Sum([|${c.ID}_m_${m.Order + 1}|])`)),
                    );
                    tmpFormula2 = `${t2}`;
                  }
                } else if (displayThirdMonth) {
                  if (displaySecondMonth) {
                    let t2 = tempFormula3;
                    children.forEach(
                      (c) =>
                        (t2 = t2.replaceAll(
                          c,
                          `MxMath.Sum([|${c.ID}_m_${m.Order + 1}|,|${c.ID}_m_${m.Order + 2}|])`,
                        )),
                    );
                    tmpFormula2 = `${t2}`;
                  } else {
                    let t3 = tempFormula3;
                    children.forEach(
                      (c) => (t3 = t3.replaceAll(c, `MxMath.Sum([|${c.ID}_m_${m.Order + 2}|])`)),
                    );
                    tmpFormula2 = `${t3}`;
                  }
                } else {
                  tmpFormula2 = null;
                }
              }
              value.Formula = tmpFormula2;
            } else {
              if (displayFirstMonth && displaySecondMonth && displayThirdMonth) {
                tmpFormula = `${tmpFormula.replaceAll(d, d + "_" + m.sufix)} + ${secondMonth} + ${thirdMonth}`;
              } else {
                if (displayFirstMonth) {
                  tmpFormula = `${tmpFormula.replaceAll(d, d + "_" + m.sufix)}`;
                  if (displaySecondMonth) {
                    tmpFormula = `${tmpFormula} +  ${secondMonth}`;
                  }
                  if (displayThirdMonth) {
                    tmpFormula = `${tmpFormula} +  ${thirdMonth}`;
                  }
                } else if (displaySecondMonth) {
                  tmpFormula = `${secondMonth}`;
                  if (displayThirdMonth) {
                    tmpFormula = `${tmpFormula} +  ${thirdMonth}`;
                  }
                } else if (displayThirdMonth) {
                  tmpFormula = `${thirdMonth}`;
                  if (displaySecondMonth) {
                    tmpFormula = `${tmpFormula} +  ${secondMonth}`;
                  }
                } else {
                  tmpFormula = null;
                }
              }
              value.Formula = `${tmpFormula} * 100`;
              FinancialStatementsReport.setPercentageFormulas(
                value,
                formula,
                dates.filter((d) => d),
                driver.DriverCategory === DriverCategories.Average ? "Average" : "Sum",
              );
            }
          } else {
            if (displayFirstMonth && displaySecondMonth && displayThirdMonth) {
              tmpFormula = `${tmpFormula.replaceAll(d, d + "_" + m.sufix)} + ${secondMonth} + ${thirdMonth}`;
            } else {
              if (displayFirstMonth) {
                tmpFormula = `${tmpFormula.replaceAll(d, d + "_" + m.sufix)}`;
                if (displaySecondMonth) {
                  tmpFormula = `${tmpFormula} +  ${secondMonth}`;
                }
                if (displayThirdMonth) {
                  tmpFormula = `${tmpFormula} +  ${thirdMonth}`;
                }
              } else if (displaySecondMonth) {
                tmpFormula = `${secondMonth}`;
                if (displayThirdMonth) {
                  tmpFormula = `${tmpFormula} +  ${thirdMonth}`;
                }
              } else if (displayThirdMonth) {
                tmpFormula = `${thirdMonth}`;
                if (displaySecondMonth) {
                  tmpFormula = `${tmpFormula} +  ${secondMonth}`;
                }
              } else {
                tmpFormula = null;
              }
            }
          }
        }
      });
      if (driver.UnitType !== UnitTypes.Percentage) {
        value.Formula = tmpFormula;
      }
    });
  };
}

export default FinancialStatementsReport;
