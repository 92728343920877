import React, { Fragment, useState } from "react";
// import RightMenuPortal from "./RightMenuPortal.js";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import InputBox from "../InputBoxComponent/InputBox";
import { FlipHorizontally, FlipVertically, Rotate90 } from "../IconButtons/SubMenuIcons";
import MaterialSelect from "../Select/MaterialSelect";
import { FontSizeOptions } from "../../SlideObjects/ChartComponents/FontSizeOptions";

function AxisComponent(props) {
  const [openAxis, setOpenAxis] = React.useState(false);
  const [showAxis, setShowAxis] = React.useState(true);

  const handleClickAxis = () => {
    if (openAxis) {
      setOpenAxis(false);
    } else {
      setOpenAxis(true);
      props.rightMenuFunc.closeAxis = null;
      Object.values(props.rightMenuFunc).map((value) => {
        if (typeof value === "function") {
          value.call();
        }
      });
    }
  };

  const closeMenu = () => {
    setOpenAxis(false);
  };

  props.rightMenuFunc.closeAxis = closeMenu;

  const handleFontChange = (e) => {
    props.onPropsChange({ axisFontSize: e.target.value });
  };

  const handleShowAxis = (e) => {
    if (e.target.checked) {
      setShowAxis(true);
    } else {
      setShowAxis(false);
      props.onPropsChange({ showHorizontalAxis: false, showVerticalAxis: false });
    }
  };

  const handleShowHorizontal = (e) => {
    props.onPropsChange({ showHorizontalAxis: e.target.checked });
  };

  const handleShowVertical = (e) => {
    props.onPropsChange({ showVerticalAxis: e.target.checked });
  };

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      onKeyDown={(e) => {
        e.stopPropagation();
      }}
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
    >
      <ListItemButton id={"listItem"} onClick={handleClickAxis}>
        <ListItemText primary="Axis" />
        {openAxis ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <div style={{ width: "230px", border: "1px solid #F0F1F7", marginLeft: "18px" }} />
      <Collapse in={openAxis} timeout="auto" unmountOnExit>
        <div className={"collapse"}>
          <div className={"listRow"}>
            <input
              type="checkbox"
              checked={showAxis}
              style={{ margin: 0, marginRight: "4px" }}
              onChange={handleShowAxis}
            />
            <div className={"listSubHeading"}>Show axis labels</div>
          </div>
          <div className={"listRow"}>
            <input
              type="checkbox"
              disabled={!showAxis}
              checked={props.showVerticalAxis}
              style={{ margin: 0, marginRight: "4px" }}
              onChange={handleShowVertical}
            />
            <div className={"listRowElement"}>Vertical</div>
            <input
              type="checkbox"
              disabled={!showAxis}
              checked={props.showHorizontalAxis}
              style={{ margin: 0, marginRight: "4px" }}
              onChange={handleShowHorizontal}
            />
            <div className={"listRowElement"}>Horizontal</div>
          </div>
          <div className={"listRow listColumn"}>
            <label className={"listSubHeading"}>Font size: </label>
            <MaterialSelect
              value={props.axisFontSize}
              options={FontSizeOptions}
              onChange={handleFontChange}
            />
          </div>
        </div>
      </Collapse>
    </div>
  );
}
export default AxisComponent;
