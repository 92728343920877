import React, { Component } from "react";
import PropTypes from "prop-types";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import ButtonMui from "../../../buttons/buttonMui/buttonMui";
import { Input2 } from "../../../actions/Input";
import InputM from "../../../actions/inputs/InputM";
import request from "superagent";
import Subscriptions from "../../../../pages/Public/Auth/Register/Subscriptions";
import {
  CancelAccountIcon,
  CancelSubscriptionIcon,
  CancelSubscriptionIcon2,
  CoffeeMugIcon,
  HaveIssuesOrQuestionsIcon,
  InfoIcon,
  TryDifferentPlanIcon,
} from "../../../icons/svgIcons";
import { DialogTypes } from "../../../../data/Finance/constants";
import { Upgrade_Downgrade_types } from "../../../../data/SubscriptionEnum";
import AppDialog from "../index";

class CancelSubscription extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,
      key: new Date().getTime(),
      password: "",
      passwordError: "",
    };

    this.functionObject = {};

    this.subscription_plan = global.Modeliks.subscriptionPlans.find(
      (c) => c.ID == global.Modeliks.subscriptionInfo.SubscriptionID,
    );
    this.plan_name = this.subscription_plan.name;
  }

  setRef = (ref, content) => {
    if (ref) {
      ref.innerHTML = content;
    }
  };

  confirmPassword = () => {
    if (global.Modeliks.Account.Owner_ID === global.Modeliks.User.ID) {
      request
        .post("/api/confirm_password")
        .set("authorization", "Bearer " + window.localStorage.getItem("token"))
        .send({
          password: this.state.password,
        })
        .then((c) => {
          this.props.onAccept && this.props.onAccept(!!this.state.openPasswordDialogMessages);
        })
        .catch((e) => {
          this.setState({ passwordError: "Incorrect password" });
          console.error(e);
          console.log(e);
        });
    }
  };

  getCards = () => {
    if (this.props.message === "Subscription") {
      return [
        {
          title: "<b>Cancel Subscription</b>",
          text: [
            "Your subscription will be canceled <b>immediately</b>",
            `You will <b>no longer have access</b> to Modeliks' features <b>after ${new Date(global.Modeliks.subscriptionInfo.DateEnd).toLocaleDateString()}</b>`,
            "Your data will be <b>stored free of charge</b> for up to <b>3 months</b>",
            "<b>Reactivate</b> your subscription <b>at any time during that</b> window by upgrading to a new plan",
            "<b>After 3 months</b>, if you don’t activate your subscription, we’ll <b>securely delete your data and account</b> ",
          ],
          buttonLabel: "Cancel Subscription",
          onClick: () => {
            if (global.Modeliks.Account.Owner_ID === global.Modeliks.User.ID) {
              this.setState({ openPasswordDialog: true });
            }
          },
          icon: (
            <div className={"icon_wrapper_container"}>
              <CancelSubscriptionIcon2 />
            </div>
          ),
          buttonColor: "red",
          disabledButton: global.Modeliks.Account.Owner_ID !== global.Modeliks.User.ID,
        },
      ];
    }
    return [
      {
        title: `<b>Cancel ${this.props.message}</b>`,
        text: [
          "Your subscription will be cancelled <b>now</b>, and your account data will be <b>permanently deleted</b>",
          "You will <b>not have access to your data</b> anymore",
          "There are <b>no recovery options</b>",
          "There will be <b>no prorated refunds</b>",
        ],
        btn_width: 168,
        buttonLabel: "Cancel " + this.props.message,
        icon: <CancelAccountIcon />,
        onClick: () => {
          if (global.Modeliks.Account.Owner_ID === global.Modeliks.User.ID) {
            this.setState({ openPasswordDialog: true });
          }
        },
        disabledButton: global.Modeliks.Account.Owner_ID !== global.Modeliks.User.ID,
        buttonColor: "red",
      },
    ];
  };

  getContent = () => {
    return {
      1: {
        sub_title_text:
          this.props.message.toLowerCase() == "account"
            ? "Are you sure you want to cancel your account? Your subscription will be cancelled now, and your account data will be permanently deleted. There will be no prorated refunds."
            : `Are you sure you want to cancel your ${this.props.message.toLowerCase()}? You will no longer have access to Modeliks features after ${new Date(
                global.Modeliks.subscriptionInfo.DateEnd,
              ).toLocaleDateString()}`,
        cards: [
          {
            title: "<b>Have Issues or Questions?</b>",
            text: "Our support team can help you with any problems you might be facing.",
            buttonLabel: "Contact Us",
            onClick: (e) => {
              global.Modeliks.toggleChatBot && global.Modeliks.toggleChatBot(e);
              this.props.onCancel && this.props.onCancel();
            },
            icon: <HaveIssuesOrQuestionsIcon />,
          },
          {
            title: "<b>Try a Different Plan?</b>",
            text: "Unsatisfied with your current plan? Try another!",
            buttonLabel: "Change Plan",
            onClick: () => {
              this.setState({ subscriptions: true });
            },
            icon: <TryDifferentPlanIcon />,
          },
        ],
        actionButtons: [
          {
            width: 168,
            label: "Never mind",
            onClick: this.props.onCancel,
          },
          {
            width: 168,
            label: "Continue to Cancel",
            onClick: () => this.setState({ step: 2, key: new Date().getTime() }),
            color: "red",
          },
        ],
      },
      2: {
        sub_title_text:
          this.props.message.toLowerCase() == "account"
            ? "We are sad to see you go.<br><b>Are you sure you want to cancel your account?</b>"
            : "<b>Are you sure you want to cancel your subscription?</b>",
        cards: this.getCards(),
        actionButtons: [
          {
            width: 168,
            label: "Never mind",
            onClick: this.onClose, //this.setState({step: 1, key: new Date().getTime()}),
          },
        ],
      },
    };
  };

  ConfirmDowngradePlan = (ID) => {
    this.functionObject.showDialog(
      {
        oldPlan: global.Modeliks.subscriptionPlans.find(
          (c) => c.ID === global.Modeliks.subscriptionInfo.SubscriptionID,
        ),
        newPlan: global.Modeliks.subscriptionPlans.find((c) => c.ID === ID),
      },
      DialogTypes.DowngradeSubscription,
      null,
      () => {
        this.setState({ loading: true });

        request
          .post("/api/DowngradeSubscription")
          .set("Content-Type", "application/x-www-form-urlencoded")
          .set("authorization", "Bearer " + window.localStorage.getItem("token"))
          .send({
            AccountID: global.Modeliks.Account.ID,
            type: Upgrade_Downgrade_types.Subscription,
            selectedSubscriptionID: ID,
          })
          .then((res) => {
            if (res) {
              window.location.reload(false);
            }
          });
      },
    );
  };

  ConfirmUpgradePlan = (ID) => {
    this.functionObject.showDialog(
      {
        oldPlan: global.Modeliks.subscriptionPlans.find(
          (c) => c.ID === global.Modeliks.subscriptionInfo.SubscriptionID,
        ),
        newPlan: global.Modeliks.subscriptionPlans.find((c) => c.ID === ID),
      },
      DialogTypes.UpgradeSubscription,
      null,
      () => {
        this.setState({ loading: true });
        request
          .post("/api/UpgradeSubscription")
          .set("Content-Type", "application/x-www-form-urlencoded")
          .set("authorization", "Bearer " + window.localStorage.getItem("token"))
          .send({
            AccountID: global.Modeliks.Account.ID,
            type: Upgrade_Downgrade_types.Subscription,
            selectedSubscriptionID: ID,
            email: global.Modeliks.User.Email,
          })
          .then((res) => {
            if (res) {
              if (global.Modeliks.subscriptionInfo.price != 0) {
                window.location.reload(false);
              } else {
                window.open(JSON.parse(res.body).url, "_self");
              }
            }
          });
      },
      false,
      " upgrade_plan",
    );
  };

  handleCardClick = (id, price, stripe_price, strength) => {
    if (this.subscription_plan.strength == strength) {
      this.setState({ subscriptions: false });
    } else if (this.subscription_plan.strength > strength) {
      this.ConfirmDowngradePlan(id);
    } else {
      this.ConfirmUpgradePlan(id);
    }
  };

  onClose = () => {
    this.props.onCancel && this.props.onCancel();
    this.state.openPasswordDialogMessages = false;
  };

  render() {
    return (
      <>
        <DialogTitle sx={{ paddingLeft: "0" }}>Cancel {this.props.message}</DialogTitle>
        <DialogContent key={this.state.key + "_d_c"}>
          <div
            style={{ marginBottom: "10px" }}
            ref={(ref) => this.setRef(ref, this.getContent()[this.state.step]["sub_title_text"])}
          />
          {this.getContent()[this.state.step]["cards"].map((c) => (
            <div className={"grey_card icon_container"}>
              {c.icon && c.icon}

              <div className={"grey_card inner_content"}>
                <div ref={(ref) => this.setRef(ref, c.title)} />
                {Array.isArray(c.text) ? (
                  <ul>
                    {c.text.map((t) => (
                      <li ref={(ref) => this.setRef(ref, t)} />
                    ))}
                  </ul>
                ) : (
                  <div ref={(ref) => this.setRef(ref, c.text)} />
                )}
              </div>

              <div className={"button_container"}>
                <ButtonMui
                  disabled={c.disabledButton}
                  width={c.btn_width}
                  label={c.buttonLabel}
                  variant={"contained"}
                  onClick={c.onClick}
                  color={c.buttonColor}
                />
                {c.infoText && (
                  <div className={"button_container info_text"}>
                    <InfoIcon />
                    {c.infoText}
                  </div>
                )}
              </div>
            </div>
          ))}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", columnGap: "22px" }}>
          {this.getContent()[this.state.step].actionButtons.map((b) => (
            <ButtonMui {...b} variant={"contained"} />
          ))}
        </DialogActions>

        {this.state.openPasswordDialog && (
          <Dialog className={"check_password"} open={true} onClose={this.onClose}>
            <DialogTitle>
              {this.state.openPasswordDialogMessages
                ? this.state.openPasswordDialogMessages[0]
                : "Confirm cancellation by entering password"}
            </DialogTitle>
            <DialogContent>
              <InputM
                error={!!this.state.passwordError}
                errorMessage={this.state.passwordError}
                placeholder={"Enter your password"}
                value={this.state.password}
                type={"password"}
                onChange={(e) => this.setState({ password: e.target.value })}
              />
              <div className={"text_cont"}>
                Only the account owner can{" "}
                {this.state.openPasswordDialogMessages
                  ? this.state.openPasswordDialogMessages[1]
                  : "cancel"}{" "}
                the {this.props.message.toLowerCase()}.
              </div>
            </DialogContent>
            <DialogActions>
              <ButtonMui label={"Never mind"} onClick={this.onClose} variant={"contained"} />
              <ButtonMui
                label={"Confirm"}
                color={"red"}
                onClick={this.confirmPassword}
                variant={"contained"}
              />
            </DialogActions>
            <DialogContent>
              <div className={"text_cont"}>
                If you are having any trouble,{" "}
                <a href={"https://help.modeliks.com/"}>please get in touch</a>. We are here to help!
              </div>
            </DialogContent>
          </Dialog>
        )}
        {this.state.subscriptions && (
          <Subscriptions
            dontShowFreeTrial
            goBackLabel={"Go Back"}
            goBack={() => this.setState({ subscriptions: false })}
            changeSubscriptionPlan
            onCardClick={this.handleCardClick}
            className={"floating"}
          />
        )}
        <AppDialog functionObject={this.functionObject} />
      </>
    );
  }
}

CancelSubscription.propTypes = {
  message: PropTypes.oneOf(["Subscription", "Account"]),
};

export default CancelSubscription;
