import React, { Component } from "react";
import MxMath from "../../../data/MxMath";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ArrowDown } from "../../../components/icons/svgIcons";
import { InputFormats } from "../../../components/constants/finance";
import { NumberFormatTypes } from "../../../components/constants/charts";

export default class ChartTableComponent extends Component {
  constructor(props) {
    super(props);
  }

  update = () => {
    this.forceUpdate();
  };

  componentDidMount() {
    this.props.data.subscriptions.push(this.update);
  }

  componentWillUnmount() {
    this.props.data.subscriptions.pop();
  }

  calculateCellDollarDifference = (c) => {
    if (!(isNaN(c.data[0]) && isNaN(c.data[1]))) {
      if (!isNaN(MxMath.Round(c.data[0] - c.data[1]))) {
        return MxMath.Round(
          (c.data[0] - c.data[1]) / NumberFormatTypes[this.props.numberFormat].splitBy,
        );
      }
      return 0;
    }
    return 0;
  };
  calculateCellDollarPercentage = (c) => {
    if (!(isNaN(c.data[0]) && isNaN(c.data[1]))) {
      if (!isNaN(MxMath.Round(((c.data[0] - c.data[1]) / c.data[1]) * 100))) {
        return MxMath.Round(((c.data[0] - c.data[1]) / c.data[1]) * 100);
      }
      return 0;
    }
    return 0;
  };

  getCellClassName = (func, c) => {
    if (func(c) >= 0) {
      return "green";
    }
    return "red";
  };

  getTotalsFromArraysOnIndex = (arrays, index = 0) => {
    let total = 0;
    arrays.forEach((arr) => {
      if (!isNaN(arr.data[index])) {
        total += arr.data[index];
      }
    });
    return total;
  };

  render() {
    this.totalData = {
      data: [
        this.getTotalsFromArraysOnIndex(this.props.data.rows, 0),
        this.getTotalsFromArraysOnIndex(this.props.data.rows, 1),
      ],
    };
    return (
      <>
        {this.props.TitleObject}
        <Table
          className={"dashboard_chart_table"}
          padding={"checkbox"}
          stickyHeader
          size={"small"}
          sx={{ backgroundColor: "#FBFBFB" }}
        >
          <TableHead sx={{ backgroundColor: "#FBFBFB" }}>
            <TableRow>
              <TableCell sx={{ backgroundColor: "#FBFBFB" }} size={"small"}></TableCell>
              {this.props.data.chart.chartOptions.dollarDifference && (
                <TableCell align={"right"} size={"small"} sx={{ backgroundColor: "#FBFBFB" }}>
                  {global.Modeliks.CompanyInfo.Currency.value} difference
                </TableCell>
              )}
              {this.props.data.chart.chartOptions.percentDifference && (
                <TableCell align={"right"} size={"small"} sx={{ backgroundColor: "#FBFBFB" }}>
                  % difference
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell size={"small"}>Total</TableCell>
              {this.props.data.chart.chartOptions.dollarDifference && (
                <TableCell
                  className={this.getCellClassName(
                    this.calculateCellDollarDifference,
                    this.totalData,
                  )}
                  align={"right"}
                  size={"small"}
                >
                  <span className={"td_container"}>
                    {global.Modeliks.CompanyInfo.Currency.value}
                    {InputFormats.formatNumber(this.calculateCellDollarDifference(this.totalData))}
                  </span>
                </TableCell>
              )}
              {this.props.data.chart.chartOptions.percentDifference && (
                <TableCell
                  className={this.getCellClassName(
                    this.calculateCellDollarPercentage,
                    this.totalData,
                  )}
                  align={"right"}
                  size={"small"}
                >
                  <span className={"td_container"}>
                    {InputFormats.formatNumber(this.calculateCellDollarPercentage(this.totalData))}%
                  </span>
                </TableCell>
              )}
            </TableRow>
            {this.props.data.rows.map((c, index) => {
              return (
                <TableRow key={"c_row_" + index}>
                  <TableCell size={"small"}>
                    {
                      <div
                        style={{
                          borderRadius: "50%",
                          height: 12,
                          width: 12,
                          backgroundColor: c.backgroundColor,
                        }}
                      ></div>
                    }
                  </TableCell>
                  {this.props.data.chart.chartOptions.dollarDifference && (
                    <TableCell
                      className={this.getCellClassName(this.calculateCellDollarDifference, c)}
                      align={"right"}
                      size={"small"}
                    >
                      <span className={"td_container"}>
                        {global.Modeliks.CompanyInfo.Currency.value}
                        {InputFormats.formatNumber(this.calculateCellDollarDifference(c))}
                        <div className={"svg_container"}>
                          <ArrowDown />
                        </div>
                      </span>
                    </TableCell>
                  )}
                  {this.props.data.chart.chartOptions.percentDifference && (
                    <TableCell
                      className={this.getCellClassName(this.calculateCellDollarPercentage, c)}
                      align={"right"}
                      size={"small"}
                    >
                      <span className={"td_container"}>
                        {InputFormats.formatNumber(this.calculateCellDollarPercentage(c))}%
                        <div className={"svg_container"}>
                          <ArrowDown />
                        </div>
                      </span>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </>
    );
  }
}
