import React, { Fragment } from "react";
import "./tableobject.scss";
import HeaderTable from "../SlideHeader/HeaderTable";
import HeaderTableEditor from "../SlideHeader/HeaderTableEditor";
import SCContextMenuPortal from "../components/menus/newContextMenu/SCContextMenuPortal";
import RightMenuPortal from "../components/Portals/RightMenuPortal";
import ContextMenu from "../components/menus/newContextMenu/ContextMenu";
import TableLink from "../components/TableLink/index";
import { MenuItem, SubMenu } from "@szhsin/react-menu";
import {
  Copy,
  Cut,
  Exit,
  FormatColor,
  LowerCase,
  Numbered,
  Paste,
  TableBorder1,
  TableBorder2,
  TableBorder3,
  TableBorder4,
  TableBorder5,
  TableBorder6,
  TableBorder7,
  TableBorder8,
  TableBorder9,
  TitleCase,
  UpperCase,
} from "../components/IconButtons/SubMenuIcons";
import HeaderDefault from "../SlideHeader/HeaderDefault";
import TextFitting from "../components/RightMenuComponents/TextFittingComponent";
import SpecialCharacters from "../components/RightMenuComponents/SpecialCharactersComponent";
import OrderSubMenu from "../components/menus/newContextMenu/OrderSubMenu";
import SizeAndRotation from "../components/RightMenuComponents/SizeAndRotationComponent";
import Position from "../components/RightMenuComponents/PositionComponent";
import ListSubheader from "@mui/material/ListSubheader";
import { IconButton } from "@mui/material";
import List from "@mui/material/List";
import HeaderMidPortal from "../components/Portals/HeaderMidPortal";
import FormatPainterPortal from "../components/Portals/FormatPainterPortal";
import CenterOnPageSubMenu from "../components/menus/newContextMenu/CenterOnPageSubMenu";
import Tooltip from "@mui/material/Tooltip";
import ButtonMui from "../../../components/buttons/buttonMui/buttonMui";

class TableObject extends React.Component {
  tableRef = null;

  constructor(props) {
    super(props);

    this.state = {
      structure: props.structure ? props.structure : { colCount: 0, rowCount: 0, rows: null },
      selectedCells: [],
      anchorPoint: { x: 0, y: 0 },
      menuProps: undefined,
      textRotation: 0,
      multipleSelect: false,
      activeFormatPainter: false,
      formatPainterStyle: {},
      selectedStyle: {},
      cellContext: false,
      borderMenu: false,
    };

    global.pasteListener = this.handlePaste;

    this.rightMenuFucn = {};

    this.shouldPush = true;

    this.shoudlStopFormatPainter = false;

    this.editingCell = null;

    this.borderColPostion = -1;
    this.borderRowPostion = -1;

    this.startShiftColIndex = 0;
    this.startShiftRowIndex = 0;

    this.default_CellStyle = {
      fontSize: "11pt",
      paddingTop: "0px",
      paddingBottom: "0px",
      paddingLeft: "0px",
      paddingRight: "0px",
      fontFamily: "Inter",
      borderWeight: "1px",
      borderStyle: "solid",
      borderColor: "black",
      verticalAlign: "text-top",
    };

    this.mouseAction = null;
    this.td_elements = {};
    this.div_elements = {};
    this.key_ctrl = false;
    this.shouldRender = true;

    this.oldCaretPosition = null;
    this.caretPosition = null;

    this.cut = false;

    if (this.state.structure.rows == null) {
      this.state.structure.rows = [];
      this.state.structure = { colCount: 0, rowCount: 0, colWidths: [], rowHeights: [], rows: [] };
    }

    if (!this.state.structure.colWidths) {
      this.state.structure.colWidths = [];
    }

    if (!this.state.structure.rowHeights) {
      this.state.structure.rowHeights = [];
    }

    this.updateMissingCells();
  }

  componentDidMount() {}

  componentWillUnmount() {
    document.removeEventListener("mouseup", this.stopMultipleSelect);
  }

  updateMissingCells = () => {
    for (var i = 0; i < this.state.structure.rowCount; i++) {
      if (this.state.structure.rows.length < i + 1) {
        this.state.structure.rows.push([]);
        this.state.structure.rowHeights.push(
          this.props.height / this.state.structure.rowCount + "px",
        );
      }

      for (var k = 0; k < this.state.structure.colCount; k++) {
        if (this.state.structure.colWidths.length < k + 1) {
          this.state.structure.colWidths.push(
            this.props.width / this.state.structure.colCount + "px",
          );
        }
        if (this.state.structure.rows[i].length < k + 1) {
          const td = {
            value: "",
            key: `td_${i}_${k}`,
            style: this.default_CellStyle,
          };
          if (this.state.structure.rowHeights[i] != "auto") {
            td.style.height = this.state.structure.rowHeights[i];
          }
          if (this.state.structure.colWidths[k] != "auto") {
            td.style.width = this.state.structure.colWidths[k];
          }
          this.state.structure.rows[i].push(td);
          // if(k !== 0 && this.state.structure && this.state.structure.rows[i][k - 1].rowSpan && this.state.structure.rows[i][k - 1].rowSpan > 1) {
          //
          // }
          // else {
          //     this.state.structure.rows[i].push(td);
          // }
        }
      }
    }

    this.state.structure.rows.forEach((row) => {
      row.forEach((cell) => {
        if (!this.td_elements[cell.key]) {
          this.td_elements[cell.key] = React.createRef();
        }

        if (!this.div_elements[cell.key]) {
          this.div_elements[cell.key] = React.createRef();
        }
      });
    });

    this.pushPropsChanges(false);
  };

  distributeColumns = () => {
    let sortedArr = this.state.selectedCells.sort((a, b) =>
      a.colPosition < b.colPosition ? 1 : -1,
    );
    let colPositions = [];
    let totalWidth = 0;

    sortedArr.forEach((c) => {
      if (colPositions.indexOf(c.colPosition) === -1) {
        colPositions.push(c.colPosition);
        totalWidth += Number(this.state.structure.colWidths[c.colPosition].replace("px", ""));
      }
    });

    if (colPositions.length > 1) {
      colPositions.forEach((c) => {
        this.state.structure.colWidths[c] = totalWidth / colPositions.length + "px";
      });
    } else {
      for (let i = 0; i < this.state.structure.colWidths.length; i++) {
        this.state.structure.colWidths[i] = this.props.width / this.state.structure.colCount + "px";
      }
    }
    this.forceUpdate();
  };

  distributeRows = () => {
    let sortedArr = this.state.selectedCells.sort((a, b) =>
      a.rowPosition < b.rowPosition ? 1 : -1,
    );
    let rowPositions = [];
    let totalHeight = 0;

    sortedArr.forEach((c) => {
      if (rowPositions.indexOf(c.rowPosition) === -1) {
        rowPositions.push(c.rowPosition);
        totalHeight += Number(this.state.structure.rowHeights[c.rowPosition].replace("px", ""));
      }
    });

    if (rowPositions.length > 1) {
      rowPositions.forEach((c) => {
        this.state.structure.rowHeights[c] = totalHeight / rowPositions.length + "px";
      });
    } else {
      for (let i = 0; i < this.state.structure.rowHeights.length; i++) {
        this.state.structure.rowHeights[i] =
          this.props.height / this.state.structure.rowCount + "px";
      }
    }

    this.forceUpdate();
  };

  createRef = () => {
    this.state.structure.rows.forEach((row) => {
      row.forEach((cell) => {
        if (!this.td_elements[cell.key]) {
          this.td_elements[cell.key] = React.createRef();
        }
        if (!this.div_elements[cell.key]) {
          this.div_elements[cell.key] = React.createRef();
        }
      });
    });
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    if (nextProps.multipleSeleced != this.props.multipleSeleced) {
      if (nextProps.multipleSeleced) {
        global.pasteListener = this.handlePaste;
      }
    }

    if (nextProps.selected != this.props.selected) {
      if (nextProps.selected) {
        global.pasteListener = this.handlePaste;
      } else {
        this.startShiftColIndex = 0;
        this.startShiftRowIndex = 0;
        if (!this.props.businessPlan) {
          this.state.selectedCells = [];
        }
      }
    }

    if (
      (JSON.stringify(nextProps) == JSON.stringify(this.props) &&
        JSON.stringify(nextState) == JSON.stringify(this.state)) ||
      !this.shouldRender
    ) {
      return false;
    } else {
      return true;
    }
  };

  onValueChanged = (event, cell) => {
    if (event.target.innerText) {
      cell.value = event.target.innerHTML;
      if (cell.rowSpan && cell.rowSpan > 1) {
        let sum = 0;
        for (let i = cell.rowPosition; i < cell.rowPosition + cell.rowSpan - 1; i++) {
          sum += Number(this.state.structure.rowHeights[i].replace("px", ""));
        }
        this.state.structure.rowHeights[cell.rowPosition + cell.rowSpan - 1] =
          (this.td_elements[cell.key].current.getBoundingClientRect().height * 1) /
            window.panelScale -
          sum +
          "px";
      } else {
        this.state.structure.rowHeights[cell.rowPosition] =
          (this.td_elements[cell.key].current.getBoundingClientRect().height * 1) /
            window.panelScale +
          "px";
      }

      if (this.props.height !== this.getSizesSum("rowHeights")) {
        this.shouldRender = false;
        this.props.onHeightChange(this.getSizesSum("rowHeights"), true, true, () => {
          this.shouldRender = true;
        });
      }
    } else {
      cell.value = "";
    }
  };

  pushPropsChanges = (shouldPushChange = true, oldStructure) => {
    if (shouldPushChange) {
      this.props.onPropsChange({ structure: this.state.structure }, "props", true, {
        prevChange: oldStructure,
        nextChange: this.state.structure,
      });

      if (!this.shouldPush) {
        this.shouldPush = true;
      }
    } else {
      this.props.onPropsChange({ structure: this.state.structure }, "props", false);
    }
  };

  getSizesSum = (key) => {
    let sum = 0;

    this.state.structure[key].forEach((c) => {
      sum += Number(c.replace("px", ""));
    });

    return sum;
  };

  onMouseMove = (event) => {
    let tableRect = this.tableRef.getBoundingClientRect();

    if (this.mouseAction.action == "resizeCol") {
      const x =
        ((event.clientX - this.mouseAction.startPoint.x) * this.getSizesSum("colWidths")) /
        tableRect.width;
      if (this.mouseAction.startSizeFirst + x > 0 && this.mouseAction.startSizeSecond - x > 0) {
        const sizeFirst = this.mouseAction.startSizeFirst + x;
        const sizeSecond = this.mouseAction.startSizeSecond - x;

        this.state.structure.colWidths[this.mouseAction.index] = sizeFirst + "px";
        this.state.structure.colWidths[this.mouseAction.index + 1] = sizeSecond + "px";
      }
    } else if (this.mouseAction.action == "resizeRowTop") {
      const y = ((event.clientY - this.mouseAction.startPoint.y) * 1) / window.panelScale;
      if (this.mouseAction.startSizeFirst - y > 0) {
        const sizeFirst = this.mouseAction.startSizeFirst - y;

        if (sizeFirst > 25) {
          this.state.structure.rowHeights[this.mouseAction.index] = sizeFirst + "px";
          // this.state.structure.rowHeights[this.mouseAction.index + 1] = sizeSecond + "px";
          if (this.mouseAction.action == "resizeRowTop" && this.mouseAction.index == 0) {
            this.props.changeTopLeft(this.mouseAction.startTop + y, this.props.left);
          }
        }
      }
    } else {
      const y = ((event.clientY - this.mouseAction.startPoint.y) * 1) / window.panelScale;
      if (this.mouseAction.startSizeFirst + y > 0) {
        const sizeFirst = this.mouseAction.startSizeFirst + y;

        if (sizeFirst > 25) {
          this.state.structure.rowHeights[this.mouseAction.index] = sizeFirst + "px";
          // this.state.structure.rowHeights[this.mouseAction.index + 1] = sizeSecond + "px";
        }
      }
    }
    this.forceUpdate();
  };

  onMouseUp = () => {
    this.pushPropsChanges(false);
    window.removeEventListener("mousemove", this.onMouseMove);
    window.removeEventListener("mouseup", this.onMouseUp);
  };

  onMouseDown = (action, index, event) => {
    let startSizeFirst = 0;
    let startTop = 0;
    let startSizeSecond = 0;
    let startPoint = { x: event.clientX, y: event.clientY };
    if (action == "resizeCol") {
      startSizeFirst = Number(this.state.structure.colWidths[index].replace("px", ""));
      startSizeSecond = this.state.structure.colWidths[index + 1]
        ? Number(this.state.structure.colWidths[index + 1].replace("px", ""))
        : 0;
    } else if (action == "resizeRowTop") {
      startSizeFirst = Number(this.state.structure.rowHeights[index].replace("px", ""));
      startSizeSecond = this.state.structure.rowHeights[index + 1]
        ? Number(this.state.structure.rowHeights[index + 1].replace("px", ""))
        : 0;
      startTop = this.props.top;
    } else {
      startSizeFirst = Number(this.state.structure.rowHeights[index].replace("px", ""));
      startSizeSecond = this.state.structure.rowHeights[index + 1]
        ? Number(this.state.structure.rowHeights[index + 1].replace("px", ""))
        : 0;
    }

    this.mouseAction = {
      action,
      index,
      startSizeFirst,
      startSizeSecond,
      startPoint,
      startTop,
    };

    this.forceUpdate();

    window.addEventListener("mousemove", this.onMouseMove);
    window.addEventListener("mouseup", this.onMouseUp);

    event.preventDefault();
    event.stopPropagation();
    return false;
  };

  changeStyles = (style, callBack) => {
    let oldStruct = JSON.parse(JSON.stringify({ ...this.state.structure }));

    this.state.selectedCells.forEach((cell) => {
      const newObj = {};
      Object.assign(newObj, cell.style);
      Object.assign(newObj, style);
      cell.style = newObj;
    });

    this.forceUpdate(() => {
      this.pushPropsChanges(true, oldStruct);
      this.getRealRowHeights();
      callBack && callBack();
    });
  };

  addSymbol = (symbol) => {
    let oldStruct = JSON.parse(JSON.stringify({ ...this.state.structure }));
    this.state.selectedCells.forEach((cell) => {
      cell.value += symbol;
    });
    this.forceUpdate();
    this.pushPropsChanges(true, oldStruct);
  };

  setLink = (link) => {
    let oldStruct = JSON.parse(JSON.stringify({ ...this.state.structure }));
    this.state.selectedCells.forEach((cell) => {
      if (link) {
        cell.link = true;
        cell.href = link;
      } else {
        cell.link = false;
        cell.href = "";
      }
    });

    this.forceUpdate();
    this.pushPropsChanges(true, oldStruct);
  };

  removeStyles = () => {
    let oldStruct = JSON.parse(JSON.stringify(this.state.structure));
    this.oldStructure = oldStruct;

    this.state.selectedCells.forEach((cell) => {
      cell.style = {};
      cell.bullet = false;
      cell.number = false;
      cell.curType = "";
    });

    this.forceUpdate();
    this.pushPropsChanges(true, oldStruct);
  };

  changeRotation = (deg) => {
    let oldStruct = JSON.parse(JSON.stringify(this.state.structure));
    this.state.selectedCells.forEach((cell) => {
      cell.textRotation = deg;
      cell.style = {
        ...cell.style,
        writingMode: deg === "stacked" ? "horizontal-tb" : deg,
        transform:
          deg === "vertical-lr"
            ? "rotate(360deg)"
            : deg !== "horizontal-tb" && deg !== "stacked"
              ? "rotate(180deg)"
              : "rotate(0deg)",
        letterSpacing: deg === "stacked" ? "1rem" : "normal",
      };
    });
    this.forceUpdate(() => {
      this.getRealRowHeights();
    });
    this.pushPropsChanges(true, oldStruct);
  };

  onCellClick = (cell, e) => {
    if (!e.shiftKey) {
      this.startShiftColIndex = cell.colPosition;
      this.startShiftRowIndex = cell.rowPosition;
    }

    if (e.button !== 2 || (e.button === 2 && this.state.selectedCells.indexOf(cell) == -1)) {
      cell.oldValue = cell.value;
      if (
        e.detail === 1 &&
        (this.editingCell === null || JSON.stringify(this.editingCell) != JSON.stringify(cell)) &&
        this.state.selectedCells.indexOf(cell) == -1
      ) {
        // cell.key = new Date().getTime() + cell.colPosition + cell.rowPosition;
        // this.forceUpdate();
        // document.getElementById(cell.colPosition.toString() + cell.rowPosition.toString()).innerHTML = cell.value;
        this.editingCell = null;

        if (!this.props.activeFormatPainter) {
          if (e.ctrlKey) {
            if (this.state.selectedCells.indexOf(cell) == -1 || true) {
              this.state.selectedCells.push(cell);
              this.forceUpdate();
            }
          } else if (e.shiftKey) {
            let startColIndex = -1;
            let endColIndex = -1;
            let startRowIndex = -1;
            let endRowIndex = -1;

            if (this.startShiftColIndex > cell.colPosition) {
              startColIndex = cell.colPosition;
              endColIndex = this.startShiftColIndex;
            } else {
              startColIndex = this.startShiftColIndex;
              endColIndex = cell.colPosition;
            }

            if (this.startShiftRowIndex > cell.rowPosition) {
              startRowIndex = cell.rowPosition;
              endRowIndex = this.startShiftRowIndex;
            } else {
              startRowIndex = this.startShiftRowIndex;
              endRowIndex = cell.rowPosition;
            }

            for (let i = startRowIndex; i <= endRowIndex; i++) {
              for (let j = startColIndex; j <= endColIndex; j++) {
                if (this.state.selectedCells.indexOf(this.state.structure.rows[i][j]) == -1) {
                  this.state.selectedCells.push(this.state.structure.rows[i][j]);
                  this.forceUpdate();
                }
              }
            }
          } else {
            this.state.selectedCells = [cell];
            // this.forceUpdate();
          }
        } else {
          cell.style = { ...cell.style, ...this.props.formatPainterStyle };
          this.shoudlStopFormatPainter = true;
          this.setState({ selectedCells: [cell] });
        }
      } else if (e.detail === 1 && this.state.selectedCells.length > 1) {
        this.state.selectedCells = [cell];
        this.forceUpdate();
      } else if (e.detail === 2 && this.editingCell === null) {
        cell.key = new Date().getTime() + cell.colPosition + cell.rowPosition;
        this.createRef();
        this.editingCell = cell;
        this.state.selectedCells = [cell];
        this.forceUpdate();
        setTimeout(() => this.handleSetSelectionLeftRight(false, cell.key), 100);
      }
    }
    // else {
    //     if (this.editingCell === null) {
    //         cell.key = new Date().getTime() + cell.colPosition.toString() + cell.rowPosition.toString();
    //         this.createRef();
    //     }
    // }
  };

  handleKeyDown = (event) => {
    if (this.editingCell !== null) {
      event.stopPropagation();
    }

    if (event.keyCode == 17) {
      event.stopPropagation();
      this.key_ctrl = true;
    } else if (event.keyCode == 16) {
      this.key_shift = true;
    } else if (event.ctrlKey && event.key === "x") {
      event.stopPropagation();
    }
  };

  handleKeyUp = (event) => {
    if (event.keyCode == 17) {
      this.key_ctrl = false;
    } else if (event.keyCode == 16) {
      this.key_shift = false;
    }
  };

  handleOnAddColumn = () => {
    this.state.structure.colCount++;
    this.updateMissingCells();
    this.forceUpdate();
  };

  handleOnAddRow = () => {
    this.state.structure.rowCount++;
    this.updateMissingCells();
    this.forceUpdate();
  };

  multipleSelect = (e) => {
    if (e.button !== 2) {
      this.setState({ multipleSelect: true });
      document.addEventListener("mouseup", this.stopMultipleSelect);
    }
  };

  stopMultipleSelect = (e) => {
    if (this.props.activeFormatPainter && this.shoudlStopFormatPainter) {
      this.shoudlStopFormatPainter = false;
      this.handleActiveFormatPainter();
      this.setState({ multipleSelect: false, activeFormatPainter: false, formatPainterStyle: [] });
    } else {
      this.setState({ multipleSelect: false });
    }

    document.removeEventListener("mouseup", this.stopMultipleSelect);
  };

  selectCell = (cell) => {
    if (this.editingCell === null) {
      if (this.state.multipleSelect && this.props.activeFormatPainter) {
        cell.style = { ...cell.style, ...this.props.formatPainterStyle };
        this.state.selectedCells.push(cell);
        this.shoudlStopFormatPainter = true;
        this.forceUpdate();
      } else if (this.state.multipleSelect) {
        this.state.selectedCells = [];

        let startColIndex = -1;
        let endColIndex = -1;
        let startRowIndex = -1;
        let endRowIndex = -1;

        startColIndex = this.startShiftColIndex;
        endColIndex = cell.colPosition;
        startRowIndex = this.startShiftRowIndex;
        endRowIndex = cell.rowPosition;

        if (endRowIndex > startRowIndex) {
          this.state.structure.rows.forEach((row, index) => {
            if (index >= startRowIndex && index <= endRowIndex) {
              row.forEach((curCell) => {
                if (startColIndex > endColIndex) {
                  if (curCell.colPosition >= endColIndex && curCell.colPosition <= startColIndex) {
                    this.state.selectedCells.push(curCell);
                    this.forceUpdate();
                  }
                } else {
                  if (curCell.colPosition >= startColIndex && curCell.colPosition <= endColIndex) {
                    this.state.selectedCells.push(curCell);
                    this.forceUpdate();
                  }
                }
              });
            }
          });
        } else {
          this.state.structure.rows.forEach((row, index) => {
            if (index >= endRowIndex && index <= startRowIndex) {
              row.forEach((curCell) => {
                if (startColIndex > endColIndex) {
                  if (curCell.colPosition >= endColIndex && curCell.colPosition <= startColIndex) {
                    this.state.selectedCells.push(curCell);
                    this.forceUpdate();
                  }
                } else {
                  if (curCell.colPosition >= startColIndex && curCell.colPosition <= endColIndex) {
                    this.state.selectedCells.push(curCell);
                    this.forceUpdate();
                  }
                }
              });
            }
          });
        }
      }
    }
  };

  checkMergeType = () => {
    this.state.selectedCells.forEach((cell) => {});
  };

  findCommonValues = (obj1, obj2) => {
    var result = {};
    for (let key in obj1) {
      if (obj1[key] && obj1[key] === obj2[key]) result[key] = obj1[key];
    }
    return result;
  };

  getSelectedRotation = () => {
    let degrees = [];
    this.state.selectedCells.forEach((cell) => {
      if (cell.textRotation) {
        degrees.push(cell.textRotation);
      } else {
        degrees.push("horizontal-tb");
      }
    });

    const result = degrees.every((element) => {
      if (element === degrees[0]) {
        return true;
      }
    });

    if (result) {
      return degrees[0];
    } else {
      return "horizontal-tb";
    }
  };

  getSelectedCellsStyle = () => {
    let styles = [];

    this.state.selectedCells.forEach((cell) => {
      styles.push({
        ...cell.style,
        bullet: cell.bullet || cell.number,
        bulletType: cell.bullet || cell.number ? (cell.bullet ? "bullet" : "number") : "",
        curType: cell.curType,
      });
    });

    if (styles.length > 1) {
      let result = {};
      for (let k = 0; k < styles.length; k++) {
        if (k === 0) {
          result = this.findCommonValues(styles[k], styles[k + 1]);
          k++;
        } else {
          result = this.findCommonValues(result, styles[k]);
        }
      }
      return result;
    } else {
      return styles[0];
    }
  };

  checkIfCanMerge = () => {
    let sortedArr = this.state.selectedCells.sort((a, b) =>
      a.colPosition < b.colPosition ? 1 : -1,
    );
    sortedArr.sort((a, b) => (a.rowPosition > b.rowPosition ? 1 : -1));
    let canMergeCol = true;
    let canMergeRow = true;

    sortedArr.forEach((el, index) => {
      if (index > 0) {
        if (
          !(
            el.rowPosition === sortedArr[index - 1].rowPosition &&
            el.colPosition ===
              sortedArr[index - 1].colPosition +
                (sortedArr[index - 1].colSpan && sortedArr[index - 1].colSpan > 1
                  ? sortedArr[index - 1].colSpan
                  : 1)
          )
        ) {
          canMergeCol = false;
        }
      }
    });

    sortedArr.forEach((el, index) => {
      if (index > 0) {
        if (
          !(
            el.colPosition === sortedArr[index - 1].colPosition &&
            el.rowPosition ===
              sortedArr[index - 1].rowPosition +
                (sortedArr[index - 1].rowSpan && sortedArr[index - 1].rowSpan > 1
                  ? sortedArr[index - 1].rowSpan
                  : 1)
          )
        ) {
          canMergeRow = false;
        }
      }
    });

    let curRow = -1;
    let curCol = -1;
    let startingCol = -1;
    let curColCount = 0;
    let beforeColCount = -1;
    let canMergeCombo = true;

    sortedArr.forEach((cell, index) => {
      if (index === 0) {
        curRow = cell.rowPosition;
        curCol = cell.colPosition;
        startingCol = cell.colPosition;
        cell.colSpan && cell.colSpan > 1 ? (curColCount += cell.colSpan) : curColCount++;
      } else {
        if (curRow === cell.rowPosition) {
          cell.colSpan && cell.colSpan > 1 ? (curColCount += cell.colSpan) : curColCount++;
          if (
            curCol + 1 !== cell.colPosition ||
            (beforeColCount != -1 && curColCount > beforeColCount)
          ) {
            canMergeCombo = false;
            return;
          } else {
            curRow = cell.rowPosition;
            curCol = cell.colPosition;
          }
        } else {
          if (
            !cell.rowSpan &&
            curRow + 1 === cell.rowPosition &&
            (beforeColCount === -1 || beforeColCount === curColCount)
          ) {
            if (startingCol === cell.colPosition) {
              curRow = cell.rowPosition;
              curCol = cell.colPosition;
              beforeColCount = curColCount;
              curColCount = cell.colSpan && cell.colSpan > 1 ? cell.colSpan : 1;
            } else {
              canMergeCombo = false;
              return;
            }
          } else {
            canMergeCombo = false;
            return;
          }
        }
      }
    });

    if (this.state.selectedCells.length === 1) {
      if (
        this.state.selectedCells[0].rowSpan &&
        this.state.selectedCells[0].rowSpan > 1 &&
        this.state.selectedCells[0].colSpan &&
        this.state.selectedCells[0].colSpan > 1
      ) {
        return "unmerge combo";
      } else if (this.state.selectedCells[0].colSpan && this.state.selectedCells[0].colSpan > 1) {
        return "unmerge col";
      } else if (this.state.selectedCells[0].rowSpan && this.state.selectedCells[0].rowSpan > 1) {
        return "unmerge row";
      } else {
        return "cannot";
      }
    }

    if (canMergeCol) {
      return "col";
    } else if (canMergeRow) {
      return "row";
    } else if (canMergeCombo) {
      return "combo";
    } else {
      return "cannot";
    }
  };

  mergeCells = () => {
    if (this.state.selectedCells.length >= 1) {
      let merge = this.checkIfCanMerge();
      let oldStruct = JSON.parse(JSON.stringify(this.state.structure));
      let shouldPushChange = true;
      // this.state.selectedCells.sort((a, b) => a.key > b.key ? 1 : -1);

      if (merge === "col") {
        let colSpan = 0;

        this.state.selectedCells.forEach((cell) => {
          if (cell.colSpan && cell.colSpan > 0) {
            colSpan = colSpan + cell.colSpan;
          } else {
            colSpan = colSpan + 1;
          }
        });

        for (var i = 0; i < this.state.selectedCells.length; i++) {
          if (i !== 0) {
            for (var c = 0; c < this.state.structure.rows.length; c++) {
              for (var j = 0; j < this.state.structure.rows[c].length; j++) {
                if (this.state.selectedCells[i].key === this.state.structure.rows[c][j].key) {
                  this.state.structure.rows[c][j].hidden = true;
                  this.state.structure.rows[c][j].colPosition = -1;
                  this.state.structure.rows[c][j].rowPosition = -1;
                }
              }
            }
          } else {
            for (var p = 0; p < this.state.structure.rows.length; p++) {
              for (var k = 0; k < this.state.structure.rows[p].length; k++) {
                if (this.state.selectedCells[i].key === this.state.structure.rows[p][k].key) {
                  this.state.structure.rows[p][k].colSpan = colSpan;
                }
              }
            }
          }
        }
      } else if (merge === "row") {
        let rowSpan = 0;

        this.state.selectedCells.forEach((cell) => {
          if (cell.rowSpan && cell.rowSpan > 0) {
            rowSpan = rowSpan + cell.rowSpan;
          } else {
            rowSpan = rowSpan + 1;
          }
        });

        for (var i = 0; i < this.state.selectedCells.length; i++) {
          if (i !== 0) {
            for (var c = 0; c < this.state.structure.rows.length; c++) {
              for (var j = 0; j < this.state.structure.rows[c].length; j++) {
                if (this.state.selectedCells[i].key === this.state.structure.rows[c][j].key) {
                  this.state.structure.rows[c][j].hidden = true;
                  this.state.structure.rows[c][j].colPosition = -1;
                  this.state.structure.rows[c][j].rowPosition = -1;
                }
              }
            }
          } else {
            for (var p = 0; p < this.state.structure.rows.length; p++) {
              for (var k = 0; k < this.state.structure.rows[p].length; k++) {
                if (this.state.selectedCells[i].key === this.state.structure.rows[p][k].key) {
                  this.state.structure.rows[p][k].rowSpan = rowSpan;
                }
              }
            }
          }
        }
      } else if (merge === "combo") {
        let curRow = -1;
        let curCol = -1;
        let startingCol = -1;
        let colSpan = 0;
        let rowSpan = 0;
        let shouldCountCols = true;

        this.state.selectedCells.forEach((cell, index) => {
          if (index === 0) {
            curRow = cell.rowPosition;
            curCol = cell.colPosition;
            startingCol = cell.colPosition;
            if (cell.colSpan && cell.colSpan > 0) {
              colSpan = colSpan + cell.colSpan;
            } else {
              colSpan = colSpan + 1;
            }
            if (cell.rowSpan && cell.rowSpan > 0) {
              rowSpan = rowSpan + cell.colSpan;
            } else {
              rowSpan = rowSpan + 1;
            }
          } else {
            if (curRow === cell.rowPosition) {
              if (shouldCountCols) {
                if (cell.colSpan && cell.colSpan > 0) {
                  colSpan = colSpan + cell.colSpan;
                } else {
                  colSpan = colSpan + 1;
                }
                curRow = cell.rowPosition;
                curCol = cell.colPosition;
              }
            } else {
              if (cell.rowSpan && cell.rowSpan > 0) {
                rowSpan = rowSpan + cell.colSpan;
              } else {
                rowSpan = rowSpan + 1;
              }
              curRow = cell.rowPosition;
              curCol = cell.colPosition;
              shouldCountCols = false;
            }
          }
        });

        for (var i = 0; i < this.state.selectedCells.length; i++) {
          if (i !== 0) {
            for (var c = 0; c < this.state.structure.rows.length; c++) {
              for (var j = 0; j < this.state.structure.rows[c].length; j++) {
                if (this.state.selectedCells[i].key === this.state.structure.rows[c][j].key) {
                  this.state.structure.rows[c][j].hidden = true;
                  this.state.structure.rows[c][j].colPosition = -1;
                  this.state.structure.rows[c][j].rowPosition = -1;
                }
              }
            }
          } else {
            for (var p = 0; p < this.state.structure.rows.length; p++) {
              for (var k = 0; k < this.state.structure.rows[p].length; k++) {
                if (this.state.selectedCells[i].key === this.state.structure.rows[p][k].key) {
                  this.state.structure.rows[p][k].rowSpan = rowSpan;
                  this.state.structure.rows[p][k].colSpan = colSpan;
                }
              }
            }
          }
        }
      } else if (merge === "unmerge col") {
        let selectedCell = this.state.selectedCells[0];
        let colSpan = selectedCell.colSpan;
        let colPosition = selectedCell.colPosition;
        let rowPosition = selectedCell.rowPosition;

        for (let i = colPosition + 1; i <= colPosition + colSpan - 1; i++) {
          if (i < this.state.structure.colCount) {
            this.state.structure.rows[rowPosition][i].hidden = false;
          } else {
            break;
          }
        }
        this.state.selectedCells[0].colSpan = 1;
      } else if (merge === "unmerge row") {
        let selectedCell = this.state.selectedCells[0];
        let rowSpan = selectedCell.rowSpan;
        let colPosition = selectedCell.colPosition;
        let rowPosition = selectedCell.rowPosition;

        for (let i = rowPosition + 1; i <= rowPosition + rowSpan - 1; i++) {
          if (i < this.state.structure.rowCount) {
            this.state.structure.rows[i][colPosition].hidden = false;
          } else {
            break;
          }
        }
        this.state.selectedCells[0].rowSpan = 1;
      } else if (merge === "unmerge combo") {
        let selectedCell = this.state.selectedCells[0];
        let colSpan = selectedCell.colSpan;
        let rowSpan = selectedCell.rowSpan;
        let colPosition = selectedCell.colPosition;
        let rowPosition = selectedCell.rowPosition;

        for (let i = selectedCell.rowPosition; i < rowSpan + selectedCell.rowPosition; i++) {
          for (let j = selectedCell.colPosition; j < colSpan + selectedCell.colPosition; j++) {
            if (this.state.structure.rows[i][j].hidden) {
              this.state.structure.rows[i][j].rowSpan = 1;
              this.state.structure.rows[i][j].colSpan = 1;
              this.state.structure.rows[i][j].hidden = false;
            }
          }
        }
        this.state.selectedCells[0].colSpan = 1;
        this.state.selectedCells[0].rowSpan = 1;

        // for(let i = colPosition + 1; i <= (colPosition + colSpan - 1); i++){
        //     this.state.structure.rows[rowPosition][i].hidden = false;
        //
        // }
        // for(let i = rowPosition + 1; i <= (rowPosition + rowSpan - 1); i++){
        //     this.state.structure.rows[i][colPosition].hidden = false;
        // }
      } else {
        shouldPushChange = false;
        alert("cannot merge");
      }

      if (shouldPushChange) {
        this.pushPropsChanges(true, oldStruct);
      }
    }

    this.state.selectedCells = [];
    this.forceUpdate();
  };

  activeFormatPainter = () => {
    if (this.state.formatPainter === false && this.state.selectedCells.length === 1) {
      this.setState({ formatPainter: true, selectedStyle: this.state.selectedCells[0].style });
    } else {
      this.setState({ formatPainter: false, selectedStyle: {} });
    }
  };

  handleContextMenu = (e) => {
    if (this.props.disableEdit) {
      return;
    }
    e.stopPropagation();
    if (!this.props.moreThanOneSelected && this.editingCell == null) {
      e.preventDefault();
      this.setState({
        menuProps: true,
        anchorPoint: { x: e.pageX, y: e.pageY },
        borderMenu: false,
      });
      this.props.onSelect();
      // this.forceUpdate()
    } else if (this.editingCell != null) {
      e.showDefault = true;
    }
  };
  handleBorderContextMenu = (e) => {
    if (!this.props.moreThanOneSelected) {
      e.stopPropagation();
      e.preventDefault();
      this.setState({
        menuProps: true,
        anchorPoint: { x: e.pageX, y: e.pageY },
        borderMenu: true,
      });
      this.props.onSelect();
    }
  };
  handleContextMenuClose = () => {
    this.setState({ anchorPoint: { x: 0, y: 0 }, menuProps: undefined });
    this.forceUpdate();
  };

  handleActiveFormatPainter = () => {
    let selectedStyle = this.getSelectedCellsStyle();
    this.props.handleActiveFormatPainter(selectedStyle);
    this.forceUpdate();
    // this.setState({activeFormatPainter: !this.state.activeFormatPainter});
  };

  deleteRow = () => {
    let oldStruct = JSON.parse(JSON.stringify(this.state.structure));

    let rowPositions = [];
    let sortedArr = this.state.selectedCells.sort((a, b) =>
      a.rowPosition < b.rowPosition ? 1 : -1,
    );
    sortedArr.forEach((cell, index) => {
      if (cell.rowSpan && cell.rowSpan > 1) {
        for (let i = cell.rowPosition + cell.rowSpan - 1; i >= cell.rowPosition; i--) {
          if (rowPositions.indexOf(i) === -1) {
            rowPositions.push(i);
          }
        }
      } else {
        if (rowPositions.indexOf(cell.rowPosition) === -1) {
          rowPositions.push(cell.rowPosition);
        }
      }
    });

    if (rowPositions.length === this.state.structure.rowCount) {
      this.props.onDelete();
    } else {
      rowPositions.forEach((rowPosition) => {
        this.state.structure.rows.splice(rowPosition, 1);
        this.state.structure.rowHeights.splice(rowPosition, 1);
        this.state.structure.rowCount--;
      });

      this.pushPropsChanges(true, oldStruct);
    }

    this.forceUpdate();
  };

  deleteColumn = () => {
    let oldStruct = JSON.parse(JSON.stringify(this.state.structure));

    let colPositions = [];
    let sortedArr = this.state.selectedCells.sort((a, b) =>
      a.colPosition > b.colPosition ? 1 : -1,
    );
    sortedArr.sort((a, b) => (a.rowPosition > b.rowPosition ? 1 : -1));

    sortedArr.forEach((cell, index) => {
      if (cell.colSpan && cell.colSpan > 1) {
        for (let i = cell.colPosition + cell.colSpan - 1; i >= cell.colPosition; i--) {
          if (colPositions.indexOf(i) === -1) {
            colPositions.push(i);
          }
        }
      } else {
        if (colPositions.indexOf(cell.colPosition) === -1) {
          colPositions.push(cell.colPosition);
        }
      }
    });

    if (colPositions.length === this.state.structure.colCount) {
      this.props.onDelete();
    } else {
      colPositions.forEach((colPosition) => {
        this.state.structure.rows.forEach((row, index) => {
          if (row[colPosition].colSpan && row[colPosition].colSpan > 1) {
            row[colPosition].colSpan--;
          } else {
            row.splice(colPosition, 1);
          }
        });
        this.state.structure.colWidths.splice(colPosition, 1);
        this.state.structure.colCount--;
      });

      this.pushPropsChanges(true, oldStruct);
    }

    this.forceUpdate();
  };

  insertRowAbove = () => {
    let oldStruct = JSON.parse(JSON.stringify(this.state.structure));

    let min = Math.min(...this.state.selectedCells.map((o) => o.rowPosition));
    let tmpSelectedCell = this.state.selectedCells.filter((c) => c.rowPosition === min);
    let selectedCell = tmpSelectedCell[0];
    let newRowPos = selectedCell.rowPosition;
    let hasColSpan = false;
    let colSpanCounter = 0;
    let newRow = [];

    this.state.structure.rows.forEach((row, index) => {
      if (index === selectedCell.rowPosition) {
        row.forEach((cell, indexCol) => {
          if (cell.colSpan && cell.colSpan > 1) {
            newRow.push({
              colSpan: cell.colSpan,
              style: cell.style,
              rowPosition: newRowPos,
              key: "td_" + new Date().getTime() + cell.colPosition + cell.rowPosition,
              value: "",
              isBorderTopBolded: cell.isBorderTopBolded,
              isBorderRightBolded: cell.isBorderRightBolded,
              isBorderBottomBolded: cell.isBorderBottomBolded,
              isBorderLeftBolded: cell.isBorderLeftBolded,
              hideTopBorder: cell.hideTopBorder,
              hideRightBorder: cell.hideRightBorder,
              hideBottomBorder: cell.hideBottomBorder,
              hideLeftBorder: cell.hideLeftBorder,
            });

            hasColSpan = true;
            colSpanCounter = cell.colSpan - 1;
          } else if (cell.hidden && hasColSpan) {
            if (colSpanCounter === 1) {
              hasColSpan = false;
              colSpanCounter = 0;
              newRow.push({
                hidden: true,
                style: cell.style,
                rowPosition: newRowPos,
                key: "td_" + new Date().getTime() + cell.colPosition + cell.rowPosition,
                value: "",
                isBorderTopBolded: cell.isBorderTopBolded,
                isBorderRightBolded: cell.isBorderRightBolded,
                isBorderBottomBolded: cell.isBorderBottomBolded,
                isBorderLeftBolded: cell.isBorderLeftBolded,
                hideTopBorder: cell.hideTopBorder,
                hideRightBorder: cell.hideRightBorder,
                hideBottomBorder: cell.hideBottomBorder,
                hideLeftBorder: cell.hideLeftBorder,
              });
            } else {
              newRow.push({
                hidden: true,
                style: cell.style,
                rowPosition: newRowPos,
                key: "td_" + new Date().getTime() + cell.colPosition + cell.rowPosition,
                value: "",
                isBorderTopBolded: cell.isBorderTopBolded,
                isBorderRightBolded: cell.isBorderRightBolded,
                isBorderBottomBolded: cell.isBorderBottomBolded,
                isBorderLeftBolded: cell.isBorderLeftBolded,
                hideTopBorder: cell.hideTopBorder,
                hideRightBorder: cell.hideRightBorder,
                hideBottomBorder: cell.hideBottomBorder,
                hideLeftBorder: cell.hideLeftBorder,
              });
              colSpanCounter--;
            }
          } else if (cell.hidden && !hasColSpan) {
            newRow.push({
              hidden: true,
              style: cell.style,
              rowPosition: newRowPos,
              key: "td_" + new Date().getTime() + cell.colPosition + cell.rowPosition,
              value: "",
              isBorderTopBolded: cell.isBorderTopBolded,
              isBorderRightBolded: cell.isBorderRightBolded,
              isBorderBottomBolded: cell.isBorderBottomBolded,
              isBorderLeftBolded: cell.isBorderLeftBolded,
              hideTopBorder: cell.hideTopBorder,
              hideRightBorder: cell.hideRightBorder,
              hideBottomBorder: cell.hideBottomBorder,
              hideLeftBorder: cell.hideLeftBorder,
            });

            for (let i = this.state.structure.rows.length - 1; i >= 0; i--) {
              if (
                this.state.structure.rows[i][indexCol].rowSpan &&
                this.state.structure.rows[i][indexCol].rowSpan > 1
              ) {
                this.state.structure.rows[i][indexCol].rowSpan++;
                break;
              }
            }
          } else {
            newRow.push({
              style: cell.style,
              rowPosition: newRowPos,
              key: "td_" + new Date().getTime() + cell.colPosition + cell.rowPosition,
              value: "",
              isBorderTopBolded: cell.isBorderTopBolded,
              isBorderRightBolded: cell.isBorderRightBolded,
              isBorderBottomBolded: cell.isBorderBottomBolded,
              isBorderLeftBolded: cell.isBorderLeftBolded,
              hideTopBorder: cell.hideTopBorder,
              hideRightBorder: cell.hideRightBorder,
              hideBottomBorder: cell.hideBottomBorder,
              hideLeftBorder: cell.hideLeftBorder,
            });
          }
        });
      } else if (index >= selectedCell.rowPosition) {
        row.forEach((cell) => {
          cell.rowPosition++;
        });
      }
    });

    this.state.structure.rowHeights.splice(
      selectedCell.rowPosition,
      0,
      this.state.structure.rowHeights[selectedCell.rowPosition],
    );
    this.state.structure.rowCount++;
    this.state.structure.rows.splice(selectedCell.rowPosition, 0, newRow);
    this.props.onHeightChange(
      this.state.structure.rowHeights[selectedCell.rowPosition].replace("px", ""),
    );

    this.pushPropsChanges(true, oldStruct);
  };

  insertRowBelow = () => {
    let oldStruct = JSON.parse(JSON.stringify(this.state.structure));

    let max = Math.max(...this.state.selectedCells.map((o) => o.rowPosition));
    let tmpSelectedCell = this.state.selectedCells.filter((c) => c.rowPosition === max);
    let selectedCell = tmpSelectedCell[0];
    let newRowPos = selectedCell.rowPosition + 1;
    let hasColSpan = false;
    let colSpanCounter = 0;
    let newRow = [];

    this.state.structure.rows.forEach((row, index) => {
      if (index === selectedCell.rowPosition) {
        row.forEach((cell, indexCol) => {
          if (cell.colSpan && cell.colSpan > 1) {
            newRow.push({
              colSpan: cell.colSpan,
              style: cell.style,
              rowPosition: newRowPos,
              key: "td_" + new Date().getTime() + cell.colPosition + cell.rowPosition,
              value: "",
              isBorderTopBolded: cell.isBorderTopBolded,
              isBorderRightBolded: cell.isBorderRightBolded,
              isBorderBottomBolded: cell.isBorderBottomBolded,
              isBorderLeftBolded: cell.isBorderLeftBolded,
              hideTopBorder: cell.hideTopBorder,
              hideRightBorder: cell.hideRightBorder,
              hideBottomBorder: cell.hideBottomBorder,
              hideLeftBorder: cell.hideLeftBorder,
            });

            hasColSpan = true;
            colSpanCounter = cell.colSpan - 1;
          } else if (cell.hidden && hasColSpan) {
            if (colSpanCounter === 1) {
              hasColSpan = false;
              colSpanCounter = 0;
              newRow.push({
                hidden: true,
                style: cell.style,
                rowPosition: newRowPos,
                key: "td_" + new Date().getTime() + cell.colPosition + cell.rowPosition,
                value: "",
                isBorderTopBolded: cell.isBorderTopBolded,
                isBorderRightBolded: cell.isBorderRightBolded,
                isBorderBottomBolded: cell.isBorderBottomBolded,
                isBorderLeftBolded: cell.isBorderLeftBolded,
                hideTopBorder: cell.hideTopBorder,
                hideRightBorder: cell.hideRightBorder,
                hideBottomBorder: cell.hideBottomBorder,
                hideLeftBorder: cell.hideLeftBorder,
              });
            } else {
              newRow.push({
                hidden: true,
                style: cell.style,
                rowPosition: newRowPos,
                key: "td_" + new Date().getTime() + cell.colPosition + cell.rowPosition,
                value: "",
                isBorderTopBolded: cell.isBorderTopBolded,
                isBorderRightBolded: cell.isBorderRightBolded,
                isBorderBottomBolded: cell.isBorderBottomBolded,
                isBorderLeftBolded: cell.isBorderLeftBolded,
                hideTopBorder: cell.hideTopBorder,
                hideRightBorder: cell.hideRightBorder,
                hideBottomBorder: cell.hideBottomBorder,
                hideLeftBorder: cell.hideLeftBorder,
              });
              colSpanCounter--;
            }
          } else if (cell.rowSpan && cell.rowSpan > 1) {
            cell.rowSpan++;
            newRow.push({
              hidden: true,
              style: cell.style,
              rowPosition: newRowPos,
              key: "td_" + new Date().getTime() + cell.colPosition + cell.rowPosition,
              value: "",
              isBorderTopBolded: cell.isBorderTopBolded,
              isBorderRightBolded: cell.isBorderRightBolded,
              isBorderBottomBolded: cell.isBorderBottomBolded,
              isBorderLeftBolded: cell.isBorderLeftBolded,
              hideTopBorder: cell.hideTopBorder,
              hideRightBorder: cell.hideRightBorder,
              hideBottomBorder: cell.hideBottomBorder,
              hideLeftBorder: cell.hideLeftBorder,
            });
          } else if (cell.hidden && !hasColSpan) {
            newRow.push({
              hidden: true,
              style: cell.style,
              rowPosition: newRowPos,
              key: "td_" + new Date().getTime() + cell.colPosition + cell.rowPosition,
              value: "",
              isBorderTopBolded: cell.isBorderTopBolded,
              isBorderRightBolded: cell.isBorderRightBolded,
              isBorderBottomBolded: cell.isBorderBottomBolded,
              isBorderLeftBolded: cell.isBorderLeftBolded,
              hideTopBorder: cell.hideTopBorder,
              hideRightBorder: cell.hideRightBorder,
              hideBottomBorder: cell.hideBottomBorder,
              hideLeftBorder: cell.hideLeftBorder,
            });

            for (let i = this.state.structure.rows.length - 1; i >= 0; i--) {
              if (
                this.state.structure.rows[i][indexCol].rowSpan &&
                this.state.structure.rows[i][indexCol].rowSpan > 1
              ) {
                this.state.structure.rows[i][indexCol].rowSpan++;
                break;
              }
            }
          } else {
            newRow.push({
              style: cell.style,
              rowPosition: newRowPos,
              key: "td_" + new Date().getTime() + cell.colPosition + cell.rowPosition,
              value: "",
              isBorderTopBolded: cell.isBorderTopBolded,
              isBorderRightBolded: cell.isBorderRightBolded,
              isBorderBottomBolded: cell.isBorderBottomBolded,
              isBorderLeftBolded: cell.isBorderLeftBolded,
              hideTopBorder: cell.hideTopBorder,
              hideRightBorder: cell.hideRightBorder,
              hideBottomBorder: cell.hideBottomBorder,
              hideLeftBorder: cell.hideLeftBorder,
            });
          }
        });
      } else if (index > newRowPos) {
        row.forEach((cell) => {
          cell.rowPosition++;
        });
      }
    });

    this.state.structure.rowHeights.splice(
      selectedCell.rowPosition + 1,
      0,
      this.state.structure.rowHeights[selectedCell.rowPosition],
    );
    this.state.structure.rowCount++;
    this.state.structure.rows.splice(selectedCell.rowPosition + 1, 0, newRow);
    this.props.onHeightChange(
      this.state.structure.rowHeights[selectedCell.rowPosition].replace("px", ""),
    );

    this.pushPropsChanges(true, oldStruct);
  };

  insertColumnLeft = () => {
    let oldStruct = JSON.parse(JSON.stringify(this.state.structure));

    let min = Math.min(...this.state.selectedCells.map((o) => o.colPosition));
    let tmpSelectedCell = this.state.selectedCells.filter((c) => c.colPosition === min);
    let selectedCell = { ...tmpSelectedCell[0] };
    let hasRowSpan = false;
    let rowSpanCounter = 0;

    this.state.structure.rows.forEach((row, indexRow) => {
      let newColPos = row[selectedCell.colPosition].colPosition;
      let newStyle = row[selectedCell.colPosition].style;
      row.forEach((cell, index) => {
        if (index === selectedCell.colPosition) {
          if (cell.rowSpan && cell.rowSpan > 1) {
            row.splice(selectedCell.colPosition, 0, {
              colPosition: newColPos,
              rowSpan: cell.rowSpan,
              key: "td_" + new Date().getTime() + indexRow + newColPos,
              style: newStyle,
              value: "",
              isBorderTopBolded: cell.isBorderTopBolded,
              isBorderRightBolded: cell.isBorderRightBolded,
              isBorderBottomBolded: cell.isBorderBottomBolded,
              isBorderLeftBolded: cell.isBorderLeftBolded,
              hideTopBorder: cell.hideTopBorder,
              hideRightBorder: cell.hideRightBorder,
              hideBottomBorder: cell.hideBottomBorder,
              hideLeftBorder: cell.hideLeftBorder,
            });
            hasRowSpan = true;
            rowSpanCounter = cell.rowSpan - 1;
          } else if (cell.hidden && hasRowSpan) {
            if (rowSpanCounter === 1) {
              rowSpanCounter = 0;
              hasRowSpan = false;
              row.splice(selectedCell.colPosition, 0, {
                colPosition: newColPos,
                hidden: true,
                key: "td_" + new Date().getTime() + indexRow + newColPos,
                style: newStyle,
                value: "",
                isBorderTopBolded: cell.isBorderTopBolded,
                isBorderRightBolded: cell.isBorderRightBolded,
                isBorderBottomBolded: cell.isBorderBottomBolded,
                isBorderLeftBolded: cell.isBorderLeftBolded,
                hideTopBorder: cell.hideTopBorder,
                hideRightBorder: cell.hideRightBorder,
                hideBottomBorder: cell.hideBottomBorder,
                hideLeftBorder: cell.hideLeftBorder,
              });
            } else {
              row.splice(selectedCell.colPosition, 0, {
                colPosition: newColPos,
                hidden: true,
                key: "td_" + new Date().getTime() + indexRow + newColPos,
                style: newStyle,
                value: "",
                isBorderTopBolded: cell.isBorderTopBolded,
                isBorderRightBolded: cell.isBorderRightBolded,
                isBorderBottomBolded: cell.isBorderBottomBolded,
                isBorderLeftBolded: cell.isBorderLeftBolded,
                hideTopBorder: cell.hideTopBorder,
                hideRightBorder: cell.hideRightBorder,
                hideBottomBorder: cell.hideBottomBorder,
                hideLeftBorder: cell.hideLeftBorder,
              });
              rowSpanCounter--;
            }
          } else if (cell.hidden && !hasRowSpan) {
            row.splice(selectedCell.colPosition, 0, {
              colPosition: newColPos,
              hidden: true,
              key: "td_" + new Date().getTime() + indexRow + newColPos,
              style: newStyle,
              value: "",
              isBorderTopBolded: cell.isBorderTopBolded,
              isBorderRightBolded: cell.isBorderRightBolded,
              isBorderBottomBolded: cell.isBorderBottomBolded,
              isBorderLeftBolded: cell.isBorderLeftBolded,
              hideTopBorder: cell.hideTopBorder,
              hideRightBorder: cell.hideRightBorder,
              hideBottomBorder: cell.hideBottomBorder,
              hideLeftBorder: cell.hideLeftBorder,
            });

            for (let i = row.length - 1; i >= 0; i--) {
              if (row[i].colSpan && row[i].colSpan > 1) {
                row[i].colSpan++;
                break;
              }
            }
          } else {
            row.splice(selectedCell.colPosition, 0, {
              colPosition: newColPos,
              key: "td_" + new Date().getTime() + indexRow + newColPos,
              style: newStyle,
              value: "",
              isBorderTopBolded: cell.isBorderTopBolded,
              isBorderRightBolded: cell.isBorderRightBolded,
              isBorderBottomBolded: cell.isBorderBottomBolded,
              isBorderLeftBolded: cell.isBorderLeftBolded,
              hideTopBorder: cell.hideTopBorder,
              hideRightBorder: cell.hideRightBorder,
              hideBottomBorder: cell.hideBottomBorder,
              hideLeftBorder: cell.hideLeftBorder,
            });
          }
        } else if (index >= selectedCell.colPosition) {
          cell.colPosition++;
        }
      });
    });

    this.state.structure.colWidths.splice(
      selectedCell.colPosition,
      0,
      this.state.structure.colWidths[selectedCell.colPosition],
    );
    this.state.structure.colCount++;
    this.props.onWidthChange(
      this.state.structure.colWidths[selectedCell.colPosition].replace("px", ""),
    );

    this.pushPropsChanges(true, oldStruct);
  };

  insertColumnRight = () => {
    let oldStruct = JSON.parse(JSON.stringify(this.state.structure));

    let max = Math.max(...this.state.selectedCells.map((o) => o.colPosition));
    let tmpSelectedCell = this.state.selectedCells.filter((c) => c.colPosition === max);
    let selectedCell = tmpSelectedCell[0];

    this.state.structure.rows.forEach((row, indexRow) => {
      let newColPos = row[selectedCell.colPosition].colPosition + 1;
      let newStyle = row[selectedCell.colPosition].style;
      let colSpan = false;
      let counter = 0;
      let hasRowSpan = false;
      let rowSpanCounter = 0;

      row.forEach((cell, index) => {
        if (index > newColPos) {
          cell.colPosition++;
        }

        if (index === selectedCell.colPosition) {
          if (cell.rowSpan && cell.rowSpan > 1) {
            row.splice(selectedCell.colPosition + 1, 0, {
              colPosition: newColPos,
              rowSpan: cell.rowSpan,
              key: "td_" + new Date().getTime() + indexRow + newColPos,
              style: newStyle,
              value: "",
              isBorderTopBolded: cell.isBorderTopBolded,
              isBorderRightBolded: cell.isBorderRightBolded,
              isBorderBottomBolded: cell.isBorderBottomBolded,
              isBorderLeftBolded: cell.isBorderLeftBolded,
              hideTopBorder: cell.hideTopBorder,
              hideRightBorder: cell.hideRightBorder,
              hideBottomBorder: cell.hideBottomBorder,
              hideLeftBorder: cell.hideLeftBorder,
            });
            hasRowSpan = true;
            rowSpanCounter = cell.rowSpan - 1;
          } else if (cell.hidden && hasRowSpan) {
            if (rowSpanCounter === 1) {
              rowSpanCounter = 0;
              hasRowSpan = false;
              row.splice(selectedCell.colPosition + 1, 0, {
                colPosition: newColPos,
                hidden: true,
                key: "td_" + new Date().getTime() + indexRow + newColPos,
                style: newStyle,
                value: "",
                isBorderTopBolded: cell.isBorderTopBolded,
                isBorderRightBolded: cell.isBorderRightBolded,
                isBorderBottomBolded: cell.isBorderBottomBolded,
                isBorderLeftBolded: cell.isBorderLeftBolded,
                hideTopBorder: cell.hideTopBorder,
                hideRightBorder: cell.hideRightBorder,
                hideBottomBorder: cell.hideBottomBorder,
                hideLeftBorder: cell.hideLeftBorder,
              });
            } else {
              row.splice(selectedCell.colPosition + 1, 0, {
                colPosition: newColPos,
                hidden: true,
                key: "td_" + new Date().getTime() + indexRow + newColPos,
                style: newStyle,
                value: "",
                isBorderTopBolded: cell.isBorderTopBolded,
                isBorderRightBolded: cell.isBorderRightBolded,
                isBorderBottomBolded: cell.isBorderBottomBolded,
                isBorderLeftBolded: cell.isBorderLeftBolded,
                hideTopBorder: cell.hideTopBorder,
                hideRightBorder: cell.hideRightBorder,
                hideBottomBorder: cell.hideBottomBorder,
                hideLeftBorder: cell.hideLeftBorder,
              });
              rowSpanCounter--;
            }
          } else if (cell.hidden && !hasRowSpan) {
            row.splice(selectedCell.colPosition + 1, 0, {
              colPosition: newColPos,
              hidden: true,
              key: "td_" + new Date().getTime() + indexRow + newColPos,
              style: newStyle,
              value: "",
              isBorderTopBolded: cell.isBorderTopBolded,
              isBorderRightBolded: cell.isBorderRightBolded,
              isBorderBottomBolded: cell.isBorderBottomBolded,
              isBorderLeftBolded: cell.isBorderLeftBolded,
              hideTopBorder: cell.hideTopBorder,
              hideRightBorder: cell.hideRightBorder,
              hideBottomBorder: cell.hideBottomBorder,
              hideLeftBorder: cell.hideLeftBorder,
            });

            for (let i = row.length - 1; i >= 0; i--) {
              if (row[i].colSpan && row[i].colSpan > 1) {
                row[i].colSpan++;
                break;
              }
            }
          } else if (cell.colSpan && cell.colSpan > 1) {
            cell.colSpan++;
            row.splice(selectedCell.colPosition + 1, 0, {
              colPosition: newColPos,
              hidden: true,
              key: "td_" + new Date().getTime() + indexRow + newColPos,
              style: newStyle,
              value: "",
              isBorderTopBolded: cell.isBorderTopBolded,
              isBorderRightBolded: cell.isBorderRightBolded,
              isBorderBottomBolded: cell.isBorderBottomBolded,
              isBorderLeftBolded: cell.isBorderLeftBolded,
              hideTopBorder: cell.hideTopBorder,
              hideRightBorder: cell.hideRightBorder,
              hideBottomBorder: cell.hideBottomBorder,
              hideLeftBorder: cell.hideLeftBorder,
            });
          } else {
            row.splice(selectedCell.colPosition + 1, 0, {
              colPosition: newColPos,
              key: "td_" + new Date().getTime() + indexRow + newColPos,
              style: newStyle,
              value: "",
              isBorderTopBolded: cell.isBorderTopBolded,
              isBorderRightBolded: cell.isBorderRightBolded,
              isBorderBottomBolded: cell.isBorderBottomBolded,
              isBorderLeftBolded: cell.isBorderLeftBolded,
              hideTopBorder: cell.hideTopBorder,
              hideRightBorder: cell.hideRightBorder,
              hideBottomBorder: cell.hideBottomBorder,
              hideLeftBorder: cell.hideLeftBorder,
            });
          }
        }
      });
    });

    this.state.structure.colWidths.splice(
      selectedCell.colPosition + 1,
      0,
      this.state.structure.colWidths[selectedCell.colPosition],
    );
    this.state.structure.colCount++;
    this.props.onWidthChange(
      this.state.structure.colWidths[selectedCell.colPosition].replace("px", ""),
    );

    this.pushPropsChanges(true, oldStruct);
  };

  changeKey = (cell) => {
    this.editingCell = null;
    if (cell.value !== cell.oldValue) {
      this.pushPropsChanges(true, this.oldStruct);
    }
    cell.key = new Date().getTime() + cell.colPosition + cell.rowPosition;
    this.createRef();
    this.forceUpdate();
  };

  handleCut = (e) => {
    e.stopPropagation();
    if (this.editingCell === null) {
      let oldStruct = JSON.parse(JSON.stringify(this.state.structure));

      e.preventDefault();
      this.cut = true;
      let startingColPosition = this.state.selectedCells[0].colPosition;
      let colPosition = -1;
      let rowPosition = -1;
      let arr = [];
      let finalArr = [];

      let table = document.createElement("table");
      let tr = document.createElement("tr");

      this.state.selectedCells.forEach((cell, index) => {
        if (index === this.state.selectedCells.length - 1) {
          if (index != 0) {
            if (cell.rowPosition === this.state.selectedCells[index - 1].rowPosition) {
              let td = document.createElement("td");
              td.innerHTML = cell.value;
              cell.value = "";
              tr.appendChild(td);
              table.appendChild(tr);
            } else {
              table.appendChild(tr);
              tr = document.createElement("tr");
              let td = document.createElement("td");
              td.innerHTML = cell.value;
              cell.value = "";
              tr.appendChild(td);
              table.appendChild(tr);
            }
          } else {
            let td = document.createElement("td");
            td.innerHTML = cell.value;
            cell.value = "";
            tr.appendChild(td);
            table.appendChild(tr);
          }
          // arr.push(cell.value.trim());
          // finalArr.push(arr);
        } else if (index === 0) {
          colPosition = cell.colPosition;
          rowPosition = cell.rowPosition;
          // arr.push(cell.value.trim());
          let td = document.createElement("td");
          td.innerHTML = cell.value;
          cell.value = "";
          tr.appendChild(td);
        } else {
          if (cell.rowPosition === rowPosition) {
            if (cell.colPosition === colPosition + 1) {
              colPosition = cell.colPosition;
              rowPosition = cell.rowPosition;
              let td = document.createElement("td");
              td.innerHTML = cell.value;
              cell.value = "";
              tr.appendChild(td);
            } else {
              alert("this operation cannot be done on multiple select");
            }
          } else if (cell.rowPosition === rowPosition + 1) {
            if (cell.colPosition === startingColPosition) {
              colPosition = cell.colPosition;
              rowPosition = cell.rowPosition;
              table.appendChild(tr);
              tr = document.createElement("tr");
              let td = document.createElement("td");
              td.innerHTML = cell.value;
              cell.value = "";
              tr.appendChild(td);
            } else {
              alert("this operation cannot be done on multiple select");
            }
          } else {
            alert("this operation cannot be done on multiple select");
          }
        }
      });

      const tableBlob = new Blob([`<table>${table.innerHTML}</table>`], { type: "text/html" });
      const clipboardItem = new window.ClipboardItem({ "text/html": tableBlob });

      try {
        navigator.clipboard.write([clipboardItem]);
      } catch (e) {
        console.error(e, "oh nooo");
      }
      this.forceUpdate();
      this.pushPropsChanges(true, oldStruct);
    } else {
    }
  };

  handleCopy = (e) => {
    e.stopPropagation();
    if (this.editingCell === null) {
      e.preventDefault();
      let startingColPosition = this.state.selectedCells[0].colPosition;
      let colPosition = -1;
      let rowPosition = -1;
      let arr = [];
      let finalArr = [];

      let table = document.createElement("table");
      let tr = document.createElement("tr");

      this.state.selectedCells.forEach((cell, index) => {
        if (index === this.state.selectedCells.length - 1) {
          if (index != 0) {
            if (cell.rowPosition === this.state.selectedCells[index - 1].rowPosition) {
              let td = document.createElement("td");
              td.innerHTML = cell.value;
              tr.appendChild(td);
              table.appendChild(tr);
            } else {
              table.appendChild(tr);
              tr = document.createElement("tr");
              let td = document.createElement("td");
              td.innerHTML = cell.value;
              tr.appendChild(td);
              table.appendChild(tr);
            }
          } else {
            let td = document.createElement("td");
            td.innerHTML = cell.value;
            tr.appendChild(td);
            table.appendChild(tr);
          }
          // arr.push(cell.value.trim());
          // finalArr.push(arr);
        } else if (index === 0) {
          colPosition = cell.colPosition;
          rowPosition = cell.rowPosition;
          // arr.push(cell.value.trim());
          let td = document.createElement("td");
          td.innerHTML = cell.value;
          tr.appendChild(td);
        } else {
          if (cell.rowPosition === rowPosition) {
            if (cell.colPosition === colPosition + 1) {
              colPosition = cell.colPosition;
              rowPosition = cell.rowPosition;
              let td = document.createElement("td");
              td.innerHTML = cell.value;
              tr.appendChild(td);
            } else {
              alert("this operation cannot be done on multiple select");
              return;
            }
          } else if (cell.rowPosition === rowPosition + 1) {
            if (cell.colPosition === startingColPosition) {
              colPosition = cell.colPosition;
              rowPosition = cell.rowPosition;
              table.appendChild(tr);
              tr = document.createElement("tr");
              let td = document.createElement("td");
              td.innerHTML = cell.value;
              tr.appendChild(td);
            } else {
              alert("this operation cannot be done on multiple select");
              return;
            }
          } else {
            alert("this operation cannot be done on multiple select");
            return;
          }
        }
      });

      const tableBlob = new Blob([`<table>${table.innerHTML}</table>`], { type: "text/html" });
      const clipboardItem = new window.ClipboardItem({ "text/html": tableBlob });

      try {
        navigator.clipboard.write([clipboardItem]);
      } catch (e) {
        console.error(e, "oh nooo");
      }
    }
  };

  handlePaste = (event) => {
    // event.defaultPrevented = false;

    console.log("enters here");

    if (
      event.clipboardData.getData("text") &&
      this.props.checkIfSlideObjects(event.clipboardData.getData("text"))
    ) {
      event.preventDefault();
      this.props.slideComponentPaste(event, true);
      return;
    }

    if (this.state.selectedCells.length === 0) {
      event.preventDefault();
      this.props.slideComponentPaste(event, true);
      return;
    }

    if (this.editingCell === null || !this.editingCell.value) {
      event.preventDefault();

      let oldStruct = JSON.parse(JSON.stringify(this.state.structure));

      let trs = [];

      if (event.clipboardData.getData("text/html")) {
        let htmlStr = event.clipboardData.getData("text/html");

        let parser = new DOMParser();
        let htmlDoc = parser.parseFromString(htmlStr, "text/html");

        trs = htmlDoc.getElementsByTagName("tr");
      }

      if (trs.length > 0) {
        let arr = [];

        for (let i = 0; i < trs.length; i++) {
          let tmp1Arr = [];

          let childs = trs[i].children;

          for (let j = 0; j < childs.length; j++) {
            tmp1Arr.push(childs[j].innerText);
          }

          arr.push(tmp1Arr);
        }

        let sortedArr = this.state.selectedCells.sort((a, b) =>
          a.colPosition < b.colPosition ? 1 : -1,
        );
        sortedArr.sort((a, b) => (a.rowPosition > b.rowPosition ? 1 : -1));
        let tmpArr = [];
        let newArr = [];
        let rowPosition = this.state.selectedCells[0].rowPosition;
        let colPosition = this.state.selectedCells[0].colPosition;
        let counter = 0;

        sortedArr.forEach((cell, index) => {
          if (index === sortedArr.length - 1) {
            if (index === 0) {
              tmpArr.push(cell);
              newArr.push(tmpArr);
            } else {
              if (cell.rowPosition === sortedArr[index - 1].rowPosition) {
                tmpArr.push(cell);
                newArr.push(tmpArr);
              } else {
                newArr.push(tmpArr);
                tmpArr = [cell];
                newArr.push(tmpArr);
              }
            }
          } else if (index === 0) {
            tmpArr.push(cell);
          } else {
            if (cell.rowPosition === sortedArr[index - 1].rowPosition) {
              tmpArr.push(cell);
            } else {
              newArr.push(tmpArr);
              tmpArr = [cell];
            }
          }
        });

        if (newArr[0].length <= arr[0].length && newArr.length <= arr.length) {
          for (let i = 0; i < arr.length; i++) {
            for (let j = 0; j < arr[i].length; j++) {
              if (colPosition + j + 1 === this.state.structure.colCount) {
                this.state.structure.rows[rowPosition][colPosition + j].value = arr[i][j];
                break;
              } else {
                this.state.structure.rows[rowPosition][colPosition + j].value = arr[i][j];
              }
              counter++;
            }
            if (rowPosition + 1 === this.state.structure.rowCount) {
              break;
            } else {
              rowPosition++;
            }
          }
        } else {
          let counterRow = 0;
          let counterCol = 0;

          for (let i = 0; i < newArr.length; i++) {
            for (let j = 0; j < newArr[i].length; j++) {
              newArr[i][j].value = arr[counterRow][counterCol];

              if (counterCol === arr[counterRow].length - 1) {
                counterCol = 0;
              } else {
                counterCol++;
              }
            }
            if (counterRow === arr.length - 1) {
              counterRow = 0;
            } else {
              counterRow++;
            }
          }
        }
      } else {
        let text = event.clipboardData.getData("text");

        if (text.indexOf("\t") > -1) {
          let clipBoard = event.clipboardData.getData("text").replace(/\n/g, "");
          clipBoard = clipBoard.split(/\r/);
          let newClipboardData = [];

          clipBoard.forEach((el) => newClipboardData.push(el.split(/\t/)));
          if (newClipboardData[newClipboardData.length - 1] == "") {
            newClipboardData.pop();
          }

          let rowPosition = this.state.selectedCells[0].rowPosition;
          let colPosition = this.state.selectedCells[0].colPosition;

          let counter = 0;

          let sortedArr = this.state.selectedCells.sort((a, b) =>
            a.colPosition < b.colPosition ? 1 : -1,
          );
          sortedArr.sort((a, b) => (a.rowPosition > b.rowPosition ? 1 : -1));
          let newArr = [];
          let tmpArr = [];

          sortedArr.forEach((cell, index) => {
            if (index === sortedArr.length - 1) {
              tmpArr.push(cell);
              newArr.push(tmpArr);
            } else if (index === 0) {
              tmpArr.push(cell);
            } else {
              if (cell.rowPosition === sortedArr[index - 1].rowPosition) {
                tmpArr.push(cell);
              } else {
                newArr.push(tmpArr);
                tmpArr = [cell];
              }
            }
          });

          if (newArr[0].length < newClipboardData[0].length) {
            for (let i = 0; i < newClipboardData.length; i++) {
              for (let j = 0; j < newClipboardData[i].length; j++) {
                if (colPosition + j + 1 === this.state.structure.colCount) {
                  this.state.structure.rows[rowPosition][colPosition + j].value =
                    newClipboardData[i][j];
                  break;
                } else {
                  this.state.structure.rows[rowPosition][colPosition + j].value =
                    newClipboardData[i][j];
                }
                counter++;
              }
              if (rowPosition + 1 === this.state.structure.rowCount) {
                break;
              } else {
                rowPosition++;
              }
            }
          } else {
            let counterRow = 0;
            let counterCol = 0;

            for (let i = 0; i < newArr.length; i++) {
              for (let j = 0; j < newArr[i].length; j++) {
                newArr[i][j].value = newClipboardData[counterRow][counterCol];

                if (counterCol === newClipboardData[counterRow].length - 1) {
                  counterCol = 0;
                } else {
                  counterCol++;
                }
              }
              if (counterRow === newClipboardData.length - 1) {
                counterRow = 0;
              } else {
                counterRow++;
              }
            }
          }
        } else {
          this.state.selectedCells.forEach((cell) => {
            cell.value = text;
          });
          // this.forceUpdate();
        }
        if (this.editingCell === null) {
          setTimeout(
            () => this.handleSetSelectionLeftRight(false, this.state.selectedCells[0].key),
            100,
          );
        } else {
          setTimeout(() => this.handleSetSelectionLeftRight(false, this.editingCell.key), 100);
        }
      }

      this.forceUpdate();
    } else {
      event.preventDefault();

      let paste = (event.clipboardData || window.clipboardData).getData("text");

      if (!paste) {
        let htmlStr = event.clipboardData.getData("text/html");

        let parser = new DOMParser();
        let htmlDoc = parser.parseFromString(htmlStr, "text/html");

        paste = htmlDoc.body.innerText;
      }

      const selection = window.getSelection();

      if (!selection.rangeCount) return;
      selection.deleteFromDocument();
      selection.getRangeAt(0).insertNode(document.createTextNode(paste));
      selection.collapseToEnd();

      setTimeout(() => {
        if (event.target.attributes["contenteditable"]) {
          this.editingCell.value = event.target.innerHTML;
        } else {
          let parent = this.findContentEditable(event.target);
          this.editingCell.value = parent.innerHTML;
        }
      }, 30);
    }

    if (this.cut) {
      navigator.clipboard.writeText("");
      this.cut = false;
    }

    setTimeout(() => this.handleSetSelectionLeftRight(false, this.editingCell.key), 100);
    this.forceUpdate(() => this.getRealRowHeights());
  };

  findContentEditable = (target) => {
    let parent = target.parentElement;
    if (parent.attributes["contenteditable"]) {
      return parent;
    } else {
      return this.findContentEditable(parent);
    }
  };

  cleareChildStyles = (event) => {
    let childs = event.target.children;

    for (let i = 0; i < childs.length; i++) {
      childs[i].style = {};
    }
  };

  handleBullet = (type, curType) => {
    let oldStruct = JSON.parse(JSON.stringify({ ...this.state.structure }));
    this.oldStructure = oldStruct;

    this.state.selectedCells.forEach((cell) => {
      if (type === "bullet") {
        if (cell.bullet && cell.curType === curType) {
          cell.curType = "disc";
          cell.bullet = false;
          cell.number = false;
        } else {
          cell.curType = curType;
          cell.number = false;
          cell.bullet = true;
        }
      } else {
        if (cell.number && cell.curType === curType) {
          cell.curType = "decimal";
          cell.bullet = false;
          cell.number = false;
        } else {
          cell.curType = curType;
          cell.bullet = false;
          cell.number = true;
        }
      }
    });
    this.forceUpdate();
    this.pushPropsChanges(true, oldStruct);
  };

  setCellsBorder = (type) => {
    let oldStruct = JSON.parse(JSON.stringify({ ...this.state.structure }));

    if (type === "all") {
      this.state.selectedCells.forEach((cell) => {
        cell.hideTopBorder = false;
        cell.hideRightBorder = false;
        cell.hideBottomBorder = false;
        cell.hideLeftBorder = false;
      });
    } else if (type === "top") {
      let minRow = this.state.structure.rows.length - 1;
      this.state.selectedCells.forEach((cell) => {
        if (cell.rowPosition < minRow) {
          minRow = cell.rowPosition;
        }
      });
      this.state.selectedCells.forEach((cell) => {
        if (cell.rowPosition === minRow) {
          cell.hideTopBorder = false;
        }
      });
    } else if (type === "bottom") {
      let maxRow = 0;
      this.state.selectedCells.forEach((cell) => {
        if (cell.rowPosition > maxRow) {
          maxRow = cell.rowPosition;
        }
      });
      this.state.selectedCells.forEach((cell) => {
        if (cell.rowPosition === maxRow) {
          cell.hideBottomBorder = false;
        }
      });
    } else if (type === "left") {
      let minCol = this.state.structure.colCount - 1;
      this.state.selectedCells.forEach((cell) => {
        if (cell.colPosition < minCol) {
          minCol = cell.colPosition;
        }
      });
      this.state.selectedCells.forEach((cell) => {
        if (cell.colPosition === minCol) {
          cell.hideLeftBorder = false;
        }
      });
    } else if (type === "right") {
      let maxCol = 0;
      this.state.selectedCells.forEach((cell) => {
        if (cell.colPosition > maxCol) {
          maxCol = cell.colPosition;
        }
      });
      this.state.selectedCells.forEach((cell) => {
        if (cell.colPosition === maxCol) {
          cell.hideRightBorder = false;
        }
      });
    } else if (type === "outside") {
      let maxRow = 0;
      let maxCol = 0;
      let minRow = this.state.structure.rows.length - 1;
      let minCol = this.state.structure.colCount - 1;

      this.state.selectedCells.forEach((cell) => {
        if (cell.rowPosition > maxRow) {
          maxRow = cell.rowPosition;
        }
        if (cell.rowPosition < minRow) {
          minRow = cell.rowPosition;
        }
        if (cell.colPosition > maxCol) {
          maxCol = cell.colPosition;
        }
        if (cell.colPosition < minCol) {
          minCol = cell.colPosition;
        }
      });

      this.state.selectedCells.forEach((cell) => {
        if (cell.rowPosition === maxRow) {
          cell.hideBottomBorder = false;
        }
        if (cell.rowPosition === minRow) {
          cell.hideTopBorder = false;
        }
        if (cell.colPosition === maxCol) {
          cell.hideRightBorder = false;
        }
        if (cell.colPosition === minCol) {
          cell.hideLeftBorder = false;
        }
      });
    } else if (type === "vertical") {
      let maxCol = 0;
      this.state.selectedCells.forEach((cell) => {
        if (cell.colPosition > maxCol) {
          maxCol = cell.colPosition;
        }
      });

      this.state.selectedCells.forEach((cell) => {
        if (cell.colPosition !== maxCol) {
          cell.hideRightBorder = false;
        }
      });
    } else if (type === "horizontal") {
      let maxRow = 0;
      this.state.selectedCells.forEach((cell) => {
        if (cell.rowPosition > maxRow) {
          maxRow = cell.rowPosition;
        }
      });

      this.state.selectedCells.forEach((cell) => {
        if (cell.rowPosition !== maxRow) {
          cell.hideBottomBorder = false;
        }
      });
    } else if (type === "inside") {
      let maxRow = 0;
      let maxCol = 0;
      this.state.selectedCells.forEach((cell) => {
        if (cell.rowPosition > maxRow) {
          maxRow = cell.rowPosition;
        }
        if (cell.colPosition > maxCol) {
          maxCol = cell.colPosition;
        }
      });

      this.state.selectedCells.forEach((cell) => {
        if (cell.rowPosition !== maxRow) {
          cell.hideBottomBorder = false;
        }
        if (cell.colPosition !== maxCol) {
          cell.hideRightBorder = false;
        }
      });
    } else if (type === "none") {
      this.state.selectedCells.forEach((cell) => {
        cell.hideTopBorder = true;
        cell.hideRightBorder = true;
        cell.hideBottomBorder = true;
        cell.hideLeftBorder = true;
      });
    } else if (type === "verticalAll") {
      let minCol = this.state.selectedCells.length - 1;
      this.state.selectedCells.forEach((cell) => {
        if (cell.colPosition < minCol) {
          minCol = cell.colPosition;
        }
      });

      this.state.selectedCells.forEach((cell) => {
        cell.hideLeftBorder = false;
        cell.hideRightBorder = false;
      });
    } else if (type === "horizontalAll") {
      let minRow = this.state.selectedCells.length - 1;
      this.state.selectedCells.forEach((cell) => {
        if (cell.rowPosition < minRow) {
          minRow = cell.rowPosition;
        }
      });

      this.state.selectedCells.forEach((cell) => {
        cell.hideTopBorder = false;
        cell.hideBottomBorder = false;
      });
    }

    this.forceUpdate();

    this.pushPropsChanges(true, oldStruct);
  };

  renderVal = (value, c, r) => {
    return value.map((val, index) => {
      if (val) {
        return <p style={{ margin: 0 }}>{val}</p>;
      } else {
        return (
          <p style={{ margin: 0 }}>
            <br />
          </p>
        );
      }
    });
  };

  renderBullet = (type, values, curType) => {
    if (type === "bullet") {
      return `<ul style="list-style-type: ${curType}; list-style-position: outside; margin-block: unset;" >
                ${values
                  .map((c) => {
                    if (c) {
                      return `<li>${c}</li>`;
                    }
                  })
                  .join("")
                  .split(",")}
            </ul>`;
    } else {
      return `<ol class=${"table_list"} style="list-style-type: ${curType}; list-style-position: outside; margin-block: unset;" >
                ${values
                  .map((c) => {
                    if (c) {
                      return `<li>${c}</li>`;
                    }
                  })
                  .join("")
                  .split(",")}
            </ol>`;
    }
  };

  // addSymbol = (symbol) => {
  //     if (this.state.selectedCells.length > 0) {
  //         this.state.selectedCells.forEach(cell => {
  //             cell.value += symbol;
  //         })
  //     }
  // }

  handleSelectCapture = (e) => {
    this.shouldChangeCell = false;

    var range = window.getSelection().getRangeAt(0),
      preCaretRange = range.cloneRange(),
      caretPosition,
      tmp = document.createElement("div");

    let start = range.startContainer;

    if (start.nodeName && start.nodeName != "TD") {
      let parentEl = start.parentElement;

      preCaretRange.selectNodeContents(parentEl);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      tmp.appendChild(preCaretRange.cloneContents());
      caretPosition = tmp.innerHTML.length;

      this.caretPosition = caretPosition;
    }
  };

  handleSetSelectionLeftRight = (start, key) => {
    let td = this.td_elements[key].current;

    if (td.children[0].innerText) {
      if (start) {
        let range = new Range();
        range.setStart(td.children[0].firstChild, 0);
        range.setEnd(td.children[0].firstChild, 0);
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(range);
      } else {
        td.children[0].focus();
        if (
          typeof window.getSelection != "undefined" &&
          typeof document.createRange != "undefined"
        ) {
          var range = document.createRange();
          range.selectNodeContents(td.children[0]);
          range.collapse(false);
          var sel = window.getSelection();
          sel.removeAllRanges();
          sel.addRange(range);
        } else if (typeof document.body.createTextRange != "undefined") {
          var textRange = document.body.createTextRange();
          textRange.moveToElementText(td.children[0]);
          textRange.collapse(false);
          textRange.select();
        }
      }
    } else {
      td.children[0].focus();
    }
  };

  handleSetSelectionUpDown = (up, key) => {
    let td = this.td_elements[key].current;

    if (td.children[0].innerText) {
      if (!up) {
        let range = new Range();
        range.setStart(td.children[0].firstChild, 0);
        range.setEnd(td.children[0].firstChild, 0);
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(range);
      } else {
        td.children[0].focus();
        if (
          typeof window.getSelection != "undefined" &&
          typeof document.createRange != "undefined"
        ) {
          var range = document.createRange();
          range.selectNodeContents(td.children[0]);
          range.collapse(false);
          var sel = window.getSelection();
          sel.removeAllRanges();
          sel.addRange(range);
        } else if (typeof document.body.createTextRange != "undefined") {
          var textRange = document.body.createTextRange();
          textRange.moveToElementText(td.children[0]);
          textRange.collapse(false);
          textRange.select();
        }
      }
    } else {
      td.children[0].focus();
    }
    // td.focus();
    // const childs = [...td.childNodes];
    //
    // let paragraphs = [];
    // if (!up) {
    //     for (let i = 0; i < childs.length; i++) {
    //         if (childs[i].nodeName === 'P') {
    //             paragraphs.push(childs[i]);
    //             break;
    //         }
    //         else if (childs[i].nodeName === 'UL' || childs[i].nodeName === 'OL') {
    //             for (let j = 0; j < childs[i].children.length; j++) {
    //                 paragraphs.push(childs[i].children[j]);
    //                 break;
    //             }
    //         }
    //     }
    // }
    // else {
    //     for (let i = childs.length - 1; i >= 0; i--) {
    //         if (childs[i].nodeName === 'P') {
    //             paragraphs.push(childs[i]);
    //             break;
    //         }
    //         else if (childs[i].nodeName === 'UL' || childs[i].nodeName === 'OL') {
    //             for (let j = 0; j < childs[i].children.length; j++) {
    //                 paragraphs.push(childs[i].children[j]);
    //                 break;
    //             }
    //         }
    //     }
    // }
    //
    // const paragraph = paragraphs[0];
    //
    // let range = new Range();
    //
    // if (paragraph.innerText) {
    //     if (this.selectCapture) {
    //         if (paragraph.innerText.length >= this.selectCapture.StartIndex) {
    //             range.setStart(paragraph.firstChild, this.selectCapture.StartIndex);
    //             range.setEnd(paragraph.firstChild, this.selectCapture.StartIndex)
    //         }
    //         else {
    //             range.setStart(paragraph.firstChild, paragraph.innerText.length);
    //             range.setEnd(paragraph.firstChild, paragraph.innerText.length)
    //         }
    //
    //         setTimeout(this.handleSelectCapture, 50);
    //     }
    //     else {
    //         range.setStart(paragraph.firstChild, 0);
    //         range.setEnd(paragraph.firstChild, 0)
    //     }
    //
    //     document.getSelection().removeAllRanges();
    //     document.getSelection().addRange(range);
    // }
    // else {
    //     td.focus();
    // }
  };

  changeCell = (key, cell) => {
    document.removeEventListener("selectionchange", this.handleSelectCapture);

    let selection = window.getSelection();

    if (this.shouldChangeCell && !selection.getRangeAt(0).toString()) {
      if (key === "ArrowRight") {
        let curCell = null;
        let colSpan = this.state.selectedCells[0].colSpan ? this.state.selectedCells[0].colSpan : 1;
        let rowSpan = this.state.selectedCells[0].rowSpan ? this.state.selectedCells[0].rowSpan : 1;

        if (
          this.state.selectedCells[0].colPosition + colSpan - 1 ===
            this.state.structure.colCount - 1 &&
          this.state.selectedCells[0].rowPosition === this.state.structure.rowCount - 1
        ) {
          this.state.structure.rows.forEach((row) => {
            row.forEach((cell) => {
              if (cell.colPosition === 0 && cell.rowPosition === 0) {
                curCell = cell;
              }
            });
          });
        } else if (
          this.state.selectedCells[0].colPosition + colSpan - 1 ===
          this.state.structure.colCount - 1
        ) {
          this.state.structure.rows.forEach((row) => {
            row.forEach((cell) => {
              if (
                cell.colPosition === 0 &&
                (cell.rowPosition === this.state.selectedCells[0].rowPosition + rowSpan ||
                  cell.rowPosition + (cell.rowSpan ? cell.rowSpan - 1 : 0) ===
                    this.state.selectedCells[0].rowPosition + rowSpan)
              ) {
                curCell = cell;
              }
            });
          });
        } else {
          this.state.structure.rows.forEach((row) => {
            row.forEach((cell) => {
              if (
                cell.colPosition === this.state.selectedCells[0].colPosition + colSpan &&
                (cell.rowPosition + (cell.rowSpan ? cell.rowSpan - 1 : 0) ===
                  this.state.selectedCells[0].rowPosition ||
                  cell.rowPosition === this.state.selectedCells[0].rowPosition)
              ) {
                curCell = cell;
              }
            });
          });
        }

        curCell.oldValue = curCell.value;
        this.state.selectedCells = [curCell];
        this.forceUpdate();
        this.handleSetSelectionLeftRight(true, curCell.key);
      } else if (key === "ArrowLeft") {
        let curCell = null;
        this.editingCell = null;
        if (
          this.state.selectedCells[0].colPosition === 0 &&
          this.state.selectedCells[0].rowPosition === 0
        ) {
          this.state.structure.rows.forEach((row) => {
            row.forEach((cell) => {
              if (
                cell.colPosition === this.state.structure.colCount - 1 &&
                cell.rowPosition === this.state.structure.rowCount - 1
              ) {
                curCell = cell;
              }
            });
          });
        } else if (this.state.selectedCells[0].colPosition === 0) {
          this.state.structure.rows.forEach((row) => {
            row.forEach((cell) => {
              if (
                cell.colPosition + (cell.colSpan ? cell.colSpan - 1 : 0) ===
                  this.state.structure.colCount - 1 &&
                (cell.rowPosition === this.state.selectedCells[0].rowPosition - 1 ||
                  cell.rowPosition + (cell.rowSpan ? cell.rowSpan : 1) ===
                    this.state.selectedCells[0].rowPosition)
              ) {
                curCell = cell;
              }
            });
          });
        } else {
          this.state.structure.rows.forEach((row) => {
            row.forEach((cell) => {
              if (
                cell.colPosition + (cell.colSpan ? cell.colSpan : 1) ===
                  this.state.selectedCells[0].colPosition &&
                (cell.rowPosition + (cell.rowSpan ? cell.rowSpan - 1 : 0) ===
                  this.state.selectedCells[0].rowPosition ||
                  cell.rowPosition === this.state.selectedCells[0].rowPosition)
              ) {
                curCell = cell;
              }
            });
          });
        }

        curCell.oldValue = curCell.value;
        this.state.selectedCells = [curCell];
        this.handleSetSelectionLeftRight(false, curCell.key);
        this.forceUpdate();
      } else if (key === "ArrowUp") {
        let curCell = null;
        this.editingCell = null;
        if (this.state.selectedCells[0].rowPosition !== 0) {
          this.state.structure.rows.forEach((row) => {
            row.forEach((cell) => {
              if (
                (cell.colPosition + (cell.colSpan ? cell.colSpan - 1 : 0) ===
                  this.state.selectedCells[0].colPosition ||
                  cell.colPosition === this.state.selectedCells[0].colPosition) &&
                cell.rowPosition + (cell.rowSpan ? cell.rowSpan : 1) ===
                  this.state.selectedCells[0].rowPosition
              ) {
                curCell = cell;
              }
            });
          });
          curCell.oldValue = curCell.value;
          this.state.selectedCells = [curCell];
          this.handleSetSelectionUpDown(true, curCell.key);
          this.forceUpdate();
        }
      } else if (key === "ArrowDown") {
        let curCell = null;
        this.editingCell = null;
        let rowSpan = this.state.selectedCells[0].rowSpan ? this.state.selectedCells[0].rowSpan : 1;
        if (this.state.selectedCells[0].rowPosition !== this.state.structure.rowCount - 1) {
          this.state.structure.rows.forEach((row) => {
            row.forEach((cell) => {
              if (
                (cell.colPosition + (cell.colSpan ? cell.colSpan - 1 : 0) ===
                  this.state.selectedCells[0].colPosition ||
                  cell.colPosition === this.state.selectedCells[0].colPosition) &&
                cell.rowPosition === this.state.selectedCells[0].rowPosition + rowSpan
              ) {
                curCell = cell;
              }
            });
          });
          curCell.oldValue = curCell.value;
          this.state.selectedCells = [curCell];
          this.handleSetSelectionUpDown(false, curCell.key);
          this.forceUpdate();
        }
      }
    }
  };

  handleTdKeyDown = (e, cell) => {
    if (e.key === "Backspace") {
      e.stopPropagation();
    } else if (
      e.key === "ArrowRight" ||
      e.key === "ArrowLeft" ||
      e.key === "ArrowUp" ||
      e.key === "ArrowDown"
    ) {
      document.addEventListener("selectionchange", this.handleSelectCapture);
      e.stopPropagation();
      this.shouldChangeCell = true;
      setTimeout(() => this.changeCell(e.key, cell), 1);
    } else if (e.key === "Delete") {
      e.preventDefault();
      e.stopPropagation();

      let oldStruct = JSON.parse(JSON.stringify(this.state.structure));

      if (this.editingCell === null) {
        this.state.selectedCells.forEach((cell) => {
          // cell.key = new Date().getTime() + cell.colPosition + cell.rowPosition;
          cell.value = "";
        });
        // this.createRef();
        this.forceUpdate();

        this.pushPropsChanges(true, oldStruct);
      }
    } else if (this.key_ctrl && e.keyCode === 65) {
      let selectedCells = [];
      this.state.structure.rows.forEach((row) => {
        row.forEach((cell) => {
          selectedCells.push(cell);
        });
      });
      this.setState({ selectedCells });
    } else if (e.key === "Escape") {
      e.preventDefault();
      e.stopPropagation();
      if (this.editingCell !== null) {
        this.state.selectedCells = [this.editingCell];
        this.editingCell = null;
        this.forceUpdate();
      } else {
        this.state.selectedCells.forEach((cell, index) => {
          cell.key = new Date().getTime() + index + cell.colPosition + cell.rowPosition;
        });
        this.startShiftColIndex = -1;
        this.startShiftColIndex = -1;
        this.createRef();
        this.setState({ selectedCells: [] });
      }
    } else if (this.state.selectedCells.length === 1 && e.key === "F2") {
      e.preventDefault();
      e.stopPropagation();
      this.editingCell = this.state.selectedCells[0];
      this.state.selectedCells[0].key =
        new Date().getTime() +
        this.state.selectedCells[0].rowPosition +
        this.state.selectedCells[0].colPosition;
      this.createRef();
      setTimeout(
        () => this.handleSetSelectionLeftRight(false, this.state.selectedCells[0].key),
        100,
      );
      this.forceUpdate();
    } else if (e.key === "Tab") {
      e.preventDefault();
      let curCell = null;
      e.stopPropagation();
      let colSpan = this.state.selectedCells[0].colSpan ? this.state.selectedCells[0].colSpan : 1;
      let rowSpan = this.state.selectedCells[0].rowSpan ? this.state.selectedCells[0].rowSpan : 1;

      if (
        this.state.selectedCells[0].colPosition + colSpan - 1 ===
          this.state.structure.colCount - 1 &&
        this.state.selectedCells[0].rowPosition === this.state.structure.rowCount - 1
      ) {
        this.insertRowBelow();
        curCell = this.state.structure.rows[this.state.structure.rowCount - 1][0];
      } else if (
        this.state.selectedCells[0].colPosition + colSpan - 1 ===
        this.state.structure.colCount - 1
      ) {
        this.state.structure.rows.forEach((row) => {
          row.forEach((cell) => {
            if (
              cell.colPosition === 0 &&
              (cell.rowPosition === this.state.selectedCells[0].rowPosition + rowSpan ||
                cell.rowPosition + (cell.rowSpan ? cell.rowSpan - 1 : 0) ===
                  this.state.selectedCells[0].rowPosition + rowSpan)
            ) {
              curCell = cell;
            }
          });
        });
      } else {
        this.state.structure.rows.forEach((row) => {
          row.forEach((cell) => {
            if (
              cell.colPosition === this.state.selectedCells[0].colPosition + colSpan &&
              (cell.rowPosition + (cell.rowSpan ? cell.rowSpan - 1 : 0) ===
                this.state.selectedCells[0].rowPosition ||
                cell.rowPosition === this.state.selectedCells[0].rowPosition)
            ) {
              curCell = cell;
            }
          });
        });
      }

      curCell.oldValue = curCell.value;
      this.state.selectedCells = [curCell];
      this.createRef();
      this.forceUpdate(() => {
        this.handleSetSelectionLeftRight(false, curCell.key);
      });
      this.forceUpdate();
    } else if (e.ctrlKey && e.key === "z") {
      if (cell.value !== cell.oldValue) {
        e.stopPropagation();
      } else {
        e.preventDefault();
      }
    }
  };

  focusTd = (cell) => {
    if (cell.value === cell.oldValue) {
      this.oldStruct = JSON.parse(JSON.stringify({ ...this.state.structure }));
    }

    this.forceUpdate();
  };
  setTableRef = (ref) => {
    this.tableRef = ref;
  };

  createMarkup(cell) {
    let finalHtml = "";
    if (cell.value) {
      if (!cell.bullet && !cell.number) {
        finalHtml = cell.value;
      } else if (cell.bullet) {
        if (cell.value.indexOf("<ul ") === -1) {
          const values = cell.value.split("<div>");
          let finalValues = [];
          values.forEach((val) => {
            finalValues.push(val.replace("</div>", ""));
          });
          const html = this.renderBullet("bullet", finalValues, cell.curType);
          finalHtml = html;
        } else {
          finalHtml = cell.value;
        }
      } else if (cell.number) {
        if (cell.value.indexOf("<ol ") === -1) {
          const values = cell.value.split("<div>");
          let finalValues = [];
          values.forEach((val) => {
            finalValues.push(val.replace("</div>", ""));
          });
          const html = this.renderBullet("number", finalValues, cell.curType);
          finalHtml = html;
        } else {
          finalHtml = cell.value;
        }
      }
    } else {
      finalHtml = "";
    }

    if (cell.link) {
      finalHtml = `<a href=${cell.href}>${finalHtml}</a>`;
    }

    return { __html: finalHtml };
  }

  handleTDKeyUP = (e) => {
    // this.handleSelectCapture(e);
    // document.removeEventListener('keyup', this.handleTDKeyUP)
  };

  recalculateHeights = (currentHeight, oldHeight) => {
    let ratio = currentHeight / oldHeight;

    this.state.structure.rowHeights.forEach((height, index) => {
      this.state.structure.rowHeights[index] = Number(height.replace("px", "")) * ratio + "px";
    });
  };
  recalculateWidths = (currentWidth, oldWidth) => {
    let ratio = currentWidth / oldWidth;

    this.state.structure.colWidths.forEach((width, index) => {
      this.state.structure.colWidths[index] = Number(width.replace("px", "")) * ratio + "px";
    });
  };

  getRealRowHeights = () => {
    this.state.structure.rows.forEach((row, indexRow) => {
      row.forEach((cell, index) => {
        if (index === 0) {
          let td = this.td_elements[cell.key].current;
          if (td) {
            if (
              this.state.structure.rowHeights[indexRow] !==
              (td.getBoundingClientRect().height * 1) / window.panelScale + "px"
            ) {
              this.state.structure.rowHeights[indexRow] =
                (td.getBoundingClientRect().height * 1) / window.panelScale + "px";
              this.forceUpdate();
            }
          }
        }
      });
    });
  };

  render() {
    let className = "sc_to_table";

    if (this.props.selected) {
      className += " sc_to_table sc_to_table_selected";
    }

    if (!this.props.publishMode) {
      className += " hover";
    }

    if (this.props.height !== this.getSizesSum("rowHeights")) {
      if (this.props.resizing) {
        this.recalculateHeights(this.props.height, this.getSizesSum("rowHeights"));
      }
      // } else {
      //     this.props.onHeightChange(this.getSizesSum('rowHeights'), true, false)
      // }
    }

    if (this.props.width !== this.getSizesSum("colWidths")) {
      if (this.props.resizing) {
        this.recalculateWidths(this.props.width, this.getSizesSum("colWidths"));
      }
    }

    let counterRow = -1;
    let counterCol = -1;

    return (
      <>
        {this.state.menuProps && (
          <SCContextMenuPortal>
            <ContextMenu
              borderMenu={this.state.borderMenu}
              handleClose={() => this.handleContextMenuClose()}
              anchorPoint={this.state.anchorPoint}
              menuProps={this.state.menuProps}
            >
              {!this.state.borderMenu && (
                <>
                  <MenuItem onClick={this.props.cutObjects} className="cm_icon_helper">
                    <Cut className="cm_icon" />
                    <span className="cm_btn_name">Cut</span>
                    <span className="cm_helper_text">Ctrl+X</span>
                  </MenuItem>
                  <MenuItem onClick={this.props.copyObjects} className="cm_icon_helper">
                    <Copy className="cm_icon" />
                    <span className="cm_btn_name">Copy</span>
                    <span className="cm_helper_text">Ctrl+C</span>
                  </MenuItem>
                  <MenuItem className="cm_icon_helper" onClick={this.props.handleDuplicateObject}>
                    <Paste className="cm_icon" />
                    <span className="cm_btn_name">Duplicate</span>
                    <span className="cm_helper_text">Ctrl+D</span>
                  </MenuItem>
                  <MenuItem onClick={this.props.onDelete}>
                    <span className="cm_btn_name">Delete</span>
                  </MenuItem>
                  <div className="cm_divider" />
                  <OrderSubMenu
                    sentToFront={this.props.sentToFront}
                    sentToBack={this.props.sentToBack}
                    bringForword={this.props.bringForword}
                    bringBackward={this.props.bringBackward}
                  />
                  <CenterOnPageSubMenu
                    {...this.props}
                    horizontally={() =>
                      this.props.updatePosition(640 - this.props.width / 2, this.props.top)
                    }
                    vertically={() =>
                      this.props.updatePosition(this.props.left, 360 - this.props.height / 2)
                    }
                  />
                  <SubMenu label="Capitalization">
                    <MenuItem
                      className="cm_icon_helper"
                      onClick={() => this.changeStyles({ textTransform: "lowercase" })}
                    >
                      <LowerCase className="cm_icon" />
                      <span className="cm_btn_name">lowercase</span>
                    </MenuItem>
                    <MenuItem
                      className="cm_icon_helper"
                      onClick={() => this.changeStyles({ textTransform: "uppercase" })}
                    >
                      <UpperCase className="cm_icon" />
                      <span className="cm_btn_name">UPPERCASE</span>
                    </MenuItem>
                    <MenuItem
                      className="cm_icon_helper"
                      onClick={() => this.changeStyles({ textTransform: "capitalize" })}
                    >
                      <TitleCase className="cm_icon" />
                      <span className="cm_btn_name">Title Case</span>
                    </MenuItem>
                  </SubMenu>
                  <SubMenu label={"Link"}>
                    <TableLink setLink={this.setLink} />
                  </SubMenu>
                  <MenuItem onClick={this.distributeRows}>
                    <span className={"cm_btn_name"}>Distribute rows</span>
                  </MenuItem>
                  <MenuItem onClick={this.distributeColumns}>
                    <span className={"cm_btn_name"}>Distribute columns</span>
                  </MenuItem>
                  <MenuItem onClick={this.insertRowAbove}>
                    <span className={"cm_btn_name"}>Insert row above</span>
                  </MenuItem>
                  <MenuItem onClick={this.insertRowBelow}>
                    <span className={"cm_btn_name"}>Insert row below</span>
                  </MenuItem>
                  <MenuItem onClick={this.insertColumnLeft}>
                    <span className={"cm_btn_name"}>Insert column left</span>
                  </MenuItem>
                  <MenuItem onClick={this.insertColumnRight}>
                    <span className={"cm_btn_name"}>Insert column right</span>
                  </MenuItem>
                  <div className="cm_divider" />
                  <MenuItem onClick={this.deleteRow}>
                    <span className={"cm_btn_name"}>Delete row</span>
                  </MenuItem>
                  <MenuItem onClick={this.deleteColumn}>
                    <span className={"cm_btn_name"}>Delete column</span>
                  </MenuItem>
                  {this.state.selectedCells.length > 1 && (
                    <MenuItem onClick={this.mergeCells}>
                      <span className={"cm_btn_name"}>Merge cells</span>
                    </MenuItem>
                  )}
                  {((this.state.selectedCells.length === 1 &&
                    this.state.selectedCells[0].colSpan &&
                    this.state.selectedCells[0].colSpan > 1) ||
                    (this.state.selectedCells.length === 1 &&
                      this.state.selectedCells[0].rowSpan &&
                      this.state.selectedCells[0].rowSpan > 1)) && (
                    <MenuItem onClick={this.mergeCells}>
                      <span className={"cm_btn_name"}>Unmerge cells</span>
                    </MenuItem>
                  )}
                  <MenuItem onClick={this.props.toggleFormatOptions}>
                    <span className={"cm_btn_name"}>Format options</span>
                  </MenuItem>
                </>
              )}
              {/*{this.state.borderMenu && <>*/}
              {/*<MenuItem onClick={() => this.setCellsBorder('all')}><TableBorder1/></MenuItem>*/}
              {/*<MenuItem onClick={() => this.setCellsBorder('inside')}><TableBorder2/></MenuItem>*/}
              {/*<MenuItem onClick={() => this.setCellsBorder('outside')}><TableBorder3/></MenuItem>*/}
              {/*<MenuItem onClick={() => this.setCellsBorder('top')}><TableBorder4/></MenuItem>*/}
              {/*<MenuItem onClick={() => this.setCellsBorder('horizontal')}><TableBorder5/></MenuItem>*/}
              {/*<MenuItem onClick={() => this.setCellsBorder('bottom')}><TableBorder6/></MenuItem>*/}
              {/*<MenuItem onClick={() => this.setCellsBorder('left')}><TableBorder7/></MenuItem>*/}
              {/*<MenuItem onClick={() => this.setCellsBorder('vertical')}><TableBorder8/></MenuItem>*/}
              {/*<MenuItem onClick={() => this.setCellsBorder('right')}><TableBorder9/></MenuItem>*/}
              {/*</>}*/}
            </ContextMenu>
          </SCContextMenuPortal>
        )}
        {this.props.formatOptions && this.props.selected && (
          <RightMenuPortal>
            <div className={"right_menu_mask"}>
              <List
                style={{
                  paddingLeft: "0",
                  position: "absolute",
                  width: "100%",
                  height: "auto",
                  maxWidth: 250,
                  bgcolor: "background.paper",
                  paddingBottom: "0",
                  paddingTop: "0",
                  // overflow:'scroll',
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                  <ListSubheader component="div" id="nested-list-subheader">
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <span style={{ fontWeight: 700, fontSize: "16px", textAlign: "left" }}>
                        Format Options
                      </span>
                      <IconButton onClick={this.props.toggleFormatOptions}>
                        <Exit />
                      </IconButton>
                    </div>
                  </ListSubheader>
                }
              >
                <SizeAndRotation
                  changeLockedAspect={this.props.changeLockedAspect}
                  onChange={this.props.onUpdateSizeAndRotation}
                  style={this.props.style}
                  width={this.props.width}
                  lockedAspect={this.props.lockedAspect}
                  rightMenuFunc={this.rightMenuFucn}
                  height={this.props.height}
                  rotateAngle={this.props.rotateAngle}
                  scaleX={this.props.scaleX}
                  scaleY={this.props.scaleY}
                />
                <Position
                  onChange={this.props.onUpdatePosition}
                  rightMenuFunc={this.rightMenuFucn}
                  style={this.props.style}
                  top={this.props.top}
                  left={this.props.left}
                />
                <TextFitting
                  onChange={this.changeStyles}
                  rightMenuFunc={this.rightMenuFucn}
                  getRealRowHeights={this.getRealRowHeights}
                  objType={"table"}
                  textRotation={this.getSelectedRotation()}
                  changeRoatation={this.changeRotation}
                  {...TableObject.config.textFitting}
                  style={this.state.selectedCells.length > 0 ? this.getSelectedCellsStyle() : {}}
                  width={this.props.width}
                />
                <SpecialCharacters
                  onChange={this.props.onChange}
                  rightMenuFunc={this.rightMenuFucn}
                  style={this.props.style}
                  onValueChange={this.props.onValueChange}
                  value={this.props.value}
                  selection={this.state.selection}
                  addSymbol={this.addSymbol}
                />
              </List>
            </div>
          </RightMenuPortal>
        )}

        {this.props.selected && (
          <FormatPainterPortal>
            <ButtonMui
              tooltipText={"Format Painter"}
              onClick={this.handleActiveFormatPainter}
              variant={"text"}
              isIconButton
              roundnessType={"squared"}
              color={"black"}
              label={<FormatColor active={this.props.activeFormatPainter} />}
            />
          </FormatPainterPortal>
        )}

        {this.props.selected && (
          <div
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
          >
            <HeaderMidPortal>
              <HeaderTable
                visible={this.state.selectedCells.length > 0 && this.props.selected}
                style={this.state.selectedCells.length > 0 ? this.getSelectedCellsStyle() : {}}
                onChange={this.changeStyles}
                setCellsBorder={this.setCellsBorder}
                toggleFormatOptions={this.props.toggleFormatOptions}
                onStyleRemove={this.removeStyles}
                handleBullet={this.handleBullet}
                onPreview={this.props.onPreview}
              />

              <HeaderTableEditor
                visible={this.state.selectedCells.length > 0 && this.props.selected}
                onRowAdd={this.handleOnAddRow}
                onColumnAdd={this.handleOnAddColumn}
                mergeCells={this.mergeCells}
                activeFormatPainter={this.activeFormatPainter}
              />
            </HeaderMidPortal>
          </div>
        )}

        <table
          ref={this.setTableRef}
          onKeyDown={this.handleKeyDown}
          onKeyUp={this.handleKeyUp}
          // onPaste={this.handlePaste}
          onCopy={this.handleCopy}
          onCut={this.handleCut}
          onMouseDown={(e) => this.multipleSelect(e)}
          suppressContentEditableWarning={true}
          className={className}
          cellPadding={0}
          cellSpacing={0}
          width="100%"
          height="100%"
        >
          <tbody>
            {this.state.structure.rows.map((row, r) => {
              counterRow++;
              counterCol = -1;
              return (
                <tr
                  style={{
                    height: this.state.structure.rowHeights[r],
                  }}
                  key={`tr_${r}`}
                >
                  {row.map((cell, c) => {
                    ++counterCol;
                    if (!cell.hidden || cell.hidden === false) {
                      if (Object.keys(cell.style).length === 0) {
                        cell.style = this.default_CellStyle;
                      }

                      cell.colPosition = counterCol;
                      cell.rowPosition = counterRow;

                      return (
                        <td
                          onMouseDown={(event) => this.onCellClick(cell, event)}
                          onMouseMove={() => this.selectCell(cell)}
                          onContextMenu={(e) => this.handleContextMenu(e)}
                          onKeyDown={(e) => {
                            this.handleTdKeyDown(e, cell);
                          }}
                          // onSelectCapture={this.handleSelectCapture}
                          suppressContentEditableWarning={true}
                          ref={this.td_elements[cell.key]}
                          id={counterCol.toString() + counterRow.toString()}
                          style={{
                            borderTop: cell.isBorderTopBolded
                              ? `${parseInt(cell.style.borderWeight.replace("px", "")) + 2 + "px"} ${cell.style.borderStyle} ${cell.style.borderColor}`
                              : !cell.hideTopBorder
                                ? `${cell.style.borderWeight} ${cell.style.borderStyle} ${cell.style.borderColor}`
                                : "none",
                            borderRight: cell.isBorderRightBolded
                              ? `${parseInt(cell.style.borderWeight.replace("px", "")) + 2 + "px"} ${cell.style.borderStyle} ${cell.style.borderColor}`
                              : !cell.hideRightBorder
                                ? `${cell.style.borderWeight} ${cell.style.borderStyle} ${cell.style.borderColor}`
                                : "none",
                            borderBottom: cell.isBorderBottomBolded
                              ? `${parseInt(cell.style.borderWeight.replace("px", "")) + 2 + "px"} ${cell.style.borderStyle} ${cell.style.borderColor}`
                              : !cell.hideBottomBorder
                                ? `${cell.style.borderWeight} ${cell.style.borderStyle} ${cell.style.borderColor}`
                                : "none",
                            borderLeft: cell.isBorderLeftBolded
                              ? `${parseInt(cell.style.borderWeight.replace("px", "")) + 2 + "px"} ${cell.style.borderStyle} ${cell.style.borderColor}`
                              : !cell.hideLeftBorder
                                ? `${cell.style.borderWeight} ${cell.style.borderStyle} ${cell.style.borderColor}`
                                : "none",
                            writingMode: cell.style.writingMode ? cell.style.writingMode : "unset",
                            transform: cell.style.transform ? cell.style.transform : "unset",
                            letterSpacing: cell.style.letterSpacing
                              ? cell.style.letterSpacing
                              : "unset",
                            width: this.state.structure.colWidths[c],
                            height: "auto !important",
                            minHeight: "auto !important",
                          }}
                          // contentEditable={true}
                          key={cell.key}
                          spellCheck={true}
                          onBlur={() => this.changeKey(cell)}
                          onFocus={() => this.focusTd(cell)}
                          colSpan={cell.colSpan}
                          rowSpan={cell.rowSpan}
                          // dangerouslySetInnerHTML={{__html: this.createMarkup(cell.value)}}
                        >
                          <div
                            id={"div_" + counterCol.toString() + counterRow.toString()}
                            spellCheck={true}
                            contentEditable={!this.props.scale.enabled && !this.props.publishMode}
                            style={{
                              ...cell.style,
                              paddingLeft:
                                cell.number &&
                                cell.curType == "decimal-leading-zero" &&
                                cell.style.paddingLeft === "0px"
                                  ? "20px"
                                  : cell.style.paddingLeft,
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              height: "100%",
                              borderWeight: "none",
                              borderColor: "none",
                              borderStyle: "none",
                              border: "none",
                              borderTop: "none",
                              borderBottom: "none",
                              borderLeft: "none",
                              borderRight: "none",
                              writingMode: "unset",
                              transform: "unset",
                              letterSpacing: "unset",
                              boxSizing: "border-box",
                            }}
                            onInput={(event) => this.onValueChanged(event, cell)}
                            dangerouslySetInnerHTML={this.createMarkup(cell)}
                          />

                          <div
                            className="sc_cell_selected"
                            hidden={
                              this.state.selectedCells.indexOf(cell) == -1 ||
                              JSON.stringify(this.editingCell) === JSON.stringify(cell)
                            }
                            contentEditable={false}
                          />
                          {counterRow === 0 && (
                            <div
                              className="sc_cell_top"
                              contentEditable={false}
                              onMouseDown={(event) => this.onMouseDown("resizeRowTop", r, event)}
                              onContextMenu={(e) => this.handleBorderContextMenu(e)}
                            ></div>
                          )}
                          <div
                            className="sc_cell_right"
                            contentEditable={false}
                            onMouseDown={(event) => this.onMouseDown("resizeCol", c, event)}
                            onContextMenu={(e) => this.handleBorderContextMenu(e)}
                          ></div>
                          <div
                            className="sc_cell_bottom"
                            contentEditable={false}
                            onMouseDown={(event) => this.onMouseDown("resizeRow", r, event)}
                            onContextMenu={(e) => this.handleBorderContextMenu(e)}
                          ></div>
                        </td>
                      );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  }
}

TableObject.config = {
  textFitting: {
    showTextDirectionMenu: true,
    DontshowIndentationMenu: true,
  },
};

export default TableObject;
