import React from "react";
import { UnitTypes } from "../../../../../../data/Finance/CalculatedDriver/constants";
import { Vector } from "../../../../../../components/dividers/Vector";
import { Label } from "../../../../../../components/typography/label/Label";
import Mx_NumInput from "../../../../../../components/actions/Mx_NumInput";
import MaterialSelect from "../../../../../../components/actions/SelectM";
import Mx_Tooltip from "../../../../../../components/icons/Mx_Tooltip_Click";

class CalculatedDriverGrowthInputs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { dataGridRow, changeSingleValue, changeToDate, growthMonths } = this.props;

    return (
      <div className="psffcc_growth_child_container">
        <div className="psffcc_growth_label">
          <Label
            color={"black"}
            size="label3"
            label={dataGridRow && dataGridRow.Name ? dataGridRow.Name : "Growth"}
          />
          <Vector color={true} />
        </div>

        <div className="psffcc_growth_inputs_container">
          <div className="psffcc_input_date">
            <div className="psffcc_input_label_divider">
              <Label
                className="psffcc_title_input"
                color={"black"}
                size="label3"
                label={`Start period `}
              />
              <Mx_Tooltip
                description={
                  "Select the date when the forecast should begin. For example, if you are forecasting revenue that will only start in the third month of your forecast period, select the month when you will start earning this revenue."
                }
              />
            </div>
            <MaterialSelect
              valuePlaceholder="Header"
              minWidth="120px"
              valueDescription="Header"
              onChange={(e) => changeToDate(e, dataGridRow)}
              options={growthMonths}
              value={
                dataGridRow.GrowthDriver.Values.find((d) => d.Unit === UnitTypes.GrowthValue)
                  .DateHeader
              }
            />
          </div>
          <div className="psffcc_input_width">
            <div className="psffcc_input_label_divider">
              <Label
                className="psffcc_title_input"
                color={"black"}
                size="label3"
                label="Start value"
              />
              <Mx_Tooltip
                description={
                  "Input the value of the Start period. For example, if you are forecasting Unit Prices, input here the unit price that you expect to charge in the month that you already selected in Start Period. \n" +
                  "In all following fields in the table, input the percent growth you expect in comparison to the previous period."
                }
              />
            </div>
            <Mx_NumInput
              singleInput={true}
              isDialogInputSmall={true}
              selected={true}
              isMultipleSelected={false}
              isEditable={true}
              label={"label"}
              name={"label"}
              data={
                dataGridRow.GrowthDriver.Values.find((d) => d.Unit === UnitTypes.GrowthValue).Value
              }
              unit={
                dataGridRow.UnitType === UnitTypes.Percentage
                  ? "%"
                  : dataGridRow.GrowthDriver.Values.find((d) => d.Unit === UnitTypes.GrowthValue)
                      .UnitParsed
              }
              onChange={(e) =>
                changeSingleValue(
                  e,
                  dataGridRow.GrowthDriver.Values.find((d) => d.Unit === UnitTypes.GrowthValue),
                )
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

export default CalculatedDriverGrowthInputs;
