import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "./TabsMenu.scss";

export default function TabsMenu(props) {
  const [value, setValue] = React.useState(props.categories[0].CategoryName);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.onChangeTab(newValue);
  };

  return (
    <React.Fragment>
      <Box sx={{ width: "100%" }}>
        <Tabs
          variant={"scrollable"}
          value={value}
          sx={{
            ".MuiTabs-scrollButtons": {
              marginTop: "-15px",
            },
          }}
          onChange={handleChange}
          TabIndicatorProps={{
            children: (
              <div className="mctabs_indicator_container">
                <div className={"mctabs_indicator"} />
              </div>
            ),
          }}
        >
          {props.categories.map((category, index) => {
            if (
              !props.businessPlan ||
              (category.CategoryName !== "Charts" && category.CategoryName !== "Tables")
            ) {
              return (
                <Tab
                  key={category.CategoryName + "_" + index}
                  disableRipple
                  value={
                    category.CategoryName === "Custom" ? props.customTabName : category.CategoryName
                  }
                  label={
                    <div className={"tab_label"}>
                      {category.CategoryName === "Custom"
                        ? props.customTabName
                        : category.CategoryName}
                      <div className={"tab_label small"}>
                        {category.CategoryName === "Custom"
                          ? props.customTabName
                          : category.CategoryName}
                      </div>
                    </div>
                  }
                />
              );
            }
          })}
        </Tabs>
      </Box>
    </React.Fragment>
  );
}
