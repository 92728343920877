import React, { Fragment, useState } from "react";
import {
  Arc,
  BlockArc,
  Bolt,
  Can,
  Chord,
  Cloud,
  Cross,
  Cube,
  Decagon,
  DiagonalStripe,
  Diamond,
  Dodecagon,
  Donut,
  FlowchartAccessStorage,
  FlowchartCard,
  FlowchartCollate,
  FlowchartConnector,
  FlowchartDecision,
  FlowchartDelay,
  FlowchartDirectAccessStorage,
  FlowchartDisplay,
  FlowchartDocument,
  FlowchartMagneticDisc,
  FlowchartMerge,
  FlowchartOffPageConnector,
  FlowchartOr,
  FlowchartPredifinedProcess,
  FlowchartPreparation,
  FlowchartSort,
  FlowchartStoredData,
  FlowchartSummingJunction,
  FlowchartTape,
  FlowchartTerminator,
  FoldedCorner,
  Frame,
  HalfFrame,
  Heart,
  Heptagon,
  Hexagon,
  InternalStorage,
  LShape,
  ManualInput,
  ManualOperation,
  Moon,
  MultipleDocuments,
  NoSymbol,
  Octagon,
  Oval,
  Parallelogram,
  Pentagon,
  Pie,
  Plague,
  Rectangle,
  RightTriangle,
  RoundDiagonal,
  RoundedRectangle,
  RoundSameSide,
  RoundSingle,
  Smiley,
  SnipAndRoundSingle,
  SnipDiagonal,
  SnipSameSide,
  SnipSingleCorner,
  Sun,
  Teardrop,
  Trapezoid,
  Triangle,
} from "../../components/Shapes/ShapeIcons";
import { SlideObject, SlideTypes } from "../../SlideObjects/SlideStructures";
import { MenuItem, SubMenu } from "@szhsin/react-menu";

function ShapesSubMenu(props) {
  const handleNewShapeObj = (shapeType) => {
    const nObj = new SlideObject(SlideTypes.shapeObject, null, {
      shapeType,
      width: 300,
      height: 300,
      top: 300,
      left: 100,
      id: new Date().getTime(),
    });
    props.onNewObject(nObj);
  };

  const ShapesMenu = () => {
    return (
      <SubMenu
        label={
          <>
            <Rectangle />
            <span className="item_name">Shapes</span>
            <span className="helper_text">Shift+Alt</span>
          </>
        }
      >
        <MenuItem onClick={() => handleNewShapeObj(1)}>
          <Rectangle />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(2)}>
          <RoundedRectangle />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(3)}>
          <SnipSingleCorner />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(4)}>
          <SnipSameSide />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(5)}>
          <SnipDiagonal />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(6)}>
          <SnipAndRoundSingle />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(7)}>
          <RoundSingle />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(8)}>
          <RoundSameSide />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(9)}>
          <RoundDiagonal />
        </MenuItem>
        <div className="separator_container">
          <div className="horizontal_separator" />
        </div>
        <MenuItem onClick={() => handleNewShapeObj(10)}>
          <Oval />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(11)}>
          <Triangle />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(12)}>
          <RightTriangle />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(13)}>
          <Parallelogram />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(14)}>
          <Trapezoid />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(15)}>
          <Diamond />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(16)}>
          <Pentagon />
        </MenuItem>
        {/*<MenuItem onClick={()=>handleNewShapeObj(17)}><Hexagon /></MenuItem>*/}
        {/*<MenuItem onClick={()=>handleNewShapeObj(18)}><Heptagon /></MenuItem>*/}
        {/*<MenuItem onClick={()=>handleNewShapeObj(19)}><Octagon /></MenuItem>*/}
        {/*<MenuItem onClick={()=>handleNewShapeObj(20)}><Decagon /></MenuItem>*/}
        {/*<MenuItem onClick={()=>handleNewShapeObj(21)}><Dodecagon /></MenuItem>*/}
        <MenuItem onClick={() => handleNewShapeObj(22)}>
          <Pie />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(23)}>
          <Arc />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(24)}>
          <Chord />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(25)}>
          <Teardrop />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(26)}>
          <Frame />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(27)}>
          <HalfFrame />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(28)}>
          <LShape />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(29)}>
          <DiagonalStripe />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(30)}>
          <Cross />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(31)}>
          <Plague />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(32)}>
          <Can />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(33)}>
          <Cube />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(34)}>
          <Donut />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(35)}>
          <NoSymbol />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(36)}>
          <BlockArc />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(37)}>
          <FoldedCorner />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(38)}>
          <Smiley />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(39)}>
          <Heart />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(40)}>
          <Bolt />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(41)}>
          <Sun />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(42)}>
          <Moon />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(43)}>
          <Cloud />
        </MenuItem>
        <div className="separator_container">
          <div className="horizontal_separator" />
        </div>

        <MenuItem onClick={() => handleNewShapeObj(1)}>
          <Rectangle />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(2)}>
          <RoundedRectangle />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(44)}>
          <FlowchartDecision />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(45)}>
          <FlowchartPredifinedProcess />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(46)}>
          <InternalStorage />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(47)}>
          <FlowchartDocument />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(48)}>
          <MultipleDocuments />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(49)}>
          <FlowchartTerminator />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(50)}>
          <FlowchartPreparation />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(51)}>
          <ManualInput />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(52)}>
          <ManualOperation />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(53)}>
          <FlowchartConnector />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(54)}>
          <FlowchartOffPageConnector />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(55)}>
          <FlowchartCard />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(56)}>
          <FlowchartTape />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(57)}>
          <FlowchartSummingJunction />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(58)}>
          <FlowchartOr />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(59)}>
          <FlowchartCollate />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(60)}>
          <FlowchartSort />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(61)}>
          <FlowchartMerge />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(62)}>
          <FlowchartStoredData />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(63)}>
          <FlowchartDelay />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(64)}>
          <FlowchartAccessStorage />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(65)}>
          <FlowchartMagneticDisc />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(66)}>
          <FlowchartDirectAccessStorage />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(67)}>
          <FlowchartDisplay />
        </MenuItem>
      </SubMenu>
    );
  };
  return <>{ShapesMenu()}</>;
}
export default ShapesSubMenu;
