import React from "react";
import CircleLoader from "../../../../components/Loaders/CircleLoader/CircleLoader";
import {
  MainHeader,
  ValContainer,
  CardsContainer,
  Card,
  SubSubHeaderNormalText,
  CenterDiv,
} from "../components/ValuationContainer";
import FinanceTableMain from "../../../../components/tables/FinanceTable/FinanceTableMain";
import DiscountedCash from "../../../../data/Finance/Valuation/DiscountedCash";
import ExportPortal from "../../Dashboards/FinancialStatements/components/ExportPortal";
import ExportReport from "../../Financials/components/Export/ExportReport";
import { InputFormats } from "../../../../data/Finance/constants";
import "./discounted_cash_flow.scss";
const Loader = (
  <div className={"main_circle_loader_container"}>
    <CircleLoader />
  </div>
);

export default class DiscountedCashFlowMain extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      key: new Date().getTime(),
      loading: true,
    };

    this.gridRows = [];
    this.DiscountedCashFlowDriver = DiscountedCash.getDiscountedCashFlowDriver();
    this.headers = global.Modeliks.DateHelper.discount_cash_years;
    this.EnterPriceValue = this.DiscountedCashFlowDriver.EnterpriseValue;
    this.DebtValue = this.DiscountedCashFlowDriver.Debt;
    this.CashValue = this.DiscountedCashFlowDriver.Cash;
    this.EquityValue = this.DiscountedCashFlowDriver.EquityValue;
    this.TerminalValuePercentOfEnterpriseValue =
      this.DiscountedCashFlowDriver.TerminalValuePercentOfEnterpriseValue;

    this.Cards = [
      {
        title: "Enterprise Value",
        value: `$ ${InputFormats.formatNumber(this.EnterPriceValue.Value, 0)}`,
      },
      { title: "Debt", value: `$ ${InputFormats.formatNumber(this.DebtValue.Value, 0)}` },
      { title: "Cash", value: `$ ${InputFormats.formatNumber(this.CashValue.Value, 0)}` },
      { title: "Equity Value", value: `$ ${InputFormats.formatNumber(this.EquityValue.Value, 0)}` },
    ];
  }

  componentDidMount() {
    this.setData();
  }

  setData = () => {
    this.gridRows = this.createGridRows(this.DiscountedCashFlowDriver);
    this.setState({
      loading: false,
    });
  };
  createGridRows = (driver) => {
    if (driver && driver.hasOwnProperty("Totals")) {
      const childDrivers = driver.Totals.getChildDrivers();
      if (childDrivers) {
        return childDrivers.map((child, index) => {
          return {
            id: child.ID,
            header: child.DriverName,
            revenue: child,
            boldRowHeader:
              childDrivers.length - 1 === index || child.DriverName === "Free Cash Flow",
            hasEdit: false,
            data: child,
          };
        });
      }
    }
  };

  render() {
    if (this.state.loading) {
      return Loader;
    }

    return (
      <ValContainer key={this.state.key}>
        <MainHeader>Discounted Cash Flow Valuation Estimate</MainHeader>
        <CardsContainer>
          {this.Cards.map((card) => (
            <Card title={card.title} value={card.value} />
          ))}
        </CardsContainer>
        <ExportPortal>
          <ExportReport showExcelExport={false} />
        </ExportPortal>
        <div className="main_table_container spsvd_container_table">
          {this.gridRows && (
            <div className="psf_reports_export_pdf" id="report_table">
              <FinanceTableMain
                rows={this.gridRows}
                wrapperClassName={"max_height"}
                headers={this.headers}
                showRowHeaders={true}
                disabledEdit={false}
                disabledMove={true}
                useDashboards={true}
                useDateIndexes={true}
                editableColor={true}
                // onDataChanged={this.handleGridDataChanged}
              />
            </div>
          )}
        </div>
        <CenterDiv>
          <SubSubHeaderNormalText>
            Note:{" "}
            {InputFormats.formatNumber(100 - this.TerminalValuePercentOfEnterpriseValue.Value)}% of
            the Enterprise Value comes from the forecasting period cashflows and the remaining{" "}
            {InputFormats.formatNumber(this.TerminalValuePercentOfEnterpriseValue.Value)}% comes
            from the terminal value cashflows.
          </SubSubHeaderNormalText>
        </CenterDiv>

        {/*table here finan with months*/}
      </ValContainer>
    );
  }
}
