import React, { Fragment, useState } from "react";
import PreviousButton from "../IconButtons/PreviousButton";
import NextButton from "../IconButtons/NextButton";
import { Slider } from "@mui/material";
import "./ScalePanelFooter.scss";
import MaterialSelect from "../MaterialSelect/MaterialSelectComponent";
import ButtonJ from "../Buttons/Button";
import Button from "../../../../components/actions/Button";
import CircleLoader from "../../../../components/Loaders/CircleLoader/CircleLoader";
import ButtonMui from "../../../../components/buttons/buttonMui/buttonMui";
import { DownwardArrowBlue, UpwardArrowBlue } from "../IconButtons/SubMenuIcons";
import { IconMinus, IconPlus } from "../../../../components/icons/svgIcons";

class FooterComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectVal: "Fit to screen (117%)",
      showButton: false,
    };

    global.Modeliks.Subscribe("onPreviewMode", this.handleButton);
  }

  handleChange = (e) => {
    this.setState({ selectVal: e.target.value });
  };

  handleButton = (data) => {
    this.setState({ showButton: data });
  };

  componentWillUnmount() {
    global.Modeliks.Unsubscribe("onPreviewMode", this.handleButton);
  }

  render() {
    return (
      <>
        <div className={"se_panel_footer"}>
          <div
            style={{
              display: "flex",
              columnGap: 25,
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
            }}
          >
            {!this.props.disablePreviousButton && (
              <ButtonMui
                width={136}
                label={"Previous"}
                variant={"text"}
                startIcon={<UpwardArrowBlue />}
                onClick={() => this.props.changeSection(-1)}
                disabled={this.props.disablePreviousButton}
              />
            )}
            {!this.props.disableNextButton && (
              <ButtonMui
                width={136}
                label={"Next"}
                variant={"text"}
                startIcon={<DownwardArrowBlue />}
                onClick={() => this.props.changeSection(+1)}
                disabled={this.props.disableNextButton}
              />
            )}
            {this.state.showButton && (
              <ButtonMui
                label={"Edit Mode"}
                variant={"outlined"}
                onClick={this.props.onPreviewMode}
              />
            )}

            {this.props.objectLoad && (
              <div className={"loader_container"}>
                <div className={"loader_small"}>
                  <CircleLoader />
                </div>
                <div>saving...</div>
              </div>
            )}
          </div>

          {this.props.enableZoom && (
            <div
              style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "50%" }}
            >
              <ButtonMui
                disabled={
                  this.props.zoomValue < (this.props.zoomRange ? this.props.zoomRange[0] : 1)
                }
                label={<IconMinus />}
                width={36}
                color={"black"}
                variant={"text"}
                isIconButton
                onClick={() => this.props.onZoomChange(this.props.zoomValue - 1)}
              />
              <Slider
                sx={{
                  marginLeft: "3%",
                  // width: '23%',
                  marginRight: "3%",
                  color: " #E1F2F6",
                  flexGrow: "2",
                }}
                value={this.props.zoomValue}
                onChangeCommitted={() => this.props.handleZoomEnd()}
                step={1}
                size={"medium"}
                min={this.props.zoomRange ? this.props.zoomRange[0] : 0}
                max={this.props.zoomRange ? this.props.zoomRange[1] : 100}
                onChange={(e) => this.props.onZoomChange(e.target.value)}
                valueLabelDisplay="auto"
              />
              <ButtonMui
                label={<IconPlus />}
                width={36}
                color={"black"}
                variant={"text"}
                isIconButton
                onClick={() => this.props.onZoomChange(this.props.zoomValue + 1)}
              />
            </div>
          )}
        </div>
      </>
    );
  }
}

export default FooterComponent;
