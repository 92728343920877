import MxDataArray from "../../../MxDataArray";
import CustomKPI from "./index";
import ProfitLoss from "../../Reports/ProfitLoss";
import FinancialStatementsReport from "../FinancialStatements";

class CustomKPIStorage extends MxDataArray {
  dataManager = null;
  driverStorage = null;
  loaded = false;
  getAllDates = (dates = global.Modeliks.DashboardSettings.Value.financialDates) => {
    return {
      ...dates,
      allMonths: [
        ...global.Modeliks.DateHelper.months_before_actual,
        ...global.Modeliks.DateHelper.months,
      ],
      allYears: [
        ...global.Modeliks.DateHelper.years_before_actual,
        ...global.Modeliks.DateHelper.gridPeriodHeaders_Years(),
      ],
    };
  };

  setData = (callBack, data = this.data) => {
    this.pushArray(
      data.map((item) => {
        const newItem = new CustomKPI(item);
        if (newItem.Totals) {
          newItem.data = {
            dataGrid: [],
            changeDates: (dates = this.getAllDates()) => {
              return (newItem.data.dataGrid = FinancialStatementsReport.getDashboardDrivers(
                newItem.Totals.getChildDrivers(),
                dates,
                true,
              ));
            },
          };
          newItem.data.changeDates();

          return newItem;
        } else {
          // this.dataManager.del(this.dataManager.Tables.Dashboards_CustomKPI.TableName, {ID: newItem.ID}, () => {
          //     console.log('deleting',newItem);
          //
          // }, () => {
          // });
        }
      }),
    );
    this.loaded = true;

    callBack();
  };

  loadCustomKPI = (callBack, shouldFinish = true) => {
    this.clear();
    this.dataManager.get(
      this.dataManager.Tables.Dashboards_CustomKPI.TableName,
      { ID_CompanyScenario: this.dataManager.CompanyScenarioInfo.ID },
      (data) => {
        if (shouldFinish) {
          this.setData(callBack, data);
        } else {
          this.data = data;
          // ProfitLoss.getReport();
          callBack();
        }
      },
      null,
      false,
    );
  };

  static create = () => {
    const newArr = new CustomKPIStorage();
    newArr.pushOld = newArr.push;
    newArr.push = newArr.pushThis;

    return newArr;
  };
}

export default CustomKPIStorage;
