import React, { Component } from "react";
import { Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import ButtonMui from "../../../../../components/buttons/buttonMui/buttonMui";

import "./BillingHistory.scss";
import request from "superagent";
import { parseDate } from "../index";
import { Upgrade_Downgrade_types } from "../../../../../data/SubscriptionEnum";

class TransactionsHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      direction: "asc",
    };
  }

  componentDidMount() {
    const query = new URLSearchParams(window.location.search);

    const ptxn = query.get("_ptxn");
    const upgradeType = query.get("upgradeType");
    const quantity = query.get("quantity");
    const subscription_id = query.get("subscription_id");

    console.log("updateType", upgradeType);

    if (ptxn) {
      global.Modeliks.redirectUrl = "/profile/account_details/transactions";
    }

    this.sortByDate();
  }

  updatePaymentMethod = () => {
    request
      .get(
        `/api/getUpdatedPaymentMethodLink?subscription_id=${global.Modeliks.subscriptionInfo.PaddleSubscriptionID}`,
      )
      .then((res) => {
        if (res) {
          window.open(res.body, "_self");
        }
      });
  };

  getPdf = (transaction_id) => {
    request.get(`/api/getTransactionPdfLink?transaction_id=${transaction_id}`).then((res) => {
      if (res) {
        console.log("res", res.body);
        window.open(res.body, "_self");
      }
    });
  };

  getBillingCycle = (ID) => {
    const period = global.Modeliks.subscriptionPlans.find((c) => c.ID === ID).billing_type;

    if (period === "year") {
      return "annual";
    } else {
      return "month";
    }
  };

  sortByDate = () => {
    if (this.state.direction === "asc") {
      this.props.transactions.sort(function (a, b) {
        let keyA = new Date(a.created_at),
          keyB = new Date(b.created_at);
        // Compare the 2 dates
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });
      this.setState({ direction: "desc" });
    } else {
      this.props.transactions.sort(function (a, b) {
        let keyA = new Date(a.created_at),
          keyB = new Date(b.created_at);
        // Compare the 2 dates
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });
      this.setState({ direction: "asc" });
    }
  };

  render() {
    return (
      <div className={"transactions_container"}>
        <div className={"table_container"}>
          <Table stickyHeader={true}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel onClick={this.sortByDate} direction={this.state.direction}>
                    <span>Date</span>
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <span>Invoice Ref.</span>
                </TableCell>
                <TableCell>
                  <span>Billing Cycle</span>
                </TableCell>
                {/*<TableCell>*/}
                {/*    <span>Billing Period</span>*/}
                {/*</TableCell>*/}
                <TableCell>
                  <span>Billed Amount</span>
                </TableCell>
                <TableCell>
                  <wbr />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.props.transactions.map((c, i) => {
                {
                  if (Number(c.total) != 0) {
                    return (
                      <TableRow key={`tr_c_${i}`} className={""}>
                        <TableCell>
                          <span>{parseDate(c.created_at)}</span>
                        </TableCell>
                        <TableCell>
                          <span>{c.invoice_number}</span>
                        </TableCell>
                        <TableCell>
                          <span>{c.billing_interval === "month" ? "Monthly" : "Annually"}</span>
                        </TableCell>
                        {/*<TableCell><span>{`${parseDate(global.Modeliks.subscriptionInfo.DateStart)} - ${parseDate(global.Modeliks.subscriptionInfo.DateEnd)}`}</span></TableCell>*/}
                        <TableCell>
                          <span>{`$${c.total}/${c.billing_interval}`}</span>
                        </TableCell>
                        <TableCell>
                          <div className={"button_container"}>
                            {
                              <ButtonMui
                                label={"Get PDF"}
                                variant={"contained"}
                                onClick={() => this.getPdf(c.id)}
                              />
                            }
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  }
                }
              })}
            </TableBody>
          </Table>
        </div>

        <div className={"button_container margin"}>
          <ButtonMui
            variant={"contained"}
            disabled={global.Modeliks.subscriptionInfo.data_key === "Free Trial"}
            label={"Update Payment Method"}
            onClick={this.updatePaymentMethod}
          />
        </div>
      </div>
    );
  }
}

export default TransactionsHistory;
