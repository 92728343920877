import * as React from "react";
import Dialog from "@mui/material/Dialog";
import "./SectionDialog.scss";
import ButtonMui from "../../../../components/buttons/buttonMui/buttonMui";
import "./longIstructiondDialog.scss";

export default function LongInstructionsDialog(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    setTimeout(() => {
      let el = document.getElementById("instructions_container");
      if (el) {
        el.innerHTML = props.instructions;
      }
    }, 50);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonMui
        label={"Read more..."}
        style={{ marginTop: "10px", height: "24px" }}
        variant={"text"}
        onClick={handleClickOpen}
      />
      <Dialog fullWidth={true} maxWidth={"lg"} open={open} onClose={handleClose}>
        <div
          id={"instructions_container"}
          style={{
            width: "100%",
            boxSizing: "border-box",
            wordWrap: "break-word",
            textAlign: "center",
          }}
        ></div>
      </Dialog>
    </React.Fragment>
  );
}
