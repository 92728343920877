export const referrals = {
  imapct: "imacpt",
  modeliks: "modeliks",
};

export const modeliksLinks = {
  termsOfService: "https://www.modeliks.com/terms-of-service",
  privacyPolicy: "https://www.modeliks.com/privacy-policy",
  pricing: "https://www.modeliks.com/pricing",
  affiliatePartnerProgram: "https://www.modeliks.com/affiliate-partner-program",
}
