import { ShortMonths, PeriodTypes, DateHelper } from "../../data/dates";
import MxMath from "../../data/MxMath";
import LocalStorage from "../../helpers/LocalStorage";
import {
  tableConfig,
  tableConfigTypes,
  tableObject,
} from "../tables/FinanceTable/components/constants";

const RevenueTypes = {
  UnitSales: "unit-sales",
  BillableHours: "billable-hours",
  Subscriptions: "subscription",
  RevenueOnly: "revenue-only",
};

const PersonnelFunctionTypes = {
  DirectLabor: "direct-labor",
  SalesAndMarketing: "sales-and-marketing",
  ResearchAndDevelopment: "research-and-development",
  GeneralAndAdministrative: "general-and-administrative",
};
const PersonnelEmploymentStatus = {
  Employeed: "employeed-by-you",
  Contract: "contract-workers",
};

const PersonnelEmployeeSalaries = {
  Value: "per-employee",
  Totals: "totals-of-revenue",
  SpecificStream: "specific-revenue-stream",
};

const maxRows = 1000;

const InputFormats = {
  formatNumber: (number, propDecimal = null, IsReport = false) => {
    if (number != null) {
      const minDecimals =
        propDecimal || propDecimal === 0
          ? propDecimal
          : LocalStorage.get(
              tableObject,
              tableConfig,
              !IsReport
                ? tableConfigTypes.displayOfDecimals
                : tableConfigTypes.displayOfDecimalsReport,
            );
      return number.toLocaleString(undefined, {
        minimumFractionDigits: minDecimals,
        maximumFractionDigits: minDecimals,
      });
    } else {
      return "";
    }
  },
};

const RevenueTotals = {
  getMonthlyData: (data) => {
    return data.filter((c) => c.PeriodType == PeriodTypes.month);
  },
  getYearlyData: (data) => {
    return data.filter((c) => c.PeriodType == PeriodTypes.year);
  },
  splitIntoPeriodsData: (data) => {
    const months = [];
    const years = [];
    data.forEach((c) => (c.PeriodType == PeriodTypes.month ? months.push(c) : years.push(c)));
    return { months, years };
  },
  getPeriodHeaders: (data) => {
    const periods = RevenueTotals.splitIntoPeriodsData(data);
    const monthHeaders = periods.months.map(
      (i) => ShortMonths[i.Month] + " " + i.Year.toString().substring(2),
    );
    const yearHeaders = periods.years.map((i) => i.Year.toString());

    return { monthHeaders, yearHeaders };
  },
  calculateTotals: (revenue, data) => {
    switch (revenue.RevenueType) {
      case RevenueTypes.UnitSales:
      case RevenueTypes.BillableHours:
      case RevenueTypes.Subscriptions:
        return RevenueTotals.calculateQuantitySinglePrice(data);
      case RevenueTypes.RevenueOnly:
        return RevenueTotals.calculatePriceOnly(data);
    }
  },
  calculateQuantitySinglePrice: (data) => {
    const periods = RevenueTotals.splitIntoPeriodsData(data);
    const monthValues = periods.months.map(RevenueTotals.calculation_QuantitySinglePrice);
    const yearValues = periods.years.map(RevenueTotals.calculation_QuantitySinglePrice);

    return { monthValues, yearValues };
  },
  calculatePriceOnly: (data) => {
    const periods = RevenueTotals.splitIntoPeriodsData(data);
    const monthValues = periods.months.map((c) => c.SinglePrice);
    const yearValues = periods.years.map((c) => c.SinglePrice);
    return { monthValues, yearValues };
  },

  calculation_QuantitySinglePrice: (data) =>
    data.Quantity != null && data.SinglePrice != null ? data.Quantity * data.SinglePrice : null,
  calculation_SubscriptionPlan: (data) => {
    if (data.Quantity == null && data.SinglePrice == null) {
      return null;
    }

    const successSubscriptions = data.Refund != null ? 1 - data.Refund / 100 : 1;
    return data.Quantity * data.SinglePrice * successSubscriptions;
  },
  calculation_CalculateChange: (item, prevItem, calcField = "total") => {
    if (prevItem) {
      const val = MxMath.Round(
        ((item[calcField] - prevItem[calcField]) / prevItem[calcField]) * 100,
      );
      if (prevItem[calcField] < item[calcField]) {
        return Math.abs(val);
      }
      return val;
    }
    return null;
  },
  calculation_CalculateChange2: (item, prevItem, calcField = "total") => {
    if (prevItem) {
      if (prevItem[calcField] == 0) {
        return null;
      } else if (item[calcField] == 0) {
        return -100;
      } else {
        return RevenueTotals.calculation_CalculateChange(item, prevItem, calcField);
      }
    }
    return null;
  },
};

export { RevenueTypes, RevenueTotals, InputFormats, maxRows };
