import CalculatedDriver from "../../CalculatedDriver/index";
import { DriverCategories, UnitTypes } from "../../CalculatedDriver/constants";
import Reports from "../index";
import { ReportsTypes } from "../constants";
import Revenue from "../../Revenue";
import CostSales from "../../CostSales";
import Assets from "../../Assets";
import Financing from "../../Financing";
import Expense from "../../Expense";
import ProfitLoss from "../ProfitLoss";
import Subscription from "../../Revenue/Subscription";
import Personnel from "../../Personnel";
import { TaxesTypes } from "../../Taxes/constants";
import Taxes from "../../Taxes";

class ReportingDrivers extends Reports {
  Name = "";
  ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
  ReportType = ReportsTypes.ReportingDrivers;
  constructor(db_record) {
    super(db_record);
    if (this.db_record) {
      this.Name = this.db_record.Name;
      this.ReportType = this.db_record.ReportType;
      // this.setReport()
    }
  }

  static DriversDesc = Object.assign({
    MonthlyRecurringRevenue: {
      driverName: "Monthly Recurring Revenue (MRR)",
      fieldName: "MonthlyRecurringRevenue",
      driverID: "monthly_recurring_revenue",
      unit: UnitTypes.Price,
      category: DriverCategories.Average,
    },
    AnnualRecurringRevenue: {
      driverName: "Annual Recurring Revenue (ARR)",
      fieldName: "AnnualRecurringRevenue",
      driverID: "annual_recurring_revenue",
      unit: UnitTypes.Price,
      category: DriverCategories.Average,
    },
    AnnualRevenuePerUser: {
      driverName: "Annual Revenue Per User (ARPU)",
      fieldName: "AnnualRevenuePerUser",
      driverID: "annual_revenue_per_user",
      unit: UnitTypes.Price,
      category: DriverCategories.Average,
    },
    CustomerAcquisitionCostPaid: {
      driverName: "Customer Acquisition Cost (CAC Paid)",
      fieldName: "CustomerAcquisitionCostPaid",
      driverID: "customer_acquisition_cost_paid",
      unit: UnitTypes.Price,
      category: DriverCategories.Average,
    },
    CustomerAcquisitionCostFullyLoaded: {
      driverName: "Customer Acquisition Cost (CAC Fully Loaded)",
      fieldName: "CustomerAcquisitionCostFullyLoaded",
      driverID: "customer_acquisition_cost_fully_loaded",
      unit: UnitTypes.Price,
      category: DriverCategories.Average,
    },
    LifetimeValueOfCustomer: {
      driverName: "Lifetime Value of Customer (LTV)",
      fieldName: "LifetimeValueOfCustomer",
      driverID: "lifetime_value_of_customer",
      unit: UnitTypes.Price,
      category: DriverCategories.Average,
    },
    CACPaidPayback: {
      driverName: "CAC (Paid) Payback",
      fieldName: "CACPaidPayback",
      driverID: "cac_paid_payback",
      unit: UnitTypes.Units,
      category: DriverCategories.Average,
    },
    CACFullyLoadedPayback: {
      driverName: "CAC (Fully Loaded) Payback",
      fieldName: "CACFullyLoadedPayback",
      driverID: "cac_fully_loaded_payback",
      unit: UnitTypes.Units,
      category: DriverCategories.Average,
    },
    DebtService: {
      driverName: "Debt Service",
      fieldName: "DebtService",
      driverID: "debt_service",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
      isExpense: true,
      IsExisting: true,
    },
    ROIC: {
      driverName: "ROIC",
      fieldName: "ROIC",
      driverID: "roic",
      unit: UnitTypes.Percentage,
      category: DriverCategories.Sum,
      IsExisting: true,
    },
    NOPLAT: {
      driverName: "NOPLAT",
      fieldName: "NOPLAT",
      driverID: "noplat",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
      IsExisting: true,
    },
    InvestedCapital: {
      driverName: "Invested Capital",
      fieldName: "InvestedCapital",
      driverID: "invested_capital",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
      IsExisting: true,
    },
    ROCE: {
      driverName: "ROCE",
      fieldName: "ROCE",
      driverID: "roce",
      unit: UnitTypes.Percentage,
      category: DriverCategories.Sum,
      IsExisting: true,
    },
    NetDebt: {
      driverName: "NetDebt",
      fieldName: "NetDebt",
      driverID: "net_debt_r",
      unit: UnitTypes.Price,
      category: DriverCategories.LastPeriod,
      IsExisting: true,
    },
    CapitalEmployed: {
      driverName: "Capital Employed",
      fieldName: "CapitalEmployed",
      driverID: "capital_employed",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
      IsExisting: true,
    },
  });
  getMonthDatesAll = () => {
    return [
      ...global.Modeliks.DateHelper.months,
      ...global.Modeliks.DateHelper.months_before_actual,
    ];
  };

  getYearDatesAll = () => {
    return [
      ...global.Modeliks.DateHelper.years_all,
      ...global.Modeliks.DateHelper.years_before_actual,
    ];
  };
  periodsData = {};

  buildPeriodsData = () => {
    const allPeriods = this.MonthlyRecurringRevenue.Values.map((c) => c.Date);
    allPeriods.forEach((period) => {
      this.periodsData[period.dateID] = {};
      Object.values(this.constructor.DriversDesc).forEach(
        (driver) =>
          (this.periodsData[period.dateID][driver.fieldName] = this[
            driver.fieldName
          ].getItemByDateSufix(period.sufix)),
      );
      this.periodsData[period.dateID].Date = period;
    });
  };

  setReport = () => {
    this.buildPeriodsData();
    this.setFormulas();
  };

  setFormulas = () => {
    this.createMonthsFormulas();
    this.createYearsFormulas();
  };

  createMonthsFormulas = () => {
    const months = this.getMonthDatesAll();
    const SubscriptionTotals = Subscription.getSubscriptionsTotalsReport();
    const CustomersAtEndOfPeriodTotals = Subscription.getCustomersAtEndOfPeriodTotals();
    const SignupsTotals = Subscription.getSingupsTotals();
    const SalesAndMarketingTotals = Expense.getSalesAndMarketingTotals();
    const SalesAndMarketingEmployeesTotals = Personnel.getSalesAndMarketingEmployeesTotals();
    const SinglePriceTotals = Subscription.getSinglePriceTotals();
    const ChurnRateTotals = Subscription.getChurnRateTotals();

    const TotalPrincipalRepayments = Financing.getTotalPrincipalRepayment();
    const TotalInterestExpense = Financing.getInterestExpense();
    const OperatingProfit = global.Modeliks.DriversStore.find(
      (d) => d.Ref_Field === ProfitLoss.DriversDesc.OperatingProfit.driverID,
    );
    const IncomeTaxRateDriver = Taxes.getIncomeTaxRateDriver();
    const TotalAssets = Assets.getAssetsTotals();
    const TotalIntangiblesDrivers = Assets.getTotalIntangiblesDrivers();
    const TotalInvestmentsDrivers = Assets.getTotalInvestmentsDrivers();
    const CashBalance = global.Modeliks.ReportsStore.find(
      (d) => d.ReportType === ReportsTypes.CashFlow,
    ).CashAtEndOfThePeriod;
    const CurrentLiabilities = Financing.getCurrentLiabilities();
    const LongTermLiabilities = Financing.getLiabilities();
    const OtherCurrentLiabilities = Financing.getCurrentOtherLiabilities();
    const TotalEquity = Financing.getEquity();

    months.forEach((month) => {
      const curMonth = this.periodsData[month.dateID];
      curMonth.MonthlyRecurringRevenue.Formula = `${SubscriptionTotals.getItemByDateSufix(month.sufix)}`;
      curMonth.AnnualRecurringRevenue.Formula = `${SubscriptionTotals.getItemByDateSufix(month.sufix)} * 12`;
      curMonth.AnnualRevenuePerUser.Formula = `(${SubscriptionTotals.getItemByDateSufix(month.sufix)} / ${CustomersAtEndOfPeriodTotals.getItemByDateSufix(month.sufix)}) * 12`;
      curMonth.CustomerAcquisitionCostPaid.Formula = `${SalesAndMarketingTotals.getItemByDateSufix(month.sufix)} / ${SignupsTotals.getItemByDateSufix(month.sufix)}`;
      curMonth.CustomerAcquisitionCostFullyLoaded.Formula = `(${SalesAndMarketingTotals.getItemByDateSufix(month.sufix)} + ${SalesAndMarketingEmployeesTotals.getItemByDateSufix(month.sufix)}) / ${SignupsTotals.getItemByDateSufix(month.sufix)}`;
      curMonth.LifetimeValueOfCustomer.Formula = `${SinglePriceTotals.getItemByDateSufix(month.sufix)} * (1 / ${ChurnRateTotals.getItemByDateSufix(month.sufix)})`;
      curMonth.CACPaidPayback.Formula = `${curMonth.LifetimeValueOfCustomer} / ${curMonth.CustomerAcquisitionCostPaid}`;
      curMonth.CACFullyLoadedPayback.Formula = `${curMonth.LifetimeValueOfCustomer} / ${curMonth.CustomerAcquisitionCostFullyLoaded}`;

      curMonth.DebtService.Formula = `${TotalPrincipalRepayments.getItemByDateSufix(month.sufix)} + ${TotalInterestExpense.getItemByDateSufix(month.sufix)}`;
      curMonth.ROIC.Formula = `${curMonth.NOPLAT} / ${curMonth.InvestedCapital}`;
      curMonth.NOPLAT.Formula = `${OperatingProfit.getItemByDateSufix(month.sufix)} * (1 - ${IncomeTaxRateDriver.getItemByDateSufix(month.sufix)}) `;
      curMonth.ROCE.Formula = `${OperatingProfit.getItemByDateSufix(month.sufix)} / ${curMonth.CapitalEmployed}`;
      curMonth.CapitalEmployed.Formula = `${TotalAssets.getItemByDateSufix(month.sufix)} - ${CurrentLiabilities.getItemByDateSufix(month.sufix)}`;
      curMonth.NetDebt.Formula = `${LongTermLiabilities.getItemByDateSufix(month.sufix)} + ${OtherCurrentLiabilities.getItemByDateSufix(month.sufix)} - ${CashBalance.getItemByDateSufix(month.sufix)}`;
      curMonth.InvestedCapital.Formula = `${curMonth.NetDebt} + ${TotalEquity.getItemByDateSufix(month.sufix)}`;
    });
  };

  createYearsFormulas = () => {
    const years = this.getYearDatesAll();
    const SubscriptionTotals = Subscription.getSubscriptionsTotalsReport();
    const CustomersAtEndOfPeriodTotals = Subscription.getCustomersAtEndOfPeriodTotals();
    const SignupsTotals = Subscription.getSingupsTotals();
    const SalesAndMarketingTotals = Expense.getSalesAndMarketingTotals();
    const SalesAndMarketingEmployeesTotals = Personnel.getSalesAndMarketingEmployeesTotals();
    const SinglePriceTotals = Subscription.getSinglePriceTotals();
    const ChurnRateTotals = Subscription.getChurnRateTotals();
    const TotalPrincipalRepayments = Financing.getTotalPrincipalRepayment();
    const TotalInterestExpense = Financing.getInterestExpense();
    const OperatingProfit = global.Modeliks.DriversStore.find(
      (d) => d.Ref_Field === ProfitLoss.DriversDesc.OperatingProfit.driverID,
    );
    const IncomeTaxRateDriver = Taxes.getIncomeTaxRateDriver();
    const TotalAssets = Assets.getAssetsTotals();
    const TotalIntangiblesDrivers = Assets.getTotalIntangiblesDrivers();
    const TotalInvestmentsDrivers = Assets.getTotalInvestmentsDrivers();
    const CashBalance = global.Modeliks.ReportsStore.find(
      (d) => d.ReportType === ReportsTypes.CashFlow,
    ).CashAtEndOfThePeriod;
    const CurrentLiabilities = Financing.getCurrentLiabilities();
    const LongTermLiabilities = Financing.getLiabilities();
    const OtherCurrentLiabilities = Financing.getCurrentOtherLiabilities();
    const TotalEquity = Financing.getEquity();

    years.forEach((year) => {
      const curYear = this.periodsData[year.dateID];
      const yearMonths = year.months;
      const lastMonth = yearMonths[yearMonths.length - 1];

      curYear.MonthlyRecurringRevenue.Formula = `${SubscriptionTotals.getItemByDateSufix(lastMonth.sufix)}`;
      curYear.AnnualRecurringRevenue.Formula = `${SubscriptionTotals.getItemByDateSufix(lastMonth.sufix)} * 12`;
      curYear.AnnualRevenuePerUser.Formula = `${SubscriptionTotals.getItemByDateSufix(year.sufix)} / MxMath.Average([${yearMonths.map((m) => CustomersAtEndOfPeriodTotals.getItemByDateSufix(m.sufix))}])`;
      curYear.CustomerAcquisitionCostPaid.Formula = `${SalesAndMarketingTotals.getItemByDateSufix(year.sufix)} / ${SignupsTotals.getItemByDateSufix(year.sufix)}`;
      curYear.CustomerAcquisitionCostFullyLoaded.Formula = `(${SalesAndMarketingTotals.getItemByDateSufix(year.sufix)} + ${SalesAndMarketingEmployeesTotals.getItemByDateSufix(year.sufix)}) / ${SignupsTotals.getItemByDateSufix(year.sufix)}`;
      curYear.LifetimeValueOfCustomer.Formula = `${SinglePriceTotals.getItemByDateSufix(year.sufix)} * (1 / (${ChurnRateTotals.getItemByDateSufix(year.sufix)}))`;
      curYear.CACPaidPayback.Formula = `${curYear.LifetimeValueOfCustomer} / ${curYear.CustomerAcquisitionCostPaid}`;
      curYear.CACFullyLoadedPayback.Formula = `${curYear.LifetimeValueOfCustomer} / ${curYear.CustomerAcquisitionCostFullyLoaded}`;

      curYear.DebtService.Formula = `${TotalPrincipalRepayments.getItemByDateSufix(year.sufix)} + ${TotalInterestExpense.getItemByDateSufix(year.sufix)}`;
      curYear.ROIC.Formula = `${curYear.NOPLAT} / ${curYear.InvestedCapital}`;
      curYear.NOPLAT.Formula = `${OperatingProfit.getItemByDateSufix(year.sufix)} * (1 - ${IncomeTaxRateDriver.getItemByDateSufix(year.sufix)}) `;
      curYear.ROCE.Formula = `${OperatingProfit.getItemByDateSufix(year.sufix)} / ${curYear.CapitalEmployed}`;
      curYear.CapitalEmployed.Formula = `${TotalAssets.getItemByDateSufix(year.sufix)} - ${CurrentLiabilities.getItemByDateSufix(year.sufix)}`;
      curYear.NetDebt.Formula = `${LongTermLiabilities.getItemByDateSufix(year.sufix)} + ${OtherCurrentLiabilities.getItemByDateSufix(year.sufix)} - ${CashBalance.getItemByDateSufix(year.sufix)}`;
      curYear.InvestedCapital.Formula = `${curYear.NetDebt} + ${TotalEquity.getItemByDateSufix(year.sufix)}`;
    });
  };

  static getReportingDrivers = () => {
    const driver = global.Modeliks.ReportsStore.find(
      (d) => d.ReportType === ReportsTypes.ReportingDrivers,
    );
    if (driver) {
      driver.setFormulas();
      return driver;
    } else {
      const newReport = new ReportingDrivers();
      newReport.ID = "reporting_drivers";
      newReport.Name = "Reporting Drivers";
      newReport.ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
      newReport.ReportType = ReportsTypes.ReportingDrivers;
      newReport.Totals = CalculatedDriver.createDriverFromTable(
        newReport,
        ProfitLoss.DriversDesc.Total.driverID,
        UnitTypes.Price,
        DriverCategories.Sum,
        "Reporting Drivers",
        true,
      );
      newReport.createDrivers();
      newReport.setReport();
      newReport.changeDriversName();
      global.Modeliks.ReportsStore.push(newReport);
      return newReport;
    }
  };
}

export default ReportingDrivers;
