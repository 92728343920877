import React from "react";
import Dialog from "@mui/material/Dialog";
import "./CompanyLimit.scss";
import { Exit } from "../../../SlideEditor/components/components/IconButtons/SubMenuIcons";
import IconButton from "@mui/material/IconButton";
import Button from "../../actions/Button";
import { Navigate } from "react-router-dom";
import ButtonMui from "../../buttons/buttonMui/buttonMui";
import { RoutesPerPermission } from "../../constants/permissions";
import {modeliksLinks} from "../../../Enums/ModeliksEnum";

class UpdatePlan extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: true,
    };
  }

  handleClose = () => {
    this.props.handleClose();
  };

  getCancelButtonProps = () => {
    if (this.props.onCancel) {
      return {
        onClick: this.props.onCancel,
      };
    }
    return {
      linkTo: RoutesPerPermission[global.Modeliks.allowedRouteKeys[0]],
    };
  };

  render() {
    if (this.state.link) {
      const link = this.state.link;
      this.state.link = null;
      return <Navigate to={link} />;
    }
    return (
      <React.Fragment>
        <Dialog
          fullWidth={true}
          maxWidth={"lg"}
          open={this.props.open}
          className={"company_limit_dialog"}
          onClose={this.handleClose}
        >
          <IconButton onClick={this.handleClose} className={"cld_close_button"}>
            <Exit />
          </IconButton>

          <div className={"p1_strong cld_heading"}>
            Upgrade Required: Access the Power of Modeliks' Advanced Features!
          </div>

          <div className={"cld_typography"}>
            {this.props.dashboards ? (
              <p>
                The <b>Dashboard Module</b> is not available in your current subscription plan.{" "}
                <b>
                  Unlock the full potential of Modeliks by upgrading to a more advanced plan today.
                </b>
              </p>
            ) : (
              <p>
                This feature is not available in your current subscription plan. Unlock the full
                potential of Modeliks by upgrading to a more advanced plan today.
              </p>
            )}
            <p>
              Gain access to a wide range of features for business planning, financial forecasting
              and analysis, empowering you to make smarter decisions and achieve greater success.
              Check out a detailed overview of the features here{" "}
              <a href={modeliksLinks.pricing} target="_blank" rel="noreferrer">
                Compare Plans
              </a>
              .
            </p>
          </div>
          <div className={"cld_typography2_buttons"}>
            <ButtonMui
              linkTo={"/profile/account_details/manage_subscription"}
              label={"Upgrade Now"}
              width={130}
              variant={"contained"}
              onClick={this.handleClose}
            />
            <ButtonMui
              {...this.getCancelButtonProps()}
              width={130}
              label={"Cancel"}
              color={"black"}
              onClick={this.handleClose}
            />
          </div>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default UpdatePlan;
