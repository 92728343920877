const CostOfCapitalTypes = {
  DiscountRate: "discount-rate",
  IndustryBenchmark: "industry-benchmark",
  AdvancedWACC: "advanced-wacc",
};

const StateOfDevelopment = {
  PreSeed: { value: "pre-seed", description: "Pre-seed" },
  Maturity: { value: "maturity", description: "Maturity" },
  Growth: { value: "growth", description: "Growth" },
  Seed: { value: "seed", description: "Seed" },
};

export { CostOfCapitalTypes, StateOfDevelopment };
