import React, { Component } from "react";
import PropTypes from "prop-types";
import "./portfolio_management.scss";
import ButtonMui from "../../../components/buttons/buttonMui/buttonMui";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import MaterialSelect from "../../../components/actions/SelectM";
import { IconPlus } from "../../../components/icons/svgIcons";
import {
  DeleteIcon,
  EditIcon,
  MoveIcon,
} from "../../../SlideEditor/components/components/menus/LeftMenu/components/Icons/LeftMenuIcons";
import EditStagesDialog from "./components/EditStagesDialog";
import CircleLoader from "../../../components/Loaders/CircleLoader/CircleLoader";
import FilterComponent from "./components/FilterComponent";
import request from "superagent";
import NotesEditor from "./components/NotesEditor";
import AutocompleteInput from "../../../components/actions/inputs/AutocompleteInput";
import MxDataArray from "../../../data/MxDataArray";
import { parseDate } from "../ProfileSettings/AccountDetails";

export class Stage {
  constructor(Name, Order, Color, Key, Deletable = true, Hidden = false) {
    this.Name = Name;
    this.Order = Order;
    this.Color = Color;
    this.Key = Key;
    this.Deletable = Deletable;
    this.Hidden = Hidden;
  }
}

const stageNames = {
  New: "New",
  Advancing: "Advancing",
  Committed: "Committed",
  DueDiligence: "Due Diligence",
  Closed: "Closed",
  Passed: "Passed",
  Unassigned: "Unassigned",
  Inactive: "Inactive",
};

const otherStages = {
  [stageNames.Unassigned]: new Stage(
    stageNames.Unassigned,
    6,
    "#f5f5f5",
    stageNames.Unassigned,
    false,
  ),
  [stageNames.Inactive]: new Stage(stageNames.Inactive, 7, "#f5f5f5", stageNames.Inactive, false),
};
export const defaultStages = [
  new Stage(stageNames.New, 0, "#DAE3F3", stageNames.New),
  new Stage(stageNames.Advancing, 1, "#9DC3E6", stageNames.Advancing),
  new Stage(stageNames.Committed, 2, "#01AFFD", stageNames.Committed),
  new Stage(stageNames.DueDiligence, 3, "#FFC000", stageNames.DueDiligence),
  new Stage(stageNames.Closed, 4, "#92D050", stageNames.Closed),
  new Stage(stageNames.Passed, 5, "#00B050", stageNames.Passed),
  otherStages[stageNames.Unassigned],
  otherStages[stageNames.Inactive],
];

const saveJsonValueObject = (object, newValue = object.Value) => {
  object.Value = JSON.stringify(newValue);
  object.Save && object.Save();
  object.Value = JSON.parse(object.Value);
};

const getContrastYIQ = (hexcolor) => {
  let r = parseInt(hexcolor.substring(1, 3), 16);
  let g = parseInt(hexcolor.substring(3, 5), 16);
  let b = parseInt(hexcolor.substring(5, 7), 16);
  let yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "black" : "white";
};

const TableCells = (items, color, onClick) => (
  <TableRow onClick={onClick} style={color ? { backgroundColor: color } : {}}>
    {items.map((c, i) => (
      <TableCell
        key={`table_cells_${i}`}
        style={color ? { color: getContrastYIQ(color) } : {}}
        className={color ? "bold" : ""}
      >
        {c}
      </TableCell>
    ))}
  </TableRow>
);

const filterParams = ["StageName", "CompanyName", "Status"];

class PortfolioManagement extends Component {
  Stages = null;

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };

    this.ExpandedObject = {};
    this.counter = 0;

    global.Modeliks.GetAllUserCompanies(this.finish);
    global.Modeliks.GetPortfolio(() => global.Modeliks.GetCompanyUsersStages(this.finish));
  }

  finish = () => {
    if (++this.counter == 2) {
      this.init();
      this.autoCompleteOptions = this.getAutocompleteOptions();

      this.setState({ loading: false });
    }
  };

  init = () => {
    if (global.Modeliks.PortfolioStages) {
      this.Stages = global.Modeliks.PortfolioStages.Value.sort(this.sortByOrder);
    }
    if (this.state.loading) {
      this.TableHeaderItems = [
        "Company Name",
        "",
        "Pipeline Stage",
        "Status",
        "Active From",
        "Notes",
      ];
      this.TableHeaderCells = TableCells(this.TableHeaderItems);
    }
    this.Companies = global.Modeliks.AllUserCompanies;
    if (global.Modeliks.CompanyUserStages) {
      this.CompanyUserStages = global.Modeliks.CompanyUserStages.Value;
    }
    this.setCompanies();
  };

  setCompanies = () => {
    this.Companies.forEach((company) => {
      if (company.Status) {
        let currentStage = Object.keys(this.CompanyUserStages)
          .map((c) => {
            if (this.CompanyUserStages[c].hasOwnProperty(company.ID)) {
              return c;
            }
            return null;
          })
          .filter((c) => !!c);
        if (currentStage.length && currentStage[0]) {
          Object.assign(company, this.CompanyUserStages[currentStage[0]][company.ID]);
          company.Stage = this.Stages.getItem(currentStage[0]);
        }
        if (!company.Stage) {
          Object.assign(company, this.CompanyUserStages[stageNames.Unassigned][company.ID]);
          company.Stage = otherStages[stageNames.Unassigned];
        }
      } else {
        Object.assign(company, this.CompanyUserStages[stageNames.Inactive][company.ID]);
        company.Stage = otherStages[stageNames.Inactive];
      }
      company.StageName = company.Stage.Name;
      company.Status ? (company.Status = "Active") : (company.Status = "Inactive");
    });
  };

  sortByOrder = (a, b) => {
    return a.Order - b.Order;
  };

  getCompaniesByStageKey = (key, companies = this.Companies) => {
    if (this.CompanyUserStages[key] && Object.keys(this.CompanyUserStages[key]).length) {
      return Object.keys(this.CompanyUserStages[key])
        .map((ID) => companies.getItem(ID))
        .filter((c) => !!c && c.Status);
    }
    return [];
  };
  getCompanyRow = (company, key) =>
    TableCells([
      company.CompanyName,
      <ButtonMui
        label={"Go to Company"}
        disabled={company.ID == global.Modeliks.CompanyInfo.ID || company.Status == "Inactive"}
        onClick={() => this.getAccount(company)}
        variant={"text"}
      />,
      this.getPipelineStage(company, key),
      company.Status,
      parseDate(company.CreatedAt),
      <NotesEditor handleSave={(c) => this.handleCompanyNotesChange(c, key)} company={company} />,
    ]);

  toggleRow = (key) => {
    this.ExpandedObject[key] = !this.ExpandedObject[key];
    this.forceUpdate();
  };

  getAccount = (company, callBack) => {
    this.setState({ loading: true });
    changeCompany(company);
  };

  getCompanyRows = (stage) => {
    let companyRows = [];
    let companies = this.Companies;
    const filterParameter = this.state.filterParameter;
    if (filterParameter) {
      companies = companies.filter((c) => c[filterParameter.key] == filterParameter.value);
    }

    if (!stage.Deletable) {
      companyRows = companies
        .filter((c) => {
          return c.Stage && c.Stage.Key == stage.Key;
        })
        .map((company) => this.getCompanyRow(company, stage.Key));
    } else {
      companies = this.getCompaniesByStageKey(stage.Key);
      if (filterParameter) {
        companies = companies.filter((c) => c[filterParameter.key] == filterParameter.value);
      }

      companyRows = companies.map((company) => this.getCompanyRow(company, stage.Key));
    }
    return companyRows;
  };

  getStageRows = (stage) => {
    let companyRows = this.getCompanyRows(stage);

    if (!companyRows.length) {
      return [];
    }
    const isExpanded = !!this.ExpandedObject[stage.Key];
    const Row = TableCells(
      [
        `${isExpanded ? "▼" : "▶"} ${stage.Name} (${companyRows.length})`,
        <ButtonMui label={"Go to Company"} style={{ visibility: "hidden" }} />,
        <div className={"empty_div"} />,
        "",
        "",
        "",
      ],
      stage.Color,
      () => this.toggleRow(stage.Key),
    );
    if (this.ExpandedObject[stage.Key]) {
      return [Row, ...companyRows];
    }
    return [Row];
  };

  getStagesWithItems = (Stages = this.Stages) => {
    return Stages.sort(this.sortByOrder).filter((c) => !!c);
  };

  getRows = () => {
    return this.Stages.filter((c) => !c.Hidden)
      .sort(this.sortByOrder)
      .flatMap(this.getStageRows);
  };

  handleCompanyNotesChange = (newCompany, stageKey) => {
    if (newCompany.Notes) {
      if (!this.CompanyUserStages[stageKey][newCompany.ID]) {
        this.CompanyUserStages[stageKey][newCompany.ID] = {};
      }
      this.CompanyUserStages[stageKey][newCompany.ID].Notes = newCompany.Notes;
      saveJsonValueObject(global.Modeliks.CompanyUserStages, this.CompanyUserStages);
      this.setCompanies();
      this.forceUpdate();
    }
  };

  handleCompanyStageChange = (company, stageKey) => {
    let compObj = {};
    if (stageKey == stageNames.Unassigned) {
      Object.values(this.CompanyUserStages).forEach((c) => {
        if (c[company.ID]) {
          compObj = c[company.ID];
          delete c[company.ID];
        }
      });
      this.CompanyUserStages[stageKey][company.ID] = compObj;
      saveJsonValueObject(global.Modeliks.CompanyUserStages, this.CompanyUserStages);
      this.setCompanies();
      this.forceUpdate();
    } else {
      if (!this.CompanyUserStages.hasOwnProperty(stageKey)) {
        this.CompanyUserStages[stageKey] = {};
      }
      if (this.CompanyUserStages[stageKey][company.ID]) {
        return;
      }
      Object.values(this.CompanyUserStages).forEach((c) => {
        if (c[company.ID]) {
          compObj = c[company.ID];
          delete c[company.ID];
        }
      });
      this.CompanyUserStages[stageKey][company.ID] = compObj;
      saveJsonValueObject(global.Modeliks.CompanyUserStages, this.CompanyUserStages);
      this.setCompanies();
      this.forceUpdate();
    }
  };

  onChangeAutoComplete = (e, value) => {
    if (value) {
      this.setState({
        filterParameter: {
          key: value.value.split("_")[1],
          value: value.DriverName,
        },
      });
    } else {
      this.setState({ filterParameter: null });
    }
  };

  getPipelineStage = (company, key) => {
    if (key == stageNames.Inactive) {
      return null;
    }
    return (
      <MaterialSelect
        onChange={(e) => this.handleCompanyStageChange(company, e.target.value)}
        value={key}
        options={this.Stages.sort(this.sortByOrder)
          .filter((c) => c.Key != stageNames.Inactive)
          .map((c) => {
            return { value: c.Key, description: c.Name };
          })}
      />
    );
  };

  saveStages = (Stages) => {
    this.Stages = MxDataArray.create(Stages, "Key");
    let curStages = JSON.parse(JSON.stringify(Stages));
    curStages.forEach((c) => (c.Hidden = false));
    saveJsonValueObject(global.Modeliks.PortfolioStages, curStages);
    this.setState({ editStages: false });
  };

  getAutocompleteOptions = () => {
    let obj = {};
    this.Companies.forEach((company) => {
      filterParams.forEach((filterParam) =>
        Object.assign(obj, { [company[filterParam]]: filterParam }),
      );
    });

    return Object.keys(obj).map((key) => {
      return {
        DriverName: key,
        value: key + "_" + obj[key],
      };
    });
  };

  render() {
    if (this.state.loading) {
      return (
        <div className={"main_circle_loader_container"}>
          <CircleLoader />
        </div>
      );
    }
    return (
      <div className={"portfolio_management"}>
        {this.state.editStages && (
          <EditStagesDialog
            saveStages={this.saveStages}
            Stages={this.Stages}
            onClose={() => this.setState({ editStages: false })}
          />
        )}
        <div className={"p_m_main_container"}>
          <div className={"white_card"}>
            <div className={"title_container"}>
              <div className={"card_title"}>Portfolio Management</div>
              <ButtonMui
                label={"Edit Stages"}
                variant={"contained"}
                onClick={() => this.setState({ editStages: true })}
              />
            </div>

            <div className={"p_stages"}>
              {this.Stages &&
                this.Stages.filter((c) => c.Deletable)
                  .sort(this.sortByOrder)
                  .map((stage, i) => (
                    <div
                      key={`stage_${i}`}
                      style={{ backgroundColor: stage.Color }}
                      className={"p_stage"}
                    >
                      <div
                        className={"p_stage_text"}
                        style={{ color: getContrastYIQ(stage.Color) }}
                      >
                        {stage.Name}
                        <br />
                        {this.getCompaniesByStageKey(stage.Key).length}
                      </div>
                    </div>
                  ))}
            </div>
            <div className={"main_table_container"}>
              <div className={"search_box"}>
                <AutocompleteInput
                  height={30}
                  className="fdd_cd_driver_autocomplete_input_style"
                  placeholder={"Search by keyword..."}
                  options={this.autoCompleteOptions}
                  value={
                    this.state.filterParameter
                      ? this.autoCompleteOptions.find(
                          (c) =>
                            c.value ==
                            this.state.filterParameter.value + "_" + this.state.filterParameter.key,
                        )
                      : null
                  }
                  onChange={this.onChangeAutoComplete}
                />
                <div>
                  <FilterComponent
                    Stages={this.Stages}
                    saveStages={this.saveStages}
                    getFilteredStages={this.getStagesWithItems}
                  />
                </div>
              </div>
              <div className={"table_container"}>
                <Table stickyHeader>
                  <TableHead>{this.TableHeaderCells}</TableHead>
                  <TableBody>{this.getRows()}</TableBody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PortfolioManagement.propTypes = {};

export const changeCompany = (company) => {
  let currentCompanyIndex = global.Modeliks.Companies.findIndex((c) => c.ID == company.ID);
  if (currentCompanyIndex > -1) {
    global.Modeliks.ChangeCompany(global.Modeliks.Companies[currentCompanyIndex], () => {
      window.location.reload();
    });
  } else {
    request
      .post("/api/changeAccount")
      .query({ AccountID: company.AccID ? company.AccID : company.AccountID })
      .set("authorization", "Bearer " + window.localStorage.getItem("token"))
      .send({ TimeStamp: new Date().getTime() })
      .then((data) => {
        const Account = data.body.Accounts[0];
        window.localStorage.setItem("Company_ID", company.ID);
        window.localStorage.setItem("token", Account.token);
        window.localStorage.setItem("Account_ID", Account.ID);
        window.location.reload();
      })
      .catch((err) => {});
  }
};

export default PortfolioManagement;
