import React from "react";
import Button from "@mui/material/Button";
import { Line } from "react-chartjs-2";
import Chart from "./Chart";
import ChartActual from "./ChartActual";
import { ChartDataTypes, ChartTypes, NumberOfMonths } from "../../../components/constants/charts";
import { Theme } from "../../../theme/styles/theme";
import {
  ReusableMenu,
  ReusableMenuItem,
} from "../../../components/menus/ReusableMenu/ReusableMenu";

const ColorSets = {
  red: {
    key: "red",
    gradientOne: "rgba(249,180,180, 0.3)",
    gradientTwo: "rgba(254,237,237, 0.3)",
    borderColor: "#EA0000",
  },
  green: {
    key: "green",
    gradientOne: "rgba(210,241,204, 0.3)",
    gradientTwo: "rgba(244,252,243, 0.3)",
    borderColor: "#66D151",
  },
};

const ColorKeys = {
  red: "Alizarin",
  green: "Emerald",
};

export default class AreaChart extends Chart {
  width;
  height;
  gradient;
  ColorSet = ColorSets.green.key;
  colorIndexOffset = 2;
  datasetColors = [];

  constructor(props) {
    super(props);
    this.chartData.categories = ChartDataTypes.reportFrequency;
    this.chartData.series = ChartDataTypes.comparisonPeriod;
    this.chartClassName = "AreaChart";

    this.state = {
      isMounted: false,
    };

    if (props.chartFunctionsObj) {
      props.chartFunctionsObj.changeNumberOfMonths = this.changeNumberOfMonths;
    }
    this.setClassName();
    this.buildData();
  }

  componentDidMount() {
    this.state.isMounted = true;
  }

  componentWillUnmount() {
    this.state.isMounted = false;
  }

  changeNumberOfMonths = (numberOfMonths) => {
    this.chartOptions.numberOfMonths = numberOfMonths;
    this.buildData();
    this.chartRef.data.datasets = this.getData();
    this.chartRef.data.labels = this.getChartLabels();
    this.chartRef.options = this.getChartOptions();
    this.chartRef.update();
    this.saveCurrentSettings();
    // this.forceUpdate();
  };

  getCurrentIndex = () => {
    let colorKey = ColorKeys[this.ColorSet];
    return (
      (this.currentColorIndex + this.colorIndexOffset) %
      Object.values(Theme.colors.chart[colorKey]).length
    );
  };

  getAdditionalDataSettings() {
    let currentIndex = this.getCurrentIndex();
    this.datasetColors[this.currentColorIndex] = this.getBorderColor(currentIndex);
    this.currentColorIndex += 2;
    return {
      tension: 0.4,
      fill: true,
      backgroundColor: (context) => {
        const chart = context.chart;
        const { ctx, chartArea } = chart;

        if (!chartArea) {
          return;
        }
        return this.getGradient(ctx, chartArea);
      },
      borderColor: (context) => {
        const chart = context.chart;
        const { ctx, chartArea } = chart;

        if (!chartArea) {
          return;
        }
        return this.getBorderColor(currentIndex);
      },
      pointRadius: 0,
    };
  }

  getBorderColor(currentIndex) {
    let colorKey = ColorKeys[this.ColorSet];
    return Object.values(Theme.colors.chart[colorKey]).reverse()[currentIndex];
  }

  getGradient(ctx, chartArea) {
    this.handleColorSet(false);
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (!this.gradient || this.width !== chartWidth || this.height !== chartHeight) {
      this.width = chartWidth;
      this.height = chartHeight;
      this.gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
      this.gradient.addColorStop(1, ColorSets[this.ColorSet].gradientOne);
      this.gradient.addColorStop(0, ColorSets[this.ColorSet].gradientTwo);
    }
    return this.gradient;
  }

  handleColorSet(update = true) {
    let previous = this.ColorSet;
    if (this.getDifferenceCalculation() < 0) {
      this.ColorSet = ColorSets.red.key;
    } else {
      this.ColorSet = ColorSets.green.key;
    }

    if (previous !== this.ColorSet) {
      if (this.chartRef) {
        this.chartRef.data.datasets = this.getData();
        this.chartRef.update();
      }
    }
    if (update && this.state.isMounted) {
      this.forceUpdate();
    }
  }

  getDifferenceCalculation = () => {
    let array = Object.values(this.seriesTotals);
    // Object.values(this.seriesTotals).forEach(value => {
    //
    // })
    if (array[1] === 0) {
      return 0;
    }
    return (array[0] / array[1] - 1) * 100;
  };

  getBottomItemsContainer() {
    return (
      <div className={"bottom_item_container block"}>
        {Object.entries(this.seriesTotals).map((entry, index) => {
          return (
            <div className={"row"} key={"bottom_item_" + index}>
              <div className={"key flex"}>
                <div
                  style={{ background: this.getBorderColor(index * 2 + this.colorIndexOffset) }}
                  className={"circle"}
                />
                {entry[0]}
              </div>
              <div className={"value"}>{this.getCurrency(this.DataLabelFormat(entry[1]))}</div>
            </div>
          );
        })}
        <div className={"splitting_line"} />
        {this.chartData.comparisonPeriod.length > 1 &&
          this.chartData.comparisonPeriod[0] != this.chartData.comparisonPeriod[0] && (
            <div className={"row"} style={{ color: ColorSets[this.ColorSet].borderColor }}>
              <div className={"key"}>Difference</div>
              <div className={"value"}>
                {this.getPercentageFormat(this.getDifferenceCalculation())}%
              </div>
            </div>
          )}
        {this.getMonthsTrendButton()}
      </div>
    );
  }

  getCurrentDateRange() {
    if (this.props.dateRange) {
      let oldDateMonth = this.props.dateRange.dateFrom.month;
      let newDataRange = {};
      Object.assign(newDataRange, { ...this.props.dateRange });
      if (
        Math.abs(
          this.props.dateRange.dateFrom.month.Order - this.props.dateRange.dateTo.month.Order,
        ) +
          1 <
        this.chartOptions.numberOfMonths
      ) {
        let newMonth = this.getMonthDatesAll().find(
          (date) =>
            date.Order ===
            this.props.dateRange.dateTo.month.Order - this.chartOptions.numberOfMonths + 1,
        );
        if (!newMonth) {
          newMonth = this.getMonthDatesAll()[0];
        }
        newDataRange.dateFrom.month = newMonth;
      }
      return { ...newDataRange, validDateFrom: { month: oldDateMonth } };
    }
    if (this.chartData) {
      let date = {
        validDateFrom: this.getDateFrom(),
        dateFrom: this.getDateFrom(),
        dateTo: {
          month: this.getMonthDatesAll().find(
            (c) => c.Month == this.chartData.dateTo.month && c.Year == this.chartData.dateTo.year,
          )
            ? this.getMonthDatesAll().find(
                (c) =>
                  c.Month == this.chartData.dateTo.month && c.Year == this.chartData.dateTo.year,
              )
            : this.currentDateRange.dateTo.month,
          year: this.getYearDatesAll().find((c) => c.Year == this.chartData.dateTo.year)
            ? this.getYearDatesAll().find((c) => c.Year == this.chartData.dateTo.year)
            : this.currentDateRange.dateTo.year,
        },
      };
      return date;
    }
    return null;
  }

  getTopPadding() {
    return 300;
  }

  getChartLabels() {
    let chartLabels = super.getChartLabels();
    let splitPoint = Math.abs(chartLabels.length - this.chartOptions.numberOfMonths);
    if (chartLabels.length > this.chartOptions.numberOfMonths) {
      return chartLabels.filter((c, index) => index >= splitPoint);
    }
    return chartLabels;
  }

  getData() {
    if (!global.Modeliks.CompanyInfo && this.props.snapShotData) {
      return this.props.snapShotData.datasets;
    }
    let splitPoint = Math.abs(this.chartLabels.length - this.chartOptions.numberOfMonths);
    let datasets = this.getArrayDatasets();
    if (this.chartLabels.length > this.chartOptions.numberOfMonths) {
      datasets.forEach((dataset, index) => {
        dataset.data = dataset.data.filter((c, index) => index >= splitPoint);
      });
    }
    return datasets;
  }

  getMonthsTrendButton = () => {
    return (
      <ReusableMenu
        menuButton={<Button>{this.chartOptions.numberOfMonths} months trend</Button>}
        menuItems={NumberOfMonths.map(
          (c) =>
            new ReusableMenuItem(
              c,
              () => {
                this.changeNumberOfMonths(c);
                this.props.handleChartMount();
              },
              this.chartOptions.numberOfMonths === c,
            ),
        )}
      />
    );
  };

  render() {
    if (this.getImageSrc()) {
      return this.getChartElementAsImage();
    }
    return (
      <div
        className={"c_c_chart_container"}
        style={{ width: `${this.props.grid ? 100 : this.chartConfig.width}%` }}
        ref={this.setChartContainerRef}
      >
        {this.getTitleElement()}
        <Line
          ref={this.setChartRef}
          data={{
            labels: this.getChartLabels(),
            datasets: this.getData(),
          }}
          options={this.getChartOptions()}
          type={"line"}
        />
      </div>
    );
  }
}
