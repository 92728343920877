import React from "react";
import DialogContent from "@mui/material/DialogContent";

export const WarningSimpleText = ({ ...props }) => {
  return (
    <>
      <DialogContent sx={{ textAlign: "center", whiteSpace: "normal", margin: 2 }}>
        {props.message}
      </DialogContent>
    </>
  );
};
