import React from "react";
import Dialog from "@mui/material/Dialog";
import "./FreeTrialDialog.scss";
import { Exit } from "../../../SlideEditor/components/components/IconButtons/SubMenuIcons";
import IconButton from "@mui/material/IconButton";
import Button from "../../actions/Button";
import { Navigate } from "react-router-dom";

class FreeTrialDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: true,
      link: null,
    };
  }

  handleClose = () => {
    this.props.handleClose();
  };

  render() {
    if (this.state.link) {
      return <Navigate to={this.state.link} />;
    }
    return (
      <React.Fragment>
        <Dialog
          fullWidth={true}
          maxWidth={"lg"}
          open={this.props.open ? this.props.open : false}
          className={"free_trial_dialog"}
          onClose={this.handleClose}
        >
          <IconButton onClick={this.handleClose} className={"cld_close_button"}>
            <Exit />
          </IconButton>

          <div className={"p1_strong cld_heading"}>Free trial</div>

          <div className={"cld_typography"}>
            <p>
              You are using free trial in order to use this functionality you need to upgrade your
              subscription
            </p>
          </div>
          <div className={"cld_typography2_buttons"}>
            <Button
              label={"Upgrade Now"}
              padding={"0 0 0 0"}
              backgroundColor={"primary"}
              onClick={() =>
                this.setState({ link: "/profile/account_details/manage_subscription" })
              }
            />
            <Button
              label={"Cancel"}
              padding={"0 0 0 0"}
              noElevation={true}
              color={"black"}
              onClick={this.handleClose}
            />
          </div>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default FreeTrialDialog;
