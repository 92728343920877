import React, { Fragment, useState } from "react";
// import '../HeaderTextEditor.scss'
// import '../HeaderDefault.scss'
import Menu from "@mui/material/Menu";
import { Cloud, Oval, Rectangular, Rounded } from "../../components/Shapes/CalloutIcons";
import { RightArrow } from "../../components/IconButtons/SubMenuIcons";
import { SlideObject, SlideTypes } from "../../SlideObjects/SlideStructures";
import { MenuItem, SubMenu } from "@szhsin/react-menu";

function CalloutsSubMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openCallouts = Boolean(anchorEl);
  const handleClick = (event) => {
    if (!anchorEl) setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleNewShapeObj = (shapeType) => {
    const nObj = new SlideObject(SlideTypes.shapeObject, null, {
      shapeType,
      width: 300,
      height: 300,
      top: 100,
      left: 100,
    });
    props.onNewObject(nObj);
  };

  const CalloutsMenu = () => {
    return (
      <SubMenu
        className="callouts"
        label={
          <>
            <Rectangular />
            <span className={"item_name"}>Callouts</span>
            <span className={"helper_text"}>Shift+Alt</span>
          </>
        }
      >
        <MenuItem
          onClick={() =>
            props.onNewObject(
              new SlideObject(SlideTypes.calloutObject, null, {
                width: 300,
                height: 200,
                top: 390,
                left: 100,
                calloutHeight: 200,
                calloutAngle: 330,
                calloutType: 1,
                backgroundColor: "#585858",
              }),
            )
          }
        >
          <Rectangular />
        </MenuItem>
        <MenuItem
          onClick={() =>
            props.onNewObject(
              new SlideObject(SlideTypes.calloutObject, null, {
                width: 300,
                height: 200,
                top: 390,
                left: 100,
                calloutHeight: 200,
                calloutAngle: 330,
                calloutType: 2,
                backgroundColor: "#585858",
              }),
            )
          }
        >
          <Rounded />
        </MenuItem>
        <MenuItem
          onClick={() =>
            props.onNewObject(
              new SlideObject(SlideTypes.calloutObject, null, {
                width: 300,
                height: 200,
                top: 390,
                left: 100,
                calloutHeight: 200,
                calloutAngle: 330,
                calloutType: 3,
                backgroundColor: "#585858",
              }),
            )
          }
        >
          <Oval />
        </MenuItem>
        {/*<MenuItem onClick={()=>handleNewShapeObj(92)}><Cloud/></MenuItem>*/}
      </SubMenu>
    );
  };
  return <>{CalloutsMenu()}</>;
}
export default CalloutsSubMenu;
