import React from "react";
import Mx_NumInput from "../../../../../../../components/actions/Mx_NumInput";
import { Paragraph } from "../../../../../../../components/typography/paragraph/Paragraph";
import { Label } from "../../../../../../../components/typography/label/Label";

class SP_Driver_Values_Control extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { label, rows, field, singleValues, name, min, max, unit, onChange } = this.props;
    let totalValue = 0;
    if (field === "Quantity_Split") {
      totalValue = rows.reduce(
        (accumulator, currentValue) =>
          accumulator +
          (singleValues
            ? currentValue[field].getSingleDriverValue().Value
            : currentValue[field].Value),
        0,
      );
    }

    return (
      <div className="psfrcsc_multiple_customers_at_start_div">
        {label && (
          <div className="psfrcsc_multiple_customers_at_start_paragraph">
            <Paragraph size="p2_strong" label={label} />
          </div>
        )}

        <div className="psfrcsc_multiple_customers_at_start_childrens">
          {field === "Quantity_Split" && (
            <span className="psfrcssc_total_percentage">
              <Paragraph
                color={totalValue === 100 ? "#01AFFD" : "red"}
                size="p2_strong"
                label={`${totalValue}% =`}
              />
            </span>
          )}

          {rows.map((child, index) => {
            let value = child[field];
            if (singleValues) {
              value = value.getSingleDriverValue();
            }

            return (
              <div
                key={"sp_child" + child.ID + index}
                className="psfrcsc_multiple_customers_at_start_group"
              >
                <div className="psfrcsc_multiple_customers_at_start_childrens_p">
                  <Label size="label3" label={child.RevenueName} />
                </div>
                <div className="psfrcsc_multiple_customers_at_start_childrens_input">
                  <Mx_NumInput
                    singleInput={true}
                    selected={true}
                    isMultipleSelected={false}
                    isEditable={true}
                    label={label}
                    name={name}
                    data={value && value.Value ? value.Value : 0}
                    max={max}
                    min={min}
                    unit={unit}
                    onChange={(e) => onChange(value, e)}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default SP_Driver_Values_Control;
