import React, { Component } from "react";
import "./ManageSubscriptionLifeTime.scss";
import { Input2 } from "../../../../../components/actions/Input";
import ButtonMui from "../../../../../components/buttons/buttonMui/buttonMui";
import request from "superagent";
import UpgradeLifeTimeDialog from "./UpgradeLifeTimeDialog";

class ManageSubscriptionLifeTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coupon: "",
      openUpdareDialog: false,
    };
  }

  validate = () => {
    if (this.state.coupon.length) {
      return true;
    }
    return false;
  };

  handleSubmit = () => {
    if (this.validate()) {
      request
        .put("/api/UpgradeLifeTimeSubscription")
        .set("Content-Type", "application/x-www-form-urlencoded")
        .set("authorization", "Bearer " + window.localStorage.getItem("token"))
        .send({ coupon: this.state.coupon, subscriptionID: this.nextPlan.ID })
        .then((res) => {
          if (res) {
            console.log("res", res);
            if (res.body === "Invalid coupon") {
              this.setState({
                couponError:
                  "Invalid code. Please enter a valid code. You can find the correct codes in your AppSumo account. ",
              });
            } else if (res.body === "Used coupon") {
              this.setState({
                couponError:
                  "This code has already been used. Please enter a valid code. You can find the correct codes in your AppSumo account.",
              });
            } else {
              this.setState({ openUpdareDialog: true });
            }
          }
        });
    } else {
      this.setState({ couponError: "Please enter AppSumo code" });
    }
  };

  handleCloseUpdateDialog = () => {
    window.location.href = "/profile/account_details/general_info";
  };

  render() {
    this.subscriptions = global.Modeliks.subscriptionPlans.filter((c) => c.Type === "AppSumo");
    this.strength = global.Modeliks.subscriptionPlans.find(
      (c) => c.ID === global.Modeliks.subscriptionInfo.SubscriptionID,
    ).strength;
    this.currentPlan = global.Modeliks.subscriptionPlans.find(
      (c) => c.ID === global.Modeliks.subscriptionInfo.SubscriptionID,
    );
    this.nextPlan = this.subscriptions.find((c) => c.strength === this.strength + 1);

    return (
      <div className={"mslt_container"}>
        {this.nextPlan ? (
          <>
            <p className={"p1Strong"}>Upgrade to {this.nextPlan && this.nextPlan.name} Plan</p>
            <p>Enter AppSumo Code</p>
            <div style={{ width: "100%" }}>
              <Input2
                value={this.state.coupon}
                onChange={(e) => {
                  this.setState({ coupon: e.target.value, couponError: "" });
                }}
                errorMessage={this.state.couponError}
              />
            </div>
            <ButtonMui label={"SUBMIT"} variant={"contained"} onClick={this.handleSubmit} />

            <UpgradeLifeTimeDialog
              open={this.state.openUpdareDialog}
              planName={this.nextPlan ? this.nextPlan.name : ""}
              handleClose={this.handleCloseUpdateDialog}
            />
          </>
        ) : (
          <p
            className={"p1Strong"}
          >{`You cannot upgrade because you are already subscribed to ${this.currentPlan.name} Plan`}</p>
        )}
      </div>
    );
  }
}

export default ManageSubscriptionLifeTime;
