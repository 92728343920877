import React from "react";
import Step1 from "./components/WizardSteps/Step1";
import Step2 from "./components/WizardSteps/Step2";
import Step3 from "./components/WizardSteps/Step3";
import Step4 from "./components/WizardSteps/Step4";
import Step5 from "./components/WizardSteps/Step5";
import Step6 from "./components/WizardSteps/Step6";
import { BodyWrapper } from "../../Public/Auth/components/BodyWrapper";
import { LogoWrapper } from "../../Public/Auth/components/LogoWrapper";
import { ArrowLeft, ArrowRight, Logo, ModeliksTextIcon } from "../../../components/icons/svgIcons";
import { OnBoardingWizzard } from "../../Public/Auth/components/OnBoardingWizzard";
import { BottomWrapper } from "../../Public/Auth/components/BottomWrapper";
import request from "superagent";
import { PermissionTypes } from "../../../components/constants/permissions";
import CircleLoader from "../../../components/Loaders/CircleLoader/CircleLoader";
import { Navigate } from "react-router-dom";
import NewStep2 from "./components/WizardSteps/NewStep2";
import { IndustryTypes } from "../../../components/constants/industries";
import NewStep3 from "./components/WizardSteps/NewStep3";
import Button from "../../../components/actions/Button";
import CompanyLimit from "../../../components/dialogs/LimitDialogs/CompanyLimit";
import UsersLimit from "../../../components/dialogs/LimitDialogs/UsersLimit";
import ButtonMui from "../../../components/buttons/buttonMui/buttonMui";
import { ButtonsWrapper } from "./components/ButtonsWrapper";
import { BoardingCard } from "./components/BoardingCard";
import { BoardingTitle } from "./components/BoardingTitle";
import InputM from "../../../components/actions/inputs/InputM";
import { validateMail } from "../../../helpers/EmailHelper";
import { ScrollerComponent } from "../../../components/dialogs/financials/AddDialog/AddDialogContent";
import { Currencies, AllCurrencies } from "../../../helpers/constants";

const yearsOfPlaning = [
  { description: "3 Years", value: 3 },
  { description: "5 Years", value: 5 },
];

export class Collaborator {
  constructor(email = "", userType = 0, mailError = null, validated = false) {
    this._email = email;
    this._userType = userType;
    this._mailError = mailError;
    this._validated = validated;
  }

  get email() {
    return this._email;
  }

  get userType() {
    return this._userType;
  }

  get mailError() {
    return this._mailError;
  }

  get validated() {
    return this._validated;
  }

  set email(value) {
    this._email = value;
  }

  set userType(value) {
    this._userType = value;
  }

  set mailError(value) {
    this._mailError = value;
  }

  set validated(value) {
    this._validated = value;
  }
}

class Onboarding extends React.Component {
  constructor(props) {
    super(props);
    this.optionsForCompanySize = [
      "No employees",
      "Less than 5 employees",
      "5 to 10 employees",
      "11 to 25 employees",
      "26 to 50 employees",
      "51 to 100 employees",
      "More than 100 employees",
    ];
    this.optionsForCompanyStage = [
      "Idea stage",
      "Pre-revenue stage",
      "Growth stage",
      "Mature business stage",
    ];
    this.AllCurrenies = Object.values(AllCurrencies);

    this.state = {
      wizard: 1,
      wizardSteps: 7,
      companyLimitNotification: false,
      openUsersLimitNotification: false,
      predefinedModel: "Yes",
      company: {
        companyName: "",
        currency: AllCurrencies["USD"],
        StartMonth: new Date().getMonth(),
        StartYear: new Date().getFullYear(),
        status: true,
        Size: this.optionsForCompanySize[0],
        Industry: Object.values(IndustryTypes)[0],
        Stage: "Own Company",
        ActiveYears: 3,
      },
      companyScenario: {
        ScenarioName: "Base Scenario",
        ID_Company: null,
      },
      Account: {
        Name: "",
      },
      loading: false,
      financialPlanData: false,
      key: new Date().getTime(),
    };

    if (global.sendOnboardingEvent) {
      global.sendOnboardingEvent("onboarding_started", global.Modeliks.User.ID);
    }

    this.toggleObj = {};

    this.newCompanyID = null;

    this.Collaborators = [];
    if (global.Modeliks.limitInfo.TotalUsers - global.Modeliks.limitInfo.ActiveUsers) {
      // this.Collaborators.push(new Collaborator());
    }
  }

  changeCompany = (key, value) =>
    this.setState((prevState) => ({
      company: {
        ...prevState.company,
        [key]: value,
      },
      key: key == "Industry" ? new Date().getTime() : prevState.key,
    }));

  getCollaborators = (CompanyID) => {
    let users = this.Collaborators.filter((c) => c.validated && !c.mailError).map((user) => {
      return {
        user: {
          Email: user.email,
          UserType: user.userType,
        },
        permissions: this.setPermissions(),
        companies: [{ Company_ID: CompanyID }],
      };
    });
    return users;
  };

  setPermissions = () => {
    let permissions = [];
    permissions.push(PermissionTypes.Pitch);
    permissions.push(PermissionTypes.BusinessPlan);
    permissions.push(PermissionTypes.Financials);
    permissions.push(PermissionTypes.Dashboards);
    permissions.push(PermissionTypes.Valuation);
    return permissions;
  };

  validateCollaborators = () => {
    let err = false;
    this.Collaborators.forEach((collaborator, index) => {
      if (collaborator.email == "") {
        collaborator.mailError = null;
        collaborator.validated = false;
      } else if (!validateMail(collaborator.email)) {
        collaborator.mailError = "invalid mail";
        err = true;
      } else {
        if (this.Collaborators.findIndex((c) => c.email == collaborator.email) === index) {
          collaborator.mailError = null;
          collaborator.validated = true;
        } else {
          collaborator.mailError = "already invited collaborator";
          collaborator.validated = false;
          err = true;
        }
      }
    });

    this.setState({ key: new Date().getTime() });
    if (!err) {
      return true;
    }
    return false;
  };

  setLoader = (loading = !this.state.loading, text) => {
    if (this.props.secure && global.Modeliks.toggleGlobalLoader) {
      global.Modeliks.toggleGlobalLoader(loading, text);
    }
    this.setState({ loading });
  };

  saveCompany = () => {
    if (this.validateCollaborators()) {
      this.setLoader(true, "Please wait while we set up your company...");
      if (global.Modeliks.limitInfo.ActiveCompanies < global.Modeliks.limitInfo.TotalCompanies) {
        let companyUsers = [];
        if (global.Modeliks.User.ID != global.Modeliks.Account.Owner_ID) {
          companyUsers.push({
            User_ID: global.Modeliks.Account.Owner_ID,
          });
        }
        companyUsers.push({
          User_ID: global.Modeliks.User.ID,
        });

        global.Modeliks.post(
          "/api/insert_company",
          {
            company: {
              CompanyName: this.state.company.companyName
                ? this.state.company.companyName
                : "Company1",
              Currency: this.state.company.currency ? this.state.company.currency.code : "USD",
              StartMonth: this.state.company.StartMonth,
              StartYear: this.state.company.StartYear,
              ForecastPeriodYears: 5,
              MonthlyDetailsYears: 1,
              Status: this.state.company.status,
              Stage: this.state.company.Stage,
              Industry: this.state.company.Industry,
              Size: this.state.company.Size,
              ActiveYears: this.state.company.ActiveYears,
            },
            companyUsers,
          },
          (data) => {
            this.newCompanyID = { ID: data.id };
            global.Modeliks.limitInfo.ActiveCompanies++;

            let userPermissionsInsert = (callBack) => {
              if (global.Modeliks.Companies.length) {
                callBack && callBack();
              } else {
                request
                  .post("/api/initializeUserPermissions")
                  .set("authorization", "Bearer " + window.localStorage.getItem("token"))
                  .send({
                    User_ID: global.Modeliks.User.ID,
                    user: { UserType: 2 },
                    permissions: Object.keys(PermissionTypes).map(
                      (permission) => PermissionTypes[permission],
                    ),
                  })
                  .then(() => {
                    callBack && callBack();
                  });
              }
            };
            userPermissionsInsert(() => {
              global.Modeliks.post(
                "companyscenarios",
                {
                  ID_Company: data.id,
                  ScenarioName: this.state.companyScenario.ScenarioName,
                  PredefinedModel: this.state.financialPlanData,
                },
                (dataScenario) => {
                  request
                    .post("/api/BusinessPlanPages")
                    .set("authorization", "Bearer " + window.localStorage.getItem("token"))
                    .query({ company_id: data.id })
                    .then(() => {});
                  global.Modeliks.post(
                    "pitchscenarios",
                    {
                      Name: "Pitch Deck (original)",
                      Company_ID: data.id,
                    },
                    (pitchScenario) => {
                      request
                        .post("/api/PitchScenarios")
                        .set("authorization", "Bearer " + window.localStorage.getItem("token"))
                        .query({ pitch_scenario_id: pitchScenario.id, company_id: data.id })
                        .then(() => {
                          global.Modeliks.post(
                            "businessplanconfig",
                            {
                              Counter: true,
                              FooterText: null,
                              Logo: false,
                              BackgroundColor: "#FFFFFF",
                              CompanyID: data.id,
                            },
                            (businessPlanConfig) => {
                              global.Modeliks.post(
                                "businessplanscenarios",
                                {
                                  Name: "Business Plan (original)",
                                  Company_ID: data.id,
                                },
                                (businessPlanScenario) => {
                                  request
                                    .post("/api/BusinessPlanScenarios")
                                    .set(
                                      "authorization",
                                      "Bearer " + window.localStorage.getItem("token"),
                                    )
                                    .query({
                                      business_plan_scenario_id: businessPlanScenario.id,
                                      company_id: data.id,
                                    })
                                    .then(() => {
                                      if (this.getValueTemplateOption()) {
                                        request
                                          .post("/api/insertFinancialData")
                                          .query({
                                            industry: this.state.company.Industry,
                                            ID_CompanyScenario: dataScenario.id,
                                            accountID: global.Modeliks.Account.ID,
                                          })
                                          .then(() => {
                                            // if (global.Modeliks.isFirstLogin) {
                                            //     if (global.onUserRegister) {
                                            //         global.onUserRegister(global.Modeliks.User.ID, global.Modeliks.subscriptionInfo.SubscriptionID, global.Modeliks.subscriptionInfo.data_key);
                                            //     }
                                            // }
                                            this.finalCallBack(data, dataScenario);
                                          });
                                      } else {
                                        this.finalCallBack(data, dataScenario);
                                      }
                                    });
                                },
                              );
                            },
                          );
                        });
                    },
                  );
                },
              );
            });
          },
          (err) => {
            console.error("err", err);
          },
          true,
        );
      } else {
        this.setState({ loading: false, companyLimitNotification: true });
      }
    }
  };

  finalCallBack = (data, scenData) => {
    if (this.getCollaborators(data.id).length) {
      if (global.Modeliks.limitInfo.ActiveUsers < global.Modeliks.limitInfo.TotalUsers) {
        request
          .post("/api/inviteUser")
          .set("authorization", "Bearer " + window.localStorage.getItem("token"))
          .send({
            users: this.getCollaborators(data.id),
            name: global.Modeliks.User.Name,
            email: global.Modeliks.User.Email,
            User_ID: global.Modeliks.User.ID,
          })
          .then((data) => {
            if (data.text === "limit reached") {
              this.setState({ openUsersLimitNotification: true });
            }
          });
      } else {
        this.setState({ openUsersLimitNotification: true });
      }
    }
    if (this.props.nextRoute) {
      global.Modeliks.get(
        "View_CompanyUsers_Companies",
        {
          User_ID: global.Modeliks.User.ID,
          AccID: global.Modeliks.Account.ID,
        },
        (view_companies) => {
          global.Modeliks.get("companies", { ID: view_companies.map((c) => c.ID) }, (companies) => {
            global.Modeliks.Companies = companies;
            global.Modeliks.CompanyInfo = companies.find((c) => c.ID == data.id);
            window.localStorage.setItem("CompanyScenarioID", scenData.id);
            global.Modeliks.ClearDriversForCompanyChange(() => {
              global.Modeliks.ChangeCompany(global.Modeliks.CompanyInfo, () => {
                this.setLoader(false);
                global.Modeliks.NavigateTo(this.props.nextRoute);
                if (global.HelpHero) {
                  global.HelpHero.startTour("mGOfD1W36O");
                }
              });
            });
          });
        },
      );
    } else {
      this.state.loading = false;
      global.Modeliks.Authenticate();
    }

    if (global.sendOnboardingEvent) {
      global.sendOnboardingEvent("onboarding_finished", global.Modeliks.User.ID);
    }
  };

  NextStep = () => {
    let wizard = this.state.wizard;
    wizard !== 7 && this.setState({ wizard: wizard + 1, key: new Date().getTime() });
  };
  BackStep = () => {
    let wizard = this.state.wizard;
    wizard !== 1 && this.setState({ wizard: wizard - 1, key: new Date().getTime() });
  };

  ShowStep = () => {
    const step = this.state.wizard;
    switch (step) {
      case 1:
        return (
          <Step2
            saveCompany={() => this.saveCompany()}
            setCompanyName={(companyName, callBack) =>
              this.setState(
                (prevState) => ({
                  company: {
                    ...prevState.company,
                    companyName: companyName,
                  },
                }),
                () => callBack(),
              )
            }
            User={this.state.User}
            company={this.state.company}
            NextStep={this.NextStep}
          />
        );
      case 2:
        return (
          <NewStep2
            saveCompany={() => this.saveCompany()}
            setCompany={(key, value) =>
              this.setState((prevState) => ({
                company: {
                  ...prevState.company,
                  [key]: value,
                },
              }))
            }
            Sizes={this.optionsForCompanySize}
            Stages={this.optionsForCompanyStage}
            company={this.state.company}
            BackStep={this.BackStep}
            NextStep={this.NextStep}
          />
        );
      case 3:
        return (
          <NewStep3
            financialPlanData={this.state.financialPlanData}
            setFinancialPlanData={() =>
              this.setState({ financialPlanData: !this.state.financialPlanData })
            }
            saveCompany={() => this.saveCompany()}
            company={this.state.company}
            NextStep={this.NextStep}
            BackStep={this.BackStep}
          />
        );
      case 4:
        return (
          <Step3
            saveCompany={() => this.saveCompany()}
            setCurrency={(currency, callBack) =>
              this.setState(
                (prevState) => ({
                  company: {
                    ...prevState.company,
                    currency: currency,
                  },
                }),
                () => callBack(),
              )
            }
            company={this.state.company}
            NextStep={this.NextStep}
            BackStep={this.BackStep}
          />
        );
      case 5:
        return (
          <Step4
            saveCompany={() => this.saveCompany()}
            company={this.state.company}
            collaborators={this.Collaborators}
            NextStep={this.NextStep}
            BackStep={this.BackStep}
          />
        );
      case 6:
        return (
          <Step5
            saveCompany={() => this.saveCompany()}
            CompanyName={this.state.companyName}
            company={this.state.company}
            Currency={this.state.currency}
            NextStep={this.NextStep}
            BackStep={this.BackStep}
          />
        );
      case 7:
        return (
          <Step6
            saveCompany={() => this.saveCompany()}
            NextStep={this.NextStep}
            BackStep={this.BackStep}
          />
        );
    }
  };

  closeCompanyLimitNotification = () => {
    this.setState({ companyLimitNotification: false });
  };

  getOptionsForTemplate = () => {
    if (global.Modeliks.PERMISSIONS.Financials.restrictions.FinancialModelTemplates.active) {
      return [
        { value: true, description: "Yes" },
        { value: false, description: "No, I build my own model" },
      ];
    }
    return [{ value: false, description: "Not available for this plan" }];
  };

  getValueTemplateOption = () => {
    if (this.state.company.Industry === IndustryTypes.Other) {
      return false;
    }
    return this.state.financialPlanData;
  };

  getDisabledValueTemplateOption = () => {
    if (
      !global.Modeliks.PERMISSIONS.Financials.restrictions.FinancialModelTemplates.active ||
      this.state.company.Industry === IndustryTypes.Other
    ) {
      return true;
    }
    return false;
  };

  changeModelTemplatePreference = (key, value) => {
    this.setState({ financialPlanData: value });
  };

  addCollaborator = (collaborator) => {
    if (global.Modeliks.limitInfo.TotalUsers - global.Modeliks.limitInfo.ActiveUsers) {
      this.Collaborators.push(collaborator);
      this.forceUpdate(() => {
        if (this.toggleObj && this.toggleObj.scrollToBottom) {
          this.toggleObj.scrollToBottom();
        }
      });
    }
  };

  deleteCollaborator = (index) => {
    this.Collaborators.splice(index, 1);
    this.forceUpdate();
  };

  changeCurrency = (currency) => {
    this.state.company.currency = currency;
    this.forceUpdate();
  };

  render() {
    if (
      global.Modeliks.limitInfo.ActiveCompanies >= global.Modeliks.limitInfo.TotalCompanies &&
      this.props.secure
    ) {
      global.Modeliks.NavigateTo("/profile/companies");
      return null;
    }
    this.boardingCards = [
      {
        title: "How are you planning to use Modeliks?",
        subtitle:
          "Are you planning to use Modeliks for your own company or employer or as a service for your clients?",
        inputType: "select",
        name: "Stage",
        value: this.state.company.Stage,
        options: ["Service for clients", "Own Company"],
        onChange: this.changeCompany,
      },
      {
        title: "What is your company name?",
        subtitle:
          "Don’t worry if you have not decided yet on your company name - you can change it later.",
        inputType: "text",
        name: "companyName",
        value: this.state.company.companyName,
        placeholder: "My Company",
        onChange: this.changeCompany,
      },
      {
        title: "What is your industry?  ",
        subtitle: "We’ll customize your Modeliks experience based on your choice.",
        inputType: "select",
        name: "Industry",
        value: this.state.company.Industry,
        options: Object.values(IndustryTypes),
        placeholder: "My Company",
        onChange: this.changeCompany,
      },
      {
        title: "Want to use a pre-defined financial model based on your industry?",
        subtitle:
          "Modeliks did the heavy lifting for you, pre-defined industry models are here to make your life event easier!",
        inputType: "select",
        value: this.getValueTemplateOption(),
        definedOptions: this.getOptionsForTemplate(),
        placeholder: "My Company",
        disabled: this.getDisabledValueTemplateOption(),
        onChange: this.changeModelTemplatePreference,
      },
      {
        title: "What’s the first month in your plan?",
        subtitle:
          "Modeliks’ forecast allow for monthly inputs in the first year. Ideally the forecast year starts with your accounting year. No worries if not, we can work through this later too.",
        inputType: "datepicker",
        value: `${this.state.company.StartYear}-${(this.state.company.StartMonth + 1).toString().padStart(2, "0")}`,
        placeholder: "My Company",
        onChange: (e) => {
          if (e) {
            this.state.company.StartMonth = e.getMonth();
            this.state.company.StartYear = e.getFullYear();
            this.forceUpdate();
          }
        },
      },
      {
        title: "For how many years are you planning?",
        subtitle:
          "Typical planning periods are 3 or 5 years. You can re adjust this later if you change your mind.",
        inputType: "select",
        name: "ActiveYears",
        value: this.state.company.ActiveYears,
        options: yearsOfPlaning,
        definedOptions: yearsOfPlaning,
        placeholder: "My Company",
        onChange: this.changeCompany,
      },
      {
        title: "Which currency do you want to use?",
        subtitle: "If you are not sure what currency to use, don’t worry, you can change it later!",
        inputType: "autocomplete",
        name: "currency",
        value: this.state.company.currency,
        options: this.AllCurrenies,
        definedOptions: this.AllCurrenies,
        placeholder: "Currency",
        onChange: this.changeCurrency,
      },
      {
        title: "Want to collaborate? Add users!",
        subtitle:
          "Collaboration is at the center of all we do! Want to add your colleagues and users directly? Else you can always add and adjust your team in user settings later.",
        inputType: false,
        addButton: true,
        collaborators: this.Collaborators,
        addCollaborator: this.addCollaborator,
        deleteCollaborator: this.deleteCollaborator,
      },
    ];

    if (this.state.loading) {
      return (
        <div className={"main_circle_loader_container"}>
          <CircleLoader message={"Please wait while we set up your account..."} />
        </div>
      );
    }

    return (
      <div className="spso_boarding_container_main">
        <BoardingTitle isCompanies={this.props.nextRoute} />
        <ScrollerComponent
          onboarding
          className="spso_boarding_cards_container"
          toggleObj={this.toggleObj}
        >
          <div className="spso_boarding_cards_sub_container" key={this.state.key}>
            {this.boardingCards.map((b, index) => {
              if (this.props.nextRoute && index === 0) {
              } else {
                return (
                  <BoardingCard
                    key={`bc_i${index}`}
                    collaborators={this.Collaborators}
                    {...b}
                    number={this.props.nextRoute ? index : index + 1}
                  />
                );
              }
            })}
          </div>
        </ScrollerComponent>
        <div className="spso_boarding_action_buttons">
          <ButtonMui
            label={"Get Started"}
            style={{ height: "40px" }}
            width={150}
            variant={"contained"}
            endIcon={<ArrowRight />}
            onClick={() => this.saveCompany()}
          />
        </div>

        <CompanyLimit
          open={this.state.companyLimitNotification}
          handleClose={this.closeCompanyLimitNotification}
        />
        <UsersLimit
          open={this.state.openUsersLimitNotification}
          handleClose={() => this.setState({ openUsersLimitNotification: false })}
        />
      </div>
    );

    return (
      <div className="psoc_onboarding_main">
        <div className="psoc_onboarding_contained">
          <div className="psoc_logo_wrapper">
            <Logo
              onClick={() =>
                this.setState({ wizard: this.state.wizard !== 7 ? this.state.wizard + 1 : 7 })
              }
            />
          </div>
          <div className="psoc_body_wrapper">
            <OnBoardingWizzard steps={this.state.wizardSteps} wizard={this.state.wizard} />
            {/*<div className='o_content_wrapper'>*/}
            {this.ShowStep()}
            {/*</div>*/}
            {/*<ButtonsWrapper key={`o_b_w_${this.state.key}`}>*/}
            {/*    <div className='o_btn_container'>*/}
            {/*        {(this.state.wizard > 1) ? <ButtonMui startIcon={<ArrowLeft/>} label={'Back'} width='120px' variant={'contained'} onClick={this.BackStep}/> : <div/>}*/}
            {/*    </div>*/}
            {/*    <div className='o_btn_container start'>*/}
            {/*        {(this.state.wizard < 7) ?*/}
            {/*            <ButtonMui label={'Next'} width='120px' variant={'contained'} endIcon={<ArrowRight/>} onClick={this.NextStep}/>:*/}
            {/*            <ButtonMui label={'Get started'} width='120px' color='secondary' variant={'contained'} endIcon={<ArrowRight/>} onClick={this.saveCompany}/>*/}
            {/*        }*/}
            {/*    </div>*/}
            {/*</ButtonsWrapper>*/}
          </div>
          <div className="psoc_actions_buttons">
            <div className="psoc_buttom_button">
              {this.state.wizard !== 7 && (
                <ButtonMui
                  onClick={this.saveCompany}
                  width={120}
                  label="Skip Setup"
                  variant={"contained"}
                />
              )}
            </div>
            <BottomWrapper>
              © Copyright - Modeliks Ltd {new Date().getFullYear()}. All Rights Reserved.
            </BottomWrapper>
          </div>
        </div>
      </div>
    );
  }
}

export default Onboarding;
