import * as React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import PropTypes from "prop-types";
import "./DatePickerYM.scss";
import { Label } from "../../typography/label/Label";

function DatePickerYM(props) {
  const {
    className,
    views,
    name,
    onChange,
    value,
    minDate,
    maxDate,
    doMultipleDateValidation,
    monthValidation,
    startDateValue,
    endDateValue,
    format,
  } = props;

  let validationDate = null;

  const isMonthYear = views && views.length === 2;

  if (doMultipleDateValidation) {
    if (startDateValue) {
      validationDate = new Date(startDateValue);
    }

    if (endDateValue) {
      validationDate = new Date(endDateValue);
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {/*<p className={'scadd_title'}>dadadasss</p>*/}
      {props.label && (
        <Label color={"black"} size="label3" label={props.label} marginLeft={props.marginLeft} />
      )}
      <DesktopDatePicker
        disabled={props.disabled}
        disableToolbar
        shouldDisableMonth={
          doMultipleDateValidation && monthValidation
            ? (month) =>
                endDateValue
                  ? month.getTime() > validationDate.getTime()
                  : month.getTime() < validationDate.getTime()
            : null
        }
        shouldDisableYear={
          doMultipleDateValidation && !monthValidation
            ? (year) =>
                endDateValue
                  ? year.getYear() > validationDate.getYear()
                  : year.getYear() < validationDate.getYear()
            : null
        }
        className={className + " date_picker_year_month"}
        views={views}
        name={name}
        onMonthChange={(e) => e.preventDefault}
        onAccept={(e) => onChange(e)}
        onYearChange={(e) => e.preventDefault}
        minDate={minDate}
        maxDate={maxDate}
        inputFormat={isMonthYear ? "MMM yyyy" : "yyyy"}
        value={value}
        orientation={"landscape"}
        onChange={(e) => e.preventDefault}
        renderInput={(params) => (
          <TextField
            onKeyDown={(e) => e.preventDefault()}
            className={`date_picker_input ${className}`}
            size="small"
            {...params}
            helperText={null}
          />
        )}
      />
    </LocalizationProvider>
  );
}

DatePickerYM.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  className: PropTypes.string,
  doMultipleDateValidation: PropTypes.bool,
  monthValidation: PropTypes.bool,
};

DatePickerYM.defaultProps = {
  views: ["year", "month"],
  doMultipleDateValidation: false,
  label: null,
};

export default DatePickerYM;
