import React, { Component } from "react";
import PropTypes from "prop-types";
import ButtonMui from "../../../../components/buttons/buttonMui/buttonMui";
import { ControlledMenu, Menu, MenuItem } from "@szhsin/react-menu";

class FilterComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      key: new Date().getTime(),
      open: false,
    };

    this.Stages = JSON.parse(JSON.stringify(this.props.Stages));
  }

  toggleFilterForStage = (stage) => {
    stage.Hidden = !stage.Hidden;
    this.forceUpdate();
  };

  toggleMenu = () => {
    this.Stages = JSON.parse(JSON.stringify(this.props.Stages));
    this.setState({ open: !this.state.open });
  };

  acceptChanges = () => {
    this.props.saveStages(this.Stages);
    this.toggleMenu();
  };

  toggleAll = (stages, value) => {
    stages.forEach((stage) => (stage.Hidden = value));
    this.forceUpdate();
  };

  setRef = (ref) => (this.btnRef = ref);

  render() {
    const Stages = this.props.getFilteredStages(this.Stages);

    return (
      <>
        <ButtonMui
          onClick={this.toggleMenu}
          ref={this.setRef}
          color={"black"}
          label={"Filter"}
          roundnessType={"squared"}
          variant={"text"}
          endIcon={<FilterIcon />}
        />
        <ControlledMenu
          key={this.state.key + "_filter_menu"}
          className={"color_menu filter_component"}
          state={this.state.open ? "open" : "closed"}
          ref={this.btnRef}
          onClose={this.toggleMenu}
        >
          <div className={"menu_item_filter div"}>
            <ButtonMui
              onClick={() => this.toggleAll(Stages, true)}
              roundnessType={"squared"}
              label={"Unselect All"}
              variant={"text"}
            />
            <ButtonMui
              onClick={() => this.toggleAll(Stages, false)}
              roundnessType={"squared"}
              label={"Select All"}
              variant={"text"}
            />
          </div>
          {Stages.map((stage, i) => (
            <MenuItem
              key={`stage_fltr_${i}`}
              className={"menu_item_filter"}
              onClick={(e) => {
                this.toggleFilterForStage(stage);
                e.stopPropagation = true;
                e.keepOpen = true;
              }}
            >
              <span>{stage.Name}</span>
              {SelectedIcon[!!stage.Hidden]}
            </MenuItem>
          ))}
          <div className={"menu_item_filter div"}>
            <ButtonMui
              onClick={this.toggleMenu}
              label={"Cancel"}
              color={"grey"}
              variant={"contained"}
            />
            <ButtonMui onClick={this.acceptChanges} label={"Ok"} variant={"contained"} />
          </div>
        </ControlledMenu>
      </>
    );
  }
}

FilterComponent.propTypes = {};

const CheckedIcon = () => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="9.39337" cy="9.39337" r="9.39337" fill="#01AFFD" />
    <path
      d="M5.36768 9.39351L8.02011 12.0773L13.4191 6.70969"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="square"
    />
  </svg>
);

const UncheckedIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_i_1023_2314)">
      <circle cx="9.52106" cy="9.52106" r="9.52106" fill="#FBFBFB" />
    </g>
    <circle cx="9.52106" cy="9.52106" r="9.02106" stroke="#D4D5DE" />
    <defs>
      <filter
        id="filter0_i_1023_2314"
        x="0"
        y="0"
        width="19.0421"
        height="19.0421"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="3" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1023_2314" />
      </filter>
    </defs>
  </svg>
);

const SelectedIcon = {
  [false]: <CheckedIcon />,
  [true]: <UncheckedIcon />,
};

const FilterIcon = () => (
  <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0.871257C0 0.640185 0.0965771 0.418577 0.268485 0.255185C0.440394 0.0917929 0.673551 0 0.916667 0H10.0833C10.3264 0 10.5596 0.0917929 10.7315 0.255185C10.9034 0.418577 11 0.640185 11 0.871257V2.08288C10.9999 2.39095 10.8711 2.68638 10.6419 2.90419L7.33333 6.04884V10.361C7.33335 10.4699 7.30407 10.577 7.24828 10.6722C7.19248 10.7673 7.11202 10.8473 7.01454 10.9046C6.91706 10.9618 6.8058 10.9945 6.69132 10.9994C6.57684 11.0042 6.46294 10.9812 6.36044 10.9325L4.08894 9.85333C3.96206 9.79303 3.85535 9.70035 3.78076 9.58565C3.70618 9.47096 3.66667 9.33879 3.66667 9.20395V6.04884L0.358111 2.90419C0.128884 2.68638 6.92235e-05 2.39095 0 2.08288V0.871257Z"
      fill="currentColor"
    />
  </svg>
);

export default FilterComponent;
