import MxDataArray from "../../../MxDataArray";
import TradingMultiples from "./index";

class TradingMultiplesStorage extends MxDataArray {
  dataManager = null;
  driverStorage = null;
  loaded = false;

  setData = (callBack, data = this.data) => {
    this.pushArray(
      data.map((item) => {
        const newItem = new TradingMultiples(item);
        if (newItem.Revenue) {
          return newItem;
        } else {
          // this.dataManager.del(this.dataManager.Tables.Valuation_Trading_Multiples.TableName, {ID: newItem.ID}, () => {
          //     console.log('deleting',newItem);
          //
          // }, () => {
          // });
        }
      }),
    );
    this.loaded = true;
    TradingMultiples.tmpTotalsTradingMultiples(() => {});
    callBack();
  };

  load = (callBack, shouldFinish = true) => {
    this.clear();
    this.dataManager.get(
      this.dataManager.Tables.Valuation_Trading_Multiples.TableName,
      { ID_CompanyScenario: this.dataManager.CompanyScenarioInfo.ID },
      (data) => {
        if (shouldFinish) {
          this.setData(callBack, data);
        } else {
          this.data = data;
          callBack();
        }
      },
      null,
      false,
    );
  };

  static create = () => {
    const newArr = new TradingMultiplesStorage();
    newArr.pushOld = newArr.push;
    newArr.push = newArr.pushThis;

    return newArr;
  };
}

export default TradingMultiplesStorage;
