export default {
  photos: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2zm0 1.5a.5.5 0 0 0-.5.5v14c0 .28.22.5.5.5h14a.5.5 0 0 0 .5-.5V5a.5.5 0 0 0-.5-.5H5zm5.75 10.1 3.05-4.15a2 2 0 0 1 3.22-.01L21 15.78V19a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-.09l3.82-5.25a2 2 0 0 1 3.22 0l.7.95zm3.6 4.9H19a.5.5 0 0 0 .5-.5v-2.72l-3.69-4.94a.5.5 0 0 0-.8 0l-3.33 4.53 2.68 3.63zm-5.51-4.96a.5.5 0 0 0-.81 0l-3.44 4.74a.5.5 0 0 0 .41.22h7.5l-3.66-4.96zM8.5 10a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
      ></path>
    </svg>
  ),
  templates: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M19.5 10V5a.5.5 0 0 0-.5-.5h-4.5V10h5zm0 1.5h-5v8H19a.5.5 0 0 0 .5-.5v-7.5zm-6.5-7H5a.5.5 0 0 0-.5.5v14c0 .28.22.5.5.5h8v-15zM5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2z"
      ></path>
    </svg>
  ),
  elements: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
        d="m6.5 4.25.75-.75a2.121 2.121 0 0 1 3 3L6.5 10.25 2.75 6.5a2.121 2.121 0 0 1 3-3l.75.75zm7 6 4-7.5 4 7.5h-8zm-10.75 3.5h7.5v7.5h-7.5v-7.5zm14.75-.25a4 4 0 1 0 0 8 4 4 0 0 0 0-8z"
      ></path>
    </svg>
  ),
  text: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M18 5.5h-5.25V18c0 .28.22.5.5.5h2a.75.75 0 1 1 0 1.5h-6.5a.75.75 0 1 1 0-1.5h2a.5.5 0 0 0 .5-.5V5.5H6a.5.5 0 0 0-.5.5v1.25a.75.75 0 0 1-1.5 0V5.5C4 4.67 4.67 4 5.5 4h13c.83 0 1.5.67 1.5 1.5v1.75a.75.75 0 1 1-1.5 0V6a.5.5 0 0 0-.5-.5z"
      ></path>
    </svg>
  ),
  charts: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M2.75 19h18.5a.75.75 0 1 1 0 1.5H2.75a.75.75 0 1 1 0-1.5zm7.32-9.01-5.54 5.54a.75.75 0 0 1-1.06-1.06l6-6c.26-.26.68-.3.98-.07l3.44 2.58 5.54-6.47a.75.75 0 0 1 1.14.98l-6 7c-.26.3-.7.35-1.02.11l-3.48-2.61z"
      ></path>
    </svg>
  ),
  uploads: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M12.75 13.81v7.44a.75.75 0 1 1-1.5 0v-7.4L9.49 15.6a.75.75 0 1 1-1.06-1.06l2.35-2.36c.68-.68 1.8-.68 2.48 0l2.35 2.36a.75.75 0 1 1-1.06 1.06l-1.8-1.8zM9 18v1.5H6.75v-.01A5.63 5.63 0 0 1 5.01 8.66a6 6 0 0 1 11.94-.4 5.63 5.63 0 0 1 .3 11.23v.01H15V18h1.88a4.12 4.12 0 1 0-1.5-7.97A4.51 4.51 0 0 0 11 4.5a4.5 4.5 0 0 0-4.43 5.29 4.13 4.13 0 0 0 .68 8.2V18H9z"
      ></path>
    </svg>
  ),
  more: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M5 14a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm7 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm7 0a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
      ></path>
    </svg>
  ),
  chartBarTemplate: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 100 100"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M100 14H0V15H100V14ZM0 28H100V29H0V28ZM100 42H0V43H100V42ZM0 56H100V57H0V56ZM100 70H0V71H100V70ZM100 84H0V85H100V84Z"
        fill="white"
        fillOpacity="0.1"
      />
      <path
        d="M25 56C23.8954 56 23 56.8954 23 58V85H31V58C31 56.8954 30.1046 56 29 56H25Z"
        fill="#2F9DC6"
      />
      <path
        d="M13 72C13 70.8954 13.8954 70 15 70H19C20.1046 70 21 70.8954 21 72V85H13V72Z"
        fill="#6CE5E8"
      />
      <path
        d="M53 42C51.8954 42 51 42.6755 51 43.5088V85H59V43.5088C59 42.6755 58.1046 42 57 42H53Z"
        fill="#2F9DC6"
      />
      <path
        d="M41 57.3488C41 56.6039 41.8954 56 43 56H47C48.1046 56 49 56.6039 49 57.3488V85H41V57.3488Z"
        fill="#6CE5E8"
      />
      <path
        d="M81 14C79.8954 14 79 14.8954 79 16V85H87V16C87 14.8954 86.1046 14 85 14H81Z"
        fill="#2F9DC6"
      />
      <path
        d="M69 29.8095C69 28.8102 69.8954 28 71 28H75C76.1046 28 77 28.8102 77 29.8095V85H69V29.8095Z"
        fill="#6CE5E8"
      />
    </svg>
  ),
  chartLineTemplate: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 100 100"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M100 14H0V15H100V14ZM0 28H100V29H0V28ZM100 42H0V43H100V42ZM0 56H100V57H0V56ZM100 70H0V71H100V70ZM100 84H0V85H100V84Z"
        fill="white"
        fillOpacity="0.1"
      />
      <path
        d="M9 83L29 57.5L50.1426 67.5L70.9709 36.7734L90.9724 39.334"
        stroke="#6CE5E8"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 86C10.6569 86 12 84.6569 12 83C12 81.3431 10.6569 80 9 80C7.34315 80 6 81.3431 6 83C6 84.6569 7.34315 86 9 86Z"
        fill="#6CE5E8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29 60C30.6569 60 32 58.6569 32 57C32 55.3431 30.6569 54 29 54C27.3431 54 26 55.3431 26 57C26 58.6569 27.3431 60 29 60Z"
        fill="#6CE5E8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50 70C51.6569 70 53 68.6569 53 67C53 65.3431 51.6569 64 50 64C48.3431 64 47 65.3431 47 67C47 68.6569 48.3431 70 50 70Z"
        fill="#6CE5E8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M91 42C92.6569 42 94 40.6569 94 39C94 37.3431 92.6569 36 91 36C89.3431 36 88 37.3431 88 39C88 40.6569 89.3431 42 91 42Z"
        fill="#6CE5E8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71 40C72.6569 40 74 38.6569 74 37C74 35.3431 72.6569 34 71 34C69.3431 34 68 35.3431 68 37C68 38.6569 69.3431 40 71 40Z"
        fill="#6CE5E8"
      />
    </svg>
  ),
  chartPieTemplate: (
    <img
      width="35"
      height="35"
      src="https://static.canva.com/charts/thumbnails/piechart-v2-1.svg"
      alt=""
      draggable="false"
    />
  ),
  chartPolarAreaTemplate: (
    <img width="35" height="35" src={require("./PieChartTemplate.jpg")} alt="Nope" />
  ),
  chartDoughnutTemplate: (
    <img
      width="35"
      height="35"
      className="_9Al4OQ"
      src="https://static.canva.com/charts/thumbnails/donutchart-v2-1.svg"
      alt=""
      draggable="false"
    />
  ),

  triangleImg: (
    <img
      width="35"
      height="35"
      className="_9Al4OQ A_yLpA _1vGB4g"
      crossOrigin="anonymous"
      src="https://template.canva.com/EADqYVm1X-k/1/0/800w-mt9f2x6PY8U.png"
      alt="Equilateral Triangle"
      draggable="false"
    />
  ),
  squareImg: (
    <img
      width="35"
      height="35"
      className="_9Al4OQ A_yLpA _1vGB4g"
      crossOrigin="anonymous"
      src="https://template.canva.com/EADX7aFGo6g/2/0/1600w-mkJyKOPpkVI.png"
      alt="Square"
      draggable="false"
    />
  ),
  circleImg: (
    <img
      width="35"
      height=""
      src={"https://template.canva.com/EADqYKce8L0/2/0/800w-8QUiraJhI54.png"}
    />
  ),
  centerText: (
    <svg
      className={"TextAlignIcon"}
      xmlns="http://www.w3.org/2000/svg"
      width="30px"
      height="30px"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3.75 5.25h16.5a.75.75 0 1 1 0 1.5H3.75a.75.75 0 0 1 0-1.5zm4 4h8.5a.75.75 0 1 1 0 1.5h-8.5a.75.75 0 1 1 0-1.5zm-4 4h16.5a.75.75 0 1 1 0 1.5H3.75a.75.75 0 1 1 0-1.5zm4 4h8.5a.75.75 0 1 1 0 1.5h-8.5a.75.75 0 1 1 0-1.5z"
      ></path>
    </svg>
  ),
  leftText: (
    <svg
      className={"TextAlignIcon"}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3.75 5.25h16.5a.75.75 0 1 1 0 1.5H3.75a.75.75 0 0 1 0-1.5zm0 4h8.5a.75.75 0 1 1 0 1.5h-8.5a.75.75 0 1 1 0-1.5zm0 4h16.5a.75.75 0 1 1 0 1.5H3.75a.75.75 0 1 1 0-1.5zm0 4h8.5a.75.75 0 1 1 0 1.5h-8.5a.75.75 0 1 1 0-1.5z"
      ></path>
    </svg>
  ),
  rightText: (
    <svg
      className={"TextAlignIcon"}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M20.25 5.25a.75.75 0 1 1 0 1.5H3.75a.75.75 0 0 1 0-1.5h16.5zm0 4a.75.75 0 1 1 0 1.5h-8.5a.75.75 0 1 1 0-1.5h8.5zm0 4a.75.75 0 1 1 0 1.5H3.75a.75.75 0 1 1 0-1.5h16.5zm0 4a.75.75 0 1 1 0 1.5h-8.5a.75.75 0 1 1 0-1.5h8.5z"
      ></path>
    </svg>
  ),
  elipseImg: (
    <img
      width="35"
      height="17"
      src={"https://template.canva.com/EADqYKce8L0/2/0/800w-8QUiraJhI54.png"}
    />
  ),
  lineImg: (
    <img
      className="UuukAw"
      src="data:image/svg+xml,<svg xmlns=''http://www.w3.org/2000/svg'' stroke=''rgb(240, 240, 240)'' fill=''rgb(240, 240, 240)'' viewBox=''0 -0.5 33 1''><line x1=''0.5'' x2=''32.5'' strokeDasharray='''' strokeLinecap=''round'' fill=''none''></line></svg>"
      alt="Add this line to the canvas"
      draggable="false"
    />
  ),

  lineType: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
      <rect width="18" height="1.5" x="3" y="4" fill="currentColor" rx=".75"></rect>
      <rect width="18" height="3" x="3" y="8.5" fill="currentColor" rx="1"></rect>
      <rect width="18" height="5.5" x="3" y="14.5" fill="currentColor" rx="1"></rect>
    </svg>
  ),

  lineNormal: (
    <svg className="_88_uDg" viewBox="0 0 48 24">
      <line
        x1="2"
        y1="50%"
        x2="46"
        y2="50%"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="4"
        strokeDasharray=""
      ></line>
    </svg>
  ),

  lineDotted: (
    <svg className="_88_uDg" viewBox="0 0 44 24">
      <line
        x1="2"
        y1="50%"
        x2="46"
        y2="50%"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="4"
        strokeDasharray="0,8"
      ></line>
    </svg>
  ),

  lineDashed: (
    <svg className="_88_uDg" viewBox="0 0 44 24">
      <line
        x1="2"
        y1="50%"
        x2="46"
        y2="50%"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="4"
        strokeDasharray="4,8"
      ></line>
    </svg>
  ),
  rightArrowFilled: (
    <svg viewBox=" 0 0 100 100">
      <polygon points="0,100 100,50 0,0" />
    </svg>
  ),
  leftArrowFilled: (
    <svg viewBox=" 0 0 100 100">
      <polygon fill={"black"} points="0,50 100,100 100,0" />
    </svg>
  ),

  lineRightArrow: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      stroke="rgb(240, 240, 240)"
      fill="rgb(240, 240, 240)"
      viewBox="0 -0.5 33 1"
    >
      <line x1="0.5" x2="30" strokeLinecap="round" fill="none"></line>
      <g transform="translate(33)">
        <path
          fill="inherit"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M -2.5,-1.5,-0.5,0,-2.5,1.5 Z"
        ></path>
      </g>
    </svg>
  ),
  lineLeftRightArrow: (
    <img
      className="UuukAw"
      src='data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" stroke="rgb(240, 240, 240)" fill="rgb(240, 240, 240)" viewBox="0 -0.5 33 1"><line x1="0.75" x2="32.25" strokeDasharray="" strokeLinecap="round" fill="none"></line><path fill="none" strokeLinecap="round" stroke-linejoin="round" d="M 2.5,-1.5,0.5,0,2.5,1.5 "></path><g transform="translate(33)"><path fill="none" strokeLinecap="round" stroke-linejoin="round" d="M -2.5,-1.5,-0.5,0,-2.5,1.5 "></path></g></svg>'
      alt="Add this line to the canvas"
      draggable="false"
    />
  ),
  TableBorder: (
    <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 16 16">
      <path d="M0 0h16v16H0V0zm1 1v6.5h6.5V1H1zm7.5 0v6.5H15V1H8.5zM15 8.5H8.5V15H15V8.5zM7.5 15V8.5H1V15h6.5z" />
    </svg>
  ),
  TableBorderTop: (
    <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 20 20">
      <path d="M1 1h18v2H1V1zm0 4h2v2H1V5zm0 4h2v2H1V9zm0 4h2v2H1v-2zm0 4h2v2H1v-2zm4-8h2v2H5V9zm0 8h2v2H5v-2zM9 5h2v2H9V5zm0 4h2v2H9V9zm0 4h2v2H9v-2zm0 4h2v2H9v-2zm4-8h2v2h-2V9zm0 8h2v2h-2v-2zm4-12h2v2h-2V5zm0 4h2v2h-2V9zm0 4h2v2h-2v-2zm0 4h2v2h-2v-2z" />
    </svg>
  ),
  TableBorderBottom: (
    <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 20 20">
      <path d="M1 1h2v2H1V1zm0 4h2v2H1V5zm0 4h2v2H1V9zm0 4h2v2H1v-2zm0 4h18v2H1v-2zM5 1h2v2H5V1zm0 8h2v2H5V9zm4-8h2v2H9V1zm0 4h2v2H9V5zm0 4h2v2H9V9zm0 4h2v2H9v-2zm4-12h2v2h-2V1zm0 8h2v2h-2V9zm4-8h2v2h-2V1zm0 4h2v2h-2V5zm0 4h2v2h-2V9zm0 4h2v2h-2v-2z" />
    </svg>
  ),
  TableBorderLeft: (
    <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 20 20">
      <path d="M1 1h2v18H1V1zm4 0h2v2H5V1zm0 8h2v2H5V9zm0 8h2v2H5v-2zM9 1h2v2H9V1zm0 4h2v2H9V5zm0 4h2v2H9V9zm0 4h2v2H9v-2zm0 4h2v2H9v-2zm4-16h2v2h-2V1zm0 8h2v2h-2V9zm0 8h2v2h-2v-2zm4-16h2v2h-2V1zm0 4h2v2h-2V5zm0 4h2v2h-2V9zm0 4h2v2h-2v-2zm0 4h2v2h-2v-2z" />
    </svg>
  ),
  TableBorderRight: (
    <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 20 20">
      <path d="M5 1h2v2H5V1zm0 8h2v2H5V9zm0 8h2v2H5v-2zM9 1h2v2H9V1zm0 4h2v2H9V5zm0 4h2v2H9V9zm0 4h2v2H9v-2zm0 4h2v2H9v-2zm4-16h2v2h-2V1zm0 8h2v2h-2V9zm0 8h2v2h-2v-2zM1 1h2v2H1V1zm0 4h2v2H1V5zm0 4h2v2H1V9zm0 4h2v2H1v-2zm0 4h2v2H1v-2zM17 1h2v18h-2V1z" />
    </svg>
  ),
  BorderWeight: (
    <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px">
      <rect width="18" height="1.5" x="3" y="4" fill="currentColor" rx=".75"></rect>
      <rect width="18" height="3" x="3" y="8.5" fill="currentColor" rx="1"></rect>
      <rect width="18" height="5.5" x="3" y="14.5" fill="currentColor" rx="1"></rect>
    </svg>
  ),
  TableBackgroundColor: (
    <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 20 20">
      <path d="M20 14c-.092.064-2 2.083-2 3.5 0 1.494.949 2.448 2 2.5.906.044 2-.891 2-2.5 0-1.5-1.908-3.436-2-3.5zM9.586 20c.378.378.88.586 1.414.586s1.036-.208 1.414-.586l7-7-.707-.707L11 4.586 8.707 2.293 7.293 3.707 9.586 6 4 11.586c-.378.378-.586.88-.586 1.414s.208 1.036.586 1.414L9.586 20zM11 7.414 16.586 13H5.414L11 7.414z" />
    </svg>
  ),

  template1: (
    <svg width="35px" height="35px" viewBox="0 0 100 100">
      <polygon fill={"black"} points="0,0 100,0 100,30 0,30" />
      <polygon fill={"black"} points="0,40 100,40 100,100 0,100" />
    </svg>
  ),
  template2: (
    <svg width="35px" height="35px" viewBox="0 0 100 100">
      <polygon fill={"black"} points="0,0 100,0 100,30 0,30" />
      <polygon fill={"black"} points="0,40 45,40 45,100 0,100" />
      <polygon fill={"black"} points="55,40 100,40 100,100 55,100" />
    </svg>
  ),
  template3: <img width="35px" src={require("./template 3.jpg")} />,

  moveIcon: <img width="14" src={require("./images/move_icon.png")} />,

  image1: <img width="150" height="auto" src={require("./images/0x0.jpg")} />,
  image2: <img width="150" height="auto" src={require("./images/Bio_Lede_Still-1720x968.jpg")} />,
  image3: (
    <img width="150" height="auto" src={require("./images/business-plan.jpg-900x505-1.jpg")} />
  ),
  image4: (
    <img width="150" height="auto" src={require("./images/Custom-Software-Development.jpg")} />
  ),
  image5: <img width="150" height="auto" src={require("./images/elements-of-art-6.jpg")} />,
  image6: (
    <img
      width="150"
      height="auto"
      src={require("./images/logistics-supply-chain-international-shipping.png")}
    />
  ),
  image7: (
    <img
      width="150"
      height="auto"
      src={require("./images/SIPMM-Impacting-Transportation-Logistics-in-Supply-Chain-Management.jpg")}
    />
  ),

  moveIcon: <img width="14" src={require("./images/move_icon.png")} />,
};
