import styled from "styled-components";

const SelectWrapperStyled = styled.div`
  margin-top: 20px;
  max-width: 335px;
  min-width: 335px;
`;

export const SelectWrapper = (props) => {
  return <SelectWrapperStyled>{props.children}</SelectWrapperStyled>;
};
