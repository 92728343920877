import React from "react";
import { Dialog } from "@mui/material";
import CircleLoader from "../CircleLoader/CircleLoader";

class DialogLoader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    if (!this.state.revenue || this.state.loading) {
      return (
        <Dialog
          open={true}
          scroll={"paper"}
          maxWidth={"md"}
          sx={{
            zIndex: 1000,
            ".MuiBackdrop-root": {
              backgroundColor: `rgb(255 255 255 / 90%)`,
            },
            ".MuiDialog-paper": {
              boxShadow: "none",
              overflowY: "visible",
            },
          }}
          PaperProps={{
            sx: {
              width: "686px",
              // position: 'absolute',
              backgroundColor: "#F5F5F5",
              // bottom: -32
            },
          }}
        >
          <div style={{ display: "block" }} className="loader_chart_container">
            <CircleLoader />
          </div>
        </Dialog>
      );
    }
  }
}

export default DialogLoader;
