import React, { Component } from "react";
import styled from "styled-components";
import { DragDropContext, Droppable, Draggable, type } from "react-beautiful-dnd";
import { InputFormats } from "../constants/finance";

const portal = document.createElement("div");
portal.classList.add("my-super-cool-portal");
if (!document.body) {
  throw new Error("body not ready for portal creation!");
}

const Container = styled.div``;

const TableCellHeader = styled.div`
  min-width: 240px !important;
  outline: none !important;
  height: 40px;
  // border-right: 1px solid #F0F1F7;
  // border-bottom: 1px solid #F0F1F7;
  // border-top: 1px solid transparent;
  // border-left: 1px solid transparent;
  font-family: Inter;
  background: #e9eaf0;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 160%;
  color: #252525;
  text-align: left;
  display: flex;
  align-items: center;
  padding-left: 25px;
`;

const TableCell = styled.div`
  min-width: 104px !important;
  width: -webkit-fill-available;
  height: 40px !important;
  outline: none !important;
  // border-right: 1px solid #F0F1F7;
  // border-bottom: 1px solid #F0F1F7;
  // border-top: 1px solid transparent;
  // border-left: 1px solid transparent;
  font-family: Inter;
  background: #e9eaf0;
  text-align: right;
  justify-content: right;
  color: #252525;
  align-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 160%;
  text-align: left;
  display: flex;
  align-items: center;
`;

const TableCloneRow = styled.div`
  // width: 100%;
  display: flex;
  height: 40px;
  // overflow-x: hidden;
`;

const TableContainer = styled.div`
  display: table !important;
  width: 100% !important;
  // height: 100%;
`;

const TableRowContainer = styled.div``;

class MainTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.Dates = [...global.Modeliks.DateHelper.months, ...global.Modeliks.DateHelper.years_all];
    this.DatesCustomKPI = [
      ...global.Modeliks.DateHelper.months_comaprative,
      ...global.Modeliks.DateHelper.months_quarterly_view,
    ];
  }

  getRenderItem = (items, className) => (provided, snapshot, rubric) => {
    const item = items[rubric.source.index];
    const children = this.getChildValues(item);
    const values = this.getValues(item);
    return this.createCloneElement(item, values, children, rubric.source.index, provided, snapshot);
  };

  getValues = (row) => {
    if (this.props.useCustomKPIDates) {
      return this.DatesCustomKPI.map((value) => (
        <TableCell
          key={value.sufix}
        >{`$ ${row.data.getItemByDateSufix(value.sufix).Value ? InputFormats.formatNumber(row.data.getItemByDateSufix(value.sufix).Value) : ""}`}</TableCell>
      ));
    }

    return this.Dates.map((value) => (
      <TableCell
        key={value.sufix}
      >{`$ ${row.data.getItemByDateSufix(value.sufix).Value ? InputFormats.formatNumber(row.data.getItemByDateSufix(value.sufix).Value) : ""}`}</TableCell>
    ));
  };

  getChildValues = (row) => {
    return null;
    // if(row.children && row.children.length > 0){
    //     return row.children.map(child => <TableCloneRow><TableCellHeader>{child.header}</TableCellHeader>{this.getValues(child)}</TableCloneRow>)
    // }
  };

  createCloneElement = (row, values, children, index, provided, snapshot, parent = true) => {
    let newVal = values;
    let newChildren = children;
    if (!newVal) {
      return null;
    }

    return (
      <TableContainer
        key={index + "_clone"}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <TableCloneRow>
          <TableCellHeader>{row.header}</TableCellHeader>
          {newVal}
        </TableCloneRow>
        {newChildren}
      </TableContainer>
    );
  };

  elementRow = (row, index, provided, snapshot) => {
    return (
      <TableRowContainer
        key={index}
        className="MuiTableRow-root mct_ft_tablerow"
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        {this.props.Row(this.props.getRefID(row), row, index, 0, null, null, provided)}
      </TableRowContainer>
    );
  };

  render() {
    return (
      <Droppable
        droppableId="MainTableBody"
        renderClone={this.getRenderItem(this.props.data, "copy")}
        isDropDisabled={false}
      >
        {(provided, snapshot) => (
          <Container className="MuiTableBody-root mct_ft_tablebody" ref={provided.innerRef}>
            {this.props.data.map((row, index) => {
              return (
                <Draggable
                  isDragDisabled={false}
                  isClone={false}
                  draggableId={index.toString()}
                  index={index}
                  key={index.toString()}
                >
                  {(draggableProvided, draggableSnapshot) =>
                    this.elementRow(row, index, draggableProvided, draggableSnapshot)
                  }
                </Draggable>
              );
            })}
            {provided.placeholder}
          </Container>
        )}
      </Droppable>
    );
  }
}

export default class PortalApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.rows,
    };
  }

  reorder = (list, startIndex, endIndex) => {
    const [removed] = list.splice(startIndex, 1);
    list.splice(endIndex, 0, removed);
    return list;
  };

  onDragEnd = (result) => {
    // dropped outside the list
    const { source, destination } = result;

    if (!destination || result.destination.index === result.source.index) {
      return;
    }

    // no movement
    if (destination.index === source.index) {
      return;
    }
    const data = this.reorder(this.state.data, source.index, destination.index);

    this.props.onDragEnd(data);
    // this.setState({data,});
  };

  render() {
    return (
      <DragDropContext
        onBeforeCapture={() => this.props.onDragStart}
        {...this.props}
        onDragEnd={this.onDragEnd}
      >
        <MainTable {...this.props} data={this.props.rows} />
      </DragDropContext>
    );
  }
}
