import React, { Fragment, useState } from "react";
import "../HeaderTextEditor.scss";
import "../HeaderDefault.scss";
import { BorderColor } from "../../components/IconButtons/SubMenuIcons";
import { SketchPicker } from "react-color";
import { Menu, ControlledMenu, MenuItem, SubMenu } from "@szhsin/react-menu";
import Tooltip from "@mui/material/Tooltip";
import ButtonMui from "../../../../components/buttons/buttonMui/buttonMui";

function StrokeColorEditComponent(props) {
  const onChange = (style) => {
    props.onChange(style);
  };
  const rgbToRgba = (event) => {
    return "rgba(" + event.rgb.r + "," + event.rgb.g + "," + event.rgb.b + "," + event.rgb.a + ")";
  };
  const StrokeColorEdit = () => {
    return (
      <Menu
        className={"color_menu"}
        menuButton={({ open }) => (
          <ButtonMui
            isIconButton
            tooltipText={"Border Color"}
            className={""}
            variant={"text"}
            roundnessType={"squared"}
            color={"black"}
            label={
              <BorderColor
                active={open}
                activeColor={
                  !props.style ||
                  !props.style.backgroundColor ||
                  props.style.backgroundColor === undefined
                    ? "#585858"
                    : props.style.backgroundColor
                }
              />
            }
          />
        )}
      >
        <SketchPicker
          color={props.style.backgroundColor}
          onChange={(event) => onChange({ backgroundColor: rgbToRgba(event) })}
        />
      </Menu>
    );
  };

  return <>{StrokeColorEdit()}</>;
}

export default StrokeColorEditComponent;
