import React from "react";
import {
  ArrowDown,
  ArrowUp,
  IconI,
  IconNotification,
  IconX,
} from "../../../../components/icons/svgIcons";
import { IconButton } from "@mui/material";
import "./Footer/Footer.scss";
import { Vector } from "../../../../components/dividers/Vector";
import Mx_Tooltip from "../../../../components/icons/Mx_Tooltip_Click";

class ShowHideTitle extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  getActualTitle = () => {
    return (
      <Mx_Tooltip
        description={
          <>
            Actuals are displayed to help you derive forecast assumptions and check for
            plausibility. Actuals can only be inputted from the <b>Dashboards - Actuals</b> module.
            Please note that, depending on your input, actuals will be presented here either on a
            monthly and/or annual basis.
          </>
        }
      />
    );
  };

  getHoverTitle = () => {
    return <Mx_Tooltip description={<>{this.props.hoverMessage}</>} />;
  };

  render() {
    if (this.props.button) {
      return (
        <>
          <div className="spsffcf_collapse_div">
            <span className="spsffcf_title">{this.props.title}</span>
            {this.props.showActualTitle && this.getActualTitle()}
            {this.props.showIconHover && this.getHoverTitle()}
            <IconButton onClick={this.props.onChange} sx={{ color: "#A9A9A9" }} size="medium">
              <ArrowUp color={"#A9A9A9"} width="14" height="14" />
            </IconButton>
          </div>
        </>
      );
    } else {
      return (
        <div className="sccc_chart_menu">
          <div className="sccc_chart_icon">
            <span className="spsffcf_title">{this.props.title}</span>
            {this.props.showActualTitle && this.getActualTitle()}
            {this.props.showIconHover && this.getHoverTitle()}
            <IconButton onClick={this.props.onChange} sx={{ color: "#A9A9A9" }} size="medium">
              <ArrowDown width="14" height="14" />
            </IconButton>
          </div>
          <div className="sccc_chart_divider">
            <Vector color="#A9A9A9" />
          </div>
        </div>
      );
    }
  }
}

export default ShowHideTitle;
