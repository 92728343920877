import React from "react";
import { Dialog, DialogActions } from "@mui/material";
import PropTypes from "prop-types";
import "./info_dialog.scss";

class InfoDialog extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <Dialog
          open={this.props.open}
          onClose={this.props.onClose}
          scroll="paper"
          maxWidth={"md"}
          sx={{
            zIndex: 1000,
            ".MuiBackdrop-root": {
              backgroundColor: `rgb(255 255 255 / 90%)`,
            },
            "& .MuiDialog-container": {
              justifyContent: "center",
              alignItems: "center",
            },
          }}
          PaperProps={{
            sx: {
              minWidth: "100%",
              minHeight: "100%",
              paddingLeft: "15px",
              borderRadius: "12px",
              backgroundColor: `rgb(255 255 255 / 0%)`,
            },
          }}
        >
          <div className="scdi_info_dialog_container">
            <div className="scdi_info_dialog_div">{this.props.children}</div>
          </div>
        </Dialog>
      </>
    );
  }
}

export default InfoDialog;

InfoDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onNextClick: PropTypes.func,
  onPreviousClick: PropTypes.func,
};
