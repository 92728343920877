import React from "react";
import { Menu, MenuItem, SubMenu, FocusableItem } from "@szhsin/react-menu";
import { useMatch, useResolvedPath } from "react-router-dom/dist/index";
import Button from "@mui/material/Button";
import "./reusableMenu.scss";
import PropTypes from "prop-types";

const GetMatch = () => {
  const match = useMatch({ path: useResolvedPath(window.location.pathname).pathname, end: true });
  if (match) {
    return match;
  }
  return { pathname: "" };
};

function ReusableMenu(props) {
  return (
    <Menu arrow={props.arrow} className={"reusable_menu"} menuButton={props.menuButton}>
      {props.menuItems &&
        props.menuItems.map((c, index) => getMenuItem(c, index)).filter((c) => !!c)}
      {props.menuElementItems && props.menuElementItems}
    </Menu>
  );
}

function getMenuItem(item, index) {
  if (item) {
    let MenuItemEl = MenuItem;

    if (item.focusable) {
      MenuItemEl = FocusableItem;
    }

    if (item.children && item.children.length) {
      return (
        <SubMenu key={index} label={item.label}>
          {item.children.map((c, index) => getMenuItem(c, index + "item_child"))}
        </SubMenu>
      );
    }
    if (item.component) {
      return (
        <MenuItemEl
          disabled={item.disabled}
          key={index + "item_comp"}
          className={`link ${GetMatch().pathname == item.to && "selected"}`}
        >
          <Button disableRipple sx={{ color: "#979797" }} component={item.component} to={item.to}>
            {item.label}
          </Button>
        </MenuItemEl>
      );
    }
    return (
      <MenuItemEl
        disabled={item.disabled}
        key={index + "item"}
        className={item.selected ? "selected" : ""}
        onClick={item.onClick && item.onClick}
      >
        {item.label}
      </MenuItemEl>
    );
  }
  return null;
}

ReusableMenu.propTypes = {
  menuItems: PropTypes.array,
  menuElementItems: PropTypes.any,
  menuButton: PropTypes.element,
};

class ReusableMenuItem {
  label = "";
  onClick = null;
  selected = false;
  children = [];
  component = null;
  to = null;
  disabled = null;
  focusable = false;

  constructor(
    label,
    onClick,
    selected = false,
    children = [],
    component = null,
    to = null,
    disabled = false,
    focusable = false,
  ) {
    this.label = label;
    this.onClick = onClick;
    this.selected = selected;
    this.children = children;
    this.component = component;
    this.to = to;
    this.disabled = disabled;
    this.focusable = focusable;
    if (this.component && this.to) {
      this.selected = (GetMatch() && GetMatch().pathname) == this.to;
    }
  }
}

export { ReusableMenu, ReusableMenuItem };
