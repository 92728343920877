import * as React from "react";
import { Routes, Route, Outlet, Link } from "react-router-dom";
import { NavigationF } from "../../../components/containers/NavigationF";
import { Box } from "../../../components/containers/Box";
import { Vector } from "../../../components/dividers/Vector";
import SubTabs from "../../../components/tabs/SubTabs";
import request from "superagent";
import CircleLoader from "../../../components/Loaders/CircleLoader/CircleLoader";
import DashboardsBlankAdmin from "../../Admin/Dashboards/components/DashboardsBlankAdmin";
import ChartEditor from "../../Admin/ChartsEditor/ChartsEditor";

const layout_side = "/dashboards";

const dashboards_paths = {
  forecast_summary_annually: "/forecast_summary_annually",
  forecast_summary_monthly: "/forecast_summary_monthly",
  charts: "/charts",
  single_indicators: "/single_indicators",
  ratios: "/ratios",
  custom_dashboards: "/custom_dashboards",
  saas_dashboard_annually: "/saas_dashboard_annually",
  saas_dashboard_monthly: "/saas_dashboard_monthly",
};

export default class Dashboards extends React.Component {
  Dashboard = global.Modeliks.DashboardSection;
  constructor(props) {
    super(props);
    const ActualDates = require("../../Secure/Financials/Actuals/constants").ActualDates;

    this.state = {
      loading: false,
      chartEditor: false,
      chartEditorKey: "",
      months: ActualDates.allMonths,
      years: ActualDates.allYears,
      allYears: ActualDates.allYears,
      allMonths: ActualDates.allMonths,
    };

    this.curMonths = {
      startMonth: ActualDates.minDateFinancialStatements,
      endMonth: ActualDates.maxDate,
      startMonthOrder: global.Modeliks.DateHelper.months_before_actual[0].Order,
      endMonthOrder:
        global.Modeliks.DateHelper.months[global.Modeliks.DateHelper.months.length - 1].Order,
      startYear: ActualDates.minYearFinancialStatements,
      endYear: ActualDates.maxYearFinancialStatements,
      startYearOrder: ActualDates.minYearFinancialStatementsOrder,
      endYearOrder: global.Modeliks.DateHelper.years_before[0].Order,
      minDate: ActualDates.minDateFinancialStatements,
      maxDate: ActualDates.maxDate,
      minYear: ActualDates.minYearFinancialStatements,
      maxYear: ActualDates.maxYearFinancialStatements,
      showAllDrivers: false,
      showMonthly: true,
    };

    this.curCharts = {};
  }

  getDateRange = () => {
    return {
      dateFrom: {
        month: this.state.months.find((c) => c.Order == this.curMonths.startMonthOrder),
        year: this.state.years.find((c) => c.Order == this.curMonths.startYearOrder),
      },
      dateTo: {
        month: this.state.months.find((c) => c.Order == this.curMonths.endMonthOrder),
        year: this.state.years.find((c) => c.Order == this.curMonths.endYearOrder),
      },
    };
  };

  getCurrentCharts = () => {
    return this.curCharts.allCharts.filter((c) =>
      this.curCharts.selectedSubsection.charts.hasOwnProperty(c.ID),
    );
  };
  SaveDashboardSections = () => {
    request
      .put(`/api/master/settings`)
      .set("Access-Control-Allow-Origin", window.location.protocol + "//" + window.location.host)
      .set("Accept", "application/json")
      .query({ ID: this.Dashboard.ID })
      .send({ Value: JSON.stringify(this.Dashboard.Value) })
      .set("authorization", "Bearer " + window.localStorage.getItem("token"))
      .then((res, err) => {
        if (err) {
          console.error(err);
        }
      });
  };

  setChartEdit = (key) => {
    this.setState({ chartEditor: true, chartEditorKey: key });
  };
  handleChartDelete = (id) => {
    delete this.curCharts.selectedSubsection.charts[id];
    this.SaveDashboardSections();
  };

  setCurMonths = (key, value) => {
    this.curMonths[key] = value;
  };

  getDashboardPathElements = () => {
    const dashboardProps = {
      saveDashboard: this.SaveDashboardSections,
      data: this.Dashboard.Value.DashboardSections,
      curMonths: this.curMonths,
      onChartEdit: this.setChartEdit,
      curCharts: this.curCharts,
      setCurMonths: this.setCurMonths,
    };

    return {
      routes: [
        {
          path: dashboards_paths.forecast_summary_annually,
          element: (
            <DashboardsBlankAdmin
              dataKey={"ForecastSummary"}
              {...{
                ...dashboardProps,
                curMonths: { ...dashboardProps.curMonths, showMonthly: false },
              }}
              key={"fs_a_" + this.props.ctrl_key}
            />
          ),
        },
        {
          path: dashboards_paths.forecast_summary_monthly,
          element: (
            <DashboardsBlankAdmin
              dataKey={"ForecastSummaryMonthly"}
              {...dashboardProps}
              key={"fs_m_" + this.props.ctrl_key}
            />
          ),
        },
        {
          path: dashboards_paths.charts,
          element: (
            <DashboardsBlankAdmin
              dataKey={"Charts"}
              {...dashboardProps}
              key={"cc_" + this.props.ctrl_key}
            />
          ),
        },
        {
          path: dashboards_paths.single_indicators,
          element: (
            <DashboardsBlankAdmin
              dataKey={"SingleIndicators"}
              {...dashboardProps}
              key={"sic_" + this.props.ctrl_key}
            />
          ),
        },
        {
          path: dashboards_paths.ratios,
          element: (
            <DashboardsBlankAdmin
              dataKey={"Ratios"}
              {...dashboardProps}
              key={"rc_" + this.props.ctrl_key}
            />
          ),
        },
        {
          path: dashboards_paths.saas_dashboard_annually,
          element: (
            <DashboardsBlankAdmin
              dataKey={"SaasDashboard"}
              {...{
                ...dashboardProps,
                curMonths: { ...dashboardProps.curMonths, showMonthly: false },
              }}
              key={"saas_" + this.props.ctrl_key}
            />
          ),
        },
        {
          path: dashboards_paths.saas_dashboard_monthly,
          element: (
            <DashboardsBlankAdmin
              dataKey={"SaasDashboardMonthly"}
              {...dashboardProps}
              key={"saas_m_" + this.props.ctrl_key}
            />
          ),
        },
      ],
      tabs: [
        {
          link: `${layout_side}${dashboards_paths.forecast_summary_annually}`,
          color: "black",
          size: "p3",
          label: "Summary Annually",
        },
        {
          link: `${layout_side}${dashboards_paths.forecast_summary_monthly}`,
          color: "black",
          size: "p3",
          label: "Summary Monthly",
        },
        {
          link: `${layout_side}${dashboards_paths.charts}`,
          color: "black",
          size: "p3",
          label: "Charts",
        },
        {
          link: `${layout_side}${dashboards_paths.single_indicators}`,
          color: "black",
          size: "p3",
          label: "Single Indicators",
        },
        {
          link: `${layout_side}${dashboards_paths.ratios}`,
          color: "black",
          size: "p3",
          label: "Ratios",
        },
        {
          link: `${layout_side}${dashboards_paths.saas_dashboard_annually}`,
          color: "black",
          size: "p3",
          label: "Saas Dashboard Annually",
        },
        {
          link: `${layout_side}${dashboards_paths.saas_dashboard_monthly}`,
          color: "black",
          size: "p3",
          label: "Saas Dashboard Monthly",
        },
      ],
    };
  };
  getRenderedDashboardRoutes = () => {
    return this.getDashboardPathElements().routes.map((route) => (
      <Route key={`f_route_${route.path}`} exact path={route.path} element={route.element} />
    ));
  };

  render() {
    if (this.state.loading) {
      return (
        <div className={"main_circle_loader_container"}>
          <CircleLoader />
        </div>
      );
    }

    if (this.state.chartEditor) {
      return (
        <ChartEditor
          showIndicatorBoxes={this.state.chartEditorKey === "SingleIndicators"}
          goBack={() => this.setState({ chartEditor: false })}
          months={this.state.months}
          years={this.state.years}
          dateRange={this.getDateRange()}
          onChartDelete={this.handleChartDelete}
          onChartInsert={() => this.SaveDashboardSections()}
          charts={this.getCurrentCharts()}
          chartSettings={this.curCharts.selectedSubsection.charts}
        />
      );
    }

    return (
      <Routes>
        <Route
          path="/"
          element={<DashboardsLayout dashboardsElements={this.getDashboardPathElements()} />}
        >
          {this.getRenderedDashboardRoutes()}
        </Route>
      </Routes>
    );
  }
}

function DashboardsLayout(props) {
  return (
    <React.Fragment>
      <NavigationF hidden={props.loading}>
        <Box className="spsi_financials_navigation_streams_div">
          <SubTabs mainPath={`${layout_side}`}>{props.dashboardsElements}</SubTabs>
        </Box>
      </NavigationF>
      <Vector />
      <Outlet />
    </React.Fragment>
  );
}
