import React from "react";
import {
  EditIcon,
  Tick,
} from "../../../components/components/menus/LeftMenu/components/Icons/LeftMenuIcons";
import { IconButton } from "@mui/material";
import { Exit } from "../../../components/components/IconButtons/SubMenuIcons";

function DraggableItem(props) {
  const [edit, setEdit] = React.useState(false);
  const [name, setName] = React.useState(props.name);

  React.useEffect(() => {
    if (props.name !== name) {
      setName(props.name);
    }
  }, [props.name]);

  const ItemName = (
    <div className="driver_grid_item">
      <span className={"Paragraph_P3"}>{name}</span>
      <div className={"btns_container"}>
        {props.edit && (
          <IconButton
            onClick={() => setEdit(!edit)}
            onMouseDown={(e) => {
              e.stopPropagation();
            }}
            size={"small"}
          >
            <EditIcon />
          </IconButton>
        )}
        <IconButton
          onClick={props.onDelete}
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
          size={"small"}
        >
          <Exit />
        </IconButton>
      </div>
    </div>
  );

  const saveEdit = () => {
    if (props.name !== name) {
      props.changeName && props.changeName(name);
    }
    setEdit(!edit);
  };

  const ItemNameInput = (
    <div className="driver_grid_item">
      <input
        onMouseDown={(e) => e.stopPropagation()}
        className={"Paragraph_P3"}
        style={{ outline: "none" }}
        type={"text"}
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <div>
        <IconButton
          onClick={() => saveEdit()}
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
          size={"small"}
        >
          <Tick />
        </IconButton>
      </div>
    </div>
  );

  if (edit) {
    return ItemNameInput;
  }
  return ItemName;
}

export default DraggableItem;
