import React, { Fragment, useState } from "react";
// import RightMenuPortal from "./RightMenuPortal.js";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import InputBox from "../InputBoxComponent/InputBox";
import { FlipHorizontally, FlipVertically, Rotate90 } from "../IconButtons/SubMenuIcons";
import { FontSizeOptions } from "../../SlideObjects/ChartComponents/FontSizeOptions";
import MaterialSelect from "../Select/MaterialSelect";

function TextFitting(props) {
  const [openTextFitting, setOpenTextFitting] = React.useState(false);
  const [wrap, setWrap] = React.useState(true);

  const handleClickTextFitting = () => {
    if (openTextFitting) {
      setOpenTextFitting(false);
    } else {
      setOpenTextFitting(true);
      props.rightMenuFunc.closeTextFitting = null;
      Object.values(props.rightMenuFunc).map((value) => {
        if (typeof value === "function") {
          value.call();
        }
      });
    }
  };

  const textRotationOptions = [
    { value: "horizontal-tb", name: "Horizontal" },
    { value: "vertical-lr", name: "Rotate all text 90°" },
    { value: "vertical-rl", name: "Rotate all text 270°" },
    { value: "stacked", name: "Stacked" },
  ];

  const handleMarginTopChange = (value) => {
    props.onChange({ paddingTop: value }, () => {
      if (props.objType === "table") {
        props.getRealRowHeights();
      }
    });
  };
  const handleMarginBottomChange = (value) => {
    props.onChange({ paddingBottom: value }, () => {
      if (props.objType === "table") {
        props.getRealRowHeights();
      }
    });
  };
  const handleMarginLeftChange = (value) => {
    if (props.objType && (props.objType === "table" || props.objType === "textObject")) {
      value = value.replace("px", "");
      if (value >= 0 && value <= 90) {
        props.onChange({ paddingLeft: value + "%" }, () => {
          if (props.objType === "table") {
            props.getRealRowHeights();
          }
        });
      }
    } else {
      props.onChange({ paddingLeft: value });
    }
  };
  const handleMarginRightChange = (value) => {
    if (props.objType && (props.objType === "table" || props.objType === "textObject")) {
      value = value.replace("px", "");
      if (value >= 0 && value <= 90) {
        props.onChange({ paddingRight: value + "%" }, () => {
          if (props.objType === "table") {
            props.getRealRowHeights();
          }
        });
      }
    } else {
      props.onChange({ paddingRight: value });
    }
  };
  const handleWrap = (e) => {
    if (e.target.checked) {
      setWrap(true);
      props.onChange({ whiteSpace: "pre-wrap" });
    } else {
      setWrap(false);
      props.onChange({ whiteSpace: "nowrap" });
    }
  };

  const handleTextRotationChange = (e) => {
    props.changeRoatation(e.target.value);
  };

  const closeMenu = () => {
    setOpenTextFitting(false);
  };

  props.rightMenuFunc.closeTextFitting = closeMenu;

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      onKeyDown={(e) => {
        e.stopPropagation();
      }}
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
    >
      <ListItemButton id={"listItem"} onClick={handleClickTextFitting}>
        <ListItemText primary="Text fitting" />
        {openTextFitting ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <div style={{ width: "230px", border: "1px solid #F0F1F7", marginLeft: "18px" }} />
      <Collapse in={openTextFitting} timeout="auto" unmountOnExit>
        <div className={"collapse"}>
          {!props.DontshowIndentationMenu && (
            <div>
              <div className={"listSubHeading"}>Indentation</div>
              <div className={"listRow"}>
                <div className={"listRowElement"}>Left</div>
                <div className={"listRowElement"}>Right</div>
              </div>
              <div className={"listRow"}>
                <div className={"listRowElement"}>
                  <InputBox
                    onChange={(e) => handleMarginLeftChange(Number(e.target.value) + "px")}
                    value={
                      props.style.paddingLeft
                        ? parseFloat(props.style.paddingLeft.replace("px", ""))
                        : 0
                    }
                    onUpClick={() =>
                      handleMarginLeftChange(
                        (props.style.paddingLeft
                          ? parseFloat(props.style.paddingLeft.replace("px", ""))
                          : 0) +
                          1 +
                          "px",
                      )
                    }
                    onDownClick={() =>
                      props.style.paddingLeft &&
                      parseFloat(props.style.paddingLeft.replace("px", "")) !== 0 &&
                      handleMarginLeftChange(
                        (props.style.paddingLeft
                          ? parseFloat(props.style.paddingLeft.replace("px", ""))
                          : 0) -
                          1 +
                          "px",
                      )
                    }
                  />
                </div>
                <div className={"listRowElement"}>
                  <InputBox
                    onChange={(e) => handleMarginRightChange(Number(e.target.value) + "px")}
                    value={
                      props.style.paddingRight
                        ? parseFloat(props.style.paddingRight.replace("px", ""))
                        : 0
                    }
                    onUpClick={() =>
                      handleMarginRightChange(
                        (props.style.paddingRight
                          ? parseFloat(props.style.paddingRight.replace("px", ""))
                          : 0) +
                          1 +
                          "px",
                      )
                    }
                    onDownClick={() =>
                      props.style.paddingRight &&
                      parseFloat(props.style.paddingRight.replace("px", "")) !== 0 &&
                      handleMarginRightChange(
                        (props.style.paddingRight
                          ? parseFloat(props.style.paddingRight.replace("px", ""))
                          : 0) -
                          1 +
                          "px",
                      )
                    }
                  />
                </div>
              </div>
            </div>
          )}
          {props.showTextDirectionMenu && (
            <div>
              <div className={"listSubHeading"}>Text Box</div>
              <div className={"listRow"}>
                <label className={"listSubHeading"}>Text direction: </label>
                <MaterialSelect
                  value={props.textRotation}
                  options={textRotationOptions}
                  onChange={handleTextRotationChange}
                />
              </div>
            </div>
          )}
          <div className={"listSubHeading"}>Margins</div>
          <div className={"listRow"}>
            <div className={"listRowElement"}>Top</div>
            <div className={"listRowElement"}>Bottom</div>
          </div>
          <div className={"listRow"}>
            <div className={"listRowElement"}>
              <InputBox
                onChange={(e) => handleMarginTopChange(Number(e.target.value) + "px")}
                value={
                  props.style.paddingTop ? parseFloat(props.style.paddingTop.replace("px", "")) : 0
                }
                onUpClick={() =>
                  handleMarginTopChange(
                    (props.style.paddingTop
                      ? parseFloat(props.style.paddingTop.replace("px", ""))
                      : 0) +
                      1 +
                      "px",
                  )
                }
                onDownClick={() =>
                  props.style.paddingTop &&
                  parseFloat(props.style.paddingTop.replace("px", "")) !== 0 &&
                  handleMarginTopChange(
                    (props.style.paddingTop
                      ? parseFloat(props.style.paddingTop.replace("px", ""))
                      : 0) -
                      1 +
                      "px",
                  )
                }
              />
            </div>
            <div className={"listRowElement"}>
              <InputBox
                onChange={(e) => handleMarginBottomChange(Number(e.target.value) + "px")}
                value={
                  props.style.paddingBottom
                    ? parseFloat(props.style.paddingBottom.replace("px", ""))
                    : 0
                }
                onUpClick={() =>
                  handleMarginBottomChange(
                    (props.style.paddingBottom
                      ? parseFloat(props.style.paddingBottom.replace("px", ""))
                      : 0) +
                      1 +
                      "px",
                  )
                }
                onDownClick={() =>
                  props.style.paddingBottom &&
                  parseFloat(props.style.paddingBottom.replace("px", "")) !== 0 &&
                  handleMarginBottomChange(
                    (parseFloat(props.style.paddingBottom.replace("px", ""))
                      ? parseFloat(props.style.paddingBottom.replace("px", ""))
                      : 0) -
                      1 +
                      "px",
                  )
                }
              />
            </div>
          </div>
          <div className={"listRow"}>
            <div className={"listRowElement"}>Left</div>
            <div className={"listRowElement"}>Right</div>
          </div>
          <div className={"listRow"}>
            <div className={"listRowElement"}>
              <InputBox
                onChange={(e) => handleMarginLeftChange(Number(e.target.value) + "px")}
                value={
                  props.style.paddingLeft
                    ? parseFloat(props.style.paddingLeft.replace("px", ""))
                    : 0
                }
                onUpClick={() =>
                  handleMarginLeftChange(
                    (props.style.paddingLeft
                      ? parseFloat(props.style.paddingLeft.replace("px", ""))
                      : 0) +
                      1 +
                      "px",
                  )
                }
                onDownClick={() =>
                  props.style.paddingLeft &&
                  parseFloat(props.style.paddingLeft.replace("px", "")) !== 0 &&
                  handleMarginLeftChange(
                    (props.style.paddingLeft
                      ? parseFloat(props.style.paddingLeft.replace("px", ""))
                      : 0) -
                      1 +
                      "px",
                  )
                }
              />
            </div>
            <div className={"listRowElement"}>
              <InputBox
                onChange={(e) => handleMarginRightChange(Number(e.target.value) + "px")}
                value={
                  props.style.paddingRight
                    ? parseFloat(props.style.paddingRight.replace("px", ""))
                    : 0
                }
                onUpClick={() =>
                  handleMarginRightChange(
                    (props.style.paddingRight
                      ? parseFloat(props.style.paddingRight.replace("px", ""))
                      : 0) +
                      1 +
                      "px",
                  )
                }
                onDownClick={() =>
                  props.style.paddingRight &&
                  parseFloat(props.style.paddingRight.replace("px", "")) !== 0 &&
                  handleMarginRightChange(
                    (props.style.paddingRight
                      ? parseFloat(props.style.paddingRight.replace("px", ""))
                      : 0) -
                      1 +
                      "px",
                  )
                }
              />
            </div>
          </div>
          <div className={"listRow"}>
            <input
              type="checkbox"
              style={{ margin: 0, marginRight: "4px" }}
              onChange={handleWrap}
              checked={wrap}
            />
            <div className={"listSubHeading light"}>Wrap text in shape</div>
          </div>
        </div>
      </Collapse>
    </div>
  );
}

export default TextFitting;
