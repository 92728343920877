import styled from "styled-components";

export default styled.div`
  position: absolute;
  display: flex;
  justify-content: center;

  .circle {
    display: none;
    width: 16px;
    height: 16px;
    background: #80d7fe;
    //border: px solid #FFFFFF !important;
    border-radius: 50%;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    z-index: 10;
  }

  &.selected.hover {
    .circle {
      display: block;
    }
  }
`;
