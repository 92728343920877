import React, { useEffect } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import "./ScenarioMenu.scss";
import { Paragraph } from "../../typography/paragraph/Paragraph";
import {
  ArrowDown,
  IconPlus,
  MoreHorizontal,
  SmallPlusIcon,
  TrashCan,
  InfoIcon,
  PlayIcon,
} from "../../icons/svgIcons";
import Button from "../../actions/Button";
import CircleCheckboxM from "../../actions/CircleCheckboxM";
import Input from "../../actions/Input";
import { AddDialogTitle } from "../../dialogs/financials/AddDialog/AddDialogTitle";
import { AddDialogContent } from "../../dialogs/financials/AddDialog/AddDialogContent";
import AddDialog from "../../dialogs/financials/AddDialog/AddDialog";
import MaterialSelect from "../../actions/SelectM";
import { DialogActions } from "@mui/material";
import { default as MuiButton } from "@mui/material/Button";
import CancelDialog from "../../dialogs/settings/CancelDialog";
import request from "superagent";
import { EditIcon } from "../../../SlideEditor/components/components/menus/LeftMenu/components/Icons/LeftMenuIcons";
import { IndustryTypes } from "../../constants/industries";
import PitchScenarioLimitDialog from "../../../SlideEditor/components/components/Dialog/PitchScenarioLimitDialog";
import Mx_MainLoader from "../../Loaders/MainLoader/Mx_MainLoader";
import ButtonMui from "../../buttons/buttonMui/buttonMui";
import { PERMISSIONS } from "../../../data/Permissions/Permissions";
import { DialogTypes } from "../../../data/Finance/constants";
import { ReusableMenu, ReusableMenuItem } from "../ReusableMenu/ReusableMenu";
import Mx_Tooltip_Hover from "../../icons/Mx_Tooltip_Hover";
import InstructionalPopup from "../../dialogs/instructionsPopup/instructionalPopup";
import { PermissionTypes } from "../../constants/permissions";

const ScenarioDesc = (
  <span>
    Use Scenarios to create several versions of your Forecast. For example, create 2 different
    Forecasts - best case and worst case - to compare the impact of changes in your assumptions.
    <p>
      The financial figures of the selected forecast scenario are globally applied to all other
      modules (Pitch, Business Plan and Dashboards).
    </p>
  </span>
);

export default function ScenarioMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [scenarios, setScenarios] = React.useState(global.Modeliks.CompanyScenarios);
  const [dialogOpen, setDialogOpen] = React.useState(null);
  const [editDialogOpen, setEditDialogOpen] = React.useState(null);
  const [newScenario, setNewScenario] = React.useState(false);
  const [duplicateScenario, setDuplicateScenario] = React.useState(false);
  const [scenarioName, setScenarioName] = React.useState("");
  const [scenarioForDeletion, setScenarioForDeletion] = React.useState(null);
  const [cancelDialog, setCancelDialog] = React.useState(null);
  const [openScenarioLimit, setOpenScenarioLimit] = React.useState(false);
  const [selectedScenarioForDuplicate, setSelectedScenarioForDuplicate] = React.useState(
    scenarios[0].ID,
  );
  const [selectedScenarioForEdit, setSelectedScenarioForEdit] = React.useState(null);
  const [scenarioNameError, setScenarioNameError] = React.useState("");
  const [editedScenarioName, setEditedScenarioName] = React.useState("");
  const [selectedIndustry, setSelectedIndustry] = React.useState(Object.keys(IndustryTypes)[0]);
  const [loader, setLoader] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = () => {
    if (dialogOpen) {
      setDialogOpen(false);
    } else {
      setDialogOpen(true);
    }
  };
  if (props.toggleMenuObj) {
    props.toggleMenuObj.toggleFunction = handleToggle;
  }
  const changeScenario = (ID) => {
    setLoader(true);

    if (props.companyScenario) {
      global.Modeliks.GetCompanyScenarios(() => {
        // changeScenario(ID)
        global.Modeliks.ChangeCompanyScenario(ID);
        props.onScenarioChange && props.onScenarioChange(ID);
      }, false);
      // global.Modeliks.ChangeCompanyScenario(ID);
      // props.onScenarioChange && props.onScenarioChange(ID);
    } else {
      global.Modeliks.PitchScenarioInfo = global.Modeliks.PitchScenarios.find((c) => c.ID == ID);
      props.onScenarioChange(() => {
        scenarios.push(global.Modeliks.PitchScenarioInfo);
      });
    }
  };

  const saveEditedScenario = () => {
    if (editedScenarioName && editedScenarioName.length > 0) {
      selectedScenarioForEdit.ScenarioName = editedScenarioName;
      selectedScenarioForEdit.Save((success, error) => {
        setSelectedScenarioForEdit(null);
        setEditedScenarioName("");
        setEditDialogOpen(false);
      });
    } else {
      setSelectedScenarioForEdit(null);
      setEditedScenarioName("");
      setEditDialogOpen(false);
    }
  };
  const addNewScenario = () => {
    if (validate()) {
      if (props.companyScenario) {
        setLoader(true);
        if (duplicateScenario || newScenario) {
          global.Modeliks.post(
            "companyscenarios",
            {
              ID_Company: global.Modeliks.CompanyInfo.ID,
              ScenarioName: scenarioName
                ? scenarioName
                : "Version" + global.Modeliks.CompanyScenarios.length,
            },
            (dataScenario) => {
              if (selectedIndustry) {
                request
                  .post("/api/insertFinancialData")
                  .query({
                    industry: duplicateScenario ? "Other" : selectedIndustry,
                    ID_CompanyScenario: dataScenario.id,
                    accountID: global.Modeliks.Account.ID,
                  })
                  .then(
                    (data) =>
                      global.Modeliks.GetCompanyScenarios(() =>
                        global.Modeliks.ChangeCompanyScenario(dataScenario.id),
                      ),
                    (err) => {
                      if (JSON.parse(err.response.text).error == "no data") {
                        setScenarioNameError("No data in selected industry");
                      }
                    },
                  );
              } else {
                global.Modeliks.GetCompanyScenarios(() =>
                  global.Modeliks.ChangeCompanyScenario(dataScenario.id),
                );
              }
            },
            (err) => {
              console.error("err", err);
            },
          );
        } else {
          request
            .post("/api/cloneScenario")
            .set("authorization", "Bearer " + window.localStorage.getItem("token"))
            .query({
              ID_CompanyScenario: selectedScenarioForDuplicate,
              ScenarioName: scenarioName
                ? scenarioName
                : "Scenario" + global.Modeliks.CompanyScenarios.length,
            })
            .then((data) =>
              global.Modeliks.GetCompanyScenarios(() =>
                global.Modeliks.ChangeCompanyScenario(data.body.id),
              ),
            );
        }
      } else {
        setDialogOpen(false);
        props.setSaving(true);
        if (global.Modeliks.PitchScenarios.length < 3) {
          if (newScenario) {
            global.Modeliks.post(
              "pitchscenarios",
              {
                Company_ID: global.Modeliks.CompanyInfo.ID,
                Name: scenarioName,
              },
              (pitchScenario) => {
                request
                  .post("/api/PitchScenarios")
                  .set("authorization", "Bearer " + window.localStorage.getItem("token"))
                  .query({
                    pitch_scenario_id: pitchScenario.id,
                    company_id: global.Modeliks.CompanyInfo.ID,
                  })
                  .then((data) => {
                    global.Modeliks.GetCompanyPitchScenarios(() => {
                      changeScenario(pitchScenario.id);
                    });
                  });
              },
              (err) => {
                props.setSaving(false);
                console.error("err", err);
              },
            );
          } else {
            request
              .post("/api/clonePitchScenario")
              .set("authorization", "Bearer " + window.localStorage.getItem("token"))
              .query({
                PitchScenario_ID: selectedScenarioForDuplicate,
                ScenarioName: scenarioName
                  ? scenarioName
                  : "Scenario" + global.Modeliks.PitchScenarios.length,
              })
              .then((data) => {
                global.Modeliks.GetCompanyPitchScenarios(() => {
                  changeScenario(data.body.id);
                });
              });
          }
        } else {
          props.setSaving(false);
          setOpenScenarioLimit(true);
        }
      }
    }
  };

  const onScenarioChange = (ID) => {
    if (props.companyScenario) {
      if (ID === global.Modeliks.CompanyScenarioInfo.ID) {
        handleClose();
        return 0;
      } else {
        // props.onScenarioChange(ID);
        global.Modeliks.ChangeCompanyScenario(ID);
      }
    } else {
      if (ID === global.Modeliks.PitchScenarioInfo.ID) {
        handleClose();
        return 0;
      } else {
        global.Modeliks.PitchScenarioInfo = props.Scenarios.find((el) => el.ID == ID);
        window.localStorage.setItem("PitchScenarioID", ID);
        props.onScenarioChange(handleClose);
      }
    }
  };

  const deleteScenario = (ID, scenario = null) => {
    if (props.companyScenario) {
      global.Modeliks.showDialog(
        <div>
          <p>
            Are you sure that you want to delete "
            {scenario.hasOwnProperty("ScenarioName") ? scenario.ScenarioName : ""}" Scenario?
          </p>
          <p>Please be aware that the deletion of the scenario is irrevocable.</p>
        </div>,
        DialogTypes.WarningActionTitle,
        null,
        () => {
          setLoader(true);
          if (ID != global.Modeliks.CompanyScenarioInfo.ID) {
            global.Modeliks.del(
              "companyscenarios",
              { ID: ID },
              (data) => {
                global.Modeliks.GetCompanyScenarios(() => {
                  global.Modeliks.ChangeCompanyScenario(global.Modeliks.CompanyScenarioInfo.ID);
                  handleClose();
                });
              },
              (err) => {
                console.error("err", err);
              },
            );
          } else {
            if (scenarios.length > 1) {
              if (scenarios[0].ID == ID) {
                global.Modeliks.del(
                  "companyscenarios",
                  { ID: ID },
                  (data) => {
                    global.Modeliks.GetCompanyScenarios(() => {
                      global.Modeliks.ChangeCompanyScenario(scenarios[1].ID);
                      handleClose();
                    });
                  },
                  (err) => {
                    console.error("err", err);
                  },
                );
              } else {
                global.Modeliks.del(
                  "companyscenarios",
                  { ID: ID },
                  (data) => {
                    global.Modeliks.GetCompanyScenarios(() => {
                      global.Modeliks.ChangeCompanyScenario(scenarios[0].ID);
                      handleClose();
                    });
                  },
                  (err) => {
                    console.error("err", err);
                  },
                );
              }
            }
          }
        },
        "Delete Scenario",
      );
    } else {
      setScenarioForDeletion(scenario);
      setCancelDialog(true);
    }
  };

  const handleDeletePitchScenario = (callBack) => {
    if (props.Scenarios.length > 1) {
      if (scenarios[0].ID == scenarioForDeletion.ID) {
        onScenarioChange(scenarios[1].ID);
        global.Modeliks.del(
          "pitchscenarios",
          { ID: scenarioForDeletion.ID },
          (data) => {
            global.Modeliks.GetCompanyPitchScenarios();
            callBack();
          },
          (err) => {
            console.error("err", err);
          },
        );
      } else {
        onScenarioChange(scenarios[0].ID);
        global.Modeliks.del(
          "pitchscenarios",
          {
            ID: scenarioForDeletion.ID,
            Company_ID: global.Modeliks.CompanyInfo.ID,
          },
          (data) => {
            props.Scenarios.filter((el) => el.ID !== scenarioForDeletion.ID);
            setScenarios(scenarios.filter((el) => el.ID !== scenarioForDeletion.ID));

            global.Modeliks.GetCompanyPitchScenarios();
            callBack();
          },
          (err) => {
            console.error("err", err);
          },
        );
      }
    }
  };

  const validate = () => {
    if (scenarioName) {
      return true;
    } else {
      setScenarioNameError("please type a version name");
      return false;
    }
  };

  const onAddNewScenario = () => {
    let limit =
      global.Modeliks.CompanyScenarios.length >=
      PERMISSIONS.Financials.restrictions.MaxStreamsCount.TotalScenarios;
    if (limit) {
      global.Modeliks.showDialog(
        `
            You have reached the maximum number of ${PERMISSIONS.Financials.restrictions.MaxStreamsCount.TotalScenarios} Scenarios. 
            Please reorganize your Scenarios. \n Click outside of the box to continue with your work.`,
        DialogTypes.WarningSimpleText,
        () => {
          handleClose();
        },
      );
    } else {
      handleClose();
      setDialogOpen(true);
    }
  };

  const checkIfSelectedScenario = () => {
    if (props.companyScenario) {
    } else {
    }
  };

  const getIndustryOptions = () => {
    let arr = [];

    Object.keys(IndustryTypes).forEach((industry) => {
      if (industry !== "Other") {
        arr.push({
          value: industry,
          description: industry,
        });
      }
    });
    return arr;
  };

  const enableScenarioEdit = (scenario, e) => {
    if (e) {
      e.stopPropagation();
    }
    setAnchorEl(null);
    setSelectedScenarioForEdit(scenario);
    setEditedScenarioName(props.companyScenario ? scenario.ScenarioName : scenario.Name);
    setEditDialogOpen(true);
  };

  const changeSelected = (isNewScenario = true) => {
    setNewScenario(isNewScenario);
    if (duplicateScenario) {
      setDuplicateScenario(false);
    }
  };

  const disabled = global.Modeliks.UserPermissions[PermissionTypes.Financials] == 0;
  return (
    <>
      {props.companyScenario && !props.pitchHeader && !props.toggleMenuObj && (
        <>
          <ButtonMui
            width={180}
            label={
              scenarios.length > 1 ? global.Modeliks.CompanyScenarioInfo.ScenarioName : "Scenario"
            }
            roundnessType={"squared"}
            endIcon={scenarios.length > 1 ? <ArrowDown /> : <IconPlus />}
            onClick={handleClick}
            variant={"contained"}
          />
          {/*<Paragraph className={props.className}  color={'black'} size='p2' label={'Scenarios'} onClick={handleClick}/>*/}
          {/*<MenuItem  disabled={props.disabled} onClick={handleClick}>{global.Modeliks.CompanyScenarioInfo.ScenarioName}</MenuItem>*/}
        </>
      )}

      {props.companyScenario && !props.pitchHeader && props.toggleMenuObj && (
          <IconButton  onClick={handleClick}>
              <div className="svg_container">
                  <MoreHorizontal />
              </div>
          </IconButton>
      )}

      {loader && <Mx_MainLoader />}

      {props.companyScenario && props.pitchHeader && (
        <MuiButton
          className={"scenario_menu_button"}
          disabled={props.disabled}
          onClick={handleClick}
        >
          {global.Modeliks.CompanyScenarioInfo.ScenarioName}
        </MuiButton>
      )}

      {props.pitchScenario && (
        <>
          <MuiButton
            disabled={props.disabled}
            className={`scenario_menu_button `}
            onClick={handleClick}
          >
            {props.companyScenario ? "scenarios" : props.selectedScenario.Name}
          </MuiButton>
        </>
      )}

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className={`scenario_menu `}
        PaperProps={{ className: "menu_paper" }}
      >
        {scenarios.map((scenario, index) => {
          if (index === 0) {
            return (
              <MenuItem
                key={"sm_" + scenario.ID}
                className={
                  (
                    props.companyScenario
                      ? scenario.ID == global.Modeliks.CompanyScenarioInfo.ID
                      : scenario.ID == global.Modeliks.PitchScenarioInfo.ID
                  )
                    ? "menu_item first selected"
                    : "menu_item first"
                }
              >
                <span className="scenario_name" onClick={() => onScenarioChange(scenario.ID)}>
                  {scenario.ScenarioName ? scenario.ScenarioName : scenario.Name}
                </span>
                {!disabled && (
                  <>
                    <IconButton
                      className={"edit_icon"}
                      onClick={() => enableScenarioEdit(scenario)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      disabled
                      className={"trash_can"}
                      onClick={(e) => e.preventDefault()}
                    >
                      <TrashCan />
                    </IconButton>
                  </>
                )}
              </MenuItem>
            );
          } else {
            return (
              <MenuItem
                key={"sm_" + scenario.ID}
                className={
                  (
                    props.companyScenario
                      ? scenario.ID == global.Modeliks.CompanyScenarioInfo.ID
                      : scenario.ID == global.Modeliks.PitchScenarioInfo.ID
                  )
                    ? "menu_item selected"
                    : "menu_item"
                }
                children={scenario.ScenarioName}
              >
                <span className="scenario_name" onClick={() => onScenarioChange(scenario.ID)}>
                  {scenario.ScenarioName ? scenario.ScenarioName : scenario.Name}
                </span>
                {!disabled && (
                  <>
                    <IconButton
                      className={"edit_icon"}
                      onClick={() => enableScenarioEdit(scenario)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      className={"trash_can"}
                      onClick={() => deleteScenario(scenario.ID, scenario)}
                    >
                      <TrashCan />
                    </IconButton>
                  </>
                )}
              </MenuItem>
            );
          }
        })}
        {!props.pitchHeader && (
          <MenuItem
            disabled={props.companyScenario ? disabled : false}
            onClick={() => onAddNewScenario()}
            className="add_new_scenario_button"
          >
            {props.companyScenario ? "Add Scenario" : "Add New Pitch Scenario"}
            <IconButton>
              <SmallPlusIcon color="#01AFFD" />
            </IconButton>
          </MenuItem>
        )}

        {props.companyScenario && (
          <>
            <p className="scenario_divider" />

            <Mx_Tooltip_Hover
              onClick={() => {}}
              placement={"left"}
              title={ScenarioDesc}
              icon={
                <span className="scenario_instruction">
                  <span className="scenario_instruction_title">Instructions</span>
                  <InfoIcon color="#0080BF" />{" "}
                </span>
              }
            />

            <InstructionalPopup
              route="forecast/scenarios"
              actionButton={
                <MenuItem className="scenario_videos">
                  <span className="scenario_instruction_title">Videos</span>
                  <PlayIcon color="#0080BF" />
                </MenuItem>
              }
              data_key={"video_links"}
            />
          </>
        )}
      </Menu>

      {dialogOpen && (
        <AddDialog onClose={() => setDialogOpen(false)} open={true}>
          <AddDialogTitle title="Add New Scenario" onClose={() => setDialogOpen(false)} />
          <AddDialogContent>
            <div className="scenario_add_content">
              <div className="sad_heading">Name of Scenario</div>
              <div className="sad_input">
                <Input
                  placeholder="Enter name..."
                  value={scenarioName}
                  error={!!scenarioNameError}
                  max={18}
                  errorMessage={scenarioNameError}
                  onChange={(event) => setScenarioName(event.target.value)}
                />
              </div>
              <div className="sad_heading">Please select one of the following</div>
              <div className="scmsc_scenario_inputs_div">
                <div className="scmsc_inputs_div">
                  <CircleCheckboxM
                    checked={!newScenario && !duplicateScenario}
                    onChange={() => changeSelected(false)}
                    size="medium"
                    label="Duplicate existing scenario"
                  />
                </div>
                {!newScenario && !duplicateScenario && (
                  <MaterialSelect
                    onChange={(e) => setSelectedScenarioForDuplicate(e.target.value)}
                    value={selectedScenarioForDuplicate}
                    width="280px"
                    options={scenarios.map((scenario) => {
                      return {
                        value: scenario.ID,
                        description: props.companyScenario ? scenario.ScenarioName : scenario.Name,
                      };
                    })}
                  />
                )}
              </div>
              <div className="scmsc_scenario_inputs_div">
                <div className="scmsc_inputs_div">
                  <CircleCheckboxM
                    checked={duplicateScenario}
                    onChange={() => setDuplicateScenario(!duplicateScenario)}
                    size="medium"
                    label="Create new scenario from scratch"
                  />
                </div>
              </div>
              <div className="scmsc_scenario_inputs_div">
                <div className="scmsc_inputs_div">
                  <CircleCheckboxM
                    disabled={
                      !global.Modeliks.PERMISSIONS.Financials.restrictions.ScenarioManager.active
                    }
                    checked={newScenario && !duplicateScenario}
                    onChange={() => changeSelected(true)}
                    size="medium"
                    label="Select industry model template"
                  />
                </div>
                {props.companyScenario && newScenario && !duplicateScenario && (
                  <MaterialSelect
                    disabled={
                      !global.Modeliks.PERMISSIONS.Financials.restrictions.ScenarioManager.active
                    }
                    width="280px"
                    onChange={(e) => setSelectedIndustry(e.target.value)}
                    value={selectedIndustry}
                    options={getIndustryOptions()}
                  />
                )}
              </div>
            </div>
          </AddDialogContent>
          <DialogActions>
            <Button
              color={"primary"}
              backgroundColor={"primary"}
              width="91px"
              padding="0 0 0 0"
              label="Create"
              onClick={addNewScenario}
            />
          </DialogActions>
        </AddDialog>
      )}

      {editDialogOpen && (
        <AddDialog onClose={() => setDialogOpen(false)} open={true}>
          <AddDialogTitle title="New scenario" onClose={() => setEditDialogOpen(false)} />
          <AddDialogContent>
            <div className="scenario_add_content">
              <div className="sad_heading">Change Name of Scenario</div>
              <div className="sad_input">
                <Input
                  placeholder="Please enter..."
                  value={editedScenarioName}
                  error={!!scenarioNameError}
                  max={18}
                  errorMessage={scenarioNameError}
                  onChange={(event) => setEditedScenarioName(event.target.value)}
                />
              </div>
            </div>
          </AddDialogContent>
          <DialogActions>
            <Button
              color={"primary"}
              backgroundColor={"primary"}
              width="91px"
              padding="0 0 0 0"
              label="Save"
              onClick={() => saveEditedScenario()}
            />
          </DialogActions>
        </AddDialog>
      )}
      {cancelDialog && (
        <CancelDialog
          firstBtnName={"Go Back"}
          btnName={"Delete Scenario"}
          onExit={() => setCancelDialog(false)}
          onDelete={() => handleDeletePitchScenario(() => setCancelDialog(false))}
          title={`Are you sure you want to delete ${props.companyScenario ? scenarioForDeletion.ScenarioName : scenarioForDeletion.Name}?`}
          open={true}
        >
          This action is irreversable. All added data will be lost.
        </CancelDialog>
      )}
      {openScenarioLimit && (
        <PitchScenarioLimitDialog onClose={() => setOpenScenarioLimit(false)} />
      )}
    </>
  );
}
