import React from "react";
import "@szhsin/react-menu/dist/index.css";
import "../../../Admin/Dashboards/components/dashboardsBlankAdmin.scss";
import "../FinancialStatements/index.scss";
import FinancialNavigation from ".././FinancialStatements/components/FinancialNavigation";
import FinanceTableMain from "../../../../components/tables/FinanceTable/FinanceTableMain";
import { FinancialStatementsTypes } from "../../../../data/Finance/Dashboards/constants";
import CustomKPIMenu from "./components/CustomKPIMenu";
import Button from "../../../../components/actions/Button";
import { FinancialBlank } from "../../../../components/containers/FinancialBlank";
import AddNewCustomKPIDialog from "./components/AddNewCustomKPIDialog";
import AddCustomIndicator from "./components/AddCustomIndicator";
import Reports from "../../../../data/Finance/Reports";
import DeleteCustomIndicatorDialog from "./components/DeleteCustomIndicatorDialog";
import DialogLoader from "../../../../components/Loaders/DialogLoader/DialogLoader";
import { DialogTypes, ExpandCollapseTablesID } from "../../../../data/Finance/constants";
import { DriverCategories, UnitTypes } from "../../../../data/Finance/CalculatedDriver/constants";
import { PERMISSIONS } from "../../../../data/Permissions/Permissions";
import CostSales from "../../../../data/Finance/CostSales";
import CalculatedDriver from "../../../../data/Finance/CalculatedDriver";
import FooterContent from "../../Financials/components/Footer";
import { StarChartIcon, TrashCan } from "../../../../components/icons/svgIcons";
import IconButton from "@mui/material/IconButton";
import ReportExport from "../../../../helpers/ReportExport";

export default class DisplayCustomKPI extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      key: new Date().getTime(),
      loading: true,
      addNewCustomKPI: false,
      AddForm: false,
      isCustomKPI: true,
      isDeleteConfirm: false,
      editCustomIndicator: null,
      deleteCustomIndicator: null,
      months: this.props.FinancialStatements.headers.months,
      years: this.props.FinancialStatements.headers.years,
      allYears: this.props.allYears,
      allMonths: this.props.allMonths,
      selectedSubsection: props.selectedSubsection,
      types: FinancialStatementsTypes,
      selectedType: props.defaultView.custom_kpi,
      newKPI: null,
    };
    this.gridRows = props.CustomKPI.dataGrid;
  }

  componentDidMount() {
    this.getMonths();
    this.getYears();
    this.setData();
  }

  getMonths = (
    changeState = false,
    start = this.props.financialMonths.startMonthOrder,
    end = this.props.financialMonths.endMonthOrder,
  ) => {
    const getFromState = this.state.allMonths;
    this.state.months = getFromState.filter(
      (m) => m.Order >= start && m.Order <= end && m.PeriodType === "month",
    );
    if (changeState) {
      this.props.updateDataGrid();
      this.props.saveDates();
    }
  };

  getYears = (
    changeState = false,
    start = this.props.financialMonths.startYearOrder,
    end = this.props.financialMonths.endYearOrder,
  ) => {
    const getFromState = this.state.allYears;
    this.state.years = getFromState.filter(
      (m) => m.Order >= start && m.Order <= end && m.PeriodType === "year",
    );
    if (changeState) {
      this.props.saveDates();
      this.props.updateDataGrid();
      this.forceUpdate();
    }
  };

  getGridRows = () => {
    const checkView = (row) => {
      const selectedType = this.state.selectedType;
      row.data = row.views.tmpData;
      if (
        selectedType === FinancialStatementsTypes.Quarterly ||
        selectedType === FinancialStatementsTypes.Comparative
      ) {
        row.data = row.views.tmpData;
      } else {
        row.data = row.views.data;
      }

      if (row.children && row.children.length > 0) {
        row.children.forEach((c) => checkView(c));
      }

      return row;
    };

    return this.gridRows.map((row, index) => {
      row = checkView(row);
      row.hasEdit = this.props.customDashboard ? false : row.data.ID.includes("custom_indicator");
      row.disabledEdit = this.props.customDashboard ? true : false;
      row.hasDelete = !this.props.customDashboard;
      if (!this.props.customDashboard) {
        row.onEditClick = () => this.editCustomIndicator(row.id);
      }
      if (row.children && row.children.length > 0) {
        row.children.forEach((c) => checkView(c));
      }
      return row;
    });
  };

  setData = (selectedReport, closeAddForm = false) => {
    if (selectedReport) {
      this.gridRows = selectedReport.dataGrid;
    }

    this.setState(
      {
        loading: false,
      },
      () => closeAddForm && this.onCloseAddForm(),
    );
  };

  editCustomIndicator = (ID) => {
    const editCustomIndicator = global.Modeliks.DriversStore.find((d) => d.ID === ID);
    if (editCustomIndicator) {
      this.setState({ addCustomIndicator: true, editCustomIndicator, isCustomKPI: false });
    }
  };

  changeSelectedCustomKPI = (section, change) => {
    if (!change) {
      this.props.changeSelectedCustomKPI(section.ID, (newKPI) => this.setData(newKPI));
    } else {
      this.props.deleteCustomKPI(section);
    }
  };

  changeType = (e, value) => {
    this.props.defaultView.custom_kpi = value;
    this.setState({ selectedType: value });
  };

  changeColumns = () => {
    this.forceUpdate();
  };

  onCloseAddForm = (deleteRecord = true) => {
    if (
      deleteRecord !== false &&
      this.state.newKPI &&
      this.state.newKPI.hasOwnProperty("Totals") &&
      !this.state.newKPI.Totals.db_record
    ) {
      global.Modeliks.DriversStore.remove(this.state.newKPI.Totals);
    }

    this.setState({
      AddForm: false,
      isCustomKPI: false,
      editCustomIndicator: false,
      isDeleteConfirm: false,
      newKPI: null,
    });
  };
  openAddForm = (newCustomKPI = false, isDeleteConfirm = false) => {
    if (newCustomKPI) {
      this.setState({ loading: true }, this.createNewKPIB);
    } else {
      let limit =
        (this.state.selectedSubsection.Totals.getChildDrivers()
          ? this.state.selectedSubsection.Totals.getChildDrivers().length
          : 0) >= PERMISSIONS.Financials.restrictions.MaxStreamsCount.TotalCustomKPILists;
      if (limit) {
        global.Modeliks.showDialog(
          `
            You have reached the maximum number of [${PERMISSIONS.Financials.restrictions.MaxStreamsCount.TotalCustomKPILists}] lists in Custom KPI. 
            Please reorganize your lists in Custom KPI. \n Click outside of the box to continue with your work.`,
          DialogTypes.WarningSimpleText,
          () => {
            this.setState({ AddForm: false });
          },
        );
      } else {
        this.setState({ AddForm: true, isCustomKPI: newCustomKPI, isDeleteConfirm });
      }
    }
  };

  createNewKPIB = () => {
    this.props.createNewKPI((kpi) => {
      if (kpi) {
        this.setState({ newKPI: kpi, loading: false, AddForm: true, isCustomKPI: true });
      } else {
        this.setState({ loading: false });
      }
    });
  };

  openAddCustomIndicator = () => {
    let limit =
      (this.state.selectedSubsection.Totals.getChildDrivers()
        ? this.state.selectedSubsection.Totals.getChildDrivers().length
        : 0) >= PERMISSIONS.Financials.restrictions.MaxStreamsCount.TotalCustomKPILists;
    if (limit) {
      global.Modeliks.showDialog(
        `
            You have reached the maximum number of [${PERMISSIONS.Financials.restrictions.MaxStreamsCount.TotalCustomKPILists}] lists in Custom KPI. 
            Please reorganize your lists in Custom KPI. \n Click outside of the box to continue with your work.`,
        DialogTypes.WarningSimpleText,
        () => {
          this.setState({ AddForm: false });
        },
      );
    } else {
      this.setState({
        addCustomIndicator: !this.state.addCustomIndicator,
        editCustomIndicator: false,
      });
    }
  };

  deleteIndicatorForm = (driver) => {
    let deleteCustomIndicator = null;
    if (
      this.state.selectedType === FinancialStatementsTypes.Quarterly ||
      this.state.selectedType === FinancialStatementsTypes.Comparative
    ) {
      deleteCustomIndicator = this.gridRows.find((row) => row.views.tmpData.ID === driver.ID);
    } else {
      deleteCustomIndicator = this.gridRows.find((row) => row.views.data.ID === driver.ID);
    }
    if (deleteCustomIndicator) {
      this.setState({ deleteCustomIndicator, isDeleteConfirm: true });
    }
  };

  createIndicator = (driver) => {
    this.props.handleStreamLoad && this.props.handleStreamLoad(true);
    this.props.CustomKPI.selectedKPI.addIndicator(driver);
    this.props.CustomKPI.selectedKPI.Totals.Save(() => {
      this.props.loadCustomKPI(null, (kpi) => {
        this.setData(kpi);
        this.onCloseAddForm();
      });
    });

    // this.props.CustomKPI.selectedKPI.addIndicator(driver);
    // this.props.updateSelectedKPI(driver.ID,(kpi) => {
    //     this.setData(kpi);
    //     this.onCloseAddForm();
    // });
  };
  createCustomIndicator = (driver, isUpdate = null) => {
    if (driver && !driver.db_record) {
      this.props.handleStreamLoad && this.props.handleStreamLoad(true);
      this.props.CustomKPI.selectedKPI.addIndicator(driver);
      driver.Save(() => {
        this.props.CustomKPI.selectedKPI.Totals.Save(() => {
          this.props.loadCustomKPI(null, (kpi) => {
            this.setData(kpi);
            this.openAddCustomIndicator();
          });
        });
      });
    } else {
      if (driver.UnitType !== driver.db_record.UnitType) {
        this.props.handleStreamLoad && this.props.handleStreamLoad(true);
        driver.Save(() => {
          this.props.loadCustomKPI(null, (kpi) => {
            this.setData(kpi);
            this.openAddCustomIndicator();
          });
        });
      } else {
        driver.Save(() => {});
        this.props.updateSelectedKPI(driver, (kpi) => {
          this.setData(kpi);
          this.openAddCustomIndicator();
        });
      }
    }
  };

  deleteIndicator = (driver) => {
    this.props.CustomKPI.selectedKPI.removeIndicator(driver);
    this.props.updateSelectedKPI(driver.ID, (kpi) => {
      this.setData(kpi);
      this.onCloseAddForm();
    });
  };

  createCustomKPI = (kpiName) => {
    this.props.createCustomKPI(kpiName);
    this.onCloseAddForm(false);
  };

  handeMoveRow = (rows) => {
    this.gridRows = rows;
    this.forceUpdate();
  };

  exportReport = (gridData, isTmpData = false, periods) => {
    const Name = this.props.CustomKPI.selectedKPI.Name;
    return ReportExport.excelSingleReport(
      gridData,
      `Custom Kpi - ${Name}`.replaceAll("  ", " "),
      periods,
      "Actual",
      isTmpData,
      periods[0] === global.Modeliks.DateHelper.months_comaprative[0],
      true,
    );
  };

  getTable = (gridRows, Headers, CustomKPI, isComparative, isEditable = true) => {
    return (
      <FinanceTableMain
        rows={gridRows}
        // wrapperClassName={'max_height'}
        applyItalic={true}
        headers={Headers}
        showRowHeaders={true}
        useActual={true}
        wrapperClassName={"finance_table_main_wrapper_div_kpi"}
        disabledEdit={!global.Modeliks.PERMISSIONS.Dashboards.restrictions.edit.active}
        disableExpandAll={
          !isEditable || !global.Modeliks.PERMISSIONS.Dashboards.restrictions.edit.active
        }
        isCustomDashboard={!isEditable}
        disabledMove={false}
        useDashboards={true}
        dates={
          this.state.selectedType === FinancialStatementsTypes.Annual
            ? this.state.years
            : this.state.months
        }
        useDateIndexes={true}
        checkIsDividedWithZero={true}
        useDrag={false}
        useCustomizableTable={true}
        useExpandCollapseStore={true}
        ExpandCollapseID={ExpandCollapseTablesID.Dashboards.CustomKPI}
        isComparativeView={isComparative}
        useCustomKPIDates={true}
        textColor={isComparative}
        editableColor={true}
        handeMoveRow={this.handeMoveRow}
        unitDisplay={true}
        onDelete={this.deleteIndicatorForm}
        titleDate={this.props.titleDate}
        title={
          <>
            {CustomKPI.selectedKPI.Name}
            {!this.props.customDashboard ? (
              <IconButton onClick={this.props.addTableToCustomDashboard}>
                <StarChartIcon />
              </IconButton>
            ) : (
              ""
            )}
          </>
        }
        // title={this.props.selectedSubsection.name}
        // onDataChanged={this.handleGridDataChanged}
      />
    );
  };

  getFooterComponent = (getHeaders) => (
    <FooterContent
      showCheckBox={true}
      hideCheckBox
      disabled={this.EditPermission}
      disablePrev={true}
      disableFilter={!this.props.customDashboard}
      getHeaders={getHeaders}
      changeColumns={this.changeColumns}
      customDashboard={this.props.customDashboard}
      showMonthly={this.state.showMonthly}
      selectedType={this.state.selectedType}
      changeType={this.changeType}
      updateTable={() => this.forceUpdate()}
      useCustomizableTable={true}
      showMonthlyOnChange={() => this.setState({ showMonthly: !this.state.showMonthly })}
    />
  );

  render() {
    if (this.state.loading) {
      return <DialogLoader />;
    }

    if (!global.Modeliks.CustomKPIStore.length > 0) {
      return (
        <>
          <FinancialBlank
            onAdd={() => this.openAddForm(true)}
            headerText="Create your custom KPI list"
            buttonLabel="Create KPI List"
            disabled={!global.Modeliks.PERMISSIONS.Dashboards.restrictions.edit.active}
          />
          {this.state.AddForm && (
            <AddNewCustomKPIDialog
              createCustomKPI={this.createCustomKPI}
              isCustomKPI={this.state.isCustomKPI}
              open={this.state.AddForm}
              onClose={this.onCloseAddForm}
              newKPI={this.state.newKPI}
            />
          )}
        </>
      );
    }

    const {
      CustomKPI,
      disabled,
      financialMonths,
      showMonthly,
      selectedSubsection,
      FinancialStatements,
    } = this.props;
    const {
      addCustomIndicator,
      addNewCustomKPI,
      isCustomKPI,
      allMonths,
      allYears,
      selectedType,
      months,
      years,
      AddForm,
      isDeleteConfirm,
      editCustomIndicator,
    } = this.state;
    const isQuarterly = selectedType === FinancialStatementsTypes.Quarterly;
    const isComparative = selectedType === FinancialStatementsTypes.Comparative;
    const isMonthly = selectedType === FinancialStatementsTypes.Monthly;
    const isTmpData = isQuarterly || isComparative;
    const qMonths = FinancialStatements.headers.quarterly;
    const cMonths = FinancialStatements.headers.comparative;
    const getHeaders =
      isTmpData && isQuarterly
        ? qMonths
        : isTmpData && isComparative
          ? cMonths
          : !isTmpData && isMonthly
            ? months
            : years;
    const Headers = isComparative
      ? getHeaders.filter((h) => h.checked)
      : isQuarterly
        ? qMonths.filter(
            (h) => h.Order >= months[0].Order && h.Order <= months[months.length - 1].Order,
          )
        : getHeaders;

    const gridRows = this.getGridRows();
    if (this.props.customDashboard) {
      return (
        <>
          <div>{this.getFooterComponent(getHeaders)}</div>
          <div className={"main_table_container table_container"}>
            {this.getTable(gridRows, Headers, CustomKPI, isComparative, false)}
          </div>
        </>
      );
    }
    return (
      <React.Fragment>
        <div className="psdf_menu_navigation">
          <CustomKPIMenu
            IsKPI={true}
            CustomKPI={CustomKPI}
            createNewKPI={this.props.createNewKPI}
            createCustomKPI={this.props.createCustomKPI}
            onChangeKPI={this.changeSelectedCustomKPI}
            onOpenDialog={() => this.openAddForm(true)}
          />
          <CustomKPIMenu
            IsKPI={false}
            addIndicator={() => this.openAddForm(false)}
            addCustomIndicator={this.openAddCustomIndicator}
          />
          {/*<Button size='large' width='160px' padding='5px 15px 5px 15px' disable={disabled} backgroundColor='primary' label={'Add KPI'} onClick={this.openAddCustomIndicator} iconRight={true} />*/}
          {addCustomIndicator && (
            <AddCustomIndicator
              IsKPI={true}
              KPI={CustomKPI.selectedKPI}
              addCustomIndicator={this.createCustomIndicator}
              onClose={this.openAddCustomIndicator}
              open={addCustomIndicator}
            />
          )}

          {editCustomIndicator && (
            <AddCustomIndicator
              IsKPI={true}
              editCustomIndicator={this.state.editCustomIndicator}
              addCustomIndicator={this.createCustomIndicator}
              onClose={this.openAddCustomIndicator}
              open={editCustomIndicator}
            />
          )}
          {AddForm && (
            <AddNewCustomKPIDialog
              isCustomKPI={isCustomKPI}
              editCustomIndicator={editCustomIndicator}
              createCustomKPI={this.createCustomKPI}
              KPI={CustomKPI.selectedKPI.Totals}
              createIndicator={this.createIndicator}
              open={AddForm}
              onClose={this.onCloseAddForm}
              newKPI={this.state.newKPI}
            />
          )}

          {isDeleteConfirm && (
            <DeleteCustomIndicatorDialog
              open={isDeleteConfirm}
              onClose={() => this.setState({ isDeleteConfirm: false, deleteCustomIndicator: null })}
              deleteIndicator={() => this.deleteIndicator(this.state.deleteCustomIndicator)}
            />
          )}

          <FinancialNavigation
            financialMonths={financialMonths}
            types={this.state.types}
            changeType={this.changeType}
            getMonths={this.getMonths}
            getYears={this.getYears}
            getHeaders={getHeaders}
            months={allMonths}
            years={allYears}
            useActual={true}
            title={CustomKPI.selectedKPI.Name}
            selectedType={selectedType}
            saveSettings={this.props.saveSettings}
            changeColumns={this.changeColumns}
            selectedSubsection={selectedSubsection}
            exportReport={(arg1, arg2, arg3, title) =>
              this.exportReport(gridRows, isTmpData, Headers, title)
            }
            periods={Headers}
            isTmpData={isTmpData}
            rows={gridRows}
            pdfReportType={CustomKPI.selectedKPI.Name}
          />
        </div>

        <div className="main_table_container pfr_main_revenue_content">
          {this.gridRows && (
            <>
              <div className="psf_reports_export_pdf" id="report_table">
                {this.getFooterComponent(getHeaders)}
                {this.getTable(gridRows, Headers, CustomKPI, isComparative)}
              </div>
            </>
          )}
        </div>
      </React.Fragment>
    );
  }
}
