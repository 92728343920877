import React from "react";
import FinanceTable from "../../../../../../components/tables/FinanceTable";
import PropTypes from "prop-types";
import CalculatedDriverTitle from ".././components/CalculatedDriverTitle";
import CalculatedDriverGrowth from "./CalculatedDriverGrowth";
import CollapseTableComponent from "../../../../../../components/tables/FinanceTable/components/CollapseTableComponent";

class FinanceCalculatedDrivers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (!this.props.hasDriver || !this.props.drivers.length > 0) {
      return null;
    }

    return (
      <>
        {this.props.drivers.map((driver, index) => {
          const isTab = this.props.tabs.find(
            (d) => d.isDriver && d.dataGridRow.data.ID === driver.ID,
          );
          const isDisabled =
            (driver && driver.Ref_Field !== "value") ||
            driver.hasOwnProperty("GrowthDriver") ||
            driver.IsFormulaEditable === false ||
            driver.DriverName === "Number Of New Employees (delta)";
          return (
            <div key={"financeTable_sft_drivers" + driver.ID + index}>
              <CalculatedDriverTitle
                disabled={isDisabled}
                title={driver.DriverName}
                button={isTab ? "Edit Formula" : "Set Formula"}
                width={"135px"}
                scroll={this.props.scroll}
                driver={driver}
                onClick={
                  isTab
                    ? () => this.props.editCalculatedDriver(isTab)
                    : () => this.props.addCalculatedDriver(driver)
                }
              />
              <div className="dialog_table_container" onScroll={this.props.onScroll}>
                <CollapseTableComponent hideCheckBox={true} disableHeight={true} title="Forecast">
                  <FinanceTable
                    indexTab={2500 + index * 100}
                    disabled={this.props.selectedTab.checkedGrowth}
                    rows={[{ data: driver }]}
                    headers={this.props.headers}
                    useDateIndexes={true}
                    disabledEdit={isDisabled}
                    EditStream
                    displayOfDecimals={this.props.displayOfDecimals}
                    IsNumberFormatDisabled
                    unitDisplay={true}
                    showRowHeaders={false}
                    useCustomizableTable={true}
                    min={0}
                    onDataChanged={this.props.onDataChanged}
                  />
                </CollapseTableComponent>
              </div>

              <CalculatedDriverGrowth
                data={driver}
                isEnabledGrowth={this.props.selectedTab.growthEnabled}
                isHidden={false}
                onScroll={this.props.onScroll}
                onDataChanged={this.props.onDataChanged}
                headers={this.props.headers}
                onChange={this.props.handleGrowth}
                growthDriverTableID={this.props.growthDriverTableID}
              />

              <div className="dialog_table_container">
                {global.Modeliks.PERMISSIONS.Dashboards.restrictions.overAll.active &&
                  this.props.selectedTab.allowDisplayActuals &&
                  this.props.selectedTab.showActuals && (
                    <CollapseTableComponent
                      title="Actuals"
                      showActualTitle={true}
                      hideCheckBox={true}
                    >
                      <FinanceTable
                        indexTab={3000}
                        min={0}
                        useActual={true}
                        useDashboards={true}
                        showRowHeaders={false}
                        EditStream
                        displayOfDecimals={this.props.displayOfDecimals}
                        unitDisplay={true}
                        useCustomizableTable={true}
                        disabled={this.props.selectedTab.checkedGrowth}
                        rows={[{ data: driver }]}
                        headers={this.props.actualPeriods}
                        useDateIndexes={true}
                        onDataChanged={this.props.onDataChanged}
                      />
                    </CollapseTableComponent>
                  )}
              </div>
            </div>
          );
        })}
      </>
    );
  }
}

FinanceCalculatedDrivers.propTypes = {
  drivers: PropTypes.array,
  selectedTab: PropTypes.object,
  hasDriver: PropTypes.bool,
  headers: PropTypes.array,
  index: PropTypes.number,
  onDataChanged: PropTypes.func,
};

export default FinanceCalculatedDrivers;
