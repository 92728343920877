import React from "react";
import "../../../Admin/Dashboards/components/dashboardsBlankAdmin.scss";
import CircleLoader from "../../../../components/Loaders/CircleLoader/CircleLoader";
import "../FinancialStatements/index.scss";
import DisplayActuals from "./DisplayActuals";
import { ReportRoutes, RouteReports } from "../index";

const Loader = (
  <div className={"main_circle_loader_container"}>
    <CircleLoader />
  </div>
);

export default class ActualsStatements extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: new Date().getTime(),
      loading: true,
      selectedSubsection:
        props.FinancialStatements.subsections[this.props.defaultSelectedReport.actuals],
      activeOpeningBalances: this.props.showObj.openingBalances,
    };
  }

  componentDidMount() {
    this.setSubsectionByRoute();
    global.Modeliks.Subscribe("onLocationChange", this.hanldeRouteChange);
  }

  componentWillUnmount() {
    global.Modeliks.Unsubscribe2("onLocationChange", this.hanldeRouteChange);
  }

  hanldeRouteChange = () => {
    const newSection = this.getSubsectionByRoute();
    if (newSection && this.state.selectedSubsection.key != newSection.key) {
      this.setSelectedSubsection(newSection, undefined, new Date().getTime());
    }
  };

  getSubsectionByRoute = () => {
    const arr = window.location.href.split("/");
    const route = arr[arr.length - 1];
    let subsection = undefined;
    if (RouteReports.hasOwnProperty(route)) {
      let reportKey = RouteReports[route];
      subsection = this.props.FinancialStatements.subsections[reportKey];
    }
    return subsection;
  };

  setSubsectionByRoute = () => {
    this.setSelectedSubsection(this.getSubsectionByRoute());
  };

  setSelectedSubsection = (section, callBack, key = this.state.key) => {
    this.setState({ loading: true });
    const prefixRoute = "/dashboards/actuals/";
    if (section && callBack) {
      this.props.defaultSelectedReport.actuals = section.key;
      this.props.FinancialStatements.selectedSubsection = section;
      const route = prefixRoute + ReportRoutes[section.key];
      if (window.location.pathname != route) {
        global.Modeliks.NavigateTo(route);
      }
      callBack();
    } else {
      this.props.FinancialStatements.selectedSubsection =
        section ??
        this.props.FinancialStatements.subsections[this.props.defaultSelectedReport.actuals];
      const route =
        prefixRoute + ReportRoutes[this.props.FinancialStatements.selectedSubsection.key];
      if (window.location.pathname != route) {
        global.Modeliks.NavigateTo(route);
      }
      this.setState({
        loading: false,
        selectedSubsection: this.props.FinancialStatements.selectedSubsection,
        key,
      });
    }
  };

  onChangeView = () => {
    this.props.financialMonths.showMonthly = !this.props.financialMonths.showMonthly;
    this.updateDataGrid();
  };

  updateDataGrid = () => {
    this.props.FinancialStatements.selectedSubsection.gridRows =
      this.props.setReports[this.props.FinancialStatements.selectedSubsection.key]();
    this.props.saveActualDates();
    this.forceUpdate();
  };

  setOpeningBalanceDates = () => {
    this.props.showObj.openingBalances = !this.props.showObj.openingBalances;
    this.forceUpdate();
  };

  render() {
    if (this.state.loading || global.Modeliks.isLoading) {
      return Loader;
    }

    return (
      <div className={"pfr_main_revenue_content streams "} key={this.state.key}>
        <DisplayActuals
          loader={Loader}
          saveActualDates={this.props.saveActualDates}
          updateDataGrid={this.updateDataGrid}
          activeOpeningBalances={this.props.showObj.openingBalances}
          setOpeningBalanceDates={this.setOpeningBalanceDates}
          setReports={this.props.setReports}
          financialMonths={this.props.financialMonths}
          allMonths={this.props.allMonths}
          allYears={this.props.allYears}
          reload={() => this.setState({ key: new Date().getTime() })}
          showMonthly={this.props.financialMonths.showMonthly}
          onChangeView={this.onChangeView}
          FinancialStatements={this.props.FinancialStatements}
          setSelectedSubsection={this.setSelectedSubsection}
          selectedSubsection={this.props.FinancialStatements.selectedSubsection}
          {...this.props}
        />
      </div>
    );
  }
}
