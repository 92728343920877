import React, { Fragment } from "react";
import all_icons from "../../all_icons";
import TextObject from "./TextObject";
import HeaderLineEditor from "../SlideHeader/HeaderLineEditor";
import "../components/Lines.css";
import { Line, Arrow, Elbow, Curved, Curve, PolyLine, Scribble } from "../components/Lines";
import LineContextMenuComponent from "../components/ContextMenus/LineContextMenu";
import ContextMenuPortal from "../Templates/ContextMenuPortal";
import ChangeConnectorSubContextMenu from "../components/ContextMenus/ChangeConnectorSubContextMenu";
import ListSubheader from "@mui/material/ListSubheader";
import { IconButton } from "@mui/material";
import List from "@mui/material/List";
import RightMenuPortal from "../components/Portals/RightMenuPortal";
import SizeAndRotation from "../components/RightMenuComponents/SizeAndRotationComponent";
import Position from "../components/RightMenuComponents/PositionComponent";
import { Exit } from "../components/IconButtons/SubMenuIcons";
import DrawComponent from "../components/LineComponents/DrawComponent";

function LineObject(props) {
  // const [shapeType, setShape] = React.useState(props.shapeType ? props.shapeType : 1);
  const selected = props.selected;
  const [style, setStyle] = React.useState(props.style ? props.style : {});
  const [styleArrow, setStyleArrow] = React.useState({
    borderTop: "solid",
    borderColor: "black",
    borderWidth: "15px",
  });

  const handleColorChange = (event) => {
    setStyle({ ...style, borderColor: event.target.value });
  };

  const handleLineType = (event) => {
    setStyle({ ...style, borderTop: event.target.value });
    // handleMinusLineWidth()
    // handlePlusLineWidth()
    //setStyle({...style, borderWidth:style.borderWidth})
  };
  const handleMinusLineWidth = () => {
    if (Number(style.borderWidth.slice(0, -2)) > 0) {
      setStyle({ ...style, borderWidth: Number(style.borderWidth.slice(0, -2)) - 1 + "px" });
    }
  };
  const handlePlusLineWidth = () => {
    setStyle({
      ...style,
      borderWidth: Number(style.borderWidth.slice(0, -2)) + 1 + "px",
      height: Number(style.height.slice(0, -2)) + "px",
    });
    // if(props.lineType===2 || props.lineType===2 || props.lineType===4){
    //     setStyle({...style, borderWidth: Number(style.borderWidth.slice(0,-2))+1 +'px', })
    // }
  };

  const checkType = () => {
    if (props.lineType === 2)
      setStyle({ ...style, borderTopRightRadius: "3px", borderBottomRightRadius: "3px" });
  };

  let rightMenuFunc = {};

  const onStyleChange = (newStyle) => {
    let curStyle = { ...style, ...newStyle };
    setStyle(curStyle);
    props.onPropsChange({ style: curStyle });
  };

  return (
    <Fragment>
      {props.selected && !props.multipleSelected && (
        <div
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        >
          <HeaderLineEditor
            visible={props.lineType && selected}
            handleColorChange={handleColorChange}
            handleLineType={handleLineType}
            onStyleChange={onStyleChange}
            handleMinusLineWidth={handleMinusLineWidth}
            handlePlusLineWidth={handlePlusLineWidth}
            style={style}
            toggleFormatOptions={props.toggleFormatOptions}
            onPreview={props.onPreview}
          />
        </div>
      )}
      {props.formatOptions && props.selected && (
        <RightMenuPortal>
          <div className={"right_menu_mask"}>
            <List
              style={{
                paddingLeft: "0",
                position: "absolute",
                width: "100%",
                height: "auto",
                maxWidth: 250,
                bgcolor: "background.paper",
                paddingBottom: "0",
                paddingTop: "0",
                // overflow:'scroll',
              }}
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 700,
                        fontSize: "16px",
                        textAlign: "left",
                      }}
                    >
                      Format Options
                    </span>
                    <IconButton onClick={props.toggleFormatOptions}>
                      <Exit />
                    </IconButton>
                  </div>
                </ListSubheader>
              }
            >
              <SizeAndRotation
                changeLockedAspect={props.changeLockedAspect}
                onChange={props.onUpdateSizeAndRotation}
                rightMenuFunc={rightMenuFunc}
                style={props.style}
                width={props.width}
                height={props.height}
                rotateAngle={props.rotateAngle}
                scaleX={props.scaleX}
                scaleY={props.scaleY}
              />
              <Position
                onChange={props.onUpdatePosition}
                rightMenuFunc={rightMenuFunc}
                style={props.style}
                top={props.top}
                left={props.left}
              />
            </List>
          </div>
        </RightMenuPortal>
      )}

      {props.lineType === 1 && <Line style={style} />}
      {props.lineType === 2 && <Arrow />}
      {props.lineType === 3 && <Elbow />}
      {props.lineType === 4 && <Curved />}
      {props.lineType === 5 && <Curve />}
      {props.lineType === 6 && <PolyLine />}
      {props.lineType === 7 && (
        <div
          style={{
            height: props.style.height,
            width: props.style.width,
            position: "relative",
            overflow: "hidden",
          }}
        >
          <div
            style={{ position: "absolute", top: -props.svgTop, left: -props.svgLeft }}
            dangerouslySetInnerHTML={{ __html: props.value }}
          />
        </div>
      )}
    </Fragment>
  );
}

LineObject.config = {
  height: "auto",
  minWidth: 50,
};

export default LineObject;
