const FontSizeOptions = [
  { value: 6, name: "6pt", description: "6pt" },
  { value: 8, name: "8pt", description: "8pt" },
  { value: 10, name: "10pt", description: "10pt" },
  { value: 12, name: "12pt", description: "12pt" },
  { value: 14, name: "14pt", description: "14pt" },
  { value: 16, name: "16pt", description: "16pt" },
  { value: 18, name: "18pt", description: "18pt" },
  { value: 20, name: "20pt", description: "20pt" },
  { value: 22, name: "22pt", description: "22pt" },
  { value: 24, name: "24pt", description: "24pt" },
  { value: 26, name: "26pt", description: "26pt" },
  { value: 28, name: "28pt", description: "28pt" },
  { value: 30, name: "30pt", description: "30pt" },
  { value: 32, name: "32pt", description: "32pt" },
  { value: 34, name: "34pt", description: "34pt" },
  { value: 36, name: "36pt", description: "36pt" },
  { value: 38, name: "38pt", description: "38pt" },
  { value: 40, name: "40pt", description: "40pt" },
  { value: 42, name: "42pt", description: "42pt" },
  { value: 44, name: "44pt", description: "44pt" },
  { value: 46, name: "46pt", description: "46pt" },
  { value: 48, name: "48pt", description: "48pt" },
  { value: 50, name: "50pt", description: "50pt" },
];

export { FontSizeOptions };
