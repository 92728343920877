import * as React from "react";
import Dialog from "@mui/material/Dialog";
import "../../components/components/Dialog/SectionDialog.scss";
import "../../components/components/Dialog/SectionName.scss";
import CircleCheckboxM from "../../../components/actions/CircleCheckboxM";
import ButtonJ from "../../components/components/Buttons/Button";

export default function AddNewIndustryDialog(props) {
  const [success, setSuccess] = React.useState(false);
  const [name, setName] = React.useState("Industry name");

  const handleClose = () => {
    props.closeDialog();
    setTimeout(() => {
      setSuccess(false);
      setName("Industry name");
    }, 150);
  };

  const handleSave = () => {
    props.addNewIndustry(name, () => setSuccess(true));
  };

  return (
    <React.Fragment>
      {props.open && (
        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          open={props.open}
          className={"name_dialog"}
          onClose={handleClose}
        >
          {success ? (
            <>
              <div className={"checkbox_container"}>
                <CircleCheckboxM size="large" checked={true} />
              </div>
              <div className="Label_2">Industry successfully created</div>
              <ButtonJ
                size="large"
                width="118px"
                padding="0 0 0 0"
                backgroundColor="primary"
                label={"OK"}
                onClick={() => handleClose()}
              />
            </>
          ) : (
            <>
              <div className={"label"}>Create New Industry</div>
              <div className={"input_container"}>
                <input
                  className={"input"}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder={"Industry name..."}
                />
              </div>
              <div className={"btns_container"}>
                <ButtonJ
                  size="large"
                  width="118px"
                  padding="0 0 0 0"
                  color="black"
                  noElevation={true}
                  onClick={handleClose}
                  label={"Cancel"}
                />
                <ButtonJ
                  size="large"
                  width="118px"
                  padding="0 0 0 0"
                  backgroundColor="primary"
                  label={"Create Industry"}
                  onClick={() => handleSave()}
                />
              </div>
            </>
          )}
        </Dialog>
      )}
    </React.Fragment>
  );
}
