import React, { Fragment, useState } from "react";
import {
  Bent,
  BentUp,
  Chevron,
  Down,
  DownCallout,
  Left,
  LeftCallout,
  LeftRight,
  LeftRightUp,
  LeftUp,
  Notched,
  Pentagon,
  Quad,
  Right,
  RightCallout,
  Striped,
  Up,
  UpCallout,
  UpDown,
  UTurn,
} from "../../components/Shapes/ArrowIcons";
import { RightArrow } from "../../components/IconButtons/SubMenuIcons";
import { SlideObject, SlideTypes } from "../../SlideObjects/SlideStructures";
import { MenuItem, SubMenu } from "@szhsin/react-menu";

function ArrowsSubMenu(props) {
  const handleNewShapeObj = (shapeType) => {
    const nObj = new SlideObject(SlideTypes.shapeObject, null, {
      shapeType,
      width: 300,
      height: 300,
      top: 300,
      left: 100,
    });
    props.onNewObject(nObj);
  };

  const ArrowsMenu = () => {
    return (
      <SubMenu
        label={
          <>
            <Right />
            <span className="item_name">Arrows</span>
            <span className="helper_text">Shift+Alt</span>
          </>
        }
      >
        <MenuItem onClick={() => handleNewShapeObj(69)}>
          <Right />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(70)}>
          <Left />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(71)}>
          <Up />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(72)}>
          <Down />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(73)}>
          <LeftRight />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(74)}>
          <UpDown />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(75)}>
          <Quad />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(76)}>
          <LeftRightUp />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(77)}>
          <Bent />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(78)}>
          <UTurn />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(79)}>
          <LeftUp />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(80)}>
          <BentUp />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(81)}>
          <Striped />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(82)}>
          <Notched />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(83)}>
          <Pentagon />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(84)}>
          <Chevron />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(85)}>
          <RightCallout />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(86)}>
          <DownCallout />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(87)}>
          <LeftCallout />
        </MenuItem>
        <MenuItem onClick={() => handleNewShapeObj(88)}>
          <UpCallout />
        </MenuItem>
      </SubMenu>
    );
  };
  return <>{ArrowsMenu()}</>;
}
export default ArrowsSubMenu;
