import React, { Component } from "react";
import Input from "../../../../../components/actions/Input";
import ButtonMui from "../../../../../components/buttons/buttonMui/buttonMui";
import { GreyCard, parseDate } from "../index";
import request from "superagent";
import CancelDialog from "../../../../../components/dialogs/settings/CancelDialog";
import { DialogTypes } from "../../../../../data/Finance/constants";
import SwitchAccountDialog from "../../../../../components/dialogs/SwitchAccount/SwitchAccountDialog";
import CircleLoader from "../../../../../components/Loaders/CircleLoader/CircleLoader";

class GeneralInfo extends Component {
  constructor() {
    super();
    this.Account = global.Modeliks.Account;

    this.state = {
      accountName: this.Account.Name,
      accDialog: false,
    };

    this.plan = global.Modeliks.subscriptionPlans.find(
      (c) => c.ID == global.Modeliks.subscriptionInfo.SubscriptionID,
    );
    if (this.plan) {
      this.plan_name = this.plan.name;
    }
  }

  openAccDialog = () => {
    this.setState({ accDialog: true });
  };
  closeAccDialog = () => {
    this.setState({ accDialog: false });
  };

  changeAccountName = () => {
    if (this.Account.Name !== this.state.accountName) {
      this.Account.Name = this.state.accountName;
      request
        .put("/api/master/accounts")
        .set("authorization", "Bearer " + window.localStorage.getItem("token"))
        .query({ ID: this.Account.ID })
        .send({ Name: this.Account.Name })
        .then()
        .catch((err) => console.error(err));
    }
  };

  pauseSubsctiption = () => {
    request
      .put("/api/updateSubscriptionPauseStatus")
      .set("Content-Type", "application/x-www-form-urlencoded")
      .send({
        AccountID: global.Modeliks.Account.ID,
      })
      .then((res) => {
        if (res) {
          if (
            global.Modeliks.subscriptionInfo.isCanceled === null ||
            global.Modeliks.subscriptionInfo.isCanceled === false
          ) {
            global.Modeliks.subscriptionInfo.isCanceled = true;
          } else {
            global.Modeliks.subscriptionInfo.isCanceled = false;
          }
          this.forceUpdate();
        }
      });
  };

  cancelAccount = () => {
    global.Modeliks.showDialog(
      "Account",
      DialogTypes.CancelSubscription,
      () => {},
      (shouldPause) => {
        if (shouldPause) {
          this.pauseSubsctiption();
        } else {
          this.setState({ loading: true });
          debugger;
          request
            .put("/api/cancelSubscription")
            .set(
              "Access-Control-Allow-Origin",
              window.location.protocol + "//" + window.location.host,
            )
            .set("Accept", "application/json")
            .set("authorization", "Bearer " + window.localStorage.getItem("token"))
            .send({ AccountID: global.Modeliks.Account.ID })
            .then((res) => {
              if (res.body == "OK") {
                request
                  .del("/api/cancel_account")
                  .set("authorization", "Bearer " + window.localStorage.getItem("token"))
                  .then((data) => {
                    this.setState({ switchAccDialog: true, loading: false });
                  })
                  .catch((e) => console.error(e));
              } else {
                this.setState({ loading: false });
              }
            })
            .catch((e) => console.error(e));
        }
      },
      "",
      false,
      "cancel_subscription",
    );
  };

  componentWillUnmount() {
    this.changeAccountName();
  }

  getIncludedCompanies = (
    numberOfCompanies = global.Modeliks.subscriptionInfo.companies_number,
  ) => {
    if (numberOfCompanies == 1) {
      return "1 Company";
    }
    return numberOfCompanies + " Companies";
  };

  getAddOnPlans = () => {
    const extraCompanies =
      global.Modeliks.subscriptionInfo.TotalCompanies -
      global.Modeliks.subscriptionInfo.companies_number;

    if (extraCompanies === 0) {
      return null;
    }
    return (
      <div>
        <div className={"g_c_content"}>
          + {this.getIncludedCompanies(extraCompanies)} (${this.plan.price_extra_company} per
          Company)
        </div>
        {/*<ul>*/}
        {/*    <li className={'g_c_content'}>{this.getIncludedCompanies(extraCompanies)}</li>*/}
        {/*</ul>*/}
      </div>
    );
  };

  getIncludedUsers = (multiplier = 1, users = null) => {
    const numberOfUsers =
      (users !== null ? users : global.Modeliks.subscriptionInfo.users_number) * multiplier;

    if (numberOfUsers == 1) {
      return "1 User";
    }
    return numberOfUsers + " Users";
  };

  getAddOnUsers = () => {
    const numberOfUsers =
      global.Modeliks.subscriptionInfo.TotalUsers - global.Modeliks.subscriptionInfo.users_number;
    if (numberOfUsers === 0) {
      return null;
    }
    return (
      <div className={"g_c_content"}>
        + {this.getIncludedUsers(1, numberOfUsers)} ($
        {global.Modeliks.subscriptionInfo.price_extra_user} per user)
      </div>
    );
  };

  render() {
    return (
      <>
        {this.state.loading && (
          <div className={"main_circle_loader_container white_back"}>
            <CircleLoader message={"Please wait while we securely delete your account..."} />
          </div>
        )}
        <div className={"column"}>
          <div className={"second_title"}>Account Name</div>
          <div>
            <Input
              onBlur={() => this.changeAccountName()}
              placeholder={"Enter account name"}
              allowEmpty={true}
              onChange={(e) => this.setState({ accountName: e.target.value })}
              value={this.state.accountName}
            />
          </div>
          <div className={"second_title"}>Account Owner</div>
          <div>
            <Input
              value={
                global.Modeliks.AccountUsers &&
                global.Modeliks.AccountUsers.find(
                  (c) => c.User.ID === global.Modeliks.Account.Owner_ID,
                )
                  ? global.Modeliks.AccountUsers.find(
                      (c) => c.User.ID === global.Modeliks.Account.Owner_ID,
                    ).User.Email
                  : ""
              }
              disabled
              max={30}
              name="Owner"
              onChange={(e) => e.preventDefault()}
            />
          </div>
          <div className={"second_title"}>Account ID:</div>
          <div>
            <Input value={this.Account.GUID} disabled max={30} />
          </div>
        </div>
        <div className={"column"}>
          <div className={"second_title"}>
            Plan Name: <span className={"property_name"}>{this.plan_name}</span>
          </div>
          <GreyCard>
            <div className={"third_title"}>Plan overview</div>
            <div className={"g_c_content"}>
              Active until{" "}
              {!global.Modeliks.subscriptionInfo.isLifeTime
                ? parseDate(global.Modeliks.subscriptionInfo.DateEnd)
                : "Lifetime"}
            </div>
            {!global.Modeliks.subscriptionInfo.isLifeTime && (
              <div className={"g_c_content"}>
                Next billing {parseDate(global.Modeliks.subscriptionInfo.DateEnd)}
              </div>
            )}
            {!global.Modeliks.subscriptionInfo.isLifeTime && (
              <div className={"g_c_content"}>
                ${global.Modeliks.subscriptionInfo.price} per {this.plan.billing_type}
              </div>
            )}
            <div className={"third_title"}>Included in Plan</div>
            <div className={"g_c_content"}>{this.getIncludedUsers()}</div>
            <div className={"g_c_content"}>{this.getIncludedCompanies()}</div>
          </GreyCard>

          {(this.getAddOnPlans() || this.getAddOnUsers()) && (
            <>
              <div className={"second_title"}>Add-ons to Plan</div>
              <GreyCard>
                {this.getAddOnUsers()}
                {this.getAddOnPlans()}
              </GreyCard>
            </>
          )}

          <div className={"third_title"}>
            Total Users: {global.Modeliks.subscriptionInfo.TotalUsers}
          </div>
          <div className={"third_title"}>
            Total Companies: {global.Modeliks.subscriptionInfo.TotalCompanies}
          </div>
          {!global.Modeliks.subscriptionInfo.isLifeTime && (
            <div className={"btn_container"}>
              <ButtonMui
                style={{ height: "34px" }}
                label="Cancel Account"
                variant={"contained"}
                onClick={this.cancelAccount}
              />
            </div>
          )}
        </div>
        {this.state.accDialog && (
          <CancelDialog
            open={true}
            onExit={() => this.closeAccDialog()}
            onDelete={() => this.closeAccDialog()}
          >
            If you cancel your account now, you'll still have access to it until the end of the
            current subscription period (Nov 01, 2022). After Nov 01, 2022 we will backup your data
            in case you want to reactivate your subscription and have access to your data.
          </CancelDialog>
        )}

        {this.state.switchAccDialog && <SwitchAccountDialog open={true} disableClose />}
      </>
    );
  }
}

export default GeneralInfo;
