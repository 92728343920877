import React from "react";
import AuthPage from "../components/AuthPage";

class Sent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
  }

  componentDidMount() {}

  render() {
    return <AuthPage type="emailSent" />;
  }
}

export default Sent;
