import React from "react";
import List from "@mui/material/List";
import { LeftArrow, ResetTemplate, TemplateSelectedCheckbox } from "../IconButtons/SubMenuIcons";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListItemButton from "@mui/material/ListItemButton";
import "./OptionsMenuList.scss";
import Button from "@mui/material/Button";
import AddInstructionDialog from "../Dialog/AddInstructionDialog";
import LongInstructionsDialog from "../Dialog/LongInstructionsDialog";
import ButtonMui from "../../../../components/buttons/buttonMui/buttonMui";
import InstructionalPopup from "../../../../components/dialogs/instructionsPopup/instructionalPopup";
import TemplatesDialog from "../Dialog/TemplatesDialog";

const CSSClass = "se_right_menu_container";

class RightMenu extends React.Component {
  constructor(props) {
    super(props);
    this.isCustom = false;
    this.state = {
      expandTemplates: true,
      expandInstructions: true,
      expandedMenuClass: props.rightExpandedMenuClass ?? "",
      openTemplatesDialog: false,
    };

    props.scale.resizeRight = this.toggleMenu;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props !== nextProps) {
      return true;
    } else {
      return false;
    }
  }

  handleResize = (value) => {
    setTimeout(this.props.update, 150);
  };

  toggleMenu = (callBack, preview = false) => {
    if (preview) {
      if (this.props.scale.enabled) {
        this.state.expandedMenuClass = " extended";
        this.props.scale.rightMenu = true;
      } else {
        this.state.expandedMenuClass = " hidden";
        this.props.scale.rightMenu = false;
      }
    } else {
      if (this.state.expandedMenuClass === " hidden") {
        this.state.expandedMenuClass = " extended";
        this.props.scale.rightMenu = true;
      } else {
        this.state.expandedMenuClass = " hidden";
        this.props.scale.rightMenu = false;
      }
    }
    this.forceUpdate();
    if (callBack) {
      callBack();
    }
  };

  toggleTemplates = () => {
    this.state.expandTemplates = !this.state.expandTemplates;
    this.forceUpdate();
  };

  toggleInstructions = () => {
    this.state.expandInstructions = !this.state.expandInstructions;
    this.forceUpdate();
  };

  getShortInstructions = () => {
    if (this.props.selectedSection) {
      if (this.props.selectedSection.MasterSectionID) {
        if (global.Modeliks.PitchInstructionsContent[this.props.selectedSection.MasterSectionID]) {
          return global.Modeliks.PitchInstructionsContent[
            this.props.selectedSection.MasterSectionID
          ].shortInstruction;
        }
      }
      if (this.props.selectedSection.MasterPageID) {
        if (global.Modeliks.BPInstructionsContent[this.props.selectedSection.MasterPageID]) {
          return global.Modeliks.BPInstructionsContent[this.props.selectedSection.MasterPageID]
            .shortInstruction;
        }
      }
      return this.props.selectedSection.Instructions ? this.props.selectedSection.Instructions : "";
    }
    return "";
  };
  getLongInstructions = () => {
    if (this.props.selectedSection) {
      if (this.props.selectedSection.MasterSectionID) {
        if (global.Modeliks.PitchInstructionsContent[this.props.selectedSection.MasterSectionID]) {
          return global.Modeliks.PitchInstructionsContent[
            this.props.selectedSection.MasterSectionID
          ].longInstruction;
        }
      }
      if (this.props.selectedSection.MasterPageID) {
        if (global.Modeliks.BPInstructionsContent[this.props.selectedSection.MasterPageID]) {
          return global.Modeliks.BPInstructionsContent[this.props.selectedSection.MasterPageID]
            .longInstruction;
        }
      }
      return this.props.selectedSection.LongInstructions
        ? this.props.selectedSection.LongInstructions
        : "";
    }
    return "";
  };

  render() {
    return (
      <div className={CSSClass + "" + this.state.expandedMenuClass} id={"se_right_menu_container"}>
        <div
          style={{
            height: 24,
            width: 24,
            backgroundColor: "white",
            borderRadius: "50%",
            zIndex: 9,
            top: 10,
          }}
          className={"closing_btn"}
        >
          <ButtonMui
            label={<LeftArrow color={"#585858"} />}
            isIconButton
            onClick={() => this.toggleMenu(() => this.handleResize())}
            variant={"text"}
            color={"black"}
            style={{ borderRadius: "50%", zIndex: 999, height: 24, width: 24, padding: "4px" }}
          />
        </div>

        <div className={"se_right_menu"} id={"se_right_menu"}>
          {!this.props.hideInstructions && (
            <div className={"top_btn_cont"}>
              <InstructionalPopup
                actionButton={<ButtonMui label={"Videos"} variant={"text"} color={"secondary"} />}
                data_key={"video_links"}
              />
              <InstructionalPopup
                actionButton={
                  <ButtonMui label={"Instructions"} variant={"text"} color={"secondary"} />
                }
                data_key={"instructions_contents"}
              />
            </div>
          )}
          <List
            style={{
              paddingLeft: "0",
              position: "relative",
              width: "242px",
              height: "auto",
              bgcolor: "background.paper",
              paddingBottom: "0 ",
              // overflow:'scroll',
            }}
            className={this.props.className ? this.props.className : ""}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            {(!this.props.dontShowTemplates || this.props.isAdmin) && (
              <ListItemButton onClick={() => this.toggleTemplates()}>
                <ListItemText primary="Templates" />
                {this.state.expandTemplates ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            )}
            <div className="right_menu_splitter" />
            {
              <Collapse
                in={this.state.expandTemplates}
                timeout="auto"
                className={`templates_collapse ${this.props.businessPlan ? "bp" : ""}`}
              >
                {!this.props.dontShowTemplates &&
                  this.props.templates.map((template, index) => {
                    if (index <= 3) {
                      return (
                        <Button
                          onClick={() => this.props.selectTemplate(template)}
                          key={"right_menu_item" + index}
                          className={
                            this.props.selectedTemplateID === template.ID
                              ? "template_container selected"
                              : "template_container"
                          }
                        >
                          {this.props.selectedTemplateID === template.ID &&
                            (!template.hasOwnProperty("isCustom") ||
                              template.isCustom === false) && (
                              <TemplateSelectedCheckbox
                                className={
                                  this.props.selectedTemplateID === template.ID
                                    ? "selection_checkbox"
                                    : "selection_checkbox hidden"
                                }
                              />
                            )}

                          {this.props.selectedTemplateID === template.ID && template.isCustom && (
                            <ResetTemplate
                              className={"selection_checkbox"}
                              onClick={(e) => {
                                e.stopPropagation();
                                this.props.resetTemplate();
                              }}
                            />
                          )}

                          <img
                            src={`/api/img?tmp=${this.props.isAdmin ? template.ID : template.MasterTemplateID}&v=${template.UpdatedAt}`}
                            className={"template_img"}
                            alt=""
                          />
                        </Button>
                      );
                    }
                  })}

                <div id={"template_helper_buttons"}>
                  {!this.props.businessPlan && this.props.templates.length > 4 && (
                    <ButtonMui
                      label={"Show More"}
                      onClick={() => {
                        this.setState({ openTemplatesDialog: true });
                        this.forceUpdate();
                      }}
                      variant={"text"}
                    />
                  )}
                  {(!this.props.dontShowTemplates || this.props.isAdmin) && (
                    <div id={"template_buttons"} />
                  )}
                </div>
              </Collapse>
            }

            {!this.props.isAdmin && this.props.businessPlan && (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ButtonMui
                  fontSize={14}
                  width={200}
                  label={"Reset page to default"}
                  variant={"text"}
                  onClick={this.props.resetTemplate}
                />
              </div>
            )}
            {!this.props.hideInstructions && (
              <>
                <ListItemButton onClick={() => this.toggleInstructions()}>
                  <ListItemText primary="Instructions" />
                  {this.state.expandInstructions ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <div className="right_menu_splitter" />
                <Collapse className={"instructions_collapse"} in={this.state.expandInstructions}>
                  {this.getShortInstructions()}
                  {this.getLongInstructions() && (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <LongInstructionsDialog instructions={this.getLongInstructions()} />
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {this.props.isAdmin && (
                      <AddInstructionDialog
                        onSave={this.props.saveInstructions}
                        instructions={
                          this.props.selectedSection && this.props.selectedSection.Instructions
                            ? this.props.selectedSection.Instructions
                            : ""
                        }
                        label={"Add short instructions"}
                      />
                    )}
                    {this.props.isAdmin && (
                      <AddInstructionDialog
                        onSave={this.props.saveInstructions}
                        instructions={
                          this.props.selectedSection && this.props.selectedSection.LongInstructions
                            ? this.props.selectedSection.LongInstructions
                            : ""
                        }
                        label={"Add long instructions"}
                        isLong
                      />
                    )}
                    {this.props.isAdmin && (
                      <ButtonMui label={"+ Template"} onClick={this.props.createBlankTemplate} />
                    )}
                    {this.props.isAdmin && (
                      <ButtonMui
                        label={
                          this.props.templates.find(
                            (c) => c.ID === this.props.selectedTemplateID,
                          ) &&
                          this.props.templates
                            .find((c) => c.ID === this.props.selectedTemplateID)
                            .hasOwnProperty("isPublished")
                            ? "Unpublish"
                            : "Publish"
                        }
                        onClick={this.props.updateSelectedTemplatePublishStatus}
                      />
                    )}
                  </div>
                </Collapse>
              </>
            )}
          </List>
        </div>

        <TemplatesDialog
          open={this.state.openTemplatesDialog}
          handleClose={() => {
            this.setState({ openTemplatesDialog: false });
            this.forceUpdate();
          }}
          selectTemplate={this.props.selectTemplate}
          templates={this.props.templates}
        />
      </div>
    );
  }
}

export default RightMenu;
