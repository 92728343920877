import React from "react";
import styled, { keyframes, css } from "styled-components";
import PropTypes from "prop-types";
import { Theme } from "../../../theme/styles/theme";

let styles = {
  white: Theme.colors.greyscale.white,
};

const Indicator = styled.div`
  width: ${(props) =>
    props.size === "small"
      ? props.squared
        ? "20px"
        : "17px"
      : props.size === "medium"
        ? props.squared
          ? "18px"
          : "20px"
        : props.squared
          ? "27px"
          : "29px"};
  height: ${(props) =>
    props.size === "small"
      ? props.squared
        ? "20px"
        : "17px"
      : props.size === "medium"
        ? props.squared
          ? "18px"
          : "20px"
        : props.squared
          ? "27px"
          : "29px"};
  background: ${styles["white"]};
  border: 1px solid #d4d5de;
  border-radius: ${(props) => (props.squared ? `3px` : `50%`)};
  box-sizing: border-box;
  box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.2);
  margin: ${(props) =>
    props.size === "small"
      ? props.squared
        ? 0
        : "1.5px"
      : props.size === "medium"
        ? props.squared
          ? "3px"
          : "2px"
        : props.squared
          ? "4px"
          : "3px"};
`;

function CustomUnChecked({ size = "small", squared = false }) {
  return <Indicator size={size} squared={squared} />;
}

CustomUnChecked.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  squared: PropTypes.bool,
};

export default CustomUnChecked;
