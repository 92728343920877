import * as React from "react";
import CircleLoader from "../../../components/Loaders/CircleLoader/CircleLoader";
import PropTypes from "prop-types";
import request from "superagent";

class Publish extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };

    this.getFinanceData();
  }

  getFinanceData = () => {
    const account_guid = window.location.href.split("/")[4];
    const client_guid = window.location.href.split("/")[5];
    global.Modeliks.GetDashboardSections(() =>
      request
        .get(
          `/api/financial_data_publish?account_guid=${account_guid}&client_guid=${client_guid}&editor_type=${this.props.editor_type ?? "pitch"}`,
        )
        .then((res) => {
          if (res.text !== "0") {
            this.handleFinanceData(res.body);
          } else {
            this.setState({
              loading: false,
            });
          }
        }),
    );
  };

  handleFinanceData = (data) => {
    global.Modeliks.CompanyInfo = data.Company;
    global.Modeliks.CompanyScenarioInfo = data.CompanyScenario;

    global.Modeliks.StaticDataObject = data;
    global.Modeliks.DateHelper.buildDates(global.Modeliks.CompanyInfo);
    global.Modeliks.InitializeDrivers(false, () => {
      this.setState({ loading: false });
    });
  };

  render() {
    if (this.state.loading) {
      return (
        <div className={"main_circle_loader_container"}>
          <CircleLoader />
        </div>
      );
    }
    return this.props.children;
  }
}

const dataKeys = ["SlideEditor", "BusinessPlan"];

Publish.propTypes = {
  dataKey: PropTypes.oneOf(dataKeys),
};

export default Publish;
