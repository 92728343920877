import React from "react";
import styled from "styled-components";
import { Theme } from "../../../../../../theme/styles/theme";
import Input from "../../../../../../components/actions/Input";
import AddDialog from "../../../../../../components/dialogs/financials/AddDialog/AddDialog";
import { AddDialogTitle } from "../../../../../../components/dialogs/financials/AddDialog/AddDialogTitle";
import { AddDialogContent } from "../../../../../../components/dialogs/financials/AddDialog/AddDialogContent";
import { DialogActions } from "@mui/material";
import Button from "../../../../../../components/actions/Button";
import Mx_NumInput from "../../../../../../components/actions/Mx_NumInput";
import Personnel from "../../../../../../data/Finance/Personnel";

const SyledP = styled.p`
  ${Theme.text.paragraph.p2_strong};
  color: #252525;
`;
const StyledTextArea = styled.div`
  background: #f5f5f5;
  border: 1px solid #f0f1f7;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 16px 35px 16px 32px;
  ${Theme.text.paragraph.p2};
  color: #252525;
`;
class BurdenRate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      BurdenRate: global.Modeliks.DriverValuesStore.getItem(Personnel.getBurdenRateID()),
    };
  }

  changeBurdenRate = (value) => {
    this.state.BurdenRate.Value = value;
    this.props.onChange([this.state.BurdenRate]);
    this.forceUpdate();
  };
  updateBurdenRate = () => {
    this.state.BurdenRate.Save(this.props.onClose(true));
  };

  render() {
    return (
      <>
        <AddDialog open={this.props.open} onClose={this.props.onClose}>
          <AddDialogTitle title="Burden Rate" onClose={this.props.onClose} />
          <AddDialogContent closeOnEscapeClick={true} onClose={this.props.onClose}>
            <SyledP>What is burden rate?</SyledP>

            <StyledTextArea>
              Salaries and wages are far from the only expenses involved in having employees.
              Depending on your location, other employee-related expenses may include payroll taxes,
              worker's compensation insurance, health insurance, and other benefits and taxes. These
              expenses need to be reflected in your financial projections. It's not necessary to try
              to predict these expenses in precise detail. Instead financial plans typically use
              what's called a "burden" rate. This is just a simple percentage of total employee
              compensation that is added to cover these related expenses. (Note that the burden rate
              does not apply to contract workers.) In the profit and loss statement, you can see the
              calculated amount — total employee compensation multiplied by the burden percentage —
              on the Employee- Related Expenses line.
            </StyledTextArea>

            <SyledP style={{ marginBottom: 10, marginLeft: 4 }}>
              Enter your estimated burden rate
              <div
                style={{
                  maxWidth: "150px",
                  paddingBottom: "0px",
                  height: "45px",
                  background: Theme.colors.primary.lightblue["300"],
                }}
              >
                <Mx_NumInput
                  singleInput={true}
                  selected={true}
                  isMultipleSelected={false}
                  isEditable={true}
                  unit="%"
                  key={"burden_r" + this.state.BurdenRate.ID}
                  onChange={this.changeBurdenRate}
                  data={this.state.BurdenRate.Value}
                />
              </div>
            </SyledP>
          </AddDialogContent>

          <DialogActions
            style={{
              justifyContent: "space-between",
              background: "#F5F5F5",
              marginLeft: "16px",
            }}
          >
            <Button
              onClick={this.props.onClose}
              size="small"
              padding="15px"
              noElevation={true}
              color="red150"
              label="Close"
            />
            <Button
              onClick={this.updateBurdenRate}
              size="small"
              padding="15px"
              noElevation={true}
              color="primary"
              label="Save"
            />
          </DialogActions>
        </AddDialog>
      </>
    );
  }
}

export default BurdenRate;
