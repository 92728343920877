import React from "react";
import "./financeTableSE.scss";

import ProfitLoss from "../../../../data/Finance/Reports/ProfitLoss/index";
import { ReportsTableSizes, ReportsTypes } from "../../../../data/Finance/Reports/constants";
import CashFlow from "../../../../data/Finance/Reports/CashFlow/index";
import BalanceSheet from "../../../../data/Finance/Reports/BalanceSheet/index";
import { InputFormats } from "../../../../components/constants/finance";
import { UnitTypes } from "../../../../data/Finance/CalculatedDriver/constants";

const fontRatio = 10;

const tableDateType = {
  month: "Month",
  year: "Year",
};

class FinanceTable extends React.Component {
  taxes = [];
  rows = [];
  reportType = ReportsTypes.ProfitLoss.key;
  tableSize = ReportsTableSizes.Short.size;
  tableName = null;
  dateType = tableDateType.month;
  fontSize = 10;

  biggestAmntOfDigits = 0;
  className = "";

  constructor(props) {
    super(props);

    if (props.value) {
      if (props.value.reportType) {
        this.reportType = props.value.reportType;
      }

      if (props.value.dateType) {
        this.dateType = props.value.dateType;
      }

      if (props.value.tableSize) {
        this.tableSize = props.value.tableSize;
      }
    }

    if (global.Modeliks.DateHelper.months) {
      if (this.dateType == tableDateType.month) {
        this.gridPeriodHeaders = [...global.Modeliks.DateHelper.months].map((h) => {
          return { Header: h.Header, sufix: h.sufix };
        });
      } else {
        this.gridPeriodHeaders = [...global.Modeliks.DateHelper.gridPeriodHeaders_Years()].map(
          (h) => {
            return { Header: h.Header, sufix: h.sufix };
          },
        );
      }

      this.setData();
      this.generateRows();
    } else {
      this.gridPeriodHeaders = props.gridPeriodHeaders;
      this.rows = props.rows;
      this.tableName = props.tableName;
    }

    if (this.props.tableName) {
      this.tableName = this.props.tableName;
    }
  }

  getMaxLevel = () => {
    if (this.props.exportPeriods) {
      return 5;
    }
    if (this.reportType === ReportsTypes.BalanceSheet.key) {
      return ReportsTableSizes[this.tableSize].maxLevel + 2;
    }
    return ReportsTableSizes[this.tableSize].maxLevel;
  };

  generateRows = () => {
    let rows = [];
    this.rows = [];
    this.taxes.forEach((c) => {
      this.createRows(c, this.getMaxLevel()).forEach((r) => {
        rows.push(r);
      });
    });
    this.rows = this.makeLeanRows(rows);
    this.props.onPropsChange &&
      this.props.onPropsChange({
        rows: this.rows,
        tableName: this.tableName,
        gridPeriodHeaders: this.gridPeriodHeaders,
      }, "props", false);
  };

  setData = () => {
    if (this.props.exportPeriods) {
      this.taxes = this.props.exportRows;
      this.gridPeriodHeaders = this.props.exportPeriods;
      return;
    }
    let driver = null;
    switch (this.reportType) {
      case ReportsTypes.ProfitLoss.key:
        driver = ProfitLoss.getReport();

        this.tableName = "Profit & Loss";

        this.taxes = driver.getReportTotals();
        break;

      case ReportsTypes.BalanceSheet.key:
        driver = BalanceSheet.getReport();
        this.tableName = "Balance Sheet";

        this.taxes = [driver.Assets, driver.getTotalLiabilitiesAndEquity()];

        break;
      case ReportsTypes.CashFlow.key:
        driver = CashFlow.getReport();
        this.tableName = "Cash Flow";

        this.taxes = [
          driver.NetCashFromOperatingActivities,
          driver.NetCashFromInvestingActivities,
          driver.NetCashFromFinancingActivities,
          driver.NetChangeInCash,
          driver.CashAtBeginningOfThePeriod,
          driver.CashAtEndOfThePeriod,
        ];
        break;
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    return false;
  }

  checkIfBoldRow = (revenue) => {
    switch (this.reportType) {
      case ReportsTypes.ProfitLoss.key:
        return (
          revenue.Ref_ID === "operating_expenses" ||
          revenue.Ref_Field === "gross_profit" ||
          revenue.Ref_Field === "ebitda" ||
          revenue.Ref_Field === "operating_profit" ||
          revenue.Ref_Field === "profit_before_tax" ||
          revenue.Ref_Field === "net_income" ||
          revenue.Ref_ID === 0 ||
          revenue.Ref_Table === "Finance_CostSales"
        );
      case ReportsTypes.CashFlow.key:
        return true;
      case ReportsTypes.BalanceSheet.key:
        return true;
    }
  };
  // getValue = ()

  createRows = (revenue, maxLevel = 0, isChild = false, level = 0, arr = []) => {
    if (this.props.exportRows) {
      if (revenue && revenue.data) {
        if (level) {
          arr.push({
            id: revenue.data.ID,
            header: revenue.data.DriverName.split("- ")[1]
              ? revenue.data.DriverName.split("- ")[1]
              : revenue.data.DriverName.split("- ")[0],
            boldRowHeader: revenue.boldRowHeader,
            data: revenue.data,
            level: level,
            colorType: revenue.colorType,
          });
          let children = revenue.children;

          if (maxLevel > level && children) {
            children.forEach((c) => {
              arr = [...arr, ...this.createRows(c, maxLevel, true, level + 1)];
            });
          }
        } else {
          arr.push({
            id: revenue.data.ID,
            header: revenue.data.DriverName.split("- ")[0]
              ? revenue.data.DriverName.split("- ")[0]
              : revenue.data.DriverName.split("- ")[1],
            boldRowHeader: revenue.boldRowHeader, //revenue.Ref_ID === "operating_expenses" || revenue.Ref_Field === "gross_profit" || revenue.Ref_Field === "ebitda" || revenue.Ref_Field === "operating_profit" || revenue.Ref_Field === "profit_before_tax" || revenue.Ref_Field === "net_income" || revenue.Ref_ID === 0 || revenue.Ref_Table === 'Finance_CostSales',
            data: revenue.data,
            level: level,
            colorType: revenue.colorType,
          });

          if (revenue.children && +revenue.children.length) {
            let children = revenue.children;

            if (maxLevel > level && children) {
              children.forEach((c) => {
                arr = [...arr, ...this.createRows(c, maxLevel, true, level + 1)];
              });
            }
          }
        }
      }
    } else {
      if (revenue) {
        if (level) {
          if (!revenue.isValid) {
            return arr;
          }
          arr.push({
            id: revenue.ID,
            header: revenue.DriverName.split("- ")[1]
              ? revenue.DriverName.split("- ")[1]
              : revenue.DriverName.split("- ")[0],
            boldRowHeader: false,
            data: revenue,
            level: level,
          });
          let children = revenue.getChildDrivers();

          if (maxLevel > level && children) {
            children.forEach((c) => {
              arr = [...arr, ...this.createRows(c, maxLevel, true, level + 1)];
            });
          }
        } else {
          const hiddenDrivers = ["gain_loss_from_asset_sales", "interest_expense"];
          if (!revenue.isValid || hiddenDrivers.includes(revenue.Ref_Field)) {
            return arr;
          }
          arr.push({
            id: revenue.ID,
            header: revenue.DriverName.split("- ")[0]
              ? revenue.DriverName.split("- ")[0]
              : revenue.DriverName.split("- ")[1],
            boldRowHeader: this.checkIfBoldRow(revenue), //revenue.Ref_ID === "operating_expenses" || revenue.Ref_Field === "gross_profit" || revenue.Ref_Field === "ebitda" || revenue.Ref_Field === "operating_profit" || revenue.Ref_Field === "profit_before_tax" || revenue.Ref_Field === "net_income" || revenue.Ref_ID === 0 || revenue.Ref_Table === 'Finance_CostSales',
            data: revenue,
            level: level,
          });

          if (revenue.getChildDrivers() && +revenue.getChildDrivers().length) {
            let children = revenue
              .getChildDrivers()
              .filter((driver) => !hiddenDrivers.includes(driver.Ref_Field));

            if (maxLevel > level && children) {
              children.forEach((c) => {
                arr = [...arr, ...this.createRows(c, maxLevel, true, level + 1)];
              });
            }
          }
        }
      }
    }

    return arr;
  };

  getFirstColumnWidth = () => {
    if (this.gridPeriodHeaders.length === 5) {
      return "25%";
    }
    return "14%";
  };

  generateTableHeaders = () => {
    return (
      <thead className={"s_e_ft_thead"}>
        <tr>
          <th className={"s_e_ft_th"} style={{ width: this.getFirstColumnWidth() }}>
            {this.tableName}
          </th>
          {this.gridPeriodHeaders.map((h, index) => {
            return (
              <th className={"s_e_ft_th"} key={`financeTableHeaderKey_${index}`}>
                {h.Header}
              </th>
            );
          })}
        </tr>
      </thead>
    );
  };

  getValue = (unitLeft, item, unitRight) => {
    let field = "Value";
    if (this.props.exportRows && !this.props.field) {
      field = "Actual";
    }
    let value = 0;

    if (item && !isNaN(item[field])) {
      value = item[field];
    }

    if (!Number.isFinite(value)) {
      value = "";
    }
    if (item.UnitParsed == "%") {
      unitLeft = false;
      unitRight = true;
    }
    return (
      (unitLeft ? item.UnitParsed + " " : "") +
      InputFormats.formatNumber(value) +
      (unitRight ? " " + item.UnitParsed : "")
    );
  };

  getClassNameByValue = (row, item, colIndex) => {
    let className = "";
    if (this.props.exportRows) {
      if (
        this.props.exportPeriods[colIndex].Header.includes("%") ||
        this.props.exportPeriods[colIndex].Header.includes(
          global.Modeliks.CompanyInfo.Currency.value,
        )
      ) {
        if (item) {
          let field = "Actual";
          if (this.props.field) {
            field = this.props.field;
          }
          if (!isNaN(item[field])) {
            if (item[field] > 0) {
              if (row.colorType.positiveGreen) {
                className = "color_success";
              } else {
                className = "color_warning";
              }
            }
            if (item[field] < 0) {
              if (row.colorType.positiveGreen) {
                className = "color_warning";
              } else {
                className = "color_success";
              }
            }
          }
        }
      }
    }
    return className;
  };

  makeLeanRows = (fatRows) => {
    let rows = [];
    fatRows.forEach((r) => {
      let unitLeft,
        unitRight = false;
      if (r.data.UnitType == UnitTypes.Percentage) {
        unitRight = true;
      } else if (r.data.UnitType == UnitTypes.Price) {
        unitLeft = true;
      }
      rows.push({
        header: r.header,
        headerClassName: r.boldRowHeader ? "bold" : "",
        paddingLeft: r.level * 10,
        values: this.gridPeriodHeaders.map((h, colIndex) => {
          let item = r.data.getItemByDateSufix(h.sufix);
          return {
            value: this.getValue(unitLeft, item, unitRight),
            className: this.getClassNameByValue(r, item, colIndex),
          };
        }),
      });
    });
    return rows;
  };

  handleAmountOfDigits = (value) => {
    if (value && this.biggestAmntOfDigits < value.length) {
      this.biggestAmntOfDigits = value.length;
    }
  };

  getPaddingBottom = () => {
    if (this.props.businessPlan) {
      return { paddingBottom: "3pt" };
    }
    return {};
  };

  TableRow = (row, index) => {
    return (
      <tr className={"s_e_ft_tb_tr"} key={`financeTableRowKey_${index}`}>
        <td
          style={{ paddingLeft: row.paddingLeft, ...this.getPaddingBottom() }}
          className={`s_e_ft_tb_td ${row.headerClassName}`}
        >
          {row.header}
        </td>
        {row.values.map((c, columnIndex) => {
          !this.props.exportRows && this.handleAmountOfDigits(c.value);
          return (
            <td
              key={`financeTableColumnKey_${index}-${columnIndex}`}
              style={this.getPaddingBottom()}
              className={`s_e_ft_tb_td ${row.headerClassName} ${c.className}`}
            >
              {c.value}
            </td>
          );
        })}
      </tr>
    );
  };

  generateTableRows = () => {
    return this.rows.map((r, index) => {
      return this.TableRow(r, index);
    });
  };

  componentDidMount() {
    this.setClassName();
  }

  setClassName = () => {
    if (this.biggestAmntOfDigits > 12) {
      if (this.className !== "small") {
        this.className = "small";
        this.forceUpdate();
      }
    } else if (this.biggestAmntOfDigits > 9) {
      if (this.className !== "medium") {
        this.className = "medium";
        this.forceUpdate();
      }
    } else {
      if (this.className !== "large") {
        this.className = "large";
        this.forceUpdate();
      }
    }
  };

  getDateTypeClassName = () => {
    if (this.dateType === tableDateType.year) {
      return " year";
    }
    return "";
  };

  getColumnRatio = () => {
    if (this.gridPeriodHeaders.length === 5) {
      return 0.8;
    } else {
      return 0.6;
    }
  };

  getSlideRatio = () => {
    if (this.props.businessPlan) {
      return 0.85;
    }
    return 1.3;
  };

  getYearlyOffset = () => {
    if (!this.props.exportRows) {
      if (this.dateType === tableDateType.year) {
        return 1.3;
      }
    }
    return 1;
  };

  setFontSize = () => {
    this.fontSize =
      fontRatio * this.getYearlyOffset() * this.getColumnRatio() * this.getSlideRatio();
  };

  render() {
    // this.setClassName();
    this.setFontSize();
    return (
      <div className={"s_e_financial_table_container"}>
        <table
          style={{ fontSize: `${this.fontSize}pt` }}
          className={"s_e_table" + this.getDateTypeClassName()}
        >
          {this.generateTableHeaders()}
          <tbody className={"s_e_ft_tbody"}>{this.generateTableRows()}</tbody>
        </table>
      </div>
    );
  }
}

export { FinanceTable, tableDateType };
