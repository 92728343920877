const ChartSizes = {
  annualChart: 341,
  annualChartM: 440,
  monthlyChart: 1080,
  monthlyChartM: 1350,
};

const ChartHelper = {
  setTextOverflow: (val, ctx) => {
    const ActiveYears = global.Modeliks.CompanyInfo.ActiveYears;
    const chartWidth = ctx.chart.width;
    let IsAnnualChart = true;
    let NumYears = 3;
    if (ctx.dataset.data.length > 5) {
      IsAnnualChart = false;
    }
    let MaxChartLength = 12;

    if (IsAnnualChart && chartWidth >= ChartSizes.annualChart) {
      if (chartWidth > ChartSizes.annualChartM) {
        if (ActiveYears === 3) {
          MaxChartLength = 22;
        } else {
          MaxChartLength = 12;
        }
      } else {
        if (ActiveYears === 3) {
          MaxChartLength = 22;
        } else {
          MaxChartLength = 12;
        }
      }
    }

    if (!IsAnnualChart && chartWidth >= ChartSizes.monthlyChart) {
      MaxChartLength = 17;
      if (chartWidth > ChartSizes.monthlyChartM) {
        MaxChartLength = 17;
      }
    }

    if (typeof val === "string" && val.length > MaxChartLength) {
      if (!IsAnnualChart && chartWidth < ChartSizes.monthlyChart) {
        return `${val.substring(0, MaxChartLength + 3)}...`;
      }

      if (IsAnnualChart && chartWidth < ChartSizes.annualChart) {
        return `${val.substring(0, MaxChartLength + 3)}...`;
      }
      return `${val.substring(0, MaxChartLength)}...`;
    }

    return val;
  },
};

export default ChartHelper;
