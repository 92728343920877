const ReportsTypes = {
  ProfitLoss: {
    Name: "Profit and Loss",
    ShortName: "PL",
    key: "profit_loss",
    show: true,
  },
  BalanceSheet: {
    Name: "Balance Sheet",
    ShortName: "BS",
    key: "balance_sheet",
    show: true,
  },
  CashFlow: {
    Name: "Cash Flow",
    ShortName: "CF",
    key: "cash_flow",
    show: true,
  },
  ReportingDrivers: {
    Name: "Reporting Drivers",
    ShortName: "RD",
    key: "reporting_drivers",
    show: false,
  },
  SaaSSubscriptionReportingDrivers: {
    Name: "SaaS Subscription Reporting Drivers",
    ShortName: "SaaSSubscription",
    key: "saas_subscription_reporting_drivers",
    show: false,
  },
};

const ReportsTableSizes = {
  Short: {
    size: "Short",
    maxLevel: 0,
  },
  Detailed: {
    size: "Detailed",
    maxLevel: 1,
  },
};

export { ReportsTypes, ReportsTableSizes };
