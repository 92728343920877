import React, { Component } from "react";
import Index from "../../../../../SlideEditor/User";
import request from "superagent";
import { IconX, Refresh } from "../../../../../components/icons/svgIcons";
import ButtonMui from "../../../../../components/buttons/buttonMui/buttonMui";
import {
  EditIcon,
  Tick,
} from "../../../../../SlideEditor/components/components/menus/LeftMenu/components/Icons/LeftMenuIcons";
import Input from "../../../../../components/actions/Input";
import "./UserMngReport.scss";
import {PermissionTypes} from "../../../../../components/constants/permissions";
import {CommandManagerContext} from "../../../../../SlideEditor/services/commands/CommandManagerProvider";
import pitchFlows from "../../../../../SlideEditor/services/pitchFlows";

class UserMngReport extends Index {
  SlideSectionsTableName = "MR_Sections";
  TemplatesTableName = "MR_Templates";
  TemplateNamePrefix = "mr_";
  SectionNameKey = "Name";
  ScenarioNamePrefix = " MGMT Report";
  scale = {
    enabled: false,
    rightMenu: false,
    textObjs: [],
  };

  constructor(props) {
    super(props);

    this.documentTitle = props.publishMode
      ? ""
      : `${this.props.PitchScenarioInfo.Name}_${global.Modeliks.CompanyInfo.CompanyName}`;
    this.leftMenuTopOffset = 250;
    this.PDFTimeout = 1200;

    this.SlideSectionsTableName = global.Modeliks.Tables.MR_Sections.TableName;
    this.TemplatesTableName = global.Modeliks.Tables.MR_Templates.TableName;
    this.PitchScenarioIDKey = "ReportID";
    this.type = "management_reports";
    this.hideInstructions = true;
    this.hideTopButton = true;
    this.topButtonText = <ReportName report={this.props.PitchScenarioInfo} />;
    this.rightExpandedMenuClass = " hidden";
    this.leftMenuOffset = 45;
    this.AdditionalClassName = " management_reports";

    if (!this.props.publishMode) {
      this.EditPermission = global.Modeliks.UserPermissions[PermissionTypes.Dashboards] > 0;
    }
    this.PitchFlows = pitchFlows({
      SlideSectionsTableName: this.SlideSectionsTableName,
      TemplatesTableName: this.TemplatesTableName,
      SectionNameKey: this.SectionNameKey,
      PitchScenarioIDKey: this.PitchScenarioIDKey
    });

    this.editor_type = "management_reports";
    this.addBlankSection = this.addBlankSection.bind(this);
    this.LoadingFunc = this.LoadingFunc.bind(this);
    this.createSection = this.createSection.bind(this);
    this.getSecondaryHeaderButton = this.getSecondaryHeaderButton.bind(this);
    this.getAllCategories = this.getAllCategories.bind(this);
    this.dontShowTemplates = this.dontShowTemplates.bind(this);
  }

  async addBlankSection(callBack) {
    const section = {
      Name: "Blank Slide",
      ReportID: this.PitchScenarioInfo.ID,
      isBlank: true,
      Templates: [{}],
    }
    this.createSection(section, callBack);
  }

  hasOverflowY(element) {
    return element.scrollHeight != Math.max(element.offsetHeight, element.clientHeight);
  }

  beforeSlidePrint(callBack) {
    this.props.handleTOCUpdate(this.SlideSections, false, () => {
      super.beforeSlidePrint(callBack);
    });
  }

  dontShowTemplates() {
    return this.state.selectedSection.Templates.length == 1;
  }

  afterSlidePrint(callBack) {
    document.getElementById("style1").media = "false";
    this.DefaultTemplates = [];
    let slidesContainer = document.getElementById("printableContainer");
    let arr = [];
    for (let i = 0; i < slidesContainer.children.length; i++) {
      arr.push(slidesContainer.children[i]);
    }
    arr.forEach((c) => slidesContainer.removeChild(c));
    this.setState({ printEnabled: false }, callBack && callBack());
  }

  static getDerivedStateFromError(error) {
    return { printEnabled: false };
  }

  handleSlideObjectsBeforePrint(callBack) {
    const rowsLimit = 21;
    setTimeout(() => {
      let slidesContainer = document.getElementById("printableContainer");
      if (slidesContainer) {
        this.MainFinanceTableMapping.forEach((c) => {
          const tableContainingSlide = slidesContainer.children[c.templateIndex];
          const table = this.findChildElement(
            tableContainingSlide.children[c.slideObjectIndex],
            "table",
          );
          if (!table) {
            return;
          }
          if (!this.hasOverflowY(table.parentElement)) {
            console.log("i dont have overflow somehow");
            // return
          }
          const tableBody = this.findChildElement(table, "tbody") ?? table.children[1];
          const nextSlide = slidesContainer.children[c.templateIndex + 1];
          const lengthOfTableSlides = Math.ceil(tableBody.children.length / rowsLimit);

          let rows = [...tableBody.children];
          let splitPoints = [];

          let currentSplitPoint = null;

          rows.forEach((row, index) => {
            const classList = [...row.children[0].classList];
            if (classList.includes("split_point")) {
              if (currentSplitPoint) {
                if (currentSplitPoint.startIndex != currentSplitPoint.endIndex) {
                  splitPoints.push(currentSplitPoint);
                }
              }
              currentSplitPoint = {
                startIndex: index,
                row,
                endIndex: index,
              };
            } else if (classList.includes("split_child")) {
              if (currentSplitPoint) {
                currentSplitPoint.endIndex = index;
              }
            }
          });
          if (currentSplitPoint && currentSplitPoint.startIndex != currentSplitPoint.endIndex) {
            splitPoints.push(currentSplitPoint);
          }

          let arrayOfTables = [];

          let currentTableSize = 0;
          let currentTableIndex = 0;
          let currentStartIndex = 0;
          let currentEndIndex = 0;
          let currentSplitPointIndex = 0;
          let extraRowIndexes = [];

          rows.forEach((row, index) => {
            if (!currentTableSize) {
              currentTableIndex = arrayOfTables.length;
              arrayOfTables.push({});
            }

            if (
              splitPoints[currentSplitPointIndex] &&
              splitPoints[currentSplitPointIndex].startIndex == index
            ) {
              //the start of the section is here
              currentStartIndex = index;
              currentEndIndex = splitPoints[currentSplitPointIndex].endIndex;

              //if the end of the section exceeds the max table size

              let sectionLength =
                splitPoints[currentSplitPointIndex].endIndex -
                splitPoints[currentSplitPointIndex].startIndex +
                1;
              let calculatedSize = rowsLimit - currentTableSize - sectionLength;
              if (calculatedSize == 0) {
                //ako sa tocno kolku sto i prazniot prostor :D
                arrayOfTables[currentTableIndex][index] = true;
                currentTableSize++;
              } else if (calculatedSize > 0) {
                //ako sa pomalku od prazniot prostor :D
                arrayOfTables[currentTableIndex][index] = true;
                currentTableSize++;
              } else {
                //ako sa povise redovi od prazen prostor
                if (sectionLength > rowsLimit) {
                  //ako sa povise redovi od tabela NOTE: treba da se vidi kolku redovi ako sa mozda i da ne treba odma da se deli
                  extraRowIndexes.push(splitPoints[currentSplitPointIndex].startIndex);
                }
                if (currentTableSize != 0) {
                  currentTableSize = 0;
                  currentTableIndex = arrayOfTables.length;
                  arrayOfTables.push({});
                }
                arrayOfTables[currentTableIndex][index] = true;
                currentTableSize++;
              }
            } else if (
              splitPoints[currentSplitPointIndex] &&
              splitPoints[currentSplitPointIndex].endIndex == index
            ) {
              currentSplitPointIndex++;
              arrayOfTables[currentTableIndex][index] = true;
              currentTableSize++;
            } else {
              arrayOfTables[currentTableIndex][index] = true;
              currentTableSize++;
            }

            if (currentTableSize == rowsLimit) {
              currentTableSize = 0;
            }
          });
          let numberOfColumnSplittingTables = 1;
          if (rows.length) {
            if (rows[0].children.length > 13) {
              numberOfColumnSplittingTables++;
            }
            for (let i = 0; i < arrayOfTables.length; i++) {
              for (let CP = 0; CP < numberOfColumnSplittingTables; CP++) {
                let clonedNode = tableContainingSlide.cloneNode(true);
                const tableBodyCurrent = this.findChildElement(clonedNode, "tbody");
                const tableHeadCurrent = this.findChildElement(clonedNode, "thead");
                const headerRow = tableHeadCurrent.children[0];
                let min = CP * 12 + 1;
                let max = (CP + 1) * 12;
                if (headerRow) {
                  let colArrToBeRemoved = [];
                  for (let j = 1; j < headerRow.children.length; j++) {
                    if (j < min || j > max) {
                      colArrToBeRemoved.push(headerRow.children[j]);
                    }
                  }
                  colArrToBeRemoved.forEach((c) => headerRow.removeChild(c));
                }
                if (tableBodyCurrent && tableBodyCurrent.children) {
                  let arrToBeRemoved = [];
                  [...tableBodyCurrent.children].forEach((r, index) => {
                    if (!arrayOfTables[i][index]) {
                      arrToBeRemoved.push(tableBodyCurrent.children[index]);
                    } else {
                      let colArrToBeRemoved = [];
                      for (let j = 1; j < r.children.length; j++) {
                        if (j < min || j > max) {
                          colArrToBeRemoved.push(r.children[j]);
                        }
                      }
                      colArrToBeRemoved.forEach((c) => r.removeChild(c));
                    }
                  });
                  arrToBeRemoved.forEach((c) => tableBodyCurrent.removeChild(c));
                }
                slidesContainer.insertBefore(clonedNode, nextSlide);
              }
            }

            // for (let i = 0; i < lengthOfTableSlides; i++) {
            //     let clonedNode = tableContainingSlide.cloneNode(true)
            //     const tableBodyCurrent = this.findChildElement(clonedNode, 'tbody');
            //     if (tableBodyCurrent && tableBodyCurrent.children) {
            //         let arrToBeRemoved = [];
            //         for (let j = 0; j < tableBodyCurrent.children.length; j++) {
            //             if (j < (i * rowsLimit) || j >= (i * rowsLimit + rowsLimit)) {
            //                 arrToBeRemoved.push(tableBodyCurrent.children[j])
            //             }
            //         }
            //         arrToBeRemoved.forEach(c => tableBodyCurrent.removeChild(c))
            //     }
            //     slidesContainer.insertBefore(clonedNode, nextSlide);
            // }
            try {
              slidesContainer.removeChild(tableContainingSlide);
            } catch (e) {}
          }
        });
      }
      callBack && callBack();
    }, 200);
  }

  createSection(section, callBack, duplicate) {
    const callBackFunc = () =>
      this.LoadingFunc(
        callBack,
        section.ID == this.state.selectedSection.ID,
        this.PitchScenarioInfo.ID,
      );
    const insertFunction = () => {
      global.Modeliks.post(this.SlideSectionsTableName, section, (data) => {
        section.Templates.forEach((c) => {
          delete c.ID;
          c.SectionID = data.id;
        });
        global.Modeliks.post(this.TemplatesTableName, section.Templates, callBackFunc);
      });
      if (section.hasOwnProperty("CategoryID")) {
        section.Name = section.SectionName;
        section.ReportID = this.PitchScenarioInfo.ID;
        section.order = this.state.selectedSection.order + 1;
        section.Templates.forEach((c) => {
          c.MasterTemplateID = c.ID;
          c.isCustom = false;
        });
      } else if (section.isBlank) {
        section.order = this.state.selectedSection.order + 1;
      } else {
        this.handleDuplicate(section, callBack);
      }
    }
    insertFunction();
  }

  addSection(section, callBack, duplicate = false) {
    if (section.SectionName) {
      section.Name = section.SectionName;
      section.ReportID = this.PitchScenarioInfo.ID;
    }
    super.addSection(section, callBack, duplicate);
  }

  hideTemplateButtons() {
    return true;
  }

  getSecondaryHeaderButton(section) {
    return {
      onClick: section.isTableOfContent
        ? () => this.props.handleTOCUpdate(this.SlideSections)
        : () => this.props.handleDataUpdate(this.state.selectedSection.ID),
      canPreview: true,
      label: section.isTableOfContent ? "Update Data" : "Financials",
      variant: section.isTableOfContent ? "text" : undefined,
      startIcon: <Refresh />,
    };
  }

  getAllCategories(callBack) {
    global.Modeliks.get('/api/getAdminCategories', {}, (data) => {
      this.Categories = data.categories
        .filter((c) => c.CategoryName == "Tables" || c.CategoryName == "Charts");
      this.Categories
        .forEach((c) => {
        c.Sections = c.Sections.filter(c => !c.IndustryID);
      });
        callBack && callBack();
    }, undefined, false, false, true)
  }

  LoadingFunc(callBack, shouldSave = false, pitchScenarioID = null) {
    if (shouldSave) {
      this.saveContent(
        this.state.selectedTemplate.ID,
        this.jsonFunc.returnJsonString(),
        () => {
          this.getAllCategories(() => {
            this.getSections(
              () => {
                this.getTemplates(() => {
                  this.getTemplateContent(callBack && callBack());
                  this.updateOrder(false);
                }, TemplatesTableName);
              },
              SlideSectionsTableName,
              { ReportID: this.props.Report.ID },
            );
          });
        },
        this.AccountID,
        global.Modeliks.CompanyInfo.ID,
        pitchScenarioID,
        false,
        false,
        undefined,
        false,
      );
    } else {
      this.getSections(
        () => {
          this.getAllCategories(() => {
            this.getTemplates(() => {
              this.getTemplateContent(callBack && callBack());
              this.updateOrder(false);
            }, TemplatesTableName);
          });
        },
        SlideSectionsTableName,
        { ReportID: this.props.Report.ID },
      );
    }
  }

  handleDeleteSection = (section, index, callBack) => {
    global.Modeliks.del(global.Modeliks.Tables.MR_Sections.TableName, { ID: section.ID }, () => {});
    this.SlideSections.splice(index, 1); // = this.sortSections(filteredSections);
    delete this.SlideSectionIndexes[section.ID];
    this.setState(
      {
        selectedSection: this.SlideSections[0],
        selectedTemplate: this.SlideSections[0].Templates[0],
      },
      () => {
        this.state.zoom = 1;
        this.state.footerZoomVal = 1;
        this.getTemplateContent();
      },
    );
  };
}

const ReportName = (props) => {
  const [editMode, setEditMode] = React.useState(false);
  const [Name, setName] = React.useState(props.report.Name);

  const handleOnChange = (e) => {
    setName(e.target.value);
  };

  const saveName = () => {
    props.report.Name = Name;
    props.report.Save && props.report.Save();
    setEditMode(false);
  };

  const exit = () => {
    setName(props.report.Name);
    setEditMode(false);
  };

  if (editMode) {
    return (
      <div className={"flex"}>
        <Input value={Name} onChange={handleOnChange} />
        <ButtonMui
          label={<Tick />}
          variant={"text"}
          style={{ height: "28px", padding: "2px" }}
          width={28}
          onClick={saveName}
          isIconButton
          tooltipText={"Edit"}
        />
        <ButtonMui
          label={<IconX />}
          variant={"text"}
          style={{ height: "28px", padding: "2px" }}
          color={"red"}
          width={28}
          onClick={exit}
          isIconButton
          tooltipText={"Edit"}
        />
      </div>
    );
  }
  return (
    <div className={"flex hidden_overflow"}>
      <div className={"r_name"}>{Name}</div>
      <ButtonMui
        label={<EditIcon />}
        variant={"text"}
        onClick={() => setEditMode(true)}
        isIconButton
        tooltipText={"Edit"}
      />
    </div>
  );
};
UserMngReport.contextType = CommandManagerContext;

const SlideSectionsTableName = "MR_Sections";
const TemplatesTableName = "MR_Templates";
export default UserMngReport;
