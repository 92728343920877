import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import BusinessPlanUser from "./index";
import Preview from "./preview";
import SlideHeaderContainer from "../../SlideEditor/components/components/SlideHeaderContainer";

class BpRoutes extends Component {
  render() {
    return (
      <Routes>
        <Route path="/" element={<BusinessPlanUser />}></Route>
        <Route path="/preview" element={<Preview />}></Route>
      </Routes>
    );
  }
}

export default BpRoutes;
