import React, { Component } from "react";
import { DialogTitle, DialogContent, DialogActions } from "@mui/material";
import ButtonMui from "../../../buttons/buttonMui/buttonMui";

class SimpleDialog2 extends Component {
  render() {
    const { title, content, actionProps } = this.props.config;

    return (
      <>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{content}</DialogContent>
        <DialogActions>
          {actionProps.map((c) => (
            <ButtonMui
              label={c.label}
              onClick={c.isCancel ? this.props.onCancel : c.onClick}
              variant={c.variant}
              color={c.color}
            />
          ))}
        </DialogActions>
      </>
    );
  }
}

export default SimpleDialog2;
