import React from "react";
import "./FinancialMainCharts.scss";
import { ChartMenu } from "../../../../../../components/chart/components/ChartMenu";
import FinancialMainBreakDownCharts from "./FinancialMainBreakDownCharts";
import FinancialMainTotalCharts from "./FinancialMainTotalCharts";
import CalculatedDriver from "../../../../../../data/Finance/CalculatedDriver";
import Revenue from "../../../../../../data/Finance/Revenue";
import CostSales from "../../../../../../data/Finance/CostSales";
import LocalStorage from "../../../../../../helpers/LocalStorage";
import {
  DriverCategories,
  UnitTypes,
} from "../../../../../../data/Finance/CalculatedDriver/constants";
import {
  chartConfig,
  chartConfigTypes,
  chartObject,
  tableConfig,
  tableConfigTypes,
  tableObject,
} from "../../../../../../components/tables/FinanceTable/components/constants";
import { NumberFormatTypes } from "../../../../../../components/constants/charts";

if (LocalStorage.getByKeyFromLocalStorageObjects(chartObject, chartConfig) === null) {
  LocalStorage.appendObjectsToLocalStorage(
    chartObject,
    Object.assign({
      [chartConfig]: {
        showGrowth: false,
        showMargin: false,
        showBreakdown: false,
        showNumberOfEmployees: false,
      },
    }),
  );
}

class FinancialMainCharts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      showGrowth: false,
      showMargin: false,
      showBreakdown: true,
      showNumberOfEmployees: false,
      unitValue: global.Modeliks.DashboardSettings.Value.financialDates.numberFormatType,
      monthValues: [],
      yearValues: [],
      monthDatasets: [],
      yearDatasets: [],
      monthChange: [],
      yearChange: [],
      chartKey: "chart_" + new Date().getTime(),
      hideChart: false,
    };

    this.colors = ["#00807E", "#00B3B0", "#00E6E2", "#ADF6F7"];

    if (LocalStorage.getByKeyFromLocalStorageObjects(chartObject, chartConfig) !== null) {
      const chartObjectLocal = LocalStorage.getByKeyFromLocalStorageObjects(
        chartObject,
        chartConfig,
      );
      this.state = {
        showGrowth: chartObjectLocal.showGrowth,
        showMargin: chartObjectLocal.showMargin,
        showBreakdown: chartObjectLocal.showBreakdown,
        showNumberOfEmployees: chartObjectLocal.showNumberOfEmployees,
      };
    }

    this.selectedRevenuesTotalsDriver = global.Modeliks.DriversStore.getItem(
      `ChartTemp-${this.props.totals.Ref_ID}-${this.props.totals.Ref_Table}-temp_totals`,
    );
    if (!this.selectedRevenuesTotalsDriver) {
      this.selectedRevenuesTotalsDriver = CalculatedDriver.createDriver(
        "ChartTemp",
        `${this.props.totals.Ref_ID}-${this.props.totals.Ref_Table}`,
        "temp_totals",
        UnitTypes.Price,
        DriverCategories.Sum,
        "Chart",
        true,
        false,
      );
    }
    this.selectedRevenuesTotalsDriver.GrowthDriver = Revenue.getRevenueGrowth(
      this.selectedRevenuesTotalsDriver,
      false,
    );
    if (this.props.showMargin) {
      this.selectedRevenuesTotalsDriver.MarginDriver = CostSales.getCostSalesMarginFromRevenue();
    }

    if (this.props.showNumberOfEmployees) {
      this.selectedRevenuesTotalsDriver.numberOfEmployeesDriver =
        this.props.numberOfEmployeesDriver;
    }
  }
  setBrakedown = () => {
    this.state.showBreakdown = !this.state.showBreakdown;
    LocalStorage.updateValueFromObject(
      chartObject,
      chartConfig,
      chartConfigTypes.showBreakdown,
      this.state.showBreakdown,
    );
    this.state.chartKey = "chart_" + new Date().getTime();
    this.forceUpdate();
  };

  setGrowth = () => {
    if (this.state.showMargin) {
      LocalStorage.updateValueFromObject(
        chartObject,
        chartConfig,
        chartConfigTypes.showMargin,
        false,
      );
      this.state.showMargin = false;
    }

    if (this.state.showNumberOfEmployees) {
      LocalStorage.updateValueFromObject(
        chartObject,
        chartConfig,
        chartConfigTypes.showNumberOfEmployees,
        false,
      );
      this.state.showNumberOfEmployees = false;
    }

    LocalStorage.updateValueFromObject(
      chartObject,
      chartConfig,
      chartConfigTypes.showGrowth,
      !this.state.showGrowth,
    );
    this.state.showGrowth = !this.state.showGrowth;
    this.state.chartKey = "chart_" + new Date().getTime();
    this.forceUpdate();
  };

  setMargin = () => {
    if (this.state.showGrowth) {
      LocalStorage.updateValueFromObject(
        chartObject,
        chartConfig,
        chartConfigTypes.showGrowth,
        false,
      );
      this.state.showGrowth = false;
    }
    LocalStorage.updateValueFromObject(
      chartObject,
      chartConfig,
      chartConfigTypes.showMargin,
      !this.state.showMargin,
    );
    this.state.showMargin = !this.state.showMargin;
    this.state.chartKey = "chart_" + new Date().getTime();
    this.forceUpdate();
  };

  setNumberOfEmployees = () => {
    if (this.state.showGrowth) {
      LocalStorage.updateValueFromObject(
        chartObject,
        chartConfig,
        chartConfigTypes.showGrowth,
        false,
      );
      this.state.showGrowth = false;
    }
    LocalStorage.updateValueFromObject(
      chartObject,
      chartConfig,
      chartConfigTypes.showNumberOfEmployees,
      !this.state.showNumberOfEmployees,
    );
    this.state.showNumberOfEmployees = !this.state.showNumberOfEmployees;
    this.state.chartKey = "chart_" + new Date().getTime();
    this.forceUpdate();
  };

  setUnitValue = (value) => {
    global.Modeliks.DashboardSettings.Value.financialDates.numberFormatType = value;
    global.Modeliks.DashboardSettings.Value = JSON.stringify(
      global.Modeliks.DashboardSettings.Value,
    );
    global.Modeliks.DashboardSettings.Save();
    global.Modeliks.DashboardSettings.Value = JSON.parse(global.Modeliks.DashboardSettings.Value);
    LocalStorage.updateValueFromObject(
      tableObject,
      tableConfig,
      tableConfigTypes.displayOfDecimals,
      NumberFormatTypes[value].numDecimals,
    );
    this.setState(
      { unitValue: value, chartKey: "chart_" + new Date().getTime() },
      () => this.props.updateTable && this.props.updateTable(),
    );
  };

  changeValue = (index, value) => {
    let newData = [];

    for (let i = 0; i < index; i++) {
      newData.push(this.state.data[i]);
    }
    newData.push(value);
    for (let i = index + 1; i < this.state.data.length; i++) {
      newData.push(this.state.data[i]);
    }

    this.setState({ data: newData });
    this.forceUpdate();
  };

  changeChartView = () => {
    this.setState({ hideChart: !this.state.hideChart });
  };

  render() {
    this.growthDriver = this.props.growthDriver;
    this.marginDriver = this.props.marginDriver;
    this.numberOfEmployeesDriver = this.props.numberOfEmployeesDriver;

    return (
      <div className={`financial_main_charts_wrapper ${this.state.hideChart && "hide_chart"}`}>
        <div className={"financial_main_charts_menu"}>
          <ChartMenu
            growth={this.state.showGrowth}
            margin={this.props.showMargin && this.state.showMargin}
            numberOfEmployees={this.props.showNumberOfEmployees && this.state.showNumberOfEmployees}
            setGrowth={this.setGrowth}
            setMargin={this.setMargin}
            setNumberOfEmployees={this.setNumberOfEmployees}
            brakedown={this.state.showBreakdown}
            setBrakedown={this.setBrakedown}
            unitValue={this.state.unitValue}
            setUnitValue={this.setUnitValue}
            showMargin={this.props.showMargin}
            showNumberOfEmployees={this.props.showNumberOfEmployees}
            hideChart={!this.state.hideChart}
            onChangeChartView={this.changeChartView}
          />
        </div>

        {!this.state.hideChart && (
          <>
            {this.props.barData || !this.state.showBreakdown ? (
              <FinancialMainTotalCharts
                unitValue={global.Modeliks.DashboardSettings.Value.financialDates.numberFormatType}
                revenueTotals={this.props.totals}
                periodHeaders={this.props.periodHeaders}
                monthValues={this.state.monthValues}
                yearValues={this.state.yearValues}
                monthChange={this.state.monthChange}
                isItalic={this.state.showGrowth}
                useShowHideCurrency={this.props.useShowHideCurrency}
                yearChange={this.state.yearChange}
                showDriver={
                  this.state.showGrowth || this.state.showMargin || this.state.showNumberOfEmployees
                }
                Driver={
                  this.props.showMargin && this.state.showMargin
                    ? this.marginDriver
                    : this.props.showNumberOfEmployees && this.state.showNumberOfEmployees
                      ? this.numberOfEmployeesDriver
                      : this.growthDriver
                }
                headers={this.props.headers}
              />
            ) : (
              <FinancialMainBreakDownCharts
                showGrowth={
                  this.state.showGrowth ||
                  (this.props.showMargin && this.state.showMargin) ||
                  (this.props.showNumberOfEmployees && this.state.showNumberOfEmployees)
                }
                showMargin={this.props.showMargin && this.state.showMargin}
                showNumberOfEmployees={
                  this.props.showNumberOfEmployees && this.state.showNumberOfEmployees
                }
                DriverName={
                  this.props.showMargin && this.state.showMargin
                    ? "MarginDriver"
                    : this.props.showNumberOfEmployees && this.state.showNumberOfEmployees
                      ? "numberOfEmployeesDriver"
                      : "GrowthDriver"
                }
                headers={this.props.headers}
                breakdownName={this.props.breakdownName}
                totalsDriver={this.selectedRevenuesTotalsDriver}
                useShowHideCurrency={this.props.useShowHideCurrency}
                isPercentage={
                  !(this.props.showNumberOfEmployees && this.state.showNumberOfEmployees)
                }
                unitValue={global.Modeliks.DashboardSettings.Value.financialDates.numberFormatType}
                revenues={this.props.revenues}
                revenueTotals={this.props.totals}
                periodHeaders={this.props.periodHeaders}
                monthChange={this.state.monthChange}
                yearChange={this.state.yearChange}
              />
            )}
          </>
        )}
      </div>
    );
  }
}

export default FinancialMainCharts;
