import React from "react";
import "../../App.scss";
import SlideComponent from "../../SlideEditor/components/SlideComponent";
import { SlideObject, SlideTypes } from "../../SlideEditor/components/SlideObjects/SlideStructures";
import Box from "@mui/material/Box";
import Footer from "../../SlideEditor/components/components/Footer/ScalePanelFooter";
import request from "superagent";

import "../../SlideEditor/components/MenuObject.scss";
import html2canvas from "html2canvas";

import RightMenu from "../../SlideEditor/components/components/RightMenuComponents/RightMenu";
import LeftMenu from "../../SlideEditor/components/components/menus/LeftMenu/LeftMenu";
import { Logo } from "../../components/icons/svgIcons";
import ChartsComponent from "../../SlideEditor/components/ChartsComponent";
import BarChart from "../../SlideEditor/Admin/Charts/BarChart";
import CircleLoader from "../../components/Loaders/CircleLoader/CircleLoader";
import StackedBarChart from "../../SlideEditor/Admin/Charts/StackedBarChart";
import WaterfallChart from "../../SlideEditor/Admin/Charts/WaterfallChart";
import HorizontalBarChart from "../../SlideEditor/Admin/Charts/HorizontalBarChart";
import LineChart from "../../SlideEditor/Admin/Charts/LineChart";
import { ChartTypes } from "../../components/constants/charts";
import SlideHeaderContainer from "../../SlideEditor/components/components/SlideHeaderContainer";
import { CommandManagerContext } from "../../SlideEditor/services/commands/CommandManagerProvider";

const scale = {
  enabled: false,
  rightMenu: true,
  textObjs: [],
};

class ScreenTypes {
  constructor(props) {
    this.ScreenName = props.ScreenName;
    this.draggable = props.draggable;
    this.TabKey = props.TabKey;
  }
}

const screenTypes = [];

class Index extends React.Component {
  CompanyInfo = [];
  Account = null;

  constructor(props) {
    super(props);
    this.Categories = [];
    this.DefaultTemplates = [];

    this.getAllIndustries(() => {
      this.getAllCategories(() => {
        this.getAllTemplatesData();
      });
    });

    this.getDashboardCharts();

    this.EditingScreens = [
      new ScreenTypes({
        ScreenName: "Pitch Sections",
        draggable: true,
        TabKey: "SectionName",
      }),
      new ScreenTypes({
        ScreenName: "Pitch Charts",
        draggable: false,
        TabKey: "Name",
      }),
      new ScreenTypes({
        ScreenName: "Dashboard Charts",
        draggable: false,
        TabKey: "Name",
      }),
    ];

    this.dashboardCharts = [];

    this.shouldPasteInPlace = false;
    this.timesPasted = 0;

    this.state = {
      categories: [],
      selectedCategory: "",
      sections: [],
      selectedSectionID: "",
      templates: [],
      open: false,
      selectedTemplateID: null,
      templateKey: "template_" + new Date().getTime(),
      slideKey: "slideComponent_" + new Date().getTime(),
      jsonData: "",
      printEnabled: false,
      CurrentScreen: 0,
      selectedDashboardChart: null,
    };
  }

  componentDidMount() {}

  createNewChartObject = (type = ChartTypes.BarChart.name, chartData = {}, ID, Name) => {
    switch (type) {
      case ChartTypes.BarChart.name:
        return new BarChart({ ...chartData, ID: ID, Name: Name });
      case ChartTypes.StackedBarChart.name:
        return new StackedBarChart({ ...chartData, ID: ID, Name: Name });
      case ChartTypes.HorizontalBarChart.name:
        return new HorizontalBarChart({ ...chartData, ID: ID, Name: Name });
      case ChartTypes.LineChart.name:
        return new LineChart({ ...chartData, ID: ID, Name: Name });
      case ChartTypes.WaterfallChart.name:
        return new WaterfallChart({ ...chartData, ID: ID, Name: Name });
    }
    return null;
  };

  getAllIndustries = (callBack) => {
    fetch("/api/master/BusinessPlanIndustries")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.Industries = [{ ID: null, Name: "No Industry" }];
        data.resultIndustries.forEach((c) => {
          this.Industries.push(c);
        });
        this.selectedIndustry = this.Industries[0];
        callBack && callBack();
      })
      .catch((err) => console.error(":(", err));
  };

  getDashboardCharts = () => {
    request
      .get(`/api/master/dashboardcharts`)
      .set("authorization", "Bearer " + window.localStorage.getItem("token"))
      .then((res) => {
        if (res) {
          if (res.body.length) {
            res.body.forEach((chart) => {
              let chartData = JSON.parse(chart.Data);
              this.dashboardCharts.push({ ...chart, Data: chartData });
            });
            this.state.selectedDashboardChart = this.dashboardCharts[0];
          } else {
            this.dashboardCharts.push(new BarChart({ ID: 1, Name: "chart.Name" }));
            this.state.selectedDashboardChart = this.dashboardCharts[0];
          }
        }
      })
      .catch((err) => console.error(err));
  };

  setShouldPasteInPlace = (val) => {
    this.shouldPasteInPlace = val;
    this.forceUpdate();
  };

  setTimesPasted = (val) => {
    this.timesPasted = val;
    this.forceUpdate();
  };

  isLinkValid = () => {
    const account_guid = window.location.href.split("/")[4];
    const client_guid = window.location.href.split("/")[5];

    request
      .get(`/api/publish?account_guid=${account_guid}&client_guid=${client_guid}`)
      .then((res) => {
        if (res.text !== "0") {
          let obj = JSON.parse(res.text);
          request
            .get(`/api/getPublishTemplates?client_id=${obj.client_id}&company_id=${obj.company_id}`)
            .then((res2) => {
              if (res2.text !== "0") {
                let templatesArr = JSON.parse(res2.text);
                this.setState({ index: 0, templatesArray: templatesArr, open: true });
              }
            });
        }
      });
  };

  getAllCategories = (changeCategory = true) => {
    fetch("/api/master/BusinessPlanCategories")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        this.Categories = data.categories.filter((c) => c.CategoryName !== "Custom");
        this.Categories.forEach((c) => {
          c.Sections = c.Sections.filter((c) => c.IndustryID === this.selectedIndustry.ID);
        });
        this.changeCategory(data.categories[0].CategoryName, changeCategory);
      })
      .catch((err) => console.error(":(", err));
  };

  updateState = () => {
    this.forceUpdate();
  };

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  saveContent = (id, jsonString, isCustom = false, callback) => {
    let api = "/api/master/";
    this.saving = true;
    this.forceUpdate();

    html2canvas(document.getElementById("se_scale_panel"), {
      allowTaint: true,
      useCORS: true,
    })
      .then((canvas) => {
        // It will return a canvas element
        let image = canvas.toDataURL("image/png", 0.5);
        request
          .post(api + "uploadImage")
          .send({ TemplateID: id, base64str: image, businessPlan: true })
          .then((err, res) => {
            request
              .post(api + "slideObject")
              .send({ TemplateID: id, jsonString: jsonString, businessPlan: true })
              .then((err, res) => {
                this.saving = false;
                global.Modeliks.SaveMostUsedColors();
                this.forceUpdate();
              })
              .catch((error) => console.error("error", error));
          })
          .catch((error) => console.error("error", error));
      })
      .catch((e) => {
        // Handle errors
        console.error(e);
      });
  };

  changeCategory = (name, changeCategory, callBack) => {
    let category = this.Categories.filter((c) => c.CategoryName === name);
    let sections = this.sortSections(category[0].Sections.filter((c) => c.isDeleted !== true));

    delete scale.textObjs;
    scale.textObjs = [];
    this.state.selectedCategory = name;
    this.Categories.find((c) => c.CategoryName === this.state.selectedCategory).Sections = sections;
    this.state.selectedSectionID = this.Categories.find(
      (c) => c.CategoryName === this.state.selectedCategory,
    ).Sections[0].ID;
    this.state.selectedSection = this.Categories.find(
      (c) => c.CategoryName === this.state.selectedCategory,
    ).Sections[0];
    this.getTemplateContent(
      this.Categories.find((c) => c.CategoryName === this.state.selectedCategory).Sections[0].ID,
    );
    this.forceUpdate(() => {
      this.updateOrder();
    });
    if (callBack) {
      callBack();
    }
  };

  handleChange = (e, val, callBack) => {
    if (this.state.CurrentScreen == 0) {
      if (navigator.clipboard && navigator.clipboard.readText) {
        navigator.clipboard
          .readText()
          .then((clipText) => {
            this.timesPasted = 0;
            this.shouldPasteInPlace =
              clipText.includes("copyObject") || clipText.includes("copyObjects");
            this.forceUpdate();
          })
          .catch(() => {});
      }

      delete scale.textObjs;
      scale.textObjs = [];
      this.state.selectedSectionID = val;
      this.state.selectedSection = this.Categories.find(
        (c) => c.CategoryName === this.state.selectedCategory,
      ).Sections.find((section) => section.ID == val);
      this.getTemplateContent(val);
      this.forceUpdate();
    } else {
      this.state.selectedDashboardChart = this.dashboardCharts.find((chart) => chart.ID == val);
      this.forceUpdate();
    }
    if (callBack) {
      callBack();
    }
  };

  selectTemplate = (template) => {
    if (template.ID !== this.state.selectedTemplateID)
      if (navigator.clipboard && navigator.clipboard.readText) {
        navigator.clipboard
          .readText()
          .then((clipText) => {
            this.timesPasted = 0;
            this.shouldPasteInPlace =
              clipText.includes("copyObject") || clipText.includes("copyObjects");
            this.forceUpdate();
          })
          .catch(() => {});
      }

    this.updateTemplate(this.state.selectedTemplateID, { isDefault: false }, (err, res) => {
      this.findTemplate(this.state.selectedTemplateID)[0].isDefault = false;
      this.updateTemplate(template.ID, { isDefault: true }, () => {
        let fakeTemplate = this.findTemplate(template.ID)[0];
        if (fakeTemplate) fakeTemplate.isDefault = true;
        this.state.selectedTemplateID = template.ID;
        this.getTemplateContent(template.ID);
        this.forceUpdate();
      });
    });
  };

  updateTemplate = (id, query, callBack) => {
    let section_index = this.Categories.find(
      (c) => c.CategoryName === this.state.selectedCategory,
    ).Sections.findIndex((s) => s.ID === this.state.selectedSectionID);
    let section = this.Categories.find((c) => c.CategoryName === this.state.selectedCategory)
      .Sections[section_index];
    request
      .put("/api/master/updateTemplate")
      .query({ ID: id })
      .send(query)
      .then((err, res) => {
        if (callBack) {
          callBack();
        }
      });
  };

  findTemplate = (id) => {
    return this.state.templates.filter((obj) => {
      return obj.ID === id;
    });
  };

  handleKeyDown = (e) => {
    let index = this.Categories.find(
      (c) => c.CategoryName === this.state.selectedCategory,
    ).Sections.findIndex((section) => section.ID === this.state.selectedSectionID);
    switch (e.key) {
      case "ArrowRight":
        this.checkForChange(e, index + 1, +1);
        break;
      case "ArrowLeft":
        this.checkForChange(e, index - 1, -1);
        break;
    }
  };

  sortSections = (sections) => {
    sections.sort(function (a, b) {
      if (a.order > b.order) {
        return 1;
      } else if (a.order < b.order) {
        return -1;
      } else {
        return a.ID > b.ID ? 1 : -1;
      }
    });
    return sections;
  };

  updateOrder = (update = true, callBack) => {
    this.Categories.find((c) => c.CategoryName === this.state.selectedCategory).Sections.forEach(
      (c, index) => {
        if (c.order != index + 1) {
          c.order = index + 1;
          request
            .put(`/api/master/businessplanpages`)
            .query({ ID: c.ID })
            .send({ order: index + 1 })
            .set(
              "Access-Control-Allow-Origin",
              window.location.protocol + "//" + window.location.host,
            )
            .set("Accept", "application/json")
            .set("authorization", "Bearer " + window.localStorage.getItem("token"))
            .then((res) => {});
        }
      },
    );

    if (update) {
      this.forceUpdate(() => {
        callBack && callBack();
      });
    } else {
      callBack && callBack();
    }
  };

  addSectionInObject = (index, section, isCustom) => {
    if (
      index >=
      this.Categories.find((c) => c.CategoryName === this.state.selectedCategory).Sections.length
    ) {
      this.Categories.find((c) => c.CategoryName === this.state.selectedCategory).Sections.push(
        section,
      );
    } else {
      this.Categories.find((c) => c.CategoryName === this.state.selectedCategory).Sections.splice(
        index,
        0,
        section,
      );
    }
    this.updateOrder();
  };

  getTemplateContent = (id) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
      mode: "no-cors",
    };

    let section = this.Categories.find(
      (c) => c.CategoryName === this.state.selectedCategory,
    ).Sections.filter((s) => s.ID === this.state.selectedSectionID)[0];

    fetch(`/api/file?tmp=${id}&is_custom=${false}&businessPlan=${true}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          this.setState({
            jsonData: JSON.stringify(result),
            slideKey: "slideComponent_" + new Date().getTime(),
          });
        } else {
          this.setState({
            jsonData: false,
            slideKey: "slideComponent_" + new Date().getTime(),
          });
        }
      })
      .catch((error) => console.error("error", this.state.jsonData));
  };
  changeScreen = (screen) => {
    this.setState({ CurrentScreen: screen });
  };

  getTabs = () => {
    if (this.state.CurrentScreen == 0) {
      return this.Categories.find((c) => c.CategoryName === this.state.selectedCategory).Sections
        ? this.Categories.find((c) => c.CategoryName === this.state.selectedCategory).Sections
        : [];
    }
    //     if (this.state.CurrentScreen == 1) {
    //     return []
    // } else
    else return this.dashboardCharts;
  };

  handleAddChart = () => {
    let newChart = new BarChart({ Name: `Chart ${this.dashboardCharts.length + 1}` });
    request
      .post(`/api/master/dashboardcharts`)
      .set("authorization", "Bearer " + window.localStorage.getItem("token"))
      .send(newChart.getDataFromChart())
      .then((res) => {
        if (res) {
          let chartObjData = {
            Data: JSON.parse(newChart.getDataFromChart().Data),
            Name: newChart.getDataFromChart().Name,
            ID: res.body.id,
          };
          this.dashboardCharts.push(chartObjData);
          this.setState(
            { selectedDashboardChart: this.dashboardCharts[this.dashboardCharts.length - 1] },
            () => {
              newChart = null;
            },
          );
        }
      });
  };

  deleteSection = (section_id, callBack) => {
    let section_index = this.Categories.find(
      (c) => c.CategoryName === this.state.selectedCategory,
    ).Sections.findIndex((s) => s.ID === section_id);
    let section = this.Categories.find((c) => c.CategoryName === this.state.selectedCategory)
      .Sections[section_index];
    request
      .put(`/api/master/slidesections`)
      .query({ ID: section_id })
      .send({ isDeleted: true })
      .set("Access-Control-Allow-Origin", window.location.protocol + "//" + window.location.host)
      .set("Accept", "application/json")
      .set("authorization", "Bearer " + window.localStorage.getItem("token"))
      .then((res, err) => {
        callBack && callBack();
      });
  };

  deleteComplete = (section_id, categoryID, callBack) => {
    let section_index = this.Categories.find(
      (c) => c.CategoryName === this.state.selectedCategory,
    ).Sections.findIndex((s) => s.ID === section_id);
    let sectionsCount = this.Categories.find((c) => c.CategoryName === this.state.selectedCategory)
      .Sections.length;

    if (sectionsCount > 1) {
      if (section_id) {
        request
          .delete(`/api/master/businessplanpages`)
          .query({ ID: section_id })
          .set(
            "Access-Control-Allow-Origin",
            window.location.protocol + "//" + window.location.host,
          )
          .set("Accept", "application/json")
          .set("authorization", "Bearer " + window.localStorage.getItem("token"))
          .then((res, err) => {
            this.Categories.find((c) => c.ID === categoryID).Sections.splice(section_index, 1);
            this.forceUpdate();
            callBack && callBack();
          });
      }
    }
  };

  editSection = (name, section_id, categoryID, callBack) => {
    let section_index = this.Categories.find((c) => c.ID === categoryID).Sections.findIndex(
      (s) => s.ID === section_id,
    );

    request
      .put(`/api/master/businessplanpages`)
      .query({ ID: section_id })
      .send({ PageName: name })
      .set("Access-Control-Allow-Origin", window.location.protocol + "//" + window.location.host)
      .set("Accept", "application/json")
      .set("authorization", "Bearer " + window.localStorage.getItem("token"))
      .then((res, err) => {
        this.Categories.find((c) => c.ID === categoryID).Sections[section_index].PageName = name;
        this.forceUpdate();
        callBack && callBack();
      });
  };

  addNewSection = (name, categoryID, callBack) => {
    let max_order = Math.max(
      ...this.Categories.filter((c) => c.ID === categoryID)[0].Sections.map((o) => o.order),
    );
    let index = this.Categories.find((c) => c.ID === categoryID).Sections.indexOf(
      this.state.selectedSection,
    );

    request
      .post(`/api/master/businessplanpages`)
      .send({
        PageName: name,
        order: max_order + 1,
        CategoryID: categoryID,
        IndustryID: this.selectedIndustry.ID,
      })
      .set("Access-Control-Allow-Origin", window.location.protocol + "//" + window.location.host)
      .set("Accept", "application/json")
      .set("authorization", "Bearer " + window.localStorage.getItem("token"))
      .then((res, err) => {
        request
          .get(`/api/master/businessplanpages`)
          .query({ ID: res.body.id })
          .set(
            "Access-Control-Allow-Origin",
            window.location.protocol + "//" + window.location.host,
          )
          .set("Accept", "application/json")
          .set("authorization", "Bearer " + window.localStorage.getItem("token"))
          .then((res2, err) => {
            let section = res2.body[0];
            this.addSectionInObject(index, section, false);
            this.forceUpdate();
            callBack && callBack();
          });
      });
  };

  saveInstructions = (instructions, isLong) => {
    request
      .put("/api/updateInstructions")
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("authorization", "Bearer " + window.localStorage.getItem("token"))
      .send({
        ID: this.state.selectedSection.ID,
        Instructions: instructions,
        isLong: isLong,
        businessPlan: true,
      })
      .then((res) => {
        if (res) {
          if (isLong) {
            this.state.selectedSection.LongInstructions = instructions;
            this.Categories.find(
              (c) => c.CategoryName === this.state.selectedCategory,
            ).Sections.find((c) => c.ID === this.state.selectedSection.ID).LongInstructions =
              instructions;
            this.forceUpdate();
          } else {
            this.state.selectedSection.Instructions = instructions;
            this.Categories.find(
              (c) => c.CategoryName === this.state.selectedCategory,
            ).Sections.find((c) => c.ID === this.state.selectedSection.ID).Instructions =
              instructions;
            this.forceUpdate();
          }
        }
      });
  };

  handleDeleteChart = (chart_id, callBack) => {
    request
      .delete(`/api/master/dashboardcharts`)
      .set("authorization", "Bearer " + window.localStorage.getItem("token"))
      .query({ ID: chart_id })
      .then((res) => callBack && callBack());
  };

  addSection = (section, callBack, duplicate = false) => {};

  onIndustryChange = (inudstryID) => {
    this.selectedIndustry = this.Industries.find((c) => c.ID === inudstryID);
    this.getAllCategories();
  };

  deleteIndustry = (industryID) => {
    request
      .delete(`/api/master/businessplanindustries`)
      .query({ ID: industryID })
      .set("Access-Control-Allow-Origin", window.location.protocol + "//" + window.location.host)
      .set("Accept", "application/json")
      .set("authorization", "Bearer " + window.localStorage.getItem("token"))
      .then((res, err) => {
        if (industryID !== null) {
          this.Industries = this.Industries.filter((c) => c.ID !== industryID);
          if (this.selectedIndustry.ID === industryID) {
            this.selectedIndustry = this.Industries[0];
            this.getAllCategories();
          }
          this.forceUpdate();
        }
      });
  };

  editIndustry = (industryID, name, callBack) => {
    request
      .put(`/api/master/businessplanindustries`)
      .query({ ID: industryID })
      .send({ Name: name })
      .set("Access-Control-Allow-Origin", window.location.protocol + "//" + window.location.host)
      .set("Accept", "application/json")
      .set("authorization", "Bearer " + window.localStorage.getItem("token"))
      .then((res, err) => {
        let index = this.Industries.findIndex((c) => c.ID === industryID);
        this.Industries[index].Name = name;
        this.forceUpdate();
        callBack && callBack();
      });
  };

  addNewIndustry = (name, callBack) => {
    request
      .post("/api/master/createNewIndustryBp")
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("authorization", "Bearer " + window.localStorage.getItem("token"))
      .send({ Name: name })
      .then((res) => {
        if (res) {
          this.Industries.push({ ID: parseInt(res.body), Name: name, Hidden: true });
          this.forceUpdate();
          callBack && callBack();
        }
      });
  };

  updateIndustryVisibility = (industryID) => {
    let Hidden = this.Industries.find((c) => c.ID === industryID).Hidden;
    request
      .put(`/api/master/businessplanindustries`)
      .query({ ID: industryID })
      .send({ Hidden: !Hidden })
      .set("Access-Control-Allow-Origin", window.location.protocol + "//" + window.location.host)
      .set("Accept", "application/json")
      .set("authorization", "Bearer " + window.localStorage.getItem("token"))
      .then((res, err) => {
        if (industryID !== null) {
          let index = this.Industries.findIndex((c) => c.ID === industryID);
          this.Industries[index].Hidden = !Hidden;
          this.forceUpdate();
        }
      });
  };

  render() {
    this.CurrCategory = this.Categories.find((c) => c.CategoryName === this.state.selectedCategory);
    if (
      (this.CurrCategory && this.CurrCategory.Sections && this.CurrCategory.Sections.length > 0) ||
      this.dashboardCharts.length
    )
      return (
        <div className="App slide_editor">
          {this.saving && (
            <div className={"slide_loader"}>
              <CircleLoader />
            </div>
          )}
          {this.state.CurrentScreen == 0 && (
            <SlideHeaderContainer
              isAdmin={true}
              Industries={this.Industries}
              selectedIndustry={this.selectedIndustry}
              addNewIndustry={this.addNewIndustry}
              onIndustryChange={this.onIndustryChange}
              updateIndustryVisibility={this.updateIndustryVisibility}
              deleteIndustry={this.deleteIndustry}
              editIndustry={this.editIndustry}
            />
          )}
          <div className={"se_panel_wrapper bp"}>
            <LeftMenu
              updateSection={this.updateSection}
              hideSection={this.hideSection}
              deleteSection={
                this.state.CurrentScreen == 0 ? this.deleteSection : this.handleDeleteChart
              }
              addSection={this.addSection}
              scale={scale}
              addNewSection={this.addNewSection}
              update={(val) => scale.handleResize(val)}
              businessPlan={true}
              handleAddChart={this.handleAddChart}
              selectedChart={this.state.selectedDashboardChart}
              tabs={this.getTabs()}
              updateOrder={this.updateOrder}
              deleteComplete={this.deleteComplete}
              editSection={this.editSection}
              tabKey={"PageName"}
              isAdmin={true}
              label={"Add new page"}
              categories={this.Categories}
              selectedSectionID={this.state.selectedSectionID}
              selectedSection={
                this.state.CurrentScreen == 0
                  ? this.state.selectedSection
                  : this.state.selectedDashboardChart
              }
              handleChange={this.handleChange}
              changeCategory={this.changeCategory}
              selectedCategory={this.state.selectedCategory}
              onSectionMove={() => scale.updateSlideComp()}
              CurrentScreen={this.state.CurrentScreen}
              EditingScreens={this.EditingScreens}
              changeScreen={this.changeScreen}
            />
            {this.state.CurrentScreen == 0 && (
              <div className={"se_panel_container"} id={"se_panel_container"}>
                <SlideComponent
                  onPreview={() => this.onPreview()}
                  scale={scale}
                  beforeSlidePrint={(callBack) => {
                    this.getAllTemplatesData(() =>
                      this.setState({ printEnabled: true }, () =>
                        setTimeout(() => callBack(), 300),
                      ),
                    );
                  }}
                  afterSlidePrint={() => this.setState({ printEnabled: false })}
                  toggleFormatOptions={(val) => this.setState({ formatOptions: val })}
                  saveNewSection={this.saveNewSection}
                  saveContent={(x, y) =>
                    this.saveContent(x, y, this.state.selectedCategory === "Custom")
                  }
                  isAdmin={true}
                  setShouldPasteInPlace={this.setShouldPasteInPlace}
                  shouldPasteInPlace={this.shouldPasteInPlace}
                  setTimesPasted={this.setTimesPasted}
                  timesPasted={this.timesPasted}
                  width={1080}
                  height={1527}
                  businessPlan={true}
                  selectedSection={
                    this.state.CurrentScreen == 0
                      ? this.state.selectedSection
                      : this.state.selectedDashboardChart
                  }
                  selectedTemplateID={this.state.selectedTemplateID}
                  jsonData={this.state.jsonData}
                  key={this.state.slideKey}
                />
                <div className={"se_panel_chart_editor"} id="chart_editor_portal"></div>
                <Footer
                  changeSection={(val) =>
                    this.handleChange(null, this.state.selectedSectionID + val, null)
                  }
                />
              </div>
            )}
            {this.state.CurrentScreen > 0 && (
              <div className={"chart_component_container"}>
                <ChartsComponent
                  setSelectedChart={(c) => {
                    this.setState({
                      selectedDashboardChart: this.dashboardCharts.length
                        ? this.dashboardCharts.find((chart) => chart == c)
                        : null,
                    });
                  }}
                  selectedDashboardChart={this.state.selectedDashboardChart}
                  charts={this.dashboardCharts}
                />
              </div>
            )}
            <RightMenu
              scale={scale}
              update={(val) => scale.handleResize(val)}
              businessPlan={true}
              dontShowTemplates={true}
              className={"options_menu"}
              selectTemplate={this.selectTemplate}
              isAdmin={true}
              selectedTemplateID={this.state.selectedTemplateID}
              templates={this.state.templates}
              selectedSectionID={this.state.selectedSectionID}
              selectedSection={this.state.selectedSection}
              saveInstructions={this.saveInstructions}
              sections={
                this.Categories.find((c) => c.CategoryName === this.state.selectedCategory).Sections
              }
            />
          </div>
        </div>
      );
    else {
      return <div>wait a sec</div>;
    }
  }
}

Index.contextType = CommandManagerContext;
export default Index;
