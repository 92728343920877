import React, {useEffect} from "react";
import * as ReactDOM from "react-dom/client";
import App from "./App";
import {BrowserRouter, useLocation, useNavigate} from "react-router-dom";
import Modeliks from "./data/DataManager";
import ErrorBoundary from "./components/errors/errorBoundary";
import AppDialog from "./components/dialogs/AppDialog";
import AppNotification from "./components/notifications/AppNotification";
import {SwitchAccountDialogConditional, TOSDialogConditional} from "./hooks/dialogs_conditional_wrapper";

window.Modeliks = Modeliks;

function NavigateHook() {
	window.Modeliks.NavigateTo = useNavigate();
}

function LocationHook() {
	window.Modeliks.Location = useLocation();

	useEffect(() => {
		global.Modeliks.NotifySubsctiptions("onLocationChange");
	}, [window.Modeliks.Location]);
}

document.getElementById("root").addEventListener("wheel", (e) => {
	if (global.wheelListener) {
		global.wheelListener(e);
	}
});
document.addEventListener("keydown", (e) => {
	if (global.keyListener) {
		global.keyListener(e);
	}
});
document.addEventListener("paste", (e) => {
	if (global.pasteListener) {
		global.pasteListener(e);
	}
});
document.addEventListener("copy", (e) => {
	if (global.copyListener) {
		global.copyListener(e);
	}
});

window.saveSummaryDateSettings = (
	SummaryDateSettings,
	SummaryDateSettingsValue,
	saveObj,
	callBack,
) => {
	if (!SummaryDateSettings) {
		SummaryDateSettingsValue = saveObj;
		SummaryDateSettings = {
			Key: "SummaryDateSettings",
			Value: JSON.stringify(SummaryDateSettingsValue),
			CompanyID: global.Modeliks.CompanyInfo.ID,
		};
		global.Modeliks.post("settings_client", SummaryDateSettings, (success, err) => {
			if (success) {
				SummaryDateSettings.ID = success.id;
				global.Modeliks.SummaryDateSettings = SummaryDateSettings;
				callBack && callBack();
			} else {
				console.error("error", err);
			}
		});
	} else {
		SummaryDateSettings.Value = JSON.stringify(saveObj);
		if (SummaryDateSettings.Save) {
			SummaryDateSettings.Save();
		} else {
			global.Modeliks.put(
				"settings_client",
				{ID: SummaryDateSettings.ID},
				{Value: JSON.stringify(SummaryDateSettingsValue)},
				(success, err) => {
					if (!success) {
						console.error("error", err);
					}
				},
			);
		}
	}
};

navigator.saysWho = (() => {
	const {userAgent} = navigator;
	let match = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
	let temp;

	if (/trident/i.test(match[1])) {
		temp = /\brv[ :]+(\d+)/g.exec(userAgent) || [];

		return `IE ${temp[1] || ""}`;
	}

	if (match[1] === "Chrome") {
		temp = userAgent.match(/\b(OPR|Edge)\/(\d+)/);

		if (temp !== null) {
			return temp.slice(1).join(" ").replace("OPR", "Opera");
		}

		temp = userAgent.match(/\b(Edg)\/(\d+)/);

		if (temp !== null) {
			return temp.slice(1).join(" ").replace("Edg", "Edge (Chromium)");
		}
	}

	match = match[2] ? [match[1], match[2]] : [navigator.appName, navigator.appVersion, "-?"];
	temp = userAgent.match(/version\/(\d+)/i);

	if (temp !== null) {
		match.splice(1, 1, temp[1]);
	}

	return match.join(" ");
})();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<BrowserRouter>
		<ErrorBoundary>
			<App/>
			<AppDialog/>
			<AppNotification/>
			<NavigateHook/>
			<LocationHook/>
			<SwitchAccountDialogConditional/>
			<TOSDialogConditional/>
		</ErrorBoundary>
	</BrowserRouter>,
);

// const scriptEle = document.createElement("script");
// scriptEle.src = "https://cdn.paddle.com/paddle/v2/paddle.js";
// document.getElementsByTagName('body')[0].appe
