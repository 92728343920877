import ReactDOM from "react-dom";

const ExportPortal = ({ children }) => {
  const el = document.getElementById("export_portal");
  if (children && el) {
    return ReactDOM.createPortal(children, el);
  }
};

export default ExportPortal;
