import React, { Component } from "react";
import "./MoustUsedColors.scss";

class MostUsedColors extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let colors = JSON.parse(JSON.stringify(global.Modeliks.mostUsedColors));

    if (this.props.disableAlpha) {
      Object.keys(colors).forEach((key) => {
        let reg = /^#([a-fA-F0-9]){3}$|[a-fA-F0-9]{6}$/;
        if (!reg.test(key)) {
          let char1 = key.indexOf("(") + 1;
          let char2 = key.lastIndexOf(")");
          let values = key.substring(char1, char2).split(",");

          if (values.length === 4) {
            if (values[3] !== "1") {
              delete colors[key];
            }
          }
        }
      });
    }

    return (
      global.Modeliks.mostUsedColors && (
        <>
          <span className={"most_used_title"}>Most used colors:</span>
          <div className={"color_container"}>
            {Object.keys(colors)
              .sort((x, y) => {
                if (colors[x] < colors[y]) {
                  return 1;
                } else return -1;
              })
              .map((c, index) => {
                if (index < 16) {
                  return (
                    <div
                      className={"color_box"}
                      style={{ backgroundColor: c }}
                      onClick={() => this.props.onClick(c)}
                    ></div>
                  );
                }
              })}
          </div>
        </>
      )
    );
  }
}

export default MostUsedColors;
