import React, { createContext } from "react";
import IntroScreen from "./IntroScreen";
import RecentReports from "./components/RecentReports";
import LeftMenu from "../../../../SlideEditor/components/components/menus/LeftMenu/LeftMenu";
import "./index.scss";
import MainTable from "./components/MainTable";
import CircleLoader from "../../../../components/Loaders/CircleLoader/CircleLoader";
import { DashboardElementType } from "../../../../components/constants/charts";
import {
  SlideObject,
  SlideTypes,
} from "../../../../SlideEditor/components/SlideObjects/SlideStructures";
import UserMngReport from "./components/UserMngReport";
import request from "superagent";
import MxDataArray from "../../../../data/MxDataArray";
import { parseDate } from "../../ProfileSettings/AccountDetails";
import { DashboardSectionsObj } from "../../../Admin/Dashboards/components/DashboardSectionsObj";
import { DialogTypes } from "../../../../data/Finance/constants";
import LocalStorage from "../../../../helpers/LocalStorage";
import MxIDUseID from "../../../../helpers/MxIUniqueD";

const PitchProperties = { width: 1280, height: 720, margin: 40 };
const CoverPageKeyWords = {
  Title: "MANAGEMENT_REPORT_TITLE",
  Name: "MANAGEMENT_REPORT_NAME",
  Date: "MANAGEMENT_REPORT_DATE",
  CreatedBy: "MANAGEMENT_REPORT_CREATED_BY",
  SectionName: "MANAGEMENT_REPORT_SECTION_NAME",
  SectionOrder: "MANAGEMENT_REPORT_SECTION_ORDER",
};

const chartLayoutTypes = {
  full_width: {
    width: 100,
    height: 340,
    name: "chart_full_width",
  },
  half_width: {
    width: 50,
    height: 464,
    name: "chart_half_width",
  },
};
const CoverPageTypes = {
  cover_page: {
    name: "cover_page_mr",
  },
};
const tableLayoutTypes = {
  page_1: {
    name: "table_page_1",
  },
  page_2: {
    name: "table_page_2",
  },
};
const tableOfContentTypes = {
  type_1: {
    name: "table_of_content_1",
  },
};

const Loader = (
  <div className={"main_circle_loader_container"}>
    <CircleLoader />
  </div>
);

const FolderIDKey = "MRFolderID_";

class ManagementReports extends React.Component {
  Folders = [];
  Reports = [];
  DashboardCharts = [];
  DataManager = global.Modeliks;

  previouslySelectedSlide = null;

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      selectedFolder: null,
      publishManagementReports: false,
    };
    this.scale = {};
  }

  setPreviouslySelectedSlide = (value) => {
    this.previouslySelectedSlide = value;
  };

  componentDidMount() {
    this.getData();
  }

  getSlideTemplates = () => {
    [
      ...Object.values(chartLayoutTypes),
      ...Object.values(tableLayoutTypes),
      ...Object.values(CoverPageTypes),
      ...Object.values(tableOfContentTypes),
    ].forEach((value) => {
      const url = `/api/serveStatic/${value.name}.json`;
      const urlPrefix = global.Modeliks.cache_routes[url]
        ? `?v=${global.Modeliks.cache_routes[url]}`
        : "";
      request.get(url + urlPrefix).then((data) => {
        Object.assign(value, data.body);
      });
    });
  };

  getFolderIDFromLocalStorage = () =>
    LocalStorage.getObjectFromLocalStorage(FolderIDKey + global.Modeliks.CompanyInfo.ID);

  getData = () => {
    if (this.props.publishManagementReports) {
      this.setState({ loading: false, publishManagementReports: true });
      return;
    }
    this.getSlideTemplates();
    global.Modeliks.get(global.Modeliks.Tables.DashboardCharts.TableName, null, (charts) => {
      charts.forEach((c) => (c.Data = JSON.parse(c.Data)));
      global.Modeliks.DashboardCharts = MxDataArray.create(charts);
      this.DashboardCharts = global.Modeliks.DashboardCharts;
      global.Modeliks.get(
        global.Modeliks.Tables.MR_Folders.TableName,
        { CompanyID: global.Modeliks.CompanyInfo.ID },
        (folders) => {
          if (folders.length) {
            this.Folders = MxDataArray.create(folders.sort(this.sortFolders));
            global.Modeliks.get(
              global.Modeliks.Tables.MR_Reports.TableName,
              { FolderID: folders.map((c) => c.ID) },
              (reports) => {
                this.Reports = MxDataArray.create(reports);
                const selectedFolder = this.getSelectedFolder();
                this.setState({ loading: false, selectedFolder });
              },
            );
          } else {
            this.setState({ loading: false });
          }
        },
      );
    });
  };

  getSelectedFolder = () => {
    if (this.state.selectedFolder) {
      return this.Folders.getItem(this.state.selectedFolder.ID);
    }
    const lsFolderID = this.getFolderIDFromLocalStorage();
    if (lsFolderID && this.Folders.getItem(lsFolderID)) {
      return this.Folders.getItem(lsFolderID);
    }

    return this.Folders[0];
  };

  getFinancialData = (report, callBack) => {
    const queryString = `/api/serveStatic/ClientData/${global.Modeliks.Account.ID}/${global.Modeliks.CompanyInfo.ID}/mr_`;
    request
      .get(queryString + report.ID + `/financial_data.json?v=${report.ID}_` + report.UpdatedAt) //+ new Date().getTime())
      .then((data) => {
        report.FinancialData = data.body;
        callBack && callBack();
      })
      .catch((e) => console.error(e, "no data for report" + report.ID));
  };

  getSlide = (data, type) => {
    if (type === DashboardElementType.charts) {
      return Object.keys(data[type]).map((key) => {
        const chart = this.DashboardCharts.getItem(key);
        const itemWidth = chart.Data.chartConfig.width;
        const Name = chart.Data.chartOptions.title;
        const position = data[type][key];
        let widthType = JSON.parse(
          JSON.stringify(
            itemWidth <= 50 ? chartLayoutTypes.half_width : chartLayoutTypes.full_width,
          ).replace(CoverPageKeyWords.Title, Name),
        );
        return {
          slideObjects: [
            ...widthType.slideObjects,
            new SlideObject(
              SlideTypes.financialChart,
              null,
              {
                ID: key,
                ...data[type][key],
                staticKey: "so_" + MxIDUseID(),
                width:
                  (widthType.width / 100) * (PitchProperties.width - 2 * PitchProperties.margin),
                left: 40,
                top: 216,
                height: widthType.height,
              },
              false,
              false,
            ),
          ],
          Name,
          position,
        };
      });
    }
    if (
      DashboardElementType.financial_statements === type ||
      DashboardElementType.custom_kpis === type
    ) {
      return data[type].flatMap((obj) => {
        let Name;
        const { x, y } = obj;
        const position = { x, y };
        if (type === DashboardElementType.financial_statements) {
          Name =
            DashboardSectionsObj.DashboardSections.FinancialStatements.subsections[
              obj.defaultSelectedReport[type]
            ].name + " - ";
        } else {
          let kpi = global.Modeliks.CustomKPIStore.getItem(obj.ID);
          if (!kpi) {
            return [];
          }
          Name = kpi.Name + " - ";
        }
        return [
          {
            slideObjects: [
              ...JSON.parse(
                JSON.stringify(tableLayoutTypes.page_1.slideObjects).replace(
                  CoverPageKeyWords.Title,
                  Name + "(1/2)",
                ),
              ),
              new SlideObject(
                SlideTypes.mainFinanceTable,
                null,
                {
                  type,
                  ...obj,
                  staticKey: "so_" + MxIDUseID(),
                  left: PitchProperties.margin,
                  height: 560,
                  top: 120,
                  width: PitchProperties.width - 2 * PitchProperties.margin,
                },
                false,
                false,
              ),
            ],
            Name: `${Name}(1/2)`,
            position,
          },
          {
            slideObjects: JSON.parse(
              JSON.stringify(tableLayoutTypes.page_2.slideObjects).replace(
                CoverPageKeyWords.Title,
                Name + "(2/2)",
              ),
            ),
            Name: `${Name}(2/2)`,
            position,
          },
        ];
      });
    }
    return [];
  };
  setReportFinanceData = (data, callBack) => {
    // this.OldScenario = this.DataManager.CompanyScenarioInfo;
    // this.DataManager.CompanyScenarioInfo = data.CompanyScenario;
    this.DataManager.StaticDataObject = data;
    global.Modeliks.disableCDUpdate = true;
    this.DataManager.InitializeDrivers(false, () => {
      this.props.resetDashboards && this.props.resetDashboards();
      callBack && callBack();
    });
  };

  unsetReportFinanceData = (callBack) => {
    // this.DataManager.CompanyScenarioInfo = this.OldScenario;
    // this.OldScenario = null;
    this.DataManager.StaticDataObject = null;
    global.Modeliks.disableCDUpdate = false;
    this.DataManager.isAuth &&
      this.DataManager.InitializeDrivers(false, () => {
        this.props.resetDashboards && this.props.resetDashboards();
        callBack && callBack();
      });
  };

  generateSlides = (data) => {
    return Object.keys(data).flatMap((c) => {
      return this.getSlide(data, c);
    }); //.map(c => [c])
  };

  sortSlidesByPosition = ({ position: a }, { position: b }) => {
    if (a.y > b.y) {
      return 1;
    }
    if (a.y < b.y) {
      return -1;
    }
    return a.x - b.x;
  };

  handleGenerateReport = (data, callBack) => {
    if (!data.customDashboard) {
      return;
    }
    const firstSlide = {
      slideObjects: JSON.parse(
        JSON.stringify(CoverPageTypes.cover_page.slideObjects)
          .replace(CoverPageKeyWords.Name, data.Name)
          .replace(CoverPageKeyWords.Date, parseDate(new Date().toISOString()))
          .replace(
            CoverPageKeyWords.CreatedBy,
            `${global.Modeliks.User.Name} ${global.Modeliks.User.Surname}`,
          ),
      ),
      Name: "Cover Page",
    };
    const generatedSlides = this.generateSlides(data.customDashboard.Settings).sort(
      this.sortSlidesByPosition,
    );
    const Slides = [
      firstSlide,
      ...this.getTableOfContents(firstSlide, generatedSlides),
      ...generatedSlides,
    ];
    if (Slides.length) {
      this.setState({ loading: true });
      if (this.Folders.length && data.selectedFolder) {
        global.Modeliks.post(
          "/api/insertReport",
          {
            FolderID: data.selectedFolder,
            ReportName: data.Name,
            Slides,
            CompanyScenarioID: global.Modeliks.CompanyScenarioInfo.ID,
            CompanyID: global.Modeliks.CompanyInfo.ID,
          },
          this.getData,
          () => {},
          true,
        );
      } else {
        global.Modeliks.post(
          global.Modeliks.Tables.MR_Folders.TableName,
          {
            CompanyID: global.Modeliks.CompanyInfo.ID,
            Name: data.FolderName,
          },
          (success) => {
            global.Modeliks.post(
              "/api/insertReport",
              {
                FolderID: success.id,
                ReportName: data.Name,
                Slides,
                CompanyScenarioID: global.Modeliks.CompanyScenarioInfo.ID,
                CompanyID: global.Modeliks.CompanyInfo.ID,
              },
              this.getData,
              () => {},
              true,
            );
          },
        );
      }
    } else {
      console.log("please choose other dashboard or add some items to the selected dashboard");
    }
  };

  handleAddNewFolder = (name, callBack) => {
    global.Modeliks.post(
      global.Modeliks.Tables.MR_Folders.TableName,
      {
        CompanyID: global.Modeliks.CompanyInfo.ID,
        Name: name,
      },
      (success) => {
        global.Modeliks.get(
          global.Modeliks.Tables.MR_Folders.TableName,
          { CompanyID: global.Modeliks.CompanyInfo.ID },
          (folders) => {
            if (folders.length) {
              this.Folders = MxDataArray.create(folders.sort(this.sortFolders));
              this.forceUpdate(callBack);
            }
          },
        );
      },
    );
  };

  getPageNumber = (index, total) => {
    return ` - (${index}/${total})`;
  };

  getTableOfContents = (coverPage, slides) => {
    const defaultSlides = coverPage ? slides.length + 1 : slides.length;
    let totalTableOfContentPages = Math.ceil(defaultSlides / 14);
    const tableOfContent = {
      slideObjects: JSON.parse(JSON.stringify(tableOfContentTypes.type_1.slideObjects)),
    };
    let numberObject = null;
    let nameObject = null;
    let tableOfContentSlides = [];
    tableOfContent.slideObjects.forEach((obj) => {
      if (JSON.stringify(obj).includes(CoverPageKeyWords.SectionName)) {
        nameObject = obj;
      } else if (JSON.stringify(obj).includes(CoverPageKeyWords.SectionOrder)) {
        numberObject = obj;
      }
    });

    tableOfContent.slideObjects = tableOfContent.slideObjects.filter((obj) => {
      if (JSON.stringify(obj).includes(CoverPageKeyWords.SectionName)) {
        return false;
      } else if (JSON.stringify(obj).includes(CoverPageKeyWords.SectionOrder)) {
        return false;
      }
      return true;
    });

    for (let i = 1; i <= totalTableOfContentPages; i++) {
      const Name = `Table of contents${totalTableOfContentPages > 1 ? this.getPageNumber(i, totalTableOfContentPages) : ""}`;
      tableOfContentSlides.push({
        slideObjects: JSON.parse(
          JSON.stringify(tableOfContent.slideObjects).replace(CoverPageKeyWords.Title, Name),
        ),
        Name,
        isTableOfContent: true,
      });
    }
    slides.forEach((slide, index) => {
      const toc_index = Math.ceil((index + 1) / 15) - 1;
      const newTop = numberObject.props.top + 32 * (index % 15);
      const newNumberObject = JSON.parse(
        JSON.stringify(numberObject).replace(
          CoverPageKeyWords.SectionOrder,
          (index + 1).toString(),
        ),
      );
      const newNameObject = JSON.parse(
        JSON.stringify(nameObject).replace(CoverPageKeyWords.SectionName, slide.Name),
      );

      newNumberObject.props.top = newTop;
      newNumberObject.props.height = 25;
      newNumberObject.props.struct[0].characters[0].style.fontSize = "14pt";
      newNameObject.props.struct[0].characters[0].style.fontSize = "14pt";
      newNameObject.props.top = newTop;
      newNameObject.props.height = 25;
      newNumberObject.props.staticKey = "so_" + MxIDUseID();
      newNameObject.props.staticKey = "so_" + MxIDUseID();
      tableOfContentSlides[toc_index].slideObjects.push(newNumberObject);
      tableOfContentSlides[toc_index].slideObjects.push(newNameObject);
    });
    return tableOfContentSlides;
  };

  setUpdatedAt = (data, previouslySelectedSlide) => {
    Object.assign(this.state.selectedReport, data);
    this.previouslySelectedSlide = previouslySelectedSlide;
    this.selectReport(this.state.selectedReport);
  };

  handleDataUpdate = (previouslySelectedSlide) => {
    this.setState({ loading: true });
    global.Modeliks.put(
      "/api/updateMRData",
      {
        FolderID: this.state.selectedFolder.ID,
        CompanyScenarioID: global.Modeliks.CompanyScenarioInfo.ID,
        CompanyID: global.Modeliks.CompanyInfo.ID,
        ReportID: this.state.selectedReport.ID,
      },
      null,
      (data) => this.setUpdatedAt(data, previouslySelectedSlide),
      undefined,
      undefined,
      true,
    );
  };

  handleChange = (e, val) => {
    this.setState({ selectedFolder: this.Folders.getItem(val) }, () => {
      window.localStorage.setItem(
        FolderIDKey + global.Modeliks.CompanyInfo.ID,
        this.state.selectedFolder.ID,
      );
    });
  };

  selectReport = (selectedReport) => {
    !this.state.loading && this.setState({ loading: true });
    this.getFinancialData(selectedReport, () => {
      if (selectedReport.FinancialData) {
        this.setReportFinanceData(selectedReport.FinancialData, () =>
          this.setState({
            loading: false,
            selectedReport,
          }),
        );
      } else {
        this.setState({ selectedReport });
      }
    });
  };

  componentWillUnmount() {
    if (this.state.selectedReport) {
      this.DataManager.disableCDUpdate = false;
      this.DataManager.StaticDataObject = null;
      this.DataManager.toggleGlobalLoader && this.DataManager.toggleGlobalLoader(true);
      this.unsetReportFinanceData(this.props.resetDashboards);
    }
  }

  handleGoBack = (sections = null) => {
    if (sections) {
      this.setState({ loading: true });
      this.updateTOC(sections, false, () => {
        this.unsetReportFinanceData(() =>
          this.setState({
            selectedReport: null,
            loading: false,
          }),
        );
      });
    }
  };

  updateTOC = (Sections, setState = true, callBack) => {
    setState && this.setState({ loading: true });
    const filteredSections = Sections.filter((c) => !c.isTableOfContent);
    const Slides = this.getTableOfContents(
      filteredSections[0],
      filteredSections.filter((c, index) => index > 0),
      "Name",
    );
    global.Modeliks.put(
      "/api/update_toc",
      null,
      {
        ReportID: this.state.selectedReport.ID,
        CompanyID: global.Modeliks.CompanyInfo.ID,
        Slides,
      },
      (success) => {
        if (setState) {
          this.setState({ loading: false }, () => {
            callBack && callBack();
          });
        } else {
          callBack && callBack();
        }
      },
      (err) => console.error(err),
      false,
      true,
    );
  };

  updateFoldersOrder = (update, callBack) => {
    this.Folders.forEach((c, index) => {
      if (c.order != index) {
        c.order = index;
        global.Modeliks.put(
          global.Modeliks.Tables.MR_Folders.TableName,
          { ID: c.ID },
          { Order: index },
          () => {},
        );
      }
    });

    if (update) {
      this.forceUpdate(() => {
        callBack && callBack();
      });
    } else {
      callBack && callBack();
    }
  };

  sortFolders = (a, b) => a.Order - b.Order;

  handleDeleteSection = (folder, index, callBack) => {
    global.Modeliks.del(global.Modeliks.Tables.MR_Folders.TableName, { ID: folder.ID }, () => {
      this.Folders.splice(index, 1);
      if (this.state.selectedFolder == folder) {
        this.setState({ selectedFolder: this.Folders[0] }, callBack ?? undefined);
      } else {
        callBack && callBack();
      }
    });
  };

  handleDeleteReport = (e, report, index) => {
    e.stopPropagation();
    global.Modeliks.del(global.Modeliks.Tables.MR_Reports.TableName, { ID: report.ID }, () => {});
    let reportIndex = this.Reports.findIndex((c) => c == report);
    this.Reports.splice(reportIndex, 1);
    this.forceUpdate();
  };

  openDialog = () =>
    global.Modeliks.showDialog(
      undefined,
      DialogTypes.SimpleDialog,
      undefined,
      undefined,
      undefined,
      false,
      undefined,
      {
        title: "Please create a Custom Dashboard",
        content:
          "To create a new Management report, you have to first create a Custom Dashboard. The Management report will be created based on the Custom Dashboard. Please go to Custom Dashboards section and create your first one. \n",
        actionProps: [
          {
            label: "Cancel",
            isCancel: true,
            variant: "outlined",
          },
          {
            label: "Create Custom Dashboard",
            variant: "contained",
            onClick: () => {
              global.Modeliks.NavigateTo("/dashboards/custom_dashboards");
              global.Modeliks.showDialog.handleClose();
            },
          },
        ],
      },
    );

  handleReportSave = () => {
    this.state.selectedReport.Save(() => {
      this.state.selectedReport.UpdatedAt = new Date();
    });
  };

  render() {
    if (this.state.loading) {
      return Loader;
    }

    if (this.state.selectedReport || this.state.publishManagementReports) {
      return (
        <MyContext.Provider
          value={{
            properties: { ...this.props, FinancialStatements: this.props.getFinancialStatements() },
            DataManager: this.DataManager,
          }}
        >
          <UserMngReport
            handleDataUpdate={this.handleDataUpdate}
            publishMode={this.props.publishManagementReports}
            DataManager={this.DataManager}
            editor_type={"management_reports"}
            handleTOCUpdate={this.updateTOC}
            goBack={this.handleGoBack}
            {...this.props}
            PitchScenarioInfo={this.state.selectedReport}
            Charts={this.DashboardCharts}
            onSave={this.handleReportSave}
            previouslySelectedSlide={this.previouslySelectedSlide}
            setPreviouslySelectedSlide={this.setPreviouslySelectedSlide}
            Report={this.state.selectedReport}
          />
        </MyContext.Provider>
      );
    }

    if (this.Folders.length > 0) {
      return (
        <div className={"management_reports_main_container"}>
          <RecentReports
            management_reports={this.Reports}
            keyField={"Name"}
            onSelect={this.selectReport}
          />
          <div className={"mr_main_content"}>
            <div className={"mr_mc_left_menu_container"}>
              <LeftMenu
                avoidNameChangeRequest
                handleChange={this.handleChange}
                leftMenuTopOffset={430}
                visibleScrollbar
                update={() => this.forceUpdate()}
                updateOrder={this.updateFoldersOrder}
                selectedSection={this.state.selectedFolder}
                tabs={this.Folders.sort(this.sortFolders)}
                tabKey={"Name"}
                scale={this.scale}
                CurrentScreen={0}
                addNewSection={this.handleAddNewFolder}
                handleDeleteSection={this.handleDeleteSection}
                hideHiddenProperty
                hideDuplicate
                hideDelete={this.Folders.length < 2}
                ManagementReports
                text={"Are you sure you want to delete the Folder?"}
              />
            </div>
            <div className={"mr_mc_parent_table_container"}>
              <div className={"mr_mc_table_container"}>
                <MainTable
                  Folders={this.Folders}
                  customDashboards={global.Modeliks.CustomDashboards}
                  openDialog={this.openDialog}
                  keyField={"Name"}
                  selectedFolder={this.state.selectedFolder}
                  onAdd={this.handleGenerateReport}
                  onSelect={this.selectReport}
                  deleteReport={this.handleDeleteReport}
                  management_reports={this.Reports.filter(
                    (c) => c.FolderID == this.state.selectedFolder.ID,
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <IntroScreen
          Folders={this.Folders}
          onAdd={this.handleGenerateReport}
          openDialog={this.openDialog}
          customDashboards={global.Modeliks.CustomDashboards}
          keyField={"Name"}
        />
      );
    }
  }
}

export const MyContext = createContext("");

export default ManagementReports;
