import CircleLoader from "../../components/Loaders/CircleLoader/CircleLoader";
import * as React from "react";
import AvatarMenu from "../../components/menus/AvatarMenu/AvatarMenu";
import SlideEditorAdmin from "../../SlideEditor/Admin/index";
import { Routes, Route, Outlet, Link, Navigate } from "react-router-dom";
import IconButton from "./../../components/actions/IconButton";
import { MainContainer } from "../../components/containers/MainContainer";
import Menu from "../../components/containers/Menu";
import { Box } from "../../components/containers/Box";
import {
  ArrowDown,
  Avatar,
  Logo,
  QuestionIcon,
  SettingsIcon,
} from "../../components/icons/svgIcons";
import { Vector } from "../../components/dividers/Vector";
import TabsM from "../../components/tabs/Tabs";
import Dashboards from "./Dashboards/index";
import BusinessPlanAdmin from "../../BusinessPlan/Admin/index";
import {CommandManagerProvider} from "../../SlideEditor/services/commands/CommandManagerProvider";

class Admin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ctrl_key: new Date().getTime(),
    };

    this.modeliks_path_elements = {
      helper_routes: [
        { path: `*`, element: <Navigate to="/" key={"navigate_" + this.props.ctrl_key} /> },
      ],
      routes: [
        {
          path: `/pitch`,
          element: <Pitch key={"m_pitch_" + this.props.ctrl_key} />,
          UserPermissions: false,
        },
        {
          path: `/business_plan`,
          element: <BusinessPlan key={"m_buss_plan_" + this.props.ctrl_key} />,
          UserPermissions: false,
        },
        {
          path: `/dashboards/*`,
          element: <DashboardsTab key={"m_dashboards_" + this.props.ctrl_key} />,
          UserPermissions: false,
        },
        { path: `/`, element: <Navigate to="/pitch" />, UserPermissions: false, exact: true },
      ],
      tabs: [
        { link: `/pitch`, color: "black", parent: "/pitch", size: "p2", label: "Pitch" },
        {
          link: `/business_plan`,
          parent: "/business_plan",
          color: "black",
          size: "p2",
          label: "Business Plan",
        },
        {
          link: `/dashboards/charts`,
          parent: "/dashboards",
          color: "black",
          size: "p2",
          label: "Dashboards",
        },
      ],
    };
    this.renderModeliksRoutes = this.modeliks_path_elements.routes.map((route) => (
      <Route key={`f_route_${route.path}`} exact path={route.path} element={route.element} />
    ));
    this.renderModeliksHelperRutes = this.modeliks_path_elements.helper_routes.map((route) => (
      <Route key={`f_route_${route.path}`} exact path={route.path} element={route.element} />
    ));
  }

  render = () => {
    return (
      <Routes>
        <Route path="/" element={<Layout modeliks_elements={this.modeliks_path_elements} />}>
          {this.renderModeliksRoutes}
        </Route>
        {this.renderModeliksHelperRutes}
      </Routes>
    );
  };
}

function DashboardsTab() {
  return <Dashboards admin />;
}

function Layout(props) {
  if (props.modeliks_elements) {
    return (
      <>
        <MainContainer id="MainContainer">
          <Menu>
            <Box
              className={"box1"}
              style={{ width: "75%" }}
              display="flex"
              gap="51px"
              alignItems="center"
            >
              <Logo className="Logo" />
            </Box>
            <Box
              width="100%"
              style={{ width: "100%" }}
              display="flex"
              gap="40px"
              alignitems="center"
            >
              <TabsM>{props.modeliks_elements}</TabsM>
            </Box>
            <Box
              width="75%"
              style={{
                marginTop: "1px",
                marginRight: "-6px",
                width: "75%",
                justifyContent: "flex-end",
              }}
              display="flex"
              gap="19px"
              alignitems="center"
            >
              <AvatarMenu admin />
            </Box>
          </Menu>
          <Outlet />
        </MainContainer>
      </>
    );
  } else {
    return (
      <div className={"main_circle_loader_container"}>
        <CircleLoader />
      </div>
    );
  }
}

function Pitch() {
  return <CommandManagerProvider>
  <SlideEditorAdmin />
  </CommandManagerProvider>
}

function BusinessPlan() {
  return <CommandManagerProvider>
  <BusinessPlanAdmin />
  </CommandManagerProvider>
}

export default Admin;
