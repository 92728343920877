import React from "react";

export default function BorderedWhiteCard(props) {
  return (
    <>
      {props.visible && (
        <div className={props.className ? "white_card " + props.className : "white_card"}>
          {props.children}
        </div>
      )}
    </>
  );
}
