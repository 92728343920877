import React from "react";
import Button from "../../../../../components/actions/Button";
import { IconI, IconX } from "../../../../../components/icons/svgIcons";
import { Paragraph } from "../../../../../components/typography/paragraph/Paragraph";
import UnitSalesChart from "../../../../../components/chart/UnitSalesChart";
import PropTypes from "prop-types";
import "./SingleFinanceTab.scss";
import ButtonMui from "../../../../../components/buttons/buttonMui/buttonMui";
import UpdatePlan from "../../../../../components/dialogs/LimitDialogs/UpdatePlan";
import { AccessControl } from "../../../../../data/Permissions/AccessControl";
import Mx_Tooltip from "../../../../../components/icons/Mx_Tooltip_Click";
import Mx_Tooltip_Hover from "../../../../../components/icons/Mx_Tooltip_Hover";

const FormulaDescription = () => (
  <Mx_Tooltip description="With Set Formula you can create your own custom driver-based forecast calculations." />
);

class SingleFinanceChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      limitActive: false,
    };
  }

  onDriverClick = () => {
    if (!(this.props.disabled && !this.props.hasDriver)) {
      if (this.props.hasDriver == false && global.HelpHero) {
        global.HelpHero.startTour("y4wojDBmUO");
      }

      this.props.onClickCalculatedDriver();
    }
  };

  getFormulaButton = () => {
    if (this.props.hasGrowthValue) {
      const title = (
        <p className="spsfcfs_formula_tool_tip">
          When <b>growth rate</b> is selected you cannot set a formula for that driver.
        </p>
      );
      return (
        <span className="spsfcfs_formula_div_tool">
          <Mx_Tooltip_Hover
            title={title}
            icon={
              <ButtonMui
                width={125}
                disabled
                label={"Set Formula"}
                color="secondary"
                variant={"contained"}
              />
            }
          />
        </span>
      );
    }

    if (
      (!this.props.multiple && this.props.monthlyChart.data[0].IsSimple) ||
      this.props.isTabSignups
    ) {
      return (
        <>
          <ButtonMui
            width={125}
            disabled={this.props.disabled && !this.props.hasDriver}
            onClick={this.onDriverClick}
            label={this.props.hasDriver ? "Edit Formula" : "Set Formula"}
            color="secondary"
            variant={"contained"}
          />
          <FormulaDescription />
        </>
      );
    }
  };

  render() {
    this.validMonthValues = false;
    this.validYearValues = false;
    this.dummyMonthData = [];
    this.dummyYearData = [];
    this.props.monthlyChart.data.forEach((el) => {
      if (el.Value !== null) {
        this.validMonthValues = true;
      }
      this.dummyMonthData.push({ Value: 50, DateHeader: el.DateHeader });
    });

    this.props.yearlyChart.data.forEach((el, index) => {
      if (el.Value !== null) {
        if (index !== 0 && el.Value !== 0) {
          this.validYearValues = true;
        }
      }
      this.dummyYearData.push({ Value: 50, DateHeader: el.DateHeader });
    });

    return (
      <>
        {this.state.limitActive && (
          <UpdatePlan
            handleClose={() => this.setState({ limitActive: false })}
            open={this.state.limitActive}
          />
        )}

        <div className="pscf_single_finance_chart_container">
          <div className="pscf_single_finance_chart_div">
            <div className="pscf_single_finance_chart_div_content">
              <Paragraph size="p1_strong" label={this.props.title} />
              <div className="pscf_single_finance_chart_button">
                <AccessControl
                  allowedPermissions={[
                    {
                      [global.Modeliks.PERMISSIONS.Financials.key]:
                        global.Modeliks.PERMISSIONS.Financials.restrictions.FinancialDriverFeature
                          .key,
                    },
                  ]}
                  renderNoAccess={
                    <>
                      <ButtonMui
                        width={125}
                        onClick={() => this.setState({ limitActive: true })}
                        label={this.props.hasDriver ? "Edit Formula" : "Set Formula"}
                        color="secondary"
                        variant={"contained"}
                      />
                      <FormulaDescription />
                    </>
                  }
                >
                  {this.getFormulaButton()}
                </AccessControl>
              </div>
            </div>
            <div className="pscf_single_finance_chart_main_charts">
              <div className="pscf_single_finance_chart_first_chart">
                <UnitSalesChart
                  height={50.9}
                  data={this.validMonthValues ? this.props.monthlyChart.data : this.dummyMonthData}
                  color={"#0088F7"}
                  labels={this.props.monthlyChart.labels}
                  field={this.props.field}
                  unit={this.props.unit}
                  isItalic={this.props.unit && this.props.unit === "%"}
                  displayOfDecimals={this.props.unit === "%" ? 2 : this.props.displayOfDecimals}
                  changeValue={this.props.onMonthChartChangeValue}
                  changeValueEnabled={!this.props.checkedGrowth}
                  valueLabels={this.props.monthlyChart.data}
                  valueLabelsTotal={
                    this.props.monthlyChartTotal && this.props.monthlyChartTotal.data
                  }
                  dummyValues={!this.validMonthValues}
                />
              </div>
              <div className="pscf_single_finance_chart_second_chart">
                <UnitSalesChart
                  height={200.5}
                  data={
                    this.validYearValues || this.validMonthValues
                      ? this.props.yearlyChart.data
                      : this.dummyYearData
                  }
                  color={"#00B3B0"}
                  labels={this.props.yearlyChart.labels}
                  field={this.props.field}
                  unit={this.props.unit}
                  isItalic={this.props.unit && this.props.unit === "%"}
                  displayOfDecimals={this.props.unit === "%" ? 2 : this.props.displayOfDecimals}
                  changeValue={this.props.onYearChartChangeValue}
                  changeValueEnabled={!this.props.checkedGrowth}
                  valueLabels={this.props.yearlyChart.data}
                  valueLabelsTotal={this.props.yearlyChartTotal && this.props.yearlyChartTotal.data}
                  dummyValues={!(this.validYearValues || this.validMonthValues)}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

SingleFinanceChart.propTypes = {
  title: PropTypes.string,
  monthlyChartData: PropTypes.object,
  yearlyChartData: PropTypes.object,
  dataGridRow: PropTypes.object,
  onMonthChartChangeValue: PropTypes.func,
  onYearChartChangeValue: PropTypes.func,
  onClickCalculatedDriver: PropTypes.func,
  checkedGrowth: PropTypes.bool,
  yearlyChart: PropTypes.object,
  monthlyChart: PropTypes.object,
};

export default SingleFinanceChart;
