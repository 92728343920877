import React, { Component } from "react";
import Card from "../../../../components/cards/Card";
import Input from "../../../../components/actions/Input";
import { GreyCard as GreyCardOld } from "../../../../components/cards/GreyCard";
import styled from "styled-components";
import Button from "../../../../components/actions/Button";
import { HistoryCard } from "../../../../components/cards/HistoryCard";
import CancelDialog from "../../../../components/dialogs/settings/CancelDialog";
import { IconI } from "../../../../components/icons/svgIcons";
import MaterialSelect from "../../../../components/actions/SelectM";
import request from "superagent";
import { Upgrade_Downgrade_types } from "../../../../data/SubscriptionEnum";
import Mx_MainLoader from "../../../../components/Loaders/MainLoader/Mx_MainLoader";
import ButtonMui from "../../../../components/buttons/buttonMui/buttonMui";
import Mx_Tooltip from "../../../../components/icons/Mx_Tooltip_Click";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import ActiveSubMenu from "../../../../helpers/ActiveSubMenu";
import { NavigationF } from "../../../../components/containers/NavigationF";
import { Box } from "../../../../components/containers/Box";
import ProfileTabs from "../../../../components/tabs/ProfileTabs";
import "./account_details.scss";
import GeneralInfo from "./components/GeneralInfo";
import BillingHistory from "./components/BillingHistory";
import ManageSubscription from "./components/ManageSubscription";
import ManageSubscriptionLifeTime from "./components/ManageSubscriptionLifeTime";
import TransactionsHistory from "./components/TransactionsHistory";
import CircleLoader from "../../../../components/Loaders/CircleLoader/CircleLoader";

const L3 = styled.div`
  font-weight: 600;
  font-size: 12px;
  font-family: Inter;
`;

const P3 = styled.div`
  font-weight: 400;
  font-size: 14px;
  font-family: Inter;
  line-height: 22.4px;
`;

const P4Strong = styled.div`
  font-weight: 700;
  font-size: 12px;
  font-family: Inter;
  line-height: 22.4px;
`;

const P4 = styled.div`
  font-weight: 400;
  font-size: 12px;
  font-family: Inter;
  line-height: 22.4px;
`;

const StyledUl = styled.ul`
  line-height: 213%;
  font-family: Inter;
  font-size: 12px;
  width: 100%;
  padding: 0;
  margin: 0;
  padding-left: 30px;
`;

const P2Strong = styled.div`
  font-weight: 700;
  font-size: 16px;
  font-family: Inter;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;
const StyledLI = styled.div`
  padding-top: 1px;
  display: flex;
  gap: 17px;
  align-items: center;
  font-family: Inter;
`;

const StrongLi = styled.div`
  padding-top: 1px;
  display: flex;
  gap: 17px;
  align-items: center;
  font-family: Inter;
  font-weight: 700;
`;
const Description = {
  SubscriptionPlan:
    "Choose the plan that best fits your needs. Upgrade or downgrade your subscription at any time to access additional features or adjust your level of service. Select a plan below to view the pricing.",
  NumberOfEditors:
    "Expand your team by adding additional users to your Account. Each additional user costs $5 USD per month. Upgrade your subscription plan to accommodate more users and enhance collaboration within your organization.",
  NumberOfCompanies:
    "Increase the number of companies. The cost for each additional company varies based on your subscription plan.",
};

class AccountDetails extends React.Component {
  constructor(props) {
    super(props);
    this.User = global.Modeliks.User;
    this.Account = global.Modeliks.Account;
    this.state = {
      loading: false,
      open: true,
      accDialog: false,
      subDialog: false,
      accountName: this.Account.Name ? this.Account.Name : "",
      numberOfCompanies: global.Modeliks.limitInfo.TotalCompanies,
      numberOfEditors: global.Modeliks.limitInfo.TotalUsers,
      extra_users: 0,
      selectedSubscriptionID: global.Modeliks.subscriptionInfo.SubscriptionID,
      OpenCheckout: false,
      TransactionID: null,
      type: null,
    };
  }

  componentDidMount() {}

  colors = [
    { colorHex: "#01AFFD", color: "blue" },
    { colorHex: "#01AFFD", color: "blue" },
    { colorHex: "#01AFFD", color: "blue" },
    { colorHex: "#01AFFD", color: "blue" },
  ];

  openAccDialog = () => {
    this.setState({ accDialog: true });
  };
  closeAccDialog = () => {
    this.setState({ accDialog: false });
  };

  openSubDialog = () => {
    this.setState({ subDialog: true });
  };
  closeSubDialog = () => {
    this.setState({ subDialog: false });
  };

  cancelSubscription = () => {
    request
      .put("/api/updateSubscriptionPauseStatus")
      .set("Content-Type", "application/x-www-form-urlencoded")
      .send({
        AccountID: global.Modeliks.Account.ID,
      })
      .then((res) => {
        if (res) {
          if (
            global.Modeliks.subscriptionInfo.isCanceled === null ||
            global.Modeliks.subscriptionInfo.isCanceled === false
          ) {
            global.Modeliks.subscriptionInfo.isCanceled = true;
          } else {
            global.Modeliks.subscriptionInfo.isCanceled = false;
          }
          this.forceUpdate();
          this.closeSubDialog();
        }
      });
  };

  setNumberOfEditors = (e) => {
    this.setState({ numberOfEditors: e.target.value });
  };

  setNumberOfCompanies = (e) => {
    this.setState({ numberOfCompanies: e.target.value });
  };

  getCurrentCompanyPrice = () => {
    let date_1 = new Date(global.Modeliks.subscriptionInfo.DateEnd);
    let date_2 = new Date();
    let difference = date_1.getTime() - date_2.getTime();
    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    return parseFloat(
      (((this.state.numberOfCompanies - global.Modeliks.limitInfo.TotalCompanies) *
        global.Modeliks.subscriptionInfo.price) /
        30) *
        TotalDays,
    ).toFixed(2);
  };

  getCurrentUserPrice = () => {
    let date_1 = new Date(global.Modeliks.subscriptionInfo.DateEnd);
    let date_2 = new Date();
    let difference = date_1.getTime() - date_2.getTime();
    let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
    return parseFloat(
      (((this.state.numberOfEditors - global.Modeliks.limitInfo.TotalUsers) *
        global.Modeliks.subscriptionInfo.price_extra_user) /
        30) *
        TotalDays,
    ).toFixed(2);
  };

  ConfirmDowngradeCompanies = () => {
    this.setState({ loading: true });
    request
      .post("/api/DowngradeSubscription")
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("authorization", "Bearer " + window.localStorage.getItem("token"))
      .send({
        AccountID: global.Modeliks.Account.ID,
        type: Upgrade_Downgrade_types.Companies,
        numberOfCompanies: this.state.numberOfCompanies,
      })
      .then((res) => {
        if (res) {
          this.setState({ loading: false });
          window.location.reload(false);
        }
      });
  };

  ConfirmUpgradeCompanies = () => {
    this.setState({ loading: true });
    request
      .post("/api/UpgradeSubscription")
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("authorization", "Bearer " + window.localStorage.getItem("token"))
      .send({
        AccountID: global.Modeliks.Account.ID,
        type: Upgrade_Downgrade_types.Companies,
        numberOfCompanies: this.state.numberOfCompanies,
        clickid: global.Modeliks.referralId,
        referralSource: global.Modeliks.referralSource,
      })
      .then((res) => {
        if (res) {
          this.setState({ loading: false });
          if (res.body === "OK") {
            window.location.reload(false);
          }
        }
      });
  };

  ConfirmUpgradeUsers = () => {
    this.setState({ loading: true });
    request
      .post("/api/UpgradeSubscription")
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("authorization", "Bearer " + window.localStorage.getItem("token"))
      .send({
        AccountID: global.Modeliks.Account.ID,
        type: Upgrade_Downgrade_types.Users,
        numberOfUsers: this.state.numberOfEditors,
        clickid: global.Modeliks.referralId,
        referralSource: global.Modeliks.referralSource,
      })
      .then((res) => {
        if (res) {
          this.setState({ loading: false });
          if (res.body === "OK") {
            window.location.reload(false);
          }
        }
      });
  };

  ConfirmDowngradeUsers = () => {
    this.setState({ loading: true });
    request
      .post("/api/DowngradeSubscription")
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("authorization", "Bearer " + window.localStorage.getItem("token"))
      .send({
        AccountID: global.Modeliks.Account.ID,
        type: Upgrade_Downgrade_types.Users,
        numberOfUsers: this.state.numberOfEditors,
      })
      .then((res) => {
        if (res) {
          this.setState({ loading: false });
          window.location.reload(false);
        }
      });
  };

  ConfirmDowngradePlan = () => {
    this.setState({ loading: true });
    request
      .post("/api/DowngradeSubscription")
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("authorization", "Bearer " + window.localStorage.getItem("token"))
      .send({
        AccountID: global.Modeliks.Account.ID,
        type: Upgrade_Downgrade_types.Subscription,
        selectedSubscriptionID: this.state.selectedSubscriptionID,
      })
      .then((res) => {
        if (res) {
          this.setState({ loading: false });
          window.location.reload(false);
        }
      });
  };

  ConfirmUpgradePlan = () => {
    console.log("test", global.Modeliks.subscriptionInfo.price != 0);

    if (global.Modeliks.subscriptionInfo.price != 0) {
      this.setState({ loading: true });
      request
        .post("/api/UpgradeSubscription")
        .set("Content-Type", "application/x-www-form-urlencoded")
        .set("authorization", "Bearer " + window.localStorage.getItem("token"))
        .send({
          AccountID: global.Modeliks.Account.ID,
          type: Upgrade_Downgrade_types.Subscription,
          selectedSubscriptionID: this.state.selectedSubscriptionID,
          email: global.Modeliks.User.Email,
          clickid: global.Modeliks.referralId,
          referralSource: global.Modeliks.referralSource,
        })
        .then((res) => {
          if (res) {
            this.setState({ loading: false });
            window.location.reload(false);
          }
        });
    } else {
      window.Paddle.Checkout.open({
        items: [
          {
            priceId: global.Modeliks.filteredSubscriptionPlans().find(
              (c) => c.ID === this.state.selectedSubscriptionID,
            ).paddle_price,
            quantity: 1,
          },
        ],
        customer: {
          email: global.Modeliks.User.Email,
        },
        customData: {
          account_id: global.Modeliks.Account.ID,
          subscription_id: this.state.selectedSubscriptionID,
        },
      });
    }
  };

  getSubscriptionPlansOptions = () => {
    let options = [];
    global.Modeliks.filteredSubscriptionPlans().map((c) =>
      options.push({ value: c.ID, description: c.name }),
    );
    return options;
  };

  changeSubscriptionPlan = (e) => {
    this.setState({ selectedSubscriptionID: e.target.value });
  };

  shouldShowUpgradeSubscription = () => {
    let currentSubscriptionID = global.Modeliks.subscriptionInfo.SubscriptionID;
    if (this.state.selectedSubscriptionID !== currentSubscriptionID) {
      let selectedSubscription = global.Modeliks.subscriptionPlans.find(
        (c) => c.ID === this.state.selectedSubscriptionID,
      );
      let currentSubscription = global.Modeliks.subscriptionPlans.find(
        (c) => c.ID === currentSubscriptionID,
      );

      if (selectedSubscription.strength > currentSubscription.strength) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  shouldShowDowngradeSubscription = () => {
    let currentSubscriptionID = global.Modeliks.subscriptionInfo.SubscriptionID;
    if (this.state.selectedSubscriptionID !== currentSubscriptionID) {
      let selectedSubscription = global.Modeliks.subscriptionPlans.find(
        (c) => c.ID === this.state.selectedSubscriptionID,
      );
      let currentSubscription = global.Modeliks.subscriptionPlans.find(
        (c) => c.ID === currentSubscriptionID,
      );

      if (selectedSubscription.strength < currentSubscription.strength) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  returnCompaniesGrayCard = () => {
    if (
      global.Modeliks.subscriptionInfo.price == 0 &&
      this.state.numberOfCompanies > global.Modeliks.limitInfo.TotalCompanies
    ) {
      return (
        <GreyCardOld height={"80px"}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "5px",
              paddingTop: "5px",
              paddingBottom: "5px",
              height: "100%",
              gap: "20px",
            }}
          >
            <P3>To increase the number of companies, please subscribe to a paid plan.</P3>
          </div>
        </GreyCardOld>
      );
    } else if (this.state.numberOfCompanies > global.Modeliks.limitInfo.TotalCompanies) {
      return (
        <GreyCardOld height={"220px"}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "5px",
              paddingTop: "5px",
              paddingBottom: "5px",
              height: "100%",
              gap: "20px",
            }}
          >
            <P3>
              When adding a new company to your subscription plan, you will be charged{" "}
              {this.getCurrentPlanPrice()}$, prorated for the remaining time in your current
              subscription. Meaning your subscription plan will be the same for all your companies.
            </P3>
          </div>
          <StyledDiv>
            <Button
              size={"small"}
              padding={"0"}
              backgroundColor={"primary"}
              label={"Confirm"}
              onClick={this.ConfirmUpgradeCompanies}
            ></Button>

            <Button
              label="Cancel"
              size="small"
              onClick={() => {
                this.setState({ numberOfCompanies: global.Modeliks.limitInfo.TotalCompanies });
              }}
              padding="5px"
              color="black"
              noElevation={true}
            />
          </StyledDiv>
        </GreyCardOld>
      );
    } else if (this.state.numberOfCompanies < global.Modeliks.limitInfo.TotalCompanies) {
      return (
        <GreyCardOld height={"220px"}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "5px",
              paddingTop: "5px",
              paddingBottom: "5px",
              height: "100%",
              gap: "20px",
              overflow: "scroll",
            }}
          >
            <P3>
              When decreasing the number of companies, your change will be effective immediately.
              The remaining part of your subscription for the removed company will be given to you
              as credit. We will automatically reduce the price you pay during your next billing
              period for your credit. The credit balance will be set off against future invoices,
              but it can’t be refunded.
            </P3>
          </div>
          <StyledDiv>
            <Button
              size={"small"}
              padding={"0"}
              backgroundColor={"primary"}
              label={"Confirm"}
              onClick={this.ConfirmDowngradeCompanies}
            ></Button>

            <Button
              label="Cancel"
              size="small"
              onClick={() => {
                this.setState({ numberOfCompanies: global.Modeliks.limitInfo.TotalCompanies });
              }}
              padding="5px"
              color="black"
              noElevation={true}
            />
          </StyledDiv>
        </GreyCardOld>
      );
    } else {
      return "";
    }
  };

  returnEditorsGrayCard = () => {
    if (
      global.Modeliks.subscriptionInfo.price == 0 &&
      this.state.numberOfEditors > global.Modeliks.limitInfo.TotalUsers
    ) {
      return (
        <GreyCardOld height={"80px"}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "5px",
              paddingTop: "5px",
              paddingBottom: "5px",
              height: "100%",
              gap: "20px",
            }}
          >
            <P3>To increase the number of editors, please subscribe to a paid plan.</P3>
          </div>
        </GreyCardOld>
      );
    } else if (this.state.numberOfEditors > global.Modeliks.limitInfo.TotalUsers) {
      return (
        <GreyCardOld height={"185px"}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "5px",
              paddingTop: "5px",
              paddingBottom: "5px",
              height: "100%",
              gap: "20px",
            }}
          >
            <P3>
              For increasing the number of editors, you will be charged USD $
              {global.Modeliks.subscriptionInfo.price_extra_user} per month, per additional editor,
              for the remaining time of your current subscription.{" "}
            </P3>
          </div>
          <StyledDiv>
            <Button
              size={"small"}
              padding={"0"}
              backgroundColor={"primary"}
              label={"Confirm"}
              onClick={this.ConfirmUpgradeUsers}
            ></Button>

            <Button
              label="Cancel"
              size="small"
              onClick={() => {
                this.setState({ numberOfEditors: global.Modeliks.limitInfo.TotalUsers });
              }}
              padding="5px"
              color="black"
              noElevation={true}
            />
          </StyledDiv>
        </GreyCardOld>
      );
    } else if (this.state.numberOfEditors < global.Modeliks.limitInfo.TotalUsers) {
      return (
        <GreyCardOld height={"220px"}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "5px",
              paddingTop: "5px",
              paddingBottom: "5px",
              height: "100%",
              gap: "20px",
              overflow: "scroll",
            }}
          >
            <P3>
              When decreasing the number of editors, your change will be effective immediately. The
              remaining part of your subscription for the removed editors will be given to you as
              credit. We will automatically reduce the price you pay during your next billing period
              for your credit. The credit balance will be set off against future invoices, but it
              can’t be refunded.{" "}
            </P3>
          </div>
          <StyledDiv>
            <Button
              size={"small"}
              padding={"0"}
              backgroundColor={"primary"}
              label={"Confirm"}
              onClick={this.ConfirmDowngradeUsers}
            ></Button>

            <Button
              label="Cancel"
              size="small"
              onClick={() => {
                this.setState({ numberOfEditors: global.Modeliks.limitInfo.TotalUsers });
              }}
              padding="5px"
              color="black"
              noElevation={true}
            />
          </StyledDiv>
        </GreyCardOld>
      );
    }
  };

  returnSubscriptionGrayCard = () => {
    if (this.shouldShowUpgradeSubscription()) {
      return (
        <GreyCardOld height={"185px"}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "5px",
              paddingTop: "5px",
              paddingBottom: "5px",
              height: "100%",
              gap: "20px",
            }}
          >
            <P3>
              For upgrading the plan, you’ll be charged {this.getCurrentPlanPrice()}$, prorated for
              the remaining part of your current subscription.
            </P3>
          </div>
          <StyledDiv>
            <Button
              size={"small"}
              padding={"0"}
              backgroundColor={"primary"}
              label={"Confirm"}
              onClick={this.ConfirmUpgradePlan}
            ></Button>

            <Button
              label="Cancel"
              size="small"
              onClick={() => {
                this.setState({
                  selectedSubscriptionID: global.Modeliks.subscriptionInfo.SubscriptionID,
                });
              }}
              padding="5px"
              color="black"
              noElevation={true}
            />
          </StyledDiv>
        </GreyCardOld>
      );
    } else if (this.shouldShowDowngradeSubscription()) {
      return (
        <GreyCardOld height={"220px"}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "5px",
              paddingTop: "5px",
              paddingBottom: "5px",
              height: "100%",
              gap: "20px",
              overflow: "scroll",
            }}
          >
            <P3>
              When downgrading your subscription plan, your change will be effective immediately.
              The remaining part of your current subscription will be given to you as credit. We
              will automatically reduce the price you pay during your next billing period for your
              credit. The credit balance will be set off against future invoices, but it can’t be
              refunded. When downgrading from a higher to lower plan, please ensure you don’t lose
              any important data, in case the new plan is missing some of the features you will
              require in the future.
            </P3>
          </div>

          <StyledDiv>
            <Button
              size={"small"}
              padding={"0"}
              backgroundColor={"primary"}
              label={"Confirm"}
              onClick={this.ConfirmDowngradePlan}
            ></Button>

            <Button
              label="Cancel"
              size="small"
              onClick={() => {
                this.setState({
                  selectedSubscriptionID: global.Modeliks.subscriptionInfo.SubscriptionID,
                });
              }}
              padding="5px"
              color="black"
              noElevation={true}
            />
          </StyledDiv>
        </GreyCardOld>
      );
    } else {
      return "";
    }
  };

  getCurrentPlanPrice = () => {
    let selectedSubscription = global.Modeliks.subscriptionPlans.find(
      (c) => c.ID === this.state.selectedSubscriptionID,
    );
    return selectedSubscription.recurring_price;
  };

  parseDate = (date) => {
    return new Date(date).toLocaleString("en-US", {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    // return new Date(date).toLocaleDateString('en-us', {year:"numeric", month:"short", day:"numeric"});
  };

  render_old() {
    if (!this.state.loading) {
      return (
        <>
          <div
            style={{
              display: "flex",
              gap: "16px",
              width: "100%",
              justifyContent: "center",
              overflow: "auto",
            }}
          >
            <Card title={"General Info"} hasTitle={true} height={"670px"}>
              <div style={{ display: "flex", flexDirection: "column", gap: "16px", width: "100%" }}>
                <div className="spss_second_container_input">
                  <StyledLI>
                    <P2Strong>Account Name</P2Strong>
                  </StyledLI>
                </div>

                <div className="spss_cards_container_input">
                  <Input
                    onBlur={() => this.changeAccountName()}
                    placeholder={"Enter account name"}
                    allowEmpty={true}
                    onChange={(e) => this.setState({ accountName: e.target.value })}
                    value={this.state.accountName}
                  />
                </div>

                <div className="spss_second_container_input">
                  <StyledLI>
                    <P2Strong>Account Owner</P2Strong>
                    <IconI />
                  </StyledLI>
                </div>

                <div className="spss_cards_container_input">
                  <Input
                    value={
                      global.Modeliks.AccountUsers.find(
                        (c) => c.User.ID === global.Modeliks.Account.Owner_ID,
                      )
                        ? global.Modeliks.AccountUsers.find(
                            (c) => c.User.ID === global.Modeliks.Account.Owner_ID,
                          ).User.Email
                        : ""
                    }
                    disabled
                    max={30}
                    name="Owner"
                    onChange={(e) => e.preventDefault()}
                  />
                </div>
                <div className="spss_second_container_input">
                  <StyledLI>
                    <P2Strong>Plan Name:</P2Strong>
                    <span>
                      {global.Modeliks.subscriptionInfo.SubscriptionID &&
                        global.Modeliks.subscriptionPlans.find(
                          (p) => p.ID === global.Modeliks.subscriptionInfo.SubscriptionID,
                        ).name}
                    </span>
                  </StyledLI>
                </div>

                <GreyCardOld>
                  <StyledUl>
                    <li>
                      {global.Modeliks.subscriptionInfo.price + "$"} per{" "}
                      {global.Modeliks.subscriptionInfo.billing_type}
                    </li>
                    <li>active until {this.parseDate(global.Modeliks.subscriptionInfo.DateEnd)}</li>
                    <li>next billing {this.parseDate(global.Modeliks.subscriptionInfo.DateEnd)}</li>
                    <li>
                      up to {global.Modeliks.subscriptionInfo.users_number}{" "}
                      {global.Modeliks.subscriptionInfo.users_number === 1 ? "user" : "users"}
                    </li>
                    <li>1 company</li>
                  </StyledUl>
                </GreyCardOld>

                <div className="spss_second_container_input">
                  <StyledLI>
                    <P2Strong>Additions to Plan:</P2Strong>
                  </StyledLI>
                </div>

                <GreyCardOld>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                      width: "100%",
                    }}
                  >
                    <P4>
                      +{" "}
                      {global.Modeliks.subscriptionInfo.TotalUsers -
                        global.Modeliks.subscriptionInfo.users_number}{" "}
                      Users ($5 per user)
                    </P4>
                    <P4>
                      + {global.Modeliks.subscriptionInfo.TotalCompanies - 1} Company ($59 per
                      Company)
                    </P4>
                    <P4Strong>Total Users: {global.Modeliks.subscriptionInfo.TotalUsers}</P4Strong>
                    <P4Strong>
                      Total Companies: {global.Modeliks.subscriptionInfo.TotalCompanies}
                    </P4Strong>
                  </div>
                </GreyCardOld>

                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end",
                    marginTop: "10px",
                  }}
                >
                  <ButtonMui
                    style={{ height: "34px" }}
                    label="Cancel Account"
                    variant={"contained"}
                    onClick={() => this.openAccDialog()}
                  />
                </div>
              </div>
            </Card>

            <Card title={"Manage Subscription"} hasTitle={true} height={"670px"}>
              <div style={{ display: "flex", flexDirection: "column", gap: "9px", width: "100%" }}>
                <div className="spss_second_container_input">
                  <StyledLI>
                    <P2Strong>Subscription Plan</P2Strong>
                    <Mx_Tooltip description={Description.SubscriptionPlan} />
                  </StyledLI>
                </div>
                <MaterialSelect
                  minWidth="332px"
                  value={this.state.selectedSubscriptionID}
                  options={this.getSubscriptionPlansOptions()}
                  onChange={(e) => this.changeSubscriptionPlan(e)}
                />
                {this.returnSubscriptionGrayCard()}
                <div className="spss_second_container_input">
                  <StyledLI>
                    <P2Strong>Number of Users</P2Strong>
                    <Mx_Tooltip description={Description.NumberOfEditors} />
                  </StyledLI>
                </div>

                <MaterialSelect
                  minWidth="332px"
                  onChange={(e) => this.setNumberOfEditors(e)}
                  value={this.state.numberOfEditors}
                  options={[
                    { value: 1, description: "1 User" },
                    {
                      value: 2,
                      description: "2 Users",
                    },
                    { value: 3, description: "3 Users" },
                    {
                      value: 4,
                      description: "4 Users",
                    },
                    { value: 5, description: "5 Users" },
                    { value: 6, description: "6 Users" },
                    { value: 7, description: "7 Users" },
                    { value: 8, description: "8 Users" },
                    { value: 9, description: "9 Users" },
                    { value: 10, description: "10 Users" },
                  ]}
                />
                {this.returnEditorsGrayCard()}
                <div className="spss_second_container_input">
                  <StyledLI>
                    <P2Strong>Number of Companies</P2Strong>
                    <Mx_Tooltip description={Description.NumberOfCompanies} />
                  </StyledLI>
                </div>
                <MaterialSelect
                  minWidth="332px"
                  onChange={(e) => this.setNumberOfCompanies(e)}
                  value={this.state.numberOfCompanies}
                  options={[
                    { value: 1, description: "1 Company" },
                    {
                      value: 2,
                      description: "2 Companies",
                    },
                    { value: 3, description: "3 Companies" },
                    {
                      value: 4,
                      description: "4 Companies",
                    },
                    { value: 5, description: "5 Companies" },
                  ]}
                />
                {this.returnCompaniesGrayCard()}
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end",
                    marginTop: "20px",
                  }}
                >
                  <ButtonMui
                    style={{ height: "34px" }}
                    onClick={() => this.openSubDialog()}
                    label={
                      global.Modeliks.subscriptionInfo.isCanceled
                        ? "Continue Subscription"
                        : "Cancel Subscription"
                    }
                    variant={"contained"}
                  />
                </div>
              </div>
            </Card>
            <Card hasTitle={true} title={"Billing History"} height={"670px"}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  marginTop: "-4px",
                }}
              >
                <L3 style={{ width: "100%" }}>Billing Date</L3>
                <L3 style={{ width: "61%" }}>Amount</L3>
                {/*<L3 style={{width: '70%', textAlign: 'right', paddingRight: '8px'}}>Receipt</L3>*/}
              </div>
              <div style={{ width: "100%", borderBottom: "1px solid #F0F1F7", marginTop: "8px" }} />
              {global.Modeliks.transactionsInfo &&
                global.Modeliks.transactionsInfo.map((c) => {
                  return (
                    <HistoryCard
                      date={this.parseDate(new Date(c.created * 1000))}
                      amount={
                        Number(c.amount_paid / 100).toLocaleString(undefined, {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                        }) + global.Modeliks.CompanyInfo.Currency.value
                      }
                    />
                  );
                })}
            </Card>
          </div>

          {this.state.accDialog && (
            <CancelDialog
              open={this.state.accDialog}
              onExit={() => this.closeAccDialog()}
              onDelete={() => this.closeAccDialog()}
            >
              If you cancel your account now, you'll still have access to it until the end of the
              current subscription period (Nov 01, 2022). After Nov 01, 2022 we will backup your
              data in case you want to reactivate your subscription and have access to your data.
            </CancelDialog>
          )}
          {this.state.subDialog && (
            <CancelDialog
              btnName={
                global.Modeliks.subscriptionInfo.isCanceled
                  ? "Continue Subscription"
                  : "Cancel Subscription"
              }
              title={
                global.Modeliks.subscriptionInfo.isCanceled
                  ? "Continue your subscription"
                  : "Cancel your subscription"
              }
              open={this.state.subDialog}
              onExit={() => this.closeSubDialog()}
              onDelete={() => this.cancelSubscription()}
            >
              {global.Modeliks.subscriptionInfo.isCanceled
                ? `Your current subscription is valid until ${this.parseDate(global.Modeliks.subscriptionInfo.DateEnd)}. We will automatically renew your subscription on ${this.parseDate(global.Modeliks.subscriptionInfo.DateEnd)}.`
                : `If you cancel your subscription now, you’ll still have access to it until the end of the current subscription period (${this.parseDate(global.Modeliks.subscriptionInfo.DateEnd)}). After ${this.parseDate(global.Modeliks.subscriptionInfo.DateEnd)} we will backup your data in case you want to reactivate your subscription and have access to your data.`}
              <div style={{ paddingBottom: "20px" }} />
            </CancelDialog>
          )}
        </>
      );
    } else {
      return <Mx_MainLoader />;
    }
  }

  render() {
    let routes = [
      { path: "/general_info", element: <GeneralInfoTab />, label: "General Info" },
      {
        path: "/manage_subscription",
        element: <ManageSubscriptionTab />,
        label: "Manage Subscription",
      },
      { path: "/transactions", element: <ListAllTransactionsTab />, label: "Transactions" },
    ];

    const routeComponents = routes.map(({ path, element }, key) => (
      <Route exact path={path} element={element} key={"acc_detauls_route_" + key} />
    ));
    return (
      <Routes>
        <Route path="/" element={<AccountDetailsLayout routes={routes} />}>
          {routeComponents}

          <Route path={"/*"} element={<Navigate to={"/profile/account_details/general_info"} />} />
        </Route>

        <Route path={"/*"} element={<Navigate to={"/profile/account_details/general_info"} />} />
      </Routes>
    );
  }
}

export const parseDate = (date) => {
  return new Date(date).toLocaleString(undefined, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
  // return new Date(date).toLocaleString("en-US", {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone})
  // return new Date(date).toLocaleDateString('en-us', {year:"numeric", month:"short", day:"numeric"});
};

const AccountDetailsLayout = (props) => {
  const submenuComponents = props.routes.map(({ path, label }, key) => {
    return (
      <ActiveSubMenu
        width={"auto"}
        link={"/profile/account_details" + path}
        color={"black"}
        size="p3"
        label={label}
        key={"acc_details_" + key}
      />
    );
  });

  const getSessionPortal = () => {
    request
      .post("/api/create-customer-portal-session")
      .set("authorization", "Bearer " + window.localStorage.getItem("token"))
      .send({ link: window.location.href })
      .then((data) => {
        window.open(data.body, "_self");
      });
  };

  return (
    <div className={"grid_container acc_details"}>
      <NavigationF>
        <Box
          style={{ width: "100%" }}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <ProfileTabs>{submenuComponents}</ProfileTabs>
        </Box>
      </NavigationF>
      <Outlet />
    </div>
  );
};

class WhiteCard extends Component {
  constructor(props) {
    super(props);

    this.titleClass = this.props.titleClass ? this.props.titleClass : "";
    this.className = this.props.className ? this.props.className : "";
  }

  render() {
    return (
      <div className={"card " + this.className}>
        {this.props.title && (
          <div className={"title_cont"}>
            <div className={"title " + this.titleClass}>{this.props.title}</div>
          </div>
        )}
        <div className={"content"} style={{ height: "100%" }}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export const GreyCard = (props) => {
  const className = props.className ? props.className : "";

  return <div className={"grey_card " + className}>{props.children}</div>;
};

class GeneralInfoTab extends Component {
  render() {
    return (
      <div className={"tab_content"}>
        <WhiteCard className={"general_info"}>
          <GeneralInfo />
        </WhiteCard>
        {/*<WhiteCard title={'Billing History'} className={'billing_history'}>*/}
        {/*    <BillingHistory/>*/}
        {/*</WhiteCard>*/}
      </div>
    );
  }
}

class ListAllTransactionsTab extends Component {
  constructor(props) {
    super(props);
    this.getAllTransactions();
    this.transactions = [];
    this.state = {
      loading: true,
    };
  }

  getAllTransactions = () => {
    request
      .get(
        `/api/getAllTransactions?subscription_id=${global.Modeliks.subscriptionInfo.PaddleSubscriptionID}`,
      )
      .then((res) => {
        if (res && res.body) {
          this.transactions = res.body;
          this.setState({ loading: false });
        }
      });
  };

  render() {
    if (this.state.loading) {
      return <CircleLoader />;
    }
    return (
      <div className={"tab_content"}>
        <WhiteCard className={`general_info manage_subscription`}>
          <TransactionsHistory transactions={this.transactions} />
        </WhiteCard>
      </div>
    );
  }
}

class ManageSubscriptionTab extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className={"tab_content"}>
        <WhiteCard
          className={`general_info manage_subscription ${global.Modeliks.subscriptionInfo.isLifeTime ? "small" : ""}`}
        >
          {!global.Modeliks.subscriptionInfo.isLifeTime ? (
            <ManageSubscription />
          ) : (
            <ManageSubscriptionLifeTime />
          )}
        </WhiteCard>
      </div>
    );
  }
}

export default AccountDetails;
