import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  centerToTL,
  tLToCenter,
  getNewStyle,
  degToRadian,
  getLength,
} from "../../ResizableRect/utils";
import LineRect from "./Rect/LineRect";

export default class ResizableLineRect extends Component {
  static propTypes = {
    left: PropTypes.number.isRequired,
    top: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    rotatable: PropTypes.bool,
    moveHandle: PropTypes.bool,
    rotateAngle: PropTypes.number,
    parentRotateAngle: PropTypes.number,
    scaleX: PropTypes.number,
    scaleY: PropTypes.number,
    zIndex: PropTypes.number,
    zoomable: PropTypes.string,
    minWidth: PropTypes.number,
    minHeight: PropTypes.number,
    aspectRatio: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    onDoubleClick: PropTypes.func,
    onRotateStart: PropTypes.func,
    onRotate: PropTypes.func,
    onRotateEnd: PropTypes.func,
    onResizeStart: PropTypes.func,
    onResize: PropTypes.func,
    onResizeEnd: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrag: PropTypes.func,
    onDragEnd: PropTypes.func,
    onDelete: PropTypes.func,
    className: PropTypes.string,
  };

  static defaultProps = {
    parentRotateAngle: 0,
    rotateAngle: 0,
    rotatable: true,
    moveHandle: false,
    minWidth: 10,
    minHeight: 10,
    zIndex: 0,
  };

  handleResize = (angle, startAngle, deltaX, deltaY, clientX, clientY, flag) => {
    if (!this.props.publishMode) {
      const newSide = getLength(deltaX, deltaY);
      if (!this.props.onResize) return;
      let rotateAngle = startAngle + angle;
      if (rotateAngle >= 360) {
        rotateAngle -= 360;
      } else if (rotateAngle < 0) {
        rotateAngle += 360;
      }

      if (flag) {
        this.props.onResize({
          width: newSide,
          deltaX: deltaX,
          deltaY: deltaY,
          top: clientY,
          left: clientX,
          rotateAngle: rotateAngle,
        });
      } else {
        this.props.onResize({
          width: newSide,
          deltaX: deltaX,
          deltaY: deltaY,
          top: this.props.top,
          left: this.props.left,
          rotateAngle: rotateAngle,
        });
      }
    }
  };

  handleDrag = (deltaX, deltaY, elemLeft, elemTop) => {
    if (!this.props.publishMode) {
      this.props.onDrag && this.props.onDrag(deltaX, deltaY, elemLeft, elemTop);
    }
  };

  render() {
    const {
      className,
      top,
      left,
      width,
      height,
      rotateAngle,
      rotatable,
      onRotate,
      onResizeStart,
      onResizeEnd,
      onDragStart,
      onDragEnd,
      onClick,
      zIndex,
    } = this.props;
    const styles = tLToCenter({ top, left, width, height, rotateAngle, zIndex });
    return (
      <LineRect
        styles={styles}
        className={className}
        rotatable={Boolean(rotatable && onRotate)}
        businessPlan={this.props.businessPlan ? this.props.businessPlan : false}
        // parentRotateAngle={parentRotateAngle}
        onClick={onClick}
        onResizeStart={onResizeStart}
        onResize={this.handleResize}
        onResizeEnd={onResizeEnd}
        onDragStart={onDragStart}
        onDrag={this.handleDrag}
        onDragEnd={onDragEnd}
      >
        {this.props.children}
      </LineRect>
    );
  }
}
