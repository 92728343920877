import React, { useState } from "react";
import styled from "styled-components";
import { Theme } from "../../../../theme/styles/theme";
import { IconButton } from "@mui/material";
import { ArrowDown } from "../../../icons/svgIcons";
import { Vector } from "../../../dividers/Vector";
import "./collapsed_table.scss";

const SyledP = styled.p`
  ${(props) => (props.strong ? Theme.text.paragraph.p4_strong : Theme.text.paragraph.p4)};
  font-family: Inter;
`;

export const CollapsedTable = ({ ...props }) => {
  return (
    <div className={`sccc_table_menu ${props.disableHeight ? " scc_table_disable_height" : ""}`}>
      <div className="sccc_table_icon">
        <SyledP strong>{props.title}</SyledP>
        <IconButton onClick={props.onChange} sx={{ color: "#A9A9A9" }} size="medium">
          <ArrowDown width="14" height="14" />
        </IconButton>
      </div>
      <div className="sccc_table_divider">
        <Vector color="#A9A9A9" />
      </div>
    </div>
  );
};
