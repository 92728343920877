import CalculatedDriver from "../../CalculatedDriver/index";
import { DriverCategories, UnitTypes } from "../../CalculatedDriver/constants";
import MxIDHelper from "../../../MxIDHelper";
import datastructure from "../../../datastructure.json";
import FinanceGeneric from "../../FinanceGeneric";
import CostSales from "../../CostSales/index";
import MxIDUseID from "../../../../helpers/MxIUniqueD";
import { DialogTypes } from "../../constants";
import { PERMISSIONS } from "../../../Permissions/Permissions";

class CustomKPI extends FinanceGeneric {
  Name = "";
  ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
  CustomKPIDriver;
  KPIType;
  Totals;

  constructor(db_record = null) {
    super(db_record);
    // console.log('db', db_record);
    if (this.db_record) {
      this.clean();
    }
  }

  clean = (cleanDrivers = false) => {
    if (this.db_record && this.Totals) {
      this.Name = this.db_record.Name;
      this.KPIType = this.db_record.KPIType;
      this.ID_CompanyScenario = this.db_record.ID_CompanyScenario;

      if (cleanDrivers) {
        this.cleanDrivers();
      }
    }
  };

  static DriversDesc = Object.assign({
    Total: {
      driverName: "$Name",
      fieldName: "Totals",
      driverID: "total",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
  });

  static Types = {
    Total: "total",
  };

  static TableName = datastructure.Dashboards_CustomKPI.TableName;

  Save = (callBack, saveDrivers = true) => {
    if (!this.isNew && !this.IsCreated) {
      global.Modeliks.put(this.constructor.TableName, null, this, (res) => {
        if (saveDrivers) {
          this.SaveDrivers(callBack);
        } else {
          callBack();
        }
      });
    } else {
      global.Modeliks.put(this.constructor.TableName, null, this, (res) => {
        if (saveDrivers) {
          this.SaveDrivers(callBack);
        } else {
          callBack();
        }
      });
    }
  };

  changeDriversName = () => {
    Object.keys(this.constructor.DriversDesc).forEach((key) => {
      const driverDesc = this.constructor.DriversDesc[key];
      if (this[driverDesc.fieldName]) {
        this[driverDesc.fieldName].DriverName = driverDesc.driverName.replace("$Name", this.Name);
      } else {
        console.log("driver not found", this, driverDesc, this[driverDesc.fieldName]);
      }
    });
  };

  static getIndicatorID = () => {
    return `-indicator`;
  };
  static getCustomFormulaIndicatorID = () => {
    return `custom_indicator`;
  };

  getMonthDatesAll = () => {
    return [...global.Modeliks.DateHelper.months, ...global.Modeliks.DateHelper.months_after];
  };

  getYearDatesAll = () => {
    return global.Modeliks.DateHelper.years_all;
  };

  addIndicator = (driver, callBack) => {
    if (!driver) {
      return;
    }
    const driversBefore = this.Totals.Formula ? this.Totals.getChildDrivers() : [];
    this.Totals.setFormula_Sum(driversBefore.concat(driver));
    // this.Totals.Save();
  };

  removeIndicator = (row, callBack) => {
    if (!row) {
      return;
    }
    let isCustomKpi = false;
    let newFormula = null;
    const driver = row.views.data;
    if (driver.ID.includes(CustomKPI.getCustomFormulaIndicatorID()) && row.hasEdit) {
      isCustomKpi = true;
    }

    let driversBefore = this.Totals.getChildDrivers() ? this.Totals.getChildDrivers() : [];
    if (driversBefore.length > 0) {
      newFormula = driversBefore.filter((d) => d.ID !== driver.ID);
      this.Totals.setFormula_Sum(newFormula);
    }

    if (isCustomKpi) {
      driver.Delete();
    }

    this.Totals.Save();
  };

  deleteIndicator = (driver) => {
    let newFormula = null;
    let driversBefore = this.Totals.getChildDrivers() ? this.Totals.getChildDrivers() : [];
    if (driversBefore.length > 0) {
      newFormula = driversBefore.filter((d) => d.ID !== driver.ID);
      this.Totals.setFormula_Sum(newFormula);
    }
  };

  static createIndicator = (ID = MxIDHelper.newID()) => {
    return CalculatedDriver.createDriverFromTable(
      CustomKPI,
      CustomKPI.getIndicatorID(),
      UnitTypes.Price,
      DriverCategories.Sum,
    );
  };

  createCustomIndicator = (Name = ``, ID = MxIDUseID()) => {
    const CustomIndicator = CalculatedDriver.createDriver(
      CustomKPI.TableName,
      `${ID}`,
      CustomKPI.getCustomFormulaIndicatorID(),
      UnitTypes.Price,
      DriverCategories.Average,
      Name,
    );
    CustomIndicator.Ref_ID = this.ID;
    return CustomIndicator;
  };
  SaveNew = (callBack) => {
    let limit =
      global.Modeliks.CustomKPIStore.length >=
      PERMISSIONS.Financials.restrictions.MaxStreamsCount.TotalCustomKPI;
    if (limit) {
      global.Modeliks.showDialog(
        `
            You have reached the maximum number of [${PERMISSIONS.Financials.restrictions.MaxStreamsCount.TotalCustomKPI}] streams in Custom KPI. 
            Please reorganize your streams in Custom KPI. \n Click outside of the box to continue with your work.`,
        DialogTypes.WarningSimpleText,
        () => {
          callBack(null);
        },
      );
    } else {
      global.Modeliks.post(this.constructor.TableName, this, (res) => {
        this.ID = res.id;
        this.Totals = CalculatedDriver.createDriverFromTable(
          this,
          CostSales.DriversDesc.Total.driverID,
          UnitTypes.Price,
          DriverCategories.Sum,
        );
        this.IsCreated = true;
        callBack(this);
      });
    }
  };

  static getEmptyKPI = (callBack) => {
    const cost = CustomKPI.createNewEmpty();
    cost.SaveNew((record) => {
      return callBack(record);
    });
  };

  static createNewEmpty = () => {
    const kpi = new CustomKPI();
    kpi.ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
    kpi.Name = "";
    kpi.KPIType = CustomKPI.Types.Total;
    return kpi;
  };

  static createCustomKPI = (ID = MxIDHelper.newID()) => {
    const newCustomKPI = new CustomKPI();
    newCustomKPI.ID = ID;
    newCustomKPI.ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
    newCustomKPI.Totals = CalculatedDriver.createDriverFromTable(
      newCustomKPI,
      CustomKPI.DriversDesc.Total.driverID,
      UnitTypes.Price,
      DriverCategories.Sum,
    );
    newCustomKPI.KPIType = CustomKPI.Types.Total;
    return newCustomKPI;
  };
}

export default CustomKPI;
