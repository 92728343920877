import Reports from "../index";
import Assets from "../../Assets";
import { ReportsTypes } from "../constants";

class BalanceSheet extends Reports {
  Name = "";
  ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
  ReportType = ReportsTypes.BalanceSheet;
  ReportsType = ReportsTypes.BalanceSheet;
  Assets;
  TotalLiabilitiesAndEquity;
  constructor(db_record) {
    super(db_record);
    if (this.db_record) {
      this.Name = this.db_record.Name;
      this.ReportType = this.db_record.ReportType;
      // this.setReport();
    }
  }

  getAssetsTotalsDriver = () => {
    return Assets.getAssetsTotals();
  };

  getTotalLiabilitiesAndEquity = () => {
    return Reports.getTotalLiabilitiesAndEquity();
  };

  getReportTotals = () => {
    const driver = BalanceSheet.getReport();

    return [
      driver.Assets,
      // driver.Liabilities,
      // driver.Equity,
      driver.TotalLiabilitiesAndEquity,
    ];
  };

  static getReport = () => {
    const driver = global.Modeliks.ReportsStore.find(
      (d) => d.ReportsType === ReportsTypes.BalanceSheet,
    );
    if (driver) {
      driver.Assets = driver.getAssetsTotalsDriver();
      driver.TotalLiabilitiesAndEquity = driver.getTotalLiabilitiesAndEquity();
      // driver.Liabilities = driver.getLiabilities();
      // driver.Equity = driver.getEquity();
      return driver;
    } else {
      const newReport = new BalanceSheet();
      newReport.ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
      newReport.ID = "balance_sheet_report";
      newReport.Name = "Balance Sheet";
      newReport.ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
      newReport.ReportType = ReportsTypes.BalanceSheet;
      newReport.ReportsType = ReportsTypes.BalanceSheet;
      newReport.Assets = newReport.getAssetsTotalsDriver();
      // newReport.Liabilities = newReport.getLiabilities();
      // newReport.Equity = newReport.getEquity();
      newReport.TotalLiabilitiesAndEquity = newReport.getTotalLiabilitiesAndEquity();
      global.Modeliks.ReportsStore.push(newReport);
      return newReport;
    }
  };
}

export default BalanceSheet;
