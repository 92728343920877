import React from "react";
import CircleLoader from "../../../../components/Loaders/CircleLoader/CircleLoader";
import {
  Container,
  Header,
  LGrayContainer,
  CenterDiv,
  SubHeader,
  SGrayContainer,
  DTextDiv,
  PSInput,
  SubCenterDiv,
  SubSubHeader,
  MBox,
  MarginD,
  CB,
  DTextDivL,
  PIconH,
  PSelect,
  DTextDivLP,
} from "../components/ValuationContainer";
import Valuation from "../../../../data/Finance/Valuation";
import CostOfCapitalWACC from "../../../../data/Finance/Valuation/CostOfCapitalWACC";

const Loader = (
  <div className={"main_circle_loader_container"}>
    <CircleLoader />
  </div>
);

const DiscountCashFlowTypes = {
  TerminalCalc: "terminal-calc",
  ExitMultiple: "exit-multiple",
};

const ExitMultipleTypes = {
  EBITDAM: "ebitda_multiple",
  NetProfitM: "net_profit_multiple",
  RevenueM: "revenue_multiple",
};

const StateOfDevelopment = {
  PreSeed: { value: "pre-seed", description: "Pre-seed" },
  Maturity: { value: "maturity", description: "Maturity" },
  Growth: { value: "growth", description: "Growth" },
  Seed: { value: "seed", description: "Seed" },
};

export default class SettingsMain extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      key: new Date().getTime(),
      loading: true,
    };

    this.ValuationSettings = Valuation.getValuationSettings();
    this.CostOfCapital = CostOfCapitalWACC.getCostOfCapitalWACC();
    this.LastYear =
      global.Modeliks.DateHelper.years_all[global.Modeliks.DateHelper.years_all.length - 1].Year;
    this.TerminalGrowthYear = `${this.LastYear + 1} to ${this.LastYear + 6}`;
    this.TerminalGrowthLastYear = `after ${this.LastYear + 6}`;
  }

  componentDidMount() {
    if (this.ValuationSettings) {
      this.setState({ loading: false });
    }
  }

  onChangeCheckBox = (e) => {
    this.ValuationSettings[e.target.name] = !this.ValuationSettings[e.target.name];
    this.ValuationSettings.Save(() => {}, false, false);
    this.forceUpdate();
  };

  onChangeCheckBoxWithType = (e, type) => {
    this.ValuationSettings[e.target.name] = type;
    this.ValuationSettings.Save(() => {}, false, false);
    this.forceUpdate();
  };

  onChangeInput = (value, key) => {
    this.ValuationSettings[key].Value = value;
    this.ValuationSettings[key].Save();
    this.forceUpdate();
  };

  getExitMultipleVal = () => {
    switch (this.ValuationSettings["ExitMultipleType"]) {
      case ExitMultipleTypes.EBITDAM:
        return { name: "EBITDA", value: "EBITDA" };
      case ExitMultipleTypes.NetProfitM:
        return { name: "Net Profit", value: "NetProfit" };
      case ExitMultipleTypes.RevenueM:
        return { name: "Revenue", value: "Revenue" };
      default:
        return { name: "Revenue", value: "Revenue" };
    }
  };

  renderExitMultiple = () => {
    const getSelected = this.getExitMultipleVal();
    const checkedValue = getSelected.value;
    const checkedName = getSelected.name;

    return (
      <SubCenterDiv>
        <MarginD>
          <SubSubHeader>
            Which Exit Multiple you want to use to calculate your terminal value?
          </SubSubHeader>
          <MBox>
            <CB
              value={this.ValuationSettings["ExitMultipleType"] === ExitMultipleTypes.EBITDAM}
              onChange={(e) => this.onChangeCheckBoxWithType(e, ExitMultipleTypes.EBITDAM)}
              name="ExitMultipleType"
              label="EBITDA Multiple"
            />
            <CB
              value={this.ValuationSettings["ExitMultipleType"] === ExitMultipleTypes.NetProfitM}
              onChange={(e) => this.onChangeCheckBoxWithType(e, ExitMultipleTypes.NetProfitM)}
              name="ExitMultipleType"
              label="Net Profit Multiple"
            />
            <CB
              value={this.ValuationSettings["ExitMultipleType"] === ExitMultipleTypes.RevenueM}
              onChange={(e) => this.onChangeCheckBoxWithType(e, ExitMultipleTypes.RevenueM)}
              name="ExitMultipleType"
              label="Revenue Multiple"
            />
          </MBox>
        </MarginD>

        <SGrayContainer>
          <div className="spsvc_gray_small_container_exit_aligment spsvc_gray_exit_aligment_reverse">
            <p>{`Expected ${checkedName} Exit Multiple`}</p>
            <PSInput
              Input={{
                onChange: (val) => this.onChangeInput(val, `${checkedValue}Med`),
                name: `${checkedValue}Med`,
                key: `${checkedValue}Med`,
                value: this.ValuationSettings[`${checkedValue}Med`].Value,
              }}
            />
          </div>
        </SGrayContainer>
        <SGrayContainer>
          <div className="spsvc_gray_small_container_exit_aligment">
            <p>{checkedName} Exit Multiple Range</p>
            <PSInput
              Input={{
                onChange: (val) => this.onChangeInput(val, `${checkedValue}Min`),
                name: `${checkedValue}Min`,
                key: `${checkedValue}Min`,
                value: this.ValuationSettings[`${checkedValue}Min`].Value,
              }}
              label="min"
            />
            <PSInput
              Input={{
                onChange: (val) => this.onChangeInput(val, `${checkedValue}Max`),
                name: `${checkedValue}Max`,
                key: `${checkedValue}Max`,
                value: this.ValuationSettings[`${checkedValue}Max`].Value,
              }}
              label="max"
            />
          </div>
        </SGrayContainer>
      </SubCenterDiv>
    );
  };

  render() {
    if (this.state.loading && !this.ValuationSettings) {
      return Loader;
    }

    return (
      <Container className={" fill_height"} key={this.state.key}>
        <Header>Select the stage of development and the industry of your company.</Header>

        <>
          <DTextDivL>
            <PIconH title="Stage of development" description="Text Here" />
            <PSelect
              value={this.CostOfCapital.StateOfDevelopment}
              name="StateOfDevelopment"
              onChange={this.changeStateOfDevelopment}
              options={Object.values(StateOfDevelopment)}
            />
          </DTextDivL>
          <DTextDivL>
            <p>Select Industry</p>
            <PSelect
              value={555}
              name="SelectedIndustry"
              onChange={() => console.log("d")}
              options={[
                { description: "Select Industry", value: 555 },
                { description: "ADD", value: 1 },
              ]}
            />
          </DTextDivL>
        </>

        <DTextDivLP>
          <Header>
            How would you like to calculate the terminal value in your Discount Cash Flow (DCF)
            valuation?
          </Header>
        </DTextDivLP>

        {/*<CB value={this.ValuationSettings['DiscountedCashFlow']} onChange={this.onChangeCheckBox} name='DiscountedCashFlow' label='Discounted Cash Flow (DCF) Valuation'/>*/}
        <LGrayContainer label="Discounted cash flow (DCF) is a valuation method used to estimate the value of an investment based on its expected future cash flows." />
        <CenterDiv>
          <CB
            value={
              this.ValuationSettings["TerminalValueType"] === DiscountCashFlowTypes.TerminalCalc
            }
            onChange={(e) => this.onChangeCheckBoxWithType(e, DiscountCashFlowTypes.TerminalCalc)}
            name="TerminalValueType"
            label="Terminal Value Calculation"
          />

          {this.ValuationSettings.TerminalValueType === DiscountCashFlowTypes.TerminalCalc && (
            <SubCenterDiv>
              <DTextDiv>
                <p>Expected Terminal Growth Rate</p>
                <p>Terminal Growth Rate Range</p>
              </DTextDiv>
              <SGrayContainer>
                <div className="spsvc_gray_small_container_aligment">
                  <PSInput
                    Input={{
                      onChange: (val) => this.onChangeInput(val, "FirstPeriodMed"),
                      name: "FirstPeriodMed",
                      key: "FirstPeriodMed",
                      value: this.ValuationSettings["FirstPeriodMed"].Value,
                    }}
                    label={this.TerminalGrowthYear}
                  />
                  {/*<PSInput Input={{onChange: (val) => this.onChangeInput(val,'FirstPeriodMin'),name:'FirstPeriodMin',key:'FirstPeriodMin',value: this.ValuationSettings['FirstPeriodMin'].Value}} label='min'/>*/}
                  {/*<PSInput Input={{onChange: (val) => this.onChangeInput(val,'FirstPeriodMax'),name:'FirstPeriodMax',key:'FirstPeriodMax',value: this.ValuationSettings['FirstPeriodMax'].Value}} label='max'/>*/}
                </div>
              </SGrayContainer>
              <SGrayContainer>
                <div className="spsvc_gray_small_container_aligment">
                  <PSInput
                    Input={{
                      onChange: (val) => this.onChangeInput(val, "SecondPeriodMed"),
                      name: "SecondPeriodMed",
                      key: "SecondPeriodMed",
                      value: this.ValuationSettings["SecondPeriodMed"].Value,
                    }}
                    label={this.TerminalGrowthLastYear}
                  />
                  <PSInput
                    Input={{
                      onChange: (val) => this.onChangeInput(val, "SecondPeriodMin"),
                      name: "SecondPeriodMin",
                      key: "SecondPeriodMin",
                      value: this.ValuationSettings["SecondPeriodMin"].Value,
                    }}
                    label="min"
                  />
                  <PSInput
                    Input={{
                      onChange: (val) => this.onChangeInput(val, "SecondPeriodMax"),
                      name: "SecondPeriodMax",
                      key: "SecondPeriodMax",
                      value: this.ValuationSettings["SecondPeriodMax"].Value,
                    }}
                    label="max"
                  />
                </div>
              </SGrayContainer>
            </SubCenterDiv>
          )}
          <CB
            label="Exit Multiple"
            value={
              this.ValuationSettings["TerminalValueType"] === DiscountCashFlowTypes.ExitMultiple
            }
            onChange={(e) => this.onChangeCheckBoxWithType(e, DiscountCashFlowTypes.ExitMultiple)}
            name="TerminalValueType"
          />

          {this.ValuationSettings.TerminalValueType === DiscountCashFlowTypes.ExitMultiple &&
            this.renderExitMultiple()}
        </CenterDiv>
        {/*<CB value={this.ValuationSettings['TradingMultipleValuation']} onChange={this.onChangeCheckBox} name='TradingMultipleValuation'  label='Trading Multiples Valuation'/>*/}
        {/*<LGrayContainer label='Use trading EBITDA, Net Profit or Revenue multiples of peer companies in order to estimate the value of your company.' />*/}
        {/*<CB value={this.ValuationSettings['TrasanctionMultiplesValuation']} onChange={this.onChangeCheckBox} name='TrasanctionMultiplesValuation' label='Transaction Multiples Valuation'/>*/}
        {/*<LGrayContainer label='Use EBITDA, Net Profit or Revenue multiples of past M&A transactions in order to estimate the value of your company.' />*/}
        {/*<CB value={this.ValuationSettings['BookValue']} onChange={this.onChangeCheckBox} name='BookValue' label='Book Value'/>*/}
        {/*<LGrayContainer label='Use EBITDA, Net Profit or Revenue multiples of past M&A transactions in order to estimate the value of your company.' />*/}
      </Container>
    );
  }
}
