import { React, useState } from "react";
import { MenuItem, FormControl, InputLabel, FormHelperText } from "@mui/material";
import Select from "@mui/material/Select";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Theme } from "../../theme/styles/theme";
import "./actions.scss";

const ErrorMessage = styled.span`
  ${Theme.text.paragraph["p5"]};
  color: ${Theme.colors.accent.red[150]};
  margin-top: 0;
  margin-left: 8px;
`;

const MaterialSelect = (props) => {
  const options = props.options ? props.options : [];
  const value = props.value;
  let BorderColor = "#E0E0E0";

  if (props.error) {
    BorderColor = "red";
  }
  return (
    <FormControl
      style={{
        zIndex: props.zIndex ? props.zIndex : 500,
        ...props.style,
        justifyContent: "center",
      }}
    >
      <InputLabel
        sx={{ top: "-7px" }}
        focused={false}
        shrink={false}
        className="scaa_select_m_input_label"
      >
        {!props.value && props.value !== 0 && props.label}
      </InputLabel>
      <Select
        multiple={props.multipleSelect}
        className="scaa_select_input"
        style={{
          borderColor: BorderColor,
          minWidth: props.minWidth,
          borderLeft: `1px solid ${props.error ? BorderColor : "#E0E0E0"}`,
          borderLeftWidth: "0",
        }}
        value={value && value.toString()}
        name={props.name}
        options={props.options}
        disabled={props.disabled}
        onChange={props.onChange}
        defaultValue={props.defaultValue}
        label=""
        // autoWidth
        sx={{
          minWidth: props.width,
          height: 40,
          fontSize: "12px !important",
          boxSizing: `border-box`,
          // border: '1px solid #E0E0E0',
          borderLeft: "none",
          borderRadius: `8px`,
          borderLeftWidth: "0 !important",
          borderLeftStyle: "none !important",
          color: "black",
          ".fieldset.MuiOutlinedInput-notchedOutline.MuiOutlinedInput-notchedOutline": {
            borderColor: "none",
            border: "none",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          ".MuiSvgIcon-root": {
            height: "1em",
          },
          ".MuiSelect-root": {
            borderLeftWidth: "0 !important",
            borderLeftStyle: "none !important",
            fontSize: "12px !important",
          },
          ".MuiInputBase-root": {
            borderLeftWidth: "0 !important",
            borderLeftStyle: "none !important",
            fontSize: "12px !important",
          },
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              zIndex: 10,
              marginTop: -2.5,
              marginBottom: "0",
              paddingBottom: 0,
              boxShadow: "none",
              borderRadius: "0",
              borderLeft: `1px solid #F0F1F7`,
              borderRight: `1px solid #F0F1F7`,
              borderBottom: `1px solid #F0F1F7`,
              borderBottomLeftRadius: "8px",
              backgroundColor: "white",
              borderBottomRightRadius: "8px",
              ".MuiList-root": {
                paddingBottom: 0,
                borderColor: "#F0F1F7",
              },
              ".MuiInputBase-root": {
                borderLeftWidth: 0,
                borderLeftStyle: "none !important",
                fontSize: "12px !important",
              },
              ".MuiMenuItem-root": {
                fontSize: "12px !important",
              },
            },
          },
        }}
      >
        {options.length > 0 &&
          options.map((row, index) => {
            let value = "";
            let description = "";
            if (props.valuePlaceholder) {
              value = row[props.valuePlaceholder];
            } else {
              value = row.value;
            }
            if (props.valueDescription) {
              description = row[props.valueDescription];
            } else {
              description = row.description;
            }

            return (
              <MenuItem key={"select" + row.value + index} className="scaa_menu_item" value={value}>
                {description}
              </MenuItem>
            );
          })}
        {props.children}
      </Select>
      {props.error && (
        <ErrorMessage>{props.errorMessage ? props.errorMessage : "Required"}</ErrorMessage>
      )}
    </FormControl>
  );
};

MaterialSelect.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  options: PropTypes.any,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.any,
  error: PropTypes.any,
  width: PropTypes.any,
  valuePlaceholder: PropTypes.any,
  valueDescription: PropTypes.any,
  errorMessage: PropTypes.any,
};

MaterialSelect.defaultProps = {
  value: "",
};

export default MaterialSelect;
