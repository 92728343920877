import React, { useState } from "react";
import styled, { keyframes, css } from "styled-components";
import PropTypes from "prop-types";
import { Theme } from "../../theme/styles/theme";
let styles = {
  white: Theme.colors.greyscale.white,
  lightgrey500: Theme.colors.primary.lightgrey[500],
  lightblue150: Theme.colors.primary.lightblue[150],
  lightblue300: Theme.colors.primary.lightblue[300],
  lightgrey300: Theme.colors.primary.lightgrey[300],
  darkgrey50: Theme.colors.primary.darkgrey[50],
  darkgrey150: Theme.colors.primary.darkgrey[150],
  p4: Theme.text.paragraph.p4,
  paragraph3: Theme.text.paragraph.p3,
};

const Input = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;
`;
const popIn = keyframes`
        from {
          opacity: 0;
          transform: translate(-50%, -50%) scale(1.5) ;
        }
        to {
          opacity: 1;
          transform: translate(-50%, -50%) scale(1) ;
        }
     `;
const Label = styled.label`
  position: relative;
  display: inline-block;
  cursor: pointer;
  ${styles["p4"]};
  color: ${styles["darkgrey150"]};
  font-family: Inter;
`;
const Indicator = styled.div`
  border: 1px solid ${(props) => (props.check ? styles["lightblue300"] : styles["lightgrey500"])};
  border-radius: 1em;
  box-sizing: border-box;
  box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.2);
  width: 14px;
  height: 14px;
  position: absolute;
  left: 0;
  top: 30%;
  margin-left: -20px;
  margin-top: -4px;
  background: ${styles["white"]};
  ${Label}:hover & {
    border: 1px solid ${(props) => (props.check ? styles["lightblue150"] : styles["lightgrey500"])};
    background: ${styles["lightgrey300"]};
    box-sizing: border-box;
    box-shadow: 0px 4.375px 17.5px rgba(0, 0, 0, 0.06);
  }
  &::after {
    content: "";
    position: absolute;
    display: none;
  }
  ${Input}:checked + &::after {
    display: block;
    border: 3px solid ${styles["white"]};
    border-radius: 1em;
    background: ${styles["lightblue300"]};
    width: 0.5em;
    height: 0.5em;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation-name: ${popIn};
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
  }
  ${Input}:hover + &::after {
    background: ${styles["lightblue150"]};
  }
  ${Input}:disabled + & {
    pointer-events: none;
    opacity: 0.6;
    //background: red;
  }
`;

function RadioButton({
  value,
  type,
  name,
  placeholder,
  size = "small",
  checked = false,
  disabled,
  onChange,
  defaultChecked = false,
}) {
  const [check, setChecked] = useState(checked);
  const handleChange = () => {
    setChecked(!check);
  };
  return (
    <Label style={{ marginLeft: "10px" }} onChange={onChange}>
      {placeholder}
      <Input
        type="radio"
        role="radio"
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        defaultChecked={defaultChecked}
        onChange={() => {}}
      />
      <Indicator check={checked} />
    </Label>
  );
}

RadioButton.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  checked: PropTypes.bool,
  // error: PropTypes.bool,
  // errorMessage: PropTypes.string,
};

export default RadioButton;
