import React from "react";
import { AddDialogTitle } from "../../../../components/dialogs/financials/AddDialog/AddDialogTitle";
import AddDialog from "../../../../components/dialogs/financials/AddDialog/AddDialog";
import { AddDialogContent } from "../../../../components/dialogs/financials/AddDialog/AddDialogContent";
import PropTypes from "prop-types";
import Input from "../../../../components/actions/Input";
import Button from "../../../../components/actions/Button";
import { DialogActions } from "@mui/material";
import "./CustomDashboardDetails.scss";

function CustomDashboardDetails(props) {
  const [Name, setName] = React.useState("");

  return (
    <AddDialog onClose={() => props.handleClose(false)} open>
      <AddDialogTitle
        title="Create new Custom Dashboard"
        onClose={() => props.handleClose(false)}
      />
      <AddDialogContent>
        <div className="spsdcc_custom_dashboards_add_dialog_content">
          <div className="spsdcc_custom_dashboards_heading">{props.heading}</div>
          <div>
            <Input
              placeholder="Please enter..."
              value={Name}
              // error={!!scenarioNameError}
              // errorMessage={scenarioNameError}
              onChange={(event) => setName(event.target.value)}
            />
          </div>
        </div>

        <div className="spsdcc_custom_text_area">
          Create a custom dashboard. You can add to your custom dashboard charts and ratios by
          clicking on the star icon next to the charts and ratios in the sections <b>Charts</b> and{" "}
          <b>Ratios</b>.
        </div>
      </AddDialogContent>
      <DialogActions>
        <Button
          color={"primary"}
          backgroundColor={"primary"}
          width="91px"
          padding="0 0 0 0"
          label="Create"
          onClick={() => props.addNewCustomDashboard(Name)}
        />
      </DialogActions>
    </AddDialog>
  );
}

CustomDashboardDetails.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  addNewCustomDashboard: PropTypes.func,
  heading: PropTypes.string,
};

export default CustomDashboardDetails;
