import React, { Fragment, useState } from "react";
import "./InputBox.scss";
import { DownwardArrow, UpwardArrow } from "../IconButtons/SubMenuIcons";

function InputBox(props) {
  return (
    <div className={"CustomInputBox"}>
      <input
        contentEditable={true}
        className={"Input"}
        value={props.value}
        type="number"
        onChange={props.onChange}
      />
      {!props.noArrows && (
        <div id={"Arrows"}>
          <div
            id={"UpwardArrow"}
            onClick={props.upLimit && props.value === props.upLimit ? "" : props.onUpClick}
          >
            <UpwardArrow />
          </div>
          <div
            id={"DownwardArrow"}
            onClick={props.downLimit && props.value === props.downLimit ? "" : props.onDownClick}
          >
            <DownwardArrow />
          </div>
        </div>
      )}
    </div>
  );
}

export default InputBox;
