import styled from "styled-components";

const StyledBodyWrapper = styled.div`
  color: white;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const BodyWrapper = (props) => {
  return <StyledBodyWrapper className="auth_page">{props.children}</StyledBodyWrapper>;
};
