import Revenue from "../../Revenue/index";
import CalculatedDriver from "../../CalculatedDriver";
import { DriverCategories, UnitTypes } from "../../CalculatedDriver/constants";
import { ReportsTypes } from "../../Reports/constants";
import Assets from "../../Assets";
import { CostOfCapitalTypes } from "../CostOfCapitalWACC/constants";
import datastructure from "../../../datastructure";
import CalculatedDriver_Values from "../../CalculatedDriver/CalculatedDriver_Values";
import OpeningBalanceCalc from "../../Reports/OpeningBalance";
import FinanceGeneric from "../../FinanceGeneric";

class DiscountedCash extends FinanceGeneric {
  Type = "Disconted Cash Flow";

  constructor(db_record) {
    super(db_record);
    if (this.db_record) {
    }
  }
  static DriverValuesDesc = Object.assign({
    EnterpriseValue: {
      fieldName: "EnterpriseValue",
      driverID: "discounted_cash_flow_enterprice_value",
    },
    Cash: {
      fieldName: "Cash",
      driverID: "discounted_cash_flow_cash",
    },
    Debt: {
      fieldName: "Debt",
      driverID: "discounted_cash_flow_debt",
    },
    EquityValue: {
      fieldName: "EquityValue",
      driverID: "discounted_cash_flow_equity_value",
    },
    TerminalValuePercentOfEnterpriseValue: {
      fieldName: "TerminalValuePercentOfEnterpriseValue",
      driverID: "discounted_cash_flow_terminal_value_percent_of_enterprise_value",
    },
    FreeCashFlowTerminalValueMin: {
      fieldName: "FreeCashFlowTerminalValueMin",
      driverID: "discounted_cash_flow_free_cash_flow_terminal_min_val",
    },
    FreeCashFlowTerminalValueMax: {
      fieldName: "FreeCashFlowTerminalValueMax",
      driverID: "discounted_cash_flow_free_cash_flow_terminal_max_val",
    },
    PresentValueOfFreeCashFlowMin: {
      fieldName: "PresentValueOfFreeCashFlowMin",
      driverID: "discounted_cash_flow_present_value_offree_cash_flow_min",
    },
    PresentValueOfFreeCashFlowMax: {
      fieldName: "PresentValueOfFreeCashFlowMax",
      driverID: "discounted_cash_flow_present_value_offree_cash_flow_max",
    },
    EnterpriseValueMin: {
      fieldName: "EnterpriseValueMin",
      driverID: "discounted_cash_flow_enterprise_value_min",
    },
    EnterpriseValueMax: {
      fieldName: "EnterpriseValueMax",
      driverID: "discounted_cash_flow_enterprise_value_max",
    },
    EquityValueMin: {
      fieldName: "EquityValueMin",
      driverID: "discounted_cash_flow_equity_value_min",
    },
    EquityValueMax: {
      fieldName: "EquityValueMax",
      driverID: "discounted_cash_flow_equity_value_max",
    },
  });

  static DriversDesc = {
    Total: {
      driverName: "$Name",
      fieldName: "Totals",
      driverID: "total",
    },
    OperatingProfit: {
      driverName: "Operating Profit (EBIT)",
      fieldName: "OperatingProfit",
      driverID: "dcf_operating_profit",
    },
    IncomeTaxes: {
      driverName: "(-) Income Taxes",
      fieldName: "IncomeTaxes",
      driverID: "dcf_income_taxes",
    },
    NOPAT: {
      driverName: "NOPAT",
      fieldName: "NOPAT",
      driverID: "dcf_nopat",
    },
    CAPEX: {
      driverName: "CAPEX",
      fieldName: "CAPEX",
      driverID: "dcf_capex",
    },
    Depreciation: {
      driverName: "(+) Depreciation",
      fieldName: "Depreciation",
      driverID: "dcf_depreciation",
    },
    ChangeInWorkingCapital: {
      driverName: "Change In Working Capital",
      fieldName: "ChangeInWorkingCapital",
      driverID: "dcf_change_in_working_capital",
    },
    FreeCashFlow: {
      driverName: "Free Cash Flow",
      fieldName: "FreeCashFlow",
      driverID: "dcf_free_cash_flow",
    },
    WACC: {
      driverName: "WACC",
      fieldName: "WACC",
      driverID: "dcf_wacc",
      unit: UnitTypes.Percentage,
    },
    DiscountFactor: {
      driverName: "Discount Factor",
      fieldName: "DiscountFactor",
      driverID: "dcf_discount_factor",
      unit: UnitTypes.Units,
    },
    PresentValueOfFreeCashFlows: {
      driverName: "Present Value Of Free Cash Flows",
      fieldName: "PresentValueOfFreeCashFlows",
      driverID: "dcf_presentValueOfFreeCashFlows",
    },
  };

  static TableName = datastructure.Valuation_Settings.TableName;

  createDriverValues = (recreate = false) => {
    Object.values(this.constructor.DriverValuesDesc).forEach((driverVal) => {
      if (this.hasOwnProperty(driverVal.fieldName) == false) {
        this[driverVal.fieldName] = new CalculatedDriver_Values(null, driverVal.driverID);
      }
    });
  };
  createDrivers = (recreate = false) => {
    Object.values(this.constructor.DriversDesc).forEach((driver) => {
      if (this.hasOwnProperty(driver.fieldName) == false || recreate) {
        this[driver.fieldName] = CalculatedDriver.createDiscountCashFlowDriver(
          this.Totals,
          this.ID,
          driver.driverID,
          driver.unit,
          driver.fieldName,
          driver.driverName,
        );
      }
    });
  };

  createFormulas = (creatingFirst = false) => {
    const dates = this.getDatesAll();
    const ProfitLoss = global.Modeliks.ReportsStore.find(
      (d) => d.ReportType === ReportsTypes.ProfitLoss,
    );
    const CashFlow = global.Modeliks.ReportsStore.find(
      (d) => d.ReportType === ReportsTypes.CashFlow,
    );
    const OperatingProfit = ProfitLoss.OperatingProfit;
    const IncomeTax = Assets.getDeferredIncomeTax();
    const Depreciation = Assets.getDepreciation();
    const ChangeInCurrentAssetsAndLiabilities = CashFlow.ChangeInCurrentAssetsAndLiabilities;
    const CAPEX = CashFlow.NetCashFromInvestingActivities;
    const CostOfCapitalWACC = global.Modeliks.CostOfCapitalStore[0];
    const WACCValue =
      CostOfCapitalWACC.CostOfCapitalType === CostOfCapitalTypes.DiscountRate
        ? CostOfCapitalWACC.DiscountRate
        : CostOfCapitalWACC.CostOfCapitalType === CostOfCapitalTypes.IndustryBenchmark
          ? CostOfCapitalWACC.WACCSelectedStage
          : CostOfCapitalWACC.WACC;
    const terminal_growthRate_1 = global.Modeliks.ValuationSettingsStore[0].FirstPeriodMed;
    const terminal_growthRate_2 = global.Modeliks.ValuationSettingsStore[0].SecondPeriodMed;

    this.Totals.Formula = `${this.OperatingProfit} + ${this.IncomeTaxes} + ${this.NOPAT} + ${this.Depreciation} + ${this.ChangeInWorkingCapital} + ${this.CAPEX} + ${this.FreeCashFlow} + ${this.WACC} + ${this.DiscountFactor} + ${this.PresentValueOfFreeCashFlows}`;

    dates.forEach((year) => {
      const curYear = this.periodsData[year.dateID];

      if (year.Order === dates[dates.length - 1].Order) {
        const setActualValuesDrivers = [
          DiscountedCash.DriversDesc.OperatingProfit,
          DiscountedCash.DriversDesc.IncomeTaxes,
          DiscountedCash.DriversDesc.NOPAT,
          DiscountedCash.DriversDesc.Depreciation,
          DiscountedCash.DriversDesc.ChangeInWorkingCapital,
          DiscountedCash.DriversDesc.CAPEX,
        ];
        setActualValuesDrivers.forEach((d) => (curYear[d.fieldName].Unit = UnitTypes.Hide));
        const years_all = global.Modeliks.DateHelper.years_all;
        const last_year = years_all[years_all.length - 1];
        const lastYear = this.periodsData[last_year.dateID];
        const lastYearCashFlow = this.periodsData[last_year.dateID].FreeCashFlow;
        const lastYearWACC = this.periodsData[last_year.dateID].WACC;

        // const LastYear = dates.find

        const allYearsFormulas = years_all
          .map(
            (d) =>
              `(${lastYearCashFlow} *  MxMath.Pow(1 + ${terminal_growthRate_1} , ${d.Order + 1}))`,
          )
          .join("+");
        const freeCashFlowSecondPart = `${lastYear.FreeCashFlow} * (MxMath.Pow(1 + ${terminal_growthRate_1}, ${last_year.Order + 1}) * (1+${terminal_growthRate_2})) / (${lastYearWACC} - ${terminal_growthRate_2}) * (1 / MxMath.Pow(1 + ${lastYearWACC}, ${year.Order + 1})) `;
        const PresentValueFormula = years_all
          .map(
            (d) =>
              `(${lastYearCashFlow} *  MxMath.Pow(1 + ${terminal_growthRate_1} , ${d.Order + 1})) * ${this.DiscountFactor.getItemByDateSufix(d.sufix)}`,
          )
          .join("+");
        curYear.FreeCashFlow.Formula = `${allYearsFormulas} + ${freeCashFlowSecondPart} `;

        curYear.WACC.Formula = `${WACCValue}`;
        curYear.DiscountFactor.Formula = `1 / MxMath.Pow(1 + ${curYear.WACC}, ${year.Order + 1})`;
        curYear.PresentValueOfFreeCashFlows.Formula = `${PresentValueFormula} + (${freeCashFlowSecondPart} * ${curYear.DiscountFactor})`;

        if (creatingFirst) {
          const terminal_growthRate_2_min =
            global.Modeliks.ValuationSettingsStore[0].SecondPeriodMin;
          const terminal_growthRate_2_max =
            global.Modeliks.ValuationSettingsStore[0].SecondPeriodMax;
          const freeCashFlowSecondPartMin = `${lastYear.FreeCashFlow} * (MxMath.Pow(1 + ${terminal_growthRate_1}, ${last_year.Order + 1}) * (1+${terminal_growthRate_2_min})) / (${lastYearWACC} - ${terminal_growthRate_2_min}) * (1 / MxMath.Pow(1 + ${lastYearWACC}, ${year.Order + 1})) `;
          const freeCashFlowSecondPartMax = `${lastYear.FreeCashFlow} * (MxMath.Pow(1 + ${terminal_growthRate_1}, ${last_year.Order + 1}) * (1+${terminal_growthRate_2_max})) / (${lastYearWACC} - ${terminal_growthRate_2_max}) * (1 / MxMath.Pow(1 + ${lastYearWACC}, ${year.Order + 1})) `;
          this.FreeCashFlowTerminalValueMin.Formula = `${allYearsFormulas} + (${freeCashFlowSecondPartMin} * ${curYear.DiscountFactor}) `;
          this.FreeCashFlowTerminalValueMax.Formula = `${allYearsFormulas}  + (${freeCashFlowSecondPartMax} * ${curYear.DiscountFactor})`;
        }
      } else {
        curYear.OperatingProfit.Formula = `${OperatingProfit.getItemByDateSufix(year.sufix)}`;
        curYear.IncomeTaxes.Formula = `${IncomeTax.getItemByDateSufix(year.sufix)}`;
        curYear.NOPAT.Formula = `${curYear.OperatingProfit} - ${curYear.IncomeTaxes}`;
        curYear.Depreciation.Formula = `${Depreciation.getItemByDateSufix(year.sufix)}`;
        curYear.ChangeInWorkingCapital.Formula = `${ChangeInCurrentAssetsAndLiabilities.getItemByDateSufix(year.sufix)}`;
        curYear.CAPEX.Formula = `${CAPEX.getItemByDateSufix(year.sufix)}`;
        curYear.FreeCashFlow.Formula = `${curYear.NOPAT} + ${curYear.Depreciation} + ${curYear.ChangeInWorkingCapital} - ${curYear.CAPEX}`;
        curYear.WACC.Formula = `${WACCValue}`;
        curYear.PresentValueOfFreeCashFlows.Formula = `${curYear.FreeCashFlow} * ${curYear.DiscountFactor}`;

        if (year.Order < 0) {
          const setActualValuesDrivers = [
            DiscountedCash.DriversDesc.OperatingProfit,
            DiscountedCash.DriversDesc.IncomeTaxes,
            DiscountedCash.DriversDesc.NOPAT,
            DiscountedCash.DriversDesc.Depreciation,
            DiscountedCash.DriversDesc.ChangeInWorkingCapital,
            DiscountedCash.DriversDesc.CAPEX,
            DiscountedCash.DriversDesc.FreeCashFlow,
            DiscountedCash.DriversDesc.Depreciation,
          ];
          // to do extend object
          setActualValuesDrivers.forEach(
            (d) =>
              (curYear[d.fieldName].evalFormula = curYear[d.fieldName].evalFormula.replaceAll(
                "ValueFormula",
                "ActualFormula",
              )),
          );

          curYear.DiscountFactor.Unit = UnitTypes.Hide;
          curYear.PresentValueOfFreeCashFlows.Unit = UnitTypes.Hide;
        } else {
          curYear.DiscountFactor.Formula = `1 / MxMath.Pow(1 + ${curYear.WACC}, ${year.Order + 1})`;
        }
      }
    });
  };
  periodsData = {};

  getDatesAll = () => {
    return global.Modeliks.DateHelper.discount_cash_years;
  };
  buildPeriodsData = () => {
    const allPeriods = this.getDatesAll();
    allPeriods.forEach((period) => {
      this.periodsData[period.dateID] = {};
      Object.values(this.constructor.DriversDesc).forEach(
        (driver) =>
          (this.periodsData[period.dateID][driver.fieldName] = this[
            driver.fieldName
          ].getItemByDateSufix(period.sufix)),
      );
      this.periodsData[period.dateID].Date = period;
    });
  };

  static createDiscountedCashFlow = (creatingFirstTime = false) => {
    const discountedCashFlowID = "discounted_cash_flow";
    const newDiscountCashFlow = new DiscountedCash();
    newDiscountCashFlow.ID = discountedCashFlowID;
    newDiscountCashFlow.Totals = CalculatedDriver.createDriverFromTable(
      newDiscountCashFlow,
      DiscountedCash.DriversDesc.Total.driverID,
      UnitTypes.Price,
      DriverCategories.Sum,
      true,
    );
    newDiscountCashFlow.Totals.DriverName = "Discounted Cash Flow";
    newDiscountCashFlow.Totals.IsTemporary = true;
    newDiscountCashFlow.createDrivers();
    newDiscountCashFlow.buildPeriodsData();
    newDiscountCashFlow.createDriverValues();
    newDiscountCashFlow.createFormulas(creatingFirstTime);
    newDiscountCashFlow.createTotalFormulas();
    global.Modeliks.DiscountedCashStore.push(newDiscountCashFlow);
    return newDiscountCashFlow;
  };

  createTotalFormulas = () => {
    const openingBalance = OpeningBalanceCalc.getOpeningBalance();
    const years_all = global.Modeliks.DateHelper.years_all;
    const PresetValueOfFreeCashFlowDrivers = years_all
      .map((v) => this.PresentValueOfFreeCashFlows.getItemByDateSufix(v.sufix).ID_f)
      .join("+");

    this.EnterpriseValue.Formula = this.PresentValueOfFreeCashFlows.Values.map((v) => v).join("+");
    this.Debt.Formula = `(${openingBalance.gridRowsEquity[0].children[1].Value.Formula}) * -1`;
    this.Cash.Formula = `(|${openingBalance.gridRowsAssets.children[0].children[0].Value.ID}|)`;
    this.EquityValue.Formula = `${this.EnterpriseValue} + ${this.Debt} + ${this.Cash}`;
    this.TerminalValuePercentOfEnterpriseValue.Formula = `(${this.PresentValueOfFreeCashFlows.Values[this.PresentValueOfFreeCashFlows.Values.length - 1].ID_f} / ${this.EnterpriseValue.ID_f} ) * 100 `;
    this.PresentValueOfFreeCashFlowMin.Formula = `${this.FreeCashFlowTerminalValueMin} * ${this.DiscountFactor.Values[this.DiscountFactor.Values.length - 1]}`;
    // curYear.PresentValueOfFreeCashFlows.Formula = `${curYear.FreeCashFlow} * ${curYear.DiscountFactor}`;
    this.PresentValueOfFreeCashFlowMax.Formula = `${this.FreeCashFlowTerminalValueMax} * ${this.DiscountFactor.Values[this.DiscountFactor.Values.length - 1]}`;
    this.EnterpriseValueMin.Formula = `${PresetValueOfFreeCashFlowDrivers} + ${this.PresentValueOfFreeCashFlowMin}`;
    this.EnterpriseValueMax.Formula = `${PresetValueOfFreeCashFlowDrivers} + ${this.PresentValueOfFreeCashFlowMax}`;
    this.EquityValueMin.Formula = `${this.EnterpriseValueMin}  + ${this.Debt} + ${this.Cash}`;
    this.EquityValueMax.Formula = `${this.EnterpriseValueMax}  + ${this.Debt} + ${this.Cash}`;
  };

  static getDiscountedCashFlowDriver = () => {
    if (!global.Modeliks.DiscountedCashStore.length > 0) {
      return DiscountedCash.createDiscountedCashFlow(true);
    }

    global.Modeliks.DiscountedCashStore[0].createFormulas();
    return global.Modeliks.DiscountedCashStore[0];
  };
}

export default DiscountedCash;
