import React from "react";
import FormulaBox from "./FormulaBox/FormulaBox";
import "./AddCalculatedDriver.scss";
import { IconI } from "../../../../../../components/icons/svgIcons";
import ConvertToSimpleDialog from "./ConvertToSimpleDialog";
import ButtonMui from "../../../../../../components/buttons/buttonMui/buttonMui";

class CalculatedDriverFormula extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      open: false,
    };
  }

  createFormula = (formula) => {
    let err = false;
    let text = "";
    for (let i = 0; i < formula.length; i++) {
      if (formula[i].complex) {
        if (formula[i].hasInput) {
          text += formula[i].Formula;
          text += formula[i].value;
          text += ",";
        } else {
          text += formula[i].Formula;
        }
      } else {
        if (formula[i].isDragDisabled) {
          text += formula[i].value;
        } else {
          if (formula[i].ID) {
            if (text[i - 1] === "1") {
              err = true;
              break;
            }
            text += "1";
          } else {
            text += formula[i].value;
          }
        }
      }
    }
    text = text.replace("MxMath.FormulaMin", "");
    text = text.replace("MxMath.FormulaMax", "");
    try {
      eval(text);
    } catch (e) {
      err = true;
    }

    if (err) {
      this.setState({ error: true });
    } else {
      if (this.state.error) {
        this.setState({ error: false });
      }
      this.props.createFormula(this.props.Formula);
    }
  };

  removeFormula = () => {
    this.props.drivers.forEach((d) => {
      if (d.Ref_Field === "value" && d.db_record === null) {
        global.Modeliks.DriversStore.remove(d);
      }
    });
    this.props.setFormula(null);
  };

  render() {
    if (!this.props.open) {
      return;
    }

    return (
      <>
        <FormulaBox
          error={this.state.error}
          title={this.props.title}
          drivers={this.props.drivers}
          HiddenOperators={this.props.HiddenOperators}
          Formula={this.props.Formula}
          Operators={this.props.Operators}
        />

        <div className="psfccc_formulabox_title">
          {this.props.hasFormula && (
            <div className="psfccc_formulabox_to_simple_driver_group">
              <ButtonMui
                style={{ fontSize: "14px" }}
                width={150}
                color={"red"}
                label="Remove Formula"
                onClick={() => this.setState({ open: true })}
                variant={"contained"}
              />
              <IconI />
            </div>
          )}

          <div className="psfcc_formulabox_error">
            <span>{this.props.errorMessage}</span>
          </div>

          <div className="psfccc_formulabox_button">
            <ButtonMui
              width={this.props.width ? this.props.width : 137}
              label={"Set Formula"}
              onClick={() => this.createFormula(this.props.Formula)}
              color="secondary"
              variant={"contained"}
            />
          </div>
        </div>
        <ConvertToSimpleDialog
          drivers={this.props.drivers}
          title={this.props.title}
          open={this.state.open}
          Convert={() => this.removeFormula()}
          onClose={() => this.setState({ open: false })}
        />
      </>
    );
  }
}

export default CalculatedDriverFormula;
