import React from "react";
import ResizableCalloutRect from "../components/ResizableCallout/ResizableCallout";
import ShapeTextEditor from "./TextInSvgComponents/ShapeTextEditor";
import { RGBAToHexA } from "./ShapeObject";
import FillColorEditComponent from "../SlideHeader/EditorComponents/FillColorEditComponent";

export default class CalloutObject extends React.Component {
  constructor(props) {
    super(props);

    this.textEditorUtils = {
      updateMe: null,
    };

    this.key = new Date().getTime();

    this.state = {
      calloutHeight: props.calloutHeight ? props.calloutHeight : props.height + 20,
      // calloutWidth: props.width ? 1/5 * props.width: 0,
      calloutAngle: props.calloutAngle ? props.calloutAngle : 340,
      value: props.value ? props.value : "",
      top: 0,
      left: 0,
      style: { backgroundColor: "#585858", ...props.style },
    };
    this.lastKnownHeight = this.state.calloutHeight;
    global.pasteListener = this.handlePaste;
  }

  handleResize = (e) => {
    this.setState(
      {
        calloutHeight: e.height,
        calloutAngle: e.calloutAngle,
      },
      () => (this.lastKnownHeight = this.state.calloutHeight),
    );
  };

  handlePaste = (event) => {
    event.preventDefault();
    this.props.slideComponentPaste(event, true);
  };

  handleFillColorChange = (event, callBack) => {
    let rgba = event.backgroundColor.toString().slice(5).slice(0, -1).split(",");
    this.setState(
      (prevState) => {
        return {
          ...prevState,
          style: {
            ...prevState.style,
            backgroundColor: RGBAToHexA(
              Number(rgba[0]),
              Number(rgba[1]),
              Number(rgba[2]),
              Number(rgba[3]),
            ),
          },
        };
      },
      () => {
        this.props.onPropsChange({ style: this.state.style });
        this.textEditorUtils.updateMe && this.textEditorUtils.updateMe();
      },
    );
  };

  handleResizeEnd = () => {
    this.props.onPropsChange({
      calloutHeight: this.state.calloutHeight,
      calloutAngle: this.state.calloutAngle,
    });
  };
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.resizing && !this.props.resizing) {
      this.handleResizeEnd();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.multipleSeleced != this.props.multipleSeleced) {
      if (nextProps.multipleSeleced) {
        global.pasteListener = this.handlePaste;
      }
    }

    if (nextProps.selected != this.props.selected) {
      if (nextProps.selected) {
        global.pasteListener = this.handlePaste;
      }
    }

    return (
      nextProps.resizing ||
      nextProps.resizing != this.props.resizing ||
      nextProps.selected != this.props.selected ||
      this.state != nextState
    );
  }

  calculateHeightRatio = (oldParentHeight, oldCalloutHeight) => {
    return oldCalloutHeight / oldParentHeight;
  };

  render() {
    if (this.props.resizing) {
      this.state.calloutHeight =
        this.props.height *
        this.calculateHeightRatio(this.props.preResizeHeight, this.lastKnownHeight);
    }
    let className = "";
    if (this.props.selected || this.props.multipleSelected) {
      className += " callout_selected";
    }
    return (
      <>
        <ShapeTextEditor
          onMouseDown={(e) => e.stopPropagation()}
          textEditorUtils={this.textEditorUtils}
          handleFillColorChange={this.handleFillColorChange}
          id={this.key}
          style={this.props.style}
          value={this.state.value}
          selected={this.props.selected}
          onPropsChange={this.props.onPropsChange}
        >
          <FillColorEditComponent
            disableAlpha={true}
            onChange={this.handleFillColorChange}
            style={this.state.style}
          />
        </ShapeTextEditor>
        <div
          style={{
            height: this.props.height,
            width: this.props.width,
            position: "relative",
          }}
        >
          {this.props.calloutType == 1 && (
            <svg
              className={"shape"}
              preserveAspectRatio="none"
              viewBox="1.5 2.5 21 19"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22 3H2V21H22V3Z"
                fill={this.state.style.backgroundColor}
                stroke={this.state.style.backgroundColor}
                strokeLinejoin="round"
              />
            </svg>
          )}

          {this.props.calloutType == 2 && (
            <svg
              className={"shape"}
              preserveAspectRatio="none"
              viewBox="1.5 2.5 21 19"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path //strokeDasharray={borderDashed}
                d="M18 3H6C3.79086 3 2 4.79086 2 7V17C2 19.2091 3.79086 21 6 21H18C20.2091 21 22 19.2091 22 17V7C22 4.79086 20.2091 3 18 3Z"
                fill={this.state.style.backgroundColor}
                stroke={this.state.style.backgroundColor}
                strokeLinejoin="round"
              />
            </svg>
          )}
          {this.props.calloutType == 3 && (
            <svg width={"100%"} height={"100%"} className={"shape"} preserveAspectRatio="none">
              <ellipse
                fill={this.state.style.backgroundColor}
                stroke={this.state.style.backgroundColor}
                cx="50%"
                cy="50%"
                rx="50%"
                ry="50%"
              />
            </svg>
          )}
          {/*{this.props.calloutType == 2 &&}*/}
          <ResizableCalloutRect
            className={className}
            height={this.state.calloutHeight}
            selected={this.props.selected}
            resizing={this.props.resizing}
            preResizeHeight={this.props.preResizeHeight}
            width={this.props.width ? (1 / 5) * this.props.width : 0}
            onResizeEnd={() => this.handleResizeEnd()}
            rotateAngle={this.state.calloutAngle}
            parentRotateAngle={this.props.parentRotateAngle}
            left={this.props.width / 2}
            top={this.props.height / 2}
            onResize={this.handleResize}
          >
            <svg
              preserveAspectRatio="none"
              viewBox="1.5 2 21 21"
              fill={this.state.style.backgroundColor}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeWidth="0"
                strokeDasharray="0 0"
                d="M2 2L22 2L22 2L12 23"
                stroke={this.state.style.backgroundColor}
                strokeLinejoin="round"
              />
            </svg>
          </ResizableCalloutRect>
        </div>
      </>
    );
  }
}
