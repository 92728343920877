import React, { useEffect } from "react";
import { GridContextProvider, GridDropZone, GridItem, swap } from "react-grid-dnd";
import "./draggableContainer.scss";
import DriverInsertionTool from "../DriverInsertTemporary/DriverInsertionTool";
import { StreamTypes } from "../../../../components/constants/charts";
import PropTypes from "prop-types";
import DraggableItem from "./DraggableItem";

function DraggableComponent(props) {
  const [items, setItems] = React.useState(props.items);

  const getItemsPerRow = () => {
    if (props.maxItemsPerRow) {
      return props.maxItemsPerRow;
    }
    return 5;
  };

  const [rowItemsNumber, setRowItemsNumber] = React.useState(getItemsPerRow());

  const ref = React.useRef(null);

  function onChange(sourceId, sourceIndex, targetIndex, targetId) {
    if (sourceIndex !== items.length && items.length !== targetIndex) {
      const nextState = swap(items, sourceIndex, targetIndex);
      setItems(nextState);
      props.onChange(nextState);
    } else {
      setItems(items);
    }
  }

  const getDriverInfo = (c) => {
    if (StreamTypes[c]) {
      return StreamTypes[c].DriverName;
    }
    try {
      let obj = JSON.parse(c);
      if (obj.Ref_ID && obj.Ref_ID.toString().includes("CompanyScenarios-$CS_ID-")) {
        obj.Ref_ID = obj.Ref_ID.replace(
          "CompanyScenarios-$CS_ID-",
          `CompanyScenarios-${global.Modeliks.CompanyScenarioInfo.ID}-`,
        );
      }
      if (obj.Ref_Table) {
        return global.Modeliks.DriversStore.find(
          (d) =>
            d.Ref_Field == obj.Ref_Field && d.Ref_ID == obj.Ref_ID && d.Ref_Table == obj.Ref_Table,
        ).DriverName;
      } else if (obj.Ref_ID) {
        return global.Modeliks.DriversStore.find(
          (d) => d.Ref_Field == obj.Ref_Field && d.Ref_ID == obj.Ref_ID,
        ).DriverName;
      } else {
        return global.Modeliks.DriversStore.find((d) => d.Ref_Field == obj.Ref_Field).DriverName;
      }
    } catch (e) {
      let driver = global.Modeliks.DriversStore.find((d) => d.DriverName === c);
      if (driver) {
        return driver.DriverName;
      }
      return null;
    }
  };

  useEffect(() => {
    if (!props.maxItemsPerRow) {
      setRowItemsNumber(Math.floor(ref.current.getBoundingClientRect().width / 172));
    }
  }, []);

  useEffect(() => {
    if (props.items != items) {
      setItems(props.items);
    }
  }, [props.items]);

  const handleDelete = (index) => {
    let newState = [...items];
    newState.splice(index, 1);
    setItems(newState);
    props.onChange(newState);
  };

  const getItemWidth = () => {
    if (ref.current) {
      return ref.current.getBoundingClientRect().width / rowItemsNumber - 7;
    } else {
      return 172 - 7;
    }
  };

  const getComponentHeight = () => {
    let height = Math.ceil((items.length + 1) / rowItemsNumber) * 33;
    if (height < 33) return 33;
    return height;
  };

  const getItem = (item) => {
    if (typeof item === "object" && item !== null) {
      return item.driverName;
    }
    return getDriverInfo(item);
  };

  const getItemEdit = (item) => {
    if (typeof item === "object" && item !== null) {
      return true;
    }
    return false;
  };

  const setName = (item, name) => {
    if (item.driverName) {
      item.driverName = name;
    }
    props.onChange(items);
  };

  return (
    <div className="driver_grid_container" ref={ref}>
      <GridContextProvider onChange={onChange}>
        <GridDropZone
          id="items"
          boxesPerRow={rowItemsNumber}
          rowHeight={33}
          style={{ height: getComponentHeight() }}
        >
          {items.map((item, index) => (
            <GridItem key={`${index}_grid_item_key`} className="driver_grid_item_container">
              <DraggableItem
                changeName={(name) => setName(item, name)}
                name={getItem(item)}
                edit={getItemEdit(item)}
                onDelete={() => handleDelete(index)}
              />
            </GridItem>
          ))}
          <GridItem onMouseDown={(e) => e.stopPropagation()} className="driver_grid_item_container">
            <DriverInsertionTool {...props} width={getItemWidth()} />
          </GridItem>
        </GridDropZone>
      </GridContextProvider>
    </div>
  );
}

DraggableComponent.propTypes = {
  maxItemsPerRow: PropTypes.bool,
  items: PropTypes.array,
  onChange: PropTypes.func,
};

export default DraggableComponent;
