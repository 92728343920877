import React from "react";
import AuthPage from "../components/AuthPage";
import Steps from "./Steps";
import Subscriptions from "./Subscriptions";
import request from "superagent";
import { referrals } from "../../../../Enums/ModeliksEnum";
import RegisterPage from "./RegisterPage";

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      step: 0,
      SelectedSubscriptionID: null,
      price: null,
    };

    const query = new URLSearchParams(window.location.search);
    const irclickid = query.get("irclickid");

    if (irclickid && irclickid.toLowerCase().includes(referrals.modeliks)) {
      this.referralId = irclickid.substring(
        irclickid.indexOf(referrals.modeliks + "-") + referrals.modeliks.length + 1,
      );
      this.referralSource = referrals.modeliks;
    } else if (irclickid) {
      this.referralId = irclickid;
      this.referralSource = referrals.imapct;
    } else {
      this.referralId = null;
      this.referralSource = null;
    }
  }

  componentDidMount() {
    document.title = "Plans & Pricing | Modeliks Financial Planning Software";

    const metaDescription = document.createElement("meta");
    metaDescription.name = "description";
    metaDescription.content =
      "Explore Modeliks Flexible Pricing Plans. Access Budget-Friendly Professional Business and Financial Planning Tools. Register & Start Your Free Trial Today!";
    document.head.appendChild(metaDescription);

    const metaKeywords = document.createElement("meta");
    metaKeywords.name = "keywords";
    metaKeywords.content =
      "modeliks, financial planning software free trial, business plan software free trial, business software price, business software cost, best budget software for small business, low cost financial planning software for advisors, professional business plan cost, pitch deck pricing";
    document.head.appendChild(metaKeywords);
  }

  componentWillUnmount() {
    document.title = "Modeliks";

    const metaDescription = document.querySelector('meta[name="description"]');
    const metaKeywords = document.querySelector('meta[name="keywords"]');

    document.head.removeChild(metaDescription);
    document.head.removeChild(metaKeywords);
  }

  onCardClick = (SelectedSubscriptionID, price, paddle_price) => {
    if (
      window.localStorage.getItem("Account_ID") &&
      window.localStorage.getItem("Email") &&
      window.localStorage.getItem("isVerified") === "false"
    ) {
      if (price !== 0) {
        window.Paddle.Checkout.open({
          items: [
            {
              priceId: paddle_price,
              quantity: 1,
            },
          ],
          customer: {
            email: window.localStorage.getItem("Email"),
          },
          customData: {
            account_id: window.localStorage.getItem("Account_ID"),
            subscription_id: SelectedSubscriptionID,
          },
        });
      } else {
        request
          .post("/api/createFreeTrial")
          .send({
            accountID: window.localStorage.getItem("Account_ID"),
            subscriptionID: SelectedSubscriptionID,
          })
          .then((data) => {
            if (data) {
              window.location.href = "/SuccessPayment/1";
            }
          });
      }
    } else {
      this.setState({ step: 1, SelectedSubscriptionID, price, paddle_price });
    }
  };

  render() {
    return (
      <>
        <h1 style={{ display: "none" }}>Plans & Pricing | Modeliks Financial Planning Software</h1>
        {/*<Steps step={this.state.step}/>*/}
        {this.state.step === 0 && <Subscriptions onCardClick={this.onCardClick} />}
        {/*{this.state.step === 1 && <AuthPage type='register' referralId={this.referralId} referralSource={this.referralSource} SelectedSubscriptionID={this.state.SelectedSubscriptionID} price={this.state.price} paddle_price={this.state.paddle_price}/>}*/}
        {this.state.step === 1 && (
          <RegisterPage
            referralId={this.referralId}
            referralSource={this.referralSource}
            SelectedSubscriptionID={this.state.SelectedSubscriptionID}
            price={this.state.price}
            paddle_price={this.state.paddle_price}
          />
        )}
      </>
    );
  }
}

export default Register;
