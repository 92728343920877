import React from "react";
import DisplayReports from "./DisplayReports";
import CircleLoader from "../../../../components/Loaders/CircleLoader/CircleLoader";
import "./index.scss";
import "../../../Admin/Dashboards/components/dashboardsBlankAdmin.scss";
import AddChartDialog from "../CustomDashboards/AddChartDialog";
import { ReportRoutes, RouteReports } from "../index";

const Loader = (
  <div className={"main_circle_loader_container"}>
    <CircleLoader />
  </div>
);

export default class FinancialStatements extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      key: new Date().getTime(),
      loading: true,
      selectedSubsection:
        props.FinancialStatements.subsections[
          this.props.defaultSelectedReport.financial_statements
        ],
      openDialog: false,
    };
  }

  componentDidMount() {
    this.setSubsectionByRoute();
    !this.props.customDashboard &&
      global.Modeliks.Subscribe("onLocationChange", this.handleRouteChange);
  }

  componentWillUnmount() {
    !this.props.customDashboard &&
      global.Modeliks.Unsubscribe2("onLocationChange", this.handleRouteChange);
  }

  handleRouteChange = () => {
    const newSection = this.getSubsectionByRoute();
    if (newSection && this.state.selectedSubsection.key != newSection.key) {
      this.setSelectedSubsection(newSection, undefined, new Date().getTime());
    }
  };

  getSubsectionByRoute = () => {
    const arr = window.location.href.split("/");
    const route = arr[arr.length - 1];
    let subsection = undefined;
    if (RouteReports.hasOwnProperty(route)) {
      let reportKey = RouteReports[route];
      if (reportKey !== "EmployeeSchedule") {
        subsection = this.props.FinancialStatements.subsections[reportKey];
      }
    }
    return subsection;
  };

  setSubsectionByRoute = () => {
    this.setSelectedSubsection(this.getSubsectionByRoute());
  };

  setSelectedSubsection = (section, callBack, key = this.state.key) => {
    this.setState({ loading: true });
    const prefixRoute = "/dashboards/financial_statements/";
    if (section && callBack) {
      this.props.defaultSelectedReport.financial_statements = section.key;
      this.props.FinancialStatements.selectedSubsection = section;
      const route = prefixRoute + ReportRoutes[section.key];
      if (window.location.pathname != route && !this.props.customDashboard) {
        global.Modeliks.NavigateTo(route);
      }
      callBack();
    } else {
      this.props.FinancialStatements.selectedSubsection =
        section ??
        this.props.FinancialStatements.subsections[
          this.props.defaultSelectedReport.financial_statements
        ];
      const route =
        prefixRoute + ReportRoutes[this.props.FinancialStatements.selectedSubsection.key];
      if (window.location.pathname != route && !this.props.customDashboard) {
        global.Modeliks.NavigateTo(route);
      }
      this.setState({
        loading: false,
        selectedSubsection: this.props.FinancialStatements.selectedSubsection,
        key,
      });
    }
  };

  onChangeView = () => {
    this.props.financialMonths.showMonthly = !this.props.financialMonths.showMonthly;
    this.updateDataGrid();
  };

  finishUpdating = () => {
    Object.entries(this.props.FinancialStatements.subsections).forEach(([key, section]) => {
      if (key !== "EmployeeSchedule") {
        section.gridRows = this.props.setReports[section.key]();
      }
    });
    setTimeout(() => {
      this.setState({ loading: false });
    }, 100);
  };

  toggleDialog = () => {
    this.setState({ openDialog: !this.state.openDialog });
  };

  getDisplayReports = (customDashboard) => (
    <div className={"pfr_main_revenue_content streams"} key={this.state.key}>
      <DisplayReports
        addTableToCustomDashboard={this.toggleDialog}
        loader={Loader}
        customDashboard={customDashboard}
        updateDataGrid={this.updateDataGrid}
        defaultView={this.props.defaultView}
        setReports={this.props.setReports}
        financialMonths={this.props.financialMonths}
        showMonthly={this.props.financialMonths.showMonthly}
        onChangeView={this.onChangeView}
        titleDate={this.props.titleDate}
        reload={() => this.setState({ key: new Date().getTime() })}
        FinancialStatements={this.props.FinancialStatements}
        setSelectedSubsection={this.setSelectedSubsection}
        selectedSubsection={
          this.state.selectedSubsection
            ? this.state.selectedSubsection
            : this.props.FinancialStatements.selectedSubsection
        }
        {...this.props}
        onDeleteTable={this.props.onDeleteTable}
      />
    </div>
  );

  updateDataGrid = () => {
    this.setState({ loading: true }, this.finishUpdating);
  };

  render() {
    if (this.state.loading || global.Modeliks.isLoading) {
      return Loader;
    }
    if (this.props.customDashboard) {
      return this.getDisplayReports(true);
    }
    console.log(this);
    return (
      <>
        {this.state.openDialog && (
          <AddChartDialog
            handleClose={this.toggleDialog}
            tableInfo={{
              defaultSelectedReport: this.props.defaultSelectedReport,
              defaultView: this.props.defaultView,
            }}
          />
        )}
        {this.getDisplayReports()}
      </>
    );
  }
}
