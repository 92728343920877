import React from "react";
import { Vector } from "../../dividers/Vector";
import Button from "../../actions/Button";
import Input from "../../actions/Input";
import MaterialSelect from "../../actions/SelectM";
import { Paragraph } from "../../typography/paragraph/Paragraph";
import IconButton from "@mui/material/IconButton";
import { IconI, IconPlus, IconX, TrashCan2 } from "../../icons/svgIcons";
import "./SupscriptionPlans.scss";
import Tooltip from "@mui/material/Tooltip";
import Mx_Tooltip from "../../icons/Mx_Tooltip_Click";
import CancelDialog from "../settings/CancelDialog";
import ButtonMui from "../../buttons/buttonMui/buttonMui";
import { DialogTypes } from "../../../data/Finance/constants";
import { PERMISSIONS } from "../../../data/Permissions/Permissions";

class SubscriptionPlans extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subscription: [],
      open: false,
      index: null,
      row: null,
    };
    this.titleSubscriptionPeriod = `Subscription period means the period of time during which a subscription remains active for a user of a particular service. Once the subscription period has passed, the subscription for the user will automatically renew at the same price and for the same subscription period duration.`;
  }

  handleChange = (e, row) => {
    let value = parseInt(e.target.value);

    if (!row.ID) {
      const plan =
        this.props.revenue.subscriptionplans[this.props.revenue.subscriptionplans.indexOf(row)];
      plan.SubscriptionPeriodMonths = value;
      this.forceUpdate();
    } else {
      const plan =
        this.props.revenue.subscriptionplans[this.props.revenue.subscriptionplans.indexOf(row)];
      plan.SubscriptionPeriodMonths = value;
      const children = this.props.revenue.children.find((c) => c.ID === row.ID);
      children.SubscriptionPeriod.Value = value;
      this.forceUpdate();
    }
  };

  handleChangeSubscriptionName = (e, row) => {
    if (!row.ID) {
      const plan =
        this.props.revenue.subscriptionplans[this.props.revenue.subscriptionplans.indexOf(row)];
      plan.SubscriptionName = e.target.value;
      this.forceUpdate();
    } else {
      const plan =
        this.props.revenue.subscriptionplans[this.props.revenue.subscriptionplans.indexOf(row)];
      plan.SubscriptionName = e.target.value;
      const children = this.props.revenue.children.find((c) => c.ID === row.ID);
      children.RevenueName = e.target.value;
      this.forceUpdate();
    }
  };

  addRow = () => {
    if (
      this.props.revenue.subscriptionplans.length >=
      PERMISSIONS.Financials.restrictions.MaxStreamsCount.TotalSubscriptionPerPlan
    ) {
      global.Modeliks.showDialog(
        `
            You have reached the maximum number of [${PERMISSIONS.Financials.restrictions.MaxStreamsCount.TotalSubscriptionPerPlan}] plans per Subscription Revenue. 
            Please reorganize your plans in Subscription. \n Click outside of the box to continue with your work.`,
        DialogTypes.WarningSimpleText,
        () => {
          this.forceUpdate();
        },
      );
    } else {
      this.props.revenue.subscriptionplans.push({
        SubscriptionName: "",
        SubscriptionPeriodMonths: 1,
        ID: null,
        Delete: false,
      });
      this.forceUpdate();
    }
  };

  deleteRow = (index, row) => {
    this.setState({ open: true, index: index, row: row });
  };

  removeRow = (index, row) => {
    if (row.ID) {
      this.setState({ open: false }, () => this.props.removeSubscription(row, true));
    } else {
      this.props.revenue.subscriptionplans = this.props.revenue.subscriptionplans.filter(
        (d) => d !== row,
      );
      this.setState({ open: false }, () => this.props.removeSubscription(row, false));
    }
  };

  manageDialog = () => {
    this.setState({ open: false, index: null, row: null });
  };

  render() {
    return (
      <div style={{ paddingBottom: "33px" }}>
        <div style={{ display: "flex" }}>
          <Paragraph size={"p3_strong"} label="Define Subscription Plan" />
        </div>

        <div style={{ display: "flex", width: "100%" }}>
          <div style={{ display: "flex", justifyContent: "start", width: "57%" }}>
            <Paragraph size={"p3"} label="Subscription plan Name" />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              gap: "15px",
              marginLeft: "10px",
            }}
          >
            <Paragraph size={"p3"} label="Subscription Period" />
            <Mx_Tooltip description={this.titleSubscriptionPeriod} />
          </div>
        </div>
        {this.props.SubscriptionPlans.filter((row) => !row.Delete).map((row, index) => {
          return (
            <div
              key={"srows" + index}
              style={{ display: "flex", alignItems: "start", gap: "19px", marginTop: "15px" }}
            >
              <div style={{ width: "80%" }}>
                <Input
                  errorMessage={
                    this.props.error &&
                    row.SubscriptionName === "" &&
                    "Please enter subscription name"
                  }
                  error={this.props.error && row.SubscriptionName === ""}
                  name="SubscriptionName"
                  value={row.SubscriptionName || ""}
                  onChange={(e) => this.handleChangeSubscriptionName(e, row)}
                  placeholder={"Add name"}
                />
              </div>

              <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                <MaterialSelect
                  errorMessage={
                    this.props.error &&
                    row.SubscriptionPeriodMonths === "" &&
                    "Please enter subscription period"
                  }
                  error={this.props.error && row.SubscriptionPeriodMonths === ""}
                  value={row.SubscriptionPeriodMonths ? row.SubscriptionPeriodMonths : "1"}
                  name="SubscriptionPeriodMonths"
                  label="Please select"
                  onChange={(e) => this.handleChange(e, row)}
                  width={"180px"}
                  options={this.props.SubscriptionPeriodMonths}
                />
              </div>
              <IconButton
                className={
                  index || this.props.SubscriptionPlans.filter((d) => d.Delete === false).length > 1
                    ? "icon_button"
                    : "icon_button hidden"
                }
                size={"small"}
                onClick={() => this.deleteRow(index, row)}
              >
                <TrashCan2 className="trash_can_2" />
              </IconButton>
            </div>
          );
        })}

        <div style={{ marginLeft: "-10px", marginTop: "25px" }}>
          <ButtonMui
            style={{ height: "30px", fontSize: "14px" }}
            startIcon={<IconPlus />}
            label={"Add subscription plan"}
            variant={"contained"}
            onClick={this.addRow}
          />
        </div>

        <div style={{ padding: 0, margin: 0, marginBottom: "15px", marginTop: "15px" }}>
          <Vector />
        </div>

        <CancelDialog
          btnName="Confirm"
          firstBtnName="Cancel"
          title={`Delete Subscription Plan`}
          onDelete={() => this.removeRow(this.state.index, this.state.row)}
          open={this.state.open}
          onExit={this.manageDialog}
        >
          {`Are you sure that you want to delete ${this.state.row && this.state.row.SubscriptionName} ?`}
        </CancelDialog>
      </div>
    );
  }
}

export default SubscriptionPlans;
