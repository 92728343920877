import React from "react";
import AuthPage, { EmailRegex } from "../components/AuthPage";
import "./login.scss";
import BackgroundBalls from "../components/BackgroundBalls";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import ButtonMui from "../../../../components/buttons/buttonMui/buttonMui";
import {
  LoginTopLeftCornerBigShape,
  LoginBottomRightCornerBigShape,
  MobileTitleShape,
} from "../../../../components/icons/svgIcons";
import request from "superagent";
import Mx_MainLoader from "../../../../components/Loaders/MainLoader/Mx_MainLoader";
import LoginInput from "../../../../components/inputs/loginInput/LoginInput";
import { BackgroundWrapper, CopyrightMsg, ModeliksHeaderLogo } from "../Register/RegisterPage";
import GoogleButton from "../../../../components/buttons/googleButton";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      keepMeLoggedIn: false,
      email: "",
      emailError: "",
      passwordError: "",
      password: "",
      loading: false,
    };

    if (props.shouldPrefillEmail) {
      let arr = window.location.pathname.split("/");
      this.user_id = arr.pop();
      this.guid = arr.pop();
    }

    const query = new URLSearchParams(window.location.search);

    const irclickid = query.get("irclickid");

    if (irclickid) {
      this.qs = "?irclickid=" + irclickid;
    } else {
      this.qs = "";
    }

    this.querySting = window.location.search;
  }

  handleCheckboxChange = () => {
    this.setState((prevState) => ({
      keepMeLoggedIn: !prevState.keepMeLoggedIn,
    }));
  };

  componentDidMount() {
    if (this.props.shouldPrefillEmail) {
      request
        .get("/api/getEmail")
        .query({ guid: this.guid.toString(), User_ID: this.user_id })
        .then((res) => {
          if (res) {
            let result = JSON.parse(res.body);
            this.setState({ email: result.userEmail });
          }
        });
    }

    document.title = "Account Login | Modeliks Strategic Planning Software";

    const metaDescription = document.createElement("meta");
    metaDescription.name = "description";
    metaDescription.content =
      "Modeliks Leading Strategic Planning Software. Advanced Business Planning Tools Designed for Optimal Strategy Management. Login & Kickstart Your Business Growth!";
    document.head.appendChild(metaDescription);

    const metaKeywords = document.createElement("meta");
    metaKeywords.name = "keywords";
    metaKeywords.content =
      "modeliks, strategic planning software, best strategic planning software, strategic planning systems, strategic planning platform, strategic planning software tools, strategic account planning software, strategic planning dashboard software, strategy management software";
    document.head.appendChild(metaKeywords);
  }

  componentWillUnmount() {
    document.title = "Modeliks";

    const metaDescription = document.querySelector('meta[name="description"]');
    const metaKeywords = document.querySelector('meta[name="keywords"]');

    document.head.removeChild(metaDescription);
    document.head.removeChild(metaKeywords);
  }

  validate = () => {
    const { email, password } = this.state;
    let emailError = "";
    let passwordError = "";

    if (email == "Administrator") {
      return true;
    }
    switch (true) {
      case !EmailRegex.test(email):
        emailError = "email error";
        break;
    }

    // if(password.length < 8){
    // 	passwordError = 'Your password must be 8 characters or longer';
    // }
    // switch (true) {
    //     case password.length < 8:
    //         passwordError = 'Your password must be 8 characters or longer';
    //         break;
    //     case password === email:
    //         passwordError = 'Email and Password cannot be the same';
    //         break;
    // }

    if (emailError || passwordError) {
      this.setState({ emailError, passwordError });
      return false;
    }
    return true;
  };

  client = global.Modeliks.googleClient((response) => {
    this.setState({ loading: true });
    request
      .post("/api/auth-code")
      .send({ code: response.code, TimeStamp: new Date().getTime() })
      .set("Content-Type", "application/x-www-form-urlencoded")
      .set("X-Requested-With", "XmlHttpRequest")
      .then(this.handleLoginResponse)
      .catch((e) => {
        console.error(" i am here", e);
        this.setState({ loading: false });
      });
    // Set custom header for CRSF
  });

  toggleLoading = () => this.setState({ loading: !this.state.loading });

  loginWithoutAccount = (data_body) => {
    this.setState({ loading: true });
    window.localStorage.setItem("User_ID", data_body.UserID);
    window.localStorage.setItem("token", data_body.token);
    global.Modeliks.NoAccount = true;
    global.Modeliks.Authenticate();
  };

  loginWithAccount = (index, data) => {
    this.setState({ loading: true });
    handleLogin(index, data);
  };

  handleLoginResponse = (newData) => {
    this.setState({ newData });
    if (newData.body.Accounts) {
      if (newData.body.Accounts.length > 1) {
        this.setState({
          loading: false,
          chooseAccount: true,
          accounts: newData.body.Accounts,
          newData,
        });
      } else {
        this.loginWithAccount(0, newData);
      }
    } else {
      // user is without accounts
      this.loginWithoutAccount(newData.body);
    }
  };

  login = () => {
    const { keepMeLoggedIn, password, email, emailError, passwordError } = this.state;
    if (this.validate()) {
      this.setState({ loading: true });
      request
        .post("/api/auth")
        .send({
          Email: email,
          Password: password,
          TimeStamp: new Date().getTime(),
          keepLoggedIn: keepMeLoggedIn,
        })
        .then(this.handleLoginResponse)
        .catch((err) => {
          if (err.response.body.error.User_ID) {
            this.setState({
              UserID: err.response.body.error.User_ID,
              chooseAccount: true,
            });
          } else {
            const newState = { chooseAccount: false };
            if (err.response.body.error[0]) {
              Object.assign(newState, {
                passwordError: err.response.body.error[0],
              });
            } else {
              Object.assign(newState, { passwordError: "Invalid Credentials" });
            }
            if (err.response.body.error[1]) {
              Object.assign(newState, { errorTip: err.response.body.error[1] });
            }
            // Object.assign({newState, passwordError:  ? err.response.body.error : "Invalid Credentials"});
            this.setState(newState);
          }
          this.setState({ loading: false });
          // setLoading(false);
          // err.response.body.error[1] && setTipText(err.response.body.error);
        });
    }
  };

  createAnAccount = () => {
    request.post("/api/insertAccount").send({ User_ID: this.state.UserID }).then(this.login);
  };

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + "Error"]: "",
    });
  };

  getContent = () => {
    const {
      keepMeLoggedIn,
      password,
      email,
      emailError,
      passwordError,
      errorTip,
      chooseAccount,
      accounts,
      newData,
    } = this.state;

    if (chooseAccount) {
      return (
        <>
          <div className="login-title">
            <span>
              {accounts ? "Choose an account" : "No accounts linked with this email address."}
            </span>
          </div>
          {accounts ? (
            accounts.map((c, index) => (
              <ButtonMui
                onClick={() => this.loginWithAccount(index, newData)}
                roundnessType={"squared"}
                style={{
                  width: "510px",
                  marginTop: "10px",
                  height: "58px",
                  borderRadius: "10px",
                  fontSize: "16px",
                  fontWeight: 700,
                }}
                variant={"contained"}
                label={c.Name ? c.Name : `Account ${index + 1}`}
              />
            ))
          ) : (
            <ButtonMui
              onClick={this.createAnAccount}
              roundnessType={"squared"}
              style={{
                width: "510px",
                marginTop: "10px",
                height: "58px",
                borderRadius: "10px",
                fontSize: "16px",
                fontWeight: 700,
              }}
              variant={"contained"}
              label={"Create an Account"}
            />
          )}
        </>
      );
    }
    return (
      <>
        <div className="login-title">
          <span>Welcome Back</span>
        </div>
        <div className="input-field" style={{ marginBottom: "16px" }}>
          <LoginInput
            value={email}
            type="email"
            name="email"
            label="Email"
            onKeyDown={(e) => handleKeyDown(e, this.login)}
            placeholder="Enter your email"
            onChange={this.handleInputChange}
            errorMessage={emailError}
          />
        </div>
        <div className="input-field">
          <LoginInput
            value={password}
            type="password"
            name="password"
            label="Password"
            onKeyDown={(e) => handleKeyDown(e, this.login)}
            placeholder="Enter your password"
            onChange={this.handleInputChange}
            errorMessage={passwordError}
            errorTip={errorTip}
          />
        </div>
        <div className="keep-login">
          <FormControlLabel
            control={
              <Checkbox
                checked={keepMeLoggedIn}
                onChange={this.handleCheckboxChange}
                defaultChecked
                sx={{
                  color: "#01affd",
                  "&.Mui-checked": {
                    color: "#01affd",
                  },
                }}
              />
            }
            label="Keep me logged in"
          />
        </div>
        <div className="action-btns-wrapper">
          <div className="login-btn">
            <ButtonMui
              label="Log In"
              style={{ height: "44px" }}
              width="100%"
              variant={"contained"}
              onClick={this.login}
            />
          </div>
          <GoogleButton onClick={() => this.client.requestCode()} />
          <div className="forgot-pass-btn">
            <ButtonMui
              label="Forgot Password?"
              width="100%"
              variant={"text"}
              onClick={() => global.Modeliks.NavigateTo("/reset")}
            />
          </div>
        </div>
      </>
    );
  };

  render() {
    const { keepMeLoggedIn, password, email, emailError, passwordError, errorTip } = this.state;
    return (
      <>
        <h1 style={{ display: "none" }}>Account Login | Modeliks Strategic Planning Software</h1>
        {this.state.loading && <Mx_MainLoader secondLoader={true} className={"blurred"} />}
        <div className="login-wrapper">
          <BackgroundWrapper>
            <div className="login-content-wrapper">
              <div className="content">
                <div className="logo">
                  <ModeliksHeaderLogo />
                </div>
                <div className="login-content">{this.getContent()}</div>
                <div className="sign-up-content">
                  <div className="no-acc">
                    <span className="no-acc-text">Don't have an account?</span>
                    <ButtonMui
                      label="Sign Up"
                      width={90}
                      variant={"contained"}
                      onClick={() => global.Modeliks.NavigateTo("/register")}
                    />
                  </div>
                </div>
                <CopyrightMsg />
              </div>
            </div>
          </BackgroundWrapper>
        </div>
      </>

      // <AuthPage qs={this.querySting ? this.querySting : ''} LoginApp={this.props.LoginApp} type='login'/>
    );
  }
}

export const handleKeyDown = (e, submitFunction, defaultFunction) => {
  if (e.key === "Enter") {
    e.preventDefault();
    e.stopPropagation();
    submitFunction();
  } else {
    defaultFunction && defaultFunction(e);
  }
};

export const handleLogin = (index, data) => {
  const data_body = JSON.parse(JSON.stringify(data.body));
  global.Modeliks.isAdmin = data_body.Accounts[index].isAdmin;
  window.localStorage.setItem("User_ID", data_body.ID);
  window.localStorage.setItem("token", data_body.Accounts[index].token);
  if (data.body.Accounts[index].ID) {
    window.localStorage.setItem("Account_ID", data_body.Accounts[index].ID);
    global.Modeliks.Account = { ID: data_body.Accounts[index].ID };
    request
      .get(`/api/checkIfShouldInit?AccountID=${data_body.Accounts[index].ID}`)
      .then((result) => {
        global.Modeliks.Login(data.body, result.body);
      })
      .catch((e) => {
        console.error("error adding account", e);
      });
  } else {
    global.Modeliks.Login(data.body, false);
  }
};

export default Login;
