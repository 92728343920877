import PropTypes from "prop-types";
import { Component } from "react";
import { CommandManagerContext } from "../../SlideEditor/services/commands/CommandManagerProvider";
import ContentPanelBusinessPlan from "../ContentPanelBusinessPlan";
import { getDraggableElementRect, isAbove } from "../services/customSortableFlow";

let draggingEle;
let placeholder;
let isDraggingStarted = false;
let x = 0;
let y = 0;

let draggingElementHeight;
let draggingElementWidth;

class CustomSortable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.draggingIndex = null;
    this.droppingIndex = null;
    this.mouseHasMoved = false;
    this.swapped = false;
  }

  getRealXPosition = (slideCompRect, value) => {
    let offsetLeft = slideCompRect.left;
    return (value - offsetLeft) / window.panelScale;
  };

  getRealYPosition = (slideCompRect, value) => {
    let offsetTop = slideCompRect.top;
    return (value - offsetTop) / window.panelScale;
  };

  mouseDownHandler = (e, id) => {
    // Attach the listeners to `document`
    document.addEventListener("mousemove", this.mouseMoveHandler);

    draggingEle = document.getElementById("slide_object_" + id);
    this.draggingIndex = id;

    // Calculate the mouse position
    let scalePanel = document.getElementById("se_scale_panel").getBoundingClientRect();

    const { width, height, top, left } = getDraggableElementRect(draggingEle);
    draggingElementHeight = height;
    draggingElementWidth = width;
    x = this.getRealXPosition(scalePanel, e.pageX) - this.getRealXPosition(scalePanel, left);
    y = this.getRealYPosition(scalePanel, e.pageY) - this.getRealYPosition(scalePanel, top);
  };

  mouseMoveHandler = (e) => {
    if (!this.mouseHasMoved) {
      document.addEventListener("mouseup", this.mouseUpHandler);
      this.mouseHasMoved = true;
    }
    if (e.buttons !== 1) {
      this.mouseUpHandler();
      return;
    }
    const draggingRect = draggingEle.getBoundingClientRect();

    if (!isDraggingStarted) {
      // Update the flag
      isDraggingStarted = true;

      // Let the placeholder take the height of dragging element
      // So the next element won't move up
      placeholder = document.createElement("div");
      placeholder.classList.add("placeholder");
      draggingEle.parentNode.insertBefore(placeholder, draggingEle.nextSibling);

      // Set the placeholder's height
      placeholder.style.height = `${draggingRect.height / window.panelScale}px`;
      placeholder.style.width = `${draggingRect.width / window.panelScale}px`;
    }
    let scalePanel = document.getElementById("se_scale_panel").getBoundingClientRect();

    // Set position for dragging element
    draggingEle.style.position = "absolute";
    draggingEle.style.top = `${this.getRealYPosition(scalePanel, e.pageY) - y}px`;
    draggingEle.style.left = `${this.getRealXPosition(scalePanel, e.pageX) - x}px`;
    draggingEle.style.height = `${draggingElementHeight}`;
    draggingEle.style.width = `${draggingElementWidth}`;

    const prevEle = draggingEle.previousElementSibling;
    const nextEle = placeholder.nextElementSibling;

    if (prevEle && isAbove(draggingEle, prevEle)) {
      // The current order    -> The new order
      // prevEle              -> placeholder
      // draggingEle          -> draggingEle
      // placeholder          -> prevEle
      this.swap(placeholder, draggingEle);
      this.swap(placeholder, prevEle);
      return;
    }

    if (nextEle && isAbove(nextEle, draggingEle)) {
      // The current order    -> The new order
      // draggingEle          -> nextEle
      // placeholder          -> placeholder
      // nextEle              -> draggingEle
      this.swap(nextEle, placeholder, true);
      this.swap(nextEle, draggingEle, true);
    }
  };

  swap = (nodeA, nodeB, isDownwards) => {
    this.swapped = true;
    
    let elem = nodeB;
    if (isDownwards) {
      elem = nodeA;
    }

    if (elem) {
      if (elem.hasAttribute("id")) {
        let val = Number.parseInt(elem.getAttribute("id").replace("slide_object_", ""));
        if (Number.isInteger(val) && val !== this.draggingIndex) {
          this.droppingIndex = val;
        }
      }
    }

    this.swapElementsPlaces(nodeA, nodeB);
   };

   swapElementsPlaces = (nodeA, nodeB) => {
    const parentA = nodeA.parentNode;
    const siblingA = nodeA.nextSibling === nodeB ? nodeA : nodeA.nextSibling;
    // Move `nodeA` to before the `nodeB`
    nodeB.parentNode.insertBefore(nodeA, nodeB);

    // Move `nodeB` to before the sibling of `nodeA`
    parentA.insertBefore(nodeB, siblingA);
   }

  mouseUpHandler = () => {
    // Remove the handlers of `mousemove` and `mouseup`
    document.removeEventListener("mousemove", this.mouseMoveHandler);
    document.removeEventListener("mouseup", this.mouseUpHandler);
    if (!this.mouseHasMoved) {
      return;
    }
    this.mouseHasMoved = false;
    // Remove the placeholder
    if (placeholder && placeholder.parentNode) {
      placeholder && placeholder.parentNode.removeChild(placeholder);
    }
    // Reset the flag
    isDraggingStarted = false;
    // Remove the position styles
    draggingEle.style.removeProperty("top");
    draggingEle.style.removeProperty("left");
    draggingEle.style.removeProperty("position");

    x = null;
    y = null;

    this.onSwapFinished(this.draggingIndex, this.droppingIndex);
    
    this.swapped = false;
    draggingEle = null;
  };

  onSwapFinished = (index1, index2) => {
    if (index1 === null || index2 === null || !this.swapped) {
      return;
    }
    this.props.onSwapFinished(index1, index2);
  };

  render() {
    return (
      <div style={{ display: "flex", flexDirection: "column", rowGap: "15px" }}>
        {this.props.objectsForContentPanel.map((object, index) => {
          object.onDragStart = (e) => this.mouseDownHandler(e, index);
          return (
            <ContentPanelBusinessPlan
              getZoom={this.props.getZoom}
              id={`slide_object_${index}`}
              key={index}
              {...object}
            />
          );
        })}
      </div>
    );
  }
}

CustomSortable.propTypes = {
  objectsForContentPanel: PropTypes.array.isRequired,
};

CustomSortable.contextType = CommandManagerContext;

export default CustomSortable;
