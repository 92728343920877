import React from "react";
import { PermissionTypes } from "../../../../../components/constants/permissions";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import "./overview_table.scss";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import ShowHideMenu from "../../../Dashboards/FinancialStatements/components/SowHideMenu";
import MaterialSelect from "../../../../../components/actions/SelectM";
import ExportReport from "../../../Financials/components/Export/ExportReport";
import ExportPortal from "../../../Dashboards/FinancialStatements/components/ExportPortal";
import Mx_NumInput from "../../../../../components/actions/Mx_NumInput";
import Revenue from "../../../../../data/Finance/Revenue";
import { RefBaseTypes } from "../../../../../data/Finance/Valuation/Overview/constants";
import CircleLoader from "../../../../../components/Loaders/CircleLoader/CircleLoader";

class CompanyTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      refBase: props.Overview.refBase,
      refBaseKey: props.Overview.refBaseKey,
    };
    this.EditPermission = global.Modeliks.UserPermissions[PermissionTypes.Financials] == 0;
    this.gridRows = [];

    this.headers = [
      { Header: "Discounted Cash Flows", checked: true, single: true },
      { Header: "Trading Multiples", checked: true, parent: true },
      { Header: "EV / Revenue", checked: true, child: true },
      { Header: "EV / EBITDA", checked: true, child: true },
      { Header: "EV / E", checked: true, child: true },
      { Header: "Transaction Multiples", checked: true, parent: true },
      { Header: "EV / Revenue", checked: true, child: true },
      { Header: "EV / EBITDA", checked: true, child: true },
      { Header: "EV / E", checked: true, child: true },
      { Header: "Book Value", checked: true, child: false, single: true },
    ];
  }

  componentDidMount() {
    this.setData();
  }

  setData = () => {
    this.setState({
      loading: false,
    });
  };

  changeRows = (c) => {
    this.forceUpdate();
  };

  onTableCellClick = (row) => {
    return;
    if (row.hasOwnProperty("expanded")) {
      row.expanded = !row.expanded;
      this.forceUpdate();
    }
  };

  onChangeRefBase = (e) => {
    this.props.Overview.refBase.Value = e.target.value;
    this.props.Overview.refBase.Save();
    this.props.Overview.refBaseKey = Object.keys(RefBaseTypes).find(
      (key) => RefBaseTypes[key].value === e.target.value,
    );
    this.forceUpdate();
  };

  TableHead = () => {
    return (
      <React.Fragment>
        <TableRow className={`psfco_overview_table_header psfco_overview_main_header`}>
          <TableCell
            onClick={() => this.onTableCellClick()}
            rowSpan={2}
            component="th"
            scope="row"
            className={`psfco_overview_table_cell psfco_overview_hide_b`}
          >
            <ShowHideMenu
              className="spsvs_show_hide"
              isValuation={true}
              changeColumns={this.changeRows}
              getHeaders={this.headers}
              name={"Valuation Method"}
            />
          </TableCell>
          <TableCell
            onClick={() => this.onTableCellClick()}
            align="center"
            colSpan={3}
            component="th"
            scope="row"
            className={`psfco_overview_table_cell `}
          >
            Multiple
          </TableCell>
          <TableCell
            onClick={() => this.onTableCellClick()}
            rowSpan={2}
            component="th"
            scope="row"
            className={`psfco_overview_table_cell psfco_ref_base`}
          >
            <div className="psfco_ref_base_div">
              <span className="psfco_span_ref_b">Reference Base</span>
              <MaterialSelect
                onChange={this.onChangeRefBase}
                options={Object.values(RefBaseTypes).map((v) =>
                  Object.assign({ description: v.key, value: v.value }),
                )}
                value={this.props.Overview.refBase.Value}
              />
            </div>
          </TableCell>
        </TableRow>
        <TableRow className={`psfco_overview_table_header`}>
          <TableCell component="th" scope="row" className={`psfco_overview_table_cell `}>
            Median
          </TableCell>
          <TableCell component="th" scope="row" className={`psfco_overview_table_cell `}>
            Minimum
          </TableCell>
          <TableCell component="th" scope="row" className={`psfco_overview_table_cell `}>
            Maximum
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  getAllCheck = (indexes) => {
    return indexes.every((i) => this.headers[i].checked);
  };

  cellInputMx = (row, field) => {
    return (
      <TableCell align="right" component="th" scope="row" className={`psfco_overview_table_cell`}>
        <Mx_NumInput
          isDialogInput={true}
          singleInput={true}
          selected={true}
          disabled={false}
          boldRow={false}
          isMultipleSelected={false}
          isEditable={false}
          key={"p_overview_tc_m"}
          // onChange={(e) => this.saveValue(e,row.Revenue)}
          unit={row[field] && row[field].UnitParsed}
          data={row[field] && row[field].Value}
        />
      </TableCell>
    );
  };

  render() {
    if (this.state.loading) {
      return (
        <div className="loader_chart_container">
          <CircleLoader />
        </div>
      );
    }

    const { refBaseKey } = this.props.Overview;

    return (
      <div className="pfr_main_revenue_content">
        <div className="psfco_main_container">
          <div className="psfco_opening_overview" key="first_table">
            <ExportPortal>
              <ExportReport showExcelExport={false} />
            </ExportPortal>
            <Table className="psfco_overview_table" stickyHeader={true} id="report_table">
              <TableBody>
                {this.TableHead()}
                {this.headers[0].checked && (
                  <>
                    <TableRow className={`psfco_overview_table_header`}>
                      <TableCell
                        onClick={() => this.onTableCellClick()}
                        colSpan={5}
                        component="th"
                        scope="row"
                        className={`psfco_overview_table_cell bold`}
                      >
                        Discounted Cash Flow (DCF)
                      </TableCell>
                    </TableRow>
                    <TableRow className={`psfco_overview_table_header`}>
                      <TableCell
                        onClick={() => this.onTableCellClick()}
                        component="th"
                        scope="row"
                        className={`psfco_overview_table_cell `}
                      >
                        Terminal Growth Rate
                      </TableCell>
                      <TableCell
                        onClick={() => this.onTableCellClick()}
                        component="th"
                        scope="row"
                        className={`psfco_overview_table_cell `}
                      >
                        3%
                      </TableCell>
                      <TableCell
                        onClick={() => this.onTableCellClick()}
                        component="th"
                        scope="row"
                        className={`psfco_overview_table_cell `}
                      >
                        2%
                      </TableCell>
                      <TableCell
                        onClick={() => this.onTableCellClick()}
                        component="th"
                        scope="row"
                        className={`psfco_overview_table_cell `}
                      >
                        5%
                      </TableCell>
                      <TableCell
                        onClick={() => this.onTableCellClick()}
                        component="th"
                        scope="row"
                        className={`psfco_overview_table_cell `}
                      >
                        -
                      </TableCell>
                    </TableRow>
                  </>
                )}

                {(this.headers[1].checked ||
                  this.headers[2].checked ||
                  this.headers[3].checked ||
                  this.headers[4].checked) && (
                  <>
                    <TableRow className={`psfco_overview_table_header`}>
                      <TableCell
                        onClick={() => this.onTableCellClick()}
                        colSpan={5}
                        component="th"
                        scope="row"
                        className={`psfco_overview_table_cell bold`}
                      >
                        Trading Multiples Valuation
                      </TableCell>
                    </TableRow>

                    {this.headers[2].checked && (
                      <TableRow className={`psfco_overview_table_header`}>
                        <TableCell
                          onClick={() => this.onTableCellClick()}
                          component="th"
                          scope="row"
                          className={`psfco_overview_table_cell `}
                        >
                          EV / Revenue
                        </TableCell>
                        {this.cellInputMx(this.props.tradingMultiples[0], "RevenueMultiple")}
                        {this.cellInputMx(this.props.tradingMultiples[2], "RevenueMultiple")}
                        {this.cellInputMx(this.props.tradingMultiples[3], "RevenueMultiple")}
                        {this.cellInputMx(
                          this.props.ReferenceBaseObjectValues["Revenue"],
                          refBaseKey,
                        )}
                      </TableRow>
                    )}

                    {this.headers[3].checked && (
                      <TableRow className={`psfco_overview_table_header`}>
                        <TableCell
                          onClick={() => this.onTableCellClick()}
                          component="th"
                          scope="row"
                          className={`psfco_overview_table_cell `}
                        >
                          EV / EBITDA
                        </TableCell>
                        {this.cellInputMx(this.props.tradingMultiples[0], "EBITDAMultiple")}
                        {this.cellInputMx(this.props.tradingMultiples[2], "EBITDAMultiple")}
                        {this.cellInputMx(this.props.tradingMultiples[3], "EBITDAMultiple")}
                        {this.cellInputMx(
                          this.props.ReferenceBaseObjectValues["EBITDA"],
                          refBaseKey,
                        )}
                      </TableRow>
                    )}

                    {this.headers[4].checked && (
                      <TableRow className={`psfco_overview_table_header`}>
                        <TableCell
                          onClick={() => this.onTableCellClick()}
                          component="th"
                          scope="row"
                          className={`psfco_overview_table_cell `}
                        >
                          P / E
                        </TableCell>

                        {this.cellInputMx(this.props.tradingMultiples[0], "PEMultiple")}
                        {this.cellInputMx(this.props.tradingMultiples[2], "PEMultiple")}
                        {this.cellInputMx(this.props.tradingMultiples[3], "PEMultiple")}
                        {this.cellInputMx(
                          this.props.ReferenceBaseObjectValues["NetIncome"],
                          refBaseKey,
                        )}
                      </TableRow>
                    )}
                  </>
                )}

                {(this.headers[5].checked ||
                  this.headers[6].checked ||
                  this.headers[7].checked ||
                  this.headers[8].checked) && (
                  <>
                    <TableRow className={`psfco_overview_table_header`}>
                      <TableCell
                        onClick={() => this.onTableCellClick()}
                        colSpan={5}
                        component="th"
                        scope="row"
                        className={`psfco_overview_table_cell bold `}
                      >
                        Transaction Multiples Valuation
                      </TableCell>
                    </TableRow>
                    {this.headers[6].checked && (
                      <TableRow className={`psfco_overview_table_header`}>
                        <TableCell
                          onClick={() => this.onTableCellClick()}
                          component="th"
                          scope="row"
                          className={`psfco_overview_table_cell `}
                        >
                          EV / Revenue
                        </TableCell>
                        {this.cellInputMx(this.props.transactionMultiples[0], "RevenueMultiple")}
                        {this.cellInputMx(this.props.transactionMultiples[2], "RevenueMultiple")}
                        {this.cellInputMx(this.props.transactionMultiples[3], "RevenueMultiple")}
                        {this.cellInputMx(
                          this.props.ReferenceBaseObjectValues["Revenue"],
                          refBaseKey,
                        )}
                      </TableRow>
                    )}

                    {this.headers[7].checked && (
                      <TableRow className={`psfco_overview_table_header`}>
                        <TableCell
                          onClick={() => this.onTableCellClick()}
                          component="th"
                          scope="row"
                          className={`psfco_overview_table_cell `}
                        >
                          EV / EBITDA
                        </TableCell>

                        {this.cellInputMx(this.props.transactionMultiples[0], "EBITDAMultiple")}
                        {this.cellInputMx(this.props.transactionMultiples[2], "EBITDAMultiple")}
                        {this.cellInputMx(this.props.transactionMultiples[3], "EBITDAMultiple")}
                        {this.cellInputMx(
                          this.props.ReferenceBaseObjectValues["EBITDA"],
                          refBaseKey,
                        )}
                      </TableRow>
                    )}
                    {this.headers[8].checked && (
                      <TableRow className={`psfco_overview_table_header`}>
                        <TableCell
                          onClick={() => this.onTableCellClick()}
                          component="th"
                          scope="row"
                          className={`psfco_overview_table_cell `}
                        >
                          P / E
                        </TableCell>

                        {this.cellInputMx(this.props.transactionMultiples[0], "EBITDAMultiple")}
                        {this.cellInputMx(this.props.transactionMultiples[2], "PEMultiple")}
                        {this.cellInputMx(this.props.transactionMultiples[3], "PEMultiple")}
                        {this.cellInputMx(
                          this.props.ReferenceBaseObjectValues["NetIncome"],
                          refBaseKey,
                        )}
                      </TableRow>
                    )}
                  </>
                )}

                {this.headers[9].checked && (
                  <>
                    <TableRow className={`psfco_overview_table_header`}>
                      <TableCell
                        onClick={() => this.onTableCellClick()}
                        colSpan={5}
                        component="th"
                        scope="row"
                        className={`psfco_overview_table_cell bold`}
                      >
                        Book Value
                      </TableCell>
                    </TableRow>
                    <TableRow className={`psfco_overview_table_header`}>
                      <TableCell
                        onClick={() => this.onTableCellClick()}
                        component="th"
                        scope="row"
                        className={`psfco_overview_table_cell `}
                      >
                        Book Value
                      </TableCell>
                      <TableCell
                        onClick={() => this.onTableCellClick()}
                        component="th"
                        scope="row"
                        className={`psfco_overview_table_cell `}
                      >
                        -
                      </TableCell>
                      <TableCell
                        onClick={() => this.onTableCellClick()}
                        component="th"
                        scope="row"
                        className={`psfco_overview_table_cell `}
                      >
                        0.5x
                      </TableCell>
                      <TableCell
                        onClick={() => this.onTableCellClick()}
                        component="th"
                        scope="row"
                        className={`psfco_overview_table_cell `}
                      >
                        2.0x
                      </TableCell>
                      {this.cellInputMx(
                        this.props.ReferenceBaseObjectValues["BookValue"],
                        refBaseKey === "TTM" ? "TTMBook" : refBaseKey,
                      )}
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    );
  }
}

export default CompanyTable;
