import MxDataArray from "../../../MxDataArray";
import TransactionsMultiples from "./index";

class TransactionsMultiplesStorage extends MxDataArray {
  dataManager = null;
  driverStorage = null;
  loaded = false;

  setData = (callBack, data = this.data) => {
    this.pushArray(
      data.map((item) => {
        const newItem = new TransactionsMultiples(item);
        if (newItem.Revenue) {
          return newItem;
        } else {
          // this.dataManager.del(this.dataManager.Tables.Valuation_Transactions_Multiples.TableName, {ID: newItem.ID}, () => {
          //     console.log('deleting', newItem);
          //
          // }, () => {
          // });
        }
      }),
    );
    this.loaded = true;
    callBack();
  };

  load = (callBack, shouldFinish = true) => {
    this.clear();
    this.dataManager.get(
      this.dataManager.Tables.Valuation_Transactions_Multiples.TableName,
      { ID_CompanyScenario: this.dataManager.CompanyScenarioInfo.ID },
      (data) => {
        if (shouldFinish) {
          this.setData(callBack, data);
        } else {
          this.data = data;
          callBack();
        }
        TransactionsMultiples.tmpTotalsTransactionMultiples(() => {});
      },
      null,
      false,
    );
  };

  static create = () => {
    const newArr = new TransactionsMultiplesStorage();
    newArr.pushOld = newArr.push;
    newArr.push = newArr.pushThis;

    return newArr;
  };
}

export default TransactionsMultiplesStorage;
