import PropTypes from "prop-types";
import React, { Component } from "react";
import Rect from "./Rect/index";
import { centerToTL, tLToCenter, getNewStyle, degToRadian } from "./utils";

export default class ResizableRect extends Component {
  static propTypes = {
    left: PropTypes.number.isRequired,
    top: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    rotatable: PropTypes.bool,
    moveHandle: PropTypes.bool,
    rotateAngle: PropTypes.number,
    parentRotateAngle: PropTypes.number,
    scaleX: PropTypes.number,
    scaleY: PropTypes.number,
    zIndex: PropTypes.number,
    zoomable: PropTypes.string,
    minWidth: PropTypes.number,
    minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    aspectRatio: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    onDoubleClick: PropTypes.func,
    onRotateStart: PropTypes.func,
    onRotate: PropTypes.func,
    onRotateEnd: PropTypes.func,
    onResizeStart: PropTypes.func,
    onResize: PropTypes.func,
    onResizeEnd: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrag: PropTypes.func,
    onDragEnd: PropTypes.func,
    onDelete: PropTypes.func,
    id: PropTypes.string,
    reference: PropTypes.any,
    fullWidth: PropTypes.bool,
  };
  static defaultProps = {
    parentRotateAngle: 0,
    rotateAngle: 0,
    rotatable: true,
    moveHandle: false,
    zoomable: "",
    minWidth: 10,
    minHeight: 10,
    scaleX: 1,
    scaleY: 1,
    zIndex: 0,
  };

  constructor(props) {
    super(props);

    if (this.props.parentFunctionsObject) {
      this.props.parentFunctionsObject["handleResize"] = this.handleResize;
    }
  }

  handleRotate = (angle, startAngle) => {
    if (!this.props.onRotate) return;
    let rotateAngle = Math.round(startAngle + angle);
    if (rotateAngle >= 360) {
      rotateAngle -= 360;
    } else if (rotateAngle < 0) {
      rotateAngle += 360;
    }
    if (rotateAngle > 356 || rotateAngle < 4) {
      rotateAngle = 0;
    } else if (rotateAngle > 86 && rotateAngle < 94) {
      rotateAngle = 90;
    } else if (rotateAngle > 176 && rotateAngle < 184) {
      rotateAngle = 180;
    } else if (rotateAngle > 266 && rotateAngle < 274) {
      rotateAngle = 270;
    }
    this.props.onRotate(rotateAngle);
  };

  handleResize = (length, alpha, rect, type, isShiftKey) => {
    if (
      this.props.resizerFunctionsObject &&
      this.props.resizerFunctionsObject.hasOwnProperty("handleResize")
    ) {
      this.props.resizerFunctionsObject["handleResize"](length, alpha, rect, type, isShiftKey);
      return;
    }
    if (!this.props.onResize) return;
    const { rotateAngle, aspectRatio, minWidth, minHeight, parentRotateAngle, scaleX, scaleY } =
      this.props;
    const beta = alpha - degToRadian(rotateAngle + parentRotateAngle);
    const deltaW = length * Math.cos(beta);
    const deltaH = length * Math.sin(beta);
    const ratio = isShiftKey && !aspectRatio ? rect.width / rect.height : aspectRatio;
    const {
      position: { centerX, centerY },
      size: { width, height },
    } = getNewStyle(type, { ...rect, rotateAngle }, deltaW, deltaH, ratio, minWidth, minHeight);

    this.props.onResize(
      centerToTL({ centerX, centerY, width, height, rotateAngle }),
      isShiftKey,
      type,
    );
  };

  handleDrag = (deltaX, deltaY, elemLeft, elemTop) => {
    this.props.onDrag && this.props.onDrag(deltaX, deltaY, elemLeft, elemTop);
  };

  render() {
    const {
      top,
      left,
      width,
      height,
      rotateAngle,
      scaleX,
      scaleY,
      parentRotateAngle,
      zoomable,
      rotatable,
      moveHandle,
      onDoubleClick,
      onRotate,
      onResizeStart,
      onResizeEnd,
      onRotateStart,
      onRotateEnd,
      onDragStart,
      onDragEnd,
      onClick,
      zIndex,
      onDelete,
      publishMode,
      preview,
      id,
      borderMove,
      reference,
      minWidth,
      disableTopMoveHandle,
      fullWidth,
    } = this.props;

    const styles = tLToCenter({ top, left, width, height, rotateAngle, scaleX, scaleY, zIndex });

    return (
      <Rect
        borderMove={borderMove}
        styles={styles}
        zoomable={zoomable}
        rotatable={Boolean(rotatable && onRotate)}
        moveHandle={moveHandle}
        parentRotateAngle={parentRotateAngle}
        onDoubleClick={onDoubleClick}
        onClick={onClick}
        onResizeStart={onResizeStart}
        onResize={this.handleResize}
        onResizeEnd={onResizeEnd}
        onRotateStart={onRotateStart}
        onRotate={this.handleRotate}
        onRotateEnd={onRotateEnd}
        onDragStart={onDragStart}
        onDrag={this.handleDrag}
        onDragEnd={onDragEnd}
        publishMode={publishMode}
        preview={preview}
        onDelete={onDelete}
        className={`${fullWidth ? "full_width" : ""} ${this.props.className}`}
        id={id}
        reference={reference}
        minWidth={minWidth}
        disableTopMoveHandle={disableTopMoveHandle}
      >
        {this.props.children}
      </Rect>
    );
  }
}
