import React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Theme } from "../../../../theme/styles/theme";
import { Menu, MenuItem, SubMenu } from "@szhsin/react-menu";
import Button from "@mui/material/Button";
import "./barColorComponent.scss";
import { SketchPicker } from "react-color";

function BarColorComponent(props) {
  const [openColor, setOpenColor] = React.useState(false);

  const handleClickColor = () => {
    if (openColor) {
      setOpenColor(false);
    } else {
      setOpenColor(true);
      props.rightMenuFunc.closeColor = null;
      Object.values(props.rightMenuFunc).map((value) => {
        if (typeof value === "function") {
          value.call();
        }
      });
    }
  };

  const closeMenu = () => {
    setOpenColor(false);
  };

  props.rightMenuFunc.closeColor = closeMenu;

  let chartDatasets = props.getChartColorDatasets();

  const [currentCustomColor, setCurrentCustomColor] = React.useState(
    Theme.colors.chart.BelizeHole[500],
  );

  const changeColor = (key, value, color) => {
    setCurrentCustomColor(color);
  };

  return (
    <>
      <ListItemButton id={"listItem"} onClick={handleClickColor}>
        <ListItemText primary="Dataset Colors" />
        {openColor ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <div style={{ width: "230px", border: "1px solid #F0F1F7", marginLeft: "18px" }} />
      <Collapse in={openColor} timeout="auto" unmountOnExit>
        <div className={"collapse"}>
          <div className={"listSubHeading"}>Colors</div>
          {chartDatasets &&
            chartDatasets.length &&
            chartDatasets.map((dataSet) => {
              if (dataSet) {
                return (
                  <Menu
                    direction={"left"}
                    menuButton={
                      <Button sx={{ width: "150px", textTransform: "none", color: "black" }}>
                        {dataSet.label}
                      </Button>
                    }
                    transition
                  >
                    {props.hasCustomColor && (
                      <SubMenu direction={"left"} label={"Select Custom Color"}>
                        <SketchPicker
                          color={currentCustomColor}
                          disableAlpha
                          onChange={(event) => changeColor(dataSet.label, null, event.hex)}
                        />
                        <MenuItem
                          onClick={() =>
                            props.onChangeColor(dataSet.label, null, currentCustomColor)
                          }
                        >
                          Set Color
                        </MenuItem>
                      </SubMenu>
                    )}
                    <div className={"colors_container"}>
                      {Object.keys(Theme.colors.chart).map((c) => {
                        let colorKeys = Theme.colors.chart[c];
                        return (
                          <div className={"color_column"}>
                            {Object.keys(colorKeys).map((color) => {
                              return (
                                <MenuItem
                                  style={{ background: colorKeys[color] }}
                                  onClick={() => props.onChangeColor(dataSet.label, c, color)}
                                />
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                  </Menu>
                );
              }
            })}
        </div>
      </Collapse>
    </>
  );
}
export default BarColorComponent;
