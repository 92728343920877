import HeaderPortal from "./HeaderPortal";
import React, { Fragment, useState } from "react";
import "./HeaderDefault.scss";
import {
  Undo,
  Redo,
  Select,
  Separator,
  TextBox,
  FormatColor,
  FormatColorDisabled,
  Tables,
} from "../components/IconButtons/SubMenuIcons";
import InsertChartComponent from "../SlideHeader/EditorComponents/InsertChartComponent";
import InsertTableComponent from "../SlideHeader/EditorComponents/InsertTableComponent";
import InsertLineComponent from "../SlideHeader/EditorComponents/InsertLineComponent";
import InsertImageComponent from "../SlideHeader/EditorComponents/InsertImageComponent";
import InsertShapeComponent from "../SlideHeader/EditorComponents/InsertShapeComponent";
import { SlideObject, SlideTypes } from "../SlideObjects/SlideStructures";
import Tooltip from "@mui/material/Tooltip";
import InsertIconsMenu from "../components/menus/newTopMenu/InsertIconsMenu";
import html2canvas from "html2canvas";
import ButtonJ from "../components/Buttons/Button";
import { Menu, MenuItem, MenuButton, SubMenu } from "@szhsin/react-menu";
import ShareDialog from "../components/Dialog/ShareDialog";
import ScenarioMenu from "../../../components/menus/ScenarioMenu/ScenarioMenu";
import PrintablePortal from "../components/Portals/printablePortal";
import SlideComponent from "../SlideComponent";
import request from "superagent";
import { PermissionTypes } from "../../../components/constants/permissions";
import ThemesMenuComponent from "./ThemesComponents/ThemesMenuComponent";
import { tableDateType } from "../SlideObjects/FinanceTable/FinanceTable";
import { ReportsTableSizes, ReportsTypes } from "../../../data/Finance/Reports/constants";
import CircleLoader from "../../../components/Loaders/CircleLoader/CircleLoader";
import { Button } from "../../../components/actions/buttons/Button";
import {
  ReusableMenu,
  ReusableMenuItem,
} from "../../../components/menus/ReusableMenu/ReusableMenu";
import HeaderLeftPortal from "../components/Portals/HeaderLeftPortal";
import HeaderRightPortal from "../components/Portals/HeaderRightPortal";
import IconButton from "@mui/material/IconButton";
import ButtonMui from "../../../components/buttons/buttonMui/buttonMui";
import { ArrowDown } from "../../../components/icons/svgIcons";
import PitchHeaderButton from "../../../components/buttons/buttonMui/pitchHeaderButton";
import { Icon1 } from "../components/Icons/Icons";
import { AccessControl } from "../../../data/Permissions/AccessControl";
import InsertFinancialChartComponent from "./EditorComponents/InsertFinancialChartComponent";
import InsertFinancialTableComponent from "./EditorComponents/InsertFinancialTableComponent";
import CantShareDialog from "../../../components/dialogs/CantShareDialog/CantShareDialog";
import UpdatePlan from "../../../components/dialogs/LimitDialogs/UpdatePlan";

export default HeaderDefault;

function HeaderDefault(props) {
  const onChange = (style) => {
    props.onChange(style);
  };
  const documentTitle = document.title;

  const [printEnabled, setPrintEnabled] = React.useState(false);
  const [DefaultTemplates, setDefaultTemplates] = React.useState([]);
  const [openCantShare, setOpenCantShare] = React.useState(false);
  const [render, setRender] = React.useState(1);
  const [showUpgradeSubscription, setShowUpgradeSubscription] = React.useState(false);

  const onNewObject = (top, left) => {
    let s = {
      style: {
        lineHeight: "auto",
        height: "100%",
        width: "100%",
        fontFamily: "Inter",
        fontWeight: "300",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        textAlign: "left",
      },
      left: left,
      top: top,
      width: 450,
      height: 75,
    };
    const nObj = new SlideObject(SlideTypes.textObject, "Enter text here", s);
    props.onNewObject(nObj);
  };

  function closePrint() {
    document.body.removeChild(this.__container__);
  }

  function setPrint() {
    setTimeout(() => {
      this.contentWindow.__container__ = this;
      this.contentWindow.onbeforeunload = closePrint;
      this.contentWindow.onafterprint = closePrint;
      this.contentWindow.focus(); // Required for IE
      this.contentWindow.print();
    }, 1000);
  }

  function printPage(sURL) {
    const hideFrame = document.createElement("iframe");
    hideFrame.sandbox.add("allow-downloads");
    hideFrame.sandbox.add("allow-scripts");
    hideFrame.sandbox.add("allow-same-origin");
    hideFrame.sandbox.add("allow-modals");
    hideFrame.onload = setPrint;
    hideFrame.style.position = "fixed";
    hideFrame.style.right = "0";
    hideFrame.style.bottom = "0";
    hideFrame.style.width = "0";
    hideFrame.style.height = "0";
    hideFrame.style.border = "0";
    hideFrame.src = sURL;
    document.body.appendChild(hideFrame);
  }

  window.printElement = (element, className) => {
    const printContents = element.outerHTML;

    const printEl = document.getElementById("printableContainer");
    printEl.innerHTML = printContents;

    printEl.style.display = "block";

    if (className) {
      printEl.className = className;
    }

    document.body.className = "print";

    window.print();
    printEl.style.display = "none";
    printEl.innerHTML = "";
    printEl.className = "";
    document.body.className = "";
  };

  const afterPrintFunc = () => {
    document.title = documentTitle;
    window.iscurrentlyPrinting = false;
    props.afterPrint();
    window.removeEventListener("afterprint", afterPrintFunc);
  };

  const setInsertMode = (type, insertFunction, e) => {
    e.stopPropagation && e.stopPropagation();
    props.setInsertMode(type, insertFunction);
  };

  const pitchFinancialCharts = global.Modeliks.PitchFinancialCharts;
  return (
    <>
      {showUpgradeSubscription && (
        <UpdatePlan
          handleClose={() => setShowUpgradeSubscription(false)}
          onCancel={() => setShowUpgradeSubscription(false)}
          open
        />
      )}
      <HeaderPortal visible={true}>
        {/*<img src={image} alt="" style={{position:'absolute',top:'100px',left:'100px'}}/>*/}

        {!props.preview && (
          <HeaderLeftPortal>
            <PitchHeaderButton
              tooltipText={"Undo (Ctrl+Z)"}
              disabled={props.disableEdit || !props.canUndo}
              onClick={props.undo}
              label={<Undo />}
            />
            <PitchHeaderButton
              tooltipText={"Redo (Ctrl+Y)"}
              disabled={props.disableEdit || !props.canRedo}
              onClick={props.redo}
              label={<Redo />}
            />
            <div id={"format_painter"}>
              {/*{(props.selectedObject !== 'panel' && (props.selectedObject && props.selectedObject.type !== SlideTypes.textObject && props.selectedObject.type !== SlideTypes.tableObject)) &&*/}
              {/*<Tooltip disableHoverListener={props.disableEdit} arrow title={'Format Color (Ctrl+Y)'}>*/}
              {/*<span><IconButton disabled={props.disableEdit} onClick={props.handleActiveFormatPainter}>*/}
              {/*<FormatColor active={props.activeFormatPainter}/>*/}
              {/*</button></span>*/}
              {/*</Tooltip>}*/}
              {props.selectedObject == "panel" && (
                <PitchHeaderButton
                  tooltipText={"Format Painter"}
                  disabled={true}
                  label={<FormatColorDisabled />}
                />
              )}
            </div>
            <div
              className="sub_header_vertical_separator"
              onMouseMove={(e) => {
                e.stopPropagation();
              }}
            />

            <ThemesMenuComponent
              disableEdit={props.disableEdit}
              update={props.update}
              businessPlan={props.businessPlan}
              PitchScenarioInfo={props.PitchScenarioInfo}
              setCounter={props.setCounter}
              setFooter={props.setFooter}
              selectedTemplateColor={props.selectedTemplateColor}
              changeTemplateColor={props.changeTemplateColor}
              changeDeckColor={props.changeDeckColor}
              setLogo={props.setLogo}
            />
            {/*<Tooltip disableHoverListener={props.disableEdit} arrow title={'Select'}>*/}
            {/*<span><IconButton disabled={props.disableEdit}>*/}
            {/*<Select/>*/}
            {/*</button></span>*/}
            {/*</Tooltip>*/}

            <Menu
              style={{ width: "155px !important", minWidth: 0 }}
              className={`object_insert_menu ${global.Modeliks.isAdmin ? "admin" : ""}`}
              menuButton={
                <ButtonMui
                  disabled={props.disableEdit}
                  variant={"text"}
                  roundnessType={"squared"}
                  color={"black"}
                  label={"Insert"}
                />
              }
            >
              <Tooltip
                disableHoverListener={props.disableEdit}
                arrow
                title={"Text Box"}
                placement="right"
              >
                <span>
                  <MenuItem
                    className={"o_i_btn"}
                    disabled={props.disableEdit}
                    onClick={(e) => setInsertMode(SlideTypes.textObject, onNewObject, e)}
                  >
                    <TextBox
                      active={
                        (props.insertMode && props.insertMode.type === SlideTypes.textObject) ||
                        (props.selectedObject ? props.selectedObject.type === "textObject" : false)
                      }
                    />
                  </MenuItem>
                </span>
              </Tooltip>

              <InsertImageComponent
                disableEdit={props.disableEdit}
                newImage={true}
                handleUploadImage={props.onUploadImage}
              />
              <InsertShapeComponent
                disableEdit={props.disableEdit}
                onNewObject={props.onNewObject}
                setInsertMode={setInsertMode}
              />
              <InsertLineComponent
                disableEdit={props.disableEdit}
                onNewObject={props.onNewObject}
                handleNewScribble={props.handleNewScribble}
                setInsertMode={setInsertMode}
              />
              <InsertTableComponent
                tooltipLabel={"Insert Table"}
                disableEdit={props.disableEdit}
                onAddTable={props.onAddTable}
              />
              <InsertChartComponent
                disableEdit={props.disableEdit}
                onNewObject={props.onNewObject}
                setInsertMode={setInsertMode}
              />
              <InsertFinancialTableComponent
                disableEdit={props.disableEdit}
                onNewObject={props.onNewObject}
              />
              <InsertFinancialChartComponent
                disableEdit={props.disableEdit}
                onNewObject={props.onNewObject}
              />

              <InsertIconsMenu
                disableEdit={props.disableEdit}
                newIcon={true}
                onNewObject={props.onNewObject}
                setInsertMode={setInsertMode}
              />

              {global.Modeliks.isAdmin && (
                <>
                  <SubMenu
                    // menuButton={<IconButton className="sub_menu_button">finance table month</IconButton>}>
                    label={"finance table month"}
                  >
                    {Object.keys(ReportsTableSizes).map((r, i) => {
                      return Object.keys(ReportsTypes).map((key, index) => {
                        return (
                          <MenuItem
                            key={"mnth+" + i + index}
                            onClick={() => {
                              props.onNewObject(
                                new SlideObject(
                                  SlideTypes.financeTable,
                                  {
                                    dateType: tableDateType.month,
                                    reportType: ReportsTypes[key].key,
                                    tableSize: ReportsTableSizes[r].size,
                                  },
                                  { width: 600, height: 600, top: 480, left: 100 },
                                ),
                              );
                            }}
                          >
                            {key + " " + r}
                          </MenuItem>
                        );
                      });
                    })}
                  </SubMenu>
                  <SubMenu
                    // menuButton={<IconButton className="sub_menu_button">finance table year</IconButton>}>
                    label={"finance table year"}
                  >
                    {Object.keys(ReportsTableSizes).map((r, i) => {
                      return Object.keys(ReportsTypes).map((key, index) => {
                        return (
                          <MenuItem
                            key={"yrr+" + i + index}
                            onClick={() => {
                              props.onNewObject(
                                new SlideObject(
                                  SlideTypes.financeTable,
                                  {
                                    dateType: tableDateType.year,
                                    reportType: ReportsTypes[key].key,
                                    tableSize: ReportsTableSizes[r].size,
                                  },
                                  { width: 600, height: 600, top: 480, left: 100 },
                                ),
                              );
                            }}
                          >
                            {key + " " + r}
                          </MenuItem>
                        );
                      });
                    })}
                  </SubMenu>
                  {/*<ReusableMenu menuButton={<IconButton className="sub_menu_button">Financial Charts</button>} menuItems={pitchFinancialCharts.map(c => new ReusableMenuItem(c.Data.chartOptions.title, ()=>{props.onNewObject(new SlideObject(SlideTypes.financialChart, null, c))}))}/>*/}
                  <SubMenu
                    // menuButton={<IconButton className="sub_menu_button">Financial Charts</IconButton>}
                    label={"Financial Charts Monthly"}
                  >
                    {global.Modeliks.PitchFinancialChartsMonthly.map((c) => (
                      <MenuItem
                        onClick={() => {
                          props.onNewObject(
                            new SlideObject(SlideTypes.financialChart, null, {
                              ID: c.ID,
                              height: 400,
                              width: 800,
                              top: 280,
                              left: 100,
                            }),
                          );
                        }}
                      >
                        {c.Data.chartOptions.title}
                      </MenuItem>
                    ))}
                  </SubMenu>
                  <SubMenu
                    // menuButton={<IconButton className="sub_menu_button">Financial Charts</IconButton>}
                    label={"Financial Charts Yearly"}
                  >
                    {global.Modeliks.PitchFinancialChartsYearly.map((c) => (
                      <MenuItem
                        onClick={() => {
                          props.onNewObject(
                            new SlideObject(SlideTypes.financialChart, null, {
                              ID: c.ID,
                              height: 400,
                              width: 800,
                              top: 280,
                              left: 100,
                            }),
                          );
                        }}
                      >
                        {c.Data.chartOptions.title}
                      </MenuItem>
                    ))}
                  </SubMenu>
                </>
              )}
            </Menu>
            {/*do not delete please*/}
          </HeaderLeftPortal>
        )}

        <HeaderRightPortal>
          <AccessControl
            allowedPermissions={[
              {
                [props.businessPlan
                  ? global.Modeliks.PERMISSIONS.BusinessPlan.key
                  : global.Modeliks.PERMISSIONS.Pitch.key]: props.businessPlan
                  ? global.Modeliks.PERMISSIONS.BusinessPlan.restrictions.ExportPDF.key
                  : global.Modeliks.PERMISSIONS.Pitch.restrictions.ExportPDF.key,
              },
              {
                [props.businessPlan
                  ? global.Modeliks.PERMISSIONS.BusinessPlan.key
                  : global.Modeliks.PERMISSIONS.Pitch.key]: props.businessPlan
                  ? global.Modeliks.PERMISSIONS.BusinessPlan.restrictions.ShareLink.key
                  : global.Modeliks.PERMISSIONS.Pitch.restrictions.ShareLink.key,
              },
            ]}
            renderNoAccess={
              <ButtonMui
                onClick={(e) => {
                  setShowUpgradeSubscription(true);
                }}
                variant={"contained"}
                label={"Share"}
                color={"grey"}
                endIcon={<ArrowDown />}
              />
            }
          >
            {props.canShare && (
              <Menu
                className={"hd_rc_share_menu"}
                // menuButton={<IconButton onClick={() => {props.saveContent && props.saveContent(false, () => {})}} disabled={global.Modeliks.UserPermissions[PermissionTypes.Pitch] == 0} className={'hd_rc_share_container'}>
                menuButton={
                  <ButtonMui
                    disabled={props.disableEdit}
                    onClick={(e) => {
                      props.saveContent && props.saveContent(false, () => {});
                    }}
                    variant={"contained"}
                    label={"Share"}
                    endIcon={<ArrowDown />}
                  />
                }
                transition
              >
                <ShareDialog
                  ScenarioID={props.ScenarioID}
                  type={props.type}
                  saveContent={props.saveContent}
                  ScenarioName={props.ScenarioName}
                  ScenarioNamePrefix={props.ScenarioNamePrefix}
                  openFreeTrialDialog={props.openFreeTrialDialog}
                  businessPlan={props.businessPlan}
                />
                <MenuItem
                  onClick={(e) => {
                    if (props.businessPlan) {
                      props.exportToPdf();
                    } else {
                      request
                        .get(`/api/checkIfFreeTrial`)
                        .set("authorization", "Bearer " + window.localStorage.getItem("token"))
                        .then((res) => {
                          if (res.body.isFreeTrial) {
                            props.openFreeTrialDialog();
                          } else {
                            window.iscurrentlyPrinting = true;
                            window.addEventListener("afterprint", afterPrintFunc);
                            props.beforeSlidePrint(() => {
                              document.title =
                                props.documentTitle ??
                                `${global.Modeliks.CompanyInfo.CompanyName} Pitch Deck`;
                              window.print();
                            });
                          }
                        });
                    }
                  }}
                  className={"hd_rc_sm_menu_item"}
                >
                  Export to PDF
                </MenuItem>
              </Menu>
            )}
            {!props.canShare && (
              <ButtonMui
                disabled={props.disableEdit}
                onClick={(e) => {
                  setOpenCantShare(true);
                }}
                variant={"contained"}
                label={"Share"}
                endIcon={<ArrowDown />}
              />
            )}
          </AccessControl>
        </HeaderRightPortal>
      </HeaderPortal>
      <CantShareDialog
        open={openCantShare}
        businessPlan={props.businessPlan}
        handleClose={() => {
          setOpenCantShare(false);
        }}
      />
    </>
  );
}
