import React, { Component, Fragment } from "react";
import { SlideObject, SlideTypes } from "../SlideEditor/components/SlideObjects/SlideStructures";
import "./ContentPanelBusinessPlan.scss";
import {
  AlignCenter,
  AlignItemBottom,
  AlignItemLeft,
  AlignItemMiddle,
  AlignItemTop,
  AlignRight,
  Copy,
  Cut,
  DistributeHorizontally,
  DistributeVertically,
  Paste,
} from "../SlideEditor/components/components/IconButtons/SubMenuIcons";
import OrderSubMenu from "../SlideEditor/components/components/menus/newContextMenu/OrderSubMenu";
import ResizableRect from "../SlideEditor/components/ResizableRect/index";
import { MenuItem, SubMenu } from "@szhsin/react-menu";
import SCContextMenuPortal from "../SlideEditor/components/components/menus/newContextMenu/SCContextMenuPortal";
import ContextMenu from "../SlideEditor/components/components/menus/newContextMenu/ContextMenu";
import RotateSubMenu from "../SlideEditor/components/components/menus/newContextMenu/RotateSubMenu";
import ResizableLineRect from "../SlideEditor/components/LineComponents/LineRect/index.";

class ContentPanelBusinessPlan extends Component {
  subscribedToMoveEvent = false;
  th_x = 0;
  th_y = 0;

  constructor(props) {
    super(props);
    this.slideObj = props.slideObject;

    this.slideObj.props.updateSlideProps = this.props.updateSlideProps;
    this.state = {
      anchorPoint: { x: 0, y: 0 },
      menuProps: undefined,
      width: "100%",
      height: "auto",
      top: 0,
      left: 0,
      scaleX: 1,
      scaleY: 1,
      rotateAngle: 0,
      activeHorizontal: null,
      activeVertical: null,
      activeFrame: null,
      lockedAspect: this.slideObj.type === SlideTypes.imageObject ? true : false,
      minHeight: false,
      formatOptions: true,
      crop: false,
    };

    this.preResizeHeight = null;

    this.startWidth = null;
    this.startHeight = null;
    this.startRotateAngle = null;

    this.startTop = null;
    this.startLeft = null;

    this.slideObj.move = this.multipleObjectMove;
    this.slideObj.moveEnd = this.handleDragEnd;
    this.slideObj.moveStart = this.dragStart;
    this.slideObj.updatePosition = this.updatePosition;
    this.slideObj.changeTopLeft = this.changeTopLeft;
    this.slideObj.changeWidthHeight = this.changeWidthHeight;

    Object.assign(this.state, this.slideObj.props);
    // if (this.state.height == 'auto') {
    //     this.state.height = 50;
    // }

    this.slideObj.height = this.state.height;
    this.group = {};

    this.dragging = false;
    this.resizing = false;
    this.rotating = false;
    this.elRef = null;

    this.last_left = this.state.left;
    this.last_top = this.state.top;
  }

  changeLockedAspect = () => {
    this.setState({ lockedAspect: !this.state.lockedAspect });
  };

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    if(nextProps.selected != this.props.selected){
      if(nextProps.selected){
        this.setState({formatOptions: true});
      }
    }
  }

  handleResize = (style, isShiftKey, type) => {
    // type is a string and it shows which resize-handler you clicked
    // e.g. if you clicked top-right handler, then type is 'tr'
    let { height } = style;
    height = Math.round(height);
    const newProps = {
      height,
    };

    if (height > 0) {
      this.slideObj.updateProps(newProps, "props", false);
      this.setState(newProps);
      if (this.props.selected == false) {
        this.props.onClick();
      }
    }
  };

  handleResizeLine = (style) => {
    let { top, left, width, rotateAngle, deltaX, deltaY } = style;
    const newProps = {
      top,
      left,
      width,
      rotateAngle,
      deltaX,
      deltaY,
    };
    this.slideObj.updateProps(newProps, "props", false);
    this.setState(newProps);
    if (this.props.selected == false) {
      this.props.onClick();
    }
  };

  handleChildResize = (width, height) => {
    if (width && height) {
      const newProps = {
        width,
        height,
      };
      this.setState(newProps);
      this.slideObj.updateProps(newProps, "props", false);
    } else {
      this.updateHeight();
    }
  };

  handleRotate = (rotateAngle) => {
    const newProps = {
      rotateAngle,
    };
    this.setState(newProps);
    this.slideObj.updateProps(newProps);

    if (this.props.selected == false) {
      this.props.onClick();
    }
  };

  checkIfSlideObjects = (text) => {
    if (
      text.includes("cutObjects") ||
      text.includes("cutObject") ||
      text.includes("copyObjects") ||
      text.includes("copyObject")
    ) {
      try {
        JSON.parse(text);
        return true;
      } catch (e) {
        return false;
      }
    } else {
      return false;
    }
  };

  checkBoundries_X = (pX) => {
    const b_x = this.props.boundries.x;
    for (const x of b_x) {
      if (!(x.objKey == undefined)) {
        if (!(x.objKey == this.slideObj.key) && x.xmin <= pX && x.xmax >= pX) {
          return x;
        }
      } else {
        if (x.xmin <= pX && x.xmax >= pX) return x;
      }
    }
  };

  checkBoundries_Y = (pY) => {
    const b_y = this.props.boundries.y;
    for (const y of b_y) {
      if (!(y.objKey == undefined)) {
        if (!(y.objKey == this.slideObj.key) && y.ymin <= pY && y.ymax >= pY) {
          return y;
        }
      } else {
        if (y.ymin <= pY && y.ymax >= pY) return y;
      }
    }
    return null;
  };

  handleDrag = (deltaX, deltaY, elemLeft, elemTop, multiple = false) => {
    let left = deltaX / window.panelScale;
    let top = deltaY / window.panelScale;
    const m_x = left - this.last_left;
    const m_y = top - this.last_top;

    const active = { activeHorizontal: null, activeVertical: null, activeFrame: null };
    if (!multiple) {
      const b_x = this.checkBoundries_X(left);
      let b_w = null;
      if (b_x == null) {
        b_w = this.checkBoundries_X(left + this.state.width);
      }

      if (b_x) {
        left = b_x.x;
        Object.assign(active, b_x.active);
        this.th_x += m_x;
      } else if (b_w) {
        left = b_w.x - this.state.width;
        Object.assign(active, b_w.active);
        this.th_x += m_x;
      } else if (this.th_x != 0) {
        left = this.state.left;
        this.th_x = 0;
      }

      const b_y = this.checkBoundries_Y(top);
      let b_h = null;
      if (b_y == null) {
        b_h = this.checkBoundries_Y(top + this.state.height);
      }

      if (b_y) {
        top = b_y.y;
        Object.assign(active, b_y.active);
        this.th_y += m_y;
      } else if (b_h) {
        top = b_h.y - this.state.height;
        Object.assign(active, b_h.active);
        this.th_y += m_y;
      } else if (this.th_y != 0) {
        top = this.state.top;
        this.th_y = 0;
      }
      this.last_left = left;
      this.last_top = top;
      const newProps = {
        left: left,
        top: top,
        ...active,
      };
      this.setState(newProps);
      this.slideObj.updateProps(newProps, "props", false);
      // this.props.move();
    } else {
      this.props.handleDrag(left - this.state.left, top - this.state.top);
    }
  };

  handleResizeEnd = () => {
    this.preResizeHeight = null;
    this.resizing = false;
    global.slideHistory.pushNewChange(
      this.slideObj,
      "resize",
      {
        height: this.state.height,
        width: this.state.width,
        rotateAngle: this.state.rotateAngle,
      },
      {
        height: this.startHeight,
        width: this.startWidth,
        rotateAngle: this.startRotateAngle,
      },
    );
    this.startHeight = null;
    this.startWidth = null;
    this.startRotateAngle = null;
    // this.resizing = false;
    this.props.onPositionChanged(this.slideObj);
    if (
      this.slideObj.type == SlideTypes.calloutObject ||
      this.slideObj.type == SlideTypes.tableObject
    ) {
      this.forceUpdate();
    }
  };

  handleRotateEnd = () => {
    this.props.onPositionChanged(this.slideObj);
    this.rotating = false;
  };
  handleDragEnd = (multiple = false) => {
    this.dragging = false;
    this.props.onPositionChanged(this.slideObj);
    this.setState({ activeHorizontal: null, activeVertical: null, activeFrame: null });
    this.slideObj.updateProps(
      { activeHorizontal: null, activeVertical: null, activeFrame: null },
      "props",
      false,
    );
    if (!this.props.selected && !multiple) {
      this.props.onClick();
    }
    if (this.startTop !== this.state.top || this.startLeft !== this.state.left) {
      global.slideHistory.pushNewChange(
        this.slideObj,
        "position",
        { top: this.state.top, left: this.state.left },
        {
          top: this.startTop,
          left: this.startLeft,
        },
      );
      this.startTop = null;
      this.startLeft = null;
    }
  };

  updateSizeAndRotation = (width, height, rotateAngle, scaleX = 1, scaleY = 1) => {
    this.setState({
      width: width,
      height: height,
      rotateAngle: rotateAngle,
      scaleX: scaleX,
      scaleY: scaleY,
    });
    this.slideObj.updateProps(
      {
        width: width,
        height: height,
        rotateAngle: rotateAngle,
        scaleX: scaleX,
        scaleY: scaleY,
      },
      "props",
      true,
      () => {},
    );
    // this.props.resetPositioning();
    this.props.onPositionChanged(this.slideObj);
  };

  preventEventDefault = (e) => {
    e.preventDefault();
  };

  setHeight = () => {
    setTimeout(() => {
      let container = document.getElementById("se_scale_panel");
      let topOffset = 0;
      if (container) {
        topOffset = container.getBoundingClientRect().top;
      }
      const zoom = this.props.getZoom ? this.props.getZoom() : 1;
      let thisElement = document.getElementById(this.props.id);
      if (thisElement) {
        let { top, left, width, height } = thisElement.getBoundingClientRect();
        top -= topOffset;
        top *= 1 / (window.panelScale * zoom);
        height *= 1 / (window.panelScale * zoom);
        width = 2480 - 500;

        this.slideObj.updateProps(
          {
            top: top,
            left: 250,
            width: width,
            height: height,
          },
          "props",
          false,
        );
      }
    }, 1);
    document.removeEventListener("mouseup", this.setHeight);
  };

  componentDidMount = () => {
    document.addEventListener("contextmenu", this.preventEventDefault);

    this.setHeight();
  };

  changeTopLeft = (top, left, shouldUpdateProps = false) => {
    this.state.left = left;
    this.state.top = top;

    if (shouldUpdateProps) {
      this.slideObj.updateProps({ top, left });
    }

    this.forceUpdate();
  };

  changeWidthHeight = (width, height, rotateAngle) => {
    this.state.width = width;
    this.state.height = height;
    this.state.rotateAngle = rotateAngle;
    this.forceUpdate();
  };

  dragStart = (e) => {
    this.dragging = true;
    if (this.props.onDragStart) {
      this.props.onDragStart(e);
      document.addEventListener("mouseup", this.setHeight);
    }
  };

  updatePosition = (left, top) => {
    if (left + this.state.width > 1280) {
      left = 1280 - this.state.width;
    }
    if (top + this.state.height > 1080) {
      top = 1080 - this.state.height;
    }
    this.setState({ left: left, top: top });
    this.slideObj.updateProps({ left: left, top: top });
    this.props.resetPositioning();
    this.props.onPositionChanged(this.slideObj);
  };

  toggleFormatOptions = () => {
    if (!this.props.scale.rightMenu) {
      this.props.scale.resizeRight(null, false);
      if (!this.state.formatOptions) this.setState({ formatOptions: true });
    } else {
      this.setState({ formatOptions: !this.state.formatOptions });
    }
    // this.setState({formatOptions: !this.props.formatOptions})
  };

  onMinHeightChange = (val, borderWeight = 0) => {
    if (document.getElementById("se_scale_panel")) {
      this.setState({
        minHeight:
          (val * 1080) / document.getElementById("se_scale_panel").getBoundingClientRect().height +
          borderWeight * 2,
      });
    }
  };

  onHeightChange = (val, realValue = false, setMinHeight = false, callBack) => {
    this.setState(
      {
        height: realValue ? Number(val) : this.state.height + Number(val),
        minHeight: setMinHeight && realValue ? Number(val) : this.state.minHeight,
      },
      () => {
        this.slideObj.updateProps({ height: this.state.height }, "props", false);
        callBack && callBack();
      },
    );
  };

  onWidthChange = (val) => {
    this.setState({ width: this.state.width + Number(val) }, () => {
      this.slideObj.updateProps({ width: this.state.width }, "props", false);
    });
  };

  updateHeight = (height) => {
    if (this.dragging == false && this.elRef && this.elRef.clientHeight != this.state.height) {
      const newProps = { height: this.elRef.clientHeight };
      this.setState(newProps);
      this.slideObj.updateProps(newProps, "props", false);
    }
    if (height) {
      const newProps = { height: "auto" };
      this.setState(newProps);
      this.slideObj.updateProps(newProps, "props", false);
    }
  };

  setElementReference = (instance) => {
    if (instance != null && this.elRef == null && this.slideObj.props.height == "auto") {
      this.elRef = instance;
      this.updateHeight();
    } else if (instance != null) {
      this.elRef = instance;
    }
  };

  handleContextClick = (e) => {
    if (this.props.disableEdit) {
      return;
    }
    if (
      (this.props.multipleSelected && this.props.moreThanOneSelected) ||
      this.slideObj.type === SlideTypes.shapeObject ||
      this.slideObj.type === SlideTypes.chartObject ||
      this.slideObj.type === SlideTypes.newLineObject ||
      this.slideObj.type === SlideTypes.elbowConnectorObject ||
      this.slideObj.type === "tableObject" ||
      this.slideObj.type === SlideTypes.calloutObject
    ) {
      if (window.getSelection().toString()) {
        e.stopPropagation();
        e.showDefault = true;
      } else {
        e.stopPropagation();
        e.preventDefault();
        this.setState({
          menuProps: true,
          anchorPoint: { x: e.pageX, y: e.pageY },
        });
        if (
          !this.props.multipleSelected &&
          !this.props.moreThanOneSelected &&
          !this.props.selected
        ) {
          this.props.onClick();
        }
        this.forceUpdate();
      }
    }
  };
  handleContextClose = () => {
    this.setState({ anchorPoint: { x: 0, y: 0 }, menuProps: undefined });
    this.forceUpdate();
  };

  handleKeyDown = (e) => {
    if (e.key === "ArrowRight") {
      document.removeEventListener("keydown", this.handleKeyDown);
      this.slideObj.updateProps({ left: this.state.left + 1 }, "props", false);
      global.slideHistory.pushNewChange(
        this.slideObj,
        "position",
        {
          top: this.state.top,
          left: this.state.left + 1,
        },
        {
          top: this.state.top,
          left: this.state.left,
        },
      );
      this.state.left = this.state.left + 1;
      this.forceUpdate();
    } else if (e.key === "ArrowLeft") {
      document.removeEventListener("keydown", this.handleKeyDown);
      this.slideObj.updateProps({ left: this.state.left - 1 }, "props", false);
      global.slideHistory.pushNewChange(
        this.slideObj,
        "position",
        {
          top: this.state.top,
          left: this.state.left - 1,
        },
        {
          top: this.state.top,
          left: this.state.left,
        },
      );
      this.state.left = this.state.left - 1;
      this.forceUpdate();
    } else if (e.key === "ArrowUp") {
      document.removeEventListener("keydown", this.handleKeyDown);
      this.slideObj.updateProps({ top: this.state.top - 1 }, "props", false);
      global.slideHistory.pushNewChange(
        this.slideObj,
        "position",
        {
          top: this.state.top - 1,
          left: this.state.left,
        },
        {
          top: this.state.top,
          left: this.state.left,
        },
      );
      this.state.top = this.state.top - 1;
      this.forceUpdate();
    } else if (e.key === "ArrowDown") {
      document.removeEventListener("keydown", this.handleKeyDown);
      this.slideObj.updateProps({ top: this.state.top + 1 }, "props", false);
      global.slideHistory.pushNewChange(
        this.slideObj,
        "position",
        {
          top: this.state.top + 1,
          left: this.state.left,
        },
        {
          top: this.state.top,
          left: this.state.left,
        },
      );
      this.state.top = this.state.top + 1;
      this.forceUpdate();
    }
  };

  multipleObjectMove = (deltaX, deltaY) => {
    this.last_left = this.state.left + deltaX;
    this.last_top = this.state.top + deltaY;
    const newProps = {
      left: this.last_left,
      top: this.last_top,
    };
    this.setState(newProps);
    this.slideObj.updateProps(newProps, "props", false);
    this.props.move();
  };

  handleDragStart = () => {
    this.dragging = true;
  };

  onDrag = (deltaX, deltaY, elemLeft, elemTop) => {
    // if (this.slideObj.type === SlideTypes.GroupObject) {
    //     this.handleDrag(deltaX, deltaY, elemLeft, elemTop,  true)
    //     this.group.moves.forEach(obj => obj.move(deltaX, deltaY, elemLeft, elemTop, true))
    // } else {
    if (this.props.moreThanOneSelected && this.props.multipleSelected) {
      this.handleDrag(deltaX, deltaY, elemLeft, elemTop, true);
    } else {
      this.handleDrag(deltaX, deltaY, elemLeft, elemTop);
    }
  };

  handleCrop = (crop) => {
    this.setState({ crop: crop });
  };

  componentWillUnmount() {
    document.removeEventListener("contextmenu", this.preventEventDefault);
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  getHeight = () => {
    const { height } = this.state;

    if (
      this.slideObj.type === SlideTypes.financialChart ||
      this.slideObj.type === SlideTypes.chartObject
    ) {
      return height;
    }
    return "auto";
  };

  render() {
    if (this.props.selected || this.props.multipleSelected) {
      document.addEventListener("keydown", this.handleKeyDown);
    } else {
      document.removeEventListener("keydown", this.handleKeyDown);
    }
    const { width, top, left, height, rotateAngle, scaleX, scaleY, minHeight } = this.state;
    let className = "";
    let panelClass = "sp_cp_main_panel_bp";
    if (this.slideObj.type === SlideTypes.textObject) {
      className = "sp_cp_mainpanel_bp auto_height";
    } else if (this.slideObj.type !== SlideTypes.IconObject) {
      className = "sp_cp_mainpanel_bp";
    } else {
      className = "sp_cp_mainpanel_bp_centered";
    }
    let classNameLine = "";
    if ((this.props.selected || this.props.multipleSelected) && !this.state.crop) {
      className += " sp_cp_mainpanel_selected";
      classNameLine = " selected";
    }

    if (this.slideObj.type === SlideTypes.financeTable) {
      panelClass += " full_height";
    }

    if (this.slideObj.props.height == "auto" && this.slideObj.type !== "textObject") {
      className += " sp_cp_mainpanel_autoheight";
    }

    if (!this.props.publishMode) {
      className += " hover";
      classNameLine += " hover";
    }

    this.slideObjProps = {
      getDateRange: this.props.getDateRange,
      businessPlan: true,
      disableEdit: this.props.disableEdit,
      onPreview: this.props.onPreview,
      onMinHeightChange: this.onMinHeightChange,
      onWidthChange: this.onWidthChange,
      onHeightChange: this.onHeightChange,
      changeTopLeft: this.changeTopLeft,
      cutObjects: this.props.cutObjects,
      copyObjects: this.props.copyObjects,
      refreshPosition: this.refreshPosition,
      selected: this.props.selected,
      onChangedSize: this.handleChildResize,
      dragging: this.dragging,
      resizing: this.resizing,
      slideComponentPaste: this.props.slideComponentPaste,
      checkIfSlideObjects: this.checkIfSlideObjects,
      rotating: this.rotating,
      handleCopy: this.props.handleCopy,
      handleDuplicateObject: this.props.handleDuplicateObject,
      handleActiveFormatPainter: this.props.handleActiveFormatPainter,
      activeFormatPainter: this.props.activeFormatPainter,
      formatPainterStyle: this.props.formatPainterStyle,
      sentToFront: this.props.sentToFront,
      sentToBack: this.props.sentToBack,
      bringForword: this.props.bringForword,
      bringBackward: this.props.bringBackward,
      onContextMenu: this.handleContextClick,
      onDelete: this.props.onDelete,
      multipleSeleced: this.props.multipleSelected,
      moreThanOneSelected: this.props.moreThanOneSelected,
      rotateAngle: this.state.rotateAngle,
      scaleX: this.state.scaleX,
      scaleY: this.state.scaleY,
      height: this.state.height,
      width: this.state.width,
      onChange: this.updateSizeAndRotation,
      updatePosition: this.updatePosition,
      top: this.state.top,
      left: this.state.left,
      handleReplaceImage: this.props.handleReplaceImage,
      formatOptions: this.state.formatOptions,
      toggleFormatOptions: this.toggleFormatOptions,
      onSelect: () => this.props.onSelect(this.slideObj),
      onNewObject: this.props.onNewObject,
      onAddTable: this.props.onAddTable,
      onUploadImage: this.props.onUploadImage,
      selectedObject: this.props.selectedObject,
      onSave: this.props.onSave,
      onUpdateSizeAndRotation: this.updateSizeAndRotation,
      onUpdatePosition: this.updatePosition,
      lockedAspect: this.state.lockedAspect,
      updateHeight: this.updateHeight,
      handleNewScribble: this.props.handleNewScribble,
      changeLockedAspect: this.changeLockedAspect,
      scale: this.props.scale,
      group: this.group,
      publishMode: this.props.publishMode,
      parentRotateAngle: this.state.rotateAngle,
      preResizeHeight: this.preResizeHeight,
      preview: this.props.preview,
      addChartImage: this.props.addChartImage,
      className: classNameLine,
      slideObjKey: this.slideObj.key,
      setCrop: this.handleCrop,
      crop: this.state.crop,
    };
    return (
      <Fragment>
        {this.state.menuProps && (
          <SCContextMenuPortal>
            <ContextMenu
              handleClose={() => this.handleContextClose()}
              anchorPoint={this.state.anchorPoint}
              menuProps={this.state.menuProps}
            >
              <MenuItem className="cm_icon_helper" onClick={this.props.cutObjects}>
                <Cut className="cm_icon" />
                <span className="cm_btn_name">Cut</span>
                <span className="cm_helper_text">Ctrl+X</span>
              </MenuItem>
              <MenuItem className="cm_icon_helper" onClick={this.props.copyObjects}>
                <Copy className="cm_icon" />
                <span className="cm_btn_name">Copy</span>
                <span className="cm_helper_text">Ctrl+C</span>
              </MenuItem>
              {(this.slideObj.type === "shapeObject" ||
                this.slideObj.type === "lineObject" ||
                this.slideObj.type === "chartObject" ||
                this.slideObj.type == SlideTypes.calloutObject ||
                this.slideObj.type == SlideTypes.elbowConnectorObject ||
                this.slideObj.type == SlideTypes.newLineObject) && (
                <MenuItem className="cm_icon_helper" onClick={this.props.handleDuplicateObject}>
                  <Paste className="cm_icon" />
                  <span className="cm_btn_name">Duplicate</span>
                  <span className="cm_helper_text">Ctrl+D</span>
                </MenuItem>
              )}
              <MenuItem
                onClick={
                  this.props.moreThanOneSelected && this.props.multipleSelected
                    ? this.props.handleMultipleDelete
                    : this.props.onDelete
                }
              >
                <span className="cm_btn_name">Delete</span>
              </MenuItem>
              <div className="cm_divider" />
              {(this.slideObj.type === "shapeObject" ||
                this.slideObj.type === "lineObject" ||
                this.slideObj.type === "chartObject" ||
                this.slideObj.type == SlideTypes.calloutObject ||
                this.slideObj.type == SlideTypes.elbowConnectorObject ||
                this.slideObj.type == SlideTypes.newLineObject) && (
                <OrderSubMenu
                  sentToFront={this.props.sentToFront}
                  sentToBack={this.props.sentToBack}
                  bringForword={this.props.bringForword}
                  bringBackward={this.props.bringBackward}
                />
              )}
              <SubMenu label="Center on page">
                <MenuItem
                  onClick={() => this.updatePosition(640 - this.state.width / 2, this.state.top)}
                >
                  Horizontally
                </MenuItem>
                <MenuItem
                  onClick={() => this.updatePosition(this.state.left, 360 - this.state.height / 2)}
                >
                  Vertically
                </MenuItem>
              </SubMenu>
              <RotateSubMenu
                height={this.state.height}
                width={this.state.width}
                rotateAngle={this.state.rotateAngle}
                scaleX={this.state.scaleX}
                scaleY={this.state.scaleY}
                onChange={this.updateSizeAndRotation}
              />
              {this.slideObj.type === "lineObject" && (
                <SubMenu label="Change connector">
                  <MenuItem>Straight connector</MenuItem>
                  <MenuItem>Elbow connector</MenuItem>
                  <MenuItem>Curved connector</MenuItem>
                </SubMenu>
              )}
              {this.props.moreThanOneSelected && (
                <>
                  {/*<MenuItem onClick={this.props.groupObjects}><span className='cm_btn_name'>Group objects</span></MenuItem>*/}
                  {/*<MenuItem><span className='cm_btn_name'>Ungroup objects</span></MenuItem>*/}
                  <SubMenu className="cm_sub_align" label="Align horizontally">
                    <MenuItem
                      className="cm_icon_helper"
                      onClick={() => this.props.updatePosition(0, null)}
                    >
                      <AlignItemLeft className="cm_icon" />
                      <span className="cm_btn_name">Left</span>
                    </MenuItem>
                    <MenuItem
                      className="cm_icon_helper"
                      onClick={() => this.props.updatePosition(960, null)}
                    >
                      <AlignCenter className="cm_icon" />
                      <span className="cm_btn_name">Center</span>
                    </MenuItem>
                    <MenuItem
                      className="cm_icon_helper"
                      onClick={() => this.props.updatePosition(1280, null)}
                    >
                      <AlignRight className="cm_icon" />
                      <span className="cm_btn_name">Right</span>
                    </MenuItem>
                  </SubMenu>
                  <SubMenu className="cm_sub_align" label="Align vertically">
                    <MenuItem
                      className="cm_icon_helper"
                      onClick={() => this.props.updatePosition(null, 0)}
                    >
                      <AlignItemTop className="cm_icon" />
                      <span className="cm_btn_name">Top</span>
                    </MenuItem>
                    <MenuItem
                      className="cm_icon_helper"
                      onClick={() => this.props.updatePosition(null, 540)}
                    >
                      <AlignItemMiddle className="cm_icon" />
                      <span className="cm_btn_name">Middle</span>
                    </MenuItem>
                    <MenuItem
                      className="cm_icon_helper"
                      onClick={() => this.props.updatePosition(null, 1080)}
                    >
                      <AlignItemBottom className="cm_icon" />
                      <span className="cm_btn_name">Bottom</span>
                    </MenuItem>
                  </SubMenu>
                  <SubMenu className="cm_sub_align" label="Distribute">
                    <MenuItem className="cm_icon_helper" onClick={this.props.distributeH}>
                      <DistributeHorizontally className="cm_icon" />
                      <span className="cm_btn_name">Distribute horizontally</span>
                    </MenuItem>
                    <MenuItem className="cm_icon_helper" onClick={this.props.distributeV}>
                      <DistributeVertically className="cm_icon" />
                      <span className="cm_btn_name">Distribute vertically</span>
                    </MenuItem>
                  </SubMenu>
                </>
              )}
              <MenuItem onClick={() => this.toggleFormatOptions()}>
                <span className="cm_btn_name">Format options</span>
              </MenuItem>
            </ContextMenu>
          </SCContextMenuPortal>
        )}

        <ResizableRect
          left={left}
          top={top}
          width={1}
          fullWidth={true}
          height={this.getHeight()}
          rotateAngle={rotateAngle}
          borderMove
          disableTopMoveHandle
          moveHandle
          scaleX={scaleX}
          scaleY={scaleY}
          zIndex={this.props.zIndex}
          id={this.props.id}
          rotatable={false}
          aspectRatio={this.state.lockedAspect ? this.state.width / this.state.height : false}
          // minWidth={10}
          minHeight={this.state.minHeight ? this.state.minHeight : ""}
          //moveHandle={false}//this.slideObj.type == SlideTypes.htmlObject || this.slideObj.type == SlideTypes.textObject || this.slideObj.type == SlideTypes.tableObject || this.slideObj.type == SlideTypes.DataGridM}
          zoomable={
            this.slideObj.type === SlideTypes.financialChart ||
            this.slideObj.type === SlideTypes.chartObject
              ? "s"
              : ""
          } //'n, w, s, e, nw, ne, se, sw'
          onRotateStart={() => (this.rotating = true)}
          onRotate={this.handleRotate}
          onRotateEnd={() => this.handleRotateEnd()}
          onResizeStart={() => {
            this.preResizeHeight = this.state.height;
            this.resizing = true;
            if (this.startHeight === null && this.startHeight === null) {
              this.startHeight = this.state.height;
              this.startWidth = this.state.width;
              this.startRotateAngle = this.state.rotateAngle;
            }
          }}
          onResize={this.handleResize}
          onResizeEnd={() => this.handleResizeEnd()}
          onDragStart={this.dragStart}
          className={className}
          onClick={this.props.onClick}
          onDoubleClick={this.props.onClick}
          onDelete={this.props.onDelete}
          onKeyDown={this.handleKeyDown}
          publishMode={this.props.publishMode}
          preview={this.props.preview}
        >
          {document.getElementById("contextMenuContentPanel") && (
            <div id="contextMenuContentPanel"></div>
          )}
          <div
            className="sp_cp_childpanel"
            onClick={this.props.onClick}
            ref={this.setElementReference}
            onContextMenu={(e) => this.handleContextClick(e)}
            onKeyDown={this.handleKeyDown}
          >
            {this.slideObj.render(this.slideObjProps)}
          </div>
        </ResizableRect>
      </Fragment>
    );
  }
}

export default ContentPanelBusinessPlan;
