import React from "react";
import styled from "styled-components";
import { Theme } from "../.././theme/styles/theme";
import PropTypes from "prop-types";
import { Vector } from "../dividers/Vector";
import { Download } from "../icons/svgIcons";
import { Paragraph } from "../typography/paragraph/Paragraph";

const StyledHistoryCard = styled.div`
  width: 100%;
  display: flex;
  flexdirection: row;
  justifycontent: space-between;
  margin-top: 20px;
  margin-bottom: 8px;
`;

const P3 = styled.div`
  font-weight: 400;
  font-size: 14px;
  font-family: Inter;
`;

export const HistoryCard = ({
  date = "Dec 01, 2021",
  amount = "$50",
  receipt = true,
  ...props
}) => {
  return (
    <>
      <StyledHistoryCard>
        <P3 style={{ width: "100%" }}>{date}</P3>
        <P3 style={{ width: "68%" }}>{amount}</P3>
        {/*<P3 style={{width:'70%', textAlign:'right'}}><Download/></P3>*/}

        {/*<div style={{width: '100%', borderBottom: '1px solid #F0F1F7', marginTop:'8px'}}></div>*/}
        {/*<div style={{width:'100%',display:'flex',justifyContent:'flex-start'}}><Paragraph label={date} size={'p3'}/> </div>*/}
        {/*<div style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center',fontFamily:'Inter'}}>{amount}</div>*/}
        {/*{receipt&& <div style={{width:'100%', display:'flex', justifyContent:'flex-end', alignItems:'center'}}><Download/></div>}*/}
      </StyledHistoryCard>
      <div style={{ width: "100%", borderBottom: "1px solid #F0F1F7" }}></div>
    </>
  );
};

HistoryCard.propTypes = {
  date: PropTypes.string,
  amount: PropTypes.number,
  receipt: PropTypes.bool,
};
