import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Exit, AddRows, AddColumns, DeleteRow, DeleteCol } from "../IconButtons/SubMenuIcons";
import "./ChartTableStyle.scss";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { IconButton, Tooltip } from "@mui/material";
import ColorPicker from "./ColorPicker";
import PropTypes from "prop-types";
import MaterialSelect from "../Select/MaterialSelect";
import { DataTypes } from "../../SlideObjects/ChartComponents/DataTypes";
import { FontSizeOptions } from "../../SlideObjects/ChartComponents/FontSizeOptions";

class ChartTable extends React.Component {
  cellEl = null;

  constructor(props) {
    super(props);
    this.state = {
      colIndex: null,
      rowIndex: null,
      columns: [],
      dataType: "",
      title: "",
      subtitle: "",
      rows: [],
    };

    this.titleInput = React.createRef();
    this.subtitleInput = React.createRef();

    this.rowEl = null;
    this.colEl = null;

    this.cellEl = null;
  }

  componentDidMount() {
    this.buildData(false);
    this.setState({
      dataType: this.props.dataType,
      title: this.props.chartTitle,
      subtitle: this.props.chartSubtitle,
    });
  }

  focusTitle = () => {
    this.oldTitleValue = this.titleInput.current.value;
  };

  focusSubtitle = () => {
    this.oldSubtitleValue = this.subtitleInput.current.value;
  };

  blurTitle = () => {
    if (this.oldTitleValue && this.oldTitleValue != this.titleInput.current.value) {
      console.log("blur title");
      this.props.handleChartOptions({ title: this.titleInput.current.value }, true);
      this.oldTitleValue = null;
    }
  };

  blurSubtitle = () => {
    if (this.oldSubtitleValue && this.oldSubtitleValue != this.subtitleInput.current.value) {
      console.log("blur subtitle");
      this.props.handleChartOptions({ subtitle: this.subtitleInput.current.value }, true);
      this.oldSubtitleValue = null;
    }
  };

  handlePopoverOpen = (event, a, b) => {
    const field = event.currentTarget.dataset.field;
    const id = event.currentTarget.parentElement.dataset.id;
    const row = this.state.rows.find((r) => r.id === id);
    this.cellEl = event.currentTarget;

    const colIndex = Array.prototype.indexOf.call(
      this.cellEl.parentElement.childNodes,
      this.cellEl,
    );
    const rowIndex = event.currentTarget.parentElement.dataset.rowindex;

    if (colIndex != this.state.colIndex || rowIndex != this.state.rowIndex) {
      this.rowEl = event.currentTarget.parentElement;
      this.colEl = this.rowEl.parentElement.childNodes[0].childNodes[colIndex];
      this.setState({ colIndex, rowIndex });
      if (event.currentTarget.getElementsByTagName("input").length > 0) {
        event.currentTarget.getElementsByTagName("input")[0].focus();
      }
    }
  };

  handlePopoverClose = () => {
    this.rowEl = null;
    this.colEl = null;
  };

  handleDeleteRow = (e) => {
    if (this.state.rowIndex != 0 && this.props.canAddRows) {
      let rows = [...this.state.rows];
      rows.splice(this.state.rowIndex, 1);
      this.state.rows = rows;
      this.forceUpdate();
      this.props.changeData(rows);
    }
  };

  handleDeleteColumn = () => {
    if (
      (this.props.canAddColumns && this.state.colIndex != 0) ||
      (this.props.canAddColumns && this.state.colIndex == 0 && !this.props.hasColumnHeaders)
    ) {
      if (this.props.lastRowNotEditable && this.state.colIndex === this.state.columns.length - 1) {
      } else {
        let index = this.state.colIndex;
        let rows = [...this.state.rows];

        rows.forEach((row) => {
          row.splice(index, 1);
        });

        this.state.rows = rows;
        this.forceUpdate();
        this.buildData();
      }
    }
  };

  changeChartTitle = (e) => {
    this.setState({ title: e.target.value });
    this.props.handleChartOptions({ title: e.target.value }, false);
  };

  changeChartSubtitle = (e) => {
    this.setState({ subtitle: e.target.value });
    this.props.handleChartOptions({ subtitle: e.target.value }, false);
  };

  getColumnClassName(indexr, index, row) {
    let className = "";

    if (this.props.hasColumnHeaders) {
      if (indexr === 0) {
        className = "chart_datagrid_header";
      }
    }

    // if(this.props.lastRowNotEditable && indexr === row.length - 1){
    //     className = 'sum';
    // }

    return className;
  }

  buildData = (shouldPushChange = true) => {
    let rows = [];
    let columns = [];
    rows = this.props.data;

    rows.forEach((row, index) => {
      row.id = "row_" + new Date().getTime() + "_" + index;
      row.index = index;
      row.className = index === 0 ? "chart_datagrid_header" : "";
      // row.className += (this.props.lastRowNotEditable) && index === rows.length - 1 ? 'sum' : '';

      if (index == 0) {
        row.forEach((r, indexr) => {
          columns.push({
            className: this.getColumnClassName(indexr, index, row),
            index: indexr,
            field: indexr.toString(),
            width: 140,
            editable: true,
            renderCell: (row) => {
              if (
                this.props.lastRowNotEditable &&
                row.row.index === 0 &&
                parseInt(row.field) === row.row.length - 1
              ) {
                return row.value;
              } else if (
                this.props.rowSeries === true &&
                row.row.index == 0 &&
                this.props.hasColumnHeaders === false
              ) {
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    {" "}
                    {row.value}{" "}
                    <ColorPicker
                      changeColorAtIndex={this.props.changeColorAtIndex}
                      index={row.colDef.index}
                      series={true}
                      color={this.props.colors[row.colDef.index]}
                    />
                  </div>
                );
              } else if (
                this.props.rowSeries === true &&
                row.row.index == 0 &&
                row.colDef.index > 0
              ) {
                //render color picker
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    {row.value}{" "}
                    <ColorPicker
                      changeColorAtIndex={this.props.changeColorAtIndex}
                      index={row.colDef.index - 1}
                      series={true}
                      color={this.props.colors[row.colDef.index - 1]}
                    />
                  </div>
                );
              } else if (
                this.props.rowSeries === false &&
                row.colDef.index == 0 &&
                row.row.index > 0
              ) {
                //render color picker
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    {row.value}{" "}
                    <ColorPicker
                      changeColorAtIndex={this.props.changeColorAtIndex}
                      index={row.row.index - 1}
                      series={true}
                      color={this.props.colors[row.row.index - 1]}
                    />
                  </div>
                );
              }

              if (
                this.props.rowSeries === true &&
                row.row.index > 0 &&
                this.props.hasColumnHeaders === false
              ) {
                return row.value + DataTypes[this.state.dataType];
              } else if (
                this.props.rowSeries === true &&
                row.row.index > 0 &&
                row.colDef.index > 0
              ) {
                return row.value + DataTypes[this.state.dataType];
              } else if (
                this.props.rowSeries === false &&
                row.colDef.index > 0 &&
                row.row.index > 0
              ) {
                return row.value + DataTypes[this.state.dataType];
              } else {
                return row.value;
              }
            },
          });
        });
      }
    });
    this.state.rows = rows;
    this.state.columns = columns;
    this.forceUpdate();
    if (shouldPushChange) {
      this.props.changeData(this.state.rows);
    }
  };

  handleAddColumn = () => {
    if (this.state.colIndex != 0 && this.props.canAddColumns) {
      if (this.props.lastRowNotEditable) {
        this.state.columns[this.state.columns.length - 1].editable = true;
      }

      let rows = [...this.state.rows];
      let columns = [...this.state.columns];

      columns.push({
        field: rows[0].length.toString(),
        index: columns.length,
        width: 140,
        editable: true,
        renderCell: (row) => {
          if (
            this.props.rowSeries === true &&
            row.row.index == 0 &&
            this.props.hasColumnHeaders === false
          ) {
            return (
              <div style={{ display: "flex" }}>
                {row.value}{" "}
                <ColorPicker
                  changeColorAtIndex={this.props.changeColorAtIndex}
                  index={row.colDef.index}
                  series={true}
                  color={this.props.colors[row.colDef.index]}
                />
              </div>
            );
          } else if (this.props.rowSeries === true && row.row.index == 0 && row.colDef.index > 0) {
            //render color picker
            return (
              <div style={{ display: "flex" }}>
                {row.value}{" "}
                <ColorPicker
                  changeColorAtIndex={this.props.changeColorAtIndex}
                  index={row.colDef.index - 1}
                  series={true}
                  color={this.props.colors[row.colDef.index - 1]}
                />
              </div>
            );
          } else if (this.props.rowSeries === false && row.colDef.index == 0 && row.row.index > 0) {
            //render color picker
            return (
              <div style={{ display: "flex" }}>
                {row.value}{" "}
                <ColorPicker
                  changeColorAtIndex={this.props.changeColorAtIndex}
                  index={row.row.index - 1}
                  series={true}
                  color={this.props.colors[row.row.index - 1]}
                />
              </div>
            );
          }
          if (row.row.index > 0) {
            return row.value + DataTypes[this.state.dataType];
          } else {
            return row.value;
          }
        },
      });

      for (let i = 0; i < rows.length; i++) {
        if (i === 0) {
          rows[i].push(this.props.ColumnHeader);
        } else {
          rows[i].push("");
        }
      }

      // else {
      //     columns.splice(this.state.columns.length - 1, 0, {
      //         field: (rows[0].length - 1).toString(),
      //         index: columns.length,
      //         width: 140,
      //         editable: true,
      //         renderCell: (row) => {
      //             if (this.props.rowSeries === true && row.row.index == 0 && this.props.hasColumnHeaders === false) {
      //                 return <div style={{display: 'flex'}}>{row.value} <ColorPicker
      //                     changeColorAtIndex={this.props.changeColorAtIndex} index={row.colDef.index}
      //                     series={true} color={this.props.colors[row.colDef.index]}/></div>
      //
      //             }
      //             else if (this.props.rowSeries === true && row.row.index == 0 && row.colDef.index > 0) {
      //                 //render color picker
      //                 return <div style={{display: 'flex'}}>{row.value} <ColorPicker
      //                     changeColorAtIndex={this.props.changeColorAtIndex} index={row.colDef.index - 1}
      //                     series={true} color={this.props.colors[row.colDef.index - 1]}/></div>
      //
      //             }
      //             else if (this.props.rowSeries === false && row.colDef.index == 0 && row.row.index > 0) {
      //                 //render color picker
      //                 return <div style={{display: 'flex'}}>{row.value} <ColorPicker
      //                     changeColorAtIndex={this.props.changeColorAtIndex} index={row.row.index - 1}
      //                     series={true} color={this.props.colors[row.row.index - 1]}/></div>
      //
      //             }
      //             if (row.row.index > 0) {
      //                 return row.value + DataTypes[this.state.dataType];
      //             }
      //             else {
      //                 return row.value;
      //             }
      //
      //
      //         }
      //     })
      //
      //     for (let i = 0; i < rows.length; i++) {
      //         if (i === 0) {
      //             rows[i].splice(this.state.columns.length - 1, 0,this.props.ColumnHeader);
      //         }
      //         else {
      //             rows[i].splice(this.state.columns.length - 1, 0,'');
      //         }
      //     }
      // }

      this.state.columns = columns;
      this.state.rows = rows;
      this.forceUpdate();
      this.props.changeData(this.state.rows);
      // this.buildData()
    }
  };

  handleAddRow = () => {
    let lastRow = this.state.rows[this.state.rows.length - 1];
    let newRow = [];

    for (let i = 0; i < lastRow.length; i++) {
      if (i === 0) {
        newRow.push(this.props.RowHeader);
      } else {
        newRow.push("");
      }
    }

    newRow.id = "row_" + new Date().getTime();
    newRow.index = this.state.rows.length;

    this.state.rows = [...this.state.rows, newRow];

    this.forceUpdate();
    this.props.changeData(this.state.rows);
  };

  handleEditData = (a, b, c) => {
    let oldRows = JSON.parse(JSON.stringify(this.state.rows));
    let rows = [...this.state.rows];
    let rowIndex = -1;
    let fieldIndex = parseInt(a.field);
    rows.forEach((row, index) => {
      if (row.id === a.id) {
        rowIndex = index;
      }
    });

    rows[rowIndex][fieldIndex] = a.value;
    this.state.rows = rows;
    this.forceUpdate();
    this.props.changeData(rows, oldRows);
  };

  handleDataTypeChange = (e) => {
    this.props.handleChartOptions({ dataType: e.target.value });
    this.setState({ dataType: e.target.value });
  };

  componentWillUnmount() {
    this.blurTitle();
    this.blurSubtitle();
  }

  render() {
    return (
      <div
        style={{ width: "100%", height: "100%" }}
        onKeyDown={(e) => {
          e.stopPropagation();
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 30,
            marginLeft: 16,
            height: "40px",
          }}
        >
          <div
            id={"container"}
            onMouseDown={(e) => {
              e.stopPropagation();
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
          >
            <span id={"Title"}>Chart title</span>
            <input
              id={"OptionsInput"}
              ref={this.titleInput}
              onFocus={this.focusTitle}
              onBlur={this.blurTitle}
              type="text"
              value={this.state.title}
              placeholder={" Type"}
              onChange={this.changeChartTitle}
            />
          </div>

          <div
            id={"container"}
            onMouseDown={(e) => {
              e.stopPropagation();
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
          >
            <span id={"Title"}>Subtitle</span>
            <input
              id={"OptionsInput"}
              ref={this.subtitleInput}
              onFocus={this.focusSubtitle}
              onBlur={this.blurSubtitle}
              value={this.state.subtitle}
              type="text"
              placeholder={" Type"}
              onChange={this.changeChartSubtitle}
            />
          </div>

          <div id={"container"}>
            <label className={"listSubHeading"}>Data type: </label>
            <MaterialSelect
              value={this.props.dataType}
              options={[
                { value: "number", name: "#" },
                {
                  value: "percent",
                  name: "%",
                },
                { value: "currency", name: "$" },
              ]}
              onChange={this.handleDataTypeChange}
            />
          </div>

          <div id={"EndContainer"}>
            <div
              id={"add"}
              className={this.props.canAddRows ? "" : "disabled"}
              onClick={this.handleAddRow}
            >
              <span>Add Row</span>
              <AddRows />
            </div>
            <div
              id={"add"}
              className={this.props.canAddColumns ? "" : "disabled"}
              onClick={this.handleAddColumn}
            >
              <span>Add Column</span>
              <AddColumns />
            </div>
            <Exit onClick={this.props.handleTableOpen} />
          </div>
        </div>

        <DataGrid
          className={this.props.lastRowNotEditable ? "chart_table l_r_n_editable" : "chart_table"}
          onCellEditCommit={this.handleEditData}
          columns={this.state.columns}
          rows={this.state.rows}
          hideFooter={true}
          headerHeight={0}
          // rowsPerPageOptions={[5]}
          density={"compact"}
          // autoHeight
          autoWidth
          rowHeight={45}
          getCellClassName={(cellDef) => {
            if (cellDef.colDef.className) {
              return cellDef.colDef.className;
            } else if (cellDef.row.className) {
              return cellDef.row.className;
            }

            return null;
          }}
          style={{
            // height: this.state.rows.length < 6 ? this.state.rows.length < 3 ? this.state.rows.length * 41.0 : this.state.rows.length * 40.6  : 5*46,
            width:
              this.state.columns.length < 10
                ? this.state.columns.length < 4
                  ? this.state.columns.length * 140.9
                  : this.state.columns.length * 140.4
                : 9 * 140.4,
            // height:'auto',
            height: "65%",
            // maxHeight:'200px',
            maxWidth: "90%",
            marginLeft: 16,
            border: "1px solid #F0F1F7",
          }}
          isCellEditable={(cell) =>
            !(
              this.props.lastRowNotEditable &&
              parseInt(cell.field) === cell.row.length - 1 &&
              cell.row.index === 1
            )
          }
          componentsProps={{
            cell: {
              onMouseEnter: this.handlePopoverOpen,
              onMouseLeave: this.handlePopoverClose,
            },
          }}
        />

        <Popover
          className="column_pop_over"
          sx={{
            pointerEvents: "none",
          }}
          open={this.rowEl != null}
          anchorEl={this.rowEl}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          onClose={this.handlePopoverClose}
          disableAutoFocus
          disableEnforceFocus
          disableEscapeKeyDown
          disableRestoreFocus
        >
          <Tooltip title={"delete"} style={{ pointerEvents: "auto" }}>
            <IconButton size={"small"} onClick={this.handleDeleteRow}>
              <DeleteRow />
            </IconButton>
          </Tooltip>
        </Popover>

        <Popover
          className="column_pop_over"
          sx={{
            pointerEvents: "none",
          }}
          open={this.colEl != null}
          anchorEl={this.colEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          onClose={this.handlePopoverClose}
          disableAutoFocus
          disableEnforceFocus
          disableEscapeKeyDown
          disableRestoreFocus
        >
          <Tooltip title={"delete"} style={{ pointerEvents: "auto" }}>
            <IconButton size={"small"} onClick={this.handleDeleteColumn}>
              <DeleteCol />
            </IconButton>
          </Tooltip>
        </Popover>
      </div>
    );
  }
}

ChartTable.defaultProps = {
  RowHeader: "New series",
  ColumnHeader: "New label",
};
ChartTable.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  rows: PropTypes.array,
  canAddRows: PropTypes.bool,
  canAddColumns: PropTypes.bool,
  hasColumnHeaders: PropTypes.bool,
  hasRowHeaders: PropTypes.bool,
  colors: PropTypes.array,
  rowSeries: PropTypes.bool,
};

export default ChartTable;
