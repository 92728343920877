import * as React from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    IconButton,
    Tooltip,
} from "@mui/material";
import "./financetable.scss";
import PropTypes from "prop-types";
import {
    ArrowDown,
    Edit,
    Move,
    Copy,
    TrashCan2,
    DoubleArrowUp,
    DoubleArrowDown,
    ArrowUp,
} from "../../icons/svgIcons";
import {DeleteIcon} from "../../../SlideEditor/components/components/menus/LeftMenu/components/Icons/LeftMenuIcons";
import PortalApp from "../portal";
import {DriverCategories, UnitTypes} from "../../../data/Finance/CalculatedDriver/constants";
import {PeriodTypes} from "../../../data/dates";
import {InputFormats, maxRows} from "../../constants/finance";
import FinanceTableHeader from "./components/FinanceTableHeader";
import {FinanceTableHeadComparative} from "./components/FinanceTableHeadComparative";
import FinanceTableCell from "./FinanceTableCell";
import LocalStorage from "../../../helpers/LocalStorage";
import Mx_Tooltip_Hover from "../../icons/Mx_Tooltip_Hover";
import MxIDUseID from "../../../helpers/MxIUniqueD";
import {tableObject, tableConfig, tableConfigTypes} from "./components/constants";
import ButtonMui from "../../buttons/buttonMui/buttonMui";
import ButtonsActions from "../../dialogs/financials/AddDialog/ButtonsActions";

window.currentTable = null;
const validUnits = [".", "-", "+"];
const TableFields = {
    Value: "Value",
    Actual: "Actual",
};
window.getRealValue = (value) => {
    let newValueString = "";
    for (let i = 0; i < value.length; i++) {
        if (!isNaN(parseInt(value[i])) || validUnits.indexOf(value[i]) != -1) {
            newValueString += value[i];
        }
    }
    return newValueString;
};
const settingsTable = "t_exp_col_settings";
const settingsConfigTypes = {
    showSuccess: "showSuccess",
};

class FinanceTable extends React.Component {
    lastMouseSelectedCell = {colIndex: null, rowIndex: null};
    MaxLengthOfValue = 8;
    tableWidth = null;
    hoverEff = false;
    totalExpanded = 0;

    constructor(props) {
        super(props);
        // console.log(' super props', props)
        this.state = {
            editingItem: null,
            dragRow: false,
            expandedRows: {},
            selectedCell: {colIndex: null, rowIndex: null},
            originCell: null,
            selectedCells: [],
            lastKeyEventTimeStamp: 0,
            mouseDown: false,
            lastMouseSelected: {colIndex: null, rowIndex: null},
            financeKey: "finance_table_" + new Date().getTime(),
            isDragging: false,
            isTableExpanded: false,
        };
        if (this.checkExpandCollapseStore()) {
            if (
                LocalStorage.getByKeyFromLocalStorageObjects(settingsTable, props.ExpandCollapseID) === null
            ) {
                this.state.expandedRows = LocalStorage.appendObjectsToLocalStorage(
                    settingsTable,
                    Object.assign({[props.ExpandCollapseID]: {isTableExpanded: false}}),
                );
            } else {
                this.localstorageExpandedRows = LocalStorage.getByKeyFromLocalStorageObjects(
                    settingsTable,
                    props.ExpandCollapseID,
                );
                this.state.expandedRows = LocalStorage.getByKeyFromLocalStorageObjects(
                    settingsTable,
                    props.ExpandCollapseID,
                );
                this.state.isTableExpanded = this.localstorageExpandedRows["isTableExpanded"];
            }
        }

        this.unique_key = MxIDUseID();
        this.Field = null;
        if (this.props.useActual) {
            this.Field = TableFields.Actual;
        } else {
            this.Field = TableFields.Value;
        }
        this.tableWidth = this.getCalculatedTableWidth();
        this.checkExpanded();

        this.tableRef = React.createRef();
    }

    componentWillUnmount() {
        this.removeEventListeners();
    }

    removeEventListeners = () => {
        if (window.currentTable == this.props.indexTab) {
            window.currentTable = null;
            global.pasteListener = null;
            global.copyListener = null;
            global.keyListener = null;
        }
    };

    onCellChanged = (items, values) => {
        let newItems = [];

        items.forEach((item, index) => {
            if (values[index] != undefined || (values[index] == undefined && values.length == 1)) {
                if (values[index] == item[this.Field]) {
                    return;
                }

                const oldValue = item[this.Field];

                if (item.hasOwnProperty("error") && item.error.length > 0) {
                    item.error = [];
                }

                if (values[index] == null || values[index].length == 0) {
                    item[this.Field] = null;
                } else {
                    const newValue = parseFloat(values[index]);
                    if (Number.isNaN(newValue) == false) {
                        try {
                            item[this.Field] = newValue;
                        } catch {
                            console.error(`can't change ${item[this.Field]}, ${newValue}`);
                        }
                    }

                    if (item.hasOwnProperty("checkIsDividedWithZero") && newValue === 0) {
                        item[this.Field] = oldValue;
                        global.Modeliks.showDialog("You cannot divide by zero. Please check your input!");
                    } else {
                        if (oldValue != item[this.Field]) {
                            newItems.push(item);
                        }
                    }
                }
            }
        });

        if (this.props.onDataChanged) {
            // this.state.financeKey =  'finance_table_' + new Date().getTime()
            this.MaxLengthOfValue = 8;
            this.getCalculatedTableWidth();
            this.props.onDataChanged(newItems);
        }
    };

    cellComparator = (cell1, cell2) => {
        return cell1.colIndex !== cell2.colIndex || cell1.rowIndex !== cell2.rowIndex;
    };

    sideScroll = (element, direction, speed, distance, step) => {
        let scrollAmount = 0;
        var slideTimer = setInterval(function () {
            if (direction == "left") {
                element.scrollLeft -= step;
            } else if (direction == "right") {
                element.scrollLeft += step;
            } else if (direction == "top") {
                element.scrollTop -= step;
            } else {
                element.scrollTop += step;
            }
            scrollAmount += step;
            if (scrollAmount >= distance) {
                window.clearInterval(slideTimer);
            }
        }, speed);
    };

    scrollIntoView = (cell, cellRect, table, leftOffset, direction) => {
        if (this.props.isCustomDashboard) {
            return;
        }
        if (!this.isElementInViewport(cell, leftOffset, 0, table)) {
            if (direction == "right") {
                let rightDiff =
                    cellRect.right - (window.innerWidth || document.documentElement.clientWidth);
                if (rightDiff > 0) {
                    this.sideScroll(table.parentElement, direction, 20, cellRect.width + 50, 5);
                }
            } else if (direction == "left") {
                if (cellRect.left - leftOffset < 0) {
                    this.sideScroll(table.parentElement, direction, 20, cellRect.width + 50, 5);
                }
            }
            if (direction == "top") {
                this.sideScroll(table.parentElement, direction, 20, cellRect.height + 25, 5);
            } else if (direction == "bottom") {
                this.sideScroll(table.parentElement, direction, 20, cellRect.height + 25, 5);
            }
        }
    };

    adjustScrollOnMouseSelect = (colIndex, rowIndex) => {
        let leftOffset = 0;
        let rightOffset = 25;
        let cell = document.getElementsByClassName(
            `t_${this.props.indexTab}-${rowIndex}_${colIndex}`,
        )[0];
        let table = this.getParentElementByTagName(cell, "TABLE");
        let cellRect = cell.getBoundingClientRect();
        let rowHeader = this.getParentElementByTagName(cell, "TR").children[0];
        if (window.getComputedStyle(rowHeader).position == "sticky") {
            leftOffset = rowHeader.getBoundingClientRect().width;
        }

        if (cell) {
            if (colIndex > this.state.originCell.colIndex) {
                //right
                this.scrollIntoView(cell, cellRect, table, leftOffset, "right");
            } else if (colIndex < this.state.originCell.colIndex) {
                //left
                this.scrollIntoView(cell, cellRect, table, leftOffset, "left");
            }
            if (rowIndex > this.lastMouseSelectedCell.rowIndex) {
                //down
                this.scrollIntoView(cell, cellRect, table, leftOffset, "bottom");
            } else if (rowIndex < this.lastMouseSelectedCell.rowIndex) {
                //up
                this.scrollIntoView(cell, cellRect, table, leftOffset, "top");
            }
        }
        this.lastMouseSelectedCell = {colIndex: colIndex, rowIndex: rowIndex};
    };

    selectCell = (colIndex, rowIndex, event) => {
        if (this.props.disabledEdit) {
            return;
        }
        if (this.props.indexTab == window.currentTable) {
            if (event.type === "mousedown") {
                this.state.lastMouseSelected = {colIndex: colIndex, rowIndex: rowIndex};
                this.lastMouseSelectedCell = {colIndex: colIndex, rowIndex: rowIndex};
                this.state.mouseDown = true;
                if (!this.state.originCell || !event.shiftKey) {
                    this.state.originCell = {colIndex: colIndex, rowIndex: rowIndex};
                }
                if (event.ctrlKey) {
                    if (!this.isMultipleSelectedCell(colIndex, rowIndex)) {
                        this.state.selectedCells.push({colIndex: colIndex, rowIndex: rowIndex});
                    } else {
                        this.setState({
                            selectedCells: this.state.selectedCells.filter((cell) =>
                                this.cellComparator(
                                    {
                                        colIndex: colIndex,
                                        rowIndex: rowIndex,
                                    },
                                    cell,
                                ),
                            ),
                            selectedCell: {
                                colIndex: this.state.selectedCells[0].colIndex,
                                rowIndex: this.state.selectedCells[0].rowIndex,
                            },
                        });
                        return true;
                    }
                } else if (!event.shiftKey) {
                    if (this.state.selectedCells.length > 0) {
                        this.state.selectedCells = [{colIndex: colIndex, rowIndex: rowIndex}];
                        this.state.originCell = {colIndex: colIndex, rowIndex: rowIndex};
                    }
                }
            }

            if (event.shiftKey) {
                this.state.selectedCells = [];
                try {
                    if (
                        colIndex < this.state.originCell.colIndex &&
                        rowIndex >= this.state.originCell.rowIndex
                    ) {
                        for (let col = colIndex; col <= this.state.originCell.colIndex; col++) {
                            for (let row = this.state.originCell.rowIndex; row <= rowIndex; row++) {
                                this.state.selectedCells.push({colIndex: col, rowIndex: row});
                            }
                        }
                    } else if (
                        colIndex > this.state.originCell.colIndex &&
                        rowIndex <= this.state.originCell.rowIndex
                    ) {
                        for (let col = this.state.originCell.colIndex; col <= colIndex; col++) {
                            for (let row = rowIndex; row <= this.state.originCell.rowIndex; row++) {
                                this.state.selectedCells.push({colIndex: col, rowIndex: row});
                            }
                        }
                    } else if (
                        colIndex <= this.state.originCell.colIndex &&
                        rowIndex < this.state.originCell.rowIndex
                    ) {
                        for (let col = colIndex; col <= this.state.originCell.colIndex; col++) {
                            for (let row = rowIndex; row <= this.state.originCell.rowIndex; row++) {
                                this.state.selectedCells.push({colIndex: col, rowIndex: row});
                            }
                        }
                    } else {
                        for (let col = this.state.originCell.colIndex; col <= colIndex; col++) {
                            for (let row = this.state.originCell.rowIndex; row <= rowIndex; row++) {
                                this.state.selectedCells.push({colIndex: col, rowIndex: row});
                            }
                        }
                    }
                } catch {
                }
            } else if (!event.ctrlKey) {
                this.state.selectedCells = [];
            }

            if (event.type !== "mousedown" && !event.shiftKey && !event.ctrlKey) {
                this.state.originCell = {colIndex: colIndex, rowIndex: rowIndex};
            }
            this.state.lastKeyEvent = event;
            this.state.selectedCell = {colIndex: colIndex, rowIndex: rowIndex};

            if (event.type !== "mousedown") this.forceUpdate();
        }
    };

    selectedCell = (colIndex, rowIndex) => {
        return (
            this.state.selectedCell.colIndex === colIndex && this.state.selectedCell.rowIndex === rowIndex
        );
    };

    isNextCellSelected = (colIndex, rowIndex) => {
        let CSSClass = "";

        if (!this.isMultipleSelectedCell(colIndex - 1, rowIndex)) CSSClass += " l";
        if (!this.isMultipleSelectedCell(colIndex + 1, rowIndex)) CSSClass += " r";
        if (!this.isMultipleSelectedCell(colIndex, rowIndex - 1)) CSSClass += " t";
        if (!this.isMultipleSelectedCell(colIndex, rowIndex + 1)) CSSClass += " b";

        return CSSClass;
    };

    isMultipleSelectedCell = (colIndex, rowIndex) => {
        for (let i = 0; i < this.state.selectedCells.length; i++) {
            if (
                this.state.selectedCells[i].colIndex === colIndex &&
                this.state.selectedCells[i].rowIndex === rowIndex
            ) {
                i = this.state.selectedCells.length + 1;
                return true;
            }
        }
        return false;
    };

    handleOnCellSelected = (colIndex, rowIndex) => {
        if (this.props.disabledEdit) {
            return;
        }
        if (this.props.indexTab != window.currentTable) {
            this.addEventListeners();
        }
        if (this.state.selectedCells.length === 0) {
            this.setState({selectedCell: {colIndex, rowIndex}, originCell: {colIndex, rowIndex}});
        }
    };

    getParentElementByTagName = (element, tagName) => {
        if (!element) {
            return null;
        }
        if (element.tagName == tagName) {
            return element;
        } else {
            return this.getParentElementByTagName(element.parentElement, tagName);
        }
    };

    isElementInViewport = (el, leftOffset = 0, rightOffset = 0, table) => {
        let rect = el.getBoundingClientRect();
        let tableParentRect = table.parentElement.getBoundingClientRect();
        let tableHeaderCell = table.children[0].children[0].children[1];
        if (!tableHeaderCell || tableHeaderCell.tagName != "TH") {
            tableHeaderCell = null;
        }
        return (
            rect.top >=
            tableParentRect.top +
            (tableHeaderCell ? tableHeaderCell.getBoundingClientRect().height : 0) &&
            rect.left >= leftOffset &&
            rect.bottom <= tableParentRect.bottom /* or $(window).height() */ &&
            rect.right + rightOffset <=
            (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
        );
    };

    mouseSelect = (colIndex, rowIndex, event) => {
        if (this.props.disabledEdit) {
            return;
        }
        if (this.state.mouseDown) {
            this.state.selectedCells = [];
            try {
                if (
                    colIndex < this.state.originCell.colIndex &&
                    rowIndex >= this.state.originCell.rowIndex
                ) {
                    for (let col = colIndex; col <= this.state.originCell.colIndex; col++) {
                        for (let row = this.state.originCell.rowIndex; row <= rowIndex; row++) {
                            this.state.selectedCells.push({colIndex: col, rowIndex: row});
                        }
                    }
                } else if (
                    colIndex > this.state.originCell.colIndex &&
                    rowIndex <= this.state.originCell.rowIndex
                ) {
                    for (let col = this.state.originCell.colIndex; col <= colIndex; col++) {
                        for (let row = rowIndex; row <= this.state.originCell.rowIndex; row++) {
                            this.state.selectedCells.push({colIndex: col, rowIndex: row});
                        }
                    }
                } else if (
                    colIndex <= this.state.originCell.colIndex &&
                    rowIndex < this.state.originCell.rowIndex
                ) {
                    for (let col = colIndex; col <= this.state.originCell.colIndex; col++) {
                        for (let row = rowIndex; row <= this.state.originCell.rowIndex; row++) {
                            this.state.selectedCells.push({colIndex: col, rowIndex: row});
                        }
                    }
                } else {
                    for (let col = this.state.originCell.colIndex; col <= colIndex; col++) {
                        for (let row = this.state.originCell.rowIndex; row <= rowIndex; row++) {
                            this.state.selectedCells.push({colIndex: col, rowIndex: row});
                        }
                    }
                }
                this.forceUpdate();
                this.adjustScrollOnMouseSelect(colIndex, rowIndex);
            } catch {
            }
        }
    };

    unselectAllCells = () => {
        setTimeout(() => {
            this.state.selectedCells = [];
            this.state.selectedCell = {colIndex: null, rowIndex: null};
            this.state.originCell = {colIndex: null, rowIndex: null};
            this.forceUpdate();
            this.mouseEnterTable();
        }, 1);
    };


    clearRowValues = (row) => {
        if (!row) {
            return;
        }

        const notEditable = this.props.useDashboards === true || row.isNotEditable;
        this.props.headers.forEach((date, index) => {
            const item = row.data.getItemByDateSufix(date.sufix);
            const isActualEditable = item.db_record && item.evalFormulaActual === null;
            const isEditable =
                notEditable && notEditable !== undefined
                    ? false
                    : this.props.useActual
                        ? isActualEditable
                        : item.IsSimple;
            if (isEditable) {
                item[this.Field] = 0;
                item.db_record[this.Field] = 1;
                if(index+1 === this.props.headers.length){
                    row.data.Save();
                }
            }
        });

        if (row.children && row.children.length > 0) {
            row.children.forEach((child) => {
                this.props.headers.forEach((date) => {
                    const item = child.data.getItemByDateSufix(date.sufix);
                    const isActualEditable = item.db_record && item.evalFormulaActual === null;
                    const isEditable =
                        notEditable && notEditable !== undefined
                            ? false
                            : this.props.useActual
                                ? isActualEditable
                                : item.IsSimple;
                    if (isEditable) {
                        item[this.Field] = 0;
                    }
                });
            });
        }
        this.forceUpdate();
    };

    onMoveMouseUp = (event) => {
        document.removeEventListener("mouseup", this.onMoveMouseUp);
        this.mouseDown = false;
    };

    onMoveMouseDown = (event) => {
        this.mouseDown = true;
        document.addEventListener("mouseup", this.onMoveMouseUp);
    };

    mouseLeave = (event) => {
        this.lastExited = event.timeStamp;
        if (!this.mouseDown && this.state.dragRow && this.lastExited - this.lastEntered > 0) {
            this.state.dragRow = false;
            this.forceUpdate();
        }
    };

    mouseLeaveTable = () => {
        if (this.props.disabledEdit) {
            return;
        }
        document.addEventListener("mousedown", this.unselectAllCells);
    };

    mouseEnterTable = () => {
        if (this.props.disabledEdit) {
            return;
        }
        document.removeEventListener("mousedown", this.unselectAllCells);
    };

    handlePaste = (e, del = false) => {
        let data = del ? [0] : e.clipboardData.getData("text");
        let newData = [];
        let tempArr2 = [];

        if (data.length > 1) {
            newData = data
                .replace(/\r/g, "")
                .split(/\n/)
                .map((c) => c.split(/\t/));
            if (newData.length) {
                let lastArr = newData[newData.length - 1];
                if (lastArr.length) {
                    if (lastArr[lastArr.length - 1] == "") {
                        lastArr.pop();
                    }
                }
                if (!lastArr.length) {
                    newData.pop();
                }
            }
        } else {
            newData = data;
        }

        let newCellsObject = {};
        this.state.selectedCells.forEach((c) => {
            if (!newCellsObject[c.rowIndex]) {
                Object.assign(newCellsObject, {[c.rowIndex]: {}});
            }
            if (!newCellsObject[c.rowIndex][c.colIndex]) {
                newCellsObject[c.rowIndex][c.colIndex] = null;
            }
        });

        if (Object.keys(newCellsObject).length > newData.length && newData.length === 1) {
            let tempNewData = [];
            Object.keys(newCellsObject).forEach((c) => {
                tempNewData.push(newData[0]);
            });

            newData = tempNewData;
        }

        Object.values(newCellsObject).forEach((c, index) => {
            if (newData[index]) {
                Object.keys(c).forEach((cell, i) => {
                    if (newData[index][i] != undefined) {
                        c[cell] = newData[index][i];
                    } else if (newData[index][i % newData[index].length] != undefined) {
                        c[cell] = newData[index][i % newData[index].length];
                    } else {
                        c[cell] = null;
                    }
                });
            }
        });

        let rowsArr = [];

        e.stopPropagation();
        this.rowCountPaste = 0;

        if (this.state.selectedCells.length > 0) {
            let maxI = this.state.selectedCells[this.state.selectedCells.length - 1].rowIndex;
            for (let i = 0; this.rowCountPaste <= maxI; i++) {
                let ex = this.rowDataPaste(
                    this.props.rows[i],
                    i,
                    0,
                    this.state.selectedCells[0].colIndex,
                    this.state.selectedCells[this.state.selectedCells.length - 1].colIndex,
                );
                rowsArr = rowsArr.concat(ex.arr);
                ex.valid.forEach((c) => {
                    tempArr2.push(newCellsObject[c.rowIndex][c.colIndex]);
                });
            }
        }
        let filteredArray = tempArr2.filter((c) => c != null);
        if (filteredArray.length > 1) {
            this.onCellChanged(rowsArr, this.getRealValues(filteredArray));
        } else if (filteredArray.length > 0) {
            this.onCellChanged(
                rowsArr,
                rowsArr.map((c) => this.getRealValues([tempArr2[0]])),
            );
        } else if (del) {
            this.onCellChanged(
                rowsArr,
                rowsArr.map((c) => 0),
            );
        } else {
        }
        this.state.financeKey = "finance_table_" + new Date().getTime();
        this.forceUpdate();

        this.rowCountPaste = 0;
    };

    getRealValues = (values = []) => {
        return values.map((v) => window.getRealValue(v));
    };

    rowDataPaste = (row, childIndex, level, col1, col2, data) => {
        let rowObj = {arr: [], valid: []};
        const rowIndex = this.rowCountPaste++;
        if (row) {
            if (row.data) {
                if (row.data.IsSimple || this.props.useActual) {
                    if (this.props.useDateIndexes == true) {
                        for (let i = Number(col1); i <= Number(col2); i++) {
                            if (this.selectedCell(i, rowIndex) || this.isMultipleSelectedCell(i, rowIndex)) {
                                rowObj.valid.push({rowIndex: rowIndex, colIndex: i});
                                rowObj.arr.push(row.data.getItemByDateSufix(this.props.headers[i].sufix));
                            }
                        }
                    }
                }
            }
            if (this.state.expandedRows[this.getRowIndex(row)] && row.children) {
                row.children.map((child, childIndex) => {
                    let rowDataPasteObj = this.rowDataPaste(child, childIndex, level + 1, col1, col2, data);
                    rowObj.arr = [...rowObj.arr, ...rowDataPasteObj.arr];
                    rowObj.valid = [...rowObj.valid, ...rowDataPasteObj.valid];
                });
            }
        }
        return rowObj;
    };

    handleDuplicate = (e, row) => {
        e.stopPropagation();
        this.props.makeDuplicate(row);
    };

    handleCopy = (e) => {
        if (this.state.selectedCells.length) {
            let rows = [...this.props.rows];
            if (this.props.totalsRows && this.props.totalsRows.length > 0) {
                rows = [...rows, ...this.props.totalsRows];
            }

            let textForCopying = "";

            this.rowCountCopy = 0;
            let maxI;
            maxI = this.state.selectedCells[this.state.selectedCells.length - 1].rowIndex;
            for (let i = 0; this.rowCountCopy <= maxI; i++) {
                const row = rows[i];
                textForCopying += this.rowDataCopy(
                    rows[i],
                    i,
                    0,
                    this.state.selectedCells[0].colIndex,
                    this.state.selectedCells[this.state.selectedCells.length - 1].colIndex,
                );
            }
            try {
                navigator.clipboard.writeText(textForCopying.replace(/,/g, ""));
            } catch {
                console.error("couldn't copy to clipboard");
            }
            this.rowCountCopy = 0;
        }
    };

    rowDataCopy = (row, childIndex, level, col1, col2) => {
        const rowIndex = this.rowCountCopy++;
        let text = "";
        if (this.props.useDateIndexes === true && row) {
            for (let i = col1; i <= col2; i++) {
                if (this.selectedCell(i, rowIndex) || this.isMultipleSelectedCell(i, rowIndex)) {
                    let data_cell = row.data.getItemByDateSufix(this.props.headers[i].sufix)[this.Field];
                    if (data_cell !== null && data_cell !== undefined) {
                        text += data_cell;
                    } else {
                        text += "";
                    }
                    if (i !== col2) text += "\t";
                }
            }
            if (
              this.state.selectedCells[0].rowIndex <= rowIndex &&
              this.state.selectedCells[this.state.selectedCells.length - 1].rowIndex >= rowIndex
            )
                text += "\n";
            // text =text.replace(',', '')
        }
        if (row) {
            if (this.state.expandedRows[this.getRowIndex(row)] && row.children) {
                text += row.children.map((child, childIndex) =>
                  this.rowDataCopy(child, childIndex, level + 1, col1, col2),
                );
            }
        }
        return text;
    };

    handleOnIconAction = (event, ref_id, onClickFunc, row, rowIndex) => {
        if (this.props.handleOnEdit) {
            this.props.handleOnEdit(ref_id, rowIndex, row);
        } else {
            onClickFunc(ref_id, rowIndex, row);
        }
        event.stopPropagation();
        return false;
    };

    adjustScroll = (direction, newColIndex, newRowIndex) => {
        if (this.props.indexTab == window.currentTable && this.state.originCell) {
            let cell = document.getElementsByClassName(
                `t_${this.props.indexTab}-${newRowIndex}_${newColIndex}`,
            )[0];
            let scrollOptions = {block: "nearest"};
            if (cell) {
                switch (direction) {
                    case "left":
                        if (this.state.originCell.colIndex > newColIndex) {
                            Object.assign(scrollOptions, {inline: "end"});
                        }
                        break;
                    case "up":
                        if (newRowIndex == 0) {
                            Object.assign(scrollOptions, {block: "center"});
                        }
                        break;
                }
                cell.scrollIntoView(scrollOptions);
            }
        }
    };

    handleOnKeyDown = (event) => {
        if (this.props.disabledEdit) {
            return;
        }
        event.stopPropagation();
        if (
            !this.state.lastKeyEvent ||
            event.timeStamp - this.state.lastKeyEvent.timeStamp > 80 ||
            event.keyCode === 38 ||
            event.keyCode === 40
        ) {
            switch (event.keyCode) {
                case 9:
                    if (this.state.selectedCells.length > 0) this.state.selectedCells = [];
                    // event.preventDefault();
                    break;
                case 16:
                    if (!this.state.originCell) {
                        this.state.lastKeyEvent = event;
                        this.forceUpdate();
                    }
                    break;
                case 17:
                    if (
                        !this.isMultipleSelectedCell(
                            this.state.selectedCell.colIndex,
                            this.state.selectedCell.rowIndex,
                        )
                    ) {
                        this.state.selectedCells.push(this.state.selectedCell);
                        this.state.lastKeyEvent = event;
                        this.forceUpdate();
                    }
                    break;
                case 27:
                    this.state.selectedCells = [];
                    this.state.selectedCell = {colIndex: null, rowIndex: null};
                    this.state.lastKeyEvent = event;
                    this.forceUpdate();
                    break;
                case 37: //left
                    if (!event.ctrlKey) {
                        if (this.state.selectedCell.colIndex > 0) {
                            this.selectCell(
                                this.state.selectedCell.colIndex - 1,
                                this.state.selectedCell.rowIndex,
                                event,
                                "left",
                            );
                            this.adjustScroll(
                                "left",
                                this.state.selectedCell.colIndex,
                                this.state.selectedCell.rowIndex,
                                event,
                            );
                        }
                    }
                    break;
                case 38: //up
                    if (!event.ctrlKey) {
                        if (this.state.selectedCell.rowIndex > 0) {
                            this.selectCell(
                                this.state.selectedCell.colIndex,
                                this.state.selectedCell.rowIndex - 1,
                                event,
                                "up",
                            );
                            this.adjustScroll(
                                "up",
                                this.state.selectedCell.colIndex,
                                this.state.selectedCell.rowIndex,
                                event,
                            );
                        }
                    }
                    break;
                case 39: //right
                    if (!event.ctrlKey) {
                        if (this.state.selectedCell.colIndex < this.props.headers.length - 1) {
                            this.selectCell(
                                this.state.selectedCell.colIndex + 1,
                                this.state.selectedCell.rowIndex,
                                event,
                                "right",
                            );
                            this.adjustScroll(
                                "right",
                                this.state.selectedCell.colIndex,
                                this.state.selectedCell.rowIndex,
                                event,
                            );
                        }
                    }
                    break;
                case 40: //down
                    if (!event.ctrlKey) {
                        if (this.state.selectedCell.rowIndex < this.rowCount - 1) {
                            this.selectCell(
                                this.state.selectedCell.colIndex,
                                this.state.selectedCell.rowIndex + 1,
                                event,
                                "down",
                            );
                            this.adjustScroll(
                                "down",
                                this.state.selectedCell.colIndex,
                                this.state.selectedCell.rowIndex,
                                event,
                            );
                        }
                    }
                    break;
                case 46:
                    this.handlePaste(event, true);
                    event.stopPropagation();
                    break;
                default:
                    // this.setState({editingItem: this.state.selectedCell});
                    break;
            }
        }
    };

    handleOnKeyUp = (event) => {
        switch (event.keyCode) {
            case 16:
                if (this.state.originCell) {
                    // this.state.originCell = null;
                    // this.forceUpdate()
                }
                break;
        }
    };

    toggleDragRow = () => {
        if (!this.state.dragRow && this.lastEntered - this.lastExited > 0) {
            this.state.dragRow = true;
            this.forceUpdate();
        }
    };

    enableDrag = (event) => {
        this.lastEntered = event.timeStamp;
        setTimeout(this.toggleDragRow, 150);
    };

    onDragEnd = (result) => {
        if (result) {
            this.state.dragRow = false;
            this.props.handeMoveRow(result);
        }
    };

    onDragStart = () => {
        this.state.dragRow = true;
    };

    getRefID = (row) => {
        if (row.revenue) {
            if (row.revenue.IsTemporary) {
                return "none";
            } else {
                if (row.revenue.Ref_ID) {
                    return row.revenue.Ref_ID;
                } else {
                    return row.id;
                }
            }
        }
    };

    addEventListeners = () => {
        window.currentTable = this.props.indexTab;
        global.pasteListener = this.handlePaste;
        global.copyListener = this.handleCopy;
        global.keyListener = this.handleOnKeyDown;
    };

    handleAutoFill = (colIndex, rowIndex, row, useActual, item) => {
        if (row.data) {
            let ChangeObj = {items: [], values: []};

            let firstYearIndex = 0;
            for (let i = colIndex; i < this.props.headers.length; i++) {
                if (this.props.headers[i].PeriodType === PeriodTypes.year) {
                    firstYearIndex = i;
                    i = this.props.headers.length;
                    break;
                }
                // row.data.getItemByDateSufix(this.props.headers[i].sufix)[this.Field] = item[this.Field];

                ChangeObj.items.push(row.data.getItemByDateSufix(this.props.headers[i].sufix));
                ChangeObj.values.push([item[this.Field]]);
                if (i === this.props.headers.length - 1) {
                    firstYearIndex = this.props.headers.length;
                }
            }

            const startWithYears = !!(colIndex === firstYearIndex);
            for (let i = firstYearIndex; i < this.props.headers.length; i++) {
                let multiplier = 1;
                if (!startWithYears && row.data.DriverCategory === DriverCategories.Sum) {
                    multiplier = 12;
                }
                // row.data.getItemByDateSufix(this.props.headers[i].sufix)[this.Field] = item[this.Field] * multiplier;

                ChangeObj.items.push(row.data.getItemByDateSufix(this.props.headers[i].sufix));
                ChangeObj.values.push([item[this.Field] * multiplier]);
            }

            this.mouseDown = false;
            this.state.mouseDown = false;
            this.onCellChanged(ChangeObj.items, ChangeObj.values);
        }
    };

    handleTableResize = (value) => {
        this.MaxLengthOfValue = InputFormats.formatNumber(value).toString().length;
        this.tableWidth = this.getCalculatedTableWidth();
    };

    Cell = (item, colIndex, row, rowIndex, level, useActual = this.props.useActual) => {
        let value = item[[this.Field]];
        if (
            !this.props.disabledEdit &&
            this.MaxLengthOfValue < (value && InputFormats.formatNumber(value).toString().length)
        ) {
            this.handleTableResize(value);
        }
        this.cellCount++;
        let singlySelectedCell = !this.props.disabledEdit && this.selectedCell(colIndex, rowIndex);
        let isThisCellSelected =
            (!this.props.disabledEdit && singlySelectedCell) ||
            this.isMultipleSelectedCell(colIndex, rowIndex);
        let isNextCellSelected =
            !this.props.disabledEdit && isThisCellSelected
                ? this.isNextCellSelected(colIndex, rowIndex)
                : "";
        return (
            <FinanceTableCell
                handleOnKeyDown={singlySelectedCell && this.handleOnKeyDown}
                key={"table_cell" + rowIndex + "_" + colIndex}
                onMouseEnter={(e) => this.mouseSelect(colIndex, rowIndex, e)}
                handleOnCellSelected={this.handleOnCellSelected}
                cellCount={this.cellCount}
                indexTab={this.props.indexTab}
                disabledEdit={this.props.disabledEdit}
                useDateIndexes={this.props.useDateIndexes}
                item={item}
                min={this.props.min}
                max={this.props.max}
                level={level}
                value={value}
                EditStream={this.props.EditStream}
                displayOfDecimals={
                    this.props.EditStream
                        ? this.props.displayOfDecimals
                        : LocalStorage.get(
                            tableObject,
                            tableConfig,
                            this.props.IsReport
                                ? tableConfigTypes.displayOfDecimalsReport
                                : tableConfigTypes.displayOfDecimals,
                        )
                }
                customStyle={this.getCustomizableStyles(true)}
                useActual={useActual}
                useCustomizableTable={this.props.useCustomizableTable}
                useDashboards={this.props.useDashboards}
                colIndex={colIndex}
                row={row}
                textColor={this.props.textColor}
                editableColor={this.props.editableColor}
                unit={item.UnitParsed}
                unitNegative={item.UnitNegative}
                hidden={item.Hidden}
                headers={this.props.headers}
                rowIndex={rowIndex}
                unitDisplay={this.props.unitDisplay}
                IsReport={this.props.IsReport}
                useShowHideCurrency={
                    this.props.useShowHideCurrency
                        ? LocalStorage.get(tableObject, tableConfig, tableConfigTypes.showCurrency)
                        : false
                }
                IsNumberFormatDisabled={
                    row.hasOwnProperty("IsNumberFormatDisabled")
                        ? row.IsNumberFormatDisabled
                        : this.props.IsNumberFormatDisabled
                }
                unitDisplayFormat={
                    this.props.IsReport
                        ? LocalStorage.get(tableObject, tableConfig, tableConfigTypes.unitDisplayReportFormat)
                        : global.Modeliks.DashboardSettings.Value.financialDates.numberFormatType
                }
                selectedCells={this.state.selectedCells}
                isThisCellSelected={isThisCellSelected}
                isNextCellSelected={isNextCellSelected}
                singlySelectedCell={singlySelectedCell}
                selectedCell={this.state.selectedCell}
                onMouseDown={this.selectCell}
                setCurrentTable={this.addEventListeners}
                showNaNValue={this.props.showNaNValue}
                onMouseUp={() => {
                    if (this.props.disabledEdit) {
                        return;
                    }
                    this.setState({
                        mouseDown: false,
                        lastMouseSelected: {colIndex: null, rowIndex: null},
                    });
                }}
                lastMouseSelected={this.state.lastMouseSelected}
                onCellChanged={(value) => this.onCellChanged([item], [value])}
                newKey={this.state.financeKey}
                onAutoFill={() => this.handleAutoFill(colIndex, rowIndex, row, useActual, item)}
            />
        );
    };

    getRecalculatedTableCellByHeader = (length) => {
        this.MaxLengthOfValue = length;
        this.tableWidth = this.getCalculatedTableWidth();
    };

  getCustomizableStyles = (isMxInput = false, isFirstTableCell = false) => {
    if (this.props.useCustomizableTable && !isMxInput && isFirstTableCell) {
      return `mct_ft_header_customizable_${LocalStorage.get(
        tableObject,
        tableConfig,
        tableConfigTypes.fontSize,
      )} ${this.props.showRowHeaders ? "mct_ft_show_headers" : ""}`;
    }
    if (this.props.useCustomizableTable && isMxInput) {
      return `mct_ft_table_customizable_${LocalStorage.get(
        tableObject,
        tableConfig,
        tableConfigTypes.fontSize,
      )} ${
        !LocalStorage.get(tableObject, tableConfig, tableConfigTypes.showFullNumbers)
          ? "mct_ft_table_full_numbers"
          : ""
      }`;
    }

    if (this.props.useCustomizableTable) {
      return `mct_ft_table_customizable_${LocalStorage.get(
        tableObject,
        tableConfig,
        tableConfigTypes.fontSize,
      )} `;
    }

        return "";
    };

    firstTableCell = () => {
        let Icon = <DoubleArrowDown/>;
        let Title = "Expand All";

        if (this.state.isTableExpanded) {
            Icon = <DoubleArrowUp/>;
            Title = "Collapse All";
        }

        const getNumberFormat = () => {
            if (this.props.IsNumberFormatDisabled) {
                return "";
            }

            let type = this.props.displayIn ? this.props.displayIn : global.Modeliks.DashboardSettings.Value.financialDates.numberFormatType;
            if (this.props.IsReport) {
                type = LocalStorage.get(tableObject, tableConfig, tableConfigTypes.unitDisplayReportFormat);
            }
            return `(in ${type === "fullNumbers" ? global.Modeliks.CompanyInfo.Currency.value : type})`;
        };

    return (
      <TableCell
        className={`mct_ft_tablehead_cell ${
          this.props.wrapperClassName && this.props.wrapperClassName
        } ${this.getCustomizableStyles(false, true)}`}
        rowSpan={2}
      >
        <div
          className={
            this.props.disabled
              ? `mct_ft_tablehead_paragraph_title mct_ft_disabled mct_ft_tablehead_div ${this.getCustomizableStyles()}`
              : `mct_ft_tablehead_paragraph_title mct_ft_tablehead_div ${this.getCustomizableStyles()}`
          }
        >
          <span className="mct_ft_table_title">
            <span>{this.props.title}</span>
              {this.props.titleDate && this.props.dates && (
                  <span>
                {this.props.dates[0].Header} -{" "}
                      {this.props.dates[this.props.dates.length - 1].Header}
              </span>
              )}
              <span className={`mct_ft_table_title_units ${this.getCustomizableStyles()}`}>
              {getNumberFormat()}
            </span>
          </span>
                    {!this.props.isCustomDashboard && !this.props.disableExpandAll && (
                        <Mx_Tooltip_Hover
                            title={Title}
                            icon={Icon}
                            onClick={() => this.expandAll(false, this.state.isTableExpanded)}
                        />
                    )}
                </div>
            </TableCell>
        );
    };

    TableHeader = () => {
        if (this.props.isComparativeView) {
            return (
                <FinanceTableHeadComparative
                    headers={this.props.headers}
                    disabled={this.props.disabled}
                    MaxLengthOfValue={this.MaxLengthOfValue}
                    getRecalculatedTableCellByHeader={this.getRecalculatedTableCellByHeader}
                    CustomizableStyles={this.getCustomizableStyles}
                >
                    {this.firstTableCell()}
                </FinanceTableHeadComparative>
            );
        } else {
            return (
                <TableHead className="mct_ft_tablehead">
                    <TableRow className={this.getCustomizableStyles(false, true)}>
                        {this.props.showRowHeaders && this.firstTableCell()}
                        {this.props.headers.map((header, index) => {
                            if (this.MaxLengthOfValue < (header && header.Header.length)) {
                                this.MaxLengthOfValue = header.Header.length;
                                this.tableWidth = this.getCalculatedTableWidth();
                            }
                            return (
                                <TableCell
                                    key={index}
                                    className={
                                        header.PeriodType === "year"
                                            ? "mct_ft_tablehead_cell mct_ft_tablehead_cell_year"
                                            : "mct_ft_tablehead_cell"
                                    }
                                >
                                    <p
                                        className={
                                            this.props.disabled
                                                ? `mct_ft_tablehead_paragraph mct_ft_disabled ${this.getCustomizableStyles()}`
                                                : `mct_ft_tablehead_paragraph ${this.getCustomizableStyles()}`
                                        }
                                    >
                                        {header.Header}
                                    </p>
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
            );
        }
    };

    handleDeleteStream = (row) => {
        let stream = null;

        if (row.data.Ref_Table === global.Modeliks.Tables.Finance_Taxes.TableName) {
            stream = global.Modeliks.TaxesStore.getItem(row.data.Ref_ID);
        } else {
            stream = row.data.getParentStream();
        }

        if (!stream) {
            return;
        }

        return (
            <ButtonsActions
                tableActions
                close={this.props.handleReload}
                isDeletedRecord={{delete: false}}
                stream={stream}
                enableDelete={true}
                RevenueName={
                    stream ? (stream.hasOwnProperty("RevenueName") ? stream.RevenueName : stream.Name) : ""
                }
            />
        );
    };

  renderRowHeader = (ref_id, row, rowIndex, level, drag, isTemporary, isTotal = false) => {
    let thClass = "";
    const showActionButtons = this.props.className === "financetablemain_wrapper" && !isTotal;
    const showArrowIcon =
      row.children && row.children.length > 0
        ? "mct_ft_tablerow_header_arrow"
        : "mct_ft_tablerow_header_arrow hidden";
    let textClassName = `mct_ft_tablerow_header_paragraph mct_ft_table_row_header_bold-0 ${this.getCustomizableStyles()} `;
    if (level == 0) {
      thClass = " split_point";
    } else {
      thClass = ` split_child`;
    }
    if (level > 0 || (row.hasOwnProperty("boldRowHeader") && row.boldRowHeader === false)) {
      textClassName = `mct_ft_tablerow_header_paragraph child mct_ft_table_row_header_bold-${
        row.hasOwnProperty("applyBoldWeight") && row.applyBoldWeight
          ? level + 1
          : level !== 0
            ? level
            : 1
      } ${this.getCustomizableStyles()}`;
    }
    if (this.props.disabled) {
      textClassName = "mct_ft_tablerow_header_paragraph mct_ft_disabled";
    }

        textClassName =
            textClassName + ` ${this.props.wrapperClassName && this.props.wrapperClassName}`;

        if (level === 0) {
            textClassName = textClassName + ` mct_ft_first_lvl`;
        }

        if (this.props.applyItalic && row && row.data && row.data.UnitType === UnitTypes.Percentage) {
            textClassName = textClassName + this.getItalicClassName();
        }

        const LinkButton = () => {
            if (row.hasOwnProperty("linkButton") && row.linkButton.active) {
                return (
                    <ButtonMui
                        roundnessType={"squared"}
                        style={{
                            padding: 0,
                            minWidth: "40px",
                            lineHeight: "unset",
                            letterSpacing: "unset",
                            height: "clamp(16px, 0.9vw, 20px)",
                            fontSize: "clamp(10px, 0.9vw, 14px)",
                        }}
                        label={row.linkButton.title}
                        variant={"contained"}
                        onClick={() => global.Modeliks.NavigateTo(row.linkButton.url)}
                    />
                );
            }
        };

        const EditButton = () => {
            return (
                <ButtonMui
                    roundnessType={"squared"}
                    className={`${this.getCustomizableStyles()}`}
                    style={{
                        padding: 0,
                        paddingTop: 1.2,
                        paddingBottom: 1.2,
                        minWidth: "40px",
                        FontFamily: "Inter",
                        lineHeight: "unset",
                        letterSpacing: "unset",
                        height: "clamp(16px, 0.9vw, 20px)",
                        fontSize: "clamp(10px, 0.9vw, 14px)",
                    }}
                    label="Edit"
                    variant={"contained"}
                    onClick={(e) =>
                        this.handleOnIconAction(
                            e,
                            row.hasOwnProperty("data") ? row.data.Ref_ID : ref_id,
                            !row.onEditChildren ? row.onEditClick : row.onEditChildren,
                            row,
                            rowIndex,
                        )
                    }
                />
            );
        };

        const MoveButton = () => {
            return (
                <Tooltip title="Move" placement="top" arrow>
                    <div
                        onDragStart={drag}
                        onMouseLeave={this.mouseLeave}
                        onMouseEnter={this.enableDrag}
                        onMouseDown={this.onMoveMouseDown}
                    >
                        <Move width={"5px"} height={"5px"}/>
                    </div>
                </Tooltip>
            );
        };

        const DuplicateButton = () => {
            return (
                <Tooltip title="Duplicate" placement="top" arrow>
                    <div onClick={(e) => this.handleDuplicate(e, row, ref_id)}>
                        <Copy width={"11px"} height={"11px"}/>
                    </div>
                </Tooltip>
            );
        };

        const Delete = () => {
            if (!this.props.disabledEdit) {
                return this.handleDeleteStream(row);
            }
        };

        const DeleteCustom = () => {
            if (!this.props.disabledEdit) {
                return (
                  <Tooltip title="Delete" placement="top" arrow>
                      <IconButton size="small" onClick={() => this.props.onDelete(row.data)}>
                          <TrashCan2 width={"12px"} height={"12px"}/>
                      </IconButton>
                  </Tooltip>
                );
            }
        };

      const ArrowIcon = (props) => {
          let Icon = (
            <ArrowDown
              className={"mct_ft_tablerow_header_container"}
              onClick={props.onClick}
              disabled={this.props.disabled}
              width={"9"}
              height={"4.5"}
            />
          );
          if (
            (this.state.expandedRows[this.getRowIndex(row)] && this.state.expandedRows[this.getRowIndex(row)] === true) ||
            (this.state.isTableExpanded && row.children)
          ) {
              Icon = (
                <ArrowUp
                  className={"mct_ft_tablerow_header_container"}
                  color="currentColor"
                  onClick={props.onClick}
                  disabled={this.props.disabled}
                  width={"9"}
                  height={"4.5"}
                />
              );
          }

          return (
            <div onClick={props.onClick} className={showArrowIcon}>
                {Icon}
            </div>
          );
      };

      const ClearRowI = () => {
          if (!this.props.disabledEdit) {
                return (
                    <Tooltip title="Clear" placement="top" arrow>
                        <IconButton onClick={() => this.clearRowValues(row)} size="small">
                            <DeleteIcon/>
                        </IconButton>
                    </Tooltip>
                );
            }
        };

        let isEditable = false;
        if (this.props.useActual) {
            const notEditable = this.props.useDashboards === true || row.isNotEditable;
            const isActualEditable =
                row.data.db_record &&
                row.data.Values &&
                row.data.Values.length > 0 &&
                row.data.Values[0].evalFormulaActual === null;
            isEditable =
                notEditable && notEditable !== undefined
                    ? false
                    : this.props.useActual
                        ? isActualEditable
                        : row.data.IsSimple;
        }

    return (
      <TableCell
        className={`mct_ft_tablerow_header ${isTotal && "mct_ft_table_header_sticky"} ${
          this.props.wrapperClassName && this.props.wrapperClassName
        }${thClass}`}
        onClick={(e) => {
          if (!this.props.isCustomDashboard) {
            this.expandRow(row, e, rowIndex, level);
          }
        }}
      >
        <FinanceTableHeader
          disableRowType={this.props.disableRowType}
          ArrowIcon={
            !this.props.isCustomDashboard &&
            !this.props.disableExpandAll && (
              <ArrowIcon onClick={(e) => this.expandRow(row, e, rowIndex, level)} />
            )
          }
          isTotal={isTotal}
          level={level}
          row={row}
          className={textClassName}
          showActionButtons={showActionButtons}
          hasRenameDriverName={this.props.hasRenameDriverName}
        >
          {row.hasEdit && !this.props.disabledEdit && <EditButton />}
          <LinkButton />
          {this.props.useActual && isEditable && <ClearRowI />}
          {/*{(!this.props.disabledMove && (row.revenue && level === 0)) && <MoveButton/>}*/}
          {row.hasEdit && <Delete />}
          {row.hasDelete && <DeleteCustom />}
          {/*{(row.revenue && level === 0 && !isTemporary) && <DuplicateButton/>}*/}
        </FinanceTableHeader>
      </TableCell>
    );
  };

    checkDividingWithZero = (row) => {
        if (row && row.data && row.data.Formula && row.data.Formula.includes("|/|")) {
            if (row.hasOwnProperty("children") && row.children.length > 0) {
                const findDriver = row.children.find((d) => row.data.Formula.includes(`|/${d.data.ID_f}`));
                if (findDriver) {
                    findDriver.data.Values.forEach((v) => {
                        if (!v.hasOwnProperty("checkIsDividedWithZero")) {
                            v.checkIsDividedWithZero = true;
                        }
                    });
                }
            }
        }
    };

    Row = (ref_id, row, childIndex, level, isChild = false, isTemporary = false, isTotal = false) => {
        if (isChild) {
            if (ref_id === "none") {
                ref_id = this.getRefID(row);
            }
        } else {
            if (row.revenue && row.revenue.IsTemporary) {
                isTemporary = row.revenue.IsTemporary;
            }
        }

        const rowIndex = this.rowCount++;
        let className = "mct_ft_tablerow";
        let classNameTotals = "totals";
        if (row && row.cssClass) {
            className += " " + row.className;
        }

        if (row && row.cssClass) {
            className += " " + classNameTotals;
        }

        if (this.props.checkIsDividedWithZero) {
            this.checkDividingWithZero(row);
        }

        if (this.props.applyItalic && row && row.data && row.data.UnitType === UnitTypes.Percentage) {
            className = className + this.getItalicClassName();
        }

        return (
            <React.Fragment key={"row_" + row.id + childIndex}>
                <TableRow className={className}>
                    {this.props.showRowHeaders &&
                        this.renderRowHeader(ref_id, row, rowIndex, level, null, isTemporary, isTotal)}

                    {this.props.useDateIndexes == false &&
                        row.data.map((item, index) => this.Cell(item, index, row, row.isEditable))}
                    {this.props.useDateIndexes == true &&
                        this.props.headers.map((header, index) => {
                            let isEditable = row.isEditable;
                            if (header.hasOwnProperty("isEditable")) {
                                isEditable = header.isEditable;
                            }
                            return this.Cell(
                                row.data.getItemByDateSufix(header.sufix),
                                index,
                                row,
                                rowIndex,
                                level,
                                this.props.useActual,
                            );
                        })}
                </TableRow>

                {((this.state.expandedRows[this.getRowIndex(row)] && this.state.expandedRows[this.getRowIndex(row)] === true) ||
                    this.state.isTableExpanded) &&
                  row.children &&
                  row.children
                    .filter((c) => !!c)
                    .map((child, childIndex) =>
                      this.Row(ref_id, child, childIndex, level + 1, true, isTemporary),
                    )}
      </React.Fragment>
    );
  };

    getItalicClassName = () => {
        return ` mct_ft_table_italic`;
    };

    getCalculatedTableWidth = () => {
        if (this.props.disabled) {
            return;
        }
        if (this.props.useCustomizableTable) {
            let calcWidth = LocalStorage.get(tableObject, tableConfig, tableConfigTypes.fontSize);
            let isBlockedWidth = LocalStorage.get(
                tableObject,
                tableConfig,
                tableConfigTypes.showFullNumbers,
            );
            let fullWidth =
                this.props.headers.length *
                (!isBlockedWidth ? 8 : this.MaxLengthOfValue) *
                (calcWidth - (this.props.headers.length > 8 ? 4 : 0)) +
                321 +
                (this.props.headers.length < 8 ? this.props.headers.length * 5 : 0);
            let innerWidth = window.innerWidth - (this.props.title ? 48 : 75);

            if (innerWidth > fullWidth && this.props.headers.length > 8) {
                return innerWidth;
            }

            return fullWidth;
        } else {
            return this.props.headers.length * this.MaxLengthOfValue * 12.5 + 321;
        }
    };

    getTableBody = (rows) => {
        return (
            <TableBody
                onMouseLeave={() => this.mouseLeaveTable()}
                onMouseEnter={() => this.mouseEnterTable()}
                className="mct_ft_tablebody"
            >
                {rows}
            </TableBody>
        );
    };

    expandAll = (e) => {
        if (this.state.isTableExpanded) {
            Object.keys(this.state.expandedRows).forEach((key) => (this.state.expandedRows[key] = false));
        } else {
            Object.keys(this.state.expandedRows).forEach((key) => (this.state.expandedRows[key] = true));
        }
        if (this.checkExpandCollapseStore()) {
            LocalStorage.updateValueFromObject(
                settingsTable,
                this.props.ExpandCollapseID,
                "isTableExpanded",
                !this.state.isTableExpanded,
            );
            Object.keys(this.state.expandedRows).forEach((key) =>
                LocalStorage.updateValueFromObject(
                    settingsTable,
                    this.props.ExpandCollapseID,
                    key,
                  !this.state.isTableExpanded,
                ),
            );
        }

        this.setState({isTableExpanded: !this.state.isTableExpanded});
    };

    checkExpandCollapseStore = () => this.props.useExpandCollapseStore && this.props.ExpandCollapseID;

    getRowIndex = (row) => {
        return `${row.id}-${row.rowIndex}`;
    }


    expandRow = (row, e, rowIndex, level) => {
        if (
          this.props.isCustomDashboard ||
          (row.hasOwnProperty("children") && row.children.length === 0)
        ) {
            if (e) {
                e.preventDefault();
            }
            return;
        }
        if (
          !this.state.dragRow &&
          e.target.classList &&
          (e.target.classList.contains("mct_ft_tablerow_header_container") ||
            e.target.classList.contains("mct_ft_tablerow_header_paragraph") ||
            e.target.classList.contains("mct_ft_tablerow_header_div") ||
            e.target.classList.contains("icon_arrow_down")) &&
          (e.target.classList.value !== "" || !e.target.classList.contains("MuiButtonBase-root"))
        ) {
            this.state.expandedRows[this.getRowIndex(row)] = !this.state.expandedRows[this.getRowIndex(row)];
            if (this.checkExpandCollapseStore()) {
                LocalStorage.updateValueFromObject(
                  settingsTable,
                  this.props.ExpandCollapseID,
                  this.getRowIndex(row),
                  this.state.expandedRows[this.getRowIndex(row)],
                );
            }

            if (row && row.hasOwnProperty("isExpanded")) {
                row.isExpanded = !row.isExpanded;
            }
            if (this.state.expandedRows[this.getRowIndex(row)] && !this.props.isCustomDashboard) {
                setTimeout(() => {
                    let cell = document.getElementsByClassName(`t_${this.props.indexTab}-${rowIndex}_0`)[0];
                    let cell2 = document.getElementsByClassName(
                      `t_${this.props.indexTab}-${rowIndex - 1}_0`,
                    )[0];
                    let element = this.getParentElementByTagName(cell, "TR");
                    let hasSecond = this.getParentElementByTagName(cell2, "TR");
                    // scrollIntoView
                    if (element) {
                        if (hasSecond) {
                            hasSecond.scrollIntoView({behavior: "smooth", block: "start"});
                        } else {
                            element.scrollIntoView({behavior: "smooth", block: "end"});
                        }
                    }
                }, 100);
            }

            if (this.state.isTableExpanded) {
                this.setState({isTableExpanded: false});
            } else {
                this.forceUpdate();
            }
        }
    };

    rowIndex = 0;
    checkExpanded = (child = false) => {
        if (this.totalExpanded >= maxRows) {
            return;
        }

        this.totalExpanded = this.totalExpanded + 1;

        const checkChildren = (child) => {
            if (child.children && child.children.length > 0) {
                child.children.forEach((c) => {
                    this.rowIndex++;
                    c.rowIndex = this.rowIndex;
                    this.checkExpanded(c)
                });
            } else {
                this.rowIndex++;
                child.rowIndex = this.rowIndex;

            }
        };

        const setExpandedRows = (row) => {
            if (this.checkExpandCollapseStore()) {
                if (
                  LocalStorage.getKeyFromObjectLocalStorage(
                    settingsTable,
                    this.props.ExpandCollapseID,
                    this.getRowIndex(row),
                  ) !== null
                ) {
                    const rowValue = LocalStorage.get(settingsTable, this.props.ExpandCollapseID, this.getRowIndex(row));
                    row["isExpanded"] = rowValue;
                    this.state.expandedRows[this.getRowIndex(row)] = rowValue;
                } else {
                    const rowValue = row.hasOwnProperty("isExpanded") ? row.isExpanded : false;
                    LocalStorage.addToLocalStorageObject(
                      settingsTable,
                      this.props.ExpandCollapseID,
                      this.getRowIndex(row),
                      rowValue,
                    );
                    row["isExpanded"] = rowValue;
                    this.state.expandedRows = {...this.state.expandedRows, [this.getRowIndex(row)]: rowValue};
                }
            } else {
                if (row.hasOwnProperty("isExpanded")) {
                    this.state.expandedRows[this.getRowIndex(row)] = row.isExpanded;
                } else {
                    row["isExpanded"] = false;

                    this.state.expandedRows[this.getRowIndex(row)] = false;
                }
            }
        };

        if (child) {
            setExpandedRows(child);
            checkChildren(child);
        } else {
            if (this.props.rows && this.props.rows.length > 0) {
                this.props.rows.forEach((r) => {
                    setExpandedRows(r);
                    checkChildren(r);
                });
            }
        }
    };

  render() {
    this.rowCount = 0;
    this.cellCount = 0;
    this.onMouseDown = false;
    this.rowCountCopy = 0;
    this.rowCountPaste = 0;
    this.lastEntered = 0;
    this.lastExited = 0;
    this.mouseDown = false;
    this.MaxLengthOfValue = 8;
    const rows = this.props.rows
      .filter((c) => !!c)
      .map((row, index) => this.Row(this.getRefID(row), row, index, 0));
    const TableHeader = this.TableHeader();
    this.tableWidth = this.getCalculatedTableWidth();
    const key = this.props.isCustomDashboard ? this.unique_key : this.tableWidth;
    return (
      <Table
        ref={this.tableRef}
        key={key}
        style={{ width: `${this.tableWidth}px` }}
        id={`${this.props.tableID && this.props.tableID}`}
        className={this.props.className ? this.props.className + " mct_ft_table" : "mct_ft_table"}
        stickyHeader={true}
        onKeyUp={this.handleOnKeyUp}
      >
        {TableHeader}
        {this.getTableBody(rows)}
        {/*{(this.props.useDrag && this.state.dragRow) ?*/}
        {/*<PortalApp*/}
        {/*rows={this.props.rows}*/}
        {/*onMouseLeave={this.mouseLeaveTable}*/}
        {/*onDragEnd={this.onDragEnd}*/}
        {/*onMouseEnter={this.mouseEnterTable}*/}
        {/*onDragStart={this.onDragStart}*/}
        {/*key={100000}*/}
        {/*Row={this.Row}*/}
        {/*getRefID={this.getRefID}*/}
        {/*useCustomKPIDates={this.props.useCustomKPIDates}*/}
        {/*className="mct_ft_tablebody"*/}
        {/*/>*/}
        {/*:*/}
        {/*<TableBody onMouseLeave={() => this.mouseLeaveTable()} onMouseEnter={() => this.mouseEnterTable()}*/}
        {/*className="mct_ft_tablebody">*/}
        {/*{this.props.rows.map((row, index) => this.Row(this.getRefID(row), row, index, 0))}*/}
        {/*</TableBody>*/}
        {/*}*/}
        {this.props.totalsRows && (
          <TableBody className="mct_ft_tablebody mct_ft_footer_sticky">
            {this.props.totalsRows
              .filter((c) => !!c)
              .map((row, index) => this.Row(this.getRefID(row), row, index, 0, true, true, true))}
          </TableBody>
        )}
      </Table>
    );
  }
}

FinanceTable.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    rows: PropTypes.array,
    headers: PropTypes.array,
    onDataChanged: PropTypes.func,
    showRowHeaders: PropTypes.bool,
    disabled: PropTypes.bool,
    disabledEdit: PropTypes.bool,
    useDateIndexes: PropTypes.bool,
    useDrag: PropTypes.bool,
    indexTab: PropTypes.number,
    useActual: PropTypes.bool,
    useDashboards: PropTypes.bool,
    isComparativeView: PropTypes.bool,
    checkIsDividedWithZero: PropTypes.bool,
    applyItalic: PropTypes.bool,
    disableExpandAll: PropTypes.bool,
    isCustomDashboard: PropTypes.bool,
    IsNumberFormatDisabled: PropTypes.bool,
    IsReport: PropTypes.bool,
    EditStream: PropTypes.bool,
    useShowHideCurrency: PropTypes.bool,
    disableRowType: PropTypes.bool,
};

FinanceTable.defaultProps = {
    useDateIndexes: false,
    useDrag: false,
    useActual: false,
    useDashboards: false,
    isComparativeView: false,
    checkIsDividedWithZero: false,
    applyItalic: false,
    disableExpandAll: false,
    IsNumberFormatDisabled: false,
    IsReport: false,
    useShowHideCurrency: false,
    EditStream: false,
    disableRowType: false,
};

export default FinanceTable;
