const globalDate = global.Modeliks.DateHelper;

const ActualDates = {
  minDateFinancialStatements:
    globalDate.months_before_actual[0].Year + "-" + (globalDate.months_before_actual[0].Month + 1),
  minYearFinancialStatements: globalDate.years_before_actual[2].Header,
  minYearFinancialStatementsOrder: globalDate.years_before_actual[2].Order,
  maxYearFinancialStatements: globalDate.years_before[0].Header,
  minDate:
    globalDate.months_before_actual[0].Year + "-" + (globalDate.months_before_actual[0].Month + 1),
  maxDate:
    globalDate.months[globalDate.months.length - 1].Year +
    "-" +
    (globalDate.months[globalDate.months.length - 1].Month + 1) +
    "-1",
  minYear: globalDate.years_before_actual[0].Header,
  maxYear: globalDate.years_all[globalDate.years_all.length - 1].Header,
  allMonths: [...globalDate.months_before_actual, ...globalDate.months],
  allYears: [...globalDate.years_before_actual, ...globalDate.years_all],
  FinancialStatements_allYears: [globalDate.years_before_actual[2], ...globalDate.years_before],
};
export { ActualDates };
