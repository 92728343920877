import React from "react";
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-dragdata";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Line } from "react-chartjs-2";
import PropTypes from "prop-types";
import { DataTypes } from "../ChartComponents/DataTypes";

import {
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import LineChart from "./LineChart";

ChartJS.register(
  CategoryScale,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
);

let i = 0;

class StackedBarLineChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        labels: [],
        datasets: [],
      },
      options: this.buildOptions(this.props.chartOptions),
    };

    this.ref = null;
  }

  componentDidMount() {
    this.buildData();
  }

  shouldComponentUpdate(nextPorps, nextState) {
    if (nextPorps != this.props && !this.imgSrc) {
      this.ref.options = this.buildOptions(nextPorps.chartOptions);
      this.buildData(nextPorps);
      this.ref.update();
    }

    return true;
  }

  buildOptions = (props) => {
    if (this.props.preview && this.props.businessPlan) {
      if (window.chartsForRender.indexOf(this.buildOptions) === -1) {
        window.chartsForRender.push(this.buildOptions);
      }
    }
    let options = {
      type: "bar",
      barRoundness: 0.3,
      devicePixelRatio: 2.0,
      maintainAspectRatio: props.lockedAspect ? props.lockedAspect : false,
      elements: {
        point: {
          radius: 5,
        },
      },
      layout: {
        padding: {
          top: props.showTitle || props.showSubtitle ? 5 : 45,
        },
      },
      animation: {
        duration: 1,
        onComplete: (animation) => {
          if (this.props.preview && this.props.businessPlan && this.ref) {
            this.imgSrc = this.ref.toBase64Image();
            this.forceUpdate(() => {
              window.amountOfChartsRendered++;
              global.Modeliks.NotifySubsctiptions("onChartRenderFinish");
            });
          }
        },
      },
      scales: {
        x: {
          grid: {
            display: props.xGrid ? props.xGrid : false,
            drawBorder: props.xGrid ? props.xGrid : true,
          },
          ticks: {
            display: props.showHorizontalAxis,
            color: "#889299",
            font: {
              weight: 400,
              style: "normal",
              size: props.axisFontSize,
              lineHeight: "160%",
            },
          },
        },
        y: {
          stacked: true,
          grid: {
            display: props.yGrid ? props.yGrid : false,
            drawBorder: props.xGrid ? props.xGrid : false,
          },
          ticks: {
            display: props.showVerticalAxis,
            color: "#889299",
            maxTicksLimit: 7,
            minTicksLimit: 4,
            font: {
              weight: 400,
              style: "normal",
              size: props.axisFontSize,
              lineHeight: "160%",
            },
            callback: function (value) {
              if (value >= 1000 && value < 1000000) {
                return value / 1000 + "k";
              } else if (value >= 1000000) {
                return value / 1000000 + "min";
              } else {
                return value;
              }
            },
          },
        },
      },
      plugins: {
        tooltip: {
          titleFont: {
            size: 14,
          },
          bodyFont: {
            size: 14,
          },
        },
        title: {
          display: props.showTitle ? props.showTitle : false,
          text:
            props.title && props.titleAlign && props.titleAlign === "start"
              ? props.title
              : props.title
                ? props.title
                : "",
          align: props.titleAlign ? props.titleAlign : "center",
          color: "#000000",

          font: {
            size: props.titleFontSize,
          },
          padding: {
            bottom: props.showSubtitle && props.showSubtitle === true ? 0 : 45,
          },
        },
        subtitle: {
          display: props.showSubtitle ? props.showSubtitle : false,
          text:
            props.subtitle && props.titleAlign && props.titleAlign === "start"
              ? props.subtitle
              : props.subtitle
                ? props.subtitle
                : "",
          align: props.titleAlign ? props.titleAlign : "center",
          padding: {
            bottom: 45,
          },
          font: {
            size: props.subTitleFontSize,
          },
        },
        datalabels: {
          anchor: "end",
          align: "end",
          formatter: function (value, ctx) {
            if (props.showDataLabels) {
              const sumValue = ctx.chart.config.data.datasets.map((datapoint) => {
                if (datapoint.data[ctx.dataIndex]) {
                  return datapoint.data[ctx.dataIndex];
                } else {
                  return 0;
                }
              });

              function totalSum(total, datapoint) {
                return total + parseFloat(datapoint);
              }

              let sum = sumValue.reduce(totalSum, 0);

              let datasets = ctx.chart.config.data.datasets;

              if (ctx.chart.config.data.datasets[ctx.datasetIndex].order === datasets.length - 1) {
                return (
                  (props.dataType === "currency" ? DataTypes[props.dataType] : "") +
                  sum.toLocaleString(undefined, {
                    minimumFractionDigits: props.datalabelsDecimalPoints,
                    maximumFractionDigits: props.datalabelsDecimalPoints,
                  }) +
                  (props.dataType === "percent" ? DataTypes[props.dataType] : "")
                );
              } else {
                return "";
              }
            } else {
              return "";
            }
          },
          font: {
            size: props.dataLabelsFontSize,
            weight: "700",
            lineHeight: "17px",
          },
        },
        legend: {
          display: props.displayLegend ? props.displayLegend : false,
          position: props.legendPosition ? props.legendPosition : "bottom",
          align: "center",
          labels: {
            usePointStyle: true,
            fontSize: props.legendFontSize,
          },
        },
      },
    };

    return options;
  };

  buildData = (nextProps = null) => {
    let labels = [];
    let datasets = [];
    let rows = this.props.data;
    let colors = this.props.colors;

    rows[0].forEach((label, index) => {
      if (index !== 0) {
        labels.push(label);
      }
    });

    rows.forEach((row, index) => {
      if (index !== 0) {
        if (index >= colors.length) {
          colors = [...colors, ...colors];
          this.props.changeColors(colors);
        }
        let obj = {};
        if (index === 1) {
          obj = {
            label: "",
            type: "bar",
            backgroundColor: colors[index - 1],
            categoryPercentage: 1,
            barPercentage:
              nextProps && nextProps.chartOptions.gapWidth
                ? nextProps.chartOptions.gapWidth / 10
                : this.props.chartOptions.gapWidth
                  ? this.props.chartOptions.gapWidth / 10
                  : 0.3,
            minBarLength: 2,
            borderRadius: 10,
            data: [],
            stack: "combined",
            order: index - 1,
          };
        } else {
          obj = {
            label: "",
            type: "line",
            borderColor: colors[index - 1],
            categoryPercentage: 0.5,
            pointBackgroundColor: colors[index - 1],
            pointBorderColor: colors[index - 1],
            pointBorderWidth: 2,
            data: [],
            stack: "combined",
            order: index - 1,
          };
        }
        row.forEach((data, indexData) => {
          if (indexData === 0) {
            obj.label = data;
          } else {
            let parsedData = parseFloat(data);
            if (parsedData % 1 === 0) {
              obj.data.push(parseInt(data));
            } else {
              obj.data.push(parsedData);
            }
          }
        });
        datasets.push(obj);
      }
    });

    this.state.data.labels = labels;
    this.state.data.datasets = datasets;
  };

  setRef = (ref) => {
    this.ref = ref;
  };

  render() {
    if (this.imgSrc) {
      return <img src={this.imgSrc} style={{ height: "100%", width: "100%" }} />;
    }
    return (
      <Line
        type={"line"}
        data={this.state.data}
        options={this.state.options}
        ref={this.setRef}
        style={{
          background: this.props.chartOptions.backgroundColor,
          border: `2px solid ${this.props.chartOptions.borderColor}`,
        }}
      />
    );
  }
}

StackedBarLineChart.config = {
  data: [
    ["", "label1", "label2", "label3"],
    ["Bar", "1", "2", "3"],
    ["Line 1", "1", "2", "3"],
  ],
  colors: ["red", "green", "blue", "yellow"],
  dataGridConfig: {
    title: "blabla",
    subtitle: "blabla",
    canAddRows: true,
    canAddColumns: true,
    hasColumnHeaders: true,
    hasRowHeaders: true,
    rowSeries: false,
  },
  dataLalbelsConfig: {
    hideSeries: false,
    dataLabelsOptions: false,
  },
  legendAndGridlinesConfig: {
    hasGridlines: true,
  },
  gapWidthConfig: {
    haveGapWidth: true,
  },
  defaultConfig: {
    gapWidth: 3,
  },
};

StackedBarLineChart.propTypes = {
  data: PropTypes.array.isRequired,
  colors: PropTypes.array.isRequired,
};

export default StackedBarLineChart;
