import CalculatedDriver from "../CalculatedDriver/index";
import { DriverCategories, UnitTypes } from "../CalculatedDriver/constants";
import MxIDHelper from "../../MxIDHelper";
import datastructure from "../../datastructure.json";
import FinanceGeneric from "../FinanceGeneric";
import CostSales from "../CostSales";

class WorkingCapital extends FinanceGeneric {
  Name = "";
  ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;

  constructor(db_record) {
    super(db_record);
    if (this.db_record) {
      this.Name = this.db_record.Name;
    }
  }

  static DriversDesc = Object.assign({
    Total: {
      driverName: "$Name",
      fieldName: "Totals",
      driverID: "total",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
  });

  static TableName = datastructure.Finance_WorkingCapital.TableName;

  Save = (callBack, saveDrivers = true) => {
    if (!this.isNew && !this.IsCreated) {
      global.Modeliks.put(this.constructor.TableName, null, this, (res) => {
        if (saveDrivers) {
          this.SaveDrivers(callBack);
        } else {
          callBack();
        }
      });
    } else {
      global.Modeliks.put(this.constructor.TableName, null, this, (res) => {
        if (saveDrivers) {
          this.SaveDrivers(callBack);
        } else {
          callBack();
        }
      });
    }
  };
  createDrivers = (recreate = false) => {
    Object.values(this.constructor.DriversDesc).forEach((driver) => {
      if (this.hasOwnProperty(driver.fieldName) == false || recreate) {
        this[driver.fieldName] = CalculatedDriver.createDriverFromTable(
          this,
          driver.driverID,
          driver.unit,
          driver.category,
        );
      }
    });
  };

  changeDriversName = () => {
    Object.keys(this.constructor.DriversDesc).forEach((key) => {
      const driverDesc = this.constructor.DriversDesc[key];
      if (this[driverDesc.fieldName]) {
        this[driverDesc.fieldName].DriverName = driverDesc.driverName.replace("$Name", this.Name);
      } else {
        console.log("driver not found", this, driverDesc, this[driverDesc.fieldName]);
      }
    });
  };

  SaveNew = (callBack) => {
    global.Modeliks.post(this.constructor.TableName, this, (res) => {
      this.ID = res.id;
      callBack(this);
    });
  };

  static getEmptyWorkingCapital = (callBack) => {
    const wk = WorkingCapital.createNewEmpty();
    wk.SaveNew((record) => {
      return callBack(record);
    });
  };

  static createNewEmpty = () => {
    const newTax = new WorkingCapital();
    newTax.Name = "";
    newTax.ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
    return newTax;
  };
}

export default WorkingCapital;
