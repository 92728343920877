import React from "react";
import ChartEditor from "../../ChartsEditor/ChartsEditor";
import Button from "../../../../components/actions/Button";
import {
  useMenuState,
  Menu,
  MenuButton,
  ControlledMenu,
  SubMenu,
  MenuItem,
} from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import { default as MuiButton } from "@mui/material/Button";
import "./dashboardsBlankAdmin.scss";
import { ArrowDown } from "../../../../components/icons/svgIcons";
import request from "superagent";
import CircleLoader from "../../../../components/Loaders/CircleLoader/CircleLoader";
import DraggableGrid from "./DraggableGrid/DraggableGrid";
import ChartsComponent from "../../../../SlideEditor/components/ChartsComponent";
import { Label } from "../../../../components/typography/label/Label";
import DatePickerYM from "../../../../components/actions/DatePickers/DatePickerYM";

export default class DashboardsBlankAdmin extends React.Component {
  allCharts = [];
  didMount = true;

  constructor(props) {
    super(props);
    const ActualDates = require("../../../Secure/Financials/Actuals/constants").ActualDates;

    this.state = {
      key: new Date().getTime(),
      loading: true,
      chartsEditor: false,
      selectedSubsection:
        props.data[this.props.dataKey].subsections[
          Object.keys(props.data[this.props.dataKey].subsections)[0]
        ],

      minDate: ActualDates.minDate,
      maxDate: ActualDates.maxDate,
      minYear: ActualDates.minYear,
      maxYear: ActualDates.maxYear,

      startMonth: props.curMonths.startMonth,
      endMonth: props.curMonths.endMonth,
      startYear: props.curMonths.startYear,
      endYear: props.curMonths.endYear,

      startMonthOrder: props.curMonths.startMonthOrder,
      endMonthOrder: props.curMonths.endMonthOrder,
      startYearOrder: props.curMonths.startYearOrder,
      endYearOrder: props.curMonths.endYearOrder,

      months: ActualDates.allMonths,
      years: ActualDates.allYears,
      allYears: ActualDates.allYears,
      allMonths: ActualDates.allMonths,

      showMonthly: props.curMonths.showMonthly,
    };

    this.props.curCharts.selectedSubsection = this.state.selectedSubsection;

    this.getAllCharts();
  }

  getAllCharts = () => {
    request
      .get(`/api/master/dashboardcharts`)
      .set("Access-Control-Allow-Origin", window.location.protocol + "//" + window.location.host)
      .set("Accept", "application/json")
      .set("authorization", "Bearer " + window.localStorage.getItem("token"))
      .then((res, err) => {
        this.allCharts = res.body.map((c) => this.getChartParsedData(c));
        this.props.curCharts.allCharts = this.allCharts;
        if (this.didMount) {
          this.setState({ loading: false });
        } else {
          this.state.loading = false;
        }
      });
  };

  SaveChart = (chart, callBack) => {
    request
      .put(`/api/master/dashboardcharts`)
      .query({ ID: chart.ID })
      .send({ Data: JSON.stringify(chart.Data) })
      .set("Access-Control-Allow-Origin", window.location.protocol + "//" + window.location.host)
      .set("Accept", "application/json")
      .set("authorization", "Bearer " + window.localStorage.getItem("token"))
      .then((res, err) => {
        callBack && callBack();
      });
  };

  getChartParsedData = (chart) => {
    return { ...chart, Data: JSON.parse(chart.Data) };
  };

  getCurrentCharts = () => {
    return this.allCharts.filter((c) => this.state.selectedSubsection.charts.hasOwnProperty(c.ID));
  };

  setSelectedSubsection = (index) => {
    this.setState(
      {
        selectedSubsection:
          this.props.data[this.props.dataKey].subsections[
            Object.keys(this.props.data[this.props.dataKey].subsections)[index]
          ],
      },
      () => {
        this.props.curCharts.selectedSubsection = this.state.selectedSubsection;
      },
    );
  };

  handleLayoutChange = (positions, widths) => {
    let currCharts = this.getCurrentCharts();
    console.log("positions", positions, "widths", widths);

    if (Object.keys(widths).length) {
      Object.keys(widths).forEach((k) => {
        currCharts[k].Data.chartConfig.width = widths[k];
        this.SaveChart(currCharts[k]);
      });
    }
    let charts = {};
    currCharts.forEach((chart) =>
      Object.assign(charts, { [chart.ID]: this.state.selectedSubsection.charts[chart.ID] }),
    ); //this.state.selectedSubsection.charts;

    if (Object.keys(positions).length) {
      Object.keys(charts).forEach((k, index) => {
        if (positions[index]) {
          Object.assign(charts[k], positions[index]);
        }
      });

      this.props.saveDashboard();
    }
    // this.forceUpdate();
  };

  handleChartDelete = (id) => {
    delete this.state.selectedSubsection.charts[id];
    this.props.saveDashboard();
  };

  getMonth = (Year, Month) => {
    return this.state.months.find((m) => m.Year === Year && m.Month === Month);
  };

  getYear = (Header) => {
    return this.state.years.find((m) => m.Header === Header.toString());
  };
  changeMonthlyView = () => {
    this.setState({ showMonthly: !this.state.showMonthly });
  };
  changeFromDate = (e) => {
    const Year = e.getFullYear();
    const Month = e.getMonth();
    const startMonth = this.getMonth(Year, Month);

    if (startMonth) {
      this.setState({
        startMonthOrder: startMonth.Order,
        startMonth: `${Year}-${Month + 1}`,
        key: new Date().getTime(),
      });
      this.props.curMonths.startMonthOrder = startMonth.Order;
      this.props.curMonths.startMonth = `${Year}-${Month + 1}`;
      this.props.saveSettings && this.props.saveSettings();
    }
  };
  changeToDate = (e) => {
    const Year = e.getFullYear();
    const Month = e.getMonth();
    const endMonth = this.getMonth(Year, Month);

    if (endMonth) {
      this.setState({
        endMonthOrder: endMonth.Order,
        endMonth: `${Year}-${Month + 1}-1`,
        key: new Date().getTime(),
      });
      this.props.curMonths.endMonthOrder = endMonth.Order;
      this.props.curMonths.endMonth = `${Year}-${Month + 1}-1`;
      this.props.saveSettings && this.props.saveSettings();
    }
  };

  getMonthByYear = (Year) => {
    let month = this.state.months.find((m) => m.Year === Year && m.Order % 12 === 0);
    if (month) {
      return month;
    }
    return this.state.months.find((m) => m.Order === 0);
  };

  changeFromDateYear = (e) => {
    const Year = e.getFullYear();
    const startYear = this.getYear(Year);

    if (startYear) {
      this.setState({
        startYearOrder: startYear.Order,
        startYear: `${startYear.Header}`,
        key: new Date().getTime(),
      });
      this.props.setCurMonths("startYearOrder", startYear.Order);
      this.props.setCurMonths("startYear", startYear.Header);
      this.props.curMonths.startYearOrder = startYear.Order;
      this.props.curMonths.startYear = `${startYear.Header}`;
      this.props.saveSettings && this.props.saveSettings();
    }
  };

  changeToDateYear = (e) => {
    const Year = e.getFullYear();
    const endYear = this.getYear(Year);

    if (endYear) {
      this.setState({
        endYearOrder: endYear.Order,
        endYear: `${endYear.Header}`,
        key: new Date().getTime(),
      });
      this.props.setCurMonths("endYearOrder", endYear.Order);
      this.props.setCurMonths("endYear", endYear.Header);
      this.props.curMonths.endYearOrder = endYear.Order;
      this.props.curMonths.endYear = `${endYear.Header}`;
      this.props.saveSettings && this.props.saveSettings();
    }
  };

  componentDidMount() {
    this.didMount = true;
  }

  getDateRange = () => {
    return {
      dateFrom: {
        month: this.state.months.find((c) => c.Order == this.state.startMonthOrder),
        year: this.state.years.find((c) => c.Order == this.state.startYearOrder),
      },
      dateTo: {
        month: this.state.months.find((c) => c.Order == this.state.endMonthOrder),
        year: this.state.years.find((c) => c.Order == this.state.endYearOrder),
      },
    };
  };

  render() {
    const {
      minDate,
      maxDate,
      minYear,
      maxYear,
      showMonthly,
      startMonth,
      startYear,
      endMonth,
      endYear,
      from,
      fromYear,
      to,
      toYear,
    } = this.state;

    let datePickerMinDate = showMonthly ? new Date(minDate) : new Date(minYear);
    let datePickerMaxDate = showMonthly ? new Date(maxDate) : new Date(maxYear);

    let dateFrom = showMonthly ? startMonth.toString().padStart(2, "0") : startYear;
    let dateTo = showMonthly ? endMonth.toString().padStart(2, "0") : endYear;

    if (this.state.loading) {
      return (
        <div className={"main_circle_loader_container"}>
          <CircleLoader />
        </div>
      );
    }

    this.currentCharts = this.getCurrentCharts();
    if (this.state.chartsEditor) {
      return (
        <ChartEditor
          showIndicatorBoxes={this.props.dataKey === "SingleIndicators"}
          months={this.state.months}
          years={this.state.years}
          dateRange={this.getDateRange()}
          onChartDelete={this.handleChartDelete}
          onChartInsert={this.props.saveDashboard}
          charts={this.currentCharts}
          chartSettings={this.state.selectedSubsection.charts}
        />
      );
    }
    return (
      <div className={"dashboard_container"} key={this.state.key}>
        <div className={"d_row"}>
          <Menu
            menuButton={
              <MuiButton>
                {this.state.selectedSubsection.name} <ArrowDown />
              </MuiButton>
            }
          >
            {Object.values(this.props.data[this.props.dataKey].subsections).map((c, index) => (
              <MenuItem key={"dash_" + index} onClick={() => this.setSelectedSubsection(index)}>
                {c.name}
              </MenuItem>
            ))}
          </Menu>

          <div className={`psfac_actuals_single_filter vertical gap`}>
            <Label style={{ marginBottom: 0 }} color={"black"} size="label3" label={"From"} />
            <DatePickerYM
              minDate={datePickerMinDate}
              maxDate={datePickerMaxDate}
              views={showMonthly ? ["year", "month"] : ["year"]}
              value={dateFrom}
              onChange={showMonthly ? this.changeFromDate : this.changeFromDateYear}
            />
          </div>

          <div className={`psfac_actuals_single_filter vertical gap`}>
            <Label style={{ marginBottom: 0 }} color={"black"} size="label3" label={"To"} />
            <DatePickerYM
              minDate={datePickerMinDate}
              maxDate={datePickerMaxDate}
              views={showMonthly ? ["year", "month"] : ["year"]}
              value={dateTo}
              onChange={showMonthly ? this.changeToDate : this.changeToDateYear}
            />
          </div>
          <Button
            label={"Edit Charts"}
            size="large"
            width="150px"
            padding={"0 0 0 0"}
            backgroundColor="primary"
            onClick={() =>
              this.props.onChartEdit(this.props.dataKey, this.state.selectedSubsection.charts)
            }
          />
        </div>
        <div className={"charts_grid"}>
          <DraggableGrid
            enableDrag
            enableResize
            onLayoutChange={this.handleLayoutChange}
            items={this.currentCharts.map((chart) => {
              return (
                <ChartsComponent
                  showIndicatorBoxes={this.props.dataKey === "SingleIndicators"}
                  dateRange={this.getDateRange()}
                  months={this.state.months}
                  years={this.state.years}
                  grid
                  selectedDashboardChart={chart}
                />
              );
            })}
            widths={this.currentCharts.map((chart) => chart.Data.chartConfig.width)}
            positions={this.currentCharts.map(
              (chart) => this.state.selectedSubsection.charts[chart.ID],
            )}
          />
        </div>
      </div>
    );
  }
}
