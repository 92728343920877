import React from "react";
import "../../../Admin/Dashboards/components/dashboardsBlankAdmin.scss";
import CircleLoader from "../../../../components/Loaders/CircleLoader/CircleLoader";
import "../FinancialStatements/index.scss";
import DisplayCustomKPI from "./DisplayCustomKPI";
import FinancialStatementsReport from "../../../../data/Finance/Dashboards/FinancialStatements";
import CustomKPI from "../../../../data/Finance/Dashboards/CustomKPI";
import AddChartDialog from "../CustomDashboards/AddChartDialog";

const Loader = (
  <div className={"main_circle_loader_container"}>
    <CircleLoader />
  </div>
);

export default class CustomKPIMain extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      key: new Date().getTime(),
      loading: true,
      selectedSubsection: props.CustomKPI.selectedKPI,
    };
  }

  componentDidMount() {
    this.setSelectedSubsection();
  }

  setSelectedSubsection = (section, callBack) => {
    if (section) {
      this.props.CustomKPI.selectedKPI = section;
      callBack();
      // this.setState({selectedSubsection: section}, callBack())
    } else {
      // this.props.CustomKPI.selectedKPI = this.props.CustomKPI.allCustomKPI[0];
      setTimeout(() => {
        this.setState({ loading: false });
      }, 100);
    }
  };

  onChangeView = () => {
    this.props.financialMonths.showMonthly = !this.props.financialMonths.showMonthly;
    this.updateDataGrid();
  };

  updateDataGrid = () => {
    // this.props.CustomKPI.selectedKPI.gridRows = this.props.setReports[this.props.FinancialStatements.selectedSubsection.key]();
    const { financialMonths, CustomKPI } = this.props;
    const selectedTotals = CustomKPI.selectedKPI.Totals;
    const dateObject = {
      ...financialMonths,
      allMonths: this.props.allMonths,
      allYears: this.props.allYears,
    };
    CustomKPI.dataGrid = FinancialStatementsReport.getDashboardDrivers(
      selectedTotals.getChildDrivers(),
      dateObject,
      true,
    );
    this.forceUpdate();
  };

  updateSelectedKPI = (find, callBack) => {
    const { financialMonths, CustomKPI } = this.props;
    const selectedTotals = CustomKPI.selectedKPI.Totals;
    const dateObject = {
      ...financialMonths,
      allMonths: this.props.allMonths,
      allYears: this.props.allYears,
    };
    CustomKPI.dataGrid = FinancialStatementsReport.getDashboardDrivers(
      selectedTotals.getChildDrivers(),
      dateObject,
      true,
    );
    callBack && callBack(CustomKPI);
  };

  changeSelectedCustomKPI = (id, callBack) => {
    this.setState({ loading: true });
    const findKPI = global.Modeliks.CustomKPIStore.find((k) => k.ID === id);
    if (!findKPI) {
      return console.log("no kpi", id, callBack);
    }
    this.props.CustomKPI.selectedKPI = findKPI;
    this.state.selectedSubsection = findKPI;
    this.updateSelectedKPI(findKPI, (CustomKPI) => {
      setTimeout(() => {
        this.setState({ loading: false }, callBack && callBack(CustomKPI));
      }, 100);
    });
  };

  deleteCustomKPI = (customKPI) => {
    this.state.loading = true;
    this.props.CustomKPI.allCustomKPI = [];
    this.props.handleStreamLoad && this.props.handleStreamLoad(true);

    const dateObject = {
      ...this.props.financialMonths,
      allMonths: this.props.allMonths,
      allYears: this.props.allYears,
    };

    customKPI.Delete(() => {
      global.Modeliks.LoadDrivers(() => {
        this.props.CustomKPI.allCustomKPI = global.Modeliks.CustomKPIStore;
        this.props.setFinancialStatements();
        if (this.props.CustomKPI.allCustomKPI.length > 0) {
          this.props.CustomKPI.selectedKPI = this.props.CustomKPI.allCustomKPI[0];
          this.props.CustomKPI.dataGrid = FinancialStatementsReport.getDashboardDrivers(
            this.props.CustomKPI.selectedKPI.Totals.getChildDrivers(),
            dateObject,
            true,
          );
          this.setState(
            { loading: false },
            this.props.handleStreamLoad && this.props.handleStreamLoad(false),
          );
        } else {
          this.props.CustomKPI.dataGrid = null;
          this.setState(
            { loading: false },
            this.props.handleStreamLoad && this.props.handleStreamLoad(false),
          );
        }
      });
    });
  };

  createCustomKPI = (KPI) => {
    this.state.loading = true;
    this.props.CustomKPI.allCustomKPI = [];
    this.props.handleStreamLoad && this.props.handleStreamLoad(true);
    KPI.changeDriversName();
    KPI.Save(() => this.loadCustomKPI(KPI.ID));
  };

  createNewKPI = (callBack) => {
    CustomKPI.getEmptyKPI((kpi) => {
      callBack(kpi);
    });
  };

  loadCustomKPI = (id) => {
    global.Modeliks.LoadDrivers(() => {
      this.props.CustomKPI.allCustomKPI = global.Modeliks.CustomKPIStore;
      this.props.setFinancialStatements();

      if (id) {
        this.changeSelectedCustomKPI(
          id,
          this.setState(
            { loading: false },
            this.props.handleStreamLoad && this.props.handleStreamLoad(false),
          ),
        );
      } else {
        this.updateSelectedKPI(
          id,
          this.setState(
            { loading: false },
            this.props.handleStreamLoad && this.props.handleStreamLoad(false),
          ),
        );
      }
    });
  };

  toggleDialog = () => {
    this.setState({ openDialog: !this.state.openDialog });
  };

  getCustomKPI = () => {
    if (!this.props.CustomKPI.dataGrid && this.props.customDashboard) {
      this.updateDataGrid();
    }
    return (
      <DisplayCustomKPI
        onDeleteTable={this.props.onDeleteTable}
        loader={Loader}
        deleteCustomKPI={this.deleteCustomKPI}
        loadCustomKPI={this.loadCustomKPI}
        createCustomKPI={this.createCustomKPI}
        handleStreamLoad={this.props.handleStreamLoad}
        updateDataGrid={this.updateDataGrid}
        defaultView={this.props.defaultView}
        saveDates={this.props.saveDates}
        CustomKPI={this.props.CustomKPI}
        createNewKPI={this.createNewKPI}
        reload={() => this.setState({ key: new Date().getTime() })}
        updateSelectedKPI={this.updateSelectedKPI}
        customDashboard={this.props.customDashboard}
        changeSelectedCustomKPI={this.changeSelectedCustomKPI}
        addTableToCustomDashboard={this.toggleDialog}
        setReports={this.props.setReports}
        financialMonths={this.props.financialMonths}
        showMonthly={this.props.financialMonths.showMonthly}
        titleDate={this.props.titleDate}
        onChangeView={this.onChangeView}
        FinancialStatements={this.props.FinancialStatements}
        setSelectedSubsection={this.setSelectedSubsection}
        selectedSubsection={this.props.CustomKPI.selectedKPI}
        {...this.props}
      />
    );
  };

  render() {
    if (this.state.loading || global.Modeliks.isLoading) {
      return Loader;
    }
    if (!global.Modeliks.CustomKPIStore.length > 0) {
      return this.getCustomKPI();
    }
    return (
      <div className={"dashboard_container apply_responsive"} key={this.state.key}>
        {this.state.openDialog && (
          <AddChartDialog
            handleClose={this.toggleDialog}
            kpiTableInfo={{
              ID: this.props.CustomKPI.selectedKPI.ID,
              defaultView: this.props.defaultView,
            }}
          />
        )}
        {this.getCustomKPI()}
      </div>
    );
  }
}
