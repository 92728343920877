import { Command } from "../../models/Command";

export class MoveSelectedObjects extends Command {
  _section = null;
  constructor({ actionForward, actionBackward, objects }) {
    super({ actionForward, actionBackward, objects });
  }

  async execute() {
    const { actionForward, objects } = this.args;
    objects.forEach((object) => {
      const { newChange } = object;
      actionForward(object.key, newChange.top, newChange.left);
    });
  }

  async undo() {
    console.log("MoveSelectedObjects undo command");
    const { actionBackward, objects } = this.args;
    objects.forEach((object) => {
      const { prevChange, newChange } = object;
      actionBackward(object.key, prevChange.top, prevChange.left);
    });
  }

  getInfo() {
    return "This is MoveSelectedObjects command";
  }
}
