import React, { useState } from "react";
import styled from "styled-components";
import { Theme } from "../../../theme/styles/theme";
import { IconButton } from "@mui/material";
import { ArrowDown } from "../../icons/svgIcons";
import "./chartmenu.scss";
import { Vector } from "../../dividers/Vector";

const SyledP = styled.p`
  ${(props) => (props.strong ? Theme.text.paragraph.p4_strong : Theme.text.paragraph.p4)};
  font-family: Inter;
  line-height: 100%;
`;

export const ShowChartMenu = ({ ...props }) => {
  return (
    <div className="sccc_chart_menu">
      <div className="sccc_chart_icon">
        <SyledP strong>{"Chart"}</SyledP>
        <IconButton onClick={props.onChange} sx={{ color: "#A9A9A9" }} size="medium">
          <ArrowDown width="14" height="14" />
        </IconButton>
      </div>
      <div className="sccc_chart_divider">
        <Vector color="#A9A9A9" />
      </div>
    </div>
  );
};
