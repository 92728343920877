import React from "react";
import FinanceTable from "../../../../../../../components/tables/FinanceTable";

class SP_Driver_Control extends React.Component {
  constructor(props) {
    super(props);

    this.rows = props.rows.map((row) => {
      return {
        id: row.ID,
        header: row.hasOwnProperty("RevenueName") ? row.RevenueName : row.Name,
        field: this.props.field,
        data: this.props.field ? row[this.props.field] : row,
      };
    });

    this.totalsRows = [
      {
        id: props.tab.dataGridRow.data.ID,
        header: props.tab.hasOwnProperty("chartTitle") ? props.tab.chartTitle : props.tab.subtitle,
        cssClass: "totals",
        boldRowHeader: true,
        data: props.tab.dataGridRow.data,
      },
    ];
  }

  render() {
    return (
      <>
        <div className="dialog_table_container" onScroll={this.props.onScroll}>
          {this.props.showSpace && <p className="psrcrsmcs_space_tables" />}
          <FinanceTable
            key={`${this.props.hasOwnProperty("tab") && this.props.tab.errors.simpleValue}-${this.props.hasOwnProperty("tab") && this.props.tab.errors.hasOwnProperty("simpleValueUpdated") && this.props.tab.errors.simpleValueUpdated}`}
            className="sticky"
            title={this.props.title}
            onDataChanged={this.props.onChange}
            disableExpandAll={true}
            headers={this.props.headers}
            showRowHeaders={true}
            displayIn={'%'}
            disabledEdit={false}
            useCustomizableTable={true}
            useDateIndexes={true}
            min={this.props.min}
            max={this.props.max}
            rows={this.rows}
            indexTab={3000}
            totalsRows={this.totalsRows}
          />
        </div>
        {this.props.tab &&
        this.props.tab.hasOwnProperty("errors") &&
        this.props.tab.errors.simpleValue ? (
          <p className="psrcrsmcs_error_values">
            The % breakdown should be equal to 100% in every month and year
          </p>
        ) : (
          <p className="psrcrsmcs_error_values" />
        )}
      </>
    );
  }
}

export default SP_Driver_Control;
