import MxDataArray from "../../MxDataArray";
import Valuation from "./index";

class ValuationSettingsStorage extends MxDataArray {
  dataManager = null;
  driverStorage = null;
  loaded = false;

  setData = (callBack, data = this.data) => {
    this.pushArray(data.map((item) => new Valuation(item)));
    this.loaded = true;
    callBack();
  };

  load = (callBack, shouldFinish = true) => {
    this.clear();
    this.dataManager.get(
      this.dataManager.Tables.Valuation_Settings.TableName,
      { ID_CompanyScenario: this.dataManager.CompanyScenarioInfo.ID },
      (data) => {
        if (shouldFinish) {
          this.setData(callBack, data);
        } else {
          this.data = data;
          callBack();
        }
      },
      null,
      false,
    );
  };

  static create = () => {
    const newArr = new ValuationSettingsStorage();
    newArr.pushOld = newArr.push;
    newArr.push = newArr.pushThis;

    return newArr;
  };
}

export default ValuationSettingsStorage;
