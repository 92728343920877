import React, { Fragment, useState } from "react";
// import RightMenuPortal from "./RightMenuPortal.js";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import InputBox from "../InputBoxComponent/InputBox";
import { FlipHorizontally, FlipVertically, Rotate90 } from "../IconButtons/SubMenuIcons";
import "./SpecialCharacters.css";

function SpecialCharacters(props) {
  const [openSpecialCharacters, setOpenSpecialCharacters] = React.useState(false);
  const specialSymbols = [
    ["←", "↑", "→", "↓", "↔", "↕", "↖", "↗", "↘", "↙"],
    ["↚", "↛", "↜", "↝", "↞", "↟", "↠", "↡", "↢", "↣"],
    ["↤", "↥", "↦", "↧", "↨", "↩", "↪", "↫", "↬", "↭"],
    ["↮", "↯", "↰", "↱", "↲", "↳", "↴", "↵", "↶", "↷"],
    ["↸", "↹", "↺", "↻", "↼", "↽", "↾", "↿", "⇀", "⇁"],
    ["⇂", "⇃", "⇄", "⇅", "⇆", "⇇", "⇈", "⇉", "⇊", "⇋"],
    ["⇌", "⇍", "⇎", "⇏", "⇐", "⇑", "⇒", "⇓", "⇔", "⇕"],
    ["⇖", "⇗", "⇘", "⇙", "⇚", "⇛", "⇜", "⇝", "⇞", "⇟"],
    ["⇠", "⇡", "⇢", "⇣", "⇤", "⇥", "⇦", "⇧", "⇨", "⇩"],
    ["⇪", "⇫", "⇬", "⇭", "⇮", "⇯", "⇰", "⇱", "⇲", "⇳"],
    ["⇴", "⇵", "⇶", "⇷", "⇸", "⇹", "⇺", "⇻", "⇼", "⇽"],
    ["⇾", "⇿", "▢", "▣", "△", "▲", "◎", "◐", "☀", "☁"],
  ];

  // const cursorPosition = () => {
  //     var sel = document. getSelection();
  //     var pos = sel. toString(). length;
  //     if(sel. anchorNode != undefined) sel. collapseToEnd();
  //     return pos;
  // }

  function doGetCaretPosition(selection) {
    // Initialize
    var iCaretPos = 0;

    // IE Support
    //     var selection=document.getSelection()

    // To get cursor position, get empty selection range
    var oSel = selection.createRange();

    // Move selection start to 0 position
    oSel.moveStart("character", -props.value.length);

    // The caret position is selection length
    iCaretPos = oSel.text.length;

    // Firefox support
    // else if (oField.selectionStart || oField.selectionStart == '0')
    //     iCaretPos = oField.selectionDirection=='backward' ? oField.selectionStart : oField.selectionEnd;

    // Return results
    return iCaretPos;
  }

  // const cursor_position = (sel) => {
  //     sel.modify("extend", "backward", "paragraphboundary");
  //     var pos = sel.toString().length;
  //     if(sel.anchorNode != undefined) sel.collapseToEnd();
  //
  //     return pos;
  // }

  // const handleOnClick = (symbol, selection) => {
  //     let arr =[]
  //     let caret = doGetCaretPosition();
  //     for(let i=0; i<caret; i++){
  //         arr.push()
  //     }
  // }

  const saveSelection = () => {
    if (window.getSelection) {
      let sel = window.getSelection();
      if (sel.getRangeAt && sel.rangeCount) {
        return sel.getRangeAt(0);
      }
    } else if (document.selection && document.selection.createRange) {
      return document.selection.createRange();
    }
    return null;
  };

  const restoreSelection = (range) => {
    if (range) {
      if (window.getSelection) {
        let sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
      } else if (document.selection && range.select) {
        range.select();
      }
    }
  };

  const handleOnClick = (symbol) => {
    props.addSymbol(symbol);
  };

  const handleClickSpecialCharacters = () => {
    if (openSpecialCharacters) {
      setOpenSpecialCharacters(false);
    } else {
      setOpenSpecialCharacters(true);
      props.rightMenuFunc.closeSpecialCharacters = null;
      Object.values(props.rightMenuFunc).map((value) => {
        if (typeof value === "function") {
          value.call();
        }
      });
    }
  };

  const closeMenu = () => {
    setOpenSpecialCharacters(false);
  };

  props.rightMenuFunc.closeSpecialCharacters = closeMenu;

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      onKeyDown={(e) => {
        e.stopPropagation();
      }}
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
    >
      <ListItemButton id={"listItem"} onClick={handleClickSpecialCharacters}>
        <ListItemText primary="Special Characters" />
        {openSpecialCharacters ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <div style={{ width: "230px", border: "1px solid #F0F1F7", marginLeft: "18px" }} />
      <Collapse in={openSpecialCharacters} timeout="auto" unmountOnExit>
        <div className={"collapse"}>
          <div className={"listSubHeading"}>Insert special characters</div>
          <div id={"specialCharactersGrid"}>
            {specialSymbols.map((elem) => {
              return (
                <div id={"specialCharactersRow"}>
                  {elem.map((el) => {
                    return (
                      <span onClick={(event) => handleOnClick(el)} id={"specialCharGrid"}>
                        {el}
                      </span>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </Collapse>
    </div>
  );
}

export default SpecialCharacters;
