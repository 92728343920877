import styled from "styled-components";

const FirstSectionStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FirstSection = (props) => {
  return (
    <FirstSectionStyled className={props.className} style={props.style}>
      {props.children}
    </FirstSectionStyled>
  );
};
