import React from "react";
import styled from "styled-components";
import { Theme } from "../../../../../theme/styles/theme";
import AddDialog from "../../../../../components/dialogs/financials/AddDialog/AddDialog";
import { AddDialogTitle } from "../../../../../components/dialogs/financials/AddDialog/AddDialogTitle";
import { AddDialogContent } from "../../../../../components/dialogs/financials/AddDialog/AddDialogContent";
import CircleCheckboxM from "../../../../../components/actions/CircleCheckboxM";
import Button from "../../../../../components/actions/Button";
import { DialogActions } from "@mui/material";
import "./navigation.scss";
import IconButton from "@mui/material/IconButton";
import {
  IconX,
  Upload,
  Refresh,
  XCircle,
  Check,
  Download,
} from "../../../../../components/icons/svgIcons";
import { Paragraph } from "../../../../../components/typography/paragraph/Paragraph";
import UploadButton from "../../../../../components/actions/UploadButton";
import BalanceSheet from "../../../../../data/Finance/Reports/BalanceSheet";
import ProfitLoss from "../../../../../data/Finance/Reports/ProfitLoss";
import CashFlow from "../../../../../data/Finance/Reports/CashFlow";
import * as XLSX from "xlsx";
import { UnitTypes } from "../../../../../data/Finance/CalculatedDriver/constants";
import { ReportsTypes } from "../../../../../data/Finance/Reports/constants";
import Reports from "../../../../../data/Finance/Reports";
import ButtonMui from "../../../../../components/buttons/buttonMui/buttonMui";

const SyledP = styled.p`
  ${Theme.text.paragraph.p2_strong};
  color: #252525;
  margin-bottom: ${(props) => props.margin && 0};
`;
const CheckBoxDiv = styled.div`
  padding: 7px 0 7px 5px;
`;

const checkBoxValues = {
  import: "import_excel",
  download: "download_template",
};

class ImportActuals extends React.Component {
  constructor(props) {
    super(props);

    this.ButtonChanges = {
      Generate: {
        label: "Generate Report",
        icon: "FileText",
        onClick: (e) => {
          let report = global.Modeliks.ReportsStore.find(
            (r) => r.Name === this.props.selectedSubsection,
          );
          if (!report) {
            report = global.Modeliks.ReportsStore.find(
              (r) => r.ReportType === ReportsTypes.ProfitLoss,
            );
          }

          return report.get_export_report_data(
            {
              getGridData: false,
              isActual: true,
              data: props.rows,
              isTmpData: false,
              isMultipleExport: false,
              EditableFields: true,
            },
            true,
            this.props.periods,
            this.props.curMonths.showAllDrivers,
          );
        },
      },
      GeneratingReport: {
        label: "Generating Report",
        icon: "Refresh",
        onClick: () => console.log("click"),
      },
      DownloadReport: {
        label: "Download Report",
        icon: "Download",
        onClick: () => console.log("click"),
      },
      SelectFile: {
        label: "Select File",
        icon: "Upload",
        disabled: false,
        onClick: (e) => {
          if (
            e &&
            e.type &&
            e.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ) {
            this.setState(
              { file: e.name, files: e, activeButton: this.ButtonChanges.UploadFile, error: false },
              () => this.ButtonChanges.UploadFile.onClick(),
            );
          } else {
            this.setState({ error: true });
          }
        },
      },
      InsertFile: {
        label: "Inset File",
        icon: "Upload",
        disabled: false,
        onClick: (e) => {

        },
      },
      UploadFile: {
        label: "Upload File",
        icon: "Upload",
        disabled: false,
        onClick: (e) => {
          let nameFile = this.state.files;

          const reader = new FileReader();
          reader.onload = (e) => {
            // evt = on_file_select event
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: "binary" });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            delete ws["A1"];
            delete ws["A3"];
            /* Convert array of arrays */
            const data2 = XLSX.utils.sheet_to_json(ws, { header: 4 });
            /* Update state */
            this.setState({ Upload: data2, activeButton: this.ButtonChanges.ValidateReport }, () =>
              this.ButtonChanges.ValidateReport.onClick(),
            );
          };
          reader.readAsBinaryString(nameFile);
        },
      },
      ValidateReport: {
        label: "Validate Report",
        icon: "Refresh",
        disabled: false,
        onClick: () => {
          if (!this.state.Upload) {
            return null;
          }

          let error = false;

          if (this.state.Upload.length > 0) {
            if (!Object.values(this.state.Upload[0]).includes("ID")) {
              error = true;
            }
          } else {
            error = true;
          }

          if (error) {
            this.setState({ error: true });
          } else {
            this.setState({ activeButton: this.ButtonChanges.UpdatingDatabase });
          }
        },
      },
      UpdatingDatabase: {
        label: "Save & Continue",
        icon: "XCircle",
        disabled: false,
        onClick: () => {
          this.setState({disableActions: true}, () => {
            if (this.state.Upload.length > 0) {
              let index = 0;
              const firstRowDates = Object.values(this.state.Upload[0]);
              const allDates = [
                ...global.Modeliks.DateHelper.years_before_actual,
                ...global.Modeliks.DateHelper.years_before,
                ...global.Modeliks.DateHelper.years,
                ...global.Modeliks.DateHelper.months,
                ...global.Modeliks.DateHelper.months_before_actual,
              ];
              const fileDates = allDates.filter((date) => firstRowDates.indexOf(date.Header) > -1);
              this.state.Upload.forEach((row, i) => {
                const driver = global.Modeliks.DriversStore.getItem(row["__EMPTY_1"]);
                if (!driver || driver.IsTemporary || i === 0) {
                  index = index + 1;
                  if (this.state.Upload.length === index) {
                    this.setState({ open: false }, () => this.setInitialState());
                    // this.setState({ activeButton: this.ButtonChanges.DatabaseUpdated }, () =>
                    //     this.ButtonChanges.DatabaseUpdated.onClick(),
                    // );
                  }
                } else {
                  let dateIndex = 0;
                  fileDates.forEach((month) => {
                    const curMonth = driver.getItemByDateSufix(month.sufix);
                    const curValue =
                        row[
                            Object.keys(this.state.Upload[0]).find(
                                (k) => this.state.Upload[0][k] === month.Header,
                            )
                            ];
                    if (curMonth.Actual !== curValue) {
                      if (typeof curValue === "number") {
                        if (curMonth.Unit === UnitTypes.Percentage) {
                          if (curValue <= 100 && curValue >= 0) {
                            curMonth.Actual = curValue;
                          }
                        } else {
                          curMonth.Actual = curValue;
                        }
                      } else {
                        console.error("not number", curValue);
                      }
                    }

                    dateIndex = dateIndex + 1;

                    if (fileDates.length === dateIndex) {
                      driver.Save(() => {
                        index = index + 1;
                        if (this.state.Upload.length === index) {
                          this.setState({ open: false }, () => this.setInitialState());
                          // this.setState({ activeButton: this.ButtonChanges.DatabaseUpdated,disableActions: false }, () =>
                          //     this.ButtonChanges.DatabaseUpdated.onClick(),
                          // );
                        }
                      });
                    }
                  });
                }
              });
            } else {
              this.setState({ open: false }, () => this.setInitialState());
              // this.setState({ activeButton: this.ButtonChanges.DatabaseUpdated,disableActions: false }, () =>
              //     this.ButtonChanges.DatabaseUpdated.onClick(),
              // );
            }
          })

        },
      },
      DatabaseUpdated: {
        label: "Database Updated!",
        disabled: true,
        icon: "Check",
        onClick: () => {
          this.setState({ activeButton: this.ButtonChanges.CloseAndContinue });
        },
      },
      CloseAndContinue: {
        label: "Close & Continue",
        icon: "XCircle",
        onClick: () => {
          this.setState({ open: false }, () => this.setInitialState());
        },
      },
    };

    this.state = {
      open: false,
      loading: false,
      activeCheckBox: checkBoxValues.download,
      activeButton: this.ButtonChanges.Generate,
      showAllDrivers: true,
      disableActions: false,
      file: null,
      Upload: [],
      error: false,
      allMonths: [...global.Modeliks.DateHelper.months, ...global.Modeliks.DateHelper.years_all],
      months: [...global.Modeliks.DateHelper.months, ...global.Modeliks.DateHelper.years_all],
    };
  }

  componentDidMount() {
    this.getMonths();
  }

  setInitialState = () => {
    this.state.Upload = [];
    this.state.error = false;
    this.state.file = null;
    this.state.activeCheckBox = checkBoxValues.download;
    this.state.activeButton = this.ButtonChanges.Generate;
    this.state.months = [
      ...global.Modeliks.DateHelper.months,
      ...global.Modeliks.DateHelper.years_all,
    ];
    this.props.reload();
    this.setState({ open: false,disableActions: false });
    // this.onChangeDialog();
  };

  onChangeDialog = (close = false) => {
    this.setState({ open: !this.state.open });
  };

  onChangeCheckbox = (e) => {
    let button = this.ButtonChanges.Generate;

    if (e.target.value === checkBoxValues.import) {
      button = this.ButtonChanges.SelectFile;
    }
    this.setState({ activeCheckBox: e.target.value, activeButton: button });
  };
  handleRemoveFile = () => {
    this.setState({ file: null, activeButton: this.ButtonChanges.SelectFile, error: null });
  };

  getMonths = (
    start = this.props.curMonths.showMonthly
      ? this.props.curMonths.startMonthOrder
      : this.props.curMonths.startYearOrder,
    end = this.props.curMonths.showMonthly
      ? this.props.curMonths.endMonthOrder
      : this.props.curMonths.endYearOrder,
  ) => {
    if (this.props.curMonths.showMonthly) {
      return this.props.months.filter(
        (m) => m.Order >= start && m.Order <= end && m.PeriodType === "month",
      );
    } else {
      return this.props.years.filter(
        (m) => m.Order >= start && m.Order <= end && m.PeriodType === "year",
      );
    }
  };

  render() {
    const { label, icon, onClick, disabled } = this.state.activeButton;

    return (
      <>
        <ButtonMui
          tooltipText="Import Actuals from Excel"
          style={{ height: "34px", fontSize: "clamp(10px, 0.9vw, 14px)" }}
          disabled={!global.Modeliks.PERMISSIONS.Dashboards.restrictions.edit.active}
          variant={"contained"}
          label="Import Actuals"
          onClick={this.onChangeDialog}
        />
        <AddDialog open={this.state.open} onClose={!this.state.disableActions && this.onChangeDialog}>
          <AddDialogTitle title="Import Actuals from Excel" onClose={!this.state.disableActions && this.onChangeDialog} />
          <AddDialogContent>
            <SyledP>What do you want to do?</SyledP>

            <CheckBoxDiv>
              <CircleCheckboxM
                size="medium"
                value={checkBoxValues.download}
                checked={this.state.activeCheckBox === checkBoxValues.download}
                onChange={this.onChangeCheckbox}
                label="Download Excel Template"
              />
            </CheckBoxDiv>
            <CheckBoxDiv>
              <CircleCheckboxM
                size="medium"
                value={checkBoxValues.import}
                checked={this.state.activeCheckBox === checkBoxValues.import}
                onChange={this.onChangeCheckbox}
                label="Import Excel File"
              />
            </CheckBoxDiv>

            {this.state.activeCheckBox === checkBoxValues.import && (
              <div className="psfaci_import_container">
                <div className="psfaci_import_div">
                  {this.state.file && (
                    <>
                      <Paragraph color={"#01AFFD"} size="p3_strong" label={this.state.file} />

                      <IconButton size="small" onClick={!this.state.disableActions && this.handleRemoveFile}>
                        <IconX />
                      </IconButton>
                    </>
                  )}
                </div>
                {this.state.error && (
                  <>
                    <Paragraph
                      color={"red"}
                      size="p3_strong"
                      label={"not valid file please import our excel template"}
                    />
                  </>
                )}
              </div>
            )}
          </AddDialogContent>

          <DialogActions className="psfaci_actions">
            {label === this.ButtonChanges.SelectFile.label ? (
              <UploadButton
                label={label}
                icon={icon}
                width="227px"
                handleFile={(e) => onClick(e)}
              />
            ) : (
              <Button
                size="large"
                padding="7.5px 25px 7.5px 25px"
                backgroundColor="primary"
                width="227px"
                disable={disabled || this.state.disableActions}
                icon={icon}
                label={label}
                onClick={(e) => onClick(e)}
                iconLeft={true}
              />
            )}
          </DialogActions>
        </AddDialog>
      </>
    );
  }
}

export default ImportActuals;
