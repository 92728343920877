import * as React from "react";
import { Routes, Route, Outlet, Link } from "react-router-dom";
import { NavigationF } from "../../../components/containers/NavigationF";
import { Box } from "../../../components/containers/Box";
import { Vector } from "../../../components/dividers/Vector";
import SubTabs from "../../../components/tabs/SubTabs";
import "../Financials/Financials.scss";
import CircleLoader from "../../../components/Loaders/CircleLoader/CircleLoader";
import FooterContent from "../Financials/components/Footer";
import SettingsMain from "./Settings";
import CostOfCapitalMain from "./CostOfCapital";
import DiscountedCashFlowMain from "./DiscountedCashFlow";
import TradingMultiplesMain from "./TradingMultiples";
import TransactionMultiplesMain from "./TransactionMultiples";
import OverviewMain from "./Overview";
import DiscountedCash from "../../../data/Finance/Valuation/DiscountedCash";
import { RefBaseTypes } from "../../../data/Finance/Valuation/Overview/constants";
import Valuation from "../../../data/Finance/Valuation";

const layout_side = "/valuation";

const valuation_paths = {
  overview: "/overview",
  settings: "/settings",
  cost_of_capital: "/cost_of_capital",
  discounted_cash_flow: "/discounted_cash_flow",
  trading_multiples: "/trading_multiples",
  transaction_multiples: "/transaction_multiples",
};

const key = "valuation";

export default class ValuationMain extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };

    this.valuationObject = {
      valuationSection: {
        Overview: {
          name: "Overview",
          refBase: Valuation.getValuationSettings().ReferenceBaseValue,
          refBaseKey: Object.keys(RefBaseTypes).find(
            (key) =>
              RefBaseTypes[key].value === Valuation.getValuationSettings().ReferenceBaseValue.Value,
          ),
        },
        Settings: {
          name: "Settings",
        },
        CostOfCapitalWACC: {
          DiscountedCash: DiscountedCash.getDiscountedCashFlowDriver(),
        },
      },
    };
  }

  componentDidMount() {
    this.setState({ loading: false });
  }

  getValuationPathElements = () => {
    const { Overview } = this.valuationObject.valuationSection;
    return {
      routes: [
        {
          path: valuation_paths.overview,
          element: (
            <OverviewMain
              Overview={Overview}
              dataKey={"Overview"}
              key={"ovw_" + this.props.ctrl_key}
            />
          ),
        },
        {
          path: valuation_paths.settings,
          element: <SettingsMain dataKey={"Settings"} key={"sts_" + this.props.ctrl_key} />,
        },
        {
          path: valuation_paths.cost_of_capital,
          element: (
            <CostOfCapitalMain dataKey={"CostOfCapital"} key={"cosv_" + this.props.ctrl_key} />
          ),
        },
        {
          path: valuation_paths.discounted_cash_flow,
          element: (
            <DiscountedCashFlowMain
              dataKey={"DiscountedCashFlow"}
              key={"dcf_" + this.props.ctrl_key}
            />
          ),
        },
        {
          path: valuation_paths.trading_multiples,
          element: (
            <TradingMultiplesMain dataKey={"TradingMultiples"} key={"trm_" + this.props.ctrl_key} />
          ),
        },
        {
          path: valuation_paths.transaction_multiples,
          element: (
            <TransactionMultiplesMain
              dataKey={"TransactionMultiples"}
              key={"tscm_" + this.props.ctrl_key}
            />
          ),
        },
      ],
      tabs: [
        {
          link: `${layout_side}${valuation_paths.overview}`,
          color: "black",
          size: "p3",
          label: "Overview",
          key,
        },
        {
          link: `${layout_side}${valuation_paths.settings}`,
          color: "black",
          size: "p3",
          label: "Settings",
          key,
        },
        {
          link: `${layout_side}${valuation_paths.cost_of_capital}`,
          color: "black",
          size: "p3",
          label: "Cost of Capital",
          key,
        },
        {
          link: `${layout_side}${valuation_paths.discounted_cash_flow}`,
          color: "black",
          size: "p3",
          label: "Discounted Cash Flow",
          key,
        },
        {
          link: `${layout_side}${valuation_paths.trading_multiples}`,
          color: "black",
          size: "p3",
          label: "Trading Multiples",
          key,
        },
        {
          link: `${layout_side}${valuation_paths.transaction_multiples}`,
          color: "black",
          size: "p3",
          label: "Transaction Multiples",
          key,
        },
      ],
    };
  };

  getRenderedValuationRoutes = () => {
    return this.getValuationPathElements().routes.map((route) => (
      <Route key={`f_route_${route.path}`} exact path={route.path} element={route.element} />
    ));
  };

  handleStreamLoad = (value, callBack) => {
    this.setState({ loading: value }, callBack && callBack());
  };

  render() {
    if (this.state.loading) {
      return (
        <div className={"main_circle_loader_container"}>
          <CircleLoader />
        </div>
      );
    }

    return (
      <Routes>
        <Route
          path="/"
          element={
            <ValuationLayout
              defaultUrl={this.props.defaultUrl}
              handleStreamLoad={this.handleStreamLoad}
              valuationElements={this.getValuationPathElements()}
            />
          }
        >
          {this.getRenderedValuationRoutes()}
        </Route>
      </Routes>
    );
  }
}

function ValuationLayout(props) {
  return (
    <div className={"grid_container"}>
      <NavigationF hidden={props.loading}>
        <Box className="spsi_financials_navigation_streams_div">
          <SubTabs defaultUrl={props.defaultUrl} mainPath={`${layout_side}`}>
            {props.valuationElements}
          </SubTabs>
        </Box>
        <div id="export_portal" />
      </NavigationF>

      <Outlet />
      <FooterContent showButton={false} disabled={false} disablePrev={true} showMonthly={false} />
    </div>
  );
}
