import React from "react";
import { DataGrid } from "@mui/x-data-grid";

export default function DataGridM(props) {
  return (
    <div
      style={{
        width: "auto",
        height:
          props.rows.length > 0 && props.rows.length <= 4
            ? props.rows.length * 150 + "px"
            : "300px",
      }}
    >
      <DataGrid
        loading={props.columns.length === 0}
        rowHeight={38}
        columns={props.columns}
        rows={props.rows}
        pageSize={5}
        getRowId={(row) =>
          Object.values(
            row[props.columns[0].field] + props.rows.length > 0
              ? row[props.columns[1].field] + Math.random(0, 100)
              : "",
          )
        }
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
      />
    </div>
  );
}
