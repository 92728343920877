import React from "react";
import { Pie } from "react-chartjs-2";
import "chartjs-plugin-dragdata";
import ChartDataLabels from "chartjs-plugin-datalabels";
import PropTypes from "prop-types";
import { DataTypes } from "../ChartComponents/DataTypes";

import {
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import StackedBarChart from "./StackedBarChart";
import DoughnoutChart from "./DoughnutChart";

ChartJS.register(
  CategoryScale,
  BarElement,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
);

class PieChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        labels: [],
        datasets: [],
      },
      options: this.buildOptions(this.props.chartOptions),
    };

    this.ref = null;
  }

  componentDidMount() {
    this.buildData();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps != this.props && !this.imgSrc) {
      this.ref.options = this.buildOptions(nextProps.chartOptions);
      this.buildData();
      this.ref.update();
    }

    return true;
  }

  buildOptions = (props) => {
    if (this.props.preview && this.props.businessPlan) {
      if (window.chartsForRender.indexOf(this.buildOptions) === -1) {
        window.chartsForRender.push(this.buildOptions);
      }
    }
    let options = {
      type: "pie",
      // title:{
      //     display:true,
      //     text:'Average Rainfall per month',
      //     fontSize:20
      // },
      devicePixelRatio: 2.0,
      maintainAspectRatio: props.lockedAspect ? props.lockedAspect : false,
      layout: {
        padding: {
          top: 5,
        },
      },
      animation: {
        duration: 1,
        onComplete: (animation) => {
          if (this.props.preview && this.props.businessPlan && this.ref) {
            this.imgSrc = this.ref.toBase64Image();
            this.forceUpdate(() => {
              window.amountOfChartsRendered++;
              global.Modeliks.NotifySubsctiptions("onChartRenderFinish");
            });
          }
        },
      },
      scales: {
        x: {
          display: false,
          grid: {
            display: props.xGrid ? props.xGrid : false,
            drawBorder: props.xGrid ? props.xGrid : false,
          },
          ticks: {
            display: props.showHorizontalAxis,
            color: "#889299",
            font: {
              weight: 400,
              style: "normal",
              size: props.axisFontSize,
              lineHeight: "160%",
            },
          },
        },
        y: {
          display: false,
          grid: {
            display: props.yGrid ? props.yGrid : false,
            drawBorder: props.xGrid ? props.xGrid : false,
          },
          ticks: {
            display: props.showVerticalAxis,
            maxTicksLimit: 4,
            color: "#889299",
            font: {
              weight: 400,
              style: "normal",
              size: props.axisFontSize,
              lineHeight: "160%",
            },
            callback: function (value) {
              if (value >= 1000 && value < 1000000) {
                return value / 1000 + "K";
              } else if (value >= 1000000) {
                return value / 1000000 + "M";
              } else {
                return value;
              }
            },
          },
        },
      },
      plugins: {
        tooltip: {
          titleFont: {
            size: 14,
          },
          bodyFont: {
            size: 14,
          },
        },
        title: {
          display: props.showTitle ? props.showTitle : false,
          text: props.title ? props.title : "",
          align: props.titleAlign ? props.titleAlign : "center",
          color: "#000000",

          font: {
            size: props.titleFontSize,
          },
          padding: {
            bottom: 0,
          },
        },
        subtitle: {
          display: props.showSubtitle ? props.showSubtitle : false,
          text: props.subtitle ? props.subtitle : "",
          align: props.titleAlign ? props.titleAlign : "center",
          font: {
            size: props.subTitleFontSize,
          },
          padding: {
            bottom: 0,
          },
        },
        datalabels: {
          anchor: props.DataLabelsPosition,
          align: props.DataLabelsPosition,
          formatter: function (value, ctx) {
            if (props.showDataLabels) {
              let val = parseFloat(value);
              return (
                (props.dataType === "currency" ? DataTypes[props.dataType] : "") +
                val.toLocaleString(undefined, {
                  minimumFractionDigits: props.datalabelsDecimalPoints,
                  maximumFractionDigits: props.datalabelsDecimalPoints,
                }) +
                (props.dataType === "percent" ? DataTypes[props.dataType] : "")
              );
            } else {
              return "";
            }
          },
          color: "#252525",
          font: {
            size: props.dataLabelsFontSize,
            weight: "700",
            lineHeight: "17px",
          },
        },
        legend: {
          display: props.displayLegend ? props.displayLegend : false,
          position: props.legendPosition ? props.legendPosition : "bottom",
          align: "center",
          labels: {
            usePointStyle: true,
            fontSize: props.legendFontSize,
          },
        },
      },
    };

    return options;
  };

  buildData = () => {
    let rows = this.props.data;
    let colors = this.props.colors;
    if (rows[1].length >= colors.length) {
      colors = [...colors, ...colors];
      this.props.changeColors(colors);
    }
    this.state.data.labels = rows[0];
    let datasets = [
      {
        data: rows[1],
        backgroundColor: colors,
      },
    ];
    this.state.data.datasets = datasets;
  };

  setRef = (ref) => {
    this.ref = ref;
  };

  render() {
    if (this.imgSrc) {
      return <img src={this.imgSrc} style={{ height: "100%", width: "100%" }} />;
    }
    return (
      <Pie
        type={"pie"}
        data={this.state.data}
        options={this.state.options}
        ref={this.setRef}
        style={{
          background: this.props.chartOptions.backgroundColor,
          border: `2px solid ${this.props.chartOptions.borderColor}`,
        }}
      />
    );
  }
}

PieChart.config = {
  data: [
    ["series1", "series2", "series3", "series4"],
    [25, 25, 25, 25],
  ],
  colors: ["orange", "blue", "green", "red"],
  dataGridConfig: {
    title: "blabla",
    subtitle: "blabla",
    canAddRows: false,
    canAddColumns: true,
    hasColumnHeaders: false,
    hasRowHeaders: true,
    ColumnHeader: "New series",
    rowSeries: true,
  },
  dataLalbelsConfig: {
    hideSeries: false,
    dataLabelsOptions: [
      { value: "Automatic", name: "Automatic" },
      { value: "end", name: "Outside" },
      { value: "middle", name: "Inside" },
    ],
  },
  legendAndGridlinesConfig: {
    hasGridlines: false,
  },
  gapWidthConfig: {
    haveGapWidth: false,
  },
};

DoughnoutChart.propTypes = {
  data: PropTypes.array.isRequired,
  colors: PropTypes.array.isRequired,
};

export default PieChart;
