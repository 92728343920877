import React, { Component } from "react";
import Dialog from "@mui/material/Dialog";
import "./LimitDialogs/CompanyLimit.scss";
import { Exit } from "./../../SlideEditor/components/components/IconButtons/SubMenuIcons";
import IconButton from "@mui/material/IconButton";
import ButtonMui from "./../buttons/buttonMui/buttonMui";

class WelcomeUserDialog extends Component {
  constructor() {
    super();

    this.state = {
      open: true,
    };
  }

  handleClose = () => {
    global.HelpHero.startTour("mGOfD1W36O");
    this.setState({ open: false });
  };
  render() {
    return (
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={this.state.open}
        className={"company_limit_dialog center"}
        onClose={this.handleClose}
      >
        <IconButton onClick={this.handleClose} className={"cld_close_button"}>
          <Exit />
        </IconButton>

        <div className={"p1_strong cld_heading"}>Welcome to Modeliks!</div>

        <div className={"cld_typography"}>
          <p>We are excited to have you on board!</p>
          <p>Get started by exploring the various features and modules.</p>
        </div>

        <div className={"video_container"}>
          <iframe
            src="https://www.youtube.com/embed/RTyPcjm7Uyc"
            title="Welcome to Modeliks"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
        <div className={"cld_typography"}>
          <p>
            If you have any questions, our support team is here to assist you or consult our{" "}
            <a href={"https://help.modeliks.com/"}>help center</a> for guidance. We also offer
            live-chat during our business hours.
          </p>
        </div>
        <div className={"cld_typography2_buttons"}>
          <ButtonMui
            label={"Get started"}
            width={160}
            variant={"contained"}
            onClick={this.handleClose}
          />
        </div>
      </Dialog>
    );
  }
}

export default WelcomeUserDialog;
