import React from "react";
import SP_Driver_Control from "../../components/SP_Driver_Control";
import SP_Driver_Values_Control from "../../components/SP_Driver_Values_Control";
import SP_Driver_BD_Component from "../../components/SP_Driver_BD_Component";

class SubscriptionPrice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      breakDown: false,
    };
  }

  changeBrakeDown = () => {
    this.state.breakDown = !this.state.breakDown;
    this.forceUpdate();
  };

  onChangeOverAllValues = () => {
    console.log("onChangeOverAllValues");
  };

  render() {
    return (
      <>
        {this.state.breakDown ? (
          <SP_Driver_Values_Control
            rows={this.props.childrens}
            field={this.props.field}
            onChange={this.onChangeOverAllValues}
          />
        ) : (
          <SP_Driver_Control
            rows={this.props.childrens}
            field={this.props.field}
            headers={this.props.headers}
            onChange={this.props.onDataChanged}
          />
        )}
        <SP_Driver_BD_Component breakDown={this.state.breakDown} onChange={this.changeBrakeDown} />
      </>
    );
  }
}

export default SubscriptionPrice;
