import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";
import "./index.scss";

class Index extends Component {
  constructor(props) {
    super(props);

    this.windowLocation = window.location.href.split("/");
    this.isFreeTrial = this.windowLocation[this.windowLocation.length - 1] === "1";

    this.state = {
      link: null,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ link: "/VerificationSent" });
    }, 3000);
  }

  render() {
    if (this.state.link) {
      return (
        <>
          <Navigate to={this.state.link} />
          {this.setState({ link: null })}
        </>
      );
    } else {
      return (
        <div className={"lp_container"}>
          <img className={"lp_logoImage"} src={require("./images/LandingLogo.png")} alt="" />

          <p className={"lp_paragraph"}>
            {this.isFreeTrial
              ? "Thank You For Signing Up For The Free Trial"
              : "Thank You For Your Purchase"}
          </p>

          <div>
            <img
              className={"lp_HandHoldingImage"}
              src={require("./images/LandingFreeTrial.png")}
              alt=""
            />
          </div>
        </div>
      );
    }
  }
}

export default Index;
