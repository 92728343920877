const UnitTypes = {
  Hide: "hide",
  Percentage: "percentage",
  Price: "price",
  PriceNegative: "price_negative",
  Units: "units",
  Integer: "integer",
  Decimal: "decimals",
  GrowthValue: "growth",
  Multiple: "multiple",
};

const DriverCategories = {
  Sum: "sum",
  Average: "avg",
  LastPeriod: "last-period",
  FirstPeriod: "first-period",
  WeightedAverage: "weighted_average",
};

const DriverCategoriesSelect = [
  { value: DriverCategories.Sum, description: "Sum" },
  { value: DriverCategories.Average, description: "Average" },
  { value: DriverCategories.FirstPeriod, description: "Opening Balance" },
  { value: DriverCategories.LastPeriod, description: "Closing Balance" },
];

const DriverPeriodTypes = {
  Current: 1,
  Previous: 2,
};

const DriverPeriodTypesSelect = [
  { value: DriverPeriodTypes.Current, description: "Current" },
  { value: DriverPeriodTypes.Previous, description: "Previous" },
];

const SpecialChar = {
  DriverNameESCChar: "_",
};

const DriverDescriptions = {
  DriverNameDesc:
    "Drivers are factors that influence your business and financial performance. Here, you should define all the drivers that you require to set a formula to calculate this revenue or cost line.  For example, if you are trying to calculate a revenue line based on the number of clients and the average spend per client, create two drivers: (1) Number of clients; (2) Average spend per client.",
  DataTypeDesc:
    "Define the driver data type. It could be: $ (currency data type); % (percent data type); or, # (a number of units data type). For example, an average price driver would be a $ data type. A conversion rate driver would be a % data type. Number of clients driver would be a # data type.",
  CategoryDesc: (
    <div className="psfco_opening_balance_description">
      <p>
        Defining the driver category helps Modeliks correctly calculate the annual numbers for the
        first year, when your inputs are on monthly basis. The driver category can be: Average, Sum,
        Closing Balance or Opening Balance.
      </p>
      <p>
        If you select Average category type, Modeliks will calculate the first year annual number as
        an average of the monthly input numbers. If you select a Sum, Modeliks will add up the
        monthly inputs to calculate the annual number. If you select Opening Balance, Modeliks will
        calculate the annual number as equal to the first month input. If you choose Closing
        Balance, the annual number will be equal to the last month input.
      </p>
    </div>
  ),
  PeriodDesc: (
    <div className="psfco_opening_balance_description">
      <p>
        The period selection can be Current Period or Previous Period. In most cases, Current Period
        is the correct choice.
      </p>
      <p>
        If you select Current Period, Modeliks will use the inputs in the current period to for the
        calculations. For example, if you want to create a formula where your consulting revenue is
        calculated using two drivers: (1) Number of clients; (2) Average spend per client and you
        select Current Period for both drivers. Then your consulting revenue for the month of
        January will be equal to the Number of Clients in January, multiplied by the Average spend
        in January.
      </p>
      <p>
        Previous period is used only when you want the formula to take into consideration the value
        of a previous period for a specific driver. For example, if you want to set a formula to
        calculate the number of clients your business will have by the following formula:
      </p>
      <p>
        Number of clients at end of period = Number of clients at beginning of period + New clients
        in the period – Lost clients in the period.
      </p>
      <p>
        In this case, you would set the Number of clients at begging of the period as a Previous
        Period driver and make it equal to the Number of clients at end of period. For the month of
        February, because Number of clients at beginning of period is a Previous Period driver, it
        will equal the Number of clients at end of period in January (the previous period).
      </p>
      <p>All other drivers in this example will remain as current period drivers.</p>
    </div>
  ),
  SetFormula:
    "Set formula using the Drivers you defined above. In the Create Formula section, click on the Drivers and the Operators to add them to the formula bar to define the formula. Drag them out of the formula bar to remove them from the formula. You can also drag and drop them within the formula bar to change their order in the formula. Once you have defined the formula, click the Set Formula button in the bottom right corner.",
};

const temporary_api_route = "/api/cdv/temporary_cdvs";

export {
  UnitTypes,
  DriverCategories,
  SpecialChar,
  DriverPeriodTypes,
  DriverCategoriesSelect,
  DriverPeriodTypesSelect,
  DriverDescriptions,
  temporary_api_route,
};
