import MxDataArray from "../../../MxDataArray";

class DiscountedCashStorage extends MxDataArray {
  dataManager = null;
  driverStorage = null;
  loaded = true;

  static create = () => {
    const newArr = new DiscountedCashStorage();
    newArr.pushOld = newArr.push;
    newArr.push = newArr.pushThis;

    return newArr;
  };
}

export default DiscountedCashStorage;
