import React from 'react';
import {DialogContent, DialogTitle, Link} from "@mui/material";
import ButtonMui from "../../../buttons/buttonMui/buttonMui";

function NoAccountDialog(props) {
	return (
		<>
			<DialogTitle sx={{padding: '0 0 20px'}}>
				You're currently not associated to any account
			</DialogTitle>
			<DialogContent>
				This could be due to either:
				<ul>
					<li>The associated account was deleted.</li>
					<li>Your access permissions to the linked account were revoked.</li>
				</ul>
				<div className={'btn_container'}>
					<ButtonMui onClick={() => {
						props.onAccept && props.onAccept()
					}} width={118} label={'OK'} variant={'contained'}/>
				</div>
				<br/>
				<div>
					If you are having trouble,{' '}
					{window.HubSpotConversations ? <Link className={'is_link'} onClick={(e) => {
							global.Modeliks.toggleChatBot && global.Modeliks.toggleChatBot(e);
						}}> please get in touch</Link> :
						<a className={'is_link'} href={"https://help.modeliks.com/"} target={'_blank'}>please get in touch</a>}. We
					are here to help!
				</div>
			</DialogContent>
		</>
	);
}

export default NoAccountDialog;