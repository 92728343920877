const FiancingCategories = {
  PropertyPlantAndEquipment: "property-plant-equipment",
  Vehicles: "vehicles",
  Furniture: "furniture",
  Investments: "investments",
  Intangibles: "intangibles",
};

const FinancingTypes = {
  Loan: "loan",
  Investments: "investments",
  LineOfCredit: "line-of-credit",
  OtherLiability: "other-liability",
  Dividends: "dividends",
};

const FinanceTermType = {
  Current: "current",
  LongTerm: "long-term",
};

const PayBackOptions = [
  { value: "12", description: "Monthly" },
  { value: "4", description: "Quarterly" },
  { value: "2", description: "Semi Annually" },
  { value: "1", description: "Annually" },
];

const FinancialMonths = [
  { value: "1", description: "1 months" },
  { value: "2", description: "2 months" },
  { value: "3", description: "3 months" },
  { value: "4", description: "4 months" },
  { value: "5", description: "5 months" },
  { value: "6", description: "6 months" },
  { value: "7", description: "7 months" },
  { value: "8", description: "8 months" },
  { value: "9", description: "9 months" },
  { value: "10", description: "10 months" },
  { value: "11", description: "11 months" },
  { value: "12", description: "12 months" },
];

const FinancialMonthsAndYears = [
  { value: "0", description: "0 months" },
  { value: "1", description: "1 months" },
  { value: "2", description: "2 months" },
  { value: "3", description: "3 months" },
  { value: "4", description: "4 months" },
  { value: "5", description: "5 months" },
  { value: "6", description: "6 months" },
  { value: "7", description: "7 months" },
  { value: "8", description: "8 months" },
  { value: "9", description: "9 months" },
  { value: "10", description: "10 months" },
  { value: "11", description: "11 months" },
  { value: "12", description: "12 months" },
  { value: "24", description: "2 years" },
  { value: "36", description: "3 years" },
  { value: "48", description: "4 years" },
  { value: "50", description: "5 years" },
  { value: "62", description: "6 years" },
  { value: "74", description: "7 years" },
  { value: "86", description: "8 years" },
  { value: "98", description: "9 years" },
  { value: "112", description: "10 years" },
];

const Messages = {
  validateCreditLimit:
    "You are repaying more than you owe the bank. Please reduce the repayments or increase the withdrawals.",
  validateMonthlyRepayments:
    "You cannot exceed the credit limit since there is no input to limit the line of credit to certain amount.",
};

export {
  FinancialMonths,
  FinancialMonthsAndYears,
  PayBackOptions,
  FinancingTypes,
  FinanceTermType,
  Messages,
};
