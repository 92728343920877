import React, { Fragment, useState } from "react";
import AddColumn from "../components/IconButtons/AddColumn";
import AddRow from "../components/IconButtons/AddRow";
import HeaderPortal from "./HeaderPortal";
import { Button } from "@mui/material";

function HeaderTableEditor(props) {
  const selected = props.visible;

  const onChange = (style) => {
    props.onChange(style);
  };

  return (
    <Fragment>
      {/*<AddColumn onClick={props.onColumnAdd}/>*/}
      {/*<AddRow onClick={props.onRowAdd}/>*/}
      {/*<Button onClick={props.mergeCells}>Merge</Button>*/}
    </Fragment>
  );
}

export default HeaderTableEditor;
