import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "../../../../../../components/actions/Button";
import "./AddCalculatedDriver.scss";
import ButtonMui from "../../../../../../components/buttons/buttonMui/buttonMui";
import { IconPlus } from "../../../../../../components/icons/svgIcons";

class AddDriver extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      anchorEl: null,
    };
  }

  openAddDriver = (e) => {
    if (this.props.customIndicator) {
      this.props.setExistingDriver();
    } else {
      this.setState({ open: true, anchorEl: e.target });
    }
  };

  closeAddDriver = () => {
    this.setState({ open: false, anchorEl: null });
  };

  addNewDriver = () => {
    this.props.setNewDriver();
  };

  addExistingDriver = () => {
    this.props.setExistingDriver();
  };

  render() {
    return (
      <div className={"driver_card"}>
        <ButtonMui
          endIcon={<IconPlus />}
          width={135}
          label="Add Driver"
          onClick={this.openAddDriver}
          variant={"contained"}
        />
        <Menu
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={this.closeAddDriver}
          id="add_driver_menu"
          PaperProps={{
            className: "menu_paper",
          }}
        >
          <MenuItem className="driver_item" onClick={this.addNewDriver}>
            Create New
          </MenuItem>
          <MenuItem className="driver_item" onClick={this.addExistingDriver}>
            Select from existing
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

export default AddDriver;
