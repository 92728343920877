import MxDataArray from "./MxDataArray";

const ShortMonths = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const ComparativeView = (currency = "$", diff = "difference") => [
  "Actual",
  "Forecast",
  `${currency} ${diff}`,
  `% ${diff}`,
  "Previous Period",
  `${currency} ${diff}`,
  `% ${diff}`,
  "Previous Year",
  `${currency} ${diff}`,
  `% ${diff}`,
];

const PeriodTypes = {
  month: "month",
  year: "year",
};

const TerminalValueHeader = "Terminal Value";

class MxDate {
  Month = null;
  Year = null;
  PeriodType = null;
  Header = null;
  dateIndex = null;
  dateID = null;

  constructor(month, year, periodType) {
    console.log("month", month, year, periodType);
    this.Month = month;
    this.Year = year;
    this.PeriodType = periodType;
    if (this.PeriodType == PeriodTypes.month) {
      this.Header = ShortMonths[this.Month] + " " + this.Year.toString().substring(2);
    } else {
      this.Header = month == 0 ? this.Year : this.Year + 1;
    }

    DateHelper.setDateIndex(this);
    DateHelper.setDateID(this);
  }
}

const DateHelper = {
  months: null,
  months_all: null,
  months_before: null,
  months_before_actual: null,
  years: null,
  years_before: null,
  years_before_actual: null,
  years_all: null,
  all: null,
  ActiveYears: null,
  // getDateIndex: (obj) => `${obj.Year}-${obj.Month.toLocaleString('en-US', {
  //     minimumIntegerDigits: 2,
  //     useGrouping: false
  // })}-${obj.PeriodType}`,
  getDateIndex: (obj) => {
    return `${obj.Year}-${("0" + obj.Order).slice(-2)}-${obj.PeriodType}`;
  },
  setHeader: (obj) => {
    if (obj.PeriodType == PeriodTypes.month) {
      obj.Header = ShortMonths[obj.Month] + " " + obj.Year.toString().substring(2);
    } else {
      obj.Header = obj.Month == 0 ? obj.Year.toString() : (obj.Year + 1).toString();
    }
  },
  setDateIndex: (obj) => (obj.dateIndex = DateHelper.getDateIndex(obj)),
  setDateID: (obj) => (obj.dateID = `${obj.Order}-${obj.PeriodType}`),
  buildMonths: (startingMonth, startingYear, length, startOrder = 0, active = true) => {
    const months = [];

    if (length < 0) {
      const startingLength = -length;
      if (startOrder == 0) {
        startOrder = length;
      }
      while (length <= -1) {
        startingMonth--;
        if (startingMonth < 0) {
          startingYear--;
          startingMonth = 11;
        }
        length++;
      }

      length = startingLength;
    }

    for (var i = 0; i < length; i++) {
      const new_month = {
        Month: startingMonth,
        Header: ShortMonths[startingMonth] + " " + startingYear.toString().substring(2),
        Year: startingYear,
        PeriodType: PeriodTypes.month,
        Order: startOrder + i,
        Active: active,
        sufix: "m_" + (startOrder + i),
      };
      DateHelper.setDateIndex(new_month);
      DateHelper.setDateID(new_month);

      months.push(new_month);
      startingMonth++;
      if (startingMonth > 11) {
        startingMonth = 0;
        startingYear++;
      }
    }

    return months;
  },
  buildMonthsComparative: (
    startingMonth,
    startingYear,
    length,
    startOrder = 0,
    active = true,
    currency,
  ) => {
    const months = [];

    if (length < 0) {
      const startingLength = -length;
      if (startOrder == 0) {
        startOrder = length;
      }
      while (length <= -1) {
        startingMonth--;
        if (startingMonth < 0) {
          startingYear--;
          startingMonth = 11;
        }
        length++;
      }

      length = startingLength;
    }

    for (var i = 0; i < length; i++) {
      const new_month = {
        Month: startingMonth,
        Header: ComparativeView(currency)[i],
        Year: startingYear,
        PeriodType: PeriodTypes.month,
        Order: startOrder + i,
        Active: active,
        // useValue: i !== 1 && i !== 2,
        // useValue: false,

        checked: true,
        sufix: "m_" + (startOrder + i),
      };
      DateHelper.setDateIndex(new_month);
      DateHelper.setDateID(new_month);
      months.push(new_month);
      startingMonth++;
      if (startingMonth > 11) {
        startingMonth = 0;
        startingYear++;
      }
    }

    return months;
  },
  buildQuarterlyMonths: (startingMonth, startingYear, length, startOrder = 0, active = true) => {
    const months = [];
    if (length < 0) {
      const startingLength = -length;
      if (startOrder == 0) {
        startOrder = length;
      }
      while (length <= -1) {
        startingMonth--;
        if (startingMonth < 0) {
          startingYear--;
          startingMonth = 11;
        }
        length++;
      }

      length = startingLength;
    }

    for (var i = 0; i < length; i += 3) {
      let getShortMonth =
        startingMonth >= 10 ? ShortMonths.length - startingMonth : startingMonth + 2;
      const Header =
        ShortMonths[startingMonth] +
        "-" +
        ShortMonths[startingMonth >= 10 ? Math.abs(getShortMonth - 2) : startingMonth + 2] +
        " " +
        " " +
        startingYear.toString().substring(2);
      const new_month = {
        Month: startingMonth,
        Header: Header,
        Year: startingYear,
        PeriodType: PeriodTypes.month,
        Order: startOrder + i,
        Active: active,
        HeaderMonths: [
          ShortMonths[startingMonth],
          ShortMonths[startingMonth >= 10 ? Math.abs(getShortMonth - 1) : startingMonth + 1],
          ShortMonths[startingMonth >= 10 ? Math.abs(getShortMonth - 2) : startingMonth + 2],
          startingYear.toString().substring(2),
        ],
        sufix: "m_" + (startOrder + i),
      };
      DateHelper.setDateIndex(new_month);
      DateHelper.setDateID(new_month);
      months.push(new_month);
      startingMonth = startingMonth + 3;
      if (startingMonth > 11 && startingMonth <= 12) {
        startingMonth = 0;
        startingYear++;
      } else if (startingMonth > 12 && startingMonth <= 13) {
        startingMonth = 1;
        startingYear++;
      } else if (startingMonth > 13 && startingMonth <= 14) {
        startingMonth = 2;
        startingYear++;
      }
    }

    return months;
  },
  buildYears: (startingMonth, startingYear, length, startOrder = 0, active = true) => {
    const years = [];
    for (var i = 0; i < length; i++) {
      const new_year = {
        Month: startingMonth,
        Year: startingYear,
        Header: startingMonth == 0 ? startingYear.toString() : (startingYear + 1).toString(),
        PeriodType: PeriodTypes.year,
        Order: startOrder + i,
        Active: active,
        sufix: "y_" + (startOrder + i),
      };
      DateHelper.setDateIndex(new_year);
      DateHelper.setDateID(new_year);
      years.push(new_year);
      startingYear++;
    }

    return years;
  },
  buildDates: (companyInfo) => {
    if (companyInfo == null) {
      return null;
    }
    console.log("companyInfo", companyInfo);
    DateHelper.ActiveYears = companyInfo.ActiveYears;
    DateHelper.years_before = DateHelper.buildYears(
      companyInfo.StartMonth,
      companyInfo.StartYear,
      companyInfo.MonthlyDetailsYears,
      0,
      false,
    );
    DateHelper.years_before_actual = DateHelper.buildYears(
      companyInfo.StartMonth,
      companyInfo.StartYear - 3,
      3,
      -3,
      false,
    );
    DateHelper.years = DateHelper.buildYears(
      companyInfo.StartMonth,
      companyInfo.StartYear + companyInfo.MonthlyDetailsYears,
      companyInfo.ForecastPeriodYears - companyInfo.MonthlyDetailsYears,
      DateHelper.years_before[DateHelper.years_before.length - 1].Order + 1,
    );

    DateHelper.months = DateHelper.buildMonths(
      companyInfo.StartMonth,
      companyInfo.StartYear,
      companyInfo.MonthlyDetailsYears * 12,
    );
    DateHelper.months_after = DateHelper.buildMonths(
      companyInfo.StartMonth,
      companyInfo.StartYear + companyInfo.MonthlyDetailsYears,
      (companyInfo.ForecastPeriodYears - companyInfo.MonthlyDetailsYears) * 12,
      DateHelper.months[DateHelper.months.length - 1].Order + 1,
      false,
    );

    let startMonth = companyInfo.StartMonth;
    let startYear = companyInfo.StartYear;
    if (startMonth < 0) {
      startMonth = 0;
      startYear--;
    }

    DateHelper.months_before = global.Modeliks.DateHelper.buildMonths(startMonth, startYear, -48);
    DateHelper.months_before_actual = global.Modeliks.DateHelper.buildMonths(
      startMonth,
      startYear,
      -12,
    );
    DateHelper.months_quarterly_view = [
      ...DateHelper.buildQuarterlyMonths(startMonth, startYear, -12),
      ...DateHelper.buildQuarterlyMonths(
        startMonth,
        startYear,
        companyInfo.MonthlyDetailsYears * 12,
      ),
    ];
    DateHelper.months_comaprative = DateHelper.buildMonthsComparative(
      companyInfo.StartMonth,
      companyInfo.StartYear,
      companyInfo.MonthlyDetailsYears * 10,
      companyInfo.MonthlyDetailsYears * 12 + 1,
      true,
      companyInfo.Currency.value,
    );

    DateHelper.all = MxDataArray.create([...DateHelper.months, ...DateHelper.years], "dateID");
    DateHelper.all_periods = MxDataArray.create(
      [
        ...DateHelper.months_before,
        ...DateHelper.months,
        ...DateHelper.months_after,
        ...DateHelper.months_before_actual,
        ...DateHelper.years_before,
        ...DateHelper.years,
        ...DateHelper.years_before_actual,
      ],
      "dateID",
    );
    DateHelper.years_all = [...DateHelper.years_before, ...DateHelper.years];
    DateHelper.months_all = [...DateHelper.months, ...DateHelper.months_after];

    DateHelper.years_all.forEach((year) => {
      const startOrder = year.Order * 12;
      const endOrder = startOrder + 12;
      const months = DateHelper.months_all.filter(
        (c) => c.Order >= startOrder && c.Order < endOrder,
      );
      year.months = months;
      year.monthIndexes = months.map((c) => c.dateID);
      months.forEach((month) => (month.year = year));
    });

    DateHelper.years_before_actual.forEach((year) => {
      const startOrder = year.Order * 12;
      const endOrder = startOrder + 12;
      const months = DateHelper.months_before.filter(
        (c) => c.Order >= startOrder && c.Order < endOrder,
      );
      year.months = months;
      year.monthIndexes = months.map((c) => c.dateID);
      months.forEach((month) => (month.year = year));
    });
    const terminal_year = DateHelper.buildYears(
      companyInfo.StartMonth,
      global.Modeliks.DateHelper.years_all[global.Modeliks.DateHelper.years_all.length - 1].Year +
        1,
      1,
      global.Modeliks.DateHelper.years_all[global.Modeliks.DateHelper.years_all.length - 1].Order +
        1,
    );
    terminal_year[0].Header = TerminalValueHeader;
    DateHelper.discount_cash_years = [
      ...global.Modeliks.DateHelper.years_before_actual,
      ...global.Modeliks.DateHelper.years_all,
      ...terminal_year,
    ];
  },
  setDatesInCompanyInfo: (companyInfo) => {
    DateHelper.buildDates(companyInfo);
    companyInfo.dates = {
      months: DateHelper.months,
      years: DateHelper.years,
      all: DateHelper.all,
    };
  },
  getRelativeMxDate: (dateObj, months = 0, years = 0) => {
    const tmpDate = new Date(dateObj.Year, dateObj.Month, 1);
    tmpDate.setMonth(tmpDate.getMonth() + months);
    tmpDate.setFullYear(tmpDate.getFullYear() + years);

    return new MxDate(tmpDate.getMonth(), tmpDate.getFullYear(), PeriodTypes.month);
  },
  getMonthDatesFromCompany: (companyInfo = global.Modeliks.CompanyInfo) => {
    return companyInfo.dates.months;
  },
  getYearDatesFromCompany: (companyInfo = global.Modeliks.CompanyInfo) => {
    return companyInfo.dates.years;
  },
  getAllDatesFromCompany: (companyInfo = global.Modeliks.CompanyInfo) => {
    return companyInfo.dates.all;
  },
  headers_Months: () => DateHelper.months.map((c) => c.Header),
  headers_Years: (includingPrev) => {
    if (includingPrev) {
      return DateHelper.years_all
        .filter((year, i) => i < DateHelper.ActiveYears)
        .map((c) => c.Header);
    }
    return DateHelper.years.filter((year, i) => i < DateHelper.ActiveYears).map((c) => c.Header);
  },
  gridPeriodHeaders_Years: () => {
    return DateHelper.years_all.filter((year, i) => i < DateHelper.ActiveYears);
  },
  getValidActiveYears: () => {
    return DateHelper.years_all.map((c) => c.Header);
  },
  headers_AllDates: () => DateHelper.all.map((c) => c.Header),
  copy_Months: () => DateHelper.months.map((c) => Object.assign({}, c)),
  copy_Years: () => DateHelper.years.map((c) => Object.assign({}, c)),
  copy_AllDates: () => DateHelper.all.map((c) => Object.assign({}, c)),
  setFinance_DateIndexes: (finance) => {
    finance.dataIndexes = {};
    finance.data.forEach((mData) => {
      DateHelper.setDateIndex(mData);
      finance.dataIndexes[mData.dateIndex] = mData;
    });
  },
  sortByDate: (a, b) => {
    if (a.hasOwnProperty("dateIndex")) {
      DateHelper.setDateIndex(a);
    }
    if (b.hasOwnProperty("dateIndex")) {
      DateHelper.setDateIndex(b);
    }
    return a.dateIndex > b.dateIndex ? 1 : -1;
  },
};

export { ShortMonths, PeriodTypes, DateHelper };
