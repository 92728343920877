import React, { useEffect } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { Avatar, FillIcon, UpgradeSave, UpgradeStars } from "../../icons/svgIcons";
import "./AvatarMenu.scss";
import SwitchAccountDialog from "../../dialogs/SwitchAccount/SwitchAccountDialog";
import Tooltip from "@mui/material/Tooltip";
import request from "superagent";
import ButtonMui from "../../buttons/buttonMui/buttonMui";
import UpgradePlan from "../../../pages/Secure/components/UpgradePlan";

export default function AvatarMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [imageTime, setImageTime] = React.useState(new Date().getTime());
  const [src, setSrc] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const setImg = () => {
    setImageTime(new Date().getTime());
    getImage();
  };

  const plan = global.Modeliks.subscriptionPlans?.find(
    (c) => c.ID === global.Modeliks.subscriptionInfo?.SubscriptionID,
  );

  const getImage = () => {
    request
      .get(`/api/get_profile_pic?user_id=${global.Modeliks.User.ID}&v=${imageTime}&encode=true`)
      .then((c) => {
        if (c.body != "no image") {
          setSrc("data:" + c.headers["content-type"] + ";base64," + c.text);
        } else {
          setSrc(null);
        }
      });
  };

  useEffect(() => {
    global.Modeliks.Subscribe("onProfilePicChange", setImg);
    getImage();

    return () => {
      global.Modeliks.Unsubscribe2("onProfilePicChange", setImg);
    };
  }, []);

  let Account = global.Modeliks.Account;

  if (props.admin) {
    return (
      <>
        <Tooltip title={"Account"}>
          <IconButton onClick={handleClick}>
            <Avatar />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          id="avatar_menu"
          PaperProps={{
            className: "menu_paper",
          }}
        >
            <MenuItem
                className="logout_item"
                onClick={() => {
                    global.Modeliks.LogOut();
                }}
            >
                Logout
            </MenuItem>
        </Menu>
      </>
    );
  }
    if (props.only_profile) {
        return (
            <>
                <Tooltip title={"Account"}>
                    <IconButton onClick={handleClick}>
                        <Avatar/>
                    </IconButton>
                </Tooltip>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    id="avatar_menu"
                    PaperProps={{
                        className: "menu_paper",
                    }}
                >
                    <SwitchAccountDialog NoAccount/>

                    <MenuItem
                        className="logout_item"
                        onClick={() => {
                            global.Modeliks.LogOut();
                        }}
                    >
                        Logout
                    </MenuItem>
                </Menu>
            </>
        );
    }

    const AccountName = Account && Account.Name ? `${Account.Name}` : "Your Account";
    const AccountAvatar = (
        <div className={src ? "img_cont_a" : "svg_cont"}>
            {src ? <img src={src} alt={""}/> : <Avatar/>}
        </div>
    );

  const ActivePlan = global.Modeliks.subscriptionPlans.find(
    (c) => c.ID === global.Modeliks.subscriptionInfo.SubscriptionID,
  );
  const HideUpgradeIcon =
    ActivePlan && ActivePlan.data_key === "Grow" && ActivePlan.billing_type === "year";

  return (
    <>
      <Tooltip title={"Account"}>
        <IconButton className={"avatar_icon_btn"} onClick={handleClick}>
          {AccountAvatar}
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        id="avatar_menu"
        PaperProps={{
          className: `menu_paper menu_account ${HideUpgradeIcon ? "fully_subscribed" : ""}`,
        }}
      >
        <MenuItem
          component={Link}
          to={"/profile/account_details/general_info"}
          className="user_item"
          onClick={() => handleClose()}
        >
          {AccountName}
        </MenuItem>

        {plan && (
          <MenuItem
            component={Link}
            to={"/profile/account_details/manage_subscription"}
            onClick={() => handleClose()}
          >
            {plan.name}
          </MenuItem>
        )}

        {/*<UpgradePlan navigationUpdate={false}/>*/}

        <SwitchAccountDialog />
        <div className="split_line" />

        <MenuItem
          // disabled={!global.Modeliks.UserPermissions.hasOwnProperty(PermissionTypes.Subscriptions)}
          component={Link}
          to={"/profile/account_details/*"}
          selected={window.location.pathname.includes("/profile/account_details")}
          onClick={() => handleClose()}
        >
          Account Details
        </MenuItem>
        {/*<MenuItem component={Link} to={'/profile/paymentmethod'} onClick={() => handleClose()}>*/}
        {/*    Payment Methods*/}
        {/*</MenuItem>*/}
        <MenuItem
          // disabled={!global.Modeliks.UserPermissions.hasOwnProperty(PermissionTypes.Users)}
          component={Link}
          to={"/profile/users"}
          selected={window.location.pathname.includes("/profile/users")}
          onClick={() => handleClose()}
        >
          Users
        </MenuItem>
        <MenuItem
          // disabled={!global.Modeliks.UserPermissions.hasOwnProperty(PermissionTypes.Subscriptions)}
          component={Link}
          to={"/profile/companies"}
          selected={window.location.pathname.includes("/profile/companies")}
          onClick={() => handleClose()}
        >
          Companies
        </MenuItem>
        <MenuItem
          component={Link}
          to={"/profile/details"}
          selected={window.location.pathname.includes("/profile/details")}
          onClick={() => handleClose()}
        >
          Profile
        </MenuItem>
        <MenuItem
          component={Link}
          to={"/portfolio_management"}
          selected={"/portfolio_management" === window.location.pathname}
          onClick={() => handleClose()}
        >
          Portfolio Management
        </MenuItem>
        <MenuItem
          className="logout_item"
          onClick={() => {
            global.Modeliks.LogOut();
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}
