import styled from "styled-components";
import { Theme } from "../../../../theme/styles/theme";
import { BoardingCheck } from "../../../../components/icons/svgIcons";

import "./../../../Secure/Onboarding/components/onboarding.scss";

const StyledLogoWrapper = styled.div`
    align-content: center;
    align: center;
    display: block
    justify-content: center;
    align-content: center;
    align-items: center;
    color: black;
    margin-top: 0px
`;

const StyledP1 = styled.p`
  ${Theme.text.paragraph.p2_strong};
  font-family: Inter;
  display: "flex";
  justifycontent: "center";
`;

const GroupSteps = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
`;

const IconNumber = styled.div`
    border 1px solid ${(props) => (props.disabled === true ? Theme.colors.primary.darkgrey[50] : Theme.colors.primary.lightblue[300])};
    border-radius: 50%;
    width 25px;
    height: 25px;
    color: ${(props) => (props.disabled === true ? Theme.colors.primary.darkgrey[50] : Theme.colors.primary.lightblue[300])};
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight:600;
    font-size: 14px;
    line-height: 150%;
    font-family:Inter;
    z-index: 100;
    background: #FBFBFB;

   `;

const Line = styled.span`
  width: 20px;
  height: 0px;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid ${Theme.colors.greyscale["300"]};
  z-index: 0;
`;
const GroupedIcons = styled.div`
  display: flex;
  // gap: 40px;
`;
const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const OnBoardingWizzard = (props) => {
  const WizardSteps = () => {
    let wizard = [];
    for (let i = 1; i <= props.steps; i++) {
      wizard.push(
        props.wizard === i ? (
          <>
            <IconNumber className="sppaco_icon_number">{i}</IconNumber>
            {props.steps !== i && <Line className="sppaco_line" />}
          </>
        ) : props.wizard < i ? (
          <>
            <IconNumber className="sppaco_icon_number" disabled>
              {i}
            </IconNumber>
            {props.steps !== i && <Line className="sppaco_line" />}
          </>
        ) : (
          <>
            <BoardingCheck className="sppaco_icon_number" />
            {props.steps !== i && <Line className="sppaco_line" />}
          </>
        ),
      );
    }
    return wizard;
  };

  return (
    <StyledLogoWrapper className="sppaco_wizzard_wapper">
      <HeaderContainer>
        <p className="p p1 strong title">Welcome to Modeliks</p>
      </HeaderContainer>
      <GroupSteps>
        <GroupedIcons className="sppaco_group_steps">
          <WizardSteps />
        </GroupedIcons>
      </GroupSteps>
    </StyledLogoWrapper>
  );
};
