import React, { useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import "./DashboardsDropDownMenu.scss";
import { MoreHorizontal, SmallPlusIcon } from "../../../../components/icons/svgIcons";
import { Link } from "react-router-dom";
import {
  ReusableMenu,
  ReusableMenuItem,
} from "../../../../components/menus/ReusableMenu/ReusableMenu";

export default function DropDownViewMenu(props) {
  const menuItems = Object.entries(props.types).map(
    ([key, value]) =>
      new ReusableMenuItem(
        key.replace(/([A-Z])/g, " $1").trim(),
        (e) => props.onChange(e, value),
        props.value === value,
        [],
        false,
        false,
      ),
  );

  return (
    <div className={props.className}>
      <ReusableMenu
        menuButton={
          <IconButton>
            <div className="svg_container">
              <MoreHorizontal width="24" />
            </div>
          </IconButton>
        }
        menuItems={menuItems}
      />
    </div>
  );
}
