import React, { Fragment, useState } from "react";
import RightMenuPortal from "./components/Portals/RightMenuPortal.js";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import InputBox from "./components/InputBoxComponent/InputBox";
import {
  Exit,
  FlipHorizontally,
  FlipVertically,
  Rotate90,
} from "./components/IconButtons/SubMenuIcons";
import SizeAndRotation from "./components/RightMenuComponents/SizeAndRotationComponent";
import Position from "./components/RightMenuComponents/PositionComponent";
import TextFitting from "./components/RightMenuComponents/TextFittingComponent";
import SpecialCharacters from "./components/RightMenuComponents/SpecialCharactersComponent";
import AdditionalOptions from "./components/RightMenuComponents/AdditionalOptionsComponent";
import "./components/RightMenuComponents/RightMenu.css";
import { IconButton } from "@mui/material";

class RightMenuObject extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.shouldUpdate) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    if (this.props.selected === false) {
      return null;
    }
    return (
      <RightMenuPortal>
        <List
          style={{
            display: this.props.selected ? "block" : "none",
            paddingLeft: "0",
            position: "relative",
            width: "100%",
            height: "auto",
            maxWidth: 250,
            bgcolor: "background.paper",
            paddingBottom: "0 ",
            // overflow:'scroll',
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span style={{ fontWeight: 700, fontSize: "16px", textAlign: "left" }}>
                  Format Options
                </span>
                <IconButton onClick={this.props.toggleFormatOptions}>
                  <Exit />
                </IconButton>
              </div>
            </ListSubheader>
          }
        >
          <SizeAndRotation
            changeLockedAspect={this.props.changeLockedAspect}
            onChange={this.props.onUpdateSizeAndRotation}
            style={this.props.style}
            width={this.props.width}
            height={this.props.height}
            rotateAngle={this.props.rotateAngle}
            scaleX={this.props.scaleX}
            scaleY={this.props.scaleY}
          />
          <Position
            onChange={this.props.onUpdatePosition}
            style={this.props.style}
            top={this.props.top}
            left={this.props.left}
          />
        </List>
      </RightMenuPortal>
    );
  }
}

// function RightMenuObject(props) {
//     // const handleChange = (event, newValue) => {
//     //     setValue(newValue);
//     // }
//
//     if(props.selected === false){
//         return null;
//     }
//     return (
//         <Fragment>
//             <RightMenuPortal>
//                 <List
//                     style={{
//                         display: props.selected? 'block': 'none',
//                         paddingLeft: '0',
//                         position: 'relative',
//                         width: '100%',
//                         height: 'auto',
//                         maxWidth: 250,
//                         bgcolor: 'background.paper',
//                         paddingBottom:'0 '
//                         // overflow:'scroll',
//                     }}
//                     component="nav"
//                     aria-labelledby="nested-list-subheader"
//                     subheader={
//                         <ListSubheader  component="div" id="nested-list-subheader">
//                             <div style={{width:'100%', display:'flex',alignItems:'center', justifyContent:'space-between'}}>
//                                 <span style={{fontWeight:700, fontSize:'16px', textAlign:'left'}}>Format Options</span>
//                                 <Exit onClick={props.onClick}/>
//                             </div>
//                         </ListSubheader>
//                     }
//                 >
//                     <SizeAndRotation onChange={props.onUpdateSizeAndRotation} style={props.style} width={props.width} height={props.height} rotateAngle={props.rotateAngle} scaleX={props.scaleX} scaleY={props.scaleY}/>
//                     <Position onChange={props.onUpdatePosition} style={props.style} top={props.top} left={props.left}/>
//
//                     {/*<TextFitting onChange={props.onChange} style={props.style}/>*/}
//                     {/*<SpecialCharacters onChange={props.onChange} style={props.style}/>*/}
//                 </List>
//             </RightMenuPortal>
//         </Fragment>
//     )
// }

export default RightMenuObject;
