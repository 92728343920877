import CalculatedDriver from "../../CalculatedDriver/index";
import { DriverCategories, SpecialChar, UnitTypes } from "../../CalculatedDriver/constants";
import MxIDHelper from "../../../MxIDHelper";
import Financing from "../../Financing";
import { FinancingTypes } from "../constants";
import CalculatedDriver_Values from "../../CalculatedDriver/CalculatedDriver_Values";
import { PeriodTypes } from "../../../dates";

class Dividends extends Financing {
  FinanceType = FinancingTypes.Dividends;
  Payments;
  Income;

  constructor(db_record) {
    super(db_record);
    if (this.db_record) {
      this.clean();
    }
  }

  clean = (cleanDrivers = false) => {
    if (this.db_record && this.Totals) {
      this.Name = this.db_record.Name;
      this.FinanceType = this.db_record.FinanceType;
      this.ID_CompanyScenario = this.db_record.ID_CompanyScenario;
      if (cleanDrivers) {
        this.cleanDrivers();
      }
    }
  };

  static DriversDesc = Object.assign({
    Total: {
      driverName: "$Name",
      fieldName: "Totals",
      driverID: "total",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    Payments: {
      driverName: `Finance Dividend Payments${SpecialChar.DriverNameESCChar}$Name`,
      fieldName: "Payments",
      driverID: "payments",
      unit: UnitTypes.Price,
      category: DriverCategories.Sum,
    },
    // Income: {
    //     driverName: '$Name - Finance Income',
    //     fieldName: 'Income',
    //     driverID: 'income',
    //     unit: UnitTypes.Price,
    //     category: DriverCategories.Sum
    // },
  });

  setFinance = () => {};

  Save = (callBack, saveDrivers = true) => {
    if (!this.isNew && !this.IsCreated) {
      global.Modeliks.put(this.constructor.TableName, null, this, (res) => {
        if (saveDrivers) {
          this.SaveDrivers(callBack);
        } else {
          callBack();
        }
      });
    } else {
      global.Modeliks.put(this.constructor.TableName, null, this, (res) => {
        if (saveDrivers) {
          this.SaveDrivers(callBack);
        } else {
          callBack();
        }
      });
    }
  };

  static convert_Finance = (finance) => {
    const newFinancing = new Dividends();
    newFinancing.ID = finance.ID;
    newFinancing.Name = finance.Name;
    newFinancing.IsCreated = true;
    newFinancing.ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
    newFinancing.Totals = CalculatedDriver.createDriverFromTable(
      newFinancing,
      Financing.DriversDesc.Total.driverID,
      UnitTypes.Price,
      DriverCategories.Sum,
    );
    newFinancing.Payments = CalculatedDriver.createDriverFromTable(
      newFinancing,
      Dividends.DriversDesc.Payments.driverID,
      UnitTypes.Price,
      DriverCategories.Sum,
    );
    newFinancing.Totals.setFormula(`|${newFinancing.Payments.ID}|`);

    return newFinancing;
  };
}

export default Dividends;
