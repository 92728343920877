import GridLayout from "react-grid-layout";
import * as React from "react";
import "./draggableGrid.scss";
import ReactGridLayout from "react-grid-layout";
import ButtonMui from "../../../../../components/buttons/buttonMui/buttonMui";
import { MoveIcon } from "../../../../../SlideEditor/components/components/menus/LeftMenu/components/Icons/LeftMenuIcons";
import { TrashCan } from "../../../../../components/icons/svgIcons";
import IconButton from "@mui/material/IconButton";

const itemsPerRow = 4;

export default class DraggableGrid extends React.Component {
  constructor(props) {
    super(props);

    this.biggestYIndex = 0;

    this.state = {
      innerWidth: window.innerWidth,
    };
  }

  getPositionCoordinate = (key, index) => {
    if (this.props.positions[index] && this.props.positions[index].hasOwnProperty(key)) {
      if (key == "y") {
        if (this.biggestYIndex < Number(this.props.positions[index][key])) {
          this.biggestYIndex = Number(this.props.positions[index][key]);
        }
      }
      return this.props.positions[index][key];
    }
    if (key == "y") {
      return this.biggestYIndex + 1;
    }
    return 0;
  };

  getWidth = (index) => {
    if (this.props.widths[index]) {
      return (this.props.widths[index] / 100) * itemsPerRow;
    }
    return 4;
  };

  getLayout = () => {
    return this.props.items.map((c, index) => {
      return {
        i: `grid_chart_${index}`,
        x: this.getPositionCoordinate("x", index),
        y: this.getPositionCoordinate("y", index),
        w: this.getWidth(index),
        h: 1,
      };
    });
  };

  onLayoutChange = (layout) => {
    let positionChangeObject = {};
    let widthChangeObject = {};

    let existingLayout = this.getLayout();
    layout.forEach((l, index) => {
      Object.keys(existingLayout[index]).forEach((key) => {
        if (l[key] != existingLayout[index][key]) {
          if (key == "w") {
            if (!widthChangeObject[index]) {
              Object.assign(widthChangeObject, { [index]: (Number(l[key]) / itemsPerRow) * 100 });
            }
          } else {
            if (!positionChangeObject[index]) {
              Object.assign(positionChangeObject, { [index]: {} });
            }
            Object.assign(positionChangeObject[index], { [key]: l[key] });
          }
        }
      });
    });
    this.props.onLayoutChange(positionChangeObject, widthChangeObject);
  };

  handleResize = () => {
    this.setState({ innerWidth: window.innerWidth });
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  getItems = () => {
    if (global.Modeliks.isAdmin) {
      return this.props.items.map((c, index) => <div key={`grid_chart_${index}`}>{c}</div>);
    }
    return this.props.items.map((c, index) => (
      <CustomGridItemComponent
        onDeleteTable={c.props.onDeleteTable}
        isDraggable={this.props.enableDrag}
        key={`grid_chart_${index}`}
      >
        {c}
      </CustomGridItemComponent>
    ));
  };

  render() {
    let items = this.getItems();

    const props = {
      isDraggable: this.props.enableDrag,
      cols: itemsPerRow,
      isResizable: this.props.enableResize,
      onLayoutChange: this.onLayoutChange,
      className: `layout ${!this.props.enableResize ? "layout_hide_resize_icon" : ""}`,
      layout: this.getLayout(),
      rowHeight: 500,
      width: this.state.innerWidth,
      margin: [25, 25],
      items,
    };

    if (global.Modeliks.isAdmin) {
      return <GridLayout {...props}>{items}</GridLayout>;
    }
    return <MyGrid {...props} />;
  }
}

const CustomGridItemComponent = React.forwardRef(
  (
    {
      style,
      className,
      onMouseDown,
      onMouseUp,
      onTouchEnd,
      children,
      isDraggable,
      onDeleteTable,
      ...props
    },
    ref,
  ) => {
    return (
      <div
        style={{ position: "relative", ...style }}
        className={className}
        ref={ref}
        onMouseUp={onMouseUp}
        onTouchEnd={onTouchEnd}
      >
        {isDraggable && (
          <ButtonMui
            style={{
              position: "absolute",
              zIndex: 100,
              top: 0,
              left: 0,
              height: 26,
              width: 26,
              padding: "6px",
            }}
            isIconButton
            label={<MoveIcon />}
            color={"grey"}
            onMouseDown={onMouseDown}
          />
        )}
        {onDeleteTable && (
          <IconButton
            style={{
              position: "absolute",
              zIndex: 100,
              top: 0,
              left: 26,
              height: 26,
              width: 26,
              padding: "6px",
            }}
            onClick={onDeleteTable}
          >
            <TrashCan />
          </IconButton>
        )}
        {children} {/* Make sure to include children to add resizable handle */}
      </div>
    );
  },
);

function MyGrid(props) {
  const children = React.useMemo(() => props.items, []);
  return <ReactGridLayout {...props}>{children}</ReactGridLayout>;
}
