export default function BackgroundBalls({
  bigRightTopBall,
  smallRightTopBall,
  bigLeftTopBall,
  smallLeftTopBall,
  smallCenterMidBall,
  smallLeftCenterBall,
  smallRightCenterBall,
  bigRightCenterBall,
  smallCenterTopBall,
  bigLeftBottomBall,
  smallRightBottomBall,
}) {
  return (
    <div className={"background-balls-wrapper"}>
      {bigRightTopBall && <div className={"background-ball big big-right-top"}></div>}
      {smallRightTopBall && <div className={"background-ball small small-right-top"}></div>}

      {smallRightBottomBall && <div className={"background-ball small small-right-bottom"}></div>}

      {bigLeftTopBall && <div className={"background-ball big big-left-top"}></div>}

      {smallLeftTopBall && <div className={"background-ball small small-left-top"}></div>}

      {smallCenterMidBall && <div className={"background-ball small small-center-mid"}></div>}

      {smallCenterTopBall && <div className={"background-ball small small-center-top"}></div>}

      {smallLeftCenterBall && <div className={"background-ball small small-left-center"}></div>}

      {smallRightCenterBall && <div className={"background-ball small small-right-center"}></div>}

      {bigRightCenterBall && <div className={"background-ball big big-left-center"}></div>}

      {bigLeftBottomBall && <div className={"background-ball big big-left-bottom"}></div>}
    </div>
  );
}
