import MxDataArray from "../../MxDataArray";
import Personnel from "./index";
import PersonnelDataGridRows from "./PersonnelDataGridRows";

class PersonnelStorage extends MxDataArray {
  dataManager = null;
  driverStorage = null;
  loaded = false;

  setData = (callBack, data = this.data) => {
    this.pushArray(
      data.map((item) => {
        const newItem = new Personnel(item);
        if (newItem.Totals) {
          this.setExistingDrivers(newItem);
          return newItem;
        } else {
          // this.dataManager.del(this.dataManager.Tables.Finance_Personnel.TableName, {ID: newItem.ID}, () => {
          //     console.log('deleting',newItem);
          // }, () => {
          // });
        }
      }),
    );

    // Personnel.getPersonnelOnlyTotals();
    Personnel.getDisplayBurdenRateInReports();
    Personnel.getPersonnelEmployeeTotals();
    Personnel.getPersonnelCategoriesTotals();
    Personnel.getPersonnelCategoriesTotalsNumbersOfEmployees();
    Personnel.getPersonnelCategoriesEmployeesCostTotals();
    Personnel.getPersonnelCategoriesEmployeesCostTotalsBurdenRate();
    Personnel.geTotalEmployeeCostTotals();
    Personnel.getSalesAndMarketingEmployeesTotals();
    Personnel.getPersonnelCategoriesTotalsBurdenRate();
    Personnel.getPersonnelDirectLaborTotals();
    Personnel.getNumberOfNewEmployees();
    this.loaded = true;
    callBack();
  };
  setGridRows = () => PersonnelDataGridRows(this);

  loadPersonnel = (callBack, shouldFinish = true) => {
    this.clear();
    this.dataManager.get(
      this.dataManager.Tables.Finance_Personnel.TableName,
      { ID_CompanyScenario: this.dataManager.CompanyScenarioInfo.ID },
      (data) => {
        if (shouldFinish) {
          this.setData(callBack, data);
        } else {
          this.data = data;
          callBack();
        }
      },
      null,
      false,
    );
  };

  setExistingDrivers = (item) => {
    const notIncludedDrivers = [
      Personnel.DriversDesc.EmployeeCost.driverID,
      Personnel.DriversDesc.EmployeeBenefits.driverID,
    ];

    item.getAllDrivers().forEach((d) => (d.IsExisting = !notIncludedDrivers.includes(d.Ref_Field)));
  };

  static create = () => {
    const newArr = new PersonnelStorage();
    newArr.pushOld = newArr.push;
    newArr.push = newArr.pushThis;

    return newArr;
  };
}

export default PersonnelStorage;
