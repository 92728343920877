import React, { Fragment, useState } from "react";
import Menu from "@mui/material/Menu";
import {
  LowerCase,
  RightArrow,
  SubScript,
  SuperScript,
  TitleCase,
  UpperCase,
} from "../IconButtons/SubMenuIcons";
import { Subscript } from "@mui/icons-material";

function CapitalizationSubContextMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openCapitalization = Boolean(anchorEl);
  const handleClick = (event) => {
    if (!anchorEl) setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const setStyle = (key, value, check) => {
    props.buildStuct();

    let selectedObject = props.getSelectedObject();

    let elementCounter = 0;
    let styleCounter = 0;

    if (check) {
      selectedObject.forEach((p) => {
        if (Object.prototype.toString.call(p) == "[object Array]") {
          p.forEach((obj) => {
            elementCounter++;
            if (obj.style.hasOwnProperty(key) && obj.style[key] === value) {
              styleCounter++;
            }
          });
        } else {
          elementCounter++;
          if (p.style.hasOwnProperty(key) && p.style[key] === value) {
            styleCounter++;
          }
        }
      });
    }

    if (styleCounter !== elementCounter || !check) {
      selectedObject.forEach((p) => {
        if (Object.prototype.toString.call(p) == "[object Array]") {
          p.forEach((obj) => {
            if (!obj.style.hasOwnProperty(key) || obj.style[key] !== value) {
              obj.style[key] = value;
              obj.style = Object.keys(obj.style)
                .sort()
                .reduce(function (result, key) {
                  result[key] = obj.style[key];
                  return result;
                }, {});
            }
          });
        } else {
          if (!p.style.hasOwnProperty(key) || p.style[key] !== value) {
            p.style[key] = value;
            p.style = Object.keys(p.style)
              .sort()
              .reduce(function (result, key) {
                result[key] = p.style[key];
                return result;
              }, {});
          }
        }
      });
    } else {
      selectedObject.forEach((p) => {
        if (Object.prototype.toString.call(p) == "[object Array]") {
          p.forEach((obj) => {
            delete obj.style[key];
            obj.style = Object.keys(obj.style)
              .sort()
              .reduce(function (result, key) {
                result[key] = obj.style[key];
                return result;
              }, {});
          });
        } else {
          delete p.style[key];
          p.style = Object.keys(p.style)
            .sort()
            .reduce(function (result, key) {
              result[key] = p.style[key];
              return result;
            }, {});
        }
      });
    }

    props.groupStruct();
  };

  const setSuperscript = () => {
    props.buildStuct();

    let selectedObject = props.getSelectedObject();
    let styleCounter = 0;
    let elementCounter = 0;

    selectedObject.forEach((p) => {
      if (Object.prototype.toString.call(p) == "[object Array]") {
        p.forEach((obj) => {
          elementCounter++;
          if (obj.sup) {
            styleCounter++;
          }
        });
      } else {
        elementCounter++;
        if (p.sup) {
          styleCounter++;
        }
      }
    });

    selectedObject.forEach((p) => {
      if (Object.prototype.toString.call(p) == "[object Array]") {
        p.forEach((obj) => {
          if (elementCounter !== styleCounter) {
            obj.sup = true;
            obj.sub = false;
          } else {
            obj.sup = false;
            obj.sub = false;
          }
        });
      } else {
        if (elementCounter !== styleCounter) {
          p.sup = true;
          p.sub = false;
        } else {
          p.sup = false;
          p.sub = false;
        }
      }
    });

    props.groupStruct();
  };

  const setSubscript = () => {
    props.buildStuct();

    let selectedObject = props.getSelectedObject();
    let styleCounter = 0;
    let elementCounter = 0;

    selectedObject.forEach((p) => {
      if (Object.prototype.toString.call(p) == "[object Array]") {
        p.forEach((obj) => {
          elementCounter++;
          if (obj.sub) {
            styleCounter++;
          }
        });
      } else {
        elementCounter++;
        if (p.sub) {
          styleCounter++;
        }
      }
    });

    selectedObject.forEach((p) => {
      if (Object.prototype.toString.call(p) == "[object Array]") {
        p.forEach((obj) => {
          if (elementCounter !== styleCounter) {
            obj.sup = false;
            obj.sub = true;
          } else {
            obj.sup = false;
            obj.sub = false;
          }
        });
      } else {
        if (elementCounter !== styleCounter) {
          p.sup = false;
          p.sub = true;
        } else {
          p.sup = false;
          p.sub = false;
        }
      }
    });

    props.groupStruct();
  };

  const CapitalizationSubMenu = () => {
    return (
      <>
        <div
          aria-controls={openCapitalization ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openCapitalization ? "true" : undefined}
          onClick={(e) => handleClick(e)}
          // onMouseOver={(e)=>handleClick(e)}
        >
          <Menu
            style={{ left: "372px", top: "-23px" }}
            anchorEl={anchorEl}
            open={openCapitalization}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <div>
              <div
                id={"contextMenuOption"}
                onClick={() => setStyle("textTransform", "lowercase", false)}
              >
                <LowerCase />
                <span>lowercase</span>
              </div>
              <div
                id={"contextMenuOption"}
                onClick={() => setStyle("textTransform", "uppercase", false)}
              >
                <UpperCase />
                <span>UPPERCASE</span>
              </div>
              <div
                id={"contextMenuOption"}
                onClick={() => setStyle("textTransform", "capitalize", false)}
              >
                <TitleCase />
                <span>Title Case</span>
              </div>
              <div></div>
              <div id={"contextMenuOption"} onClick={setSuperscript}>
                <SuperScript />
                <span>Superscript</span>
              </div>
              <div id={"contextMenuOption"} onClick={setSubscript}>
                <SubScript />
                <span>Subscript</span>
              </div>
            </div>
          </Menu>
          <span id={"contextMenuOptionWithArrow"}>
            <span>Capitalization</span>
            <RightArrow />
          </span>
        </div>
      </>
    );
  };
  return <>{CapitalizationSubMenu()}</>;
}

export default CapitalizationSubContextMenu;
