import React from "react";
import { Line } from "react-chartjs-2";
import Chart from "./Chart";
import { ChartTypes } from "../../../components/constants/charts";
import { UnitTypes } from "../../../data/Finance/CalculatedDriver/constants";
import { MenuItem } from "@szhsin/react-menu";
import NumberInputAction from "../../../components/actions/inputs/NumberInput";

export default class LineChart extends Chart {
  constructor(props) {
    super(props);
    this.chartClassName = ChartTypes.LineChart.key;
    this.setClassName();
    this.buildData();
  }
  getAdditionalChartSettings() {
    return (
      <>
        <MenuItem
          onClick={(e) => {
            e.keepOpen = true;
          }}
        >
          <NumberInputAction
            value={this.chartOptions.datalabelsDecimalPoints}
            max={3}
            label={"Show decimals"}
            onChange={(decPointsVal) => {
              this.chartOptions.datalabelsDecimalPoints = decPointsVal;
              this.setChartRightPadding();
              this.buildData();
              this.forceUpdate(() => {
                this.saveCurrentSettings();
                if (this.chartRef) {
                  this.chartRef.data.datasets = this.getData();
                  // this.chartRef.update();
                }
              });
            }}
          />
        </MenuItem>
      </>
    );
  }

  render() {
    const ChartData = this.getData();
    const ChartOptions = this.getChartOptions();

    if (ChartData && ChartData.length > 0) {
      let left = 25;
      let right = 25;
      let fv = 5;
      let lv = 4;
      let fvm = 5;
      let lvm = 4;

      if (this.chartOptions.unitType === UnitTypes.Percentage) {
        fv = 3;
        lv = 3;
        fvm = 2.5;
        lvm = 2.5;
      }

      if (this.maxDatasetsFirstValue > fv) {
        left = (this.maxDatasetsFirstValue - fvm) * 8;
      }

      if (this.maxDatasetsLastValue > lv) {
        right = (this.maxDatasetsLastValue - lvm) * 8;
      }

      if (ChartOptions && ChartOptions.hasOwnProperty("layout")) {
        ChartOptions.layout.padding.left = left;
        ChartOptions.layout.padding.right = right;
      }
    }

    if (this.getImageSrc()) {
      return this.getChartElementAsImage();
    }
    return (
      <div
        className={"c_c_chart_container"}
        style={{ width: `${this.props.grid ? 100 : this.chartConfig.width}%` }}
        ref={this.setChartContainerRef}
      >
        {/*<div ref={this.setChartTitleRef} style={{visibility: this.chartOptions.showTitle?'visible':'hidden'}} className={'chart_title_wrapper'}><ChartTitle title={this.getChartTitle(this.chartOptions.comparisonBar)} change={this.getChartTitleChange()} unit={this.getChartTitleGrowthUnit()}/></div>*/}
        {this.getTitleElement()}
        <Line
          ref={this.setChartRef}
          data={{
            labels: this.getChartLabels(),
            datasets: ChartData,
          }}
          plugins={this.getPlugins()}
          options={ChartOptions}
          type={"line"}
        />
      </div>
    );
  }
}
