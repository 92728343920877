import React, { useRef } from "react";
import {
  DecreaseIndent,
  IncreaseIndent,
  MoreOptions,
  ClearFormatting,
  Separator,
} from "../../components/IconButtons/SubMenuIcons";
import AlignEditComponent from "./AlignEditComponent";
import BulletingEditComponent from "./BulletingEditComponent";
import NumberingEditComponent from "./NumberingEditComponent";
import SpacingEditComponent from "./SpacingEditComponent";
import { ControlledMenu, MenuItem, useMenuState, MenuButton } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "../HeaderTextEditor/HorizontalSubMenu/MoreOptionsMenu.scss";
import Tooltip from "@mui/material/Tooltip";
import ButtonMui from "../../../../components/buttons/buttonMui/buttonMui";

// class MoreOptionsMenuComponent extends React.Component {
//
//     constructor(props) {
//         super(props);
//         this.state = {}
//
//     }
//
//     shouldComponentUpdate(nextState, nextProps){
//         return false;
//     }
//
//     onChange = (style) => {
//         this.props.onChange(style);
//     }
//
//     MoreOptionsMenu = () => {
//         const ref = useRef(null);
//         const [menuProps, toggleMenu] = useMenuState({ transition: true });
//
//         return <>
//             <Tooltip arrow title={'More Options'} onClick={(e) => {
//                 this.props.setRestoreSelection();
//                 e.preventDefault();
//                 e.stopPropagation();
//                 toggleMenu(true);
//             }}><MenuButton
//                 ref={ref}
//                 className='more_options_icon'><MoreOptions active={!!menuProps}/></MenuButton></Tooltip>
//             <ControlledMenu className='more_options_menu'
//
//         >
//             <AlignEditComponent onChange={this.onChange} style={this.props.style} objType={this.props.objType}/>
//             <SpacingEditComponent changeCustomSpacing={this.props.changeCustomSpacing} objType={this.props.objType}
//                                   changeSpacingBeforeItem={this.props.changeSpacingBeforeItem}
//                                   changeSpacingAfterItem={this.props.changeSpacingAfterItem}
//                                   onChange={this.props.objType && this.props.objType === 'table' || this.props.objType === 'shape' ? this.onChange : this.props.changeLineHeight}
//                                   style={this.props.style} rowStyle={this.props.rowStyle}/>
//             <BulletingEditComponent onValueChange={this.props.onValueChange} value={this.props.value}
//                                     onChange={this.onChange} style={this.props.style} setBullet={this.props.setBullet}/>
//             <NumberingEditComponent onValueChange={this.props.onValueChange} value={this.props.value}
//                                     onChange={this.onChange} style={this.props.style} setBullet={this.props.setBullet}/>
//             <Tooltip title='Decreace Indent'><MenuItem
//                 onClick={(e) => {
//                     e.keepOpen = true;
//                     this.props.decreaseIndent()
//                 }}><DecreaseIndent/></MenuItem></Tooltip>
//             <Tooltip title='Increace Indent'><MenuItem
//                 onClick={(e) => {
//                     e.keepOpen = true;
//                     this.props.increaseIndent()}}><IncreaseIndent/></MenuItem></Tooltip>
//             <Tooltip title='Clear Formatting'><MenuItem
//                 onClick={(e) => {
//                     e.keepOpen = true;
//                     this.props.removeStyle()}}><ClearFormatting/></MenuItem></Tooltip>
//             <Separator/>
//             <Tooltip title='Format options'><MenuItem onClick={(e) => {
//                     e.keepOpen = true;
//                     this.props.toggleFormatOptions()}}>Format
//                 Options</MenuItem></Tooltip>
//         </ControlledMenu>
//             </>
//     }
//
//     render() {
//         return (
//             <>
//                 {this.MoreOptionsMenu()}
//             </>
//         )
//     }
// }

function MoreOptionsMenuComponent(props) {
  const onChange = (style) => {
    props.onChange(style);
  };

  const closeMenu = () => {
    setOpen(!open);
    toggleMenu(open);
  };

  const ref = useRef(null);
  const [menuProps, toggleMenu] = useMenuState({ transition: true });
  const [open, setOpen] = React.useState(true);

  return (
    <>
      <ControlledMenu className="more_options_menu" {...menuProps} anchorRef={ref}>
        <AlignEditComponent
          className={"m_o_s"}
          onChange={onChange}
          style={props.objType === "textObject" ? props.tbStyle : props.style}
          objType={props.objType}
        />
        <SpacingEditComponent
          className={"m_o_s"}
          changeCustomSpacing={props.changeCustomSpacing}
          objType={props.objType}
          changeSpacingBeforeItem={props.changeSpacingBeforeItem}
          changeSpacingAfterItem={props.changeSpacingAfterItem}
          closeMenu={closeMenu}
          onChange={
            (props.objType && props.objType === "table") || props.objType === "shape"
              ? onChange
              : props.changeLineHeight
          }
          style={props.style}
          rowStyle={props.rowStyle}
        />
        <BulletingEditComponent
          className={"m_o_m"}
          onValueChange={props.onValueChange}
          value={props.value}
          onChange={onChange}
          style={props.style}
          rowStyle={props.rowStyle}
          getSelectedRowsStyle={props.getSelectedRowsStyle}
          setBullet={props.setBullet}
        />
        <NumberingEditComponent
          className={"m_o_m"}
          onValueChange={props.onValueChange}
          value={props.value}
          onChange={onChange}
          style={props.style}
          rowStyle={props.rowStyle}
          getSelectedRowsStyle={props.getSelectedRowsStyle}
          setBullet={props.setBullet}
        />
        <ButtonMui
          isIconButton
          tooltipText={"Decrease Indent"}
          className={"m_o_l"}
          onClick={(e) => {
            e.keepOpen = true;
            props.decreaseIndent();
          }}
          variant={"text"}
          roundnessType={"squared"}
          color={"black"}
          label={<DecreaseIndent />}
        />
        <Tooltip title="Increase Indent">
          <MenuItem className={"m_o_l"} onClick={props.increaseIndent}>
            <IncreaseIndent />
          </MenuItem>
        </Tooltip>
        <Tooltip title="Clear Formatting">
          <MenuItem className={"m_o_xl"} onClick={props.removeStyle}>
            <ClearFormatting />
          </MenuItem>
        </Tooltip>
        <Separator className={"m_o_xl"} />
        <Tooltip title="Format options">
          <MenuItem className={"m_o_xxl"} onClick={props.toggleFormatOptions}>
            Format options
          </MenuItem>
        </Tooltip>
        {props.children}
      </ControlledMenu>

      <Tooltip
        placement={menuProps.state == "closed" ? "bottom" : "top"}
        arrow
        title={"More Options"}
      >
        <span>
          <ButtonMui
            ref={ref}
            isIconButton
            className={"m_btn"}
            onClick={closeMenu}
            variant={"text"}
            roundnessType={"squared"}
            color={"black"}
            label={<MoreOptions active={!open} />}
          />
        </span>
      </Tooltip>
    </>
  );
}

export default MoreOptionsMenuComponent;
