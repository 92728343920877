import React from "react";
import CheckBoxMaterial from "../../../../../../../components/actions/CheckBoxMaterial";

class SP_Driver_BD_Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="psfrcrcc_breakdown_checkbox">
        <CheckBoxMaterial
          size="medium"
          checked={this.props.breakDown}
          onChange={this.props.onChange}
          label="Enter breakdown per month"
        />
      </div>
    );
  }
}

export default SP_Driver_BD_Component;
