import React from "react";
import TextObject2 from "./TextObject2";
import ImageObject from "./ImageObject";
import ShapeObject from "./ShapeObject";
import HtmlObject from "./HtmlObject";
import TableObject from "./TableObject";
import ChartObject from "./ChartObject";
import LineObject from "./LineObject";
import NewLineObject from "./NewLineObject";
import IconObject from "./IconObject";
import DataGridM from "../components/DataGrid/DataGridM";
import GroupObject from "./GroupObject";
import CalloutObject from "./CalloutObject";
import ElbowConnectorObject from "./ElbowConnectorObject";
import { FinanceTable } from "./FinanceTable/FinanceTable";
import FinancialChart from "./FinancialChart/FinancialChart";
import MainFinanceTable from "./MainFinanceTable";
import ErrorHandler from "./ErrorHandling/ErrorHandler";
import { mergeDeep } from "../../../helpers/utils/merge";

const SlideTypes = {
  textObject: "textObject",
  shapeObject: "shapeObject",
  chartObject: "chartObject",
  imageObject: "imageObject",
  htmlObject: "htmlObject",
  tableObject: "tableObject",
  lineObject: "lineObject",
  elbowConnectorObject: "elbowConnectorObject",
  newLineObject: "newLineObject",
  DataGridM: "DataGridM",
  IconObject: "iconObject",
  GroupObject: "groupObject",
  calloutObject: "calloutObject",
  financeTable: "financeTable",
  financialChart: "financialChart",
  mainFinanceTable: "mainFinanceTable",
};

class SlideObject {
  type = null;
  value = null;
  props = {};

  key = null;

  config = {};

  constructor(type, value = null, props = {}, replacementImage = false, shouldPushChange = true) {
    this.type = type;
    this.value = value;
    this.props = props;
    this.props.onValueChanged = this.onValueChanged;

    this.key = "so_" + Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));

    const objComp = this.getObjectComponent();
    if (objComp && objComp.config) {
      this.config = objComp.config;
    }

    if (shouldPushChange) {
      if (!replacementImage) {
        /* empty */
      } else {
        this.updateProps({}, "replace");
      }
    }
  }

  updateValue = (value) => {
    global.slideHistory.pushNewChange &&
      global.slideHistory.pushNewChange(this, "value", value, this.value);
    this.value = value;
  };

  updateProps = (
    props = {},
    type = "props",
    shouldPushChange = true,
    changes = false,
    callBack,
    historyTag = null,
  ) => {
    if (shouldPushChange) {
      let nextChange;
      let prevChange;

      if (
        this.type !== SlideTypes.textObject &&
        this.type !== SlideTypes.chartObject &&
        this.type !== SlideTypes.tableObject
      ) {
        nextChange = JSON.parse(JSON.stringify(props));
        prevChange = JSON.parse(JSON.stringify(this.props));
      } else if (this.type === SlideTypes.textObject) {
        if (changes !== false) {
          nextChange = Object.assign({ ...this.props }, { struct: changes.nextChange });
          prevChange = Object.assign({ ...this.props }, { struct: changes.prevChange });
        } else {
          nextChange = Object.assign({}, { ...props });
          prevChange = Object.assign({}, { ...this.props });
        }
      } else if (this.type === SlideTypes.tableObject) {
        if (changes !== false) {
          nextChange = Object.assign({ ...this.props }, { structure: { ...changes.nextChange } });
          prevChange = Object.assign({ ...this.props }, { structure: { ...changes.prevChange } });
        } else {
          nextChange = Object.assign({}, { ...props });
          prevChange = Object.assign({}, { ...this.props });
        }
      } else if (this.type === SlideTypes.chartObject) {
        if (changes !== false) {
          nextChange = Object.assign({ ...this.props }, { chartOptions: changes.nextChange });
          prevChange = Object.assign({ ...this.props }, { chartOptions: changes.prevChange });
        } else {
          nextChange = Object.assign({}, { ...props });
          prevChange = Object.assign({}, { ...this.props });
        }
      } else {
        if (changes !== false) {
          nextChange = Object.assign({ ...this.props }, { data: changes.nextChange });
          prevChange = Object.assign({ ...this.props }, { data: changes.prevChange });
        } else {
          nextChange = Object.assign({}, { ...props });
          prevChange = Object.assign({}, { ...this.props });
        }
      }

      global.slideHistory.pushNewChange(this, type, nextChange, prevChange, historyTag);
    }

    this.props = mergeDeep(this.props, props);

    if (callBack) {
      callBack();
    }
  };

  getObjectComponent = () => {
    switch (this.type) {
      case SlideTypes.textObject:
        return TextObject2;
      case SlideTypes.imageObject:
        return ImageObject;
      case SlideTypes.shapeObject:
        return ShapeObject;
      case SlideTypes.htmlObject:
        return HtmlObject;
      case SlideTypes.tableObject:
        return TableObject;
      case SlideTypes.chartObject:
        return ChartObject;
      case SlideTypes.lineObject:
        return LineObject;
      case SlideTypes.elbowConnectorObject:
        return ElbowConnectorObject;
      case SlideTypes.newLineObject:
        return NewLineObject;
      case SlideTypes.DataGridM:
        return DataGridM;
      case SlideTypes.IconObject:
        return IconObject;
      case SlideTypes.GroupObject:
        return GroupObject;
      case SlideTypes.calloutObject:
        return CalloutObject;
      case SlideTypes.financeTable:
        return FinanceTable;
      case SlideTypes.financialChart:
        return FinancialChart;
      case SlideTypes.mainFinanceTable:
        return MainFinanceTable;
    }

    return null;
  };

  render = (props) => {
    switch (this.type) {
      case SlideTypes.textObject:
        return (
          <ErrorHandler>
            <TextObject2
              value={this.value}
              {...this.props}
              onValueChange={this.updateValue}
              onPropsChange={this.updateProps}
              {...props}
            />
          </ErrorHandler>
        );
      case SlideTypes.imageObject:
        return (
          <ImageObject
            value={this.value}
            {...this.props}
            onValueChange={this.updateValue}
            onPropsChange={this.updateProps}
            {...props}
          />
        );
      case SlideTypes.shapeObject:
        return (
          <ShapeObject
            value={this.value}
            {...this.props}
            onValueChange={this.updateValue}
            onPropsChange={this.updateProps}
            {...props}
          />
        );
      case SlideTypes.htmlObject:
        return (
          <HtmlObject
            value={this.value}
            {...this.props}
            onValueChange={this.updateValue}
            onPropsChange={this.updateProps}
            {...props}
          />
        );
      case SlideTypes.tableObject:
        return (
          <TableObject
            value={this.value}
            {...this.props}
            onValueChange={this.updateValue}
            onPropsChange={this.updateProps}
            {...props}
          />
        );
      case SlideTypes.chartObject:
        return (
          <ChartObject
            value={this.value}
            {...this.props}
            onValueChange={this.updateValue}
            onPropsChange={this.updateProps}
            {...props}
          />
        );
      case SlideTypes.lineObject:
        return (
          <LineObject
            value={this.value}
            {...this.props}
            onValueChange={this.updateValue}
            onPropsChange={this.updateProps}
            {...props}
          />
        );
      case SlideTypes.elbowConnectorObject:
        return (
          <ElbowConnectorObject
            value={this.value}
            {...this.props}
            onValueChange={this.updateValue}
            onPropsChange={this.updateProps}
            {...props}
          />
        );
      case SlideTypes.newLineObject:
        return (
          <NewLineObject
            value={this.value}
            {...this.props}
            onValueChange={this.updateValue}
            onPropsChange={this.updateProps}
            {...props}
          />
        );
      case SlideTypes.DataGridM:
        return (
          <DataGridM
            value={this.value}
            {...this.props}
            onValueChange={this.updateValue}
            onPropsChange={this.updateProps}
            {...props}
          />
        );
      case SlideTypes.IconObject:
        return (
          <IconObject
            value={this.value}
            {...this.props}
            onValueChange={this.updateValue}
            onPropsChange={this.updateProps}
            {...props}
          />
        );
      case SlideTypes.GroupObject:
        return (
          <GroupObject
            value={this.value}
            {...this.props}
            onValueChange={this.updateValue}
            onPropsChange={this.updateProps}
            {...props}
          />
        );
      case SlideTypes.calloutObject:
        return (
          <CalloutObject
            value={this.value}
            {...this.props}
            onValueChange={this.updateValue}
            onPropsChange={this.updateProps}
            {...props}
          />
        );
      case SlideTypes.financeTable:
        return (
          <FinanceTable
            value={this.value}
            {...this.props}
            onValueChange={this.updateValue}
            onPropsChange={this.updateProps}
            {...props}
          />
        );
      case SlideTypes.financialChart:
        return (
          <FinancialChart
            value={this.value}
            {...this.props}
            onValueChange={this.updateValue}
            onPropsChange={this.updateProps}
            {...props}
          />
        );
      case SlideTypes.mainFinanceTable:
        return (
          <MainFinanceTable
            value={this.value}
            {...this.props}
            onValueChange={this.updateValue}
            onPropsChange={this.updateProps}
            {...props}
          />
        );
    }

    return <div>Nope</div>;
  };
}

export { SlideObject, SlideTypes };
