import React from "react";
import CalculatedDriver from "../../../../Financials/components/CalculatedDriver/CalculatedDriver";
import InfoDialog from "../../../../../../components/dialogs/financials/InfoDialog/InfoDialog";
import DialogContent from "@mui/material/DialogContent";
import { Label } from "../../../../../../components/typography/label/Label";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "../../../../../../components/actions/IconButton";
import { IconX } from "../../../../../../components/icons/svgIcons";
import { useParams, Navigate } from "react-router-dom";
import CircleLoader from "../../../../../../components/Loaders/CircleLoader/CircleLoader";
import ConvertToSimpleDialog from "../components/ConvertToSimpleDialog";

const route = "/forecast/revenue";

const errorMessage =
  "Your formula creates a circular reference! Please change the formula in order to avoid a circular reference.";

export default function CalculatedFormulaEdit(props) {
  const { id } = useParams();
  const [driver, setDriver] = React.useState(global.Modeliks.DriversStore.getItem(id));
  const [navigateTo, setNavigateTo] = React.useState(route);
  const [open, setOpen] = React.useState(false);
  const [forceNavigate, setForceNavigate] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const CloseFormulaEdit = () => {
    setDriver(null);
  };

  const setFormula = (formula) => {
    setLoading(true);

    if (formula) {
      driver.setFormula(formula);
    } else {
      driver.removeFormula();
    }
    global.Modeliks.DriversStore.saveAll(() => {
      setLoading(false);
      setForceNavigate(true);
    });
  };

  if (loading) {
    return (
      <div className={"main_circle_loader_container"}>
        <CircleLoader />
      </div>
    );
  }

  if ((navigateTo && !driver) || forceNavigate) {
    window.location.href = navigateTo;
    return;
    // return <Navigate to={navigateTo}/>;
  }

  // CloseFormulaEdit

  return (
    <InfoDialog key={driver.Ref_ID} onClose={CloseFormulaEdit} open={true}>
      <DialogTitle className="spsccc_indicator_main_dialog fdd_calculated_driver_object">
        <div className="spsccc_indicator_name_children ">
          <Label label={driver.Name} />
        </div>
        <IconButton size="small" onClick={() => setOpen(true)}>
          <IconX />
        </IconButton>
      </DialogTitle>
      <DialogContent className="pscf_single_finance_tab" dividers>
        <CalculatedDriver
          CalculatedDriver={driver}
          id={{ dataGridRow: { data: { ID: driver.ID } } }}
          formula={driver.Formula}
          setFormula={setFormula}
          drivers={driver.getChildDrivers() ? driver.getChildDrivers() : []}
          title={driver.Name}
          errorMessage={errorMessage}
        />
      </DialogContent>
      <ConvertToSimpleDialog
        open={open}
        title={driver.Name}
        onClose={() => setOpen(false)}
        Convert={() => setFormula(null)}
      />
    </InfoDialog>
  );
}
