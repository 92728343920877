import httpService from "../httpService";
const resource = "/api";

function createCustomPage(section) {
  return httpService().post(
    `${resource}/addToCustomPages`,
    { section },
    {
      company_id: global.Modeliks.CompanyInfo.ID,
      is_custom: true,
    },
  );
}

function createCustomPageForBusinessScenario(section) {
  return httpService().post(
    `${resource}/addToCustomPages`,
    { section },
    {
      company_id: global.Modeliks.CompanyInfo.ID,
      business_plan_scenario_id: global.Modeliks.BusinessPlanScenarioInfo.ID,
      is_custom: true,
    },
  );
}

function copyPageFile(oldId, newId) {
  return httpService().post(
    `${resource}/CopyPageFile`,
    {},
    {
      oldID: oldId,
      newID: newId,
      company_id: global.Modeliks.CompanyInfo.ID,
      business_plan_scenario_id: global.Modeliks.BusinessPlanScenarioInfo.ID,
    },
  );
}

function createNewSectionFolder() {
  return httpService({
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  }).post(`${resource}/CreateFolder`, {
    client_id: global.Modeliks.Account.ID,
    company_id: global.Modeliks.CompanyInfo.ID,
    business_plan_scenario_id: global.Modeliks.BusinessPlanScenarioInfo.ID,
  });
}

function createBlankPitchSection({ key, value, order }) {
  return httpService({
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  }).post(`${resource}/addBlankPage`, {
    section: {
      SectionName: "Blank Slide",
      order,
      CategoryID: 5,
      Hidden: false,
      [key]: value,
      isCustom: true,
      dontShowInDialog: true,
      isDeleted: false,
      CreatedAt: new Date().toISOString(),
      UpdatedAt: new Date().toISOString(),
    },
    company_id: global.Modeliks.CompanyInfo.ID,
    pitch_scenario_id: value,
  });
}

function deleteBusinessPlanSection(id) {
  return httpService({
    headers: {
      "Access-Control-Allow-Origin": window.location.protocol + "//" + window.location.host,
      Accept: "application/json",
    },
  }).deleteReq(`${resource}/businessplanpages`, { ID: id });
}

export {
  createCustomPage,
  createCustomPageForBusinessScenario,
  copyPageFile,
  deleteBusinessPlanSection,
  createNewSectionFolder,
  createBlankPitchSection,
};
