import React from "react";
import FinanceTable from "../../../../../components/tables/FinanceTable";
import PropTypes from "prop-types";
import "./SingleFinanceTab.scss";
import SingleFinanceChart from "./SingleFinanceChart";
import ChangeEnabled from "./components/ChangeEnabled";
import CalculatedDriverTitle from "./components/CalculatedDriverTitle";
import FinanceCalculatedDrivers from "./components/FinanceCalculatedDrivers";
import { UnitTypes } from "../../../../../data/Finance/CalculatedDriver/constants";
import CalculatedDriverGrowth from "./components/CalculatedDriverGrowth";
import { Vector } from "../../../../../components/dividers/Vector";
import { CollapsedTable } from "../../../../../components/tables/FinanceTable/components/CollapsedTable";
import CollapseTableComponent from "../../../../../components/tables/FinanceTable/components/CollapseTableComponent";
import SignupsTab from "../../Revenue/components/RSubscription/multiple/SignupsTab";

const growthDriverTableID = "dialog_table_growth-";
const scrollContainerID = "dialog_table_container";

class SingleFinanceTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      calculatedDriver: false,
      editDriver: false,
      min: 0,
      max: 100,
      displayOfDecimals: 0,
    };

    this.actualPeriods = [
      ...global.Modeliks.DateHelper.months_before_actual,
      ...global.Modeliks.DateHelper.years_before_actual,
    ];
    if (
      this.props.selectedTab.dataGridRow.data.Ref_Table ===
        global.Modeliks.Tables.Finance_CalculatedDrivers.TableName &&
      this.props.selectedTab.dataGridRow.data.Formula === null
    ) {
      this.props.openCalculatedDriver();
    }
  }

  handleGrowth = (driver = null) => {
    if (driver === null) {
      return;
    }

    if (driver.hasOwnProperty("GrowthDriver")) {
      driver.removeGrowth();
    } else {
      driver.convertToGrowthDriver();
    }
    this.props.onDataChanged();
    this.scrollToView(driver);
  };

  scrollToView = (driver) => {
    if (driver && driver.hasOwnProperty("GrowthDriver")) {
      setTimeout(() => {
        const id = growthDriverTableID + driver.GrowthDriver.ID;
        document.getElementById(id).scrollIntoView({ block: "end", behavior: "smooth" });
      }, 100);
    }
  };

  handeChangePercentage = (e) => {
    let num = e.target.value;
    const isDecimal = new RegExp("^[+-]?[0-9]{1,9}(?:.[0-9]{1,2})?$");
    if (isDecimal.test(num)) {
      let percent = num;
      if (percent <= this.state.min) {
        percent = this.state.min;
      }
      this.setState({ percentage: percent });
    } else if (num === "") {
      this.setState({ percentage: "" });
    }
  };

  onApplyClick = () => {
    if (this.state.percentage !== "") {
      let percent = parseFloat(this.state.percentage);
      const totalPercent = (100 + percent) / 100;
      this.props.dataGridRow.data.changeApply(totalPercent);
      this.props.onDataChanged(this.props.dataGridRow.data.Values[0]);
      this.state.percentage = "";
    } else {
      console.error("not valid", this.state.percentage);
    }
  };

  addCalculatedDriver = (driver) => {
    if (!this.props.tabs.find((d) => d.dataGridRow.data.ID === driver.ID)) {
      let newDriver = this.props.Driver(driver);
      if (newDriver.dataGridRow.data.Ref_Field === "value") {
        let index = this.props.tabs.indexOf(this.props.tab) + 1;
        this.props.tabs.splice(index, 0, newDriver);
        this.props.onTabChange(newDriver);
      }
    }
  };

  editCalculatedDriver = (driver) => {
    this.props.onTabChange(driver, false, false, (callBack) => {
      if (callBack) {
        this.props.openCalculatedDriver();
      }
    });
  };

  openCalculatedDriver = () => {
    this.props.CalculatedDriver = true;
  };

  scrollable = [];

  onScroll = (element) => {
    if (!element) {
      return null;
    }
    let elements = document.getElementsByClassName(scrollContainerID);
    let scroll = element.target.scrollLeft;
    if (elements.length > 0) {
      Object.values(elements).forEach((e, i) => {
        e.scrollLeft = scroll;
      });
    }
  };

  onScrollSingleTabDrivers = (driver) => {
    if (driver) {
      const isTab = this.props.tabs.find((t) => t.title === driver.DriverName);
      if (isTab) {
        this.props.onTabChange(isTab);
      } else {
        const el = document.getElementById(driver.ID);
        if (el) {
          el.scrollIntoView({ block: "center", behavior: "smooth" });
        } else {
          let findTab = this.props.tabs.find((t) =>
            t.dataGridRow.drivers.find((d) => d.ID === driver.ID),
          );
          if (findTab) {
            this.props.onTabChange(findTab);
            setTimeout(() => {
              const el = document.getElementById(driver.ID);
              if (el) {
                el.scrollIntoView({ block: "center", behavior: "smooth" });
              }
            }, 1000);
          }
        }
      }
    }
  };

  updateTable = (e, IsDisplayDecimals) => {
    if (IsDisplayDecimals || IsDisplayDecimals === 0) {
      this.setState({ displayOfDecimals: IsDisplayDecimals });
    } else {
      this.forceUpdate();
    }
  };

  render() {
    if (this.props.CalculatedDriver) {
      return null;
    }

    if (this.props.selectedTab.isTabSignups) {
      return (
        <SignupsTab
          updateTable={this.updateTable}
          displayOfDecimals={this.state.displayOfDecimals}
          {...this.props}
        />
      );
    }

    return (
      <>
        <SingleFinanceChart
          displayOfDecimals={this.state.displayOfDecimals}
          title={this.props.chartTitle ? this.props.chartTitle : this.props.subtitle}
          checkedGrowth={this.state.checkedGrowth}
          field={"Value"}
          hasGrowthValue={this.props.selectedTab.dataGridRow.data.hasOwnProperty("GrowthDriver")}
          unit={
            this.props.dataGridRow.data.UnitType === UnitTypes.Percentage
              ? "%"
              : this.props.dataGridRow.data.UnitType === UnitTypes.Price
                ? global.Modeliks.CompanyInfo.Currency.value
                : ""
          }
          changeValueEnabled={!this.state.checkedGrowth}
          yearlyChart={this.props.selectedTab.chart.yearlyChartData}
          monthlyChart={this.props.selectedTab.chart.monthlyChartData}
          monthlyChartTotal={this.props.selectedTab.chart.monthlyChartTotal}
          yearlyChartTotal={this.props.selectedTab.chart.yearlyChartTotal}
          hasDriver={
            this.props.selectedTab.dataGridRow.drivers.length > 0 && !this.props.enableDrivers
          }
          onClickCalculatedDriver={this.props.openCalculatedDriver}
          multiple={this.props.multiple}
          enableDrivers={this.props.enableDrivers}
          disabled={!(this.props.selectedTab.dataGridRow.data.IsSimple && this.props.enableDrivers)}
          key={"sfc_" + this.props.dataGridRow.data.ID}
        />
        <div
          id="pscf_single_finance_tab_content_div_bottom_scroll"
          className="pscf_single_finance_tab_content_div"
        >
          {!this.props.hasDriver ? (
            <ChangeEnabled
              subtitle={
                this.props.tab.hasOwnProperty("table_title")
                  ? this.props.tab.table_title
                  : this.props.tab.subtitle
              }
              description={
                this.props.tab.hasOwnProperty("description") ? this.props.tab.description : ""
              }
              disableInfoIcon={this.props.tab.disableInfoIcon}
              percentage={this.state.percentage}
              selectedTab={this.props.selectedTab}
              changeEnabled={this.props.selectedTab.changeEnabled}
              onApplyClick={this.onApplyClick}
              onDataChanged={this.props.onDataChanged}
              handeChangePercentage={this.handeChangePercentage}
            />
          ) : (
            <CalculatedDriverTitle
              title={this.props.title}
              button="Edit Formula"
              displayActuals={true}
              width="125px"
              selectedTab={this.props.selectedTab}
              scroll={this.onScrollSingleTabDrivers}
              driver={this.props.selectedTab.dataGridRow.data}
              onDataChanged={this.props.onDataChanged}
              onClick={this.props.openCalculatedDriver}
              componentChange={
                <ChangeEnabled
                  subtitle={
                    this.props.tab.hasOwnProperty("table_title")
                      ? this.props.tab.table_title
                      : this.props.tab.subtitle
                  }
                  description={
                    this.props.tab.hasOwnProperty("description") ? this.props.tab.description : ""
                  }
                  percentage={this.state.percentage}
                  selectedTab={this.props.selectedTab}
                  changeEnabled={this.props.selectedTab.changeEnabled}
                  onApplyClick={this.onApplyClick}
                  onDataChanged={this.props.onDataChanged}
                  handeChangePercentage={this.handeChangePercentage}
                  disableActuals
                  disableInfoIcon
                />
              }
            />
          )}

          <div className={scrollContainerID} onScroll={this.onScroll}>
            {!this.props.selectedTab.hideDataGridRow && (
              <CollapseTableComponent
                disableTruncateNumbers
                EditStream
                hideCheckBox={true}
                useCustomizableTable={true}
                updateTable={this.updateTable}
                disabledEdit={this.EditPermission}
                title="Forecast"
              >
                <FinanceTable
                  ref={(ref) => (this.scrollable[0] = ref)}
                  indexTab={2000}
                  EditStream
                  displayOfDecimals={this.state.displayOfDecimals}
                  IsNumberFormatDisabled
                  min={0}
                  unitDisplay={true}
                  showRowHeaders={false}
                  useCustomizableTable={true}
                  disabled={this.props.selectedTab.checkedGrowth}
                  rows={[this.props.dataGridRow]}
                  headers={this.props.headers}
                  useDateIndexes={true}
                  onDataChanged={this.props.onDataChanged}
                />
              </CollapseTableComponent>
            )}
          </div>
          {!this.props.selectedTab.dataGridRow.drivers.length > 0 &&
            this.props.selectedTab.childrenFunc &&
            this.props.selectedTab.isActualTop &&
            this.props.selectedTab.childrenFunc(this.props.selectedTab, this.onScroll)}

          <CalculatedDriverGrowth
            data={this.props.selectedTab.dataGridRow.data}
            isEnabledGrowth={this.props.selectedTab.growthEnabled}
            isHidden={true}
            onScroll={this.onScroll}
            onDataChanged={this.props.onDataChanged}
            headers={this.props.headers}
            onChange={this.handleGrowth}
            growthDriverTableID={growthDriverTableID}
          />
            {(this.props.selectedTab.dataGridRow.drivers.length > 0 && this.props.tab.showChildrenFunc && this.props.tab.childrenFunc) && this.props.tab.childrenFunc(this.props.tab)}
          {global.Modeliks.PERMISSIONS.Dashboards.restrictions.overAll.active &&
            this.props.selectedTab.allowDisplayActuals &&
            this.props.selectedTab.showActuals && (
              <div className="dialog_table_container">
                <CollapseTableComponent
                  disableTruncateNumbers
                  title="Actuals"
                  EditStream
                  showActualTitle={true}
                  useCustomizableTable={true}
                  updateTable={this.updateTable}
                  hideCheckBox={true}
                >
                  <FinanceTable
                    indexTab={3000}
                    min={0}
                    useActual={true}
                    unitDisplay={true}
                    showRowHeaders={false}
                    useCustomizableTable={true}
                    useDashboards={true}
                    disabled={this.props.selectedTab.checkedGrowth}
                    rows={[
                      this.props.selectedTab.hasOwnProperty("actualGridRow")
                        ? this.props.selectedTab.actualGridRow
                        : this.props.dataGridRow,
                    ]}
                    headers={this.actualPeriods}
                    useDateIndexes={true}
                    onDataChanged={this.props.onDataChanged}
                    EditStream
                    displayOfDecimals={this.state.displayOfDecimals}
                    IsNumberFormatDisabled
                  />
                </CollapseTableComponent>
              </div>
            )}

          {this.props.hasDriver && <Vector color top />}

          <FinanceCalculatedDrivers
            growthDriverTableID={growthDriverTableID}
            handleGrowth={this.handleGrowth}
            onScroll={this.onScroll}
            scroll={this.onScrollSingleTabDrivers}
            drivers={this.props.selectedTab.dataGridRow.drivers}
            hasDriver={this.props.hasDriver}
            headers={this.props.headers}
            displayOfDecimals={this.state.displayOfDecimals}
            actualPeriods={this.actualPeriods}
            onDataChanged={this.props.onDataChanged}
            addCalculatedDriver={this.addCalculatedDriver}
            editCalculatedDriver={this.editCalculatedDriver}
            selectedTab={this.props.selectedTab}
            tabs={this.props.tabs}
          />

          {!this.props.selectedTab.dataGridRow.drivers.length > 0 &&
            this.props.selectedTab.childrenFunc &&
            !this.props.selectedTab.isActualTop &&
            this.props.selectedTab.childrenFunc(this.props.selectedTab, this.onScroll)}
        </div>
      </>
    );
  }
}

SingleFinanceTab.propTypes = {
  title: PropTypes.string,
  tab: PropTypes.object,
  subtitle: PropTypes.string,
  monthlyChartData: PropTypes.object,
  yearlyChartData: PropTypes.object,
  dataGridRow: PropTypes.object,
  headers: PropTypes.array,
  onDataChanged: PropTypes.func,
};

export default SingleFinanceTab;
