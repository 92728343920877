import React, { Fragment, useState } from "react";
import "../HeaderTextEditor.scss";
import "../HeaderDefault.scss";
import { BorderColor, BorderWeight, Plus } from "../../components/IconButtons/SubMenuIcons";
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import Tooltip from "@mui/material/Tooltip";
import ButtonMui from "../../../../components/buttons/buttonMui/buttonMui";

function BorderWeightEditComponent(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openBorder = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onChange = (style) => {
    props.onChange(style);
    setAnchorEl(null);
  };
  const handleBorderWeightChange = (weight) => {
    if (props.objType === "textObject" || props.objType === "imageObj") {
      if (props.style.borderStyle) props.onChange({ borderWidth: weight });
      else {
        props.onChange({ borderWidth: weight, borderStyle: "solid", borderColor: "#000000" });
      }
    } else {
      props.onChange({ borderWeight: weight });
    }
  };

  const BorderWeightEdit = () => {
    return (
      <Menu
        onMouseMove={(e) => {
          e.stopPropagation();
        }}
        className="border_weight"
        menuButton={({ open }) => (
          <ButtonMui
            className={props.className ? props.className : ""}
            tooltipText={"Border Weight"}
            variant={"text"}
            isIconButton
            roundnessType={"squared"}
            color={"black"}
            label={<BorderWeight active={open} />}
          />
        )}
      >
        <MenuItem
          onClick={(event) =>
            handleBorderWeightChange(
              props.objType === "textObject" ||
                props.objType === "imageObj" ||
                props.objType === "tableObject"
                ? "0px"
                : "0",
            )
          }
        >
          No border
        </MenuItem>
        <MenuItem
          onClick={(event) =>
            handleBorderWeightChange(
              props.objType === "textObject" ||
                props.objType === "imageObj" ||
                props.objType === "tableObject"
                ? "1px"
                : "1",
            )
          }
        >
          1 pix
        </MenuItem>
        <MenuItem
          onClick={(event) =>
            handleBorderWeightChange(
              props.objType === "textObject" ||
                props.objType === "imageObj" ||
                props.objType === "tableObject"
                ? "2px"
                : "2",
            )
          }
        >
          2 pix
        </MenuItem>
        <MenuItem
          onClick={(event) =>
            handleBorderWeightChange(
              props.objType === "textObject" ||
                props.objType === "imageObj" ||
                props.objType === "tableObject"
                ? "3px"
                : "3",
            )
          }
        >
          3 pix
        </MenuItem>
        <MenuItem
          onClick={(event) =>
            handleBorderWeightChange(
              props.objType === "textObject" ||
                props.objType === "imageObj" ||
                props.objType === "tableObject"
                ? "4px"
                : "4",
            )
          }
        >
          4 pix
        </MenuItem>
        <MenuItem
          onClick={(event) =>
            handleBorderWeightChange(
              props.objType === "textObject" ||
                props.objType === "imageObj" ||
                props.objType === "tableObject"
                ? "8px"
                : "8",
            )
          }
        >
          8 pix
        </MenuItem>
        <MenuItem
          onClick={(event) =>
            handleBorderWeightChange(
              props.objType === "textObject" ||
                props.objType === "imageObj" ||
                props.objType === "tableObject"
                ? "12px"
                : "12",
            )
          }
        >
          12 pix
        </MenuItem>
        <MenuItem
          onClick={(event) =>
            handleBorderWeightChange(
              props.objType === "textObject" ||
                props.objType === "imageObj" ||
                props.objType === "tableObject"
                ? "16px"
                : "16",
            )
          }
        >
          16 pix
        </MenuItem>
        <MenuItem
          onClick={(event) =>
            handleBorderWeightChange(
              props.objType === "textObject" ||
                props.objType === "imageObj" ||
                props.objType === "tableObject"
                ? "24px"
                : "24",
            )
          }
        >
          24 pix
        </MenuItem>

        {/*<BorderTop  onClick={(event) => props.onChange({borderTop: '1px solid'})}  />*/}
        {/*<BorderBottom  onClick={(event) => props.onChange({borderBottom: '1px solid'})} />*/}
        {/*<BorderLeft  onClick={(event) => props.onChange({borderLeft: '1px solid'})}/>*/}
        {/*<BorderRight  onClick={(event) => props.onChange({borderRight: '1px solid'})} />*/}

        {/*<div style={{height:'1px', backgroundColor: 'gray', marginTop:'10px', marginBottom: '10px' }}/>*/}
        {/*<ColorPicker  value={props.style.borderColor ? props.style.borderColor : null} onChange={(event) => onChange({borderColor: event.target.value})}/>*/}
        {/*<BorderWeight aria-controls={openBorderWeight ? 'basic-menu' : undefined}*/}
        {/*aria-haspopup="true"*/}
        {/*aria-expanded={openBorderWeight ? 'true' : undefined}*/}
        {/*onClick={handleClick3}  />*/}
        {/*<Menu*/}
        {/*id="basic-menu"*/}
        {/*anchorEl={anchorE3}*/}
        {/*open={openBorderWeight}*/}
        {/*onClose={handleClose3}*/}
        {/*onClose={handleClose3}*/}
        {/*MenuListProps={{*/}
        {/*'aria-labelledby': 'basic-button',*/}
        {/*}}*/}
        {/*>*/}
        {/*<span style={{marginLeft: '20px', fontSize: '14px'}}>Border weight</span>*/}
        {/*<Box width='200px'  px={2.5} mt={3}>*/}
        {/*<Slider  value={props.style.borderWidth ? props.style.borderWidth : 0} step={1} min={0} max={10}  valueLabelDisplay="auto" onChange={(event) => props.onChange({borderWidth: event.target.value})} />*/}
        {/*</Box>*/}
        {/*</Menu>*/}
        {/*<RemoveColor onClick={(event) => props.onStyleRemove(['borderTop','borderBottom','borderLeft','borderRight','borderWidth','borderColor'])} />*/}
      </Menu>
    );
  };

  return <>{BorderWeightEdit()}</>;
}

export default BorderWeightEditComponent;
