import React from "react";
import { SkeletonElement } from "./SkeletonElement";
import "./Skeleton.scss";

export const SkeletonFinanceLoader = (props) => {
  let month = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  let year = [0, 1, 2];
  let table = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  return (
    <div className="skeleton_container">
      <div className={"skeleton_loader"}>
        <div className="skeleton_row sub_menu">
          <div className="sub_menu_btn big">
            <SkeletonElement type="bottom_nav" />
          </div>
          <div className="sub_menu_btn small">
            <SkeletonElement type="bottom_nav" />
          </div>
        </div>
        <div className="skeleton_row small">
          <div className="small_container">
            <SkeletonElement type="name" />
            <SkeletonElement type="btn" />
          </div>
          <div className="small_container">
            <SkeletonElement type="small_btn" />
            <SkeletonElement type="mid_btn" />
          </div>
        </div>
        <div className="skeleton_row charts">
          <div className="chart big">
            {month.map((el, index) => {
              return (
                <div
                  key={new Date().getTime() + "big_chart" + index}
                  className={`chart_bar_container`}
                >
                  <SkeletonElement type="chart_bar_label" />
                  <SkeletonElement type="chart_bar" />
                </div>
              );
            })}
          </div>
          <div className="chart small">
            {year.map((el, index) => {
              return (
                <div
                  key={new Date().getTime() + "small_chart" + index}
                  className={`chart_bar_container`}
                >
                  <SkeletonElement type="chart_bar_label" />
                  <SkeletonElement type="chart_bar" />
                </div>
              );
            })}
          </div>
        </div>
        <div className="skeleton_row table">
          {table.map((el, index) => {
            return (
              <div key={new Date().getTime() + "tbl_rw" + index} className="table_row">
                <div className="table_header">
                  <SkeletonElement type="tr" />
                </div>
                <div className="table_data">
                  <SkeletonElement type="tr" />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
