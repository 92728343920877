import React, { Fragment, useState } from "react";
// import '../HeaderTextEditor.scss'
// import '../HeaderDefault.scss'
import Menu from "@mui/material/Menu";
import RotateSubContextMenu from "./RotateSubContextMenu";
import { Cut, Copy, Paste, LinkFromExternalSource } from "../IconButtons/SubMenuIcons";
import OrderSubContextMenu from "./OrderSubContextMenu";
import TextSubContextMenu from "./TextSubContextMenu";
import CenterOnPageSubContextMenu from "./CenterOnPageSubContextMenu";
import LinkSubContextMenu from "./LinkSubContextMenu";
import DefaultItems from "./DefaultSubContextItems";
// import {} from "../../components/IconButtons/SubMenuIcons";

const onCopy = (e) => {
  let range;
  let selection = document.getSelection();
  let cloned = "";
  cloned += selection;
  let textarea = document.createElement("textarea");
  textarea.value = cloned;
  textarea.select();
  document.body.appendChild(textarea);
  document.execCommand("copy");
  textarea.remove();
};

const onCut = () => {
  let range;
  let selection = document.getSelection();
  let cloned = "";
  cloned += selection;
  let textarea = document.createElement("textarea");
  textarea.value = cloned;
  textarea.select();
  document.body.appendChild(textarea);
  document.execCommand("cut");
  textarea.remove();
  range = selection.getRangeAt(0);
  range.deleteContents();
};

// const onChange = (style) => {
// 	props.onChange(style);
// }
function TextContextMenuComponent(props) {
  const onChange = (style) => {
    props.onChange(style);
  };
  const TextContextMenu = () => {
    return (
      <>
        <div>
          <DefaultItems
            onDelete={() => props.onDelete()}
            onCopy={(e) => onCopy(e)}
            onCut={(e) => onCut(e)}
          />
          <OrderSubContextMenu />

          <div id={"divider"}></div>
          <div id={"contextMenuOption"}>
            <span>Format options</span>
          </div>
        </div>
      </>
    );
  };
  return <>{TextContextMenu()}</>;
}
export default TextContextMenuComponent;
