import React from "react";
import MaterialSelect from "../../../../components/actions/SelectM";
// import Input from "../../../../components/actions/Input";
import AutocompleteInput from "../../../../components/actions/inputs/AutocompleteInput";
import { Input } from "@mui/material";
import { InputAdornment, TextField, IconButton } from "@mui/material";
import CustomUnChecked from "../../../../components/actions/Checkboxes/CustomUnChecked";
import { Search } from "../../../../components/icons/svgIcons";
import { Tick } from "../../../components/components/menus/LeftMenu/components/Icons/LeftMenuIcons";

class DriverInsertionTool extends React.Component {
  SubtotalObject = { value: "subtotal", description: "Subtotal / Total" };

  constructor(props) {
    super(props);

    this.validDrivers = this.getValidDrivers();

    this.state = {
      newDriver: false,
      driverName: "",
      driver: props.value ? this.getDriverByValue(props.value) : null,
    };
  }

  isValidDriver = (driver) => {
    if (driver.hasOwnProperty("UseForCharts")) {
      return driver.UseForCharts;
    }

    return driver.IsExisting;
  };

  getValidDrivers = () => {
    return global.Modeliks.DriversStore.filter((d) => this.isValidDriver(d)).filter(
      (d) =>
        [...Object.values(this.props.validDrivers)].findIndex(
          (c) => c.DriverName == d.DriverName,
        ) == -1,
    );
  };

  getDriverByValue = (value) => {
    let options = this.getOptions();

    if (options.findIndex((c) => c.key === value) > -1) {
      return options[options.findIndex((c) => c.key === value)];
    } else if (options.findIndex((c) => c.DriverName === value) > -1) {
      return options[options.findIndex((c) => c.DriverName === value)];
    }
  };

  setDriverFromExisting = (e, driver) => {
    if (driver && driver.value && driver.value === this.SubtotalObject.value) {
      this.setState({ newDriver: true });
    } else {
      this.setState({ driver: driver }, () => this.insertDriver());
    }
    // this.props.setDriverFromExisting(driver);
  };

  insertDriver = (e) => {
    if (this.state.newDriver && this.state.driverName) {
      this.props.insertExistingDriver({ driverName: this.state.driverName });
    } else if (this.state.driver) {
      if (this.state.driver.key) {
        this.props.insertExistingDriver(this.state.driver.key);
      } else if (this.state.driver.ID) {
        let Ref_ID = this.state.driver.Ref_ID.toString();
        if (Ref_ID.includes("CompanyScenarios")) {
          let matches = Ref_ID.match(/(\d+)/);
          if (matches && matches.length) {
            Ref_ID = Ref_ID.replace(`CompanyScenarios-${matches[0]}-`, "CompanyScenarios-$CS_ID-");
          }
        } else if (this.state.driver.Ref_Field == "accrued_income_tax") {
          Ref_ID = null;
        }
        let obj = { Ref_Field: this.state.driver.Ref_Field };
        if (this.state.driver.Ref_Field == "totals") {
          Object.assign(obj, { Ref_Table: this.state.driver.Ref_Table });
        }
        if (Ref_ID) {
          Object.assign(obj, { Ref_ID: Ref_ID });
        }
        this.props.insertExistingDriver(JSON.stringify(obj));
      }
    }
    this.setState({
      newDriver: false,
      driverName: "",
      driver: null,
    });
  };

  getOptions = () => {
    let arr = [];
    if (this.props.subTotals) {
      arr.push(this.SubtotalObject);
    }
    return [...arr, ...Object.values(this.props.validDrivers), ...this.validDrivers];
  };

  render = () => {
    console.log(this.state.driver);
    return (
      <>
        {this.state.newDriver ? (
          <Input
            placeholder={"Subtotal name"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={this.insertDriver}>
                  <Tick />
                </IconButton>
              </InputAdornment>
            }
            onChange={(e) => this.setState({ driverName: e.target.value })}
          />
        ) : (
          <AutocompleteInput
            height={30}
            width={this.props.width}
            className="fdd_cd_driver_autocomplete_input_style"
            // getOptionDisabled={(option) => {
            //     return null //this.props.drivers.some((r) => r === option);
            // }}
            //                disabled={!global.Modeliks.PERMISSIONS.Dashboards.restrictions.edit.active}
            options={this.getOptions()}
            value={this.state.driver}
            onChange={(e, driver) => this.setDriverFromExisting(e, driver)}
          />
        )}
      </>
    );
  };
}

export default DriverInsertionTool;
