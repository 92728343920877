import ReactDOM from "react-dom";

const ChartEditorPortal = ({ children, visible }) => {
  if (!visible) return null;
  const el = document.getElementById("chart_editor_portal");
  if (el) {
    return ReactDOM.createPortal(children, el);
  } else {
    return null;
  }
  return ReactDOM.createPortal(children, el);
};

export default ChartEditorPortal;
