import React from "react";
import styled from "styled-components";
// import {Theme} from "../../theme/styles/theme";

const StyledBox = styled.div`
  display: ${(props) => (props.display ? props.display : "flex")};
  gap: ${(props) => (props.gap ? props.gap : "20px")};
  align-items: ${(props) => (props.alignItems ? props.alignItems : "center")};
`;

export const Box = ({ display, gap, alignItems, style, ...props }) => {
  return (
    <StyledBox
      onClick={props.onClick}
      style={style}
      display={display}
      gap={gap}
      alignItems={alignItems}
      className={props.className}
    >
      {props.children}
    </StyledBox>
  );
};
