import CircleLoader from "../../components/Loaders/CircleLoader/CircleLoader";
import * as React from "react";
import { Routes, Route, Outlet, Link, Navigate } from "react-router-dom";
import { MainContainer } from "../../components/containers/MainContainer";
import Menu from "../../components/containers/Menu";
import { Box } from "../../components/containers/Box";
import {
  BusinessPlanIcon,
  DashboardsIcon,
  FinancialsIcon,
  Logo,
  PitchIcon,
  QuestionIcon,
} from "../../components/icons/svgIcons";
import SettingsComponent from "./Settings/SettingsComponent";
import TabsM from "../../components/tabs/Tabs";
import AvatarMenu from "../../components/menus/AvatarMenu/AvatarMenu";
import CompanyMenu from "../../components/menus/CompanyMenu/CompanyMenu";
import SettingsActiveIcon from "../../components/icons/SettingsActiveIcon";
import { SkeletonFinanceLoader } from "../../components/Loaders/Skeleton/SkeletonFinanceLoader";
import SlideEditorUser from "../../SlideEditor/User/index";
import Onboarding from "./Onboarding/index";
import Invite from "../Public/Auth/Invite/Invite";
import Dashboards from "./Dashboards";
import ValuationMain from "./Valuation";
import { FinancialStatementsTypes } from "../../data/Finance/Dashboards/constants";
import Verification from "../Public/Auth/Email/Verification";
import BpRoutes from "../../BusinessPlan/User/bp_routes";
import BusinessPlanPublish from "../../BusinessPlan/User/publish";
import SlideEditor from "../../SlideEditor/User";
import CalculatedFormulaEdit from "./Financials/components/CalculatedDriver/CalculatedFormulaEdit";
import "./index.scss";
import NewPassword from "../Public/Auth/Reset/NewPassword";
import { AccessControl } from "../../data/Permissions/AccessControl";
import { PermissionTypes, RoutesPerPermission } from "../../components/constants/permissions";
import RestrictedAccess, { HelpHeroHelpers } from "./restrictedAccess/RestrictedAccess";
import WelcomeUserDialog from "../../components/dialogs/welcomeUser";
import { ReusableMenu, ReusableMenuItem } from "../../components/menus/ReusableMenu/ReusableMenu";
import ButtonMui from "../../components/buttons/buttonMui/buttonMui";
import { Exit } from "../../SlideEditor/components/components/IconButtons/SubMenuIcons";
import PortfolioManagement from "./PortfolioManagement";
import UpgradePlan from "./components/UpgradePlan";
import ManageSubscription from "./ProfileSettings/AccountDetails/components/ManageSubscription";
import SuccessPayment from "../../components/pages/SuccessPayment";
import { useNavigate } from "react-router-dom/dist/index";
import Mx_MainLoader from "../../components/Loaders/MainLoader/Mx_MainLoader";

import {CommandManagerProvider} from "../../SlideEditor/services/commands/CommandManagerProvider";
import {toggleChatBot} from "../../helpers/utils/chat_bot";

const Financials = React.lazy(() => import("./Financials/index"));
const ProfileSettings = React.lazy(() => import("./ProfileSettings/index"));

export const defaultSelection = {
  url: {
    financials: "/forecast/revenue",
    valuation: "/valuation/settings",
    dashboards: "/dashboards/financial_statements",
  },
  selectedReport: {
    actuals: "ProfitAndLoss",
    financial_statements: "ProfitAndLoss",
    reports: "ProfitAndLoss",
  },
  reportView: {
    financial_statements: FinancialStatementsTypes.Comparative,
    custom_kpi: FinancialStatementsTypes.Comparative,
  },
};

class SecureApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ctrl_key: new Date().getTime(),
      loading: false,
    };
    this.modeliks_path_elements = {
      error_routes: [
        {
          path: `/formula/:id`,
          element: <CalculatedFormulaEdit key={"dada" + this.state.ctrl_key} />,
        },
      ],
      helper_routes: [
        {
          path: `/newPassword/:guid/:id`,
          element: <NewPassword key={"NewPassword_" + this.state.ctrl_key} />,
        },
        { path: `/onboarding`, element: <OnBoarding key={"onboarding_" + this.state.ctrl_key} /> },
        {
          path: `/verification/:guid`,
          element: <Verification key={"verification_" + this.state.ctrl_key} />,
        },
        {
          path: `/invite/:invite_guid/:user_id`,
          element: <Invite key={"invite_guid_" + this.state.ctrl_key} />,
        },
        { path: `*`, element: <Navigate to="/" key={"navigate_" + this.state.ctrl_key} /> },
        {
          path: `/manage_subscription_upgrade`,
          element: <ManageSubscription upgrade_plan key={"invite_guid_" + this.state.ctrl_key} />,
          UserPermissions: true,
        },
      ],
      routes: [
        {
          path: RoutesPerPermission[PermissionTypes.Pitch],
          element: <Pitch key={"m_pitch_" + this.state.ctrl_key} />,
          UserPermissions: true,
        },
        {
          path: "/CheckoutSuccess/:transaction_id",
          element: <SuccessPayment />,
          UserPermissions: false,
        },
        {
          path: `${RoutesPerPermission[PermissionTypes.BusinessPlan]}/*`,
          element: <BusinessPlan key={"m_buss_plan_" + this.state.ctrl_key} />,
          UserPermissions: true,
        },
        {
          path: "/publish/:account_guid/:client_guid",
          element: <SlideEditor publishMode={true} />,
        },
        {
          path: "/publish_mr/:account_guid/:client_guid",
          element: (
            <Dashboards
              defaultView={defaultSelection.reportView}
              defaultSelectedReport={defaultSelection.selectedReport}
              defaultUrl={defaultSelection.url}
              publishManagementReports
              publishMode={true}
            />
          ),
        },
        {
          path: "/publish_business_plan/:account_guid/:client_guid",
          element: <BusinessPlanPublish publishMode={true} />,
        },
        {
          path: `${RoutesPerPermission[PermissionTypes.Financials]}/*`,
          element: (
            <React.Suspense fallback={<SkeletonFinanceLoader />}>
              <FinancialsTab
                defaultUrl={defaultSelection.url}
                key={"ft_" + this.state.ctrl_key}
                defaultSelectedReport={defaultSelection.selectedReport}
                changeKey={() => this.changeKey()}
              />
            </React.Suspense>
          ),
          UserPermissions: true,
          index: true,
        },
        {
          path: `${RoutesPerPermission[PermissionTypes.Dashboards]}/*`,
          element: (
            <DashboardsTab
              defaultView={defaultSelection.reportView}
              defaultSelectedReport={defaultSelection.selectedReport}
              defaultUrl={defaultSelection.url}
              key={"m_dashboards_" + this.state.ctrl_key}
            />
          ),
          UserPermissions: false,
        },
        // { path: `/valuation/*`,element: <Valuation  defaultUrl={defaultSelection.url} key={'m_valuation_' + this.state.ctrl_key}/>,UserPermissions: false,},
        {
          path: `/settings`,
          element: (
            <React.Suspense fallback={<>...</>}>
              <SettingsTab key={"m_settings_" + this.state.ctrl_key} />
            </React.Suspense>
          ),
          UserPermissions: false,
        },
        {
          path: `/profile/*`,
          element: (
            <React.Suspense fallback={<>...</>}>
              <ProfileTab key={"m_profile_" + this.state.ctrl_key} />
            </React.Suspense>
          ),
          UserPermissions: false,
        },
        {
          path: `/portfolio_management`,
          element: (
            <React.Suspense fallback={<>...</>}>
              <PortfolioManagement key={"m_portfolio_mngmt_" + this.state.ctrl_key} />
            </React.Suspense>
          ),
          UserPermissions: false,
        },
        {
          path: `/`,
          element: <Navigate to={RoutesPerPermission[global.Modeliks.allowedRouteKeys[0]]} />,
          UserPermissions: false,
          exact: true,
        },
      ],
      tabs: [
        {
          link: `/pitch`,
          color: "black",
          parent: "/pitch",
          size: "p2",
          label: "Pitch",
          startIcon: <PitchIcon />,
        },
        {
          link: `/business_plan`,
          parent: "/business_plan",
          color: "black",
          size: "p2",
          label: "Business Plan",
          startIcon: <BusinessPlanIcon />,
        },
        {
          link: `${defaultSelection.url.financials}`,
          parent: "/forecast",
          color: "black",
          size: "p2",
          label: "Forecast",
          key: "financials",
          startIcon: <FinancialsIcon />,
        },
        {
          link: `${defaultSelection.url.dashboards}`,
          parent: "/dashboards",
          color: "black",
          size: "p2",
          label: "Dashboards",
          key: "dashboards",
          startIcon: <DashboardsIcon />,
        },
        // { link: `${defaultSelection.url.valuation}`,parent:'/valuation', color: 'black',size: 'p2',label: 'Valuation',key:'valuation', startIcon: <ValuationIcon/>},
      ],
    };
    this.renderModeliksRoutes = this.modeliks_path_elements.routes.map((route) => (
      <Route key={`f_route_${route.path}`} exact path={route.path} element={route.element} />
    ));
    this.renderModeliksHelperRutes = this.modeliks_path_elements.helper_routes.map((route) => (
      <Route key={`f_route_${route.path}`} exact path={route.path} element={route.element} />
    ));
    this.renderModeliksErrorRutes = this.modeliks_path_elements.error_routes.map((route) => (
      <Route key={`f_2route_${route.path}`} exact path={route.path} element={route.element} />
    ));
  }

  componentDidMount() {
    global.Modeliks.Subscribe("onCompanyChange", this.handleCompanyChange);
    global.Modeliks.toggleGlobalLoader = this.toggleGlobalLoader;
  }

  handleCompanyChange = () => {
    this.changeKey();
  };

  componentWillUnmount() {
    global.Modeliks.Unsubscribe("onCompanyChange", this.handleCompanyChange);
    global.Modeliks.toggleGlobalLoader = null;
  }

  toggleGlobalLoader = (data = !this.state.loading, loaderText = "") => {
    // debugger
    if (!data) {
      loaderText = undefined;
    }
    if (this.state.loading != !!data) {
      global.Modeliks.isLoading = !!data;
      console.log("setting state", !!data);
      const objToSave = {};
      if (this.state.loading) {
        Object.assign(objToSave, { ctrl_key: new Date().getTime() });
      }
      this.setState({ loading: !!data, ...objToSave, loaderText });
    }
  };

  changeKey() {
    this.setState({ ctrl_key: new Date().getTime() });
  }

  render = () => {
    return (
      <>
        {this.state.loading && <Mx_MainLoader message={this.state.loaderText} />}
        <Routes>
          <Route
            path="/"
            element={
              <Layout
                defaultUrl={defaultSelection.url}
                modeliks_elements={this.modeliks_path_elements}
                key={"layout_" + this.state.ctrl_key}
              />
            }
          >
            {this.renderModeliksRoutes}
          </Route>
          {this.renderModeliksHelperRutes}
          {this.renderModeliksErrorRutes}
        </Routes>
      </>
    );
  };
}

export default SecureApp;

let menuItems = null;

const buildMenuItems = () => {
  global.Modeliks.toggleChatBot = toggleChatBot;

  menuItems = [];
  window.HubSpotConversations && menuItems.push(new ReusableMenuItem("Chat Support", toggleChatBot));
  if (global.HelpHero) {
    menuItems.push(
      new ReusableMenuItem("Guided Tours", () => {
        HelpHeroHelpers.enableHelpHero();
        global.HelpHero.openChecklist();
      }),
    );
  }
  menuItems.push(
    new ReusableMenuItem("Help Center", () => window.open("https://help.modeliks.com/", "_blank")),
  );
  menuItems.push(
    new ReusableMenuItem("Roadmap", () => window.open("https://roadmap.modeliks.com/", "_blank")),
  );
};

function Layout(props) {
  const isFirstTime = window.localStorage.getItem("accepted_invite");
  if (isFirstTime) {
    // if (global.Modeliks.User.ID === global.Modeliks.Account.Owner_ID) {
    //     if (global.onUserRegister) {
    //         global.onUserRegister(global.Modeliks.User.ID, global.Modeliks.subscriptionInfo.SubscriptionID, global.Modeliks.subscriptionInfo.data_key);
    //     }
    // }
    window.localStorage.removeItem("accepted_invite");
  }
  const [loadingCompany, setLoadingCompany] = React.useState(false);

  if (!loadingCompany && props.modeliks_elements) {
    if (menuItems == null) {
      buildMenuItems();
    }

    return (
      <>
        <div
          id={"printableContainer"}
          className={navigator.saysWho.toLowerCase().split(" ")[0]}
        ></div>
        <div id={"exportReportContainer"}></div>
        <MainContainer id="MainContainer">
          {isFirstTime && <WelcomeUserDialog />}
          <Menu>
            <Box className={"box1"} display="flex" gap="25px" alignItems="center">
              <Logo className="Logo" width={"50px"} height={"50px"} />
              <div className="spsi_company_name">
                <CompanyMenu
                  onCompanyLoad={() => setLoadingCompany(true)}
                  onCompanyLoadFinish={() => setLoadingCompany(false)}
                />
              </div>
            </Box>
            <Box display="flex" gap="40px" alignitems="center">
              <TabsM defaultUrl={props.defaultUrl}>{props.modeliks_elements}</TabsM>
            </Box>
            <Box display="flex" gap="15px" alignitems="center">
              <UpgradePlan navigationUpdate />
              <ReusableMenu
                menuButton={
                  <ButtonMui
                    style={{ height: "42px" }}
                    tooltipText="Help"
                    variant="text"
                    color={"black"}
                    label={<QuestionIcon />}
                    isIconButton
                  />
                }
                menuItems={menuItems}
              />
              <ButtonMui
                style={{ height: "42px" }}
                linkTo={"/settings"}
                tooltipText="Settings"
                variant="text"
                color={"black"}
                label={<SettingsActiveIcon />}
                isIconButton
              />
              <AvatarMenu />
            </Box>
          </Menu>
          <Outlet />
        </MainContainer>
        <div id={"tmpPrint"}></div>
        <div onClick={toggleChatBot} id={"chat_bot_area"}>
          <ButtonMui
            onClick={toggleChatBot}
            variant={"contained"}
            isFloatingButton={true}
            label={<Exit />}
          />
        </div>
        <div onClick={toggleChatBot} id={"chat_bot_area"} className={"high"} />
      </>
    );
  } else {
    return (
      <div className={"main_circle_loader_container"}>
        <CircleLoader />
      </div>
    );
  }
}

function OnBoarding() {
  return <Onboarding />;
}

function FinancialsTab(props) {
  return (
    <ComponentWithAccessControl perm_key={"Financials"}>
      <Financials {...props} />
    </ComponentWithAccessControl>
  );
}

function Pitch() {
  return (
    <ComponentWithAccessControl perm_key={"Pitch"}>
      <CommandManagerProvider>
        <SlideEditorUser />
      </CommandManagerProvider>
    </ComponentWithAccessControl>
  );
}

function DashboardsTab(props) {
  return (
    <ComponentWithAccessControl perm_key={"Dashboards"}>
      <Dashboards {...props} />
    </ComponentWithAccessControl>
  );
}

function BusinessPlan() {
  return (
    <ComponentWithAccessControl perm_key={"BusinessPlan"}>
      <CommandManagerProvider>
        <BpRoutes />
      </CommandManagerProvider>
    </ComponentWithAccessControl>
  );
}

function Valuation(props) {
  return <ValuationMain {...props} />;
}

function SettingsTab() {
  return (
    <AccessControl
      renderNoAccess={<RestrictedAccess />}
      customHasAccess={
        global.Modeliks.PERMISSIONS.Companies.restrictions.edit.active ||
        global.Modeliks.PERMISSIONS.Financials.restrictions.edit.active ||
        global.Modeliks.PERMISSIONS.Users.restrictions.edit.active
      }
      allowedPermissions={[]}
    >
      <SettingsComponent />
    </AccessControl>
  );
}

function ProfileTab() {
  return <ProfileSettings />;
}

const ComponentWithAccessControl = ({ perm_key, children, customHasAccess }) => {
  return (
    <AccessControl
      allowedPermissions={[
        {
          [global.Modeliks.PERMISSIONS[perm_key].key]:
            global.Modeliks.PERMISSIONS[perm_key].restrictions.view.key,
        },
        {
          [global.Modeliks.PERMISSIONS[perm_key].key]:
            global.Modeliks.PERMISSIONS[perm_key].restrictions.overAll.key,
        },
      ]}
      renderNoAccess={<RestrictedAccess />}
    >
      {children}
    </AccessControl>
  );
};
