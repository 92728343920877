import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import * as React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import styled from "styled-components";
import { Theme } from "../../theme/styles/theme";
import CustomUnChecked from "./Checkboxes/CustomUnChecked";

const Typography = styled.span`
  cursor: pointer;
  white-space: nowrap;
  font-family: Inter;
  font-weight: 400;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
  color: ${"#585858"};
`;

export default function CheckBoxMaterial(props) {
  let labelPlacement = "end";
  if (props && props.labelPlacement) {
    labelPlacement = props.labelPlacement;
  }

  const handleChange = (event) => {
    props.onChange();
  };

  if (!props.label) {
    return (
      <Checkbox
        size={props.size ? props.size : "small"}
        onChange={handleChange}
        icon={<CustomUnChecked size={props.size ? props.size : "small"} squared={true} />}
        checkedIcon={
          <CheckBoxRoundedIcon
            style={{ color: props.color ? props.color : Theme.colors.primary.lightblue["300"] }}
          />
        }
        checked={props.checked}
        inputProps={{ "aria-label": "controlled" }}
      />
    );
  }

  return (
    <FormControlLabel
      onChange={handleChange}
      labelPlacement={labelPlacement}
      control={
        <Checkbox
          size={props.size ? props.size : "small"}
          icon={<CustomUnChecked size={props.size ? props.size : "small"} squared={true} />}
          checkedIcon={
            <CheckBoxRoundedIcon
              style={{ color: props.color ? props.color : Theme.colors.primary.lightblue["300"] }}
            />
          }
          sx={{
            justifyContent: "space-between",
            "&.MuiSvgIcon-root": {
              color: "#FBFBFB",
              border: `1px solid red`,
              boxSizing: `border-box`,
              boxShadow: `inset 0px 0px 0px rgba(0, 0, 0, 0.2)`,
              borderRadius: `2px`,
            },
          }}
          checked={props.checked}
          inputProps={{ "aria-label": "controlled" }}
        />
      }
      label={
        props.label ? (
          <Typography fontSize={props.fontSize} fontColor={props.fontColor}>
            {props.label}
          </Typography>
        ) : (
          ""
        )
      }
    />
  );
}
