import React from "react";
import "./ButtonsActions.scss";
import ButtonMui from "../../../buttons/buttonMui/buttonMui";
import { ArrowRight } from "../../../icons/svgIcons";

export default function ButtonNext(props) {
  return (
    <ButtonMui
      label={props.nextButtonTitle ? props.nextButtonTitle : "Next"}
      width={130}
      variant={"contained"}
      color={"primary"}
      endIcon={props.nextButtonTitle ? null : <ArrowRight />}
      onClick={props.onClick}
    />
  );
}
