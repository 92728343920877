import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledSmallUserCard = styled.div`
  font-family: Inter;
  height: 450px;
  width: 100%;
  overflow: auto;
`;

const P3Strong = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  font-family: Inter;
`;

const StyledDiv = styled.div`
  width: 20%;
  text-align: left;
`;

const StyledRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #f0f1f7;
  padding-bottom: 6px;
`;

export const UserCard = ({ size = "small", ...props }) => {
  return (
    <>
      {size === "small" && (
        <StyledSmallUserCard onClick={props.onUserEdit}>{props.children}</StyledSmallUserCard>
      )}

      {size === "medium" && (
        <>
          <StyledRow style={{ position: "sticky", top: 0, left: 0, background: "white" }}>
            <StyledDiv>
              <P3Strong>Name</P3Strong>
            </StyledDiv>
            <StyledDiv>
              <P3Strong>Surname</P3Strong>
            </StyledDiv>
            <StyledDiv>
              <P3Strong>Email</P3Strong>
            </StyledDiv>
            <StyledDiv>
              <P3Strong>Active from</P3Strong>
            </StyledDiv>
            <StyledDiv>
              <P3Strong>Access type</P3Strong>
            </StyledDiv>
          </StyledRow>
          {props.children}
        </>
      )}
    </>
  );
};

UserCard.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  userType: PropTypes.string,
  hasEditIcon: PropTypes.bool,
  size: PropTypes.string,
};
