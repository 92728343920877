import React from "react";
import UnitSales from "./UnitSales";
import BillableHours from "./BillableHours";
import { RevenueTypes } from "../../../../../components/constants/finance";
import RevenueOnly from "./RevenueOnly";
import SubscriptionMultiple from "./RSubscription/multiple";
import Subscriptions from "./RSubscription";
import { DialogTypes } from "../../../../../data/Finance/constants";

class RevenueInfoDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      tab: "unit-sales",
      units: null,
      updated: false,
      multiSubscription: false,
    };
  }

  handleUnload = (e) => {
    return "Your work will be lost.";
  };

  componentDidMount() {
    window.onbeforeunload = this.handleUnload;
    document.addEventListener("keyup", this.handleEscapeKeyPress);
  }

  componentWillUnmount() {
    window.onbeforeunload = () => {};
    document.removeEventListener("keyup", this.handleEscapeKeyPress);
  }

  handleEscapeKeyPress = (event) => {
    if (event.key === "Escape") {
      global.Modeliks.showDialog(
        "Are you sure you want to close without saving?",
        DialogTypes.WarningActions,
        null,
        () => this.props.onClose(),
      );
    }
  };

  handleChange = (event, newValue) => {
    if (event !== "general") {
      this.setState({ tab: event });
    }
  };

  render() {
    if (!this.props.open) {
      return null;
    }
    if (this.props.revenue.RevenueType == RevenueTypes.UnitSales) {
      return (
        <UnitSales
          totalDriver={this.props.totalDriver}
          saveRevenue={this.props.saveRevenue}
          tabIndex={this.props.tabIndex}
          revenue={this.props.revenue}
          onClose={this.props.onClose}
          onGeneral={this.props.onGeneralClick}
          open={this.props.open}
        />
      );
    } else if (this.props.revenue.RevenueType == RevenueTypes.BillableHours) {
      return (
        <BillableHours
          totalDriver={this.props.totalDriver}
          saveRevenue={this.props.saveRevenue}
          tabIndex={this.props.tabIndex}
          revenue={this.props.revenue}
          onClose={this.props.onClose}
          onGeneral={this.props.onGeneralClick}
          open={this.props.open}
        />
      );
    } else if (this.props.revenue.RevenueType == RevenueTypes.RevenueOnly) {
      return (
        <RevenueOnly
          totalDriver={this.props.totalDriver}
          saveRevenue={this.props.saveRevenue}
          tabIndex={this.props.tabIndex}
          revenue={this.props.revenue}
          onClose={this.props.onClose}
          onGeneral={this.props.onGeneralClick}
          open={this.props.open}
        />
      );
    } else if (this.props.revenue.RevenueType == RevenueTypes.Subscriptions) {
      return (
        <Subscriptions
          totalDriver={this.props.totalDriver}
          saveRevenue={this.props.saveRevenue}
          tabIndex={this.props.tabIndex}
          revenue={this.props.revenue}
          onClose={this.props.onClose}
          onGeneral={this.props.onGeneralClick}
          open={this.props.open}
        />
      );
    }

    return null;
  }
}

export default RevenueInfoDialog;
