import React from "react";
import PropTypes from "prop-types";
import { Input, InputAdornment, FormControl, TextField, IconButton } from "@mui/material";
import "./customInputPercentage.scss";
import { EyeIcon, EyeIconClose } from "../../icons/svgIcons";
import styled from "styled-components";
import { Theme } from "../../../theme/styles/theme";

let styles = {
  white: Theme.colors.greyscale.white,
  lightgray150: Theme.colors.primary.lightgrey[150],
  lightgray500: Theme.colors.primary.lightgrey[500],
  darkgray300: Theme.colors.primary.darkgrey[300],
  darkgray150: Theme.colors.primary.darkgrey[50],
  darkgray50: Theme.colors.primary.darkgrey[50],
  paragraph3: Theme.text.paragraph.p3,
  paragraph5: Theme.text.paragraph.p5,
  error: Theme.colors.accent.red[150],
};
const ErrorMessage = styled.span`
  display: block !important;
  ${styles["paragraph5"]};
  color: ${styles["error"]};
  margin-top: 0;
  margin-left: 8px;
  //height: 30px;
  font-family: Inter;
`;

function InputM(props) {
  const [type, setType] = React.useState("password");
  const [error, setError] = React.useState(props.errorMessage);
  React.useEffect(() => {
    setError(props.errorMessage);
  }, [props.errorMessage]);

  const onChange = (e) => {
    if (e.target.value.length > props.max) {
      setError(`Maximum ${props.max} characters`);
    } else if (e.target.value.length > 0 && e.target.value.toString().trim() === "") {
      setError(`Leading space not allowed`);
    } else if (e.target.value.includes("    ")) {
      setError(`consecutive spaces not allowed`);
    } else {
      if (error) {
        setError(null);
      }

      if (e.target.value.length === 0 && !props.allowEmpty) {
        setError(`Some name is required`);
      }
      props.onChange(e);
    }
  };

  return (
    <>
      <TextField
        {...props}
        type={type}
        onChange={onChange}
        placeholder={props.placeholder}
        className="cai_custom_input"
        size="small"
        autoComplete="off"
        onKeyDown={props.onKeyDown}
        error={props.error}
        max={props.max}
        onBlur={props.onBlur}
        disabled={props.disabled}
        marginRight={props.marginRight}
        width={props.width}
        name={props.name}
        value={props.value}
        id={props.id}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setType(type === "password" ? "text" : "password")}>
                {type === "password" ? <EyeIcon /> : <EyeIconClose />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <ErrorMessage>{error}</ErrorMessage>
    </>
  );
}

InputM.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.any,
  width: PropTypes.any,
  unit: PropTypes.oneOf(["unit", "percentage", "price"]),
  max: PropTypes.number,
  allowEmpty: PropTypes.bool,
};

InputM.defaultProps = {
  unit: "unit",
  max: 50,
  allowEmpty: false,
  activeStrengthMeter: true,
};

export default InputM;
