import CalculatedDriver from "../CalculatedDriver";
import { DriverCategories, UnitTypes, SpecialChar } from "../CalculatedDriver/constants";
import datastructure from "../../datastructure.json";
import FinanceGeneric from "../FinanceGeneric";
import { DialogTypes, ExpenseTypes, ExpenseValues, PersonnelFunctionTypes } from "../constants";
import Revenue from "../Revenue";
import { WorkingCapitalTypes } from "../WorkingCapital/constants";
import { PERMISSIONS } from "../../Permissions/Permissions";
import Personnel from "../Personnel";

class Expense extends FinanceGeneric {
  Name = "";
  ID_Revenue = null;
  ExpenseType = ExpenseTypes.SalesAndMarketing;
  ExpenseMetric = ExpenseValues.Value;
  Expense;
  StartDate = new Date();

  constructor(db_record = null) {
    super(db_record);
    this.clean();
  }

  clean = (cleanDrivers = false) => {
    if (this.db_record && this.Totals) {
      this.Name = this.db_record.Name;
      this.ExpenseType = this.db_record.ExpenseType;
      this.ExpenseMetric = this.db_record.ExpenseMetric;
      this.ID_Revenue = this.db_record.ID_Revenue;
      this.ID_CompanyScenario = this.db_record.ID_CompanyScenario;
      if (
        !this.ID_Revenue &&
        this.ExpenseMetric !== ExpenseValues.Value &&
        this.Expense.DriverCategory !== DriverCategories.Average
      ) {
        this.setExpense();
      }
      if (cleanDrivers) {
        this.cleanDrivers();
      }
    }
  };

  static DriversDesc = {
    Total: {
      driverName: "$Name",
      fieldName: "Totals",
      driverID: "total",
    },
    Expense: {
      driverName: `Expense${SpecialChar.DriverNameESCChar}$Name`,
      fieldName: "Expense",
      driverID: "expense",
    },
  };

  static TableName = datastructure.Finance_Expenses.TableName;

  changeDriversName = () => {
    Object.keys(this.constructor.DriversDesc).forEach((key) => {
      const driverDesc = this.constructor.DriversDesc[key];
      if (this[driverDesc.fieldName]) {
        this[driverDesc.fieldName].DriverName = driverDesc.driverName.replace("$Name", this.Name);
      } else {
        console.log("driver not found", this, driverDesc, this[driverDesc.fieldName]);
      }
    });
  };

  getYearDatesAll = () => {
    return global.Modeliks.DateHelper.years_all;
  };

  setDriverName = () => {
    this.Expense.DriverName = `${this.Name} ${this.ID_Revenue ? `as % of ${global.Modeliks.RevenuesStore.getItem(this.ID_Revenue).RevenueName}` : this.ExpenseMetric === ExpenseValues.Totals ? "as % of total revenue" : ""}`;
  };

  Save = (callBack, saveDrivers = true) => {
    if (!this.isNew && !this.IsCreated) {
      global.Modeliks.put(this.constructor.TableName, null, this, (res) => {
        if (saveDrivers) {
          this.SaveDrivers(callBack);
        } else {
          callBack();
        }
      });
    } else {
      global.Modeliks.put(this.constructor.TableName, null, this, (res) => {
        global.Modeliks.DriversStore.createDriversFrom(
          this.ID,
          this.constructor.TableName,
          this.Totals,
          this,
        );
        if (saveDrivers) {
          this.setDriverName();
          this.SaveDrivers(callBack);
        } else {
          callBack();
        }
      });
    }
  };

  setTotalsFormula = (selectedDriver = null) => {
    if (selectedDriver) {
      this.Totals.setFormula(`${this.Expense.ID_f}*${selectedDriver.ID_f}`);
      const totalYears = global.Modeliks.DateHelper.years_before;

      totalYears.forEach((year) => {
        const curYearExpense = this.Expense.getItemByDateSufix(year.sufix);
        const curYearSecondDriver = selectedDriver.getItemByDateSufix(year.sufix);
        const curYearTotal = this.Totals.getItemByDateSufix(year.sufix);
        if (curYearExpense.Unit === UnitTypes.Percentage) {
          curYearTotal.Formula = `(${curYearSecondDriver.ID_f}*${curYearExpense.ID_f}) * 100`;
        } else {
          curYearTotal.Formula = `(${curYearSecondDriver.ID_f}*${curYearExpense.ID_f})`;
        }
      });

      const years = this.getYearDatesAll();
      years.forEach((year) => {
        if (!year.Active) {
          this.Totals.getItemByDateSufix(year.sufix).Formula =
            `${year.months.map((m) => this.Totals.getItemByDateSufix(m.sufix)).join("+")}`;
        }
      });
    } else {
      this.Totals.setFormula(`${this.Expense.ID_f}`);
    }
  };

  setExpense = () => {
    let selectedDriver = null;
    const UnitType =
      !this.ID_Revenue && this.ExpenseMetric === ExpenseValues.Value
        ? UnitTypes.Price
        : UnitTypes.Percentage;
    const DriverCategory =
      !this.ID_Revenue && this.ExpenseMetric === ExpenseValues.Value
        ? DriverCategories.Sum
        : DriverCategories.Average;

    if (!this.ID_Revenue && this.ExpenseMetric === ExpenseValues.Value) {
      if (!this.Expense) {
        this.Expense = CalculatedDriver.createDriverFromTable(
          this,
          Expense.DriversDesc.Expense.driverID,
          UnitTypes.Price,
          DriverCategories.Total,
        );
      }
    } else {
      if (!this.Expense) {
        this.Expense = CalculatedDriver.createDriverFromTable(
          this,
          Expense.DriversDesc.Expense.driverID,
          UnitTypes.Percentage,
          DriverCategories.Average,
          this.Name ? `Expense${SpecialChar.DriverNameESCChar}${this.Name}` : null,
        );
      }
      if (!this.ID_Revenue) {
        selectedDriver = Revenue.getRevenueTotals();
      } else {
        selectedDriver = global.Modeliks.RevenuesStore.getItem(this.ID_Revenue).Totals;
      }
    }

    if (this.Expense.UnitType !== UnitType || this.Expense.DriverCategory !== DriverCategory) {
      this.Expense.changeDriverTypeAndCategory(UnitType, DriverCategory);
      this.setDriverName();
    }
    this.setTotalsFormula(selectedDriver);
  };

  Delete = (callBack) => {
    this.DeleteFunc(this.ID, () => {
      global.Modeliks.ExpensesStore.removeItemByID(this.ID);
      this.getAllDrivers().forEach((d) => global.Modeliks.DriversStore.removeItemByID(d.ID));
      const workingCapitalDrivers = global.Modeliks.WorkingCapitalStore.filter(
        (d) => d.Type !== WorkingCapitalTypes.AccountReceivable,
      );
      workingCapitalDrivers.forEach((driver, index) => {
        driver.cleanWorkingCapital(this.ID, Expense.TableName, () => {
          if (callBack && workingCapitalDrivers.length === index + 1) {
            callBack();
          }
        });
      });
    });
  };

  periodsData = {};

  static createNew = (expense = null) => {
    const newExpense = new Expense();
    newExpense.ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
    newExpense.ID = expense.ID;
    if (expense) {
      newExpense.Name = expense.Name;
      newExpense.ExpenseMetric = expense.ExpenseMetric;
      newExpense.ExpenseType = expense.ExpenseType;
      newExpense.ID_Revenue = expense.ID_Revenue;
    }
    newExpense.Totals = CalculatedDriver.createDriverFromTable(
      newExpense,
      Expense.DriversDesc.Total.driverID,
      UnitTypes.Price,
      DriverCategories.Sum,
    );
    return newExpense;
  };

  static createNewEmpty = () => {
    const expense = new Expense();
    expense.ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
    expense.Name = "";
    expense.ExpenseType = ExpenseTypes.SalesAndMarketing;
    expense.ExpenseMetric = ExpenseValues.Value;
    return expense;
  };

  static getEmptyExpense = (callBack) => {
    const expense = Expense.createNewEmpty();
    expense.SaveNew((record) => {
      return callBack(record);
    });
  };

  SaveNew = (callBack) => {
    let limit =
      global.Modeliks.ExpensesStore.length >=
      global.Modeliks.PERMISSIONS.Financials.restrictions.MaxStreamsCount.TotalExpenses;
    if (limit) {
      global.Modeliks.showDialog(
        `
            You have reached the maximum number of [${global.Modeliks.PERMISSIONS.Financials.restrictions.MaxStreamsCount.TotalExpenses}] streams in Expenses. 
            Please reorganize your streams in Expenses. \n Click outside of the box to continue with your work.`,
        DialogTypes.WarningSimpleText,
        () => {
          callBack(null);
        },
      );
    } else {
      global.Modeliks.post(this.constructor.TableName, this, (res) => {
        this.ID = res.id;
        this.Totals = CalculatedDriver.createDriverFromTable(
          this,
          Expense.DriversDesc.Total.driverID,
          UnitTypes.Price,
          DriverCategories.Sum,
        );
        this.Expense = CalculatedDriver.createDriverFromTable(
          this,
          Expense.DriversDesc.Expense.driverID,
          UnitTypes.Price,
          DriverCategories.Total,
        );

        callBack(this);
      });
    }
  };

  static getExpensesDirectCostTotals = () => {
    const driverID = "other_direct_exp";
    let driver = global.Modeliks.DriversStore.getItem(
      Expense.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Expense.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.Sum,
        "Other Direct Expenses",
        false,
        false,
        false,
      );
      driver.Save();
    }
    driver.isExpense = true;
    driver.IsFormulaEditable = false;

    if (global.Modeliks.ExpensesStore.length > 0) {
      const lastCheck = global.Modeliks.ExpensesStore.filter(
        (c) => c.ExpenseType === ExpenseTypes.DirectCost,
      );
      if (lastCheck && lastCheck.length > 0) {
        driver.setFormula_Sum(lastCheck.map((c) => c.Totals));
      }
    }

    return driver;
  };

  static getDirectCostDrivers = () => {
    const driverID = "direct_cost_driver";
    let driver = global.Modeliks.DriversStore.getItem(
      Expense.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Expense.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.Sum,
        "Direct Cost",
        true,
        true,
        false,
      );
      driver.isExpense = true;
    }

    if (global.Modeliks.ExpensesStore.length > 0) {
      const drivers = global.Modeliks.ExpensesStore.map((c) => c.Totals.ID_f).join("+");
      driver.Formula = drivers;
      driver.setFormula(drivers);
    }

    return driver;
  };

  static getExpensesDirectDrivers = () => {
    return global.Modeliks.ExpensesStore.filter((c) => c.ExpenseType === ExpenseTypes.DirectCost)
      .map((c) => c.Totals.ID_f)
      .join("+");
  };

  static getSalesAndMarketingTotals = () => {
    const driverID = "sales_and_marketing_totals";
    let driver = global.Modeliks.DriversStore.getItem(
      Expense.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Expense.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.Sum,
        "Sales & Marketing Operating Expenses",
        true,
        true,
        false,
      );
    }

    if (global.Modeliks.ExpensesStore.length > 0) {
      const lastCheck = global.Modeliks.ExpensesStore.filter(
        (c) => c.ExpenseType === ExpenseTypes.SalesAndMarketing,
      );
      if (lastCheck && lastCheck.length > 0) {
        driver.setFormula_Sum(lastCheck.map((d) => d.Totals));
      }
    }

    return driver;
  };

  static getGeneralAndAdministrativeTotals = () => {
    const driverID = "general_and_administrative_totals";
    let driver = global.Modeliks.DriversStore.getItem(
      Expense.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Expense.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.Sum,
        "General & Administrative  Operating Expenses",
        true,
        true,
        false,
      );
    }

    if (global.Modeliks.ExpensesStore.length > 0) {
      const lastCheck = global.Modeliks.ExpensesStore.filter(
        (c) => c.ExpenseType === ExpenseTypes.GeneralAndAdministrative,
      );
      if (lastCheck && lastCheck.length > 0) {
        driver.setFormula_Sum(lastCheck.map((d) => d.Totals));
      }
    }

    return driver;
  };

  static getResearchAndDevelopmentTotals = () => {
    const driverID = "research_and_development_totals";
    let driver = global.Modeliks.DriversStore.getItem(
      Expense.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Expense.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.Sum,
        "Research & Development  Operating Expenses",
        true,
        true,
        false,
      );
    }

    if (global.Modeliks.ExpensesStore.length > 0) {
      const lastCheck = global.Modeliks.ExpensesStore.filter(
        (c) => c.ExpenseType === ExpenseTypes.ResearchAndDevelopment,
      );
      if (lastCheck && lastCheck.length > 0) {
        driver.setFormula_Sum(lastCheck.map((d) => d.Totals));
      }
    }

    return driver;
  };

  static getPersonnelExpenses = () => {
    const driverID = "personnel_expenses";
    let driver = global.Modeliks.DriversStore.getItem(
      Expense.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Expense.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.Sum,
        "Personnel Expenses",
        false,
        true,
        false,
      );
      driver.Save();
    }
    driver.isExpense = true;
    driver.IsExisting = true;
    driver.IsFormulaEditable = false;
    if (driver.Formula && driver.Formula.includes(Expense.TableName)) {
      driver.removeFormula();
    }

    if (global.Modeliks.PersonnelStore.length > 0) {
      const lastCheck = global.Modeliks.PersonnelStore.filter(
        (c) => c.FunctionType !== PersonnelFunctionTypes.DirectLabor,
      );
      //
      // if(lastCheck && lastCheck.length > 0){
      //     driver.setFormula(lastCheck.filter(d => d).map(d => d.Totals.ID_f).join('+'),false,false,true);
      // }

      const totalsDrivers = lastCheck.map((c) => c.Totals);
      if (lastCheck && lastCheck.length > 0) {
        driver.Values.forEach((value) => {
          value.Formula = `${totalsDrivers.map((c) => c.getItemByDateSufix(value.Date.sufix)).join("+")} Actual(${totalsDrivers.map((c) => global.Modeliks.DriversStore.getItem(`${Personnel.TableName}-${c.Ref_ID}-burden-changeable-driver`).getItemByDateSufix(value.Date.sufix).ID_f_actual).join("+")})`;
        });
        driver.Formula = totalsDrivers.map((c) => c.ID_f).join("+");
      }
    }
    return driver;
  };

  static getOperatingExpenses = () => {
    const driverID = "operating_expenses";
    let driver = global.Modeliks.DriversStore.getItem(
      Expense.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Expense.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.Sum,
        "Operating Expenses",
        true,
        true,
        false,
      );
      driver.isExpense = true;
    }

    const allDrivers = [Expense.getPersonnelExpenses()];
    const lastCheckDrivers = global.Modeliks.ExpensesStore.filter(
      (c) => c.ExpenseType !== ExpenseTypes.DirectCost,
    );

    if (lastCheckDrivers && lastCheckDrivers.length > 0) {
      allDrivers.push(...lastCheckDrivers.filter((d) => d).flatMap((d) => d.Totals));
    }
    driver.setFormula_Sum(allDrivers);

    return driver;
  };

  static getExpenseTotals = () => {
    const driverID = "exp_t_totals";
    let driver = global.Modeliks.DriversStore.getItem(
      Expense.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Expense.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.Sum,
        "Expenses Totals",
        true,
        false,
        false,
      );
      driver.isExpense = true;
    }
    if (global.Modeliks.ExpensesStore.length > 0) {
      const drivers = global.Modeliks.ExpensesStore.map((c) => c.Totals);
      driver.setFormula_Sum(drivers);
    }

    return driver;
  };

  static getExpenseOnlyTotals = () => {
    const driverID = "exp_t_totals_e_o";
    let driver = global.Modeliks.DriversStore.getItem(
      Expense.TableName + "-" + driverID + "-expenses_only_totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Expense.TableName,
        driverID,
        "expenses_only_totals",
        UnitTypes.Price,
        DriverCategories.Sum,
        "Expenses",
        true,
        false,
        false,
      );
    }
    if (global.Modeliks.ExpensesStore.length > 0) {
      const lastCheck = global.Modeliks.ExpensesStore.filter(
        (c) => c.ExpenseType !== ExpenseTypes.DirectCost,
      );
      if (lastCheck && lastCheck.length > 0) {
        driver.setFormula_Sum(lastCheck.map((d) => d.Totals));
      }
    }

    return driver;
  };

  static getTotalOperatingExpenses = () => {
    const driverID = "total_operating_expenses";
    let driver = global.Modeliks.DriversStore.getItem(
      Expense.TableName + "-" + driverID + "-totals",
    );
    if (driver == null) {
      driver = CalculatedDriver.createDriver(
        Expense.TableName,
        driverID,
        "totals",
        UnitTypes.Price,
        DriverCategories.Sum,
        "Total Operating Expenses",
        true,
        false,
        false,
      );
      driver.isExpense = true;
    }

    const totalTypes = [];

    Object.entries(ExpenseTypes).forEach(([key, value]) => {
      if (value !== ExpenseTypes.DirectCost) {
        const driverID = `${value}-expenses`;
        let typeDriver = global.Modeliks.DriversStore.getItem(
          Expense.TableName + "-" + driverID + "-totals",
        );
        if (typeDriver === null) {
          typeDriver = CalculatedDriver.createDriver(
            Expense.TableName,
            driverID,
            "totals",
            UnitTypes.Price,
            DriverCategories.Sum,
            `${key.replace(/[A-Z]/g, " $&").replace("And", "&").trim()} Total Cost`,
            true,
            true,
            false,
          );
        }
        const totalDrivers = [
          ...global.Modeliks.PersonnelStore.filter((d) => d.FunctionType === value),
          ...global.Modeliks.ExpensesStore.filter((d) => d.ExpenseType === value),
        ];
        if (totalDrivers.length > 0) {
          const lastCheck = totalDrivers.filter((d) => d);
          if (lastCheck && lastCheck.length > 0) {
            typeDriver.setFormula_Sum(lastCheck.map((d) => d.Totals));
          }
        }

        totalTypes.push(typeDriver);
      }
    });

    if (totalTypes.length > 0) {
      driver.setFormula(totalTypes.map((c) => c.ID_f).join("+"));
    }

    return driver;
  };
}

export default Expense;
