import { DriverCategories, UnitTypes } from "./CalculatedDriver/constants";
import CalculatedDriver from "./CalculatedDriver";
import MxIDHelper from "../MxIDHelper";
import datastructure from "../datastructure";
import Revenue from "./Revenue";

class FinanceGeneric {
  ID = null;
  ID_CompanyScenario = null;
  Order = 100;
  get isNew() {
    return this.db_record == null;
  }

  Totals = null;

  db_record = null;

  constructor(db_record = null) {
    this.db_record = db_record;
    if (this.db_record) {
      this.ID = this.db_record.ID;
      this.ID_CompanyScenario = this.db_record.ID_CompanyScenario;
      this.Order = this.db_record.Order;
      this.setDriversFromDataStorage();
    } else {
      this.ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
    }
  }

  cleanDrivers = () => {
    Object.keys(this.constructor.DriversDesc).forEach((key) => {
      const driverDesc = this.constructor.DriversDesc[key];
      const Driver = this[driverDesc.fieldName];
      if (Driver) {
        Driver.cleanDriver();
      } else {
        console.log("driver not found", this, driverDesc, this[driverDesc.fieldName]);
      }
    });
  };

  setDriversFromDataStorage = () => {
    Object.values(this.constructor.DriversDesc).forEach((field) => {
      const driverID = CalculatedDriver.getTableRefID(
        this.constructor.TableName,
        this.ID,
        field.driverID,
      );
      this[field.fieldName] = global.Modeliks.DriversStore.getItem(driverID);

      if (this[field.fieldName]) {
        if (field.hasOwnProperty("isExpense")) {
          this[field.fieldName].isExpense = field.isExpense;
        }

        if (field.hasOwnProperty("LastPeriodOnly")) {
          this[field.fieldName].LastPeriodOnly = field.LastPeriodOnly;
        }
      }
    });
  };

  createDrivers = (recreate = false) => {
    Object.values(this.constructor.DriversDesc).forEach((driver) => {
      if (this.hasOwnProperty(driver.fieldName) == false || recreate) {
        this[driver.fieldName] = CalculatedDriver.createDriverFromTable(
          this,
          driver.driverID,
          driver.unit,
          driver.category,
        );
      }
    });
  };

  static Fields = {
    Total: "total",
  };

  static DriversDesc = {
    Total: {
      driverName: "Finance_Generic",
      fieldName: "Totals",
      driverID: "total",
    },
  };

  cloneDrivers = (ID = MxIDHelper.newID()) => {
    return this.getAllDrivers().map((driver) => driver.cloneDriver(ID));
  };

  SaveDrivers = (callback, newID = null) => {
    global.Modeliks.DriversStore.saveAll(callback);
  };

  Save = (callback, saveDrivers = true) => {
    if (this.isNew) {
      global.Modeliks.post(this.constructor.TableName, this, (res) => {
        if (res && res.id) {
          global.Modeliks.DriversStore.updateIDs(res.id, this.constructor.TableName, this.ID);
          global.Modeliks.DriversStore.createDriversFrom(
            res.id,
            this.constructor.TableName,
            this.Totals,
            this,
          );
        }

        if (saveDrivers) {
          this.SaveDrivers(() => {
            callback(res.id);
          });
        } else {
          callback(res.id);
        }
      });
    } else {
      global.Modeliks.put(this.constructor.TableName, null, this, (res) => {
        if (saveDrivers) {
          this.SaveDrivers(callback);
        } else {
          callback();
        }
      });
    }
  };

  CheckBeforeDelete = () => {
    let allowDelete = true;
    const AllDrivers = this.getAllDrivers();
    AllDrivers.forEach((driver) => {
      if (
        global.Modeliks.DriversStore.some(
          (d) =>
            d.Formula &&
            !d.IsTemporary &&
            !d.IsNew &&
            d.Ref_ID !== driver.Ref_ID &&
            d.Formula.includes(driver.ID),
        )
      ) {
        allowDelete = false;
      }
    });
    return allowDelete;
  };

  DeleteFunc = (ID, callBack) => {
    global.Modeliks.del(this.constructor.TableName, { ID: ID }, (res) => {
      global.Modeliks.del(
        CalculatedDriver.TableName,
        { Ref_ID: ID, Ref_Table: this.constructor.TableName },
        (res) => {
          callBack();
        },
      );
    });
  };

  Delete = (callBack) => {
    if (!this.isNew && this.ID && this.constructor.TableName) {
      this.DeleteFunc(this.ID, () => {
        if (callBack) {
          callBack();
        }
      });
    }
  };

  getAllDrivers = () => {
    const allDrivers = [];
    Object.values(this.constructor.DriversDesc).forEach((field) => {
      if (this[field.fieldName]) {
        allDrivers.push(this[field.fieldName]);
      }
    });
    return allDrivers;
  };

  static TableName = "Finance_Generic";

  static getTotalsGrowth = (totalsDriver) => {
    const driverID = totalsDriver.Ref_ID;
    let driver = global.Modeliks.DriversStore.getItem(
      totalsDriver.Ref_Table + "-" + driverID + "-growth",
    );
    // let driver = global.Modeliks.DriversStore.getItem(totalsDriver.ID+'-growth');
    if (driver == null) {
      // driver = CalculatedDriver.createDriver(totalsDriver.Ref_Table, driverID, "growth", UnitTypes.Percentage, DriverCategories.Sum, totalsDriver.DriverName + " - Growth", true, totalsDriver.DriverName !== 'Chart' && totalsDriver.Ref_Table === Revenue.TableName || totalsDriver.Ref_Table === global.Modeliks.Tables.Finance_CostSales.TableName);

      driver = CalculatedDriver.createDriver(
        totalsDriver.Ref_Table,
        driverID,
        "growth",
        UnitTypes.Percentage,
        DriverCategories.Sum,
        totalsDriver.DriverName + " Growth",
        true,
        totalsDriver.DriverName !== "Chart" && totalsDriver.Ref_Table === Revenue.TableName,
      );
    }

    const dates = [
      ...global.Modeliks.DateHelper.months_before_actual,
      ...global.Modeliks.DateHelper.months,
      ...global.Modeliks.DateHelper.years_all,
    ];

    dates.forEach((date) => {
      const curDateDriver = driver.getItemByDateSufix(date.sufix);
      const prevDate = dates.find(
        (d) => d.Order === date.Order - 1 && d.PeriodType === date.PeriodType,
      );

      if (prevDate) {
        const prevDateValue = totalsDriver.getItemByDateSufix(prevDate.sufix);
        const curDateValue = totalsDriver.getItemByDateSufix(date.sufix);
        curDateDriver.Formula = `MxMath.Round((${curDateValue} - ${prevDateValue}) / ${prevDateValue} * 100)`;
      } else {
        curDateDriver.Unit = UnitTypes.Hide;
        curDateDriver.Formula = null;
        curDateDriver.Value = null;
      }
    });

    return driver;
  };
}

export default FinanceGeneric;
