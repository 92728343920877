import React from "react";
import Expenses from "./index";

class InfoExpense extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      tab: "expense",
      units: null,
      updated: false,
      multiSubscription: false,
    };
  }

  componentDidMount() {}

  handleChange = (event, newValue) => {
    if (event !== "general") {
      this.setState({ tab: event });
    }
  };

  render() {
    if (!this.props.open && !this.props.expense) {
      return null;
    } else {
      return (
        <Expenses
          saveRevenue={this.props.saveRevenue}
          tabIndex={this.props.tabIndex}
          expense={this.props.expense}
          onClose={this.props.onClose}
          totalDriver={this.props.totalDriver}
          onGeneral={this.props.onGeneralClick}
          open={this.props.open}
        />
      );
    }
  }
}

export default InfoExpense;
