import React, { Component } from "react";
import "./noCompanyAccess.scss";
import ButtonMui from "../buttons/buttonMui/buttonMui";
import SwitchAccountDialog from "../dialogs/SwitchAccount/SwitchAccountDialog";

class NoCompanyAccess extends Component {
  constructor(props) {
    super(props);

    this.state = {
      switchDialog: false,
    };
  }

  render() {
    return (
      <div className={"no_company_access"}>
        <div className={"flex column big_gap"}>
          {/*logo*/}
          <Logo />
          <div className={"flex column small_gap"}>
            <div className={"flex column"}>
              <div className={"heading_text"}>Restricted Access!</div>
              <svg
                width="452"
                height="12"
                viewBox="0 0 452 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 10C43.9285 5.8175 194.028 -1.29274 451 3.72626"
                  stroke="#01AFFD"
                  strokeWidth="3"
                />
              </svg>
            </div>
            <div className={"main_text"}>
              <b>No access to this company resources.</b> <br />
              <div className={"grey_text"}>
                Connect with the owner or create/sign in to your account for further interaction.
              </div>
            </div>
          </div>
          <div className={"flex btn_container"}>
            <ButtonMui
              label={"Change Account"}
              variant={"contained"}
              onClick={() => {
                this.setState({ switchDialog: true });
              }}
              width={188}
            />
            <ButtonMui label={"Sign Out"} onClick={() => global.Modeliks.LogOut()} width={188} />
          </div>
        </div>

        {this.state.switchDialog && (
          <SwitchAccountDialog
            handleClose={() => this.setState({ switchDialog: false })}
            open
            enableCreateAccount
          />
        )}
      </div>
    );
  }
}

const Logo = () => (
  <svg
    width="176"
    height="121"
    viewBox="0 0 468 324"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M318.2 154.1C302.6 154.1 289.9 166.5 289.9 181.8C289.9 197.1 302.6 209.5 318.2 209.5C333.8 209.5 346.5 197.1 346.5 181.8C346.5 166.5 333.8 154.1 318.2 154.1Z"
      fill="url(#paint0_linear_1_18)"
    />
    <path
      d="M329.1 0.5C328.7 0.5 328.4 0.5 328 0.5C327.6 0.5 327.3 0.5 326.9 0.5H244.2C232.8 0.5 223.5 9.6 223.5 20.8C223.5 32 232.8 41.1 244.2 41.1H273.5L243.1 70.8L220.4 48.5C203.2 31.6 177.5 26.6 155 35.8C132.5 44.9 118 66.2 118 90.1V179.1C118 192.5 129.1 203.3 142.7 203.3C156.4 203.3 167.4 192.5 167.4 179.1V90.1C167.4 84.1 171.5 81.6 173.9 80.6C176.3 79.6 181 78.6 185.4 82.8L243.1 139.3L308.4 75.3V101.8C308.4 113 317.7 122.1 329.1 122.1C340.5 122.1 349.8 113 349.8 101.8V24.8V20.8C349.8 9.6 340.5 0.5 329.1 0.5Z"
      fill="url(#paint1_linear_1_18)"
    />
    <path
      d="M88.9 273.8C92.9 277.9 94.9 283.6 94.9 290.8V321.6C94.9 322.1 94.5 322.4 94.1 322.4H76.4C75.9 322.4 75.6 322 75.6 321.6V293.3C75.6 290.4 74.8 288.1 73.1 286.5C71.4 284.9 69.2 284.1 66.4 284.1C63.6 284.1 61.3 284.9 59.7 286.5C58 288.1 57.2 290.4 57.2 293.3V321.6C57.2 322.1 56.8 322.4 56.4 322.4H38.6C38.1 322.4 37.8 322 37.8 321.6V293.3C37.8 290.4 37 288.1 35.4 286.5C33.8 284.9 31.6 284.1 28.7 284.1C25.9 284.1 23.5 284.9 21.9 286.5C20.2 288.1 19.4 290.4 19.4 293.3V321.6C19.4 322.1 19 322.4 18.6 322.4H0.8C0.3 322.4 0 322 0 321.6V268.9C0 268.4 0.4 268.1 0.8 268.1H18.5C19 268.1 19.3 268.5 19.3 268.9V273C19.3 273.7 20.2 274.1 20.7 273.6C22.2 272.1 23.9 270.8 25.9 269.8C28.6 268.4 31.8 267.7 35.3 267.7C39.4 267.7 43 268.6 46.2 270.3C49 271.8 51.3 273.9 53.1 276.6C53.4 277.1 54.2 277.1 54.5 276.6C56.4 274.1 58.7 272.1 61.6 270.4C64.8 268.6 68.4 267.6 72.3 267.6C79.4 267.6 84.9 269.7 88.9 273.8Z"
      fill="black"
    />
    <path
      d="M116.4 319.7C112 317.4 108.6 314.2 106.1 310C103.6 305.8 102.3 300.9 102.3 295.2C102.3 289.5 103.6 284.7 106.1 280.5C108.6 276.3 112.1 273.1 116.5 270.8C120.9 268.5 125.8 267.4 131.4 267.4C136.9 267.4 141.9 268.5 146.3 270.8C150.7 273.1 154.1 276.3 156.7 280.5C159.2 284.7 160.5 289.6 160.5 295.2C160.5 300.8 159.2 305.7 156.7 310C154.2 314.2 150.7 317.5 146.3 319.7C141.9 322 136.9 323.1 131.4 323.1C125.7 323.1 120.8 322 116.4 319.7ZM138.1 303.7C139.9 301.7 140.8 298.9 140.8 295.2C140.8 291.5 139.9 288.7 138.1 286.8C136.3 284.9 134.1 283.9 131.4 283.9C128.8 283.9 126.6 284.9 124.8 286.8C123 288.7 122.1 291.5 122.1 295.2C122.1 298.9 123 301.8 124.7 303.7C126.4 305.6 128.6 306.6 131.3 306.6C134 306.7 136.3 305.7 138.1 303.7Z"
      fill="black"
    />
    <path
      d="M168.3 280.5C170.4 276.3 173.2 273.1 176.8 270.8C180.4 268.5 184.4 267.4 188.9 267.4C192.5 267.4 195.6 268.1 198.4 269.6C200.4 270.7 202.2 272 203.5 273.6C204 274.2 204.9 273.8 204.9 273.1V251.6C204.9 251.2 205.3 250.8 205.7 250.8H223.5C223.9 250.8 224.3 251.2 224.3 251.6V321.7C224.3 322.1 223.9 322.5 223.5 322.5H205.7C205.2 322.5 204.9 322.1 204.9 321.7V317.5C204.9 316.8 203.9 316.4 203.5 317C202.2 318.6 200.5 319.9 198.5 321C195.7 322.5 192.5 323.2 188.7 323.2C184.3 323.2 180.3 322.1 176.7 319.8C173.1 317.5 170.3 314.3 168.2 310.1C166.1 305.9 165.1 300.9 165.1 295.3C165.1 289.7 166.2 284.7 168.3 280.5ZM202.2 287C200.3 285 197.9 284 195 284C192.2 284 189.7 285 187.8 287C185.9 289 184.9 291.7 184.9 295.3C184.9 298.9 185.9 301.5 187.8 303.6C189.7 305.6 192.1 306.7 195 306.7C197.9 306.7 200.2 305.7 202.2 303.7C204.1 301.7 205.1 298.9 205.1 295.4C205.1 291.9 204.2 289 202.2 287Z"
      fill="black"
    />
    <path
      d="M286.5 299.1H253.7C252.5 299.1 251.6 300.2 251.8 301.3C252.2 303.2 252.9 304.6 254 305.7C255.5 307.1 257.4 307.9 259.7 307.9C262.6 307.9 264.7 306.8 266.1 304.6C266.5 304 267.1 303.7 267.8 303.7H284.8C286.1 303.7 287 305 286.6 306.2C285.7 309 284.2 311.5 282.3 313.8C279.8 316.7 276.7 319 273 320.7C269.2 322.4 265.1 323.2 260.5 323.2C255 323.2 250.2 322.1 245.9 319.8C241.7 317.5 238.3 314.3 235.9 310.1C233.5 305.9 232.3 301 232.3 295.3C232.3 289.6 233.5 284.7 235.9 280.5C238.3 276.3 241.6 273.1 245.8 270.9C250.1 268.6 254.9 267.5 260.5 267.5C266 267.5 270.8 268.6 275 270.8C279.2 273 282.5 276.1 284.9 280.2C287.3 284.3 288.5 289.1 288.5 294.7C288.5 295.6 288.5 296.5 288.4 297.4C288.4 298.3 287.6 299.1 286.5 299.1ZM266.6 289.9C267.9 289.9 268.8 288.7 268.4 287.5C268 286.3 267.3 285.3 266.4 284.4C264.8 283.1 262.8 282.4 260.5 282.4C258.1 282.4 256.2 283 254.7 284.3C253.8 285.1 253.1 286 252.5 287.2C251.9 288.4 252.9 289.8 254.3 289.8L266.6 289.9Z"
      fill="black"
    />
    <path
      d="M315.9 259V314.2C315.9 318.8 312.1 322.5 307.5 322.5H305C300.3 322.5 296.6 318.8 296.6 314.2V259C296.6 254.4 300.4 250.7 305 250.7H307.5C312.1 250.8 315.9 254.5 315.9 259Z"
      fill="black"
    />
    <path
      d="M328.5 260.3C326.4 258.5 325.4 256.2 325.4 253.4C325.4 250.6 326.4 248.2 328.5 246.3C330.6 244.4 333.3 243.5 336.8 243.5C340.2 243.5 342.9 244.4 345 246.3C347.1 248.2 348.1 250.5 348.1 253.4C348.1 256.2 347.1 258.5 345 260.3C342.9 262.1 340.2 263.1 336.8 263.1C333.4 263.1 330.5 262.1 328.5 260.3ZM346.3 276.4V314.2C346.3 318.8 342.5 322.5 337.9 322.5H335.4C330.7 322.5 327 318.8 327 314.2V276.4C327 271.8 330.8 268.1 335.4 268.1H337.9C342.5 268.1 346.3 271.8 346.3 276.4Z"
      fill="black"
    />
    <path
      d="M390.9 320.7L384.2 311C382 307.7 376.8 309.3 376.8 313.2V318.5C376.8 320.7 375 322.5 372.7 322.5H361.4C359.2 322.5 357.3 320.7 357.3 318.5V254.8C357.3 252.6 359.1 250.8 361.4 250.8H372.7C374.9 250.8 376.8 252.6 376.8 254.8V276.9C376.8 280.8 381.9 282.4 384.2 279.2L390.9 269.8C391.7 268.7 392.9 268.1 394.2 268.1H407C410.4 268.1 412.3 271.9 410.2 274.5L395.5 292.9C394.3 294.4 394.3 296.4 395.5 297.9L410.4 316C412.5 318.6 410.7 322.5 407.2 322.5H394.2C392.9 322.4 391.6 321.8 390.9 320.7Z"
      fill="black"
    />
    <path
      d="M431.6 320.6C427.7 319 424.6 316.7 422.3 313.8C421.6 312.9 421 312 420.5 311.1C418.8 307.9 421.2 304.1 424.8 304.1H433.7C435.5 304.1 437.2 305 438.1 306.5C438.5 307.2 439.1 307.7 439.7 308.2C441.1 309.2 442.8 309.6 444.8 309.6C446.4 309.6 447.6 309.3 448.6 308.6C449.5 308 450 307.1 450 306.1C450 304.7 449.2 303.7 447.7 303.1C446.2 302.5 443.7 301.7 440.2 301C436.2 300.2 432.9 299.4 430.3 298.4C427.7 297.4 425.4 295.9 423.4 293.7C421.5 291.5 420.5 288.5 420.5 284.8C420.5 281.6 421.4 278.7 423.2 276C425 273.4 427.6 271.3 431.1 269.8C434.6 268.2 438.7 267.5 443.6 267.5C450.9 267.5 456.6 269.2 460.7 272.7C463.1 274.7 464.9 277.1 466.1 279.8C467.5 282.9 465.1 286.5 461.6 286.5H453.7C452 286.5 450.4 285.6 449.5 284.2C449.1 283.5 448.6 283 448 282.5C446.8 281.6 445.1 281.1 443.1 281.1C441.5 281.1 440.3 281.4 439.5 282C438.7 282.6 438.3 283.4 438.3 284.4C438.3 285.7 439.1 286.7 440.6 287.4C442.1 288.1 444.6 288.8 447.9 289.5C451.9 290.3 455.3 291.3 457.9 292.3C460.6 293.3 462.9 295 464.9 297.3C466.9 299.6 467.9 302.7 467.9 306.6C467.9 309.8 467 312.6 465.1 315.1C463.2 317.6 460.6 319.6 457.1 321.1C453.6 322.5 449.6 323.3 444.9 323.3C440 323.1 435.5 322.3 431.6 320.6Z"
      fill="black"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1_18"
        x1="346.482"
        y1="181.781"
        x2="289.894"
        y2="181.781"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00B2FF" />
        <stop offset="0.22" stopColor="#00AEFC" />
        <stop offset="0.39" stopColor="#01A5F5" />
        <stop offset="0.54" stopColor="#0494E9" />
        <stop offset="0.68" stopColor="#087DD8" />
        <stop offset="0.82" stopColor="#0C60C2" />
        <stop offset="0.95" stopColor="#123CA8" />
        <stop offset="1" stopColor="#152A9B" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1_18"
        x1="349.774"
        y1="101.931"
        x2="118"
        y2="101.931"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00B2FF" />
        <stop offset="0.22" stopColor="#00AEFC" />
        <stop offset="0.39" stopColor="#01A5F5" />
        <stop offset="0.54" stopColor="#0494E9" />
        <stop offset="0.68" stopColor="#087DD8" />
        <stop offset="0.82" stopColor="#0C60C2" />
        <stop offset="0.95" stopColor="#123CA8" />
        <stop offset="1" stopColor="#152A9B" />
      </linearGradient>
    </defs>
  </svg>
);

export default NoCompanyAccess;
