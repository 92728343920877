import dataStructure from "../data/datastructure.json";

class ObjectBuilder {
  #dataStructure = {};
  static Tables = dataStructure;
  constructor() {
    Object.keys(ObjectBuilder.Tables).forEach((key) =>
      Object.assign(this.#dataStructure, { [key.toLowerCase()]: ObjectBuilder.Tables[key] }),
    );
    console.log("dataStructure", this.#dataStructure);
  }

  blankObject(TableName = "") {
    return this.dataStructure[TableName.toLowerCase()];
  }

  get dataStructure() {
    return this.#dataStructure;
  }
}

export default ObjectBuilder;
