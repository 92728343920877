import React, { Fragment, useState } from "react";
import "../HeaderTextEditor.scss";
import "../HeaderDefault.scss";
import {
  BorderDash,
  BorderSolid,
  BorderDotted,
  BorderDashed,
  BorderDottedDashed,
  Redo,
} from "../../components/IconButtons/SubMenuIcons";
import { Menu, MenuItem, SubMenu, MenuButton } from "@szhsin/react-menu";
import Tooltip from "@mui/material/Tooltip";
import ButtonMui from "../../../../components/buttons/buttonMui/buttonMui";

function BorderDashEditComponent(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openBorderDash = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onChange = (style) => {
    props.onChange(style);
    setAnchorEl(null);
  };
  const handleBorderStyleChange = (style) => {
    if (
      props.objType === "textObject" ||
      props.objType === "imageObj" ||
      props.objType === "tableObject"
    ) {
      props.onChange({ borderStyle: style });
    } else {
      props.onChange({ strokeStyle: style });
    }
  };

  const BorderDashEdit = () => {
    return (
      <Menu
        onMouseMove={(e) => {
          e.stopPropagation();
        }}
        className="border_dash"
        menuButton={({ open }) => (
          <ButtonMui
            tooltipText={"Border Dash"}
            className={props.className ? props.className : ""}
            disabled={props.disableEdit}
            variant={"text"}
            isIconButton
            roundnessType={"squared"}
            color={"black"}
            label={<BorderDash active={open} />}
          />
        )}
      >
        <MenuItem
          onClick={(event) =>
            handleBorderStyleChange(
              props.objType === "textObject" ||
                props.objType === "imageObj" ||
                props.objType === "tableObject"
                ? "solid"
                : "0 0",
            )
          }
        >
          <BorderSolid />
        </MenuItem>
        <MenuItem
          onClick={(event) =>
            handleBorderStyleChange(
              props.objType === "textObject" ||
                props.objType === "imageObj" ||
                props.objType === "tableObject"
                ? "dotted"
                : "1 1",
            )
          }
        >
          <BorderDotted />
        </MenuItem>
        <MenuItem
          onClick={(event) =>
            handleBorderStyleChange(
              props.objType === "textObject" ||
                props.objType === "imageObj" ||
                props.objType === "tableObject"
                ? "dashed"
                : "5 5",
            )
          }
        >
          <BorderDashed />
        </MenuItem>
        <MenuItem
          onClick={(event) =>
            handleBorderStyleChange(
              props.objType === "textObject" ||
                props.objType === "imageObj" ||
                props.objType === "tableObject"
                ? "double"
                : "10 10",
            )
          }
        >
          <BorderDottedDashed />
        </MenuItem>
      </Menu>
    );
  };

  return <>{BorderDashEdit()}</>;
}

export default BorderDashEditComponent;
