import styled from "styled-components";
import { Theme } from "../../../../theme/styles/theme";

const StyledBottomWrapper = styled.div`
  ${Theme.text.paragraph.p3};
  color: ${Theme.colors.primary.lightgrey[500]};
  position: inherit;
  width: 100%;
  text-align: center;
  font-family: Inter;
  margin-top: 20px;
  padding-bottom: 20px;
`;

export const BottomWrapper = (props) => {
  return <StyledBottomWrapper>{props.children}</StyledBottomWrapper>;
};
