import React from "react";
import styled from "styled-components";
import { Theme } from "../../theme/styles/theme";
// import {Theme} from "../../theme/styles/theme";

const StyledMenu = styled.div`
  display: ${(props) => (props.hidden ? "none" : "flex")};
  height: 68px;
  min-height: 68px;
  width: 100%;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  background: white;
  z-index: 999;
  box-sizing: border-box;
  border-bottom: 1px solid ${Theme.colors.primary.lightgrey[150]};
`;

export default class Menu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hidden: false,
    };
  }

  componentWillUnmount() {}

  render() {
    return <StyledMenu hidden={this.state.hidden}>{this.props.children}</StyledMenu>;
  }
}
