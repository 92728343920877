import React from "react";
import FinanceDetailsDialog from "../../../../components/FinanceDetails/FinanceDetailsDialog";
import PropTypes from "prop-types";
import SingleSubscriptionContent from "./SingleSubscriptionContent";

class SubscriptionSingle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      revenue: this.props.revenue,
      parent: this.props.parent,
      itemChanged: false,
    };

    this.monthHeaders = global.Modeliks.DateHelper.months;
    this.yearHeaders = global.Modeliks.DateHelper.years_all;
    this.gridHeaders = [...this.monthHeaders, ...this.yearHeaders];

    this.tabs = [
      {
        title: "Signups",
        subtitle: "Total Signups",
        field: "Quantity",
        description:
          "Total signups is the number of total new customers that subscribe to your service in a specific period of time.",
        childrenFunc: this.renderCustomersAtStart,
        growthEnabled: true,
        checkedGrowth: false,
        changeEnabled: true,
        singleCustomersAtStart: true,
        dataGridRow: {
          data: this.state.revenue.Quantity,
          drivers: [],
        },
        chart: {
          monthlyChartData: {
            data: this.state.revenue.Quantity.Values.filter(
              (c) => this.monthHeaders.indexOf(c.Date) > -1,
            ),
            labels: global.Modeliks.DateHelper.headers_Months(),
          },
          yearlyChartData: {
            data: this.state.revenue.Quantity.Values.filter(
              (c) => this.yearHeaders.indexOf(c.Date) > -1,
            ),
            labels: global.Modeliks.DateHelper.headers_Years(true),
          },
        },
        customers: {
          CustomersAtStart: this.state.revenue.CustomersAtStartInit.Value,
        },
      },
      {
        title: "Churn Rate",
        chartTitle: "Churn Rate",
        subtitle: "Churn Rate % at Renewal",
        field: "ChurnRate",
        description: `Churn rate % at renewal is the percentage of your customers or subscribers who canceled subsription or didn't renew the subscription.`,
        growthEnabled: false,
        checkedGrowth: false,
        changeEnabled: true,
        singleCustomersAtStart: false,
        dataGridRow: {
          data: this.props.revenue.ChurnRate,
          drivers: [],
        },
        chart: {
          monthlyChartData: {
            data: this.state.revenue.ChurnRate.Values.filter(
              (c) => this.monthHeaders.indexOf(c.Date) > -1,
            ),
            labels: global.Modeliks.DateHelper.headers_Months(),
          },
          yearlyChartData: {
            data: this.state.revenue.ChurnRate.Values.filter(
              (c) => this.yearHeaders.indexOf(c.Date) > -1,
            ),
            labels: global.Modeliks.DateHelper.headers_Years(true),
          },
        },
      },
      {
        title: "Refund",
        subtitle: "Refunds as % of signups",
        field: "Refund",
        description:
          "Refunds as % of signups is the percentage of new customers (signups) that request a refund of their payment. ",
        growthEnabled: false,
        checkedGrowth: false,
        changeEnabled: true,
        singleCustomersAtStart: false,
        dataGridRow: {
          data: this.state.parent.Refund,
          drivers: [],
        },
        chart: {
          monthlyChartData: {
            data: this.state.revenue.Refund.Values.filter(
              (c) => this.monthHeaders.indexOf(c.Date) > -1,
            ),
            labels: global.Modeliks.DateHelper.headers_Months(),
          },
          yearlyChartData: {
            data: this.state.revenue.Refund.Values.filter(
              (c) => this.yearHeaders.indexOf(c.Date) > -1,
            ),
            labels: global.Modeliks.DateHelper.headers_Years(true),
          },
        },
      },
      {
        title: "Subscription Price",
        subtitle: "Subscription Price",
        field: "SinglePrice",
        description:
          "Subscription price is the price a user pays for using your service for the duration of the subscription period.",
        growthEnabled: true,
        checkedGrowth: false,
        changeEnabled: true,
        singleCustomersAtStart: false,
        dataGridRow: {
          data: this.state.revenue.SinglePrice,
          drivers: [],
        },
        chart: {
          monthlyChartData: {
            data: this.state.revenue.SinglePrice.Values.filter(
              (c) => this.monthHeaders.indexOf(c.Date) > -1,
            ),
            labels: global.Modeliks.DateHelper.headers_Months(),
          },
          yearlyChartData: {
            data: this.state.revenue.SinglePrice.Values.filter(
              (c) => this.yearHeaders.indexOf(c.Date) > -1,
            ),
            labels: global.Modeliks.DateHelper.headers_Years(true),
          },
        },
      },
    ];
  }

  renderCustomersAtStart = (tab) => {
    // if(!tab.singleCustomersAtStart) {
    //     return null;
    // }
    return (
      <SingleSubscriptionContent
        index={tab.dataGridRow.data.ID}
        value={tab.customers}
        CustomersAtStartInit={this.state.revenue.CustomersAtStartInit}
      />
    );
  };

  onSave = () => {
    this.props.saveRevenue();
  };

  onDataChanged = (items) => {
    this.setState({ itemChanged: true });
  };

  render = () => {
    if (this.state.revenue == null) {
      return null;
    }

    return (
      <FinanceDetailsDialog
        open={this.props.open}
        title={this.props.revenue.RevenueName}
        tabs={this.tabs}
        saveEnabled={this.state.itemChanged}
        headers={this.gridHeaders}
        onSave={this.onSave}
        onClose={this.props.onClose}
        onGeneral={this.props.onGeneral}
        onDataChanged={this.onDataChanged}
        tabIndex={this.props.tabIndex}
      />
    );
  };
}

SubscriptionSingle.propTypes = {
  revenue: PropTypes.object,
  onGeneral: PropTypes.func,
  onClose: PropTypes.func,
};

export default SubscriptionSingle;
