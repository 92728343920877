import * as React from "react";
import HeaderText from "./HeaderText";
import { EditIconName, EyeIconClose, IconX, Tick, XCircle } from "../../../icons/svgIcons";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import "../financetablemain.scss";
import { SpecialChar } from "../../../../data/Finance/CalculatedDriver/constants";

const EditDriverName = (props) => {
  if (!global.Modeliks.PERMISSIONS.Financials.restrictions.edit.active) {
    return null;
  }
  return (
    <Tooltip title="Rename" placement="top" arrow>
      <IconButton onClick={props.onClick} size="small">
        <EditIconName />
      </IconButton>
    </Tooltip>
  );
};

const EditInput = (props) => {
  const { name, SaveDriverName, onChange, updateName, cancelEdit } = props;
  const [error, setError] = React.useState(false);
  const ChangeName = (e) => {
    if (e.nativeEvent.data === SpecialChar.DriverNameESCChar) {
      setError(true);
      return;
    }
    if ((e.target.value || e.target.value === "") && e.target.value.length <= 30) {
      onChange(e);
    }
    if (e.target.value && e.target.value.length >= 31) {
      setError(true);
    }
    if (error && e.target.value.length <= 30) {
      setError(false);
    } else {
      if (error || e.target.value.length >= 31) {
        setTimeout(() => {
          setError(false);
        }, 1500);
      }
    }
  };

  const ValidateChangeName = () => {
    if (name === "") {
      setError(true);
    } else {
      updateName();
    }
  };

  return (
    <div className={`${props.className ? "sctfcf_edit_div" : "mct_ft_table_header_input_div"}`}>
      <Tooltip onClick={() => cancelEdit()} title="Cancel" placement="top" arrow>
        <IconButton size="small">
          <IconX width={13} height={13} />
        </IconButton>
      </Tooltip>
      <Tooltip
        open={error}
        disableHoverListener={!error}
        title={
          <>
            <p className="mct_ft_table_head_warning_tooltip">
              Name must containt at least 1 and maximum 30 characters.
            </p>
            <p className="mct_ft_table_head_warning_tooltip">"-" character is not allowed.</p>
          </>
        }
        placement="bottom"
        arrow
      >
        <input
          className={`mct_ft_table_header_input ${error && "mct_ft_table_head_warning"}`}
          onMouseDown={(e) => e.stopPropagation()}
          onClick={(e) => e.stopPropagation()}
          value={name}
          onKeyDown={(e) => e.stopPropagation()}
          onChange={(e) => ChangeName(e)}
          placeholder={name}
          onBlur={(e) => e.stopPropagation()}
        />
      </Tooltip>

      <Tooltip onClick={ValidateChangeName} title="Change" placement="top" arrow>
        <IconButton>
          <Tick />
        </IconButton>
      </Tooltip>
    </div>
  );
};

class FinanceTableHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      DriverName: props.row.header,
    };
  }

  setEditMode = () => {
    this.setState({ editMode: !this.state.editMode });
  };

  changeName = (e) => {
    this.setState({ DriverName: e.target.value });
  };

  updateName = () => {
    if (
      this.state.DriverName.length > 0 &&
      this.state.DriverName.toString().trim() !== "" &&
      !this.state.DriverName.includes("    ")
    ) {
      if (this.props.editScreen) {
        this.state.editMode = false;
        this.props.changeDriverName(this.state.DriverName);
      } else {
        this.props.row.data.DriverName = this.props.row.data.DriverName.replace(
          this.props.row.data.Name,
          this.state.DriverName + " ",
        );
        this.props.row.header = this.props.row.data.Name;
        this.props.row.data.Save(() => {
          this.setEditMode();
        });
      }
    } else {
      this.state.DriverName = this.props.row.header;
      this.setEditMode();
    }
  };

  cancelEditMode = () => {
    this.state.DriverName = this.props.row.header;
    this.setEditMode();
  };

  render() {
    const {
      isTotal,
      level,
      className,
      row,
      showActionButtons,
      children,
      ArrowIcon,
      hasRenameDriverName,
    } = this.props;
    const { editMode } = this.state;

    if (this.props.editScreen) {
      return (
        <>
          {editMode ? (
            <EditInput
              className="sctfcf_edit_div"
              name={this.state.DriverName}
              onChange={this.changeName}
              updateName={this.updateName}
              cancelEdit={this.cancelEditMode}
            />
          ) : (
            <>
              {children}
              {this.props.changeEnabled && <EditDriverName onClick={this.setEditMode} />}
            </>
          )}
        </>
      );
    } else {
      const showIcon =
        level > 0 &&
        this.props.row.data.Ref_Field !== "subscription_revenue" &&
        this.props.row.data.Ref_Field !== "totals" &&
        this.props.row.data.Ref_Field !== "total" &&
        this.props.row.data.db_record
          ? this.props.row.data.UnitType
          : null;

      return (
        <div
          className="mct_ft_tablerow_header_div"
          style={{ marginLeft: isTotal ? 0 : 8 + level * 8 <= 100 ? 8 + level * 8 : 52 }}
        >
          {!isTotal && !editMode && ArrowIcon}
          <div className="mct_ft_tablerow_header_container">
            {editMode ? (
              <EditInput
                name={this.state.DriverName}
                onChange={this.changeName}
                updateName={this.updateName}
                cancelEdit={this.cancelEditMode}
              />
            ) : (
              <>
                <HeaderText
                  disableRowType={this.props.disableRowType}
                  icon={showIcon}
                  header={`${this.props.row.header}`}
                  className={className}
                />

                {showActionButtons && (
                  <div className="mct_ft_tablerow_header_icons_visible">
                    {row.changeDriverNameEnabled && hasRenameDriverName && (
                      <EditDriverName onClick={this.setEditMode} />
                    )}
                    {children}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      );
    }
  }
}
export default FinanceTableHeader;
