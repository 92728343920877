import * as React from "react";
import Dialog from "@mui/material/Dialog";
import ButtonJ from "../Buttons/Button";
import TabsMenu from "../menus/TabsMenu/TabsMenu";
import "./SectionDialog.scss";
import { BluePlus, Exit } from "../IconButtons/SubMenuIcons";
import "./SectionName.scss";
import CircleCheckboxM from "../../../../components/actions/CircleCheckboxM";
import Button from "../../../../components/actions/Button";
import IconButton from "@mui/material/IconButton";
import { TrashCan } from "../../../../components/icons/svgIcons";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@mui/material/Tooltip";

export default function AdminAddSectionDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [name, setName] = React.useState(
    props.name ? props.name : props.businessPlan ? "" : "Section name",
  );

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setSuccess(false);
      setName(props.businessPlan ? "" : "Section name");
    }, 150);
  };

  const handleSave = () => {
    props.addNewSection(name, () => setSuccess(true));
  };

  return (
    <React.Fragment>
      {
        <Tooltip title={"Create new section"}>
          <IconButton onClick={handleClickOpen}>
            <AddIcon fontSize={"small"} />
          </IconButton>
        </Tooltip>
      }
      {open && (
        <Dialog
          fullWidth={true}
          maxWidth={"sm"}
          open={open}
          className={"name_dialog"}
          onClose={handleClose}
        >
          {success ? (
            <>
              <div className={"checkbox_container"}>
                <CircleCheckboxM size="large" checked={true} />
              </div>
              <div className="Label_2">Successfully saved</div>
              <div className="Paragraph_P4">
                Your section is available under <br /> custom sections in Add new section view
              </div>
              <ButtonJ
                size="large"
                width="118px"
                padding="0 0 0 0"
                backgroundColor="primary"
                label={"OK"}
                onClick={() => handleClose()}
              />
            </>
          ) : (
            <>
              <div className={"label"}>{props.text ? props.text : "Add new section"}</div>
              <div className={"subtext"}>{props.subText ? props.subText : ""}</div>
              <div className={"input_container"}>
                <input
                  className={"input"}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder={"Section name..."}
                />
              </div>
              <div className={"btns_container"}>
                <ButtonJ
                  size="large"
                  width="118px"
                  padding="0 0 0 0"
                  color="black"
                  noElevation={true}
                  onClick={handleClose}
                  label={"Cancel"}
                />
                <ButtonJ
                  size="large"
                  width="118px"
                  padding="0 0 0 0"
                  backgroundColor="primary"
                  label={"Save section"}
                  onClick={() => handleSave()}
                />
              </div>
            </>
          )}
        </Dialog>
      )}
    </React.Fragment>
  );
}
