import React from "react";
import FinancialBarChart from "../../../../../../../components/chart/FinancialBarChart";
import "./FinancialMainTotalCharts.scss";
import { PeriodTypes } from "../../../../../../../data/dates";
import { UnitTypes } from "../../../../../../../data/Finance/CalculatedDriver/constants";
import {
  tableConfig,
  tableConfigTypes,
  tableObject,
} from "../../../../../../../components/tables/FinanceTable/components/constants";
import LocalStorage from "../../../../../../../helpers/LocalStorage";

class FinancialMainTotalCharts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chartKey: "chart_" + new Date().getTime(),
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.revenueTotals != this.props.revenueTotals ||
      nextProps.unitValue != this.props.unitValue ||
      nextProps.isItalic != this.props.isItalic
    ) {
      this.setState({ chartKey: "chart_" + new Date().getTime() });
    }
    return true;
  }

  render() {
    this.driverMonths = this.props.Driver.Values.filter((c) => c.PeriodType == PeriodTypes.month);
    this.driverYears = this.props.Driver.Values.filter((c) => c.PeriodType == PeriodTypes.year);
    return (
      <div className="financial_main_total_wrapper">
        <div className={"financial_main_total_monthly_chart_container"}>
          <FinancialBarChart
            key={this.state.chartKey}
            height={64}
            isItalic={this.props.isItalic}
            hideCurrency={
              this.props.useShowHideCurrency
                ? LocalStorage.get(tableObject, tableConfig, tableConfigTypes.showCurrency)
                : false
            }
            isPercentage={this.props.Driver && this.props.Driver.UnitType === UnitTypes.Percentage}
            data={this.props.headers
              .filter((c) => c.PeriodType == PeriodTypes.month)
              .map((c) => this.props.revenueTotals.getItemByDateSufix(c.sufix))}
            labels={this.props.periodHeaders.monthHeaders}
            valueLabels={true}
            unitValue={this.props.unitValue}
            showDriver={this.props.showDriver}
            driverValues={this.props.showDriver ? this.driverMonths : ""}
          />
        </div>
        <div className={"financial_main_total_yearly_chart_container"}>
          <FinancialBarChart
            key={this.state.chartKey}
            height={202}
            isPercentage={this.props.Driver && this.props.Driver.UnitType === UnitTypes.Percentage}
            data={this.props.headers
              .filter((c) => c.PeriodType == PeriodTypes.year)
              .map((c) => this.props.revenueTotals.getItemByDateSufix(c.sufix))}
            color={"#00B3B0"}
            isItalic={this.props.isItalic}
            hideCurrency={
              this.props.useShowHideCurrency
                ? LocalStorage.get(tableObject, tableConfig, tableConfigTypes.showCurrency)
                : false
            }
            labels={this.props.periodHeaders.yearHeaders}
            valueLabels={true}
            showDriver={this.props.showDriver}
            driverValues={this.props.showDriver ? this.driverYears : ""}
            unitValue={this.props.unitValue}
          />
        </div>
      </div>
    );
  }
}

export default FinancialMainTotalCharts;
