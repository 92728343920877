import React from "react";
import { MenuItem, SubMenu } from "@szhsin/react-menu";

export default function OrderSubMenu(props) {
  return (
    <SubMenu className="cm_sub_order" label="Order">
      <MenuItem onClick={props.sentToFront} className="cm_menu_item">
        <span className="cm_sub_name">Bring to front</span>
        <span className="cm_helper_text">Ctrl+Shift+↑</span>
      </MenuItem>
      <MenuItem onClick={props.bringForword} className="cm_menu_item">
        <span className="cm_sub_name">Bring forward</span>
        <span className="cm_helper_text">Ctrl+↑</span>
      </MenuItem>
      <MenuItem onClick={props.bringBackward} className="cm_menu_item">
        <span className="cm_sub_name">Send backward</span>
        <span className="cm_helper_text">Ctrl+↓</span>
      </MenuItem>
      <MenuItem onClick={props.sentToBack} className="cm_menu_item">
        <span className="cm_sub_name">Send to back</span>
        <span className="cm_helper_text">Ctrl+Shift+↓</span>
      </MenuItem>
    </SubMenu>
  );
}
