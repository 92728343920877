import MxDataArray from "../../MxDataArray";
import { TaxesTypes } from "../Taxes/constants";
import IncomeTax from "./IncomeTax";
import SalesTax from "./SalesTax";
import VAT from "./VAT";
import Taxes from "./index";
import TaxesDataGridRows from "./TaxesDataGridRows";

class TaxesStorage extends MxDataArray {
  dataManager = null;
  driverStorage = null;
  loaded = false;

  loadTaxes = (callBack, shouldFinish = true) => {
    this.clear();
    this.dataManager.get(
      this.dataManager.Tables.Finance_Taxes.TableName,
      { ID_CompanyScenario: this.dataManager.CompanyScenarioInfo.ID },
      (data) => {
        if (shouldFinish) {
          this.setData(callBack, data);
        } else {
          this.data = data;
          callBack();
        }
      },
      null,
      false,
    );
  };

  setData = (callBack, data = this.data) => {
    this.pushArray(
      data.map((item) => {
        const newItem = this.getType(item);
        if (newItem.Totals) {
          this.setExistingDrivers(newItem);
          return newItem;
        } else {
          // this.dataManager.del(this.dataManager.Tables.Finance_Taxes.TableName, {ID: newItem.ID}, () => {
          //     console.log('deleting',newItem);
          //
          // }, () => {
          // });
        }
      }),
    );

    Taxes.getTaxesTotals();
    Taxes.getChangeInIncomeTaxPayable();
    Taxes.getChangeInSalesTaxPayable();
    Taxes.getChangeInVATTaxPayable();
    Taxes.getIncomeTaxRateDriver();

    this.loaded = true;
    callBack();
  };
  setGridRows = () => TaxesDataGridRows(this);

  getType = (item) => {
    switch (item.TaxType) {
      case TaxesTypes.IncomeTax:
        return new IncomeTax(item);
      case TaxesTypes.SalesTax:
        return new SalesTax(item);
      case TaxesTypes.VAT:
        return new VAT(item);
    }
  };

  setExistingDrivers = (item) => {
    const IncludedDrivers = [
      IncomeTax.DriversDesc.AccruedIncomeTax.driverID,
      IncomeTax.DriversDesc.IncomeTaxPayableClosingBalance.driverID,
      SalesTax.DriversDesc.TotalAccruedSalesTax.driverID,
      SalesTax.DriversDesc.SalesTaxPayableClosingBalance.driverID,
      VAT.DriversDesc.TotalAccruedVATTax.driverID,
      VAT.DriversDesc.VATTaxPayableClosingBalance.driverID,
    ];
    item.getAllDrivers().forEach((d) => {
      d.IsExisting = IncludedDrivers.includes(d.Ref_Field);
      d.isExpense = true;
    });
  };

  static create = () => {
    const newArr = new TaxesStorage();
    newArr.pushOld = newArr.push;
    newArr.push = newArr.pushThis;

    return newArr;
  };
}

export default TaxesStorage;
