import React from "react";
import styled from "styled-components";
import DialogTitle from "@mui/material/DialogTitle";
import { IconX } from "../../../icons/svgIcons";
import IconButton from "../../../actions/IconButton";
import { Theme } from "../../../../theme/styles/theme";

const Paragraph = styled.p`
  ${Theme.text.paragraph.p2_strong}
  color: #252525;
  font-family: Inter;
`;

export const AddDialogTitle = ({ ...props }) => {
  return (
    <>
      <DialogTitle
        style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
      >
        <Paragraph style={{ padding: 0, margin: 0 }}>{props.title}</Paragraph>
        <IconButton size="small" onClick={props.onClose}>
          <IconX />
        </IconButton>
      </DialogTitle>
    </>
  );
};
