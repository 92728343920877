import React, { Fragment, useState, useEffect } from "react";
import "../HeaderTextEditor.scss";
import "../HeaderDefault.scss";
import { Numbered, Spacing } from "../../components/IconButtons/SubMenuIcons";
import { Menu, MenuItem, SubMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import SpacingSubMenuComponent from "./SpacingSubMenuComponent";
import ButtonMui from "../../../../components/buttons/buttonMui/buttonMui";

function SpacingEditComponent(props) {
  const [lineSpacing, setLineSpacing] = React.useState(
    props.rowStyle && props.rowStyle.lineHeight ? parseFloat(props.rowStyle.lineHeight) : 0,
  );
  const [beforeItemSpacing, setBeforeItemSpacing] = React.useState(
    props.rowStyle && props.rowStyle.paddingTop
      ? parseFloat(props.rowStyle.paddingTop.replace("px", ""))
      : 0,
  );
  const [afterItemSpacing, setAfterItemSpacing] = React.useState(
    props.rowStyle && props.rowStyle.paddingBottom
      ? parseFloat(props.rowStyle.paddingBottom.replace("px", ""))
      : 0,
  );

  useEffect(() => {
    if (props.objType && (props.objType === "table" || props.objType === "shape")) {
      setLineSpacing(
        props.style && props.style.lineHeight ? parseFloat(props.style.lineHeight) : 0,
      );
      setBeforeItemSpacing(
        props.style && props.style.paddingTop
          ? parseFloat(props.style.paddingTop.replace("px", ""))
          : 0,
      );
      setAfterItemSpacing(
        props.style && props.style.paddingBottom
          ? parseFloat(props.style.paddingBottom.replace("px", ""))
          : 0,
      );
    } else {
      setLineSpacing(
        props.rowStyle && props.rowStyle.lineHeight ? parseFloat(props.rowStyle.lineHeight) : 0,
      );
      setBeforeItemSpacing(
        props.rowStyle && props.rowStyle.paddingTop
          ? parseFloat(props.rowStyle.paddingTop.replace("px", ""))
          : 0,
      );
      setAfterItemSpacing(
        props.rowStyle && props.rowStyle.paddingBottom
          ? parseFloat(props.rowStyle.paddingBottom.replace("px", ""))
          : 0,
      );
    }
  }, [props.style, props.rowStyle]);

  const MenuItems = () => {
    return (
      <>
        <MenuItem
          id={"MenuItems"}
          className={
            (props.style && props.style.lineHeight && props.style.lineHeight === 1) ||
            (props.rowStyle && props.rowStyle.lineHeight && props.rowStyle.lineHeight === 1)
              ? "active"
              : ""
          }
          onClick={(event) =>
            props.onChange(
              (props.objType && props.objType === "table") || props.objType === "shape"
                ? { lineHeight: 1 }
                : 1,
            )
          }
        >
          Single
        </MenuItem>
        <MenuItem
          id={"MenuItems"}
          className={
            (props.style && props.style.lineHeight && props.style.lineHeight === 1.15) ||
            (props.rowStyle && props.rowStyle.lineHeight && props.rowStyle.lineHeight === 1.15)
              ? "active"
              : ""
          }
          onClick={(event) =>
            props.onChange(
              (props.objType && props.objType === "table") || props.objType === "shape"
                ? { lineHeight: 1.15 }
                : 1.15,
            )
          }
        >
          1.15
        </MenuItem>
        <MenuItem
          id={"MenuItems"}
          className={
            (props.style && props.style.lineHeight && props.style.lineHeight === 1.5) ||
            (props.rowStyle && props.rowStyle.lineHeight && props.rowStyle.lineHeight === 1.5)
              ? "active"
              : ""
          }
          onClick={(event) =>
            props.onChange(
              (props.objType && props.objType === "table") || props.objType === "shape"
                ? { lineHeight: 1.5 }
                : 1.5,
            )
          }
        >
          1.5
        </MenuItem>
        <MenuItem
          id={"MenuItems"}
          className={
            (props.style && props.style.lineHeight && props.style.lineHeight === 2) ||
            (props.rowStyle && props.rowStyle.lineHeight && props.rowStyle.lineHeight === 2)
              ? "active"
              : ""
          }
          onClick={(event) =>
            props.onChange(
              (props.objType && props.objType === "table") || props.objType === "shape"
                ? { lineHeight: 2 }
                : 2,
            )
          }
        >
          Double
        </MenuItem>
        <div id={"MenuItems"} style={{ border: "1px solid #F0F1F7" }} />
        <MenuItem
          id={"MenuItems"}
          className={
            (props.style && props.style.paddingTop && props.style.paddingTop === "10px") ||
            (props.rowStyle && props.rowStyle.paddingTop && props.rowStyle.paddingTop === "10px")
              ? "active"
              : ""
          }
          onClick={(event) =>
            (props.objType && props.objType === "table") || props.objType === "shape"
              ? props.onChange({
                  paddingTop:
                    props.style && props.style.paddingTop && props.style.paddingTop === "10px"
                      ? "0px"
                      : "10px",
                })
              : props.changeSpacingBeforeItem(
                  props.rowStyle &&
                    props.rowStyle.paddingTop &&
                    props.rowStyle.paddingTop === "10px"
                    ? "0px"
                    : "10px",
                )
          }
        >
          Add space before list item
        </MenuItem>
        <MenuItem
          id={"MenuItems"}
          className={
            (props.style && props.style.paddingBottom && props.style.paddingBottom === "10px") ||
            (props.rowStyle &&
              props.rowStyle.paddingBottom &&
              props.rowStyle.paddingBottom === "10px")
              ? "active"
              : ""
          }
          onClick={(event) =>
            (props.objType && props.objType === "table") || props.objType === "shape"
              ? props.onChange({
                  paddingBottom:
                    props.style && props.style.paddingBottom && props.style.paddingBottom === "10px"
                      ? "0px"
                      : "10px",
                })
              : props.changeSpacingAfterItem(
                  props.rowStyle &&
                    props.rowStyle.paddingBottom &&
                    props.rowStyle.paddingBottom === "10px"
                    ? "0px"
                    : "10px",
                )
          }
        >
          Add space after list item
        </MenuItem>
        <div id={"MenuItems"} style={{ border: "1px solid #F0F1F7" }} />

        <SpacingSubMenuComponent
          style={props.style ? props.style : {}}
          rowStyle={props.rowStyle ? props.rowStyle : {}}
          onChange={props.onChange}
          changeCustomSpacing={props.changeCustomSpacing}
          objType={props.objType}
        />
      </>
    );
  };

  return (
    <>
      {props.menu ? (
        <Menu
          className={props.className ? "spacing " + props.className : "spacing"}
          menuButton={({ open }) => (
            <ButtonMui
              tooltipText={"Spacing"}
              className={props.className ? props.className : ""}
              variant={"text"}
              isIconButton
              roundnessType={"squared"}
              color={"black"}
              label={<Spacing active={open} />}
            />
          )}
        >
          <MenuItems />
        </Menu>
      ) : (
        <SubMenu
          className={props.className ? "spacing " + props.className : "spacing"}
          label={
            <Tooltip title="Spacing" arrow>
              <Spacing
                activeColor={
                  !props.style ||
                  !props.style.backgroundColor ||
                  props.style.backgroundColor === undefined
                    ? "#585858"
                    : props.style.backgroundColor
                }
              />
            </Tooltip>
          }
          direction={"bottom"}
          openTrigger="clickOnly"
        >
          <MenuItems />
        </SubMenu>
      )}
    </>
  );
}

export default SpacingEditComponent;
