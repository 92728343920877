function addItemAtIndex(array, newItem, index) {
  if (index === null) {
    return [...array, newItem];
  }
  // Destructure the array into two parts: elements before the index and elements after the index
  const [beforeIndex, afterIndex] = [array.slice(0, index), array.slice(index)];

  // Return a new array by concatenating the parts with the new item inserted in between
  return [...beforeIndex, newItem, ...afterIndex];
}

function array_move(arr, old_index, new_index) {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
}

export { addItemAtIndex, array_move };
