import React, { Fragment, useState } from "react";
import "../HeaderTextEditor.scss";
import "../HeaderDefault.scss";
import { FillColor } from "../../components/IconButtons/SubMenuIcons";
import { SketchPicker } from "react-color";
import Tooltip from "@mui/material/Tooltip";
import { Menu, ControlledMenu, MenuItem, SubMenu } from "@szhsin/react-menu";
import { FillIcon } from "../../../../components/icons/svgIcons";

function ThemeTemplateColorComponent(props) {
  const preventEvent = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const onChange = (style) => {
    props.changeTemplateColor(style);
  };
  const rgbToRgba = (event) => {
    return "rgba(" + event.rgb.r + "," + event.rgb.g + "," + event.rgb.b + "," + event.rgb.a + ")";
  };
  const FillColorEdit = () => {
    return (
      <SubMenu
        onClick={preventEvent}
        label={({ open }) => (
          <Tooltip arrow title={""} placement={"bottom"}>
            <span className={"color_menu block o_i_btn text"}>
              <div>Slide Background</div>
              {/*<FillIcon active={open} color={!props.businessPlan ? !global.Modeliks.PitchScenarioInfo.BackgroundColor ? '#FFFFFF' : global.Modeliks.PitchScenarioInfo.BackgroundColor : !global.Modeliks.BusinessPlanConfig.BackgroundColor ? '#FFFFFF' : global.Modeliks.BusinessPlanConfig.BackgroundColor}/>*/}
            </span>
          </Tooltip>
        )}
      >
        <SketchPicker
          disableAlpha
          color={props.selectedTemplateColor}
          onChange={(event) => onChange(event.hex)}
        />
      </SubMenu>
    );
  };
  return <>{FillColorEdit()}</>;
}

export default ThemeTemplateColorComponent;
