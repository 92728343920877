import React, { useEffect } from "react";
import "../../../../../components/menus/ScenarioMenu/ScenarioMenu.scss";
import CancelDialog from "../../../../../components/dialogs/settings/CancelDialog";

export default class DeleteCustomIndicatorDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      key: new Date().getTime(),
      driver: null,
    };
  }

  deleteApprove = () => this.props.deleteIndicator();

  deleteConfirmForm = () => {
    return (
      <CancelDialog
        btnName="Confirm"
        firstBtnName="Cancel"
        title={`Delete Indicator`}
        onDelete={this.deleteApprove}
        open={this.props.open}
        onExit={this.props.onClose}
      >
        {`Are you sure that you want to delete this indicator ?`}
      </CancelDialog>
    );
  };

  render() {
    if (this.props.open) {
      return this.deleteConfirmForm();
    }
  }
}
