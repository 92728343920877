import React, { Fragment, useState } from "react";
import "../HeaderTextEditor.scss";
import "../HeaderDefault.scss";
import {
  BorderDash,
  BorderSolid,
  BorderDotted,
  BorderDashed,
  BorderDottedDashed,
} from "../../components/IconButtons/SubMenuIcons";
import {
  Copy,
  Cut,
  Exit,
  FormatColor,
  LowerCase,
  Paste,
  TableBorder1,
  TableBorder2,
  TableBorder3,
  TableBorder4,
  TableBorder5,
  TableBorder6,
  TableBorder7,
  TableBorder8,
  TableBorder9,
  TableBorder10,
  TableBorder11,
  TableBorder12,
  TitleCase,
  UpperCase,
} from "../../components/IconButtons/SubMenuIcons";
import { Menu, MenuItem, SubMenu, MenuButton } from "@szhsin/react-menu";
import Tooltip from "@mui/material/Tooltip";
import ButtonMui from "../../../../components/buttons/buttonMui/buttonMui";

function BorderDashEditComponent(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openBorderDash = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onChange = (style) => {
    props.onChange(style);
    setAnchorEl(null);
  };
  const handleBorderStyleChange = (style) => {
    if (
      props.objType === "textObject" ||
      props.objType === "imageObj" ||
      props.objType === "tableObject"
    ) {
      props.onChange({ borderStyle: style });
    } else {
      props.onChange({ strokeStyle: style });
    }
  };

  const BorderDashEdit = () => {
    return (
      <Menu
        onMouseMove={(e) => {
          e.stopPropagation();
        }}
        className="border_fill"
        menuButton={({ open }) => (
          <ButtonMui
            isIconButton
            tooltipText={"Border fill"}
            className={props.className ? props.className : ""}
            variant={"text"}
            roundnessType={"squared"}
            color={"black"}
            label={<TableBorder1 active={open} />}
          />
        )}
      >
        <MenuItem onClick={() => props.setCellsBorder("all")}>
          <TableBorder1 />
        </MenuItem>
        <MenuItem onClick={() => props.setCellsBorder("inside")}>
          <TableBorder2 />
        </MenuItem>
        <MenuItem onClick={() => props.setCellsBorder("outside")}>
          <TableBorder3 />
        </MenuItem>
        <MenuItem onClick={() => props.setCellsBorder("top")}>
          <TableBorder4 />
        </MenuItem>
        <MenuItem onClick={() => props.setCellsBorder("horizontal")}>
          <TableBorder5 />
        </MenuItem>
        <MenuItem onClick={() => props.setCellsBorder("bottom")}>
          <TableBorder6 />
        </MenuItem>
        <MenuItem onClick={() => props.setCellsBorder("left")}>
          <TableBorder7 />
        </MenuItem>
        <MenuItem onClick={() => props.setCellsBorder("vertical")}>
          <TableBorder8 />
        </MenuItem>
        <MenuItem onClick={() => props.setCellsBorder("right")}>
          <TableBorder9 />
        </MenuItem>
        <MenuItem onClick={() => props.setCellsBorder("none")}>
          <TableBorder10 />
        </MenuItem>
        <MenuItem onClick={() => props.setCellsBorder("verticalAll")}>
          <TableBorder11 />
        </MenuItem>
        <MenuItem onClick={() => props.setCellsBorder("horizontalAll")}>
          <TableBorder12 />
        </MenuItem>
      </Menu>
    );
  };

  return <>{BorderDashEdit()}</>;
}

export default BorderDashEditComponent;
