import { SlideTypes } from "../../components/SlideObjects/SlideStructures";

export class BusinessPlanStrategy {
  getHistoryItem(item, itemIndex) {
    return {
      ...item,
      itemIndex,
    };
  }

  getSlideComponentClassName() {
    return "bp";
  }

  getMovableObjects(state) {
    return state.filter(
      (c) =>
        c.type === SlideTypes.imageObject ||
        c.type === SlideTypes.IconObject ||
        c.type === SlideTypes.newLineObject ||
        c.type === SlideTypes.elbowConnectorObject ||
        c.type === SlideTypes.shapeObject ||
        c.type === SlideTypes.calloutObject,
    );
    
  }

  getSlideObjects(state) {
    return state.filter(
      (c) =>
        c.type !== SlideTypes.imageObject &&
        c.type !== SlideTypes.IconObject &&
        c.type !== SlideTypes.newLineObject &&
        c.type !== SlideTypes.elbowConnectorObject &&
        c.type !== SlideTypes.shapeObject &&
        c.type !== SlideTypes.calloutObject,
    );
  }
}
