import * as React from "react";
import Dialog from "@mui/material/Dialog";
import ButtonJ from "../Buttons/Button";
import TabsMenu from "../menus/TabsMenu/TabsMenu";
import "./SectionDialog.scss";
import IconButton from "@mui/material/IconButton";
import { BluePlus, Exit } from "../IconButtons/SubMenuIcons";
import "./SectionName.scss";
import CircleCheckboxM from "../../../../components/actions/CircleCheckboxM";
import Button from "../../../../components/actions/Button";
import ButtonMui from "../../../../components/buttons/buttonMui/buttonMui";
import { IconPlus } from "../../../../components/icons/svgIcons";

export default function SectionName(props) {
  const [open, setOpen] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [name, setName] = React.useState("Custom Slide");

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setSuccess(false);
      setName("Custom Section");
    }, 150);
  };

  const handleSave = () => {
    props.addNewSection(name, () => setSuccess(true));
  };

  return (
    <React.Fragment>
      {/*{!props.openDialog &&  <ButtonMui width={200} label={'Save as Template'} variant={'text'} onClick={props.saveSection} disabled={props.disableEdit}/>}*/}
      {!props.openDialog && (
        <Button
          color="primary"
          noElevation={true}
          disable={props.disableEdit}
          padding={"0 0 0 0"}
          onClick={props.saveSection}
          label={"Save as Template"}
        />
      )}

      {props.openDialog && (
        <ButtonMui
          variant={"text"}
          disable={props.disableEdit}
          onClick={handleClickOpen}
          label={"Save as Template"}
          endIcon={<IconPlus />}
        />
      )}
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        className={"name_dialog"}
        onClose={handleClose}
      >
        {success ? (
          <>
            <div className={"checkbox_container"}>
              <CircleCheckboxM size="large" checked={true} />
            </div>
            <div className="Label_2">Successfully saved</div>
            <div className="Paragraph_P4">
              Your slide is available under <br /> My Slides in Add Slide view
            </div>
            <ButtonJ
              size="large"
              width="118px"
              padding="0 0 0 0"
              backgroundColor="primary"
              label={"OK"}
              onClick={() => handleClose()}
            />
          </>
        ) : (
          <>
            <div className={"label"}>Save as Slide</div>
            <div className={"input_container"}>
              <input
                className={"input"}
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder={""}
              />
            </div>
            <div className={"btns_container"}>
              <ButtonJ
                size="large"
                width="118px"
                padding="0 0 0 0"
                color="black"
                noElevation={true}
                onClick={handleClose}
                label={"Cancel"}
              />
              <ButtonJ
                size="large"
                width="118px"
                padding="0 0 0 0"
                backgroundColor="primary"
                label={"Save Slide"}
                onClick={() => handleSave()}
              />
            </div>
          </>
        )}
      </Dialog>
    </React.Fragment>
  );
}
