import React from "react";
import { MenuItem, SubMenu } from "@szhsin/react-menu";

export default function RotateSubMenu(props) {
  const handleRotationChange = (rotateAngle) => {
    props.onChange(props.width, props.height, Number(rotateAngle));
  };

  const handleScaleXChange = (scaleX) => {
    props.onChange(props.width, props.height, props.rotateAngle, scaleX, props.scaleY);
  };

  const handleScaleYChange = (scaleY) => {
    props.onChange(props.width, props.height, props.rotateAngle, props.scaleX, scaleY);
  };

  return (
    <SubMenu className="cm_sub_small" label="Rotate">
      <MenuItem
        className="cm_sub_menu_item"
        onClick={() => handleRotationChange(props.rotateAngle + 90)}
      >
        Rotate clockwise 90°
      </MenuItem>
      <MenuItem
        className="cm_sub_menu_item"
        onClick={() => handleRotationChange(props.rotateAngle - 90)}
      >
        Rotate counter-clockwise 90°
      </MenuItem>
      <MenuItem
        className="cm_sub_menu_item"
        onClick={() => handleScaleXChange(-1 * Number(props.scaleX))}
      >
        Flip Horizontally
      </MenuItem>
      <MenuItem
        className="cm_sub_menu_item"
        onClick={() => handleScaleYChange(-1 * Number(props.scaleY))}
      >
        Flip Vertically
      </MenuItem>
    </SubMenu>
  );
}
