async function readFileAsBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
  });
}

async function download(blobData, fileName) {
  const fileURL = URL.createObjectURL(blobData);
  // Setting various property values
  let alink = document.createElement("a");
  alink.href = fileURL;
  alink.download = fileName;
  alink.click();
}

export { readFileAsBase64, download };
