import * as React from "react";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import { Label } from "../../typography/label/Label";
import "./Mx_Switch.scss";

function Mx_Switch(props) {
  const { onChange, checked } = props;

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Label
        color={"black"}
        pointer={true}
        onClick={(e) => onChange(e, false)}
        size="label3"
        label={"Monthly"}
      />

      <Switch checked={checked} onChange={onChange} className="mx_swtich_amsw" size="small" />
      <Label
        color={"black"}
        pointer={true}
        onClick={(e) => onChange(e, true)}
        size="label3"
        label={"Annually"}
      />
    </Stack>
  );
}

Mx_Switch.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  className: PropTypes.string,
};

export default Mx_Switch;
