import { DriverCategories, UnitTypes } from "../../CalculatedDriver/constants";
import MxIDHelper from "../../../MxIDHelper";
import { FinancingTypes, RevenueTypes } from "../../constants";
import CalculatedDriver_Values from "../../CalculatedDriver/CalculatedDriver_Values";
import Taxes from "../index";
import { TaxesTypes, RateChange, RateVery } from "../constants";
import { PayBackOptions } from "../../constants";
import CalculatedDriver from "../../CalculatedDriver";
import {ResellType} from "../../Assets/constants";

class VAT extends Taxes {
    Name = "";
    ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
    TaxType = TaxesTypes.VAT;
    RateChange = RateChange.false;
    RateVery = RateVery.false;
    PayableOpenningBalance;
    TaxPaymentFrequency;

    accrued_streams = [];



    constructor(db_record) {
        super(db_record);
        if (this.db_record) {
            global.Modeliks.AssetsStore.map(d =>d.AssetPurchases).forEach(d => this.createDriversForAsset(d,false))
            global.Modeliks.AssetsStore.filter(d => d.ResellType === ResellType.Yes).map(d => d.AssetSales).forEach(d => this.createDriversForAssetResell(d,false))
            this.clean(true);
        }
        this.SaveRevenue = this.Save;
        this.Save = this.SaveTax;
    }

    clean = (cleanDrivers = false) => {
        if (this.db_record && this.Totals) {
            this.Name = this.db_record.Name;
            this.TaxType = this.db_record.TaxType;
            this.RateChange = this.db_record.RateChange;
            this.RateVery = this.db_record.RateVery;
            this.ID_CompanyScenario = this.db_record.ID_CompanyScenario;
            this.setDriversFromDataStorageSub();
            this.setAccuredStreams();
            if (cleanDrivers) {
                this.TaxPaymentFrequency.cleanValue();
                this.PayableOpenningBalance.cleanValue();
                this.cleanDrivers();
                // this.setAccuredStreams();
                this.setTax && this.setTax();
            }
        }
    };

    static DriversDesc = Object.assign({
        Total: {
            driverName: "$Name",
            fieldName: "Totals",
            driverID: "total",
            unit: UnitTypes.Price,
            category: DriverCategories.Sum,
        },
        VATTaxRate: {
            driverName: "VAT Tax Rate",
            fieldName: "VATTaxRate",
            driverID: "vat_tax_rate",
            unit: UnitTypes.Percentage,
            category: DriverCategories.Average,
        },
        TotalAccruedVATTax: {
            driverName: "Total Accrued VAT Tax",
            fieldName: "TotalAccruedVATTax",
            driverID: "total_accrued_vat_tax",
            unit: UnitTypes.Price,
            category: DriverCategories.Sum,
        },
        // TotalAccruedCostsVAT: {
        //     driverName: '$Name - Taxes Total Accrued Costs VAT',
        //     fieldName: 'TotalAccruedCostsVAT',
        //     driverID: 'total_accrued_costs_vat',
        //     unit: UnitTypes.Price,
        //     category: DriverCategories.Sum
        // },
        // TotalAccruedRevenuesVAT: {
        //     driverName: '$Name - Taxes Total Accrued Revenues VAT',
        //     fieldName: 'TotalAccruedRevenuesVAT',
        //     driverID: 'total_accrued_revenues_vat',
        //     unit: UnitTypes.Price,
        //     category: DriverCategories.Sum
        // },
        VATTaxPayableOpenningBalance: {
            driverName: "VAT Tax Payable Opening Balance",
            fieldName: "VATTaxPayableOpenningBalance",
            driverID: "vat_tax_payable_openning_balance",
            unit: UnitTypes.Price,
            category: DriverCategories.FirstPeriod,
        },
        VATTaxPayments: {
            driverName: "VAT Tax Payments",
            fieldName: "VATTaxPayments",
            driverID: "vat_tax_payments",
            unit: UnitTypes.Price,
            category: DriverCategories.Sum,
        },
        VATTaxPayableClosingBalance: {
            driverName: "VAT Tax Payable",
            fieldName: "VATTaxPayableClosingBalance",
            driverID: "vat_tax_payable_cosing_balance",
            unit: UnitTypes.Price,
            category: DriverCategories.LastPeriod,
        },
    });

    SaveTax = (callBack, saveDrivers = true) => {
        console.log("SaveTax", this);
        this.SaveRevenue((newID) => {
            this.TaxPaymentFrequency.Save();
            this.PayableOpenningBalance.Save();

            if (saveDrivers) {
                this.SaveDrivers(callBack, this.ID);
            } else {
                callBack(this.ID);
            }
        }, false);
    };

    Delete = (callBack) => {
        this.DeleteFunc(this.ID, () => {
            global.Modeliks.del(global.Modeliks.Tables.Finance_CalculatedDrivers.TableName, {Ref_Field: this.getRateDriverAssetResellID(),ID_CompanyScenario: global.Modeliks.CompanyScenarioInfo.ID}, (res) => {
                global.Modeliks.del(global.Modeliks.Tables.Finance_CalculatedDrivers.TableName, {Ref_Field: this.getSalesDriverAssetResellID(),ID_CompanyScenario: global.Modeliks.CompanyScenarioInfo.ID}, (res) => {
                    global.Modeliks.del(global.Modeliks.Tables.Finance_CalculatedDrivers.TableName, {Ref_Field: this.getAssetReverse(),ID_CompanyScenario: global.Modeliks.CompanyScenarioInfo.ID}, (res) => {
                        global.Modeliks.del(global.Modeliks.Tables.Finance_CalculatedDrivers.TableName, {Ref_Field: this.getRateDriverAssetID(),ID_CompanyScenario: global.Modeliks.CompanyScenarioInfo.ID}, (res) => {
                            global.Modeliks.del(global.Modeliks.Tables.Finance_CalculatedDrivers.TableName, {Ref_Field: this.getSalesDriverAssetID(),ID_CompanyScenario: global.Modeliks.CompanyScenarioInfo.ID}, (res) => {
                                global.Modeliks.del(global.Modeliks.Tables.Finance_CalculatedDrivers.TableName, {Ref_Field: this.getSalesDriverID(),ID_CompanyScenario: global.Modeliks.CompanyScenarioInfo.ID}, (res) => {
                                    global.Modeliks.del(global.Modeliks.Tables.Finance_CalculatedDrivers.TableName, {Ref_Field: this.getSalesDriverCostID(),ID_CompanyScenario: global.Modeliks.CompanyScenarioInfo.ID}, (res) => {
                                        global.Modeliks.del(global.Modeliks.Tables.Finance_CalculatedDrivers.TableName, {Ref_Field: this.getRateDriverID(),ID_CompanyScenario: global.Modeliks.CompanyScenarioInfo.ID}, (res) => {
                                            global.Modeliks.del(global.Modeliks.Tables.Finance_CalculatedDrivers.TableName, {Ref_Field: this.getRateDriverCostID(),ID_CompanyScenario: global.Modeliks.CompanyScenarioInfo.ID}, (res) => {
                                                global.Modeliks.del(global.Modeliks.Tables.Finance_CalculatedDrivers.TableName, {Ref_Field: this.getCostReverse(),ID_CompanyScenario: global.Modeliks.CompanyScenarioInfo.ID}, (res) => {
                                                    global.Modeliks.del(global.Modeliks.Tables.Finance_CalculatedDriver_Values.TableName, {ID: this.getTaxPaymentFrequencyID(),ID_CompanyScenario: global.Modeliks.CompanyScenarioInfo.ID}, (res) => {
                                                        global.Modeliks.del(global.Modeliks.Tables.Finance_CalculatedDriver_Values.TableName, {ID: this.getPayableOpenningBalanceID(),ID_CompanyScenario: global.Modeliks.CompanyScenarioInfo.ID}, (res) => {
                                                            if(callBack){
                                                                callBack();
                                                            }
                                                        });
                                                    });
                                                });
                                            });
                                        });
                                    });
                                });
                            });
                        });
                    });
                });
            });
        })
    }

    createExtraPeriods = () => {
        Object.values(this.constructor.DriversDesc).forEach((driver) => {
            this[driver.fieldName].addMonths(0, true, true);
        });

        this.buildPeriodsData();
    };
    periodsData = {};
    buildPeriodsData = () => {
        const allPeriods = this.VATTaxRate.Values.map((c) => c.Date);
        allPeriods.forEach((period) => {
            this.periodsData[period.dateID] = {};
            Object.values(this.constructor.DriversDesc).forEach(
                (driver) =>
                    (this.periodsData[period.dateID][driver.fieldName] = this[
                        driver.fieldName
                        ].getItemByDateSufix(period.sufix)),
            );
            this.periodsData[period.dateID].Date = period;
        });
    };
    setDriversFromDataStorageSub = () => {
        this.TaxPaymentFrequency = global.Modeliks.DriverValuesStore.getItem(
            this.getTaxPaymentFrequencyID(),
        );
        this.PayableOpenningBalance = global.Modeliks.DriverValuesStore.getItem(
            this.getPayableOpenningBalanceID(),
        );
    };
    getTaxPaymentFrequencyID = () => {
        return `${VAT.TableName}-${this.ID}-tax_payment_frequency`;
    };
    getPayableOpenningBalanceID = () => {
        return `${VAT.TableName}-${this.ID}-payable_openning_balance`;
    };
    setAccuredStreams = () => {
        if (this.TotalAccruedVATTax.Formula) {
            this.accrued_streams = this.TotalAccruedVATTax.getChildDrivers().filter(d => d);
        } else {
            this.accrued_streams = [];
        }
    }
    getMonthDatesAll = () => {
        return [...global.Modeliks.DateHelper.months, ...global.Modeliks.DateHelper.months_after]
    }
    getYearDatesAll = () => {
        return global.Modeliks.DateHelper.years_all;
    }
    //todo change streamID full ID
    getSalesStreamID = (stream) => {return `|${stream.Ref_Table}-${stream.Ref_ID}-${this.getSalesDriverID()}|`};
    getSalesStreamCostID = (cost) => {return `|${cost.Ref_Table}-${cost.Ref_ID}-${this.getSalesDriverCostID()}|`};
    getSalesStreamAssetResellID = (asset) => {return `|${asset.Ref_Table}-${asset.Ref_ID}-${this.getSalesDriverAssetResellID()}|`};
    getSalesStreamAssetID = (asset) => {return `|${asset.Ref_Table}-${asset.Ref_ID}-${this.getSalesDriverAssetID()}|`};
    getSalesDriverID = (stream = null) => {
        if(stream){
            return global.Modeliks.DriversStore.getItem(`${VAT.TableName}-${this.ID}-${this.getSalesDriverID()}`);
        }else {
            return `revenue`;
        }
    };
    getRateDriverID = (stream = null) => {
        if(stream){
            return global.Modeliks.DriversStore.getItem(`${stream.Ref_Table}-${stream.Ref_ID}-${this.getRateDriverID()}`);
        }else {
            return `revenue_rate`;
        }
    };

    getSalesDriverCostID = (cost = null) => {
        if(cost){
            return global.Modeliks.DriversStore.getItem(`${cost.Ref_Table}-${cost.Ref_ID}-${this.getSalesDriverCostID()}`);
        }else {
            return `cost`;
        }
    };
    getRateDriverCostID = (cost = null) => {
        if(cost){
            return global.Modeliks.DriversStore.getItem(`${cost.Ref_Table}-${cost.Ref_ID}-${this.getRateDriverCostID()}`);
        }else {
            return `cost_rate`;
        }
    };


    getCostReverse = (stream = null) => {
        return `cost_reverse`;
    };
    getAssetReverse = (stream = null) => {
        return `asset_reverse`;
    };
    getSalesDriverAssetResellID = (asset = null) => {
        if(asset){
            return global.Modeliks.DriversStore.getItem(`${asset.Ref_Table}-${asset.Ref_ID}-${this.getSalesDriverAssetResellID()}`);
        }else {
            return `asset_resell`;
        }
    };
    getRateDriverAssetResellID = (asset = null) => {
        if(asset){
            return global.Modeliks.DriversStore.getItem(`${asset.Ref_Table}-${asset.Ref_ID}-${this.getRateDriverAssetResellID()}`);
        }else {
            return `asset_rate_resell`;
        }
    };


    getSalesDriverAssetID = (asset = null) => {
        if(asset){
            return global.Modeliks.DriversStore.getItem(`${asset.Ref_Table}-${asset.Ref_ID}-${this.getSalesDriverAssetID()}`);
        }else {
            return `asset`;
        }
    };
    getRateDriverAssetID = (asset = null) => {
        if(asset){
            return global.Modeliks.DriversStore.getItem(`${asset.Ref_Table}-${asset.Ref_ID}-${this.getRateDriverAssetID()}`);
        }else {
            return `asset_rate`;
        }
    };



    addToTotalFormula = (ID) => {
        const TotalsTax = this.TotalAccruedVATTax;
        if(TotalsTax.IsSimple && TotalsTax.Formula === null){
            TotalsTax.setFormula(this.getSalesStreamID(ID))
        }else {
            TotalsTax.Formula = TotalsTax.Formula+` + ${this.getSalesStreamID(ID)}`;
        }
        const rateDriver = this.getRateDriverID(ID);
        if(rateDriver){
            rateDriver.removeFormula();
        }

    }

    addToTotalFormulaCost = (cost) => {
        const TotalsTax = this.TotalAccruedVATTax;
        if(TotalsTax.IsSimple && TotalsTax.Formula === null){
            TotalsTax.setFormula(this.getSalesStreamCostID(cost))
        }else {
            TotalsTax.Formula = TotalsTax.Formula+` + ${this.getSalesStreamCostID(cost)}`;
        }

        const rateDriver = this.getRateDriverCostID(cost);
        if(rateDriver){
            rateDriver.removeFormula();
        }

    }


    addToTotalFormulaAsset = (asset) => {
        const TotalsTax = this.TotalAccruedVATTax;
        if(TotalsTax.IsSimple && TotalsTax.Formula === null){
            TotalsTax.setFormula(this.getSalesStreamAssetID(asset))
        }else {
            TotalsTax.Formula = TotalsTax.Formula+` + ${this.getSalesStreamAssetID(asset)}`;
        }

        const rateDriver = this.getRateDriverAssetID(asset);
        if(rateDriver){
            rateDriver.removeFormula();
        }
    }
    addToTotalFormulaAssetResell = (asset) => {
        const TotalsTax = this.TotalAccruedVATTax;
        if(TotalsTax.IsSimple && TotalsTax.Formula === null){
            TotalsTax.setFormula(this.getSalesStreamAssetResellID(asset))
        }else {
            TotalsTax.Formula = TotalsTax.Formula+` + ${this.getSalesStreamAssetResellID(asset)}`;
        }

        const rateDriver = this.getRateDriverAssetResellID(asset);
        if(rateDriver){
            rateDriver.removeFormula();
        }
    }


    removeRevenueFromTotal = (stream) => {
        let drivers = this.TotalAccruedVATTax.getChildDrivers().filter(d => d);
        let alldrivers = drivers.filter(d => d.ID_f !== this.getSalesStreamID(stream));
        let removedDriver = this.getRateDriverID(stream);
        removedDriver.removeFormula();
        this.TotalAccruedVATTax.removeFormula();
        if(alldrivers && alldrivers.length > 0){
            this.TotalAccruedVATTax.Formula = `${alldrivers.map(d => d.ID_f).join('+')}`;
        }else {
            this.TotalAccruedVATTax.Formula = null;
        }

    }

    removeRevenueFromTotalCost = (stream) => {
        let drivers = this.TotalAccruedVATTax.getChildDrivers().filter(d => d);
        let alldrivers = drivers.filter(d => d.ID_f !== this.getSalesStreamCostID(stream));
        let removedDriver = this.getRateDriverCostID(stream);
        removedDriver.removeFormula();
        this.TotalAccruedVATTax.removeFormula();
        if(alldrivers && alldrivers.length > 0){
            this.TotalAccruedVATTax.Formula = `${alldrivers.map(d => d.ID_f).join('+')}`;
        }else {
            this.TotalAccruedVATTax.Formula = null;
        }

    }
    removeRevenueFromTotalAsset = (stream) => {
        let drivers = this.TotalAccruedVATTax.getChildDrivers().filter(d => d);
        let alldrivers = drivers.filter(d => d.ID_f !== this.getSalesStreamAssetID(stream));
        let removedDriver = this.getRateDriverAssetID(stream);
        removedDriver.removeFormula();
        this.TotalAccruedVATTax.removeFormula();
        if(alldrivers && alldrivers.length > 0){
            this.TotalAccruedVATTax.Formula = `${alldrivers.map(d => d.ID_f).join('+')}`;
        }else {
            this.TotalAccruedVATTax.Formula = null;
        }

    }
    removeRevenueFromTotalAssetResell = (stream) => {
        let drivers = this.TotalAccruedVATTax.getChildDrivers().filter(d => d);
        let alldrivers = drivers.filter(d => d.ID_f !== this.getSalesStreamAssetResellID(stream));
        let removedDriver = this.getRateDriverAssetResellID(stream);
        if(removedDriver){
            removedDriver.removeFormula();
        }
        this.TotalAccruedVATTax.removeFormula();
        if(alldrivers && alldrivers.length > 0){
            this.TotalAccruedVATTax.Formula = `${alldrivers.map(d => d.ID_f).join('+')}`;
        }else {
            this.TotalAccruedVATTax.Formula = null;
        }

    }


    //revenue
    TotalSalesIncludesID = (stream) => {
        return this.TotalAccruedVATTax.Formula && this.TotalAccruedVATTax.Formula.includes(this.getSalesStreamID(stream));
    }
    TotalSalesIncludesCostID = (Cost) => {
        return this.TotalAccruedVATTax.Formula && this.TotalAccruedVATTax.Formula.includes(this.getSalesStreamCostID(Cost));
    }
    TotalSalesIncludesAssetID = (asset) => {
        return this.TotalAccruedVATTax.Formula && this.TotalAccruedVATTax.Formula.includes(this.getSalesStreamAssetID(asset));
    }

    TotalSalesIncludesAssetResellID = (asset) => {
        return this.TotalAccruedVATTax.Formula && this.TotalAccruedVATTax.Formula.includes(this.getSalesStreamAssetResellID(asset));
    }


    setTotalAccruedSalesFormula = (stream) => {
        this.TotalSalesIncludesID(stream) ? this.removeRevenueFromTotal(stream) : this.addToTotalFormula(stream);
    }
    setTotalAccruedSalesFormulaCost = (stream) => {
        this.TotalSalesIncludesCostID(stream) ? this.removeRevenueFromTotalCost(stream) : this.addToTotalFormulaCost(stream);
    }
    setTotalAccruedSalesFormulaAsset = (stream) => {
        this.TotalSalesIncludesAssetID(stream) ? this.removeRevenueFromTotalAsset(stream) : this.addToTotalFormulaAsset(stream);
    }
    setTotalAccruedSalesFormulaAssetResell = (stream) => {
        this.TotalSalesIncludesAssetResellID(stream) ? this.removeRevenueFromTotalAssetResell(stream) : this.addToTotalFormulaAssetResell(stream);
    }


    createMonthsFromYearFormula = (newSales ,newRate,revenue) => {
        const years = this.getYearDatesAll();
        years.forEach(year => {
            const months = year.months.map(m => newSales.getItemByDateSufix(m.sufix));
            const yearRevenue = revenue.getItemByDateSufix(year.sufix);
            if(year.Active){
                months.forEach(month => month.Formula = `(${yearRevenue} / 12) * ${newRate.getItemByDateSufix(year.sufix)}`)
            }
        })
    }
    createMonthsFromYearFormulaCost = (newSales ,newRate,cost) => {
        const years = this.getYearDatesAll();
        years.forEach(year => {
            const months = year.months.map(m => newSales.getItemByDateSufix(m.sufix));
            const yearCost = cost.getItemByDateSufix(year.sufix);
            if(year.Active){
                months.forEach(month => month.Formula = `(${yearCost} / 12) * ${newRate.getItemByDateSufix(year.sufix)}`)
            }
        })
    }

    createDriversForRevenue = (revenue) => {
        let newSales = this.getSalesDriverID(revenue);
        let newRate = this.getRateDriverID(revenue);
        if(!newRate && !newSales){
            newSales = CalculatedDriver.createDriver(revenue.Ref_Table, revenue.Ref_ID, `${this.getSalesDriverID()}`,UnitTypes.Price, DriverCategories.Sum, `Accured VAT ${revenue.DriverName}`);
            newSales.addMonths(0, true, true);
            newRate = CalculatedDriver.createDriver(revenue.Ref_Table, revenue.Ref_ID, `${this.getRateDriverID()}`,UnitTypes.Percentage, DriverCategories.Average, `VAT Rate ${revenue.DriverName}`);
            newRate.addMonths(0, true, true);
            newSales.setFormula(`${revenue.ID_f} * ${newRate.ID_f}`);
            this.createMonthsFromYearFormula(newSales, newRate, revenue);
        }
        this.addToTotalFormula(revenue);
    }

    createDriversForCost = (cost) => {
        let newSales = this.getSalesDriverCostID(cost);
        let newRate = this.getRateDriverCostID(cost);
        if(!newRate && !newSales){
            let newCost = null;
            newSales = CalculatedDriver.createDriver(cost.Ref_Table, cost.Ref_ID, `${this.getSalesDriverCostID()}`,UnitTypes.Price, DriverCategories.Sum, `Accured VAT ${cost.DriverName}`);
            newSales.addMonths(0, true, true);
            newRate = CalculatedDriver.createDriver(cost.Ref_Table, cost.Ref_ID, `${this.getRateDriverCostID()}`,UnitTypes.Percentage, DriverCategories.Average, `VAT Rate ${cost.DriverName}`);
            newRate.addMonths(0, true, true);
            newCost = CalculatedDriver.createDriver(cost.Ref_Table, cost.Ref_ID, `${this.getCostReverse()}`,cost.UnitType, cost.DriverCategory, `Cost ${cost.DriverName}`);
            newCost.setFormula(`MxMath.Negative(${cost.ID_f})`);
            newCost.Formula = null;
            newSales.setFormula(`${newCost.ID_f} * ${newRate.ID_f}`);

            this.createMonthsFromYearFormulaCost(newSales, newRate, newCost);
        }

        this.addToTotalFormulaCost(cost);
    }

    createDriversForAsset = (asset, addToFormula = true) => {
        let newSales = this.getSalesDriverAssetID(asset);
        let newRate = this.getRateDriverAssetID(asset);
        if(!newRate && !newSales){
            let newAsset = null;
            newSales = CalculatedDriver.createDriver(asset.Ref_Table, asset.Ref_ID, `${this.getSalesDriverAssetID()}`,UnitTypes.Price, DriverCategories.Sum, `Accured VAT ${asset.DriverName}`);
            newSales.addMonths(0, true, true);
            newRate = CalculatedDriver.createDriver(asset.Ref_Table, asset.Ref_ID, `${this.getRateDriverAssetID()}`,UnitTypes.Percentage, DriverCategories.Average, `VAT Rate ${asset.DriverName}`);
            newRate.addMonths(0, true, true);
            newAsset = CalculatedDriver.createDriver(asset.Ref_Table, asset.Ref_ID, `${this.getAssetReverse()}`,asset.UnitType, asset.DriverCategory, `Cost ${asset.DriverName}`);
            newAsset.setFormula(`MxMath.Negative(${asset.ID_f})`);
            newAsset.Formula = null;
            newSales.setFormula(`${newAsset.ID_f} * ${newRate.ID_f}`);
            this.createMonthsFromYearFormulaCost(newSales, newRate, newAsset);
        }
        if(addToFormula && !this.TotalSalesIncludesAssetID(asset)){
            this.addToTotalFormulaAsset(asset);
        }
    }
    createDriversForAssetResell = (asset, addToFormula = true) => {
        let newSales = this.getSalesDriverAssetResellID(asset);
        let newRate = this.getSalesDriverAssetResellID(asset);
        if(!newRate && !newSales){
            newSales = CalculatedDriver.createDriver(asset.Ref_Table, asset.Ref_ID, `${this.getSalesDriverAssetResellID()}`,UnitTypes.Price, DriverCategories.Sum, `Accured VAT ${asset.DriverName}`);
            newSales.addMonths(0, true, true);
            newRate = CalculatedDriver.createDriver(asset.Ref_Table, asset.Ref_ID, `${this.getRateDriverAssetResellID()}`,UnitTypes.Percentage, DriverCategories.Average, `VAT Rate ${asset.DriverName}`);
            newRate.addMonths(0, true, true);
            newSales.setFormula(`${asset.ID_f} * ${newRate.ID_f}`);
            this.createMonthsFromYearFormula(newSales, newRate, asset);
        }
        if(addToFormula && !this.TotalSalesIncludesAssetResellID(asset)){
            this.addToTotalFormulaAssetResell(asset);
        }
    }


    SelectAllStreams = () => {
        this.TotalAccruedVATTax.Formula = null;
        let revenues = global.Modeliks.RevenuesStore.map(r => r.Totals);
        let costs = global.Modeliks.CostSaleStore.map((r) => r.Totals).concat(global.Modeliks.ExpensesStore.map((r) => r.Totals));
        let assets = global.Modeliks.AssetsStore.map(d => d.AssetPurchases);
        let assetsResell = global.Modeliks.AssetsStore.filter(d => d.ResellType === ResellType.Yes).map(d => d.AssetSales);

        if(revenues.length > 0){
            revenues.forEach(r => this.createDriversForRevenue(r))
        }
        if(costs.length > 0){
            costs.forEach(r => this.createDriversForCost(r))
        }
        if(assets.length > 0){
            assets.forEach(r => this.createDriversForAsset(r, true))
        }
        if(assetsResell.length > 0){
            assetsResell.forEach(r => this.createDriversForAssetResell(r, true))
        }


    }
    getAllRateDrivers = () => {
        if(this.accrued_streams.length > 0){
            return this.accrued_streams.map(a => {
                return a.getChildDrivers()[1];
            });
        }else {
            return []
        }
    };
    getAllStreamsDrivers = () => {
        if (this.accrued_streams.length > 0) {
            return this.accrued_streams.map((a) => {
                return a.getChildDrivers()[0]
            });
        } else {
            return [];
        }
    };

    createMonthsFormulas = () => {
        const months = this.getMonthDatesAll();
        const rateDrivers = this.getAllRateDrivers();
        const streamDrivers = this.getAllStreamsDrivers();

        months.forEach((month) => {
            const prevMonthDate = months.find((c) => c.Order === month.Order - 1);
            const curMonth = this.periodsData[month.dateID];
            const firstMonth = this.periodsData[months[0].dateID];
            const prevMonth = prevMonthDate ? this.periodsData[prevMonthDate.dateID] : null;
            const prevSingleMonthDate = (month, prevMonths) =>
                this.periodsData[months.find((c) => c.Order === month.Order - prevMonths).dateID];

            if (prevMonth) {
                curMonth.VATTaxPayableOpenningBalance.Formula = `${prevMonth.VATTaxPayableClosingBalance}`;
                curMonth.VATTaxPayments.Formula = null;
                curMonth.VATTaxPayments.Value = 0;
            } else {
                curMonth.VATTaxPayableOpenningBalance.Formula = `${this.PayableOpenningBalance}`;
                curMonth.VATTaxPayments.Formula = `MxMath.IFELSE(${curMonth.VATTaxPayableOpenningBalance} > 0, ${curMonth.VATTaxPayableOpenningBalance}, null)`;
            }

            if (month.Order >= 12 / this.TaxPaymentFrequency.Value) {
                const prevDate = prevSingleMonthDate(month, 12 / this.TaxPaymentFrequency.Value);
                if (prevDate && prevDate.VATTaxPayments.Formula) {
                    curMonth.VATTaxPayments.Formula = `MxMath.IFELSE(${curMonth.VATTaxPayableOpenningBalance} > 0, ${curMonth.VATTaxPayableOpenningBalance}, null)`;
                }
            }

            curMonth.VATTaxPayableClosingBalance.Formula = `${curMonth.TotalAccruedVATTax} + ${curMonth.VATTaxPayableOpenningBalance} - ${curMonth.VATTaxPayments}`;

            if (this.accrued_streams.length > 0) {
                curMonth.TotalAccruedVATTax.Formula = `MxMath.Sum([${this.accrued_streams.map((d) => d.getItemByDateSufix(month.sufix).ID_f).join(",")}])`;
            } else {
                curMonth.TotalAccruedVATTax.Formula = null;
            }

            curMonth.Totals.Formula = `${curMonth.TotalAccruedVATTax}`;

            if (this.RateVery === RateVery.false) {
                if (this.RateChange === RateChange.false) {
                    if (prevMonth) {
                        curMonth.VATTaxRate.Formula = `${firstMonth.VATTaxRate.ID_f} * 100`;
                    } else {
                        const tmpValue = firstMonth.VATTaxRate.Value;
                        firstMonth.VATTaxRate.Formula = null;
                        firstMonth.VATTaxRate.Value = tmpValue;
                    }
                    rateDrivers.forEach(
                        (rate) =>
                            (rate.getItemByDateSufix(month.sufix).Formula = `${firstMonth.VATTaxRate} * 100`),
                    );
                } else {
                    const tmpValue = curMonth.VATTaxRate.Value;
                    curMonth.VATTaxRate.Formula = null;
                    curMonth.VATTaxRate.Value = tmpValue;
                    rateDrivers.forEach(
                        (rate) =>
                            (rate.getItemByDateSufix(month.sufix).Formula = `${curMonth.VATTaxRate} * 100`),
                    );
                }
            } else {
                //todo VATTaxRate formulas--
                if (this.RateChange === RateChange.false) {
                    if (prevMonth) {
                        rateDrivers.forEach(
                            (rate) =>
                                (rate.getItemByDateSufix(month.sufix).Formula =
                                    `${rate.getItemByDateSufix(months[0].sufix)} * 100`),
                        );
                    } else {
                        rateDrivers.forEach((rate) => {
                            const RateDriver = rate.getItemByDateSufix(months[0].sufix);
                            const tmpValue = RateDriver.Value;
                            RateDriver.Formula = null;
                            RateDriver.Value = tmpValue;
                        });
                    }
                } else {
                    rateDrivers.forEach((rate) => {
                        const RateDriver = rate.getItemByDateSufix(month.sufix);
                        const tmpValue = RateDriver.Value;
                        RateDriver.Formula = null;
                        RateDriver.Value = tmpValue;
                    });
                }
                if (month.Active) {
                    if (this.accrued_streams.length > 0) {
                        curMonth.VATTaxRate.Formula = `(${curMonth.TotalAccruedVATTax} / MxMath.Sum([${streamDrivers.map((r) => r.getItemByDateSufix(month.sufix).ID_f).join(",")}])) * 100`;
                    } else {
                        curMonth.VATTaxRate.Formula = null;
                    }
                }
            }
        });
    };
    createYearsFormulas = () => {
        const years = this.getYearDatesAll();
        const rateDrivers = this.getAllRateDrivers();
        const streamDrivers = this.getAllStreamsDrivers();

        years.forEach((year) => {
            const curYear = this.periodsData[year.dateID];
            const months = this.periodsData[year.dateID].Date.monthIndexes.map(
                (index) => this.periodsData[index],
            );
            const lastMonth = months[months.length - 1];
            const firstMonth = months[0];

            curYear.TotalAccruedVATTax.Formula = `MxMath.Sum([${months.map((c) => c.TotalAccruedVATTax.ID_f).join(",")}])`;
            curYear.VATTaxPayableOpenningBalance.Formula = `${firstMonth.VATTaxPayableOpenningBalance}`;
            curYear.VATTaxPayments.Formula = `MxMath.Sum([${months.map((c) => c.VATTaxPayments.ID_f).join(",")}])`;
            curYear.VATTaxPayableClosingBalance.Formula = `${lastMonth.VATTaxPayableClosingBalance}`;
            curYear.Totals.Formula = `${curYear.TotalAccruedVATTax}`;

            if (this.RateVery === RateVery.false) {
                if (this.RateChange === RateChange.false) {
                    curYear.VATTaxRate.Formula = `${firstMonth.VATTaxRate} * 100`;
                    rateDrivers.forEach(
                        (rate) => (rate.getItemByDateSufix(year.sufix).Formula = `${curYear.VATTaxRate} * 100`),
                    );
                } else {
                    if (year.Active) {
                        const tempValue = curYear.VATTaxRate.Value;
                        curYear.VATTaxRate.Formula = null;
                        curYear.VATTaxRate.Value = tempValue;
                        months.forEach((m) => {
                            m.VATTaxRate.Formula = `${curYear.VATTaxRate} * 100`;
                        });
                        rateDrivers.forEach(
                            (rate) =>
                                (rate.getItemByDateSufix(year.sufix).Formula = `${curYear.VATTaxRate} * 100`),
                        );
                    } else {
                        curYear.VATTaxRate.Formula = `(${curYear.TotalAccruedVATTax} / MxMath.Sum([${streamDrivers.map((c) => c.getItemByDateSufix(year.sufix).ID_f).join(",")}])) * 100`;
                    }
                }
            } else {
                if (this.RateChange === RateChange.false) {
                    rateDrivers.forEach(
                        (rate) =>
                            (rate.getItemByDateSufix(year.sufix).Formula =
                                `${rate.getItemByDateSufix(years[0].months[0].sufix)} * 100`),
                    );
                    curYear.VATTaxRate.Formula = `(${curYear.TotalAccruedVATTax} / MxMath.Sum([${streamDrivers.map((c) => c.getItemByDateSufix(year.sufix).ID_f).join(",")}])) * 100`;
                } else {
                    if (year.Active) {
                        const tempValue = curYear.VATTaxRate.Value;
                        curYear.VATTaxRate.Formula = null;
                        curYear.VATTaxRate.Value = tempValue;
                        months.forEach((m) => {
                            m.VATTaxRate.Formula = `${curYear.VATTaxRate} * 100`;
                        });
                        rateDrivers.forEach((rate) => {
                            const tmpValue = rate.getItemByDateSufix(year.sufix).Value;
                            rate.getItemByDateSufix(year.sufix).Formula = null;
                            rate.getItemByDateSufix(year.sufix).Value = tmpValue;
                        });
                    } else {
                        rateDrivers.forEach((rate) => {
                            const Sale = this.accrued_streams.find((d) => d.Formula.includes(rate.ID));
                            rate.getItemByDateSufix(year.sufix).Formula =
                                `(${Sale.getItemByDateSufix(year.sufix)} / ${Sale.getChildDrivers()[0].getItemByDateSufix(year.sufix)}) * 100`;
                        });
                    }
                    curYear.VATTaxRate.Formula = `(${curYear.TotalAccruedVATTax} / MxMath.Sum([${streamDrivers.map((c) => c.getItemByDateSufix(year.sufix).ID_f).join(",")}])) * 100`;
                }
            }
        });
    };

    setTax = () => {
        this.buildPeriodsData();
        this.setAccuredStreams();
        this.createMonthsFormulas();
        this.createYearsFormulas();
    };

    getVatTaxStream = () => {
        return global.Modeliks.TaxesStore.find((d) => d.TaxType === TaxesTypes.VAT);
    };

    static convert_Tax = (tax) => {
        const driver = global.Modeliks.TaxesStore.find((d) => d.TaxType === TaxesTypes.VAT);

        if (driver) {
            return driver;
        } else {
            const newVAT = new VAT();

            newVAT.ID = tax.ID;
            newVAT.Name = tax.Name;
            newVAT.Totals = CalculatedDriver.createDriverFromTable(
                newVAT,
                VAT.DriversDesc.Total.driverID,
                UnitTypes.Price,
                DriverCategories.Sum,
            );
            newVAT.ID_CompanyScenario = global.Modeliks.CompanyScenarioInfo.ID;
            newVAT.TaxPaymentFrequency = new CalculatedDriver_Values(
                null,
                newVAT.getTaxPaymentFrequencyID(),
                null,
                UnitTypes.Integer,
            );
            newVAT.TaxPaymentFrequency.Value = PayBackOptions[0].value;
            newVAT.PayableOpenningBalance = new CalculatedDriver_Values(
                null,
                newVAT.getPayableOpenningBalanceID(),
                null,
                UnitTypes.Price,
            );
            newVAT.PayableOpenningBalance.Value = 0;
            newVAT.createDrivers();
            newVAT.createExtraPeriods();
            newVAT.SelectAllStreams();
            newVAT.IsCreated = true;
            return newVAT;
        }
    };
}

export default VAT;
