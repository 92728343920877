import * as React from "react";
import { Dialog, DialogActions, DialogContent } from "@mui/material";

import ButtonJ from "../Buttons/Button";
import TabsMenu from "../menus/TabsMenu/TabsMenu";
import "./SectionDialog.scss";
import IconButton from "@mui/material/IconButton";
import { Exit } from "../IconButtons/SubMenuIcons";
import Button from "../../../../components/actions/Button";
import DeleteForeverSharpIcon from "@mui/icons-material/DeleteForeverSharp";
import CalculatedDriver from "../../../../data/Finance/CalculatedDriver";
import { TrashCan } from "../../../../components/icons/svgIcons";
import AdminAddSectionDialog from "./AdminAddSectionDialog";
import AdminUpdateSectionDialog from "./AdminUpdateSectionDialog";
import BusinessPlanAddNewSectionDialog from "./BusinessPlanAddNewSectionDialog";
import { ReportsTableSizes, ReportsTypes } from "../../../../data/Finance/Reports/constants";
import { SlideObject, SlideTypes } from "../../SlideObjects/SlideStructures";
import { tableDateType } from "../../SlideObjects/FinanceTable/FinanceTable";
import { Label } from "../../../../components/typography/label/Label";
import DatePickerYM from "../../../../components/actions/DatePickers/DatePickerYM";
import ButtonMui from "../../../../components/buttons/buttonMui/buttonMui";
import { IconPlus } from "../Icons/Icons";
import Tooltip from "@mui/material/Tooltip";
import InfoDialogActions from "../../../../components/dialogs/financials/InfoDialog/InfoDialogActions";
import { AddDialogTitle } from "../../../../components/dialogs/financials/AddDialog/AddDialogTitle";

export default function SectionDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [sections, setSections] = React.useState(props.categories[0].Sections);
  const [selectedCategory, setSelectedCategory] = React.useState(props.categories[0].CategoryName);
  const [category, setCategory] = React.useState(props.categories[0]);
  const [selectedSection, setSelectedSection] = React.useState(props.categories[0].Sections[0]);
  const [deleteMode, setDeleteMode] = React.useState(false);
  const [openAddSection, setOpenAddSection] = React.useState(false);
  const [key, setKey] = React.useState(null);
  const [r, setR] = React.useState(null);
  const [type, setType] = React.useState(null);
  const [disabled, setDisabled] = React.useState(false);

  const [selectedIDs, setSelectedIDs] = React.useState([]);

  const handleClickOpen = () => {
    setOpen(true);
    setDefaults();
  };

  let categoryName = "";

  const setDefaults = () => {
    setSelectedSection(props.categories[0].Sections[0] ? props.categories[0].Sections[0] : "blank");
    setSelectedCategory(props.categories[0].CategoryName);
    setCategory(props.categories[0]);
    setSections(props.categories[0].Sections);
    setDisabled(false);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(setDefaults, 200);
  };

  const handleTabChange = (val) => {
    changeCategory(val);
  };

  const changeCategory = (name) => {
    let tmpName = name === "My Slides" || name === "My Sections" ? "Custom" : name;
    let tmpcategory = props.categories.find((c) => c.CategoryName == tmpName);
    setCategory(tmpcategory);
    let tmpSections = tmpcategory.Sections.filter(
      (c) => !c.dontShowInDialog || c.dontShowInDialog !== true,
    );
    setSelectedCategory(tmpName);
    setSections(tmpSections);
    setSelectedSection(tmpSections[0] ? tmpSections[0] : "blank");
    categoryName = tmpName;
  };

  const handleDeleteMode = () => {
    setDeleteMode(!deleteMode);
  };

  const handleDelete = () => {
    if (selectedSection && selectedSection.ID) {
      props.deleteComplete(selectedSection.ID, category.ID, () => handleClose());
    }
  };

  const addSection = () => {
    if (selectedSection === "blank") {
      props.addBlankSection(() => handleClose());
    } else {
      props.addSection(selectedSection, () => handleClose());
    }
  };

  const handleSelect = (e, id) => {
    if (selectedIDs.indexOf(id) === -1) {
      setSelectedIDs([...selectedIDs, id]);
    } else {
      let index = selectedIDs.indexOf(id);
      let tmpArr = [...selectedIDs];
      tmpArr.splice(index, 1);
      setSelectedIDs(tmpArr);
    }
  };

  const handleDeleteSuccess = () => {
    if (selectedIDs.length > 0) {
      if (!props.businessPlan) {
        global.Modeliks.del("slidesections_client", { ID: selectedIDs }, (res) => {
          setSections(sections.filter((c) => !selectedIDs.includes(c.ID)));
          props.deleteSections(selectedIDs);
          handleDeleteMode();
        });
      } else {
        global.Modeliks.del("businessplanpages_client", { ID: selectedIDs }, (res) => {
          setSections(sections.filter((c) => !selectedIDs.includes(c.ID)));
          props.deleteSections(selectedIDs);
          handleDeleteMode();
        });
      }
    }
  };

  const handleAddSection = (name) => {
    if (!props.businessPlan) {
      props.addNewSection(name, category.ID, () => handleClose());
    } else {
      if (!props.isAdmin) {
        props.addNewSection(name, () => handleClose());
      } else {
        props.addNewSection(name, category.ID, () => handleClose());
      }
    }
  };

  const getTableObjects = () => {
    return (
      <>
        {Object.keys(ReportsTableSizes).map((r, i) => {
          return Object.keys(ReportsTypes)
            .filter((key) => !!ReportsTypes[key].show)
            .map((key, index) => {
              return (
                <div
                  key={"mnth+" + i + index}
                  onClick={() => {
                    setSelectedSection(key + " " + r + " Month");
                    setKey(key);
                    setR(r);
                    setType("month");
                  }}
                  className={
                    key + " " + r + " Month" === selectedSection ? "section selected" : "section"
                  }
                >
                  <div className={"section_name"}>
                    {ReportsTypes[key].Name + " " + r + " (Monthly)"}
                  </div>
                </div>
              );
              // <MenuItem key={'mnth+'+i+index} onClick={()=>{props.onNewObject(new SlideObject(SlideTypes.financeTable, {dateType: tableDateType.month, reportType: ReportsTypes[key], tableSize: ReportsTableSizes[r].size}, {width:600,height:600,top:480,left:100}))}}>{key + ' ' + r}</MenuItem>
            });
        })}
        {Object.keys(ReportsTableSizes).map((r, i) => {
          return Object.keys(ReportsTypes)
            .filter((key) => !!ReportsTypes[key].show)
            .map((key, index) => {
              return (
                <div
                  key={"year" + i + index}
                  onClick={() => {
                    setSelectedSection(key + " " + r + " Year");
                    setKey(key);
                    setR(r);
                    setType("year");
                  }}
                  className={
                    key + " " + r + " Year" === selectedSection ? "section selected" : "section"
                  }
                >
                  <div className={"section_name"}>
                    {ReportsTypes[key].Name + " " + r + " (Annual)"}
                  </div>
                </div>
              );
            });
        })}
      </>
    );
  };

  const getChartObjects = () => {
    return [
      ...global.Modeliks.PitchFinancialChartsYearly,
      ...global.Modeliks.PitchFinancialChartsMonthly,
    ].map((c) => (
      <div
        className={c.ID === selectedSection ? "section selected" : "section"}
        onClick={() => setSelectedSection(c.ID)}
      >
        <div className={"section_name"}>{c.Data.chartOptions.title}</div>
      </div>
    ));
  };

  const handleUpdateSection = (name) => {
    if (typeof selectedSection != "number") {
      props.updateSection(name, selectedSection.ID, category.ID, () => handleClose());
    }
  };

  const getSections = () => {
    if (props.businessPlan) {
      if (selectedCategory === "Tables") {
        return getTableObjects();
      }
      if (selectedCategory === "Charts") {
        return getChartObjects();
      }

      return sections.map((section, index) => {
        let sectionID = null;

        if (section.MasterPageID) {
          sectionID = section.MasterPageID;
        } else {
          sectionID = section.ID;
        }

        return (
          <div
            key={"sections_" + index}
            onClick={() => setSelectedSection(section)}
            className={
              !deleteMode
                ? section.ID === selectedSection.ID
                  ? "section_bp selected"
                  : "section_bp"
                : selectedIDs.indexOf(section.ID) > -1
                  ? "section_bp selected"
                  : "section_bp"
            }
          >
            <img
              className={"section_thumbnail_bp"}
              src={`/api/img?tmp=${sectionID}&v=${section.UpdatedAt}&businessPlan=true`}
            />
            <div className={"section_name_bp"}>
              {section[props.tabKey] ?? section["SectionName"]}
              {section.isCustom && deleteMode && (
                <input
                  type="checkbox"
                  checked={selectedIDs.indexOf(section.ID) > -1}
                  onClick={(e) => handleSelect(e, section.ID)}
                />
              )}
            </div>
          </div>
        );
      });
    } else {
      return sections.map((section, index) => {
        let template = section.Templates.find((c) => c.isDefault);

        if (!template) {
          template = section.Templates[0];
        }

        let templateID = null;
        let queryString = "";

        if (template.MasterTemplateID) {
          templateID = template.MasterTemplateID;
        } else {
          if (section.isCustom) {
            templateID = template.ID;
            queryString += `&is_custom=true&account_id=${global.Modeliks.Account.ID}&company_id=${global.Modeliks.CompanyInfo.ID}&pitch_scenario_id=${global.Modeliks.PitchScenarioInfo.ID}`;
          } else {
            templateID = template.ID;
          }
        }

        return (
          <div
            key={"sections_" + index}
            onClick={() => setSelectedSection(section)}
            className={
              !deleteMode
                ? section.ID === selectedSection.ID
                  ? "section selected"
                  : "section"
                : selectedIDs.indexOf(section.ID) > -1
                  ? "section selected"
                  : "section"
            }
          >
            <img
              className={"section_thumbnail"}
              src={`/api/img?tmp=${templateID}&v=${template.UpdatedAt}${queryString}`}
            />
            <div className={"section_name"}>
              {section[props.tabKey] ?? section["SectionName"]}
              {section.isCustom && deleteMode && (
                <input
                  type="checkbox"
                  checked={selectedIDs.indexOf(section.ID) > -1}
                  onClick={(e) => handleSelect(e, section.ID)}
                />
              )}
            </div>
          </div>
        );
      });
    }
  };

  const conditionalAdd = () => {
    setDisabled(true);
    if (props.businessPlan) {
      if (selectedCategory === "Tables") {
        props.addNewObjectFuncs.handleOnNewObject(
          new SlideObject(
            SlideTypes.financeTable,
            {
              dateType: tableDateType[type],
              reportType: ReportsTypes[key],
              tableSize: ReportsTableSizes[r].size,
            },
            { width: 600, height: 600, top: 480, left: 100 },
          ),
        );
        handleClose();
      } else if (selectedCategory === "Charts") {
        props.addNewObjectFuncs.handleOnNewObject(
          new SlideObject(SlideTypes.financialChart, null, {
            ID: selectedSection,
            width: 600,
            height: 600,
            top: 480,
            left: 100,
          }),
        );
        handleClose();
      } else {
        addSection();
      }
    } else {
      addSection();
    }
  };

  return (
    <React.Fragment>
      <ButtonMui
        variant={"contained"}
        disabled={props.disableEdit}
        label={props.label ? props.label : "Add Slide"}
        onClick={() => {
          handleClickOpen();
        }}
        endIcon={<IconPlus />}
      />
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={open}
        scroll={"paper"}
        className={"section_dialog"}
        onClose={handleClose}
      >
        <AddDialogTitle
          title={props.businessPlan ? "Select Section" : "Select Slide"}
          onClose={() => handleClose()}
        />

        <div className={"dialog_content"}>
          <TabsMenu
            onChangeTab={handleTabChange}
            categories={props.categories}
            sections={sections}
            customTabName={props.businessPlan ? "My Sections" : "My Slides"}
            businessPlan={props.businessPlan}
          />
          <div className={"dialog_divider"} />
        </div>

        <DialogContent>
          <div className={"dialog_content"}>
            <div className={"sections_container"}>
              {getSections()}
              {!props.businessPlan && (
                <div
                  key={"sections_blank"}
                  onClick={() => setSelectedSection("blank")}
                  className={selectedSection === "blank" ? "section selected" : "section"}
                >
                  <div className={"section_name"}>{"Add blank slide"}</div>
                </div>
              )}
            </div>
          </div>
        </DialogContent>

        <DialogActions disableSpacing={true}>
          {props.isAdmin ? (
            <div>
              {selectedCategory !== "Tables" &&
                selectedCategory !== "Charts" &&
                selectedSection !== "blank" && (
                  <IconButton size="medium" className={"trash_can"} onClick={handleDelete}>
                    <TrashCan />
                  </IconButton>
                )}
              {selectedCategory !== "Tables" &&
                selectedCategory !== "Charts" &&
                selectedSection !== "blank" && (
                  <AdminAddSectionDialog
                    addNewSection={handleAddSection}
                    businessPlan={props.businessPlan}
                    text={"Add new section"}
                  />
                )}
              {selectedCategory !== "Tables" &&
                selectedCategory !== "Charts" &&
                selectedSection !== "blank" && (
                  <AdminUpdateSectionDialog
                    name={selectedSection[props.tabKey] ?? selectedSection["SectionName"]}
                    updateSection={handleUpdateSection}
                  />
                )}
            </div>
          ) : props.businessPlan ? (
            <div>
              {selectedCategory === "Custom" && !deleteMode && (
                <Tooltip title={"Delete sections"}>
                  <IconButton size="medium" className={"trash_can"} onClick={handleDeleteMode}>
                    <TrashCan />
                  </IconButton>
                </Tooltip>
              )}
              {
                <AdminAddSectionDialog
                  text={"Create new section"}
                  subText={"The created section will be stored in My sections"}
                  businessPlan={true}
                  addNewSection={handleAddSection}
                />
              }
            </div>
          ) : (
            <div>
              {selectedCategory === "Custom" && !deleteMode && (
                <Tooltip title={"Delete Slides"}>
                  <IconButton size="medium" className={"trash_can"} onClick={handleDeleteMode}>
                    <TrashCan />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          )}

          <div style={{ display: "flex", justifyContent: "space-between", columnGap: 20 }}>
            <ButtonMui
              width={136}
              style={{ height: 42 }}
              label={"Cancel"}
              variant={"text"}
              onClick={deleteMode ? handleDeleteMode : handleClose}
              color={"black"}
            />
            {!deleteMode && (
              <ButtonMui
                width={136}
                style={{ height: 42 }}
                disabled={disabled}
                label={props.businessPlan ? "Add Section" : "Add Slide"}
                variant={"contained"}
                onClick={() => conditionalAdd()}
                color={"primary"}
              />
            )}
            {deleteMode && (
              <ButtonMui
                width={136}
                style={{ height: 42 }}
                label={"Delete"}
                endIcon={<TrashCan />}
                variant={"text"}
                onClick={handleDeleteSuccess}
                color={"red"}
              />
            )}
          </div>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
