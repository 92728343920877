import React, { useState, useRef } from "react";
import { ControlledMenu, SubMenu, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import ChartSketchPicker from "./ChartSketchPicker";
import { Theme } from "../../../theme/styles/theme";
import CircleCheckboxM from "../../actions/CircleCheckboxM";
const colors = Theme.colors.chart;
const ListAllColors = (props) => {
  if (props.mostUsedColors) {
    return (
      <div className="main_chart_seris_container main_chart_series_most_used_colors">
        {Object.entries(colors).map(([key, value]) => {
          return (
            <div className="chart_series_row">
              {Object.entries(value).map(([colKey, value]) => {
                if (colKey == 400) {
                  return (
                    <span
                      onClick={() => props.chooseColor(key)}
                      style={{
                        cursor: "pointer",
                        borderRadius: "50%",
                        border: "2px solid #A2A2A2",
                        width: "0.9rem",
                        height: "0.9rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: value,
                        marginTop: "2px",
                        marginBottom: "2px",
                      }}
                    >
                      {""}
                    </span>
                  );
                }
              })}
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className="main_chart_seris_container">
      {Object.entries(colors).map(([key, value]) => {
        return (
          <div className="chart_series_row">
            {Object.entries(value).map(([colKey, value]) => {
              return (
                <span
                  onClick={() => props.chooseColor(value, [key, colKey])}
                  style={{
                    cursor: "pointer",
                    borderRadius: "50%",
                    border: "2px solid #A2A2A2",
                    width: "0.9rem",
                    height: "0.9rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: value,
                    marginTop: "2px",
                    marginBottom: "2px",
                  }}
                >
                  {""}
                </span>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

const ColumnColorShades = (props) => {
  return (
    <SubMenu label={`Column Color Shades`} onMouseDown={(e) => e.preventDefault()}>
      {props.chartLabels.map((s, index) => {
        return (
          <SubMenu
            className="scccc_chart_color_shades_menu"
            label={s}
            onMouseDown={(e) => e.preventDefault()}
          >
            <ListAllColors
              mostUsedColors
              chooseColor={(color, key) => {
                // props.setColor(color)
                // props.setState('closed')
                // console.log('color chartLabels',color, index);
                props.onSaveBarColorKeys(color, index);
              }}
            />
          </SubMenu>
        );
      })}
    </SubMenu>
  );
};

const SeriesColor = (props) => {
  const [color, setColor] = useState(null);
  return (
    <SubMenu label={`Series Color`} onMouseDown={(e) => e.preventDefault()}>
      {props.series.map((s, index) => {
        return (
          <SubMenu
            className="scccc_chart_color_shades_menu"
            label={s.label}
            onMouseDown={(e) => e.preventDefault()}
          >
            <SubMenu label={"Select Custom Color"} key={color}>
              <ChartSketchPicker
                chooseColor={(color) => {
                  setColor(color);
                  props.onSaveBarColorKeys(color, index);
                }}
              />
            </SubMenu>
            <ListAllColors
              chooseColor={(color, key) => {
                setColor(color);
                props.onSaveBarColorKeys(color, index);
              }}
            />
            {/*{s.backgroundColor.map((color, seriesIndex) => {*/}
            {/*return<SubMenu className='scccc_chart_color_shades_menu' label={props.chartLabels[seriesIndex]} onMouseDown={e => e.preventDefault()}>*/}
            {/*<SubMenu label={'Select Custom Color'} key={color}>*/}
            {/*<ChartSketchPicker chooseColor={(color) => {*/}
            {/*setColor(color)*/}
            {/*props.onSaveBarColorKeys(color,index,seriesIndex, true)*/}

            {/*}}/>*/}
            {/*</SubMenu>*/}
            {/*<ListAllColors chooseColor={(color,key) => {*/}
            {/*setColor(color)*/}
            {/*props.onSaveBarColorKeys(color,index,seriesIndex, true)*/}
            {/*}}/>*/}
            {/*</SubMenu>*/}
            {/*})}*/}
          </SubMenu>
        );
      })}
    </SubMenu>
  );
};
export default function ChartSelectSeries(props) {
  const ref = useRef(null);
  const [state, setState] = useState();
  const [color, setColor] = useState(null);
  const [series, setSeries] = useState(null);
  let counter = 0;
  return (
    <>
      <MenuItem onClick={(e) => (e.keepOpen = true)}>
        <CircleCheckboxM
          label={"Color"}
          size="medium"
          checked={series}
          onChange={(e) => setSeries(!series)}
        />
      </MenuItem>
      {series && (
        <div className="sccc_main_container_chart_colors">
          {/*<ColumnColorShades onSaveBarColorKeys={props.onSaveBarColorKeys} {...props} setState={setState}  />*/}
          <SeriesColor
            {...props}
            onSaveBarColorKeys={props.onSaveBarColorKeys}
            setState={setState}
          />
          <MenuItem onClick={(e) => props.onResetColor()}>Reset Color to default</MenuItem>
        </div>
      )}
    </>
  );
}
