import React from "react";
import { FirstSection } from "../FirstSection";
import { ButtonsWrapper } from "../ButtonsWrapper";
import MaterialSelect from "./../../../../../components/actions/SelectM";
import { IndustryTypes } from "../../../../../components/constants/industries";
import ButtonMui from "../../../../../components/buttons/buttonMui/buttonMui";
import { ArrowLeft, ArrowRight } from "../../../../../components/icons/svgIcons";

function HalwWidthWrapper(props) {
  return (
    <div
      style={{
        width: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...props.style,
      }}
    >
      {props.children}
    </div>
  );
}

export default function NewStep2(props) {
  return (
    <>
      <div className=" p title strong center">
        Tell us about your company, so we can customize Modeliks for you.
      </div>

      <FirstSection
        style={{
          paddingTop: "20px",
          flexDirection: "row",
          alignItems: "center",
          columnGap: "20px",
        }}
      >
        <HalwWidthWrapper style={{ justifyContent: "flex-end" }}>
          <div className="p p2 strong spsocwn2_title">Select industry</div>
        </HalwWidthWrapper>
        <HalwWidthWrapper style={{ justifyContent: "flex-start" }}>
          <MaterialSelect
            label=""
            width="220px"
            value={props.company.Industry}
            options={Object.values(IndustryTypes).map((c) => {
              return {
                description: c,
                value: c,
              };
            })}
            onChange={(e) => props.setCompany("Industry", e.target.value)}
          />
        </HalwWidthWrapper>
      </FirstSection>
      <FirstSection
        style={{
          paddingTop: "20px",
          flexDirection: "row",
          alignItems: "center",
          columnGap: "20px",
        }}
      >
        <HalwWidthWrapper style={{ justifyContent: "flex-end" }}>
          <div className="p p2 strong spsocwn2_title">Select Company Size</div>
        </HalwWidthWrapper>
        <HalwWidthWrapper style={{ justifyContent: "flex-start" }}>
          <MaterialSelect
            label=""
            width="220px"
            value={props.company.Size}
            options={props.Sizes.map((c) => {
              return {
                description: c,
                value: c,
              };
            })}
            onChange={(e) => props.setCompany("Size", e.target.value)}
          />
        </HalwWidthWrapper>
      </FirstSection>
      <FirstSection
        style={{
          paddingTop: "20px",
          flexDirection: "row",
          alignItems: "center",
          columnGap: "20px",
        }}
      >
        <HalwWidthWrapper style={{ justifyContent: "flex-end" }}>
          <div className="p p2 strong spsocwn2_title">Select Company Stage</div>
        </HalwWidthWrapper>
        <HalwWidthWrapper style={{ justifyContent: "flex-start" }}>
          <MaterialSelect
            label=""
            width="220px"
            value={props.company.Stage}
            options={props.Stages.map((c) => {
              return {
                description: c,
                value: c,
              };
            })}
            onChange={(e) => props.setCompany("Stage", e.target.value)}
          />
        </HalwWidthWrapper>
      </FirstSection>
      <ButtonsWrapper>
        <div className="o_btn_container">
          <ButtonMui
            startIcon={<ArrowLeft />}
            label={"Back"}
            width={120}
            variant={"contained"}
            onClick={props.BackStep}
          />
        </div>
        <div className="o_btn_container start">
          <ButtonMui
            label={"Next"}
            width={120}
            variant={"contained"}
            endIcon={<ArrowRight />}
            onClick={props.NextStep}
          />
        </div>
      </ButtonsWrapper>
    </>
  );
}
