import * as React from "react";
import { Routes, Route, Outlet, Link, Navigate } from "react-router-dom";
import { NavigationF } from "../../../components/containers/NavigationF";
import { Box } from "../../../components/containers/Box";
import { Vector } from "../../../components/dividers/Vector";
import SubTabs from "../../../components/tabs/SubTabs";
import "../Financials/Financials.scss";
import {
  ComparisonPeriod,
  ReportFrequency,
  StreamTypes,
} from "../../../components/constants/charts";
import CircleLoader from "../../../components/Loaders/CircleLoader/CircleLoader";
import DashboardsBlank from "./components/DashboardsBlank";
import FinancialStatements from "./FinancialStatements";
import BalanceSheet from "../../../data/Finance/Reports/BalanceSheet";
import ProfitLoss from "../../../data/Finance/Reports/ProfitLoss";
import CashFlow from "../../../data/Finance/Reports/CashFlow";
import FinancialStatementsReport from "../../../data/Finance/Dashboards/FinancialStatements";
import CustomKPIMain from "./CustomKPI/index";
import ActualsStatements from "./Actuals";
import ManagementReports from "./ManagementReports";
import CustomDashboards from "./CustomDashboards";
import {
  ReusableMenu,
  ReusableMenuItem,
} from "../../../components/menus/ReusableMenu/ReusableMenu";
import DashboardsDropDownMenu from "./components/DashboardsDropDownMenu";
import {Button as MuiButton} from "@mui/material";
import InstructionalPopup from "../../../components/dialogs/instructionsPopup/instructionalPopup";
import {MenuItem} from "@szhsin/react-menu";
import {InfoIcon, PlayIcon} from "../../../components/icons/svgIcons";
import ButtonMui from "../../../components/buttons/buttonMui/buttonMui";
import {AccessControl} from "../../../data/Permissions/AccessControl";
import NoAccessDashboards from "./dashboard_noaccess.png";
import Revenue from "../Financials/Revenue";
import ReportExport from "../../../helpers/ReportExport";
import Reports from "../../../data/Finance/Reports";
import {ReportsTypes} from "../../../data/Finance/Reports/constants";
import {CommandManagerProvider} from "../../../SlideEditor/services/commands/CommandManagerProvider"; // Tell webpack this JS file uses this image

const layout_side = "/dashboards";

const dashboards_paths = {
  financial_statements: "/financial_statements",
  charts: "/charts",
  single_indicators: "/single_indicators",
  ratios: "/ratios",
  custom_kpi: "/custom_kpi",
  custom_dashboards: "/custom_dashboards",
  actuals: "/actuals",
  management_reports: "/management_reports",
};

const actuals_paths = {
  profit_loss: "/profitloss",
  balance_sheet: "/balancesheet",
  cash_flow: "/cahsflow",
};
const layout_sides = ["/forecast", "/actuals", "/dashboards"];

const key = "dashboards";

const reportTypes = {
  ProfitAndLoss: "ProfitAndLoss",
  BalanceSheet: "BalanceSheet",
  CashFlow: "CashFlow",
  EmployeeSchedule: "EmployeeSchedule",
};

export const ReportRoutes = {
  [reportTypes.ProfitAndLoss]: "profit_and_loss",
  [reportTypes.BalanceSheet]: "balance_sheet",
  [reportTypes.CashFlow]: "cash_flow",
  [reportTypes.EmployeeSchedule]: "employee_schedule",
};
export const RouteReports = {
  [ReportRoutes[reportTypes.ProfitAndLoss]]: reportTypes.ProfitAndLoss,
  [ReportRoutes[reportTypes.BalanceSheet]]: reportTypes.BalanceSheet,
  [ReportRoutes[reportTypes.CashFlow]]: reportTypes.CashFlow,
  [ReportRoutes[reportTypes.EmployeeSchedule]]: reportTypes.EmployeeSchedule,
};

export default class Dashboards extends React.Component {
  Dashboard = global.Modeliks.DashboardSection;
  DashboardSettings = global.Modeliks.DashboardSettings.Value;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadingTime: 100,
    };
    if (global.Modeliks.CompanyInfo) {
      this.allMonths = [
        ...global.Modeliks.DateHelper.months_before_actual,
        ...global.Modeliks.DateHelper.months,
      ];
      this.allYears = [
        ...global.Modeliks.DateHelper.years_before_actual,
        ...global.Modeliks.DateHelper.gridPeriodHeaders_Years(),
      ];
      this.allYearsActual = [
        ...global.Modeliks.DateHelper.years_before_actual,
        ...global.Modeliks.DateHelper.gridPeriodHeaders_Years(),
      ];
      this.allMonthsActual = [
        ...global.Modeliks.DateHelper.months_before_actual,
        ...global.Modeliks.DateHelper.months,
      ];
      this.financialDates = { ...this.DashboardSettings.financialDates };
      this.actualDates = { ...this.DashboardSettings.actualDates };
      this.curCharts = {};
      this.showObj = {
        openingBalances: false,
      };
    }
    this.setReports = this.getReportsFunc();
    this.dashboardsSectionsObject = this.getDashboardsSectionsObjectFunc();
  }

  getDashboardsSectionsObjectFunc = () => {
    const { DateHelper, CompanyInfo } = global.Modeliks;
    return {
      DashboardSections: {
        FinancialStatements: {
          selectedSubsection: null,
          name: "Financial Statements",
          showMonthly: true,
          exportAllReports: (periods, isTmpData = false, title) =>
            this.setReports["AllReportsExport"](periods, isTmpData, title),
          exportReport: (key, periods, isTmpData = false, title) =>
            this.setReports[
              this.dashboardsSectionsObject.DashboardSections.FinancialStatements.selectedSubsection
                .key
            ](false, periods, isTmpData, title),
          headers: {
            quarterly:
              CompanyInfo &&
              DateHelper.months_quarterly_view.filter(
                (m) => m.Order <= this.allMonthsActual[this.allMonthsActual.length - 1].Order,
              ),
            comparative: CompanyInfo && DateHelper.months_comaprative,
            months: CompanyInfo && [...DateHelper.months_before_actual, ...DateHelper.months],
            years: CompanyInfo && [DateHelper.years_before_actual[2], ...DateHelper.years_before],
            allYears: CompanyInfo && [...DateHelper.years_before_actual, ...DateHelper.years_all],
          },
          subsections: {
            ProfitAndLoss: {
              key: "ProfitAndLoss",
              name: "Profit And Loss",
              shortName: "PL",
              gridRows: this.setReports.ProfitAndLoss(),
              ActualGridRows: null,
            },
            BalanceSheet: {
              key: "BalanceSheet",
              name: "Balance Sheet",
              shortName: "BS",
              gridRows: this.setReports.BalanceSheet(),
              ActualGridRows: null,
            },
            CashFlow: {
              key: "CashFlow",
              name: "Cash Flow",
              shortName: "CF",
              gridRows: this.setReports.CashFlow(),
              ActualGridRows: null,
              openingBalances: false,
            },
            EmployeeSchedule: {
              key: "EmployeeSchedule",
              name: "Employee Schedule",
              shortName: "CF",
              gridRows: null,
              ActualGridRows: null,
              openingBalances: false,
            },
          },
        },
        Charts: {
          name: "Charts",
          subsections: {
            FinancialSummary: {
              name: "Financial Summary",
              charts: {},
            },
            ProfitAndLoss: {
              name: "Profit And Loss",
              charts: {},
            },
            BalanceSheet: {
              name: "Balance Sheet",
              charts: {},
            },
            CashFlow: {
              name: "Cash Flow",
              charts: {},
            },
          },
        },
        SingleIndicators: {
          name: "Single Indicators",
          subsections: {
            Indicator: {
              name: "Indicator",
              charts: {},
            },
          },
        },
        Ratios: {
          name: "Ratios",
          subsections: {
            ProfitabilityRatios: {
              name: "Profitability Ratios",
              charts: {},
            },
            LiquidityRatios: {
              name: "Liquidity Ratios",
              charts: {},
            },
            LeverageRatios: {
              name: "Leverage Ratios",
              charts: {},
            },
            EfficiencyRatios: {
              name: "Efficiency Ratios",
              charts: {},
            },
          },
        },
        CustomKPI: {
          name: "Custom KPI",
          allCustomKPI: global.Modeliks.CustomKPIStore,
          selectedKPI:
            global.Modeliks.CustomKPIStore.length > 0 ? global.Modeliks.CustomKPIStore[0] : null,
          dataGrid:
            global.Modeliks.CustomKPIStore.length > 0
              ? global.Modeliks.CustomKPIStore[0].data.dataGrid
              : null,
        },
      },
      SecondComparisonPeriod: ComparisonPeriod.forecast,
    };
  };

  getReportsFunc = () => {
    return {
      [reportTypes.ProfitAndLoss]: (setGridRows = true, periods, isTmpData, title) => {
        if (setGridRows) {
          const ProfitLoss = global.Modeliks.ReportsStore.find(
            (d) => d.ReportType === ReportsTypes.ProfitLoss,
          );
          ProfitLoss?.data.changeDates(this.getAllDates());
          return ProfitLoss?.data.dataGrid;
        }

        return ReportExport.excelSingleReport(
          this.setReports.ProfitAndLoss(true, periods, isTmpData),
          "Profit & Loss",
          periods,
          "Actual",
          isTmpData,
          periods[0] === global.Modeliks.DateHelper.months_comaprative[0],
        );
      },
      [reportTypes.BalanceSheet]: (setGridRows = true, periods, isTmpData, title) => {
        if (setGridRows) {
          const BalanceSheet = global.Modeliks.ReportsStore.find(
            (d) => d.ReportType === ReportsTypes.BalanceSheet,
          );
          BalanceSheet?.data.changeDates(this.getAllDates());
          return BalanceSheet?.data.dataGrid;
        }
        return ReportExport.excelSingleReport(
          this.setReports.BalanceSheet(true, periods, isTmpData),
          "Balance Sheet",
          periods,
          "Actual",
          isTmpData,
          periods[0] === global.Modeliks.DateHelper.months_comaprative[0],
        );
      },
      [reportTypes.CashFlow]: (setGridRows = true, periods, isTmpData, title) => {
        if (setGridRows) {
          const CashFlow = global.Modeliks.ReportsStore.find(
            (d) => d.ReportType === ReportsTypes.CashFlow,
          );
          CashFlow?.data.changeDates(this.getAllDates());
          return CashFlow?.data.dataGrid;
        }
        return ReportExport.excelSingleReport(
          this.setReports.CashFlow(true, periods, isTmpData),
          "Cash Flow",
          periods,
          "Actual",
          isTmpData,
          periods[0] === global.Modeliks.DateHelper.months_comaprative[0],
        );
      },
      EmployeeSchedule: (setGridRows = true, periods, isTmpData, title) => {
        return {
          getGridData: false,
          isActual: false,
          data: null,
          isTmpData: isTmpData,
        };
      },
      AllReportsExport: (periods, isTmpData, title) => {
        return ReportExport.AllReports(
          periods,
          "Actual",
          isTmpData,
          [
            this.setReports.ProfitAndLoss(true, periods, isTmpData),
            this.setReports.BalanceSheet(true, periods, isTmpData),
            this.setReports.CashFlow(true, periods, isTmpData),
          ],
          periods[0] === global.Modeliks.DateHelper.months_comaprative[0],
        );
      },
    };
  };

  resetDashboards = () => {
    this.setReports = this.getReportsFunc();
    this.dashboardsSectionsObject = this.getDashboardsSectionsObjectFunc();
  };

  getAllDates = (dates = this.financialDates) => {
    return {
      ...dates,
      allMonths: this.allMonths,
      allYears: this.allYears,
    };
  };

  saveDashboard = (changedKey = null) => {
    const newDateObject = {
      actualDates: this.actualDates,
      financialDates: this.financialDates,
    };
    global.Modeliks.DashboardSettings.Value = newDateObject;
    Object.entries(
      this.dashboardsSectionsObject.DashboardSections.FinancialStatements.subsections,
    ).forEach(([key, section]) => {
      if (key !== "EmployeeSchedule") {
        section.gridRows = this.setReports[section.key]();
      }
    });
    const { CustomKPI } = this.dashboardsSectionsObject.DashboardSections;
    if (CustomKPI.selectedKPI) {
      CustomKPI.selectedKPI.data.changeDates(this.getAllDates());
      CustomKPI.dataGrid = CustomKPI.selectedKPI.data.dataGrid;
    }

    if (changedKey == "numberFormatType") {
      if (global.Modeliks.ChartSettings) {
        let parsedValue = JSON.parse(global.Modeliks.ChartSettings.Value);
        let keys = Object.keys(parsedValue);
        keys.forEach((key) => {
          if (parsedValue[key]["numberFormat"]) {
            delete parsedValue[key]["numberFormat"];
          }
        });
        global.Modeliks.ChartSettings.Value = JSON.stringify(parsedValue);
        global.Modeliks.ChartSettings.Save && global.Modeliks.ChartSettings.Save();
      }
    }

    global.Modeliks.put(
      "settings_client",
      {
        Key: "DashboardSettings",
        CompanyID: global.Modeliks.CompanyInfo.ID,
      },
      { Value: JSON.stringify(newDateObject) },
      (success, err) => {
        if (success) {
          this.setState({ loading: false });
        }

        if (!success) {
          console.error("error", err);
        }
      },
    );
  };

  saveDashboardSettings = (key) => {
    if (global.Modeliks.PERMISSIONS.Dashboards.restrictions.edit.active) {
      this.setState({ loading: true }, () => this.saveDashboard(key));
    }
  };

  getFinancialStatements = () =>
    this.dashboardsSectionsObject.DashboardSections.FinancialStatements;

  setFinancialStatements = () => {
    Object.entries(
      this.dashboardsSectionsObject.DashboardSections.FinancialStatements.subsections,
    ).forEach((sub) => (sub[1].gridRows = this.setReports[sub[0]]()));
  };

  getDashboardPathElements = () => {
    const dashboardProps = {
      saveDashboard: this.SaveDashboardSections,
      data: this.Dashboard.Value.DashboardSections,
      curMonths: this.financialDates,
      onChartEdit: null,
      curCharts: this.curCharts,
      saveSettings: this.saveDashboardSettings,
      showMonthly: true,
    };

    return {
      routes: [
        {
          path: dashboards_paths.financial_statements + "/:type",
          element: (
            <FinancialStatements
              defaultView={this.props.defaultView}
              defaultSelectedReport={this.props.defaultSelectedReport}
              dataKey={"FinancialStatements"}
              saveDates={this.saveDashboardSettings}
              setReports={this.setReports}
              allMonths={this.allMonthsActual}
              allYears={this.allYearsActual}
              financialMonths={this.financialDates}
              FinancialStatements={
                this.dashboardsSectionsObject.DashboardSections.FinancialStatements
              }
              {...dashboardProps}
              key={"fs_" + this.props.ctrl_key}
            />
          ),
          index: true,
        },
        {
          path: dashboards_paths.financial_statements,
          element: (
            <FinancialStatements
              defaultView={this.props.defaultView}
              defaultSelectedReport={this.props.defaultSelectedReport}
              dataKey={"FinancialStatements"}
              saveDates={this.saveDashboardSettings}
              setReports={this.setReports}
              allMonths={this.allMonthsActual}
              allYears={this.allYearsActual}
              financialMonths={this.financialDates}
              FinancialStatements={
                this.dashboardsSectionsObject.DashboardSections.FinancialStatements
              }
              {...dashboardProps}
              key={"fs_2" + this.props.ctrl_key}
            />
          ),
        },
        {
          path: dashboards_paths.charts,
          element: (
            <DashboardsBlank
              dataKey={"Charts"}
              {...{ ...dashboardProps, titleButton: true }}
              key={"cc_" + this.props.ctrl_key}
            />
          ),
        },
        {
          path: dashboards_paths.single_indicators,
          element: (
            <DashboardsBlank
              unitChanger
              dataKey={"SingleIndicators"}
              {...dashboardProps}
              key={"sic_" + this.props.ctrl_key}
            />
          ),
        },
        {
          path: dashboards_paths.ratios,
          element: (
            <DashboardsBlank
              dataKey={"Ratios"}
              {...{ ...dashboardProps, titleButton: true }}
              key={"rc_" + this.props.ctrl_key}
            />
          ),
        },
        {
          path: dashboards_paths.custom_kpi,
          element: (
            <CustomKPIMain
              setFinancialStatements={this.setFinancialStatements}
              defaultView={this.props.defaultView}
              dataKey={"CustomKPI"}
              saveDates={this.saveDashboardSettings}
              handleStreamLoad={this.handleStreamLoad}
              CustomKPI={this.dashboardsSectionsObject.DashboardSections.CustomKPI}
              setReports={this.setReports}
              allMonths={this.allMonths}
              allYears={this.allYears}
              financialMonths={this.financialDates}
              FinancialStatements={
                this.dashboardsSectionsObject.DashboardSections.FinancialStatements
              }
              {...dashboardProps}
              key={"c_kpi_" + this.props.ctrl_key}
            />
          ),
        },
        {
          path: dashboards_paths.actuals + "/:type",
          element: (
            <ActualsStatements
              showObj={this.showObj}
              defaultSelectedReport={this.props.defaultSelectedReport}
              dataKey={"Actual"}
              saveActualDates={this.saveDashboardSettings}
              setReports={this.setReports}
              allMonths={this.allMonthsActual}
              allYears={this.allYearsActual}
              financialMonths={this.actualDates}
              FinancialStatements={
                this.dashboardsSectionsObject.DashboardSections.FinancialStatements
              }
              {...dashboardProps}
              key={"act_" + this.props.ctrl_key}
            />
          ),
        },
        {
          path: dashboards_paths.actuals,
          exact: true,
          element: (
            <ActualsStatements
              showObj={this.showObj}
              defaultSelectedReport={this.props.defaultSelectedReport}
              dataKey={"Actual"}
              saveActualDates={this.saveDashboardSettings}
              setReports={this.setReports}
              allMonths={this.allMonthsActual}
              allYears={this.allYearsActual}
              financialMonths={this.actualDates}
              FinancialStatements={
                this.dashboardsSectionsObject.DashboardSections.FinancialStatements
              }
              {...dashboardProps}
              key={"act_2" + this.props.ctrl_key}
            />
          ),
        },
        // { path: dashboards_paths.actuals ,element: <ActualsStatements dataKey={'Actual'} saveActualDates={this.saveDashboardSettings} setReports={this.setReports} allMonths={this.allMonths} allYears={this.allYears}  financialMonths={this.actualDates} FinancialStatements={this.dashboardsSectionsObject.DashboardSections.FinancialStatements}  {...dashboardProps} key={'act_' + this.props.ctrl_key}/>,},
        {
          path: dashboards_paths.custom_dashboards,
          element: (
            <CustomDashboards
              allMonths={this.allMonthsActual}
              customDashboard={true}
              allYears={this.allYearsActual}
              financialMonths={this.financialDates}
              FinancialStatements={
                this.dashboardsSectionsObject.DashboardSections.FinancialStatements
              }
              defaultView={this.props.defaultView}
              defaultSelectedReport={this.props.defaultSelectedReport}
              {...dashboardProps}
              key={"cd_" + this.props.ctrl_key}
            />
          ),
        },
        {
          path: dashboards_paths.management_reports,
          element: (
              <CommandManagerProvider>
                <ManagementReports
                    allMonths={this.allMonthsActual}
                    customDashboard={true}
                    allYears={this.allYearsActual}
                    financialMonths={this.financialDates}
                    FinancialStatements={
                      this.dashboardsSectionsObject.DashboardSections.FinancialStatements
                    }
                    getFinancialStatements={this.getFinancialStatements}
                    resetDashboards={this.resetDashboards}
                    defaultView={this.props.defaultView}
                    defaultSelectedReport={this.props.defaultSelectedReport}
                    {...dashboardProps}
                    key={"mr_" + this.props.ctrl_key}
                />
              </CommandManagerProvider>
          ),
        },
      ],
      tabs: [
        {
          link: `${layout_side}${dashboards_paths.actuals}`,
          color: "black",
          size: "p3",
          label: "Actuals",
          className: "spsd_colored_tab",
          key,
        },
        {
          link: `${layout_side}${dashboards_paths.financial_statements}`,
          color: "black",
          size: "p3",
          label: "Financial Statements",
          key,
        },
        {
          link: `${layout_side}${dashboards_paths.charts}`,
          color: "black",
          size: "p3",
          label: "Charts",
          key,
        },
        {
          link: `${layout_side}${dashboards_paths.single_indicators}`,
          color: "black",
          size: "p3",
          label: "Single Indicators",
          key,
        },
        {
          link: `${layout_side}${dashboards_paths.ratios}`,
          color: "black",
          size: "p3",
          label: "Ratios",
          key,
        },
        {
          link: `${layout_side}${dashboards_paths.custom_kpi}`,
          color: "black",
          size: "p3",
          label: "Custom KPIs",
          key,
        },
        {
          link: `${layout_side}${dashboards_paths.custom_dashboards}`,
          color: "black",
          size: "p3",
          label: "Custom Dashboards",
          key,
        },
        {
          link: `${layout_side}${dashboards_paths.management_reports}`,
          color: "black",
          size: "p3",
          label: BetaLabel("Management Reports"),
          className: "mr_btn",
          key,
        },
      ],
    };
  };

  getRenderedDashboardRoutes = () => {
    return this.getDashboardPathElements().routes.map((route) => (
      <Route
        index={route.index}
        key={`f_route_${route.path}`}
        exact
        path={route.path}
        element={route.element}
      />
    ));
  };

  handleStreamLoad = (value, callBack) => {
    this.setState({ loading: value }, callBack && callBack());
  };

  render() {
    if (this.state.loading) {
      return (
        <div className={"main_circle_loader_container"}>
          <CircleLoader />
        </div>
      );
    }

    if (this.props.publishManagementReports) {
      const dashboardProps = {
        saveDashboard: this.SaveDashboardSections,
        data: this.Dashboard.Value.DashboardSections,
        curMonths: this.financialDates,
        onChartEdit: null,
        curCharts: this.curCharts,
        saveSettings: this.saveDashboardSettings,
        showMonthly: true,
      };
      return (
        <ManagementReports
          allMonths={this.allMonthsActual}
          customDashboard={true}
          allYears={this.allYearsActual}
          financialMonths={this.financialDates}
          FinancialStatements={this.dashboardsSectionsObject.DashboardSections.FinancialStatements}
          getFinancialStatements={this.getFinancialStatements}
          defaultView={this.props.defaultView}
          publishManagementReports
          resetDashboards={this.resetDashboards}
          defaultSelectedReport={this.props.defaultSelectedReport}
          {...dashboardProps}
          key={"mr_" + this.props.ctrl_key}
        />
      );
    }

    return (
      <Routes>
        <Route
          path="/"
          element={
            <DashboardsLayout
              defaultUrl={this.props.defaultUrl}
              handleStreamLoad={this.handleStreamLoad}
              dashboardsElements={this.getDashboardPathElements()}
            />
          }
        >
          {this.getRenderedDashboardRoutes()}
          <Route index element={<Navigate to={"/dashboards/financial_statements"} />} />
          <Route path={"/*"} element={<Navigate to={"/dashboards/financial_statements"} />} />
        </Route>
      </Routes>
    );
  }
}
const BetaLabel = (label) => (
  <div className={"beta_btn"}>
    <span>{label}</span>
    <div className={"beta_item"}>
      <div>Beta</div>
    </div>
  </div>
);

function DashboardsLayout(props) {
  return (
    <div className={"grid_container"}>
      <AccessControl
        allowedPermissions={[
          {
            [global.Modeliks.PERMISSIONS.Dashboards.key]:
              global.Modeliks.PERMISSIONS.Financials.restrictions.overAll.key,
          },
        ]}
        renderNoAccess={
          <img
            style={{ width: "100%", height: "screen" }}
            src={NoAccessDashboards}
            alt="No access"
          />
        }
      >
        <NavigationF hidden={props.loading}>
          <Box className="spsi_financials_navigation_streams_div apply_hidden">
            <SubTabs defaultUrl={props.defaultUrl} mainPath={`${layout_side}`}>
              {props.dashboardsElements}
            </SubTabs>
          </Box>
          <DashboardsDropDownMenu
            className="financials_navigation_dropdown"
            menuItems={props.dashboardsElements}
            menuElementItems={[
              <MenuItem
                className={"mr_menu_btn"}
                key={"menuElementsItems_1"}
                style={{ fontSize: "14px" }}
              >
                {BetaLabel("Management Reports")}
              </MenuItem>,
              <InstructionalPopup
                key={"menuElementsItems_2"}
                actionButton={<MenuItem style={{ fontSize: "14px" }}>Videos</MenuItem>}
                data_key={"video_links"}
              />,
              <InstructionalPopup
                key={"menuElementsItems_3"}
                actionButton={<MenuItem style={{ fontSize: "14px" }}>Instructions</MenuItem>}
                data_key={"instructions_contents"}
              />,
            ]}
            // menuElementItems={[<InstructionalPopup actionButton={<MenuItem style={{fontSize: '14px'}}>Videos</MenuItem>} data_key={'video_links'}/>]}
          />
          <div id="export_portal" />
          <div className="instruction_container instruction_button">
            <InstructionalPopup
              actionButton={
                <ButtonMui
                  width={130}
                  label={"Videos"}
                  variant={"text"}
                  endIcon={<PlayIcon />}
                  color={"secondary"}
                />
              }
              data_key={"video_links"}
            />
            <InstructionalPopup
              actionButton={
                <ButtonMui
                  width={150}
                  label={"Instructions"}
                  variant={"text"}
                  endIcon={<InfoIcon />}
                  color={"secondary"}
                />
              }
              data_key={"instructions_contents"}
            />
          </div>
        </NavigationF>
        <Outlet />
      </AccessControl>
    </div>
  );
}
