import React from "react";
import CircleLoader from "../../../../components/Loaders/CircleLoader/CircleLoader";
import { MainHeader, ValContainer, MainAdd } from "../components/ValuationContainer";
import CompanyTable from "../components/table/CompanyTable";
import TradingMultiples from "../../../../data/Finance/Valuation/TradingMultiples";

const Loader = (
  <div className={"main_circle_loader_container"}>
    <CircleLoader />
  </div>
);

export default class TradingMultiplesMain extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      key: new Date().getTime(),
      loading: true,
    };

    this.gridRows = TradingMultiples.getTradingMultiples();
    TradingMultiples.tmpTotalsTradingMultiples((tmp) => (this.gridRowsTmp = tmp));
  }

  componentDidMount() {
    this.setState({ loading: false });
  }

  addNewTradingMultiple = () => {
    this.setState({ loading: true }, () => this.createTradingMultiple());
  };

  createTradingMultiple = () => {
    TradingMultiples.createNewTradingMultiples((newTrading) => {
      this.loadDrivers();
    });
  };

  loadDrivers = () => {
    global.Modeliks.LoadDrivers(() => {
      this.gridRows = TradingMultiples.getTradingMultiples();
      this.setState({ loading: false });
    });
  };

  updateValue = () => {
    this.setState({ key: new Date().getTime() });
  };

  setTmp = (callBack) => {
    TradingMultiples.tmpTotalsTradingMultiples((tmp) => {
      this.gridRowsTmp = tmp;
      this.forceUpdate();
      callBack && callBack();
    });
  };

  render() {
    if (this.state.loading) {
      return Loader;
    }

    return (
      <ValContainer key={this.state.key}>
        <MainHeader>Trading Multiples</MainHeader>
        {/*<MainAdd onClick={this.addNewTradingMultiple} />*/}
        <CompanyTable
          onMainAdd={this.addNewTradingMultiple}
          updateValue={this.updateValue}
          setTmp={this.setTmp}
          gridRows={this.gridRows}
          gridRowsTmp={this.gridRowsTmp}
        />
        {/*table here finan with months*/}
      </ValContainer>
    );
  }
}
