import PropTypes from "prop-types";
import React, { PureComponent, useState } from "react";
import {
  centerToTL,
  degToRadian,
  getAngle,
  getLength,
  getNewStyle,
} from "../../../ResizableRect/utils";
import { getSideFromHypothenuse } from "../utils";
import StyledCallout from "../StyledCallout";

export default class CalloutRect extends PureComponent {
  constructor() {
    super();
    this.state = {
      rotate: false,
    };
  }

  static propTypes = {
    styles: PropTypes.object,
    rotatable: PropTypes.bool,
    moveHandle: PropTypes.bool,
    onResizeStart: PropTypes.func,
    onResize: PropTypes.func,
    onResizeEnd: PropTypes.func,
    onRotateStart: PropTypes.func,
    onRotate: PropTypes.func,
    onRotateEnd: PropTypes.func,
    parentRotateAngle: PropTypes.number,
    className: PropTypes.string,
  };

  setElementRef = (ref) => {
    this.$element = ref;
  };

  setParentElementRef = (ref) => {
    this.$parentElement = ref;
  };

  // Resize and Rotate
  startResize = (e) => {
    const scaledRect = document.getElementById("se_scale_panel").getBoundingClientRect();
    const offsetMouse = 1 / window.panelScale;
    if (e.button !== 0) return;
    // document.body.style.cursor = cursor
    const {
      styles: {
        position: { centerX, centerY },
        size: { width, height },
        transform: { rotateAngle: startAngle },
      },
    } = this.props;

    const { clientX, clientY } = e;
    const rect = { width, height, centerX, centerY };
    const parentRect = this.$parentElement.getBoundingClientRect();

    const center = {
      x: parentRect.left + parentRect.width / 2,
      y: parentRect.top + parentRect.height / 2,
    };
    const startVector = {
      x: clientX - center.x,
      y: clientY - center.y,
    };
    // const type = e.target.getAttribute('class').split(' ')[0]
    const type = "br";
    this.props.onRotateStart && this.props.onRotateStart();
    this.props.onResizeStart && this.props.onResizeStart();
    this._isMouseDown = true;
    const onMove = (e) => {
      if (!this.state.rotate) {
        this.setState({ rotate: true });
      }
      if (!this._isMouseDown) return; // patch: fix windows press win key during mouseup issue
      e.stopImmediatePropagation();
      const { clientX, clientY } = e;
      const deltaX = (clientX - center.x) * offsetMouse;
      const deltaY = (clientY - center.y) * offsetMouse;

      const rotateVector = {
        x: clientX - center.x,
        y: clientY - center.y,
      };
      const angle = getAngle(startVector, rotateVector);

      this.props.onResize(angle, startAngle, getLength(deltaX, deltaY));
    };

    const onUp = () => {
      if (this.state.rotate) {
        this.setState({ rotate: false });
      }
      document.body.style.cursor = "auto";
      document.removeEventListener("mousemove", onMove);
      document.removeEventListener("mouseup", onUp);
      if (!this._isMouseDown) return;
      this._isMouseDown = false;
      this.props.onResizeEnd && this.props.onResizeEnd();
    };
    document.addEventListener("mousemove", onMove);
    document.addEventListener("mouseup", onUp);
  };

  render() {
    const {
      styles: {
        position: { centerX, centerY },
        size: { width, height },
        transform: { rotateAngle },
        zIndex: zIndex,
      },
      rotatable,
      parentRotateAngle,
      onClick,
      className,
    } = this.props;

    const style = {
      width: "20%",
      height: Math.abs(height),
      left: "40%",
      top: centerY - Math.abs(height) / 2,
      zIndex: zIndex,
      position: "absolute",
      display: "flex",
      justifyContent: "center",
    };
    const parentStyle = {
      width: "100%",
      height: "100%",
      transform: `rotate(${rotateAngle}deg)`,
    };

    return (
      <div style={parentStyle} ref={this.setParentElementRef}>
        <StyledCallout
          ref={this.setElementRef}
          onClick={onClick}
          className={"callout_rect callout_single-resizer" + className}
          style={style}
        >
          {this.props.children}

          <div
            className={"circle" + className}
            onMouseDown={this.startResize}
            style={{ top: style.height - 5, left: "50%" }}
          />
        </StyledCallout>
      </div>
    );
  }
}
