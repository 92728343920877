import { RoutesPerPermission } from "../../components/constants/permissions";

export const SetPermissionsBySubscription = () => {
  if (
    global.Modeliks.subscriptionInfo &&
    global.Modeliks.subscriptionPlans &&
    global.Modeliks.subscriptionPlans.length > 0
  ) {
    const findSubscription = global.Modeliks.subscriptionPlans.find(
      (d) => d.ID === global.Modeliks.subscriptionInfo.SubscriptionID,
    );
    if (findSubscription) {
      const permissions = global.Modeliks.PERMISSIONS;
      if (findSubscription.billing_type === "free_trial") {
        permissions.Financials.restrictions.ExportExcel.active = false;
        permissions.Financials.restrictions.ExportPDF.active = false;
        permissions.Financials.restrictions.ScenarioManager.active = false;

        permissions.Pitch.restrictions.ShareLink.active = false;
        permissions.Pitch.restrictions.ExportPDF.active = false;

        permissions.BusinessPlan.restrictions.ShareLink.active = false;
        permissions.BusinessPlan.restrictions.ExportPDF.active = false;

        permissions.Dashboards.restrictions.ExportExcel.active = false;
        permissions.Dashboards.restrictions.ExportPDF.active = false;
        /// free user
      } else if (findSubscription.data_key === "Start") {
        permissions.Financials.restrictions.FinancialModelTemplates.active = false;
        permissions.Financials.restrictions.FinancialDriverFeature.active = false;
        permissions.Financials.restrictions.ScenarioManager.active = false;

        permissions.Dashboards.restrictions.overAll.active = false;
        permissions.Dashboards.restrictions.ExportExcel.active = false;
        permissions.Dashboards.restrictions.ExportPDF.active = false;
        permissions.Pitch.restrictions.Industries.active = false;
        //start
      } else if (findSubscription.data_key === "Fund") {
        permissions.Financials.restrictions.ScenarioManager.active = true;
        permissions.Dashboards.restrictions.overAll.active = false;
        permissions.Dashboards.restrictions.ExportExcel.active = false;
        permissions.Dashboards.restrictions.ExportPDF.active = false;
        //fund
      }

      if (
        global.Modeliks.Account.hasOwnProperty("ExtraStreams") &&
        global.Modeliks.Account.ExtraStreams
      ) {
        global.Modeliks.PERMISSIONS.Financials.restrictions.MaxStreamsCount.TotalRevenues = 120;
        global.Modeliks.PERMISSIONS.Financials.restrictions.MaxStreamsCount.TotalCostOfSales = 120;
        global.Modeliks.PERMISSIONS.Financials.restrictions.MaxStreamsCount.TotalExpenses = 40;
      }
    }
  }
};

export const SetPermissionsByUserPermissions = () => {
  if (global.Modeliks.UserPermissions) {
    console.log("global.Modeliks.UserPermissions", global.Modeliks.UserPermissions);
    const permissions = global.Modeliks.PERMISSIONS;
    const userPermissions = global.Modeliks.UserPermissions;

    Object.keys(permissions).forEach((permissionKey) => {
      if (userPermissions.hasOwnProperty(permissionKey)) {
        permissions[permissionKey].restrictions.view.active = true;
        addToRoutes(permissionKey);
      }
      if (userPermissions[permissionKey] > 0) {
        //is editor
        permissions[permissionKey].restrictions.edit.active = true;
      }
    });
  }
};

const addToRoutes = (permissionKey) => {
  const routeKeys = global.Modeliks.allowedRouteKeys;
  if (RoutesPerPermission.hasOwnProperty(permissionKey)) {
    if (routeKeys.indexOf(permissionKey) === -1) {
      routeKeys.push(permissionKey);
    }
  }
};

const checkPermissions = (userPermissions = [], allowedPermissions = []) => {
  if (allowedPermissions.length === 0) {
    return true;
  }

  return !allowedPermissions.some(
    (permission) =>
      global.Modeliks.PERMISSIONS[Object.keys(permission)[0]].restrictions[
        Object.values(permission)[0]
      ]?.active === false,
  );
  // return userPermissions.some(permission =>
  //     allowedPermissions.includes(permission)
  // );
};

// export const AllowedRoutes =

export const AccessControl = ({
  user,
  userPermissions,
  allowedPermissions,
  children,
  renderNoAccess = "",
  accessCheck,
  extraAccessData,
  customHasAccess,
}) => {
  let hasAccess;

  if (customHasAccess !== undefined) {
    hasAccess = customHasAccess;
  } else if (accessCheck) {
    hasAccess =
      accessCheck(extraAccessData, user) && checkPermissions(userPermissions, allowedPermissions);
  } else {
    hasAccess = checkPermissions(userPermissions, allowedPermissions);
  }

  if (hasAccess) {
    return children;
  }

  return renderNoAccess;
};
