import React from "react";
import ContextMenu from "../components/menus/newContextMenu/ContextMenu";
import SCContextMenuPortal from "../components/menus/newContextMenu/SCContextMenuPortal";
import CenterOnPageSubMenu from "../components/menus/newContextMenu/CenterOnPageSubMenu";
import RotateSubMenu from "../components/menus/newContextMenu/RotateSubMenu";
import OrderSubMenu from "../components/menus/newContextMenu/OrderSubMenu";
import { MenuItem } from "@szhsin/react-menu";
import { Copy, Cut, Paste } from "../components/IconButtons/SubMenuIcons";
import ContentPanel2 from "../ContentPanel2";

class GroupObject extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorPoint: { x: 0, y: 0 },
    };
    // this.props.group.moves=props.value
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    if (this.props.dragging || this.props.resizing) {
      return false;
    } else {
      return true;
    }
  };

  componentDidMount() {
    // this.props.onPropsChange({keys:this.props.value.map(obj=>obj.key)})
  }

  handleContextMenu = (e) => {
    if (!this.props.moreThanOneSelected) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({
        menuProps: true,
        anchorPoint: { x: e.pageX, y: e.pageY },
      });
      this.props.onSelect();
      this.forceUpdate();
    }
  };

  handleContextMenuClose = () => {
    this.setState({ anchorPoint: { x: 0, y: 0 }, menuProps: undefined });
    this.forceUpdate();
  };

  render() {
    return (
      <div
        onContextMenu={(e) => this.handleContextMenu(e)}
        style={{ position: "relative", width: "100%", height: "100%", zIndex: 1 }}
      >
        {this.state.menuProps && (
          <SCContextMenuPortal>
            <ContextMenu
              handleClose={() => this.handleContextMenuClose()}
              anchorPoint={this.state.anchorPoint}
              menuProps={this.state.menuProps}
            >
              <MenuItem className="cm_icon_helper">
                <Cut className="cm_icon" />
                <span className="cm_btn_name">Cut</span>
                <span className="cm_helper_text">Ctrl+X</span>
              </MenuItem>
              <MenuItem className="cm_icon_helper">
                <Copy className="cm_icon" />
                <span className="cm_btn_name">Copy</span>
                <span className="cm_helper_text">Ctrl+C</span>
              </MenuItem>
              <MenuItem className="cm_icon_helper">
                <Paste className="cm_icon" />
                <span className="cm_btn_name">Paste</span>
                <span className="cm_helper_text">Ctrl+V</span>
              </MenuItem>
              <MenuItem onClick={this.props.onDelete}>
                <span className="cm_btn_name">Delete</span>
              </MenuItem>
              <div className="cm_divider" />

              <MenuItem onClick={() => this.props.ungroupObjects(this.props.groupKey)}>
                <span className="cm_btn_name">Ungroup objects</span>
              </MenuItem>
              <OrderSubMenu
                sentToFront={this.props.sentToFront}
                sentToBack={this.props.sentToBack}
                bringForword={this.props.bringForword}
                bringBackward={this.props.bringBackward}
              />
              <CenterOnPageSubMenu
                horizontally={() =>
                  this.props.updatePosition(640 - this.props.width / 2, this.props.top)
                }
                vertically={() =>
                  this.props.updatePosition(this.props.left, 360 - this.props.height / 2)
                }
              />
              {/*<RotateSubMenu height={this.props.height} width={this.props.width}*/}
              {/*rotateAngle={this.props.rotateAngle} scaleX={this.props.scaleX}*/}
              {/*scaleY={this.props.scaleY} onChange={this.props.onChange}/>*/}
            </ContextMenu>
          </SCContextMenuPortal>
        )}
        {this.props.groupedObjects}
      </div>
    );
  }
}

export default GroupObject;
