import React from "react";
import FinanceDetailsDialog from "../../../components/FinanceDetails/FinanceDetailsDialog";
import PropTypes from "prop-types";

class RevenueOnly extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      revenue: this.props.revenue,
      data: null,
      monthlyChartData: null,
      yearlyChartData: null,
      itemChanged: false,
    };

    this.monthHeaders = global.Modeliks.DateHelper.months;
    this.yearHeaders = global.Modeliks.DateHelper.gridPeriodHeaders_Years();
    this.gridHeaders = [...this.monthHeaders, ...this.yearHeaders];

    this.tabs = [
      {
        title: this.state.revenue.RevenueOnly.Name,
        subtitle: `${this.state.revenue.RevenueOnly.Name}`,
        chartTitle: this.state.revenue.RevenueName,
        field: "SinglePrice",
        growthEnabled: true,
        checkedGrowth: false,
        changeEnabled: true,
        allowDisplayActuals: true,
        showActuals: false,
        description:
          "With Revenue Only you can enter revenue without entering unit sales and unit prices separately.",
        changeDriverNameEnabled: true,
        dataGridRow: {
          data: this.state.revenue.RevenueOnly,
          drivers: [],
        },
        chart: {
          monthlyChartData: {
            data: this.state.revenue.RevenueOnly.Values.filter(
              (c) => this.monthHeaders.indexOf(c.Date) > -1,
            ),
            labels: global.Modeliks.DateHelper.headers_Months(),
          },
          yearlyChartData: {
            data: this.state.revenue.RevenueOnly.Values.filter(
              (c) => this.yearHeaders.indexOf(c.Date) > -1,
            ),
            labels: global.Modeliks.DateHelper.headers_Years(true),
          },
        },
      },
    ];
  }

  componentDidMount() {}

  onSave = () => {
    this.props.saveRevenue();
  };

  onDataChanged = (items) => {
    // items.forEach(item=>{
    //     if(item.cache){
    //         item.cache.resetCache();
    //     }
    // })
    this.setState({ itemChanged: true });
  };

  render = () => {
    if (this.state.revenue == null) {
      return null;
    }

    return (
      <FinanceDetailsDialog
        key={"financeDetails" + new Date().getDate()}
        addTab={this.addTab}
        open={this.props.open}
        title={this.state.revenue.RevenueName}
        tabs={this.tabs}
        headers={this.gridHeaders}
        saveEnabled={this.state.itemChanged}
        setFormula={this.setFormula}
        onSave={this.onSave}
        onClose={this.props.onClose}
        table={this.state.revenue.tableName}
        parentID={this.state.revenue.ID}
        totalDriver={this.props.totalDriver}
        onGeneral={this.props.onGeneral}
        onDataChanged={this.onDataChanged}
        tabIndex={this.props.tabIndex}
      />
    );
  };
}

RevenueOnly.propTypes = {
  revenue: PropTypes.object,
  onGeneral: PropTypes.func,
  onClose: PropTypes.func,
};

export default RevenueOnly;
