export class CommandHistoryManager {
  constructor() {
    if (!CommandHistoryManager.instance) {
      // Initialize the instance if it doesn't exist
      this.histories = {
        forwardHistory: [],
        backHistory: [],
      };

      this.topForwardIndexRef = -1;
      this.topBackIndexRef = -1;
      CommandHistoryManager.instance = this; // Set the instance
    }

    return CommandHistoryManager.instance; // Return the instance
  }

  static getInstance() {
    return CommandHistoryManager.instance || new CommandHistoryManager(); // Ensure only one instance is created
  }

  async executeCommand(command) {
    // clear forward history
    this.histories.forwardHistory = [];
    this.topForwardIndexRef = -1;
    const commandReturnValue = await command.execute();
    this.histories.backHistory = [...this.histories.backHistory, command];
    this.topBackIndexRef++;

    return commandReturnValue;
  }

  async redo() {
    if (!this.histories.forwardHistory.length || this.histories.topForwardIndexRef === -1) return;
    const topRedoCommand = this.histories.forwardHistory[this.topForwardIndexRef];
    await topRedoCommand.execute();

    this.histories.forwardHistory = this.histories.forwardHistory.slice(0, -1);
    this.histories.backHistory = [...this.histories.backHistory, topRedoCommand];

    this.topForwardIndexRef--;
    this.topBackIndexRef++;
  }

  async undo() {
    if (!this.histories.backHistory.length || this.topBackIndexRef === -1) return;
    const topUndoCommand = this.histories.backHistory[this.topBackIndexRef];
    await topUndoCommand.undo();

    this.histories.forwardHistory = [...this.histories.forwardHistory, topUndoCommand];
    this.histories.backHistory = this.histories.backHistory.slice(0, -1);

    this.topBackIndexRef--;
    this.topForwardIndexRef++;
  }

  hasBackHistory() {
    return this.histories.backHistory.length > 0;
  }

  hasForwardHistory() {
    return this.histories.forwardHistory.length > 0;
  }

  getGlobalFilteredHistoriesManager() {
    this.histories.backHistory = this.histories.backHistory.filter(item => item._isGlobal === true);
    this.topBackIndexRef = this.histories.backHistory.length > 0 ? this.histories.backHistory.length - 1 : -1;
    this.histories.forwardHistory = this.histories.forwardHistory.filter(item => item._isGlobal === true);
    this.topForwardIndexRef = this.histories.forwardHistory.length > 0 ? this.histories.forwardHistory.length - 1 : -1;

    return this;
  }

  reset() {
    this.histories = {
      forwardHistory: [],
      backHistory: [],
    };

    this.topForwardIndexRef = -1;
    this.topBackIndexRef = -1;
  }
}
