import React, { Component } from "react";
import { PermissionTypes } from "../../../components/constants/permissions";
import { DialogTypes } from "../../../data/Finance/constants";
import { formatDistance } from "date-fns";
import { UpgradeSave, UpgradeStars } from "../../../components/icons/svgIcons";
import ButtonMui from "../../../components/buttons/buttonMui/buttonMui";
import MenuItem from "@mui/material/MenuItem";

const Url = "/profile/account_details/manage_subscription";

const UpgradeButtonDialog = (IconUpgrade, IconUpgradeText, disabled = false) => {
  return (
    <ButtonMui
      disabled={disabled}
      roundnessType="squared"
      width="95px"
      style={{ height: "30px", fontSize: "13px" }}
      variant="contained"
      label={IconUpgradeText}
      color="secondary"
      startIcon={IconUpgrade}
      onClick={() =>
        global.Modeliks.showDialog(
          <span className="upgrade_plan_div">
            <p className="upgrade_plan_dialog_text">
              {<UpgradeSave width="25" height="25" />} Change your plan to an annual subscription to
              save up to <span className="font_big_size">30%</span>
            </p>
            <span className="upgrade_plan_actions">
              <ButtonMui
                linkTo={Url}
                width="120px"
                variant="contained"
                onClick={() => global.Modeliks.showDialog.handleClose()}
                label={"Change Now"}
                color="primary"
              />
              <ButtonMui
                width="120px"
                variant="outlined"
                label={"Not now"}
                color="primary"
                onClick={() => global.Modeliks.showDialog.handleClose()}
              />
            </span>
          </span>,
          DialogTypes.WarningSimpleText,
        )
      }
    />
  );
};

class UpgradePlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SubscriptionPermission:
        global.Modeliks.UserPermissions.hasOwnProperty(PermissionTypes.Subscriptions) &&
        global.Modeliks.UserPermissions[PermissionTypes.Subscriptions] >= 1,
      SelectedPlan: global.Modeliks.subscriptionPlans.find(
        (c) => c.ID === global.Modeliks.subscriptionInfo.SubscriptionID,
      ),
    };
  }

  getButton = (IconUpgradeText, IconUpgrade) => {
    return (
      <ButtonMui
        linkTo={Url}
        roundnessType="squared"
        width="95px"
        disabled={!this.state.SubscriptionPermission}
        style={{ height: "30px", fontSize: "13px" }}
        variant="contained"
        label={IconUpgradeText}
        color="secondary"
        startIcon={IconUpgrade}
      />
    );
  };

  render() {
    const IsFreeTrial = this.state.SelectedPlan.billing_type === "free_trial" || this.state.SelectedPlan.billing_type === "free_trial_custom";
    const IsAnnual = this.state.SelectedPlan.billing_type === "year";
    const IsAppSumo = this.state.SelectedPlan.Type === "AppSumo";
    const IconUpgrade = IsFreeTrial || IsAnnual ? <UpgradeStars /> : <UpgradeSave />;
    const IconUpgradeText = IsFreeTrial || IsAnnual ? "Upgrade" : "Save 30%";
    const HideUpgradeIcon =
      (this.state.SelectedPlan &&
        this.state.SelectedPlan.data_key === "Grow" &&
        this.state.SelectedPlan.billing_type === "year") ||
      IsAppSumo;
    const ButtonUpgrade = (
      <ButtonMui
        disabled={!this.state.SubscriptionPermission}
        linkTo={Url}
        roundnessType="squared"
        width="95px"
        style={{ height: "30px", fontSize: "13px" }}
        variant="contained"
        label={IconUpgradeText}
        color="secondary"
        startIcon={IconUpgrade}
      />
    );

    if (this.props.navigationUpdate) {
      return (
        <>
          {!HideUpgradeIcon && (
            <span className="update_plan_icon_text_div">
              {IsFreeTrial && (
                <span className="upgrade_plan_trial_text">
                  Free Trial{" "}
                  {formatDistance(new Date(global.Modeliks.subscriptionInfo.DateEnd), new Date())}{" "}
                  left
                </span>
              )}
              {IsFreeTrial && ButtonUpgrade}
              {IsAnnual && this.getButton(IconUpgradeText, IconUpgrade)}
              {!IsFreeTrial &&
                !IsAnnual &&
                UpgradeButtonDialog(
                  IconUpgrade,
                  IconUpgradeText,
                  !this.state.SubscriptionPermission,
                )}
            </span>
          )}
        </>
      );
    }

    return (
      <MenuItem className="upgrade_item">
        <span>{this.state.SelectedPlan.name}</span>
        {!HideUpgradeIcon && this.getButton(IconUpgradeText, IconUpgrade)}
      </MenuItem>
    );
  }
}

export default UpgradePlan;
