import httpService from "../httpService";
const resource = "/api/master";

function fetchTemplatesForSection(sectionId) {
  return httpService().get(`${resource}/slidetemplates`, {
    SectionID: sectionId,
  });
}

export { fetchTemplatesForSection };
