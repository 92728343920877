import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Theme } from "../../theme/styles/theme";
import { Paragraph } from "../typography/paragraph/Paragraph";

const CardTypes = {
  small: "368px",
  medium: "1136px",
};

const StyledDiv = styled.div`
  margin-top: ${(props) => (props.marginTop ? "13px" : "0")};
  width: ${(props) => (props.width ? props.width : CardTypes[props.size])};
  height: ${(props) => (props.height ? props.height : "614px")};
  border: 1px solid ${Theme.colors.greyscale["150"]};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 17px;
  padding-right: 17px;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: white;
  overflow-y: overlay;
  overflow-x: hidden;
`;

function Card({ title, hasTitle, size = "small", width, height, marginTop = true, ...props }) {
  // console.log
  return (
    <>
      <StyledDiv
        className={props.className ? props.className : ""}
        size={size}
        height={height}
        width={width}
        title=""
        marginTop={marginTop}
      >
        {hasTitle && (
          <Paragraph
            style={{ marginTop: 0, paddingTop: "16px", fontFamily: "Inter" }}
            size={"p1_strong"}
            label={title}
          ></Paragraph>
        )}
        {props.children}
      </StyledDiv>
    </>
  );
}

Card.propTypes = {
  title: PropTypes.string,
  hasTitle: PropTypes.bool,
  size: PropTypes.oneOf(["small", "medium"]),
  marginTop: PropTypes.bool,
};

export default Card;
