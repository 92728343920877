import { React, useState } from "react";
import PropTypes from "prop-types";
import { ReusableMenu, ReusableMenuItem } from "../../menus/ReusableMenu/ReusableMenu";
import Button from "@mui/material/Button";
import "./AutocompleteInput.scss";
import "./../actions.scss";
import AutocompleteInput from "./AutocompleteInput";

const openSvg = () => (
  <svg
    className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSelect-icon MuiSelect-iconOutlined css-hfutr2-MuiSvgIcon-root-MuiSelect-icon"
    focusable="false"
    aria-hidden="true"
    viewBox="0 0 24 24"
    data-testid="ArrowDropDownIcon"
  >
    <path d="M7 10l5 5 5-5z"></path>
  </svg>
);

const closedSvg = () => (
  <svg
    className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSelect-icon MuiSelect-iconOutlined MuiSelect-iconOpen css-bpeome-MuiSvgIcon-root-MuiSelect-icon"
    focusable="false"
    aria-hidden="true"
    viewBox="0 0 24 24"
    data-testid="ArrowDropDownIcon"
  >
    <path d="M7 10l5 5 5-5z"></path>
  </svg>
);

function DropDownAutocomplete(props) {
  const [collapsed, setCollapsed] = useState(false);

  if (!props.options) {
    return;
  }

  const onChange = (e, value) => {
    if (value && value.code) {
      props.onChange(value);
    }
  };

  return (
    <>
      <ReusableMenu
        key={"dropdown_currency"}
        menuButton={
          <Button
            style={{
              textTransform: "capitalize",
              fontFamily: "inter",
              fontSize: "12px",
              color: "unset",
              width: "100%",
              justifyContent: "space-between",
              borderTop: "solid 1px lightgray",
              borderRight: "solid 1px lightgray",
              borderLeft: "solid 1px lightgray",
              borderBottom: `${!collapsed && "solid 1px lightgray"}`,
            }}
            disabled={props.disabled}
            onClick={() => setCollapsed(!collapsed)}
            endIcon={!collapsed ? openSvg() : closedSvg()}
          >
            <span className={"cmpny_name"}>{props.value.description}</span>
          </Button>
        }
        // menuItems={[]}
        menuElementItems={
          <AutocompleteInput
            width={"330px"}
            placeholder={"Search currency..."}
            optionLabel="description"
            className="fdd_cd_driver_autocomplete_input_style"
            options={props.options}
            // value={props.value}
            onChange={onChange}
          />
        }
      />
    </>
  );
}

DropDownAutocomplete.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  label: PropTypes.string,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  optionLabel: PropTypes.string,
  onBlur: PropTypes.any,
  disabled: PropTypes.bool,
};

DropDownAutocomplete.defaultProps = {
  placeholder: "Search drivers...",
  getOptionDisabled: false,
  optionLabel: "description",
  onBlur: null,
};

export default DropDownAutocomplete;
