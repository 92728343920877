import React from "react";
import SubscriptionMultiple from "./multiple";
import SubscriptionSingle from "./single";

class Subscriptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    if (this.props.revenue.children.length > 0) {
      return (
        <SubscriptionMultiple
          totalDriver={this.props.totalDriver}
          saveRevenue={this.props.saveRevenue}
          tabIndex={this.props.tabIndex}
          revenue={this.props.revenue}
          onClose={this.props.onClose}
          onGeneral={this.props.onGeneral}
          open={this.props.open}
        />
      );
    } else {
      return null;

      return (
        <SubscriptionSingle
          saveRevenue={this.props.saveRevenue}
          tabIndex={this.props.tabIndex}
          parent={this.props.revenue}
          revenue={this.props.revenue.children[0]}
          onClose={this.props.onClose}
          onGeneral={this.props.onGeneral}
          open={this.props.open}
        />
      );
    }

    return null;
  }
}

export default Subscriptions;
