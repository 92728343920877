import React from "react";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import ButtonMui from "../../../buttons/buttonMui/buttonMui";

export const AppUpdate = ({ ...props }) => {
  return (
    <div className={"info_dialog_container"}>
      <DialogTitle>Modeliks App Update</DialogTitle>
      <DialogContent>
        We have improved Modeliks to make your experience seamless. Please refresh your page to get
        the latest update…
        <div className="blue">Thank you for your understanding</div>
      </DialogContent>
      <DialogActions>
        <ButtonMui variant={"contained"} label={"Refresh"} onClick={props.onAccept} />
      </DialogActions>
    </div>
  );
};
