import React from "react";
import "./EmailVerified.scss";
import {
  LoginBottomRightCornerBigShape,
  LoginTopLeftCornerBigShape,
  MobileTitleShape,
} from "../../../../../components/icons/svgIcons";
import ButtonMui from "../../../../../components/buttons/buttonMui/buttonMui";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BackgroundBalls from "../../components/BackgroundBalls";
import { CopyrightMsg, ModeliksHeaderLogo } from "../../Register/RegisterPage";

function EmailVerified({success, awaitLogin}) {
    return (
        <div className="email-verified-wrapper">
            <div className="email-verified-content-wrapper">
                <div className="content">
                    <div className="logo">
                        <ModeliksHeaderLogo/>
                    </div>

                    <div className="email-verified-content">
                        <div className="email-verified-title">
                            {success ? <span>Email Verification</span> : <span>Email verification failed</span>}
                        </div>

                        <div className="success-desc-text">
                            {success ? "You successfully verified your email" : "Invalid verification ID."}
                        </div>
                        {awaitLogin ? <div className="email-verified-action-btns-wrapper">
                                <div className="success-desc-text">
                                    Logging you in...
                                </div>
                            </div> :
                            <div className="email-verified-action-btns-wrapper">
                                <div className="email-verified-btn">
                                    <ButtonMui
                                        label="Login"
                                        width="100%"
                                        onClick={() => global.Modeliks.NavigateTo("/login")}
                                        variant={"contained"}
                                    />
                                </div>
                            </div>}
          </div>
          <CopyrightMsg />
        </div>
      </div>
      <div className="top-left-shape">
        <LoginTopLeftCornerBigShape></LoginTopLeftCornerBigShape>
      </div>
      <div className="bottom-right-shape">
        <LoginBottomRightCornerBigShape></LoginBottomRightCornerBigShape>
      </div>
      <div className="mobile-title-shape">
        <MobileTitleShape></MobileTitleShape>
      </div>

      <BackgroundBalls
        bigRightTopBall
        smallRightTopBall
        bigLeftTopBall
        smallLeftTopBall
        smallCenterMidBall
        smallLeftCenterBall
        smallRightCenterBall
        bigRightCenterBall
        smallCenterTopBall
        bigLeftBottomBall
        smallRightBottomBall
      />
    </div>
  );
}

export default EmailVerified;
