import React from "react";
import FinanceDetailsDialog from "../../components/FinanceDetails/FinanceDetailsDialog";
import { PeriodTypes, ShortMonths } from "../../../../../data/dates";
import PropTypes from "prop-types";
import { ExpenseValues } from "../../../../../data/Finance/constants";

class Expenses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expense: this.props.expense,
      data: null,
      rows: [],
      monthlyChartData: null,
      yearlyChartData: null,
      itemChanged: false,
    };

    this.yearHeaders = global.Modeliks.DateHelper.gridPeriodHeaders_Years();
    this.monthHeaders = global.Modeliks.DateHelper.months;
    this.gridHeaders = [...this.monthHeaders, ...this.yearHeaders];

    this.tabs = [
      {
        title: props.expense.Name,
        subtitle: `${this.props.expense.Name} ${this.props.expense.ID_Revenue ? `as % of ${global.Modeliks.RevenuesStore.getItem(this.props.expense.ID_Revenue).RevenueName}` : this.props.expense.ExpenseMetric === ExpenseValues.Totals ? "as % of total revenue" : ""}`,
        field: "Expense",
        growthEnabled: this.props.expense.ExpenseMetric === ExpenseValues.Value,
        checkedGrowth: false,
        changeEnabled: true,
        allowDisplayActuals: true,
        showActuals: false,
        dataGridRow: {
          data: this.state.expense.Expense,
          drivers: [],
        },
        chart: {
          monthlyChartData: {
            data: this.state.expense.Expense.Values.filter(
              (c) => this.monthHeaders.indexOf(c.Date) > -1,
            ),
            labels: global.Modeliks.DateHelper.headers_Months(),
          },
          yearlyChartData: {
            data: this.state.expense.Expense.Values.filter(
              (c) => this.yearHeaders.indexOf(c.Date) > -1,
            ),
            labels: global.Modeliks.DateHelper.headers_Years(true),
          },
        },
      },
    ];
  }

  componentDidMount() {}

  onSave = () => {
    this.props.saveRevenue();
  };

  onDataChanged = (items) => {
    // items.forEach(item=>{
    //     if(item.cache){
    //         item.cache.resetCache();
    //     }
    // })
    this.setState({ itemChanged: true });
  };

  render = () => {
    if (this.state.expense == null) {
      return null;
    }

    return (
      <FinanceDetailsDialog
        open={this.props.open}
        title={this.state.expense.Name}
        tabs={this.tabs}
        headers={this.gridHeaders}
        monthlyChartData={this.state.monthlyChartData}
        yearlyChartData={this.state.yearlyChartData}
        saveEnabled={this.state.itemChanged}
        onSave={this.onSave}
        totalDriver={this.props.totalDriver}
        onClose={this.props.onClose}
        table={this.state.expense.tableName}
        parentID={this.state.expense.ID}
        onGeneral={this.props.onGeneral}
        onDataChanged={this.onDataChanged}
        tabIndex={this.props.tabIndex}
      />
    );
  };
}

Expenses.propTypes = {
  personnel: PropTypes.object,
  onGeneral: PropTypes.func,
  onClose: PropTypes.func,
};

export default Expenses;
