import React from "react";
import { AddDialogTitle } from "../../../../components/dialogs/financials/AddDialog/AddDialogTitle";
import AddDialog from "../../../../components/dialogs/financials/AddDialog/AddDialog";
import { AddDialogContent } from "../../../../components/dialogs/financials/AddDialog/AddDialogContent";
import { DialogActions } from "@mui/material";
import MaterialSelect from "../../../../components/actions/SelectM";
import Input from "../../../../components/actions/Input";
import ButtonMui from "../../../../components/buttons/buttonMui/buttonMui";

function AddChartDialog(props) {
  const [dashboardIndex, setDashboardIndex] = React.useState(0);
  const [Name, setName] = React.useState("");
  const [added, setAdded] = React.useState(false);
  const [dialogError, setDialogError] = React.useState(null);
  let alreadyAdded = false;

  const handleSaveDashboard = () => {
    global.Modeliks.CustomDashboards[dashboardIndex].Settings = JSON.stringify(
      global.Modeliks.CustomDashboards[dashboardIndex].Settings,
    );
    global.Modeliks.CustomDashboards[dashboardIndex].Save &&
      global.Modeliks.CustomDashboards[dashboardIndex].Save(() => {
        global.Modeliks.CustomDashboards[dashboardIndex].Settings = JSON.parse(
          global.Modeliks.CustomDashboards[dashboardIndex].Settings,
        );
        props.handleClose && props.handleClose();
      });
  };

  const addToDashboard = () => {
    !added && setAdded(true);
    if (global.Modeliks.CustomDashboards.length) {
      if (global.Modeliks.CustomDashboards[dashboardIndex].Settings) {
        if (props.chartID) {
          if (!global.Modeliks.CustomDashboards[dashboardIndex].Settings.charts[props.chartID]) {
            global.Modeliks.CustomDashboards[dashboardIndex].Settings.charts[props.chartID] = {};
            handleSaveDashboard();
          } else {
            setDialogError("The chart is already added in the selected custom dashboard");
          }
        } else if (props.tableInfo) {
          if (!global.Modeliks.CustomDashboards[dashboardIndex].Settings.financial_statements) {
            global.Modeliks.CustomDashboards[dashboardIndex].Settings.financial_statements = [];
          }
          global.Modeliks.CustomDashboards[dashboardIndex].Settings.financial_statements.push(
            props.tableInfo,
          );
          handleSaveDashboard();
        } else if (props.kpiTableInfo) {
          if (!global.Modeliks.CustomDashboards[dashboardIndex].Settings.custom_kpis) {
            global.Modeliks.CustomDashboards[dashboardIndex].Settings.custom_kpis = [];
          }
          global.Modeliks.CustomDashboards[dashboardIndex].Settings.custom_kpis.push(
            props.kpiTableInfo,
          );
          handleSaveDashboard();
        }
      }
    } else {
      global.Modeliks.post(
        "customdashboards",
        {
          Name: Name,
          Settings: JSON.stringify({ charts: {}, financial_statements: [], custom_kpis: [] }),
          CompanyID: global.Modeliks.CompanyInfo.ID,
        },
        (success) => {
          global.Modeliks.GetCustomDashboards(() => {
            addToDashboard();
          });
        },
      );
    }
  };

  const dialogContent = () => {
    if (global.Modeliks.CustomDashboards.length) {
      return (
        <>
          <div className="sad_heading">
            Choose a Custom dashboard to add the {props.chartID ? "chart" : "table"} in
          </div>
          <MaterialSelect
            width={"330px"}
            value={dashboardIndex}
            options={global.Modeliks.CustomDashboards.map((c, index) => {
              return { description: c.Name, value: index };
            })}
            onChange={(e) => {
              if (dialogError) {
                setDialogError(null);
              }
              if (added) {
                setAdded(false);
              }
              setDashboardIndex(e.target.value);
            }}
          />
        </>
      );
    } else {
      return (
        <>
          <div className="sad_heading">
            No Custom dashboards have been created yet. Do you want to create a Custom Dashboard?
          </div>
          <div>
            <Input
              placeholder="Please enter your Custom Dashboard's name"
              value={Name}
              // error={!!scenarioNameError}
              // errorMessage={scenarioNameError}
              onChange={(event) => setName(event.target.value)}
            />
          </div>
        </>
      );
    }
  };

  return (
    <AddDialog onClose={() => props.handleClose(false)} open>
      <AddDialogTitle
        title={`Add ${props.chartID ? "chart" : "table"} to a Custom Dashboard`}
        onClose={() => props.handleClose(false)}
      />
      <AddDialogContent>
        {dialogContent()}
        {dialogError && <div className="error">{dialogError}</div>}
      </AddDialogContent>
      <DialogActions>
        <ButtonMui
          label="Add"
          variant={"contained"}
          width={92}
          disabled={added}
          onClick={addToDashboard}
        />
      </DialogActions>
    </AddDialog>
  );
}

export default AddChartDialog;
