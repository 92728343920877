import React, { Fragment, useState } from "react";
import "../HeaderTextEditor.scss";
import "../HeaderDefault.scss";
import Menu from "@mui/material/Menu";
import {
  LineStart,
  LineStart1,
  LineStart2,
  LineStart3,
  LineStart4,
  LineStart5,
  LineStart6,
  LineStart7,
  LineStart8,
} from "../../components/IconButtons/SubMenuIcons";

function LineStartEditComponent(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openLineStart = Boolean(anchorEl);
  const handleClick = (event) => {
    if (!openLineStart) setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onChange = (style) => {
    props.onChange({ markerStart: style });
    setAnchorEl(null);
  };
  const LineStartEdit = () => {
    return (
      <>
        <div
          className="SubMenuButton"
          style={{ height: "auto", display: "flex", alignItems: "center" }}
          onClick={handleClick}
        >
          <span id="tooltipSubMenuButton">Line Start</span>
          <LineStart
            active={openLineStart}
            aria-controls={openLineStart ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openLineStart ? "true" : undefined}
          />
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openLineStart}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <div
              style={{
                paddingLeft: "8px",
                paddingRight: "8px",
                color: "#B9BFC3",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <LineStart1 onChange={onChange} id={"MenuItems"} />
              <LineStart2 onChange={onChange} id={"MenuItems"} />
              <LineStart3 onChange={onChange} id={"MenuItems"} />
              <LineStart4 onChange={onChange} id={"MenuItems"} />
              <LineStart5 onChange={onChange} id={"MenuItems"} />
              <LineStart6 onChange={onChange} id={"MenuItems"} />
              <LineStart7 onChange={onChange} id={"MenuItems"} />
              <LineStart8 onChange={onChange} id={"MenuItems"} />
            </div>
          </Menu>
        </div>
      </>
    );
  };

  return <>{LineStartEdit()}</>;
}

export default LineStartEditComponent;
