import MxDataArray from "../../MxDataArray";
import { FinancingTypes } from "../Financing/constants";
import Loan from "./Loan";
import OtherLiability from "./OtherLiability";
import Investments from "./Investments";
import LineOfCredit from "./LineOfCredit";
import Dividends from "./Dividends";
import Financing from "./index.js";
import FinancingDataGridRows from "./FinancingDataGridRows";

class FinancingStorage extends MxDataArray {
  dataManager = null;
  driverStorage = null;
  loaded = false;

  setData = (callBack, data = this.data) => {
    this.pushArray(
      data.map((item) => {
        let newItem = null;

        if (item.FinanceType === FinancingTypes.Loan) {
          newItem = new Loan(item);
        }
        if (item.FinanceType === FinancingTypes.OtherLiability) {
          newItem = new OtherLiability(item);
        }
        if (item.FinanceType === FinancingTypes.Investments) {
          newItem = new Investments(item);
        }
        if (item.FinanceType === FinancingTypes.LineOfCredit) {
          newItem = new LineOfCredit(item);
        }
        if (item.FinanceType === FinancingTypes.Dividends) {
          newItem = new Dividends(item);
        }
        if (newItem && newItem.Totals) {
          return newItem;
        } else {
          // if(item && item.hasOwnProperty('ID')){
          //     this.dataManager.del(this.dataManager.Tables.Finance_Financing.TableName, {ID: item.ID}, () => {
          //         console.log('deleting',item);
          //     }, () => {
          //     });
          // }
        }
      }),
    );

    Financing.getRetainedEarningID();
    Financing.getFinanceTotals();
    Financing.getCurrentOtherLiabilities();

    Financing.getCurrentOtherLiabilitiesOpeningBalance();
    Financing.getCurrentOtherLiabilitiesOtherLiabilityBalance();
    Financing.getLongTermOtherLiabilitiesOpeningBalance();
    Financing.getLongTermOtherLiabilitiesOtherLiabilityBalance();

    Financing.getLongTermOtherLiabilities();
    Financing.getLiabilities();
    Financing.getCurrentAndLongTermLiabilities();
    Financing.getCurrentLiabilities();

    Financing.getEquity();
    Financing.getEquityInvestments();
    Financing.getEquityInvestmentsOnlyEquity();

    Financing.getRetainedEarnings();
    // Financing.setRetainedEarningsFormulas();
    Financing.getOpenningBalanceRetainedEarnings();
    Financing.getDividendsTotals();
    Financing.getClosingBalanceRetainedEarnings();
    Financing.getLoanReceipts();

    Financing.getLoanRepayments();
    Financing.getChangeInLineOfCredit();
    Financing.getChangeInLone();
    Financing.getInterestExpense();
    Financing.getPrepaidRevenueOpeningBalance();
    Financing.getPrepaidRevenueClosingBalance();
    Financing.getTotalPrincipalRepayment();
    this.loaded = true;
    callBack();
  };

  setGridRows = () => FinancingDataGridRows(this);

  loadFinancing = (callBack, shouldFinish = true) => {
    this.clear();
    this.dataManager.get(
      this.dataManager.Tables.Finance_Financing.TableName,
      { ID_CompanyScenario: this.dataManager.CompanyScenarioInfo.ID },
      (data) => {
        if (shouldFinish) {
          this.setData(callBack, data);
        } else {
          this.data = data;
          callBack();
        }
      },
      null,
      false,
    );
  };

  static create = () => {
    const newArr = new FinancingStorage();
    newArr.pushOld = newArr.push;
    newArr.push = newArr.pushThis;

    return newArr;
  };
}

export default FinancingStorage;
