import React from "react";
import PropTypes from "prop-types";
import { Autocomplete, TextField, InputAdornment, createFilterOptions } from "@mui/material";
import "./AutocompleteInput.scss";
import { Search } from "../../icons/svgIcons";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

const filter = createFilterOptions();

function AutocompleteInput(props) {
  return (
    <Autocomplete
      disabled={props.disabled}
      freeSolo
      size="small"
      onChange={props.onChange}
      options={props.options}
      value={props.value}
      getOptionDisabled={props.getOptionDisabled && props.getOptionDisabled}
      sx={{ width: props.width ? props.width : 265, height: props.height ? props.height : 32 }}
      // onBlur={props.onBlur && props.onChange}
      getOptionLabel={(option) =>
        option.hasOwnProperty(props.optionLabel) ? option[props.optionLabel] : ""
      }
      renderInput={(params, index) => (
        <TextField
          className={props.className}
          {...params}
          placeholder={props.placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      )}
      renderOption={(props, option, { inputValue }) => {
        let matchOption = option.hasOwnProperty("DriverName") ? "DriverName" : "description";
        const matches = match(
          option[matchOption] !== null ? option[matchOption] : option.ID,
          inputValue,
        );
        const parts = parse(
          option[matchOption] !== null ? option[matchOption] : option.ID,
          matches,
        );
        return (
          <li {...props} key={props["data-option-index"] + "li_drivers"}>
            <div>
              {/*<span className='pscai_autocomplete_hightlight'>{option[matchOption]}</span>*/}
              {option.hasOwnProperty("DriverName") ? (
                parts.map((part, index) => (
                  <span
                    key={index}
                    className="pscai_autocomplete_hightlight"
                    style={{ color: part.highlight && `#01AFFD` }}
                  >
                    {part.text}
                  </span>
                ))
              ) : (
                <span className="pscai_autocomplete_hightlight">{option[matchOption]}</span>
              )}
            </div>
          </li>
        );
      }}
    />
  );
}

AutocompleteInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  label: PropTypes.string,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  optionLabel: PropTypes.string,
  onBlur: PropTypes.any,
  disabled: PropTypes.bool,
};

AutocompleteInput.defaultProps = {
  placeholder: "Search drivers...",
  optionLabel: "DriverName",
  onBlur: null,
};

export default AutocompleteInput;
