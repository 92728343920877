import React from 'react';
import {DialogActions, DialogContent, DialogTitle} from "@mui/material";
import ButtonMui from "../../buttons/buttonMui/buttonMui";
import Dialog from "@mui/material/Dialog";
import {functions} from "../../constants/functionNameMappings";
import {modeliksLinks} from "../../../Enums/ModeliksEnum";

const closeDialog = () => {
	if (global[functions.toggle_referral_tos_dialog]) {
		global[functions.toggle_referral_tos_dialog](false)
	}
}

const acceptToc = () => {
	closeDialog()
}

function ReferralTermsAndConditionsDialog(props) {

	return (
		<Dialog
			open={true}
			onClose={closeDialog}
			PaperProps={{
				sx: {
					maxWidth: "700px",
					height: "80%",
				},
			}}
		>
			<DialogTitle
				sx={{
					fontSize: "2rem",
					fontWeight: "bold",
					marginBottom: "20px",
				}}
			>
				{"Referral Program Terms and Conditions"}
			</DialogTitle>
			<DialogContent className="dialog-content" dividers>
				{/* Add content for your dialog */}
				<div className="text">
					The following describes the Terms and Conditions which apply to participation in
					the referral Program currently offered by Modeliks LTD (Modeliks). Modeliks
					reserves the right to amend or terminate the Program at any time, for any
					reason. If you wish to participate in the Referral Program described below, you
					agree to the terms for the Referral Program described in below, as well as
					the{" "}
					<a
						href={modeliksLinks.termsOfService}
						target="_blank"
						rel="noopener noreferrer"
					>
						Terms of Service
					</a>{" "}
					and{" "}
					<a
						href={modeliksLinks.privacyPolicy}
						target="_blank"
						rel="noopener noreferrer"
					>
						Privacy Policy
					</a>{" "}
					. If you do not agree to these Terms and Conditions in their entirety, you are
					not authorized to participate in the Program in any other manner. You may not
					participate in the Program where doing so would be prohibited by any applicable
					law or regulations.
				</div>
				<h2>Eligibility</h2>
				<div className="text">
					The referral program is open to all existing users of Modeliks with a valid user
					account. Existing users can refer friends, family, or acquaintances (referees)
					to Modeliks. Participants (referrer and referee) must be of legal age and comply
					with applicable laws and regulations. The referee must be a new Modeliks
					customer or/and not a lead of Modeliks already (in case of B2B referrals) and
					may not have an existing registered Modeliks account. Please note that referrals
					(any friend, family member, or acquaintance whom you'd like to refer) must be
					submitted through the designated referral channel (e.g., referral link or
					email). Referrals received outside of the specified channels may not be eligible
					for rewards.
				</div>
				<h2>{""}</h2>
				<div className="text">
					A given referee can only be referred to the company once, and the first referral
					counts only. Referrers cannot refer themselves. The referee and the referee
					cannot be the same person. Where we identify they are the same person, for
					example by email address, or name, the offer will be void.
				</div>
				<h2>Referral Proccres</h2>
				<div className="text">
					In the Modeliks Software you can refer Modeliks Software to friends, family and
					acquaintances through the “Earn Credits” functionality. The user can refer
					through email and/or link sharing.
				</div>
				<div className="text">
					You earn credits when someone you refer signs up for a paid plan (makes a
					qualified purchase) and doesn't cancel or get a refund within one billing
					period. Modeliks credits are stored at the user account level, not on a paid
					account level.
				</div>
				<h2>Referral Link Distribution</h2>
				<div className="text">
					You may not distribute a referral link or otherwise solicit anyone through mass
					email, any form of commercial advertising or other similar means. If you are
					interested in becoming an affiliate, please visit our{" "}
					<a
						href={modeliksLinks.affiliatePartnerProgram}
						target="_blank"
						rel="noopener noreferrer"
					>
						Affiliate program page
					</a>
					.{" "}
				</div>
				<div className="text">
					We encourage you to share your referral code to your social media pages, but
					referral shall not be posted on any other social media pages (including Modeliks
					social media pages and/or any Modeliks social media postings). Each referrer
					must comply with the terms and policies of any social media site where a
					referral link is posted/used.
				</div>
				<h2>Qualified Purchase</h2>
				<div className="text">
					A purchase qualifies if the referee is at least 18 years old, purchases a paid
					plan (Start, Fund, or Grow), and doesn't cancel the paid plan during the billing
					period. Monthly plans have a one-month billing period, and annual plans have a
					one-year billing period. A referee who made a qualifying purchase becomes a
					qualified referral.
				</div>
				<div className="text">
					A referral will not be a Qualified Referral if they cancel, return, or exchange
					their Qualifying Purchase, if their Qualifying Purchase hasn’t earned Cash Back,
					or if their purchase is found to be fraudulent, abusive, unethical, suspicious,
					or otherwise inconsistent with our Referral Program Terms and Conditions.
				</div>
				<h2>Referral Reward</h2>
				<div className="text">
					The referrer receives $10 in Modeliks credits for each qualified referral.
					<div className="text"></div>
					The referee receives $10 in Modeliks credits for a qualified purchase.
				</div>
				<h2>Referral Reward Redemption</h2>

				<div className="text">
					Credits are automatically applied to an account with a paid plan. Users with
					multiple accounts with a paid plan can choose where to apply the credits in full
					(there is no allocation of credits to multiple accounts). Users without a paid
					account can't use the credits until they sign up for a paid plan. Credits are
					applied only to recurring purchases, not the first-time purchase.
					<div className="text"></div>
					Example:
					<div className="text"></div>
					If you have $10 in Modeliks credits and purchase a $19 Start Monthly plan for
					February 2023, your March 2023 bill will be reduced to $9 ($19 - $10 credits =
					$9).
				</div>

				<div className="text" style={{margin: "10px 0"}}>
					<b>Credit Application Details:</b>
				</div>
				<div className="text">
					Credits are applied in full and only in increments of $10 Modeliks credits to
					the recurring subscription bill.Total credit amount applied cannot exceed your
					monthly or annual recurring subscription total.
				</div>
				<div className="text">For Example:</div>

				<ul style={{margin: "5px 0"}}>
					<li style={{marginBottom: "10px"}}>
						if you have an existing credit balance of $20 Modeliks credits and purchase
						a
						$19 Start Monthly plan, your next bill will be $19 - $10 Modeliks credits =
						$9. Your new credit balance will be $20 - $10 Modeliks credits = $10
						Modeliks
						credits.
					</li>
					<li>
						if you have an existing credit balance of $20 Modeliks credits and purchase
						a
						$35 Fund Monthly plan, your next bill will be $35 - $20 Modeliks credits =
						$15. Your new credit balance will be $20 - $20 Modeliks credits = $0
						Modeliks
						credits.
					</li>
				</ul>
				<div className="text" style={{margin: "10px 0"}}>
					<b>Additional Information:</b>
				</div>
				<h2>Referral Reward Limitations</h2>

				<div className="text">
					Only one referral reward and one referee reward per new customer. You will not
					receive multiple referral bonuses for the same referee buying multiple products,
					nor will the referee receive multiple welcome bonuses for buying multiple
					products.
				</div>
				<h2>{""}</h2>
				<div className="text">
					All rewards are non-transferable, non-exchangeable, and cannot be redeemed for
					cash. In case the user account is canceled and not reactivated after the cooling
					off period or the user account is deleted, the rewards earned (accumulated) will
					be lost and cannot be redeemed for cash or any other means.
				</div>
				<h2>{""}</h2>
				<div className="text">
					Modeliks accepts no liability for lost entries and no responsibility for
					difficulties experienced in submitting an entry to this Referral Program,
					including any technical, hardware or software failures of any kind or lost or
					unavailable network connections which may limit or prohibit an individual’s
					ability to participate in the Referral Program.
					<div className="text"></div>Where Modeliks needs to contact a referrer or
					referee via email, it is that individual’s responsibility to provide Modeliks
					with a valid, active email address, and Modeliks cannot be held responsible for
					an invalid email address or other problems relating to any delay to the delivery
					of or non-receipt of emails, including the effect of spam filters.
				</div>
				<h2>Referral Fraud</h2>
				<div className="text">
					Modeliks reserves the right to review, investigate, and disqualify anyone from
					participation in the Modeliks Referral Program at any time, and reserves the
					right to not honor any referral which in our sole discretion was solicited in a
					manner inconsistent with the spirit or Terms and Conditions of the Modeliks
					Referral Program or otherwise fails to comply with any applicable laws
					(including, without limitation, through any failure to include any disclosures
					as required by the FTC or otherwise required by Modeliks).{" "}
				</div>
				<h2>{""}</h2>
				<div className="text">
					A referral will not be a Qualified Referral if their purchase is found to be
					fraudulent, abusive, unethical, suspicious, or otherwise inconsistent with our
					Referral Program Terms and Conditions.
				</div>
				<h2>{""}</h2>
				<div className="text">
					Any attempts to manipulate or engage in fraudulent activities, including
					creating multiple accounts or providing false information, may result in
					disqualification from the referral program. Modeliks reserves the right to
					investigate and take appropriate action against any suspected fraudulent
					activities.
				</div>
				<h2>{""}</h2>
				<div className="text">
					Furthermore, Modeliks reserves the right to seek reimbursement from any customer
					of amounts paid as Referral Rewards to customers solicited via mass
					solicitation.
				</div>
				<h2>Program Changes</h2>
				<div className="text">
					Modeliks reserves the right to modify or terminate the referral program at any
					time without prior notice.
					<div className="text"></div>
					Any changes to the program terms and conditions will be communicated to
					participants through appropriate channels.
				</div>
				<h2>Privacy</h2>
				<div className="text">
					To protect the privacy of those you refer, referral prospect account information
					or eligibility is not, under any circumstances, permitted to be shared with
					referral promoters. Referral program participant information will be treated in
					accordance with the company's{" "}
					<a
						href={modeliksLinks.privacyPolicy}
						target="_blank"
						rel="noopener noreferrer"
					>
						Privacy Policy
					</a>
					. <div className="text"></div>
					Referrers and referred individuals may be contacted by Modeliks for
					program-related communications.
				</div>
				<h2>Conduct</h2>
				<div className="text">
					A. If a solution cannot be found to restore the integrity of the Program after
					the occurrence of Prohibited Conduct (as defined below), Casper reserves the
					right to cancel, change, or suspend the Program.
				</div>
				<h2>{""}</h2>
				<div className="text">B. Prohibited Conduct, Generally:</div>

				<ol style={{margin: "5px 0"}}>
					<li>Participants agree not to use the Program to:</li>
					<li>Violate applicable law;</li>
					<li>
						Infringe the intellectual property rights of Modeliks or any third parties;
					</li>
					<li>Stalk, harass, or harm another individual;</li>
					<li>Collect or store personal data about other Participants;</li>
					<li>
						Impersonate any person or otherwise misrepresent Participant's identity;
					</li>
					<li>
						Interfere with, disrupt or violate the Terms and Conditions or servers or
						networks connected to the Program; or disobey any requirements, procedures,
						policies, or regulations of such networks;
					</li>
					<li>Interfere with another Participants's use of the Program;</li>
					<li>
						Attempt to gain unauthorized access to the Program, other accounts, computer
						systems, or networks connected to the Program;
					</li>
					<li>
						Transmit any file that contains viruses, worms, trojan horses, or any other
						contaminating or destructive features;
					</li>
					<li>
						Conduct any illegal activity or solicit the performance of any illegal
						activity or other activity that infringes the rights of others;
					</li>
					<li>
						Resell, barter, trade, auction or otherwise generate income by providing
						access to the Program to others.
					</li>
				</ol>
				<h2>{""}</h2>
				<div className="text">C. Bulk Distribution ("Spam")</div>

				<ol style={{margin: "5px 0"}}>
					<li>
						If a referrer provides a personal link to a referee by email, the email must
						be created and distributed in a personal manner that is appropriate and
						customary for communications with friends, family members and acquaintances.
					</li>
					<li>
						Bulk email distribution, distribution to strangers, or any other promotion
						of
						a personal link in a manner that would constitute or appear to constitute
						unsolicited commercial email or "spam" in Modeliks’s sole discretion is
						expressly prohibited and may be grounds for immediate termination of the
						referrer's account and deactivation of the personal link. Modeliks has a
						no-tolerance spam policy.
					</li>
					<li>
						Modeliks has no obligation to monitor the content provided by referrers;
						however, Modeliks may choose to do so and block any email messages, remove
						any
						such content, or prohibit any use of the Program.
					</li>
					<li>
						While Modeliks is the actual sender of the referral email, each referrer
						must
						nonetheless comply with applicable law. Referrers who do not comply with the
						law, including antispam laws, are obligated to indemnify Modeliks and all of
						the Released Parties against any liabilities, costs and expenses incurred as
						a
						result of such violation.
					</li>
				</ol>
				<h2>{""}</h2>
				<div className="text">D. Fraudulent and Suspicious Behavior</div>

				<ol style={{margin: "5px 0"}}>
					<li>
						Modeliks may prohibit an referrer from participating in the Program or
						receiving a Credit or Reward, in Modeliks’s sole discretion, if Modeliks
						determines that such participant is attempting to undermine the fairness,
						integrity or legitimate operation of the Program in any way by cheating,
						hacking, deception, or any other unfair playing practices of intending to
						annoy, abuse, threaten or harass any other participant or any
						representatives
						of Modeliks.
					</li>
					<li>
						Use of any affiliate website, affiliate network properties, automated
						systems,
						script, or macro to participate is strictly prohibited and will result in
						disqualification.
					</li>
					<li>
						Referrers may not enter with multiple or fake emails addresses or accounts,
						use fictitious identities or use any system, bot or other device or artifice
						to participate in the Program or receive a Reward.
					</li>
					<li>
						Modeliks reserves the right to disqualify any participant and/or cancel any
						Reward(s) if Modeliks finds a participant to be tampering with the entry
						process or the operation of the Program or violating these Terms and
						Conditions in any way.
					</li>
					<li>
						CAUTION: ANY ATTEMPT TO DELIBERATELY DAMAGE OR UNDERMINE THE LEGITIMATE
						OPERATION OF THE PROGRAM MAY BE IN VIOLATION OF CRIMINAL AND CIVIL LAWS AND
						WILL RESULT IN DISQUALIFICATION FROM PARTICIPATION IN THE PROGRAM. SHOULD
						SUCH
						AN ATTEMPT BE MADE, MODELIKS RESERVES THE RIGHT TO SEEK REMEDIES AND DAMAGES
						(INCLUDING ATTORNEY FEES) TO THE FULLEST EXTENT OF THE LAW, INCLUDING
						CRIMINAL
						PROSECUTION.
					</li>
				</ol>
				<h2>Disclaimer</h2>
				<div className="text">
					Modeliks reserves the right to interpret and make final decisions on all matters
					related to the referral program, including eligibility, rewards, and any
					disputes. Modeliks is not responsible for any taxes, as required by law, arising
					from the provision of the referral reward.
					<div className="text"></div>
					To the maximum extent permitted by law, Modeliks accepts no liability for any
					loss, damage and/or personal injury suffered by participating in this Referral
					Program and/or arising out of the enjoyment of a Reward.
				</div>

				<h2>{""}</h2>
				<h2>{""}</h2>
				<div className="text">Last update: 10 January 2024</div>
			</DialogContent>
			<DialogActions sx={{justifyContent: "center"}}>
				<ButtonMui
					width={160}
					label={"Acknowledge"}
					variant={"contained"}
					onClick={acceptToc}
				/>
			</DialogActions>
		</Dialog>
	);
}

export default ReferralTermsAndConditionsDialog;
