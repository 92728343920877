import MxDataArray from "../../MxDataArray";
import CostSale from "./index";
import CostSaleDataGridRows from "./CostSaleDataGridRows";

class CostSaleStorage extends MxDataArray {
  dataManager = null;
  driverStorage = null;
  loaded = false;

  setData = (callBack, data = this.data) => {
    this.pushArray(
      data.map((item) => {
        const newItem = new CostSale(item);
        if (newItem.Totals) {
          this.setExistingDrivers(newItem);
          return newItem;
        } else {
          // this.dataManager.del(this.dataManager.Tables.Finance_CostSales.TableName, {ID: newItem.ID}, () => {
          //     console.log('deleting',newItem);
          //
          // }, () => {
          // });
        }
      }),
    );

    CostSale.getCostSalesTotals();
    CostSale.getTotalsGrowth(CostSale.getCostSalesTotals());
    CostSale.getCostSalesGrouped();
    CostSale.getCostSalesMarginFromRevenue();
    CostSale.getCostSaleAndExpenses();
    CostSale.getCostSaleAndDirectExpenses();

    this.loaded = true;

    callBack();
  };

  setGridRows = () => CostSaleDataGridRows(this);

  setExistingDrivers = (item) => item.getAllDrivers().forEach((d) => (d.IsExisting = true));

  loadCostSales = (callBack, shouldFinish = true) => {
    this.clear();
    this.dataManager.get(
      this.dataManager.Tables.Finance_CostSales.TableName,
      { ID_CompanyScenario: this.dataManager.CompanyScenarioInfo.ID },
      (data) => {
        if (shouldFinish) {
          this.setData(callBack, data);
        } else {
          this.data = data;
          callBack();
        }
      },
      null,
      false,
    );
  };

  static create = () => {
    const newArr = new CostSaleStorage();
    newArr.pushOld = newArr.push;
    newArr.push = newArr.pushThis;

    return newArr;
  };
}

export default CostSaleStorage;
