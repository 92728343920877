import React from "react";
import { Dialog, DialogActions } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { Avatar, EarnCreditsIcon, GreenIcon, SwitchAccount,AvatarBadge } from "../../icons/svgIcons";
import "./SwitchAccount.scss";
import request from "superagent";
import CircleLoader from "../../Loaders/CircleLoader/CircleLoader";
import { default as MuiButton } from "@mui/material/Button/Button";
import { BluePlus } from "../../../SlideEditor/components/components/IconButtons/SubMenuIcons";
import GetUserTypeIcon from "../../../pages/Public/Auth/components/GetUserTypeIcon";
import ButtonMui from "../../buttons/buttonMui/buttonMui";
import { DialogTypes } from "../../../data/Finance/constants";

class SwitchAccountDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: props.open ? props.open : false,
      loading: true,
      image: null,
      data: {},
      accounts: [],
      SelectedPlan: global.Modeliks.subscriptionInfo
        ? global.Modeliks.subscriptionPlans.find(
            (c) => c.ID === global.Modeliks.subscriptionInfo.SubscriptionID,
          )
        : false,
    };

  }

  getAccounts = (callBack) => {
    this.setState({ open: true, loading: true });
    request
      .post("/api/changeAccount")
      .set("Access-Control-Allow-Origin", window.location.protocol + "//" + window.location.host)
      .set("Accept", "application/json")
      .set("authorization", "Bearer " + window.localStorage.getItem("token"))
      .send({ TimeStamp: new Date().getTime() })
      .then((data) => {
          request
              .get(`/api/get_profile_pic?user_id=${global.Modeliks.User.ID}&v=${new Date().getTime()}&encode=true`)
              .then((c) => {
                  let image = null
                  if (c.body != "no image") {
                      image = "data:" + c.headers["content-type"] + ";base64," + c.text
                  }

                  this.setState({ data: data.body, accounts: data.body.Accounts, loading: false ,image});
                  if (callBack) {
                      callBack(data.body.Accounts);
                  }
              });


      })
        .catch((err) => {
          global.Modeliks.LogOut(() => {
            window.location.replace("https://www.modeliks.com/");
          });
        });
  };

  componentDidMount() {
    this.props.open && this.getAccounts();
  }

  loginWithAccount = (index, accID) => {
    if (global.Modeliks.Account && global.Modeliks.Account.ID === accID) return;
    this.setState({loading: true});
    const findAccount = this.state.accounts.find((a) => a.ID === accID);
    window.localStorage.setItem("User_ID", this.state.data.ID);
    window.localStorage.setItem("token", findAccount.token);
    window.localStorage.setItem("Account_ID", findAccount.ID);
    window.localStorage.removeItem(global.Modeliks.Tables.Finance_CalculatedDrivers.TableName);
    window.localStorage.removeItem(
      "lastGetTime" + global.Modeliks.Tables.Finance_CalculatedDrivers.TableName,
    );
    window.localStorage.removeItem(
      global.Modeliks.Tables.Finance_CalculatedDriver_Values.TableName,
    );
    window.localStorage.removeItem(
      "lastGetTime" + global.Modeliks.Tables.Finance_CalculatedDriver_Values.TableName,
    );
    window.localStorage.removeItem("CompanyScenarioID");
    // global.Modeliks.Account = {ID: (this.state.data.body.Accounts[index].ID)};
    // global.Modeliks.Login(this.state.data.body);
    window.location.reload();
  };

  loginWithLastAccount = (account) => {
    this.setState({ loading: true });
    window.localStorage.setItem("User_ID", this.state.data.ID);
    window.localStorage.setItem("token", account.token);
    window.localStorage.setItem("Account_ID", account.ID);
    window.localStorage.removeItem(global.Modeliks.Tables.Finance_CalculatedDrivers.TableName);
    window.localStorage.removeItem(
      "lastGetTime" + global.Modeliks.Tables.Finance_CalculatedDrivers.TableName,
    );
    window.localStorage.removeItem(
      global.Modeliks.Tables.Finance_CalculatedDriver_Values.TableName,
    );
    window.localStorage.removeItem(
      "lastGetTime" + global.Modeliks.Tables.Finance_CalculatedDriver_Values.TableName,
    );
    window.localStorage.removeItem("CompanyScenarioID");
    // global.Modeliks.Account = {ID: (this.state.data.body.Accounts[index].ID)};
    // global.Modeliks.Login(this.state.data.body);
    window.location.reload();
  };

  handleClose = () => {
    if (!this.props.disableClose) {
      this.props.handleClose && this.props.handleClose();
      this.setState({ open: false });
    }
  };

  createNewAccount = () => {
    request
      .post("/api/insertAccount")
      .send({ User_ID: global.Modeliks.User.ID })
        .then(() => {
          this.getAccounts((accounts) => {
            this.loginWithLastAccount(accounts[accounts.length - 1]);
          });
        })
        .catch((e) => {
          console.error("error adding account", e);
        });
  };

  createNewAccMenuItem = () => <MenuItem className="switch_account" onClick={this.createNewAccount}>
    <div className={"icon_cont"}>
      <BluePlus/>
    </div>
    Create new Account
  </MenuItem>

  getAccountImage = (image = this.state.image) => {
      return image ? <img className='img' src={image} alt={""}/> : <Avatar/>
  }

  render() {
    if (this.props.NoAccount) {
      return this.createNewAccMenuItem()
    }

    return (
        <>
          {!this.props.open && (
              <>
                {global.Modeliks.Account.accounts_number &&
                global.Modeliks.Account.accounts_number > 1 ? (
                  <MenuItem className="switch_account" onClick={() => this.getAccounts()}>
                    <div className={"icon_cont"}>
                      <SwitchAccount/>
                    </div>
                    Switch account
                  </MenuItem>
                ) : (
                  ""
                )}
                {this.createNewAccMenuItem()}
              </>
        )}

        {this.state.open && (
          <Dialog open={true} onClose={this.handleClose} className={"switch_account_dialog"}>
            {this.state.loading && (
              <div className={"accounts_container loader_container"}>
                <CircleLoader />
              </div>
            )}
            {!this.state.loading && (
              <div className="choose_account_title ">
                <span className="Paragraph P1_Strong">Switch account</span>
              </div>
            )}

            {this.state.accounts.length > 0 && !this.state.loading && (
              <div className="accounts_container choose_account">
                {this.state.accounts.map((account, index) => (
                  <MuiButton
                    selected
                    key={"user_acc_i_" + index}
                    onClick={() => {
                        (global.Modeliks.Account && global.Modeliks.Account.ID !== account.ID) &&
                        global.Modeliks.showDialog(
                            "Are you sure you want to switch account?",
                            DialogTypes.WarningActions,
                            null,
                            () => this.loginWithAccount(index, account.ID),
                        );
                    }}
                    type="text"
                    className={"account_card "}
                  >
                    <div className="mail_container">
                      {global.Modeliks.Account && global.Modeliks.Account.ID === account.ID ? (
                          <span className='avatar_icon_badge'>{this.getAccountImage()}<AvatarBadge className='icon_badge_avatar'/></span>
                      ) : (
                          <span className='avatar_icon_badge'>{this.getAccountImage()}</span>
                      )}
                      <span
                        className={`Paragraph P3_Strong ${global.Modeliks.Account && global.Modeliks.Account.ID === account.ID ? "account_active" : "account"}`}
                      >
                        {account.Name ? account.Name : "Account " + Number(index + 1)}
                      </span>
                    </div>
                    <GetUserTypeIcon activeAccount={global.Modeliks.Account && global.Modeliks.Account.ID === account.ID} UserType={account.UserType} />
                  </MuiButton>
                ))}
              </div>
            )}
            {!this.state.loading && this.props.enableCreateAccount && (
              <DialogActions>
                <ButtonMui
                  onClick={this.createNewAccount}
                  label={"Create new Account"}
                  variant={"text"}
                  roundnessType={"squared"}
                />
              </DialogActions>
            )}
          </Dialog>
        )}
      </>
    );
  }
}

export default SwitchAccountDialog;
