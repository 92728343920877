import React, { Fragment, useState } from "react";
import "../HeaderTextEditor.scss";
import "../HeaderDefault.scss";
import Menu from "@mui/material/Menu";
import {
  LineEnd,
  LineEnd1,
  LineEnd2,
  LineEnd3,
  LineEnd4,
  LineEnd5,
  LineEnd6,
  LineEnd7,
  LineEnd8,
} from "../../components/IconButtons/SubMenuIcons";

function LineEndEditComponent(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openLineEnd = Boolean(anchorEl);
  const handleClick = (event) => {
    if (!openLineEnd) setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onChange = (style) => {
    props.onChange({ markerEnd: style });
    setAnchorEl(null);
  };
  const LineEndEdit = () => {
    return (
      <>
        <div
          className="SubMenuButton"
          style={{ height: "auto", display: "flex", alignItems: "center" }}
          onClick={handleClick}
        >
          <span id="tooltipSubMenuButton">Line End</span>
          <LineEnd
            active={openLineEnd}
            aria-controls={openLineEnd ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openLineEnd ? "true" : undefined}
          />
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openLineEnd}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <div
              style={{
                paddingLeft: "8px",
                paddingRight: "8px",
                color: "#B9BFC3",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <LineEnd1 onChange={onChange} id={"MenuItems"} />
              <LineEnd2 onChange={onChange} id={"MenuItems"} />
              <LineEnd3 onChange={onChange} id={"MenuItems"} />
              <LineEnd4 onChange={onChange} id={"MenuItems"} />
              <LineEnd5 onChange={onChange} id={"MenuItems"} />
              <LineEnd6 onChange={onChange} id={"MenuItems"} />
              <LineEnd7 onChange={onChange} id={"MenuItems"} />
              <LineEnd8 onChange={onChange} id={"MenuItems"} />
            </div>
          </Menu>
        </div>
      </>
    );
  };

  return <>{LineEndEdit()}</>;
}

export default LineEndEditComponent;
