import React, { createRef } from "react";
import {
  EditIcon,
  CopyIcon,
  DeleteIcon,
  HideIcon,
  MoveIcon,
  Tick,
  UnhideIcon,
} from "./Icons/LeftMenuIcons";
import "./SectionTab.scss";
import Tooltip from "@mui/material/Tooltip";

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    iconPosition: "top",
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

class SectionTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: props.sectionName,
      editMode: false,
    };

    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.props.tabFunctions.closeTabFunctions.push(this.closeUpdate);
    document.addEventListener("keydown", this.onKeyPress, false);
    document.addEventListener("click", this.onClickOutside, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyPress, false);
    document.removeEventListener("click", this.onClickOutside, false);
  }

  onClickOutside = (event) => {
    const clickedElement = event.target;
    const container = this.containerRef.current;
    const scrollbarElement = document.querySelector(".MuiTabs-scrollableY");

    if (
      container &&
      container !== clickedElement &&
      !container.contains(clickedElement) &&
      container.parentElement !== clickedElement &&
      scrollbarElement !== clickedElement
    ) {
      this.closeUpdate();
    }
  };

  onKeyPress = (e) => {
    if (this.state.editMode) {
      if (e.key === "Escape") {
        this.closeUpdate();
      } else if (e.key === "Enter") {
        this.updateName(() => this.changeEditMode());
        e.stopPropagation();
      }
    }
  };
  closeUpdate = () => {
    this.setState({ editMode: false, name: this.props.sectionName });
  };

  updateName = (callBack) => {
    if (this.state.name) {
      if (this.props.sectionName !== this.state.name) {
        this.props.updateSection(this.state.name.trim(), () => callBack());
      } else {
        callBack();
      }
    } else {
      this.setState({ name: this.props.sectionName }, () => {
        callBack();
      });
    }
  };

  changeEditMode = () => {
    this.state.editMode = !this.state.editMode;
    this.forceUpdate();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.sectionName !== this.props.sectionName) {
      this.setState({ name: this.props.sectionName, editMode: false });
    }
  }

  render() {
    return (
      <div className="tab_items_container" ref={this.containerRef}>
        {!this.state.editMode && (
          <>
            {" "}
            <span className={"tab_name" + this.props.className}>{this.state.name}</span>
            {!this.props.disableEdit && (
              <div className={"btns_container"} onClick={(e) => e.stopPropagation()}>
                {!this.props.isAdmin && (
                  <Tooltip title={"Rename"}>
                    <div className={"svg_btn"} onClick={() => this.changeEditMode()}>
                      <EditIcon />
                    </div>
                  </Tooltip>
                )}
                <Tooltip title={"Move"}>
                  <div className={"svg_btn"} onMouseDown={this.props.mouseDownHandler}>
                    <MoveIcon />
                  </div>
                </Tooltip>
                {(!this.props.isAdmin || this.props.enableDuplicate) &&
                  !this.props.hideDuplicate && (
                    <Tooltip title={"Duplicate"}>
                      <div className={"svg_btn"} onClick={this.props.handleDuplicate}>
                        <CopyIcon />
                      </div>
                    </Tooltip>
                  )}
                {!this.props.hideHiddenProperty && !this.props.isAdmin && (
                  <Tooltip title={this.props.isHidden ? "Unhide" : "Hide"}>
                    <div className={"svg_btn"} onClick={this.props.hideSection}>
                      {this.props.isHidden ? <UnhideIcon /> : <HideIcon />}
                    </div>
                  </Tooltip>
                )}
                {!this.props.disableDelete && (
                  <Tooltip title={"Delete"}>
                    <div className={"svg_btn"} onClick={() => this.props.deleteSection()}>
                      <DeleteIcon />
                    </div>
                  </Tooltip>
                )}
              </div>
            )}
          </>
        )}
        {this.state.editMode && (
          <div className={"input_container"}>
            <input
              className={"section_name_input"}
              onMouseDown={(e) => e.stopPropagation()}
              onClick={(e) => e.stopPropagation()}
              defaultValue={this.state.name}
              onKeyDown={(e) => {
                if (e.key !== "Enter" && e.key !== "Escape") {
                  e.stopPropagation();
                }
              }}
              onChange={(e) => this.setState({ name: e.target.value })}
              placeholder={this.state.name}
            />
            <Tooltip title={"Save"}>
              <div
                onClick={(e) => {
                  this.updateName(() => this.changeEditMode());
                  e.stopPropagation();
                }}
              >
                <Tick />
              </div>
            </Tooltip>
          </div>
        )}
      </div>
    );
  }
}

export default SectionTab;
