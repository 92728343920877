import * as React from "react";
import Mx_NumInput from "../../actions/Mx_NumInput";
import { PropTypes } from "react";
import { RightArrow } from "../../icons/svgIcons";
import { IconButton, TableCell } from "@mui/material";
import SimpleDialog from "../../dialogs/simpleDialog/SimpleDialog";
import { UnitTypes } from "../../../data/Finance/CalculatedDriver/constants";
import { NumberFormatTypes } from "../../constants/charts";
import { InputFormats } from "../../constants/finance";

class FinanceTableCell extends React.Component {
  autoFillFunctionObj = {};

  constructor(props) {
    super(props);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.state = {
      mouseOver: false,
    };
  }

  handleKeyDown = (event) => {
    this.props.handleOnKeyDown(event);
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.isThisCellSelected !== this.props.isThisCellSelected ||
      this.props.cellCount !== nextProps.cellCount ||
      // this.currValue!==nextProps.item[this.field] ||
      this.props.newKey !== nextProps.newKey ||
      this.props.isNextCellSelected !== nextProps.isNextCellSelected ||
      this.props.useShowHideCurrency !== nextProps.useShowHideCurrency ||
      nextProps.singlySelectedCell !== this.props.singlySelectedCell ||
      this.props.value !== nextProps.value ||
      nextProps.customStyle !== this.props.customStyle ||
      this.props.displayOfDecimals !== nextProps.displayOfDecimals ||
      (this.props.unitDisplayFormat !== nextProps.unitDisplayFormat &&
        !this.props.IsNumberFormatDisabled)
    ) {
      if (
        nextProps.isThisCellSelected &&
        this.props.isThisCellSelected == false &&
        global.Modeliks.debug.printCellData
      ) {
        console.log(this.props.item);
      }
      return true;
    } else {
      return false;
    }
  }

  autoFill = (value = true) => {
    if (this.autoFillFunctionObj.open) {
      this.autoFillFunctionObj.open(value);
    }
  };

  mouseUp = () => {
    this.props.onMouseUp();
    document.removeEventListener("mouseup", this.mouseUp);
  };

  selectedCell = (colIndex, rowIndex) => {
    return (
      this.props.selectedCell.colIndex === colIndex && this.props.selectedCell.rowIndex === rowIndex
    );
  };

  preventAndStopEvents = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  render() {
    if (this.props.useActual) {
      this.currValue = this.props.item.Actual;
    } else {
      this.currValue = this.props.useDateIndexes
        ? this.props.item.Value
        : this.props.item[this.props.row.field];
    }
    const showCurrency = this.props.useShowHideCurrency;
    this.field = this.props.row.field;
    if (this.props.item) {
      const {
        item,
        row,
        isThisCellSelected,
        isNextCellSelected,
        indexTab,
        rowIndex,
        colIndex,
        textColor,
        headers,
      } = this.props;
      const cssClass = getClassName(
        item,
        row,
        isThisCellSelected,
        isNextCellSelected,
        indexTab,
        rowIndex,
        colIndex,
        textColor,
        headers,
        this.currValue,
      );
      const notEditable = this.props.useDashboards === true || this.props.row.isNotEditable;
      const isActualEditable =
        this.props.item.db_record && this.props.item.evalFormulaActual === null;
      const isEditable =
        notEditable && notEditable !== undefined
          ? false
          : this.props.useActual
            ? isActualEditable
            : this.props.item.IsSimple;
      const IsFormulaEditable = row.data.IsFormulaEditable === false && !this.props.useActual;
      return (
        <TableCell
          onMouseEnter={this.props.onMouseEnter}
          onCopy={(e) => navigator.clipboard.writeText(this.props.item[this.props.row.field])}
          key={"tc_" + this.props.newKey + this.props.item.ID}
          className={cssClass}
          onMouseDown={(e) => {
            if (!this.state.mouseOver && !isEditable && this.props.useCustomizableTable) {
              this.state.mouseOver = true;
            }
            if (!this.props.disabledEdit) {
              this.props.setCurrentTable();
              document.addEventListener("mouseup", this.mouseUp);
              this.props.onMouseDown(this.props.colIndex, this.props.rowIndex, e);
            }
          }}
          onMouseLeave={(e) => {
            this.preventAndStopEvents(e);
            if (this.state.mouseOver && !isEditable && this.props.useCustomizableTable) {
              this.state.mouseOver = false;
            }
          }}
          tabIndex={
            this.props.singlySelectedCell
              ? -1
              : this.props.indexTab
                ? this.props.indexTab + this.props.cellCount
                : this.props.cellCount
          }
          onFocus={() =>
            !this.props.disabledEdit &&
            this.props.handleOnCellSelected(this.props.colIndex, this.props.rowIndex)
          }
        >
          <div className={`mct_ft_tablebody_cell_div `}>
            {!this.props.disabledEdit &&
              isEditable &&
              this.props.item.cur_record.Unit !== UnitTypes.Hide &&
              !IsFormulaEditable && (
                <>
                  <div
                    onClick={this.preventAndStopEvents}
                    onMouseDown={(e) => {
                      this.isValid = this.props.row.data.isValid;
                      if (this.isValid) {
                        this.preventAndStopEvents(e);
                      }
                    }}
                    onMouseUp={this.preventAndStopEvents}
                    className={"autofill_container"}
                  >
                    <IconButton
                      onClick={(e) => {
                        if (this.isValid) {
                          this.autoFill();
                        } else {
                          this.props.onAutoFill();
                        }
                      }}
                      className={"autofill_btn"}
                    >
                      <RightArrow />
                    </IconButton>
                  </div>
                  <SimpleDialog
                    buttons={[
                      {
                        label: "No",
                        backgroundColor: "red150",
                        onClick: () => this.autoFill(false),
                      },
                      {
                        label: "Yes",
                        onClick: () => {
                          this.autoFill(false);
                          this.props.onAutoFill();
                        },
                      },
                    ]}
                    functionObject={this.autoFillFunctionObj}
                    text={"Paste the value to the end?"}
                  />
                </>
              )}
            <Mx_NumInput
              level={
                this.props.row.hasOwnProperty("applyBoldWeight") && this.props.row.applyBoldWeight
                  ? this.props.level + 1
                  : this.props.level
              }
              update={() => this.forceUpdate()}
              selected={this.props.singlySelectedCell}
              disabled={this.props.disabled || this.props.item.disabled || IsFormulaEditable}
              data={this.currValue}
              mouseOver={this.state.mouseOver}
              onChange={(value) =>
                this.props.onCellChanged(value, this.props.item, this.props.row.field)
              }
              lastMouseSelected={
                this.props.lastMouseSelected.colIndex === this.props.colIndex &&
                this.props.lastMouseSelected.rowIndex === this.props.rowIndex
              }
              unit={this.props.unit}
              hideCurrency={showCurrency}
              unitNegative={this.props.unitNegative}
              hidden={this.props.hidden}
              min={this.props.min}
              max={this.props.max}
              field={this.field}
              IsReport={this.props.IsReport}
              IsNumberFormatDisabled={this.props.IsNumberFormatDisabled}
              autoFocus
              allowNaNValue={headers[colIndex].Header.includes("%")}
              showNaNValue={this.props.showNaNValue}
              EditStream={this.props.EditStream}
              displayOfDecimals={this.props.displayOfDecimals}
              unitDisplay={this.props.unitDisplay}
              boldRow={
                this.props.row.hasOwnProperty("boldRowHeader") &&
                this.props.row.boldRowHeader == true
              }
              tabIndex={
                this.props.indexTab
                  ? this.props.indexTab + this.props.cellCount
                  : this.props.cellCount
              }
              unitLeft={this.props.row.unitLeft}
              shouldBeEditable={true}
              isEditable={isEditable}
              customStyle={`${this.props.customStyle} ${!(this.props.disabled || this.props.item.disabled) && this.props.singlySelectedCell && isEditable && !this.props.selectedCells.length > 0 && this.currValue && InputFormats.formatNumber(this.currValue).toString().length > 7 ? "mct_ft_table_full_numbers_editing" : ""}`}
              editableColor={this.props.editableColor}
              isMultipleSelected={this.props.selectedCells.length > 0}
            />
          </div>
        </TableCell>
      );
    } else {
      return (
        <TableCell
          onKeyDown={this.props.handleOnKeyDown}
          key={"tc_" + this.props.colIndex}
          onClick={() => this.props.handleOnCellSelected(this.props.colIndex, this.props.rowIndex)}
          tabIndex={this.props.cellCount}
          className={"mct_ft_tablebody_cell"}
        >
          <div className="mct_ft_tablebody_cell_div"></div>
        </TableCell>
      );
    }
  }
}
export default FinanceTableCell;

const getClassName = (
  item,
  row,
  isThisCellSelected,
  isNextCellSelected,
  indexTab,
  rowIndex,
  colIndex,
  textColor,
  headers,
  curValue,
) => {
  let cssClassName = "";
  let cssClass =
    item.PeriodType === "month"
      ? isThisCellSelected
        ? "mct_ft_tablebody_cell selected"
        : "mct_ft_tablebody_cell"
      : isThisCellSelected
        ? "mct_ft_tablebody_cell selected"
        : "mct_ft_tablebody_cell mct_ft_year";

  if (item.error && row.hasOwnProperty("data") && item.error.indexOf(row.data.Ref_Field) > -1) {
    cssClass += " mct_ft_tablebody_cell_error";
  }

  if (item.error && item.error.indexOf(row.field) > -1) {
    cssClass += " mct_ft_tablebody_cell_error";
  }

  if (item.mainTableError) {
    cssClass += " mct_ft_tablebody_cell_error";
  }

  if (isThisCellSelected) {
    cssClass += isNextCellSelected;
  }

  if (row && row.borderErrors) {
    if (item.Value < 0) {
      cssClass += " mct_ft_tablebody_cell_error";
    }
  }

  cssClass += ` t_${indexTab}-${rowIndex}_${colIndex}`;

  if (textColor) {
    if (headers[colIndex].Header.includes(global.Modeliks.CompanyInfo.Currency.value)) {
      if (curValue > 0) {
        if (row.colorType.positiveGreen) {
          cssClass += " mct_ft_textColor_success";
        } else {
          cssClass += " mct_ft_textColor_warning";
        }
      }

      if (curValue < 0) {
        if (row.colorType.positiveGreen) {
          cssClass += " mct_ft_textColor_warning";
        } else {
          cssClass += " mct_ft_textColor_success";
        }
      }
    } else if (headers[colIndex].Header.includes("%")) {
      const item = row.data.getItemByDateSufix(headers[colIndex - 1].sufix);
      if (item) {
        if (item.Actual > 0) {
          if (row.colorType.positiveGreen) {
            cssClass += " mct_ft_textColor_success";
          } else {
            cssClass += " mct_ft_textColor_warning";
          }
        }

        if (item.Actual < 0) {
          if (row.colorType.positiveGreen) {
            cssClass += " mct_ft_textColor_warning";
          } else {
            cssClass += " mct_ft_textColor_success";
          }
        }
      }
    }
  }
  return cssClass;
};
