import React, { Component } from "react";
import PropTypes from "prop-types";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import ButtonMui from "../../../buttons/buttonMui/buttonMui";
import "./exceededLimit.scss";

class DowngradeSubscription extends Component {
  constructor(props) {
    super(props);

    this.subscriptionPlans = this.props.subscriptionPlans;
  }

  getSavings = () => {
    let monthlySubscription = this.subscriptionPlans.newPlan.recurring_price;
    let yearlySubscription = this.subscriptionPlans.oldPlan.recurring_price / 12;
    if (monthlySubscription && yearlySubscription) {
      return (
        Math.round(
          (Math.abs(monthlySubscription - yearlySubscription) / monthlySubscription) * 100,
        ) + "%"
      );
    }

    return "";
  };

  getContent = () => {
    const data_key =
      (this.subscriptionPlans.oldPlan.strength % 3) +
      "_" +
      (this.subscriptionPlans.newPlan.strength % 3);

    if (
      this.subscriptionPlans.oldPlan.strength % 3 ===
      this.subscriptionPlans.newPlan.strength % 3
    ) {
      return [];
    }
    return content(this.subscriptionPlans)[data_key];
  };
  render() {
    let current_content = this.getContent();
    let replacedContent = "";

    let lastItemContent = "";

    if (current_content) {
      lastItemContent = current_content[current_content.length - 1];
    } else {
      current_content = [];
    }
    if (lastItemContent) {
      replacedContent = lastItemContent.replace(
        "[xx]",
        global.Modeliks.subscriptionInfo.TotalUsers - global.Modeliks.subscriptionInfo.users_number,
      );
    }

    const dictionary = {
      year: "an annual",
      month: "a monthly",
    };

    return (
      <>
        <DialogTitle className={"downgrade_subscription_title"}>
          Downgrade {this.subscriptionPlans.oldPlan.data_key.toUpperCase()}{" "}
          {subsTitleDictionary[this.subscriptionPlans.oldPlan.billing_type]} to{" "}
          <span className={"blue"}>
            {this.subscriptionPlans.newPlan.data_key.toUpperCase()}{" "}
            {subsTitleDictionary[this.subscriptionPlans.newPlan.billing_type]}
          </span>
        </DialogTitle>
        <DialogContent className={"downgrade_subscription"}>
          {this.subscriptionPlans.oldPlan.billing_type !=
          this.subscriptionPlans.newPlan.billing_type ? (
            <div className={"p1"}>
              When downgrading your subscription plan from{" "}
              {dictionary[this.subscriptionPlans.oldPlan.billing_type]} to{" "}
              {dictionary[this.subscriptionPlans.newPlan.billing_type]} plan, also your existing
              add-ons will be changed to {dictionary[this.subscriptionPlans.newPlan.billing_type]}{" "}
              billing. Your change will be effective immediately and any existing credit is setoff
              against your billing. There will be no refunds.
            </div>
          ) : (
            <div className={"p1"}>
              When downgrading your subscription plan, your change will be effective immediately.
              Please ensure you don’t lose any important data, in case the new plan is missing some
              of the features you will require in the future.
            </div>
          )}
          {this.subscriptionPlans.oldPlan.billing_type == "year" &&
            this.subscriptionPlans.newPlan.billing_type == "month" &&
            this.subscriptionPlans.oldPlan.strength == this.subscriptionPlans.newPlan.strength && (
              <div className={"p1"}>
                <b>With the downgrade</b> to the monthly plan, <b>you will lose</b> your annual
                subscription savings of {this.getSavings()}!
              </div>
            )}
          {this.subscriptionPlans.oldPlan.strength != this.subscriptionPlans.newPlan.strength && (
            <div className={"p1 bold"}>With the downgrade you will lose:</div>
          )}
          <div className={"flex_cont"}>
            <div>
              {current_content.map((c, index) =>
                index === current_content.length - 1 ? "" : <p className={"item"}>{c}</p>,
              )}
            </div>
            <Smiley />
          </div>

          <div className={"p1"}>{replacedContent}</div>

          <div className={"p1 bold"}>
            Do you still want to downgrade from{" "}
            <span className={"blue"}>
              {this.subscriptionPlans.oldPlan.data_key.toUpperCase()}{" "}
              {subsTitleDictionary[this.subscriptionPlans.oldPlan.billing_type]}
            </span>{" "}
            to{" "}
            <span className={"blue"}>
              {this.subscriptionPlans.newPlan.data_key.toUpperCase()}{" "}
              {subsTitleDictionary[this.subscriptionPlans.newPlan.billing_type]}
            </span>
            ?
          </div>
        </DialogContent>
        <DialogActions className={"downgrade_subscription_actions"}>
          <ButtonMui
            width={132}
            variant={"contained"}
            label={"Cancel"}
            onClick={this.props.onCancel}
            color={"primary"}
          />
          <ButtonMui
            width={132}
            variant={"contained"}
            label={"Confirm"}
            onClick={this.props.onAccept}
            color={"red"}
          />
        </DialogActions>
      </>
    );
  }
}

const getConditionalText = () => {
  if (
    global.Modeliks.subscriptionInfo.companies_number ==
    global.Modeliks.subscriptionInfo.TotalCompanies
  ) {
    return "";
  }
  return "Additionally, your add-on plans will be downgraded respectively with immediate effect to the lower plan, too";
};

export const subsTitleDictionary = {
  year: "(Annually)",
  month: "(Monthly)",
};

const content = (subscriptionPlans) => {
  const extraUsers =
    global.Modeliks.subscriptionInfo.TotalUsers - global.Modeliks.subscriptionInfo.users_number;
  const differenceUsers =
    subscriptionPlans.oldPlan.users_number - subscriptionPlans.newPlan.users_number;

  const totalCompanies = global.Modeliks.subscriptionInfo.TotalCompanies;

  const differenceCompanies =
    subscriptionPlans.oldPlan.companies_number - subscriptionPlans.newPlan.companies_number;

  return {
    "0_2": [
      "• Plan vs. actual reporting",
      "• Predefined dashboards",
      "• Custom dashboards",
      "• One-click investor reports",
      "• Ratio analysis",
      `• ${differenceUsers} users`,
      `• ${differenceCompanies} companies`,
      "\n",
      `Please note that with this change you are only eligible for ${totalCompanies - differenceCompanies} companies and only ${subscriptionPlans.newPlan.users_number + extraUsers} users will have access to the app. For managing companies or users, please go to the Companies respectively Users Section. ${getConditionalText()}`,
    ],
    // 'Please note that with this change only 5 users (account owner + 4 user) + [xx] additional users will have access to the app. For managing users please go to the User Section..'],
    "0_1": [
      "• Driver-based financial planning\n",
      "• Financial model templates by industry\n",
      "• Plan vs. actual reporting\n",
      "• Predefined dashboards\n",
      "• Custom dashboards\n",
      "• One-click investor reports\n",
      "• Ratio analysis\n",
      `• ${differenceUsers} users`,
      `• ${differenceCompanies} companies`,
      "\n",
      `Please note that with this change you are only eligible for ${totalCompanies - differenceCompanies} companies and only ${subscriptionPlans.newPlan.users_number + extraUsers} users will have access to the app. For managing companies or users, please go to the Companies respectively Users Section. ${getConditionalText()} 
            In the Forecast module any existing driver-based formulas will be non-editable.\n
            `,
    ],
    "2_1": [
      "• Driver-based financial planning\n",
      "• Financial model templates by industry\n",
      `• ${differenceUsers} users`,
      `• ${differenceCompanies} companies`,
      "\n",
      `Please note that with this change you are only eligible for ${totalCompanies - differenceCompanies} companies and only ${subscriptionPlans.newPlan.users_number + extraUsers} users will have access to the app. For managing companies or users, please go to the Companies respectively Users Section. ${getConditionalText()}\nIn the Forecast module any existing driver-based formulas will be non-editable.`,
    ],
  };
};

const Smiley = () => (
  <svg
    width="166"
    height="166"
    viewBox="0 0 166 166"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M58.6413 70.3696C65.1187 70.3696 70.3696 65.1187 70.3696 58.6413C70.3696 52.164 65.1187 46.9131 58.6413 46.9131C52.164 46.9131 46.9131 52.164 46.9131 58.6413C46.9131 65.1187 52.164 70.3696 58.6413 70.3696Z"
      fill="#01AFFD"
    />
    <path
      d="M107.359 70.3696C113.836 70.3696 119.087 65.1187 119.087 58.6413C119.087 52.164 113.836 46.9131 107.359 46.9131C100.881 46.9131 95.6304 52.164 95.6304 58.6413C95.6304 65.1187 100.881 70.3696 107.359 70.3696Z"
      fill="#01AFFD"
    />
    <path
      d="M83 166C37.2314 166 0 128.769 0 83C0 37.2314 37.2314 0 83 0C128.769 0 166 37.2314 166 83C166 128.769 128.769 166 83 166ZM83 11.8571C43.7529 11.8571 11.8571 43.7529 11.8571 83C11.8571 122.247 43.7529 154.143 83 154.143C122.247 154.143 154.143 122.247 154.143 83C154.143 43.7529 122.247 11.8571 83 11.8571Z"
      fill="#01AFFD"
    />
    <path
      d="M116.744 124.471C114.241 124.471 111.976 122.908 111.142 120.504C109.092 114.632 105.285 109.547 100.247 105.949C95.2087 102.351 89.1875 100.419 83.0142 100.419C76.8408 100.419 70.8196 102.351 65.7814 105.949C60.7433 109.547 56.9365 114.632 54.8863 120.504C53.8136 123.629 50.3572 125.312 47.2584 124.11C46.5162 123.851 45.8331 123.445 45.2493 122.915C44.6656 122.385 44.193 121.743 43.8597 121.026C43.5264 120.308 43.339 119.531 43.3086 118.74C43.2782 117.948 43.4055 117.159 43.6828 116.418C49.5229 99.591 65.3747 88.4131 83.0142 88.4131C100.654 88.4131 116.505 99.7112 122.346 116.418C122.668 117.325 122.77 118.297 122.642 119.253C122.515 120.208 122.162 121.118 121.613 121.907C121.064 122.696 120.335 123.341 119.488 123.787C118.641 124.233 117.7 124.468 116.744 124.471Z"
      fill="#01AFFD"
    />
  </svg>
);

DowngradeSubscription.propTypes = {
  subscriptionPlans: PropTypes.object,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
};

export default DowngradeSubscription;
