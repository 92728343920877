import React from "react";
import CalculatedDriver from "../../../Financials/components/CalculatedDriver/CalculatedDriver";
import InfoDialog from "../../../../../components/dialogs/financials/InfoDialog/InfoDialog";
import DialogContent from "@mui/material/DialogContent";
import Input from "../../../../../components/actions/Input";
import { Label } from "../../../../../components/typography/label/Label";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "../../../../../components/actions/IconButton";
import { IconX, IconI } from "../../../../../components/icons/svgIcons";
import {
  DriverCategories,
  DriverCategoriesSelect,
  UnitTypes,
} from "../../../../../data/Finance/CalculatedDriver/constants";
import CircleCheckboxM from "../../../../../components/actions/CircleCheckboxM";
import MaterialSelect from "../../../../../components/actions/SelectM";
import "../components/CustomKPIMenu.scss";

export default class AddCustomIndicator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      key: new Date().getTime(),
      error: false,
      driver: null,
    };
  }
  componentDidMount() {
    if (this.props.editCustomIndicator) {
      this.setState({ driver: this.props.editCustomIndicator });
    } else {
      const driver = this.props.KPI.createCustomIndicator();
      this.setState({ driver });
    }
  }

  setFormula = (formula) => {
    if (this.state.driver.DriverName.length > 2) {
      if (formula) {
        this.state.driver.setFormula(formula, false, true, true);

        // this.state.driver.Values.forEach(v => {
        //     if(v.Formula){
        //         v.Formula = `Actual(${v.Formula.replaceAll('|', ';')}) `;
        //     }
        // })
        this.props.addCustomIndicator(this.state.driver);
      } else {
        this.state.driver.removeFormula();
        this.props.addCustomIndicator(this.state.driver);
      }
    } else {
      this.setState({ error: true });
    }
  };

  onChangeName = (e) => {
    this.state.error = false;
    this.state.driver.DriverName = e.target.value;
    this.forceUpdate();
  };

  changeUnitType = (e) => {
    this.state.driver.UnitType = e.target.value;
    this.state.driver.error = "";
    if (this.state.driver.hasOwnProperty("ID")) {
      this.state.driver.changeDriverTypeAndCategory(
        e.target.value,
        this.state.driver.DriverCategory,
      );
    }
    this.forceUpdate();
  };

  changeCategory = (e) => {
    this.state.driver.DriverCategory = e.target.value;
    this.state.driver.error = "";
    if (this.state.driver.hasOwnProperty("ID")) {
      this.state.driver.changeDriverTypeAndCategory(this.state.driver.UnitType, e.target.value);
    }
    this.forceUpdate();
  };

  onCloseFormula = () => {
    if (!this.props.editCustomIndicator) {
      global.Modeliks.DriversStore.remove(this.state.driver);
    }
    this.props.onClose();
  };

  render() {
    if (!this.state.driver) {
      return;
    }

    return (
      <InfoDialog
        key={this.state.driver.Ref_ID}
        onClose={this.onCloseFormula}
        open={this.props.open}
      >
        <DialogTitle className="spsccc_indicator_main_dialog fdd_calculated_driver_object">
          <div className="spsccc_indicator_name_children ">
            <Label label={"Name of Custom Indicator"} />
            <div className="sad_input">
              <Input
                placeholder="Please enter name..."
                value={this.state.driver.DriverName}
                error={this.state.error}
                errorMessage={this.state.error && "Name is required"}
                onChange={this.onChangeName}
              />
            </div>
            <span className="fdd_cd_object_data_name data_type">Data Type</span>
            <IconI />
            <div className="fdd_cd_checkbox_div">
              <CircleCheckboxM
                onChange={this.changeUnitType}
                value={UnitTypes.Price}
                label={global.Modeliks.CompanyInfo.Currency.value}
                size="medium"
                checked={this.state.driver.UnitType === UnitTypes.Price}
              />
            </div>
            <div className="fdd_cd_checkbox_div">
              <CircleCheckboxM
                onChange={this.changeUnitType}
                value={UnitTypes.Percentage}
                label="%"
                size="medium"
                checked={this.state.driver.UnitType === UnitTypes.Percentage}
              />
            </div>
            <div className="fdd_cd_checkbox_div">
              <CircleCheckboxM
                onChange={this.changeUnitType}
                value={UnitTypes.Units}
                label="#"
                size="medium"
                checked={this.state.driver.UnitType === UnitTypes.Units}
              />
            </div>

            <span className="fdd_cd_object_data_name category">Category</span>
            <IconI />
            {/*<MaterialSelect/>*/}
            <div className="fdd_cd_select_div">
              <MaterialSelect
                value={this.state.driver.DriverCategory}
                // disabled={!isDisabled ? !isDisabled && driver.db_record : true}
                onChange={this.changeCategory}
                width={"150px"}
                options={DriverCategoriesSelect.concat({
                  value: DriverCategories.WeightedAverage,
                  description: "Weighted Average",
                })}
              />
            </div>
            {/*<div className='fdd_cd_checkbox_div'><CircleCheckboxM onChange={this.changeCategory}  label='avg.' size='medium' value={DriverCategories.Average} checked={this.state.driver.DriverCategory === DriverCategories.Average} /></div>*/}
            {/*<div className='fdd_cd_checkbox_div'><CircleCheckboxM onChange={this.changeCategory}  label='sum' size='medium'  value={DriverCategories.Sum} checked={this.state.driver.DriverCategory === DriverCategories.Sum} /></div>*/}
          </div>
          <IconButton size="small" onClick={this.onCloseFormula}>
            <IconX />
          </IconButton>
        </DialogTitle>
        <DialogContent className="pscf_single_finance_tab" dividers>
          <CalculatedDriver
            IsKPI={this.props.IsKPI}
            CalculatedDriver={this.state.driver}
            formula={this.state.driver.Formula}
            setFormula={this.setFormula}
            drivers={this.state.driver.getChildDrivers() ? this.state.driver.getChildDrivers() : []}
            title={this.state.driver.DriverName}
            customIndicator={true}
          />
        </DialogContent>
      </InfoDialog>
    );
  }
}
