import React, { Fragment, useState } from "react";
// import RightMenuPortal from "./RightMenuPortal.js";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import MaterialSelect from "../../../.././components/actions/SelectM";
import { FontSizeOptions } from "../../../components/SlideObjects/ChartComponents/FontSizeOptions";
import Input from "../../../../components/actions/Input";

function ChartTitleAndSubtitle(props) {
  const [openChartTitleAndSubtitle, setOpenChartTitleAndSubtitle] = React.useState(false);
  const [chartTitle, setChartTitle] = React.useState(props.title);

  const handleClickChartTitleAndSubtitle = () => {
    if (openChartTitleAndSubtitle) {
      setOpenChartTitleAndSubtitle(false);
    } else {
      setOpenChartTitleAndSubtitle(true);
      props.rightMenuFunc.closeTitleAndSubtitle = null;
      Object.values(props.rightMenuFunc).map((value) => {
        if (typeof value === "function") {
          value.call();
        }
      });
    }
  };

  const closeMenu = () => {
    setOpenChartTitleAndSubtitle(false);
  };

  props.rightMenuFunc.closeTitleAndSubtitle = closeMenu;

  const handleTopChange = (top) => {
    props.onChange(props.left, Number(top));
  };
  const handleLeftChange = (left) => {
    props.onChange(Number(left), props.top);
  };

  const handleShowTitle = (e) => {
    props.onPropsChange({ showTitle: e.target.checked });
  };
  const handleShowHeadlineNumbers = (e) => {
    props.onPropsChange({ showHeadlineNumbers: e.target.checked });
  };

  const handleTitlePosition = (e) => {
    props.onPropsChange({ titleAlign: e.target.value });
  };

  const handleShowSubtitle = (e) => {
    props.onPropsChange({ showSubtitle: e.target.checked });
  };

  const handleTitleFontSizeChange = (e) => {
    props.onPropsChange({ titleFontSize: e.target.value });
  };

  const handleSubTitleFontSizeChange = (e) => {
    props.onPropsChange({ subTitleFontSize: e.target.value });
  };

  const onChartTitleChange = (e) => {
    setChartTitle(e.target.value);
    props.onPropsChange({ title: e.target.value });
  };

  return (
    <>
      <ListItemButton id={"listItem"} onClick={handleClickChartTitleAndSubtitle}>
        <ListItemText primary="Chart Title & Subtitle" />
        {openChartTitleAndSubtitle ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <div style={{ width: "230px", border: "1px solid #F0F1F7", marginLeft: "18px" }} />
      <Collapse in={openChartTitleAndSubtitle} timeout="auto" unmountOnExit>
        <div className={"collapse"}>
          {/*<div className={'listSubHeading'}>Chart title</div>*/}
          {/*<Input value={chartTitle} onChange={onChartTitleChange}/>*/}
          <div className={"listRow"}>
            <input
              type="checkbox"
              id={"chk"}
              style={{ margin: 0, marginRight: "4px" }}
              onChange={handleShowTitle}
              checked={props.showTitle}
            />
            <div className={"listSubHeading"}>Show chart title</div>
          </div>
          <div className={"listRow"}>
            <MaterialSelect
              value={props.titleAlign}
              options={[
                { value: "start", name: "Left", description: "Left" },
                { value: "middle", name: "Middle", description: "Middle" },
                { value: "end", name: "Right", description: "Right" },
              ]}
              onChange={handleTitlePosition}
            />
          </div>
          <div className={"listRow"}>
            <input
              type="checkbox"
              style={{ margin: 0, marginRight: "4px" }}
              onChange={handleShowSubtitle}
              checked={props.showSubtitle}
            />
            <div className={"listSubHeading"}>Show subtitle</div>
          </div>
          {/*<div className={'listRow'}>*/}
          {/*<input type="checkbox" id={'chk'} style={{margin: 0, marginRight: '4px'}} onChange={handleShowHeadlineNumbers} checked={props.showHeadlineNumbers}/>*/}
          {/*<div className={'listSubHeading'}>Show Headline Numbers</div>*/}
          {/*</div>*/}
          <div className={"listRow"}>
            <label className={"listSubHeading"}>Title font size: </label>
            <MaterialSelect
              value={props.titleFontSize}
              options={FontSizeOptions}
              onChange={handleTitleFontSizeChange}
            />
          </div>
          <div className={"listRow"}>
            <label className={"listSubHeading"}>Subtitle font size: </label>
            <MaterialSelect
              value={props.subTitleFontSize}
              options={FontSizeOptions}
              onChange={handleSubTitleFontSizeChange}
            />
          </div>
        </div>
      </Collapse>
    </>
  );
}
export default ChartTitleAndSubtitle;
