import MxDataArray from "../../MxDataArray";
import Expense from "../Expense/index";
import CostSales from "../CostSales";
import Revenue from "../Revenue";
import ExpensesDataGridRows from "./ExpensesDataGridRows";

class ExpensesStorage extends MxDataArray {
  dataManager = null;
  driverStorage = null;
  loaded = false;

  setData = (callBack, data = this.data) => {
    this.pushArray(
      data.map((item) => {
        const newItem = new Expense(item);
        if (newItem.Totals) {
          this.setExistingDrivers(newItem);
          return newItem;
        } else {
          // this.dataManager.del(this.dataManager.Tables.Finance_Expenses.TableName, {ID: newItem.ID}, () => {
          //     console.log('deleting',newItem);
          //
          // }, () => {
          // });
        }
      }),
    );

    Expense.getExpensesDirectCostTotals();
    Expense.getDirectCostDrivers();
    Expense.getSalesAndMarketingTotals();
    Expense.getGeneralAndAdministrativeTotals();
    Expense.getResearchAndDevelopmentTotals();
    Expense.getPersonnelExpenses();
    Expense.getOperatingExpenses();
    Expense.getExpenseTotals();
    Revenue.getRevenueGrowth(Expense.getExpenseTotals());
    Expense.getExpenseOnlyTotals();
    Expense.getTotalOperatingExpenses();

    // Expense.getDirectCostDrivers();
    // Expense.getExpensesDirectCostTotals();
    CostSales.getCostSalesTotals();
    // Expense.getOperatingExpenses();
    // Expense.getResearchAndDevelopmentTotals()
    this.loaded = true;

    callBack();
  };

  setGridRows = () => ExpensesDataGridRows(this);

  setExistingDrivers = (item) => item.getAllDrivers().forEach((d) => (d.IsExisting = true));

  loadExpenses = (callBack, shouldFinish = true) => {
    this.clear();
    this.dataManager.get(
      this.dataManager.Tables.Finance_Expenses.TableName,
      { ID_CompanyScenario: this.dataManager.CompanyScenarioInfo.ID },
      (data) => {
        if (shouldFinish) {
          this.setData(callBack, data);
        } else {
          this.data = data;
          callBack();
        }
      },
      null,
      false,
    );
  };

  static create = () => {
    const newArr = new ExpensesStorage();
    newArr.pushOld = newArr.push;
    newArr.push = newArr.pushThis;

    return newArr;
  };
}

export default ExpensesStorage;
