import {useState} from "react";

const ConditionalWrapper = ({function_name, children, initialValue = false}) => {
	const [show, setShow] = useState(initialValue)

	if (!global[function_name]) {
		global[function_name] = setShow;
	}

	if (show) {
		return children;
	}
}

export default ConditionalWrapper