import React from "react";
import Button from "./../../../../../components/actions/Button";
import { FirstSection } from "../FirstSection";
import { ButtonsWrapper } from "../ButtonsWrapper";
import { ParagraphP2Strong } from "../PargaraphP2Strong";
import styled from "styled-components";
import { Theme } from "./../../../../../theme/styles/theme";
import DatePickerYM from "../../../../../components/actions/DatePickers/DatePickerYM";
import onboarding from "./../../components/onboarding.scss";
import ButtonMui from "../../../../../components/buttons/buttonMui/buttonMui";
import { ArrowLeft, ArrowRight } from "../../../../../components/icons/svgIcons";
import { Label } from "../../../../../components/typography/label/Label";
import MaterialSelect from "../../../../../components/actions/SelectM";
import { ForecastPeriodYears } from "../../../../../data/Finance/constants";

const InputsDiv = styled.div`
  width: 100%;
`;

const currencies = [
  { description: "Dollar ($)", value: "$" },
  { description: "Euro (€)", value: "€" },
  { description: "British pound (£)", value: "£" },
];

export default class Step5 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  handleChangeMonthAndYear = (e, b) => {
    if (e) {
      this.props.company.StartMonth = e.getMonth();
      this.props.company.StartYear = e.getFullYear();
      this.forceUpdate();
    }
  };

  changeForecastPeriodYears = (e) => {
    // ActiveYears
    this.props.company.ActiveYears = e.target.value;
    this.forceUpdate();
  };

  render() {
    return (
      <>
        <div className="p title strong center">When do you want your forecast to begin?</div>
        <div className="p p2 center p_t">
          Usually this is the starting month of your fiscal year, rather than your company founding
          date.
        </div>
        <div className="spsocwn2_container">
          <FirstSection>
            <InputsDiv>
              <ButtonsWrapper>
                <div className="o_btn_container">
                  <div className="p p2 strong spsocwn2_title">Forecast Start</div>
                </div>
                <div className="o_btn_container start m_r">
                  <div
                    className="smaller_container"
                    key={`date_${this.props.company.StartMonth}_${this.props.company.StartYear}`}
                  >
                    <DatePickerYM
                      onChange={this.handleChangeMonthAndYear}
                      value={`${this.props.company.StartYear}-${(this.props.company.StartMonth + 1).toString().padStart(2, "0")}`}
                      className="psoc_custom_date_year_datepicker"
                      width="148px"
                      views={["year", "month"]}
                    />
                  </div>
                </div>
              </ButtonsWrapper>

              <ButtonsWrapper>
                <div className="o_btn_container">
                  <div className="p p2 strong spsocwn2_title">Forecast Period</div>
                </div>
                <div className="o_btn_container start">
                  <MaterialSelect
                    width="148px"
                    onChange={this.changeForecastPeriodYears}
                    value={this.props.company.ActiveYears}
                    options={ForecastPeriodYears}
                  />
                </div>
              </ButtonsWrapper>
            </InputsDiv>
          </FirstSection>
        </div>
        <ButtonsWrapper key={`o_b_w_${this.state.key}`}>
          <div className="o_btn_container">
            <ButtonMui
              startIcon={<ArrowLeft />}
              label={"Back"}
              width={120}
              variant={"contained"}
              onClick={this.props.BackStep}
            />
          </div>
          <div className="o_btn_container start">
            <ButtonMui
              label={"Next"}
              width={120}
              variant={"contained"}
              endIcon={<ArrowRight />}
              onClick={this.props.NextStep}
            />
          </div>
        </ButtonsWrapper>
      </>
    );
  }
}
