import React from "react";
import "./components/CalculatedDriver.scss";
import CalculatedDriverTitle from "./CalculatedDriverTitle";
import CalculatedDriverDetails from "./CalculatedDriverDetails";
import PropTypes from "prop-types";
import { AccessControl } from "../../../../../data/Permissions/AccessControl";
import {modeliksLinks} from "../../../../../Enums/ModeliksEnum";

class CalculatedDriver extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      Operators: [
        { DriverName: "+", value: "+" },
        { DriverName: "-", value: "-" },
        { DriverName: "(", value: "(" },
        { DriverName: ")", value: ")" },
        { DriverName: "X", value: "*" },
        { DriverName: "/", value: "/" },
        { DriverName: ",", value: "," },
      ],
      ComplexOperators: [
        {
          DriverName: "Round",
          value: 1,
          complex: true,
          hasInput: true,
          Formula: "MxMath.FormulaRound(",
          Name: "Round(",
        },
        {
          DriverName: "Round Up",
          complex: true,
          Formula: "MxMath.FormulaRoundUp(",
          Name: "Round Up(",
        },
        {
          DriverName: "Round Down",
          complex: true,
          Formula: "MxMath.FormulaRoundDown(",
          Name: "Round Down(",
        },
        {
          DriverName: "Min",
          complex: true,
          isArray: true,
          isDragDisabled: true,
          Formula: "MxMath.FormulaMin([",
          Name: "Min(",
          className: "complex_operator",
        },
        {
          DriverName: "Max",
          complex: true,
          isArray: true,
          isDragDisabled: true,
          Formula: "MxMath.FormulaMax([",
          Name: "Max(",
          className: "complex_operator",
        },
      ],
      HiddenOperators: [
        { DriverName: ")", value: "])", isDragDisabled: true, className: "complex_operator" },
      ],
      Formula: [],
    };
  }

  componentDidMount() {
    if (this.props.formula && this.props.formula.length > 0) {
      this.splitFormula();
    }
  }
  createFormula = (formulaArr) => {
    if (!formulaArr || !formulaArr.length > 0) {
      return null;
    }
    let Formula = [];
    formulaArr.map((f) => {
      if (f.complex) {
        if (f.hasInput) {
          Formula += f.Formula;
          Formula += f.value;
          Formula += ",";
        } else {
          Formula += f.Formula;
        }
      } else {
        if (f.isDragDisabled) {
          Formula += f.value;
        } else {
          if (f.hasOwnProperty("ID")) {
            Formula += f.ID_f;
          } else {
            Formula += f.value;
          }
        }
      }
    });
    this.props.setFormula(Formula);
    return null;
  };

  splitFormula = () => {
    if (this.props.multiple) {
      return null;
    }
    const fields = this.props.formula
      .replaceAll(" ", "")
      .split("|")
      .filter((item) => {
        if (item && item.length > 0) {
          if (item.includes("MxMath.F")) {
            return item;
          } else {
            if (!item.includes("MxMath") && !item.includes("growth")) {
              return item;
            }
          }
        }
      });

    if (!fields.length > 0) {
      return null;
    }
    const newF = [];
    fields.forEach((f) => {
      if (f.length > 10 && !f.includes("MxMath.F")) {
        newF.push(global.Modeliks.DriversStore.getItem(f));
      } else {
        if (f.length < 2) {
          newF.push(this.state.Operators[this.state.Operators.findIndex((o) => o.value === f)]);
        } else {
          if (f.startsWith("MxMath.F")) {
            const complexOperator = this.state.ComplexOperators.find((o) => f.includes(o.Formula));
            if (complexOperator.hasInput) {
              complexOperator.value = parseInt(f.replace(complexOperator.Formula, ""));
            }
            newF.push(complexOperator);
          } else {
            if (f.startsWith(",")) {
              newF.push(this.state.Operators.find((o) => o.value === f.substring(0, 1)));
              newF.push(this.state.ComplexOperators.find((o) => f.includes(o.Formula)));
            } else if (f === "/(") {
              newF.push(this.state.Operators.find((o) => o.value === f.at(0)));
              newF.push(this.state.Operators.find((o) => o.value === f.at(1)));
            } else if (f === "]))") {
              newF.push(this.state.HiddenOperators[0]);
              newF.push(this.state.Operators.find((o) => o.value === f.at(2)));
            } else if (f === "))])") {
              newF.push(this.state.Operators.find((o) => o.value === f.at(0)));
              newF.push(this.state.Operators.find((o) => o.value === f.at(1)));
              newF.push(this.state.HiddenOperators[0]);
            } else if (f.includes("MxMath.F")) {
              if (f.startsWith(this.state.HiddenOperators[0].value)) {
                newF.push(this.state.HiddenOperators[0]);
                newF.push(this.state.Operators.find((o) => o.value === f.substring(2, 3)));
                newF.push(this.state.ComplexOperators.find((o) => f.includes(o.Formula)));
              } else {
                newF.push(this.state.Operators.find((o) => o.value === f.substring(0, 1)));
                newF.push(this.state.ComplexOperators.find((o) => f.includes(o.Formula)));
              }
            } else if (f === this.state.HiddenOperators[0].value) {
              newF.push(this.state.HiddenOperators[0]);
            } else if (f === ")])") {
              newF.push(this.state.Operators.find((o) => o.value === f[f.length - 1]));
              newF.push(this.state.HiddenOperators[0]);
            } else {
              f.split("").forEach((f) => {
                newF.push(
                  this.state.Operators[this.state.Operators.findIndex((o) => o.value === f)],
                );
              });
            }
          }
        }
      }
    });

    this.setState({ Formula: newF });
  };

  closeCalcDriver = () => {
    if (this.props.drivers) {
      this.props.drivers.forEach((d) => d.cleanDriver());
    }
    this.props.closeCalcululatedDriver(true);
  };

  render() {
    if (this.props.SingleTabFormula) {
      return (
        <div className="single_tab_display_formula_div">
          {this.state.Formula.map((item, index) => (
            <div
              onClick={(e) =>
                item.hasOwnProperty("Values")
                  ? this.props.onClickSingleTabFormula(item)
                  : e.preventDefault()
              }
              className="single_tab_display_formula_button"
              key={`formula_single_t_${item.DriverName}_${index}`}
            >
              {item.hasOwnProperty("complex")
                ? item.Name
                : item.hasOwnProperty("value")
                  ? item.value
                  : item.Name}
            </div>
          ))}
        </div>
      );
    }

    if (!this.props.CalculatedDriver) {
      return null;
    }

    return (
      <div className="fdd_calculated_driver_container">
        <CalculatedDriverTitle
          title={this.props.title}
          IsKPI={this.props.IsKPI}
          isNew={!(this.props.formula && this.props.formula.length > 0)}
          enableClose={this.props.enableClose}
          closeCalcululatedDriver={this.closeCalcDriver}
        />
        <AccessControl
          allowedPermissions={[
            {
              [global.Modeliks.PERMISSIONS.Financials.key]:
                global.Modeliks.PERMISSIONS.Financials.restrictions.FinancialDriverFeature.key,
            },
          ]}
          renderNoAccess={
            <p className="notification_text">
              Oops! It seems that this feature is not available in your current subscription plan.
              Unlock the full potential of Modeliks by upgrading to a more advanced plan today. Gain
              access to a wide range of features for business planning, financial forecasting and
              analysis, empowering you to make smarter decisions and achieve greater success.Check
              out a detailed overview of the features here{" "}
              <a href={modeliksLinks.pricing} target="#blak">
                Compare Plans
              </a>
              .
            </p>
          }
        >
          <CalculatedDriverDetails
            totalDriver={this.props.totalDriver}
            drivers={this.props.drivers}
            revenueID={this.props.id && this.props.id.dataGridRow.data.ID}
            Formula={this.state.Formula}
            hasFormula={this.state.Formula.length > 0}
            setFormula={this.props.setFormula}
            createFormula={this.createFormula}
            title={this.props.title}
            Operators={[...this.state.Operators, ...this.state.ComplexOperators]}
            HiddenOperators={this.state.HiddenOperators}
            customIndicator={this.props.customIndicator}
            errorMessage={this.props.errorMessage}
          />
        </AccessControl>
      </div>
    );
  }
}
CalculatedDriver.propTypes = {
  customIndicator: PropTypes.bool,
  SingleTabFormula: PropTypes.bool,
};

CalculatedDriver.defaultProps = {
  customIndicator: false,
  SingleTabFormula: false,
};

export default CalculatedDriver;
