import * as React from "react";
import Dialog from "@mui/material/Dialog";
import ButtonJ from "../Buttons/Button";
import TabsMenu from "../menus/TabsMenu/TabsMenu";
import "./SectionDialog.scss";
import IconButton from "@mui/material/IconButton";
import { Exit } from "../IconButtons/SubMenuIcons";
import Button from "../../../../components/actions/Button";
import DeleteForeverSharpIcon from "@mui/icons-material/DeleteForeverSharp";
import CalculatedDriver from "../../../../data/Finance/CalculatedDriver";
import { TrashCan } from "../../../../components/icons/svgIcons";

export default function ChartDialog(props) {
  const [open, setOpen] = React.useState(false);

  const [selectedIDs, setSelectedIDs] = React.useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        disable={props.disableEdit}
        label="Add new Chart"
        size="large"
        width="200px"
        backgroundColor="primary"
        padding="10px 15px 10px 10px"
        onClick={() => {
          props.handleAddChart();
        }}
        iconRight={true}
      />
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={open}
        className={"section_dialog"}
        onClose={handleClose}
      >
        <div className={"dialog_header"}>
          <div className={"dialog_title"}>Add new chart</div>
          <div className={"exit_btn_container"}>
            <IconButton onClick={() => handleClose()}>
              <Exit />
            </IconButton>
          </div>
        </div>
        <div className={"dialog_content"}></div>
        <div className={"dialog_actions_container"}>
          <div className={"dialog_actions"}>
            <div style={{ display: "flex" }}></div>
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
}
