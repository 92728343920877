import React from "react";
import styled from "styled-components";
import { Theme } from "../../theme/styles/theme";

const StyledNavigationFinancials = styled.div`
  display: ${(props) => (props.hidden ? "none" : "flex")};
  height: 60px;
  max-height: 60px;
  min-height: 60px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${Theme.colors.primary.lightgrey[150]};
  padding-right: 16px;
`;

export const NavigationF = ({ ...props }) => {
  return <StyledNavigationFinancials {...props}>{props.children}</StyledNavigationFinancials>;
};
