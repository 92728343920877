import React from "react";
import SP_Signups_Component from "../../components/SP_Signups_Component";
import SP_Driver_Control from "../../components/SP_Driver_Control";
import SP_Driver_Values_Control from "../../components/SP_Driver_Values_Control";
import SP_Driver_BD_Component from "../../components/SP_Driver_BD_Component";
import SimpleValues from "./SimpleValues";
import CalculatedDriverGrowth from "../../../../../components/FinanceDetails/components/CalculatedDriverGrowth";
import FinanceTable from "../../../../../../../../components/tables/FinanceTable";

class Signups extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changed: true,
    };
    this.field = this.props.field;
  }

  onChangeCustomers = (child, value) => {
    if (this.props.tab.hasOwnProperty("errors") && this.props.tab.errors.customersAtStart) {
      this.props.tab.errors.customersAtStart = false;
    }

    if (value === "") {
      value = null;
    }

    child.Value = value;
    this.forceUpdate();
  };

  onChangeOverAllValues = () => {
    console.log("onChangeOverAllValues");
  };

  changeBreakDown = () => {
    const { revenue, field, childrens, onDataChanged } = this.props;

    if (revenue && (!revenue.Quantity.Formula || revenue.Quantity.Formula.includes("quantity"))) {
      if (this.checkQuantitySplit()) {
        if (revenue[field].hasOwnProperty("GrowthDriver")) {
          revenue[field].removeGrowth();
        }

        revenue.removeQuantitySplit(field);
      } else {
        if (
          childrens &&
          childrens.length > 0 &&
          childrens[0][field].hasOwnProperty("GrowthDriver")
        ) {
          childrens.forEach((child) => {
            child[field].removeGrowth();
            child[field].resetSavedProps();
          });
        }

        revenue.useQuantitySplit(field);
      }
      onDataChanged();
    }
  };

  checkQuantitySplit = () => {
    return this.props.revenue.hasQuantitySplit();
  };

  handleGrowth = (shouldRefresh = false) => {
    const { childrens, field, onDataChanged } = this.props;

    if (childrens && childrens.length > 0) {
      if (!childrens[0][field].hasOwnProperty("GrowthDriver")) {
        childrens.forEach((child) => child[field].convertToGrowthDriver());
      } else {
        childrens.forEach((child) => child[field].removeGrowth());
      }

      if (shouldRefresh) {
        onDataChanged();
      }
    }
  };

  getChildrenGrowth = () => {
    const childrenGrowth = this.props.childrens.map((row) => {
      const QuantitySplit = row[this.props.field].GrowthDriver;
      const Driver = row[this.props.field];
      if (!QuantitySplit) {
        return;
      }

      return {
        id: QuantitySplit.ID,
        header: `${row.RevenueName} - ${QuantitySplit.Name}`,
        field: this.props.field,
        data: QuantitySplit,
        driver: Driver,
      };
    });

    return childrenGrowth.filter((t) => t);
  };

  render() {
    return (
      <>
        <SP_Signups_Component
          onChange={this.changeBreakDown}
          unit={this.state.unit}
          revenue={this.props.revenue}
          checked={this.checkQuantitySplit()}
          percentage={this.state.percentage}
          breakDown={this.state.breakDown}
        />

        {this.checkQuantitySplit() ? (
          <SimpleValues
            tab={this.props.tab}
            revenue={this.props.revenue}
            childrens={this.props.childrens}
            key={this.props.key}
            unit={this.props.tab.unit}
            field={this.props.field + "_Split"}
            headers={this.props.headers}
            onDataChanged={this.props.onDataChanged}
          />
        ) : (
          <>
            <SP_Driver_Control
              onScroll={this.props.onScroll}
              rows={this.props.childrens}
              min={0}
              tab={this.props.tab}
              field={this.props.field}
              headers={this.props.headers}
              onChange={this.props.onDataChanged}
            />
            <CalculatedDriverGrowth
              data={this.props.childrens[0][this.props.field]}
              isEnabledGrowth={this.props.tab.growthEnabled}
              isHidden={false}
              onScroll={this.props.onScroll}
              onDataChanged={this.props.onDataChanged}
              headers={this.props.headers}
              onChange={() => this.handleGrowth(true)}
              growthDriverTableID={"growth_driver_quantity_split"}
              title={this.props.title}
              hasChildren={true}
              showRowHeaders={true}
              className={"sticky"}
              childrenData={this.getChildrenGrowth()}
            />
          </>
        )}

        <SP_Driver_Values_Control
          label="Customers at start"
          min={0}
          rows={this.props.childrens}
          field="CustomersAtStartInit"
          onChange={this.onChangeCustomers}
        />

        {this.props.tab.hasOwnProperty("errors") && this.props.tab.errors.customersAtStart ? (
          <p className="psrcrsmcs_error_values">This Inputs must be greater than 0</p>
        ) : (
          <p className="psrcrsmcs_error_values" />
        )}
      </>
    );
  }
}

export default Signups;
