import Revenue from "../../Revenue/index";
import CalculatedDriver_Values from "../../CalculatedDriver/CalculatedDriver_Values";
import datastructure from "../../../datastructure.json";
import { UnitTypes } from "../../CalculatedDriver/constants";
import ProfitLoss from "../../Reports/ProfitLoss";
import Financing from "../../Financing";
import FinanceGeneric from "../../FinanceGeneric";

class Overview extends FinanceGeneric {
  constructor(db_record) {
    super(db_record);
    // if (this.db_record) {
    //     this.setDriversFromDataStorageSub();
    // }
  }

  static ReferenceBaseValues = Object.assign({
    Revenue: {
      TTM: null,
      LY: null,
      CY: null,
      LFY: null,
    },
    EBITDA: {
      TTM: null,
      LY: null,
      CY: null,
      LFY: null,
    },
    NetIncome: {
      TTM: null,
      LY: null,
      CY: null,
      LFY: null,
    },
    BookValue: {
      TTMBook: null,
      LY: null,
      CY: null,
      LFY: null,
    },
  });

  createDriverValues = (recreate = false) => {
    Object.values(this.constructor.ReferenceBaseValues).forEach((driverVal) => {
      if (this.hasOwnProperty(driverVal.fieldName) == false) {
        this[driverVal.fieldName] = new CalculatedDriver_Values(
          null,
          driverVal.driverID,
          null,
          driverVal.unit,
        );
      }
    });
  };

  getValuationValueID = (valID) => {
    return `${Overview.TableName}-${this.ID}-${valID}`;
  };

  setDriversFromDataStorageSub = () => {
    Object.values(this.constructor.ReferenceBaseValues).forEach((driverVal) => {
      this[driverVal.fieldName] = global.Modeliks.DriverValuesStore.getItem(
        this.getValuationValueID(driverVal.driverID),
      );
    });
  };

  static getDriverRefBase = (type = "Revenue") => {
    switch (type) {
      case "Revenue":
        return Revenue.getRevenueTotals();
      case "EBITDA":
        return ProfitLoss.getReport().EBITDA;
      case "NetIncome":
        return ProfitLoss.getReport().NetIncome;
      case "BookValue":
        return Financing.getEquity();
      default:
        return Revenue.getRevenueTotals();
    }
  };

  static getDriverRefBaseFormulas = (type = "TTM", driver, value) => {
    const curFullYear = new Date().getFullYear();
    const curMonth = new Date().getMonth();

    const lastYear =
      global.Modeliks.DateHelper.years_all[global.Modeliks.DateHelper.years_all.length - 1];
    let currentYear = global.Modeliks.DateHelper.years_all.find((y) => y.Year === curFullYear);
    if (!currentYear) {
      currentYear = global.Modeliks.DateHelper.years_all[0];
    }
    let findLastYearActual = global.Modeliks.DateHelper.years_before_actual.find(
      (y) => y.Order === -(currentYear.Order + 1),
    );
    if (!findLastYearActual) {
      findLastYearActual = global.Modeliks.DateHelper.years_before_actual.find(
        (y) => y.Order === -1,
      );
    }

    let findCurMonth = global.Modeliks.DateHelper.months.find(
      (m) => m.Year === curFullYear && m.Order === curMonth,
    );
    if (!findCurMonth) {
      findCurMonth = global.Modeliks.DateHelper.months[0];
    }
    let findTTMMonths = global.Modeliks.DateHelper.all_periods.filter(
      (m) =>
        m.PeriodType === "month" &&
        m.Order < findCurMonth.Order &&
        m.Order >= findCurMonth.Order - 12,
    );

    let findBeforeMonth = global.Modeliks.DateHelper.all_periods.find(
      (m) => m.PeriodType === "month" && m.Order === findCurMonth.Order - 1,
    );
    if (!findBeforeMonth) {
      findBeforeMonth = findCurMonth;
    }

    switch (type) {
      case "TTMBook":
        value.Formula = driver.getItemByDateSufix(findBeforeMonth.sufix).ID_f;
        value.evalFormula = value.evalFormula.replaceAll("ValueFormula", "ActualFormula");
        return value;
      case "TTM":
        value.Formula = findTTMMonths.map((m) => driver.getItemByDateSufix(m.sufix).ID_f).join("+");
        value.evalFormula = value.evalFormula.replaceAll("ValueFormula", "ActualFormula");
        return value;
      case "LY":
        value.Formula = driver.getItemByDateSufix(findLastYearActual.sufix).ID_f;
        value.evalFormula = value.evalFormula.replaceAll("ValueFormula", "ActualFormula");
        return value;
      case "CY":
        value.Formula = driver.getItemByDateSufix(currentYear.sufix).ID_f;
        return value;
      case "LFY":
        value.Formula = driver.getItemByDateSufix(lastYear.sufix).ID_f;
        return value;
      default:
        value.Formula = driver.getItemByDateSufix(lastYear.sufix).ID_f;
        return value;
    }
  };

  static GetReferenceBaseValues = () => {
    const ObjectRefBase = Overview.ReferenceBaseValues;

    Object.entries(Overview.ReferenceBaseValues).forEach(([key, values]) => {
      const ObjectRefBaseSingle = ObjectRefBase[key];
      Object.keys(values).forEach((keychild) => {
        const valueID = `RefBase-${keychild}-${key}`;
        let value = global.Modeliks.DriverValuesStore.getItem(valueID);
        if (value == null) {
          value = new CalculatedDriver_Values(null, valueID, null, UnitTypes.Price);
          Overview.getDriverRefBaseFormulas(keychild, Overview.getDriverRefBase(key), value);
        }

        ObjectRefBaseSingle[keychild] = value;
      });
    });

    return ObjectRefBase;
  };
}

export default Overview;
