import PropTypes from "prop-types";
import React, { PureComponent, useState } from "react";
import {
  centerToTL,
  degToRadian,
  getAngle,
  getLength,
  getNewStyle,
} from "../../../ResizableRect/utils";
import StyledLineRect from "../../StyledLineRect";

export default class LineRect extends PureComponent {
  constructor() {
    super();
    this.state = {
      rotate: false,
    };
  }

  static propTypes = {
    styles: PropTypes.object,
    moveHandle: PropTypes.bool,
    onResizeStart: PropTypes.func,
    onResize: PropTypes.func,
    onResizeEnd: PropTypes.func,
    onRotateStart: PropTypes.func,
    onRotate: PropTypes.func,
    onRotateEnd: PropTypes.func,
    parentRotateAngle: PropTypes.number,
    className: PropTypes.string,
    onDragStart: PropTypes.func,
    onDrag: PropTypes.func,
    onDragEnd: PropTypes.func,
  };

  setElementRef = (ref) => {
    this.$element = ref;
  };

  setParentElementRef = (ref) => {
    this.$parentElement = ref;
  };

  setRightElementRef = (ref) => {
    this.$rightElement = ref;
  };

  startDrag = (e) => {
    let elemRect = this.$element.getBoundingClientRect();
    let SlideComp = document.getElementById("scale_panel_container").getBoundingClientRect();
    let { clientX: startX, clientY: startY } = e;
    this.props.onDragStart && this.props.onDragStart();
    this._isMouseDown = true;
    document.body.style.cursor = "move";
    const onMove = (e) => {
      if (!this._isMouseDown) return; // patch: fix windows press win key during mouseup issue
      e.stopImmediatePropagation();
      const { clientX, clientY } = e;
      const deltaX = clientX - SlideComp.left - (startX - elemRect.left);
      const deltaY = clientY - SlideComp.top - (startY - elemRect.top);
      this.props.onDrag(deltaX, deltaY, elemRect.left, elemRect.top);
    };
    const onUp = () => {
      document.body.style.cursor = "auto";
      document.removeEventListener("mousemove", onMove);
      document.removeEventListener("mouseup", onUp);
      if (!this._isMouseDown) return;
      this._isMouseDown = false;
      this.props.onDragEnd && this.props.onDragEnd();
    };
    document.addEventListener("mousemove", onMove);
    document.addEventListener("mouseup", onUp);
  };

  // Resize and Rotate
  startResize = (e, flag = false) => {
    e.stopPropagation();
    const scaledRect = document.getElementById("se_scale_panel").getBoundingClientRect();
    const offsetMouse = 1 / window.panelScale;
    if (e.button !== 0) return;
    // document.body.style.cursor = cursor
    const {
      styles: {
        position: { centerX, centerY },
        size: { width, height },
        transform: { rotateAngle: startAngle },
      },
    } = this.props;
    // if(flag){
    //     this.props.onResize(this.calculateAngle(startAngle), startAngle, width)
    //     flag = false;
    // }

    const { clientX, clientY } = e;
    const rect = this.$element.getBoundingClientRect();
    const parentRect = this.$parentElement.getBoundingClientRect();
    const rightRect = this.$rightElement.getBoundingClientRect();

    const type = e.target.getAttribute("class").split(" ")[0];

    const center = {
      x: flag ? rightRect.left + rightRect.width / 2 : parentRect.left, //type == 'l'
      y: flag ? rightRect.top + rightRect.height / 2 : parentRect.top, //type == 'l'
    };

    const startVector = {
      x: clientX - center.x,
      y: clientY - center.y,
    };

    this.props.onRotateStart && this.props.onRotateStart();
    this.props.onResizeStart && this.props.onResizeStart();
    this._isMouseDown = true;
    const onMove = (e) => {
      if (!this.state.rotate) {
        this.setState({ rotate: true });
      }
      if (!this._isMouseDown) return; // patch: fix windows press win key during mouseup issue
      e.stopImmediatePropagation();
      const { clientX, clientY } = e;
      const deltaX = (clientX - center.x) * offsetMouse;
      const deltaY = (clientY - center.y) * offsetMouse;

      const rotateVector = {
        x: clientX - center.x,
        y: clientY - center.y,
      };
      const angle = getAngle(startVector, rotateVector);

      this.props.onResize(
        angle,
        startAngle,
        deltaX,
        deltaY,
        clientX * offsetMouse - scaledRect.left * offsetMouse,
        clientY * offsetMouse - scaledRect.top * offsetMouse,
        flag,
      );
    };

    const onUp = () => {
      if (this.state.rotate) {
        this.setState({ rotate: false });
      }
      document.body.style.cursor = "auto";
      document.removeEventListener("mousemove", onMove);
      document.removeEventListener("mouseup", onUp);
      if (!this._isMouseDown) return;
      this._isMouseDown = false;
      this.props.onResizeEnd && this.props.onResizeEnd();
    };
    document.addEventListener("mousemove", onMove);
    document.addEventListener("mouseup", onUp);
  };

  render() {
    const {
      styles: {
        position: { centerX, centerY },
        size: { width, height },
        transform: { rotateAngle },
        zIndex: zIndex,
      },
      onClick,
      className,
    } = this.props;

    const style = !this.props.businessPlan
      ? {
          width: Math.abs(width),
          height: 0,
          left: centerX - Math.abs(width) / 2,
          top: centerY - Math.abs(height) / 2,
          zIndex: zIndex,
        }
      : {
          position: "relative",
          width: "100%",
          height: 1,
          display: "block",
          zIndex: zIndex,
          marginTop: "20px",
        };
    const parentStyle = {
      position: "absolute",
      width: 0,
      height: 0,
      overflow: "visible",
      left: 0,
      top: 0,
      transform: `rotate(${rotateAngle}deg)`,
    };

    return (
      <StyledLineRect
        ref={this.setElementRef}
        onClick={onClick}
        className={"line_rect" + className}
        style={style}
        onMouseDown={this.startDrag}
      >
        <div style={parentStyle}>
          <div
            className={"circle"}
            onMouseDown={(e) => this.startResize(e, true)}
            ref={this.setParentElementRef}
            style={{ position: "absolute", top: -8, left: -8 }}
          />
          {this.props.children}
          <div
            ref={this.setRightElementRef}
            className={"circle"}
            onMouseDown={this.startResize}
            style={{ position: "absolute", top: -8, left: Math.abs(width - 8) }}
          />
        </div>
      </StyledLineRect>
    );
  }
}
